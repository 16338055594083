import { graphqlClient } from "common/graphqlClient";
import { Company } from "generated/graphql";
import {
  FeatureEnumType,
  GetNotificationsQuery,
  Invite,
  Organization,
  OrganizationUserGroupsQuery,
  SessionAccountData,
  getSdk,
} from "generated/sdk";
import { Unpacked, definitely, definitelyFilter } from "generated/utils";
import { computed, runInAction } from "mobx";
import { IDataLoaderOpts, createObservableContainer } from "storeContainer";
import { IStoreContainer } from "storesMobx";
import { StoreBase } from "storesMobx/StoreBase";
import { AclStore } from "./AclStore";

const {
  SessionAccount,
  OrganizationUserGroups,
  AccountInvites,
  AcceptInvite,
  RejectInvite,
  UpdateNotification,
  AccountPhoneNumberVerificationCodeRequest,
  AccountPhoneNumberVerificationCodeVerify,
  AccountUpdatePassword,
  GetNotifications,
} = getSdk(graphqlClient);

export class SessionStore extends StoreBase {
  session = createObservableContainer<SessionAccountData>();
  notifications = createObservableContainer<GetNotificationsQuery["GetNotifications"]>();
  invites = createObservableContainer<NonNullable<Invite[]>>();
  notificationsNumber = createObservableContainer<NonNullable<number>>();
  allBankAccounts = createObservableContainer<any>();
  organizationUserGroups =
    createObservableContainer<
      NonNullable<Unpacked<NonNullable<OrganizationUserGroupsQuery["OrganizationUsers"]>>>["user_groups"]
    >();

  // current session user's acl (not to be confused with storeContainer.AclStore, which stores acl details for an
  // arbitrary user whose permissions are being displayed)
  acl = new AclStore();

  codeRequested = createObservableContainer<NonNullable<boolean | undefined>>();
  codeConfirmed = createObservableContainer<NonNullable<boolean | undefined>>();

  init(storeContainer: IStoreContainer) {
    super.init(storeContainer);
    this.acl.init(storeContainer);
  }

  loadSessionDependencies() {
    const ctxStore = this.storeContainer?.ContextStore;

    if (!ctxStore) {
      return;
    }

    if (ctxStore.selectedOrganizationId) {
      this.acl.setContext(ctxStore.selectedOrganizationId, this.selectedOrganizationUserId ?? null, null);
    }
  }

  fetchSession = async () => (await SessionAccount()).SessionAccount;

  loadNotifications = (force?: boolean) => {
    this.notifications.cachedLoad(async () => (await GetNotifications()).GetNotifications, [], {
      forceUpdate: force,
    });
  };

  fetchOrganizationUserGroups = async (organization_id: string, organization_user_id: string) =>
    definitelyFilter((await OrganizationUserGroups({ organization_id, organization_user_id })).OrganizationUsers)[0]
      .user_groups;

  @computed
  get isLoaded() {
    return this.session.isLoaded && !this.acl.isFetching;
  }

  updateFeatureAccessRequest(feature: FeatureEnumType, organizationId: string) {
    runInAction(() => {
      const organizationUser = this.session.data?.organizationUsers?.find(
        (orgUser) => orgUser.organization.id === organizationId,
      );

      if (organizationUser) {
        const featureAccessRequests = organizationUser.organization?.feature_access_requests;

        if (featureAccessRequests) {
          featureAccessRequests.push({ feature });
        }
      }
    });
  }

  async loadSession() {
    await this.session.load(this.fetchSession() as Promise<SessionAccountData | undefined>);
    return this.session;
  }

  async getSession() {
    if (this.isLoaded) return this.session;
    return await this.loadSession();
  }

  @computed
  get isLoggedIn() {
    return !!this.session.data?.account?.id;
  }

  @computed
  get organizations(): Organization[] {
    return definitelyFilter(this.session._dataObsevable?.organizationUsers).map((ou) => ou.organization);
  }

  isOrganizationIdInSession = (organizationId?: string) =>
    this.session.data?.organizationUsers?.find((ou) => ou.organization.id === organizationId);

  getOrganization = (organizationId?: string): Organization | undefined =>
    this?.organizations?.find((organization) => organization.id === organizationId);

  @computed get selectedOrganizationUserId() {
    const ctxStore = this.storeContainer?.ContextStore;

    if (ctxStore?.selectedOrganizationId) {
      return definitely(
        this.storeContainer?.SessionStore.session.data?.organizationUsers?.find(
          (ou) => ou?.organization.id === ctxStore.selectedOrganizationId,
        ),
      )?.id;
    }

    return undefined;
  }

  companiesOfOrganization(organizationId: string): Company[] {
    return definitelyFilter(
      definitelyFilter(this.organizations)
        .filter((o) => o.id === organizationId)
        .map((o) => o.companies)[0],
    );
  }

  async fetchInvites(): Promise<Invite[]> {
    return definitelyFilter((await AccountInvites({})).AccountInvites);
  }

  getInvites(opts?: IDataLoaderOpts) {
    if (this.session.data?.has_pending_invites) {
      return this.invites.cachedLoad(() => this.fetchInvites(), [], { ...opts });
    }
  }

  async acceptInvite(invite_id: string) {
    await AcceptInvite({
      invite_id,
    });
    await new Promise((resolve) => setTimeout(resolve, 2000));
    await this.loadSession();
    await this.getInvites();
  }

  async declineInvite(invite_id: string) {
    await RejectInvite({
      invite_id,
    });
    await this.getInvites();
  }

  async UpdateNotification(notification_id: string) {
    await UpdateNotification({
      notification_id,
    }).then((res) => {
      this.notificationsNumber.load(Promise.resolve(res.UpdateNotification!));
    });
  }
  async UpdatePassword(passwordCredentials: {
    password_current: string;
    password: string;
    password_confirmation: string;
  }) {
    const res = await AccountUpdatePassword(passwordCredentials);
    return res;
  }
  async RequestCode(phone_number: string | undefined | null, channel: string) {
    if (phone_number) {
      await this.codeRequested.load(this._requestCode(phone_number, channel));
    }
  }

  async ConfirmCode(code: string) {
    await this.codeConfirmed.load(this._confirmCode(code));
  }

  async _requestCode(phone_number: string, channel: string): Promise<boolean | undefined> {
    const res = await AccountPhoneNumberVerificationCodeRequest({ phone_number, channel });
    return definitely(res.AccountPhoneNumberVerificationCodeRequest);
  }

  async _confirmCode(code: string): Promise<boolean | undefined> {
    const res = await AccountPhoneNumberVerificationCodeVerify({ code });
    return definitely(res.AccountPhoneNumberVerificationCodeVerify);
  }
}
