import { observer } from "mobx-react-lite";
import UsersTabs from "./UsersTabs";
import React, { useEffect, useState } from "react";
import { AccordionProps, AlertColor, styled } from "@mui/material";

import AccordionSummary from "@mui/material/AccordionSummary";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Table from "@mui/material/Table";

import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Accordion from "@mui/material/Accordion";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import Snackbar from "@mui/material/Snackbar";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";

import { useDebounce } from "react-use";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import CheckIcon from "@mui/icons-material/Check";
import Card from "@mui/material/Card";
import TableContainer from "@mui/material/TableContainer";
import { useStore } from "../../../../../../storeContainer";
import { AccessRestricted } from "../../../../AccessRestricted";
import { Spinner } from "../../../../../common/Spinner/Spinner";
import { COLORS } from "../../../../../../themes/default";
import { useHistory } from "react-router";
import { UserDetailsModal } from "./UserDetailsModal";
import { GroupDetailsModal } from "./GroupDetailsModal";

const UsersPermissions = () => {
  const history = useHistory();
  const {
    acl: { canManageUsers },
  } = useStore("SessionStore");
  const { selectedOrganizationId } = useStore("ContextStore");

  const organizationUsersStore = useStore("OrganizationUsersStore");
  const userGroupsStore = useStore("UserGroupsStore");

  const aclStore2 = useStore("AclStore2");
  const uiStore = useStore("UiStore");

  const { organizationUsersList, organizationGroupList, isFetching, directPermissionsList } = aclStore2;

  const [searchKeywords, setSearchKeywords] = useState("");
  const [usersAccordionExtended, setUsersAccordionExtended] = useState(true);
  const [rolesAccordionExtended, setRolesAccordionExtended] = useState(true);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [actionableUser, setActionableUser] = useState<any | undefined>(undefined);
  const [openDialog, setOpenDialog] = useState(false);
  const [action, setAction] = useState("remove");

  //Add Group modal
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [description, setDescription] = useState("");
  const [groupIsAdding, setGroupIsAdding] = useState(false);
  const handleModalClose = () => setIsModalOpened(false);
  const handleModalOpen = () => setIsModalOpened(true);

  const [selectedUser, setSelectedUser] = useState<any | undefined>(undefined);
  const [selectedGroup, setSelectedGroup] = useState<any | undefined>(undefined);

  //Add Group Snackbar
  const [openAddGroupSnackbar, setOpenAddGroupSnackbar] = useState(false);
  const [snackbarText, setSnackbarText] = useState("Added group successfully");
  const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor | undefined>("success");

  const open = Boolean(anchorEl);
  const handleClickOnAdd = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const openGroup = (group: string) => {
    history.push(`/org/${selectedOrganizationId}/users2/permissions/group/${group}`);
  };
  const openUser = (user: string) => {
    history.push(`/org/${selectedOrganizationId}/users2/permissions/user/${user}`);
  };
  const loadGroups = async (_forceUpdate = false) => {
    await aclStore2.reload(true);
  };
  const handleAddGroupButton = async () => {
    try {
      setGroupIsAdding(true);
      await userGroupsStore.createUserGroup(name, description);
      setGroupIsAdding(false);
      // history.push(`/org/${selectedOrganizationId}/groups/${response?.CreateUserGroup?.id}`);
      setName("");
      setDescription("");
      setSnackbarText("Added group successfully");
      setSnackbarSeverity("success");
      handleModalClose();
      setOpenAddGroupSnackbar(true);
      await loadGroups(true);
    } catch (e: any) {
      setNameError(e.response.errors?.message);
      setSnackbarText(e.response.errors?.message);
      setSnackbarSeverity("error");
    }
  };

  const doActionOnUser = async () => {
    setOpenDialog(false);
    if (actionableUser) {
      if (action === "remove") {
        if (actionableUser.invite_id) {
          try {
            await organizationUsersStore.deleteInvite(actionableUser.invite_id);
            setActionableUser(undefined);
          } catch (e: any) {
            setOpenAddGroupSnackbar(true);
            setSnackbarText(e.response.errors?.message);
            setSnackbarSeverity("error");
            await organizationUsersStore.loadOrganizationUsers();
          }
        }
      } else {
        await organizationUsersStore.validateUser(actionableUser.id);
        setActionableUser(undefined);
      }
    }
  };

  const StyledAccordion = styled((props: AccordionProps) => (
    <Accordion disableGutters elevation={0} square {...props} />
  ))(() => ({
    backgroundColor: "transparent",
    border: 0,
    "&:not(:last-child)": {
      border: 0,
    },
    "&:before": {
      display: "none",
    },
  }));

  const [filteredUsers, setFilteredUsers] = useState<typeof organizationUsersList>([]);
  const [filteredGroups, setFilteredGroups] = useState<typeof organizationGroupList>([]);

  // console.log("ZZZ filteredUsers", filteredUsers);
  // console.log("ZZZ filteredGroups", filteredGroups);

  useDebounce(
    () => {
      const filteredUsers = organizationUsersList?.filter((user) => {
        if (searchKeywords.length === 0) return true;
        if (user.name) {
          return user.name?.search(new RegExp(searchKeywords, "i")) > -1;
        }
        return false;
      });
      setFilteredUsers(filteredUsers);
    },
    200,
    [searchKeywords, organizationUsersList],
  );

  useDebounce(
    () => {
      const filteredGroups = organizationGroupList?.filter((group) => {
        if (searchKeywords.length === 0) return true;
        if (group.name) {
          return group.name?.search(new RegExp(searchKeywords, "i")) > -1;
        }

        return false;
      });
      setFilteredGroups(filteredGroups);
    },
    200,
    [searchKeywords, organizationGroupList],
  );

  useEffect(() => {
    if (canManageUsers) {
      aclStore2.reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrganizationId]);

  if (!canManageUsers) {
    return <AccessRestricted />;
  }

  return (
    <Box marginTop="40px">
      <UsersTabs selectedTab={0} />
      <Typography fontSize={21} lineHeight={1.5} padding={2} marginTop={2.5} sx={{ background: "#EEF2F7" }}>
        We are here to help with all your permission settings, reach out to us at{" "}
        <a href="tel:212-765-4400">212-765-4400</a> or at{" "}
        <a href="mailto:techsupport@cherrynet.com">techsupport@cherrynet.com</a> for help or any questions you may have.
        {/* Permission are the keys to your kingdom!! Thread carefully!!!<br></br> While we continue to add, functionality
        and control, these pages can be a bit overwhelming and complex, please reach out to us at&nbsp;
        <a href="tel:212-765-4400">212-765-4400</a> or at&nbsp;
        <a href="mailto:techsupport@cherrynet.com">techsupport@cherrynet.com</a> and we'll make sure it is done as you really
        want. */}
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Card
          variant={"outlined"}
          component="form"
          sx={{ mt: 3, p: 1, display: "flex", alignItems: "center", width: 260 }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search"
            value={searchKeywords}
            onKeyPress={(ev) => {
              if (ev.key === "Enter") {
                // Do code here
                ev.preventDefault();
              }
            }}
            onChange={(event) => {
              setSearchKeywords(event.target.value);
            }}
          />
        </Card>
        <Button
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClickOnAdd}
          sx={{
            textTransform: "none",
          }}
          startIcon={<AddIcon />}
          color={"secondary"}
          variant={"contained"}
        >
          Add
        </Button>

        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem
            onClick={() => {
              handleClose();
              uiStore.showInviteUserModal = true;
            }}
          >
            Add User
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleClose();
              handleModalOpen();
            }}
          >
            Add User Group
          </MenuItem>
        </Menu>
      </Box>
      {isFetching ? (
        <Box sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
          <Spinner withSpaceAround />
        </Box>
      ) : (
        <>
          <StyledAccordion
            sx={{ mt: 2, p: 0 }}
            disableGutters={true}
            expanded={usersAccordionExtended}
            onChange={() => {
              setUsersAccordionExtended(!usersAccordionExtended);
            }}
          >
            <AccordionSummary
              sx={{
                alignItems: "center",
                p: 0,
              }}
            >
              {usersAccordionExtended ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}

              <Typography variant={"h6"} sx={{ lineHeight: "23px" }}>
                Users ({filteredUsers.length})
              </Typography>
            </AccordionSummary>
            {organizationUsersStore.organizationUsersList.isFetching ? (
              <Spinner />
            ) : filteredUsers.length ? (
              <AccordionDetails sx={{ p: 0 }}>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>User Name</TableCell>
                        <TableCell>User Groups</TableCell>
                        <TableCell>Permissions</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell> </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredUsers.map((user, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            {user.state !== "UNVALIDATED" ? (
                              <Box
                                onClick={() => {
                                  setSelectedUser(user);
                                }}
                                sx={{ cursor: "pointer" }}
                              >
                                <Box display="flex" gap="8px" alignItems="center">
                                  <PersonOutlineIcon color={"disabled"} />
                                  <Box display="flex" flexDirection="column" justifyContent="center" alignItems="start">
                                    <Typography variant="body1">{user.name}</Typography>
                                    <Typography variant="body2">{user.email}</Typography>
                                  </Box>
                                </Box>
                              </Box>
                            ) : (
                              <Box display="flex" gap="8px">
                                <PersonOutlineIcon color={"disabled"} />
                                <Typography variant={"subtitle2"}>{user.name}</Typography>
                              </Box>
                            )}
                          </TableCell>
                          <TableCell>{user.user_groups?.map((group) => group.name).join(", ") || "none"}</TableCell>
                          <TableCell>
                            {
                              aclStore2.directPermissionsList.filter((dp) => dp.isUser && dp.user_id === user?.id)
                                .length
                            }
                          </TableCell>

                          <TableCell>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {(() => {
                                let color = COLORS.success;
                                switch (user.state) {
                                  case "ACTIVE":
                                    break;

                                  case "INVITED":
                                    color = COLORS.orange;
                                    break;

                                  case "UNVALIDATED":
                                    color = COLORS.error;
                                    break;
                                }
                                return (
                                  <Typography
                                    sx={{
                                      textTransform: "capitalize",
                                      fontWeight: 400,
                                      color: color,
                                    }}
                                  >
                                    {user.state?.toLowerCase() || ""}
                                  </Typography>
                                );
                              })()}
                              {user.state === "INVITED" && (
                                <IconButton
                                  onClick={() => {
                                    setAction("remove");
                                    setActionableUser(user);
                                    setOpenDialog(true);
                                  }}
                                  color={"warning"}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              )}
                              {user.state === "UNVALIDATED" && (
                                <IconButton
                                  onClick={() => {
                                    setAction("validate");
                                    setActionableUser(user);
                                    setOpenDialog(true);
                                  }}
                                  color={"error"}
                                >
                                  <CheckIcon />
                                </IconButton>
                              )}
                            </Box>
                          </TableCell>
                          <TableCell align={"right"}>
                            {user.state !== "UNVALIDATED" ? (
                              <Button
                                sx={{ textTransform: "none" }}
                                onClick={() => openUser(user.id || "")}
                                color={"secondary"}
                                variant={"outlined"}
                                size="small"
                              >
                                View effective permissions
                              </Button>
                            ) : (
                              ""
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            ) : null}
          </StyledAccordion>

          <StyledAccordion
            disableGutters={true}
            expanded={rolesAccordionExtended}
            onChange={() => {
              setRolesAccordionExtended(!rolesAccordionExtended);
            }}
          >
            <AccordionSummary
              sx={{
                alignItems: "center",
                p: 0,
              }}
            >
              {rolesAccordionExtended ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}

              <Typography variant={"h6"} sx={{ lineHeight: "23px" }}>
                User Groups ({filteredGroups.length})
              </Typography>
            </AccordionSummary>{" "}
            {filteredGroups.length ? (
              <AccordionDetails sx={{ p: 0 }}>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>User Group</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Users</TableCell>
                        <TableCell>Permissions</TableCell>
                        <TableCell> </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredGroups.map((group, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            <Button
                              sx={{
                                alignItems: "center",
                                display: "flex",
                                gap: 1,
                                textTransform: "none",
                              }}
                              onClick={() => {
                                setSelectedGroup(group);
                              }}
                            >
                              <GroupOutlinedIcon color={"disabled"} />
                              <Typography
                                variant={"subtitle2"}
                                sx={{
                                  color: COLORS.logoBlue,
                                }}
                              >
                                {group.name}
                              </Typography>
                            </Button>
                          </TableCell>
                          <TableCell>{group.description}</TableCell>
                          <TableCell>{group.organization_users?.length || 0}</TableCell>
                          <TableCell>
                            {directPermissionsList.filter((dp) => !dp.isUser && dp.user_id === group?.id).length || 0}
                          </TableCell>

                          <TableCell align={"right"}>
                            <Button
                              sx={{ textTransform: "none" }}
                              onClick={() => openGroup(group.id || "")}
                              color={"secondary"}
                              variant={"outlined"}
                              size={"small"}
                            >
                              View effective permissions
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            ) : null}
          </StyledAccordion>
        </>
      )}
      <Dialog
        open={openDialog}
        onClose={() => {
          setOpenDialog(false);
          setActionableUser(undefined);
        }}
      >
        <DialogTitle>Confirm</DialogTitle>

        <DialogContent>
          {action === "remove"
            ? `Are you sure you want to remove user ${actionableUser?.name}?`
            : `Are you sure you want to activate user ${actionableUser?.name}?`}
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => {
              setOpenDialog(false);
              setActionableUser(undefined);
            }}
          >
            Cancel
          </Button>
          <Button onClick={doActionOnUser} autoFocus={true}>
            {action === "remove" ? "Remove" : "Activate"}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={openAddGroupSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenAddGroupSnackbar(false)}
      >
        <Alert onClose={() => setOpenAddGroupSnackbar(false)} severity={snackbarSeverity} sx={{ width: "100%" }}>
          {snackbarText}
        </Alert>
      </Snackbar>
      <Dialog
        open={isModalOpened}
        onClose={handleModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Add user group</DialogTitle>
        <DialogContent>
          <Grid container direction={"column"} sx={{ width: "400px" }}>
            <DialogContentText>Please enter user group name</DialogContentText>
            <TextField
              id="textfield-edit-organization"
              label="User Group name"
              variant="outlined"
              sx={{ margin: "10px 0" }}
              value={name || ""}
              onChange={(event) => setName(event.target.value)}
              error={nameError !== ""}
              helperText={nameError}
            />
            <DialogContentText>Please enter user group description</DialogContentText>

            <TextField
              id="textfield-edit-organization"
              label="User Group description"
              variant="outlined"
              sx={{ margin: "10px 0" }}
              value={description || ""}
              onChange={(event) => setDescription(event.target.value)}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModalClose}>Cancel</Button>
          {!groupIsAdding ? (
            <Button onClick={handleAddGroupButton}>Add</Button>
          ) : (
            <Button>
              <Spinner />
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <UserDetailsModal
        user={selectedUser}
        onClose={() => {
          setSelectedUser(undefined);
        }}
      />
      <GroupDetailsModal
        onClose={() => {
          setSelectedGroup(undefined);
        }}
        group={selectedGroup}
      />
    </Box>
  );
};

export default observer(UsersPermissions);
