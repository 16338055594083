import { SvgIcon, SvgIconProps } from "@mui/material";

export const BankIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="18" height="18" viewBox="0 0 18 18" fill="none">
      <path
        d="M8.62073 0.379574C8.84536 0.206809 9.15813 0.206809 9.38276 0.379574L17.5095 6.62957C17.7216 6.79272 17.8063 7.07285 17.7202 7.32622C17.634 7.5796 17.3961 7.75 17.1285 7.75H0.87504C0.607415 7.75 0.369478 7.5796 0.283315 7.32622C0.197153 7.07285 0.281878 6.79272 0.494015 6.62957L8.62073 0.379574ZM9.00008 5.25C9.51784 5.25 9.93758 4.83026 9.93758 4.3125C9.93758 3.79474 9.51784 3.375 9.00008 3.375C8.48231 3.375 8.06258 3.79474 8.06258 4.3125C8.06258 4.83026 8.48231 5.25 9.00008 5.25ZM12.7501 9H15.2501V14H12.7501V9ZM10.2501 14V9H7.75008V14H10.2501ZM5.25008 14V9H2.75008V14H5.25008ZM1.34124 15.25C1.18798 15.2994 1.05699 15.407 0.979678 15.5539L0.321965 16.8039C0.220034 16.9976 0.22689 17.2306 0.34004 17.418C0.453203 17.6054 0.656165 17.72 0.875078 17.72H17.1285C17.3473 17.72 17.5502 17.6055 17.6633 17.4182C17.7766 17.231 17.7836 16.9982 17.682 16.8045L17.0258 15.5545C16.9486 15.4072 16.8175 15.2994 16.6641 15.25H1.34124Z"
        fill="#16458B"
      />
    </SvgIcon>
  );
};
