import { InputAdornment, TextField } from "@mui/material";
import { CloseIcon } from "components/common/icons";
import { Spinner } from "components/common/Spinner/Spinner";
import { SearchIcon } from "icons/svg/SearchIcon";

interface ISuggestionsSearchFieldProps {
  searchTerm: string;
  onSearchTermChange: (value: string) => void;
  placeholder: string;
  showLoader?: boolean;
}

export const SuggestionsSearchField = ({
  searchTerm,
  onSearchTermChange,
  placeholder,
  showLoader,
}: ISuggestionsSearchFieldProps) => {
  return (
    <TextField
      value={searchTerm}
      onChange={(event) => onSearchTermChange(event.target.value)}
      placeholder={placeholder}
      autoFocus
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            {showLoader ? <Spinner /> : <SearchIcon sx={{ fontSize: "18px" }} />}
          </InputAdornment>
        ),
        ...(searchTerm?.length > 0 && {
          endAdornment: (
            <InputAdornment position="end">
              <CloseIcon
                onClick={() => {
                  onSearchTermChange("");
                }}
                sx={{ cursor: "pointer", fontSize: "18px" }}
              />
            </InputAdornment>
          ),
        }),
      }}
      fullWidth
    />
  );
};
