import { VBillActiveFilters } from "components/pages/common/VBill/VBillActiveFilters/VBillActiveFilters";
import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { useStore } from "storeContainer";
import { usePayLedgerFilters } from "../hooks";

export const PayActiveFilters = observer(function PayActiveFilters() {
  const [paymentLedgerFilters, setPayLedgerFilters] = usePayLedgerFilters();
  const vBillPayLedgerStore = useStore("VBillPayLedgerStore");

  const additionalMappingLabels = useMemo(
    () =>
      (vBillPayLedgerStore.companiesSettings.data?.invoice ?? []).map(({ key, label }) => ({
        key,
        label: label ?? "",
      })),
    [vBillPayLedgerStore.companiesSettings.data?.invoice],
  );

  return (
    <VBillActiveFilters
      filters={{
        search: paymentLedgerFilters.filters?.search,
        amount_from: paymentLedgerFilters.filters?.amount_from,
        amount_to: paymentLedgerFilters.filters?.amount_to,
        companyIds: paymentLedgerFilters.filters?.companyIds,
        date: paymentLedgerFilters.filters?.date,
        date_to: paymentLedgerFilters.filters?.date_to,
        date_due_from: paymentLedgerFilters.filters?.date_due_from,
        date_due_to: paymentLedgerFilters.filters?.date_due_to,
        mappings: paymentLedgerFilters.filters?.mappings,
        integrationStatus: paymentLedgerFilters.filters?.integrationStatus,
        relativeDateDueFrom: paymentLedgerFilters.filters?.relativeDateDueFrom,
        relativeDateDueTo: paymentLedgerFilters.filters?.relativeDateDueTo,
        relativeReferenceDate: paymentLedgerFilters.filters?.relativeReferenceDate,
      }}
      mappingLabels={additionalMappingLabels}
      onFiltersChange={(values) =>
        setPayLedgerFilters((prev) => ({
          filters: Object.keys(values).length
            ? {
                ...prev.filters,
                search: values.search,
                amount_from: values.amount_from,
                amount_to: values.amount_to,
                companyIds: values.companyIds,
                date: values.date,
                date_to: values.date_to,
                date_due_from: values.date_due_from,
                date_due_to: values.date_due_to,
                mappings: values.mappings,
                integrationStatus: values.integrationStatus,
              }
            : undefined,
          pagination: { page: 1, per_page: prev.pagination.per_page },
        }))
      }
      sx={{ margin: "0 0 10px" }}
    />
  );
});
