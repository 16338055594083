import { Badge, Box, Button, Modal, Paper } from "@mui/material";
import { DelegationGroupScopeEnum, DelegationGroupTypeEnum } from "generated/sdk";
import { IVBillInvoiceSchema } from "generated/sdk.vbill";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useSetState } from "react-use";
import { useStore } from "storeContainer";
import { Spinner } from "../Spinner/Spinner";
import { ChooseDelegationGroup } from "./ChooseDelegationGroup";
import { DelegationTreeView } from "./DelegationTreeView";
import { EditDelegationGroup } from "./EditDelegationGroup";
import { getApprovalFlags } from "./getApprovalFlags";
import { DelegationGroup, IApprovalEntityParams, PartialDelegationGroup } from "./types";

interface IAssignButtonProps {
  isActive: boolean;
  scope: DelegationGroupScopeEnum;
  entityParams: IApprovalEntityParams;
  approvalStatus: IVBillInvoiceSchema["approvalStatus"];
}

enum ApprovalModalMode {
  "LOADING" = "LOADING",
  "CHOOSE" = "CHOOSE",
  "EDIT" = "EDIT",
  "NEW" = "NEW",
}

type ApprovalModalParams =
  | {
      type: ApprovalModalMode.NEW | ApprovalModalMode.EDIT;
      data: PartialDelegationGroup;
    }
  | {
      type: ApprovalModalMode.LOADING | ApprovalModalMode.CHOOSE;
      data: undefined;
    };

export const AssignButton: React.FC<IAssignButtonProps> = observer(
  ({ isActive, scope, entityParams, approvalStatus }) => {
    const { selectedOrganizationUserId } = useStore("SessionStore");

    const [assignModalOpen, setAssignModalOpen] = useState<boolean>(false);
    const approvalStore = useStore("ApprovalStore");

    const delegationGroups = approvalStore.delegationGroups._dataObsevable;

    const loadDelegationGroups = () =>
      approvalStore.loadDelegationGroups(
        {
          scope,
          organization_id: entityParams.organizationId,
          company_id: entityParams.companyId,
          vendor_id: entityParams.vendorId,
          bill_id: entityParams.billId,
          check_id: entityParams.checkId,
          delegator_user_id: selectedOrganizationUserId!,
          match_any: true,
        },
        true,
      );

    useEffect(() => {
      loadDelegationGroups();
    }, [scope, entityParams]);

    const [modalParams, setModalParams] = useSetState<ApprovalModalParams>({
      type: ApprovalModalMode.LOADING,
      data: undefined,
    });

    const closeModal = () => {
      setAssignModalOpen(false);
      setModalParams({
        type: ApprovalModalMode.LOADING,
        data: undefined,
      });
    };

    const getNewGroupParams = () => {
      return {
        scope,
        organization_id: entityParams.organizationId,
        company_id: entityParams.companyId,
        vendor_id: entityParams.vendorId,
        bill_id: entityParams.billId,
        check_id: entityParams.checkId,
      };
    };

    useEffect(() => {
      if (approvalStore.delegationGroups.isLoaded) {
        if (modalParams.type === ApprovalModalMode.LOADING) {
          const groupCount = delegationGroups?.delegation_groups.length;
          let newModalParams: ApprovalModalParams;

          if (groupCount === 0) {
            newModalParams = {
              type: ApprovalModalMode.NEW,
              data: getNewGroupParams(),
            };
          } else if (groupCount === 1) {
            newModalParams = {
              type: ApprovalModalMode.EDIT,
              data: delegationGroups?.delegation_groups[0]!,
            };
          } else {
            /*
             * this should be unreachable, but we may want it later
             */
            newModalParams = {
              type: ApprovalModalMode.CHOOSE,
              data: undefined,
            };
          }

          setModalParams(newModalParams);
        } else if (delegationGroups?.delegation_groups.length === 0) {
          // we've deleted everything
          setModalParams({ type: ApprovalModalMode.NEW, data: getNewGroupParams() });
        }
      }
    }, [delegationGroups, assignModalOpen]);

    if (!approvalStatus || !selectedOrganizationUserId) {
      return null;
    }

    if (approvalStore.delegationGroups.isLoading || !delegationGroups) {
      return <Spinner />;
    }

    const { canUserApprove } = getApprovalFlags(approvalStatus, selectedOrganizationUserId);

    return (
      <>
        <Badge badgeContent={delegationGroups.delegation_groups.length} color="primary">
          <Button
            variant="outlined"
            onClick={() => setAssignModalOpen(true)}
            disabled={!isActive}
            sx={{ height: "32px" }}
          >
            {delegationGroups.delegation_groups.length ? "Assigned" : "Assign"}
          </Button>
        </Badge>

        <Modal
          open={assignModalOpen}
          onClose={closeModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
        >
          <Paper sx={{ display: "flex" }}>
            <Box className="tree-wrapper" sx={{ width: "400px", height: "500px" }}>
              <DelegationTreeView
                organizationId={entityParams.organizationId}
                billId={entityParams.billId}
                checkId={entityParams.checkId}
                approvalStatus={approvalStatus}
              />
            </Box>

            <Box sx={{ borderLeft: "1px solid #f0f0f0" }}></Box>

            {modalParams.type === ApprovalModalMode.CHOOSE ? (
              <ChooseDelegationGroup
                delegationGroups={delegationGroups.delegation_groups}
                onCreateNew={() =>
                  setModalParams({
                    type: ApprovalModalMode.NEW,
                    data: getNewGroupParams(),
                  })
                }
                onEdit={(delegationGroup: DelegationGroup) =>
                  setModalParams({
                    type: ApprovalModalMode.EDIT,
                    data: delegationGroup,
                  })
                }
              />
            ) : modalParams.type === ApprovalModalMode.NEW ? (
              <EditDelegationGroup
                // goBack={
                //   delegationGroups.delegation_groups.length
                //     ? () => setModalParams({ type: ApprovalModalMode.CHOOSE, data: undefined })
                //     : undefined
                // }
                entityParams={entityParams}
                typesAllowed={
                  canUserApprove
                    ? [DelegationGroupTypeEnum.ReviewRetainApproval, DelegationGroupTypeEnum.Approval]
                    : [DelegationGroupTypeEnum.Review]
                }
                initialData={modalParams.data}
                closeModal={closeModal}
              />
            ) : modalParams.type === ApprovalModalMode.EDIT ? (
              <EditDelegationGroup
                // goBack={
                //   delegationGroups.delegation_groups.length
                //     ? () => setModalParams({ type: ApprovalModalMode.CHOOSE, data: undefined })
                //     : undefined
                // }
                entityParams={entityParams}
                typesAllowed={
                  canUserApprove
                    ? [DelegationGroupTypeEnum.ReviewRetainApproval, DelegationGroupTypeEnum.Approval]
                    : [DelegationGroupTypeEnum.Review]
                }
                initialData={modalParams.data}
                closeModal={closeModal}
              />
            ) : null}
          </Paper>
        </Modal>
      </>
    );
  },
);
