import { Box, Typography } from "@mui/material";
import { format } from "date-fns";
import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { useStore } from "storeContainer";
import { COLORS } from "themes/default";
import { vBillChatPanels, vBillSidebarPanels } from "../SidebarTabs/SidebarTabs";
import { TabPanel } from "../SidebarTabs/TabPanel/TabPanel";

interface IEmailInboxProps {
  activePannel: vBillSidebarPanels | vBillChatPanels;
}

export const EmailInbox = observer(({ activePannel }: IEmailInboxProps) => {
  const vBillStore = useStore("VBillStore");
  const { invoice } = vBillStore;
  const invoiceData = useMemo(() => invoice?.data, [invoice?.data]);
  const emailDate = useMemo(
    () => (invoiceData?.emailDate ? format(new Date(invoiceData?.emailDate), "MM/dd/yy hh:mmaaa") : "-"),
    [invoiceData?.emailDate],
  );

  return (
    <TabPanel activePannel={activePannel} panelName={vBillSidebarPanels.EMAIL}>
      <Box sx={{ padding: "20px 10px" }}>
        <Typography sx={{ marginBottom: "5px", wordWrap: "break-word" }}>
          <Box component="span" sx={{ fontSize: "16px", fontWeight: "500", color: COLORS.logoBlue }}>
            From:
          </Box>{" "}
          {invoiceData?.initialSenderName?.length ? invoiceData.initialSenderName : "-"} {"<"}
          {invoiceData?.initialSenderEmail?.length ? invoiceData.initialSenderEmail : "-"}
          {">"}
        </Typography>

        <Typography sx={{ marginBottom: "5px" }}>
          <Box component="span" sx={{ fontSize: "16px", fontWeight: "500", color: COLORS.logoBlue }}>
            Date:
          </Box>{" "}
          {invoiceData?.emailDate?.length ? emailDate : "-"}
        </Typography>
        <Typography sx={{ marginBottom: "15px" }}>
          <Box component="span" sx={{ fontSize: "16px", fontWeight: "500", color: COLORS.logoBlue }}>
            Subject:{" "}
          </Box>
          {invoiceData?.emailSubject?.length ? invoiceData.emailSubject : "-"}
        </Typography>

        <Typography sx={{ fontSize: "16px", fontWeight: "500", color: COLORS.logoBlue, marginBottom: "5px" }}>
          Content:{" "}
        </Typography>
        <Box sx={{ backgroundColor: COLORS.white, overflowX: "auto", padding: "10px" }}>
          <pre>{invoiceData?.emailBodyText?.length ? invoiceData.emailBodyText : "-"}</pre>
        </Box>
      </Box>
    </TabPanel>
  );
});
