import { Box, IconButton, Popover, Tooltip } from "@mui/material";
import { chatStaticEmojiList } from "common/static/chatStaticEmojiList";
import { RecommendIcon } from "components/common/icons";
import { IChatChatIoMessageOutputSchema, IChatContentBlockMessageRelatedType } from "generated/sdk.chat";
import { groupBy } from "lodash";
import { observer } from "mobx-react";
import { useChatChannelStore } from "modules/Chat/hooks/hooks";
import { CHAT_MSG_REACTION_BLOCK_PREFIX } from "modules/Chat/utils/utils";
import { FunctionComponent, useState } from "react";
import { COLORS } from "themes/default";

interface IMessageReactionsListProps {
  message: IChatChatIoMessageOutputSchema;
}

export const MessageReactionsList: FunctionComponent<IMessageReactionsListProps> = observer(
  ({
    message: {
      content: { related },
    },
  }) => {
    const [popoverAnchorEl, setPopoverAnchorEl] = useState<HTMLButtonElement | null>(null);
    const chatChannelStore = useChatChannelStore();

    const messageReactions = related?.filter(
      ({ related, key }) =>
        related.type === IChatContentBlockMessageRelatedType.Reaction && key.includes(CHAT_MSG_REACTION_BLOCK_PREFIX),
    );

    if (!messageReactions?.length) {
      return null;
    }

    const popoverOpen = Boolean(popoverAnchorEl);
    const popoverId = popoverOpen ? "message-Reactions-popover" : undefined;
    const groupMessageReactions = groupBy(messageReactions, "related.label");

    return (
      <Box>
        <Tooltip title="Reactions">
          <IconButton aria-describedby={popoverId} onClick={(event) => setPopoverAnchorEl(event.currentTarget)}>
            <RecommendIcon color="primary" />
          </IconButton>
        </Tooltip>
        <Popover
          id={popoverId}
          open={popoverOpen}
          anchorEl={popoverAnchorEl}
          onClose={() => setPopoverAnchorEl(null)}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Box sx={{ padding: "15px" }}>
            {Object.keys(groupMessageReactions).map((groupMessageKey) => (
              <Box
                key={groupMessageKey}
                sx={{
                  borderBottom: `1px solid ${COLORS.lightestGrey}`,
                  "&:last-child": { borderBottom: "none" },
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  padding: "10px 0",
                }}
              >
                <Box component="span" sx={{ fontSize: "10px" }}>
                  ({groupMessageReactions[groupMessageKey].length})
                </Box>

                <span
                  role="img"
                  dangerouslySetInnerHTML={{
                    __html: chatStaticEmojiList.find(({ name }) => name === groupMessageKey)?.hex ?? "",
                  }}
                />

                {groupMessageReactions[groupMessageKey].map(({ related }) => {
                  if (related.key === chatChannelStore?.currentSessionMember?.name) {
                    return (
                      <Box key={related.key} component="span" sx={{ textDecoration: "underline" }}>
                        You: {related.key}
                      </Box>
                    );
                  } else {
                    return <span key={related.key}>{related.key}</span>;
                  }
                })}
              </Box>
            ))}
          </Box>
        </Popover>
      </Box>
    );
  },
);
