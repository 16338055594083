import { useHistory } from "react-router";

export function useShtarHistory() {
  const history = useHistory();

  return {
    ...history,
    ...((window as any).isLegacyPage && {
      push: (path: string) => {
        window.location.href = path;
      },
    }),
  };
}
