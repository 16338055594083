import { Box, Table, TableContainer } from "@mui/material";
import { getSODAsUTCIsoString } from "common/helpers/utils";
import { IVBillBillsGroupingType } from "generated/sdk.vbill";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useParams } from "react-router";
import { useDeepCompareEffect } from "react-use";
import { useStore } from "storeContainer";
import { transformDatesAsSODUTCIsoString } from "../common/VBill/utils";
import { VBillRouteGuardRedirect } from "../common/VBill/VBillRouteGuardRedirect/VBillRouteGuardRedirect";
import { usePayLedgerFilters, useVBillPayLedgerChildRoute } from "./hooks";
import { PayActiveFilters } from "./PayActiveFilters/PayActiveFilters";
import { PayFooter } from "./PayFooter/PayFooter";
import { PayHeader } from "./PayHeader/PayHeader";
import { PayLedgerList } from "./PayLedgerList/PayLedgerList";
import { PayListFilters } from "./PayListFilters/PayListFilters";
import { PayQuickFilters } from "./PayQuickFilters/PayQuickFilters";
import { PayVBills } from "./PayVBills/PayVBills";
import { getVBillPayLedgerIntStatusByChildRoute } from "./utils";

export const VBillPayLedger = observer(function VBillPayLedger() {
  const { organizationId } = useParams<{ organizationId: string }>();
  const vBillPayLedgerStore = useStore("VBillPayLedgerStore");
  const userSettingsStore = useStore("UserSettingsStore");
  const [paymentLedgerFilters] = usePayLedgerFilters();
  const paymentsLedgerChildRoute = useVBillPayLedgerChildRoute();

  useDeepCompareEffect(() => {
    const intStatusByChildRoute = getVBillPayLedgerIntStatusByChildRoute(paymentsLedgerChildRoute);

    vBillPayLedgerStore.loadBillsLedgerList(
      {
        filters: {
          organizationId,
          companyIds: userSettingsStore.companiesSelectedIds.length
            ? userSettingsStore.companiesSelectedIds
            : undefined,
          integrationStatus: intStatusByChildRoute,
          date_due_to: paymentsLedgerChildRoute.isOverDueChildRoute ? getSODAsUTCIsoString(new Date()) : undefined,
          free_amount_from:
            paymentsLedgerChildRoute.isOverDueChildRoute || paymentsLedgerChildRoute.isDueToPayChildRoute
              ? "0.01"
              : undefined,
          ...paymentLedgerFilters.filters,
          ...transformDatesAsSODUTCIsoString(paymentLedgerFilters.filters),
        },
        pagination: paymentLedgerFilters.pagination,
      },
      true,
    );
    vBillPayLedgerStore.updateSelectedBillIdsToPay([]);
  }, [
    vBillPayLedgerStore,
    organizationId,
    userSettingsStore.companiesSelectedIds,
    paymentLedgerFilters.filters,
    paymentLedgerFilters.pagination,
    paymentsLedgerChildRoute,
  ]);

  useEffect(() => {
    vBillPayLedgerStore.loadCompaniesSettings({
      organizationId,
      companyIds: userSettingsStore.companiesSelectedIds.length ? userSettingsStore.companiesSelectedIds : undefined,
    });
  }, [vBillPayLedgerStore, organizationId, userSettingsStore.companiesSelectedIds]);

  useEffect(() => {
    if (isNil(userSettingsStore.frontendSettingsObservable.data)) {
      return;
    }

    vBillPayLedgerStore.loadBillsLedgerGeneralStats({
      filters: {
        organizationId,
        companyIds: userSettingsStore.companiesSelectedIds.length ? userSettingsStore.companiesSelectedIds : undefined,
      },
      grouping: { type: IVBillBillsGroupingType.IntegrationStatus },
    });

    vBillPayLedgerStore.loadBillsLedgerOverDueStats({
      filters: {
        organizationId,
        companyIds: userSettingsStore.companiesSelectedIds.length ? userSettingsStore.companiesSelectedIds : undefined,
        date_due_to: getSODAsUTCIsoString(new Date()),
        free_amount_from: "0.01",
      },
    });

    vBillPayLedgerStore.loadBillsLedgerDueToPayStats({
      filters: {
        organizationId,
        companyIds: userSettingsStore.companiesSelectedIds.length ? userSettingsStore.companiesSelectedIds : undefined,
        free_amount_from: "0.01",
      },
    });
  }, [
    vBillPayLedgerStore,
    organizationId,
    userSettingsStore.companiesSelectedIds,
    userSettingsStore.frontendSettingsObservable.data,
  ]);

  useEffect(() => {
    return () => {
      vBillPayLedgerStore.updateSelectedBillIdsToPay([]);
      vBillPayLedgerStore.billsLedgerList.flush();
      vBillPayLedgerStore.billsLedgerGeneralStats.flush();
      vBillPayLedgerStore.billsLedgerOverDueStats.flush();
      vBillPayLedgerStore.billsLedgerDueToPayStats.flush();
      vBillPayLedgerStore.companiesSettings.flush();
    };
  }, [vBillPayLedgerStore]);

  return (
    <Box>
      <VBillRouteGuardRedirect route="PAY_LEDGER" />

      <PayQuickFilters />

      <PayListFilters />

      <PayActiveFilters />

      <PayVBills />

      <TableContainer sx={{ marginBottom: "100px" }}>
        <Table>
          <PayHeader />

          <PayLedgerList />
        </Table>
      </TableContainer>

      <PayFooter />
    </Box>
  );
});
