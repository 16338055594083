import AddCircleIcon from "@mui/icons-material/AddCircle";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Box,
  Button,
  Checkbox,
  Drawer,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import { useCheckFilters } from "common/hooks/useCheckFilters";
import { CheckBatchStateEnum, CheckDetailsScopeEnum, CheckFiltersType } from "generated/sdk";
import { debounce, isEqual } from "lodash";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useRef, useState } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { useParams } from "react-router";
import { useDeepCompareEffect } from "react-use";
import { useStore } from "storeContainer";
import { highlightMatchingText } from "../VChecks/VChecksTable/VChecksTable";
import styles from "./BudgetManagement.module.scss";
import { CheckBatchItemDetails } from "./CheckBatchItemDetails";
import { TablePaginationActions } from "./CheckTable";
import { useBatchDetailsContext } from "./useBatchDetailsContext";
import { DEFAULT_PAGINATION_PER_PAGE_FOR_AVAILABLE_CHECKS, formatCurrency } from "./utils";

export const BudgetManagementDetailsAvailableChecksTable = observer(
  function BudgetManagementDetailsAvailableChecksTable({ isDragging }: { isDragging: boolean }) {
    const BudgetManagementStore = useStore("BudgetManagementStore");
    const {
      fetchCheckBatch,
      fetchAvailableChecksForBatchQuery,
      createCheckBatchItems,
      checkBatchData,
      availableChecksForBatchQuery,
      fetchAvailableBankAccountsForBatchQuery,
      fetchRawAvailableChecksForBatchQuery,
      signedBatchData,
    } = BudgetManagementStore;

    const {
      props: { isLoading, isBatchClosed },
    } = useBatchDetailsContext();

    const { batchId } = useParams<{ batchId: string }>();
    const inputRef = useRef<HTMLInputElement>(null);
    const [batchQuery, setBatchQuery] = useState(checkBatchData.data?.GetCheckBatchQuery);
    const [checksQuery, setChecksQuery] = useState(availableChecksForBatchQuery?.data?.GetAvailableChecksForBatchQuery);
    const [selectAll, setSelectAll] = useState(false);
    const [indeterminate, setIndeterminate] = useState(false);
    const [selectedChecks, setSelectedChecks] = useState<Record<string, boolean>>({});
    const [pageAvailableChecks, setPageAvailableChecks] = useState(0);
    const [rowsPerPageAvailableChecks, setRowsPerPageAvailableChecks] = useState(
      DEFAULT_PAGINATION_PER_PAGE_FOR_AVAILABLE_CHECKS,
    );
    const [selectTotal, setSelectTotal] = useState(false);
    const [openCheckDetails, setOpenCheckDetails] = useState(false);
    const { filters, setFilters } = useCheckFilters<CheckFiltersType>();
    const [orderBy, setOrderBy] = useState("");
    const [order, setOrder] = useState<"asc" | "desc">("asc");

    const fetchData = async (filters: CheckFiltersType) => {
      await fetchAvailableChecksForBatchQuery(
        batchId,
        { per_page: rowsPerPageAvailableChecks, page: pageAvailableChecks + 1 },
        filters,
        true,
      );

      setChecksQuery(availableChecksForBatchQuery.data?.GetAvailableChecksForBatchQuery);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedFetchData = useCallback(
      debounce((newValue: CheckFiltersType) => fetchData(newValue), 300),
      [],
    );

    const handleSelectAllClick = () => {
      setSelectTotal(false);
      if (selectAll) {
        setSelectedChecks({});
      } else {
        const newSelectedChecks: Record<string, any> = {};
        checksQuery?.rows?.forEach((check) => (newSelectedChecks[check?.id as string] = true));
        setSelectedChecks(newSelectedChecks);
      }
    };
    const handleCheckClick = (checkId: string) => {
      setSelectTotal(false);
      setSelectedChecks((prevSelectedChecks) => ({ ...prevSelectedChecks, [checkId]: !selectedChecks[checkId] }));
    };
    const handleChangePageAvailableCheck = (event: unknown, newPage: number) => {
      setPageAvailableChecks(newPage);
      fetchAvailableChecksForBatchQuery(
        batchId,
        { page: newPage + 1, per_page: rowsPerPageAvailableChecks },
        filters,
        true,
      );
    };
    const handleChangeRowsPerPageAvailableChecks = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPageAvailableChecks(parseInt(event.target.value, 10));
      setPageAvailableChecks(0);
      fetchAvailableChecksForBatchQuery(
        batchId,
        {
          page: pageAvailableChecks + 1,
          per_page: parseInt(event.target.value, 10),
        },
        undefined,
        true,
      );
    };
    const handleAddToBatch = async () => {
      if (selectTotal) {
        await createCheckBatchItems(batchId, undefined, { search: filters.search || "" }, true);
      } else {
        await createCheckBatchItems(batchId, Object.keys(selectedChecks), undefined, true);
      }
      await fetchCheckBatch(
        batchId,
        filters,
        { page: 1, per_page: DEFAULT_PAGINATION_PER_PAGE_FOR_AVAILABLE_CHECKS },
        true,
      );
      await fetchAvailableChecksForBatchQuery(
        batchId,
        { page: 1, per_page: DEFAULT_PAGINATION_PER_PAGE_FOR_AVAILABLE_CHECKS },
        filters,
        true,
      );
      setSelectAll(false);
      setSelectedChecks({});
    };

    const handleSorting = (name: string) => {
      const isAsc = orderBy === name && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(name);
      const sorting = `${name}_sort`;
      const prevFilters = {
        ...filters,
        date_sort: undefined,
        number_sort: undefined,
        amount_sort: undefined,
      };
      setFilters({ ...prevFilters, [sorting]: isAsc ? "desc" : "asc" });
    };

    useEffect(() => {
      if (batchQuery?.state === CheckBatchStateEnum.Open) {
        fetchAvailableChecksForBatchQuery(
          batchId,
          { page: 1, per_page: DEFAULT_PAGINATION_PER_PAGE_FOR_AVAILABLE_CHECKS },
          filters,
          true,
        );
      }
    }, [batchId, batchQuery?.state, fetchAvailableChecksForBatchQuery, filters]);

    useEffect(() => {
      const allSelected =
        Object.values(selectedChecks).every((checked) => checked) &&
        checksQuery?.rows?.length === Object.values(selectedChecks)?.length &&
        Object.values(selectedChecks)?.length > 0;
      const someSelected = Object.values(selectedChecks).some((checked) => checked);

      setSelectAll(allSelected);
      setIndeterminate(!allSelected && someSelected);
    }, [checksQuery, selectedChecks]);
    useEffect(() => {
      if (checkBatchData.data?.GetCheckBatchQuery) {
        setBatchQuery(checkBatchData.data?.GetCheckBatchQuery);
      }
    }, [checkBatchData.data?.GetCheckBatchQuery, checkBatchData.data?.GetCheckBatchQuery?.state]);
    useEffect(() => {
      if (availableChecksForBatchQuery?.data?.GetAvailableChecksForBatchQuery && !isBatchClosed) {
        setChecksQuery(availableChecksForBatchQuery?.data?.GetAvailableChecksForBatchQuery);
      }
    }, [availableChecksForBatchQuery?.data?.GetAvailableChecksForBatchQuery, isBatchClosed]);
    useDeepCompareEffect(() => {
      if (!isBatchClosed) {
        debouncedFetchData(filters);
      }
      return () => {
        debouncedFetchData.cancel();
      };
    }, [debouncedFetchData, filters, isBatchClosed]);

    useEffect(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, []);

    useEffect(() => {
      fetchAvailableBankAccountsForBatchQuery(batchId, undefined, "");
    }, [batchId, fetchAvailableBankAccountsForBatchQuery]);

    useEffect(() => {
      if (isBatchClosed) {
        return;
      }
      const intervalId = setInterval(async () => {
        const newBatchQuery = await fetchRawAvailableChecksForBatchQuery(
          batchId,
          {
            page: pageAvailableChecks,
            per_page: rowsPerPageAvailableChecks,
          },
          filters,
        );
        if (!isEqual(newBatchQuery, checksQuery)) {
          setChecksQuery(newBatchQuery!);
        }
      }, 5000);
      return () => {
        clearInterval(intervalId);
      };
    }, [
      batchId,
      checksQuery,
      fetchRawAvailableChecksForBatchQuery,
      filters,
      isBatchClosed,
      pageAvailableChecks,
      rowsPerPageAvailableChecks,
    ]);

    const selectedChecksLength = Object.values(selectedChecks)?.filter((item) => item);
    return (
      <>
        <Box display="flex" flexDirection="column" marginTop="20px" gap="20px">
          <TableContainer sx={{ maxHeight: "400px", height: "calc(100% - 52px)", overflow: "auto" }}>
            <Droppable droppableId="droppableTable1">
              {(provided, snapshot) => (
                <Table
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  sx={{
                    maxHeight: "400px",
                    ...(isDragging && {
                      border: "10px dashed #E8F4FD",
                    }),
                  }}
                  stickyHeader
                >
                  <TableHead>
                    <TableRow
                      sx={{
                        "&:hover": {
                          background: "white",
                        },
                        ".MuiTableCell-stickyHeader": {
                          backgroundColor: "white",
                        },
                      }}
                    >
                      <TableCell width="30px" variant="head">
                        <Checkbox
                          checked={selectAll}
                          indeterminate={indeterminate}
                          onChange={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            handleSelectAllClick();
                          }}
                        />
                      </TableCell>
                      <TableCell width="100px" variant="head">
                        <TableSortLabel
                          active={orderBy === "number"}
                          direction={orderBy === "number" ? order : "asc"}
                          IconComponent={KeyboardArrowDownIcon}
                          onClick={() => handleSorting("number")}
                        >
                          Check #
                        </TableSortLabel>
                      </TableCell>
                      <TableCell width="150px" variant="head">
                        <TableSortLabel
                          active={orderBy === "date"}
                          direction={orderBy === "date" ? order : "asc"}
                          IconComponent={KeyboardArrowDownIcon}
                          onClick={() => handleSorting("date")}
                        >
                          Date
                        </TableSortLabel>
                      </TableCell>
                      <TableCell width="150px" variant="head">
                        From
                      </TableCell>
                      <TableCell width="150px" variant="head">
                        To
                      </TableCell>
                      <TableCell width="150px" variant="head">
                        <TableSortLabel
                          active={orderBy === "amount"}
                          direction={orderBy === "amount" ? order : "asc"}
                          IconComponent={KeyboardArrowDownIcon}
                          onClick={() => handleSorting("amount")}
                        >
                          Amount
                        </TableSortLabel>
                      </TableCell>
                      <TableCell width="150px" variant="head">
                        Batch Status
                      </TableCell>
                    </TableRow>
                    {selectedChecksLength?.length > 0 && (
                      <TableRow>
                        <TableCell colSpan={7}>
                          <Box display="flex" alignItems="center" gap="15px" justifyContent="center">
                            <Typography variant="body2" textTransform="capitalize">
                              You have selected {selectedChecksLength?.length} check(s)
                            </Typography>
                            <Button
                              variant="contained"
                              onClick={handleAddToBatch}
                              disabled={isLoading || isBatchClosed || !selectedChecksLength?.length}
                              endIcon={<AddCircleIcon />}
                              size="small"
                            >
                              Add
                            </Button>
                          </Box>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableHead>
                  <TableBody>
                    {isLoading ? (
                      <TableRow>
                        <TableCell colSpan={7}>
                          <Skeleton variant="rectangular" height="300px" />
                        </TableCell>
                      </TableRow>
                    ) : (checksQuery?.rows?.length as number) > 0 ? (
                      checksQuery?.rows?.map((check, index) => {
                        const checkDate = new Date(check?.date as string).toLocaleDateString("en-US", {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                        });

                        const newTotal = (batchQuery?.items_amount as number) + (check?.amount as number);
                        const willBeOverBudget = (batchQuery?.budget as number) < newTotal;
                        return (
                          <Draggable
                            key={check?.id}
                            draggableId={check?.id!}
                            index={index}
                            isDragDisabled={!!check?.may_not_add_to_batch?.message}
                          >
                            {(provided, snapshot) => (
                              <TableRow
                                key={check?.id}
                                ref={provided.innerRef}
                                style={provided.draggableProps.style}
                                data-rowid={check?.id}
                                onClick={async () => {
                                  await BudgetManagementStore.fetchCheckDetails(
                                    batchId,
                                    check?.id as string,
                                    CheckDetailsScopeEnum.AvailableChecks,
                                    true,
                                  );
                                  setOpenCheckDetails(true);
                                }}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                sx={{
                                  ...(snapshot.isDragging && {
                                    ":before": {
                                      content: `"Adding this check will add ${formatCurrency(
                                        check?.amount as number,
                                      )} to the Total making it ${formatCurrency(newTotal)}. The batch will be ${
                                        !willBeOverBudget ? "underbudget" : "overbudget"
                                      } by ${formatCurrency(
                                        willBeOverBudget
                                          ? newTotal - (batchQuery?.budget as number)
                                          : (batchQuery?.budget as number) - newTotal,
                                      )} out of ${formatCurrency(batchQuery?.budget as number)}"`,
                                      width: "100px",
                                      heigth: "50px",
                                      backgroundColor: "#E6F9E8",
                                      padding: "15px",
                                    },
                                  }),
                                  ...(!!check?.may_not_add_to_batch?.message && {
                                    pointerEvents: "none",
                                    backgroundColor: "#D3D3D3",
                                  }),
                                }}
                              >
                                <TableCell>
                                  <Checkbox
                                    checked={!!selectedChecks[check?.id as string]}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      handleCheckClick(check?.id as string);
                                    }}
                                    disabled={!!check?.may_not_add_to_batch?.check_batch_id}
                                  />
                                </TableCell>
                                <TableCell>
                                  <Box className={styles["vChecksTable-btn-border"]}>
                                    <Box className={styles.tableRow}>
                                      <Box className={styles.sender}>
                                        <ArrowUpwardIcon className={styles.arrow} />
                                        {highlightMatchingText(check?.number!, filters?.search?.search_input!)}
                                      </Box>
                                    </Box>
                                  </Box>
                                </TableCell>
                                <TableCell>
                                  <Typography fontWeight="500">{checkDate}</Typography>
                                </TableCell>
                                <TableCell>
                                  <Box display="flex" flexDirection="column">
                                    <Typography variant="body1" className={styles.longTextEllipsis}>
                                      {highlightMatchingText(check?.sender_title!, filters?.search?.search_input!)}
                                    </Typography>
                                    <Typography className={styles.bank}>
                                      {highlightMatchingText(check?.sender_subtitle!, filters?.search?.search_input!)}
                                    </Typography>
                                  </Box>
                                </TableCell>
                                <TableCell>
                                  <Box display="flex" flexDirection="column">
                                    <Typography variant="body1" className={styles.longTextEllipsis}>
                                      {highlightMatchingText(check?.recipient_title!, filters?.search?.search_input!)}
                                    </Typography>
                                    <Typography className={styles.bank}>
                                      {highlightMatchingText(
                                        check?.recipient_subtitle!,
                                        filters?.search?.search_input!,
                                      )}
                                    </Typography>
                                  </Box>
                                </TableCell>
                                <TableCell>
                                  <Typography textTransform="uppercase" fontWeight="500">
                                    {formatCurrency(check?.amount as number)}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography fontWeight="500" textTransform="capitalize">
                                    {check?.may_not_add_to_batch?.message ?? "Can be added to batch"}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            )}
                          </Draggable>
                        );
                      })
                    ) : (
                      <TableRow>
                        <TableCell colSpan={7}>
                          <Typography>No checks available for this batch</Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                  <TableFooter>{provided.placeholder}</TableFooter>
                </Table>
              )}
            </Droppable>
            <Box position="sticky" bottom="0" sx={{ background: "white" }}>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100]}
                count={checksQuery?.pagination?.total ?? 0}
                rowsPerPage={rowsPerPageAvailableChecks}
                page={pageAvailableChecks}
                onPageChange={handleChangePageAvailableCheck}
                onRowsPerPageChange={handleChangeRowsPerPageAvailableChecks}
                component="div"
                ActionsComponent={(actionProps) => (
                  <TablePaginationActions {...actionProps} description="Available Checks" />
                )}
              />
            </Box>
          </TableContainer>
          <Drawer open={openCheckDetails} anchor="right" onClose={() => setOpenCheckDetails(false)}>
            <CheckBatchItemDetails />
          </Drawer>
        </Box>
      </>
    );
  },
);
