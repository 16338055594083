import { Box, Button, Typography } from "@mui/material";
import { ArrowBackIosNewIcon } from "components/common/icons";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import { useHistory, useParams } from "react-router";
import { useStore } from "storeContainer";

export const BatchBackBtn = observer(function BatchSummary() {
  const vBillBatchDetailsStore = useStore("VBillBatchDetailsStore");
  const { organizationId } = useParams<{ organizationId: string }>();
  const history = useHistory();

  const { batchDetails } = vBillBatchDetailsStore;
  const batchDetailsData = batchDetails.data;

  const handleBackBtnClick = () => {
    if (!batchDetailsData) {
      return;
    }

    history.push(`/org/${organizationId}/vbillbatch/${batchDetailsData.parentBatchId}`);
  };

  if (isNil(batchDetailsData?.parentBatchId)) {
    return null;
  }

  return (
    <Box sx={{ margin: "10px 0" }}>
      <Button
        onClick={handleBackBtnClick}
        sx={{ alignItems: "center", gap: "10px", ":hover": { textDecoration: "underline" }, height: "30px" }}
      >
        <ArrowBackIosNewIcon color="primary" />
        <Typography>Go to parent</Typography>
      </Button>
    </Box>
  );
});
