import { graphqlClient } from "common/graphqlClient";
import {
  DelegationGroupResolutionQuery,
  DelegationGroupResolutionQueryVariables,
  DelegationGroupsQuery,
  DelegationGroupsQueryVariables,
  DeleteDelegationGroupMutationVariables,
  LabelingCategoriesQuery,
  LabelingCategoriesQueryVariables,
  SaveDelegationGroupMutationVariables,
  getSdk,
} from "generated/sdk";
import { createObservableContainer } from "storeContainer";
import { StoreBase } from "./StoreBase";

const sdk = getSdk(graphqlClient);

export class ApprovalStore extends StoreBase {
  delegationGroups = createObservableContainer<DelegationGroupsQuery["DelegationGroups"]>();
  delegationGroupResolution = createObservableContainer<DelegationGroupResolutionQuery["DelegationGroupResolution"]>();
  labelingCategories = createObservableContainer<LabelingCategoriesQuery["LabelingCategories"]>();

  loadDelegationGroups(params: DelegationGroupsQueryVariables, forceUpdate: boolean = false) {
    return this.delegationGroups.cachedLoad(
      async () => (await sdk.DelegationGroups(params)).DelegationGroups,
      [params],
      {
        forceUpdate,
      },
    );
  }

  loadDelegationGroupResolution(params: DelegationGroupResolutionQueryVariables, forceUpdate: boolean = false) {
    return this.delegationGroupResolution.cachedLoad(
      async () => (await sdk.DelegationGroupResolution(params)).DelegationGroupResolution,
      [params],
      { forceUpdate },
    );
  }

  loadLabelingCategories(params: LabelingCategoriesQueryVariables, forceUpdate: boolean = false) {
    return this.labelingCategories.cachedLoad(
      async () => (await sdk.LabelingCategories(params)).LabelingCategories,
      [params],
      { forceUpdate },
    );
  }

  async saveDelegationGroup(params: SaveDelegationGroupMutationVariables, reloadDependencies: boolean = true) {
    const response = await sdk.SaveDelegationGroup(params);

    if (response.SaveDelegationGroup && reloadDependencies) {
      await this.reloadDependencies();
    }

    return response;
  }

  async deleteDelegationGroup(params: DeleteDelegationGroupMutationVariables, reloadDependencies: boolean = true) {
    const response = await sdk.DeleteDelegationGroup(params);

    if (response.DeleteDelegationGroup && reloadDependencies) {
      await this.reloadDependencies();
    }

    return response;
  }

  reloadDependencies() {
    const promises: any[] = [];

    if (this.delegationGroups.reload) {
      promises.push(this.delegationGroups.reload({ markAsLoading: false }));
    }

    if (this.delegationGroupResolution.reload) {
      promises.push(this.delegationGroupResolution.reload({ markAsLoading: false }));
    }

    if (this.labelingCategories.reload) {
      promises.push(this.labelingCategories.reload({ markAsLoading: false }));
    }

    return Promise.all(promises);
  }

  clearDelegationGroups() {
    this.delegationGroups.flush();
  }

  clearDelegationGroupResolution() {
    this.delegationGroupResolution.flush();
  }

  flush() {
    this.clearDelegationGroups();
    this.clearDelegationGroupResolution();
  }
}
