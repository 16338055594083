import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
// import StoreIcon from "@mui/icons-material/Store";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import { ReactElement, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { Link, useHistory, useParams } from "react-router-dom";
import { useStore } from "storeContainer";
import { OrganizationCompanySelector } from "../pages/Settings/OrganizationCompanySelector/OrganizationCompanySelector";
import styles from "./CompanyMenu.module.scss";

export const CompanyMenuListItem: React.FunctionComponent<{ menuItem: CompanyMenuItem }> = ({ menuItem }) => {
  const { subItems = [], title, url } = menuItem;
  const [open, setOpen] = useState<boolean>(false);
  const history = useHistory();
  const location = useLocation();

  const handleClick = () => {
    if (subItems.length > 0) {
      setOpen(!open);
      return;
    }

    if (url) {
      history.push(url);
    }
  };

  return (
    <Link
      to={url ?? ""}
      className={classNames(styles.companyMenuItem, {
        [styles.companyMenuItemSelected]: url === location.pathname,
      })}
    >
      {title}
    </Link>
  );
};

export type CompanyMenuItem = {
  title: string;
  url: string | null;
  subItems?: CompanySubMenuItem[];
  icon: ReactElement;
};

export type CompanySubMenuItem = {
  title: string;
  url: string | null;
};

export const CompanyMenu: React.FunctionComponent = observer(function CompanyMenu() {
  const { companyId, organizationId } = useParams<{ companyId: string; organizationId: string }>();
  const contextStore = useStore("ContextStore");
  const sessionStore = useStore("SessionStore");
  const companyStore = useStore("CompanyStore");
  const organizationStore = useStore("OrganizationStore");
  const integrationsStore = useStore("IntegrationsStore");

  const { acl } = sessionStore;
  const { integrations } = companyStore;

  useEffect(() => {
    if (companyId) {
      contextStore.setSelectedCompanyId(companyId);
      if (acl.canManageIntegration) {
        integrationsStore.loadCompanyIntegrations(companyId);
      }
    }
    // return () => contextStore.setSelectedCompanyId(undefined);
  }, [contextStore, companyId, integrationsStore, acl.canManageIntegration]);

  useEffect(() => {
    if (acl.canListIntegrationBankAccounts) {
      companyStore.loadIntegrations();
      organizationStore.loadOrganizationStructure(organizationId);
    }
  }, [companyStore, acl.canListIntegrationBankAccounts, companyId]);

  if (!contextStore.selectedCompanyId) {
    return null;
  }
  if (window.location.pathname.includes("importErrors")) {
    return null;
  }

  const menu: CompanyMenuItem[] = [
    {
      title: "Company Details",
      url: `/org/${contextStore.selectedOrganizationId}/company/${companyId}`,
      icon: <AccountBalanceIcon />,
    },
    ...(acl.basUserMayList.length
      ? [
          {
            title: `Bank Accounts (${acl.basUserMayList.length})`,
            url: `/org/${contextStore.selectedOrganizationId}/company/${companyId}/bas`,
            icon: <AccountBalanceIcon />,
          },
        ]
      : []),
    ...(acl.canAddIntegration && integrations.data && integrations.data.length === 0
      ? [
          {
            title: `Add Integration`,
            url: `/org/${contextStore.selectedOrganizationId}/company/${companyId}/integrations/`,
            icon: <AccountBalanceIcon />,
          },
        ]
      : []),
    ...(integrationsStore.companyIntegrationsList.isLoaded &&
    !integrationsStore.companyIntegrationsList.isLoading &&
    acl.canManageIntegration &&
    integrations.data &&
    integrations.data.length > 0
      ? [
          {
            title: `Integration Mapping`,
            url: `/org/${contextStore.selectedOrganizationId}/company/${companyId}/integrations/${integrations.data[0].integration?.integration_id}`,
            icon: <AccountBalanceIcon />,
          },
        ]
      : []),
    /*  ...(acl.canListSelectedCompanyContacts
      ? [
          {
            title: "Contacts",
            url: `/org/${contextStore.selectedOrganizationId}/company/${companyId}/vendors`,
            icon: <StoreIcon />,
          },
        ]
      : []), 
      !TEMPORARILY DISABLED
      */
  ];

  return (
    <Box
      sx={{
        width: "300px",
        maxWidth: "300px",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography variant="h1" marginTop="40px" paddingX="15px">
        Company
      </Typography>
      <OrganizationCompanySelector />

      {menu.map((item, key) => (
        <CompanyMenuListItem menuItem={item} key={key} />
      ))}
    </Box>
  );
});
