import { StoreBase } from "storesMobx/StoreBase";
import { graphqlClient } from "../common/graphqlClient";
import { Contact, ContactsQuery, getSdk, Pagination, PaginationResult } from "../generated/sdk";
import { definitely, definitelyFilter } from "../generated/utils";
import { createObservableContainer } from "../storeContainer";

const { Contacts } = getSdk(graphqlClient);

export class VendorsStore extends StoreBase {
  vendorsList = createObservableContainer<NonNullable<Contact[]>>();
  paginationResponse = createObservableContainer<NonNullable<PaginationResult>>();

  currentCompanyId: string | undefined = undefined;

  async parseVendors(contactsResponse: ContactsQuery) {
    return definitelyFilter(contactsResponse?.Contacts?.contacts);
  }
  async parsePagination(contactsResponse: ContactsQuery) {
    return definitely(contactsResponse?.Contacts?.pagination);
  }

  async _fetchVendors(companyId: string, pagination?: Pagination, search?: string) {
    return await Contacts({ companyId, pagination, search });
  }

  async loadVendors(force?: true, pagination?: Pagination, search?: string) {
    this.vendorsList.flush();
    const companyId = this.storeContainer?.ContextStore.selectedCompanyId;

    if (this.currentCompanyId === companyId && this.vendorsList.isLoaded && !force) return;

    if (companyId) {
      this.currentCompanyId = companyId;
      const contactsResponse = await this._fetchVendors(companyId, pagination, search);
      await this.paginationResponse.load(this.parsePagination(contactsResponse));
      await this.vendorsList.load(this.parseVendors(contactsResponse));
    }
  }

  getVendor(vendorId: string) {
    return this.vendorsList.data?.find((vendor) => vendor?.id === vendorId);
  }
}
