import { Typography } from "@mui/material";
import { FieldError } from "react-hook-form";
import { COLORS } from "themes/default";

interface IFormErrorMsgProps {
  fieldError?: FieldError;
}

export const FormErrorMsg = ({ fieldError }: IFormErrorMsgProps) => {
  if (!fieldError) {
    return null;
  }

  return <Typography sx={{ color: COLORS.error, marginTop: "5px" }}>{fieldError.message}</Typography>;
};
