import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Box, Button, TablePagination, Typography } from "@mui/material";
import { graphqlVBillClient } from "common/graphqlClient";
import { Spinner } from "components/common/Spinner/Spinner";
import { IVBillBillsFiltersInput } from "generated/graphql.vbill";
import { getSdk, IVBillVBillGetBatchListQuery } from "generated/sdk.vbill";
import { isEmpty } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useStore } from "storeContainer";
import { VBillActiveFilters } from "../../common/VBill/VBillActiveFilters/VBillActiveFilters";
import { BatchListFilters } from "../BatchList/BatchListFilters/BatchListFilters";
import { BatchAmountsLineChart } from "../common/BatchAmountsLineChart/BatchAmountsLineChart";
import { BatchBundleDialog } from "./BatchBundleDialog";

const { VBillUpdateBatch } = getSdk(graphqlVBillClient);

interface IBatchBundleProps {
  onDrawerClose: () => void;
}

export const BatchBundle = observer(function BatchBundle({ onDrawerClose }: IBatchBundleProps) {
  const [batchBundleDialogOpened, setBatchBundleDialogOpened] = useState(false);
  const vBillBatchDetailsStore = useStore("VBillBatchDetailsStore");
  const history = useHistory();
  const { batchFilters, batchDetails, childBatchListDetails } = vBillBatchDetailsStore;
  const { batchId, organizationId } = useParams<{ batchId: string; organizationId: string }>();
  const [batchFiltersVisible, setBatchFiltersVisible] = useState(false);

  useEffect(() => {
    vBillBatchDetailsStore.getchildBatchListDetails(
      {
        filters: {
          ...batchFilters.batch_list_fil,
          batchId: Number(batchId),
          organizationId,
        },
      },
      true,
    );
  }, [vBillBatchDetailsStore, organizationId, batchId, batchFilters.batch_list_fil, batchFilters.batch_list_pag]);

  useEffect(() => {
    if (!batchDetails.data?.settings?.BATCH_SUBBATCHES_ALLOWED || !childBatchListDetails.data) {
      return;
    }

    vBillBatchDetailsStore.getChildBatchListDetailsReactionsStats(true);
  }, [vBillBatchDetailsStore, batchDetails.data?.settings?.BATCH_SUBBATCHES_ALLOWED, childBatchListDetails.data]);

  useEffect(() => {
    setBatchFiltersVisible(false);
  }, [batchId]);

  const handleLineChartAddEditBatchSubmit = (isParentBatch?: boolean) => {
    if (isParentBatch) {
      vBillBatchDetailsStore.getBatchDetails({ batchId: Number(batchId), organizationId }, true);
    } else {
      vBillBatchDetailsStore.getchildBatchListDetails(
        {
          pagination: batchFilters.batch_list_pag,
          filters: {
            ...batchFilters.batch_list_fil,
            organizationId,
            batchId: Number(batchId),
          },
        },
        true,
      );
    }
  };

  const handleLineChartFiltersChange = async ({
    batch,
    batchFilters: filters,
    isParentBatch,
  }: {
    batch?: NonNullable<IVBillVBillGetBatchListQuery["getBatchList"]["data"]>["items"][number];
    batchFilters: IVBillBillsFiltersInput;
    isParentBatch?: boolean;
  }) => {
    if (!batch) {
      return;
    }

    await VBillUpdateBatch({
      batchId: batch.id,
      batchData: {
        name: batch.name,
        organizationId: batch.organizationId,
        batchFilters: { ...(!isEmpty(filters) ? { ...batch.batchFilters, ...filters } : undefined) },
      },
    });

    if (isParentBatch) {
      vBillBatchDetailsStore.getBatchDetails({ batchId: Number(batchId), organizationId }, true);
    } else {
      vBillBatchDetailsStore.getchildBatchListDetails(
        {
          pagination: batchFilters.batch_list_pag,
          filters: {
            ...batchFilters.batch_list_fil,
            organizationId,
            batchId: Number(batchId),
          },
        },
        true,
      );
    }
  };
  const handleLineChartConfirmDeleteBatchSubmit = (isParentBatch?: boolean) => {
    if (isParentBatch) {
      history.push(`/org/${organizationId}/vbillbatchledger`);
    } else {
      vBillBatchDetailsStore.getchildBatchListDetails(
        {
          pagination: batchFilters.batch_list_pag,
          filters: {
            ...batchFilters.batch_list_fil,
            organizationId,
            batchId: Number(batchId),
          },
        },
        true,
      );
    }
  };

  const handleLineChartMembersChange = () => {
    vBillBatchDetailsStore.getBatchMembers({ batchId: Number(batchId) }, true);
  };

  const handleLineChartRemoveBillsSubmit = (isParentBatch?: boolean) => {
    if (isParentBatch) {
      vBillBatchDetailsStore.getBatchSelectedVBills(
        {
          pagination: batchFilters.vbill_sel_pag,
          filters: { ...batchFilters.vbill_sel_fil, organizationId, batch_id_in: [Number(batchId)] },
        },
        true,
      );
    } else {
      vBillBatchDetailsStore.getchildBatchListDetails(
        {
          pagination: batchFilters.batch_list_pag,
          filters: {
            ...batchFilters.batch_list_fil,
            organizationId,
            batchId: Number(batchId),
          },
        },
        true,
      );
    }
  };

  const {
    childBatchListDetails: batchList,
    batchDetails: batch,
    batchDetailsReactionsStats: batchReactionsStats,
    childBatchListDetailsReactionsStats: batchListReactionsStats,
  } = vBillBatchDetailsStore;
  const batchListData = batchList.data;
  const showTablePagination = (batchListData?.total ?? 10) > (batchFilters.batch_list_pag.per_page ?? 10);
  const batchData = batch.data;

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Typography variant="h3">Sub batches</Typography>

        <Box>
          <Button
            onClick={() => setBatchFiltersVisible((prev) => !prev)}
            sx={{ ":hover": { textDecoration: "underline" }, height: "30px" }}
          >
            {batchFiltersVisible ? "Hide" : "Show"} filters
          </Button>
          <Button
            variant="contained"
            startIcon={<AddCircleIcon />}
            onClick={() => setBatchBundleDialogOpened(true)}
            sx={{ height: "30px" }}
          >
            Add Batch
          </Button>
        </Box>
      </Box>

      {batchFiltersVisible && <BatchListFilters hideActiveFilters sx={{ marginTop: "10px" }} />}

      <VBillActiveFilters
        chipSmallHeight
        filters={{
          search: batchFilters.batch_list_fil?.search,
          amount_from: batchFilters.batch_list_fil?.budget_from
            ? `${batchFilters.batch_list_fil?.budget_from}`
            : undefined,
          amount_to: batchFilters.batch_list_fil?.budget_to ? `${batchFilters.batch_list_fil?.budget_to}` : undefined,
          date: batchFilters.batch_list_fil?.date_from,
          date_to: batchFilters.batch_list_fil?.date_to,
        }}
        onFiltersChange={(values) =>
          vBillBatchDetailsStore.setBatchFilters((prev) => ({
            batch_list_fil: Object.keys(values).length
              ? {
                  ...prev.batch_list_fil,
                  search: values.search,
                  budget_from: values.amount_from,
                  budget_to: values.amount_to,
                  date: values.date,
                  date_to: values.date_to,
                }
              : undefined,
            batch_list_pag: { page: 1, per_page: prev.batch_list_pag.per_page },
          }))
        }
      />

      <BatchAmountsLineChart
        batch={batchData}
        showTitle
        showMenu
        showMainTooltip
        sx={{ alignItems: "flex-start" }}
        onLineMenuAddEditBatchSubmit={() => handleLineChartAddEditBatchSubmit(true)}
        onLineMenuFiltersChange={(batchFilters) =>
          handleLineChartFiltersChange({ batch: batchData, batchFilters, isParentBatch: true })
        }
        onLineMenuConfirmDeleteBatchSubmit={() => handleLineChartConfirmDeleteBatchSubmit(true)}
        onLineMenuMembersChange={handleLineChartMembersChange}
        // reactionsStatsGroups={batchReactionsStats.data}
        // onReactionClick={() => vBillBatchDetailsStore.getBatchDetailsReactionsStats(true)}
        onLineMenuRemoveBillsSubmit={() => handleLineChartRemoveBillsSubmit(true)}
      />

      {batchList.isLoading ? (
        <Box sx={{ textAlign: "center" }}>
          <Spinner />
        </Box>
      ) : batchListData?.items?.length === 0 ? (
        <Box sx={{ textAlign: "center" }}>
          <Typography>No results found</Typography>
        </Box>
      ) : (
        <>
          {batchListData?.items.map((batch) => (
            <BatchAmountsLineChart
              key={batch.id}
              batch={batch}
              showTitle
              showMenu
              showMainTooltip
              withLink
              sx={{ paddingLeft: "15px", margin: "15px 0", alignItems: "flex-end" }}
              onLineMenuAddEditBatchSubmit={handleLineChartAddEditBatchSubmit}
              onLineMenuFiltersChange={(batchFilters) => handleLineChartFiltersChange({ batch, batchFilters })}
              onLineMenuConfirmDeleteBatchSubmit={handleLineChartConfirmDeleteBatchSubmit}
              reactionsStatsGroups={batchListReactionsStats.data}
              onReactionClick={() => vBillBatchDetailsStore.getChildBatchListDetailsReactionsStats(true)}
              onLineMenuRemoveBillsSubmit={handleLineChartRemoveBillsSubmit}
              onLinkClick={onDrawerClose}
            />
          ))}

          {showTablePagination && (
            <TablePagination
              component="div"
              showFirstButton
              showLastButton
              page={(batchFilters.batch_list_pag.page ?? 1) - 1}
              rowsPerPage={batchFilters.batch_list_pag.per_page ?? 10}
              count={batchListData?.total ?? 10}
              rowsPerPageOptions={[10, 25, 50, 100]}
              onRowsPerPageChange={(event) =>
                vBillBatchDetailsStore.setBatchFilters({
                  batch_list_pag: { per_page: Number(event.target.value), page: 1 },
                })
              }
              onPageChange={(_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) =>
                vBillBatchDetailsStore.setBatchFilters((prev) => ({
                  batch_list_pag: { ...prev.batch_list_pag, page: newPage + 1 },
                }))
              }
            />
          )}
        </>
      )}

      {batchBundleDialogOpened && <BatchBundleDialog onCloseDialog={() => setBatchBundleDialogOpened(false)} />}
    </Box>
  );
});
