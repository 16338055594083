import { useStore } from "../../../../../storeContainer";
import styles from "./EditVCheckModal.module.scss";
import { IEditVCheckModalProps } from "./types";

import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/material";
import { useEffect, useState } from "react";

const EditVCheckModal = ({ isModalOpened, checkId, modalClose, reload, check, hasEditMemo }: IEditVCheckModalProps) => {
  const vChecksStore = useStore("VChecksStore");

  const [value, setValue] = useState(hasEditMemo ? check?.memo : check?.pttoo || "");
  const [error, setError] = useState("");

  const handleModalClose = () => {
    modalClose(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    if (e.target.value.match(/^([a-zA-Z0-9^#\u0600-\u06FF\u0660-\u0669\u06F0-\u06F9 _.-]+)$/)) {
      setError("");
    } else {
      setError("Symbols are not allowed.");
    }
  };

  const handleEditCheck = async () => {
    if (hasEditMemo) {
      await vChecksStore.updateCheck(checkId, undefined, value!);
    } else {
      await vChecksStore.updateCheck(checkId, value!, undefined);
    }
    reload(true);
    handleModalClose();
  };

  useEffect(() => {
    setValue(hasEditMemo ? check?.memo : check?.pttoo);
    return () => setValue("");
  }, [check?.memo, check?.pttoo, hasEditMemo]);

  return (
    <Modal
      open={isModalOpened}
      onClose={handleModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={styles.editContainer}>
        <Box className={styles.header}>
          <span>Edit contact details</span>
          <CloseIcon className={styles.closeIcon} onClick={handleModalClose} />
        </Box>
        <TextField
          label={`vCheck ${hasEditMemo ? "memo" : " pttoo"}`}
          value={value || ""}
          onChange={handleInputChange}
          sx={{ width: "100%", marginBottom: "20px" }}
          error={!!error}
          helperText={error}
        />
        <Box className={styles.checkboxContainer}>
          <Checkbox disabled={!!error} />
          <Typography>Save to contact for future vChecks</Typography>
        </Box>
        <Box className={styles.buttonsContainer}>
          <Button variant={"text"} onClick={handleModalClose}>
            Cancel
          </Button>
          <Button variant={"contained"} onClick={handleEditCheck} disabled={!!error}>
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default EditVCheckModal;
