import Box from "@mui/material/Box";
import { Spinner } from "components/common/Spinner/Spinner";
import { AccessRestricted } from "components/pages/AccessRestricted";
import { observer } from "mobx-react-lite";
import { useState, useEffect } from "react";
import { renderRoutes } from "react-router-config";
import { useStore } from "storeContainer";

const AclCheckWrapGenerator = (props: any) => {
  const { acl } = useStore("SessionStore");
  const [isAllowed, setIsAllowed] = useState<boolean | undefined>(false);

  useEffect(() => {
    if (acl?.canManageUsers) {
      setIsAllowed(true);
    }
  }, [acl]);

  if (isAllowed === undefined) return <Spinner withSpaceAround />;

  if (!isAllowed) return <AccessRestricted />;

  return (
    <Box maxWidth="1400px" width="100%">
      {renderRoutes(props.route.routes)}
    </Box>
  );
};
export default observer(AclCheckWrapGenerator);
