import { isCheckDeposited } from "common/helpers/isCheckDeposited";
import { LedgerCheck } from "generated/sdk";
import { useStore } from "storeContainer";

export const useCheckPath = (ledgerCheck: LedgerCheck) => {
  const ctxStore = useStore("ContextStore");

  if (!ledgerCheck) {
    return false;
  }

  if (ledgerCheck.role && ledgerCheck.role === "sender") {
    return `/org/${ctxStore.selectedOrganizationId}/ledger/${ledgerCheck?.sender?.id}/checks/${ledgerCheck.id}`;
  } else if (ledgerCheck.role && ledgerCheck.role === "recipient" && ledgerCheck.recipient?.organization_id) {
    return `/org/${ledgerCheck.recipient?.organization_id}/ledger/${ledgerCheck?.recipient?.id}/checks/${ledgerCheck.id}`;
  } else {
    return `/check/${ledgerCheck.id}`;
  }
};
