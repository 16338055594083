import CloudSyncIcon from "@mui/icons-material/CloudSync";
import { IconButton, Tooltip } from "@mui/material";
import { observer } from "mobx-react";
import { useStore } from "storeContainer";
import { COLORS } from "themes/default";

export const CodingSyncBtn = observer(() => {
  const vBillCodingLedgerStore = useStore("VBillCodingLedgerStore");

  const handleSyncBtnClick = () => {
    vBillCodingLedgerStore.invoicesList.reload?.();
    vBillCodingLedgerStore.invoicesStats.reload?.();
  };

  return (
    <Tooltip title="Refresh bills" arrow>
      <span>
        <IconButton
          disabled={vBillCodingLedgerStore.invoicesList.isLoading || vBillCodingLedgerStore.invoicesStats.isLoading}
          onClick={handleSyncBtnClick}
        >
          <CloudSyncIcon sx={{ fontSize: "18px", color: COLORS.logoBlue }} />
        </IconButton>
      </span>
    </Tooltip>
  );
});
