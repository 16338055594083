import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Backdrop, Box, Button, Collapse, IconButton, InputLabel, Menu, TextField, Typography } from "@mui/material";
import classNames from "classnames";
import { useCheckFilters } from "common/hooks/useCheckFilters";
import { LedgerFilterInputType, getFiltersConfig } from "components/Filter/types";
import { CloseIcon } from "components/common/icons";
import { CheckFiltersType } from "generated/sdk";
import { debounce, isEmpty, isNil } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { useParams, useRouteMatch } from "react-router-dom";
import styles from "./Filters.module.scss";

interface AmountFiltersProps {
  filtersPayload?: CheckFiltersType;
  setFiltersPayload?: React.Dispatch<React.SetStateAction<CheckFiltersType | undefined>>;
  className?: string;
  handleClose?: () => void;
}

const getAmountHelperText = (amountFrom?: number | null, amountTo?: number | null, isMaxAmount?: boolean) => {
  if (amountFrom === undefined) {
    return "";
  }
  if (amountFrom! <= 0) {
    return "Must be above 0";
  }
  if (amountTo! <= 0) {
    return "Must be above 0";
  }
  if (amountFrom && amountFrom <= 0) {
    return "Must be above 0";
  }
  if (amountTo && amountFrom && amountFrom > amountTo) {
    return isMaxAmount ? "Must be less than Max Amount" : "Must be larger than Min Amount";
  }
  return "";
};

export const AmountFilterInputs = ({ filtersPayload, setFiltersPayload, handleClose }: AmountFiltersProps) => {
  const { filters, setFilters, setPagination, pagination } = useCheckFilters<CheckFiltersType>();
  const [amountFromValueDisplayState, setAmountFromValueDisplayState] = useState<string>("");
  const [amountToValueDisplayState, setAmountToValueDisplayState] = useState<string>("");
  const { path } = useRouteMatch();
  const { selectedQuickFilter } = useParams<{ selectedQuickFilter: string }>();
  const latestQueryFilters = useRef<CheckFiltersType>();
  const formConfig = getFiltersConfig(path, selectedQuickFilter)?.[LedgerFilterInputType.InputAmount];

  const isAmountFromGreaterThanAmountTo =
    !isNil(filtersPayload?.amount_from) &&
    !isNil(filtersPayload?.amount_to) &&
    filtersPayload?.amount_from! > filtersPayload?.amount_to!;
  const isAmountFromNonPositive = !isNil(filtersPayload?.amount_from) && filtersPayload?.amount_from! <= 0;
  const isAmountToLessThanAmountFrom =
    !isNil(filtersPayload?.amount_from) &&
    !isNil(filtersPayload?.amount_to) &&
    filtersPayload?.amount_to! < filtersPayload?.amount_from!;
  const isAmountToNonPositiveOrTooLarge =
    !isNil(filtersPayload?.amount_to) && (filtersPayload?.amount_to! <= 0 || filtersPayload?.amount_to! > 1000000);

  const handleAmountFromOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value.replace(/,/g, "");
    const formattedValue = formatNumberWithCommas(inputValue);
    setAmountFromValueDisplayState(formattedValue);
    if (!handleClose) {
      debouncedSearch(event);
    }
  };
  const handleAmountToOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value.replace(/,/g, "");
    const formattedValue = formatNumberWithCommas(inputValue);
    setAmountToValueDisplayState(formattedValue);
    if (!handleClose) {
      debouncedSearch(event);
    }
  };

  useEffect(() => {
    latestQueryFilters.current = filters;
  }, [filters]);

  useEffect(() => {
    const formattedToValue = formatNumberWithCommas(filters?.amount_to!?.toString());
    const formattedFromValue = formatNumberWithCommas(filters?.amount_from!?.toString());
    if (formattedFromValue) {
      setAmountToValueDisplayState(formattedToValue);
    }
    if (formattedToValue) {
      setAmountFromValueDisplayState(formattedFromValue);
    }
  }, [filters?.amount_from, filters?.amount_to]);

  const debouncedSearch = useCallback(
    debounce((event: React.ChangeEvent<HTMLInputElement>) => {
      setFilters((prevFilters) => {
        //TODO this is a workaround for state mismanagement async race conditions, it's better than a setTimeout and it will do for now, but this should be refactored so that filters and pagination are part of a batch state update
        if (pagination.page !== 1) {
          setPagination((prevPagination) => ({ ...prevPagination, page: 1 }));
        }
        return {
          ...prevFilters,
          [event.target.name]: parseFloat(event.target.value.split(",").join("")),
        };
      });
    }, 600),
    [],
  );

  const handleOnKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    event.stopPropagation();
    return ["e", "E", "+", "-"].includes(event.key) && event.preventDefault();
  };
  const handleOnPaste = (event: React.ClipboardEvent<HTMLDivElement>) => {
    const pastedData = event.clipboardData.getData("text");
    if (/[eE+\-]/.test(pastedData)) {
      event.preventDefault();
    }
  };
  const handleClearAmountFrom = () => {
    setFilters({ ...latestQueryFilters.current, amount_from: undefined });
    setAmountFromValueDisplayState("");
  };
  const handleClearAmountTo = () => {
    setFilters({ ...latestQueryFilters.current, amount_to: undefined });
    setAmountToValueDisplayState("");
  };
  const formatNumberWithCommas = (number: string) => {
    return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const hasFiltersPayloadAmountFrom = !!filtersPayload?.amount_from;
  const hasFiltersPayloadAmountTo = !!filtersPayload?.amount_to;

  useEffect(() => {
    if (isEmpty(filters)) {
      setAmountFromValueDisplayState("");
      setAmountToValueDisplayState("");
    }
  }, [filters]);

  return !formConfig ? null : (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        minHeight={!setFiltersPayload ? "unset" : "95px"}
        gap={!setFiltersPayload ? "15px" : "0"}
      >
        <Box>
          <InputLabel variant="standard" htmlFor="amount_from">
            Min amount
          </InputLabel>
          <TextField
            id="amount_from"
            value={amountFromValueDisplayState}
            name={formConfig?.keyNameMinValue as string}
            onChange={handleAmountFromOnChange}
            onKeyDown={handleOnKeyDown}
            onPaste={handleOnPaste}
            className={styles.filtersInputWidth}
            InputProps={{
              sx: { paddingLeft: hasFiltersPayloadAmountFrom ? 1 : 2 },
              startAdornment: (
                <>
                  {hasFiltersPayloadAmountFrom && (
                    <IconButton onClick={handleClearAmountFrom} sx={{ padding: "0 2px 0 0" }}>
                      <CloseIcon />
                    </IconButton>
                  )}

                  <Typography>$ </Typography>
                </>
              ),
            }}
            inputProps={{ pattern: "[0-9]*", min: 0, max: 1000000 }}
            error={Boolean(isAmountFromGreaterThanAmountTo || isAmountFromNonPositive)}
            helperText={
              isAmountFromGreaterThanAmountTo
                ? "Min amount can't be larger than Max amount"
                : isAmountFromNonPositive
                  ? "Min amount must be larger than 0"
                  : null
            }
          />
        </Box>
        <Box>
          <InputLabel variant="standard" htmlFor="amount_to">
            Max amount
          </InputLabel>
          <TextField
            id="amount_to"
            value={amountToValueDisplayState}
            name={formConfig?.keyNameMaxValue as string}
            onChange={handleAmountToOnChange}
            onKeyDown={handleOnKeyDown}
            onPaste={handleOnPaste}
            className={styles.filtersInputWidth}
            InputProps={{
              sx: { paddingLeft: hasFiltersPayloadAmountTo ? 1 : 2 },
              startAdornment: (
                <>
                  {hasFiltersPayloadAmountTo && (
                    <IconButton onClick={handleClearAmountTo} sx={{ padding: "0 2px 0 0" }}>
                      <CloseIcon />
                    </IconButton>
                  )}

                  <Typography>$ </Typography>
                </>
              ),
            }}
            inputProps={{ pattern: "[0-9]*", min: 0, max: 1000000 }}
            error={Boolean(isAmountToLessThanAmountFrom || isAmountToNonPositiveOrTooLarge)}
            helperText={
              isAmountToLessThanAmountFrom
                ? "Max amount can't be smaller than Min amount"
                : isAmountToNonPositiveOrTooLarge && filtersPayload?.amount_to! > 1000000
                  ? "Max amount can't be larger than 1,000,000"
                  : isAmountToNonPositiveOrTooLarge
                    ? "Max amount must be larger than 0"
                    : undefined
            }
          />
        </Box>
      </Box>
      {handleClose && (
        <Button
          sx={{ marginTop: "10px" }}
          onClick={() => {
            setFilters((prevFilters) => {
              return {
                ...prevFilters,
                [formConfig?.keyNameMinValue as string]:
                  amountFromValueDisplayState?.length > 0
                    ? parseFloat(amountFromValueDisplayState.split(",").join(""))
                    : undefined,
                [formConfig?.keyNameMaxValue as string]:
                  amountToValueDisplayState?.length > 0
                    ? parseFloat(amountToValueDisplayState.split(",").join(""))
                    : undefined,
              };
            });
            handleClose();
          }}
          variant="contained"
        >
          Done
        </Button>
      )}
    </>
  );
};

export const AmountFiltersPopup = ({ filtersPayload, setFiltersPayload }: AmountFiltersProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const popoverOpen = Boolean(anchorEl);
  return (
    <Box height={"100%"} alignSelf={"flex-end"}>
      <Button
        variant={filtersPayload?.amount_from || filtersPayload?.amount_to ? "containedFilters" : "outlined"}
        onClick={handleClick}
        endIcon={<ExpandMoreIcon />}
        sx={{ fontSize: "12px", width: "150px" }}
      >
        amount
      </Button>
      <Menu
        sx={{ top: "5px" }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={handleClose}
        anchorEl={anchorEl}
        open={popoverOpen}
        slots={{ backdrop: Backdrop }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", padding: "10px" }}>
          <AmountFilterInputs
            setFiltersPayload={setFiltersPayload}
            filtersPayload={filtersPayload}
            handleClose={handleClose}
          />
        </Box>
      </Menu>
    </Box>
  );
};

export const AmountFilters = ({ filtersPayload, setFiltersPayload, className }: AmountFiltersProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const amountFromHelperText = getAmountHelperText(filtersPayload?.amount_from, filtersPayload?.amount_to);
  const amountToHelperText = getAmountHelperText(filtersPayload?.amount_from, filtersPayload?.amount_to, true);

  const handleOpen = () => setIsOpen((prevIsOpen) => !prevIsOpen);

  return (
    <Box
      className={classNames(styles.dateFiltersContainer, className, {
        [styles.dateFiltersContainerErr]: (amountToHelperText || amountFromHelperText) && isOpen,
      })}
    >
      <Box onClick={handleOpen} className={styles.dateFiltersDropdown}>
        <Typography className={styles.filterModalTitle}>Amount</Typography>
        {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </Box>
      <Collapse in={isOpen}>
        <AmountFilterInputs setFiltersPayload={setFiltersPayload} filtersPayload={filtersPayload} />
      </Collapse>
    </Box>
  );
};
