import { Box, Typography } from "@mui/material";
import { PersonOutlineIcon } from "components/common/icons";
import { IActionlogActionlogFeOutput } from "generated/graphql.actionlog";
import { FunctionComponent } from "react";
import { COLORS } from "themes/default";
import { ActivityLogActionType } from "../../types";

const initTitleStyles = { fontWeight: 500, fontSize: 13, color: COLORS.logoBlue };

interface IActionTitleProps {
  actionLog: IActionlogActionlogFeOutput;
}

export const ActionTitle: FunctionComponent<IActionTitleProps> = ({ actionLog: { changedBy, action, meta } }) => {
  // thing's will change, later (different title styles & icon & order)
  switch (action) {
    case ActivityLogActionType.CREATE:
    case ActivityLogActionType.EDIT:
    case ActivityLogActionType.EDIT_DONE:
    case ActivityLogActionType.MAPPING:
    case ActivityLogActionType.MAPPING_COMPANY:
    case ActivityLogActionType.MAPPING_VENDOR:
    case ActivityLogActionType.MAPPING_GLACCOUNT:
    case ActivityLogActionType.MAPPING_ADDITIONAL_FIELDS:
    case ActivityLogActionType.MAPPING_DONE:
    case ActivityLogActionType.COMMENT:
    case ActivityLogActionType.START_APPROVAL:
    case ActivityLogActionType.APPROVE:
    case ActivityLogActionType.UNAPPROVE:
    case ActivityLogActionType.REVOKE:
    case ActivityLogActionType.DECLINE:
    case ActivityLogActionType.DELETE:
    case ActivityLogActionType.PUSH_TO_INTEGRATION:
    case ActivityLogActionType.UPDATE_PAYMENT:
      return (
        <Typography sx={initTitleStyles}>
          {changedBy?.name && (
            <>
              <Box sx={{ fontSize: "11px", fontWeight: "500", color: COLORS.textGray, display: "flex", gap: "2px" }}>
                <PersonOutlineIcon />
                {changedBy.name}
              </Box>
            </>
          )}
          {meta?.content?.message ? meta.content.message : "Unknown Action"}{" "}
        </Typography>
      );
    default:
      return (
        <Typography sx={initTitleStyles}>
          {changedBy?.name && (
            <>
              <Box sx={{ fontSize: "11px", fontWeight: "500", color: COLORS.textGray, display: "flex", gap: "2px" }}>
                <PersonOutlineIcon /> {changedBy.name}
              </Box>
            </>
          )}
          {meta?.content?.message ? meta.content.message : "Unknown Action"}{" "}
        </Typography>
      );
  }
};
