import AddCircleIcon from "@mui/icons-material/AddCircle";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Paper, Table, TableContainer, TablePagination, Typography } from "@mui/material";
import { graphqlVBillClient } from "common/graphqlClient";
import { formatCurrency } from "common/helpers/utils";
import {
  getSdk,
  IVBillBatchStatus,
  IVBillBillsFiltersInput,
  IVBillInvoiceIncomingStatus,
  IVBillVBillGetBillsStatsQuery,
} from "generated/sdk.vbill";
import { isNil } from "lodash";
import { observer } from "mobx-react";
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { Droppable } from "react-beautiful-dnd";
import { useParams } from "react-router";
import { useStore } from "storeContainer";
import { COLORS } from "themes/default";
import { VBillActiveFilters } from "../../../common/VBill/VBillActiveFilters/VBillActiveFilters";
import { VBillDroppableArea } from "../../utils";
import { IBillsAccordianExpanded } from "../BatchVBills";
import {
  BatchVBillsAccordian,
  BatchVBillsAccordianDetails,
  BatchVBillsAccordianSummary,
} from "../BatchVBillsAccordian/BatchVBillsAccordian";
import { BatchVBillsDraggingOverlay } from "../BatchVBillsDraggingOverlay/BatchVBillsDraggingOverlay";
import { BatchVBillsHeader } from "../BatchVBillsHeader/BatchVBillsHeader";
import { VBillsAvailableFilters } from "./VBillsAvailableFilters/VBillsAvailableFilters";
import { VBillsAvailableList } from "./VBillsAvailableList/VBillsAvailableList";

const { VBillGetBillsStats } = getSdk(graphqlVBillClient);

interface IBatchVBillsAvailableProps {
  onAddVBillsToBatch: (billIds: number[]) => Promise<void>;
  onAddAllVBillsToBatch: () => Promise<void>;
  showDraggingOverlay?: boolean;
  billsAccordianExpanded: IBillsAccordianExpanded;
  setBillsAccordianExpanded: Dispatch<SetStateAction<Partial<IBillsAccordianExpanded>>>;
}

export const BatchVBillsAvailable = observer(function BatchVBillsAvailable({
  onAddVBillsToBatch,
  onAddAllVBillsToBatch,
  showDraggingOverlay,
  billsAccordianExpanded,
  setBillsAccordianExpanded,
}: IBatchVBillsAvailableProps) {
  const vBillBatchDetailsStore = useStore("VBillBatchDetailsStore");
  const [isAddBillsFetching, setIsAddBillsFetching] = useState(false);
  const [isAddAllBillsFetching, setIsAddAllBillsFetching] = useState(false);
  const [selectedVBillsToAdd, setSelectedVBillsToAdd] = useState<number[]>([]);
  const {
    batchAvailableVBills,
    batchFilters,
    batchDetails,
    vBillBatchAdditionalMappingsSettings,
    statsFiltersAppliedForBills,
  } = vBillBatchDetailsStore;
  const batchAvailableVBillsData = batchAvailableVBills.data;
  const batchDetailsData = batchDetails.data;
  const vBillBatchAdditionalMappingsSettingsData = vBillBatchAdditionalMappingsSettings.data;
  const [localBillsStats, setLocalBillsStats] = useState<IVBillVBillGetBillsStatsQuery["getBillsStats"]>();
  const [availableFiltersVisible, setAvailableFiltersVisible] = useState(false);
  const { batchId, organizationId } = useParams<{ batchId: string; organizationId: string }>();
  const youSelectedAmount = useMemo(
    () =>
      batchAvailableVBillsData?.items
        .filter(({ id }) => selectedVBillsToAdd.includes(id))
        .reduce((result, bill) => (result = result + Number(bill.amount ?? 0)), 0) ?? 0,
    [batchAvailableVBillsData?.items, selectedVBillsToAdd],
  );
  const additionalMappingsSettingsData = useMemo(
    () => vBillBatchAdditionalMappingsSettingsData?.invoice,
    [vBillBatchAdditionalMappingsSettingsData?.invoice],
  );
  const additionalMappingLabels = useMemo(
    () =>
      (additionalMappingsSettingsData ?? []).map(({ key, label }) => ({
        key,
        label: label ?? "",
      })),
    [additionalMappingsSettingsData],
  );
  const totalBatchAvailableVBills = useMemo(
    () => batchAvailableVBills.data?.items.length ?? 0,
    [batchAvailableVBills.data?.items.length],
  );
  const billsHeaderSelectAllCheckboxChecked = useMemo(
    () => (totalBatchAvailableVBills === 0 ? false : selectedVBillsToAdd.length === totalBatchAvailableVBills),
    [selectedVBillsToAdd.length, totalBatchAvailableVBills],
  );
  const billsHeaderSelectAllCheckboxIndeterminate = useMemo(
    () => (selectedVBillsToAdd.length ? selectedVBillsToAdd.length < totalBatchAvailableVBills : false),
    [selectedVBillsToAdd.length, totalBatchAvailableVBills],
  );
  const formattedYouSelectedAmount = useMemo(() => formatCurrency(youSelectedAmount), [youSelectedAmount]);
  const formattedBillsGeneralStatsAmounts = useMemo(
    () => formatCurrency(Number(localBillsStats?.generalStats.amount ?? 0)),
    [localBillsStats?.generalStats.amount],
  );
  const isBatchClosed = useMemo(
    () =>
      batchDetailsData?.status === IVBillBatchStatus.Closed || batchDetailsData?.status === IVBillBatchStatus.Deleted,
    [batchDetailsData?.status],
  );
  const showVBillsHeaderSelectAllCheckbox = useMemo(
    () => !!(batchAvailableVBillsData?.items ?? []).length,
    [batchAvailableVBillsData?.items],
  );

  useEffect(() => {
    setAvailableFiltersVisible(false);
  }, [batchId]);

  useEffect(() => {
    setAvailableFiltersVisible(statsFiltersAppliedForBills === "available");
  }, [statsFiltersAppliedForBills]);

  useEffect(() => {
    setSelectedVBillsToAdd((batchAvailableVBillsData?.items ?? []).map(({ id }) => id));
  }, [batchAvailableVBillsData?.items, setSelectedVBillsToAdd]);

  const fetchAndSetLocalBillsStats = useCallback(
    async (filters?: IVBillBillsFiltersInput) => {
      const resp = await VBillGetBillsStats({
        filters: {
          ...filters,
          organizationId,
          batch_id_in: [0],
          free_amount_from: "0.01",
          status: IVBillInvoiceIncomingStatus.Approved,
        },
      });

      if (resp.getBillsStats) {
        setLocalBillsStats(resp.getBillsStats);
      }
    },
    [organizationId],
  );

  useEffect(() => {
    fetchAndSetLocalBillsStats(batchFilters.vbill_ava_fil);
  }, [batchFilters.vbill_ava_fil, fetchAndSetLocalBillsStats]);

  // TODO: compute
  // useEffect(() => {
  //   if (!batchAvailableVBillsData) {
  //     return;
  //   }

  //   if (batchFilters.vbill_ava_fil?.in_batch) {
  //     setCurrentAvailableVBillsData({
  //       hasMore: true,
  //       total: 2,
  //       stats: batchAvailableVBillsData.stats,
  //       items: batchAvailableVBillsData.items.filter((_, index) => index < 5),
  //     });
  //   } else if (batchFilters.vbill_ava_fil?.in_other_batches) {
  //     setCurrentAvailableVBillsData({
  //       hasMore: true,
  //       total: 2,
  //       stats: batchAvailableVBillsData.stats,
  //       items: batchAvailableVBillsData.items.filter((_, index) => index < 5),
  //     });
  //   } else {
  //     setCurrentAvailableVBillsData(batchAvailableVBillsData);
  //   }
  // }, [
  //   batchFilters.vbill_ava_fil?.in_batch,
  //   batchFilters.vbill_ava_fil?.in_other_batches,
  //   batchAvailableVBillsData,
  // ]);

  const handleVBillCheckboxChange = (checked: boolean, invoiceId: number) => {
    if (checked) {
      setSelectedVBillsToAdd((prev) => [...prev, invoiceId]);
    } else {
      setSelectedVBillsToAdd((prev) => [...prev.filter((id) => id !== invoiceId)]);
    }
  };

  const handleSelectAllVBillCheckboxCLick = () => {
    if (billsHeaderSelectAllCheckboxChecked || billsHeaderSelectAllCheckboxIndeterminate) {
      setSelectedVBillsToAdd([]);
    } else {
      setSelectedVBillsToAdd((batchAvailableVBillsData?.items ?? []).map(({ id }) => id));
    }
  };

  const handleAddSelectedVBillsToBatch = async () => {
    setIsAddBillsFetching(true);
    await onAddVBillsToBatch(selectedVBillsToAdd);
    setIsAddBillsFetching(false);
  };

  const handleAddAllVBillsToBatch = async () => {
    setIsAddAllBillsFetching(true);
    await onAddAllVBillsToBatch();
    setIsAddAllBillsFetching(false);
  };

  const [ddstats, setDDStats] = useState<{ daysBack: number; count?: number; amount?: string }[]>([]);
  const computeDueDateStats = async () => {
    const ts = new Date();
    const fetchDueDate = async (daysBack: number = 0) =>
      VBillGetBillsStats({
        filters: {
          organizationId,
          date_due_from: new Date(+ts - 1000 * 3600 * 24 * daysBack).toISOString(),
          date_due_to: ts.toISOString(),
        },
      }); //FIXME: add current filters here

    const maxDays = 30;
    let daysBack = 0;

    const x: { daysBack: number; count?: number; amount?: string }[] = [];
    while (daysBack <= maxDays) {
      const data = await fetchDueDate(daysBack).catch((e) => undefined);
      if (data) {
        x.push({ daysBack, ...data.getBillsStats.generalStats });
        setDDStats(x);
        console.log("ddstats", daysBack, maxDays, x);
      }
      daysBack++;
    }
  };
  // useEffect(() => {
  //   computeStats();
  //   // computeDueDateStats();
  // }, []); // FIXME: filters

  if (isBatchClosed) {
    return null;
  }

  const availableBillsAccordianExpanded = billsAccordianExpanded.availableBills;

  return (
    <Paper sx={{ padding: "10px" }}>
      <BatchVBillsAccordian
        expanded={availableBillsAccordianExpanded}
        onChange={() => setBillsAccordianExpanded((prev) => ({ availableBills: !prev.availableBills }))}
      >
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "10px" }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <Typography sx={{ fontSize: "18px", color: COLORS.logoBlue }}>
              Available bills{" "}
              <Box component="strong" sx={{ fontSize: "14px" }}>
                ({localBillsStats?.generalStats.count ?? 0})
              </Box>
            </Typography>
            {availableBillsAccordianExpanded && (
              <Button
                onClick={() => setAvailableFiltersVisible((prev) => !prev)}
                sx={{ ":hover": { textDecoration: "underline" }, height: "30px" }}
              >
                {availableFiltersVisible ? "Hide" : "Show"} filters
              </Button>
            )}
          </Box>

          <BatchVBillsAccordianSummary billsAccordianExpanded={availableBillsAccordianExpanded} />
        </Box>

        <BatchVBillsAccordianDetails>
          {availableFiltersVisible && (
            <>
              <VBillsAvailableFilters />

              <VBillActiveFilters
                chipSmallHeight
                showCompanies
                filters={batchFilters.vbill_ava_fil}
                onFiltersChange={(values) =>
                  vBillBatchDetailsStore.setBatchFilters((prev) => ({
                    vbill_ava_fil: Object.keys(values).length ? { ...prev.vbill_ava_fil, ...values } : undefined,
                  }))
                }
                mappingLabels={additionalMappingLabels}
              />
            </>
          )}

          {/* <div onClick={computeStats}>
        {stats?.count} bills. Total amount {stats?.amount}
        <Button>Add all</Button>
      </div> */}
          {/* <DueDateOverBudgetButton
        leftBudgetAmount={
          Number(vBillBatchDetailsStore.batch.data?.budget ?? 0) - Number(vBillBatchDetailsStore.batch.data?.invoicesAmount ?? 0)
        }
      /> */}

          {/* <Button onClick={computeDueDateStats}>Due date Overbuget</Button>

      {ddstats.map((e) => (
        <div>{JSON.stringify(e)}</div>
      ))} */}

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "15px",
              margin: "10px 0",
            }}
          >
            {selectedVBillsToAdd.length > 0 && (
              <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
                <LoadingButton
                  loading={isAddBillsFetching}
                  variant="contained"
                  startIcon={<AddCircleOutlineIcon />}
                  onClick={handleAddSelectedVBillsToBatch}
                  sx={{ height: "30px" }}
                  title={`Add ${selectedVBillsToAdd?.length} selected bill(s): ${formattedYouSelectedAmount}`}
                >
                  Add
                </LoadingButton>
                <Typography variant="body2">
                  You have selected {selectedVBillsToAdd?.length} bill(s): <strong>{formattedYouSelectedAmount}</strong>{" "}
                  on this page.
                </Typography>
              </Box>
            )}
            <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
              {!isNil(localBillsStats?.generalStats.count) && (localBillsStats?.generalStats.count ?? 0) > 0 && (
                <LoadingButton
                  loading={isAddAllBillsFetching}
                  variant="contained"
                  startIcon={<AddCircleIcon />}
                  onClick={handleAddAllVBillsToBatch}
                  sx={{ height: "30px", whiteSpace: "nowrap" }}
                >
                  Add all
                </LoadingButton>
              )}
              {!isNil(localBillsStats?.generalStats.count) && !isNil(localBillsStats?.generalStats.amount) && (
                <Typography variant="body2">
                  There are a total of {localBillsStats?.generalStats.count} bill(s) matching:{" "}
                  <strong>{formattedBillsGeneralStatsAmounts}</strong>
                </Typography>
              )}
            </Box>
          </Box>

          <Droppable droppableId={VBillDroppableArea.AVAILABLE}>
            {(provided) => (
              <TableContainer
                ref={provided.innerRef}
                {...provided.droppableProps}
                sx={{
                  position: "relative",
                  marginTop: "5px",
                }}
              >
                {showDraggingOverlay && <BatchVBillsDraggingOverlay />}
                <Table>
                  <BatchVBillsHeader
                    selectAllCheckboxChecked={billsHeaderSelectAllCheckboxChecked}
                    selectAllCheckboxIndeterminate={billsHeaderSelectAllCheckboxIndeterminate}
                    onSelectAllVBillCheckboxClick={handleSelectAllVBillCheckboxCLick}
                    sortVBillsValues={batchFilters.vbill_ava_fil}
                    showSelectAllCheckbox={showVBillsHeaderSelectAllCheckbox}
                    setSortVBillsValues={(key, value) =>
                      vBillBatchDetailsStore.setBatchFilters((prev) => ({
                        vbill_ava_fil: { ...prev.vbill_ava_fil, [key]: value },
                      }))
                    }
                  />

                  <VBillsAvailableList
                    onBatchVBillCheckboxChange={handleVBillCheckboxChange}
                    billsAccordianExpanded={billsAccordianExpanded}
                    droppableProvidedPlaceholder={provided.placeholder}
                    selectedVBillsToAdd={selectedVBillsToAdd}
                  />
                </Table>
              </TableContainer>
            )}
          </Droppable>

          <TablePagination
            component="div"
            showFirstButton
            showLastButton
            page={(batchFilters.vbill_ava_pag.page ?? 1) - 1}
            rowsPerPage={batchFilters.vbill_ava_pag.per_page ?? 10}
            count={batchAvailableVBillsData?.total ?? 10}
            rowsPerPageOptions={[10, 25, 50, 100]}
            onRowsPerPageChange={(event) =>
              vBillBatchDetailsStore.setBatchFilters({
                vbill_ava_pag: { per_page: Number(event.target.value), page: 1 },
              })
            }
            onPageChange={(_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) =>
              vBillBatchDetailsStore.setBatchFilters((prev) => ({
                vbill_ava_pag: { ...prev.vbill_ava_pag, page: newPage + 1 },
              }))
            }
          />
        </BatchVBillsAccordianDetails>
      </BatchVBillsAccordian>
    </Paper>
  );
});
