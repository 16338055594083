import AddIcon from "@mui/icons-material/Add";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CheckIcon from "@mui/icons-material/Check";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import Drawer from "@mui/material/Drawer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";

import Typography from "@mui/material/Typography";

import { Spinner } from "components/common/Spinner/Spinner";
import { useSelectedOrganizationCompanyId } from "components/pages/Settings/OrganizationCompanySelector/useSelectedOrganizationCompanyId";
import { AclEntityTypeEnum } from "generated/sdk";
import { definitelyFilter } from "generated/utils";
import _, { intersection } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useStore } from "storeContainer";
import {
  PermissionGroupWithSettings,
  PermissionTypes,
  PermissionTypesLimits,
  extractLimits,
} from "storesMobx/AclStore2";
import { COLORS } from "../../../../../../themes/default";
import styles from "./Permissions2.module.scss";
import { HtmlTooltip, Permissions2Limit } from "./Permissions2EditManage";
import { PermissionsTable } from "./PermissionsTable";
import {
  Permissions2UtilEntity,
  Permissions2UtilEntityResolver,
  Permissions2UtilUser,
  Permissions2UtilUserResolver,
} from "./Permisssion2Utils";
import UsersTabs from "./UsersTabs";
import { usePermissionGroupsByCategory } from "./usePermissionGroupsByCategory";

export const Permissions2View: React.FunctionComponent<{}> = observer(function Permissions2View() {
  const { organizationId } = useSelectedOrganizationCompanyId();
  const acl2 = useStore("AclStore2");
  const organizationStore = useStore("OrganizationStore");

  const { user_type, user_id } = useParams<{
    user_type: string;
    user_id: string;
    entity_type: string;
    entity_id: string;
  }>();
  const isUser = user_type === "user";
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingAggregated, setIsLoadingAggregated] = useState(false);
  const [aggregatedPermissions, setAggregatedPermission] = useState<
    Awaited<ReturnType<typeof acl2.getAggregatedPermissions>>
  >({});
  const user = isUser ? acl2.organizationUsersList.find((e) => e.id === user_id) : undefined;
  const group = isUser ? undefined : acl2.organizationGroupList.find((e) => e.id === user_id);

  const pgs: PermissionGroupWithSettings[] = acl2.PermissionGroups;
  // console.log("pgs", pgs);

  useEffect(() => {
    if (organizationId) {
      setIsLoading(true);
      acl2.reload().then(() => setIsLoading(false));
    }
  }, [acl2, organizationId]);

  useEffect(() => {
    if (user_id) {
      setIsLoadingAggregated(true);
      acl2
        .getAggregatedPermissions(isUser ? user_id : undefined, isUser ? undefined : user_id)
        .then(setAggregatedPermission)
        .then(() => setIsLoadingAggregated(false));
    }
  }, [acl2, isUser, user_id]);

  const hasVbill = organizationStore.organizationDefaultDashboard.data?.vbill_enabled ?? false;
  const pgsByCategory = usePermissionGroupsByCategory(pgs);
  const catOrder = intersection(["MANAGEMENT", "VCHECKS", ...(hasVbill ? ["VBILL"] : [])], Object.keys(pgsByCategory));

  if (!user && !group) return <>Invalid url (user)</>;
  if (!aggregatedPermissions) return <>Invalid url (agg)</>;

  return (
    <Box sx={{ width: "100%" }} marginTop="40px">
      <UsersTabs selectedTab={1} className={styles.userTabs} />
      <div className={styles.container}>
        <Link to={`/org/${organizationId}/users2`} style={{ textDecoration: "none", color: COLORS.logoBlue }}>
          <ArrowBackIosIcon />
        </Link>
      </div>
      <Paper>
        <Box sx={{ p: 3, pb: 6, display: "flex", justifyContent: "space-between", alignItems: "center", mt: "25px" }}>
          <Box>
            <Typography
              variant="h1"
              sx={{ fontWeight: 600, color: COLORS.newPrimaryColor, fontSize: "24px" }}
              display={"inline-flex"}
            >
              View effective permissions of&nbsp;
              <Permissions2UtilUser isUser={false} user_name={user?.name || group?.name || user_id} />
            </Typography>
            <Typography variant="h2" sx={{ fontWeight: 500, color: "#707070", marginTop: "8px" }}>
              {user?.email || group?.description}
            </Typography>
          </Box>

          <Link
            to={`/org/${organizationId}/users2/permissions/add/${isUser ? "user" : "group"}/${user_id}`}
            style={{ textDecoration: "none" }}
          >
            <Button
              // sx={{
              //   textDecoration: "none",
              // }}
              startIcon={<AddIcon />}
              color={"primary"}
              variant={"contained"}
            >
              Add Permission
            </Button>
          </Link>
        </Box>
        {isUser ? (
          <Box sx={{ p: 3, pb: 6 }}>
            <Typography variant="h3" sx={{ fontWeight: 500, color: "#707070", marginBottom: "10px" }}>
              User Groups:
            </Typography>
            {!user?.user_groups?.length ? <>no user groups</> : null}
            <Grid container spacing={2}>
              {user?.user_groups?.map((g) =>
                g.id ? (
                  <Box key={g.id}>
                    <Grid item sx={{ pt: 1 }}>
                      <Link
                        style={{ textDecoration: "none", color: COLORS.black }}
                        to={`/org/${organizationId}/users2/permissions/group/${g.id}`}
                      >
                        <Box
                          sx={{
                            p: 2,
                            backgroundColor: COLORS.tableHeaderColor,
                            color: COLORS.darkText,
                            fontWeight: 500,
                            borderRadius: "5px",
                          }}
                        >
                          <Permissions2UtilUserResolver isUser={false} user_id={g.id} />
                        </Box>
                      </Link>
                    </Grid>
                  </Box>
                ) : null,
              )}
            </Grid>
          </Box>
        ) : (
          <Box sx={{ p: 3, pb: 6 }}>
            <Typography variant="h3" sx={{ fontWeight: 500, color: "#707070" }}>
              Users:
            </Typography>
            {!group?.organization_users?.length ? <>no users</> : null}
            <Grid container spacing={2}>
              {group?.organization_users.map((u) => (
                <>
                  <Grid item sx={{ pt: 1 }}>
                    <Link
                      style={{ textDecoration: "none" }}
                      to={`/org/${organizationId}/users2/permissions/user/${u.id}`}
                    >
                      <Box
                        sx={{
                          p: 2,
                          backgroundColor: COLORS.tableHeaderColor,
                          color: COLORS.darkText,
                          fontWeight: 500,
                          borderRadius: "5px",
                        }}
                      >
                        <Permissions2UtilUserResolver isUser={true} user_id={u.id} />
                      </Box>
                    </Link>
                  </Grid>
                </>
              ))}
            </Grid>
          </Box>
        )}
        <Box sx={{ p: 3 }}>
          {isLoading || isLoadingAggregated ? (
            <Spinner withSpaceAround />
          ) : (
            catOrder.map((cat) => (
              <Box sx={{ pt: 3 }} key={cat}>
                <h2>{cat}</h2>
                <PermissionsTable
                  permissionGroups={pgs.filter((pg) => pg.category === cat)}
                  isUser={isUser}
                  user_id={user_id}
                  aggregatedPermissions={aggregatedPermissions}
                />
              </Box>
            ))
          )}
        </Box>
        {/* <pre>aggregatedPermissions={JSON.stringify(aggregatedPermissions, null, 4)}</pre> */}
        {/* <pre>acl2.PermissionGroups={JSON.stringify(acl2.PermissionGroups, null, 4)}</pre> */}
        {/* <pre>entity_types={JSON.stringify(entity_types, null, 4)}</pre> */}
      </Paper>
    </Box>
  );
});
const drawerWidth = 820;

export const Permissions2PermissionGroupName: React.FunctionComponent<{ permGroup: PermissionGroupWithSettings }> = ({
  permGroup,
}) => {
  return (
    <Box sx={{ mr: 1 }}>
      <HtmlTooltip
        title={
          <React.Fragment>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 1, p: 1 }}>
              {permGroup.permission_types?.map((pt) => (
                <Typography key={pt.id} variant="body2" sx={{ fontWeight: 500, color: "#707070" }}>
                  {pt?.description}
                </Typography>
              ))}
            </Box>
          </React.Fragment>
        }
      >
        <span style={{ cursor: "help" }}>{permGroup.group_name}</span>
        {/* <InfoIcon fontSize={"small"} sx={{ color: "#a0a0a0", cursor: "help" }} /> */}
      </HtmlTooltip>
      {/*<span style={{ cursor: "help" }} className={styles.groupName}>{permGroup.group_name}</span>*/}

      {/*{permGroup.permission_types?.map((pt) => (*/}
      {/*    <Typography key={pt.id} variant="body2" sx={{ fontWeight: 500, color: "#727C8B", opacity:"75%" }}>*/}
      {/*      {pt?.description}*/}
      {/*    </Typography>*/}
      {/*))}*/}
    </Box>
  );
};

export const Permissions2ViewModal: React.FunctionComponent<{
  isUser: boolean;
  user_id: string;
  permissionGroup: PermissionGroupWithSettings;
  permissions: PermissionTypes[] | undefined;
  onClose: () => void;
}> = ({ permissions, permissionGroup, onClose, isUser, user_id }) => {
  const limit = permissions && permissions[0] ? extractLimits(permissions[0]) : {};
  const acl2 = useStore("AclStore2");
  const [isLoadingDirect, setIsLoadingDirect] = useState(false);
  const [isLoadingInherited, setIsLoadingInherited] = useState(false);
  const [inherited, setInherited] = useState<PermissionTypes[]>([]);
  const [direct, setDirect] = useState<PermissionTypes[]>([]);
  const { organizationId } = useSelectedOrganizationCompanyId();

  useEffect(() => {
    setDirect([]);
    setIsLoadingDirect(true);
    acl2
      .getDirectAppliedPermissions(isUser ? user_id : undefined, isUser ? undefined : user_id)
      .then((inheritedAllObj) => inheritedAllObj[permissionGroup.internal_alias] || [])
      .then((inherited) => setDirect(inherited))
      .then(() => setIsLoadingDirect(false));

    setInherited([]);
    setIsLoadingInherited(true);
    acl2
      .getInheritedPermissions(isUser ? user_id : undefined, isUser ? undefined : user_id)
      .then((inheritedAllObj) => inheritedAllObj[permissionGroup.internal_alias] || [])
      .then((inheritedAll) =>
        permissions
          ? inheritedAll.filter(
              (i) => permissions.find((p) => p.entity_id === i.entity_id && p.entity_type === i.entity_type),
              // && (isUser ? i.organization_user_id === user_id : i.user_group_id === user_id)
            )
          : [],
      )
      .then((inherited) => setInherited(inherited))
      .then(() => setIsLoadingInherited(false));
  }, [permissions, isUser, user_id, acl2, permissionGroup.internal_alias]);

  // FIXME: do a hook
  const { organizationEntitiesList } = acl2;
  const companies = (permissions || []).filter((e) => e.entity_type === AclEntityTypeEnum.Company);
  const organizations = (permissions || []).filter((e) => e.entity_type === AclEntityTypeEnum.Organization);
  const excludeIds: string[] = [];
  companies.forEach((cp) => {
    const entity_id = cp.entity_id;
    if (entity_id)
      organizationEntitiesList.filter((oe) => oe.parents.includes(entity_id)).forEach((oe) => excludeIds.push(oe.id));
  });
  organizations.forEach((org) => {
    const entity_id = org.entity_id;
    if (entity_id)
      organizationEntitiesList.filter((oe) => oe.parents.includes(entity_id)).forEach((oe) => excludeIds.push(oe.id));
  });

  const uniqEntityIds = definitelyFilter(_.uniq(permissions?.map((p) => p.entity_id).filter((e) => !!e))).filter(
    (id) => !excludeIds.includes(id),
  );

  return permissions ? (
    <Drawer
      anchor={"right"}
      open={permissions !== undefined}
      onClose={onClose}
      sx={{
        display: { xs: "none", sm: "block" },
        "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
      }}
    >
      <Box sx={{ p: 3 }}>
        <Box sx={{ pb: 2 }}>
          <Typography variant="h3" sx={{ fontWeight: 500, color: "#707070", pb: 1 }}>
            Permission Details:
          </Typography>
          <Typography variant="h1" sx={{ fontWeight: 500, color: "#707070", pb: 1 }} display="inline-flex">
            <Permissions2PermissionGroupName permGroup={permissionGroup} />
            for
            <Permissions2UtilUserResolver isUser={isUser} user_id={user_id} className={styles.drawerUserPermissions} />
            &nbsp;on
          </Typography>
        </Box>
        {/* <pre>limit={JSON.stringify(limit, null, 4)}</pre> */}
        <Box sx={{ pb: 2 }}>
          <Permissions2ViewAccountsResolver isInModal permissionGroup={permissionGroup} entities={permissions} />
          {Object.keys(limit).length ? (
            <>
              <Typography variant="h3" sx={{ mt: 3, fontWeight: 500, color: "#707070", pb: 1 }}>
                With limit
              </Typography>

              <Permissions2Limit pgName={permissionGroup.internal_alias} limits={limit} />
            </>
          ) : null}
          {/* <pre>{JSON.stringify(limit,null,4)}</pre> */}
        </Box>
        {/* <pre>inherited={JSON.stringify(inherited, null, 4)}</pre>
        <pre>direct={JSON.stringify(direct, null, 4)}</pre>
        uniqEntityIds={JSON.stringify(uniqEntityIds)} */}
        {isLoadingDirect || isLoadingInherited ? (
          <Spinner withSpaceAround />
        ) : (
          <Box sx={{ pb: 2 }}>
            <Typography variant="h1" sx={{ mt: 3, fontWeight: 500, color: "#707070", pb: 1 }}>
              Details
            </Typography>
            <TableContainer sx={{ width: "100%" }}>
              <Table>
                {uniqEntityIds.map((entity_id_group) => (
                  <>
                    <TableHead>
                      {/*<TableRow>*/}
                      {/*  <TableCell colSpan={4}>*/}
                      {/*    <Permissions2UtilEntityResolver entity_id={entity_id_group} />*/}
                      {/*  </TableCell>*/}
                      {/*</TableRow>*/}
                      <TableRow>
                        {permissionGroup.settings.hasLimits ? <TableCell>Limit</TableCell> : null}
                        <TableCell>Type</TableCell>
                        <TableCell>From</TableCell>
                        <TableCell>Applied to</TableCell>
                        {/* <TableCell>Applies to</TableCell> */}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {_.sortBy([...inherited, ...direct], ["entity_id"])
                        .filter((i) => i.entity_id === entity_id_group)
                        .map((i, ii) => {
                          const isUser = !i.user_group_id;
                          const user_id = i.user_group_id || i.organization_user_id;
                          const il = extractLimits(i);

                          const entity_id = i.orig_entity_id && i.orig_entity_type ? i.orig_entity_id : i.entity_id;
                          const entity_type =
                            i.orig_entity_id && i.orig_entity_type ? i.orig_entity_type : i.entity_type;
                          return user_id && entity_id && entity_type ? (
                            <TableRow key={ii}>
                              {permissionGroup.settings.hasLimits ? (
                                <TableCell>
                                  <Permissions2Limit pgName={permissionGroup.internal_alias} limits={il} />
                                </TableCell>
                              ) : null}
                              <TableCell>{i.acl_source}</TableCell>
                              <TableCell>
                                <Permissions2UtilUserResolver isUser={isUser} user_id={user_id} />
                              </TableCell>
                              {/* <TableCell>
                                <Link
                                  style={{ textDecoration: "none" }}
                                  to={
                                    `/org/${organizationId}/users2/permissions/` +
                                    `${isUser ? "user" : "group"}/${user_id}/` +
                                    `${i.entity_type.toLowerCase()}/${i.entity_id}`
                                  }
                                >
                                  <Permissions2UtilEntityResolver entity_type={i.entity_type} entity_id={i.entity_id} />
                                </Link>
                              </TableCell> */}
                              <TableCell>
                                <Link
                                  style={{ textDecoration: "none", color: COLORS.black }}
                                  to={
                                    `/org/${organizationId}/users2/permissions/` +
                                    `${isUser ? "user" : "group"}/${user_id}/` +
                                    `${entity_type.toLowerCase()}/${entity_id}`
                                  }
                                  title="Click to edit this permission"
                                >
                                  <Permissions2UtilEntityResolver entity_type={entity_type} entity_id={entity_id} />
                                </Link>
                              </TableCell>
                            </TableRow>
                          ) : null;
                        })}
                    </TableBody>
                  </>
                ))}
              </Table>
            </TableContainer>
            {/* <pre>direct={JSON.stringify(direct, null, 4)}</pre> */}
            {/* <pre>inherited={JSON.stringify(inherited, null, 4)}</pre> */}
            {/* <pre>uniqEntityIds={JSON.stringify(uniqEntityIds, null, 4)}</pre> */}
            {/* <pre>selectedPermissions={JSON.stringify(permissions, null, 4)}</pre> */}
          </Box>
        )}
        {/* <Box sx={{ pb: 2 }}>
          {permissions.map((p) =>
            p.entity_id && p.entity_type ? (
              <Permissions2UtilEntityResolver entity_id={p.entity_id} entity_type={p.entity_type} />
            ) : null
          )}
        </Box> */}
        {/* <pre>permissions={JSON.stringify(permissions, null, 4)}</pre> */}
      </Box>
    </Drawer>
  ) : null;
};

export const Permissions2ViewLimitsAndAccounts: React.FunctionComponent<{
  isUser: boolean;
  user_id: string;
  permissionGroup: PermissionGroupWithSettings;
  permissions: PermissionTypes[];
}> = ({ permissions, permissionGroup, isUser, user_id }) => {
  const permissionsLimits: PermissionTypesLimits[] = [];
  const permissionsLimitsGrouped: PermissionTypes[][] = [];
  const [selectedPermissionsIdx, setSelectedPermissionsIdx] = useState(-1);
  permissions.forEach((p) => {
    const pl = extractLimits(p);
    if (!_.find(permissionsLimits, (o) => _.isEqual(o, pl))) permissionsLimits.push(pl);
  });

  permissionsLimits.forEach((pl, pli) => {
    permissionsLimitsGrouped[pli] = permissions.filter((p) => _.isEqual(extractLimits(p), pl));
  });

  return (
    <>
      {/* <pre>permissionsLimits={JSON.stringify(permissionsLimits, null, 4)}</pre> */}
      {/* <pre>permissionsLimitsGrouped={JSON.stringify(permissionsLimitsGrouped, null, 4)}</pre> */}
      <Permissions2ViewModal
        onClose={() => setSelectedPermissionsIdx(-1)}
        permissions={permissionsLimitsGrouped[selectedPermissionsIdx]}
        permissionGroup={permissionGroup}
        isUser={isUser}
        user_id={user_id}
      />

      <TableContainer sx={{ width: "100%" }} title="Click to view why you have this permission">
        <Table>
          <TableBody>
            {permissionsLimits.map((pl, pli) => {
              // const e = entity_types_by_entity_type[p.entity_type];
              const permListWithThisLimit: PermissionTypes[] = permissionsLimitsGrouped[pli];
              const p = permListWithThisLimit[0];
              if (!p) return null;
              // if (p.entity_type && permissionGroup.appliesTo.indexOf(p.entity_type) === -1) return null;
              const sx: any = { verticalAlign: "top" };
              if (pli === permissionsLimits.length - 1) sx.borderBottom = "none";
              return (
                <TableRow
                  key={pli}
                  onClick={() => setSelectedPermissionsIdx(pli)}
                  sx={{
                    cursor: "pointer",
                    backgroundColor: pli === selectedPermissionsIdx ? "#f0f0f0" : undefined,
                  }}
                >
                  <TableCell sx={{ verticalAlign: "middle !important", width: "150px" }}>
                    <CheckIcon />
                  </TableCell>
                  <TableCell sx={{ ...sx, width: "500px" }}>
                    {permissionGroup.settings.hasLimits ? (
                      <>
                        <Permissions2Limit pgName={permissionGroup.internal_alias} limits={pl} outlined={true} />
                        {/* <pre>{JSON.stringify(p, null, 4)}</pre> */}
                      </>
                    ) : null}
                  </TableCell>
                  <TableCell align="left" sx={{ ...sx }}>
                    <Permissions2ViewAccountsResolver
                      permissionGroup={permissionGroup}
                      entities={permListWithThisLimit}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export const Permissions2ViewAccountsResolver: React.FunctionComponent<{
  permissionGroup: PermissionGroupWithSettings;
  entities: PermissionTypes[];
  isInModal?: true;
}> = observer(function Permissions2ViewAccountsResolver({ permissionGroup, entities, isInModal }) {
  const acl2 = useStore("AclStore2");

  const { organizationEntitiesList } = acl2;

  if (entities.find((e) => e.entity_type === AclEntityTypeEnum.Organization)) {
    if (permissionGroup.appliesTo.includes(AclEntityTypeEnum.Organization)) return null;
    if (permissionGroup.appliesTo.includes(AclEntityTypeEnum.Company))
      return <Permissions2UtilEntity entity_name={"All companies"} />;
    return <Permissions2UtilEntity entity_name={"All accounts"} />;
  }

  const companies = entities.filter((e) => e.entity_type === AclEntityTypeEnum.Company);
  // const backAccounts = entities.filter((e) => e.entity_type === AclEntityTypeEnum.BankAccount);
  const excludeIds: string[] = [];
  companies.forEach((cp) => {
    const entity_id = cp.entity_id;
    if (entity_id)
      organizationEntitiesList.filter((oe) => oe.parents.includes(entity_id)).forEach((oe) => excludeIds.push(oe.id));
  });
  const entitiesToBeRendered = entities
    .map((p) => {
      if (p.entity_id && excludeIds.includes(p.entity_id)) return null;
      const entity = organizationEntitiesList.find((oe) => oe.id === p.entity_id && oe.type === p.entity_type);
      return entity ? (
        <div key={entity.id}>
          <Permissions2UtilEntity entity_type={entity?.type} entity_name={entity?.name} />
        </div>
      ) : null;
    })
    .filter((el) => el != null);
  return (
    <>
      {entitiesToBeRendered.length > 4 && !isInModal
        ? [
            ...entitiesToBeRendered.slice(0, 4),
            <Box sx={{ textAlign: "center" }}>
              <Button sx={{ fontWeight: 200 }}>... {entitiesToBeRendered.length - 4} more</Button>
            </Box>,
          ]
        : entitiesToBeRendered}
    </>
  );
});
