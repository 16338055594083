import { Box, Button, Tooltip } from "@mui/material";
import { formatDistanceStrict } from "date-fns";
import { observer } from "mobx-react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useStore } from "storeContainer";
import { COLORS } from "themes/default";
import { blackBtnStyles } from "../summariesStyles";
import { SummariesRecurringBatchPopover } from "./SummariesRecurringBatchPopover/SummariesRecurringBatchPopover";

export const SummariesRecurringBatch = observer(function SummariesRecurringBatch() {
  const vBillBatchDetailsStore = useStore("VBillBatchDetailsStore");
  const { batchId, organizationId } = useParams<{ batchId: string; organizationId: string }>();
  const [popoverAnchorEl, setPopoverAnchorEl] = useState<null | HTMLElement>(null);

  const setPopoverVisible = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
    setPopoverAnchorEl(event.currentTarget);

  const { batchDetails, DEMO_batch_recurringDate } = vBillBatchDetailsStore;
  const batchData = batchDetails.data;

  if (!batchDetails.data?.settings?.BATCH_SUBBATCHES_ALLOWED) {
    return null;
  }

  const popoverVisible = Boolean(popoverAnchorEl);
  const popoverId = popoverVisible ? "batchScheduleRecurring-id" : undefined;

  return (
    <>
      <Tooltip title="Schedule next batch" arrow>
        <Button onClick={setPopoverVisible} sx={blackBtnStyles}>
          <Box sx={{ color: COLORS.logoBlue }}>Next batch:</Box>
          <strong>
            {DEMO_batch_recurringDate.value
              ? formatDistanceStrict(DEMO_batch_recurringDate.value, new Date(), {
                  unit: "day",
                  roundingMethod: "ceil",
                })
              : "-"}
          </strong>
        </Button>
      </Tooltip>

      {popoverVisible && (
        <SummariesRecurringBatchPopover
          popoverAnchorEl={popoverAnchorEl}
          popoverId={popoverId}
          onClosePopover={() => setPopoverAnchorEl(null)}
        />
      )}
    </>
  );
});
