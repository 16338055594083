// useSharedCompanyBankAccountFilters.ts
import { CheckFiltersType } from "generated/sdk";
import { uniq } from "lodash";
import { observer } from "mobx-react-lite";
import { ReactNode, createContext, useContext, useMemo, useState } from "react";
import { useStore } from "storeContainer";
import { JsonParam, useQueryParam } from "use-query-params";

interface ExtendedVCheckChecksFilterInput extends CheckFiltersType {
  [key: string]: any;
}
interface CompanyBankAccountFiltersState {
  counts: { parents: number; children: number };
  setCounts: React.Dispatch<React.SetStateAction<{ parents: number; children: number }>>;
  label: string;
  hasCompaniesSelected: boolean;
  allCompaniesBankAccountsAreSelected: boolean;
  currentBankAccounts: string[];
}

const CompanyBankAccountFiltersContext = createContext<CompanyBankAccountFiltersState | null>(null);

export const CompanyBankAccountFiltersProvider = observer(function CompanyBankAccountFiltersProvider({
  children,
  filters,
}: {
  children: ReactNode;
  filters: ExtendedVCheckChecksFilterInput;
}) {
  const userSettingsStore = useStore("UserSettingsStore");
  const OrganizationStore = useStore("OrganizationStore");
  const { organizationCompaniesWithBankaccounts } = OrganizationStore;
  const [counts, setCounts] = useState<{ parents: number; children: number }>({ parents: 0, children: 0 });
  const label = useMemo(() => {
    if (!filters?.bank_accounts || (counts.parents === 0 && counts.children === 0)) {
      return "All";
    }

    let companyLabel = "";
    if (counts.parents > 0) {
      companyLabel = `${counts.parents} ${counts.parents === 1 ? "Company" : "Companies"}`;
    }

    let accountLabel = "";
    if (counts.children > 0) {
      accountLabel = `${counts.children} ${counts.children === 1 ? "Bank Account" : "Bank Accounts"}`;
    }

    return `${accountLabel} from ${companyLabel}`;
  }, [counts.children, counts.parents, filters?.bank_accounts]);

  const companiesSelectedBankAccounts = useMemo(
    () =>
      (userSettingsStore.companiesSelectedIds ?? []).reduce<string[]>((result, companiesSelectedId) => {
        const companyWithBankAccount = organizationCompaniesWithBankaccounts.data?.find(
          ({ id }) => id === companiesSelectedId,
        );

        result = [...result, ...(companyWithBankAccount?.bank_accounts?.map(({ id }) => id) ?? [])];

        return result;
      }, []),
    [organizationCompaniesWithBankaccounts.data, userSettingsStore.companiesSelectedIds],
  );
  const currentBankAccounts: string[] = useMemo(() => {
    //TODO figure out why replacing this with .bank_accounts breaks the active filters
    return uniq([...companiesSelectedBankAccounts, ...(filters?.bankAccounts ?? [])]);
  }, [companiesSelectedBankAccounts, filters?.bankAccounts]);

  const hasCompaniesSelected = userSettingsStore.companiesSelectedIds.length > 0;
  const allCompaniesBankAccountsAreSelected = currentBankAccounts?.length === filters?.bank_accounts?.length;

  return (
    <CompanyBankAccountFiltersContext.Provider
      value={{
        counts,
        setCounts,
        label,
        hasCompaniesSelected,
        allCompaniesBankAccountsAreSelected,
        currentBankAccounts,
      }}
    >
      {children}
    </CompanyBankAccountFiltersContext.Provider>
  );
});

export const useSharedCompanyBankAccountFilters = () => {
  const context = useContext(CompanyBankAccountFiltersContext);
  if (!context) {
    throw new Error("useSharedCompanyBankAccountFilters must be used within a CompanyBankAccountFiltersProvider");
  }
  return context;
};
