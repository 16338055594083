import { useParams } from "react-router";
import { useStore } from "storeContainer";

export const useSelectedOrganizationCompanyId = () => {
  const { organizationId, companyId } = useParams<{ organizationId?: string; companyId?: string }>();
  const ctxStore = useStore("ContextStore");

  /*
   * note: running these outside of useEffect is a feature, not a bug.
   * running them within useEffect would mean the component calling "us" potentially renders without the context
   * organization or company set, causing temporary "access restricted" errors here and there.
   */

  ctxStore.setSelectedOrganizationId(organizationId);
  ctxStore.setSelectedCompanyId(companyId);

  return { organizationId, companyId };
};
