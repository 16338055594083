import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/material";
import { useCurrentVBillRoute } from "components/pages/VBill/hooks";
import {
  friendlyIntegrationStatusNames,
  friendlyInvoiceIncomingStatusNames,
  friendlyInvoicePushStatusNames,
} from "components/pages/common/VBill/utils";
import { IVBillInvoiceIncomingStatus, IVBillInvoicePushStatus } from "generated/sdk.vbill";
import { observer } from "mobx-react";
import { useMemo, useState } from "react";
import { useStore } from "storeContainer";
import { COLORS } from "themes/default";
import { ToolbarStatusBadge } from "./ToolbarStatusBadge/ToolbarStatusBadge";

export const VBillToolbarStatusDetails = observer(() => {
  const vBillStore = useStore("VBillStore");
  const { isIncomingRoute, isSummaryRoute } = useCurrentVBillRoute();
  const [isReSyncReqLoading, setIsReSyncReqLoading] = useState(false);
  const { invoice } = vBillStore;
  const invoiceData = useMemo(() => invoice.data, [invoice.data]);
  const invoiceStatusName = useMemo(
    () => (invoiceData?.status ? friendlyInvoiceIncomingStatusNames[invoiceData.status] : null),
    [invoiceData?.status],
  );
  const invoiceSyncTooltipText = useMemo(
    () => (invoiceData?.pushStatus ? friendlyInvoicePushStatusNames[invoiceData.pushStatus] : null),
    [invoiceData?.pushStatus],
  );

  const handleReSyncBtnClick = async () => {
    setIsReSyncReqLoading(true);
    await vBillStore.pushInvoice({ invoiceId: invoiceData?.id ?? 0 });
    setIsReSyncReqLoading(false);
  };

  return (
    <>
      <ToolbarStatusBadge
        color={
          invoiceData?.status === IVBillInvoiceIncomingStatus.Declined ||
          invoiceData?.status === IVBillInvoiceIncomingStatus.Deleted ||
          invoiceData?.status === IVBillInvoiceIncomingStatus.Duplicate ||
          invoiceData?.status === IVBillInvoiceIncomingStatus.PendingApproval
            ? "orange"
            : "blue"
        }
      >
        {invoiceData?.status !== IVBillInvoiceIncomingStatus.Approved ? invoiceStatusName : invoiceSyncTooltipText}
      </ToolbarStatusBadge>

      {isSummaryRoute && invoiceData?.pushStatus === IVBillInvoicePushStatus.Error && (
        <LoadingButton
          loading={isReSyncReqLoading}
          onClick={handleReSyncBtnClick}
          variant="outlined"
          sx={{
            height: "30px",
            border: "1px solid rgba(40, 119, 236, 0.25) !important",
            color: COLORS.newSecondaryColor,
            fontWeight: "500",
          }}
        >
          Re sync
        </LoadingButton>
      )}

      {isSummaryRoute && invoiceData?.pushStatus && invoiceData.integrationStatus && (
        <Box>
          Status:{" "}
          <strong>
            {invoiceData.pushStatus === IVBillInvoicePushStatus.Done
              ? friendlyIntegrationStatusNames[invoiceData.integrationStatus]
              : invoiceData.pushStatus}
          </strong>
        </Box>
      )}
    </>
  );
});
