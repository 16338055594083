import Box from "@mui/system/Box";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo } from "react";
import { matchRoutes, renderRoutes, RouteConfig } from "react-router-config";
import { useLocation } from "react-router-dom";
import { usePrevious } from "react-use";
import {
  VBillBatchLedgerPath,
  VBillCodingLedgerBasePath,
  VBillLeadingPath,
  VBillPayLedgerPath,
  VBillPaymentsLedgerPath,
} from "routes/routes";
import { useStore } from "../../storeContainer";
import Header from "./Header";
import styles from "./Layout.module.scss";

export enum HeaderTypes {
  ORGANIZATION,
  COMPANY_SETTINGS,
  ORGANIZATION_SETTINGS,
  NO_ORGANIZATION_HEADER,
  UNDEPOSITED,
  IMPORT_ERRORS,
  PROFILE,
}

const RoutesContent = React.memo(({ routes }: { routes: RouteConfig[] }) => {
  return renderRoutes(routes);
});

export const Layout: React.FunctionComponent<any> = observer(function Layout({ route }) {
  const { selectedCompanyId } = useStore("ContextStore");
  const { session } = useStore("SessionStore");

  const accountId = session?.data?.account?.id;
  const previousAccountId = usePrevious(accountId);

  const location = useLocation();

  // Memoize the matching of routes to minimize recomputation
  const { hasRoutes, hasOrganizationHeader, removeOrganizationHeader } = useMemo(
    () => ({
      hasRoutes: !!matchRoutes(route.routes, location.pathname).length,
      hasOrganizationHeader: !!matchRoutes([{ path: "/org/:organizationId/settings" }], location.pathname).length,
      removeOrganizationHeader: !!matchRoutes([{ path: "/importErrors" }], location.pathname).length,
    }),
    [route.routes, location.pathname],
  );

  // Compute the header type based on the current route
  const headerType = useMemo(() => {
    if (!hasRoutes || removeOrganizationHeader) return HeaderTypes.NO_ORGANIZATION_HEADER;

    if (!selectedCompanyId) {
      return hasOrganizationHeader ? HeaderTypes.ORGANIZATION_SETTINGS : HeaderTypes.ORGANIZATION;
    }

    return HeaderTypes.COMPANY_SETTINGS;
  }, [hasRoutes, removeOrganizationHeader, hasOrganizationHeader, selectedCompanyId]);

  // Compute whether to hide the header based on the current route
  const hideHeader = useMemo(
    () =>
      ["/account", "/verifications", "/notifications", "/billpay", "/create/regular", "/create/deposit-only"].some(
        (path) => location.pathname.includes(path),
      ),
    [location.pathname],
  );

  // Calculate classNames once using useMemo
  const containerClassNames = useMemo(
    () =>
      classNames(styles.container, {
        [styles.containerAlt]: [
          "/org/:organizationId/vchecks",
          "/depositAccounts/ledger",
          VBillCodingLedgerBasePath,
          VBillPayLedgerPath,
          VBillBatchLedgerPath,
          VBillPaymentsLedgerPath,
          "/undepositedChecks",
        ].some((path) => matchRoutes([{ path }], location.pathname).length),
        [styles.containerNoPad]: !!matchRoutes([{ path: VBillLeadingPath }], location.pathname).length,
      }),
    [location.pathname],
  );

  useEffect(() => {
    if (accountId && accountId !== previousAccountId) {
      Object.keys(localStorage)
        .filter((x) => x.startsWith("check") || x.startsWith("batch"))
        .forEach((x) => localStorage.removeItem(x));
    }
  }, [accountId, previousAccountId]);

  return (
    <>
      {!hideHeader && <Header headerType={headerType} />}
      {hasRoutes && (
        <Box className={containerClassNames} id="scrollToTop">
          <RoutesContent routes={route.routes as RouteConfig[]} />
        </Box>
      )}
    </>
  );
});
