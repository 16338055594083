import { graphqlVBillClient } from "common/graphqlClient";
import { IVBillMultiSelectAutocompleteSuggestion } from "components/pages/common/VBill/VBillSuggestionsAutocomplete/VBillSuggestionsMultiSelectAutocomplete/VBillSuggestionsMultiSelectAutocomplete";
import { VBillSuggestionsMultiSelectAutocompleteComp } from "components/pages/common/VBill/VBillSuggestionsAutocomplete/VBillSuggestionsMultiSelectAutocomplete/VBillSuggestionsMultiSelectAutocompleteComp";
import { IVBillVBillGetBatchListQuery } from "generated/graphql.vbill";
import { getSdk } from "generated/sdk.vbill";
import { pick, sortBy } from "lodash";
import { useMemo, useState } from "react";
import { useHistory, useParams } from "react-router";

const { VBillUpdateBatch } = getSdk(graphqlVBillClient);

interface IMoveBatchListProps {
  onCloseDialog: () => void;
  ledgerList: NonNullable<IVBillVBillGetBatchListQuery["getBatchList"]["data"]>["items"];
  batch: NonNullable<IVBillVBillGetBatchListQuery["getBatchList"]["data"]>["items"][number];
}

export const MoveBatchList = ({ onCloseDialog, ledgerList, batch }: IMoveBatchListProps) => {
  const { organizationId } = useParams<{ organizationId: string }>();
  const history = useHistory();

  const allBatches = useMemo(
    () => ledgerList.filter(({ id }) => id !== batch.id).map(({ id, name }) => ({ id: `${id}`, name })),
    [batch.id, ledgerList],
  );

  const [selectedBatch, setSelectedBatch] = useState<IVBillMultiSelectAutocompleteSuggestion>();

  const autocompleteOptions = useMemo(
    () =>
      sortBy(allBatches, (batch) => batch.name).map((batch) =>
        batch.id === selectedBatch?.id ? { ...batch, selected: true } : batch,
      ),
    [allBatches, selectedBatch?.id],
  );

  const handleAutocompleteOptionChange = (option: IVBillMultiSelectAutocompleteSuggestion, value: boolean) => {
    setSelectedBatch(() => (value ? option : undefined));
  };

  const handleSaveDialog = async () => {
    await VBillUpdateBatch({
      batchId: batch.id,
      batchData: {
        ...pick(batch, ["batchDateDue", "batchFilters", "budget", "name", "organizationId"]),
        parentBatchId: Number(selectedBatch?.id ?? 0),
      },
    });

    onCloseDialog();

    history.push(`/org/${organizationId}/vbillbatch/${selectedBatch?.id ?? 0}`);
  };

  return (
    <VBillSuggestionsMultiSelectAutocompleteComp
      suggestionsComponent="dialog"
      title="Set Batch parent"
      suggestions={autocompleteOptions}
      onSuggestionChange={handleAutocompleteOptionChange}
      onClose={onCloseDialog}
      onSave={handleSaveDialog}
      searchPlaceholder="Search batches"
      actionBtnsSmallHeight
    />
  );
};
