import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import Card from "@mui/material/Card";
import { COLORS } from "../../themes/default";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useHistory } from "react-router";
import { useEffect } from "react";

export const AccessRestrictedComponent: React.FunctionComponent = () => {
  let history = useHistory();
  const goToPayments = () => {
    history.replace("/");
  };

  useEffect(() => {
    window.reportError({ error: "Access restricted" });
    const timer = setTimeout(() => {
      goToPayments();
    }, 10000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Box>
      <Card variant={"outlined"} sx={{ p: 4 }}>
        <Box>
          <Typography
            color={COLORS.orange}
            variant={"h5"}
            sx={{ fontSize: 19, alignContent: "center", alignItems: "center", display: "inline-flex", gap: 1 }}
          >
            <WarningAmberRoundedIcon sx={{ fontSize: 26 }} /> Access Restricted
          </Typography>
          <Typography sx={{ mt: 2 }} variant={"subtitle2"}>
            You do not have access to view this page.
          </Typography>
          <Typography sx={{ mt: 2 }} variant={"body1"}>
            The account admin has not granted you necessary permissions to view this page.
          </Typography>
          <Typography sx={{ mt: 2 }} variant={"body1"}>
            Please contact the admin to request access.
          </Typography>
          <Button
            onClick={goToPayments}
            sx={{ mt: 4, textTransform: "capitalize" }}
            color={"secondary"}
            endIcon={<NavigateNextIcon />}
            variant={"outlined"}
          >
            Back to Payments
          </Button>
        </Box>
      </Card>
    </Box>
  );
};
