import { VBillActiveFilters } from "components/pages/common/VBill/VBillActiveFilters/VBillActiveFilters";
import { observer } from "mobx-react-lite";
import { useStore } from "storeContainer";
import { IVBillIPaymentSearchFilterContext } from "../../../../generated/sdk.vbill";
import { usePaymentsLedgerFilters } from "../hooks";

export const PaymentsActiveFilters = observer(function PaymentsActiveFilters() {
  const [paymenstLedgerFilters, setPayLedgerFilters] = usePaymentsLedgerFilters();
  const vBillPaymentsLedgerStore = useStore("VBillPaymentsLedgerStore");

  const additionalMappingsSettingsData = vBillPaymentsLedgerStore.companiesSettings.data?.invoice;
  const additionalMappingLabels = (additionalMappingsSettingsData ?? []).map(({ key, label }) => ({
    key,
    label: label ?? "",
  }));

  return (
    <VBillActiveFilters
      filters={{
        search: paymenstLedgerFilters.filters?.search?.term,
        amount_from: paymenstLedgerFilters.filters?.amountFrom,
        amount_to: paymenstLedgerFilters.filters?.amountTo,
        paymentStatus: paymenstLedgerFilters.filters?.paymentStatus,
        // companyIds: paymenstLedgerFilters.filters?.companyIds,
        // date: paymenstLedgerFilters.filters?.date,
        // date_to: paymenstLedgerFilters.filters?.date_to,
        date_due_from: paymenstLedgerFilters.filters?.dateFrom,
        date_due_to: paymenstLedgerFilters.filters?.dateTo,
        mappings: paymenstLedgerFilters.filters?.mappings,
        // integrationStatus: paymenstLedgerFilters.filters?.integrationStatus,
      }}
      mappingLabels={additionalMappingLabels}
      onFiltersChange={(values) =>
        setPayLedgerFilters((prev) => ({
          filters: Object.keys(values).length
            ? {
                // ...prev.filters,
                search: { term: values.search ?? "", context: IVBillIPaymentSearchFilterContext.Payment },
                amountFrom: values.amount_from,
                amountTo: values.amount_to,
                paymentStatus: values.paymentStatus,
                // companyIds: values.companyIds,
                // date: values.date,
                // date_to: values.date_to,
                dateFrom: values.date_due_from,
                dateTo: values.date_due_to,
                mappings: values.mappings,
                // integrationStatus: values.integrationStatus,
              }
            : undefined,
          pagination: { page: 1, per_page: prev.pagination.per_page },
        }))
      }
      sx={{ margin: "0 0 10px" }}
    />
  );
});
