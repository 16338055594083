import { IVBillInvoiceIntegrationStatus } from "generated/sdk.vbill";
import { vBillPayLedgerChildRoute } from "./hooks";

export enum VBillPayLedgerChildRoutes {
  ALL = "",
  DUE_TO_PAY = "due-to-pay",
  OVER_DUE = "over-due",
  PENDING = "pending",
  PAID = "paid",
  // VOIDED = "voided",
}

export function getVBillPayLedgerIntStatusByChildRoute(childRoute: vBillPayLedgerChildRoute) {
  const {
    isAllChildRoute,
    isDueToPayChildRoute,
    isOverDueChildRoute,
    isPendingChildRoute,
    isPaidChildRoute,
    // isVoidedChildRoute,
  } = childRoute;

  if (isAllChildRoute || isDueToPayChildRoute || isOverDueChildRoute) {
    return undefined;
  }

  if (isPendingChildRoute) {
    return IVBillInvoiceIntegrationStatus.InvoicePaymentPending;
  }

  if (isPaidChildRoute) {
    return IVBillInvoiceIntegrationStatus.InvoicePaid;
  }

  // if (isVoidedChildRoute) {
  //   return IVBillInvoiceIntegrationStatus.InvoiceVoided;
  // }
}
