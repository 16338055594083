import { Box, Button, FormHelperText, IconButton, Tooltip, Typography } from "@mui/material";
import { CloseIcon } from "components/common/icons";
import { VBillMappingAutocompleteSelector } from "components/pages/VBill/common/Forms/VBillMappingAutocompleteSelector/VBillMappingAutocompleteSelector";
import { TVBillFormAutoComOption } from "components/pages/VBill/types";
import { IVBillSingleSelectAutocompleteSuggestion } from "components/pages/common/VBill/VBillSuggestionsAutocomplete/VBillSuggestionsSingleSelectAutocomplete/VBillSuggestionsSingleSelectAutocomplete";
import { IVBillIntegrationSettingsVBillAdditionalMappingsEntryOutput } from "generated/sdk.vbill";
import { useState } from "react";
import { useController } from "react-hook-form";
import { COLORS } from "themes/default";

interface ILineItemMappingFieldSelectorProps {
  invoiceCoreOrgId: string;
  invoiceCompanyId: string;
  formFieldName: string;
  additionalMappings: IVBillIntegrationSettingsVBillAdditionalMappingsEntryOutput;
}

export const LineItemMappingFieldSelector = ({
  invoiceCoreOrgId,
  invoiceCompanyId,
  formFieldName,
  additionalMappings,
  additionalMappings: {
    label,
    storeDataType, // also as form field key name
    key,
    required,
  },
}: ILineItemMappingFieldSelectorProps) => {
  const {
    field: { value: formFieldValue = null, onChange: formFieldOnChange },
    fieldState: { error },
  } = useController({
    name: formFieldName,
    rules: { required: required ?? false },
  });
  const [isAutocompleteDialogOpen, setIsAutocompleteDialogOpen] = useState(false);

  const handleClearBtnClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    formFieldOnChange(null);
  };

  const handleAutocompleteSelect = async (option: IVBillSingleSelectAutocompleteSuggestion) => {
    setIsAutocompleteDialogOpen(false);

    const currentValue: TVBillFormAutoComOption = {
      label: option?.name ?? "",
      label2: option.label,
      addMappingSettingKey: key,
      key: option?.id ?? "",
    };

    formFieldOnChange(currentValue);
  };

  return (
    <Box sx={{ marginBottom: "5px" }}>
      <Button
        onClick={() => setIsAutocompleteDialogOpen(true)}
        endIcon={
          formFieldValue && (
            <Tooltip arrow title="Clear value">
              <IconButton onClick={handleClearBtnClick} sx={{ padding: "0" }}>
                <CloseIcon />
              </IconButton>
            </Tooltip>
          )
        }
        sx={{
          padding: "0",
          height: "auto",
          borderRadius: 0,
          justifyContent: "flex-start",
          ...(error ? { borderBottom: `1px solid ${COLORS.darkRed}` } : {}),
        }}
      >
        <Typography
          noWrap
          sx={{
            fontSize: "12px",
            color: COLORS.newSecondaryColor,
          }}
        >
          {!formFieldValue && "Add"} {label ?? storeDataType}
          {formFieldValue && ":"}
          {required && (
            <Tooltip arrow title={`${label ?? storeDataType} is required`}>
              <Box component="span" sx={{ fontSize: "16px", color: COLORS.error, marginLeft: "2px" }}>
                *
              </Box>
            </Tooltip>
          )}
        </Typography>
      </Button>

      {formFieldValue && (
        <div style={{ textAlign: "left" }}>{`${formFieldValue?.label ? formFieldValue.label : ""} - ${
          formFieldValue?.label2 ? formFieldValue.label2 : ""
        }`}</div>
      )}

      {error && error?.type !== "required" && (
        <FormHelperText error sx={{ margin: 0, lineHeight: "15px" }}>
          {/* {error.type === "required" ? `${label ?? storeDataType} is required` : error.message}. */}
          {error.message}.
        </FormHelperText>
      )}

      {isAutocompleteDialogOpen && (
        <VBillMappingAutocompleteSelector
          suggestionsComponent="dialog"
          additionalMappings={additionalMappings}
          invoiceCoreOrgId={invoiceCoreOrgId}
          invoiceCompanyId={invoiceCompanyId}
          onCloseAutocomplete={() => setIsAutocompleteDialogOpen(false)}
          onAutocompleteSelect={handleAutocompleteSelect}
          autocompleteSelectedSearchTerm={formFieldValue?.label ?? ""}
        />
      )}
    </Box>
  );
};
