import React, { useMemo, useState } from "react";
import styles from "./DepositAccountsLedger.module.scss";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import { useStore } from "../../../../storeContainer";

function useDepositAccountsLedger(handleVCheck: Function) {
  return useMemo(
    () => [
      {
        Header: "Transaction #",
        accessor: "number",
        Cell: ({ row }: any) => {
          const value = row.original.number;
          const handleClick = () => {
            handleVCheck(row.original.role, row.original.id);
          };
          return (
            <div className={styles.tableRow} onClick={handleClick}>
              {row.original.role === "recipient" ? (
                <div className={styles.recipient}>
                  <ArrowDownwardIcon className={styles.arrow} />
                  {value}
                </div>
              ) : (
                <div className={styles.sender}>
                  <ArrowUpwardIcon className={styles.arrow} />
                  {value}
                </div>
              )}
            </div>
          );
        },
      },
      {
        Header: "Date",
        accessor: "date",
        Cell: ({ row }: any) => {
          const value = formatDateToDesign(row.original.date);
          return <div className={styles.tableRow}>{value}</div>;
        },
      },
      {
        Header: "Sender",
        accessor: "senderDummy",
        Cell: ({ row }: any) => {
          return (
            <div className={styles.info}>
              <div>
                {row.original.sender.bank_name} **{row.original.sender.account_number}
              </div>
              <div className={styles.bank}>{row.original.sender.name}</div>
            </div>
          );
        },
      },
      {
        Header: "Recipient",
        accessor: "recipient",
        Cell: ({ row }: any) => {
          return row.original.recipient ? (
            <div className={styles.info}>
              <div>
                {row.original.recipient.bank_name} **{row.original.recipient.account_number}
              </div>
              <div className={styles.bank}>{row.original.recipient.name}</div>
            </div>
          ) : null;
        },
      },
      {
        Header: "Amount",
        accessor: "amount",
        Cell: ({ value }: any) => {
          return <div className={styles.bold}>$ {value}</div>;
        },
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }: any) => {
          let style = styles.SIGNED;
          switch (value) {
            case "Signed":
              style = styles.SIGNED;
              break;
            case "Completed":
              style = styles.COMPLETED;
              break;
            case "Processing":
              style = styles.PROCESSING;
              break;
            case "Deposited":
              style = styles.DEPOSITED;
              break;
            case "Sent to Recipient":
              style = styles.SENT_TO_RECIPIENT;
              break;
            case "Pending Signature":
              style = styles.PENDING_SIGNATURE;
              break;
            case "Cancelled":
              style = styles.CANCELLED;
              break;
            case "Deleted":
              style = styles.DELETED;
              break;
            default:
              style = styles.COMPLETED;
          }
          return <div className={style}>{value}</div>;
        },
      },
      {
        Header: "Action",
        accessor: "action",
        disableSortBy: true,
        Cell: ({ row }: any) => {
          const vChecksStore = useStore("VChecksStore");
          const handleAction = async (action: string, checks: [string]) => {
            await vChecksStore.checkAction(action, checks);
          };
          return row.original.action ? (
            <Button
              variant={"outlined"}
              disabled={row.original.action?.disabled}
              onClick={(_e: any) => {
                handleAction(row.original.action?.action, [row.original.id]);
              }}
            >
              {row.original.action.label}
            </Button>
          ) : null;
        },
      },
      {
        Header: "",
        accessor: "vcard",
        disableSortBy: true,

        Cell: ({ row }: any) => {
          const vChecksStore = useStore("VChecksStore");
          const [anchorEl, setAnchorEl] = useState(null);
          const handleClick = (e: any) => {
            setAnchorEl(e.currentTarget);
          };
          const handleClose = () => {
            setAnchorEl(null);
          };

          const open = Boolean(anchorEl);
          const id = open ? "simple-popover" : undefined;
          const handleAction = async (action: string, checks: [string]) => {
            await vChecksStore.checkAction(action, checks);
          };
          return (
            <div className={styles.moreContainer}>
              <MoreHorizIcon onClick={handleClick} />
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "center",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "center",
                  horizontal: "center",
                }}
              >
                <div className={styles.popover}>
                  {row.original.options ? (
                    row.original.options.map(
                      (option: {
                        action: string;
                        label: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined;
                      }) => (
                        <div
                          onClick={(_e: any) => {
                            handleAction(option.action, [row.original.id]);
                            handleClose();
                          }}
                        >
                          {option.label}
                        </div>
                      ),
                    )
                  ) : (
                    <div className={styles.noAvailableOptions}>No available options</div>
                  )}
                </div>
              </Popover>
            </div>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
}

export const formatDateToDesign = (date: string) => {
  if (date) {
    const dateArr = date.split("-");
    return `${dateArr[1]}/${dateArr[2].substring(0, 2)}/${dateArr[0].substring(2, 4)}`;
  }
  return null;
};

//Date Format to YYYY-MM-DD
const formatDate = (date: Date) => {
  const dt = new Date(date);
  let month = "" + (dt.getMonth() + 1),
    day = "" + dt.getDate(),
    year = dt.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

const statusList = [
  { label: "All", value: "ALL" },
  { label: "Completed", value: "DEPOSITED" },
  { label: "Sent to recipient", value: "SIGNED" },
  { label: "Pending Signature", value: "PENDING_SIGNATURE" },
  { label: "Locked", value: "LOCKED" },
  { label: "Processing", value: "PROCESSING" },
  { label: "Deleted", value: "DELETED" },
  { label: "Post dated", value: "post-dated" },
];

const typesList = [
  { label: "Send&Deposit", value: "ALL" },
  { label: "Send", value: "deposits" },
  { label: "Deposit", value: "withdrawals" },
];

const sortList = [
  { label: "Newest", value: "desc" },
  { label: "Oldest", value: "asc" },
];

export { useDepositAccountsLedger, statusList, typesList, sortList, formatDate };
