import { Box, SxProps, Theme } from "@mui/material";
import { observer } from "mobx-react";
import { useParams } from "react-router";
import { useStore } from "storeContainer";
import { VBillAmountFilter } from "../../../common/VBill/FilterComponents/VBillAmountFilter/VBillAmountFilter";
import { VBillDateFilter } from "../../../common/VBill/FilterComponents/VBillDateFilter/VBillDateFilter";
import { VBillSearchFilter } from "../../../common/VBill/FilterComponents/VBillSearchFilter/VBillSearchFilter";
import { VBillActiveFilters } from "../../../common/VBill/VBillActiveFilters/VBillActiveFilters";

interface IBatchListFiltersProps {
  hideActiveFilters?: boolean; // TODO: no double negation
  sx?: SxProps<Theme>;
}

export const BatchListFilters = observer(function BatchListFilters({ hideActiveFilters, sx }: IBatchListFiltersProps) {
  const vBillBatchDetailsStore = useStore("VBillBatchDetailsStore");
  const { batchFilters } = vBillBatchDetailsStore;

  const { organizationId } = useParams<{ organizationId: string }>();

  return (
    <Box sx={sx}>
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: "10px", alignItems: "center", marginBottom: "5px" }}>
        <VBillSearchFilter
          term={batchFilters.batch_list_fil?.search}
          onTermChange={(term) =>
            vBillBatchDetailsStore.setBatchFilters((prev) => ({
              batch_list_fil: { organizationId, search: term?.length ? term : undefined },
              batch_list_pag: { page: 1, per_page: prev.batch_list_pag.per_page },
            }))
          }
          smallHeight
        />
        <VBillAmountFilter
          amount={{
            amount_from: batchFilters.batch_list_fil?.budget_from,
            amount_to: batchFilters.batch_list_fil?.budget_to,
          }}
          onAmountChange={({ amount_from, amount_to }) =>
            vBillBatchDetailsStore.setBatchFilters((prev) => ({
              batch_list_fil: {
                ...prev.batch_list_fil,
                budget_from: amount_from,
                budget_to: amount_to,
              },
              batch_list_pag: { page: 1, per_page: prev.batch_list_pag.per_page },
            }))
          }
          smallHeight
          actionsBtnSmallHeight
        />

        <VBillDateFilter
          date={{
            date: batchFilters.batch_list_fil?.date_from,
            date_to: batchFilters.batch_list_fil?.date_to,
          }}
          onDateChange={({ date, date_to }) =>
            vBillBatchDetailsStore.setBatchFilters((prev) => ({
              batch_list_fil: { ...prev.batch_list_fil, date, date_to },
            }))
          }
          smallHeight
          actionsBtnSmallHeight
        />
      </Box>

      {!hideActiveFilters && (
        <VBillActiveFilters
          chipSmallHeight
          filters={{
            search: batchFilters.batch_list_fil?.search,
            amount_from: `${batchFilters.batch_list_fil?.budget_from}`,
            amount_to: `${batchFilters.batch_list_fil?.budget_to}`,
            date: batchFilters.batch_list_fil?.date_from,
            date_to: batchFilters.batch_list_fil?.date_to,
          }}
          onFiltersChange={(values) =>
            vBillBatchDetailsStore.setBatchFilters((prev) => ({
              batch_list_fil: Object.keys(values).length
                ? {
                    ...prev.batch_list_fil,
                    search: values.search,
                    budget_from: values.amount_from,
                    budget_to: values.amount_to,
                    date: values.date,
                    date_to: values.date_to,
                  }
                : undefined,
              batch_list_pag: { page: 1, per_page: prev.batch_list_pag.per_page },
            }))
          }
        />
      )}
    </Box>
  );
});
