import { useEffect } from "react";
import { useFieldArray } from "react-hook-form";
import { IPayVBillsFormFields } from "../PayVBillsDialog";
import { PayVBillsGroup } from "./PayVBillsGroup/PayVBillsGroup";

interface IPayBillsGroupsProps {
  onCloseDialog: () => void;
}

export const PayBillsGroups = ({ onCloseDialog }: IPayBillsGroupsProps) => {
  const payVBillsFormGroups = useFieldArray<IPayVBillsFormFields>({ name: "groups" });

  useEffect(() => {
    if (payVBillsFormGroups.fields.length === 0) {
      onCloseDialog();
    }
  }, [onCloseDialog, payVBillsFormGroups.fields.length]);

  return (
    <>
      {payVBillsFormGroups.fields.map((group, index) => (
        <PayVBillsGroup key={group.id} groupIndex={index} onRemoveGroup={() => payVBillsFormGroups.remove(index)} />
      ))}
    </>
  );
};
