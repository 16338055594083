import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Button, Typography } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { ExtendedCheckFiltersType1, useCheckFilters } from "common/hooks/useCheckFilters";
import { LedgerFilterInputType, getFiltersConfig } from "components/Filter/types";
import { isEqual } from "lodash";
import { useMemo, useState } from "react";
import { useParams, useRouteMatch } from "react-router-dom";
import { COLORS } from "themes/default";
import { VCheckChecksAllQuickFiltersEnum } from "../types";

export interface IPaymentsActionRequiredFilterMenuOption {
  value: string[];
  label: string;
  type: string;
}

export const PaymentsActionRequiredFilter = () => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const { filters, setFilters } = useCheckFilters<ExtendedCheckFiltersType1>();
  const { selectedQuickFilter } = useParams<{ selectedQuickFilter: VCheckChecksAllQuickFiltersEnum }>();
  const { path } = useRouteMatch();
  const staticFilter = useMemo<
    | {
        keyName: string;
        options: IPaymentsActionRequiredFilterMenuOption[];
      }
    | undefined
  >(() => {
    const staticFilterConfig = getFiltersConfig(path, selectedQuickFilter);

    return (staticFilterConfig as any)?.[LedgerFilterInputType.CheckActionRequiredDropdownSelectFilter];
  }, [path, selectedQuickFilter]);

  const handleMenuItemClick = (value: string[]) => {
    if (!staticFilter) {
      return;
    }

    setFilters((prev) => ({ ...prev, [staticFilter.keyName]: value }));
    setMenuAnchorEl(null);
  };

  if (!staticFilter) {
    return null;
  }

  const menuOpen = Boolean(menuAnchorEl);
  const menuOptions = staticFilter.options;
  const selectedFilterMenuOption: string[] | undefined = filters[staticFilter.keyName];
  const menuOption = menuOptions?.find(({ value }) => isEqual(value, selectedFilterMenuOption));
  const optionCssColor = paymentsActionRequiredOptionColor(menuOption?.type);
  const optionText = menuOption?.label ?? "";

  return (
    <Box>
      <Button
        id="payments-action-required-button"
        aria-haspopup="listbox"
        aria-controls="payments-action-required-menu"
        aria-label="select action required"
        aria-expanded={menuOpen ? "true" : undefined}
        onClick={(e) => setMenuAnchorEl(e.currentTarget)}
        variant={selectedFilterMenuOption ? "containedFilters" : "outlined"}
        sx={{ maxWidth: "230px" }}
        endIcon={<ExpandMoreIcon />}
      >
        <Typography noWrap sx={{ fontSize: "12px", color: "inherit" }}>
          {selectedFilterMenuOption ? (
            <>
              <Box component="span" sx={{ color: optionCssColor }}>
                {optionText}
              </Box>
            </>
          ) : (
            <span>Required Action</span>
          )}
        </Typography>
      </Button>

      <Menu
        id="payments-action-required-menu"
        anchorEl={menuAnchorEl}
        open={menuOpen}
        onClose={() => setMenuAnchorEl(null)}
        MenuListProps={{ "aria-labelledby": "payments-action-required-button", role: "listbox" }}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        {menuOptions?.map((option) => (
          <MenuItem
            key={option.type}
            selected={isEqual(selectedFilterMenuOption, option.value)}
            onClick={() => handleMenuItemClick(option.value)}
            sx={{ color: paymentsActionRequiredOptionColor(option.type), justifyContent: "space-between", gap: "5px" }}
          >
            {option.label.split(" ").map((text) => (
              <span key={text}>{text}</span>
            ))}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export const paymentsActionRequiredOptionColor = (type?: string) =>
  type === "AWAITING_CHERRY_RESPONSE"
    ? COLORS.darkRed
    : type === "AWAITING_CUSTOMER_RESPONSE"
      ? COLORS.newSecondaryColor
      : type === "AWAITING_VENDOR_RESPONSE"
        ? COLORS.success
        : undefined;
