import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import Card from "components/common/Card";
import { CompanyMenu } from "components/layout/CompanyMenu";
import { Integration } from "generated/sdk";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { graphqlClient } from "../../../../common/graphqlClient";
import { useGetIntegrationAuthCodeMutation } from "../../../../generated/graphql";
import { useStore } from "../../../../storeContainer";
import { Spinner } from "../../../common/Spinner/Spinner";
import IntegrationCard from "./IntegrationCard";
import styles from "./Integrations.module.scss";
import { IntegrationsProps } from "./types";

interface IntegrationsModalProps {
  open: boolean;
  handleClose: () => void;
  onSubmit: () => Promise<void>;
  company: string | undefined;
  handleCompanyChange: (e: SelectChangeEvent<string>) => void;
  companies:
    | {
        id: string;
        name: string;
      }[]
    | undefined;
  integration: {
    integration_id: string;
    companyId: string | undefined;
    description: string;
  };
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleIntegrationChange: (e: SelectChangeEvent<string>) => void;
  integrationsList: any;
}

const IntegrationsModal = ({
  open,
  handleClose,
  onSubmit,
  company,
  handleCompanyChange,
  companies,
  integration,
  handleInputChange,
  handleIntegrationChange,
  integrationsList,
}: IntegrationsModalProps) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Grid container justifyContent={"space-between"}>
          <Grid item xs={10}>
            <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ marginBottom: "30px" }}>
              Add Integration
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Button variant={"outlined"} onClick={onSubmit}>
              Submit
            </Button>
          </Grid>
        </Grid>

        <FormControl fullWidth>
          <InputLabel id="company-select-label">Company</InputLabel>
          <Select
            labelId="company-select-labelId"
            id="company-select"
            value={company}
            label="Company"
            onChange={handleCompanyChange}
          >
            {companies
              ? companies.map((company) => (
                  <MenuItem key={company?.id} value={company?.id}>
                    {company?.name}
                  </MenuItem>
                ))
              : null}
          </Select>
        </FormControl>
        <FormControl fullWidth sx={{ marginTop: "30px", marginBottom: "30px" }}>
          <InputLabel id="integration-select-label">Integration</InputLabel>
          <Select
            labelId="integration-select-labelId"
            id="integration-select"
            value={integration.integration_id}
            label="Integration"
            onChange={handleIntegrationChange}
          >
            {integrationsList.data
              ? integrationsList.data?.map((item: Record<string, any>) => {
                  return (
                    <MenuItem key={item?.integration_id} value={item?.integration_id}>
                      {item?.name!}
                    </MenuItem>
                  );
                })
              : null}
          </Select>
        </FormControl>
        <TextField
          id="outlined-required"
          label="Description"
          fullWidth
          value={integration?.description || ""}
          onChange={handleInputChange}
        />
      </Box>
    </Modal>
  );
};

const style = {
  position: "absolute",
  top: "0",
  right: "0",
  width: 600,
  height: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  outline: "none",
  p: 4,
};

export const Integrations: React.FunctionComponent<IntegrationsProps> = observer(function Integrations() {
  const { companyId } = useParams<{ companyId?: string }>();

  const UiStore = useStore("UiStore");

  const integrationsStore = useStore("IntegrationsStore");
  const { selectedOrganization } = useStore("ContextStore");
  const {
    acl: { canManageIntegration },
  } = useStore("SessionStore");
  const companies = selectedOrganization?.companies?.map((c) => ({ id: c?.id, name: c?.name }));
  const { integrationsList } = integrationsStore;

  useEffect(() => {
    if (companyId) {
      integrationsStore.loadIntegrations(companyId);
      if (canManageIntegration) {
        integrationsStore.loadCompanyIntegrations(companyId);
      }
    }
  }, [canManageIntegration, companyId, integrationsStore]);

  const { mutateAsync } = useGetIntegrationAuthCodeMutation(graphqlClient);

  const [open, setOpen] = useState(false);
  const [integration, setIntegration] = useState({
    integration_id: "",
    companyId: companyId,
    description: "",
  });
  const [company, setCompany] = useState(companyId);
  const handleCompanyChange = (e: SelectChangeEvent<string>) => {
    setCompany(e.target.value);
    setIntegration({ ...integration, companyId: e.target.value });
  };
  const handleIntegrationChange = (e: SelectChangeEvent<string>) => {
    setIntegration({ ...integration, integration_id: e.target.value });
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIntegration({ ...integration, description: e.target.value });
  };
  const handleOpen = (item: Integration) => {
    UiStore.showAddIntegrationModal = item;
    //setIntegration({ ...integration, integration_id: item.integration_id });
    //setOpen(true);
  };

  const onSubmit = async () => {
    await mutateAsync({
      companyId: integration.companyId ? integration.companyId : "",
      integrationId: integration.integration_id,
      integration_settings: {
        description: integration.description,
      },
    });
  };
  const handleClose = () => setOpen(false);

  return (
    <Box display="flex" justifyContent="space-between" gap="160px">
      <CompanyMenu />

      <Box marginTop="40px" width="100%">
        <Typography variant="h1">Add New Integration</Typography>
        <Card variant="outlined" className={styles.addNewIntegrationCardContainer}>
          {integrationsList.isFetching ? (
            <Spinner withSpaceAround />
          ) : integrationsList.data ? (
            <div className={styles.container}>
              {integrationsList.data?.map((integration) => (
                <IntegrationCard
                  key={integration.integration_id}
                  id={integration.slug}
                  data-element-id={integration.slug}
                  handleOnClick={() => handleOpen(integration!)}
                  title={integration?.display_name ?? ""}
                  src={integration?.logo ?? ""}
                  className={styles.integrationCard}
                />
              ))}
            </div>
          ) : null}
        </Card>
        <IntegrationsModal
          open={open}
          onSubmit={onSubmit}
          handleClose={handleClose}
          companies={companies}
          company={company}
          handleCompanyChange={handleCompanyChange}
          integration={integration}
          handleInputChange={handleInputChange}
          handleIntegrationChange={handleIntegrationChange}
          integrationsList={integrationsList}
        />
      </Box>
    </Box>
  );
});

export default Integrations;
