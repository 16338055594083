import { Box, ListItemText, MenuItem } from "@mui/material";
import { IChatChatIoMemberChannelNotificationStatsOutput, IChatMemberNotificationType } from "generated/sdk.chat";
import { IGetChatNotificationUrlArgs } from "modules/Chat/utils/utils";
import React from "react";
import { FormattedMessage } from "react-intl";

interface INotificationsStatsNotificationProps {
  channelNotifications: IChatChatIoMemberChannelNotificationStatsOutput[];
  onChannelNotificationClick: (channel: IGetChatNotificationUrlArgs) => void;
}

export const NotificationsStatsNotification = ({
  channelNotifications,
  onChannelNotificationClick,
}: INotificationsStatsNotificationProps) => {
  const { name } = channelNotifications[0];

  return (
    <MenuItem onClick={() => onChannelNotificationClick({ ...channelNotifications[0] })}>
      <ListItemText
        disableTypography
        primary={name}
        secondary={
          <>
            {channelNotifications.map(({ type, count, channelId }, index) => (
              <Box key={`${channelId}_${index}`} sx={{ marginTop: "5px" }}>
                {type === IChatMemberNotificationType.Message && (
                  <FormattedMessage
                    defaultMessage="You have {itemCount, plural,
                      one {# new message}
                      other {# new messages}
                  }."
                    values={{ itemCount: count }}
                    id="43jwejkw2"
                  />
                )}

                {type === IChatMemberNotificationType.Mention && (
                  <FormattedMessage
                    defaultMessage="You have {itemCount, plural,
                        one {# new mention}
                        other {# new mentions}
                    }."
                    values={{ itemCount: count }}
                    id="43kwejkw2"
                  />
                )}

                {type === IChatMemberNotificationType.Reaction && (
                  <FormattedMessage
                    defaultMessage="You have {itemCount, plural,
                        one {# new reaction}
                        other {# new reactions}
                    }."
                    values={{ itemCount: count }}
                    id="43kwejkw4"
                  />
                )}
              </Box>
            ))}
          </>
        }
      />
    </MenuItem>
  );
};
