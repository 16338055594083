import { Box, Checkbox, MenuItem, Tooltip, Typography } from "@mui/material";
import { ListChildComponentProps } from "react-window";

export interface IOption {
  id: string;
  name: string;
  label?: string | null;
}

interface ICheckboxOptionProps {
  selectedIds: string[];
  options: IOption[];
  onOptionChange: (id: string, value: boolean) => void;
  searchTerm: string;
}

export const CheckboxOption = ({
  index,
  style,
  data: { options, selectedIds, searchTerm, onOptionChange },
}: ListChildComponentProps<ICheckboxOptionProps>) => {
  const { id, name, label } = options[index];
  const isSelected = Boolean(selectedIds.find((selId) => selId === id));

  return (
    <MenuItem
      value={id}
      style={style}
      sx={{
        padding: "10px 0px 10px 0px",
        "&:hover": { backgroundColor: "transparent" },
        cursor: "default",
      }}
      disableRipple
      title={name}
    >
      <Box component="label" sx={{ display: "flex", alignItems: "center", cursor: "pointer", width: "100%" }}>
        <Tooltip arrow title={`${isSelected ? "Unselect" : "Select"}`}>
          <Checkbox
            onChange={(e) => onOptionChange(id, e.target.checked)}
            checked={isSelected}
            sx={{ marginRight: "10px" }}
          />
        </Tooltip>
        <Typography sx={{ width: "230px" }} noWrap title={`${name} ${label ? `- ${label}` : ""}`}>
          {highlightMatchingText(`${name} ${label ? `- ${label}` : ""}`, searchTerm)}
        </Typography>
      </Box>
    </MenuItem>
  );
};

const highlightMatchingText = (text: string, highlightTerm: string) => {
  if (!highlightTerm?.length) {
    return text;
  }

  const regex = new RegExp(`(${highlightTerm})`, "gi");
  const parts = text.split(regex);

  return parts.map((part, i) =>
    regex.test(part) ? (
      <strong key={i} style={{ fontWeight: "bold" }}>
        {part}
      </strong>
    ) : (
      part
    ),
  );
};
