import { Box, Button, Divider, Typography } from "@mui/material";
import { sortBy } from "lodash";
import { observer } from "mobx-react";
import React, { useMemo, useState } from "react";
import { useStore } from "storeContainer";
import { COLORS } from "themes/default";
import { LightTooltip } from "../../common/LightTooltip/LightTooltip";
import { blueBtnStyles, tooltipDefaultProps, tooltipTypographyStyles, typographyAsBtnStyles } from "../summariesStyles";
import { SummariesCompaniesDialog } from "./SummariesCompaniesDialog/SummariesCompaniesDialog";

export const SummariesCompanies = observer(function SummariesCompanies() {
  const vBillBatchDetailsStore = useStore("VBillBatchDetailsStore");
  const organizationStore = useStore("OrganizationStore");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const { batchDetails } = vBillBatchDetailsStore;
  const batchData = batchDetails.data;

  const companyNames = useMemo(
    () =>
      sortBy(
        (batchData?.batchFilters?.companyIds ?? []).map(
          (compId) =>
            (organizationStore.organizationVBillEnabledCompanies.data?.items ?? []).find(
              ({ companyId }) => companyId === compId,
            )?.name,
        ),
        (name) => name,
      ),
    [batchData?.batchFilters?.companyIds, organizationStore.organizationVBillEnabledCompanies.data?.items],
  );

  const handleCompaniesSave = async (companyIds: string[]) => {
    if (!batchData) {
      return;
    }

    await vBillBatchDetailsStore.updateBatchDetails({
      batchId: batchData.id,
      batchData: {
        name: batchData.name,
        organizationId: batchData.organizationId,
        batchFilters: { ...batchData.batchFilters, companyIds },
      },
    });
    setIsDialogOpen(false);
  };

  const totalBatchCompanies = (batchData?.batchFilters?.companyIds ?? []).length;

  return (
    <>
      <LightTooltip
        open={isTooltipOpen}
        onOpen={() => setIsTooltipOpen(true)}
        onClose={() => setIsTooltipOpen(false)}
        {...tooltipDefaultProps}
        title={
          <Box>
            {companyNames.map((name) => (
              <Typography sx={tooltipTypographyStyles} key={name}>
                {name}
              </Typography>
            ))}
            {totalBatchCompanies > 0 && <Divider sx={{ margin: "5px 0" }} />}
            <Button
              onClick={() => {
                setIsTooltipOpen(false);
                setIsDialogOpen(true);
              }}
              sx={{ ...blueBtnStyles, fontSize: "12px" }}
            >
              Add/Remove
            </Button>
          </Box>
        }
      >
        <Typography sx={typographyAsBtnStyles}>
          <Box sx={{ color: COLORS.logoBlue }}>Companies:</Box> <strong>({totalBatchCompanies})</strong>
        </Typography>
      </LightTooltip>

      {isDialogOpen && (
        <SummariesCompaniesDialog
          selectedCompanies={batchData?.batchFilters?.companyIds ?? []}
          onCompaniesSave={handleCompaniesSave}
          onDialogClose={() => setIsDialogOpen(false)}
        />
      )}
    </>
  );
});
