import { Box, Divider, Paper, Skeleton } from "@mui/material";
import { IVBillBatchStatus } from "generated/sdk.vbill";
import { observer } from "mobx-react";
import React from "react";
import { useStore } from "storeContainer";
import { SummariesBatchName } from "./SummariesBatchName/SummariesBatchName";
import { SummariesBillsAmount } from "./SummariesBillsAmount/SummariesBillsAmount";
import { SummariesBudget } from "./SummariesBudget/SummariesBudget";
import { SummariesByName } from "./SummariesByName/SummariesByName";
import { SummariesCompanies } from "./SummariesCompanies/SummariesCompanies";
import { SummariesDate } from "./SummariesDate/SummariesDate";
import { SummariesDueDate } from "./SummariesDueDate/SummariesDueDate";
import { SummariesAdditionalMappings } from "./SummariesMappings/SummariesAdditionalMappings";
import { SummariesVendorsMapping } from "./SummariesMappings/SummariesVendorsMapping";
import { SummariesMembers } from "./SummariesMembers/SummariesMembers";
import { SummariesMenu } from "./SummariesMenu/SummariesMenu";
import { SummariesPayBatch } from "./SummariesPayBatch/SummariesPayBatch";
import { SummariesRecurringBatch } from "./SummariesRecurringBatch/SummariesRecurringBatch";
import { SummariesStatus } from "./SummariesStatus/SummariesStatus";
import { SummariesSubBatches } from "./SummariesSubBatches/SummariesSubBatches";

export const BatchSummaries = observer(function BatchSummaries() {
  const vBillBatchDetailsStore = useStore("VBillBatchDetailsStore");

  const { batchDetails } = vBillBatchDetailsStore;
  const batchDetailsData = batchDetails.data;

  return (
    <Paper
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        gap: "15px",
        margin: "20px 0",
        padding: "10px",
      }}
    >
      {batchDetails.isLoading ? (
        <Skeleton variant="rounded" sx={{ width: "100%", margin: "15px 0", height: "54px" }} />
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            ...(batchDetailsData?.status === IVBillBatchStatus.Closed ||
            batchDetailsData?.status === IVBillBatchStatus.Deleted
              ? { pointerEvents: "none" }
              : {}),
          }}
        >
          <SummariesStatus />

          <Divider orientation="vertical" variant="middle" flexItem />

          <SummariesBatchName />

          <Divider orientation="vertical" variant="middle" flexItem />

          <SummariesByName />

          <Divider orientation="vertical" variant="middle" flexItem />

          <SummariesDate />

          <Divider orientation="vertical" variant="middle" flexItem />

          <SummariesBudget />

          <Divider orientation="vertical" variant="middle" flexItem />

          <SummariesBillsAmount />

          <Divider orientation="vertical" variant="middle" flexItem />

          <SummariesMembers />

          <Divider orientation="vertical" variant="middle" flexItem />

          <SummariesCompanies />

          <Divider orientation="vertical" variant="middle" flexItem />

          <SummariesDueDate />

          <Divider orientation="vertical" variant="middle" flexItem />

          <SummariesVendorsMapping />

          <Divider orientation="vertical" variant="middle" flexItem />

          <SummariesAdditionalMappings />

          <SummariesSubBatches />

          {/* <Divider orientation="vertical" variant="middle" flexItem /> */}

          <SummariesRecurringBatch />
        </Box>
      )}
      <Box sx={{ display: "flex", gap: "10px" }}>
        <SummariesMenu />

        <SummariesPayBatch />
      </Box>
    </Paper>
  );
});
