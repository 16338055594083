import { graphqlClient } from "common/graphqlClient";
import { definitely } from "generated/utils";
import type {
  BankAccount,
  BankAccountAutoDepositAddress,
  DepositBankAccountsQuery,
  LinkedAddress,
} from "generated/sdk";
import { getSdk, OrganizationBasic } from "generated/sdk";
import { flow, makeAutoObservable, observable } from "mobx";
import { BankAccountLinkedAddressesQuery } from "generated/graphql";

const {
  BankAccountAvailableAutoDepositAddresses,
  CreateBankAccountAutoDepositAddress,
  CreateBankAccountLinkedAddress,
  DeleteBankAccountAutoDepositAddress,
  DeleteBankAccountLinkedAddress,
  BankAccountLinkedAddresses,
  BankAccountAutoDepositAddresses,
  DepositBankAccounts,
} = getSdk(graphqlClient);
// @TODO add helper for server response success/error handling or use directly the generated hooks for queries and mutations
class AccountsStore {
  @observable availableAddresses: BankAccountAutoDepositAddress[] = [];
  @observable companyAccounts: { [key: string]: BankAccount[] } = {};
  @observable lastUsedCompanyId: string | null = null;
  @observable error: any | null = null;
  @observable isLoaded: boolean = false;
  @observable isSyncing: boolean = false;
  @observable bankAccounts: OrganizationBasic[] = [];
  @observable depositAccounts: DepositBankAccountsQuery = {};
  constructor() {
    makeAutoObservable(this);
  }

  // getBankAccounts = async () => {
  //   this.isSyncing = true;
  //   const data = await DepositBankAccounts();

  //   this.isSyncing = false;
  //   this.isLoaded = true;
  //   if(data){
  //     this.depositAccounts = data
  //   }
  //   return data;
  // };

  getBankAccounts = flow(function* (this: AccountsStore) {
    this.isSyncing = true;
    const data = definitely(yield DepositBankAccounts());
    if (data) {
      this.depositAccounts = data;
    }
    this.isSyncing = false;
    return data;
  }).bind(this);

  getDepositAddresses = (companyId: string, accountId: string): BankAccountAutoDepositAddress[] => {
    const account = this.companyAccounts[companyId]?.find((account) => account.id === accountId);
    // @TODO fix the type cast
    return (account?.auto_deposit_addresses as BankAccountAutoDepositAddress[]) || [];
  };
  getLinkedAddresses = (companyId: string, accountId: string): LinkedAddress[] => {
    const account = this.companyAccounts[companyId]?.find((account) => account.id === accountId);
    // @TODO fix the type cast
    return (account?.linked_addresses as LinkedAddress[]) || [];
    //return([BankAccountLinkedAddresses({bank_account_id:accountId})])
  };
  getLinkedEmailAddresses = async (bank_account_id: string): Promise<BankAccountLinkedAddressesQuery> => {
    return await BankAccountLinkedAddresses({ bank_account_id: bank_account_id });
  };
  getAutoDepositAvailableAddresses = async (bankAccountId: string) => {
    return await BankAccountAvailableAutoDepositAddresses({ bankAccountId });
  };
  getAutoDepositAddresses = async (bank_account_id: string) => {
    return await BankAccountAutoDepositAddresses({ bank_account_id });
  };
  // eslint-disable-next-line require-yield
  loadAccounts = flow(function* (this: AccountsStore, companyId) {
    if (!companyId) {
      return;
    }
    this.lastUsedCompanyId = companyId;
    this.isSyncing = true;
    // ZZZZ
    // const data =  definitely((yield BankAccounts({ companyId })).BankAccounts);
    // if (data) {
    //   // @TODO fix the types
    //   this.companyAccounts[companyId] = data as BankAccount[];
    // }
    this.isSyncing = false;
  }).bind(this);

  loadAvailableAddresses = flow(function* (this: AccountsStore, accountId: string) {
    this.isSyncing = true;
    const data = definitely(
      (yield BankAccountAvailableAutoDepositAddresses({ bankAccountId: accountId }))
        .BankAccountAvailableAutoDepositAddresses,
    );
    if (data) {
      // @TODO fix the types
      this.availableAddresses = data as BankAccountAutoDepositAddress[];
    }
    this.isSyncing = false;
  }).bind(this);
  addAddress = flow(function* (this: AccountsStore, accountId: string, emailId: string) {
    this.isSyncing = true;
    try {
      yield CreateBankAccountAutoDepositAddress({
        bankAccountId: accountId,
        emailAddressId: emailId,
      });
    } catch (error: any) {
      this.error = error;
    }
    this.isSyncing = false;
    !this.error && this.loadAccounts(this.lastUsedCompanyId);
  }).bind(this);
  deleteAutoDepositAddress = flow(function* (this: AccountsStore, accountId: string, emailId: string) {
    this.isSyncing = true;
    try {
      yield DeleteBankAccountAutoDepositAddress({
        bankAccountId: accountId,
        emailAddressId: emailId,
      });
    } catch (error: any) {
      this.error = error;
    }
    this.isSyncing = false;
    !this.error && this.loadAccounts(this.lastUsedCompanyId);
  }).bind(this);

  async addLinkedAddress(accountId: string, email: string) {
    this.isSyncing = true;
    await CreateBankAccountLinkedAddress({ bankAccountId: accountId, email: email });
    this.isSyncing = false;
  }

  deleteLinkedAddress = flow(function* (this: AccountsStore, addressId) {
    this.isSyncing = true;
    try {
      yield DeleteBankAccountLinkedAddress({
        addressId,
      });
    } catch (error: any) {
      this.error = error;
    }
    this.isSyncing = false;
    !this.error && this.loadAccounts(this.lastUsedCompanyId);
  }).bind(this);
}

export default AccountsStore;
