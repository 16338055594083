import { Box, Button, Card, CardActions, CardContent, CardHeader, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useStore } from "storeContainer";
import { CheckBatchDetailsList } from "./CheckBatchDetailsList";
import { CheckBatchDetailsSidebar } from "./CheckBatchDetailsSidebar/CheckBatchDetailsSidebar";
import { CheckBatchDetailsTabs } from "./CheckBatchDetailsTabs";
import { BatchDetailsProvider } from "./useBatchDetailsContext";

const CheckBatchDetails = observer(function CheckBatchDetails() {
  const BudgetManagementStore = useStore("BudgetManagementStore");
  const {
    fetchCheckBatch,
    checkBatchData,
    updatedCheckBatch,
    deletedCheckBatchItems,
    createdCheckBatchItems,
    availableChecksForBatchQuery,
  } = BudgetManagementStore;
  const hasError =
    checkBatchData.error || updatedCheckBatch.error || createdCheckBatchItems.error || deletedCheckBatchItems.error;
  const isLoading =
    checkBatchData.isLoading ||
    updatedCheckBatch.isLoading ||
    createdCheckBatchItems.isLoading ||
    deletedCheckBatchItems.isLoading ||
    availableChecksForBatchQuery.isLoading;

  const { organizationId, batchId } = useParams<{ organizationId: string; batchId: string }>();
  const history = useHistory();

  const [batchQuery, setBatchQuery] = useState(checkBatchData.data?.GetCheckBatchQuery);

  useEffect(() => {
    fetchCheckBatch(batchId, undefined, { page: 1, per_page: 10 }, true);
  }, [batchId, fetchCheckBatch]);

  useEffect(() => {
    if (checkBatchData.data?.GetCheckBatchQuery) {
      setBatchQuery(checkBatchData.data?.GetCheckBatchQuery);
    }
  }, [checkBatchData.data?.GetCheckBatchQuery]);

  if (!batchQuery && hasError) {
    const handleRetry = async () => await fetchCheckBatch(batchId, undefined, undefined, true);
    const handleBack = () => history.push(`/org/${organizationId}/bchecks`);

    return (
      <Box>
        <Typography variant="h2" my="40px">
          Batch Details
        </Typography>
        <Card>
          <CardHeader title="Oops, something went wrong" />
          <CardContent>
            <Typography variant="body1">
              There was an error while fetching the data. Don't worry, it's not your fault. You can try again or go back
              to the previous page.
            </Typography>
          </CardContent>
          <CardActions>
            <Button variant="contained" onClick={handleRetry} disabled={isLoading}>
              Retry
            </Button>
            <Button variant="outlined" onClick={handleBack}>
              Back
            </Button>
          </CardActions>
        </Card>
      </Box>
    );
  }

  return (
    <Box sx={{ padding: "20px", display: "flex", gap: "10px" }}>
      <Box sx={{ flexGrow: 1, flexShrink: 1, flexBasis: "0%", minWidth: 0 }}>
        <BatchDetailsProvider>
          <CheckBatchDetailsList />
          <CheckBatchDetailsTabs />
        </BatchDetailsProvider>
      </Box>

      <CheckBatchDetailsSidebar />
    </Box>
  );
});

export default CheckBatchDetails;
