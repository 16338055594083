import { IVBillPaymentListFiltersInput, IVBillPaymentPaginationInput } from "generated/sdk.vbill";
import { useParams, useRouteMatch } from "react-router";
import { VBillPaymentsLedgerBasePath } from "routes/routes";
import { JsonParam, QueryParamConfig, useQueryParams, withDefault } from "use-query-params";
import { VBillPaymentsLedgerChildRoutes } from "./utils";

export const paymentsLedgerFiltersInitialPaginationParams: IVBillPaymentPaginationInput = { page: 1, per_page: 50 };
const initialQsPaginationParams = withDefault<IVBillPaymentPaginationInput, IVBillPaymentPaginationInput>(
  JsonParam,
  paymentsLedgerFiltersInitialPaginationParams,
);

export function usePaymentsLedgerFilters() {
  return useQueryParams<{
    pagination: QueryParamConfig<IVBillPaymentPaginationInput, IVBillPaymentPaginationInput>;
    filters: QueryParamConfig<IVBillPaymentListFiltersInput, IVBillPaymentListFiltersInput>;
  }>(
    {
      pagination: initialQsPaginationParams,
      filters: JsonParam,
    },
    { removeDefaultsFromUrl: true },
  );
}

export interface vBillPaymentsLedgerChildRoute {
  isAllChildRoute: boolean;
  isProcessingChildRoute: boolean;
  // isActionRequiredChildRoute: boolean;
  isCompletedChildRoute: boolean;
  isVoidedChildRoute: boolean;
}

export function useVBillPaymentsLedgerChildRoute(): vBillPaymentsLedgerChildRoute {
  const isAllChildRoute = !!useRouteMatch({ path: VBillPaymentsLedgerBasePath, exact: true });
  const isProcessingChildRoute = !!useRouteMatch(
    `${VBillPaymentsLedgerBasePath}/${VBillPaymentsLedgerChildRoutes.PROCESSING}`,
  );
  // const isActionRequiredChildRoute = !!useRouteMatch(
  //   `${VBillPaymentsLedgerBasePath}/${VBillPaymentsLedgerChildRoutes.ACTION_REQUIRED}`,
  // );
  const isCompletedChildRoute = !!useRouteMatch(
    `${VBillPaymentsLedgerBasePath}/${VBillPaymentsLedgerChildRoutes.COMPLETED}`,
  );
  const isVoidedChildRoute = !!useRouteMatch(`${VBillPaymentsLedgerBasePath}/${VBillPaymentsLedgerChildRoutes.VOIDED}`);

  return {
    isAllChildRoute,
    isProcessingChildRoute,
    // isActionRequiredChildRoute,
    isCompletedChildRoute,
    isVoidedChildRoute,
  };
}

export function useVBillPaymentsLedgerQuickFilterUrl(route: VBillPaymentsLedgerChildRoutes) {
  const { organizationId } = useParams<{ organizationId: string }>();

  return `/org/${organizationId}/vbillpaymentsledger${route === VBillPaymentsLedgerChildRoutes.ALL ? "" : `/${route}`}`;
}
