import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  Popover,
  Radio,
  RadioGroup,
  TextField,
  TextFieldProps,
  Typography,
} from "@mui/material";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateValidationError } from "@mui/x-date-pickers/internals/hooks/validation/useDateValidation";
import { PickersActionBarProps } from "@mui/x-date-pickers/PickersActionBar";
import { formatDateYYYYMMDD, getDateAsUTC } from "common/helpers/utils";
import { ExtendedCheckFiltersType1, useCheckFilters } from "common/hooks/useCheckFilters";
import { getFiltersConfig, LedgerFilterInputType } from "components/Filter/types";
import { CalendarIcon } from "icons/svg/CalendarIcon";
import { useEffect, useState } from "react";
import { useParams, useRouteMatch } from "react-router-dom";

const CustomActionBar = ({
  name,
  onClear,
  handleClearOnClick,
  ...props
}: PickersActionBarProps & {
  name: string;
  handleClearOnClick: (name: string) => void;
}) => {
  return (
    <Box display="flex" justifyContent="flex-end" paddingRight="20px">
      <Button
        variant="text"
        disableElevation
        disableFocusRipple
        disableRipple
        disableTouchRipple
        onClick={() => handleClearOnClick(name)}
      >
        Clear
      </Button>
    </Box>
  );
};

// interface FiltersProps {
//   date?: {
//     context?: string[];
//     preset?: string | undefined;
//     from?: string | undefined;
//     to?: string | undefined;
//   };
// }

const reasonErrorMap = {
  minDate: "The selected date must be later than the 'From' date.",
  invalidDate: "Please enter a valid date in the format mm/dd/yyyy.",
  maxDate: "The selected date must be sooner than the 'Until' date.",
  shouldDisableDate: "This date cannot be selected.",
  disableFuture: "Please select a date that is not in the future.",
  disablePast: "Please select a date that is not in the past.",
};

export const DateContextFilters = () => {
  const { filters, setFilters } = useCheckFilters<ExtendedCheckFiltersType1>();
  const [filtersErrors, setFiltersErrors] = useState<Record<string, any>>();
  const [selectedRange, setSelectedRange] = useState<string | null>("");
  const [showDatePickers, setShowDatePickers] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isFromPickerOpen, setIsFromPickerOpen] = useState(false);
  const [isUntilPickerOpen, setIsUntilPickerOpen] = useState(false);
  const [contextErrorMessage] = useState<string | null>(null);

  const { path } = useRouteMatch();
  const { selectedQuickFilter } = useParams<{ selectedQuickFilter: string }>();
  const formConfig = getFiltersConfig(path, selectedQuickFilter)?.[LedgerFilterInputType.AdvancedDateFilter];
  const keyNameInput = formConfig?.keyNameInput as string;
  const keyNameContextOptions = formConfig?.keyNameContextOptions as string;
  const keyNamePresetOption = formConfig?.keyNamePresetOption as string;
  const keyNameCustomRange = formConfig?.keyNameCustomRange as string[];
  const calendarIconStyle = { width: "15px", height: "16px", cursor: "pointer" };
  const open = Boolean(anchorEl);
  const textFieldSx = {
    width: "140px",
    input: {
      height: "42px",
      padding: "0",
      "&::placeholder": {
        fontFamily: "'Inter'",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "17px",
        color: "#052048",
      },
    },
    ...((filtersErrors?.[formConfig?.keyNameInput as string]?.[formConfig?.keyNameCustomRange?.[0] as string] ||
      filtersErrors?.[formConfig?.keyNameInput as string]?.[formConfig?.keyNameCustomRange?.[1] as string]) && {
      fieldset: { border: "1px solid #d32f2f !important" },
      ".MuiFormHelperText-root": { color: "#d32f2f" },
    }),
  };
  const dateValue =
    filters?.[formConfig?.keyNameInput as string]?.[formConfig?.keyNameCustomRange?.[0] as string] || null;
  const dateToValue =
    filters?.[formConfig?.keyNameInput as string]?.[formConfig?.keyNameCustomRange?.[1] as string] || null;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setShowDatePickers(false);
  };

  const handleRangeSelect = (value: string) => {
    setSelectedRange(value);
    if (value === "custom_type") {
      setShowDatePickers(true);
    } else {
      const contextDefaultValue = formConfig?.contextOptions?.find((item) => item.default)?.value;

      setFilters((prevFilters) => {
        const newFilters = {
          ...prevFilters,
          [formConfig?.keyNameInput as string]: {
            [formConfig?.keyNameContextOptions as string]:
              prevFilters?.[formConfig?.keyNameInput as string]?.[formConfig?.keyNameContextOptions as string] ??
              contextDefaultValue,
          },
        };

        if (value) {
          newFilters[formConfig?.keyNameInput as string][formConfig?.keyNamePresetOption as string] = value;
        }
        return newFilters;
      });
      handleClose();
    }
  };

  const handleClearOnClick = (name: string) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [formConfig?.keyNameInput as string]: {
        ...prevFilters?.[formConfig?.keyNameInput as string],
        ...prevFilters?.[formConfig?.keyNameContextOptions as string],
        [name]: undefined,
      },
    }));
    name === (formConfig?.keyNameInput as string) ? setIsFromPickerOpen(false) : setIsUntilPickerOpen(false);
  };

  const handleRadioChange = (value: string) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [formConfig?.keyNameInput as string]: {
        ...(prevFilters ? prevFilters[formConfig?.keyNameInput as string] : {}),
        [formConfig?.keyNameContextOptions as string]: value,
      },
    }));
  };

  const handleDateChange = (key: string, value: Date) => {
    if (!value) {
      setFiltersErrors({ date: { [key]: undefined } });
    }

    if (
      key === "from" &&
      filters?.[formConfig?.keyNameInput as string]?.[formConfig?.keyNameCustomRange?.[1] as string] &&
      value > new Date(filters?.[formConfig?.keyNameInput as string]?.[formConfig?.keyNameCustomRange?.[1] as string])
    ) {
      setFiltersErrors((prevErrors) => {
        return { ...prevErrors, date: undefined, date_to: undefined };
      });
    } else if (
      key === "to" &&
      filters?.[formConfig?.keyNameInput as string]?.[formConfig?.keyNameCustomRange?.[0] as string] &&
      value < new Date(filters?.[formConfig?.keyNameInput as string]?.[formConfig?.keyNameCustomRange?.[0] as string])
    ) {
      setFiltersErrors((prevErrors) => {
        return { ...prevErrors, date: undefined };
      });
    }

    const contextDefaultValue = formConfig?.contextOptions?.find((item) => item.default)?.value;

    setFilters((prevFilters) => {
      const { [key]: _, ...prevFiltersPayloadClean } = prevFilters;

      return {
        ...prevFiltersPayloadClean,
        [formConfig?.keyNameInput as string]: {
          ...prevFiltersPayloadClean[formConfig?.keyNameInput as string],
          [key]: formatDateYYYYMMDD(value),
          [formConfig?.keyNameContextOptions as string]:
            filters?.[formConfig?.keyNameInput as string]?.[formConfig?.keyNameContextOptions as string] ??
            contextDefaultValue,
          [formConfig?.keyNamePresetOption as string]: undefined,
        },
      };
    });
    setIsFromPickerOpen(false);
    setIsUntilPickerOpen(false);
  };

  const handleDateError = (reason: DateValidationError): void => {
    setFiltersErrors((prevErrors) => {
      return {
        ...prevErrors,
        date: {
          from: reason ? reasonErrorMap[reason] : undefined,
        },
      };
    });
  };
  const handleDateCalendarIconClick = () => setIsFromPickerOpen(true);
  const handleDateOnOpen = () => setIsFromPickerOpen(true);
  const handleDateOnClose = () => setIsFromPickerOpen(false);
  const handleDateOnChange: (value: any, keyboardInputValue?: string | undefined) => void = (value) => {
    handleDateChange("from", value);
  };
  const handleDateToOnOpen = () => setIsUntilPickerOpen(true);
  const handleDateToOnClose = () => setIsUntilPickerOpen(false);
  const handleDateToOnChange: (value: any, keyboardInputValue?: string | undefined) => void = (value) => {
    handleDateChange("to", value);
  };
  const handleDateToCalendarClick = () => setIsUntilPickerOpen(true);
  const handleDateToOnError = (reason: DateValidationError): void => {
    setFiltersErrors((prevErrors) => {
      return {
        ...prevErrors,
        date: reason ? { to: reasonErrorMap[reason] } : undefined,
      };
    });
  };

  const getSelectedContext = (key: string) => {
    const { keyNameInput, keyNamePresetOption, keyNameCustomRange, presetOptions } = formConfig || {};
    const dateFilters = filters?.[keyNameInput as string] || {};

    const presetOption = dateFilters?.[keyNamePresetOption as string];
    const customRangeStart = dateFilters?.[keyNameCustomRange?.[0] as string];
    const customRangeEnd = dateFilters?.[keyNameCustomRange?.[1] as string];
    const defaultPresetOption = presetOptions?.find((item) => item.default)?.value;

    if (presetOption === key) {
      return "#ebf4ff";
    }

    if ((customRangeStart || customRangeEnd) && key === "custom_type") {
      return "#ebf4ff";
    }

    if (!presetOption && !customRangeStart && !customRangeEnd && key === defaultPresetOption) {
      return "#ebf4ff";
    }

    return "inherit";
  };

  useEffect(() => {
    if (dateToValue && dateValue && new Date(dateValue).getTime() > new Date(dateToValue).getTime()) {
      setFiltersErrors(() => {
        return {
          [formConfig?.keyNameInput as string]: {
            [formConfig?.keyNameCustomRange?.[0] as string]: reasonErrorMap.maxDate,
            [formConfig?.keyNameCustomRange?.[1] as string]: reasonErrorMap.minDate,
          },
        };
      });
    } else {
      setFiltersErrors(() => {
        return {
          date: undefined,
        };
      });
    }
  }, [dateToValue, dateValue, formConfig?.keyNameCustomRange, formConfig?.keyNameInput, setFiltersErrors]);

  useEffect(() => {
    const presetOptionDefaultValue = formConfig?.presetOptions?.find((item) => item.default)?.value;

    if (!filters?.[keyNameInput]?.context) {
      const contextDefaultValue = formConfig?.contextOptions?.find((item) => item.default)?.value;

      setFilters((prevFilters) => {
        const newFilters = {
          ...prevFilters,
          [keyNameInput]: {
            ...prevFilters?.[keyNameInput],
            [keyNameContextOptions]: contextDefaultValue,
          },
        };

        if (presetOptionDefaultValue) {
          newFilters[keyNameInput] = {
            ...newFilters[keyNameInput],
            [keyNamePresetOption]: presetOptionDefaultValue,
          };
        }
        return newFilters;
      });
    }

    if (filters?.[keyNameInput]?.[keyNameCustomRange?.[0]] || filters?.[keyNameInput]?.[keyNameCustomRange?.[1]]) {
      setShowDatePickers(true);
    }
  }, [
    filters,
    formConfig?.contextOptions,
    formConfig?.presetOptions,
    keyNameContextOptions,
    keyNameCustomRange,
    keyNameInput,
    keyNamePresetOption,
    setFilters,
  ]);

  useEffect(() => {
    if (
      filters?.[keyNameInput]?.[keyNamePresetOption] &&
      filters?.[keyNameInput]?.[keyNamePresetOption] !== selectedRange
    ) {
      setSelectedRange(filters?.[keyNameInput]?.[keyNamePresetOption]);
    } else if (!filters?.[keyNameInput]?.[keyNamePresetOption]) {
      setSelectedRange("");
    }
  }, [filters, keyNameInput, keyNamePresetOption, selectedRange]);

  return !formConfig && !filters?.context ? null : (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box display="flex" justifyContent="space-between" minHeight={"unset"} gap={"15px"}>
        <Box>
          <Button
            id="select_date"
            aria-describedby="select_date"
            variant={
              (selectedRange && selectedRange !== "") ||
              filters?.[formConfig?.keyNameInput as string]?.[formConfig?.keyNameCustomRange[0] as string] ||
              filters?.[formConfig?.keyNameInput as string]?.[formConfig?.keyNameCustomRange[1] as string]
                ? "containedFilters"
                : "outlined"
            }
            onClick={handleClick}
            sx={{ fontSize: "12px" }}
          >
            {
              (
                formConfig?.presetOptions.find((presetOption) => {
                  if (!!filters?.[formConfig?.keyNameInput as string]?.[formConfig?.keyNamePresetOption as string]) {
                    return (
                      presetOption.value ===
                      filters?.[formConfig?.keyNameInput as string]?.[formConfig?.keyNamePresetOption as string]
                    );
                  } else if (
                    filters?.[formConfig?.keyNameInput as string]?.[formConfig?.keyNameCustomRange[0] as string] ||
                    filters?.[formConfig?.keyNameInput as string]?.[formConfig?.keyNameCustomRange[1] as string] ||
                    (filters?.[formConfig?.keyNameInput as string]?.[formConfig?.keyNameCustomRange[0] as string] &&
                      filters?.[formConfig?.keyNameInput as string]?.[formConfig?.keyNameCustomRange[1] as string])
                  ) {
                    return presetOption.value === "custom_type";
                  } else {
                    return presetOption.default;
                  }
                }) || { value: "" }
              ).label
            }
          </Button>
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Box p={2} display="flex" flexDirection="row" gap={2}>
              <FormControl>
                <Typography sx={{ marginBottom: "5px" }} variant="body2">
                  Context
                </Typography>
                <RadioGroup
                  value={
                    filters?.[formConfig?.keyNameInput as string]?.[formConfig?.keyNameContextOptions as string] || ""
                  }
                  onChange={(e) => handleRadioChange(e.target.value)}
                >
                  {formConfig?.contextOptions?.map((item: any) => (
                    <FormControlLabel
                      key={item.value}
                      value={item.value}
                      control={<Radio />}
                      label={item.label}
                      checked={
                        filters?.[formConfig?.keyNameInput as string]?.[formConfig?.keyNameContextOptions as string] ===
                        item.value
                      }
                    />
                  ))}
                </RadioGroup>
                {contextErrorMessage && (
                  <Typography sx={{ marginTop: "10px" }} color="error">
                    {contextErrorMessage}
                  </Typography>
                )}
              </FormControl>
              <Box>
                {formConfig?.presetOptions?.map((item: any) => (
                  <MenuItem
                    key={item.value}
                    onClick={() => handleRangeSelect(item.value)}
                    sx={{ backgroundColor: getSelectedContext(item.value) }}
                  >
                    {item.label}
                  </MenuItem>
                ))}
                {showDatePickers && (
                  <Box mt={2}>
                    <Box>
                      <InputLabel variant="standard">From</InputLabel>
                      <MobileDatePicker
                        open={isFromPickerOpen}
                        onOpen={handleDateOnOpen}
                        onClose={handleDateOnClose}
                        value={dateValue ? getDateAsUTC(dateValue) : null}
                        onChange={handleDateOnChange}
                        onError={handleDateError}
                        renderInput={(props: TextFieldProps) => (
                          <TextField
                            {...props}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start" onClick={handleDateCalendarIconClick}>
                                  <CalendarIcon sx={calendarIconStyle} />
                                </InputAdornment>
                              ),
                            }}
                            sx={textFieldSx}
                            helperText={
                              filtersErrors?.[formConfig?.keyNameInput as string]?.[
                                formConfig?.keyNameCustomRange?.[0] as string
                              ]
                            }
                            onClick={handleDateOnOpen}
                          />
                        )}
                        components={{
                          ActionBar: (props: PickersActionBarProps) => (
                            <CustomActionBar
                              name={formConfig?.keyNameCustomRange?.[0]!}
                              handleClearOnClick={handleClearOnClick}
                              {...props}
                            />
                          ),
                        }}
                        componentsProps={{
                          actionBar: {
                            actions: ["clear"],
                          },
                        }}
                      />
                    </Box>
                    <Box>
                      <InputLabel variant="standard" sx={{ marginTop: "10px" }}>
                        Until
                      </InputLabel>
                      <MobileDatePicker
                        open={isUntilPickerOpen}
                        onOpen={handleDateToOnOpen}
                        onClose={handleDateToOnClose}
                        value={dateToValue ? getDateAsUTC(dateToValue) : null}
                        onChange={handleDateToOnChange}
                        onError={handleDateToOnError}
                        renderInput={(props: TextFieldProps) => (
                          <TextField
                            {...props}
                            error={false}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start" onClick={handleDateToCalendarClick}>
                                  <CalendarIcon sx={calendarIconStyle} />
                                </InputAdornment>
                              ),
                              autoComplete: "off",
                            }}
                            sx={textFieldSx}
                            helperText={
                              filtersErrors?.[formConfig?.keyNameInput as string]?.[
                                formConfig?.keyNameCustomRange?.[1] as string
                              ]
                            }
                            onClick={handleDateToOnOpen}
                          />
                        )}
                        components={{
                          ActionBar: (props: PickersActionBarProps) => (
                            <CustomActionBar
                              name={formConfig?.keyNameCustomRange?.[1]!}
                              handleClearOnClick={handleClearOnClick}
                              {...props}
                            />
                          ),
                        }}
                        componentsProps={{
                          actionBar: {
                            actions: ["clear"],
                          },
                        }}
                      />
                    </Box>
                    <Button
                      onClick={handleClose}
                      variant="contained"
                      sx={{ marginTop: "10px" }}
                      disabled={
                        !!(
                          filtersErrors?.[formConfig?.keyNameInput as string]?.[
                            formConfig?.keyNameCustomRange?.[1] as string
                          ] ||
                          filtersErrors?.[formConfig?.keyNameInput as string]?.[
                            formConfig?.keyNameCustomRange?.[0] as string
                          ]
                        )
                      }
                    >
                      Done
                    </Button>
                  </Box>
                )}
              </Box>
            </Box>
          </Popover>
        </Box>
      </Box>
    </LocalizationProvider>
  );
};
