import { chatStaticEmojiList } from "common/static/chatStaticEmojiList";
import {
  IChatChatIoMemberOutputSchema,
  IChatChatIoMessageContentBlockMessageRelatedInput,
  IChatContentBlockMessageRelatedType,
  IChatContentBlockType,
} from "generated/sdk.chat";
import { useChatChannelStore } from "modules/Chat/hooks/hooks";
import { CHAT_MSG_REACTION_INLINE_PREFIX } from "modules/Chat/utils/utils";
import { ChangeEvent, FormEvent, useRef, useState } from "react";

export enum TMentionSomethingType {
  "MEMBER",
  "RELATED",
}

export type TMentionSomething =
  | {
      type: TMentionSomethingType.MEMBER;
      data: IChatChatIoMemberOutputSchema;
    }
  | {
      type: TMentionSomethingType.RELATED;
      data: IChatChatIoMessageContentBlockMessageRelatedInput;
    };

export enum MessageEventType {
  "KEYDOWN",
  "CHANGE",
  "SUBMIT",
}

export type TMessageEvent =
  | {
      eventType: MessageEventType.CHANGE;
      event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;
    }
  | {
      eventType: MessageEventType.KEYDOWN;
      event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>;
    }
  | {
      eventType: MessageEventType.SUBMIT;
      event: FormEvent<HTMLFormElement>;
    };

export function useChatForm() {
  const chatChannelStore = useChatChannelStore();
  const mentionSomethingDialogOpen = useState(false);
  const reactionsPickerMenuOpen = useState(false);
  const [, setReactionsPickerMenuOpen] = reactionsPickerMenuOpen;
  const [, setIsMentionSomethingOpen] = mentionSomethingDialogOpen;
  const messageInputRef = useRef<HTMLInputElement>(null);
  const [messageInputValue, setMessageInputValue] = useState("");
  const [isSendMessageReqLoading, setIsSendMessageReqLoading] = useState(false);

  const handleInputMsgChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setMessageInputValue(event.target.value);
  };

  const handleInputMsgKeyDown = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (
      (event.key === "@" &&
        (messageInputValue[messageInputValue.length - 1] === " " || messageInputValue.length === 0)) ||
      event.key === "#"
    ) {
      event.preventDefault();
      setIsMentionSomethingOpen(true);
    }

    if (event.key === ":") {
      event.preventDefault();
      setReactionsPickerMenuOpen(true);
    }
  };

  const handleMessageFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (isSendMessageReqLoading || !messageInputValue.length) {
      return;
    }

    if (chatChannelStore?.forcePublic) {
      const currentMentionedMembersToAdd = (chatChannelStore?.otherPossibleMembers ?? []).filter(
        ({ name, accountId }) =>
          messageInputValue.includes(`@${name}`) &&
          !chatChannelStore?.otherMembers?.find((member) => member.accountId === accountId),
      );
      if (currentMentionedMembersToAdd.length) {
        await chatChannelStore?.setAddMembers(currentMentionedMembersToAdd);
      }
    }

    setMessageInputValue("");

    const currentMentionedMembers = (chatChannelStore?.otherMembers ?? []).filter(({ name }) =>
      messageInputValue.includes(`@${name}`),
    );
    const currentMentionedRelated = (chatChannelStore?.relatedItems ?? []).filter(({ related }) =>
      messageInputValue.includes(`#${related.label}`),
    );
    const currentEmojiSelected = chatStaticEmojiList.filter(({ name }) => messageInputValue.includes(`:${name}`));
    let templateMessageValue = messageInputValue;
    currentMentionedMembers.forEach((member) => {
      templateMessageValue = templateMessageValue.replaceAll(
        `@${member.name}`,
        `{{${IChatContentBlockMessageRelatedType.Account}:${member.accountId}}}`,
      );
    });
    currentMentionedRelated.forEach((item) => {
      templateMessageValue = templateMessageValue.replaceAll(
        `#${item.related.label}`,
        `{{${IChatContentBlockMessageRelatedType.Bill}:${item.related.key}}}`,
      );
    });
    currentEmojiSelected.forEach((item) => {
      templateMessageValue = templateMessageValue.replaceAll(
        `:${item.name}`,
        `{{${CHAT_MSG_REACTION_INLINE_PREFIX}:${item.name}}}`,
      );
    });
    const messageRelated: IChatChatIoMessageContentBlockMessageRelatedInput[] | undefined = [
      ...currentMentionedMembers.map((member) => ({
        key: `${IChatContentBlockMessageRelatedType.Account}:${member.accountId}`,
        related: {
          key: member.accountId ?? "",
          label: member.name,
          type: IChatContentBlockMessageRelatedType.Account,
        },
      })),
      ...currentMentionedRelated.map((item) => ({
        key: `${IChatContentBlockMessageRelatedType.Bill}:${item.related.key}`,
        related: {
          key: item.related.key,
          label: item.related.label,
          type: IChatContentBlockMessageRelatedType.Bill,
        },
      })),
      ...currentEmojiSelected.map((item) => ({
        key: `${CHAT_MSG_REACTION_INLINE_PREFIX}:${item.name}`,
        related: {
          key: item.name,
          label: item.name,
          type: IChatContentBlockMessageRelatedType.Reaction,
        },
      })),
    ];
    setIsSendMessageReqLoading(true);

    await chatChannelStore?.setSendMessage({
      messageIo: {
        content: {
          type: IChatContentBlockType.Message,
          message: templateMessageValue,
          related: !!messageRelated.length ? messageRelated : undefined,
        },
        ...(chatChannelStore?.replyToMessage ? { replyToMessageId: chatChannelStore?.replyToMessage.id } : {}),
      },
    });

    if (chatChannelStore?.replyToMessage) {
      chatChannelStore?.setReplyToMessage();
    }

    setIsSendMessageReqLoading(false);
  };

  const handleMentionSomethingSelect = (value: TMentionSomething) => {
    const selectionStart = messageInputRef.current?.selectionStart ?? 0;

    if (value.type === TMentionSomethingType.MEMBER) {
      setMessageInputValue(
        (prev) => `${prev.substring(0, selectionStart)}@${value.data.name}${prev.substring(selectionStart)} `,
      );
    }

    if (value.type === TMentionSomethingType.RELATED) {
      setMessageInputValue(
        (prev) => `${prev.substring(0, selectionStart)}#${value.data.related.label}${prev.substring(selectionStart)} `,
      );
    }

    setIsMentionSomethingOpen(false);
  };

  const handleReactPickerSelect = (value: string) => {
    const selectionStart = messageInputRef.current?.selectionStart ?? 0;

    setMessageInputValue((prev) => `${prev.substring(0, selectionStart)}:${value}${prev.substring(selectionStart)} `);
    setReactionsPickerMenuOpen(false);
  };

  const handleMessageEvent = (data: TMessageEvent) => {
    if (data.eventType === MessageEventType.CHANGE) {
      handleInputMsgChange(data.event);
    }

    if (data.eventType === MessageEventType.KEYDOWN) {
      handleInputMsgKeyDown(data.event);
    }

    if (data.eventType === MessageEventType.SUBMIT) {
      handleMessageFormSubmit(data.event);
    }
  };

  return {
    isSendMessageReqLoading,
    messageInputValue,
    handleMessageEvent,
    handleMentionSomethingSelect,
    handleReactPickerSelect,
    mentionSomethingDialogOpen,
    reactionsPickerMenuOpen,
    messageInputRef,
  };
}
