import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import { Box, Tooltip } from "@mui/material";
import { ChatActivityEnumType, ChatStatusEnumType, LedgerCheck } from "generated/sdk";
import { COLORS } from "themes/default";

interface IVCheckToChatDetailsProps {
  chatDetails: LedgerCheck["chat_details"];
}

export const VCheckToChatDetails = ({ chatDetails }: IVCheckToChatDetailsProps) => {
  if (!chatDetails?.chat_status || chatDetails.chat_status === ChatStatusEnumType.Closed) {
    return null;
  }

  const { chat_activity } = chatDetails;

  return (
    <Box sx={{ position: "absolute", top: "0", right: "0", zIndex: "1" }}>
      <Tooltip arrow title={`${chat_activity === ChatActivityEnumType.Active ? "Active" : "Inactive"} Chat`}>
        <QuestionAnswerIcon
          sx={{
            fontSize: "16px",
            color: chat_activity === ChatActivityEnumType.Active ? COLORS.logoBlue : COLORS.mediumGrey,
          }}
        />
      </Tooltip>
    </Box>
  );
};
