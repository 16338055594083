import { Box, Button, SxProps, Theme } from "@mui/material";
import { ExpandLessIcon, ExpandMoreIcon } from "components/common/icons";
import { IVBillBillsFiltersMappingInput, IVBillStoreIoDataType } from "generated/sdk.vbill";
import { useState } from "react";
import { AdditionalMappingsDialog } from "./AdditionalMappingsDialog/AdditionalMappingsDialog";

const expandIconStyles: SxProps<Theme> = { fontSize: "16px !important", marginRight: "4px" };

interface IAdditionalMappingsFilterProps {
  storeType: IVBillStoreIoDataType;
  storeKey: string;
  batchCompanyIds?: string[] | null;
  inputLabel: string;
  searchPlaceholder: string;
  mapping?: IVBillBillsFiltersMappingInput;
  onMappingChange: (value: IVBillBillsFiltersMappingInput) => Promise<void>;
  btnSmallHeight?: boolean;
  actionsBtnSmallHeight?: boolean;
  sx?: SxProps<Theme>;
}

export const VBillAdditionalMappingsFilter = ({
  storeType,
  storeKey,
  batchCompanyIds,
  inputLabel,
  searchPlaceholder,
  mapping,
  onMappingChange,
  btnSmallHeight,
  actionsBtnSmallHeight,
  sx,
}: IAdditionalMappingsFilterProps) => {
  const [popoverAnchorEl, setPopoverAnchorEl] = useState<HTMLElement>();

  const popoverVisible = Boolean(popoverAnchorEl);

  return (
    <Box sx={sx}>
      <Button
        onClick={(event) => setPopoverAnchorEl(event.currentTarget)}
        variant="outlined"
        endIcon={popoverVisible ? <ExpandLessIcon sx={expandIconStyles} /> : <ExpandMoreIcon sx={expandIconStyles} />}
        sx={{ ...(btnSmallHeight ? { height: "32px" } : {}) }}
      >
        {inputLabel}
      </Button>

      {popoverVisible && (
        <AdditionalMappingsDialog
          suggestionsComponent="popover"
          suggestionsPopoverAnchorEl={popoverAnchorEl}
          storeKey={storeKey}
          storeType={storeType}
          batchCompanyIds={batchCompanyIds}
          searchPlaceholder={searchPlaceholder}
          mapping={mapping}
          onMappingSave={onMappingChange}
          onCloseDialog={() => setPopoverAnchorEl(undefined)}
          actionsBtnSmallHeight={actionsBtnSmallHeight}
        />
      )}
    </Box>
  );
};
