import { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import Autocomplete from "components/common/Autocomplete";
import Box from "components/common/Box";
import Divider from "components/common/Divider";
import LoadingButton from "components/common/LoadingButton";
import Paper from "components/common/Paper";
import Typography from "components/common/Typography";
import { Save } from "components/common/icons";
import type { BankAccountAutoDepositAddress } from "generated/sdk";
import DepositAddresses from "../DepositAddresses/DepositAddresses";
import { useStore } from "storeContainer";
import Grid from "@mui/material/Grid";
import { Spinner } from "components/common/Spinner/Spinner";
import { Tooltip } from "@mui/material";
import { CompanyMenu } from "components/layout/CompanyMenu";

const getOptionLabel = (option: string | BankAccountAutoDepositAddress) => {
  if (typeof option === "string") {
    return option;
  }

  return option.email as string;
};

const DepositAddressDetails = ({
  className,
  syncing,
  onSave,
  autoDepositAvailableAddresses,
  autoDepositAddresses,
  accountId,
  isReadOnly,
  autoDepositAddressLoading,
  fetchAutoDepositAddresses,
  fetchAutoDepositAvailableAddresses,
  setAutoDepositAddressesLoading,
}: {
  className?: string;
  error: any | null;
  syncing: boolean;
  autoDepositAddresses: BankAccountAutoDepositAddress[] | null | undefined;
  autoDepositAvailableAddresses: BankAccountAutoDepositAddress[];
  accountId: string;
  onSave: (address: string) => void;
  isReadOnly: boolean;
  autoDepositAddressLoading: boolean;
  fetchAutoDepositAddresses: Function;
  fetchAutoDepositAvailableAddresses: Function;
  setAutoDepositAddressesLoading: Function;
}) => {
  const [selectedEmail, setSelectedEmail] = useState<BankAccountAutoDepositAddress | null>(null);
  const [filteredAddresses, setFilteredAddresses] = useState<BankAccountAutoDepositAddress[]>();
  const { addAddress } = useStore("AccountsStore");
  const SessionStore = useStore("SessionStore");

  const handleSave = useCallback(async () => {
    //onSave(selectedEmail!.id!);
    await addAddress(accountId, selectedEmail?.id!);
    fetchAutoDepositAddresses();
    fetchAutoDepositAvailableAddresses();
    SessionStore.loadSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEmail, onSave]);

  const handleSelect = useCallback(
    (_, email) => {
      setSelectedEmail(email);
    },
    [setSelectedEmail],
  );

  useEffect(() => {
    if (autoDepositAvailableAddresses && autoDepositAddresses) {
      const updatedAvailableAddresses = autoDepositAvailableAddresses?.filter((address) => {
        return !autoDepositAddresses.some(
          (autoDepositAddress: BankAccountAutoDepositAddress) => autoDepositAddress.id === address.id,
        );
      });
      setFilteredAddresses(updatedAvailableAddresses);
      setSelectedEmail(null);
    }
  }, [autoDepositAvailableAddresses, autoDepositAddresses]);

  return (
    <Box display="flex" width="100%" justifyContent="space-between" gap="130px" maxWidth="1400px">
      <CompanyMenu />

      <Box paddingLeft="20px" className={className} width="100%">
        <Typography variant="h1" marginY="40px">
          Add Auto Deposit Address
        </Typography>
        <Paper>
          <>
            <Typography sx={{ p: 2 }} variant="h6">
              Auto Deposit Address Form
            </Typography>
            <Divider />
            <Box alignItems="center" display="flex" flexWrap="wrap" gap={3} justifyContent="center" px={2} py={6}>
              <Tooltip
                PopperProps={{ style: { textAlign: "center" } }}
                title={isReadOnly ? "You don't have the required permissions to add a new auto deposit address." : ""}
              >
                <Box>
                  <Autocomplete
                    color="primary"
                    value={selectedEmail}
                    disabled={!!(selectedEmail && syncing) || !autoDepositAvailableAddresses || isReadOnly}
                    getOptionLabel={getOptionLabel}
                    options={filteredAddresses ?? []}
                    sx={{ minWidth: "350px" }}
                    textFieldProps={{ label: "E-mail Address" }}
                    onChange={handleSelect}
                  />
                </Box>
              </Tooltip>
              <LoadingButton
                color="primary"
                disabled={!selectedEmail}
                loading={!!(selectedEmail && syncing)}
                loadingPosition="start"
                size="large"
                startIcon={<Save />}
                variant="outlined"
                onClick={handleSave}
              >
                Add Address
              </LoadingButton>
            </Box>
          </>

          {syncing ? (
            <Grid justifyContent={"center"} container>
              <Spinner />
            </Grid>
          ) : (
            <DepositAddresses
              addresses={autoDepositAddresses}
              loading={syncing}
              error={undefined}
              isReadOnly={isReadOnly}
              autoDepositAddressLoading={autoDepositAddressLoading}
              fetchAutoDepositAvailableAddresses={fetchAutoDepositAvailableAddresses}
              fetchAutoDepositAddresses={fetchAutoDepositAddresses}
              setAutoDepositAddressesLoading={setAutoDepositAddressesLoading}
            />
          )}
        </Paper>
      </Box>
    </Box>
  );
};

export default observer(DepositAddressDetails);
