import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { IVBillBatchDateUnitType, IVBillBillsFiltersOutput, IVBillTemporality } from "generated/sdk.vbill";
import { useMemo, useState } from "react";
import { getVBillFormattedDate } from "../../utils";
import { relatedDatesGetRelativeDate } from "../../VBillRelativeDatesSelector/VBillRelativeDatesSelector";
import { VBillRelativeDatesSelectorDialog } from "../../VBillRelativeDatesSelector/VBillRelativeDatesSelectorDialog";
import { labelTextStyles } from "../VBillAddFullBatchDialog";

export type TDueDate =
  | Pick<IVBillBillsFiltersOutput, "relativeReferenceDate" | "relativeDateDueFrom" | "relativeDateDueTo">
  | null
  | undefined;

interface IAddFullBatchDueDateRangeProps {
  dueDate: TDueDate;
  onDueDateSubmit: (dueDate: TDueDate) => void;
}

export const AddFullBatchDueDateRange = ({ dueDate, onDueDateSubmit }: IAddFullBatchDueDateRangeProps) => {
  const [isRelativeDueDateDialogOpen, setIsRelativeDueDateDialogOpen] = useState(false);
  const formattedDateDueFromDate = useMemo(() => {
    if (!dueDate?.relativeReferenceDate || !dueDate?.relativeDateDueFrom) {
      return null;
    }

    const { relativeReferenceDate, relativeDateDueFrom } = dueDate;
    const newLocalRelativeReferenceDate = new Date(
      relativeReferenceDate?.customDate ? relativeReferenceDate.customDate : new Date() /* today */,
    ).toString();
    const value = `${relativeDateDueFrom.value}`;
    const temporality = relativeDateDueFrom.temporality ? relativeDateDueFrom.temporality : IVBillTemporality.Before;
    const newSelectedDateUnitType = relativeDateDueFrom?.unit ? relativeDateDueFrom.unit : IVBillBatchDateUnitType.Day;
    const relativeDateValue = relatedDatesGetRelativeDate(
      newLocalRelativeReferenceDate,
      value,
      temporality,
      newSelectedDateUnitType,
    );

    return getVBillFormattedDate(relativeDateValue);
  }, [dueDate]);

  const formattedDateDueToDate = useMemo(() => {
    if (!dueDate?.relativeReferenceDate || !dueDate?.relativeDateDueTo) {
      return null;
    }

    const { relativeReferenceDate, relativeDateDueTo } = dueDate;
    const newLocalRelativeReferenceDate = new Date(
      relativeReferenceDate?.customDate ? relativeReferenceDate.customDate : new Date() /* today */,
    ).toString();
    const value = `${relativeDateDueTo.value}`;
    const temporality = relativeDateDueTo.temporality ? relativeDateDueTo.temporality : IVBillTemporality.Before;
    const newSelectedDateUnitType = relativeDateDueTo?.unit ? relativeDateDueTo.unit : IVBillBatchDateUnitType.Day;
    const relativeDateValue = relatedDatesGetRelativeDate(
      newLocalRelativeReferenceDate,
      value,
      temporality,
      newSelectedDateUnitType,
    );

    return getVBillFormattedDate(relativeDateValue);
  }, [dueDate]);

  const hasPartialDueDate = dueDate?.relativeDateDueFrom || dueDate?.relativeDateDueTo;

  return (
    <Box sx={{ marginBottom: "15px" }}>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <Typography sx={labelTextStyles}>Bills due date range</Typography>

        <Tooltip title={!hasPartialDueDate ? "Add bills due date range" : "Edit bills due date range"} arrow>
          <IconButton onClick={() => setIsRelativeDueDateDialogOpen(true)}>
            {!hasPartialDueDate ? <AddCircleOutlineIcon /> : <EditIcon />}
          </IconButton>
        </Tooltip>
      </Box>

      {(formattedDateDueFromDate || formattedDateDueToDate) && (
        <Typography>
          Selected: {formattedDateDueFromDate ?? "-"} to {formattedDateDueToDate ?? "-"}
        </Typography>
      )}

      {isRelativeDueDateDialogOpen && (
        <VBillRelativeDatesSelectorDialog
          dates={dueDate}
          onDatesSubmit={async (values) => onDueDateSubmit(values)}
          onCloseDialog={() => setIsRelativeDueDateDialogOpen(false)}
          enableCurrentDateSelector={false}
          title="Due date"
          actionsBtnSmallHeight
        />
      )}
    </Box>
  );
};
