import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DoneIcon from "@mui/icons-material/Done";
import LanguageIcon from "@mui/icons-material/Language";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import SyncIcon from "@mui/icons-material/Sync";
import { Box, Chip, Skeleton, Typography } from "@mui/material";
import { formatCurrency } from "common/helpers/utils";
import { vBillQuickFilterCircleSx } from "components/pages/common/VBill/styles";
import { IVBillInvoiceIntegrationStatus } from "generated/sdk.vbill";
import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { useStore } from "storeContainer";
import { useVBillPayLedgerChildRoute, useVBillPayLedgerQuickFilterUrl } from "../hooks";
import { VBillPayLedgerChildRoutes } from "../utils";

export const PayQuickFilters = observer(function PayQuickFilters() {
  const vBillPayLedgerStore = useStore("VBillPayLedgerStore");
  const {
    isAllChildRoute,
    isDueToPayChildRoute,
    isOverDueChildRoute,
    isPendingChildRoute,
    isPaidChildRoute,
    // isVoidedChildRoute,
  } = useVBillPayLedgerChildRoute();
  const allRouteUrl = useVBillPayLedgerQuickFilterUrl(VBillPayLedgerChildRoutes.ALL);
  const dueToPayRouteUrl = useVBillPayLedgerQuickFilterUrl(VBillPayLedgerChildRoutes.DUE_TO_PAY);
  const overDueRouteUrl = useVBillPayLedgerQuickFilterUrl(VBillPayLedgerChildRoutes.OVER_DUE);
  const pendingRouteUrl = useVBillPayLedgerQuickFilterUrl(VBillPayLedgerChildRoutes.PENDING);
  const paidRouteUrl = useVBillPayLedgerQuickFilterUrl(VBillPayLedgerChildRoutes.PAID);
  // const voidedRouteUrl = useVBillPayLedgerQuickFilterUrl(VBillPayLedgerChildRoutes.VOIDED);

  const billsLedgerGroupStats = useMemo(
    () => vBillPayLedgerStore.billsLedgerGeneralStats?.data?.groupStats,
    [vBillPayLedgerStore.billsLedgerGeneralStats?.data?.groupStats],
  );
  const billsLedgerGeneralStats = useMemo(
    () => vBillPayLedgerStore.billsLedgerGeneralStats?.data?.generalStats,
    [vBillPayLedgerStore.billsLedgerGeneralStats?.data?.generalStats],
  );
  const billsLedgerDueToPayGeneralStats = useMemo(
    () => vBillPayLedgerStore.billsLedgerDueToPayStats.data?.generalStats,
    [vBillPayLedgerStore.billsLedgerDueToPayStats.data?.generalStats],
  );
  const billsLedgerOverDueGeneralStats = useMemo(
    () => vBillPayLedgerStore.billsLedgerOverDueStats.data?.generalStats,
    [vBillPayLedgerStore.billsLedgerOverDueStats.data?.generalStats],
  );
  const paidStats = useMemo(
    () => billsLedgerGroupStats?.find(({ value }) => value === IVBillInvoiceIntegrationStatus.InvoicePaid),
    [billsLedgerGroupStats],
  );
  const pendingStats = useMemo(
    () => billsLedgerGroupStats?.find(({ value }) => value === IVBillInvoiceIntegrationStatus.InvoicePaymentPending),
    [billsLedgerGroupStats],
  );
  // const voidedStats = useMemo(
  //   () => billsLedgerGroupStats?.find(({ value }) => value === IVBillInvoiceIntegrationStatus.InvoiceVoided),
  //   [billsLedgerGroupStats],
  // );

  return (
    <Box
      sx={{
        display: "flex",
        gap: "10px",
        padding: "20px 0 10px",
      }}
    >
      <Chip
        label={
          <div>
            {vBillPayLedgerStore.billsLedgerGeneralStats.isLoading ? (
              <Skeleton variant="rounded" sx={{ height: "22px", width: "100px" }} />
            ) : (
              <>
                <Typography>All ({billsLedgerGeneralStats?.count ?? 0})</Typography>
                <Typography>{formatCurrency(Number(billsLedgerGeneralStats?.amount ?? 0))}</Typography>
              </>
            )}
          </div>
        }
        clickable
        component={Link}
        to={allRouteUrl}
        icon={
          <Box
            sx={{
              ...vBillQuickFilterCircleSx,
              backgroundColor: "#B5ECA6",
              border: `1px solid #6FD055`,
            }}
          >
            <LanguageIcon sx={{ color: "#6FD055" }} />
          </Box>
        }
        variant={isAllChildRoute ? "awesome" : "awesome-filled"}
        sx={{ flex: 1, maxWidth: "none" }}
      />

      <Chip
        label={
          <div>
            {vBillPayLedgerStore.billsLedgerGeneralStats.isLoading ? (
              <Skeleton variant="rounded" sx={{ height: "22px", width: "100px" }} />
            ) : (
              <>
                <Typography>Over Due ({billsLedgerOverDueGeneralStats?.count ?? 0})</Typography>
                <Typography>{formatCurrency(Number(billsLedgerOverDueGeneralStats?.amount ?? 0))}</Typography>
              </>
            )}
          </div>
        }
        clickable
        component={Link}
        to={overDueRouteUrl}
        icon={
          <Box
            sx={{
              ...vBillQuickFilterCircleSx,
              backgroundColor: (billsLedgerOverDueGeneralStats?.count ?? 0) > 0 ? "#FFF3E1" : "#B5ECA6",
              border: `1px solid ${(billsLedgerOverDueGeneralStats?.count ?? 0) > 0 ? "#ECBB71" : "#6FD055"}`,
            }}
          >
            <PriorityHighIcon
              sx={{ color: (billsLedgerOverDueGeneralStats?.count ?? 0) > 0 ? "#ECBB71" : "#6FD055" }}
            />
          </Box>
        }
        variant={isOverDueChildRoute ? "awesome" : "awesome-filled"}
        sx={{ flex: 1, maxWidth: "none" }}
      />

      <Chip
        label={
          <div>
            {vBillPayLedgerStore.billsLedgerGeneralStats.isLoading ? (
              <Skeleton variant="rounded" sx={{ height: "22px", width: "100px" }} />
            ) : (
              <>
                <Typography>Due to Pay ({billsLedgerDueToPayGeneralStats?.count ?? 0})</Typography>
                <Typography>{formatCurrency(Number(billsLedgerDueToPayGeneralStats?.amount ?? 0))}</Typography>
              </>
            )}
          </div>
        }
        clickable
        component={Link}
        to={dueToPayRouteUrl}
        icon={
          <Box
            sx={{
              ...vBillQuickFilterCircleSx,
              backgroundColor: (billsLedgerDueToPayGeneralStats?.count ?? 0) > 0 ? "#FFF3E1" : "#B5ECA6",
              border: `1px solid ${(billsLedgerDueToPayGeneralStats?.count ?? 0) > 0 ? "#ECBB71" : "#6FD055"}`,
            }}
          >
            <CalendarMonthIcon
              sx={{ color: (billsLedgerDueToPayGeneralStats?.count ?? 0) > 0 ? "#ECBB71" : "#6FD055" }}
            />
          </Box>
        }
        variant={isDueToPayChildRoute ? "awesome" : "awesome-filled"}
        sx={{ flex: 1, maxWidth: "none" }}
      />

      <Chip
        label={
          <div>
            {vBillPayLedgerStore.billsLedgerGeneralStats.isLoading ? (
              <Skeleton variant="rounded" sx={{ height: "22px", width: "100px" }} />
            ) : (
              <>
                <Typography>Pending ({pendingStats?.count ?? 0})</Typography>
                <Typography>{formatCurrency(Number(pendingStats?.amount ?? 0))}</Typography>
              </>
            )}
          </div>
        }
        clickable
        component={Link}
        to={pendingRouteUrl}
        icon={
          <Box
            sx={{
              ...vBillQuickFilterCircleSx,
              backgroundColor: (pendingStats?.count ?? 0) > 0 ? "#FFF3E1" : "#B5ECA6",
              border: `1px solid ${(pendingStats?.count ?? 0) > 0 ? "#ECBB71" : "#6FD055"}`,
            }}
          >
            <SyncIcon sx={{ color: (pendingStats?.count ?? 0) > 0 ? "#ECBB71" : "#6FD055" }} />
          </Box>
        }
        variant={isPendingChildRoute ? "awesome" : "awesome-filled"}
        sx={{ flex: 1, maxWidth: "none" }}
      />

      <Chip
        label={
          <div>
            {vBillPayLedgerStore.billsLedgerGeneralStats.isLoading ? (
              <Skeleton variant="rounded" sx={{ height: "22px", width: "100px" }} />
            ) : (
              <>
                <Typography>Paid ({paidStats?.count ?? 0})</Typography>
                <Typography>{formatCurrency(Number(paidStats?.amount ?? 0))}</Typography>
              </>
            )}
          </div>
        }
        clickable
        component={Link}
        to={paidRouteUrl}
        icon={
          <Box
            sx={{
              ...vBillQuickFilterCircleSx,
              backgroundColor: (paidStats?.count ?? 0) > 0 ? "#FFF3E1" : "#B5ECA6",
              border: `1px solid ${(paidStats?.count ?? 0) > 0 ? "#ECBB71" : "#6FD055"}`,
            }}
          >
            <DoneIcon sx={{ color: (paidStats?.count ?? 0) > 0 ? "#ECBB71" : "#6FD055" }} />
          </Box>
        }
        variant={isPaidChildRoute ? "awesome" : "awesome-filled"}
        sx={{ flex: 1, maxWidth: "none" }}
      />

      {/* <Chip
        label={
          <div>
            {vBillPayLedgerStore.billsLedgerGeneralStats.isLoading ? (
              <Skeleton variant="rounded" sx={{ height: "22px", width: "100px" }} />
            ) : (
              <>
                <Typography>Voided ({voidedStats?.count ?? 0})</Typography>
                <Typography>{formatCurrency(Number(voidedStats?.amount ?? 0))}</Typography>
              </>
            )}
          </div>
        }
        clickable
        component={Link}
        to={voidedRouteUrl}
        icon={
          <Box
            sx={{
              ...vBillQuickFilterCircleSx,
              backgroundColor: (voidedStats?.count ?? 0) > 0 ? "#FFF3E1" : "#B5ECA6",
              border: `1px solid ${(voidedStats?.count ?? 0) > 0 ? "#ECBB71" : "#6FD055"}`,
            }}
          >
            <CloseIcon sx={{ color: (voidedStats?.count ?? 0) > 0 ? "#ECBB71" : "#6FD055" }} />
          </Box>
        }
        variant={isVoidedChildRoute ? "awesome" : "awesome-filled"}
        sx={{ flex: 1, maxWidth: "none" }}
      /> */}
    </Box>
  );
});
