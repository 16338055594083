import { observer } from "mobx-react-lite";
import { useStore } from "storeContainer";
import { ErrorContainer } from "./ErrorContainer";

export const GenericError: React.FunctionComponent = observer(function GenericError() {
  const sessionStore = useStore("SessionStore");

  return (
    <ErrorContainer title="An error ocurred.">
      <p>Oops... an error ocurred while processing your request.</p>
      {!sessionStore.isLoggedIn ? (
        <p>
          If you have an account with us, <a href="/login">logging in</a> might resolve this issue.
        </p>
      ) : (
        <p>
          Please try refreshing the page or <a href="/contact">contact support</a> if this issue persists.
        </p>
      )}
    </ErrorContainer>
  );
});
