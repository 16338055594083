import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Button, Checkbox, Typography } from "@mui/material";
import React from "react";
import { NodesWithChildren } from "./TreeSelect";
import styles from "./TreeSelect.module.scss";

interface TreeSelectListItemProps {
  option: NodesWithChildren;
  handleCheckboxChange: (id: string) => void;
  highlightTerm?: string;
  style: React.CSSProperties;
  listRef: any;
  index: number;
  insideDrawer?: boolean;
  handleOnlyButtonClick: (id: string) => void;
}

const highlightMatchingText = (text: string, highlightTerm?: string) => {
  if (!highlightTerm) {
    return text;
  }

  const regex = new RegExp(`(${highlightTerm})`, "gi");
  const parts = text.split(regex);

  return parts.map((part, i) =>
    regex.test(part) ? (
      <strong key={i} style={{ fontWeight: "bold" }}>
        {part}
      </strong>
    ) : (
      part
    ),
  );
};

export const TreeSelectListItem = React.memo(
  ({
    option,
    handleCheckboxChange,
    highlightTerm,
    style,
    listRef,
    index,
    insideDrawer,
    handleOnlyButtonClick,
  }: TreeSelectListItemProps) => {
    const handleClick = () => {
      if (listRef.current) {
        listRef.current.resetAfterIndex(index);
      }

      option.showChildren = !option.showChildren;
    };
    const iconStyles = {
      position: "absolute",
      right: insideDrawer ? "0px" : "15px",
      cursor: "pointer",
      fontSize: "18px",
    };
    return (
      <li style={style}>
        <Box className={styles.filterListParent}>
          <Checkbox
            color="secondary"
            checked={option.checked}
            indeterminate={
              !option.children?.every((child) => child.checked) && option.children?.some((child) => child.checked)
            }
            onClick={() => handleCheckboxChange(option.id)}
          />
          <Box display="flex" alignItems="center" width="100%">
            <Box onClick={handleClick}>
              <Typography className={styles.filterCompanyName} title={option.label}>
                {highlightMatchingText(option.label, highlightTerm)}
              </Typography>
              <Typography className={styles.filterBankAccountsCount}>
                {option.children?.length} Bank Accounts
              </Typography>
            </Box>
            {!option.showChildren ? (
              <ExpandMoreIcon sx={iconStyles} onClick={handleClick} />
            ) : (
              <ExpandLessIcon sx={iconStyles} onClick={handleClick} />
            )}
          </Box>
        </Box>
        {option.children && option.showChildren && (
          <ul className={styles.filterChildrenContainer}>
            {option.children.map((child) => (
              <li key={child.id} className={styles.childContainer} onClick={() => handleCheckboxChange(child.id)}>
                <Box display="flex" maxWidth="200px">
                  <Checkbox color="secondary" checked={child.checked} />
                  <Typography
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    maxWidth="200px"
                    title={child.label}
                    sx={{ cursor: "pointer" }}
                  >
                    {highlightMatchingText(child.label, highlightTerm)}
                  </Typography>
                </Box>
                <Button
                  variant="text"
                  size="small"
                  className="only-hover"
                  sx={{
                    visibility: "hidden",
                  }}
                  title="Select only this bank account"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    handleOnlyButtonClick(child.id);
                  }}
                >
                  only
                </Button>
              </li>
            ))}
          </ul>
        )}
      </li>
    );
  },
);
