import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Dialog, Divider, IconButton, Typography } from "@mui/material";
import { useState } from "react";

interface IConfirmBatchActionDialogProps {
  title: string;
  text: string;
  confirmBtnText: string;
  onCloseDialog: () => void;
  onConfirmSubmit: () => Promise<void>;
  actionsBtnSmallHeight?: boolean;
}
export const ConfirmBatchActionDialog = ({
  title,
  text,
  confirmBtnText,
  onCloseDialog,
  onConfirmSubmit,
  actionsBtnSmallHeight,
}: IConfirmBatchActionDialogProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleConfirmBtnClick = async () => {
    setIsLoading(true);
    await onConfirmSubmit();
    setIsLoading(false);
  };

  return (
    <Dialog
      open
      onClose={onCloseDialog}
      PaperProps={{
        sx: { padding: "15px", width: 350, position: "relative" },
      }}
    >
      <IconButton onClick={onCloseDialog} sx={{ position: "absolute", top: "5px", right: "5px", zIndex: 1 }}>
        <CloseIcon sx={{ fontSize: "20px" }} />
      </IconButton>

      <Typography sx={{ fontSize: "20px", marginBottom: "20px" }}>{title}</Typography>

      <Typography>{text}</Typography>

      <Divider sx={{ margin: "20px 0" }} />

      <Box sx={{ display: "flex", justifyContent: "end", gap: "15px" }}>
        <LoadingButton
          loading={isLoading}
          variant="contained"
          onClick={handleConfirmBtnClick}
          sx={{ ...(actionsBtnSmallHeight ? { height: "30px" } : {}) }}
        >
          {confirmBtnText}
        </LoadingButton>

        <Button
          variant="outlined"
          onClick={onCloseDialog}
          sx={{ ...(actionsBtnSmallHeight ? { height: "30px" } : {}) }}
        >
          Cancel
        </Button>
      </Box>
    </Dialog>
  );
};
