import { memo } from "react";
import type { ComponentProps, ReactNode } from "react";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "components/common/IconButton";
import { ExpandMore } from "components/common/icons";

const Header = ({ className, expanded, title, titleTypographyProps, onExpandToggle, ...rest }: Props) => {
  return (
    <CardHeader
      action={
        onExpandToggle && (
          <IconButton sx={{ transform: expanded ? "rotate(180deg)" : "rotate(0deg)" }} onClick={onExpandToggle}>
            <ExpandMore />
          </IconButton>
        )
      }
      className={className}
      title={title}
      titleTypographyProps={{ variant: "h6", ...titleTypographyProps }}
      {...rest}
    />
  );
};

type Props = ComponentProps<typeof CardHeader> & {
  className?: string;
  expanded?: boolean;
  title?: string | ReactNode;
  onExpandToggle?: () => void;
};

export default memo(Header);
