import { useMemo } from "react";
import { useParams } from "react-router";
import IconButton from "components/common/IconButton";
import { Delete } from "components/common/icons";
import { useStore } from "storeContainer";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
function useColumns(
  setAutoDepositAddressesLoading: Function,
  fetchAutoDepositAvailableAddreses: Function,
  fetchAutoDepositAddreses: Function,
  isReadOnly?: boolean,
) {
  const { accountId } = useParams<{ accountId: string }>();
  const { deleteAutoDepositAddress } = useStore("AccountsStore");

  return useMemo(
    () =>
      [
        {
          Header: "EMAIL ADDRESS",
          accessor: "email",
          headerProps: { width: "40%" },
        },
        {
          Header: "TYPE",
          accessor: "type",
          Cell: ({ row }: any) => (
            <>
              {row.values.type}
              {/*  <Link to={`/org/${Cookies.get("lastOrg")}/users2/permissions/user/${row.original.userId}`}>
              {row.values.type}
            </Link>
          TEMPORARILY DISABLED
          */}
            </>
          ),
          cellProps: { size: "small" },
          headerProps: { width: "25%" },
        },
        {
          Header: "ADDED BY",
          accessor: "created_by",
          headerProps: { width: "30%" },
        },
        !isReadOnly
          ? {
              accessor: "delete",
              disableSortBy: true,
              Cell: ({ row }: any) => (
                <IconButton
                  color="error"
                  onClick={async () => {
                    setAutoDepositAddressesLoading(true);
                    await deleteAutoDepositAddress(accountId, row.original.id);
                    fetchAutoDepositAvailableAddreses();
                    fetchAutoDepositAddreses();
                  }}
                >
                  <Delete />
                </IconButton>
              ),
              cellProps: { align: "center", size: "small" },
              headerProps: { align: "center", width: "5%" },
            }
          : undefined,
      ].filter((e) => !!e),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accountId, deleteAutoDepositAddress],
  );
}

export default useColumns;
