import { SvgIcon, SvgIconProps } from "@mui/material";

export const CalendarIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="15px" height="16px" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.25"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.36364 0C4.7653 0 5.09091 0.325611 5.09091 0.727273V1.45455H9.45455V0.727273C9.45455 0.325611 9.78016 0 10.1818 0C10.5835 0 10.9091 0.325611 10.9091 0.727273V1.45455H12.3636C13.5686 1.45455 14.5455 2.43138 14.5455 3.63636V13.8182C14.5455 15.0232 13.5686 16 12.3636 16H2.18182C0.976834 16 0 15.0232 0 13.8182V3.63636C0 2.43138 0.976833 1.45455 2.18182 1.45455H3.63636V0.727273C3.63636 0.325611 3.96198 0 4.36364 0ZM3.63636 2.90909H2.18182C1.78016 2.90909 1.45455 3.2347 1.45455 3.63636V5.81818H13.0909V3.63636C13.0909 3.2347 12.7653 2.90909 12.3636 2.90909H10.9091V3.63636C10.9091 4.03803 10.5835 4.36364 10.1818 4.36364C9.78016 4.36364 9.45455 4.03803 9.45455 3.63636V2.90909H5.09091V3.63636C5.09091 4.03803 4.7653 4.36364 4.36364 4.36364C3.96198 4.36364 3.63636 4.03803 3.63636 3.63636V2.90909ZM13.0909 7.27273H1.45455V13.8182C1.45455 14.2198 1.78016 14.5455 2.18182 14.5455H12.3636C12.7653 14.5455 13.0909 14.2198 13.0909 13.8182V7.27273Z"
        fill="#052048"
      />
    </SvgIcon>
  );
};
