import CloseIcon from "@mui/icons-material/Close";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchIcon from "@mui/icons-material/Search";
import {
  Backdrop,
  Box,
  Button,
  Collapse,
  InputAdornment,
  InputLabel,
  Menu,
  TextField,
  Typography,
} from "@mui/material";
import { useSharedCompanyBankAccountFilters } from "common/hooks/useSharedCompanyBankAccountsFilters";
import { CheckFiltersType, CompanyWithBankAccountsAndAggregatedData } from "generated/sdk";
import { isEmpty } from "lodash";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useStore } from "storeContainer";
import { COLORS } from "themes/default";
import { TreeSelectFooter } from "./TreeSelectFooter";
import { TreeSelectList } from "./TreeSelectList";

export interface NodesWithChildren {
  id: string;
  value: string;
  label: string;
  checked: boolean;
  show: boolean;
  showChildren?: boolean;
  indeterminate?: boolean;
  children?: NodesWithChildren[];
}

interface DropdownSelectProps {
  isLoading: boolean;
  error?: any;
  insideDrawer: boolean;
  filtersPayload?: CheckFiltersType;
  setFiltersPayload?: React.Dispatch<React.SetStateAction<CheckFiltersType | undefined>>;

  filters?: ExtendedVCheckChecksFilterInput;
  setFilters: React.Dispatch<React.SetStateAction<ExtendedVCheckChecksFilterInput>>;
}

interface ExtendedVCheckChecksFilterInput extends CheckFiltersType {
  [key: string]: any;
}

export const TreeSelect = function TreeSelect({
  isLoading,
  error,
  insideDrawer = false,
  filtersPayload,
  setFiltersPayload,
  filters,
  setFilters,
}: DropdownSelectProps) {
  const userSettingsStore = useStore("UserSettingsStore");
  const OrganizationStore = useStore("OrganizationStore");
  const { organizationCompaniesWithBankaccounts } = OrganizationStore;

  const companies = useMemo(
    () => organizationCompaniesWithBankaccounts?.data ?? [],
    [organizationCompaniesWithBankaccounts?.data],
  );

  const data = useMemo(
    () =>
      userSettingsStore.companiesSelectedIds.length > 0
        ? companies?.reduce<TreeCompOption[]>((result, company) => {
            if (userSettingsStore.companiesSelectedIds.includes(company.id)) {
              result = [...result, getTreeSelectCompOption(company)];
            }

            return result;
          }, [])
        : companies?.map((company) => getTreeSelectCompOption(company)) || [],
    [companies, userSettingsStore.companiesSelectedIds],
  );

  const [searchTerm, setSearchTerm] = useState("");
  const [options, setOptions] = useState(data!);
  const { counts, setCounts, label, hasCompaniesSelected, allCompaniesBankAccountsAreSelected, currentBankAccounts } =
    useSharedCompanyBankAccountFilters();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<any>(null);
  const countSelected = useCallback((nodes: NodesWithChildren[]) => {
    let parentCount = 0;
    let childCount = 0;
    for (const node of nodes) {
      if (node.checked) {
        parentCount++;
        childCount += node?.children?.filter((child) => child.checked).length ?? 0;
      } else if (node.children) {
        childCount += node.children.filter((child) => child.checked).length;
        if (node.children.some((child) => child.checked)) {
          parentCount++;
        }
      }
    }
    return { parents: parentCount, children: childCount };
  }, []);

  const getIds = (node: NodesWithChildren) => {
    let checkedChildrenIds: string[] = [];

    if (node.children && node.children.length > 0) {
      for (let child of node.children) {
        if (child.checked) {
          checkedChildrenIds.push(child.id);
        }
      }
    }

    return checkedChildrenIds;
  };

  const updateFilters = useCallback(
    (_option: NodesWithChildren) => {
      if (insideDrawer) {
        const checkedIds = options
          .map((option) => getIds(option))
          .filter((ids) => ids.length > 0)
          .flat();
        const hasOnlyBankFilters =
          Object.keys(filtersPayload || {})?.length === 1 && "bank_accounts" in filtersPayload!;

        if (setFiltersPayload) {
          if (isEmpty(filtersPayload) || hasOnlyBankFilters) {
            setFiltersPayload(checkedIds?.length ? { bank_accounts: checkedIds } : undefined);
          } else {
            setFiltersPayload((prevFiltersPayload) => ({
              ...prevFiltersPayload,
              bank_accounts: checkedIds?.length ? checkedIds : undefined,
            }));
          }
        }
      }
    },
    [filtersPayload, insideDrawer, options, setFiltersPayload],
  );

  const updateOptions = useCallback(
    (prevOptions: typeof data, updatedOption) =>
      prevOptions.map((option) => (option.id === updatedOption.id ? updatedOption : option)),
    [],
  );

  const handleCheckboxChange = useCallback(
    (id: string) => {
      setOptions((prevOptions) => {
        let newOption: {
          id: string;
          value: string;
          label: string;
          checked: boolean;
          show: boolean;
          showChildren: boolean;
          children:
            | {
                id: string;
                value: string;
                label: string;
                checked: boolean;
                show: boolean;
              }[]
            | undefined;
        } | null = null;

        const updatedOptions = prevOptions.map((option) => {
          if (option.id === id) {
            newOption = { ...option, checked: !option.checked };
            newOption.children?.forEach((child) => {
              child.checked = !!newOption?.checked;
              child.show = !!newOption?.checked;
            });
            return newOption;
          }

          const foundChild = option.children?.find((child) => child.id === id);
          if (foundChild) {
            newOption = { ...option };
            newOption.children = newOption.children?.map((child) => {
              if (child.id === id) {
                return { ...child, checked: !child.checked, show: !child.checked };
              }
              return child;
            });
            newOption.checked = newOption.children?.every((child) => child.checked) ?? false;
            return newOption;
          }
          return option;
        });

        if (newOption) {
          const updatedCounts = countSelected(updatedOptions);
          setCounts(updatedCounts);
          updateFilters(newOption);
        }

        return updatedOptions;
      });
    },
    [countSelected, setCounts, updateFilters],
  );

  const handleOnlyButtonClick = useCallback(
    (id: string) => {
      setOptions((prevOptions) => {
        const updatedOptions = prevOptions.map((option) => ({
          ...option,
          children: option.children?.map((child) => {
            console.log("🍒 ~ child:", child, id);
            return {
              ...child,
              checked: child.id === id,
              show: child.id === id,
            };
          }),
        }));

        const updatedCounts = countSelected(updatedOptions);
        setCounts(updatedCounts);

        return updatedOptions;
      });
    },
    [countSelected, setCounts],
  );

  const handleSearch = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  }, []);

  const handleToggleOptions = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (anchorEl) {
        setAnchorEl(null);
        setSearchTerm("");
      } else {
        setAnchorEl(event.currentTarget);
      }
    },
    [anchorEl],
  );
  const regex = new RegExp(searchTerm, "i");
  const searchNodes = (nodes: NodesWithChildren[], searchTerm: string): NodesWithChildren[] => {
    const matchedNodes: NodesWithChildren[] = [];
    // Traverse through all the nodes and their children
    nodes?.forEach((node) => {
      const labelMatch = regex.test(node.label);
      // If the node matches the search term, add it to the matched nodes array
      if (labelMatch) {
        matchedNodes.push(node);
      }
      // If the node has children, recursively search through them
      if (node.children) {
        const matchedChildren = searchNodes(node.children, searchTerm);
        if (matchedChildren.length && !matchedNodes.includes(node)) {
          matchedNodes.push(node);
        }
      }
    });

    return matchedNodes;
  };
  const displayedOptions = searchNodes(options, searchTerm);

  const resetOptions = useCallback(() => {
    setOptions((prevOptions) => {
      const updatedOptions = prevOptions.map((option) => ({
        ...option,
        checked: false,
        show: true,
        // showChildren: false,
        children: option.children?.map((child) => ({ ...child, checked: false, show: true })),
      }));
      const updatedCounts = countSelected(updatedOptions);
      setCounts(updatedCounts);
      return updatedOptions;
    });
    if (isEmpty(filters)) {
      setFilters({ bank_accounts: hasCompaniesSelected ? currentBankAccounts : undefined });
    } else {
      setFilters((prevFilters) => ({
        ...prevFilters,
        bank_accounts: hasCompaniesSelected ? currentBankAccounts : undefined,
      }));
    }
    setSearchTerm("");
  }, [countSelected, currentBankAccounts, filters, hasCompaniesSelected, setCounts, setFilters]);

  const handleOnDone = () => {
    if (listRef.current) {
      listRef.current.resetAfterIndex(0);
    }
    const checkedIds = options
      .map((option) => getIds(option))
      .filter((ids) => ids.length > 0)
      .flat();
    const hasOnlyBankFilters = Object.keys(filters || {})?.length === 1 && "bank_accounts" in filters!;

    if (isEmpty(filters) || hasOnlyBankFilters) {
      setFilters(checkedIds?.length ? { bank_accounts: checkedIds } : {});
    } else {
      setFilters((prevFilters) => ({ ...prevFilters, bank_accounts: checkedIds?.length ? checkedIds : undefined }));
    }

    setAnchorEl(null);
    setSearchTerm("");
  };
  const handleClose = () => {
    if (listRef.current) {
      listRef.current.resetAfterIndex(0);
    }
    setAnchorEl(null);
  };

  useEffect(() => {
    if (filters?.bank_accounts) {
      const updatedOptions = data.map((item) => {
        return {
          ...item,
          checked: !!item?.children?.every((child) => filters?.bank_accounts?.includes(child.id)),
          children: item.children?.map((child) => ({
            ...child,
            checked: !!filters?.bank_accounts?.includes(child.id),
          })),
        };
      });
      setOptions(updatedOptions);
      const updatedCounts = countSelected(updatedOptions);
      setCounts(updatedCounts);
      setSearchTerm("");
    } else if (!filters?.bank_accounts) {
      setOptions(
        data.map((item) => ({
          ...item,
          checked: false,
          children: item.children?.map((child) => ({ ...child, checked: false })),
        })),
      );
      setCounts({ parents: 0, children: 0 });
      setSearchTerm("");
    }
  }, [countSelected, data, filters?.bank_accounts, insideDrawer, setCounts, updateOptions]);

  // useEffect(() => {
  //   if (
  //     (filters?.bank_accounts?.length as number) > 0 &&
  //     filters?.bank_accounts?.some((item) =>
  //       data.flatMap((node) => node.children?.map((child) => child.id)).includes(item as string),
  //     )
  //   ) {
  //     setFilterDiffWarning(true);
  //   }
  // }, [data, filters?.bank_accounts, setFilterDiffWarning]);

  return (
    <Box
      position="relative"
      display="flex"
      flexDirection={"column"}
      sx={{ borderBottom: !insideDrawer ? "none" : "1px solid #D4DEED" }}
    >
      <Button
        variant={
          insideDrawer
            ? "text"
            : (filters?.bank_accounts?.length as number) > 0 && !allCompaniesBankAccountsAreSelected
              ? "containedFilters"
              : "outlined"
        }
        onClick={handleToggleOptions}
        fullWidth={insideDrawer}
        endIcon={!open ? <ExpandMoreIcon /> : <ExpandLessIcon />}
        sx={{
          justifyContent: "center",
          paddingX: insideDrawer ? "0px" : "10px",
          borderRadius: insideDrawer ? "0px" : "5px",
          maxWidth: "unset",
          width: "150px",
          height: insideDrawer ? "67px" : "42px",
          ...(insideDrawer && { borderBottom: "1px solid #DFE7F2", fontWeight: "600", border: "none !important" }),
        }}
      >
        {insideDrawer ? (
          <p style={{ fontSize: "12px" }}>Companies / Bank accounts</p>
        ) : (
          <Typography
            variant="body1"
            title={label}
            maxWidth="200px"
            width={"100%"}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
            fontSize={"12px"}
            color={
              (filters?.bank_accounts?.length as number) > 0 && !allCompaniesBankAccountsAreSelected
                ? COLORS.TopHeaderText
                : "#697281"
            }
          >
            {label}
          </Typography>
        )}
      </Button>
      {!insideDrawer ? (
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleOnDone}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          ref={dropdownRef}
          BackdropComponent={Backdrop}
          MenuListProps={{ style: { padding: "20px", width: "340px" } }}
        >
          <TextField
            value={searchTerm}
            onChange={handleSearch}
            placeholder="Search Companies / Bank accounts"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon sx={{ fontSize: "18px" }} />
                </InputAdornment>
              ),
              ...(searchTerm?.length > 0 && {
                endAdornment: (
                  <InputAdornment position="end">
                    <CloseIcon
                      onClick={() => {
                        setSearchTerm("");
                        // resetOptions();
                      }}
                      sx={{ cursor: "pointer", fontSize: "18px" }}
                    />
                  </InputAdornment>
                ),
              }),
            }}
            fullWidth
            disabled={isLoading}
          />
          <TreeSelectList
            displayedOptions={displayedOptions}
            handleCheckboxChange={handleCheckboxChange}
            handleOnlyButtonClick={handleOnlyButtonClick}
            highlightTerm={searchTerm}
            listRef={listRef}
            insideDrawer={false}
          />
          <TreeSelectFooter
            onCancel={resetOptions}
            onDone={handleOnDone}
            counts={counts}
            hideClearButon={allCompaniesBankAccountsAreSelected}
          />
        </Menu>
      ) : (
        <Collapse in={open} sx={{ p: 0, m: 0 }}>
          <TextField
            value={searchTerm}
            onChange={handleSearch}
            placeholder="Search Companies / Bank accounts"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {searchTerm.length === 0 ? (
                    <SearchIcon />
                  ) : (
                    <CloseIcon
                      onClick={() => {
                        setSearchTerm("");
                        // resetOptions();
                      }}
                      sx={{ cursor: "pointer" }}
                    />
                  )}
                </InputAdornment>
              ),
            }}
            fullWidth
            disabled={isLoading}
          />
          <TreeSelectList
            displayedOptions={displayedOptions}
            handleCheckboxChange={handleCheckboxChange}
            handleOnlyButtonClick={handleOnlyButtonClick}
            highlightTerm={searchTerm}
            listRef={listRef}
            insideDrawer
          />
        </Collapse>
      )}
    </Box>
  );
};

export interface TreeCompOption {
  id: string;
  value: string;
  label: string;
  checked: boolean;
  show: boolean;
  showChildren: boolean;
  children:
    | {
        id: string;
        value: string;
        label: string;
        checked: boolean;
        show: boolean;
      }[]
    | undefined;
}

export const getTreeSelectCompOption = (company: CompanyWithBankAccountsAndAggregatedData): TreeCompOption => ({
  id: company.id,
  value: company.id,
  label: company.name,
  checked: false,
  show: true,
  showChildren: false,
  children: company?.bank_accounts?.map((bank_account) => ({
    id: bank_account.id,
    value: bank_account.id,
    label: `${bank_account.name} **${bank_account.account_number}`,
    checked: false,
    show: true,
  })),
});
