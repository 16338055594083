import { SvgIcon, SvgIconProps } from "@mui/material";

export const TrashIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g opacity="0.75">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.66732 2.00002C6.49051 2.00002 6.32094 2.07026 6.19591 2.19528C6.07089 2.32031 6.00065 2.48988 6.00065 2.66669V3.33335H10.0007V2.66669C10.0007 2.48988 9.93041 2.32031 9.80539 2.19528C9.68036 2.07026 9.5108 2.00002 9.33398 2.00002H6.66732ZM11.334 3.33335V2.66669C11.334 2.13625 11.1233 1.62755 10.7482 1.25247C10.3731 0.877401 9.86442 0.666687 9.33398 0.666687H6.66732C6.13688 0.666687 5.62818 0.877401 5.2531 1.25247C4.87803 1.62755 4.66732 2.13625 4.66732 2.66669V3.33335H2.00065C1.63246 3.33335 1.33398 3.63183 1.33398 4.00002C1.33398 4.36821 1.63246 4.66669 2.00065 4.66669H2.66732V13.3334C2.66732 13.8638 2.87803 14.3725 3.2531 14.7476C3.62818 15.1226 4.13688 15.3334 4.66732 15.3334H11.334C11.8644 15.3334 12.3731 15.1226 12.7482 14.7476C13.1233 14.3725 13.334 13.8638 13.334 13.3334V4.66669H14.0007C14.3688 4.66669 14.6673 4.36821 14.6673 4.00002C14.6673 3.63183 14.3688 3.33335 14.0007 3.33335H11.334ZM4.00065 4.66669V13.3334C4.00065 13.5102 4.07089 13.6797 4.19591 13.8048C4.32094 13.9298 4.49051 14 4.66732 14H11.334C11.5108 14 11.6804 13.9298 11.8054 13.8048C11.9304 13.6797 12.0007 13.5102 12.0007 13.3334V4.66669H4.00065ZM6.66732 6.66669C7.03551 6.66669 7.33398 6.96516 7.33398 7.33335V11.3334C7.33398 11.7015 7.03551 12 6.66732 12C6.29913 12 6.00065 11.7015 6.00065 11.3334V7.33335C6.00065 6.96516 6.29913 6.66669 6.66732 6.66669ZM8.66732 7.33335C8.66732 6.96516 8.96579 6.66669 9.33398 6.66669C9.70218 6.66669 10.0007 6.96516 10.0007 7.33335V11.3334C10.0007 11.7015 9.70218 12 9.33398 12C8.96579 12 8.66732 11.7015 8.66732 11.3334V7.33335Z"
          fill="#052048"
          stroke="white"
          strokeWidth="0.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
};
