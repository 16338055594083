import { Integration } from "./../generated/graphql";
import { makeObservable, observable } from "mobx";

export enum AddBankAccountSource {
  Unknown = "unknown",
  Company = "company",
  DepositOnlyUpgrade = "depositOnlyUpgrade",
}

export class UiStore {
  @observable showInviteUserModal: true | undefined;
  @observable showUndepositedChecksModal: true | undefined;
  @observable showAddBankAccountModal: AddBankAccountSource | undefined;
  @observable showAddIntegrationModal: Integration | undefined;
  @observable confirm: true | undefined;

  constructor() {
    makeObservable(this);
  }
}
