import React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export const ActionRequired: React.FC<SvgIconProps> = ({ fill, stroke, ...props }) => {
  return (
    <SvgIcon width="44" height="44" viewBox="0 0 44 44" fill="none" {...props}>
      <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="44" height="44" rx="22" fill={fill} />
        <path
          d="M10.8893 15.9217C9.4148 15.9217 8.21542 14.8573 8.21542 13.5486V10.4518C8.21542 9.14317 9.4148 8.0787 10.8893 8.0787H22.7387C24.2128 8.0787 25.4126 9.14317 25.4126 10.4518V13.5486C25.4126 14.4456 24.8487 15.2283 24.0188 15.6313C24.1783 15.7012 24.329 15.7867 24.468 15.8859C24.7996 15.7444 25.1666 15.6697 25.5342 15.6697C25.6217 15.6697 25.7087 15.6736 25.7943 15.6818C26.3164 15.0942 26.6281 14.3529 26.6281 13.5486V10.4518C26.6281 8.54859 24.8832 7 22.7387 7H10.8893C8.74486 7 7 8.54859 7 10.4518V13.5486C7 15.4519 8.74486 17.0004 10.8893 17.0004H17.3568L16.7365 15.9217H10.8893Z"
          fill={stroke}
        />
        <path
          d="M17.6364 12.6796C17.9806 12.5234 18.3666 12.441 18.7526 12.441C19.6851 12.441 20.5271 12.8975 20.9496 13.6319L21.6468 14.843H22.7387C23.5438 14.843 24.1972 14.2635 24.1972 13.5486V10.4518C24.1972 9.73688 23.5438 9.1574 22.7387 9.1574H10.8893C10.0838 9.1574 9.43084 9.73688 9.43084 10.4518V13.5486C9.43084 14.2635 10.0838 14.843 10.8893 14.843H16.3053C16.2722 14.547 16.3082 14.245 16.4146 13.9529C16.6179 13.3967 17.0515 12.9445 17.6364 12.6796Z"
          fill={stroke}
        />
        <path
          d="M36.9445 32.8241L35.7607 30.7664C35.6338 30.5455 35.3266 30.4575 35.0781 30.5701L27.3923 34.0534L27.3097 34.091C27.0612 34.2036 26.9621 34.4763 27.0885 34.6968L28.2733 36.7545C28.3997 36.9754 28.7074 37.0634 28.9558 36.9513L36.7238 33.4299C36.9722 33.3173 37.0719 33.0446 36.9445 32.8241ZM35.3363 32.6761C35.2882 32.8073 35.1846 32.923 35.0354 32.9907L34.1992 33.3721C33.9006 33.508 33.5341 33.4036 33.3809 33.1387C33.3041 33.0062 33.2949 32.8587 33.3425 32.7275C33.3907 32.5959 33.4942 32.4807 33.6435 32.4125L34.4802 32.0315C34.7782 31.8952 35.1447 31.9996 35.2984 32.2645C35.3747 32.397 35.3835 32.5445 35.3363 32.6761Z"
          fill={stroke}
        />
        <path
          d="M34.4261 29.6333L34.501 29.5993C34.7358 29.4931 34.8535 29.2459 34.7573 29.028C34.7412 28.9922 34.7203 28.9594 34.6965 28.9296C34.6926 28.9227 34.6897 28.9158 34.6858 28.9089C34.5322 28.644 34.1661 28.5395 33.8676 28.6759L33.5637 28.8144L33.3109 28.3756C32.9473 27.7435 32.9123 27.0022 33.2147 26.3455C33.5175 25.6887 33.4821 24.9479 33.1184 24.3158L31.9934 22.3603L31.9487 22.2831L30.0512 18.9852L29.6243 18.2435L28.9991 17.1571C28.687 16.6147 27.9315 16.3977 27.3209 16.6742C26.9849 16.8265 26.7603 17.0975 26.6772 17.4004L26.649 17.3512C26.3368 16.8089 25.5818 16.5918 24.9707 16.8688C24.6353 17.0207 24.4102 17.2917 24.3275 17.595L24.0344 17.0863C23.9853 17.0009 23.925 16.9236 23.8564 16.855C23.4884 16.4878 22.871 16.3705 22.3561 16.6035C22.149 16.6975 21.9847 16.8365 21.8694 17.0004C21.7975 17.1023 21.7445 17.2136 21.7129 17.3297L21.5233 17.0004L20.9029 15.9217L20.2821 14.843L19.8674 14.1225C19.5553 13.5805 18.8003 13.3631 18.1892 13.6401C17.7584 13.8351 17.51 14.226 17.51 14.6294C17.51 14.7006 17.5178 14.7718 17.5333 14.843C17.5552 14.9405 17.5921 15.0368 17.6456 15.1295L18.1016 15.9217L18.722 17.0004L21.5447 21.9059L22.1616 22.9782L23.6381 25.5438C23.766 25.7655 23.6668 26.0374 23.4164 26.1504C23.3426 26.1841 23.2633 26.2 23.1855 26.2C23.0008 26.2 22.8223 26.1103 22.7329 25.9541L20.8879 22.7478C20.5675 22.1912 19.7921 21.9685 19.1649 22.2529C18.5377 22.5372 18.2869 23.225 18.6073 23.7816L20.6832 27.3896L21.3249 28.505C21.8019 29.3339 22.6376 29.9557 23.641 30.2275C24.1423 30.3634 24.6022 30.5869 24.996 30.8799C25.3898 31.1729 25.7184 31.5357 25.9567 31.95L26.1229 32.2391C25.9469 32.4009 25.8969 32.6494 26.0189 32.8604C26.0388 32.8949 26.0622 32.9264 26.0889 32.9549C26.0894 32.9562 26.0904 32.9575 26.0913 32.9588C26.2085 33.1193 26.4258 33.1879 26.6295 33.1495C26.7 33.1421 26.7705 33.1244 26.8371 33.0938L34.4228 29.6351C34.4243 29.6346 34.4252 29.6338 34.4261 29.6333Z"
          fill={stroke}
        />
        <rect width="44" height="44" rx="22" fill={fill} />
        <path
          d="M12.3708 16.7322C11.0928 16.7322 10.0534 15.8096 10.0534 14.6754V11.9916C10.0534 10.8574 11.0928 9.93488 12.3708 9.93488H22.6402C23.9177 9.93488 24.9576 10.8574 24.9576 11.9916V14.6754C24.9576 15.4529 24.4689 16.1312 23.7496 16.4805C23.8878 16.5411 24.0184 16.6151 24.1389 16.7011C24.4263 16.5785 24.7444 16.5138 25.063 16.5138C25.1388 16.5138 25.2142 16.5171 25.2884 16.5243C25.7409 16.0149 26.011 15.3725 26.011 14.6754V11.9916C26.011 10.3421 24.4988 9 22.6402 9H12.3708C10.5122 9 9 10.3421 9 11.9916V14.6754C9 16.3249 10.5122 17.667 12.3708 17.667H17.9759L17.4383 16.7322H12.3708Z"
          fill={stroke}
        />
        <path
          d="M18.2182 13.9223C18.5165 13.7869 18.8511 13.7155 19.1856 13.7155C19.9937 13.7155 20.7235 14.1112 21.0897 14.7476L21.6939 15.7973H22.6402C23.338 15.7973 23.9043 15.2951 23.9043 14.6754V11.9916C23.9043 11.372 23.338 10.8698 22.6402 10.8698H12.3708C11.6726 10.8698 11.1067 11.372 11.1067 11.9916V14.6754C11.1067 15.2951 11.6726 15.7973 12.3708 15.7973H17.0646C17.0359 15.5408 17.0671 15.279 17.1594 15.0258C17.3355 14.5438 17.7113 14.1519 18.2182 13.9223Z"
          fill={stroke}
        />
        <path
          d="M34.9519 31.3809L33.926 29.5976C33.816 29.4061 33.5497 29.3298 33.3344 29.4274L26.6733 32.4463L26.6017 32.4788C26.3864 32.5764 26.3004 32.8128 26.41 33.0039L27.4368 34.7872C27.5464 34.9787 27.8131 35.055 28.0284 34.9578L34.7606 31.9059C34.9759 31.8083 35.0623 31.572 34.9519 31.3809ZM33.5581 31.2527C33.5164 31.3663 33.4267 31.4666 33.2973 31.5253L32.5726 31.8558C32.3139 31.9736 31.9962 31.8831 31.8635 31.6535C31.7969 31.5387 31.7889 31.4108 31.8302 31.2972C31.8719 31.1831 31.9616 31.0833 32.091 31.0242L32.8161 30.694C33.0744 30.5758 33.3921 30.6663 33.5253 30.8959C33.5914 31.0107 33.599 31.1386 33.5581 31.2527Z"
          fill={stroke}
        />
        <path
          d="M32.7693 28.6156L32.8342 28.586C33.0377 28.494 33.1397 28.2798 33.0563 28.0909C33.0424 28.0599 33.0243 28.0314 33.0036 28.0056C33.0003 27.9997 32.9977 27.9937 32.9944 27.9877C32.8612 27.7581 32.5439 27.6676 32.2852 27.7858L32.0219 27.9058L31.8028 27.5255C31.4876 26.9777 31.4573 26.3352 31.7194 25.7661C31.9819 25.1969 31.9511 24.5548 31.636 24.007L30.661 22.3123L30.6222 22.2453L28.9777 19.3872L28.6077 18.7444L28.0659 17.8028C27.7954 17.3327 27.1406 17.1446 26.6114 17.3843C26.3203 17.5163 26.1256 17.7512 26.0535 18.0137L26.0291 17.9711C25.7586 17.501 25.1043 17.3129 24.5746 17.553C24.2839 17.6846 24.0888 17.9195 24.0172 18.1823L23.7631 17.7415C23.7206 17.6674 23.6683 17.6005 23.6089 17.541C23.2899 17.2228 22.7548 17.1211 22.3086 17.323C22.1291 17.4045 21.9867 17.5249 21.8869 17.667C21.8245 17.7553 21.7786 17.8518 21.7512 17.9524L21.5869 17.667L21.0492 16.7322L20.5112 15.7973L20.1518 15.1728C19.8813 14.7031 19.2269 14.5146 18.6973 14.7547C18.324 14.9237 18.1087 15.2625 18.1087 15.6122C18.1087 15.6739 18.1154 15.7356 18.1289 15.7973C18.1478 15.8818 18.1799 15.9652 18.2262 16.0456L18.6214 16.7322L19.1591 17.667L21.6054 21.9185L22.1401 22.8477L23.4197 25.0713C23.5305 25.2635 23.4446 25.4991 23.2276 25.597C23.1636 25.6262 23.0949 25.64 23.0274 25.64C22.8673 25.64 22.7127 25.5623 22.6352 25.4269L21.0362 22.6481C20.7585 22.1657 20.0864 21.9727 19.5429 22.2191C18.9994 22.4656 18.782 23.0616 19.0596 23.544L20.8588 26.671L21.4149 27.6377C21.8283 28.356 22.5526 28.8949 23.4222 29.1305C23.8566 29.2483 24.2552 29.442 24.5965 29.6959C24.9378 29.9498 25.2226 30.2643 25.4291 30.6233L25.5732 30.8738C25.4207 31.0141 25.3773 31.2295 25.483 31.4123C25.5003 31.4422 25.5205 31.4695 25.5437 31.4942C25.5441 31.4953 25.545 31.4965 25.5458 31.4976C25.6474 31.6367 25.8357 31.6962 26.0122 31.6629C26.0733 31.6565 26.1344 31.6412 26.1922 31.6146L32.7664 28.6171C32.7677 28.6167 32.7685 28.6159 32.7693 28.6156Z"
          fill={stroke}
        />
      </svg>
    </SvgIcon>
  );
};
