import { Checkbox, TableCell, TableHead, TableRow, TableSortLabel, Tooltip } from "@mui/material";
import { IVBillBillsFiltersInput, IVBillSortOrderType } from "generated/sdk.vbill";
import { useMemo } from "react";

const defaultTableCellSx = {
  lineHeight: "14px",
  backgroundColor: "#F5F5F5",
  whiteSpace: "nowrap",
  padding: "10px 5px",
};

interface ITableHeadCell {
  id: keyof IVBillBillsFiltersInput | string;
  sortable: boolean;
  label: string;
  value?: IVBillSortOrderType | null;
  onChange?: (value: IVBillSortOrderType) => void;
}

interface IBatchVBillsHeaderProps {
  selectAllCheckboxChecked: boolean;
  selectAllCheckboxIndeterminate: boolean;
  onSelectAllVBillCheckboxClick: () => void;
  sortVBillsValues?: IVBillBillsFiltersInput;
  setSortVBillsValues: (key: keyof IVBillBillsFiltersInput, value: IVBillSortOrderType) => void;
  showSelectAllCheckbox?: boolean;
}

export const BatchVBillsHeader = ({
  selectAllCheckboxChecked,
  selectAllCheckboxIndeterminate,
  onSelectAllVBillCheckboxClick,
  sortVBillsValues,
  setSortVBillsValues,
  showSelectAllCheckbox,
}: IBatchVBillsHeaderProps) => {
  const tableHeadCells: ITableHeadCell[] = useMemo(
    () => [
      {
        id: "vbill_number_sort",
        sortable: true,
        label: "Bill #",
        value: sortVBillsValues?.vbill_number_sort,
        onChange: (value) => setSortVBillsValues("vbill_number_sort", value),
      },
      {
        id: "companyId",
        sortable: false,
        label: "Company",
      },
      {
        id: "amount_sort",
        sortable: true,
        label: "Amount",
        value: sortVBillsValues?.amount_sort,
        onChange: (value) => setSortVBillsValues("amount_sort", value),
      },
      {
        id: "date_due_sort",
        sortable: true,
        label: "Due Date",
        value: sortVBillsValues?.date_due_sort,
        onChange: (value) => setSortVBillsValues("date_due_sort", value),
      },
      {
        id: "vendors",
        sortable: false,
        label: "Vendor",
      },
      {
        id: "add_mappings",
        sortable: false,
        label: "Properties",
      },
    ],
    [
      sortVBillsValues?.vbill_number_sort,
      sortVBillsValues?.amount_sort,
      sortVBillsValues?.date_due_sort,
      setSortVBillsValues,
    ],
  );

  return (
    <TableHead>
      <TableRow sx={{ cursor: "default" }}>
        {showSelectAllCheckbox && (
          <TableCell sx={defaultTableCellSx} variant="head">
            <Tooltip title={selectAllCheckboxChecked || selectAllCheckboxIndeterminate ? "Remove all" : "Add all"}>
              <Checkbox
                color="primary"
                checked={selectAllCheckboxChecked}
                indeterminate={selectAllCheckboxIndeterminate}
                onClick={onSelectAllVBillCheckboxClick}
              />
            </Tooltip>
          </TableCell>
        )}

        {tableHeadCells.map((headerCell) =>
          headerCell.sortable ? (
            <TableCell
              key={headerCell.id}
              variant="head"
              sx={{ ...defaultTableCellSx, textAlign: headerCell.id === "amount_sort" ? "right" : "left" }}
              sortDirection={headerCell.value === IVBillSortOrderType.Asc ? "asc" : "desc"}
            >
              <TableSortLabel
                active={headerCell.value === IVBillSortOrderType.Asc}
                direction={headerCell.value === IVBillSortOrderType.Asc ? "asc" : "desc"}
                sx={{ ...(headerCell.id === "amount_sort" ? { flexDirection: "row-reverse" } : {}) }}
                onClick={() =>
                  headerCell.onChange?.(
                    headerCell.value === IVBillSortOrderType.Asc ? IVBillSortOrderType.Desc : IVBillSortOrderType.Asc,
                  )
                }
              >
                {headerCell.label}
              </TableSortLabel>
            </TableCell>
          ) : (
            <TableCell sx={defaultTableCellSx} key={headerCell.id} variant="head">
              {headerCell.label}
            </TableCell>
          ),
        )}
        <TableCell sx={defaultTableCellSx} variant="head" />
      </TableRow>
    </TableHead>
  );
};
