import { useHistory, useLocation, useParams } from "react-router";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../../../storeContainer";
import { ReactChild, ReactFragment, ReactPortal, useEffect, useState } from "react";
import styles from "./User.module.scss";
import { AlertColor } from "@mui/material";

import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Snackbar from "@mui/material/Snackbar";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TextField from "@mui/material/TextField";

import LoadingButton from "@mui/lab/LoadingButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { a11yProps, TabPanel, useUserGroupsColumns } from "../utils";
import Table from "../../../../common/Table/Table";
import { definitelyFilter } from "../../../../../generated/utils";
import { AclPermissions } from "./Permissions/Permissions";
import { Spinner } from "../../../../common/Spinner/Spinner";
import { Link } from "react-router-dom";

const User = () => {
  const { organizationId, organizationUserId } = useParams<{ organizationId?: string; organizationUserId: string }>();

  const history = useHistory();
  const location = useLocation();
  const path = `/org/${organizationId}/users/${organizationUserId}`;

  //Snackbar
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarText, setSnackbarText] = useState("Test");
  const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor | undefined>("success");

  //Remove from group
  const [loadingRemove, setLoadingRemove] = useState(false);

  const handleRemoveFromGroup = async (groupId: string) => {
    if (groupId && user) {
      setLoadingRemove(true);
      try {
        await userGroupsStore.removeUserFromGroup(groupId, user?.id);
        setDummy(dummy + 1);
        setLoadingRemove(false);
        setSnackbarSeverity("success");
        setSnackbarText("Group removed successfully");
        setOpenSnackbar(true);
      } catch (e: any) {
        setSnackbarText(e.response.errors?.message);
        setLoadingRemove(false);
        setSnackbarSeverity("error");
        setOpenSnackbar(true);
      }
    }
  };

  const groupColumns = useUserGroupsColumns(handleRemoveFromGroup, loadingRemove);

  const [value, setValue] = useState(location.pathname.includes("permissions") ? 1 : 0);
  const userGroupsStore = useStore("UserGroupsStore");
  const organizationUsersStore = useStore("OrganizationUsersStore");
  const { userGroupsList } = userGroupsStore;

  //Add group
  const [addedGroup, setAddedGroup] = useState<string | null>();
  const [loading, setLoading] = useState(false);
  const [dummy, setDummy] = useState(0);

  const [user, setUser] = useState<any>();

  const [dataLoading, setDataLoading] = useState(false);

  useEffect(() => {
    if (organizationUserId) {
      setDataLoading(true);
      setUser(organizationUsersStore.getOrganizationUser(organizationUserId)!);
      userGroupsStore.loadUserGroups(true);
      setDataLoading(false);
    }
  }, [userGroupsStore, organizationUsersStore, dummy, organizationUserId]);

  const userGroups = user?.user_groups?.map((group: { name: string }) => group?.name);

  //TODO: Fix types
  const handleChange = (_event: any, newValue: number) => {
    setValue(newValue);
  };

  const handleAddGroup = async () => {
    if (addedGroup) {
      setLoading(true);
    } else if (!addedGroup) {
      setOpenSnackbar(true);
      setSnackbarSeverity("error");
      setSnackbarText("Please insert group name");
    }
    const groupId = userGroupsList.data?.find((group) => group?.name === addedGroup)?.id;

    if (groupId && user) {
      try {
        await userGroupsStore.addUserToGroup(groupId, user?.id);
        setDummy(dummy + 1);
        setLoading(false);
        setAddedGroup("");
        setOpenSnackbar(true);
        setSnackbarSeverity("success");
        setSnackbarText("Group added successfully");
      } catch (e: any) {
        setSnackbarText(e.response.errors?.message);
        setLoading(false);
        setSnackbarSeverity("error");
        setOpenSnackbar(true);
      }
    }
  };

  const handleBack = () => {
    history.push(`/org/${organizationId}/users`);
  };

  if (!organizationId) return null;

  return (
    <div className={styles.content}>
      <>
        <div className={styles.userInfo}>
          <Paper className={styles.paper}>
            {organizationUsersStore.organizationUsersList.isFetching && !user ? (
              <Spinner />
            ) : (
              <div className={styles.container}>
                <div className={styles.left}>
                  <div className={styles.line}>
                    <div className={styles.svg}>
                      <ArrowBackIcon className={styles.backIcon} onClick={handleBack} />
                    </div>
                    <div className={styles.textContainer}>
                      <span className={styles.name}>{user?.account?.name || user?.account?.email}</span>
                      <span className={styles.email}>{user?.account?.email}</span>
                      <span
                        className={
                          user?.account?.state ? styles[user?.account?.state as keyof typeof styles] : styles.status
                        }
                      >
                        {user?.account?.state}
                      </span>
                    </div>
                  </div>
                  <div className={styles.groupsContainer}>
                    {user?.user_groups?.map(
                      (group: {
                        id: any;
                        name: boolean | ReactChild | ReactFragment | ReactPortal | null | undefined;
                      }) => (
                        <Link className={styles.group} to={`/org/${organizationId}/groups/${group?.id}`}>
                          <span>{group?.name}</span>
                        </Link>
                      ),
                    )}
                  </div>
                </div>
              </div>
            )}
          </Paper>
        </div>
        <Paper className={styles.paper}>
          <Tabs value={value} onChange={handleChange} textColor="primary" indicatorColor="primary">
            <Tab
              label="Groups"
              onClick={() => {
                history.push(`${path}/groups`);
              }}
              {...a11yProps(0)}
            />
            <Tab
              label="Permissions"
              onClick={() => {
                history.push(`${path}/users2/permissions`);
              }}
              {...a11yProps(1)}
            />
          </Tabs>
          <TabPanel value={value} index={0} className={styles.tabPanel}>
            {dataLoading ? (
              <Spinner />
            ) : (
              <>
                {organizationUsersStore.organizationUsersList.data ? (
                  <Table
                    emptyLabel={"This user has no groups"}
                    columns={groupColumns}
                    data={definitelyFilter(user?.user_groups)}
                  />
                ) : null}
              </>
            )}
            <Grid container alignItems={"center"} sx={{ margin: "20px 0" }}>
              <Grid item>
                <Autocomplete
                  key={addedGroup}
                  style={{ minWidth: "200px", marginRight: "10px" }}
                  id="add-group-autocomplete"
                  freeSolo
                  value={addedGroup}
                  options={definitelyFilter(userGroupsList?.data)
                    .filter((group) => !userGroups?.includes(group?.name))
                    .map((group) => group?.name)}
                  onChange={(_event: any, newValue: any) => {
                    setAddedGroup(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Add to group"
                      variant="outlined"
                      sx={{ marginRight: "10px" }}
                      InputProps={{
                        ...params.InputProps,
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item>
                <LoadingButton variant={"contained"} loading={loading} onClick={handleAddGroup}>
                  Add to group
                </LoadingButton>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={1}>
            {dataLoading ? (
              <Spinner />
            ) : (
              <AclPermissions
                organizationId={organizationId}
                organizationUserId={organizationUserId}
                userGroupId={null}
              />
            )}
          </TabPanel>
        </Paper>
      </>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert onClose={() => setOpenSnackbar(false)} severity={snackbarSeverity} sx={{ width: "100%" }}>
          {snackbarText}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default observer(User);
