import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import { Box, IconButton, Skeleton, Tooltip, Typography } from "@mui/material";
import { graphqlVBillClient } from "common/graphqlClient";
import {
  getSdk,
  IVBillBillsFiltersMappingInput,
  IVBillStoreIoDataType,
  IVBillVBillCompaniesSettingsQuery,
} from "generated/sdk.vbill";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import {
  AdditionalMappingsDialog,
  ISuggestionWithSource,
} from "../../FilterComponents/VBillAdditionalMappingsFilter/AdditionalMappingsDialog/AdditionalMappingsDialog";
import { labelTextStyles } from "../VBillAddFullBatchDialog";

const { VBillStoreItemsForOrganization } = getSdk(graphqlVBillClient);

interface IAddFullBatchVendorsProps {
  additionalMappingsSettingsData?:
    | IVBillVBillCompaniesSettingsQuery["companiesSettings"]["vBill"]["additionalMappings"]["invoice"]
    | null;
  mappings?: IVBillBillsFiltersMappingInput[] | null;
  onMappingSave: (value: IVBillBillsFiltersMappingInput) => void;
  companyIds?: string[] | null;
}

export const AddFullBatchVendors = ({
  additionalMappingsSettingsData,
  mappings,
  onMappingSave,
  companyIds,
}: IAddFullBatchVendorsProps) => {
  const { organizationId } = useParams<{ organizationId: string }>();
  const [selectedStoreItems, setSelectedStoreItems] = useState<ISuggestionWithSource[]>([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const vendorSettings = useMemo(
    () => (additionalMappingsSettingsData ?? []).find((map) => map.isContact),
    [additionalMappingsSettingsData],
  );
  const selectedVendorMappings = useMemo(
    () => mappings?.find(({ key }) => key === vendorSettings?.key),
    [mappings, vendorSettings?.key],
  );
  const totalSelectedVendorMappings = useMemo(
    () => selectedVendorMappings?.valuesIn?.length,
    [selectedVendorMappings?.valuesIn?.length],
  );

  useEffect(() => {
    fetchSelectedStoreItems(selectedVendorMappings, companyIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mappings, companyIds]);

  const fetchSelectedStoreItems = async (
    mappings?: IVBillBillsFiltersMappingInput,
    batchCompanyIds?: string[] | null,
  ) => {
    const keySourcePairs =
      mappings?.valuesIn?.map(({ source, value }) => ({ key: value ?? "", source: source ?? "" })) ?? undefined;

    if (!keySourcePairs?.length || !vendorSettings?.storeDataType) {
      setSelectedStoreItems([]);
      return;
    }

    const storeItemsResp = await VBillStoreItemsForOrganization({
      type: vendorSettings.storeDataType as unknown as IVBillStoreIoDataType,
      organizationId,
      companyIds: batchCompanyIds?.length ? batchCompanyIds : undefined,
      keySourcePairs,
      limit: 20,
      offset: 0,
    });

    if (storeItemsResp.storeItemsForOrganization) {
      const storeItems = storeItemsResp.storeItemsForOrganization.map(({ key, label, source }) => ({
        id: key,
        name: label ?? "",
        source: source ?? "",
        selected: true,
      }));

      setSelectedStoreItems(storeItems);
    }
  };

  const handleMappingDialogSave = async (value: IVBillBillsFiltersMappingInput) => {
    setIsDialogOpen(false);
    onMappingSave(value);
  };

  return (
    <Box sx={{ marginBottom: "15px" }}>
      {!additionalMappingsSettingsData ? (
        <Skeleton variant="rounded" sx={{ height: "20px", margin: "6px 0" }} />
      ) : (
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <Typography sx={labelTextStyles}>
            Bills Vendors{" "}
            <Box component="sup" sx={{ fontSize: "10px" }}>
              ({totalSelectedVendorMappings ?? 0})
            </Box>
          </Typography>

          <Tooltip title={!totalSelectedVendorMappings ? "Add bills vendors" : "Edit bills vendors"} arrow>
            <IconButton onClick={() => setIsDialogOpen(true)}>
              {!totalSelectedVendorMappings ? <AddCircleOutlineIcon /> : <EditIcon />}
            </IconButton>
          </Tooltip>
        </Box>
      )}

      {(selectedStoreItems ?? []).length > 0 && (
        <Typography>
          Selected:{" "}
          {selectedStoreItems.map(({ name }, index) => (
            <span key={name}>
              {name}
              {selectedStoreItems.length - 1 > index && ", "}
            </span>
          ))}
        </Typography>
      )}

      {isDialogOpen && vendorSettings?.storeDataType && vendorSettings?.key && (
        <AdditionalMappingsDialog
          suggestionsComponent="dialog"
          storeType={vendorSettings.storeDataType as unknown as IVBillStoreIoDataType}
          storeKey={vendorSettings.key}
          mapping={selectedVendorMappings}
          batchCompanyIds={companyIds ?? []}
          onCloseDialog={() => setIsDialogOpen(false)}
          searchPlaceholder="Search vendors"
          onMappingSave={handleMappingDialogSave}
          dialogTitle="Vendors"
          actionsBtnSmallHeight
        />
      )}
    </Box>
  );
};
