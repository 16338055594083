import { IEditOrganizationModalProps } from "./types";
import { Button, DialogContent, DialogContentText, Grid, TextField } from "@mui/material";
import { useState } from "react";
import { useStore } from "../../../../../storeContainer";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import { Spinner } from "components/common/Spinner/Spinner";

function EditOrganizationModal({
  isModalOpened,
  modalClose,
  updateUi,
  groupId,
  groupName,
  groupDescription,
  group,
  setGroup,
}: IEditOrganizationModalProps) {
  const [name, setName] = useState(groupName);
  const [description, setDescription] = useState(groupDescription);
  const [loading, setLoading] = useState(false);
  const handleModalClose = () => {
    modalClose(false);
  };

  const userGroupsStore = useStore("UserGroupsStore");

  const handleSubmit = async () => {
    if (groupId && name && description) {
      setLoading(true);
      await userGroupsStore.updateUserGroup(name, description, groupId);
      updateUi();
      handleModalClose();
      setDescription("");
      setGroup({ ...group, description: description });
      setLoading(false);
    } else return;
  };
  const handleChangeDescription = (e: any) => {
    setDescription(e.target.value);
  };
  return (
    <div>
      <Dialog
        open={isModalOpened}
        onClose={handleModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Update group</DialogTitle>
        <DialogContent>
          <Grid container direction={"column"}>
            <DialogContentText>Please enter new group name</DialogContentText>
            <TextField
              id="textfield-edit-organization"
              label="Group name"
              variant="outlined"
              sx={{ margin: "10px 0" }}
              value={name || ""}
              onChange={(event) => setName(event.target.value)}
            />
            <DialogContentText>Please enter new group description</DialogContentText>
            <TextField
              id="textfield-edit-organization"
              label="Group description"
              variant="outlined"
              sx={{ margin: "10px 0" }}
              value={description || ""}
              onChange={handleChangeDescription}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModalClose}>Cancel</Button>
          {!loading ? (
            <Button onClick={handleSubmit}>Update</Button>
          ) : (
            <Button>
              <Spinner />
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default EditOrganizationModal;
