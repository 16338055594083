import { Box, Typography } from "@mui/material";
import { IVBillExpenseType } from "generated/sdk.vbill";
import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { useStore } from "storeContainer";
import { COLORS } from "themes/default";
import { useCurrentVBillRoute } from "../../hooks";
import { FormInputText } from "../Forms/FormInputText/FormInputText";

export const VBillEmailVendor = observer(() => {
  const vBillStore = useStore("VBillStore");
  const { isIncomingRoute } = useCurrentVBillRoute();
  const { invoice } = vBillStore;
  const invoiceData = useMemo(() => invoice.data, [invoice.data]);

  if (invoiceData?.expenseType !== IVBillExpenseType.Bill) {
    return null;
  }

  return (
    <Box sx={{ flex: "1", minWidth: "0px" }}>
      <Typography sx={{ fontSize: 16, marginBottom: "5px", fontWeight: 500, color: COLORS.logoBlue }}>
        VENDOR:
      </Typography>

      <FormInputText insideForm={isIncomingRoute} name="senderName" label="Name:" pushedTop />
      <FormInputText insideForm={isIncomingRoute} name="senderAddress" label="Address:" pushedTop />
      <FormInputText
        insideForm={isIncomingRoute}
        name="senderEmail"
        label="Email:"
        rules={{ required: "Email is required" }}
        pushedTop
      />
      <FormInputText insideForm={isIncomingRoute} name="senderPhone" label="Phone:" pushedTop />
    </Box>
  );
});
