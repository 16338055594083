import Box from "@mui/material/Box";
import Button from "components/common/Button";
import { CompanyWithBankAccountsAndAggregatedData, Pagination } from "generated/sdk";
import { observer } from "mobx-react-lite";
import { useEffect, useRef } from "react";
import { useStore } from "storeContainer";
import { Spinner } from "../../../../common/Spinner/Spinner";
import { CompanyCard } from "./CompanyCard";

const initialPaginationState = {
  per_page: 10,
  page: 1,
};

export const CompaniesList: React.FunctionComponent<{
  onlyWithTransactions: boolean;
  showDepositAccounts: boolean;
  companies: CompanyWithBankAccountsAndAggregatedData[];
  searchKeywords: string;
  hasMore: boolean;
  isFetching: boolean;
  loadCompanies: (pagination: Pagination) => void;
  currentPage: number;
}> = observer(
  ({
    companies,
    searchKeywords,
    onlyWithTransactions,
    hasMore,
    isFetching,
    loadCompanies,
    currentPage,
    showDepositAccounts,
  }) => {
    const filteredCompanies = showDepositAccounts
      ? companies
      : (companies ?? []).filter((company) => {
          if (onlyWithTransactions) {
            return (
              (company.bank_accounts?.filter((bank_account) => {
                return (
                  (bank_account.incoming_checks_stats?.count || 0) > 0 ||
                  (bank_account.outgoing_checks_stats?.count || 0) > 0 ||
                  (bank_account.pending_approval_checks_stats?.count || 0) > 0
                );
              })?.length || 0) > 0
            );
          } else {
            return true;
          }
        });
    const bottomRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (filteredCompanies.length > 10 && currentPage > 1) {
        bottomRef.current?.scrollIntoView({ block: "start", inline: "nearest", behavior: "smooth" });
      }
    }, [filteredCompanies.length]);

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          mt: "20px",
          gap: 1,
        }}
      >
        {filteredCompanies.map((company, index) => {
          return (
            <div key={index} style={{ marginTop: "10px" }}>
              {index === initialPaginationState.per_page * (currentPage - 1) && <div ref={bottomRef} />}
              <CompanyCard
                onlyWithTransactions={onlyWithTransactions}
                searchKeywords={searchKeywords}
                key={company.id}
                company={company}
              />
            </div>
          );
        })}

        {hasMore && !isFetching && (
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Button
              className="btn btn-primary"
              onClick={() => {
                loadCompanies({
                  ...initialPaginationState,
                  page: currentPage + 1,
                });
              }}
            >
              Show More
            </Button>
          </div>
        )}
        {isFetching && <Spinner withSpaceAround />}
      </Box>
    );
  },
);
