import { styled, Tooltip, tooltipClasses, TooltipProps } from "@mui/material";
import { COLORS } from "themes/default";

export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: COLORS.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: COLORS.white,
    "&::before": {
      backgroundColor: COLORS.white,
      border: `1px solid ${COLORS.white}`,
      boxShadow: theme.shadows[1],
    },
  },
}));
