import ClearIcon from "@mui/icons-material/Clear";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import PersonRemoveAlt1Icon from "@mui/icons-material/PersonRemoveAlt1";
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  InputAdornment,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { IChatChannelParentType, IChatChatIoMemberInputSchema } from "generated/sdk.chat";
import { debounce, isNil } from "lodash";
import { observer } from "mobx-react-lite";
import { getChatChannelStore } from "modules/Chat";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useStore } from "storeContainer";
import { CheckTableCellHead, CheckTableRowHead, TablePaginationActions } from "./CheckTable";
import { useBatchDetailsContext } from "./useBatchDetailsContext";

export const CheckBatchMembersTable = observer(function CheckBatchMembersTable() {
  const { batchId } = useParams<{ batchId: string }>();

  const inputAvailableMembersRef = useRef<HTMLInputElement>(null);
  const inputInvitedMembersRef = useRef<HTMLInputElement>(null);

  const BudgetManagementStore = useStore("BudgetManagementStore");
  const SessionStore = useStore("SessionStore");
  const {
    availableMembers,
    fetchAvailableMembers,
    fetchCheckBatch,
    invitedMembers,
    fetchInvitedMembers,
    addCheckBatchMember,
    removeCheckBatchMember,
  } = BudgetManagementStore;

  const {
    props: { isLoading, isBatchClosed },
  } = useBatchDetailsContext();

  const [newMembers, setNewMembers] = useState(availableMembers?.data?.GetCheckBatchAvailableMembersQuery);
  const [currentMembers, setCurrentMembers] = useState(invitedMembers?.data?.GetCheckBatchMembersQuery);
  const [availableMembersPage, setAvailableMembersPage] = useState(0);
  const [availableMembersRowsPerPage, setAvailableMembersRowsPerPage] = useState(10);
  const [invitedMembersPage, setInvitedMembersPage] = useState(0);
  const [invitedMembersRowsPerPage, setInvitedMembersRowsPerPage] = useState(10);
  const [selectAllAvailableMembers, setSelectAllAvailableMembers] = useState(false);
  const [indeterminateAvailableMembers, setIndeterminateAvailableMembers] = useState(false);
  const [selectAllInvitedMembers, setSelectAllInvitedMembers] = useState(false);
  const [indeterminateInvitedMembers, setIndeterminateInvitedMembers] = useState(false);
  const [selectedAvailableMembers, setSelectedAvailableMembers] = useState<Record<string, boolean>>({});
  const [selectedInvitedMembers, setSelectedInvitedMembers] = useState<Record<string, boolean>>({});
  const [searchAvailableMembersText, setSearchAvailableMembersText] = useState<string>("");
  const [searchInvitedMembersText, setSearchInvitedMembersText] = useState<string>("");

  const filteredCurrentMembers = currentMembers?.rows?.filter(
    (member) => member?.id !== SessionStore?.selectedOrganizationUserId,
  );

  // FIXME: this should be handled by be ==>
  const chatChannelStore = useMemo(() => {
    const chatChannelIo = {
      parentContext: "vCheckBatch_tab",
      parentKey: batchId,
      parentType: IChatChannelParentType.VcheckBatch,
    };

    return getChatChannelStore(chatChannelIo);
  }, [batchId]);
  // FIXME: this should be handled by be <==

  const fetchAvailableMembersData = async (searchParam: string) => {
    await fetchAvailableMembers(
      batchId,
      { per_page: availableMembersRowsPerPage, page: availableMembersPage + 1 },
      searchParam,
      true,
    );

    setNewMembers(availableMembers.data?.GetCheckBatchAvailableMembersQuery);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetchAvailableMembersData = useCallback(
    debounce((newValue: string) => fetchAvailableMembersData(newValue), 300),
    [],
  );

  const fetchInvitedMembersData = async (searchParam: string) => {
    await fetchInvitedMembers(
      batchId,
      { per_page: invitedMembersRowsPerPage - 1, page: invitedMembersPage + 1 },
      searchParam,
      true,
    );

    setNewMembers(availableMembers.data?.GetCheckBatchAvailableMembersQuery);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetchInvitedMembersData = useCallback(
    debounce((newValue: string) => fetchInvitedMembersData(newValue), 300),
    [],
  );

  const handleChangeAvailableMembersPage = (_event: unknown, newPage: number) => {
    setAvailableMembersPage(newPage);
    setSelectedAvailableMembers({});
    setSelectAllAvailableMembers(false);
    setSearchAvailableMembersText("");
  };
  const handleChangeAvailableMembersRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAvailableMembersRowsPerPage(parseInt(event.target.value, 10));
    setAvailableMembersPage(0);
    setSelectedAvailableMembers({});
    setSelectAllAvailableMembers(false);
    setSearchAvailableMembersText("");
  };

  const handleChangeInvitedMembersPage = (_event: unknown, newPage: number) => {
    setInvitedMembersPage(newPage);
    setSelectedInvitedMembers({});
    setSelectAllInvitedMembers(false);
    setSearchInvitedMembersText("");
  };
  const handleChangeInvitedMembersRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInvitedMembersRowsPerPage(parseInt(event.target.value, 10));
    setInvitedMembersPage(0);
    setSelectedInvitedMembers({});
    setSelectAllInvitedMembers(false);
    setSearchInvitedMembersText("");
  };

  const handleSelectAllAvailableMembersClick = () => {
    if (selectAllAvailableMembers) {
      setSelectedAvailableMembers({});
    } else {
      const newSelectedAvailableMembers: Record<string, any> = {};
      newMembers?.rows?.forEach((member) => (newSelectedAvailableMembers[member?.id as string] = true));
      setSelectedAvailableMembers(newSelectedAvailableMembers);
    }
  };

  const handleSelectAllInvitedMembersClick = () => {
    if (selectAllInvitedMembers) {
      setSelectedInvitedMembers({});
    } else {
      const newSelectedInvitedMembers: Record<string, any> = {};
      filteredCurrentMembers?.forEach((member) => (newSelectedInvitedMembers[member?.id as string] = true));
      setSelectedInvitedMembers(newSelectedInvitedMembers);
    }
  };

  const handleAvailableMemberCheckClick = (memberId: string) => {
    setSelectedAvailableMembers((prevSelectedAvailableMembers) => ({
      ...prevSelectedAvailableMembers,
      [memberId]: !prevSelectedAvailableMembers[memberId],
    }));
  };

  const handleInvitedMemberCheckClick = (memberId: string) => {
    setSelectedInvitedMembers((prevSelectedInvitedMembers) => ({
      ...prevSelectedInvitedMembers,
      [memberId]: !prevSelectedInvitedMembers[memberId],
    }));
  };

  useEffect(() => {
    fetchAvailableMembers(
      batchId,
      { page: availableMembersPage + 1, per_page: availableMembersRowsPerPage },
      undefined,
      true,
    );
    fetchInvitedMembers(
      batchId,
      { page: invitedMembersPage + 1, per_page: invitedMembersRowsPerPage - 1 },
      undefined,
      true,
    );
  }, [
    availableMembersPage,
    availableMembersRowsPerPage,
    batchId,
    fetchAvailableMembers,
    fetchInvitedMembers,
    invitedMembersPage,
    invitedMembersRowsPerPage,
  ]);
  useEffect(() => {
    if (availableMembers?.data?.GetCheckBatchAvailableMembersQuery) {
      setNewMembers(availableMembers?.data?.GetCheckBatchAvailableMembersQuery);
    }
    if (invitedMembers?.data?.GetCheckBatchMembersQuery) {
      setCurrentMembers(invitedMembers?.data?.GetCheckBatchMembersQuery);
    }
  }, [availableMembers?.data?.GetCheckBatchAvailableMembersQuery, invitedMembers?.data?.GetCheckBatchMembersQuery]);

  useEffect(() => {
    const allSelected =
      Object.values(selectedAvailableMembers).every((checked) => checked) &&
      newMembers?.rows?.length === Object.values(selectedAvailableMembers)?.length &&
      Object.values(selectedAvailableMembers)?.length > 0;
    const someSelected = Object.values(selectedAvailableMembers).some((checked) => checked);

    setSelectAllAvailableMembers(allSelected);
    setIndeterminateAvailableMembers(!allSelected && someSelected);
  }, [newMembers?.rows?.length, selectedAvailableMembers]);

  useEffect(() => {
    const invitedMembersArr = Object.values(selectedInvitedMembers);
    const allSelected =
      invitedMembersArr.every((checked) => checked) &&
      filteredCurrentMembers?.length === invitedMembersArr?.length &&
      invitedMembersArr?.length > 0;
    const someSelected = invitedMembersArr.some((checked) => checked) && invitedMembersArr?.length > 1;

    setSelectAllInvitedMembers(allSelected);
    setIndeterminateInvitedMembers(!allSelected && someSelected);
  }, [filteredCurrentMembers?.length, selectedInvitedMembers]);

  useEffect(() => {
    debouncedFetchInvitedMembersData(searchInvitedMembersText);
    return () => {
      debouncedFetchInvitedMembersData.cancel();
    };
  }, [debouncedFetchInvitedMembersData, searchInvitedMembersText]);

  useEffect(() => {
    debouncedFetchAvailableMembersData(searchAvailableMembersText);
    return () => {
      debouncedFetchAvailableMembersData.cancel();
    };
  }, [debouncedFetchAvailableMembersData, searchAvailableMembersText]);

  useEffect(() => {
    if (inputAvailableMembersRef.current) {
      inputAvailableMembersRef.current.focus();
    }
  }, [searchAvailableMembersText]);

  useEffect(() => {
    if (inputInvitedMembersRef.current) {
      inputInvitedMembersRef.current.focus();
    }
  }, [searchInvitedMembersText]);

  const handleInviteMembers = async () => {
    // FIXME: this should be handled by be ==>
    const chatMembersToAdd: IChatChatIoMemberInputSchema[] = Object.keys(selectedAvailableMembers).map((memberKey) => {
      const currentMember = newMembers?.rows?.find((member) => member?.id === memberKey);

      return {
        organizationUserId: currentMember?.id ?? "",
        accountId: currentMember?.account?.id ?? "",
        email: currentMember?.account?.email ?? "",
        name: currentMember?.account?.name ?? "",
      };
    });
    // FIXME: this should be handled by be <==

    await addCheckBatchMember(batchId, Object.keys(selectedAvailableMembers), true);
    await chatChannelStore?.setAddMembers(chatMembersToAdd);
    await fetchAvailableMembers(batchId, { page: 1, per_page: 10 }, undefined, true);
    await fetchInvitedMembers(batchId, { page: 1, per_page: 9 }, undefined, true);
    await fetchCheckBatch(batchId, undefined, { page: 1, per_page: 10 }, true);
    setSelectAllAvailableMembers(false);
    setSelectedAvailableMembers({});
    setSearchAvailableMembersText("");
  };
  const handleRemoveMember = async () => {
    // FIXME: this should be handled by be ==>
    const chatMembersToRemove: number[] = Object.keys(selectedInvitedMembers)
      .map((memberKey) => {
        const currentMember = chatChannelStore?.members?.find((member) => member?.organizationUserId === memberKey);

        return currentMember?.id;
      })
      .filter((id): id is number => !isNil(id));
    // FIXME: this should be handled by be <==
    await removeCheckBatchMember(batchId, Object.keys(selectedInvitedMembers), true);
    await chatChannelStore?.setRemoveMembers(chatMembersToRemove);
    await fetchAvailableMembers(batchId, { page: 1, per_page: 10 }, undefined, true);
    await fetchInvitedMembers(batchId, { page: 1, per_page: 9 }, undefined, true);
    await fetchCheckBatch(batchId, undefined, { page: 1, per_page: 10 }, true);

    setSelectAllInvitedMembers(false);
    setSelectedInvitedMembers({});
    setSearchInvitedMembersText("");
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
      >
        <Box display="flex" gap="10px" marginTop="15px" width="100%" flexGrow="1">
          <Paper sx={{ width: "100%" }}>
            <Box display="flex" justifyContent="space-around" paddingY="15px">
              <Typography variant="h3" color="green">
                Available Members
              </Typography>
              <TextField
                placeholder="Search"
                variant="outlined"
                value={searchAvailableMembersText}
                onChange={(e) => setSearchAvailableMembersText(e.target.value)}
                sx={{ maxWidth: "300px" }}
                inputRef={inputAvailableMembersRef}
                InputProps={{
                  ...(searchAvailableMembersText?.length && {
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => {
                            setSearchAvailableMembersText("");
                          }}
                        >
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }),
                }}
                disabled={isBatchClosed || isLoading}
              />
              <Button
                title={"Invite Members"}
                endIcon={<PersonAddAlt1Icon />}
                disabled={!Object.values(selectedAvailableMembers)?.length || isBatchClosed || isLoading}
                variant="contained"
                onClick={handleInviteMembers}
              >
                Invite
              </Button>
            </Box>
            <TableContainer sx={{ position: "relative" }}>
              <Table>
                <TableHead>
                  <CheckTableRowHead>
                    <CheckTableCellHead variant="head">
                      <Checkbox
                        checked={selectAllAvailableMembers}
                        indeterminate={indeterminateAvailableMembers}
                        onChange={handleSelectAllAvailableMembersClick}
                        disabled={isBatchClosed || isLoading}
                      />
                    </CheckTableCellHead>
                    <CheckTableCellHead variant="head">Name</CheckTableCellHead>
                    <CheckTableCellHead variant="head">
                      <Box>
                        <Typography variant="body1">Email</Typography>
                      </Box>
                    </CheckTableCellHead>
                  </CheckTableRowHead>
                </TableHead>
                <TableBody>
                  {newMembers?.rows?.map((member) => {
                    return (
                      <TableRow key={member?.id}>
                        {availableMembers?.isLoading ? (
                          <TableCell colSpan={3}>
                            <Skeleton variant="text" />
                          </TableCell>
                        ) : (
                          <>
                            <TableCell>
                              <Checkbox
                                checked={!!selectedAvailableMembers[member?.id as string]}
                                onChange={() => handleAvailableMemberCheckClick(member?.id as string)}
                                disabled={isBatchClosed || isLoading}
                              />
                            </TableCell>
                            <TableCell>
                              <Typography
                                variant="body1"
                                title={member?.account?.name!}
                                whiteSpace="nowrap"
                                overflow="hidden"
                                textOverflow="ellipsis"
                                width="220px"
                              >
                                {member?.account?.name}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography
                                variant="body1"
                                title={member?.account?.name!}
                                whiteSpace="nowrap"
                                overflow="hidden"
                                textOverflow="ellipsis"
                                width="220px"
                              >
                                {member?.account?.email}
                              </Typography>
                            </TableCell>
                          </>
                        )}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              count={newMembers?.pagination?.total ?? 0}
              rowsPerPage={availableMembersRowsPerPage}
              page={availableMembersPage}
              onPageChange={handleChangeAvailableMembersPage}
              onRowsPerPageChange={handleChangeAvailableMembersRowsPerPage}
              ActionsComponent={TablePaginationActions}
              component={"div"}
            />
          </Paper>
          <CompareArrowsIcon sx={{ fontSize: "3em", alignSelf: "center" }} />
          <Paper sx={{ width: "100%" }}>
            <Box display="flex" justifyContent="space-around" paddingY="15px">
              <Typography variant="h3" color="blue">
                Invited Members
              </Typography>
              <TextField
                placeholder="Search"
                variant="outlined"
                value={searchInvitedMembersText}
                onChange={(e) => setSearchInvitedMembersText(e.target.value)}
                sx={{ maxWidth: "300px" }}
                inputRef={inputInvitedMembersRef}
                InputProps={{
                  ...(searchInvitedMembersText?.length && {
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => {
                            setSearchInvitedMembersText("");
                          }}
                        >
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }),
                }}
                disabled={isBatchClosed || isLoading}
              />
              <Button
                disabled={!Object.values(selectedInvitedMembers)?.length || isBatchClosed || isLoading}
                onClick={handleRemoveMember}
                title={"Remove Members"}
                endIcon={<PersonRemoveAlt1Icon />}
                variant="contained"
              >
                Remove
              </Button>
            </Box>
            <TableContainer sx={{ position: "relative" }}>
              <Table>
                <TableHead>
                  <CheckTableRowHead>
                    <CheckTableCellHead variant="head">
                      <Checkbox
                        checked={selectAllInvitedMembers}
                        indeterminate={indeterminateInvitedMembers}
                        onChange={handleSelectAllInvitedMembersClick}
                        disabled={isBatchClosed || isLoading}
                      />
                    </CheckTableCellHead>
                    <CheckTableCellHead variant="head">Name</CheckTableCellHead>
                    <CheckTableCellHead variant="head">Email</CheckTableCellHead>
                  </CheckTableRowHead>
                </TableHead>
                <TableBody>
                  {currentMembers?.rows?.map((member) => {
                    return (
                      <TableRow key={member?.id}>
                        {invitedMembers?.isLoading ? (
                          <TableCell colSpan={3}>
                            <Skeleton variant="text" />
                          </TableCell>
                        ) : (
                          <>
                            <TableCell>
                              <Checkbox
                                checked={!!selectedInvitedMembers[member?.id as string]}
                                onChange={() => handleInvitedMemberCheckClick(member?.id as string)}
                                disabled={
                                  member?.id === SessionStore?.selectedOrganizationUserId || isBatchClosed || isLoading
                                }
                              />
                            </TableCell>
                            <TableCell>{member?.account?.name}</TableCell>
                            <TableCell>{member?.account?.email}</TableCell>
                          </>
                        )}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              count={currentMembers?.pagination?.total ?? 0}
              rowsPerPage={invitedMembersRowsPerPage}
              page={invitedMembersPage}
              onPageChange={handleChangeInvitedMembersPage}
              onRowsPerPageChange={handleChangeInvitedMembersRowsPerPage}
              ActionsComponent={TablePaginationActions}
              component={"div"}
            />
          </Paper>
        </Box>
      </Box>
    </Box>
  );
});
