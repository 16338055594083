import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { formatCurrency } from "common/helpers/utils";
import {
  friendlyPaymentIntegrationStatusNames,
  friendlyPaymentPushStatusNames,
  getPaymentIntegrationStatusStyleColor,
  getPaymentPushStatusStyleColor,
} from "components/pages/common/VBill/utils";
import { IVBillPaymentIntegrationStatus, IVBillPaymentPushStatus } from "generated/sdk.vbill";
import { observer } from "mobx-react";
import { useStore } from "storeContainer";
import { COLORS } from "themes/default";

const defaultTableCellSx = {
  padding: "5px 16px",
  fontWeight: "500",
};

const defaultTableHeadCellSx = {
  padding: "10px 16px",
  whiteSpace: "nowrap",
};

export const SidebarPayments = observer(function SidebarPayments() {
  const { batchDetails } = useStore("VBillBatchDetailsStore");

  const batchDetailsData = batchDetails.data;

  return (
    <Box sx={{ marginTop: "10px" }}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow sx={{ cursor: "default" }}>
              <TableCell sx={defaultTableHeadCellSx}>Batch #</TableCell>
              <TableCell sx={defaultTableHeadCellSx}>Payment #</TableCell>
              <TableCell sx={defaultTableHeadCellSx}>Bill #</TableCell>
              <TableCell sx={{ ...defaultTableHeadCellSx, textAlign: "right" }}>Bill In Batch</TableCell>
              <TableCell sx={defaultTableHeadCellSx}>Errors</TableCell>
              <TableCell sx={defaultTableHeadCellSx}>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(batchDetailsData?.batchPayments ?? []).length === 0 ? (
              <TableRow>
                <TableCell colSpan={6} sx={{ textAlign: "center" }}>
                  <Typography>No results found</Typography>
                </TableCell>
              </TableRow>
            ) : (
              batchDetailsData?.batchPayments.map((payment) => (
                <TableRow key={payment.paymentId} sx={{ cursor: "default" }}>
                  <TableCell sx={{ ...defaultTableCellSx, color: COLORS.newSecondaryColor }}>
                    #{payment.batchId}
                  </TableCell>
                  <TableCell sx={defaultTableCellSx}>#{payment.paymentId}</TableCell>
                  <TableCell sx={defaultTableCellSx}>#{payment.invoiceId}</TableCell>
                  <TableCell sx={{ ...defaultTableCellSx, textAlign: "right" }}>
                    <Box
                      sx={{
                        textDecoration:
                          payment.paymentPushStatus === IVBillPaymentPushStatus.SubmittedError ||
                          payment.paymentIntegrationStatus === IVBillPaymentIntegrationStatus.Voided
                            ? "line-through"
                            : "none",
                      }}
                    >
                      {formatCurrency(Number(payment.paymentAmountForBillInBatch))}
                    </Box>
                  </TableCell>
                  <TableCell sx={defaultTableCellSx}>
                    {payment.paymentErrors.length ? (
                      <Tooltip arrow title={JSON.stringify(payment.paymentErrors, null, 2)}>
                        <span>View </span>
                      </Tooltip>
                    ) : (
                      "-"
                    )}
                  </TableCell>
                  <TableCell sx={defaultTableCellSx}>
                    <Box
                      sx={{
                        color:
                          payment.paymentPushStatus === IVBillPaymentPushStatus.SubmittedOk
                            ? getPaymentIntegrationStatusStyleColor(payment.paymentIntegrationStatus)
                            : getPaymentPushStatusStyleColor(payment.paymentPushStatus),
                      }}
                    >
                      {payment.paymentPushStatus === IVBillPaymentPushStatus.SubmittedOk
                        ? friendlyPaymentIntegrationStatusNames[payment.paymentIntegrationStatus]
                        : friendlyPaymentPushStatusNames[payment.paymentPushStatus]}
                    </Box>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
});
