import { graphqlClient } from "common/graphqlClient";
import { GeneralLedgerAccountsQuery, getSdk } from "generated/sdk";
import { definitelyFilter } from "generated/utils";
import { computed } from "mobx";
import { createContainer } from "storeContainer";

const { GeneralLedgerAccounts } = getSdk(graphqlClient);

export class GLAccountsStore {
  accountsList = createContainer<NonNullable<GeneralLedgerAccountsQuery["GeneralLedgerAccounts"]>>();
  private companyId?: string;

  async fetch(companyId: string) {
    return definitelyFilter((await GeneralLedgerAccounts({ companyId }))?.GeneralLedgerAccounts);
  }

  async load(companyId: string, force?: true) {
    this.accountsList.cachedLoad(() => this.fetch(companyId), [companyId], { forceUpdate: force });
    this.companyId = companyId;
  }

  @computed
  get bankAccountList() {
    return definitelyFilter(this.accountsList.data?.filter((gla) => gla?.account_type === "bank"));
  }
}
