import { graphqlVBillClient } from "common/graphqlClient";
import { IVBillVBillInvoiceQuery, getSdk } from "generated/sdk.vbill";
import { useState } from "react";

const { VBillMarkInvoiceAsDuplicate } = getSdk(graphqlVBillClient);

export function useMarkAsDuplicate(
  invoice: IVBillVBillInvoiceQuery["invoice"] | null | undefined,
  reloadInvoicesList: Function,
) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const markAsDuplicate = async () => {
    if (invoice) {
      setIsLoading(true);
      await VBillMarkInvoiceAsDuplicate({ invoiceId: invoice.id });
      await reloadInvoicesList();
      setIsLoading(false);
    }
  };

  return { isLoading, markAsDuplicate };
}
