import { Box, Table, TableContainer } from "@mui/material";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import { useEffect, useMemo } from "react";
import { useLocation, useParams } from "react-router";
import { useStore } from "storeContainer";
import { VBillRequestErrorToast } from "../common/VBill/VBillRequestErrorToast/VBillRequestErrorToast";
import { VBillRouteGuardRedirect } from "../common/VBill/VBillRouteGuardRedirect/VBillRouteGuardRedirect";
import { transformDatesAsSODUTCIsoString } from "../common/VBill/utils";
import { CodingActiveFilters } from "./CodingActiveFilters/CodingActiveFilters";
import { CodingFooter } from "./CodingFooter/CodingFooter";
import { CodingHeader } from "./CodingHeader/CodingHeader";
import { CodingLedgerList } from "./CodingLedgerList/CodingLedgerList";
import { CodingListFilters } from "./CodingListFilters/CodingListFilters";
import { CodingQuickFilters } from "./CodingQuickFilters/CodingQuickFilters";
import { useCodingLedgerFilters, useVBillCodingLedgerChildRoute } from "./hooks";
import { getVBillCodingLedgerBaseFiltersByChildRoute } from "./utils";

export const VBillCodingLedger = observer(() => {
  const vBillCodingLedgerStore = useStore("VBillCodingLedgerStore");
  const userSettingsStore = useStore("UserSettingsStore");
  const organizationStore = useStore("OrganizationStore");
  const { organizationId } = useParams<{ organizationId: string }>();
  const [codingLedgerFilters] = useCodingLedgerFilters();
  const codingLedgerChildRoute = useVBillCodingLedgerChildRoute();
  const location = useLocation();
  const selectedCompanyIdsByVbillEnabled = useMemo(
    () =>
      userSettingsStore.companiesSelectedIds?.filter((compSelectedId) =>
        (organizationStore.organizationVBillEnabledCompanies.data?.items ?? []).find(
          ({ companyId }) => companyId === compSelectedId,
        ),
      ),
    [organizationStore.organizationVBillEnabledCompanies.data?.items, userSettingsStore.companiesSelectedIds],
  );

  useEffect(() => {
    if (
      isNil(userSettingsStore.frontendSettingsObservable.data) ||
      isNil(organizationStore.organizationVBillEnabledCompanies.data)
    ) {
      return;
    }

    const baseFiltersByChildRoute = getVBillCodingLedgerBaseFiltersByChildRoute(
      codingLedgerChildRoute,
      organizationId,
      selectedCompanyIdsByVbillEnabled,
    );
    const filters = {
      ...baseFiltersByChildRoute,
      ...codingLedgerFilters.filters,
      ...transformDatesAsSODUTCIsoString(codingLedgerFilters.filters),
    };

    vBillCodingLedgerStore.loadInvoicesList({
      filters,
      pagination: codingLedgerFilters.pagination,
    });
  }, [
    codingLedgerChildRoute,
    codingLedgerFilters.filters,
    codingLedgerFilters.pagination,
    organizationId,
    organizationStore.organizationVBillEnabledCompanies.data,
    selectedCompanyIdsByVbillEnabled,
    userSettingsStore.frontendSettingsObservable.data,
    vBillCodingLedgerStore,
  ]);

  useEffect(() => {
    if (
      isNil(userSettingsStore.frontendSettingsObservable.data) ||
      isNil(organizationStore.organizationVBillEnabledCompanies.data)
    ) {
      return;
    }
    vBillCodingLedgerStore.loadInvoicesStats({
      filters: {
        // organizationId: selectedCompanyIdsByVbillEnabled.length ? undefined : organizationId,
        organizationId: organizationId,
        companyIds: selectedCompanyIdsByVbillEnabled.length ? selectedCompanyIdsByVbillEnabled : [],
      },
    });
    vBillCodingLedgerStore.loadCompaniesSettings({
      organizationId,
      companyIds: selectedCompanyIdsByVbillEnabled.length ? selectedCompanyIdsByVbillEnabled : [],
    });
  }, [
    vBillCodingLedgerStore,
    organizationId,
    userSettingsStore.companiesSelectedIds,
    userSettingsStore.frontendSettingsObservable.data,
    organizationStore.organizationVBillEnabledCompanies.data,
    selectedCompanyIdsByVbillEnabled,
  ]);

  useEffect(() => {
    vBillCodingLedgerStore.setLedgerRecordedNavigation(`${location.pathname}${location.search}`);
  }, [location.pathname, location.search, vBillCodingLedgerStore]);

  useEffect(() => {
    return () => {
      vBillCodingLedgerStore.invoicesList.flush();
      vBillCodingLedgerStore.invoicesStats.flush();
      vBillCodingLedgerStore.companiesSettings.flush();
    };
  }, [vBillCodingLedgerStore]);

  return (
    <Box>
      <VBillRouteGuardRedirect route="CODING_LEDGER" />

      <CodingQuickFilters />

      <CodingListFilters />

      <CodingActiveFilters />

      <TableContainer sx={{ paddingBottom: "100px" }}>
        <Table>
          <CodingHeader />

          <CodingLedgerList />
        </Table>
      </TableContainer>

      <CodingFooter />

      <VBillRequestErrorToast />
    </Box>
  );
});
