import { Box } from "@mui/material";
import { graphqlVBillClient } from "common/graphqlClient";
import { getSdk as vBillGetSdk, IVBillBatchInvoiceInput, IVBillInvoiceIncomingStatus } from "generated/sdk.vbill";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { useParams } from "react-router";
import { useSetState } from "react-use";
import { useStore } from "storeContainer";
import { VBillDroppableArea } from "../utils";
import { BatchVBillsAvailable } from "./BatchVBillsAvailable/BatchVBillsAvailable";
import { BatchVBillsSelected } from "./BatchVBillsSelected/BatchVBillsSelected";

const { VBillAddAllInvoicesToBatch } = vBillGetSdk(graphqlVBillClient);

export interface IBillsAccordianExpanded {
  selectedBills: boolean;
  availableBills: boolean;
}

const defaultBillsAccordianExpanded: IBillsAccordianExpanded = {
  selectedBills: true,
  availableBills: true,
};

export const BatchVBills = observer(function BatchVBills() {
  const vBillBatchDetailsStore = useStore("VBillBatchDetailsStore");
  const { batchId, organizationId } = useParams<{ batchId: string; organizationId: string }>();
  const [showDraggingOverlayFrom, setshowDraggingOverlayFrom] = useState<VBillDroppableArea>();
  const [billsAccordianExpanded, setBillsAccordianExpanded] =
    useSetState<IBillsAccordianExpanded>(defaultBillsAccordianExpanded);
  useEffect(() => {
    setBillsAccordianExpanded(defaultBillsAccordianExpanded);
  }, [batchId, setBillsAccordianExpanded]);

  const { batchAvailableVBills, batchFilters } = vBillBatchDetailsStore;

  const reloadBatchVBills = async () => {
    await vBillBatchDetailsStore.getBatchSelectedVBills(
      {
        pagination: batchFilters.vbill_sel_pag,
        filters: { ...batchFilters.vbill_sel_fil, organizationId, batch_id_in: [Number(batchId)] },
      },
      true,
      false,
    );
    await vBillBatchDetailsStore.getBatchAvailableVBills(
      {
        pagination: batchFilters.vbill_ava_pag,
        filters: {
          ...batchFilters.vbill_ava_fil,
          organizationId,
          batch_id_in: [0],
        },
      },
      true,
      false,
    );
  };

  const handleDeleteVBillsFromBatch = async (ids: number[]) => {
    await vBillBatchDetailsStore.removeVBillFromBatch({
      batchId: Number(batchId),
      invoiceIds: ids,
    });
    await reloadBatchVBills();
  };

  const handleAddAllVBillsToBatch = async () => {
    await VBillAddAllInvoicesToBatch({
      batchId: Number(batchId),
      billsFilters: {
        ...batchFilters.vbill_ava_fil,
        organizationId,
        batch_id_in: [0],
        free_amount_from: "0.01",
        status: IVBillInvoiceIncomingStatus.Approved,
      },
    });
    await reloadBatchVBills();
  };

  const handleAddVBillsToBatch = async (ids: number[]) => {
    const batchInvoices = (batchAvailableVBills.data?.items ?? []).reduce<IVBillBatchInvoiceInput[]>(
      (result, { id, amount, amounts }) =>
        ids.includes(id)
          ? (result = [
              ...result,
              {
                invoiceId: id,
                // FIXME: we don't need to send amounts for the initial add, since the backend will compute them
                // batchAmountForInvoice: `${
                //   Number(amount) -
                //   (amounts?.amountInBatchesBreakdown ?? []).reduce(
                //     (result, amount) => (result = result + Number(amount.batchAmountForInvoice ?? 0)),
                //     0,
                //   )
                // }`,
              },
            ])
          : result,
      [],
    );
    await vBillBatchDetailsStore.addVBillToBatch({
      batchId: Number(batchId),
      batchInvoices,
    });
    await reloadBatchVBills();
  };

  const handleVBillDragEnd = ({ source, destination, draggableId }: DropResult) => {
    setshowDraggingOverlayFrom(undefined);

    if (!destination || source.droppableId === destination.droppableId) {
      return;
    }

    if (
      source.droppableId === VBillDroppableArea.AVAILABLE &&
      destination.droppableId === VBillDroppableArea.SELECTED
    ) {
      handleAddVBillsToBatch([Number(draggableId)]);
    }

    if (
      source.droppableId === VBillDroppableArea.SELECTED &&
      destination.droppableId === VBillDroppableArea.AVAILABLE
    ) {
      handleDeleteVBillsFromBatch([Number(draggableId)]);
    }
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: "0%",
        position: "relative",
        paddingRight: "5px",
        // "&:after": {
        //   content: '""',
        //   borderRight: `1px solid #0000001f`,
        //   position: "absolute",
        //   zIndex: "1",
        //   top: "0",
        //   right: "-3px",
        //   height: "100%",
        // },
      }}
    >
      <DragDropContext
        onDragEnd={handleVBillDragEnd}
        onDragStart={(start) => setshowDraggingOverlayFrom(start.source.droppableId as VBillDroppableArea)}
        nonce={(window as any).nonce}
      >
        <BatchVBillsSelected
          billsAccordianExpanded={billsAccordianExpanded}
          setBillsAccordianExpanded={setBillsAccordianExpanded}
          onDeleteVBillsFromBatch={handleDeleteVBillsFromBatch}
          showDraggingOverlay={showDraggingOverlayFrom === VBillDroppableArea.AVAILABLE}
        />

        <BatchVBillsAvailable
          billsAccordianExpanded={billsAccordianExpanded}
          setBillsAccordianExpanded={setBillsAccordianExpanded}
          onAddVBillsToBatch={handleAddVBillsToBatch}
          showDraggingOverlay={showDraggingOverlayFrom === VBillDroppableArea.SELECTED}
          onAddAllVBillsToBatch={handleAddAllVBillsToBatch}
        />
      </DragDropContext>
    </Box>
  );
});
