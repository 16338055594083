import { useMemo } from "react";
import styles from "./CompanyPendingChecksUtils.module.scss";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { formatCurrency } from "common/helpers/utils";
import InboxIcon from "@mui/icons-material/Inbox";
import Cookies from "js-cookie";

function useCompanyPendingChecksColumns(
  _path: string,
  _startAddingPermissions: Function,
  startAccountMapping: Function,
  startUserMapping: Function,
  companyId: string,
  bankAccountsWithGl: any,
  integrationId: string,
  setArchiveModalOpen: Function,
  setChecksToBeArchived: Function,
  selectedOrganizationId?: string,
  _setOrganizationUserId?: Function,
  setSigner?: Function,
) {
  const handleArchiveCheck = (entity_ref: string) => {
    setArchiveModalOpen(true);
    setChecksToBeArchived([entity_ref]);
  };
  return useMemo(
    () => [
      {
        Header: "External Ref",
        disableSortBy: true,
        accessor: "entity_ref",
        Cell: ({ row }: any) => {
          return (
            <div style={{ color: "#3DB8E7" }} className={styles.tableRow}>
              {row.original.entity_ref}
            </div>
          );
        },
      },
      {
        Header: "Date",
        disableSortBy: true,
        accessor: "date",
        Cell: ({ value }) => {
          return (
            <div style={{ color: "#707070" }} className={styles.tableRow}>
              {dateFormat(value.split("T")[0])}
            </div>
          );
        },
      },
      {
        Header: "Vendor",
        disableSortBy: true,
        accessor: "pttoo",
        Cell: ({ value }) => {
          return (
            <div style={{ color: "#707070" }} className={styles.tableRow}>
              {value}
            </div>
          );
        },
      },
      {
        Header: "Amount",
        disableSortBy: true,
        accessor: "amount",
        Cell: ({ value }) => {
          return (
            <div style={{ color: "#707070" }} className={styles.tableRow}>
              {formatCurrency(value)}
            </div>
          );
        },
      },

      {
        Header: "Error Details",
        disableSortBy: true,
        accessor: "memo",
        Cell: ({ row }) => {
          return (
            <div style={{ color: "#C63331", fontWeight: 600 }} className={styles.tableRow}>
              {row.original?.errors?.length ? row.original?.errors?.[0]?.message : ""}
            </div>
          );
        },
      },

      {
        Header: "GL Account",
        disableSortBy: true,
        accessor: "gl_account",
        Cell: ({ row }) => {
          return <div>{row.original.gl_account?.full_name}</div>;
        },
      },
      {
        Header: "Resolve",
        disableSortBy: true,
        accessor: "action",
        Cell: ({ row }) => {
          return row.original?.errors?.length ? (
            row.original?.errors[0]?.type === "UNMAPPED_GL_ACCOUNT" ? (
              <Button
                onClick={(_e: any) => {
                  startAccountMapping(row.original.gl_account);
                }}
                variant="outlined"
              >
                {`MAP ACCOUNT`}
              </Button>
            ) : /* row.original?.errors[0]?.type === "INSUFFICIENT_PERMISSION" ? (
              <Link
                style={{ textDecoration: "none" }}
                to={`/org/${selectedOrganizationId}/users2/permissions/user/${
                  JSON.parse(row.original.errors[0]?.data).organization_user_id
                }/bank_account/${findBankAccountId(
                  bankAccountsWithGl,
                  row.original.gl_account
                )}?msg=Please grant access to Create vChecks for at least $${row.original.amount}`}
              >
                <Button
                  onClick={() => {
                 
                  }}
                  variant="outlined"
                >
                  set permissions
                </Button>
              </Link>
            ) : 
            !TEMPORARILY DISABLED
            */ row.original?.errors[0]?.type === "UNMAPPED_USER" ? (
              <Button
                onClick={() => {
                  startUserMapping();
                  if (setSigner && JSON.parse(row.original.errors[0]?.data).signer)
                    setSigner(JSON.parse(row.original.errors[0]?.data).signer);
                }}
                variant="outlined"
              >
                MAP USER
              </Button>
            ) : (
              ""
            )
          ) : (
            ""
          );
        },
      },
      {
        Header: "Archive",
        disableSortBy: true,
        accessor: "archive",
        Cell: ({ row }) => {
          return (
            <IconButton
              onClick={() => {
                handleArchiveCheck(row.original.entity_ref);
              }}
            >
              <InboxIcon />
            </IconButton>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [integrationId, companyId],
  );
}
// const findBankAccountId = (bankAccountsWithGl: any, glAccount: any) => {
//   let bankAccountIdToReturn = "";
//   bankAccountsWithGl.forEach((bankAccount: any) => {
//     if (bankAccount?.external_ref === glAccount?.external_ref) {
//       bankAccountIdToReturn = bankAccount.id;
//     }
//   });
//   return bankAccountIdToReturn;
// };
const dateFormat = (date: string) => {
  if (date) {
    const dateArr = date.replace(/-/g, "/");
    return dateArr;
  }
  return null;
};

export { useCompanyPendingChecksColumns };
