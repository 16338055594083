import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Box, Checkbox, TableCell, TableRow, Typography } from "@mui/material";
import { CheckBatchItemType, CheckFiltersType, ReactionEnumType } from "generated/sdk";
import { capitalize, isEqual } from "lodash";
import { useEffect, useRef, useState } from "react";
import { DraggableProvided, DraggableStateSnapshot } from "react-beautiful-dnd";
import { JsonParam, useQueryParam } from "use-query-params";
import { highlightMatchingText } from "../VChecks/VChecksTable/VChecksTable";
import { formatWords, getStyles } from "../VChecks/utils";
import styles from "./BudgetManagement.module.scss";
import { CheckBatchItemsReactions } from "./CheckBatchItemsReactions";
import { useBatchDetailsContext } from "./useBatchDetailsContext";
import { formatCurrency } from "./utils";

export const CheckBatchItemTableRow = ({
  item,
  countReactions,
  selectedItems,
  handleRowClick,
  handleCheckBoxCellClick,
  handleReactionClick,

  index = 0,
  provided,
  snapshot,
  totalAmount,
  budget,
}: {
  item?: CheckBatchItemType;
  countReactions: (id: string) => {
    likeCount: number;
    dislikeCount: number;
    warnCount: number;
  };
  selectedItems: Record<string, boolean>;
  handleRowClick: () => Promise<void>;
  handleCheckBoxCellClick: (e: React.MouseEvent<HTMLTableCellElement, MouseEvent>) => void;
  handleReactionClick?: (check_batch_item_ids: string[], reaction: ReactionEnumType) => Promise<void>;

  index?: number;
  provided?: DraggableProvided;
  snapshot?: DraggableStateSnapshot;
  totalAmount?: number;
  budget?: number;
}): any => {
  const [filters] = useQueryParam<CheckFiltersType>("filters", JsonParam);
  const {
    props: { isLoading, isBatchClosed },
  } = useBatchDetailsContext();

  const { likeCount, dislikeCount, warnCount } = countReactions(item?.id as string);
  const check = item?.check;
  const {
    number,
    date,
    pttoo,
    amount,
    sender_title,
    sender_subtitle,
    recipient_title,
    recipient_subtitle,
    status,
    required_actions,
  } = check!;
  const formatedDate = new Date(date as string).toLocaleDateString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
  const formatedAmount = formatCurrency(amount as number);
  const selected = Object.keys(selectedItems)?.includes(item?.check?.id!);
  const [hasChanged, setHasChanged] = useState(false);
  const prevDataRef = useRef<CheckBatchItemType | undefined>();

  const newTotal = (totalAmount as number) - (check?.amount as number);
  const willBeOverBudget = (budget as number) < newTotal;

  useEffect(() => {
    if (
      prevDataRef.current &&
      prevDataRef?.current?.check?.id === item?.check?.id &&
      !isEqual(item, prevDataRef.current)
    ) {
      setHasChanged(true);
      setTimeout(() => {
        setHasChanged(false);
      }, 10000);
    }
    prevDataRef.current = item!;
  }, [item]);

  const tableRowStyle = hasChanged
    ? { border: "2px solid green", ...provided?.draggableProps.style }
    : { ...provided?.draggableProps.style };
  const tableRowSx = {
    ...(snapshot?.isDragging && {
      ":before": {
        content: `"Removing this check will take away ${formatCurrency(
          check?.amount as number,
        )} from the Total making it ${formatCurrency(newTotal)}. The batch will be ${
          !willBeOverBudget ? "underbudget" : "overbudget"
        } by ${formatCurrency(
          willBeOverBudget ? newTotal - (budget as number) : (budget as number) - newTotal,
        )} out of ${formatCurrency(budget as number)}"`,
        width: "100px",
        heigth: "50px",
        background: "#CD5C5C",
        padding: "15px",
      },
    }),
  };

  return (
    <TableRow
      ref={provided?.innerRef}
      data-rowid={item?.check?.id}
      {...provided?.draggableProps}
      {...provided?.dragHandleProps}
      selected={selected}
      onClick={handleRowClick}
      style={tableRowStyle}
      sx={tableRowSx}
    >
      <TableCell onClick={handleCheckBoxCellClick}>
        <Checkbox checked={!!selectedItems[item?.check?.id as string]} disabled={isBatchClosed} />
      </TableCell>
      <TableCell title={number!} sx={{ position: "relative" }}>
        <Box className={styles["vChecksTable-btn-border"]}>
          <Box className={styles.tableRow}>
            <Box className={styles.sender} sx={{ justifyContent: "flex-start" }}>
              <ArrowUpwardIcon className={styles.arrow} />
              {highlightMatchingText(number!, filters?.search?.search_input!)}
            </Box>
          </Box>
          {handleReactionClick && (
            <CheckBatchItemsReactions
              isLoading={isLoading}
              isBatchClosed={isBatchClosed}
              likeCount={likeCount}
              dislikeCount={dislikeCount}
              warnCount={warnCount}
              handleReactionClick={handleReactionClick}
              itemId={item?.id!}
            />
          )}
        </Box>
      </TableCell>
      <TableCell title={formatedDate}>
        <Typography fontWeight="500">{formatedDate}</Typography>
      </TableCell>
      <TableCell title={sender_title!}>
        <Box display="flex" flexDirection="column">
          <Typography variant="body1" className={styles.longTextEllipsis}>
            {highlightMatchingText(sender_title!, filters?.search?.search_input!)}
          </Typography>
          <Typography className={styles.bank}>
            {highlightMatchingText(sender_subtitle!, filters?.search?.search_input!)}
          </Typography>
        </Box>
      </TableCell>
      <TableCell title={pttoo!}>
        <Box display="flex" flexDirection="column">
          <Typography variant="body1" className={styles.longTextEllipsis}>
            {highlightMatchingText(recipient_title!, filters?.search?.search_input!)}
          </Typography>
          <Typography className={styles.bank}>
            {highlightMatchingText(recipient_subtitle!, filters?.search?.search_input!)}
          </Typography>
        </Box>
      </TableCell>
      <TableCell title={formatedAmount}>
        <Typography textTransform="uppercase" fontWeight="500">
          {formatedAmount}
        </Typography>
      </TableCell>
      <TableCell title={status?.status!}>
        {/* <Typography fontWeight="500" className={getStyles(status?.status!)} textTransform="capitalize">
          {formatWords(status?.required_action!)?.toLocaleLowerCase()}
        </Typography> */}
        <Typography fontSize="12px" fontWeight="400" color="#697281">
          {status?.status}
        </Typography>
        {required_actions?.map((action) => {
          return (
            <Typography
              className={getStyles(status?.status!)}
              fontSize="12px"
              fontWeight="400"
              sx={{ color: "#697281" }}
            >
              {capitalize(formatWords(action?.description as string).toLowerCase())}
            </Typography>
          );
        })}
      </TableCell>
    </TableRow>
  );
};
