import { ExitIcon } from "icons/svg/ExitIcon";
import Modal from "react-modal";
import styles from "./ModalPanel.module.scss";

export const ModalPanel: React.FunctionComponent<ReactModal.Props> = ({ children, isOpen, ...props }) => {
  // put the modal in the render tree only when it's open to enable stacked modals
  return isOpen ? (
    <Modal
      {...props}
      isOpen
      className={styles.content}
      overlayClassName={styles.overlay}
      bodyOpenClassName={styles.modalOpened}
    >
      <button className={styles.closeBtn}>
        <ExitIcon onClick={props.onRequestClose} className={styles.closeBtnIcon} />
      </button>
      {children}
    </Modal>
  ) : null;
};
