import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { BatchMembersDialog } from "components/pages/VBillBatchDetails/common/BatchMembersDialog/BatchMembersDialog";
import { sortBy } from "lodash";
import { observer } from "mobx-react";
import { useMemo, useState } from "react";
import { useStore } from "storeContainer";
import { labelTextStyles } from "../VBillAddFullBatchDialog";

interface IAddFullBatchMembersProps {
  memberIds?: string[];
  onMembersSubmit: (memberIds: string[]) => void;
}

export const AddFullBatchMembers = observer(function AddFullBatchMembers({
  memberIds,
  onMembersSubmit,
}: IAddFullBatchMembersProps) {
  const orgUserStore = useStore("OrganizationUsersStore");
  const sessionStore = useStore("SessionStore");
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const { organizationUsersList } = orgUserStore;

  const memberNames = useMemo(
    () =>
      sortBy(
        (memberIds ?? []).reduce<string[]>((result, memberId) => {
          if (
            (organizationUsersList.data ?? []).find(({ id }) => id === memberId)?.account?.id !==
            sessionStore.session.data?.account?.id
          ) {
            result = [
              ...result,
              (organizationUsersList.data ?? []).find(({ id }) => id === memberId)?.account?.name ?? "",
            ];
          }

          return result;
        }, []),
        (name) => name,
      ),
    [memberIds, organizationUsersList.data, sessionStore.session.data?.account?.id],
  );
  const totalBatchMembers = useMemo(
    () =>
      (memberIds ?? []).filter(
        (memberId) =>
          (organizationUsersList.data ?? []).find(({ id }) => id === memberId)?.account?.id !==
          sessionStore.session.data?.account?.id,
      ).length,
    [memberIds, organizationUsersList.data, sessionStore.session.data?.account?.id],
  );
  const batchMemberIds = useMemo(
    () =>
      (memberIds ?? []).filter(
        (memberId) =>
          memberId !==
          (organizationUsersList.data ?? []).find(
            ({ account }) => account?.id === sessionStore.session.data?.account?.id,
          )?.id,
      ),
    [memberIds, organizationUsersList.data, sessionStore.session.data?.account?.id],
  );

  const handleMembersDialogSave = async (selectedMemberIds: string[]) => {
    onMembersSubmit(selectedMemberIds);
    setIsDialogOpen(false);
  };

  return (
    <Box sx={{ marginBottom: "15px" }}>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <Typography sx={labelTextStyles}>
          Members{" "}
          <Box component="sup" sx={{ fontSize: "10px" }}>
            ({totalBatchMembers})
          </Box>
        </Typography>

        <Tooltip title={totalBatchMembers < 1 ? "Add members" : "Edit members"} arrow>
          <IconButton onClick={() => setIsDialogOpen(true)}>
            {totalBatchMembers < 1 ? <AddCircleOutlineIcon /> : <EditIcon />}
          </IconButton>
        </Tooltip>
      </Box>
      {totalBatchMembers > 0 && (
        <Typography>
          Selected:{" "}
          {memberNames.map((name, index) => (
            <span key={name}>
              {name}
              {memberNames.length - 1 > index && ", "}
            </span>
          ))}
        </Typography>
      )}

      {isDialogOpen && (
        <BatchMembersDialog
          suggestionsComponent="dialog"
          selectedMembers={batchMemberIds}
          onMembersSave={handleMembersDialogSave}
          onDialogClose={() => setIsDialogOpen(false)}
          actionBtnsSmallHeight
        />
      )}
    </Box>
  );
});
