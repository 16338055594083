import AddIcon from "@mui/icons-material/Add";
import { Box, Button, Grid, TablePagination, Typography } from "@mui/material";
import { useCheckFilters } from "common/hooks/useCheckFilters";
import { CheckBatchType, GetCheckBatchesFiltersType } from "generated/sdk";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useStore } from "storeContainer";
import AddEditBatchModal from "./AddEditBatchModal";
import styles from "./BudgetManagement.module.scss";
import BudgetManagementFilters from "./BudgetManagementFilters";
import BudgetManagementTable from "./BudgetManagementTable";
import ConfirmDeleteBatchModal from "./ConfirmDeleteBatchModal";

const BudgetManagement: React.FC = () => {
  const BudgetManagementStore = useStore("BudgetManagementStore");
  const { checkBatches, fetchCheckBatches } = BudgetManagementStore;
  const [openAddBatchModal, setOpenAddBatchModal] = useState(false);
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState(false);
  const [selectedBatchToEdit, setSelectedBatchToEdit] = useState<CheckBatchType>();
  const [selectedBatchId, setSelectedBatchId] = useState<string | undefined>("");

  const { filters, pagination, setPagination } = useCheckFilters<GetCheckBatchesFiltersType>();

  const { organizationId } = useParams<{ organizationId?: string }>();
  useEffect(() => {
    if (organizationId) {
      fetchCheckBatches(organizationId, filters, pagination);
    }
  }, [filters, pagination, organizationId, fetchCheckBatches]);

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPagination((prev) => {
      return { ...prev, per_page: parseInt(event.target.value, 10), page: 1 };
    });
  };

  const handlePageChange = (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPagination((prev) => {
      return { ...prev, page: newPage + 1 };
    });
  };
  const handleOpenAddBatchModal = () => {
    setOpenAddBatchModal(true);
  };
  const handleEditBatch = (batch: CheckBatchType) => {
    setSelectedBatchToEdit(batch);
    setOpenAddBatchModal(true);
  };

  const handleConfirmOpenDeleteBatchModal = (batchId: string) => {
    setSelectedBatchId(batchId);
    setIsConfirmDeleteModalOpen(true);
  };
  return (
    <Grid>
      <Grid alignItems={"flex-end"} justifyContent={"space-between"} container flexDirection={"row"}>
        <Grid item>
          <BudgetManagementFilters />
        </Grid>
        <Grid margin="20px 0 20px 0" item>
          <Box sx={{ height: "fit-content" }}>
            <Button variant="contained" onClick={handleOpenAddBatchModal}>
              <AddIcon
                sx={{
                  color: "white",
                  transform: "scale(1.2)",
                  marginRight: "10px",
                }}
              />
              <Typography color={"white"}>Add Batch</Typography>
            </Button>
          </Box>
        </Grid>
      </Grid>
      <BudgetManagementTable
        batches={checkBatches.data}
        isLoading={checkBatches.isLoading}
        rowsPerPage={pagination?.per_page || 10}
        handleEditBatch={handleEditBatch}
        handleConfirmOpenDeleteBatchModal={handleConfirmOpenDeleteBatchModal}
      />

      <TablePagination
        showFirstButton={true}
        showLastButton={true}
        count={checkBatches.data?.pagination?.total!}
        page={(pagination?.page || 1) - 1}
        onRowsPerPageChange={handleChangeRowsPerPage}
        onPageChange={handlePageChange}
        rowsPerPage={pagination?.per_page || 10}
        component={"form"}
        className={styles.paginationSticky}
      />

      <AddEditBatchModal
        selectedBatchToEdit={selectedBatchToEdit}
        setSelectedBatchToEdit={setSelectedBatchToEdit}
        isAddBatchModalOpen={openAddBatchModal}
        setIsAddBatchModalOpen={setOpenAddBatchModal}
        ledgerFilters={filters}
        reloadLedger
      />
      <ConfirmDeleteBatchModal
        selectedBatchId={selectedBatchId}
        setSelectedBatchId={setSelectedBatchId}
        isConfirmDeleteModalOpen={isConfirmDeleteModalOpen}
        setIsDeleteConfirmModalOpen={setIsConfirmDeleteModalOpen}
      />
    </Grid>
  );
};

export default observer(BudgetManagement);
