import CheckIcon from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/Error";
import SyncIcon from "@mui/icons-material/Sync";
import SyncDisabledIcon from "@mui/icons-material/SyncDisabled";
import { Box, Tooltip } from "@mui/material";
import { friendlyInvoiceIncomingStatusNames } from "components/pages/common/VBill/utils";
import { IVBillInvoiceIncomingStatus, IVBillInvoicePushStatus, IVBillVBillInvoicesQuery } from "generated/sdk.vbill";
import React, { ReactNode } from "react";
import { COLORS } from "themes/default";
import styles from "./vBillStatus.module.scss";

const SyncTooltipText: { [key in IVBillInvoicePushStatus | ""]?: string } = {
  [IVBillInvoicePushStatus.Error]: "Failed to sync",
  [IVBillInvoicePushStatus.Queued]: "Syncing in progress",
  [IVBillInvoicePushStatus.Done]: "Synced",
  "": "n/a",
};

const SyncIconMap: { [key in IVBillInvoicePushStatus | ""]: ReactNode } = {
  [IVBillInvoicePushStatus.Error]: <ErrorIcon sx={{ fontSize: "20px", color: COLORS.error }} />,
  [IVBillInvoicePushStatus.Queued]: <SyncIcon sx={{ fontSize: "20px", color: COLORS.inactiveBlue }} />,
  [IVBillInvoicePushStatus.Done]: <CheckIcon sx={{ fontSize: "20px", color: COLORS.success }} />,
  "": <SyncDisabledIcon sx={{ fontSize: "20px" }} />,
};

type IVBillStatusProps = Pick<IVBillVBillInvoicesQuery["invoices"]["items"][number], "status" | "pushStatus">;
export const VBillStatus: React.FunctionComponent<IVBillStatusProps> = ({ status, pushStatus }) => {
  return (
    <Box className={styles[status as keyof typeof styles]} sx={{ display: "flex", alignItems: "center" }}>
      {status !== IVBillInvoiceIncomingStatus.Approved ? (
        friendlyInvoiceIncomingStatusNames[status]
      ) : (
        <>
          <Tooltip title={SyncTooltipText[`${pushStatus ?? ""}`]}>
            <>
              {SyncIconMap[`${pushStatus ?? ""}`]}
              {/* {Object.keys(SyncIconMap).map((k) => (SyncIconMap as any)[k])} */}
            </>
          </Tooltip>
          {SyncTooltipText[`${pushStatus ?? ""}`]}
        </>
      )}
    </Box>
  );
};
