import { Box, FormControlLabel, Switch, Typography } from "@mui/material";
import { IVBillFormMainFileOption, TVBillFormFields, TVBillFormLineFields } from "components/pages/VBill/types";
import { observer } from "mobx-react";
import { ChangeEvent, useMemo } from "react";
import { useController, useFormContext, useWatch } from "react-hook-form";
import { useStore } from "storeContainer";
import { emptyOCRFormLineFields, lineItemMetaToFormLineFields } from "../../../utils";

export const LineItemsApplySuggestions = observer(() => {
  const vBillStore = useStore("VBillStore");
  const formMainFileObserver: IVBillFormMainFileOption | null = useWatch({ name: "mainFile" });
  const formlineItemsObserver: TVBillFormLineFields[] = useWatch({ name: "lineItems" });
  const formMethods = useFormContext<TVBillFormFields>();
  const {
    field: { value: hasLineItemsFieldValue },
  } = useController<TVBillFormFields>({ name: "hasLineItems" });
  const { invoice } = vBillStore;
  const invoiceData = useMemo(() => invoice.data, [invoice.data]);
  const selectedFileMetadata = useMemo(
    () => invoiceData?.files?.find(({ id }) => id === formMainFileObserver?.value)?.metadata?.GDAI,
    [invoiceData?.files, formMainFileObserver?.value],
  );
  const suggestionsCounter = useMemo(
    () =>
      hasLineItemsFieldValue
        ? (formlineItemsObserver ?? []).length
        : // : invoiceData?.status === IVBillInvoiceIncomingStatus.Queued
          //   ? selectedFileMetadata?.lineItems?.length ?? 0
          //   : invoiceData?.lineItems?.length ?? 0,
          selectedFileMetadata?.lineItems?.length ?? 0,

    [
      hasLineItemsFieldValue,
      formlineItemsObserver,
      invoiceData?.status,
      selectedFileMetadata?.lineItems?.length,
      invoiceData?.lineItems?.length,
    ],
  );

  const handleSwitchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const isSwitchChecked = event.target.checked;

    if (isSwitchChecked) {
      // if (invoiceData?.status === IVBillInvoiceIncomingStatus.Queued) {
      const vBillFormLineItemsValues = (selectedFileMetadata?.lineItems ?? []).map((lineItem) =>
        lineItemMetaToFormLineFields(lineItem),
      );
      const formValues = {
        ...formMethods.getValues(),
        hasLineItems: true,
        lineItems: vBillFormLineItemsValues.length ? vBillFormLineItemsValues : [emptyOCRFormLineFields],
      };

      formMethods.reset(formValues);
      // } else {
      //   const vBillFormLineItemsValues =
      //     invoiceData?.mainFile?.id === formMainFileObserver?.value
      //       ? invoiceData?.hasLineItems
      //         ? (invoiceData?.lineItems ?? []).map((lineItem) => lineItemToFormLineFields(lineItem))
      //         : [emptyOCRFormLineFields]
      //       : (selectedFileMetadata?.lineItems ?? []).map((lineItem) => lineItemMetaToFormLineFields(lineItem));

      //   const formValues = {
      //     ...formMethods.getValues(),
      //     hasLineItems: true,
      //     lineItems: vBillFormLineItemsValues.length ? vBillFormLineItemsValues : [],
      //   };

      //   formMethods.reset(formValues);
      // }
    } else {
      const formValues = { ...formMethods.getValues(), hasLineItems: false, lineItems: [] };

      formMethods.reset(formValues);
    }
  };

  return (
    <Box sx={{ marginBottom: "5px" }}>
      <FormControlLabel
        control={<Switch checked={(hasLineItemsFieldValue as boolean) ?? false} onChange={handleSwitchChange} />}
        label={
          <Typography>
            Itemized bill{" "}
            <Box component="strong" sx={{ fontSize: "12px" }}>
              ({suggestionsCounter})
            </Box>
          </Typography>
        }
      />
    </Box>
  );
});
