import { AclEntityTypeEnum } from "generated/sdk";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import CorporateFareOutlinedIcon from "@mui/icons-material/CorporateFareOutlined";
import StoreOutlinedIcon from "@mui/icons-material/StoreOutlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import Box from "@mui/material/Box";
import { observer } from "mobx-react-lite";
import { useStore } from "storeContainer";
import { useEffect, useState } from "react";
import styles from "./Permissions2.module.scss";
import { Typography } from "@mui/material";

export const Permissions2UtilEntityResolver: React.FunctionComponent<{
  entity_type?: AclEntityTypeEnum;
  entity_id: string;
}> = observer(function Permissions2UtilEntityResolver({ entity_type, entity_id }) {
  const acl2 = useStore("AclStore2");
  const [entity, setEntity] = useState<
    | {
        id: string;
        name: string;
        type: AclEntityTypeEnum;
        parents: string[];
      }
    | undefined
  >();
  useEffect(() => {
    setEntity(
      acl2.organizationEntitiesList.find((oe) => oe.id === entity_id && (entity_type ? oe.type === entity_type : true)),
    );
  }, [entity_type, entity_id, acl2.organizationEntitiesList]);

  return <Permissions2UtilEntity entity_name={entity?.name || entity_id} entity_type={entity?.type || entity_type} />;
});

export const Permissions2UtilEntity: React.FunctionComponent<{
  entity_type?: AclEntityTypeEnum;
  entity_name: string;
}> = ({ entity_type, entity_name }) => {
  const icon = (
    <>
      {entity_type === AclEntityTypeEnum.Organization ? <CorporateFareOutlinedIcon htmlColor="#CC3232" /> : null}
      {entity_type === AclEntityTypeEnum.Company ? <StoreOutlinedIcon htmlColor="#DB8C15" /> : null}
      {entity_type === AclEntityTypeEnum.BankAccount ? <AccountBalanceOutlinedIcon htmlColor="#61B54B" /> : null}
    </>
  );
  return (
    <Box
      marginLeft={entity_type === "ORGANIZATION" ? "0" : entity_type === "COMPANY" ? "15px" : "30px"}
      sx={{ display: "flex", alignItems: "center" }}
    >
      {entity_type === AclEntityTypeEnum.Organization ? (
        <>
          {/* <Typography variant={"subtitle2"} title={entity_name}> */}
          <span style={{ display: "flex" }} title={entity_type}>
            {icon}
          </span>
          Organization
          {/* </Typography> */}
        </>
      ) : (
        <>
          {/* <Typography variant={"subtitle2"} title={entity_type}> */}
          <span style={{ display: "flex" }} title={entity_type}>
            {icon}
          </span>
          {entity_name}
          {/* </Typography> */}
        </>
      )}
    </Box>
  );
};

export const Permissions2UtilUserResolver: React.FunctionComponent<{
  isUser?: boolean;
  user_id?: string;
  className?: any;
}> = observer(function ({ isUser, user_id, className }) {
  const acl2 = useStore("AclStore2");
  const [user, setUser] = useState<{ name: string } | undefined>();
  useEffect(() => {
    const user = isUser
      ? acl2.organizationUsersList.find((ou) => ou.id === user_id)
      : acl2.organizationGroupList.find((og) => og.id === user_id);
    setUser(user);
  }, [isUser, user_id, acl2.organizationUsersList, acl2.organizationGroupList]);

  return user_id ? (
    <Permissions2UtilUser user_name={user?.name || user_id} isUser={isUser} className={className} />
  ) : null;
});

export const Permissions2UtilUser: React.FunctionComponent<{
  isUser?: boolean;
  user_name: string;
  className?: any;
}> = ({ isUser, user_name, className }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }} className={className}>
      {/* <Typography
        variant={"subtitle2"}
        sx={{
          color: COLORS.logoBlue,
        }}
      > */}
      <span title={isUser ? "User" : "User group"} className={styles.titleIcon}>
        {isUser ? <PersonOutlineOutlinedIcon color={"secondary"} /> : <GroupOutlinedIcon color={"primary"} />}
      </span>
      {user_name}
      {/* </Typography> */}
    </Box>
  );
};
