import { LoadingButton } from "@mui/lab";
import { Alert, Box, FormControl, InputLabel, MenuItem, Select, Snackbar } from "@mui/material";
import { graphqlClient } from "common/graphqlClient";
import {
  DelegationGroupRequirementTypeEnum,
  DelegationGroupScopeEnum,
  DelegationGroupTypeEnum,
  DelegationRoleEnum,
  getSdk,
  OrganizationUserEnum,
} from "generated/sdk";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useStore } from "storeContainer";
import { useDelegationGroups } from "./useDelegationGroups";

interface IRootDelegationGroupWidgetProps {
  scope: DelegationGroupScopeEnum;
  // type: RootApprovalWidgetTypes;
  organizationId: string;
  companyId?: string;
  vendorId?: string;
}

export const RootDelegationGroupWidget: React.FunctionComponent<IRootDelegationGroupWidgetProps> = observer(
  ({ scope, organizationId, companyId, vendorId }) => {
    const organizationUsersStore = useStore("OrganizationUsersStore");
    const [rootDelegatorId, setRootDelegatorId] = useState<string>();
    const [hasError, setHasError] = useState<boolean>(false);
    const [isSaveBtnLoading, setIsSaveBtnLoading] = useState(false);
    const [delegationGroupsResponse, delegationGroupsLoading, reloadDelegationGroups] = useDelegationGroups(
      scope,
      {
        organizationId,
        companyId,
        vendorId,
      },
      undefined,
      undefined,
      true,
    );

    useEffect(() => {
      if (delegationGroupsResponse?.delegation_groups?.length) {
        setRootDelegatorId(delegationGroupsResponse.delegation_groups[0].delegator.id);
      }
    }, [delegationGroupsResponse, delegationGroupsLoading]);

    useEffect(() => {
      organizationUsersStore.loadOrganizationUsers();
    }, []);

    const saveRootApprover = async () => {
      setHasError(false);
      setIsSaveBtnLoading(true);

      try {
        const response = await getSdk(graphqlClient).SaveDelegationGroup({
          scope,
          type: DelegationGroupTypeEnum.Approval,
          requirement_type: DelegationGroupRequirementTypeEnum.Everyone,
          delegation_group_id: delegationGroupsResponse?.delegation_groups?.length
            ? delegationGroupsResponse.delegation_groups[0].id
            : null,
          organization_id: organizationId,
          company_id: companyId,
          vendor_id: vendorId,
          is_root: true,
          root_delegator_id: rootDelegatorId,
          approvals_required: 1,
          reviews_required: 0,
          delegations: [
            {
              delegate_user_id: rootDelegatorId!,
              role: DelegationRoleEnum.Approver,
            },
          ],
        });

        if (!response.SaveDelegationGroup) {
          setHasError(true);
        } else {
          reloadDelegationGroups();
        }
      } catch (e) {
        setHasError(true);
      } finally {
        setIsSaveBtnLoading(false);
      }
    };

    return (
      <div>
        <Box sx={{ display: "flex", alignItems: "center", gap: "10px", marginTop: "20px" }}>
          <FormControl sx={{ minWidth: "204px" }}>
            <InputLabel id="root-delagation-select-label">{rootDelegatorId ? "User" : "Select user"}</InputLabel>
            <Select
              labelId="root-delagation-select-label"
              id="root-delagation-select"
              value={rootDelegatorId ?? ""}
              label="User"
              onChange={(e) => setRootDelegatorId(e.target.value)}
              MenuProps={{ PaperProps: { style: { maxHeight: "400px" } } }}
            >
              {organizationUsersStore.organizationUsersList.data
                ?.filter((orgUser) => orgUser.state === OrganizationUserEnum.Active)
                .map((orgUser) => (
                  <MenuItem key={orgUser.id} value={orgUser.id}>
                    {orgUser.account?.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>

          <LoadingButton loading={isSaveBtnLoading} onClick={saveRootApprover} disabled={!rootDelegatorId}>
            Save
          </LoadingButton>
        </Box>

        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={hasError}
          autoHideDuration={6000}
          onClose={() => setHasError(false)}
        >
          <Alert onClose={() => setHasError(false)} severity="error" sx={{ width: "100%" }}>
            Error: Your changes could not be saved.
          </Alert>
        </Snackbar>
      </div>
    );
  },
);
