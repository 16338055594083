import React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export const ReadyToDeposit: React.FC<SvgIconProps> = ({ fill, stroke, ...props }) => {
  return (
    <SvgIcon {...props} width="44" height="44" viewBox="0 0 44 44" fill="none">
      <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="44" height="44" rx="22" fill={fill} />
        <path
          d="M21.3674 27.1846L21.3674 21.2603"
          stroke={stroke}
          strokeOpacity="0.75"
          strokeWidth="1.2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24.3298 23.4819L24.3298 27.1846"
          stroke={stroke}
          strokeOpacity="0.75"
          strokeWidth="1.2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.8486 14.9652L26.5512 14.9652C26.9604 14.9652 27.2917 15.2966 27.2917 15.7058L27.2917 29.0353C27.2917 29.4445 26.9604 29.7759 26.5512 29.7759L18.4054 29.7759C17.9962 29.7759 17.6648 29.4445 17.6648 29.0353L17.6648 19.4084"
          stroke={stroke}
          strokeOpacity="0.75"
          strokeWidth="1.2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.108 29.776L18.4053 29.776C17.9962 29.776 17.6648 29.4446 17.6648 29.0354L17.6648 15.7059C17.6648 15.2967 17.9962 14.9653 18.4053 14.9653L26.5512 14.9653C26.9603 14.9653 27.2917 15.2967 27.2917 15.7059L27.2917 25.3328"
          stroke={stroke}
          strokeOpacity="0.75"
          strokeWidth="1.2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect x="16.1838" y="14.2246" width="12.5891" height="3.70266" fill={fill} />
        <path
          d="M30.2539 17.9272H13.9622"
          stroke={stroke}
          strokeOpacity="0.75"
          strokeWidth="1.2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M30.1789 20.292H33V15.7055H11V20.292H13.8211"
          stroke={stroke}
          strokeOpacity="0.75"
          strokeWidth="1.2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect x="20.5652" y="24.2681" width="2.86957" height="4.78261" fill={fill} />
      </svg>
    </SvgIcon>
  );
};
