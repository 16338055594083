import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Card from "components/common/Card";
import { Spinner } from "components/common/Spinner/Spinner";
import { CompanyIntegration } from "generated/sdk";
import { observer } from "mobx-react-lite";
import { useHistory } from "react-router-dom";
import { useStore } from "storeContainer";
import { IntegrationNameAndStatus } from "./IntegrationNameAndStatus";

export const AccountingApplication: React.FunctionComponent<{
  integrations: CompanyIntegration[] | undefined;
  isLoading: boolean;
}> = observer(function AccountingApplication({ integrations, isLoading }) {
  const contextStore = useStore("ContextStore");
  const {
    acl: { canAddIntegration },
  } = useStore("SessionStore");
  const history = useHistory();

  const openAddIntegration = () => {
    history.push(`/org/${contextStore.selectedOrganizationId}/company/${contextStore.selectedCompanyId}/integrations`);
  };
  const openEditIntegration = (integration?: CompanyIntegration) => {
    if (integration) {
      history.push(
        `/org/${contextStore.selectedOrganizationId}/company/${contextStore.selectedCompanyId}/integrations/${integration.integration?.integration_id}`,
      );
    }
  };

  const intHeader = {
    sx: { mb: 0, pb: 0 },
    action: (
      <>
        {!isLoading && integrations?.length === 0 ? (
          <Button onClick={openAddIntegration}>Add Integration</Button>
        ) : (
          <>
            {!isLoading && integrations?.length !== 0 ? (
              <IconButton
                onClick={() => {
                  if (integrations) {
                    openEditIntegration(integrations[0]);
                  }
                }}
              >
                <EditIcon />
              </IconButton>
            ) : null}
          </>
        )}
      </>
    ),
  };

  return (
    <Card
      sx={{ overflow: "visible", marginTop: "20px" }}
      title={
        <Typography sx={{ fontSize: "18px" }} variant="h5">
          Accounting Applications
        </Typography>
      }
      headerProps={canAddIntegration ? intHeader : undefined}
    >
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {integrations?.length === 0 ? (
            <Typography variant="body1">No integration found</Typography>
          ) : (
            <>
              {integrations?.map((integration, index) => (
                <IntegrationNameAndStatus key={index} integration={integration} />
              ))}
            </>
          )}
        </>
      )}
    </Card>
  );
});
