import { Box } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import Tooltip from "@mui/material/Tooltip";
import classnames from "classnames";
import { memo } from "react";
import styles from "./IntegrationCard.module.scss";
import { IIntegrationCardProps } from "./types";

function IntegrationCard({
  className,
  components: { Container = "div" } = {},
  title,
  src,
  handleOnClick,
  ...rest
}: IIntegrationCardProps) {
  return (
    <Container className={classnames(styles.container, className)} onClick={handleOnClick} {...rest}>
      {src ? (
        <Box sx={{ minHeight: "68px", display: "flex", alignItems: "center", padding: "0 10px" }}>
          <img src={src} alt={title} className={styles.logo} onClick={handleOnClick} />
        </Box>
      ) : (
        <Skeleton animation={false} variant="rectangular" width={70} height={70} />
      )}
      <Tooltip title={title}>
        <div className={styles.title}>{title}</div>
      </Tooltip>
    </Container>
  );
}

export default memo(IntegrationCard);
