import { Box, Skeleton } from "@mui/material";
import { VBillRequestErrorToast } from "components/pages/common/VBill/VBillRequestErrorToast/VBillRequestErrorToast";
import { VBillRouteGuardRedirect } from "components/pages/common/VBill/VBillRouteGuardRedirect/VBillRouteGuardRedirect";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { FunctionComponent, useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router";
import { RouteConfigComponentProps, renderRoutes } from "react-router-config";
import { useHistory, useLocation } from "react-router-dom";
import { usePrevious } from "react-use";
import { useStore } from "storeContainer";
import { COLORS } from "themes/default";
import { VBillSidebar } from "../VBillSidebar/VBillSidebar";
import { useCurrentVBillRoute } from "../hooks";
import { TVBillFormFields } from "../types";
import {
  getVBillFormIncomingDefaultValues,
  getVBillFormNewDefaultValues,
  getVBillRouteRedirectByStatus,
} from "../utils";

export const VBillLayout: FunctionComponent<RouteConfigComponentProps> = observer(({ route }) => {
  const { vbillId, organizationId } = useParams<{ vbillId: string; organizationId: string }>();
  const prevVbillId = usePrevious(vbillId);
  const vBillStore = useStore("VBillStore");
  const formMethods = useForm<TVBillFormFields>({ mode: "onChange" });
  const currentVBillRoute = useCurrentVBillRoute();
  const hasVBillRoute = useMemo(() => Object.values(currentVBillRoute).some((isRoute) => isRoute), [currentVBillRoute]);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    loadInvoiceAndRedirect(vbillId, hasVBillRoute, organizationId, location.search, prevVbillId);
  }, [vbillId, prevVbillId, hasVBillRoute, organizationId, location.search]);

  const loadInvoiceAndRedirect = async (
    vbillId: string,
    hasVBillRoute: boolean,
    organizationId: string,
    search: string,
    prevVbillId?: string,
  ) => {
    const invoiceData =
      vbillId !== prevVbillId
        ? (await vBillStore.loadInvoice({ invoiceId: Number(vbillId) }))?.data
        : vBillStore.invoice.data;

    if (!hasVBillRoute) {
      const vBillRouteRedirectByStatusUrl = getVBillRouteRedirectByStatus(
        organizationId,
        vbillId,
        search,
        invoiceData?.status,
      );

      history.push(vBillRouteRedirectByStatusUrl);
    }
  };

  useEffect(() => {
    if (!vBillStore.invoice.data?.companyId) {
      return;
    }

    vBillStore.loadSettings({ companyId: vBillStore.invoice.data.companyId, invoiceId: Number(vbillId) }, true);
  }, [vBillStore, vBillStore.invoice.data?.companyId, vbillId]);

  useEffect(() => {
    return () => {
      vBillStore.invoice.flush();
      vBillStore.settings.flush();
      vBillStore.setIncomingLineItemHighlightedId(null);
    };
  }, [vbillId, vBillStore]);

  const { isIncomingRoute, isNewRoute, isSummaryRoute } = currentVBillRoute;

  // https://github.com/react-hook-form/react-hook-form/issues/7753 :(
  useEffect(() => {
    const invoiceData = toJS(vBillStore.invoice.data);

    if (isIncomingRoute) {
      const vBillFormIncomingDefaultValues = getVBillFormIncomingDefaultValues(invoiceData);

      formMethods.reset(vBillFormIncomingDefaultValues);
    }

    if (isNewRoute) {
      const vBillFormNewDefaultValues = getVBillFormNewDefaultValues(invoiceData);

      formMethods.reset(vBillFormNewDefaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vBillStore.invoice.data, isIncomingRoute, isNewRoute, isSummaryRoute]);

  return (
    <Box sx={{ display: "flex" }}>
      <VBillRouteGuardRedirect route="CODING_DETAILS" />

      <FormProvider {...formMethods}>
        <Box
          sx={{
            flexGrow: 1,
            flexShrink: 1,
            flexBasis: "0%",
            overflowX: "auto",
            backgroundColor: vBillStore.invoice.isLoading ? "transparent" : COLORS.white,
          }}
        >
          <Box
            sx={{
              // TODO: "HEADER HEIGHT"
              maxHeight: "calc(100vh - 91px)",
              paddingLeft: "20px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {vBillStore.invoice.isLoading ? (
              <>
                <Skeleton variant="rounded" sx={{ margin: "10px", height: "33px" }} />
                <Skeleton variant="rounded" sx={{ margin: "10px", height: "374px" }} />
                <Skeleton variant="rounded" sx={{ margin: "10px", height: "43px" }} />
              </>
            ) : (
              renderRoutes(route?.routes)
            )}
          </Box>
        </Box>

        <VBillSidebar />
      </FormProvider>

      <VBillRequestErrorToast />
    </Box>
  );
});
