import DownloadIcon from "@mui/icons-material/Download";
import RestoreIcon from "@mui/icons-material/Restore";
import { Grid, Skeleton } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import classNames from "classnames";
import { formatCurrency } from "common/helpers/utils";
import { VCheckCheckQueryQuery } from "generated/sdk";
import { capitalize } from "lodash";
import { observer } from "mobx-react-lite";
import { useStore } from "storeContainer";
import { formatDateToDateTime, formatDateToDesign, formatWords } from "../VChecks/utils";
import CheckStubTable from "../VChecks/VCheckCheck/CheckStubTable";
import styles from "./BudgetManagement.module.scss";

const previewableExtensions = ["jpeg", "jpg", "png", "webp", "gif"];

const getColors = (status: string) => {
  const styleMap: Record<string, string> = {
    Signed: "#61B54B",
    Completed: "#61B54B",
    Processing: "#61B54B",
    Deposited: "#61B54B",
    "Sent to Recipient": "#697281",
    "Pending Signature": "#DB8C15",
    Cancelled: "#697281",
    Deleted: "#697281",
    Undeposited: "#697281",
  };
  return styleMap[status] || "#61B54B";
};

export const CheckBatchItemDetails = observer(function CheckBatchItemDetails() {
  const BudgetManagementStore = useStore("BudgetManagementStore");
  const { checkDetailsData } = BudgetManagementStore;
  const checkDetails = checkDetailsData?.data?.GetCheckDetailsQuery;

  return (
    <Box width="100%" maxWidth="1400px" minWidth="300px">
      <Box className={styles.infoContainer} justifyContent={"start"}>
        <Box className={styles.content} maxWidth={"720px"} margin={"0 auto"}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="69px"
            sx={{ backgroundColor: "#EFF5FE" }}
          >
            <Typography variant="h1" fontSize="24px" fontWeight="500">
              Check {checkDetails?.number}
            </Typography>
          </Box>
          <Box className={styles.statusContainer}>
            <Grid container>
              <Box className={styles.labels}>
                <Typography
                  variant="body1"
                  color="rgba(5, 32, 72, 0.5)"
                  fontWeight="400"
                  fontSize="12px"
                  lineHeight="15px"
                >
                  Status
                </Typography>
                {checkDetailsData.isFetching ? (
                  <Skeleton variant="text" />
                ) : (
                  <Typography
                    variant="body1"
                    color={`${getColors(checkDetails?.status?.status!)}`}
                    fontWeight="500"
                    fontSize="14px"
                    lineHeight="17px"
                    marginTop="4px"
                  >
                    {capitalize(formatWords(checkDetails?.status?.state as string))}
                  </Typography>
                )}
                <Button>{checkDetails?.action?.label}</Button>
              </Box>
            </Grid>
          </Box>
          <Box className={styles.detailsContainer}>
            <Box className={styles.row}>
              <Box className={styles.column}>
                <Typography className={styles.columnTextThin}>FROM</Typography>
                {checkDetailsData.isFetching ? (
                  <>
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                  </>
                ) : (
                  <>
                    <Typography className={styles.columnTextBold}>{checkDetails?.sender_title}</Typography>
                    <Typography className={styles.columnTextRegular}>{checkDetails?.sender_subtitle}</Typography>
                  </>
                )}
              </Box>
              <Box className={styles.column}>
                <Typography align="right" className={styles.columnTextThin}>
                  DATE
                </Typography>
                {checkDetailsData.isFetching ? (
                  <Skeleton variant="text" />
                ) : (
                  <Typography align="right" className={styles.columnTextBold}>
                    {checkDetails?.date ? formatDateToDesign(checkDetails.date) : null}
                  </Typography>
                )}
              </Box>
            </Box>
            <Box className={styles.row}>
              <Box className={styles.column}>
                <Typography className={styles.columnTextThin}>PAY TO THE ORDER OF</Typography>
                {checkDetailsData.isFetching ? (
                  <>
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                  </>
                ) : (
                  <>
                    <Typography className={styles.columnTextBold}>{checkDetails?.pttoo}</Typography>
                    <Typography className={styles.columnTextRegular}>{checkDetails?.recipient_email}</Typography>
                  </>
                )}
              </Box>
              <Box className={styles.column}>
                <Typography align="right" className={styles.columnTextThin}>
                  Amount:
                </Typography>
                {checkDetailsData.isFetching ? (
                  <Skeleton variant="text" />
                ) : (
                  <Typography align="right" className={styles.columnTextBold}>
                    {formatCurrency(checkDetails?.amount || 0)}
                  </Typography>
                )}
              </Box>
            </Box>
            <Box className={styles.row}>
              <Box className={styles.column}>
                <Box className={styles.columnTextThin}>Memo</Box>
                {checkDetailsData.isFetching ? (
                  <Skeleton variant="text" />
                ) : (
                  <Box className={styles.columnTextBold}>{checkDetails?.memo}</Box>
                )}
              </Box>
            </Box>
          </Box>
          {checkDetailsData.isFetching ? (
            <Skeleton variant="rectangular" width={660} height={200} sx={{ marginLeft: "30px" }} />
          ) : (
            checkDetails && (
              <Box paddingX="60px">
                <CheckStubTable checkDetails={checkDetails as NonNullable<VCheckCheckQueryQuery["VCheckCheckQuery"]>} />
              </Box>
            )
          )}
        </Box>

        <Box className={styles.activityContainer}>
          <Box display="flex" alignItems="center" gap="8px">
            <RestoreIcon htmlColor="#16458B" />
            <Typography className={styles.activityHeader}>Activity</Typography>
          </Box>
          <ul className={styles.activityList}>
            {checkDetails?.activity!.map((activity, idx) => {
              return (
                <li key={`${activity?.id}-${idx}`}>
                  {checkDetailsData.isFetching ? (
                    <Skeleton variant="text" />
                  ) : (
                    <Box display="flex" gap="10px" alignItems="start" flexDirection="column">
                      <Box display="flex" gap="5px" alignItems="center">
                        <Typography className={styles.activityDate}>
                          {formatDateToDateTime(activity?.created_at!)}
                        </Typography>

                        {activity.username && (
                          <Typography variant="h1" fontWeight={400} fontSize="11px" lineHeight="13px" color="#697281">
                            {` - @${activity?.username}`}
                          </Typography>
                        )}
                      </Box>

                      {(activity?.title || activity?.note) && (
                        <Typography
                          className={classNames(styles.activityTitle, {
                            [styles.activityComment]: activity.type === 1,
                          })}
                        >
                          {activity?.title || activity?.note}
                        </Typography>
                      )}
                      {activity?.attachments?.at(0) &&
                      previewableExtensions.includes(activity.attachments.at(0)?.media?.type?.toLocaleLowerCase()!) ? (
                        <Box>
                          <img
                            className={styles.imageWrapper}
                            src={activity.attachments[0].media?.path!}
                            alt="activity attachement"
                          ></img>
                        </Box>
                      ) : (
                        activity.attachments?.at(0) && (
                          <Grid
                            alignItems={"center"}
                            justifyContent="space-between"
                            container
                            sx={{ paddingX: "35px" }}
                          >
                            <Typography fontSize={16}>{activity.attachments?.at(0)?.media?.filename}</Typography>
                            <a href={`/notes/${activity?.id}/media/${activity.attachments?.at(0)?.media?.id}`}>
                              <IconButton>
                                <DownloadIcon color="secondary" />
                              </IconButton>
                            </a>
                          </Grid>
                        )
                      )}
                    </Box>
                  )}
                </li>
              );
            })}
          </ul>
        </Box>
      </Box>
    </Box>
  );
});
