import { IconButton, Tooltip } from "@mui/material";
import { chatStaticEmojiList } from "common/static/chatStaticEmojiList";
import { AddReactionIcon } from "components/common/icons";
import { Spinner } from "components/common/Spinner/Spinner";
import { IChatChatIoMessageOutputSchema, IChatContentBlockMessageRelatedType } from "generated/sdk.chat";
import { observer } from "mobx-react";
import { useChatChannelStore } from "modules/Chat/hooks/hooks";
import { CHAT_MSG_REACTION_BLOCK_PREFIX } from "modules/Chat/utils/utils";
import { FunctionComponent, useState } from "react";
import { ReactionsPickerMenu } from "../../../ChatForm/ReactionsPickerMenu/ReactionsPickerMenu";

interface IMessageReactionsPickerProps {
  message: IChatChatIoMessageOutputSchema;
}

export const MessageReactionsPicker: FunctionComponent<IMessageReactionsPickerProps> = observer(
  ({
    message: {
      id: messageId,
      content: { related },
    },
  }) => {
    const chatChannelStore = useChatChannelStore();
    const [isReactionsPickerMenuOpen, setReactionsPickerMenuOpen] = useState(false);
    const [isSetReactLoading, setIsSetReactLoading] = useState(false);

    const handleReactionPick = async (reactionName: string) => {
      if (!messageId || !chatChannelStore?.currentSessionMember?.name) {
        return;
      }

      setIsSetReactLoading(true);

      const messageRelatedIo = {
        key: `${CHAT_MSG_REACTION_BLOCK_PREFIX}:${reactionName}`,
        related: {
          key: chatChannelStore?.currentSessionMember?.name,
          label: chatStaticEmojiList.find(({ name }) => name === reactionName)?.name ?? "",
          type: IChatContentBlockMessageRelatedType.Reaction,
        },
      };

      await chatChannelStore?.setReactToMessage(messageId, messageRelatedIo);
      setIsSetReactLoading(false);
      setReactionsPickerMenuOpen(false);
    };

    const hasSessionUserMessageReactions = related?.filter(
      ({ related, key }) =>
        related.type === IChatContentBlockMessageRelatedType.Reaction &&
        key.includes(CHAT_MSG_REACTION_BLOCK_PREFIX) &&
        related.key === chatChannelStore?.currentSessionMember?.name,
    );

    if (!!hasSessionUserMessageReactions?.length) {
      return null;
    }

    return (
      <ReactionsPickerMenu
        menuOpen={isReactionsPickerMenuOpen}
        onCloseMenu={() => setReactionsPickerMenuOpen(false)}
        onMenuItemClick={handleReactionPick}
        menuPosition="BOTTOM"
      >
        <Tooltip title="React">
          <IconButton onClick={() => setReactionsPickerMenuOpen(true)}>
            {isSetReactLoading ? <Spinner /> : <AddReactionIcon color="primary" />}
          </IconButton>
        </Tooltip>
      </ReactionsPickerMenu>
    );
  },
);
