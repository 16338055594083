import {
  AccountBalanceIcon,
  ApprovalIcon,
  ChatBubbleOutlineIcon,
  CheckCircleOutlineIcon,
  CompareArrowsIcon,
  DeleteOutlineIcon,
  EditIcon,
  HubIcon,
  ReceiptIcon,
  ThumbDownOffAltIcon,
  UpdateIcon,
} from "components/common/icons";
import { CopyOutlineIcon } from "icons/svg/CopyOutlineIcon";
import { FunctionComponent } from "react";
import { COLORS } from "themes/default";
import { ActivityLogActionType } from "../../types";

const infoGrafIconStyles = {
  position: "absolute",
  top: "12px",
  left: "12px",
  color: COLORS.stTropaz,
  fontSize: "20px",
};

export const ActionIcon: FunctionComponent<{ action: string }> = ({ action }) => {
  let Icon = <></>;

  switch (action) {
    case ActivityLogActionType.APPROVE:
    case ActivityLogActionType.START_APPROVAL:
      Icon = <ApprovalIcon sx={infoGrafIconStyles} />;
      break;
    case ActivityLogActionType.COMMENT:
      Icon = <ChatBubbleOutlineIcon sx={infoGrafIconStyles} />;
      break;
    case ActivityLogActionType.CREATE:
    case ActivityLogActionType.UPDATE_PUSH_STATUS:
      Icon = <ReceiptIcon sx={infoGrafIconStyles} />;
      break;
    case ActivityLogActionType.DECLINE:
      Icon = <ThumbDownOffAltIcon sx={infoGrafIconStyles} />;
      break;
    case ActivityLogActionType.DELETE:
      Icon = <DeleteOutlineIcon sx={infoGrafIconStyles} />;
      break;
    case ActivityLogActionType.EDIT:
      Icon = <EditIcon sx={infoGrafIconStyles} />;
      break;
    case ActivityLogActionType.EDIT_DONE:
      Icon = <EditIcon sx={infoGrafIconStyles} />;
      break;
    case ActivityLogActionType.MAPPING:
      Icon = <CompareArrowsIcon sx={infoGrafIconStyles} />;
      break;
    case ActivityLogActionType.MAPPING_ADDITIONAL_FIELDS:
      Icon = <HubIcon sx={infoGrafIconStyles} />;
      break;
    case ActivityLogActionType.MAPPING_DONE:
      Icon = <CheckCircleOutlineIcon sx={infoGrafIconStyles} />;
      break;
    case ActivityLogActionType.MAPPING_GLACCOUNT:
      Icon = <AccountBalanceIcon sx={infoGrafIconStyles} />;
      break;
    case ActivityLogActionType.MAPPING_VENDOR:
    case ActivityLogActionType.MAPPING_COMPANY:
      Icon = <CopyOutlineIcon sx={infoGrafIconStyles} />;
      break;
    case ActivityLogActionType.UPDATE_PAYMENT:
    case ActivityLogActionType.PUSH_TO_INTEGRATION:
      Icon = <UpdateIcon sx={infoGrafIconStyles} />;
      break;
    default:
      Icon = <HubIcon sx={infoGrafIconStyles} />;
  }

  return Icon;
};
