import { HtmlTooltip } from "./AclInheritedPermissions";
import * as React from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CallSplitIcon from "@mui/icons-material/CallSplit";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import { AllBasePermissionType } from "../../../../../../storesMobx/AclStore";
import { definitelyFilter } from "../../../../../../generated/utils";
import { useStore } from "../../../../../../storeContainer";
import { COLORS } from "themes/default";

export const AclSourceLine = ({ rowPermission }: { rowPermission: AllBasePermissionType }) => {
  const session = useStore("SessionStore");
  const acl = useStore("AclStore");
  const inheritedPermissions = acl.inheritedPermissions;

  const resolveIdToName = (id: string | null | undefined) => {
    return id
      ? definitelyFilter(
          session.organizationUserGroups.data?.filter((e: any) => e?.id === id).map((e: { name?: any }) => e?.name),
        )[0] ||
          definitelyFilter(inheritedPermissions.filter((e) => e.id === id).map((e) => e.name))[0] ||
          id
      : undefined;
  };

  return (
    <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
      {rowPermission.user_group_id ? (
        <Typography variant="body2" sx={{ fontWeight: 500 }} color="info">
          {resolveIdToName(rowPermission.user_group_id)}
        </Typography>
      ) : (
        <Typography variant="body2" sx={{ fontWeight: 500 }} color="info">
          User
        </Typography>
      )}
      /
      {rowPermission.orig_entity_id ? (
        <>
          <Typography variant="body2" sx={{ fontWeight: 500 }} color="info">
            {resolveIdToName(rowPermission.orig_entity_id)}
          </Typography>
          <Typography variant="body2" sx={{ fontWeight: 500 }} color={COLORS.mediumGrey}>
            {rowPermission.orig_entity_type}
          </Typography>
        </>
      ) : (
        <>
          <Typography variant="body2" sx={{ fontWeight: 500 }} color="info">
            {resolveIdToName(rowPermission.entity_id)}
          </Typography>
          <Typography variant="body2" sx={{ fontWeight: 500 }} color={COLORS.mediumGrey}>
            {rowPermission.entity_type}
          </Typography>
        </>
      )}
    </Box>
  );
};

export const AclSource = ({
  hasDirect,
  hasInherited,
  rowPermissions,
}: {
  hasDirect: boolean;
  hasInherited: boolean | undefined;
  rowPermissions: AllBasePermissionType[];
}) => {
  return (
    <>
      {hasDirect && hasInherited ? (
        <HtmlTooltip
          title={
            <React.Fragment>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 1, p: 1 }}>
                <Box sx={{ display: "flex", gap: 1 }}>
                  <CallSplitIcon sx={{ color: "#707070" }} fontSize="small" />
                  <Typography variant="body2" sx={{ fontWeight: 500, color: "#707070" }}>
                    Merged Rule:
                  </Typography>
                </Box>
                {rowPermissions.map((rowPermission, rowPermissionIdx) => (
                  <div key={rowPermissionIdx}>
                    {rowPermission.direct && (
                      <Typography variant="body2" sx={{ fontWeight: 500 }} color="info">
                        Direct Rule
                      </Typography>
                    )}

                    <AclSourceLine rowPermission={rowPermission} />
                  </div>
                ))}
              </Box>
            </React.Fragment>
          }
        >
          <CallSplitIcon sx={{ transform: "rotate(90deg)", color: "#707070" }} />
        </HtmlTooltip>
      ) : (
        <>
          {hasDirect && (
            <HtmlTooltip
              title={
                <React.Fragment>
                  <Box sx={{ display: "flex", gap: 1, p: 1 }}>
                    <StarOutlineIcon sx={{ color: "#707070" }} fontSize="small" />
                    <Typography variant="body2" sx={{ fontWeight: 500, color: "#707070" }}>
                      Direct Rule
                    </Typography>
                  </Box>
                </React.Fragment>
              }
            >
              <StarOutlineIcon sx={{ color: "#707070" }} />
            </HtmlTooltip>
          )}
          {hasInherited && (
            <HtmlTooltip
              title={
                <React.Fragment>
                  <Box sx={{ display: "flex", flexDirection: "column", gap: 1, p: 1 }}>
                    <Box sx={{ display: "flex", gap: 1 }}>
                      <SubdirectoryArrowRightIcon sx={{ color: "#707070" }} fontSize="small" />
                      <Typography variant="body2" sx={{ fontWeight: 500, color: "#707070" }}>
                        Inherited Rule:
                      </Typography>
                    </Box>

                    {rowPermissions.map((rowPermission) => (
                      <AclSourceLine rowPermission={rowPermission} />
                    ))}
                  </Box>
                </React.Fragment>
              }
            >
              <SubdirectoryArrowRightIcon sx={{ color: "#707070" }} />
            </HtmlTooltip>
          )}
        </>
      )}
    </>
  );
};
