import { useHistory, useLocation, useParams } from "react-router";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import styles from "./Organization.module.scss";
import EditIcon from "@mui/icons-material/Edit";
import { AlertColor } from "@mui/material";

import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

import Snackbar from "@mui/material/Snackbar";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

import { a11yProps, TabPanel } from "../Users/utils";
import { useStore } from "../../../../storeContainer";
import { useUsersColumns } from "./utils";
import Table from "../../../common/Table";
import EditOrganizationModal from "./EditOrganizationModal";
import DeleteIcon from "@mui/icons-material/Delete";
import { definitely, definitelyFilter } from "../../../../generated/utils";
import { Spinner } from "../../../common/Spinner/Spinner";
import LoadingButton from "@mui/lab/LoadingButton";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import { AclPermissions } from "../Users/User/Permissions/Permissions";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const Organization = () => {
  const { organizationId } = useParams<{ organizationId?: string }>();

  const history = useHistory();
  const location = useLocation();
  const { groupId } = useParams<{ organizationId: string; groupId: string }>();
  const userGroupsStore = useStore("UserGroupsStore");
  const organizationUsersStore = useStore("OrganizationUsersStore");
  const { organizationUsersList } = organizationUsersStore;
  const { userGroupUsersList } = userGroupsStore;
  const path = `/org/${organizationId}/groups/${groupId}`;
  //Snackbar
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarText, setSnackbarText] = useState("");
  const [groupIsDeleting, setGroupIsDeleting] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor | undefined>("success");

  //Remove from group
  const [loadingRemove, setLoadingRemove] = useState(false);

  const handleRemoveFromGroup = async (userId: string) => {
    if (groupId && organizationId && userId) {
      setLoadingRemove(true);
      try {
        await userGroupsStore.removeUserFromGroup(groupId, userId);
        setDummy(dummy + 1);
        setLoadingRemove(false);
        setSnackbarSeverity("success");
        setSnackbarText("User removed successfully");
        setOpenSnackbar(true);
      } catch (e: any) {
        setSnackbarText(e.response.errors?.message);
        setLoadingRemove(false);
        setSnackbarSeverity("error");
        setOpenSnackbar(true);
      }
    }
  };

  const usersColumns = useUsersColumns(handleRemoveFromGroup, loadingRemove);

  const [editModal, setEditModal] = useState(false);
  const [dummy, setDummy] = useState(0);

  const handleEditModal = (value: any) => {
    setEditModal(value);
  };

  const handleUpdateUi = () => {
    setDummy(dummy + 1);
  };

  const handleOpenEditModal = () => {
    setEditModal(true);
  };

  const handleDeleteGroup = async () => {
    if (groupId) {
      setGroupIsDeleting(true);
      await userGroupsStore.deleteUserGroup(groupId);
      setGroupIsDeleting(false);
      history.push(`/org/${organizationId}/groups/`);
    }
  };
  const [group, setGroup] = useState(userGroupsStore.getGroup(groupId));
  const [dataLoading, setDataLoading] = useState(false);
  useEffect(() => {
    setDataLoading(true);
    organizationUsersStore.loadOrganizationUsers();
    userGroupsStore.loadUserGroupUsers(groupId, true);
    userGroupsStore.loadUserGroups().then(() => {
      setGroup(userGroupsStore.getGroup(groupId)!);
      setDataLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dummy]); //jumpto
  const users = organizationUsersStore.getOrganizationUsersAccounts();

  const _activeUsers = users?.map((user) => user?.account?.name || user?.account?.email);
  // const tableUsers=definitelyFilter(userGroupUsersList?.data)[0].organization_users?.map
  // (user => user?.account?.name || user?.account?.email) ?? [];
  const [activeUsers, setActiveUsers] = useState(_activeUsers);
  // .filter(user=> !activeUsers?.includes(user?.account?.name || user?.account?.email))

  const [value, setValue] = useState(location.pathname.includes("permissions") ? 1 : 0);

  //Remove dialog
  const [openRemoveDialog, setOpenRemoveDialog] = useState(false);

  const handleRemoveDialogOpen = () => {
    setOpenRemoveDialog(true);
  };

  const handleRemoveDialogClose = () => {
    setOpenRemoveDialog(false);
  };

  //Add user

  const [addedUser, setAddedUser] = useState<string | null>("");
  const [loading, setLoading] = useState(false);

  //TODO: Fix types
  const handleChange = (_event: any, newValue: any) => {
    setValue(newValue);
  };

  const handleAddUser = async () => {
    if (!addedUser) {
      setOpenSnackbar(true);
      setSnackbarSeverity("error");
      setSnackbarText("Please insert user name or email");
    } else {
      setLoading(true);
      const user = organizationUsersList.data?.find(
        (user) => user?.account?.email === addedUser || user?.account?.name === addedUser,
      );
      if (user?.id) {
        try {
          await userGroupsStore.addUserToGroup(groupId, user.id);
          setActiveUsers(activeUsers?.filter((au) => au !== user?.account?.name || au !== user?.account?.email));
          setDummy(dummy + 1);
          setLoading(false);
          setAddedUser("");
          setSnackbarSeverity("success");
          setSnackbarText("User added successfully");
          setOpenSnackbar(true);
        } catch (e: any) {
          setSnackbarText(e.response.errors?.message);
          setLoading(false);
          setSnackbarSeverity("error");
          setOpenSnackbar(true);
        }
      }
    }
  };

  const handleBack = () => {
    history.push(`/org/${organizationId}/groups`);
  };

  return (
    <div className={styles.content}>
      <div className={styles.userInfo}>
        <Paper className={styles.paper}>
          <div className={styles.container}>
            <div className={styles.left}>
              <div className={styles.textContainer}>
                {!dataLoading ? (
                  <>
                    <div className={styles.line}>
                      <ArrowBackIcon className={styles.backIcon} onClick={handleBack} />
                      <span className={styles.name}>{group?.name}</span>
                    </div>
                    <span className={styles.email}>{group?.description}</span>
                  </>
                ) : (
                  <Spinner />
                )}
              </div>
            </div>
            <div>
              <EditIcon className={styles.editIcon} onClick={handleOpenEditModal} />
              <DeleteIcon className={styles.editIcon} onClick={handleRemoveDialogOpen} />
            </div>
          </div>
        </Paper>
      </div>
      <Paper className={styles.paper}>
        <Tabs value={value} onChange={handleChange} textColor="primary" indicatorColor="primary">
          <Tab
            label="Users"
            onClick={() => {
              history.push(`${path}/users`);
            }}
            {...a11yProps(0)}
          />
          <Tab
            label="Permissions"
            onClick={() => {
              history.push(`${path}/users2/permissions`);
            }}
            {...a11yProps(1)}
          />
        </Tabs>
        <TabPanel value={value} index={0} className={styles.tabPanel}>
          {userGroupUsersList.isFetching ? (
            <Spinner />
          ) : userGroupUsersList.data ? (
            <>
              <Table
                columns={usersColumns}
                data={definitelyFilter(definitelyFilter(userGroupUsersList?.data)[0].organization_users).map((user) => {
                  return {
                    id: user.id,
                    name: user?.account?.name,
                    email: user?.account?.email,
                    state: user?.account?.state,
                  };
                })}
              />

              <Grid container alignItems={"center"} sx={{ margin: "20px 0" }}>
                <Grid item>
                  <Autocomplete
                    key={addedUser}
                    style={{ minWidth: "200px", marginRight: "10px" }}
                    id="add-user-autocomplete"
                    freeSolo
                    value={addedUser}
                    options={definitelyFilter(users)
                      .filter(
                        (user) =>
                          (!activeUsers?.includes(user?.account?.name) ||
                            !activeUsers?.includes(user?.account?.email)) &&
                          user?.state !== "UNVALIDATED" &&
                          !definitelyFilter(userGroupUsersList?.data)[0]
                            .organization_users?.map((user) => user?.account?.name || user?.account?.email)
                            ?.includes(user?.account?.name) &&
                          !definitelyFilter(userGroupUsersList?.data)[0]
                            .organization_users?.map((user) => user?.account?.name || user?.account?.email)
                            .includes(user?.account?.email),
                      )
                      .map((user) => user?.account?.name || user?.account?.email)
                      .filter((e) => !!e)}
                    onChange={(_event, newValue, _reason) => {
                      setAddedUser(newValue || null);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Add user"
                        InputProps={{
                          ...params.InputProps,
                        }}
                        sx={{ width: "350px" }}
                      />
                    )}
                  />
                </Grid>
                <Grid item>
                  <LoadingButton variant={"contained"} loading={loading} onClick={handleAddUser}>
                    Add
                  </LoadingButton>
                </Grid>
              </Grid>
            </>
          ) : null}
        </TabPanel>
        <TabPanel value={value} index={1}>
          {organizationId ? (
            <AclPermissions organizationId={organizationId} organizationUserId={null} userGroupId={groupId} />
          ) : null}
        </TabPanel>
      </Paper>
      {userGroupUsersList.isLoading ? null : (
        <EditOrganizationModal
          isModalOpened={editModal}
          modalClose={handleEditModal}
          updateUi={handleUpdateUi}
          groupId={groupId}
          groupName={definitely(group?.name)}
          groupDescription={definitely(group?.description)}
          setGroup={setGroup}
          group={definitely(group)}
        />
      )}
      <Dialog
        open={openRemoveDialog}
        onClose={handleRemoveDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Are you sure you want to remove group {group.name} ?</DialogTitle>
        <DialogActions>
          <Button onClick={handleRemoveDialogClose}>Cancel</Button>
          {!groupIsDeleting ? (
            <Button onClick={handleDeleteGroup} autoFocus>
              Remove
            </Button>
          ) : (
            <Button>
              <Spinner />
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert onClose={() => setOpenSnackbar(false)} severity={snackbarSeverity} sx={{ width: "100%" }}>
          {snackbarText}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default observer(Organization);
