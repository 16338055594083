import { Box, IconButton, InputBase, Tooltip } from "@mui/material";
import { Spinner } from "components/common/Spinner/Spinner";
import { AlternateEmailIcon, InsertEmoticonIcon, SendIcon, TagIcon } from "components/common/icons";
import { observer } from "mobx-react";
import { useChatChannelStore } from "modules/Chat/hooks/hooks";
import { FormEvent, FunctionComponent } from "react";
import { COLORS } from "themes/default";
import { MentionSomethingPicker } from "./MentionSomethingPicker/MentionSomethingPicker";
import { ReactionsPickerMenu } from "./ReactionsPickerMenu/ReactionsPickerMenu";
import { MessageEventType, useChatForm } from "./useChatForm";

export const ChatForm: FunctionComponent = observer(() => {
  const chatChannelStore = useChatChannelStore();
  const {
    isSendMessageReqLoading,
    messageInputValue,
    handleMessageEvent,
    handleMentionSomethingSelect,
    handleReactPickerSelect,
    mentionSomethingDialogOpen,
    reactionsPickerMenuOpen,
    messageInputRef,
  } = useChatForm();
  const [isMentionSomethingDialogOpen, setIsMentionSomethingDialogOpen] = mentionSomethingDialogOpen;
  const [isReactionsPickerMenuOpen, setReactionsPickerMenuOpen] = reactionsPickerMenuOpen;

  return (
    <Box
      sx={{
        display: "flex",
        padding: "20px 20px 0 20px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          backgroundColor: COLORS.mercury,
          padding: "5px 15px",
          borderRadius: "10px",
        }}
      >
        <Box
          onSubmit={(event: FormEvent<HTMLFormElement>) =>
            handleMessageEvent({ eventType: MessageEventType.SUBMIT, event })
          }
          sx={{ display: "flex", width: "100%", alignItems: "center" }}
          component="form"
        >
          <MentionSomethingPicker
            dialogOpen={isMentionSomethingDialogOpen}
            onDialogClose={() => setIsMentionSomethingDialogOpen(false)}
            onSelectMention={handleMentionSomethingSelect}
          />

          <InputBase
            autoFocus={!chatChannelStore?.channelComingSoon}
            inputRef={messageInputRef}
            value={messageInputValue}
            onKeyDown={(event) => handleMessageEvent({ eventType: MessageEventType.KEYDOWN, event })}
            onChange={(event) => handleMessageEvent({ eventType: MessageEventType.CHANGE, event })}
            sx={{ width: "100%", backgroundColor: COLORS.mercury }}
          />

          <Tooltip title="Mention something" placement="top">
            <IconButton disableRipple onClick={() => setIsMentionSomethingDialogOpen(true)}>
              <AlternateEmailIcon color="primary" sx={{ fontSize: "20px" }} />
              <TagIcon color="primary" sx={{ fontSize: "20px" }} />
            </IconButton>
          </Tooltip>

          <ReactionsPickerMenu
            menuOpen={isReactionsPickerMenuOpen}
            onCloseMenu={() => setReactionsPickerMenuOpen(false)}
            onMenuItemClick={handleReactPickerSelect}
          >
            <Tooltip title="Emoji" placement="top">
              <IconButton disableRipple onClick={() => setReactionsPickerMenuOpen(true)}>
                <InsertEmoticonIcon color="primary" sx={{ fontSize: "20px" }} />
              </IconButton>
            </Tooltip>
          </ReactionsPickerMenu>

          <Tooltip title="Send" placement="top">
            <IconButton type="submit" disableRipple sx={{ ...(!messageInputValue.length ? { opacity: "0.5" } : {}) }}>
              {isSendMessageReqLoading ? <Spinner /> : <SendIcon color="primary" sx={{ fontSize: "20px" }} />}
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </Box>
  );
});
