import { Box, Typography } from "@mui/material";
import { FunctionComponent } from "react";
import { COLORS } from "themes/default";

interface IActionCommentProps {
  message: string;
}

export const ActionComment: FunctionComponent<IActionCommentProps> = ({ message }) => (
  <Box
    sx={{
      padding: "20px",
      backgroundColor: COLORS.white,
      borderBottom: "1px solid #ebf4ff",
    }}
  >
    <Typography variant="body2">{message}</Typography>
  </Box>
);
