import { graphqlChatClient } from "common/graphqlClient";
import {
  getSdk,
  IChatChatIoChannelInputSchema,
  IChatChatIoChannelOutputSchema,
  IChatChatIoMemberChannelNotificationStatsOutput,
  IChatChatIoMemberInputSchema,
  IChatChatIoMemberOutputSchema,
  IChatChatIoMessageContentBlockMessageRelatedInput,
  IChatChatIoMessageOutputSchema,
  IChatChatSendMessageMutationVariables,
  IChatGetMessagesRelativeToMessageType,
  IChatMemberNotificationType,
  IChatMessageType,
  IChatNotificationSubscriptionType,
} from "generated/sdk.chat";
import { uniq, uniqBy } from "lodash";
import { action, computed, makeObservable, observable, runInAction } from "mobx";
import { ChatMemberStore, chatMemberStore } from "./ChatMemberStore";

const {
  ChatGetChannel,
  ChatGetPossibleMembers,
  ChatGetMembers,
  ChatGetMessages,
  ChatMakePrivateChannel,
  ChatAddMembers,
  ChatRemoveMembers,
  ChatSendMessage,
  ChatCreateChannel,
  ChatUpdateLastSeenMessage,
  ChatReactToMessage,
  ChatUpdateNotificationSubscription,
  ChatGetMessagesRelativeToMessage,
  ChatCloseChannel,
} = getSdk(graphqlChatClient);

export type IChatChannelRelatedItems = Array<
  IChatChatIoMessageContentBlockMessageRelatedInput & {
    onSelect?: (id: string) => void; // TODO:
  }
>;

export type TBaseIoChannel = Pick<IChatChatIoChannelInputSchema, "parentContext" | "parentKey" | "parentType">;

export interface IChatChannelStoreInit {
  config: {
    baseIoChannel: TBaseIoChannel;
    sessionAccountId: string;
    organizationId: string;
  };
  settings?: {
    showMembers?: boolean;
    showVisibility?: boolean;
    forcePublic?: boolean;
    channelComingSoon?: boolean;
  };
  possibleMembers?: IChatChatIoMemberOutputSchema[] | null;
  relatedItems?: IChatChannelRelatedItems;
}

export class ChatChannelStore {
  memberStore: ChatMemberStore = chatMemberStore;

  channel?: IChatChatIoChannelOutputSchema;
  possibleMembers?: IChatChatIoMemberOutputSchema[] | null;
  relatedItems?: IChatChannelRelatedItems;
  members?: IChatChatIoMemberOutputSchema[];
  messages: IChatChatIoMessageOutputSchema[] = [];
  replyToMessage?: IChatChatIoMessageOutputSchema;
  channelNotifications?: IChatChatIoMemberChannelNotificationStatsOutput[];
  isChannelVisibleInViewport?: boolean;
  hasMoreMessagesBefore?: boolean;

  _config: IChatChannelStoreInit["config"];
  _settings: IChatChannelStoreInit["settings"];

  constructor({ config, settings, possibleMembers, relatedItems }: IChatChannelStoreInit) {
    makeObservable(this, {
      channel: observable,
      possibleMembers: observable,
      relatedItems: observable,
      members: observable,
      messages: observable,
      replyToMessage: observable,
      channelNotifications: observable,
      hasMoreMessagesBefore: observable,
      setReplyToMessage: action,
      setChannelNotifications: action,
      currentSessionMember: computed,
      otherMembers: computed,
      otherPossibleMembers: computed,
      orderedMessages: computed,
      channelMessageNotification: computed,
      channelMentionNotification: computed,
      channelReactionNotification: computed,
      showMembers: computed,
      showVisibility: computed,
      forcePublic: computed,
      channelComingSoon: computed,
    });

    this._config = config;
    this._settings = settings;
    this.possibleMembers = possibleMembers;
    this.relatedItems = relatedItems;
  }

  getChannelInitialData = async () => {
    this.loadGetChannel();
    this.loadPossibleMembers();
    this.loadMembers();
    this.loadInitialMessages();
  };

  loadGetChannel = async () => {
    const resp = await ChatGetChannel({ channelIo: this._config.baseIoChannel });

    if (resp.getChannel?.data) {
      this._setChannelData(resp.getChannel.data);
    }
  };

  loadPossibleMembers = async () => {
    if (!!this.possibleMembers?.length) {
      return;
    }

    const resp = await ChatGetPossibleMembers({
      channelIo: this._config.baseIoChannel,
      orgId: this._config.organizationId,
    });

    runInAction(() => {
      if (resp.getPossibleMembers?.data) {
        this.possibleMembers = resp.getPossibleMembers.data;
      }
    });
  };

  loadMembers = async () => {
    const resp = await ChatGetMembers({ channelIo: this._config.baseIoChannel });

    runInAction(() => {
      if (resp.getMembers?.data) {
        this.members = resp.getMembers.data;
      }
    });
  };

  loadInitialMessages = async () => {
    const resp = await ChatGetMessages({
      channelIo: this._config.baseIoChannel,
      pagination: { page: 1, per_page: 30 },
      filters: { search: undefined },
    });

    runInAction(() => {
      if (resp.getMessages?.data) {
        this.messages = uniqBy(resp.getMessages.data.items, "id");
        this.hasMoreMessagesBefore = resp.getMessages.data.hasMore;
      }
    });
  };

  loadMessagesRelativeToMessage = async (directionType: IChatGetMessagesRelativeToMessageType) => {
    if (directionType === IChatGetMessagesRelativeToMessageType.Before) {
      const resp = await ChatGetMessagesRelativeToMessage({
        channelIo: this._config.baseIoChannel,
        pagination: { per_page: 30 },
        filters: { type: directionType, messageId: this.messages[this.messages.length - 1].id },
      });

      runInAction(() => {
        if (resp.getMessagesRelativeToMessage?.data) {
          this.messages = uniqBy([...this.messages, ...resp.getMessagesRelativeToMessage.data.items], "id");

          this.hasMoreMessagesBefore = resp.getMessagesRelativeToMessage.data.hasMore;
        }
      });
    }

    if (directionType === IChatGetMessagesRelativeToMessageType.After) {
      const resp = await ChatGetMessagesRelativeToMessage({
        channelIo: this._config.baseIoChannel,
        pagination: { per_page: 30 },
        filters: { type: directionType, messageId: this.channelMessageNotification?.lastSeenMessageId },
      });

      runInAction(() => {
        if (resp.getMessagesRelativeToMessage?.data) {
          this.messages = uniqBy([...resp.getMessagesRelativeToMessage.data.items, ...this.messages], "id");
          const accountIds = uniq(this.messages.map((message) => message.accountId));
          const missingAccountIds = accountIds.filter(
            (accountId) => !this.members?.find((member) => accountId === member.accountId),
          );
          if (missingAccountIds.length) {
            this.loadMembers();
          }
        }
      });
    }
  };

  setMakePrivateChannel = async () => {
    const resp = await this._initializeChannelBeforeAction(() =>
      ChatMakePrivateChannel({ channelIo: { ...this._config.baseIoChannel } }),
    );

    if (resp.makePrivateChannel?.data) {
      this._setChannelData(resp.makePrivateChannel.data);
    }
  };

  setAddMembers = async (members: IChatChatIoMemberInputSchema[]) => {
    const resp = await this._initializeChannelBeforeAction(() =>
      ChatAddMembers({
        channelIo: this._config.baseIoChannel,
        membersIo: this._processMembersToBaseProps(members),
      }),
    );

    if (resp.addMembers?.data?.members) {
      this._setMembersData(resp.addMembers.data.members);
    }

    if (resp.addMembers?.data?.message) {
      this._setNewMessageData(resp.addMembers.data.message);
    }
  };

  setRemoveMembers = async (membersIds: number[]) => {
    const resp = await this._initializeChannelBeforeAction(() =>
      ChatRemoveMembers({
        channelIo: this._config.baseIoChannel,
        membersIds,
      }),
    );

    if (resp.removeMembers?.data?.members) {
      this._setMembersData(resp.removeMembers.data.members);
    }
    if (resp.removeMembers?.data?.message) {
      this._setNewMessageData(resp.removeMembers.data.message);
    }
  };

  setSendMessage = async ({ messageIo }: Pick<IChatChatSendMessageMutationVariables, "messageIo">) => {
    const resp = await this._initializeChannelBeforeAction(() =>
      ChatSendMessage({ channelIo: this._config.baseIoChannel, messageIo }),
    );

    if (!this.currentSessionMember) {
      await this.loadMembers();
    }

    if (
      (!this.channel?.canBeClosed && resp.sendMessage.data?.systemMessage?.type === IChatMessageType.System) ||
      this.channel?.isClosed
    ) {
      await this.loadGetChannel();
    }

    runInAction(() => {
      if (resp.sendMessage.data?.message) {
        this.messages = uniqBy([resp.sendMessage.data.message, ...this.messages], "id");
      }
    });

    this.memberStore.reOrderMemberLatestMessages(this.channel?.id);
  };

  setUpdateLastSeenMessage = (messageId: number) => {
    return ChatUpdateLastSeenMessage({ channelIo: this._config.baseIoChannel, messageId });
  };

  setUpdateLastSeenReaction = async (messageId: number) => {
    const messsagesResp = await ChatGetMessagesRelativeToMessage({
      channelIo: this._config.baseIoChannel,
      pagination: { per_page: 1 },
      filters: { type: IChatGetMessagesRelativeToMessageType.Around, messageId },
    });

    runInAction(() => {
      if (messsagesResp.getMessagesRelativeToMessage?.data?.items?.[0]) {
        this.messages = uniqBy(
          this.messages.map((message) =>
            message.id === messageId
              ? messsagesResp.getMessagesRelativeToMessage?.data?.items[0]
                ? messsagesResp.getMessagesRelativeToMessage.data.items[0]
                : message
              : message,
          ),
          "id",
        );
      }
    });

    ChatUpdateLastSeenMessage({ channelIo: this._config.baseIoChannel, messageId });
  };

  setReactToMessage = async (
    messageId: number,
    messageRelatedIo: IChatChatIoMessageContentBlockMessageRelatedInput,
  ) => {
    if (!this.channel) {
      return;
    }

    const { parentContext, parentType, parentKey } = this.channel;

    const resp = await ChatReactToMessage({
      channelIo: { parentContext, parentType, parentKey },
      messageId,
      messageRelatedIo,
    });

    runInAction(() => {
      if (resp.reactToMessage.data) {
        this.messages = uniqBy(
          this.messages.map(
            (message) =>
              message.id === resp.reactToMessage.data?.message.id
                ? (resp.reactToMessage.data?.message as IChatChatIoMessageOutputSchema)
                : message, // ts gone crazy
          ),
          "id",
        );
      }
    });
  };

  setReplyToMessage = (message?: IChatChatIoMessageOutputSchema) => {
    this.replyToMessage = message;
  };

  setisChannelVisibleInViewport = (value: boolean) => {
    this.isChannelVisibleInViewport = value;
  };

  setChannelNotifications = (notifications: IChatChatIoMemberChannelNotificationStatsOutput[]) => {
    this.channelNotifications = notifications;
  };

  setUpdateNotificationSubscription = async (subscription: IChatNotificationSubscriptionType) => {
    if (!this.currentSessionMember?.id) {
      return;
    }

    const resp = await this._initializeChannelBeforeAction(() =>
      ChatUpdateNotificationSubscription({ channelIo: this._config.baseIoChannel, subscription }),
    );

    if (resp.updateNotificationSubscription?.data) {
      await this.loadMembers();
    }
  };

  setCloseChannel = async () => {
    const resp = await ChatCloseChannel({ channelIo: this._config.baseIoChannel });

    if (resp.closeChannel.data) {
      this._setChannelData(resp.closeChannel.data.channel);
    }

    if (resp.closeChannel?.data?.message) {
      this._setNewMessageData(resp.closeChannel.data.message);
    }
  };

  get currentSessionMember() {
    return this.members?.find(({ accountId }) => accountId === this._config.sessionAccountId);
  }

  get otherMembers() {
    return this.members;
  }

  get otherPossibleMembers() {
    return this.possibleMembers;
  }

  get orderedMessages() {
    return [...this.messages].sort((a, b) => (a?.id ?? 0) - (b?.id ?? 0));
  }

  get channelMessageNotification() {
    return this.channelNotifications?.find(({ type }) => type === IChatMemberNotificationType.Message);
  }

  get channelMentionNotification() {
    return this.channelNotifications?.find(({ type }) => type === IChatMemberNotificationType.Mention);
  }

  get channelReactionNotification() {
    return this.channelNotifications?.find(({ type }) => type === IChatMemberNotificationType.Reaction);
  }

  get showMembers() {
    return this._settings?.showMembers;
  }

  get showVisibility() {
    return this._settings?.showVisibility;
  }

  get forcePublic() {
    return this._settings?.forcePublic;
  }

  get channelComingSoon() {
    return this._settings?.channelComingSoon;
  }

  private _initializeChannelBeforeAction = async <T>(promise: () => Promise<T>) => {
    if (!this.channel?.exists) {
      await ChatCreateChannel({
        channelIo: {
          ...this._config.baseIoChannel,
          ...(this._settings?.forcePublic ? { isPrivate: false } : {}),
          parentOrganizationId: this._config.organizationId,
        },
        membersIo: [],
      });
      await this.getChannelInitialData();
      const promiseResp = await promise();
      this.memberStore.loadInitialMemberLatestMessages(); // async
      return promiseResp;
    }

    return promise();
  };

  private _processMembersToBaseProps = (members: IChatChatIoMemberInputSchema[]) =>
    members.map(({ organizationUserId, accountId, name, email }) => ({
      organizationUserId,
      accountId,
      name,
      email,
    }));

  private _setChannelData = (data: IChatChatIoChannelOutputSchema) => {
    runInAction(() => {
      this.channel = data;
    });
  };

  private _setMembersData = (data: IChatChatIoMemberOutputSchema[]) => {
    runInAction(() => {
      this.members = data;
    });
  };

  private _setNewMessageData = (message: IChatChatIoMessageOutputSchema) => {
    runInAction(() => {
      this.messages = uniqBy([...this.messages, message], "id");
    });
  };
}
