import DoneIcon from "@mui/icons-material/Done";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NotificationsOffOutlinedIcon from "@mui/icons-material/NotificationsOffOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import { ButtonProps, Card, CardContent, CardHeader, Collapse, styled, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { IntegrationStatus } from "components/pages/Company/IntegrationStatus";
import { CompanyIntegration, CompanyWithBankAccountsAndAggregatedData } from "generated/sdk";
import { definitelyFilter } from "generated/utils";
import { BankIcon } from "icons/svg/BankIcon";
import { escapeRegExp, isNull } from "lodash";
import { observer } from "mobx-react-lite";
import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router";
import { useStore } from "storeContainer";
import { COLORS } from "../../../../../themes/default";
import { BankAccountsList } from "./BankAccountsList";
import { CompanySettingsDropDownMenu } from "./CompanySettingsDropdownMenu";
interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ValidateCompany = styled(Button)<ButtonProps>(() => ({
  color: "#DB8C15",
  backgroundColor: "#FFF4E2",
  borderColor: "#FFF4E2",
  fontSize: "14px",
  fontWeight: 400,
  height: "32px",
  "&:hover": {
    backgroundColor: "#FFF4E2",
    borderColor: "#DB8C15",
  },
}));
const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export const CompanyCard: React.FunctionComponent<{
  onlyWithTransactions: boolean;
  company: CompanyWithBankAccountsAndAggregatedData;
  searchKeywords: string;
}> = observer(function CompanyCard({ company, searchKeywords, onlyWithTransactions }) {
  const contextStore = useStore("ContextStore");
  const companyStore = useStore("CompanyStore");
  const location = useLocation();
  const [expanded, setExpanded] = useState(false);
  const [user_subscribed_to_company_notifications, setuser_subscribed_to_company_notifications] = useState(
    company.user_subscribed_to_company_notifications,
  );

  const updateCompanyNotificationSubscription = async (email_notification: boolean) => {
    await companyStore.updateCompanyNotificationSubscription(company.id, email_notification);
  };

  const handleChangeBankAccountName = async (id: string, name: string) => {
    await companyStore.changeBankAccountName(id, name);
  };

  const [integrations, setIntegrations] = useState<CompanyIntegration[] | undefined>();

  useEffect(() => {
    if (location.search.includes("integrations=1")) {
      companyStore.fetchIntegrationsForCompanyId(company.id).then(setIntegrations);
    }
  }, []);

  const CompanyVerificationStatusComponent = () => {
    return (company.verification_status === "Pending" || company.verification_status === "Draft") &&
      !isNull(company.verification_link) ? (
      <Tooltip
        title={
          "We’re in the process of verifying the information provided and it looks like we need additional documentation. Please click the link so that we can continue with the Verification Process. "
        }
      >
        <Link underline="none" href={company.verification_link!}>
          <ValidateCompany
            sx={{ ml: 2, textTransform: "capitalize", whiteSpace: "nowrap" }}
            variant="outlined"
            size="small"
            endIcon={<NavigateNextIcon />}
          >
            Verify Company
          </ValidateCompany>
        </Link>
      </Tooltip>
    ) : (
      <Tooltip
        title={
          "We’re in the process of verifying the Company information provided. Please email security@cherrynet.com for any inquiries."
        }
      >
        <Typography
          sx={{
            color: "#DB8C15",
            backgroundColor: "#FFF4E2",
            p: 1,
            borderRadius: "5px",
            ...(!company.verification_status && { display: "none" }),
          }}
          fontSize={14}
          marginLeft={1}
        >
          {company.verification_status}
        </Typography>
      </Tooltip>
    );
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const filteredBankAccounts = useMemo(() => {
    const escapedKeywords = escapeRegExp(searchKeywords.trim());
    const keywordRegex = new RegExp(escapedKeywords, "i");

    return definitelyFilter(company.bank_accounts)
      .filter((bankAccount) => bankAccount.name.search(keywordRegex) > -1)
      .filter((bankAccount) => {
        if (onlyWithTransactions) {
          return (
            (bankAccount.incoming_checks_stats?.count || 0) > 0 ||
            (bankAccount.outgoing_checks_stats?.count || 0) > 0 ||
            (bankAccount.pending_approval_checks_stats?.count || 0) > 0
          );
        }
        return true;
      });
  }, [company.bank_accounts, searchKeywords, onlyWithTransactions]);

  return (
    <Card sx={{ maxWidth: "840px" }}>
      <CardHeader
        avatar={
          <>
            <ExpandMore expand={expanded} onClick={handleExpandClick} aria-expanded={expanded} aria-label="show more">
              <ExpandMoreIcon />
            </ExpandMore>
            <BankIcon />
          </>
        }
        action={
          <Box display="flex" gap="10px" alignItems="center">
            {user_subscribed_to_company_notifications !== null && (
              <Tooltip title="Toggle company notifications">
                <IconButton
                  onClick={() => {
                    updateCompanyNotificationSubscription(user_subscribed_to_company_notifications !== true);
                    setuser_subscribed_to_company_notifications(user_subscribed_to_company_notifications !== true);
                  }}
                >
                  {user_subscribed_to_company_notifications === true ? (
                    <NotificationsOutlinedIcon />
                  ) : (
                    <NotificationsOffOutlinedIcon />
                  )}
                </IconButton>
              </Tooltip>
            )}

            {company.id ? (
              <CompanySettingsDropDownMenu
                organizationId={contextStore.selectedOrganizationId}
                companyId={company.id}
                bankAccountsNumber={company.bank_accounts?.length || 0}
              />
            ) : null}
          </Box>
        }
        title={
          <Box display="flex" alignItems="centere">
            <Typography
              variant={"h2"}
              color={COLORS.logoBlue}
              maxWidth="300px"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              title={company.name}
            >
              {company.id ? company.name : `Deposit Accounts (${filteredBankAccounts.length})`}
            </Typography>
            {company.vbill_enabled && (
              <Box
                sx={{
                  border: `1px solid ${COLORS.success}`,
                  display: "flex",
                  flexDirection: "row",
                  margin: "5px",
                  padding: "2px 4px",
                  borderRadius: "5px",
                }}
              >
                <Typography variant="body2" color="black">
                  Bill enabled
                </Typography>
                <DoneIcon color="success" />
              </Box>
            )}
            {company.verification_status !== "Active" ? <CompanyVerificationStatusComponent /> : null}
            {!!integrations?.length && (
              <Box>
                {integrations?.map((integration, index) => <IntegrationStatus {...(integration.settings as any)} />)}
              </Box>
            )}
          </Box>
        }
      />

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <BankAccountsList
            handleChangeBankAccountName={handleChangeBankAccountName}
            companyId={company.id}
            depositAccounts={false}
            backgroundColor={COLORS.white}
            bankAccounts={filteredBankAccounts}
          />
        </CardContent>
      </Collapse>
    </Card>
  );
});
