import { VBillActiveFilters } from "components/pages/common/VBill/VBillActiveFilters/VBillActiveFilters";
import { isNil } from "lodash";
import { observer } from "mobx-react";
import { useMemo } from "react";
import { useStore } from "storeContainer";
import { useCodingLedgerFilters } from "../hooks";

export const CodingActiveFilters = observer(() => {
  const vBillCodingLedgerStore = useStore("VBillCodingLedgerStore");
  const [codingLedgerFilters, setCodingLedgerFilters] = useCodingLedgerFilters();

  const additionalMappingLabels = useMemo(
    () =>
      (vBillCodingLedgerStore.companiesSettings.data?.invoice ?? []).map(({ key, label }) => ({
        key,
        label: label ?? "",
      })),
    [vBillCodingLedgerStore.companiesSettings.data?.invoice],
  );

  return (
    <VBillActiveFilters
      filters={{
        search: codingLedgerFilters.filters?.search,
        quickFilterStatusList: codingLedgerFilters.filters?.quickFilterStatusList,
        actionRequiredStatusList: codingLedgerFilters.filters?.actionRequiredStatusList,
        amount_from: !isNil(codingLedgerFilters.filters?.amount_from)
          ? `${codingLedgerFilters.filters.amount_from}`
          : undefined,
        amount_to: !isNil(codingLedgerFilters.filters?.amount_to)
          ? `${codingLedgerFilters.filters.amount_to}`
          : undefined,
        createDateFrom: codingLedgerFilters.filters?.createDateFrom,
        createDateTo: codingLedgerFilters.filters?.createDateTo,
        invoiceDateDueFrom: codingLedgerFilters.filters?.invoiceDateDueFrom,
        invoiceDateDueTo: codingLedgerFilters.filters?.invoiceDateDueTo,
        invoiceDateFrom: codingLedgerFilters.filters?.invoiceDateFrom,
        invoiceDateTo: codingLedgerFilters.filters?.invoiceDateTo,
        invoice_number_sort: codingLedgerFilters.filters?.invoice_number_sort,
        invoice_id_sort: codingLedgerFilters.filters?.invoice_id_sort,
        amount_sort: codingLedgerFilters.filters?.amount_sort,
        dates_sort: codingLedgerFilters.filters?.dates_sort,
        mappings: codingLedgerFilters.filters?.mappings,
      }}
      mappingLabels={additionalMappingLabels}
      onFiltersChange={(values) => {
        setCodingLedgerFilters((prev) => {
          return {
            filters: Object.keys(values).length
              ? {
                  ...prev.filters,
                  search: values.search,
                  quickFilterStatusList: values.quickFilterStatusList,
                  actionRequiredStatusList: values.actionRequiredStatusList,
                  amount_from: values.amount_from ? Number(values.amount_from) : undefined,
                  amount_to: values.amount_to ? Number(values.amount_to) : undefined,
                  createDateFrom: values.createDateFrom,
                  createDateTo: values.createDateTo,
                  invoiceDateDueFrom: values.invoiceDateDueFrom,
                  invoiceDateDueTo: values.invoiceDateDueTo,
                  invoiceDateFrom: values.invoiceDateFrom,
                  invoiceDateTo: values.invoiceDateTo,
                  invoice_number_sort: values.invoice_number_sort,
                  invoice_id_sort: values.invoice_id_sort,
                  amount_sort: values.amount_sort,
                  dates_sort: values.dates_sort,
                  mappings: values.mappings,
                }
              : undefined,
            pagination: { page: 1, per_page: prev.pagination.per_page },
          };
        });
      }}
      sx={{ margin: "0 0 10px" }}
    />
  );
});
