import Typography from "@mui/material/Typography";
import { Spinner } from "components/common/Spinner/Spinner";
import { BankAccountBasic, Invite, PendingAction } from "generated/sdk";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { DataContainerObsevable, useStore } from "storeContainer";
import { COLORS } from "../../themes/default";
import { CollapsiblePendingActionsComponent } from "./CollapsiblePendingActionsComponent";
import { Component } from "./Component";
import { InvitationPendingWidget } from "./InvitationPendingWidget";
interface Props {
  invites: DataContainerObsevable<Invite[]>;
  unconfirmedBankAccounts?: BankAccountBasic[];
  pendingActions?: PendingAction[] | null;
}

const typographyStyle = {
  variant: "h3",
  mb: "15px",
  mt: "30px",
  fontSize: "16px",
  fontWeight: 500,
  display: "flex",
  alignItems: "center",
  color: COLORS.newPrimaryColor,
  paddingLeft: "20px",
};

export const PendingActions: React.FunctionComponent<Props> = observer(function PendingActions({
  invites,
  unconfirmedBankAccounts,
  pendingActions,
}) {
  const [invitesDisplayed, setInvitesDisplayed] = useState<Invite[]>([]);
  const [accceptedInvitesIds, setAcceptedInvitesIds] = useState<string[]>([]);
  const SessionStore = useStore("SessionStore");
  const { session } = SessionStore;
  const companyRelatedPendingActions: PendingAction[] | undefined = pendingActions?.filter(
    (action) => action.company_name !== null,
  );
  const bankAccountRelatedPendingActions: PendingAction[] | undefined = pendingActions?.filter(
    (action) => action.bank_account_account_number !== null,
  );
  useEffect(() => {
    setInvitesDisplayed(() => {
      const filteredInvites = invites?.data?.filter(function (invite) {
        return !accceptedInvitesIds.includes(invite.id!);
      });
      let activeInvites: Invite[] = [];
      filteredInvites?.forEach((invite) => {
        if (invite.status === "ACTIVE") {
          activeInvites.push(invite);
        }
      });

      return activeInvites;
    });
  }, [accceptedInvitesIds, invites.data]);

  return (
    <>
      {invitesDisplayed.length || pendingActions?.length ? (
        <Typography sx={typographyStyle}>Pending Actions</Typography>
      ) : (
        <Typography sx={typographyStyle}>No Pending Actions</Typography>
      )}
      {companyRelatedPendingActions && companyRelatedPendingActions.length > 1 ? (
        <CollapsiblePendingActionsComponent
          checkText={`${companyRelatedPendingActions.length} Companies`}
          pendingActions={companyRelatedPendingActions}
        />
      ) : (
        companyRelatedPendingActions?.map((action: PendingAction, index: number) => {
          return (
            <Component
              key={`${action.bank_account_account_number}-${index}`}
              validateOrConfirm={"validate"}
              checkText={action?.company_name || ""}
              companyOrBa={"company"}
              url={action.verification_link}
              color={COLORS.deepCerulean}
            />
          );
        })
      )}
      {bankAccountRelatedPendingActions && bankAccountRelatedPendingActions.length > 1 ? (
        <CollapsiblePendingActionsComponent
          checkText={`${bankAccountRelatedPendingActions.length} Bank Accounts`}
          pendingActions={bankAccountRelatedPendingActions}
        />
      ) : (
        bankAccountRelatedPendingActions?.map((action: PendingAction, index: number) => {
          return (
            <Component
              key={`${action.bank_account_account_number}-${index}`}
              validateOrConfirm={"validate"}
              checkText={`${action.bank_account_name}****${action.bank_account_account_number}`}
              companyOrBa={"ba"}
              url={action.verification_link}
              color={COLORS.deepCerulean}
            />
          );
        })
      )}
      {session.data
        ? unconfirmedBankAccounts?.map((ba) => (
            <Component
              validateOrConfirm={"confirm"}
              checkText={`${ba.name} ****${ba.account_number}` ?? ""}
              companyOrBa={"ba"}
              id={ba.id}
              color={COLORS.deepCerulean}
            />
          ))
        : null}
      {invites.isFetching ? (
        <Spinner />
      ) : (
        <>
          {invitesDisplayed?.map((invite, index) => (
            <InvitationPendingWidget
              acceptedInvitesIds={accceptedInvitesIds}
              setAcceptedInvitesIds={setAcceptedInvitesIds}
              invites={invitesDisplayed}
              setInvitesDisplayed={setInvitesDisplayed}
              invite={invite}
              key={index}
            />
          ))}
        </>
      )}
    </>
  );
});
