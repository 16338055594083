import {
  IVBillAdditionalMappingInput,
  IVBillLineItemInput,
  IVBillNullableInvoiceInput,
  IVBillStoreIoDataType,
} from "generated/sdk.vbill";
import { TVBillFormFields } from "../../types";

export const adaptNewVBillFormToInvoiceInputType = (
  formFields: TVBillFormFields,
  invoiceId: number,
): IVBillNullableInvoiceInput => {
  const {
    amount,
    invoiceDate,
    invoiceDateDue,
    invoiceNumber,
    senderName,
    senderEmail,
    senderAddress,
    senderPhone,
    mainFile,
    lineItems,
    hasLineItems,
    ...additionalMapping
  } = formFields;

  const lineItemsInput: IVBillLineItemInput[] = (lineItems ?? []).map(
    ({ description, qty, rate, amount, lineItemId, ...glMappings }, index) => {
      const additionalMappingInput: IVBillAdditionalMappingInput[] = (
        Object.keys(glMappings) as IVBillStoreIoDataType[]
      )
        .filter((add) => Object.values(IVBillStoreIoDataType).find((key) => key === add)) // remove extra metaData & highlighted
        .map((mappingType) => {
          const currentAdditionalMapping = glMappings[mappingType];

          return {
            type: mappingType,
            key: currentAdditionalMapping?.addMappingSettingKey ?? "",
            value: currentAdditionalMapping?.key ?? "",
          };
        })
        .filter(({ key, value }) => !!key.length && !!value.length); // remove empty values, temp

      return {
        description: description.length ? description : "",
        qty: qty.length ? qty : "",
        rate: rate.length ? rate : "",
        amount: amount.length ? amount : "",
        invoiceId,
        id: lineItemId.length ? Number(lineItemId) : undefined,
        orderIndex: index,
        glMappings: additionalMappingInput.length ? [{ additionalMapping: additionalMappingInput }] : undefined,
      };
    },
  );
  const additionalMappingInput: IVBillAdditionalMappingInput[] = (
    Object.keys(additionalMapping) as IVBillStoreIoDataType[]
  )
    .filter((add) => Object.values(IVBillStoreIoDataType).find((key) => key === add)) // remove extra field meta
    .map((mappingType) => {
      const currentAdditionalMapping = additionalMapping[mappingType];

      return {
        type: mappingType,
        key: currentAdditionalMapping?.addMappingSettingKey ?? "",
        value: currentAdditionalMapping?.key ?? "",
      };
    })
    .filter(({ key, value }) => !!key.length && !!value.length); // remove empty values, temp

  const invoiceData: IVBillNullableInvoiceInput = {
    amount: amount.length ? amount : "",
    invoiceDate: invoiceDate.length ? invoiceDate : undefined,
    invoiceDateDue: invoiceDateDue.length ? invoiceDateDue : undefined,
    invoiceNumber: invoiceNumber.length ? invoiceNumber : undefined,
    senderName: senderName.length ? senderName : undefined,
    senderEmail: senderEmail.length ? senderEmail : undefined,
    senderAddress: senderAddress.length ? senderAddress : undefined,
    senderPhone: senderPhone.length ? senderPhone : undefined,
    mainFileId: mainFile?.value ? mainFile.value : undefined,
    hasLineItems,
    // additionalMapping
    additionalMapping: additionalMappingInput.length ? additionalMappingInput : undefined,
    // line items
    lineItems: lineItemsInput.length ? lineItemsInput : undefined,
  };

  return invoiceData;
};
