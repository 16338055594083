import { Maybe } from "./sdk";

export const definitelyFilter = <T>(data: Maybe<T | undefined>[] | null | undefined) =>
  data ? data.filter((e): e is T => !!e) : [];
export const definitely = <T>(data: Maybe<T> | null | undefined) => data || undefined;

export const definitelyObject = <T>(obj: Maybe<T | undefined>) => (obj ? obj : ({} as T));

export const enumKeys = <T>(myEnum: T): (keyof T)[] =>
  Object.keys(myEnum!)
    .map((key) => (myEnum as any)[key])
    .filter((value) => typeof value === "string") as any as (keyof T)[];

export const enumValues = <T>(myEnum: T) => enumKeys(myEnum).map((k) => myEnum[k]);

// TYPESCRIPT WIN: https://github.com/microsoft/TypeScript/issues/33591#issuecomment-841832390
export const flattenUnionOfArrayTypes = <T extends Array<K>, K>(a: T): Array<T[number]> => a;

export type Await<T> = T extends Promise<infer U> ? U : T;

export type Unpacked<T> = T extends (infer U)[] ? U : T;

export type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (k: infer I) => void ? I : never;
