import { Box, Table, TableContainer } from "@mui/material";
import { PaymentsNavigation } from "components/common/PaymentsNavigation/PaymentsNavigation";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useParams } from "react-router";
import { useStore } from "storeContainer";
import { transformDatesAsSODUTCIsoString } from "../common/VBill/utils";
import { VBillRouteGuardRedirect } from "../common/VBill/VBillRouteGuardRedirect/VBillRouteGuardRedirect";
import { usePaymentsLedgerFilters, useVBillPaymentsLedgerChildRoute } from "./hooks";
import { PaymentsActiveFilters } from "./PaymentsActiveFilters/PaymentsActiveFilters";
import { PaymentsFooter } from "./PaymentsFooter/PaymentsFooter";
import { PaymentsHeader } from "./PaymentsHeader/PaymentsHeader";
import { PaymentsLedgerList } from "./PaymentsList/PaymentsLedgerList";
import { PaymentsListFilters } from "./PaymentsListFilters/PaymentsListFilters";
import { PaymentsQuickFilters } from "./PaymentsQuickFilters/PaymentsQuickFilters";
import { getVBillPaymentsLedgerPaymentStatusByChildRoute } from "./utils";

export const VBillPaymentsLedger = observer(function VBillPaymentsLedger() {
  const { organizationId } = useParams<{ organizationId: string }>();
  const vBillPaymentsLedgerStore = useStore("VBillPaymentsLedgerStore");
  const userSettingsStore = useStore("UserSettingsStore");
  const [paymenstLedgerFilters] = usePaymentsLedgerFilters();
  const paymentsLedgerChildRoute = useVBillPaymentsLedgerChildRoute();

  useEffect(() => {
    const paymentStatusByChildRoute = getVBillPaymentsLedgerPaymentStatusByChildRoute(paymentsLedgerChildRoute);

    vBillPaymentsLedgerStore.loadPaymentsLedgerList({
      organizationId,
      pagination: paymenstLedgerFilters.pagination,
      filters: {
        companyIds: userSettingsStore.companiesSelectedIds.length ? userSettingsStore.companiesSelectedIds : undefined,
        paymentStatus: paymentStatusByChildRoute ? paymentStatusByChildRoute : undefined,
        ...paymenstLedgerFilters.filters,
        ...transformDatesAsSODUTCIsoString(paymenstLedgerFilters.filters),
      },
    });
  }, [
    organizationId,
    paymenstLedgerFilters.pagination,
    paymenstLedgerFilters.filters,
    paymentsLedgerChildRoute,
    userSettingsStore.companiesSelectedIds,
    vBillPaymentsLedgerStore,
  ]);

  useEffect(() => {
    vBillPaymentsLedgerStore.loadCompaniesSettings({
      organizationId,
      companyIds: userSettingsStore.companiesSelectedIds.length ? userSettingsStore.companiesSelectedIds : undefined,
    });
  }, [vBillPaymentsLedgerStore, organizationId, userSettingsStore.companiesSelectedIds]);

  useEffect(() => {
    vBillPaymentsLedgerStore.loadPaymentsStats({ organizationId });
  }, [organizationId, vBillPaymentsLedgerStore]);

  useEffect(() => {
    return () => {
      vBillPaymentsLedgerStore.paymentsLedgerList.flush();
      vBillPaymentsLedgerStore.paymentsStats.flush();
      vBillPaymentsLedgerStore.companiesSettings.flush();
    };
  }, [vBillPaymentsLedgerStore]);

  return (
    <Box>
      <VBillRouteGuardRedirect route="PAYMENTS_LEDGER" />

      <PaymentsNavigation />

      <PaymentsQuickFilters />

      <PaymentsListFilters />

      <PaymentsActiveFilters />

      <TableContainer sx={{ marginBottom: "100px" }}>
        <Table>
          <PaymentsHeader />

          <PaymentsLedgerList />
        </Table>
      </TableContainer>

      <PaymentsFooter />
    </Box>
  );
});
