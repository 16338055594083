import { Box, Tooltip, Typography } from "@mui/material";
import { IInvoiceProps } from "components/pages/VBill/types";
import {
  IVBillIIntegrationIntegrationSettingsVBillAdditionalMappingsEntryShowAs,
  IVBillIntegrationSettingsVBillAdditionalMappingsEntryOutput,
} from "generated/sdk.vbill";
import { FunctionComponent } from "react";
import { COLORS } from "themes/default";
import { InvoiceMappingFieldSelector } from "./InvoiceMappingFieldSelector/InvoiceMappingFieldSelector";

interface IInvoiceMappingFieldProps extends IInvoiceProps {
  additionalMapping: IVBillIntegrationSettingsVBillAdditionalMappingsEntryOutput;
  title?: string;
}

export const InvoiceMappingField: FunctionComponent<IInvoiceMappingFieldProps> = ({
  additionalMapping: { showAs, label, storeDataType, required },
  additionalMapping,
  invoice,
  title,
}) => {
  if (showAs === IVBillIIntegrationIntegrationSettingsVBillAdditionalMappingsEntryShowAs.Select) {
    return (
      <Box sx={{ marginBottom: "10px" }}>
        <Typography
          sx={{
            fontSize: 16,
            marginBottom: "5px",
            fontWeight: 500,
            color: COLORS.logoBlue,
            textTransform: "uppercase",
          }}
        >
          {title ? title : label ? label : storeDataType}:{" "}
          {required && (
            <Tooltip arrow title={`${(title ? title : label ? label : storeDataType).toUpperCase()} is required`}>
              <Box component="span" sx={{ fontSize: "16px", color: COLORS.error, marginLeft: "2px" }}>
                *
              </Box>
            </Tooltip>
          )}
        </Typography>

        <InvoiceMappingFieldSelector
          additionalMappings={additionalMapping}
          formFieldName={storeDataType}
          invoiceCompanyId={invoice?.companyId ?? ""}
          invoiceCoreOrgId={invoice?.coreOrgId ?? ""}
        />
      </Box>
    );
  }

  return null;
};
