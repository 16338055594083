import { graphqlClient } from "common/graphqlClient";
import { DelegationGroupScopeEnum, DelegationGroupsQuery, getSdk } from "generated/sdk";
import { IVBillStoreIoDataType, IVBillVBillInvoiceQuery } from "generated/sdk.vbill";
import { useEffect, useState } from "react";

export const useVendorGroups = (invoice?: IVBillVBillInvoiceQuery["invoice"] | null) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [delegationGroups, setDelegationGroups] = useState<DelegationGroupsQuery["DelegationGroups"] | undefined>();
  const [reloadIdx, setReloadIdx] = useState<number>(0);

  useEffect(() => {
    if (invoice) {
      const vendor_id = invoice?.additionalMappings?.find(
        (additionalMapping) => additionalMapping.type === IVBillStoreIoDataType.Contact,
      )?.key;

      if (vendor_id) {
        setLoading(true);
        getSdk(graphqlClient)
          .DelegationGroups({
            scope: DelegationGroupScopeEnum.VbillApprove,
            organization_id: invoice?.coreOrgId!,
            // FIXME: also allow company root approver to clear the vendor rules they have access to
            // this is non-trivial
            // company_id: invoice?.company?.companyId!,
            vendor_id: invoice?.additionalMappings?.find(
              (additionalMapping) => additionalMapping.type === IVBillStoreIoDataType.Contact,
            )?.key,
            match_any: false,
          })
          .then((data) => {
            setDelegationGroups(data.DelegationGroups);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  }, [invoice, reloadIdx]);

  const reload = () => setReloadIdx((prev) => ++prev);

  return [delegationGroups, loading, reload] as const;
};
