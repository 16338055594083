import ClearIcon from "@mui/icons-material/Clear";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Dialog, Divider, IconButton, InputAdornment, TextField, Tooltip } from "@mui/material";
import Typography from "@mui/material/Typography";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { graphqlChatClient, graphqlVBillClient } from "common/graphqlClient";
import { VBillPriceAmount } from "components/pages/common/VBill/VBillPriceAmount/VBillPriceAmount";
import { useBatchLedgerFilters } from "components/pages/VBillBatchLedger/hooks";
import { getSdk as chatGetSdk, IChatChannelParentType } from "generated/sdk.chat";
import {
  getSdk as vBillGetSdk,
  IVBillBillsFiltersMappingInput,
  IVBillInvoiceIncomingStatus,
  IVBillNullableCreateBatchInput,
  IVBillVBillCompaniesSettingsQuery,
} from "generated/sdk.vbill";
import { omit } from "lodash";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSetState } from "react-use";
import { useStore } from "storeContainer";
import { COLORS } from "themes/default";
import { transformDatesAsSODUTCIsoString } from "../utils";
import { AddFullBatchCategories } from "./AddFullBatchCategories/AddFullBatchCategories";
import { AddFullBatchCompanies } from "./AddFullBatchCompanies/AddFullBatchCompanies";
import { AddFullBatchDueDateRange } from "./AddFullBatchDueDateRange/AddFullBatchDueDateRange";
import { AddFullBatchMembers } from "./AddFullBatchMembers/AddFullBatchMembers";
import { AddFullBatchSummary } from "./AddFullBatchSummary/AddFullBatchSummary";
import { AddFullBatchVendors } from "./AddFullBatchVendors/AddFullBatchVendors";

const { VBillCreateBatch, VBillCompaniesSettings, VBillAddMembersToBatch, VBillAddAllInvoicesToBatch } =
  vBillGetSdk(graphqlVBillClient);
const { ChatCreateChannel, ChatAddMembers } = chatGetSdk(graphqlChatClient);

export const labelTextStyles = { color: COLORS.logoBlue, fontWeight: 500, minWidth: "100px" };

export interface ICreateBatchData extends IVBillNullableCreateBatchInput {
  memberIds?: string[];
  addBillsToBatch?: boolean;
}

export interface IVBillAddFullBatchDialogProps {
  onDialogClose: () => void;
}

export const VBillAddFullBatchDialog = ({ onDialogClose }: IVBillAddFullBatchDialogProps) => {
  const vBillBatchLedgerStore = useStore("VBillBatchLedgerStore");
  const [batchLedgerFilters, setBatchLedgerFilters] = useBatchLedgerFilters();
  const [hasCreateBatchError, setHasCreateBatchError] = useState(false);
  const orgUserStore = useStore("OrganizationUsersStore");
  const [showCreateBtnSpinner, setShowCreateBtnSpinner] = useState(false);
  const { organizationId } = useParams<{ organizationId: string }>();
  const [createBatchData, setCreateBatchData] = useSetState<ICreateBatchData>();
  const [createBatchFieldErrors, setCreateBatchFieldErrors] = useSetState({ name: false, budget: false });
  const [companiesSettings, setCompaniesSettings] = useState<
    IVBillVBillCompaniesSettingsQuery["companiesSettings"]["vBill"]["additionalMappings"]["invoice"] | undefined
  >();
  const { organizationUsersList } = orgUserStore;

  useEffect(() => {
    setCompaniesSettings(undefined);
    VBillCompaniesSettings({
      organizationId,
      companyIds: createBatchData.batchFilters?.companyIds?.length
        ? createBatchData.batchFilters.companyIds
        : undefined,
    }).then((resp) => setCompaniesSettings(resp.companiesSettings.vBill.additionalMappings.invoice));
  }, [createBatchData.batchFilters?.companyIds, organizationId]);

  useEffect(() => {
    if (!createBatchData.batchFilters?.companyIds?.length) {
      return;
    }

    setCreateBatchData((prev) => ({
      batchFilters: { ...prev.batchFilters, mappings: undefined },
      addBillsToBatch: false,
    }));
  }, [createBatchData.batchFilters?.companyIds?.length, setCreateBatchData]);

  const handleCreateBatchSubmit = async () => {
    setHasCreateBatchError(false);

    if (!createBatchData.name?.length || !createBatchData.budget?.length) {
      return setCreateBatchFieldErrors({
        name: !createBatchData.name?.length,
        budget: !createBatchData.budget?.length,
      });
    }
    setShowCreateBtnSpinner(true);

    const batchDataPayload = omit(createBatchData, ["memberIds", "addBillsToBatch"]);

    try {
      const createBatchResp = await VBillCreateBatch({
        batchData: { ...batchDataPayload, ...transformDatesAsSODUTCIsoString(batchDataPayload), organizationId },
      });

      const memberIds = createBatchData.memberIds ?? [];
      const batchId = createBatchResp.createBatch.data?.id ?? 0;
      const channelIo = {
        parentContext: "sending",
        parentKey: `${batchId}`,
        parentType: IChatChannelParentType.VbillBatch,
        parentOrganizationId: organizationId,
      };

      await Promise.all([
        ...(memberIds.length ? [VBillAddMembersToBatch({ batchId, memberIds })] : []),
        ...(createBatchData.addBillsToBatch && createBatchData.batchFilters
          ? [
              VBillAddAllInvoicesToBatch({
                batchId,
                billsFilters: {
                  ...createBatchData.batchFilters,
                  batch_id_in: [0],
                  free_amount_from: "0.01",
                  status: IVBillInvoiceIncomingStatus.Approved,
                },
              }),
            ]
          : []),
        ChatCreateChannel({ channelIo, membersIo: [] }),
      ]);

      if (memberIds.length) {
        const chatAddMembersData = (organizationUsersList.data ?? [])
          .filter(({ id }) => memberIds.includes(id))
          .map(({ id, account }) => ({
            accountId: account?.id,
            email: account?.email,
            name: account?.name ?? "",
            organizationUserId: id,
          }));

        await ChatAddMembers({ channelIo: omit(channelIo, ["parentOrganizationId"]), membersIo: chatAddMembersData });
      }

      const newFilters = {
        filters: {},
        pagination: { page: 1, per_page: batchLedgerFilters.pagination.per_page },
      };

      setBatchLedgerFilters(newFilters);
      vBillBatchLedgerStore.loadBatchLedgerList(
        { filters: { ...newFilters.filters, organizationId }, pagination: newFilters.pagination },
        true,
      );
      onDialogClose();
    } catch (err) {
      setHasCreateBatchError(true);
    } finally {
      setShowCreateBtnSpinner(false);
    }
  };

  const handleNameInputChange = (value: string) => {
    if (createBatchFieldErrors.name) {
      setCreateBatchFieldErrors({ name: false });
    }

    setCreateBatchData({ name: value });
  };

  const handleBudgetInputChange = (value: string) => {
    if (createBatchFieldErrors.budget) {
      setCreateBatchFieldErrors({ budget: false });
    }

    setCreateBatchData({ budget: value });
  };

  const handleBatchVendorsMappingSave = (value: IVBillBillsFiltersMappingInput) => {
    const currentMappings = createBatchData.batchFilters?.mappings ?? [];
    const newMappings = (
      currentMappings.find(({ key }) => key === value.key)
        ? currentMappings.map((mapping) => (mapping.key === value.key ? value : mapping))
        : [...currentMappings, value]
    ).filter(({ valuesIn }) => valuesIn.length > 0);

    setCreateBatchData((prev) => ({ batchFilters: { ...prev.batchFilters, mappings: newMappings } }));
  };

  const handleBatchCategoriesMappingsSave = (value: IVBillBillsFiltersMappingInput) => {
    const currentMappings = createBatchData.batchFilters?.mappings ?? [];
    const newMappings = (
      currentMappings.find(({ key }) => key === value.key)
        ? currentMappings.map((mapping) => (mapping.key === value.key ? value : mapping))
        : [...currentMappings, value]
    ).filter(({ valuesIn }) => valuesIn.length > 0);

    setCreateBatchData((prev) => ({ batchFilters: { ...prev.batchFilters, mappings: newMappings } }));
  };

  return (
    <Dialog
      onClose={onDialogClose}
      open
      PaperProps={{ sx: { padding: "15px", position: "relative", width: "400px" } }}
      scroll="body"
    >
      <Tooltip arrow title="Close">
        <IconButton onClick={onDialogClose} sx={{ position: "absolute", top: "5px", right: "5px", zIndex: 1 }}>
          <CloseIcon sx={{ fontSize: "20px" }} />
        </IconButton>
      </Tooltip>

      <Typography sx={{ fontSize: "20px", marginBottom: "20px" }}>Add batch:</Typography>

      <Box sx={{ marginBottom: "15px" }}>
        <Typography sx={{ ...labelTextStyles, marginBottom: "8px" }}>Name:</Typography>
        <TextField
          placeholder="Name"
          fullWidth
          value={createBatchData.name ?? ""}
          onChange={(e) => handleNameInputChange(e.target.value)}
          error={createBatchFieldErrors.name}
          helperText={createBatchFieldErrors.name ? "Name is required." : undefined}
        />
      </Box>

      <Box sx={{ marginBottom: "15px" }}>
        <Typography sx={{ ...labelTextStyles, marginBottom: "8px" }}>Budget:</Typography>
        <VBillPriceAmount
          sx={{ width: "140px" }}
          value={createBatchData.budget ?? ""}
          setValue={handleBudgetInputChange}
          errorMsg={createBatchFieldErrors.budget ? "Budget is required." : undefined}
          placeholder="Budget"
        />
      </Box>

      <Box sx={{ marginBottom: "15px" }}>
        <Typography sx={{ ...labelTextStyles, marginBottom: "8px" }}>Date:</Typography>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Select date"
            value={createBatchData.batchDate ?? null}
            onChange={(date) => setCreateBatchData({ batchDate: date ? date.toString() : undefined })}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                sx={{ width: "140px" }}
                {...(createBatchData.batchDate && {
                  InputProps: {
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title="Clear" arrow>
                          <IconButton
                            onClick={() => setCreateBatchData({ batchDate: undefined })}
                            sx={{ marginRight: "-12px" }}
                          >
                            <ClearIcon />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  },
                })}
              />
            )}
          />
        </LocalizationProvider>
      </Box>

      <AddFullBatchMembers
        memberIds={createBatchData.memberIds}
        onMembersSubmit={(memberIds) => setCreateBatchData({ memberIds })}
      />

      <AddFullBatchCompanies
        companyIds={createBatchData.batchFilters?.companyIds}
        onCompaniesSubmit={(companyIds) =>
          setCreateBatchData((prev) => ({ batchFilters: { ...prev.batchFilters, companyIds } }))
        }
      />

      <AddFullBatchDueDateRange
        dueDate={createBatchData.batchFilters}
        onDueDateSubmit={(dueDate) =>
          setCreateBatchData((prev) => ({ batchFilters: { ...prev.batchFilters, ...dueDate } }))
        }
      />

      <AddFullBatchVendors
        additionalMappingsSettingsData={companiesSettings}
        mappings={createBatchData.batchFilters?.mappings}
        companyIds={createBatchData.batchFilters?.companyIds}
        onMappingSave={handleBatchVendorsMappingSave}
      />

      <AddFullBatchCategories
        additionalMappingsSettingsData={companiesSettings}
        mappings={createBatchData.batchFilters?.mappings}
        companyIds={createBatchData.batchFilters?.companyIds}
        onMappingsSave={handleBatchCategoriesMappingsSave}
      />

      <AddFullBatchSummary
        batchFilters={createBatchData.batchFilters}
        addBillsSwitcherChecked={createBatchData.addBillsToBatch ?? false}
        onAddBillsSwitcherChange={(checked) => setCreateBatchData({ addBillsToBatch: checked })}
      />

      <Divider sx={{ marginTop: 4, marginBottom: 2 }} />

      {hasCreateBatchError && (
        <Typography sx={{ marginBottom: "15px", fontSize: "12px", color: "#B00020", textAlign: "center" }}>
          There was an error processing your request. Please try again.
        </Typography>
      )}

      <Box sx={{ display: "flex", justifyContent: "end", gap: "15px" }}>
        <LoadingButton loading={showCreateBtnSpinner} variant="contained" onClick={handleCreateBatchSubmit}>
          Create
        </LoadingButton>

        <Button variant="outlined" onClick={onDialogClose}>
          Cancel
        </Button>
      </Box>
    </Dialog>
  );
};
