import { observer } from "mobx-react-lite";
import { ComponentProps, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useStore } from "storeContainer";
import LinkedAddresses from "./LinkedAddresses";

const LinkedAddressesContainer = (
  props: Omit<ComponentProps<typeof LinkedAddresses>, "addresses" | "error" | "loading" | "bankAccountId">,
) => {
  const { accountId } = useParams<{ accountId: string }>();
  const { error, isSyncing, getLinkedEmailAddresses } = useStore("AccountsStore");
  const [linkedAddresses, setLinkedAddresses] = useState<any>();

  const fetchLinked = async () => {
    setLinkedAddresses((await getLinkedEmailAddresses(accountId)).BankAccountLinkedAddresses);
  };

  useEffect(() => {
    setLinkedAddresses(null);
    fetchLinked();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSyncing]);
  return (
    <>
      <LinkedAddresses
        bankAccountId={accountId}
        addresses={linkedAddresses}
        error={error}
        loading={isSyncing}
        {...props}
      />
    </>
  );
};

export default observer(LinkedAddressesContainer);
