import { Box, Typography } from "@mui/material";
import { COLORS } from "themes/default";

export const ChannelComingSoonLayer = () => (
  <>
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 1,
        backgroundColor: COLORS.lightGrey,
        opacity: ".5",
      }}
    />
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 2,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography sx={{ fontSize: "20px" }}>Offline</Typography>
    </Box>
  </>
);
