import { useCallback } from "react";
import { observer } from "mobx-react-lite";
import { useHistory, useParams } from "react-router";
import LinkedAddressDetails from "./LinkedAddressDetails";

const LinkedAddressDetailsContainer = () => {
  const history = useHistory();
  const { accountId, organizationId, companyId } = useParams<{
    accountId: string;
    organizationId: string;
    companyId: string;
  }>();

  const handleClose = useCallback(() => {
    history.push(`/org/${organizationId}/company/${companyId}/bas/${accountId}/emails`);
  }, [accountId, companyId, history, organizationId]);

  return <LinkedAddressDetails onClose={handleClose} />;
};

export default observer(LinkedAddressDetailsContainer);
