import { Box, MenuItem, Tooltip, Typography } from "@mui/material";
import { format, formatDistance } from "date-fns";
import { IChatChatGetMemberLatestMessagesQuery } from "generated/sdk.chat";
import { isNil } from "lodash";
import { ChatMessageBlockContent } from "modules/Chat/components/common/ChatViewChannel/ChatMessages/ChatMessage/ChatMessageBlockContent/ChatMessageBlockContent";
import { IGetChatNotificationUrlArgs } from "modules/Chat/utils/utils";
import { FunctionComponent } from "react";
import { COLORS } from "themes/default";

interface ILatestMessagesNotificationProps {
  latestMessage: NonNullable<
    NonNullable<IChatChatGetMemberLatestMessagesQuery["getMemberLatestMessages"]>["data"]
  >["items"][0];
  onChannelNotificationClick: (channel: IGetChatNotificationUrlArgs) => void;
  notificationsCount?: number;
  isMessageOwner: boolean;
}

export const LatestMessagesNotification: FunctionComponent<ILatestMessagesNotificationProps> = ({
  latestMessage,
  onChannelNotificationClick,
  notificationsCount,
  isMessageOwner,
}) => (
  <MenuItem
    onClick={() => onChannelNotificationClick(latestMessage.channel)}
    sx={{
      borderBottom: `1px solid ${COLORS.borderColor}`,
      "&:last-child": { borderBottom: "none" },
    }}
  >
    <Box sx={{ width: "100%" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography sx={{ fontWeight: 500, maxWidth: "240px" }} noWrap title={latestMessage.channel.name}>
          {latestMessage.channel.name}
        </Typography>

        <Tooltip title={format(new Date(latestMessage.createdAt), "h:maaa MM/dd/yyyy")}>
          <Typography
            sx={{ fontSize: "10px", ...(notificationsCount ? { color: COLORS.logoBlue, fontWeight: 600 } : {}) }}
          >
            {formatDistance(new Date(latestMessage.createdAt), new Date())}
          </Typography>
        </Tooltip>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <ChatMessageBlockContent
          content={latestMessage.content}
          isMessageOwner={false}
          sx={{
            background: "none",
            maxWidth: "280px",
            fontSize: "12px",
            padding: 0,
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
          prefixText={<>{isMessageOwner ? "You" : latestMessage.member.name}: </>}
        />

        {!isNil(notificationsCount) && (
          <Typography
            sx={{
              backgroundColor: COLORS.logoBlue,
              color: COLORS.white,
              fontSize: "10px",
              lineHeight: "12px",
              height: "14px",
              width: "14px",
              borderRadius: "7px",
              textAlign: "center",
            }}
          >
            {notificationsCount}
          </Typography>
        )}
      </Box>
    </Box>
  </MenuItem>
);
