import { Box, Button, FormControl, InputLabel, Popover, TextField, Typography } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { getVBillFormattedDate } from "components/pages/common/VBill/utils";
import { format } from "date-fns";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { useStore } from "storeContainer";
import { RecurringBatchCustomPicker } from "./RecurringBatchCustomPicker/RecurringBatchCustomPicker";

interface ISummariesRecurringBatchPopoverProps {
  popoverId?: string;
  popoverAnchorEl: HTMLElement | null;
  onClosePopover: () => void;
}

// TODO: selected tooltip
// TODO: next batch preview

const timeRanges = ["none", "daily", "weekly", "monthly", "annually", "every_weekday", "custom"] as const;

// https://date-fns.org/v3.3.1/docs/eachDayOfInterval

export const SummariesRecurringBatchPopover = observer(function SummariesRecurringBatchPopover({
  popoverId,
  popoverAnchorEl,
  onClosePopover,
}: ISummariesRecurringBatchPopoverProps) {
  const vBillBatchDetailsStore = useStore("VBillBatchDetailsStore");

  const [selectedTimeRange, setSelectedTimeRange] = useState<(typeof timeRanges)[number]>(timeRanges[0]);
  const [currentDateSelected, setCurrentDateSelected] = useState<string | null>(null); // date string

  const { batchDetails, DEMO_batch_recurringDate } = vBillBatchDetailsStore;
  const batchData = batchDetails.data;

  return (
    <Popover
      id={popoverId}
      open
      anchorEl={popoverAnchorEl}
      onClose={onClosePopover}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
    >
      <Box sx={{ padding: "20px", minWidth: "320px" }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: "10px", paddingBottom: "20px" }}>
          <Typography sx={{ whiteSpace: "nowrap" }}>Relative date:</Typography>
          {selectedTimeRange !== "none" && (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Pick relative date"
                value={currentDateSelected}
                onChange={(date) => {
                  setCurrentDateSelected(date ? new Date(date).toString() : null);
                }}
                renderInput={(params) => <TextField {...params} size="small" sx={{ width: "150px", height: "32px" }} />}
              />
            </LocalizationProvider>
          )}
        </Box>
        <Box sx={{ maxWidth: "600px" }}>
          <FormControl fullWidth>
            <InputLabel id="repeat-step-label">Range</InputLabel>
            <Select
              labelId="repeat-step-label"
              id="repeat-step"
              value={selectedTimeRange}
              label="Range"
              onChange={(e) => setSelectedTimeRange(e.target.value as (typeof timeRanges)[number])}
            >
              {timeRanges.map((range) => (
                <MenuItem key={range} value={range}>
                  {range === "none"
                    ? "Does not repeat"
                    : range === "daily"
                    ? "Daily"
                    : range === "weekly"
                    ? `Weekly on ${format(currentDateSelected ? new Date(currentDateSelected) : new Date(), "EEEE")}`
                    : range === "monthly"
                    ? `Monthly on the last ${format(
                        currentDateSelected ? new Date(currentDateSelected) : new Date(),
                        "EEEE",
                      )}`
                    : range === "annually"
                    ? `Annualy on ${format(currentDateSelected ? new Date(currentDateSelected) : new Date(), "MMMM d")}`
                    : range === "every_weekday"
                    ? "Every weekday (Monday to Friday)"
                    : range === "custom"
                    ? "Custom..."
                    : undefined}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {(DEMO_batch_recurringDate.type ?? "none") !== "none" && (
            <Box sx={{ display: "flex", alignItems: "center", gap: "10px", marginTop: "20px" }}>
              <Typography sx={{ whiteSpace: "nowrap" }}>
                Next batch date: <strong>{getVBillFormattedDate(DEMO_batch_recurringDate?.value?.toString())}</strong>
              </Typography>
            </Box>
          )}

          <Box sx={{ display: "flex", justifyContent: "flex-end", gap: "10px", marginTop: "20px" }}>
            <Button variant="contained" sx={{ height: "32px" }} onClick={onClosePopover}>
              Save
            </Button>

            <Button variant="outlined" sx={{ height: "32px" }} onClick={onClosePopover}>
              Cancel
            </Button>
          </Box>
        </Box>
      </Box>

      {selectedTimeRange === "custom" && (
        <RecurringBatchCustomPicker
          onCloseDialog={() => setSelectedTimeRange("none")}
          currentDateSelected={currentDateSelected}
        />
      )}
    </Popover>
  );
});
