import { AccessRestrictedComponent } from "../common/AccessRestrictedComponent";
import Box from "@mui/material/Box";

export const AccessRestricted: React.FunctionComponent = () => {
  return (
    <Box sx={{ display: "flex", mt: 10, justifyContent: "center", width: "100%" }}>
      <AccessRestrictedComponent />
    </Box>
  );
};
