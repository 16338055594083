import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Skeleton } from "@mui/material";
import { LedgerCheck } from "generated/sdk";
import { useHistory } from "react-router-dom";
import { formatCurrency } from "../../../../common/helpers/utils";
import styles from "./RelatedTransactionsTable.module.scss";

interface RelatedTransactionsTableProps {
  data: LedgerCheck[];
  isLoading: boolean;
}

const RelatedTransactionsTable = ({ data, isLoading = false }: RelatedTransactionsTableProps) => {
  const history = useHistory();
  const getStyle = (status: string) => {
    const statusDictionary = {
      Signed: styles.SIGNED,
      Completed: styles.COMPLETED,
      Processing: styles.PROCESSING,
      Deposited: styles.DEPOSITED,
      "Sent to Recipient": styles.SENT_TO_RECIPIENT,
      "Pending Signature": styles.PENDING_SIGNATURE,
      Cancelled: styles.CANCELLED,
      Deleted: styles.DELETED,
      default: styles.COMPLETED,
    };
    //TODO this is proper typescript, but a better way would be to create an interface instead of typeof statusDictionary
    return statusDictionary[status as keyof typeof statusDictionary] || statusDictionary.default;
  };
  const handleClick = (check: LedgerCheck) => {
    if (check.role === "sender") {
      history.push(`ledger/${check?.sender?.id}/checks/${check.id}`);
    } else if (check.role === "recipient") {
      history.push(`ledger/${check?.recipient?.id}/checks/${check.id}`);
    } else {
      history.push(`/check/${check.id}`);
    }
  };

  return (
    <table className={styles.table}>
      <thead>
        <tr>
          <th>Check #</th>
          <th>Amount</th>
          <th>Sender</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        {isLoading ? (
          <tr>
            <td>
              <Skeleton variant="text" />
            </td>
            <td>
              <Skeleton variant="text" />
            </td>
            <td>
              <Skeleton variant="text" />
            </td>
            <td>
              <Skeleton variant="text" />
            </td>
          </tr>
        ) : (
          data?.map((check, index) => {
            return (
              <tr key={`${check.number}=${index}`}>
                <td onClick={() => handleClick(check)}>
                  {check.role === "recipient" ? (
                    <div className={styles.recipient}>
                      <ArrowDownwardIcon className={styles.arrow} />
                      {check?.number}
                    </div>
                  ) : (
                    <div className={styles.sender}>
                      <ArrowUpwardIcon className={styles.arrow} />
                      {check?.number}
                    </div>
                  )}
                </td>
                <td>{formatCurrency(check?.amount!)}</td>
                <td>{check?.sender?.name}</td>
                <td>
                  <div className={getStyle(check?.status?.status!)}>{check?.status?.status}</div>
                </td>
              </tr>
            );
          })
        )}
      </tbody>
    </table>
  );
};

export default RelatedTransactionsTable;
