import { ForwardedRef, forwardRef, memo, useCallback, useMemo, useState } from "react";
import classnames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import * as components from "./components";
import styles from "./Card.module.scss";
import { ICardProps } from "./types";
import MappingHeader from "components/pages/Settings/Integrations/Integration/MappingHeader";
import { Paper } from "@mui/material";

// @TODO analyze if separation between basic Card and ExpandableCard is needed
const Card = (
  {
    children,
    className,
    components: { Container = Paper, Content = components.Content, Header = components.Header } = {},
    contentProps = {},
    defaultExpanded,
    headerProps = {},
    spacingClass = styles.spacing,
    title,
    onChange,
    ...rest
  }: ICardProps,
  ref: ForwardedRef<typeof Container>,
) => {
  const isExpandable = useMemo(() => defaultExpanded !== undefined, [defaultExpanded]);
  const [expanded, setExpanded] = useState(isExpandable ? defaultExpanded : true);

  const handleExpandToggle = useCallback(() => {
    onChange && onChange(!expanded);
    setExpanded(!expanded);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expanded, setExpanded]);

  return (
    <>
      <Paper className={classnames(styles.container, className)} {...rest}>
        <MappingHeader
          spacingClass={spacingClass}
          title={title}
          {...(isExpandable ? { expanded: expanded, onExpandToggle: handleExpandToggle } : {})}
          {...headerProps}
        />
        <AnimatePresence initial={false}>
          {expanded && (
            <motion.div
              initial="collapsed"
              animate="open"
              exit="collapsed"
              variants={{
                open: {
                  opacity: 1,
                  overflow: "hidden",
                  height: "auto",
                  transitionEnd: { overflow: "initial" },
                },
                collapsed: { opacity: 0, overflow: "hidden", height: 0 },
              }}
            >
              {children}
            </motion.div>
          )}
        </AnimatePresence>
      </Paper>
    </>
  );
};

export default memo(forwardRef(Card));
