import { InputAdornment, TextField } from "@mui/material";
import { CloseIcon } from "components/common/icons";
import { SearchIcon } from "icons/svg/SearchIcon";

interface ISearchFieldProps {
  searchTerm: string;
  setSearchTerm: (value: string) => void;
  placeholder: string;
}

export const SearchField = ({ searchTerm, setSearchTerm, placeholder }: ISearchFieldProps) => {
  return (
    <TextField
      value={searchTerm}
      onChange={(event) => setSearchTerm(event.target.value)}
      placeholder={placeholder}
      autoFocus
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon sx={{ fontSize: "18px" }} />
          </InputAdornment>
        ),
        ...(searchTerm?.length > 0 && {
          endAdornment: (
            <InputAdornment position="end">
              <CloseIcon
                onClick={() => {
                  setSearchTerm("");
                }}
                sx={{ cursor: "pointer", fontSize: "18px" }}
              />
            </InputAdornment>
          ),
        }),
      }}
      fullWidth
    />
  );
};
