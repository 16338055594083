import classNames from "classnames";
import { capitalize } from "lodash";
import { FunctionComponent } from "react";
import styles from "./Spinner.module.scss";

interface ISpinnerProps {
  color?: "blue" | "white";
  size?: number;
  className?: string;
  withSpaceAround?: boolean;
}

export const Spinner: FunctionComponent<ISpinnerProps> = ({
  color = "blue",
  size = 16,
  className,
  withSpaceAround,
}) => (
  <svg
    className={classNames(styles.spinnerBlue, className, { [styles.withSpaceAround]: withSpaceAround })}
    viewBox="0 0 50 50"
    height={size}
    width={size}
  >
    <circle className={styles.spinnerBlueCircle} cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
  </svg>
);
