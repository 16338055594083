import CloseIcon from "@mui/icons-material/Close";
import { Box, Divider, Drawer, IconButton, Tooltip } from "@mui/material";
import { Spinner } from "components/common/Spinner/Spinner.tsx";
import { Suspense, lazy } from "react";
import { BatchBundle } from "../../../BatchBundle/BatchBundle";

// const BatchStatsOutlinedPromise = import("../../../BatchStats/BatchStatsOutlined.tsx");
// const BatchStatsOutlinedLazy = lazy(() => BatchStatsOutlinedPromise);

const BatchStatsOutlinedLazy = lazy(() => import("../../../BatchStats/BatchStatsOutlined.tsx"));

interface IBatchBudgetsDrawerProps {
  onDrawerClose: () => void;
}

export const SubBatchesDrawer = ({ onDrawerClose }: IBatchBudgetsDrawerProps) => {
  return (
    <Drawer anchor="left" open onClose={onDrawerClose}>
      <Box sx={{ padding: "50px 20px", position: "relative", minWidth: "500px" }}>
        <Tooltip arrow title="Close">
          <IconButton onClick={onDrawerClose} sx={{ position: "absolute", top: "5px", right: "5px", zIndex: 1 }}>
            <CloseIcon sx={{ fontSize: "20px" }} />
          </IconButton>
        </Tooltip>

        <BatchBundle onDrawerClose={onDrawerClose} />

        <Divider sx={{ margin: "10px 0px" }} />

        <Suspense fallback={<Spinner />}>
          <BatchStatsOutlinedLazy />
        </Suspense>
      </Box>
    </Drawer>
  );
};
