import { IVBillMultiSelectAutocompleteSuggestion } from "components/pages/common/VBill/VBillSuggestionsAutocomplete/VBillSuggestionsMultiSelectAutocomplete/VBillSuggestionsMultiSelectAutocomplete";
import {
  TVBillSuggestionsMultiSelectAutocompleteConditionalComp,
  VBillSuggestionsMultiSelectAutocompleteComp,
} from "components/pages/common/VBill/VBillSuggestionsAutocomplete/VBillSuggestionsMultiSelectAutocomplete/VBillSuggestionsMultiSelectAutocompleteComp";
import { sortBy } from "lodash";
import { observer } from "mobx-react";
import React, { useEffect, useMemo, useState } from "react";
import { useStore } from "storeContainer";

type IBatchMembersDialogProps = TVBillSuggestionsMultiSelectAutocompleteConditionalComp & {
  onDialogClose: () => void;
  onMembersSave: (memberIds: string[]) => Promise<void>;
  selectedMembers: string[];
  actionBtnsSmallHeight?: boolean;
};

export const BatchMembersDialog = observer(function BatchMembersDialog({
  onDialogClose,
  onMembersSave,
  selectedMembers,
  actionBtnsSmallHeight,
  ...props
}: IBatchMembersDialogProps) {
  const orgUserStore = useStore("OrganizationUsersStore");
  const sessionStore = useStore("SessionStore");
  const { organizationUsersList } = orgUserStore;
  const allMembers = useMemo(
    () =>
      sortBy(organizationUsersList.data ?? [], (orgUser) => orgUser.account?.name ?? "").reduce<
        IVBillMultiSelectAutocompleteSuggestion[]
      >((result, orgUser) => {
        if (orgUser.account?.id !== sessionStore.session.data?.account?.id) {
          result = [
            ...result,
            {
              name: orgUser.account?.name ?? "",
              id: orgUser.id,
            },
          ];
        }

        return result;
      }, []),
    [organizationUsersList.data, sessionStore?.session?.data?.account?.id],
  );
  const [autocompleteSuggestions, setAutocompleteSuggestions] = useState<IVBillMultiSelectAutocompleteSuggestion[]>([]);

  useEffect(() => {
    const suggestionsSelectedFirst = sortBy(
      allMembers.map((member) => ({ ...member, selected: selectedMembers.includes(member.id) })),
      (member) => !member.selected,
    );

    setAutocompleteSuggestions(suggestionsSelectedFirst);
  }, [selectedMembers, allMembers]);

  const handleAutocompleteOptionChange = (option: IVBillMultiSelectAutocompleteSuggestion, value: boolean) => {
    setAutocompleteSuggestions((prev) =>
      prev.map((suggestion) => (suggestion.id === option.id ? { ...suggestion, selected: value } : suggestion)),
    );
  };

  const handleSaveDialog = async () => {
    await onMembersSave(autocompleteSuggestions.filter(({ selected }) => selected).map(({ id }) => id));
  };

  return (
    <VBillSuggestionsMultiSelectAutocompleteComp
      title="Select members"
      suggestions={autocompleteSuggestions}
      onSuggestionChange={handleAutocompleteOptionChange}
      onClose={onDialogClose}
      onSave={handleSaveDialog}
      searchPlaceholder="Search members"
      actionBtnsSmallHeight={actionBtnsSmallHeight}
      {...props}
    />
  );
});
