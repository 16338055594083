import InfoIcon from "@mui/icons-material/Info";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import * as React from "react";
import { useState } from "react";
import { IAclPermissionRow, IPermissionGroupWithSettings } from "storesMobx/AclStore";
import { AclEntityRow } from "./AclEntityRow";
import { HtmlTooltip } from "./AclInheritedPermissions";
import { AclOnChangeHanddler, StyledTableCell } from "./Permissions";

export const AclPermissionCategory = ({
  onChange,
  pg,
  inheritedPermissions,
}: {
  onChange: AclOnChangeHanddler;
  pg: IPermissionGroupWithSettings[];
  inheritedPermissions: IAclPermissionRow[];
}) => {
  const [expanded, setExpanded] = useState<{ [entyty_id: string]: true | undefined }>({});
  const expandId = (entityId: string) => setExpanded({ ...expanded, [entityId]: true });
  const colapseId = (entityId: string) => setExpanded({ ...expanded, [entityId]: undefined });
  const areParentsExpanded = (parentsEntityId: string[]) => parentsEntityId.reduce((p, c) => p && !!expanded[c], true);
  const expandAll = () => {
    const expanded: { [entyty_id: string]: true | undefined } = {};
    inheritedPermissions.forEach((p) => (expanded[p.id] = true));
    setExpanded(expanded);
  };

  React.useEffect(expandAll, [inheritedPermissions]);

  return (
    <TableContainer component={Paper}>
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>
              <Box sx={{ mr: 1 }}>Organization</Box>
            </StyledTableCell>
            {pg.map((permGroup) => (
              <StyledTableCell>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: permGroup.internal_alias === "BANK_ACCOUNT_CHECK_CREATE" ? "left" : "center",
                  }}
                >
                  <Box sx={{ mr: 1 }}>{permGroup.group_name}</Box>
                  <HtmlTooltip
                    title={
                      <React.Fragment>
                        <Box sx={{ display: "flex", flexDirection: "column", gap: 1, p: 1 }}>
                          {permGroup.permission_types?.map((pt) => (
                            <Typography variant="body2" sx={{ fontWeight: 500, color: "#707070" }}>
                              {pt?.description}
                            </Typography>
                          ))}
                        </Box>
                      </React.Fragment>
                    }
                  >
                    <InfoIcon fontSize={"small"} sx={{ color: "#707070" }} />
                  </HtmlTooltip>
                </Box>
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {inheritedPermissions
            .map((row, rowIdx) =>
              areParentsExpanded(row.parents) ? (
                <AclEntityRow
                  {...{
                    key: `${row.id}-${rowIdx}`,
                    row,
                    rowIdx,
                    pg,
                    onChange,
                    expanded,
                    expandId,
                    colapseId,
                  }}
                />
              ) : null,
            )
            .filter((e) => !!e)}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
