import { Box, Table, TableBody, TableContainer, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { useFieldArray } from "react-hook-form";
import { useStore } from "storeContainer";
import { COLORS } from "themes/default";
import { VBillLineItemsHead } from "../../common/VBillLineItemsHead/VBillLineItemsHead";
import { TVBillFormFields } from "../../types";
import { NewLineItem } from "./NewLineItem/NewLineItem";

export const NewLineItems = observer(() => {
  const vBillStore = useStore("VBillStore");
  const { invoice } = vBillStore;
  const invoiceData = useMemo(() => invoice.data, [invoice.data]);
  const { fields } = useFieldArray<TVBillFormFields>({ name: "lineItems" });

  if (!invoiceData?.hasLineItems) {
    return null;
  }

  return (
    <>
      <Typography sx={{ fontSize: 16, fontWeight: 500, color: COLORS.logoBlue, marginBottom: "15px" }}>
        ITEMS{" "}
        <Box component="strong" sx={{ fontSize: "14px", fontWeight: 600 }}>
          ({fields.length})
        </Box>
      </Typography>

      <TableContainer sx={{ marginBottom: "20px" }}>
        <Table>
          <VBillLineItemsHead />

          <TableBody>
            {fields.map((field, index) => (
              <NewLineItem key={field.id} index={index} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
});
