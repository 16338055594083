import AddIcon from "@mui/icons-material/Add";
import { AlertColor, Box } from "@mui/material";

import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Snackbar from "@mui/material/Snackbar";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TextField from "@mui/material/TextField";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { AccessRestricted } from "components/pages/AccessRestricted";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { useParams } from "react-router-dom";
import { definitelyFilter } from "../../../../generated/utils";
import { useStore } from "../../../../storeContainer";
import Card from "../../../common/Card";
import { Spinner } from "../../../common/Spinner/Spinner";
import Table from "../../../common/Table";
import { a11yProps, TabPanel, useGroupsColumns, useUsersColumns } from "./utils";

import styles from "./Users.module.scss";

const Users = () => {
  const history = useHistory();
  const location = useLocation();
  const uiStore = useStore("UiStore");
  const userGroupsStore = useStore("UserGroupsStore");
  const organizationUsersStore = useStore("OrganizationUsersStore");
  const { organizationUsersList } = organizationUsersStore;
  const { userGroupsList } = userGroupsStore;
  const {
    acl: { canManageUsers },
  } = useStore("SessionStore");
  const { organizationId } = useParams<{ organizationId?: string }>();

  const path = `/org/${organizationId}`;
  const userColumns = useUsersColumns(history.location.pathname);
  const groupColumns = useGroupsColumns(history.location.pathname);
  const [value, setValue] = useState(location.pathname.includes("groups") ? 1 : 0);
  const [filteredGroupsData, setFilteredGroupsData] = useState<any>(); //TODO: Fix type
  //Add Group modal
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [description, setDescription] = useState("");
  const [groupIsAdding, setGroupIsAdding] = useState(false);
  const handleModalClose = () => setIsModalOpened(false);
  const handleModalOpen = () => setIsModalOpened(true);

  //Add Group Snackbar
  const [openAddGroupSnackbar, setOpenAddGroupSnackbar] = useState(false);
  const [snackbarText, setSnackbarText] = useState("Added group successfully");
  const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor | undefined>("success");

  //Filtering
  const [userSearchTerm, setUserSearchTerm] = useState("");
  const [groupSearchTerm, setGroupSearchTerm] = useState("");
  const [groupFilter, setGroupFilter] = useState("All");
  const [statusFilter, setStatusFilter] = useState("All");

  //TODO: Fix types
  const handleChange = (_event: any, newValue: any) => {
    setValue(newValue);
  };
  const loadUsers = (forceUpdate = false) => {
    organizationUsersStore.loadOrganizationUsers(forceUpdate);
  };

  const loadGroups = async (forceUpdate = false) => {
    await userGroupsStore.loadUserGroups(forceUpdate);
  };

  useEffect(() => {
    // don't trigger any requests if user doesn't have access to this page
    if (canManageUsers) {
      loadUsers();
      loadGroups();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFilteredGroupsData(
      userGroupsList?.data?.filter((val) => {
        if (groupSearchTerm === "") {
          return val;
        } else if (val?.name?.toLowerCase().includes(groupSearchTerm.toLowerCase())) {
          return val;
        }
        return null;
      }),
    );
  }, [groupSearchTerm, userGroupsList.data]);

  if (!canManageUsers) {
    return <AccessRestricted />;
  }

  const usersData = organizationUsersStore.getOrganizationUsersAccounts();

  const groupsList = userGroupsList.data?.map((group) => ({
    label: group?.name,
    value: group?.id,
  }));
  groupsList?.unshift({ label: "All", value: "ALL" });

  const statusList = [
    { label: "All", value: "ALL" },
    { label: "Active", value: "ACTIVE" },
    { label: "Invited", value: "INVITED" },
  ];

  const addFiltersToUrl = (event: any, type: string) => {
    const url = `${path}/${type}`;
    const query = event.target.value.replace(" ", "+");
    if (history.location.pathname.includes("?")) {
      history.push(`${url}/&${event.target.name}=${query}`);
    } else {
      history.push(`${url}/?${event.target.name}=${query}`);
    }
  };

  const filteredUsersData = definitelyFilter(usersData)
    .filter((val) => {
      if (groupFilter === "All") {
        return val;
      } else if (val?.user_groups?.some((group) => group?.id === groupFilter)) {
        return val;
      }
      return null;
    })
    .filter((val) => {
      if (statusFilter === "All") {
        return val;
      } else if (val?.account?.state === statusFilter) {
        return val;
      }
      return null;
    })
    .filter((val) => {
      if (userSearchTerm === "") {
        return val;
      } else if (
        val?.account?.name?.toLowerCase().includes(userSearchTerm.toLowerCase()) ||
        val?.account?.email?.toLowerCase().includes(userSearchTerm.toLowerCase())
      ) {
        return val;
      }
      return null;
    });

  const handleAddGroupButton = async () => {
    try {
      setGroupIsAdding(true);
      const response = await userGroupsStore.createUserGroup(name, description);
      setGroupIsAdding(false);
      history.push(`${path}/groups/${response?.CreateUserGroup?.id}`);
      setName("");
      setDescription("");
      setSnackbarText("Added group successfully");
      setSnackbarSeverity("success");
      loadGroups(true);
      handleModalClose();
      setOpenAddGroupSnackbar(true);
    } catch (e: any) {
      setNameError(e.response.errors?.message);
      setSnackbarText(e.response.errors?.message);
      setSnackbarSeverity("error");
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Card>
        <>
          <Tabs value={value} onChange={handleChange} textColor="primary" indicatorColor="primary">
            <Tab label="Users" onClick={() => history.push(`${path}/users`)} {...a11yProps(0)} />
            <Tab label="Groups" onClick={() => history.push(`${path}/groups`)} {...a11yProps(1)} />
          </Tabs>

          <TabPanel value={value} index={0}>
            <Grid container sx={{ margin: "20px 0" }} alignItems={"center"} justifyContent={"space-between"}>
              <Grid item>
                <TextField
                  id="search"
                  label="Search"
                  name="search"
                  variant="outlined"
                  sx={{ marginRight: "10px" }}
                  value={userSearchTerm || ""}
                  onChange={(e) => {
                    addFiltersToUrl(e, "users");
                    setUserSearchTerm(e.target.value);
                  }}
                />
                <FormControl className={styles.form}>
                  <InputLabel id="group-select-label">Group:</InputLabel>
                  <Select
                    defaultValue={"All"}
                    labelId="group-select-label"
                    id="group-select"
                    label="group:"
                    name="group"
                    value={groupFilter}
                    onChange={(e: any) => {
                      setGroupFilter(e.target.value);
                      addFiltersToUrl(e, "users");
                    }}
                  >
                    {groupsList?.map((group) => {
                      if (group.value) {
                        return <MenuItem value={group.value}>{group.label}</MenuItem>;
                      }
                      return null;
                    })}
                  </Select>
                </FormControl>
                <FormControl className={styles.formStatus}>
                  <InputLabel id="status-select-label">Status:</InputLabel>
                  <Select
                    defaultValue="ALL"
                    labelId="status-select-label"
                    id="status-select"
                    label="status:"
                    name="status"
                    value={statusFilter}
                    onChange={(e: any) => {
                      setStatusFilter(e.target.value);
                      addFiltersToUrl(e, "users");
                    }}
                  >
                    {statusList?.map((status) => {
                      if (status.value) {
                        return <MenuItem value={status.value}>{status.label}</MenuItem>;
                      }
                      return null;
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <Button
                  variant={"contained"}
                  startIcon={<AddIcon />}
                  onClick={() => (uiStore.showInviteUserModal = true)}
                >
                  Invite user
                </Button>
              </Grid>
            </Grid>
            {organizationUsersList.isFetching ? (
              <Spinner withSpaceAround />
            ) : organizationUsersList.data ? (
              <Table columns={userColumns} data={filteredUsersData.filter((account) => account.account !== null)} />
            ) : null}
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Grid container sx={{ margin: "20px 0" }} alignItems={"center"} justifyContent={"space-between"}>
              <TextField
                id="search"
                label="Search"
                name="search"
                variant="outlined"
                sx={{ marginRight: "10px" }}
                value={groupSearchTerm || ""}
                onChange={(e) => {
                  addFiltersToUrl(e, "groups");
                  setGroupSearchTerm(e.target.value);
                }}
              />
              <Button variant={"contained"} startIcon={<AddIcon />} onClick={handleModalOpen}>
                Add group
              </Button>
            </Grid>
            {userGroupsList.isFetching ? (
              <Spinner withSpaceAround />
            ) : userGroupsList.data ? (
              <Table columns={groupColumns} data={definitelyFilter(filteredGroupsData)} />
            ) : null}
          </TabPanel>
        </>
      </Card>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={openAddGroupSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenAddGroupSnackbar(false)}
      >
        <Alert onClose={() => setOpenAddGroupSnackbar(false)} severity={snackbarSeverity} sx={{ width: "100%" }}>
          {snackbarText}
        </Alert>
      </Snackbar>
      <Dialog
        open={isModalOpened}
        onClose={handleModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Add group</DialogTitle>
        <DialogContent>
          <Grid container direction={"column"} sx={{ width: "400px" }}>
            <DialogContentText>Please enter group name</DialogContentText>
            <TextField
              id="textfield-edit-organization"
              label="Group name"
              variant="outlined"
              sx={{ margin: "10px 0" }}
              value={name || ""}
              onChange={(event) => setName(event.target.value)}
              error={nameError !== ""}
              helperText={nameError}
            />
            <DialogContentText>Please enter group description</DialogContentText>

            <TextField
              id="textfield-edit-organization"
              label="Group description"
              variant="outlined"
              sx={{ margin: "10px 0" }}
              value={description || ""}
              onChange={(event) => setDescription(event.target.value)}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModalClose}>Cancel</Button>
          {!groupIsAdding ? (
            <Button onClick={handleAddGroupButton}>Add</Button>
          ) : (
            <Button>
              <Spinner />
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default observer(Users);
