import ClearIcon from "@mui/icons-material/Clear";
import {
  Box,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { processJsDateToIsoOnMidnight } from "common/helpers/dates";
import { getVBillFormattedDate } from "components/pages/common/VBill/utils";
import { isValid } from "date-fns";
import { isNil } from "lodash";
import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { ControllerProps, useController, useFormContext } from "react-hook-form";
import { COLORS } from "themes/default";
import { TVBillFormFields } from "../../../types";

interface IFormInputDateProps {
  name: keyof TVBillFormFields;
  rules?: ControllerProps<TVBillFormFields>["rules"];
  insideForm?: boolean;
  label: string;
  pushedTop?: boolean;
}

export const FormInputDate: FunctionComponent<IFormInputDateProps> = ({
  name,
  rules,
  insideForm,
  label,
  pushedTop,
}) => {
  const formMethods = useFormContext<TVBillFormFields>();
  const {
    field: { value: formValue, onChange: formOnChange },
    fieldState: { error },
  } = useController<TVBillFormFields>({
    name,
    rules,
  });
  const [localMuiDate, setLocalMuiDate] = useState<Date | null>(null);
  const [localMuiTextFieldValue, setLocalMuiTextFieldValue] = useState("");
  const staticRequiredField = useMemo(() => Object.keys(rules ?? {}).includes("required"), []);

  const handleClearBtnClick = () => {
    setLocalMuiDate(null);
    formOnChange("");
  };

  useEffect(() => {
    if (localMuiTextFieldValue.length < 10 && localMuiTextFieldValue.length > 0) {
      formMethods.setError(name, { type: "custom", message: "Invalid Date" });
      return;
    }

    if (!isValid(localMuiDate)) {
      formMethods.setError(name, { type: "custom", message: "Invalid Date" });
      return;
    }

    const fieldValue = processJsDateToIsoOnMidnight((localMuiDate ?? new Date()).toString());

    formOnChange(fieldValue);
    formMethods.clearErrors(name);
  }, [localMuiTextFieldValue, localMuiDate]);

  useEffect(() => {
    if (isNil(formValue)) {
      return;
    }

    if ((formValue as string).length === 0) {
      setLocalMuiDate(null);
    } else {
      setLocalMuiDate(new Date(formValue as string));
    }
  }, [formValue]);

  const hasFieldError = !!error ? true : !isValid(localMuiDate);

  return (
    <Box sx={{ ...(pushedTop ? { marginBottom: "10px" } : {}) }}>
      <Typography sx={{ color: COLORS.textGray, marginBottom: "5px" }}>
        {label}
        {insideForm && staticRequiredField && (
          <Tooltip arrow title={rules?.["required"]}>
            <Box component="span" sx={{ fontSize: "16px", color: COLORS.error, marginLeft: "2px" }}>
              *
            </Box>
          </Tooltip>
        )}
      </Typography>

      {insideForm ? (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            value={localMuiDate}
            onChange={setLocalMuiDate}
            renderInput={(params) => {
              setLocalMuiTextFieldValue((params?.inputProps?.value as string) ?? "");

              return (
                <TextField
                  {...params}
                  size="small"
                  sx={{ width: "100%" }}
                  InputProps={{
                    sx: { height: "32px" },
                    ...params?.InputProps,
                    ...(!hasFieldError && {
                      endAdornment: (
                        <InputAdornment position="end">
                          <Tooltip title="Clear" arrow>
                            <IconButton onClick={handleClearBtnClick} sx={{ marginRight: "-12px" }}>
                              <ClearIcon />
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }),
                  }}
                  error={!!error}
                />
              );
            }}
          />
        </LocalizationProvider>
      ) : (
        <Typography>{getVBillFormattedDate(formValue as string)}</Typography>
      )}

      {hasFieldError && error && error.type !== "required" && (
        <FormControl error fullWidth>
          <FormHelperText sx={{ margin: 0, lineHeight: "15px" }}>{error.message}</FormHelperText>
        </FormControl>
      )}
    </Box>
  );
};
