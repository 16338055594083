import { observer } from "mobx-react-lite";
import { AlertColor } from "@mui/material";

import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import Paper from "@mui/material/Paper";
import Snackbar from "@mui/material/Snackbar";
import Typography from "@mui/material/Typography";

import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";

import { COLORS } from "../../../../../../themes/default";
import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import { useStore } from "../../../../../../storeContainer";
import { LoadingButton } from "@mui/lab";
import { Permissions2UtilEntity, Permissions2UtilUserResolver } from "./Permisssion2Utils";
import { Link } from "react-router-dom";

const drawerWidth = 460;

export const AddGroupToUserView: React.FunctionComponent<{
  group: any | undefined;
  onClose: () => void;
  addGroupToUser: (user: any) => void;
  removeGroupFromUser: (user: any) => void;
  users: any[];
  error: string | undefined;
}> = observer(function AddGroupToUserView({ group, onClose, addGroupToUser, removeGroupFromUser, users, error }) {
  const aclStore2 = useStore("AclStore2");

  const [searchKeywords, setSearchKeywords] = useState("");
  const [loadingUser, setLoadingUser] = useState<any | undefined>(undefined);
  const { organizationUsersList } = aclStore2;

  useEffect(() => {
    setTimeout(() => {
      setLoadingUser(undefined);
    }, 1000); //The browser might take too long to execute users iteration
  }, [users, error]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          textAlign: "center",
          gap: 2,
        }}
      >
        <IconButton
          size={"small"}
          color={"primary"}
          sx={{
            border: 1,
            borderColor: COLORS.logoBlue,
          }}
          onClick={onClose}
        >
          <ArrowBackIosRoundedIcon />
        </IconButton>
        <Typography variant={"h6"} sx={{ my: 1, fontSize: "18px", fontWeight: 500 }}>
          Add user
        </Typography>
      </Box>

      <Card
        variant={"outlined"}
        component="form"
        sx={{ my: 3, p: 1, display: "flex", alignItems: "center", width: 260 }}
      >
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Search"
          value={searchKeywords}
          onChange={(event) => {
            setSearchKeywords(event.target.value);
          }}
        />
      </Card>

      <Divider sx={{ borderColor: COLORS.borderColor }} />

      {organizationUsersList
        .filter((user) => {
          if (searchKeywords.length === 0) return true;
          if (user.name) {
            return user.name?.search(new RegExp(searchKeywords, "i")) > -1;
          }

          return false;
        })
        .filter((u) => u.state !== "UNVALIDATED")
        .map((user, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{ p: 1, display: "flex", justifyContent: "space-between", alignItems: "center", borderRadius: "5px" }}
            >
              <Typography variant={"body1"} sx={{ fontWeight: 500, color: COLORS.logoBlue }}>
                {user.name}
              </Typography>
              {user.user_groups?.find((_group) => _group.id === group?.id) !== undefined ? (
                <LoadingButton
                  loading={loadingUser?.id === user.id}
                  onClick={() => {
                    setLoadingUser(user);
                    removeGroupFromUser(user);
                  }}
                  sx={{ textTransform: "none", fontWeight: 400 }}
                  variant={"text"}
                  size={"small"}
                  color={"warning"}
                  disabled={loadingUser !== undefined}
                >
                  Remove
                </LoadingButton>
              ) : (
                <LoadingButton
                  loading={loadingUser?.id === user.id}
                  onClick={() => {
                    setLoadingUser(user);
                    addGroupToUser(user);
                  }}
                  sx={{ textTransform: "none", fontWeight: 400 }}
                  variant={"outlined"}
                  size={"small"}
                  color={"secondary"}
                  disabled={loadingUser !== undefined}
                >
                  Add
                </LoadingButton>
              )}
            </Box>
            <Divider sx={{ borderColor: COLORS.borderColor }} />
          </Box>
        ))}
    </>
  );
});

export const SummaryGroupView: React.FunctionComponent<{
  group: any | undefined;
  onAddUserClicked: () => void;
  removeGroupFromUser: (user: any) => void;
  users: any[];
  error: string | undefined;
}> = observer(function SummaryGroupView({ group, onAddUserClicked, removeGroupFromUser, users, error }) {
  const [loadingUser, setLoadingUser] = useState<any | undefined>(undefined);
  const { selectedOrganizationId } = useStore("ContextStore");

  const aclStore2 = useStore("AclStore2");
  const directPermissionsList = group?.id
    ? aclStore2.directPermissionsList.filter((dp) => !dp.isUser && dp.user_id === group?.id)
    : [];

  useEffect(() => {
    setLoadingUser(undefined);
  }, [users, error]);

  return (
    <>
      <Typography variant={"body1"} sx={{ fontWeight: 500 }} color={"primary"}>
        User Group:
      </Typography>
      <Typography variant={"h6"} sx={{ my: 1, fontSize: "20px", fontWeight: 600 }}>
        {group?.name}
      </Typography>

      <Link
        style={{ textDecoration: "none" }}
        to={`/org/${selectedOrganizationId}/users2/permissions/group/${group?.id}`}
      >
        <Button sx={{ mt: 3, textTransform: "none" }} variant={"outlined"} color={"secondary"}>
          View user group permissions
        </Button>
      </Link>
      {/* Pemrmissions */}
      <>
        <Typography sx={{ mt: 3, mb: 1 }} color={"primary"} variant={"body2"}>
          Permissions:
        </Typography>
        {!directPermissionsList.length ? (
          <Typography sx={{ mt: 3, mb: 1, pl: 1 }} color={"primary"} variant={"body2"}>
            no permissions
          </Typography>
        ) : null}
        {directPermissionsList.map((dp: any, index: number) => (
          <Box
            key={index}
            sx={{
              p: 1,
              mb: 1,
              backgroundColor: COLORS.selago,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderRadius: "5px",
            }}
          >
            <Typography variant={"body1"} sx={{ fontWeight: 500 }} color={"primary"}>
              <Permissions2UtilEntity entity_name={dp.entity_name} entity_type={dp.entity_type} />
            </Typography>
            <Link
              to={
                `/org/${selectedOrganizationId}/users2/permissions/` +
                `${false ? "user" : "group"}/${group?.id}/` +
                `${dp.entity_type.toLocaleLowerCase()}/${dp.entity_id}`
              }
              style={{ textDecoration: "none" }}
            >
              <LoadingButton
                sx={{ textTransform: "none", fontWeight: 400 }}
                variant={"text"}
                size={"small"}
                color={"warning"}
              >
                Edit
              </LoadingButton>
            </Link>
          </Box>
        ))}
        <Link
          to={`/org/${selectedOrganizationId}/users2/permissions/add/group/${group?.id}`}
          style={{ textDecoration: "none" }}
        >
          <Button sx={{ textTransform: "none", mt: 1 }} color={"secondary"} variant={"text"} startIcon={<AddIcon />}>
            Add Permission
          </Button>
        </Link>
        {/* <pre>{JSON.stringify(toJS(directPermissionsList), null, 2)}</pre> */}
      </>
      <>
        <Typography sx={{ mt: 3, mb: 1 }} color={"primary"} variant={"body2"}>
          Users:
        </Typography>
        {!users.length ? (
          <Typography sx={{ mt: 3, mb: 1, pl: 1 }} color={"primary"} variant={"body2"}>
            no users
          </Typography>
        ) : null}
        {users.map((user, index) => (
          <Box
            key={index}
            sx={{
              p: 1,
              mb: 1,
              backgroundColor: COLORS.selago,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderRadius: "5px",
            }}
          >
            <Typography variant={"body1"} sx={{ fontWeight: 500 }} color={"primary"}>
              <Permissions2UtilUserResolver isUser={true} user_id={user.id} />
            </Typography>
            <LoadingButton
              loading={loadingUser?.id === user.id}
              onClick={() => {
                setLoadingUser(user);
                removeGroupFromUser(user);
              }}
              sx={{ textTransform: "none", fontWeight: 400 }}
              variant={"text"}
              size={"small"}
              color={"warning"}
              disabled={loadingUser !== undefined}
            >
              Remove
            </LoadingButton>
          </Box>
        ))}
        <Button
          sx={{ textTransform: "none", mt: 1 }}
          color={"secondary"}
          onClick={onAddUserClicked}
          variant={"text"}
          startIcon={<AddIcon />}
        >
          Add User
        </Button>
      </>
    </>
  );
});

export const GroupDetailsModal: React.FunctionComponent<{ onClose: () => void; group: any | undefined }> = observer(
  function GroupDetailsModal({ onClose, group }) {
    const userGroupsStore = useStore("UserGroupsStore");

    const [showAddGroupToUser, setShowAddGroupToUser] = useState(false);

    const [users, setUsers] = useState<any[]>([]);

    const [error, setError] = useState<string | undefined>(undefined);

    //Snackbar
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarText, setSnackbarText] = useState("Test");
    const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor | undefined>("success");

    const toggleAddUser = () => {
      setShowAddGroupToUser(!showAddGroupToUser);
    };

    const onAddGroupToUser = async (user: any) => {
      try {
        await userGroupsStore.addUserToGroup(group.id || "", user?.id || "");
        setUsers((oldUsers) => [...oldUsers, user]);
        setOpenSnackbar(true);
        setSnackbarSeverity("success");
        setSnackbarText("User added successfully");
      } catch (e: any) {
        setError(e.response.errors?.message);
        setUsers((oldUsers) => [...oldUsers, user]);
        setSnackbarText(e.response.errors?.message);
        setSnackbarSeverity("error");
        setOpenSnackbar(true);
      }
    };

    const onRemoveGroupFromUser = async (user: any) => {
      try {
        await userGroupsStore.removeUserFromGroup(group.id || "", user?.id || "");
        setUsers(users.filter((_user) => _user.id !== user.id));
        setOpenSnackbar(true);
        setSnackbarSeverity("success");
        setSnackbarText("User removed successfully");
      } catch (e: any) {
        setError(e.response.errors?.message);
        setSnackbarText(e.response.errors?.message);
        setSnackbarSeverity("error");
        setOpenSnackbar(true);
      }
    };

    useEffect(() => {
      setShowAddGroupToUser(false);
      setUsers(group?.organization_users || []);
    }, [group]);

    return (
      <Drawer
        anchor={"right"}
        open={group !== undefined}
        onClose={onClose}
        sx={{
          display: { xs: "none", sm: "block" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between", flexDirection: "column", height: "100%" }}>
          <Box sx={{ p: 2, flexDirection: "column" }}>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Box>
            {showAddGroupToUser ? (
              <AddGroupToUserView
                onClose={toggleAddUser}
                addGroupToUser={onAddGroupToUser}
                removeGroupFromUser={onRemoveGroupFromUser}
                group={group}
                users={users}
                error={error}
              />
            ) : (
              <SummaryGroupView
                onAddUserClicked={toggleAddUser}
                removeGroupFromUser={onRemoveGroupFromUser}
                group={group}
                users={users}
                error={error}
              />
            )}
          </Box>
          <Paper sx={{ display: "flex", justifyContent: "flex-end", gap: 2, p: 2, backgroundColor: COLORS.selago }}>
            <Button onClick={onClose} variant={"outlined"}>
              Close
            </Button>
            {/* <Button onClick={onSave} variant={"contained"} color={"secondary"} endIcon={<NavigateNextIcon />}>
              Save
            </Button> */}
          </Paper>

          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={openSnackbar}
            autoHideDuration={3000}
            onClose={() => setOpenSnackbar(false)}
          >
            <Alert onClose={() => setOpenSnackbar(false)} severity={snackbarSeverity} sx={{ width: "100%" }}>
              {snackbarText}
            </Alert>
          </Snackbar>
        </Box>
      </Drawer>
    );
  },
);
