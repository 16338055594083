import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import Box from "components/common/Box";
import Paper from "components/common/Paper";
import Table from "components/common/Table";
import useColumns from "./useColumns";
import { useStore } from "storeContainer";
import { Spinner } from "components/common/Spinner/Spinner";
import { BankAccountAutoDepositAddress } from "generated/sdk";

const DepositAddresses = ({
  addresses,
  className,
  error,
  isReadOnly,
  autoDepositAddressLoading,
  setAutoDepositAddressesLoading,
  fetchAutoDepositAvailableAddresses,
  fetchAutoDepositAddresses,
}: {
  addresses: BankAccountAutoDepositAddress[] | null | undefined;
  className?: string;
  error: any | null;
  loading: boolean;
  isReadOnly: boolean;
  autoDepositAddressLoading: boolean;
  fetchAutoDepositAvailableAddresses: Function;
  fetchAutoDepositAddresses: Function;
  setAutoDepositAddressesLoading: Function;
}) => {
  const organizationUsersStore = useStore("OrganizationUsersStore");
  const [tableIsLoading, setTableIsLoading] = useState(true);
  const [addressesWithUserId, setAddressesWithUserId] = useState<any[]>([]);
  const { organizationUsersList } = organizationUsersStore;

  const fetchOrganizationUsers = async () => {
    let addressesWithUserIdVar: BankAccountAutoDepositAddress & { userId: string }[] = [];
    //!TEMPORARILY DISABLED until implementation of link to org user -> if (acl.canManageUsers) await organizationUsersStore.loadOrganizationUsers();
    organizationUsersList.data?.forEach((value) => {
      addresses?.forEach((address: any) => {
        if (value?.account?.email === address.email) addressesWithUserIdVar.push({ ...address, userId: value?.id });
      });
    });
    setAddressesWithUserId(addressesWithUserIdVar);
    setTableIsLoading(false);
  };

  const tableColumns = useColumns(
    setAutoDepositAddressesLoading,
    fetchAutoDepositAvailableAddresses,
    fetchAutoDepositAddresses,
    isReadOnly,
  );

  useEffect(() => {
    error && console.error("error:", error);
  }, [error]);

  useEffect(() => {
    fetchOrganizationUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addresses]);

  return (
    <Box className={className} width="100%">
      <Paper>
        <Box sx={{ px: 2, py: 2.5 }}>
          * When a Check is sent to an Email Address listed here, It will be Auto-Deposited to this Bank Account.
        </Box>
        {!tableIsLoading && !autoDepositAddressLoading ? (
          <Table columns={tableColumns} data={addressesWithUserId.length ? addressesWithUserId : addresses || []} />
        ) : (
          <Box>
            <Spinner />
          </Box>
        )}
      </Paper>
    </Box>
  );
};

export default observer(DepositAddresses);
