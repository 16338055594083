import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClearIcon from "@mui/icons-material/Clear";
import CloseIcon from "@mui/icons-material/Close";
import { Backdrop, Box, Chip, IconButton, InputAdornment, Menu, TextField, Tooltip, Typography } from "@mui/material";
import { ExtendedCheckFiltersType1, useCheckFilters } from "common/hooks/useCheckFilters";
import { LedgerFilterInputType, getFiltersConfig } from "components/Filter/types";
import { SearchIcon } from "icons/svg/SearchIcon";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useParams, useRouteMatch } from "react-router-dom";
import { AdvancedSearchInput } from "./AdvancedSearchInput";

const closeIconStyle = {
  height: "16x",
  width: "16px",
  cursor: "pointer",
};

const SearchInput = () => {
  const { filters, setFilters, setPagination } = useCheckFilters<ExtendedCheckFiltersType1>();

  const { selectedQuickFilter } = useParams<{ selectedQuickFilter: string }>();
  const { path } = useRouteMatch();
  const formConfig = getFiltersConfig(path, selectedQuickFilter)?.[LedgerFilterInputType.AdvancedSearchFilter];
  const keyName = formConfig?.keyName as string;
  const keyNameInput = formConfig?.keyNameInput as string;
  const keyNameOptions = formConfig?.keyNameOptions as string;
  const keyNameAdditionalInput = formConfig?.keyNameAdditionalInput as string;
  const keyNameAdditionalOptions = formConfig?.keyNameAdditionalOptions as string;

  const [searchInputValue, setSearchInputValue] = useState(filters?.[keyName]?.[keyNameInput] || "");
  const [anchorElSearchOptions, setAnchorElSearchOptions] = useState<null | HTMLElement>(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce((query?: string) => {
      setFilters((prevFilters) => {
        //TODO this is a workaround for state mismanagement async race conditions, it's better than a setTimeout and it will do for now, but this should be refactored so that filters and pagination are part of a batch state update

        setPagination((prevPagination) => ({
          ...prevPagination,
          page: 1,
        }));

        return {
          ...prevFilters,
          [keyName]: {
            [keyNameInput]: query,
            [keyNameOptions]: undefined,
            ...(prevFilters?.[keyName]?.[keyNameAdditionalInput] && {
              [keyNameAdditionalInput]: prevFilters?.[keyName]?.[keyNameAdditionalInput],
              [keyNameAdditionalOptions]: prevFilters?.[keyName]?.[keyNameAdditionalOptions],
            }),
          },
        };
      });
    }, 600),
    [],
  );

  const handleCloseIconClick = () => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [keyName]: {
        [keyNameInput]: undefined,
        [keyNameOptions]: [],
        [keyNameAdditionalInput]: undefined,
        [keyNameAdditionalOptions]: [],
      },
    }));
    setSearchInputValue("");
  };
  const handleTextFieldOnChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchInputValue(e.target.value);
    debouncedSearch(e.target.value);
  };

  const handleSearchOptionsDropdownClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElSearchOptions(event.currentTarget);
  };

  const handleOnSubmit = (formData: { [key: string]: any }) => {
    setFilters((prevFilters) => ({ ...prevFilters, [keyName]: formData }));
  };

  useEffect(() => {
    if (!filters?.[keyName]?.[keyNameInput]) {
      setSearchInputValue("");
    } else if (filters?.[keyName]?.[keyNameInput]! !== searchInputValue) {
      setSearchInputValue(filters?.[keyName]?.[keyNameInput]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters?.[keyName]?.[keyNameInput]]);

  useEffect(() => {
    if (!filters?.[keyName]?.[keyNameInput] && !!filters?.[keyName]?.[keyNameAdditionalInput]) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        [keyName]: {
          [keyNameInput]: prevFilters?.[keyName]?.[keyNameAdditionalInput],
          [keyNameOptions]: prevFilters?.[keyName]?.[keyNameAdditionalOptions],
          [keyNameAdditionalInput]: undefined,
          [keyNameAdditionalOptions]: undefined,
        },
      }));
    }
  }, [filters, keyName, keyNameAdditionalInput, keyNameAdditionalOptions, keyNameInput, keyNameOptions, setFilters]);

  return !formConfig ? null : (
    <Box display="flex" flexDirection="column">
      <Box>
        <TextField
          placeholder={"Search"}
          id="search"
          name="search"
          value={searchInputValue}
          onChange={handleTextFieldOnChange}
          title={formConfig?.tooltip}
          sx={{
            width: "265px",
          }}
          type="search"
          InputProps={{
            startAdornment: (
              <>
                <InputAdornment position="start">
                  {searchInputValue?.length > 0 ? (
                    <Tooltip title="Clear search" placement="left">
                      <CloseIcon onClick={handleCloseIconClick} sx={closeIconStyle} />
                    </Tooltip>
                  ) : (
                    <SearchIcon sx={closeIconStyle} />
                  )}
                </InputAdornment>

                {filters?.[keyName]?.[keyNameAdditionalInput] && (
                  <Tooltip
                    title={`${filters?.[keyName]?.[keyNameAdditionalInput]} (Additional search term)`}
                    placement="top"
                  >
                    <Chip
                      sx={{ borderRadius: "4px", height: "30px", marginRight: "5px", maxWidth: "100px" }}
                      variant="awesome"
                      size="small"
                      label={
                        <Typography whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis" color="#697281">
                          {filters?.[keyName]?.[keyNameAdditionalInput]}
                        </Typography>
                      }
                      onDelete={() => {
                        setFilters((prevFilters) => ({
                          ...prevFilters,
                          [keyName]: {
                            ...prevFilters?.[keyName],
                            [keyNameAdditionalInput]: undefined,
                            [keyNameAdditionalOptions]: undefined,
                          },
                        }));
                      }}
                      deleteIcon={
                        <Tooltip title="Clear additional search term" placement="right">
                          <ClearIcon sx={{ color: "black !important" }} />
                        </Tooltip>
                      }
                    />
                  </Tooltip>
                )}
              </>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleSearchOptionsDropdownClick}>
                  <Tooltip title="Toggle advanced search options" placement="top">
                    <ArrowDropDownIcon />
                  </Tooltip>
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Menu
          open={Boolean(anchorElSearchOptions)}
          anchorEl={anchorElSearchOptions}
          onClose={() => {
            setAnchorElSearchOptions(null);
          }}
          anchorOrigin={{
            vertical: "center",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: 51,
            horizontal: 271,
          }}
          slots={{ backdrop: Backdrop }}
        >
          <AdvancedSearchInput
            config={formConfig}
            initialData={filters?.[keyName]!}
            handleCloseMenu={() => {
              setAnchorElSearchOptions(null);
            }}
            handleClearClick={() => {
              if (filters[keyName]) {
                setSearchInputValue("");
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  [keyName]: {
                    [keyNameInput]: "",
                    [keyNameOptions]: [],
                    [keyNameAdditionalInput]: "",
                    [keyNameAdditionalOptions]: [],
                  },
                }));
              }
            }}
            handleOnSubmit={handleOnSubmit}
          />
        </Menu>
      </Box>
    </Box>
  );
};

export default SearchInput;
