import { Badge, BadgeProps, IconButton, styled, Tooltip } from "@mui/material";
import { IVBillReactionType, IVBillVBillGetReactionsStatsQuery } from "generated/graphql.vbill";
import { useState } from "react";
import { COLORS } from "themes/default";

interface IReactionProps {
  ButtonIcon: React.ElementType;
  stats?: NonNullable<IVBillVBillGetReactionsStatsQuery["getReactionsStats"]["groups"]>[number]["stats"][number];
  userNames?: string[];
  onIconBtnClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const Reaction = ({ ButtonIcon, userNames, stats, onIconBtnClick }: IReactionProps) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  const handleTooltipOpen = () => {
    if ((userNames ?? []).length) {
      setTooltipOpen(true);
    }
  };

  return (
    <Tooltip
      open={tooltipOpen}
      onClose={handleTooltipClose}
      onOpen={handleTooltipOpen}
      arrow
      title={<div>{userNames?.map((name) => <div>{name}</div>)}</div>}
    >
      <StyledBadge badgeContent={stats?.count} color="primary">
        <IconButton onClick={onIconBtnClick} sx={{ padding: "6px" }}>
          <ButtonIcon
            sx={{ ...(stats?.includesMyReaction ? { color: getBtnColorByStats(stats?.type) } : {}), fontSize: "14px" }}
          />
        </IconButton>
      </StyledBadge>
    </Tooltip>
  );
};

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 7,
    top: 7,
    width: 14,
    height: 14,
    borderRadius: 7,
    minWidth: 14,
    fontSize: "9px",
  },
}));

const getBtnColorByStats = (type?: IVBillReactionType | null) => {
  switch (type) {
    case IVBillReactionType.Like:
      return COLORS.success;
    case IVBillReactionType.Dislike:
      return COLORS.darkRed;
    case IVBillReactionType.Null:
      return COLORS.orange;
  }
};
