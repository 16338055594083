import { Box } from "@mui/material";
import { RemittanceRecordMetaType, VCheckCheckQueryQuery } from "generated/sdk";
import { cloneDeep, isNil, sortBy } from "lodash";
import { useMemo } from "react";
import { COLORS } from "themes/default";
import { formatCurrency, isValidHttpUrl } from "../../../../common/helpers/utils";
import { formatDateToDesign } from "../utils";
import styles from "./RelatedTransactionsTable.module.scss";

// const mock = {
//   meta: [
//     {
//       label: "_SageEntityId",
//       order: 90,
//       value: null,
//     },
//     {
//       label: "_Type",
//       order: 200,
//       value: null,
//     },
//     {
//       label: "Reference Number",
//       order: 300,
//       value: null,
//     },
//     {
//       label: "_Amount",
//       order: 400,
//       value: null,
//     },
//     {
//       label: "Amount Due",
//       order: 401,
//       value: null,
//     },
//     {
//       label: "Payment",
//       order: 402,
//       value: null,
//     },
//   ],
//   ledger_check: {
//     id: "7dWKxbqkz8",
//     recipient_email: "eduard.ghinea+sync2@rndsoftwaregroup.com",
//     pttoo: "State Farm Insurance",
//     date: "2024-07-23 00:00:00",
//     number: "v1272",
//     amount: 400,
//     memo: "",
//     voided_at: null,
//     status: {
//       status: "processing",
//       state: "SIGNED",
//       sub_status: "vendor-accepted",
//     },
//     required_actions: [],
//     role: "sender",
//     sender: {
//       id: "yka4X7QP6J",
//       name: "samanta 1",
//       account_number: "0800",
//       routing_number: "321171184",
//       bank_name: "CITIBANK  FSB",
//       company_id: "a6VNPgzEJr",
//       company_name: "samanta 1",
//       organization_id: "nAR9r7lq3B",
//       organization_name: "samanta 1",
//     },
//     sender_title: "samanta 1 **0800",
//     sender_subtitle: null,
//     recipient: null,
//     recipient_title: "State Farm Insurance",
//     recipient_subtitle: "eduard.ghinea+sync2@rndsoftwaregroup.com",
//     vendor_is_valid: false,
//     action: {
//       type: "action",
//       label: "Send Reminder",
//       action: "resend",
//       disabled: true,
//       priority: null,
//       message: null,
//     },
//     options: [
//       {
//         type: "action",
//         label: "Void Check",
//         action: "void",
//         disabled: null,
//         priority: null,
//         message: null,
//       },
//     ],
//     related_by: null,
//     chat_details: {
//       chat_status: null,
//       chat_activity: null,
//     },
//   },
//   remittances: [
//     {
//       entity: "Bill",
//       entity_ref: "3662",
//       date: "2024-07-23 00:00:00",
//       amount: 800,
//       meta: [
//         {
//           label: "EntityId",
//           order: 90,
//           value: "200",
//         },
//         {
//           label: "Document Number",
//           order: 201,
//           value: "actual",
//         },
//         {
//           label: "Reference Number",
//           order: 300,
//           value: "",
//         },
//         {
//           label: "Amount Due",
//           order: 401,
//           value: "1000",
//         },
//         {
//           label: "Amount Applied",
//           order: 402,
//           value: "800",
//         },
//       ],
//       created_at: "2024-07-23 13:32:28",
//     },
//     {
//       entity: "Bill Credit",
//       entity_ref: "3659",
//       date: "2024-07-23 13:31:31",
//       amount: 50,
//       meta: [
//         {
//           label: "EntityId",
//           order: 90,
//           value: "200",
//         },
//         {
//           label: "Document Number",
//           order: 201,
//           value: "nega2",
//         },
//         {
//           label: "Bill Reference Number",
//           order: 300,
//           value: "dsadsadasassasdasddsa",
//         },
//         {
//           label: "Amount Due",
//           order: 401,
//           value: "600",
//         },
//         {
//           label: "Amount Applied",
//           order: 402,
//           value: "-50",
//         },
//       ],
//       created_at: "2024-07-23 13:32:28",
//     },
//     {
//       entity: "Advance Credit",
//       entity_ref: "3649",
//       date: "2024-07-23 13:31:31",
//       amount: 100,
//       meta: [
//         {
//           label: "EntityId",
//           order: 90,
//           value: "200",
//         },
//         {
//           label: "Document Number",
//           order: 201,
//           value: "1266",
//         },
//         {
//           label: "Bill Reference Number",
//           order: 300,
//           value: "",
//         },
//         {
//           label: "Amount Due",
//           order: 401,
//           value: "100",
//         },
//         {
//           label: "Amount Applied",
//           order: 402,
//           value: "-100",
//         },
//       ],
//       created_at: "2024-07-23 13:32:28",
//     },
//     {
//       entity: "Advance Credit",
//       entity_ref: "3650",
//       date: "2024-07-23 13:31:31",
//       amount: 100,
//       meta: [
//         {
//           label: "EntityId",
//           order: 90,
//           value: "200",
//         },
//         {
//           label: "Document Number",
//           order: 201,
//           value: "1267",
//         },
//         {
//           label: "Bill Reference Number",
//           order: 300,
//           value: "",
//         },
//         {
//           label: "Amount Due",
//           order: 401,
//           value: "100",
//         },
//         {
//           label: "Amount Applied",
//           order: 402,
//           value: "-100",
//         },
//       ],
//       created_at: "2024-07-23 13:32:28",
//     },
//     {
//       entity: "Advance Credit",
//       entity_ref: "3651",
//       date: "2024-07-23 13:31:31",
//       amount: 100,
//       meta: [
//         {
//           label: "EntityId",
//           order: 90,
//           value: "200",
//         },
//         {
//           label: "Document Number",
//           order: 201,
//           value: "1268",
//         },
//         {
//           label: "Bill Reference Number",
//           order: 300,
//           value: "",
//         },
//         {
//           label: "Amount Due",
//           order: 401,
//           value: "100",
//         },
//         {
//           label: "Amount Applied",
//           order: 402,
//           value: "-100",
//         },
//       ],
//       created_at: "2024-07-23 13:32:28",
//     },
//     {
//       entity: "Advance Credit",
//       entity_ref: "3653",
//       date: "2024-07-23 13:31:31",
//       amount: 150,
//       meta: [
//         {
//           label: "EntityId",
//           order: 90,
//           value: "200",
//         },
//         {
//           label: "Document Number",
//           order: 201,
//           value: "1269",
//         },
//         {
//           label: "Bill Reference Number",
//           order: 300,
//           value: "",
//         },
//         {
//           label: "Amount Due",
//           order: 401,
//           value: "150",
//         },
//         {
//           label: "Amount Applied",
//           order: 402,
//           value: "-150",
//         },
//       ],
//       created_at: "2024-07-23 13:32:28",
//     },
//   ],
//   activity: [
//     {
//       id: "Z3LXrPdjwM",
//       title: null,
//       note: "Sent to recipient.",
//       type: 0,
//       context: 0,
//       can_manage: false,
//       attachments: [],
//       created_at: "2024-07-23 09:34:10",
//       username: null,
//     },
//     {
//       id: "rOjkgN5jEY",
//       title: null,
//       note: "Signed this Check.",
//       type: 3,
//       context: 0,
//       can_manage: false,
//       attachments: [],
//       created_at: "2024-07-23 09:34:05",
//       username: "valrnd",
//     },
//     {
//       id: "ZaLwZ2QnlX",
//       title: null,
//       note: "Created this check.",
//       type: 0,
//       context: 0,
//       can_manage: false,
//       attachments: [],
//       created_at: "2024-07-23 09:32:28",
//       username: "valrnd",
//     },
//   ],
//   timeline: {
//     events: [
//       {
//         label: "Signed",
//         date: "07/23/2024 09:34 AM",
//         status: "finished",
//         description: [
//           {
//             description: "Signed by Val at 07/23/2024 09:34 AM",
//             static: false,
//             url: null,
//           },
//         ],
//         flags: [],
//         images: [],
//       },
//       {
//         label: "Emailed to recipient",
//         date: "07/23/2024 09:34 AM",
//         status: "finished",
//         description: [
//           {
//             description:
//               "[eduard.ghinea+sync2@rndsoftwaregroup.com] [#v1272] samanta 1 has sent you $400.00 with Cherry at 07/23/2024 09:34 AM",
//             static: false,
//             url: null,
//           },
//         ],
//         flags: [
//           {
//             flag: true,
//             label: "Opened",
//           },
//           {
//             flag: false,
//             label: "Clicked",
//           },
//           {
//             flag: false,
//             label: "Bounced",
//           },
//         ],
//         images: [],
//       },
//       {
//         label: "Estimated payment date",
//         date: null,
//         status: "in_progress",
//         description: [],
//         flags: [],
//         images: [],
//       },
//       {
//         label: "Paid",
//         date: null,
//         status: "pending",
//         description: [],
//         flags: [],
//         images: [],
//       },
//     ],
//   },
//   other_context_available: null,
//   other_context_organization_id: null,
// } as NonNullable<VCheckCheckQueryQuery["VCheckCheckQuery"]>;

// const mockMeta = mock.meta;
// const mockRemittances = mock.remittances;

interface IRemittanceHeadCol {
  label: string;
  order: number;
  visible?: boolean;
}

type IRemittanceBodyCol = IRemittanceHeadCol & { value: string };

const staticRemittanceCols: IRemittanceHeadCol[] = [
  {
    label: "Date",
    order: 100,
    visible: true,
  },
  {
    label: "Type",
    order: 200,
    visible: true,
  },
  {
    label: "Ref",
    order: 300,
    visible: true,
  },
  {
    label: "Amount",
    order: 400,
    visible: true,
  },
];

interface ICheckStubTableProps {
  checkDetails: NonNullable<VCheckCheckQueryQuery["VCheckCheckQuery"]>;
}

const CheckStubTable = ({ checkDetails: { remittances, meta, ledger_check } }: ICheckStubTableProps) => {
  const [remitanceHeadCols, remitanceBodyCols] = useMemo(() => {
    const initCols: IRemittanceHeadCol[] = cloneDeep(staticRemittanceCols);

    // update initCols
    const processedColsByMeta: IRemittanceHeadCol[] = initCols.map((rCol) => {
      const colInMeta = meta?.find((mCol) => mCol.order === rCol.order);

      // remove col
      if (colInMeta && colInMeta.label?.startsWith("_")) {
        return { ...rCol, visible: false };
        // update col label
      } else if (colInMeta) {
        return { ...rCol, label: colInMeta.label ?? "" };
      } else {
        return rCol;
      }
    });

    // add more to initCols
    const otherColsByMeta: IRemittanceHeadCol[] = (meta ?? []).reduce<IRemittanceHeadCol[]>((result, col) => {
      const colExists = processedColsByMeta?.find((sCol) => sCol.order === col.order);

      if (colExists) {
        return result;
      }

      result = [
        ...result,
        {
          label: col.label ?? "",
          order: col.order ?? -1,
          visible: !col.label?.startsWith("_"),
        },
      ];

      return result;
    }, []);

    // all colls & add by remitance.meta[]
    const mightBeAllColls = [...processedColsByMeta, ...otherColsByMeta];

    remittances?.forEach((remitance) => {
      remitance.meta?.forEach((remittanceMeta) => {
        const colExists = mightBeAllColls.find((mCol) => mCol.order === remittanceMeta.order);

        if (!colExists) {
          mightBeAllColls.push({
            label: remittanceMeta.label ?? "",
            order: remittanceMeta.order ?? -1,
            visible: true,
          });
        }
      });
    });

    // final headCols sorted
    const headCols = sortBy(mightBeAllColls, (col) => col.order);

    // init  bodyCols
    const bodyCols: IRemittanceBodyCol[][] = [];

    remittances?.forEach((remittance) => {
      const bodyCol: IRemittanceBodyCol[] = headCols.map((col) => {
        // override root static fields by remittance.meta[]
        const colInRemittanceMeta = remittance.meta?.find(({ order }) => order === col.order);

        return col.order === 100 || col.order === 200 || col.order === 300 || col.order === 400
          ? {
              ...col,
              value:
                col.order === 100
                  ? colInRemittanceMeta?.value
                    ? (formatDateToDesign(colInRemittanceMeta.value) as string)
                    : remittance.date
                    ? (formatDateToDesign(remittance.date) as string)
                    : ""
                  : col.order === 200
                  ? colInRemittanceMeta?.value
                    ? colInRemittanceMeta?.value
                    : remittance.entity ?? ""
                  : col.order === 300
                  ? colInRemittanceMeta?.value
                    ? colInRemittanceMeta.value
                    : remittance.entity_ref ?? ""
                  : col.order === 400
                  ? !isNil(colInRemittanceMeta?.value)
                    ? formatCurrency(Number(colInRemittanceMeta?.value ?? 0))
                    : !isNil(remittance.amount)
                    ? formatCurrency(remittance.amount)
                    : ""
                  : "",
            }
          : safeRemitanceCol(col, remittance.meta);
      });

      bodyCols.push(bodyCol);
    });

    // final headCols visible
    const headColsVisible = headCols.filter((col) => col.visible);

    // final bodyCols visible
    const bodyColsVisible = bodyCols.map((bCol) => bCol.filter((col) => col.visible));

    return [headColsVisible, bodyColsVisible];
  }, [meta, remittances]);

  if (!remittances?.length) {
    return null;
  }

  return (
    <Box sx={{ width: "100%", overflowX: "auto" }}>
      <table className={styles.table}>
        <thead>
          <tr>
            {remitanceHeadCols.map(({ label }, index) => (
              <td key={index} className={styles.smallHeadCol}>
                {label}
              </td>
            ))}
          </tr>
        </thead>
        <tbody>
          {remitanceBodyCols.map((rem, index) => (
            <tr key={index}>
              {rem.map(({ value }, index) => (
                <td key={index} className={styles.smallBodyCol} title={value}>
                  {isValidHttpUrl(value) ? (
                    <Box component="a" href={value} target="_blank" rel="noreferrer" sx={{ color: COLORS.mediumGrey }}>
                      Link
                    </Box>
                  ) : (
                    value
                  )}
                </td>
              ))}
            </tr>
          ))}

          {!isNil(ledger_check?.amount) && (
            <tr>
              <Box
                component="td"
                sx={{ fontWeight: "600 !important", color: `${COLORS.colorNewPrimary} !important` }}
                colSpan={(remitanceBodyCols[0].length ? remitanceBodyCols[0].length : 4) - 1}
              >
                Total:
              </Box>
              <Box component="td" sx={{ fontWeight: "600 !important", color: `${COLORS.colorNewPrimary} !important` }}>
                {" "}
                {formatCurrency(ledger_check?.amount ?? 0)}
              </Box>
            </tr>
          )}
        </tbody>
      </table>
    </Box>
  );
};

const safeRemitanceCol = (
  headCol: IRemittanceHeadCol,
  remitanceMeta?: RemittanceRecordMetaType[] | null,
): IRemittanceBodyCol => {
  const meta = remitanceMeta?.find(({ order }) => order === headCol.order);

  return meta
    ? {
        ...headCol,
        label: meta.label?.length ? meta.label : "",
        value: meta.value?.length ? meta.value : "",
        order: meta.order ?? -1,
      }
    : {
        ...headCol,
        // empty col
        label: "",
        value: "",
        order: -1,
      };
};

export default CheckStubTable;
