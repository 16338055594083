import { IconButton, Tooltip } from "@mui/material";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary";
import { styled } from "@mui/material/styles";
import { ExpandLess, ExpandMoreIcon } from "components/common/icons";
import { omit } from "lodash";

// const expandIconStyle = { fontSize: "22px", color: COLORS.logoBlue };

export const CompaniesAccordian = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))({ background: "none", ":before": { display: "none" } });

export const CompaniesAccordianSummary = styled(
  (
    props: AccordionSummaryProps & {
      accordianExpanded: boolean;
    },
  ) => (
    <MuiAccordionSummary {...omit(props, "accordianExpanded")}>
      <Tooltip arrow title={props.accordianExpanded ? "Hide bills categories" : "Show bills categories"}>
        <IconButton>{props.accordianExpanded ? <ExpandLess /> : <ExpandMoreIcon />}</IconButton>
      </Tooltip>
    </MuiAccordionSummary>
  ),
)(() => ({
  padding: 0,
  minHeight: "auto",
  ".MuiAccordionSummary-content": {
    margin: 0,
  },
}));

export const CompaniesAccordianDetails = styled(MuiAccordionDetails)(() => ({
  padding: 0,
}));
