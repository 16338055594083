import ClearIcon from "@mui/icons-material/Clear";
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Popover,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { processJsDateToIsoOnMidnight } from "common/helpers/dates";
import { useCodingLedgerFilters } from "components/pages/VBillCodingLedger/hooks";
import { CodingDateGroups, staticCodingDateLines } from "components/pages/VBillCodingLedger/utils";
import { isValid } from "date-fns";
import { IVBillBillsFiltersInput } from "generated/sdk.vbill";
import { isNil } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useSetState } from "react-use";

export type DateValues = Pick<IVBillBillsFiltersInput, "date" | "date_to">;

interface IDateFilterPopoverProps {
  popoverId?: string;
  popoverAnchorEl: null | HTMLElement;
  onClosePopover: () => void;
}

export const DateFilterPopover = ({ popoverId, popoverAnchorEl, onClosePopover }: IDateFilterPopoverProps) => {
  const [codingLedgerFilters, setCodingLedgerFilters] = useCodingLedgerFilters();
  const [localMuiDates, setLocalMuiDates] = useSetState<{ start: Date | null; end: Date | null }>({
    start: null,
    end: null,
  });
  const [selectedDateGroup, setSelectedDateGroup] = useState<CodingDateGroups | undefined>(CodingDateGroups.CreateDate);
  const filters = useMemo(() => codingLedgerFilters.filters, [codingLedgerFilters.filters]);

  useEffect(() => {
    const newSelectedDateGroup =
      !!filters?.createDateFrom || !!filters?.createDateTo
        ? CodingDateGroups.CreateDate
        : !!filters?.invoiceDateDueFrom || !!filters?.invoiceDateDueTo
          ? CodingDateGroups.BillDue
          : !!filters?.invoiceDateFrom || !!filters?.invoiceDateTo
            ? CodingDateGroups.BillDate
            : CodingDateGroups.CreateDate;

    setSelectedDateGroup(newSelectedDateGroup);
  }, [
    filters?.createDateFrom,
    filters?.createDateTo,
    filters?.invoiceDateDueFrom,
    filters?.invoiceDateDueTo,
    filters?.invoiceDateFrom,
    filters?.invoiceDateTo,
  ]);

  useEffect(() => {
    const newStart = !!filters?.createDateFrom
      ? isNil(filters?.createDateFrom)
        ? null
        : new Date(filters?.createDateFrom ?? "")
      : !!filters?.invoiceDateDueFrom
        ? isNil(filters?.invoiceDateDueFrom)
          ? null
          : new Date(filters?.invoiceDateDueFrom ?? "")
        : !!filters?.invoiceDateFrom
          ? isNil(filters?.invoiceDateFrom)
            ? null
            : new Date(filters?.invoiceDateFrom ?? "")
          : null;
    const newEnd = !!filters?.createDateTo
      ? isNil(filters?.createDateTo)
        ? null
        : new Date(filters?.createDateTo ?? "")
      : !!filters?.invoiceDateDueTo
        ? isNil(filters?.invoiceDateDueTo)
          ? null
          : new Date(filters?.invoiceDateDueTo ?? "")
        : !!filters?.invoiceDateTo
          ? isNil(filters?.invoiceDateTo)
            ? null
            : new Date(filters?.invoiceDateTo ?? "")
          : null;

    setLocalMuiDates({ start: newStart, end: newEnd });
  }, [
    filters?.createDateFrom,
    filters?.createDateTo,
    filters?.invoiceDateDueFrom,
    filters?.invoiceDateDueTo,
    filters?.invoiceDateFrom,
    filters?.invoiceDateTo,
    setLocalMuiDates,
  ]);

  const handleSaveDialog = async () => {
    const fromKey =
      selectedDateGroup === CodingDateGroups.CreateDate
        ? "createDateFrom"
        : selectedDateGroup === CodingDateGroups.BillDue
          ? "invoiceDateDueFrom"
          : selectedDateGroup === CodingDateGroups.BillDate
            ? "invoiceDateFrom"
            : "createDateFrom";
    const toKey =
      selectedDateGroup === CodingDateGroups.CreateDate
        ? "createDateTo"
        : selectedDateGroup === CodingDateGroups.BillDue
          ? "invoiceDateDueTo"
          : selectedDateGroup === CodingDateGroups.BillDate
            ? "invoiceDateTo"
            : "createDateTo";

    setCodingLedgerFilters((prev) => ({
      filters: {
        ...prev.filters,
        invoice_number_sort: undefined,
        invoice_id_sort: undefined,
        amount_sort: undefined,
        dates_sort: undefined,

        ...(selectedDateGroup === CodingDateGroups.CreateDate
          ? { invoiceDateDueFrom: undefined, invoiceDateFrom: undefined }
          : {}),
        ...(selectedDateGroup === CodingDateGroups.BillDate
          ? { createDateFrom: undefined, invoiceDateDueFrom: undefined }
          : {}),
        ...(selectedDateGroup === CodingDateGroups.BillDue
          ? { createDateFrom: undefined, invoiceDateFrom: undefined }
          : {}),

        ...(selectedDateGroup === CodingDateGroups.CreateDate
          ? { invoiceDateDueTo: undefined, invoiceDateTo: undefined }
          : {}),
        ...(selectedDateGroup === CodingDateGroups.BillDate
          ? { createDateTo: undefined, invoiceDateDueTo: undefined }
          : {}),
        ...(selectedDateGroup === CodingDateGroups.BillDue
          ? { createDateTo: undefined, invoiceDateTo: undefined }
          : {}),

        [fromKey]:
          localMuiDates.start && isValid(localMuiDates.start)
            ? processJsDateToIsoOnMidnight(localMuiDates.start.toString())
            : undefined,
        [toKey]:
          localMuiDates.end && isValid(localMuiDates.end)
            ? processJsDateToIsoOnMidnight(localMuiDates.end.toString())
            : undefined,
      },
      pagination: { page: 1, per_page: prev.pagination.per_page },
    }));
    onClosePopover();
  };

  const saveBtnDisabled =
    (isNil(localMuiDates.start) ? false : !isValid(localMuiDates.start)) ||
    (isNil(localMuiDates.end) ? false : !isValid(localMuiDates.end));

  return (
    <Popover
      transitionDuration={0}
      id={popoverId}
      open
      anchorEl={popoverAnchorEl}
      onClose={onClosePopover}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
    >
      <Box sx={{ padding: "20px 10px 10px" }}>
        {/* <Typography sx={{ fontSize: "20px", marginBottom: "20px" }}>Select VBill date</Typography> */}
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSaveDialog();
          }}
        >
          <Box>
            <Typography sx={{ fontSize: "16px", marginBottom: "10px" }}>Apply for:</Typography>
            <Box sx={{ display: "flex", gap: "10px", marginBottom: "10px" }}>
              <RadioGroup
                row
                aria-labelledby="vbill-date-filter-radio-buttons-group"
                name="date-filter-radio-buttons-group"
                value={selectedDateGroup}
                onChange={(event) => setSelectedDateGroup(event.target.value as unknown as CodingDateGroups)}
              >
                {staticCodingDateLines.map(({ group, label }) => (
                  <FormControlLabel key={group} value={group} control={<Radio />} label={label} />
                ))}
              </RadioGroup>
            </Box>
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column", gap: "15px" }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Start date:"
                value={localMuiDates.start}
                onChange={(date) => setLocalMuiDates({ start: date })}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    sx={{ width: "100%" }}
                    {...(isValid(localMuiDates.start) && {
                      InputProps: {
                        endAdornment: (
                          <InputAdornment position="end">
                            <Tooltip title="Clear" arrow>
                              <IconButton
                                onClick={() => setLocalMuiDates({ start: null })}
                                sx={{ marginRight: "-12px" }}
                              >
                                <ClearIcon />
                              </IconButton>
                            </Tooltip>
                          </InputAdornment>
                        ),
                      },
                    })}
                  />
                )}
              />

              <DatePicker
                label="End date:"
                value={localMuiDates.end}
                onChange={(date) => setLocalMuiDates({ end: date })}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    sx={{ width: "100%" }}
                    {...(isValid(localMuiDates.end) && {
                      InputProps: {
                        endAdornment: (
                          <InputAdornment position="end">
                            <Tooltip title="Clear" arrow>
                              <IconButton onClick={() => setLocalMuiDates({ end: null })} sx={{ marginRight: "-12px" }}>
                                <ClearIcon />
                              </IconButton>
                            </Tooltip>
                          </InputAdornment>
                        ),
                      },
                    })}
                  />
                )}
              />
            </LocalizationProvider>
          </Box>

          <Divider sx={{ margin: "15px 0" }} />

          <Box sx={{ display: "flex", justifyContent: "end", gap: "15px" }}>
            <Button disabled={saveBtnDisabled} variant="contained" type="submit" sx={{ height: "32px" }}>
              Save
            </Button>
            <Button variant="outlined" onClick={onClosePopover} sx={{ height: "32px" }}>
              Cancel
            </Button>
          </Box>
        </form>
      </Box>
    </Popover>
  );
};
