import { Box, Typography } from "@mui/material";
import { formatCurrency } from "common/helpers/utils";
import { observer } from "mobx-react";
import { useStore } from "storeContainer";
import { COLORS } from "themes/default";
import { LightTooltip } from "../../common/LightTooltip/LightTooltip";
import { tooltipDefaultProps, tooltipTypographyStyles, typographyAsBtnStyles } from "../summariesStyles";

export const SummariesBillsAmount = observer(function SummariesBillsAmount() {
  const vBillBatchDetailsStore = useStore("VBillBatchDetailsStore");

  const { batchDetails } = vBillBatchDetailsStore;
  const batchData = batchDetails.data;
  const invoicesAmount = Number(batchData?.invoicesAmount ?? 0);
  const budgetAmount = Number(batchData?.budget ?? 0);
  const isOverBudget = invoicesAmount > budgetAmount;

  return (
    <LightTooltip
      {...tooltipDefaultProps}
      title={
        <Box>
          <Typography sx={tooltipTypographyStyles}>
            Bills amount: <strong>{formatCurrency(invoicesAmount)}</strong>
          </Typography>
          <Typography sx={tooltipTypographyStyles}>
            Budget amount: <strong>{formatCurrency(budgetAmount)}</strong>
          </Typography>
          <Typography sx={tooltipTypographyStyles}>
            {isOverBudget ? "Over" : "Left"} budget:{" "}
            <strong>{formatCurrency(Math.abs(budgetAmount - invoicesAmount))}</strong>
          </Typography>
        </Box>
      }
    >
      <Typography sx={typographyAsBtnStyles}>
        <Box sx={{ color: COLORS.logoBlue }}>Bills amount:</Box>{" "}
        <Box component="strong" sx={{ color: isOverBudget ? COLORS.orange : COLORS.success }}>
          {formatCurrency(invoicesAmount)}
        </Box>
      </Typography>
    </LightTooltip>
  );
});
