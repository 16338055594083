import { useSelectedOrganizationCompanyId } from "components/pages/Settings/OrganizationCompanySelector/useSelectedOrganizationCompanyId";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useStore } from "storeContainer";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import { Permissions2UtilEntity, Permissions2UtilUser } from "./Permisssion2Utils";
import { AccountStateEnum, OrganizationUserEnum } from "generated/sdk";
import { Link, useParams } from "react-router-dom";
import { Permissions2EditManage } from "./Permissions2EditManage";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import UsersTabs from "./UsersTabs";
import { COLORS } from "../../../../../../themes/default";
import styles from "./Permissions2.module.scss";
import { StringParam, useQueryParam } from "use-query-params";
import { AclEntityTypeEnum } from "generated/sdk";

export const Permissions2Edit: React.FunctionComponent<{}> = observer(function Permissions2Edit() {
  const { organizationId } = useSelectedOrganizationCompanyId();
  const acl2 = useStore("AclStore2");
  const [msg] = useQueryParam("msg", StringParam);

  const { user_type, user_id, entity_type, entity_id } = useParams<{
    user_type: string;
    user_id: string;
    entity_type: string;
    entity_id: string;
  }>();

  const isUser = user_type === "user";
  const user:
    | {
        id: string;
        name: string;
        email?: string;
        state?: OrganizationUserEnum;
        accountState?: AccountStateEnum;
        description?: string | undefined;
      }
    | undefined = isUser
    ? acl2.organizationUsersList.find((e) => e.id === user_id)
    : acl2.organizationGroupList.find((e) => e.id === user_id);

  useEffect(() => {
    organizationId && acl2.reload();
  }, [acl2, organizationId]);

  const entity = acl2.organizationEntitiesList.find((e) => e.id === entity_id && e.type === entity_type.toUpperCase());

  if (!entity) return <div>Invalid url (entity)</div>;
  if (!user) return <div>Invalid url (user)</div>;

  return (
    <Box sx={{ width: "100%" }} marginTop="40px">
      <UsersTabs selectedTab={1} className={styles.userTabs} />
      <div className={styles.container}>
        <Link
          to={`/org/${organizationId}/users2/permissions`}
          style={{ textDecoration: "none", color: COLORS.logoBlue }}
        >
          <ArrowBackIosIcon />
        </Link>
      </div>
      <Paper>
        <Box sx={{ p: 3, pb: 6, display: "flex", justifyContent: "space-between", alignItems: "center", mt: "25px" }}>
          <Box>
            <Typography
              variant="h1"
              sx={{ fontWeight: 600, color: COLORS.newPrimaryColor, fontSize: "24px" }}
              display={"inline-flex"}
            >
              Edit permission of&nbsp;
              <Permissions2UtilUser isUser={false} user_name={user?.name || user_id} />
              &nbsp; on&nbsp;
              <Box
                sx={
                  entity.type === AclEntityTypeEnum.BankAccount
                    ? { transform: "translate(-25px)" }
                    : entity.type === AclEntityTypeEnum.Company
                      ? { transform: "translate(-15px)" }
                      : {}
                }
              >
                <Permissions2UtilEntity entity_type={entity.type} entity_name={entity.name} />
              </Box>
            </Typography>
            <Typography variant="h2" sx={{ fontWeight: 500, color: "#707070", marginTop: "8px" }}>
              {user?.email}
            </Typography>
          </Box>
        </Box>
        <Grid container spacing={2} sx={{ p: 3, pb: 6 }}>
          <Grid item sx={{ pt: 1 }}>
            <Link
              to={`/org/${organizationId}/users2/permissions/${isUser ? "user" : "group"}/${user_id}`}
              style={{ textDecoration: "none", color: COLORS.black }}
            >
              <Box
                sx={{
                  p: 2,
                  backgroundColor: COLORS.tableHeaderColor,
                  color: COLORS.darkText,
                  fontWeight: 500,
                  borderRadius: "5px",
                  maxHeight: "56px",
                }}
              >
                <Permissions2UtilUser user_name={user.name} isUser={!!isUser} />
              </Box>
            </Link>
          </Grid>
          <Grid item sx={{ pt: 1 }}>
            <Box
              sx={{
                p: 2,
                backgroundColor: COLORS.tableHeaderColor,
                color: COLORS.darkText,
                fontWeight: 500,
                borderRadius: "5px",
                maxHeight: "56px",
              }}
            >
              <Box
                sx={
                  entity.type === AclEntityTypeEnum.BankAccount
                    ? { transform: "translate(-25px)" }
                    : entity.type === AclEntityTypeEnum.Company
                      ? { transform: "translate(-15px)" }
                      : {}
                }
              >
                <Permissions2UtilEntity entity_type={entity.type} entity_name={entity.name} />
              </Box>
            </Box>
          </Grid>
        </Grid>
        {/* <pre>organizationEntitiesList={JSON.stringify(acl2.organizationEntitiesList, null, 4)}</pre> */}
        {/* <pre>userRoles={JSON.stringify(userRoles, null, 4)}</pre> */}
        {/* <pre>user={JSON.stringify(user, null, 4)}</pre> */}
        {/* <pre>entity={JSON.stringify(entity, null, 4)}</pre> */}
        <Box sx={{ p: 3, pb: 6 }}>
          <Typography variant="h2" sx={{ fontWeight: 600, color: COLORS.newPrimaryColor, marginBottom: "8px" }}>
            {msg ?? ""}
          </Typography>
          <Permissions2EditManage isUser={isUser} user_id={user_id} entity_type={entity.type} entity_id={entity.id} />
        </Box>
        {/* <AclPermissions
        organizationId={organizationId!}
        organizationUserId={isUser && user_id ? user_id : null}
        userGroupId={!isUser && user_id ? user_id : null}
      /> */}
      </Paper>
    </Box>
  );
});
