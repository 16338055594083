import type { ComponentProps } from "react";
import MuiAutocomplete from "@mui/material/Autocomplete";
import type { AutocompleteProps, AutocompleteRenderInputParams } from "@mui/material/Autocomplete";

import TextField from "components/common/TextField";

interface Props<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
> extends Omit<AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, "renderInput"> {
  textFieldProps?: ComponentProps<typeof TextField>;
  renderInput?: (params: AutocompleteRenderInputParams) => React.ReactNode;
}

function Autocomplete<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
>({ textFieldProps = {}, ...rest }: Props<T, Multiple, DisableClearable, FreeSolo>) {
  return (
    <MuiAutocomplete
      renderInput={(params) => {
        return <TextField {...params} {...textFieldProps} />;
      }}
      {...rest}
    />
  );
}

export default Autocomplete;
