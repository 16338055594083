import { Box, Divider, Typography } from "@mui/material";
import { getVBillFormattedDate } from "components/pages/common/VBill/utils";
import { IVBillExpenseType } from "generated/sdk.vbill";
import { observer } from "mobx-react";
import { useMemo } from "react";
import { useStore } from "storeContainer";
import { COLORS } from "themes/default";

export const VExpenseEmployeeDetails = observer(() => {
  const vBillStore = useStore("VBillStore");
  const { invoice } = vBillStore;
  const invoiceData = useMemo(() => invoice.data, [invoice.data]);

  if (invoiceData?.expenseType !== IVBillExpenseType.Reimbursement) {
    return null;
  }

  return (
    <Box>
      <Typography sx={{ fontSize: 16, marginBottom: "5px", fontWeight: 500 }} color={COLORS.logoBlue}>
        EMPLOYEE:
      </Typography>
      <Box sx={{ marginBottom: "10px" }}>
        <Typography sx={{ color: COLORS.textGray, marginBottom: "5px" }}>Expense Date:</Typography>
        <Typography sx={{ wordWrap: "break-word" }}>
          {getVBillFormattedDate(invoiceData?.expenseReport?.expenseDate)}
        </Typography>
      </Box>
      <Box sx={{ marginBottom: "10px" }}>
        <Typography sx={{ color: COLORS.textGray, marginBottom: "5px" }}>Expense report number:</Typography>
        <Typography sx={{ wordWrap: "break-word" }}>
          {invoiceData?.expenseReport?.expenseReportNumber ?? "-"}
        </Typography>
      </Box>
      <Box sx={{ marginBottom: "10px" }}>
        <Typography sx={{ color: COLORS.textGray, marginBottom: "5px" }}>Employee ID:</Typography>
        <Typography sx={{ wordWrap: "break-word" }}>{invoiceData?.expenseReport?.employeeId ?? "-"}</Typography>
      </Box>
      <Box sx={{ marginBottom: "10px" }}>
        <Typography sx={{ color: COLORS.textGray, marginBottom: "5px" }}>Reason for Expense:</Typography>
        <Typography sx={{ wordWrap: "break-word" }}>{invoiceData?.expenseReport?.reasonForExpense ?? "-"}</Typography>
      </Box>
      <Divider sx={{ marginBottom: "10px" }} />
      <Typography sx={{ fontSize: 16, marginBottom: "5px", fontWeight: 500 }} color={COLORS.logoBlue}>
        PAYEE:
      </Typography>
      <Box sx={{ marginBottom: "10px" }}>
        <Typography sx={{ color: COLORS.textGray, marginBottom: "5px" }}>Email address:</Typography>
        <Typography sx={{ wordWrap: "break-word" }}>{invoiceData?.expenseReport?.payee.email ?? "-"}</Typography>
      </Box>
      <Box sx={{ marginBottom: "10px" }}>
        <Typography sx={{ color: COLORS.textGray, marginBottom: "5px" }}>Bank Name:</Typography>
        <Typography sx={{ wordWrap: "break-word" }}>{invoiceData?.expenseReport?.payee.bankName ?? "-"}</Typography>
      </Box>
      <Box sx={{ marginBottom: "10px" }}>
        <Typography sx={{ color: COLORS.textGray, marginBottom: "5px" }}>Type of account:</Typography>
        <Typography sx={{ wordWrap: "break-word" }}>
          {invoiceData?.expenseReport?.payee.bankAccountType ?? "-"}
        </Typography>
      </Box>
      <Box sx={{ marginBottom: "10px" }}>
        <Typography sx={{ color: COLORS.textGray, marginBottom: "5px" }}>Bank Account Number:</Typography>
        <Typography sx={{ wordWrap: "break-word" }}>
          {invoiceData?.expenseReport?.payee.bankAccountNumber ?? "-"}
        </Typography>
      </Box>
      <Box sx={{ marginBottom: "10px" }}>
        <Typography sx={{ color: COLORS.textGray, marginBottom: "5px" }}>Bank Routing Number:</Typography>
        <Typography sx={{ wordWrap: "break-word" }}>
          {invoiceData?.expenseReport?.payee.bankRoutingNumber ?? "-"}
        </Typography>
      </Box>
    </Box>
  );
});
