import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary";
import { styled } from "@mui/material/styles";
import { ExpandMoreIcon } from "components/common/icons";

export const AdditionalContentAccordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({}));

export const AdditionalContentAccordianSummary = styled((props: AccordionSummaryProps & {}) => (
  <MuiAccordionSummary expandIcon={<ExpandMoreIcon sx={{ fontSize: "20px" }} />} {...props} />
))(({ theme }) => ({
  ".MuiAccordionSummary-content": {
    flexDirection: "column",
    alignItems: "flex-start",
  },
}));

export const AdditionalContentAccordianDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: "10px",
}));
