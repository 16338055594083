import { Skeleton, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import { observer } from "mobx-react";
import { useStore } from "storeContainer";
import { BatchRow } from "../BatchRow/BatchRow";

const emptySkeletonList = new Array(50).fill("");

export const BatchLedgerList = observer(function BatchLedgerList() {
  const vBillBatchLedgerStore = useStore("VBillBatchLedgerStore");

  const batchLedgerListData = vBillBatchLedgerStore.batchLedgerList.data;

  return (
    <TableBody>
      {vBillBatchLedgerStore.batchLedgerList.isLoading ? (
        <>
          {emptySkeletonList.map((_, index) => (
            <TableRow key={index} sx={{ cursor: "default" }}>
              <TableCell colSpan={11} sx={{ padding: "12px" }}>
                <Skeleton variant="rounded" sx={{ height: "34px" }} />
              </TableCell>
            </TableRow>
          ))}
        </>
      ) : (batchLedgerListData?.items ?? []).length === 0 ? (
        <TableRow>
          <TableCell colSpan={12} sx={{ textAlign: "center" }}>
            <Typography>No results found</Typography>
          </TableCell>
        </TableRow>
      ) : (
        batchLedgerListData?.items.map((batch) => <BatchRow key={batch.id} batch={batch} />)
      )}
    </TableBody>
  );
});
