import { memo } from "react";
import type { TableInstance } from "react-table";
import Button from "components/common/Button";
import styles from "./Footer.module.scss";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

// @TODO replace the button text with icons
function Footer({
  canNextPage,
  canPreviousPage,
  gotoPage,
  nextPage,
  pageCount,
  pageOptions,
  previousPage,
  setPageSize,
  state,
}: Props) {
  const { pageIndex, pageSize } = state;

  return (
    <div className={styles.pagination}>
      <div className={styles.buttonsContainer}>
        <Button
          disabled={!canPreviousPage}
          size="small"
          variant="contained"
          className={styles.paginationButton}
          onClick={() => gotoPage(0)}
        >
          {"<<"}
        </Button>
        <Button
          disabled={!canPreviousPage}
          size="small"
          variant="contained"
          className={styles.paginationButton}
          onClick={() => previousPage()}
        >
          {"<"}
        </Button>
        <Button
          className={styles.paginationButton}
          disabled={!canNextPage}
          size="small"
          variant="contained"
          onClick={() => nextPage()}
        >
          {">"}
        </Button>
        <Button
          className={styles.paginationButton}
          disabled={!canNextPage}
          size="small"
          variant="contained"
          onClick={() => gotoPage(pageCount - 1)}
        >
          {">>"}
        </Button>
      </div>

      <div className={styles.pageInfoContainer}>
        Page&nbsp;
        <strong>
          {pageIndex + 1} of {pageOptions.length}
        </strong>
      </div>
      <FormControl className={styles.showSelector}>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <MenuItem key={pageSize} value={pageSize}>
              Show {pageSize}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

type Props = Pick<
  TableInstance,
  | "canNextPage"
  | "canPreviousPage"
  | "gotoPage"
  | "nextPage"
  | "pageCount"
  | "pageOptions"
  | "previousPage"
  | "setPageSize"
  | "state"
>;

export default memo(Footer);
