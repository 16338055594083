import { Box, Typography, Button } from "@mui/material";
import styles from "./TreeSelect.module.scss";

type TreeSelectFooterProps = {
  onCancel: () => void;
  onDone: () => void;
  hideClearButon: boolean;
  counts: { parents: number; children: number };
};

export const TreeSelectFooter = ({ onCancel, counts, onDone, hideClearButon }: TreeSelectFooterProps) => {
  return (
    <Box
      className={styles.filterFooterContainer}
      sx={{ justifyContent: hideClearButon ? "flex-end" : "space-between" }}
    >
      {!hideClearButon && (
        <Typography className={styles.filterClearBtnText} onClick={onCancel}>
          Clear ({counts.children})
        </Typography>
      )}
      <Button color="secondary" size="medium" variant="contained" onClick={onDone}>
        Done
      </Button>
    </Box>
  );
};
