import { Box, Button, IconButton, Pagination, SxProps, Theme, Tooltip, Typography } from "@mui/material";
import { graphqlActionLogClient } from "common/graphqlClient";
import { useDocumentVisibilityChange } from "common/hooks/useDocumentVisibilityChange";
import { IActionlogActionlogFeListOutput, IActionlogActionlogListFiltersInput } from "generated/graphql.actionlog";
import { getSdk } from "generated/sdk.actionlog";
import { ChangeEvent, FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDeepCompareEffect } from "react-use";
import { COLORS } from "themes/default";
import { ExpandLess, ExpandMoreIcon } from "../icons";
import { Spinner } from "../Spinner/Spinner";
import { ActivityAction } from "./ActivityAction/ActivityAction";

const { ActionLogGetActions } = getSdk(graphqlActionLogClient);

const ACTIVITY_LOG_PER_PAGE = 6;

const POOL_ACTIVITY_ACTIONS_STREAM_INTERVAL_MS = Number(
  import.meta.env.VITE_APP_POOL_ACTIVITY_ACTIONS_STREAM_INTERVAL_MS ?? 30000,
);

const expandIconStyle = { fontSize: "22px", color: COLORS.logoBlue };

interface IActivityLogProps {
  filters: IActionlogActionlogListFiltersInput;
  reloadObserver: any;
  sx?: SxProps<Theme>;
}

export const ActivityLog: FunctionComponent<IActivityLogProps> = ({ filters, reloadObserver, sx }) => {
  const [isGetActionsFetching, setIsGetActionsFetching] = useState(false);
  const [activityLogActions, setActivityLogActions] = useState<IActionlogActionlogFeListOutput>();
  const currentActivityLogActions = useRef<IActionlogActionlogFeListOutput>();
  const [currentPage, setCurrentPage] = useState(1);
  const documentVisible = useDocumentVisibilityChange();
  const getActionsStreamIntervalId = useRef<number>();
  const [hasNewLogActions, setHasNewLogActions] = useState(false);
  const [actionsAdditionalContentExpanded, setActionsAdditionalContentExpanded] = useState(false);

  useEffect(() => {
    currentActivityLogActions.current = activityLogActions;
  }, [activityLogActions]);

  const subscribeToGetActionsStream = useCallback(() => {
    getActionsStreamIntervalId.current = window.setInterval(() => {
      ActionLogGetActions({ filters, pagination: { per_page: 0, page: 1 } }).then((resp) => {
        if ((resp.getActions?.total ?? 0) > (currentActivityLogActions.current?.total ?? 0)) {
          setHasNewLogActions(true);
        }
      });
    }, POOL_ACTIVITY_ACTIONS_STREAM_INTERVAL_MS);
  }, [filters]);

  const unSubscribeToGetActionsStream = () => {
    window.clearInterval(getActionsStreamIntervalId.current);
  };

  const getActivityLogActionsOnPageOne = useCallback(async () => {
    unSubscribeToGetActionsStream();
    setIsGetActionsFetching(true);
    const resp = await ActionLogGetActions({ filters, pagination: { page: 1, per_page: ACTIVITY_LOG_PER_PAGE } });

    if (resp.getActions) {
      setCurrentPage(1);
      setActivityLogActions(resp.getActions);
    }

    subscribeToGetActionsStream();
    setIsGetActionsFetching(false);
  }, [filters, subscribeToGetActionsStream]);

  useDeepCompareEffect(() => {
    if (documentVisible) {
      getActivityLogActionsOnPageOne();
    } else {
      unSubscribeToGetActionsStream();
    }
  }, [reloadObserver, documentVisible]);

  useEffect(() => {
    return () => {
      unSubscribeToGetActionsStream();
    };
  }, []);

  const totalActivityLogActions = activityLogActions?.total ?? 0;

  const totalPaginationPages = useMemo(
    () => Math.ceil(totalActivityLogActions / ACTIVITY_LOG_PER_PAGE),
    [totalActivityLogActions],
  );

  const hasActionsAdditionalContent = useMemo(
    () => activityLogActions?.items.some((item) => item?.meta?.additionalContent?.length ?? 0),
    [activityLogActions?.items],
  );

  const handlePaginationChange = async (_event: ChangeEvent<unknown>, page: number) => {
    unSubscribeToGetActionsStream();
    setIsGetActionsFetching(true);
    setHasNewLogActions(false);
    const resp = await ActionLogGetActions({ filters, pagination: { page, per_page: ACTIVITY_LOG_PER_PAGE } });

    if (resp.getActions) {
      setCurrentPage(page);
      setActivityLogActions(resp.getActions);
    }

    subscribeToGetActionsStream();
    setIsGetActionsFetching(false);
  };

  const handleReloadBtnClick = async () => {
    await getActivityLogActionsOnPageOne();
    setHasNewLogActions(false);
    setActionsAdditionalContentExpanded(false);
  };

  return (
    <Box sx={sx}>
      {isGetActionsFetching ? (
        <Spinner withSpaceAround />
      ) : (
        <>
          <div>
            {hasNewLogActions && (
              <Typography sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                New events.{" "}
                <Button onClick={handleReloadBtnClick} sx={{ "&:hover": { textDecoration: "underline" } }}>
                  Reload
                </Button>
                to view
              </Typography>
            )}

            {hasActionsAdditionalContent && (
              <Box sx={{ display: "flex", justifyContent: "flex-end", padding: "0 8px 10px 0" }}>
                <Tooltip title={`${actionsAdditionalContentExpanded ? "Hide" : "Show"} additional content`}>
                  <IconButton onClick={() => setActionsAdditionalContentExpanded((prev) => !prev)}>
                    {actionsAdditionalContentExpanded ? (
                      <ExpandLess sx={expandIconStyle} />
                    ) : (
                      <ExpandMoreIcon sx={expandIconStyle} />
                    )}
                  </IconButton>
                </Tooltip>
              </Box>
            )}

            {totalActivityLogActions === 0 ? (
              <Typography>No Activity</Typography>
            ) : (
              activityLogActions?.items.map((actionLog) => (
                <ActivityAction
                  additionalContentExpanded={actionsAdditionalContentExpanded}
                  key={actionLog.id}
                  actionLog={actionLog}
                />
              ))
            )}
          </div>

          {totalActivityLogActions > ACTIVITY_LOG_PER_PAGE && (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Pagination count={totalPaginationPages} page={currentPage} onChange={handlePaginationChange} />
            </Box>
          )}
        </>
      )}
    </Box>
  );
};
