import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Button, Divider, IconButton, Popover, Skeleton, Tooltip, Typography } from "@mui/material";
import { graphqlVBillClient } from "common/graphqlClient";
import { CloseIcon, ExpandLessIcon } from "components/common/icons";
import {
  invoiceAdditionalMappingsToFormFields,
  lineItemAdditionalMappingsToFormLineFields,
} from "components/pages/VBill/utils";
import { getVBillFormattedDate } from "components/pages/common/VBill/utils";
import {
  IVBillExpenseType,
  IVBillIIntegrationIntegrationSettingsVBillAdditionalMappingsEntryShowIn,
  IVBillVBillGetStoreItemQuery,
  getSdk,
} from "generated/sdk.vbill";
import { partition } from "lodash";
import { observer } from "mobx-react";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useStore } from "storeContainer";
import { COLORS } from "themes/default";
import { VBillVendorDifferenceLine } from "../../common/VBillVendorDifferenceLine/VBillVendorDifferenceLine";
import { VExpenseEmployeeDetails } from "../../common/VExpenseEmployeeDetails/VExpenseEmployeeDetails";
import { TAdditionalMappings } from "../../types";

const expandIconStyles = { fontSize: "14px !important" };

const { VBillGetStoreItem } = getSdk(graphqlVBillClient);

/*


// recaptcha before submit 
// disabled btn

// remove input with copy use vbillcopy component

// after submit remove form 



ledger:
from / email

// remove generate from org, just company


// back account fe verification


 bank_account_info {
        account_number
        routing_number
        bank_name
      }
      global_vendor {
        name
        email
        address
      }
      check_type

NEW

sunt puse pe vcheckcheckquery si vchecks

// payments tab

// account number => 6
// routing number => 5



*/

export const SummaryInvoiceDetails = observer(() => {
  const vBillStore = useStore("VBillStore");
  const organizationStore = useStore("OrganizationStore");
  const { organizationId } = useParams<{ organizationId: string }>();
  const invoiceData = useMemo(() => vBillStore.invoice.data, [vBillStore.invoice.data]);
  const isBill = useMemo(() => invoiceData?.expenseType === IVBillExpenseType.Bill, [invoiceData?.expenseType]);
  const invoiceCompanyName = useMemo(
    () =>
      organizationStore.organizationVBillEnabledCompanies.data?.items.find(
        ({ companyId }) => companyId === invoiceData?.companyId,
      )?.name,
    [organizationStore.organizationVBillEnabledCompanies.data?.items, invoiceData?.companyId],
  );
  const settingsData = useMemo(() => vBillStore.settings.data, [vBillStore.settings.data]);
  const invoiceMappings = useMemo(
    () => invoiceAdditionalMappingsToFormFields(invoiceData?.additionalMappings),
    [invoiceData?.additionalMappings],
  );
  const invoiceVendorMapping = useMemo(
    () =>
      (settingsData?.vBill.additionalMappings.invoice ?? []).find(
        (additionalMapping) =>
          additionalMapping.showIn !==
            IVBillIIntegrationIntegrationSettingsVBillAdditionalMappingsEntryShowIn.Payment &&
          additionalMapping.showIn !== IVBillIIntegrationIntegrationSettingsVBillAdditionalMappingsEntryShowIn.None &&
          additionalMapping.isContact,
      ),
    [settingsData?.vBill.additionalMappings.invoice],
  );
  const invoiceVendorTitle = useMemo(
    () => `${vBillStore.settings.data?.info?.type ? `${vBillStore.settings.data.info.type} ` : " "}VENDOR:`,
    [vBillStore.settings.data?.info?.type],
  );
  const invoiceAdditionalMappings = useMemo(
    () =>
      (settingsData?.vBill.additionalMappings.invoice ?? []).filter(
        (additionalMapping) =>
          additionalMapping.showIn !==
            IVBillIIntegrationIntegrationSettingsVBillAdditionalMappingsEntryShowIn.Payment &&
          additionalMapping.showIn !== IVBillIIntegrationIntegrationSettingsVBillAdditionalMappingsEntryShowIn.None &&
          !additionalMapping.isContact,
      ),
    [settingsData?.vBill.additionalMappings.invoice],
  );
  const invoiceVendorMappingStoreData = useMemo(
    () =>
      invoiceAdditionalMappingsToFormFields(invoiceData?.additionalMappings)[
        invoiceVendorMapping?.storeDataType as unknown as keyof TAdditionalMappings
      ],
    [invoiceData?.additionalMappings, invoiceVendorMapping?.storeDataType],
  );
  const [showVendorMappingSkeleton, setShowVendorMappingSkeleton] = useState(false);
  const [vendorMappingStoreItem, setVendorMappingStoreItem] =
    useState<IVBillVBillGetStoreItemQuery["getStoreItem"]["data"]>();
  const hasLineItems = useMemo(() => !!invoiceData?.hasLineItems, [invoiceData?.hasLineItems]);
  const [oneLineItemGlAccMapping, oneLineItemAdditionalMappings] = useMemo(() => {
    const lineAdditionalMappings = (settingsData?.vBill.additionalMappings.line ?? []).filter(
      (additionalMapping) =>
        additionalMapping.showIn !== IVBillIIntegrationIntegrationSettingsVBillAdditionalMappingsEntryShowIn.Payment &&
        additionalMapping.showIn !== IVBillIIntegrationIntegrationSettingsVBillAdditionalMappingsEntryShowIn.None,
    );

    return partition(lineAdditionalMappings, (map) => map.isGlAccount);
  }, [settingsData?.vBill.additionalMappings.line]);

  const oneLineItemMappings = useMemo(
    () => lineItemAdditionalMappingsToFormLineFields(invoiceData?.lineItems?.[0]),
    [invoiceData?.lineItems?.[0]],
  );
  const [moreDetailsPopoverAnchorEl, setPopoverAnchorEl] = useState<null | HTMLElement>(null);
  const moreDetailsPopoverVisible = Boolean(moreDetailsPopoverAnchorEl);
  const moreDetailsPopoverId = moreDetailsPopoverVisible ? "amount-filter" : undefined;
  const showAdditionalMappingsSkeleton = useMemo(() => vBillStore.settings.isLoading, [vBillStore.settings.isLoading]);

  useEffect(() => {
    if (!invoiceVendorMappingStoreData) {
      return;
    }

    setShowVendorMappingSkeleton(true);
    VBillGetStoreItem({
      organizationId,
      companyId: invoiceData?.companyId,
      key: invoiceVendorMappingStoreData.key,
      type: (invoiceVendorMappingStoreData as any).type,
    }).then((resp) => {
      setVendorMappingStoreItem(resp.getStoreItem.data);
      setShowVendorMappingSkeleton(false);
    });
  }, [invoiceData?.companyId, invoiceVendorMapping, invoiceVendorMappingStoreData, organizationId]);

  return (
    <>
      <Box sx={{ display: "flex", gap: "20px", marginBottom: "20px" }}>
        <Box sx={{ flex: "1", minWidth: "0px" }}>
          {showAdditionalMappingsSkeleton ? (
            <Skeleton variant="rounded" sx={{ height: "80px" }} />
          ) : (
            invoiceVendorMapping && (
              <Box sx={{ marginBottom: "10px" }}>
                <Typography
                  sx={{ fontSize: 16, marginBottom: "5px", fontWeight: 500, textTransform: "uppercase" }}
                  color={COLORS.logoBlue}
                >
                  {invoiceVendorTitle}
                </Typography>

                <Typography sx={{ wordBreak: "break-word" }}>{`${
                  invoiceMappings[invoiceVendorMapping.storeDataType]?.label ?? "-"
                }${
                  invoiceMappings[invoiceVendorMapping.storeDataType]?.label2
                    ? ` - ${invoiceMappings[invoiceVendorMapping.storeDataType]?.label2}`
                    : ""
                }`}</Typography>
              </Box>
            )
          )}

          {invoiceVendorMapping && <Divider sx={{ margin: "10px 0" }} />}

          {isBill ? (
            <>
              <Typography sx={{ fontSize: 16, marginBottom: "5px", fontWeight: 500 }} color={COLORS.logoBlue}>
                VENDOR:
              </Typography>
              <Box sx={{ marginBottom: "10px" }}>
                <Typography sx={{ color: COLORS.textGray, marginBottom: "5px" }}>Name:</Typography>
                <Typography sx={{ wordWrap: "break-word" }}>{invoiceData?.senderName ?? "-"}</Typography>
              </Box>
              <Box sx={{ marginBottom: "10px" }}>
                <Typography sx={{ color: COLORS.textGray, marginBottom: "5px" }}>Address:</Typography>
                <Typography sx={{ wordWrap: "break-word" }}>{invoiceData?.senderAddress ?? "-"}</Typography>
              </Box>
              <Box sx={{ marginBottom: "10px" }}>
                <Typography sx={{ color: COLORS.textGray, marginBottom: "5px" }}>Email:</Typography>
                <Typography sx={{ wordWrap: "break-word" }}>{invoiceData?.senderEmail ?? "-"}</Typography>
              </Box>
              <Box sx={{ marginBottom: "10px" }}>
                <Typography sx={{ color: COLORS.textGray, marginBottom: "5px" }}>Phone:</Typography>
                <Typography sx={{ wordWrap: "break-word" }}>{invoiceData?.senderPhone ?? "-"}</Typography>
              </Box>
            </>
          ) : (
            <VExpenseEmployeeDetails />
          )}

          {isBill && (
            <>
              {showAdditionalMappingsSkeleton ? (
                <Skeleton variant="rounded" sx={{ height: "80px" }} />
              ) : (
                <>
                  <Divider sx={{ marginBottom: "10px" }} />

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "5px",
                      alignItems: "flex-start",
                      maxWidth: "200px",
                    }}
                  >
                    <Typography sx={{ fontSize: 16, fontWeight: 500, color: COLORS.logoBlue }}>
                      VENDOR DIFFERENCE: <ErrorOutlineIcon sx={{ color: COLORS.textGray, fontSize: "14px" }} />
                    </Typography>

                    {showVendorMappingSkeleton ? (
                      <Skeleton variant="rounded" sx={{ height: "20px", width: "100%" }} />
                    ) : (
                      vendorMappingStoreItem && (
                        <>
                          <Button
                            onClick={(event) => setPopoverAnchorEl(event.currentTarget)}
                            aria-describedby={moreDetailsPopoverId}
                            variant="text"
                            endIcon={
                              moreDetailsPopoverVisible ? (
                                <ExpandLessIcon sx={expandIconStyles} />
                              ) : (
                                <ExpandMoreIcon sx={expandIconStyles} />
                              )
                            }
                            sx={{
                              padding: "0",
                              height: "auto",
                              fontSize: "12px",
                              "&:hover": { textDecoration: "underline" },
                            }}
                          >
                            Details
                          </Button>
                          <Popover
                            id={moreDetailsPopoverId}
                            open={moreDetailsPopoverVisible}
                            anchorEl={moreDetailsPopoverAnchorEl}
                            onClose={() => setPopoverAnchorEl(null)}
                            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                          >
                            <Box sx={{ padding: "20px 10px 10px", position: "relative" }}>
                              <Tooltip arrow title="Close">
                                <IconButton
                                  onClick={() => setPopoverAnchorEl(null)}
                                  sx={{ position: "absolute", top: "5px", right: "5px", zIndex: 1, padding: "2px" }}
                                >
                                  <CloseIcon sx={{ fontSize: "14px" }} />
                                </IconButton>
                              </Tooltip>
                              <table>
                                <tbody>
                                  <VBillVendorDifferenceLine
                                    rowName="Name:"
                                    integrationText={vendorMappingStoreItem.data?.contact?.name ?? "-"}
                                    codingText={invoiceData?.senderName ?? ""}
                                  />

                                  <VBillVendorDifferenceLine
                                    rowName="Address:"
                                    integrationText={vendorMappingStoreItem.data?.contact?.address ?? "-"}
                                    codingText={invoiceData?.senderAddress ?? ""}
                                  />

                                  <VBillVendorDifferenceLine
                                    rowName="Email:"
                                    integrationText={vendorMappingStoreItem.data?.contact?.email ?? "-"}
                                    codingText={invoiceData?.senderEmail ?? ""}
                                  />

                                  <VBillVendorDifferenceLine
                                    rowName="Phone:"
                                    integrationText={vendorMappingStoreItem.data?.contact?.phone ?? "-"}
                                    codingText={invoiceData?.senderPhone ?? ""}
                                  />
                                </tbody>
                              </table>
                            </Box>
                          </Popover>
                        </>
                      )
                    )}
                  </Box>
                </>
              )}
            </>
          )}
        </Box>

        <Divider flexItem orientation="vertical" />

        <Box sx={{ flex: "1", minWidth: "0px" }}>
          <Box sx={{ marginBottom: "10px" }}>
            <Typography sx={{ fontSize: 16, marginBottom: "5px", fontWeight: 500 }} color={COLORS.logoBlue}>
              COMPANY:
            </Typography>

            <Typography>{invoiceCompanyName}</Typography>
          </Box>

          {showAdditionalMappingsSkeleton ? (
            <Skeleton variant="rounded" sx={{ height: "100px" }} />
          ) : (
            invoiceAdditionalMappings.map((invoiceVendorMapping) => (
              <Box key={invoiceVendorMapping.key} sx={{ marginBottom: "10px" }}>
                <Typography
                  sx={{ fontSize: 16, marginBottom: "5px", fontWeight: 500, textTransform: "uppercase" }}
                  color={COLORS.logoBlue}
                >
                  {invoiceVendorMapping.label ?? invoiceVendorMapping.storeDataType}:
                </Typography>

                <Typography sx={{ wordBreak: "break-word" }}>{`${
                  invoiceMappings[invoiceVendorMapping.storeDataType]?.label ?? "-"
                }${
                  invoiceMappings[invoiceVendorMapping.storeDataType]?.label2
                    ? ` - ${invoiceMappings[invoiceVendorMapping.storeDataType]?.label2}`
                    : ""
                }`}</Typography>
              </Box>
            ))
          )}
        </Box>

        <Divider flexItem orientation="vertical" />

        <Box sx={{ flex: "1", minWidth: "0px" }}>
          {isBill && (
            <Box sx={{ marginBottom: "10px" }}>
              <Typography sx={{ fontSize: 16, marginBottom: "5px", fontWeight: 500 }} color={COLORS.logoBlue}>
                BILL INFO:
              </Typography>

              <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                <Box sx={{ flex: "1" }}>
                  <Typography sx={{ color: COLORS.textGray, marginBottom: "5px" }}>Bill Date:</Typography>
                  <Typography>{getVBillFormattedDate(invoiceData?.invoiceDate)}</Typography>
                </Box>

                <Box sx={{ flex: "1" }}>
                  <Typography sx={{ color: COLORS.textGray, marginBottom: "5px" }}>Bill Due:</Typography>
                  <Typography>{getVBillFormattedDate(invoiceData?.invoiceDateDue)}</Typography>
                </Box>
              </Box>
            </Box>
          )}

          {showAdditionalMappingsSkeleton ? (
            <Skeleton variant="rounded" sx={{ height: "100px" }} />
          ) : (
            !hasLineItems && (
              <>
                {isBill && <Divider sx={{ marginBottom: "10px" }} />}

                <Typography
                  sx={{
                    fontSize: 16,
                    marginBottom: "5px",
                    fontWeight: 500,
                    color: COLORS.logoBlue,
                  }}
                >
                  MAPPINGS
                </Typography>

                {oneLineItemGlAccMapping.map((additionalMapping) => (
                  <Box key={additionalMapping.key} sx={{ marginBottom: "10px" }}>
                    <Typography sx={{ marginBottom: "5px", color: COLORS.logoBlue }}>
                      {additionalMapping.label}:
                    </Typography>

                    <Typography sx={{ wordBreak: "break-word" }}>{`${
                      oneLineItemMappings[additionalMapping.storeDataType]?.label ?? "-"
                    }${
                      oneLineItemMappings[additionalMapping.storeDataType]?.label2
                        ? ` - ${oneLineItemMappings[additionalMapping.storeDataType]?.label2}`
                        : ""
                    }`}</Typography>
                  </Box>
                ))}

                {oneLineItemAdditionalMappings.map((additionalMapping) => (
                  <Box key={additionalMapping.key} sx={{ marginBottom: "10px" }}>
                    <Typography sx={{ fontSize: 16, marginBottom: "5px", color: COLORS.logoBlue }}>
                      {additionalMapping.label}:
                    </Typography>

                    <Typography sx={{ wordBreak: "break-word" }}>{`${
                      oneLineItemMappings[additionalMapping.storeDataType]?.label ?? "-"
                    }${
                      oneLineItemMappings[additionalMapping.storeDataType]?.label2
                        ? ` - ${oneLineItemMappings[additionalMapping.storeDataType]?.label2}`
                        : ""
                    }`}</Typography>
                  </Box>
                ))}
              </>
            )
          )}
        </Box>
      </Box>
    </>
  );
});
