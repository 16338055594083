import { graphqlCheckBatchingClient } from "common/graphqlClient";
import {
  CheckBatchStateEnum,
  CreateCheckBatchItemsMutationMutation,
  CreateCheckBatchMembersMutationMutation,
  CreateCheckBatchMutationMutation,
  DeleteCheckBatchItemsMutationMutation,
  DeleteCheckBatchMembersMutationMutation,
  DeleteCheckBatchMutationMutation,
  CheckFiltersType,
  GetAvailableChecksForBatchQueryQuery,
  GetCheckBatchAvailableMembersQueryQuery,
  GetCheckBatchMembersQueryQuery,
  GetCheckBatchQueryQuery,
  GetCheckBatchesFiltersType,
  GetCheckBatchesQueryQuery,
  InputMaybe,
  PaginationRequestType,
  SignCheckBatchMutationMutation,
  ReactionEnumType,
  UpdateCheckBatchItemsReactionMutationMutation,
  UpdateCheckBatchMutationMutation,
  getSdk,
  GetCheckDetailsQueryQuery,
  CheckDetailsScopeEnum,
} from "generated/sdk";

import { definitely } from "generated/utils";
import { createObservableContainer } from "storeContainer";
import { StoreBase } from "./StoreBase";
import { GetAvailableBankAccountsForBatchQueryQuery } from "generated/graphql";
import { filter } from "lodash";

const {
  GetCheckBatchQuery,
  GetCheckBatchesQuery,
  GetAvailableChecksForBatchQuery,
  CreateCheckBatchMutation,
  DeleteCheckBatchMutation,
  UpdateCheckBatchMutation,
  UpdateCheckBatchItemsReactionMutation,
  CreateCheckBatchItemsMutation,
  DeleteCheckBatchItemsMutation,
  SignCheckBatchMutation,
  DeleteCheckBatchMembersMutation,
  GetCheckBatchAvailableMembersQuery,
  GetCheckBatchMembersQuery,
  CreateCheckBatchMembersMutation,
  GetAvailableBankAccountsForBatchQuery,
  GetCheckDetailsQuery,
} = getSdk(graphqlCheckBatchingClient);

class BudgetManagementStore extends StoreBase {
  availableChecksForBatchQuery = createObservableContainer<GetAvailableChecksForBatchQueryQuery>();
  availableBankAccountsForBatchQuery = createObservableContainer<GetAvailableBankAccountsForBatchQueryQuery>();
  availableMembers = createObservableContainer<GetCheckBatchAvailableMembersQueryQuery>();
  checkBatchData = createObservableContainer<GetCheckBatchQueryQuery>();
  checkBatches = createObservableContainer<GetCheckBatchesQueryQuery["GetCheckBatchesQuery"]>();
  createdCheckBatch = createObservableContainer<CreateCheckBatchMutationMutation>();
  createdCheckBatchItems = createObservableContainer<CreateCheckBatchItemsMutationMutation>();
  deletedCheckBatchItems = createObservableContainer<DeleteCheckBatchItemsMutationMutation>();
  updatedCheckBatch = createObservableContainer<UpdateCheckBatchMutationMutation>();
  updatedCheckBatchItemReaction = createObservableContainer<UpdateCheckBatchItemsReactionMutationMutation>();
  signedBatchData = createObservableContainer<SignCheckBatchMutationMutation>();
  deleteBatchResponse = createObservableContainer<DeleteCheckBatchMutationMutation>();
  removedCheckBatchMember = createObservableContainer<DeleteCheckBatchMembersMutationMutation>();
  invitedMembers = createObservableContainer<GetCheckBatchMembersQueryQuery>();
  inviteMembers = createObservableContainer<CreateCheckBatchMembersMutationMutation>();
  pollingInterval?: NodeJS.Timeout;
  checkDetailsData = createObservableContainer<GetCheckDetailsQueryQuery>();

  signBatch = async (id: string, forceUpdate?: boolean) =>
    this.signedBatchData.cachedLoad(
      async () => definitely(await SignCheckBatchMutation({ check_batch_id: id })),
      [id],
      { forceUpdate },
    );

  fetchCheckBatch = async (
    id: string,
    filters?: CheckFiltersType,
    pagination?: PaginationRequestType,
    forceUpdate?: boolean,
  ) =>
    this.checkBatchData.cachedLoad(
      async () => definitely(await GetCheckBatchQuery({ check_batch_id: id, filters, pagination })),
      [id, pagination, filters],
      { forceUpdate },
    );

  fetchRawCheckBatch = async (id: string, filters?: CheckFiltersType, pagination?: PaginationRequestType) => {
    const result = definitely(await GetCheckBatchQuery({ check_batch_id: id, filters, pagination }));

    return result?.GetCheckBatchQuery;
  };

  fetchCheckBatches = async (
    organizationId: string,
    filters?: InputMaybe<GetCheckBatchesFiltersType> | undefined,
    pagination?: InputMaybe<PaginationRequestType> | undefined,
    forceUpdate?: boolean,
  ) => {
    return await this.checkBatches.cachedLoad(
      async () =>
        definitely(await GetCheckBatchesQuery({ organization_id: organizationId, filters, pagination }))
          ?.GetCheckBatchesQuery,
      [organizationId, filters, pagination],
      { forceUpdate },
    );
  };

  fetchCheckDetails = async (
    check_batch_id: string,
    check_id: string,
    check_details_scope: CheckDetailsScopeEnum,
    forceUpdate?: boolean,
  ) => {
    return await this.checkDetailsData.cachedLoad(
      async () => definitely(await GetCheckDetailsQuery({ check_batch_id, check_id, check_details_scope })),
      [check_batch_id, check_id],
      { forceUpdate },
    );
  };

  fetchAvailableMembers = async (
    check_batch_id: string,
    pagination?: InputMaybe<PaginationRequestType> | undefined,
    search?: string,
    forceUpdate?: boolean,
  ) =>
    this.availableMembers.cachedLoad(
      async () => definitely(await GetCheckBatchAvailableMembersQuery({ check_batch_id, pagination, search })),
      [check_batch_id, pagination],
      { forceUpdate },
    );

  fetchInvitedMembers = async (
    check_batch_id: string,
    pagination?: InputMaybe<PaginationRequestType> | undefined,
    search?: string,

    forceUpdate?: boolean,
  ) =>
    this.invitedMembers.cachedLoad(
      async () => definitely(await GetCheckBatchMembersQuery({ check_batch_id, pagination, search })),
      [check_batch_id, pagination],
      { forceUpdate },
    );

  fetchAvailableChecksForBatchQuery = async (
    check_batch_id: string,
    pagination?: InputMaybe<PaginationRequestType> | undefined,
    filters?: InputMaybe<CheckFiltersType> | undefined,
    forceUpdate?: boolean,
  ) => {
    if (this.checkBatchData?._data?.GetCheckBatchQuery?.state === CheckBatchStateEnum.Closed) {
      return;
    }
    return this.availableChecksForBatchQuery.cachedLoad(
      async () => definitely(await GetAvailableChecksForBatchQuery({ check_batch_id, pagination, filters })),
      [check_batch_id, filters],
      { forceUpdate },
    );
  };

  fetchRawAvailableChecksForBatchQuery = async (
    check_batch_id: string,
    pagination?: InputMaybe<PaginationRequestType> | undefined,
    filters?: InputMaybe<CheckFiltersType> | undefined,
  ) => {
    const result = definitely(await GetAvailableChecksForBatchQuery({ check_batch_id, pagination, filters }));
    return result?.GetAvailableChecksForBatchQuery;
  };

  fetchAvailableBankAccountsForBatchQuery = async (
    check_batch_id: string,
    pagination?: InputMaybe<PaginationRequestType> | undefined,
    search?: InputMaybe<string> | undefined,
    forceUpdate?: boolean,
  ) => {
    if (this.checkBatchData?._data?.GetCheckBatchQuery?.state === CheckBatchStateEnum.Closed) {
      return;
    }
    return this.availableBankAccountsForBatchQuery.cachedLoad(
      async () => definitely(await GetAvailableBankAccountsForBatchQuery({ check_batch_id, pagination, search })),
      [check_batch_id, pagination, search],
      { forceUpdate },
    );
  };

  createCheckBatch = async (
    company_id: string,
    name: string,
    budget?: InputMaybe<number> | undefined,
    forceUpdate?: boolean,
  ) =>
    this.createdCheckBatch.cachedLoad(
      async () => definitely(await CreateCheckBatchMutation({ company_id, name, budget })),
      [company_id, name, budget],
      { forceUpdate },
    );
  createCheckBatchItems = async (
    check_batch_id: string,
    checks?: string[],
    filters?: any | undefined,
    forceUpdate?: boolean,
  ) =>
    this.createdCheckBatch.cachedLoad(
      async () => definitely(await CreateCheckBatchItemsMutation({ check_batch_id, checks, filters })),
      [check_batch_id, checks],
      { forceUpdate },
    );

  deleteCheckBatchItems = async (check_batch_id: string, checks: string[], forceUpdate?: boolean) =>
    this.createdCheckBatch.cachedLoad(
      async () => definitely(await DeleteCheckBatchItemsMutation({ check_batch_id, checks })),
      [check_batch_id, checks],
      { forceUpdate },
    );

  updateCheckBatch = async (
    check_batch_id: string,
    name?: InputMaybe<string> | undefined,
    budget?: InputMaybe<number> | undefined,
    forceUpdate?: boolean,
  ) =>
    this.updatedCheckBatch.cachedLoad(
      async () => definitely(await UpdateCheckBatchMutation({ check_batch_id, name, budget })),
      [check_batch_id, name, budget],
      { forceUpdate },
    );

  updateCheckBatchItemReaction = async (
    check_batch_item_ids: string[],
    reaction: ReactionEnumType,
    forceUpdate?: boolean,
  ) =>
    this.updatedCheckBatchItemReaction.cachedLoad(
      async () => definitely(await UpdateCheckBatchItemsReactionMutation({ check_batch_item_ids, reaction })),
      [check_batch_item_ids, reaction],
      { forceUpdate },
    );

  deleteCheckBatch = async (check_batch_id: string, forceUpdate?: boolean) =>
    this.deleteBatchResponse.cachedLoad(
      async () => definitely(await DeleteCheckBatchMutation({ check_batch_id })),
      [check_batch_id],
      { forceUpdate },
    );
  removeCheckBatchMember = async (check_batch_id: string, organization_user_ids: string[], forceUpdate?: boolean) =>
    this.removedCheckBatchMember.cachedLoad(
      async () => definitely(await DeleteCheckBatchMembersMutation({ check_batch_id, organization_user_ids })),
      [check_batch_id, organization_user_ids],
      { forceUpdate },
    );
  addCheckBatchMember = async (check_batch_id: string, organization_user_ids: string[], forceUpdate?: boolean) =>
    this.inviteMembers.cachedLoad(
      async () => definitely(await CreateCheckBatchMembersMutation({ check_batch_id, organization_user_ids })),
      [check_batch_id, organization_user_ids],
      { forceUpdate },
    );
}

export default BudgetManagementStore;
