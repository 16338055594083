import { LoadingButton } from "@mui/lab";
import { Box, Tooltip } from "@mui/material";
import { graphqlVBillClient } from "common/graphqlClient";
import { useVBillCodingLedgerQuickFilterUrl } from "components/pages/VBillCodingLedger/hooks";
import { VBillCodingLedgerChildRoutes } from "components/pages/VBillCodingLedger/utils";
import {
  IVBillInvoiceIncomingStatus,
  IVBillInvoiceUserActionShowIn,
  IVBillInvoiceUserActionType,
  IVBillNullableInvoiceInput,
  IVBillRelatedInvoiceSchema,
  getSdk,
} from "generated/sdk.vbill";
import { isNil } from "lodash";
import { observer } from "mobx-react";
import { useMemo, useState } from "react";
import { SubmitHandler, useFormContext } from "react-hook-form";
import { useHistory } from "react-router";
import { useStore } from "storeContainer";
import { COLORS } from "themes/default";
import { DuplicatesModal } from "../../VBillIncoming/Duplicates/DuplicatesModal";
import { DuplicatesModalSavingActions } from "../../VBillIncoming/Duplicates/DuplicatesModalSavingActions";
import { ToolbarActionsMenu } from "../../common/VBillToolbar/ToolbarActionsMenu/ToolbarActionsMenu";
import { VBillToolbarBackBtn } from "../../common/VBillToolbar/VBillToolbarBackBtn/VBillToolbarBackBtn";
import { VBillToolbarBillIdInfo } from "../../common/VBillToolbar/VBillToolbarBillIdInfo/VBillToolbarBillIdInfo";
import { VBillToolbarFormErrors } from "../../common/VBillToolbar/VBillToolbarFormErrors/VBillToolbarFormErrors";
import { VBillToolbarStatusDetails } from "../../common/VBillToolbar/VBillToolbarStatusDetails/VBillToolbarStatusDetails";
import { vBillToolbarInnerSx, vBillToolbarLeftColSx, vBillToolbarOuterSx } from "../../common/VBillToolbar/styles";
import { TVBillFormFields } from "../../types";
import { adaptNewVBillFormToInvoiceInputType } from "./utils";

const { VBillValidateInvoice, VBillCheckInvoiceForDuplicates } = getSdk(graphqlVBillClient);

export const NewToolbar = observer(() => {
  const vBillStore = useStore("VBillStore");
  const { invoice } = vBillStore;
  const invoiceData = useMemo(() => invoice.data, [invoice.data]);
  const formMethods = useFormContext<TVBillFormFields>();
  const history = useHistory();
  const [isSaveDraftReqPending, setIsSaveDraftReqPending] = useState(false);
  const [isStartApprovalReqPending, setIsStartApprovalReqPending] = useState(false);
  const [showDuplicatesModal, setShowDuplicatesModal] = useState<boolean>(false);
  const [duplicates, setDuplicates] = useState<IVBillRelatedInvoiceSchema[]>([]);
  const vbillCodingLedgerCodingUrl = useVBillCodingLedgerQuickFilterUrl(VBillCodingLedgerChildRoutes.CODING);
  const submitBtns = useMemo(
    () =>
      (invoiceData?.nextUserActions ?? [])
        .filter(
          (action) =>
            action.showIn === IVBillInvoiceUserActionShowIn.InvoiceDetails &&
            (action.type === IVBillInvoiceUserActionType.SaveDraft ||
              action.type === IVBillInvoiceUserActionType.StartApproval),
        )
        .sort((a) => (a.type === IVBillInvoiceUserActionType.SaveDraft ? -1 : 1)),
    [invoiceData?.nextUserActions],
  );

  const validateInvoice = async (
    formFields: TVBillFormFields,
    invoiceData: IVBillNullableInvoiceInput,
    invoiceId: number,
  ) => {
    const validateInvoiceResp = await VBillValidateInvoice({ invoiceId, invoiceData });

    // invoice
    const invoiceRequiredFieldsAndMappingsError = [
      ...(validateInvoiceResp?.validateInvoice.validationErrors?.invoice.fields.required ?? []),
      ...(validateInvoiceResp?.validateInvoice.validationErrors?.invoice.mappings.required ?? []),
    ];
    const invoiceCustomFieldsError =
      validateInvoiceResp?.validateInvoice.validationErrors?.invoice.fields.custom?.fields ?? [];
    const invoiceCustomMappingsError =
      validateInvoiceResp?.validateInvoice.validationErrors?.invoice.mappings.custom?.fields ?? [];

    Object.keys(formFields).forEach((fieldKey) => {
      if (invoiceCustomFieldsError.includes(fieldKey)) {
        formMethods.setError(fieldKey as keyof TVBillFormFields, {
          type: "custom",
          message: validateInvoiceResp?.validateInvoice.validationErrors?.invoice.fields.custom?.message,
        });

        return;
      } else if (invoiceCustomMappingsError.includes(fieldKey)) {
        formMethods.setError(fieldKey as keyof TVBillFormFields, {
          type: "custom",
          message: validateInvoiceResp?.validateInvoice.validationErrors?.invoice.mappings.custom?.message,
        });

        return;
      } else if (invoiceRequiredFieldsAndMappingsError.includes(fieldKey)) {
        formMethods.setError(fieldKey as keyof TVBillFormFields, {
          type: "required",
          message: `${fieldKey} is required.`,
        });
      }
    });

    // line items

    let lineItemsError = false;
    formFields.lineItems?.forEach((lineItem, index) => {
      const lineItemKeys = Object.keys(lineItem);

      for (const fieldKey of lineItemKeys) {
        const lineErrors = validateInvoiceResp.validateInvoice.validationErrors?.lineItems?.find(
          ({ key }) => key === lineItem.lineItemId,
        );

        if (!lineErrors) {
          break;
        }

        lineItemsError = true;

        const lineItemCustomFieldsError = lineErrors?.fields.custom?.fields;
        const lineItemCustomGLMappingsError = lineErrors?.glMapping.mappings.custom?.fields;
        const lineItemRequiredFieldsAndMappingsError = [
          ...(lineErrors?.fields.required ?? []),
          ...(lineErrors?.glMapping.mappings.required ?? []),
          ...(lineErrors?.glMapping.fields.required ?? []),
        ];

        if (lineItemCustomFieldsError?.includes(fieldKey)) {
          formMethods.setError(`lineItems.${index}.${fieldKey}` as unknown as keyof TVBillFormFields, {
            type: "custom",
            message: lineErrors?.fields.custom?.message,
          });

          break;
        } else if (lineItemCustomGLMappingsError?.includes(fieldKey)) {
          formMethods.setError(`lineItems.${index}.${fieldKey}` as unknown as keyof TVBillFormFields, {
            type: "custom",
            message: lineErrors?.glMapping.mappings.custom?.message,
          });

          break;
        } else if (lineItemRequiredFieldsAndMappingsError.includes(fieldKey)) {
          formMethods.setError(`lineItems.${index}.${fieldKey}` as unknown as keyof TVBillFormFields, {
            type: "required",
            message: `${fieldKey} is required.`,
          });
        }
      }
    });

    if (
      invoiceRequiredFieldsAndMappingsError.length ||
      invoiceCustomFieldsError.length ||
      invoiceCustomMappingsError.length ||
      lineItemsError
    ) {
      return false;
    }

    return true;
  };

  const handleSaveDraftSubmit = async () => {
    const formFields = formMethods.getValues();

    setIsSaveDraftReqPending(true);

    const toSendInvoiceData = adaptNewVBillFormToInvoiceInputType(formFields, invoiceData?.id ?? 0);

    await vBillStore.updateInvoiceAndMappings({
      invoiceId: invoiceData?.id ?? 0,
      invoiceData: toSendInvoiceData,
      skipAdditionalMappingsValidations: isNil(invoiceData?.companyId),
      saveDraft: true,
    });
    setIsSaveDraftReqPending(false);
    formMethods.clearErrors();
  };

  const handleStartApprovalSubmit: SubmitHandler<TVBillFormFields> = async (formFields) => {
    setIsStartApprovalReqPending(true);
    const toSendInvoiceData = adaptNewVBillFormToInvoiceInputType(formFields, invoiceData?.id ?? 0);
    const isValidInvoice = await validateInvoice(formFields, toSendInvoiceData, invoiceData?.id ?? 0);

    if (!isValidInvoice) {
      setIsStartApprovalReqPending(false);

      return;
    }

    const duplicatesResponse = await VBillCheckInvoiceForDuplicates({
      invoiceId: invoiceData?.id ?? 0,
      invoiceData: toSendInvoiceData,
    });

    if (duplicatesResponse.checkInvoiceForDuplicates.length) {
      setShowDuplicatesModal(true);
      setDuplicates(duplicatesResponse.checkInvoiceForDuplicates as IVBillRelatedInvoiceSchema[]);
      setIsStartApprovalReqPending(false);

      return;
    }

    const updateResponse = await vBillStore.updateInvoiceAndMappings({
      invoiceId: invoiceData?.id ?? 0,
      invoiceData: toSendInvoiceData,
      skipAdditionalMappingsValidations: false,
    });

    if (updateResponse?.status !== IVBillInvoiceIncomingStatus.Mapped) {
      setIsStartApprovalReqPending(false);

      return;
    }

    const startApprovalResponse = await vBillStore.startApprovalForInvoice({ invoiceId: invoiceData?.id ?? 0 });

    if (startApprovalResponse?.status !== IVBillInvoiceIncomingStatus.PendingApproval) {
      setIsStartApprovalReqPending(false);

      return;
    }

    setIsStartApprovalReqPending(false);
    history.push(vbillCodingLedgerCodingUrl);
  };

  const hideDuplicatesModal = () => {
    setShowDuplicatesModal(false);
  };

  return (
    <>
      <Box sx={vBillToolbarOuterSx}>
        <Box sx={vBillToolbarInnerSx}>
          <Box sx={vBillToolbarLeftColSx}>
            <VBillToolbarBackBtn />

            <VBillToolbarBillIdInfo />

            <VBillToolbarStatusDetails />
          </Box>
          <Box sx={{ flexGrow: "1", display: "flex", justifyContent: "space-between", gap: "10px" }}>
            {submitBtns.map((action) => (
              <Tooltip arrow title={action.description} key={action.type}>
                <LoadingButton
                  loading={
                    action.type === IVBillInvoiceUserActionType.SaveDraft
                      ? isSaveDraftReqPending
                      : isStartApprovalReqPending
                  }
                  onClick={() =>
                    action.type === IVBillInvoiceUserActionType.SaveDraft
                      ? handleSaveDraftSubmit()
                      : formMethods.handleSubmit(handleStartApprovalSubmit)()
                  }
                  variant={action.type === IVBillInvoiceUserActionType.SaveDraft ? "outlined" : "contained"}
                  sx={{
                    ...(action.type === IVBillInvoiceUserActionType.SaveDraft
                      ? {
                          border: "1px solid rgba(40, 119, 236, 0.25) !important",
                          color: COLORS.newSecondaryColor,
                          fontWeight: "500",
                        }
                      : {}),
                    height: "32px",
                  }}
                >
                  {action.displayName}
                </LoadingButton>
              </Tooltip>
            ))}
          </Box>

          <ToolbarActionsMenu />
        </Box>

        <VBillToolbarFormErrors />
      </Box>

      {showDuplicatesModal && invoiceData && (
        <DuplicatesModal
          invoiceId={invoiceData?.id ?? 0}
          isOpen={showDuplicatesModal}
          onClose={() => hideDuplicatesModal()}
          duplicates={duplicates}
        >
          <DuplicatesModalSavingActions
            invoice={invoiceData}
            onClose={() => hideDuplicatesModal()}
            reloadInvoicesList={() => vBillStore.invoice?.reload?.()}
          />
        </DuplicatesModal>
      )}
    </>
  );
});
