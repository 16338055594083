import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, FormControl, FormHelperText, SxProps, Theme, Tooltip, Typography } from "@mui/material";
import { ExpandLessIcon, ExpandMoreIcon } from "components/common/icons";
import { TVBillFormAutoComOption } from "components/pages/VBill/types";
import { IVBillSingleSelectAutocompleteSuggestion } from "components/pages/common/VBill/VBillSuggestionsAutocomplete/VBillSuggestionsSingleSelectAutocomplete/VBillSuggestionsSingleSelectAutocomplete";
import { IVBillIntegrationSettingsVBillAdditionalMappingsEntryOutput } from "generated/sdk.vbill";
import { useState } from "react";
import { useController } from "react-hook-form";
import { VBillMappingAutocompleteSelector } from "../../VBillMappingAutocompleteSelector/VBillMappingAutocompleteSelector";
const expandIconStyles: SxProps<Theme> = { fontSize: "16px !important", marginRight: "4px" };

interface IInvoiceMappingFieldSelectorProps {
  invoiceCoreOrgId: string;
  invoiceCompanyId: string;
  formFieldName: string;
  additionalMappings: IVBillIntegrationSettingsVBillAdditionalMappingsEntryOutput;
}

export const InvoiceMappingFieldSelector = ({
  invoiceCoreOrgId,
  invoiceCompanyId,
  formFieldName,
  additionalMappings,
  additionalMappings: {
    label,
    storeDataType, // also as form field key name
    key,
    required,
  },
}: IInvoiceMappingFieldSelectorProps) => {
  const {
    field: { value: formFieldValue = null, onChange: formFieldOnChange },
    fieldState: { error },
  } = useController({
    name: formFieldName,
    rules: { required: required ?? false },
  });
  const [isAutocompleteDialogOpen, setIsAutocompleteDialogOpen] = useState(false);

  const handleClearBtnClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    formFieldOnChange(null);
  };

  const handleAutocompleteSelect = async (option: IVBillSingleSelectAutocompleteSuggestion) => {
    setIsAutocompleteDialogOpen(false);

    const currentValue: TVBillFormAutoComOption = {
      label: option?.name ?? "",
      label2: option.label,
      addMappingSettingKey: key,
      key: option?.id ?? "",
    };

    formFieldOnChange(currentValue);
  };

  return (
    <>
      <Button
        variant="outlined"
        onClick={() => setIsAutocompleteDialogOpen(true)}
        endIcon={
          <Box sx={{ display: "flex", gap: "5px" }}>
            {formFieldValue && (
              <Tooltip arrow title="Clear value">
                <span onClick={handleClearBtnClick}>
                  <CloseIcon />
                </span>
              </Tooltip>
            )}
            <Tooltip arrow title="Open">
              <span>
                {isAutocompleteDialogOpen ? (
                  <ExpandLessIcon sx={expandIconStyles} />
                ) : (
                  <ExpandMoreIcon sx={expandIconStyles} />
                )}
              </span>
            </Tooltip>
          </Box>
        }
        sx={{
          width: "100%",
          justifyContent: "space-between",
          textAlign: "left",
          height: "32px",
          ...(error ? { borderColor: "rgb(176, 0, 32) !important" } : {}),
        }}
      >
        <Typography noWrap>
          {formFieldValue
            ? `${formFieldValue.label} ${formFieldValue.label2 ? `- ${formFieldValue.label2}` : ""}`
            : "Select"}
        </Typography>
      </Button>

      {error && error.type !== "required" && (
        <FormControl error fullWidth>
          <FormHelperText sx={{ margin: 0, lineHeight: "15px" }}>{error.message}.</FormHelperText>
        </FormControl>
      )}

      {isAutocompleteDialogOpen && (
        <VBillMappingAutocompleteSelector
          suggestionsComponent="dialog"
          additionalMappings={additionalMappings}
          invoiceCoreOrgId={invoiceCoreOrgId}
          invoiceCompanyId={invoiceCompanyId}
          onCloseAutocomplete={() => setIsAutocompleteDialogOpen(false)}
          onAutocompleteSelect={handleAutocompleteSelect}
          autocompleteSelectedSearchTerm={formFieldValue?.label ?? ""}
        />
      )}
    </>
  );
};
