import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Backdrop, InputAdornment, Menu, TextField, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import classNames from "classnames";
import { CheckQuickFiltersEnumType, type Organization } from "generated/sdk";
import { SearchIcon } from "icons/svg/SearchIcon";
import Cookies from "js-cookie";
import { observer } from "mobx-react-lite";
import { ComponentProps, useCallback, useEffect, useMemo, useState } from "react";
import { matchPath, useHistory } from "react-router-dom";
import { useStore } from "storeContainer";
import { Spinner } from "../../../common/Spinner/Spinner";
import styles from "./OrganizationSelector.module.scss";

export const OrganizationSelector = observer(function OrganizationSelector(
  props: Omit<ComponentProps<typeof OrganizationSelectorComponent>, "organization" | "organizations">,
) {
  const session = useStore("SessionStore");
  const organizationId = matchPath<{ organizationId?: string }>(window.location.pathname, {
    path: "/org/:organizationId",
  })?.params?.organizationId;
  const lastOrgId = Cookies.get("lastOrg");
  const currentOrgId = organizationId ?? lastOrgId;
  const selectedOrganization = session.organizations.find((o) => o.id === currentOrgId);
  const depositAccountsCount = session.session.data?.deposit_accounts || 0;
  const phoneConfirmed = session.session.data?.account?.phone_number_verified;

  return phoneConfirmed && !(session.organizations.length < 1 && depositAccountsCount < 1) ? (
    <OrganizationSelectorComponent
      organizations={session.organizations}
      organization={selectedOrganization}
      isLoading={session.session.isLoading}
      {...props}
    />
  ) : null;
});

const OrganizationSelectorComponent = observer(function OrganizationSelectorComponent({
  organization,
  organizations,
  isLoading,
}: {
  className?: string;
  organization?: Organization;
  organizations: Organization[];
  isLoading?: boolean;
}) {
  const OrganizationStore = useStore("OrganizationStore");
  const sessionStore = useStore("SessionStore");
  const ContextStore = useStore("ContextStore");
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const mockOrganizationIdForDepositAccounts = "-1";
  const urlForDepositAccounts = "/depositAccounts/ledger";
  const open = Boolean(anchorEl);
  const regex = new RegExp(searchTerm, "i");
  const lastOrdIdCookieName = "lastOrg";
  const depositAccountsCount = sessionStore.session.data?.deposit_accounts || 0;
  const depositAccountMockOrganization: Organization | undefined = useMemo(
    () =>
      depositAccountsCount
        ? {
            id: mockOrganizationIdForDepositAccounts,
            name: `Deposit Accounts (${depositAccountsCount})`,
            companies: [],
          }
        : undefined,
    [depositAccountsCount],
  );
  const organizationAndDepositBankaccounts = depositAccountMockOrganization
    ? [...organizations, depositAccountMockOrganization]
    : organizations;
  let initialOrganization = organization;

  const initialOptions = organizationAndDepositBankaccounts.map((organization) => ({
    id: organization.id,
    name: organization.name,
  }));
  const [options, setOptions] = useState(initialOptions);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
  };
  const handleSelection = useCallback(
    (option) => {
      if (option.id) {
        if (option.id === mockOrganizationIdForDepositAccounts) {
          history.push(`${urlForDepositAccounts}/ready_to_deposit`);
          ContextStore.setSelectedOrganizationId(undefined);
          Cookies.remove(lastOrdIdCookieName);
        } else {
          ContextStore.setSelectedOrganizationId(option.id);
          ContextStore.setSelectedCompanyId(undefined);
          Cookies.set(lastOrdIdCookieName, option.id);
          OrganizationStore.loadPendingActions(
            {
              per_page: 10,
              page: 1,
            },
            false,
          );
          history.push(`/org/${option.id}/vchecks/payments/${CheckQuickFiltersEnumType.PendingSignature}`);
        }
      }
      setAnchorEl(null);
    },
    [ContextStore, OrganizationStore, history],
  );

  const closeIconStyle = {
    height: "16x",
    width: "16px",
    cursor: "pointer",
  };

  if (ContextStore.selectedOrganizationId) {
    initialOrganization = organizations.find((org) => org.id === ContextStore.selectedOrganizationId) as Organization;
  }

  if (history.location.pathname.startsWith("/depositAccounts")) {
    initialOrganization = depositAccountMockOrganization as Organization;
  }

  useEffect(() => {
    if (!options) {
      setOptions(initialOptions);
    }
  }, [initialOptions, options]);

  useEffect(() => {
    const orgs = depositAccountMockOrganization ? [...organizations, depositAccountMockOrganization] : organizations;
    const options = orgs.map((organization) => ({
      id: organization.id,
      name: organization.name,
    }));
    setOptions(options);
  }, [depositAccountMockOrganization, organizations]);

  return (
    <>
      <Box sx={{ borderRight: "1px solid #DFE7F2", maxWidth: "180px", paddingRight: "20px" }}>
        <Button
          onClick={handleClick}
          variant="text"
          disableRipple
          disableElevation
          disableFocusRipple
          disableTouchRipple
          disabled={organizationAndDepositBankaccounts.length === 1 && !!initialOrganization?.name}
          endIcon={organizationAndDepositBankaccounts.length !== 1 && <KeyboardArrowDownIcon />}
          className={styles.organizationSelectionButton}
        >
          <Typography
            className={styles.organizationSelectionButtonText}
            title={initialOrganization?.name || "Select an organization"}
          >
            {initialOrganization?.name || "Select an organization"}
          </Typography>
        </Button>
      </Box>
      <Menu
        id="organization-select-menu"
        aria-labelledby="organization-select-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{ disablePadding: true }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            width: "240px",
            padding: "20px",
          },
        }}
        slots={{ backdrop: Backdrop }}
      >
        <TextField
          value={searchTerm}
          onChange={(event) => {
            setSearchTerm(event.target.value);
          }}
          placeholder="Search"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {searchTerm ? (
                  <CloseIcon
                    onClick={() => {
                      setSearchTerm("");
                    }}
                    sx={closeIconStyle}
                  />
                ) : (
                  <SearchIcon sx={closeIconStyle} />
                )}
              </InputAdornment>
            ),
          }}
          fullWidth
        />
        {isLoading ? (
          <Spinner />
        ) : (
          <Box marginTop="15px">
            {options
              .filter((organization) => regex.test(organization.name))
              .map((option) => {
                return (
                  <Typography
                    key={option.id}
                    className={classNames(styles.menuItemText, {
                      [styles.menuItemTextSelected]: option.id === initialOrganization?.id,
                    })}
                    onClick={() => handleSelection(option)}
                    title={option.name}
                  >
                    {option.name}
                  </Typography>
                );
              })}
          </Box>
        )}
      </Menu>
    </>
  );
});
