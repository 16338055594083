import { Box, InputProps, SxProps, TextField, Theme } from "@mui/material";
import { NumericFormat } from "react-number-format";

interface IVBillPriceAmountProps {
  value: string;
  setValue: (value: string) => void;
  errorMsg?: string;
  label?: string;
  sx?: SxProps<Theme>;
  textFieldSx?: SxProps<Theme>;
  placeholder?: string;
  textFieldInputProps?: Partial<InputProps>;
}

export const VBillPriceAmount = ({
  value,
  setValue,
  errorMsg,
  label,
  sx,
  placeholder,
  textFieldSx,
  textFieldInputProps,
}: IVBillPriceAmountProps) => {
  return (
    <Box sx={sx}>
      <NumericFormat
        allowNegative={false}
        placeholder={placeholder ?? "Amount"}
        value={value}
        prefix="$"
        onValueChange={(values) => {
          setValue(values.value);
        }}
        thousandSeparator
        decimalScale={2}
        customInput={TextField}
        sx={textFieldSx}
        InputProps={textFieldInputProps}
        error={Boolean(errorMsg)}
        label={label}
        helperText={errorMsg ? errorMsg : undefined}
      />
    </Box>
  );
};
