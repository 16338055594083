import { graphqlClient } from "common/graphqlClient";
import { DelegationGroupScopeEnum, DelegationGroupsQuery, getSdk } from "generated/sdk";
import { useEffect, useState } from "react";
import { IApprovalEntityParams } from "./types";

export const useDelegationGroups = (
  scope: DelegationGroupScopeEnum,
  entityParams: IApprovalEntityParams,
  delegatorUserId?: string,
  matchAny?: boolean,
  isRoot?: boolean,
) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [delegationGroups, setDelegationGroups] = useState<DelegationGroupsQuery["DelegationGroups"] | undefined>();
  const [reloadIdx, setReloadIdx] = useState<number>(0);

  useEffect(() => {
    setLoading(true);
    getSdk(graphqlClient)
      .DelegationGroups({
        scope,
        organization_id: entityParams.organizationId,
        company_id: entityParams.companyId,
        vendor_id: entityParams.vendorId,
        bill_id: entityParams.billId,
        check_id: entityParams.checkId,
        delegator_user_id: delegatorUserId,
        match_any: matchAny,
        is_root: isRoot,
      })
      .then((data) => {
        setDelegationGroups(data.DelegationGroups);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    entityParams.organizationId,
    entityParams.companyId,
    entityParams.vendorId,
    entityParams.billId,
    entityParams.checkId,
    reloadIdx,
  ]);

  const reload = () => setReloadIdx((prev) => ++prev);

  return [delegationGroups, loading, reload] as const;
};
