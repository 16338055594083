import { Box, Tab, Tabs } from "@mui/material";
import classnames from "classnames";
import { memo } from "react";
import { IntegrationStatus } from "../../../../Company/IntegrationStatus";
import styles from "./IntegrationHeader.module.scss";
import { IIntegrationHeaderProps } from "./types";

function IntegrationHeader({
  className,
  expanded,
  title,
  settings,
  spacingClass,
  onExpandToggle,
  tabs,
  selectedTab,
  setSelectedTab,
  ...rest
}: IIntegrationHeaderProps) {
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <div className={classnames(styles.header, className, spacingClass)} {...rest}>
      {title && tabs?.length === 0 && <div className={styles.title}>{title}</div>}
      {onExpandToggle && (
        <button className={styles.expandButton} onClick={onExpandToggle}>
          Expand Icon
        </button>
      )}
      {tabs && (
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={selectedTab} onChange={handleTabChange!} aria-label="basic tabs example">
            {tabs.map((tab, index) => (
              <Tab key={index} label={tab?.scope} />
            ))}
          </Tabs>
        </Box>
      )}
      {settings ? (
        <div>
          <span className={styles.lastSync}>
            <IntegrationStatus {...(settings as any)} />
          </span>
        </div>
      ) : null}
    </div>
  );
}

export default memo(IntegrationHeader);
