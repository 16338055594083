import { AllBasePermissionType } from "storesMobx/AclStore";
import { AclSourceLine } from "./AclSource";

export const AclPermLimitSimpleMaxValue = ({
  value,
  rowPermission,
}: {
  value: number;
  rowPermission: AllBasePermissionType | undefined;
  outlined: true | undefined;
}) => {
  const infValue = -1;
  const getRealNewValue = () => +value || -1;
  return (
    <>
      Up to {/* <span title={`Value ${value} ${JSON.stringify(rowPermission, null, 4)}`}> */}
      <span>{getRealNewValue() === infValue ? "No Limit" : `$${getRealNewValue()}`}</span>
      {rowPermission ? <AclSourceLine rowPermission={rowPermission} /> : null}
    </>
  );
};
