import { Box, Skeleton, SxProps, TextField, Theme, Typography } from "@mui/material";
import { graphqlVBillClient } from "common/graphqlClient";
import { VBillCopyTextToClipboard } from "components/pages/common/VBill/VBillCopyTextToClipboard/VBillCopyTextToClipboard";
import { IVBillVBillGetExpenseTokenQuery, getSdk } from "generated/sdk.vbill";
import { useEffect, useState } from "react";

const { VBillGetExpenseToken } = getSdk(graphqlVBillClient);

interface IVBillReimbursementTokenDetailsProps {
  organizationId: string;
  companyId?: string;
  sx?: SxProps<Theme>;
}

export const VBillReimbursementTokenDetails = ({
  organizationId,
  companyId,
  sx,
}: IVBillReimbursementTokenDetailsProps) => {
  const [expenseToken, setExpenseToken] = useState<IVBillVBillGetExpenseTokenQuery["getExpenseToken"]>();

  useEffect(() => {
    loadAndSetExpenseToken(organizationId, companyId);
  }, [organizationId, companyId]);

  const loadAndSetExpenseToken = async (organizationId: string, companyId?: string) => {
    const resp = await VBillGetExpenseToken({ input: { organizationId, companyId } });

    if (resp.getExpenseToken) {
      setExpenseToken(resp.getExpenseToken);
    }
  };

  return (
    <Box sx={sx}>
      {!expenseToken ? (
        <Skeleton variant="rounded" sx={{ height: "54px", width: "100%" }} />
      ) : (
        <>
          <Typography sx={{ marginBottom: "5px" }}>Reimbursement link:</Typography>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              width: "600px",
            }}
          >
            <TextField
              defaultValue={expenseToken.expensePublicUrl}
              InputProps={{
                sx: { height: "32px" },
                readOnly: true,
              }}
              fullWidth
            />

            <VBillCopyTextToClipboard textToCopy={expenseToken.expensePublicUrl ?? ""} />
          </Box>
        </>
      )}
    </Box>
  );
};
