export enum ActivityLogActionType {
  "CREATE" = "CREATE", //
  "EDIT" = "EDIT", //
  "EDIT_DONE" = "EDIT_DONE", //
  "MAPPING" = "MAPPING", //
  "MAPPING_COMPANY" = "MAPPING_COMPANY", //
  "MAPPING_VENDOR" = "MAPPING_VENDOR", //
  "MAPPING_GLACCOUNT" = "MAPPING_GLACCOUNT", //
  "MAPPING_ADDITIONAL_FIELDS" = "MAPPING_ADDITIONAL_FIELDS", //
  "MAPPING_DONE" = "MAPPING_DONE", //
  "COMMENT" = "COMMENT", //
  "START_APPROVAL" = "START_APPROVAL",
  "APPROVE" = "APPROVE", //
  "UNAPPROVE" = "UNAPPROVE",
  "REVOKE" = "REVOKE",
  "DECLINE" = "DECLINE", //
  "DELETE" = "DELETE", //
  "PUSH_TO_INTEGRATION" = "PUSH_TO_INTEGRATION", //
  "UPDATE_PAYMENT" = "UPDATE_PAYMENT", //
  "UPDATE_PUSH_STATUS" = "UPDATE_PUSH_STATUS", //
}
