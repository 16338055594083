import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Box, Tooltip, Typography } from "@mui/material";
import { CHDecimal } from "common/helpers/decimal";
import { formatCurrency } from "common/helpers/utils";
import { useVBillUrl } from "components/pages/common/VBill/hooks";
import { IVBillInvoiceIncomingStatus, IVBillVBillInvoiceQuery, IVBillVBillSettingsQuery } from "generated/sdk.vbill";
import { isNil } from "lodash";
import { useState } from "react";
import { COLORS } from "themes/default";

interface IVBillDetailsProps {
  vBillData: IVBillVBillInvoiceQuery["invoice"];
  vBillAdditionalMappingsSettings?: IVBillVBillSettingsQuery["settings"]["vBill"]["additionalMappings"];
}

export const VBillDetails = ({ vBillData, vBillAdditionalMappingsSettings }: IVBillDetailsProps) => {
  const [invoiceLineItemsExpanded, setInvoiceLineItemsExpanded] = useState(false);

  const {
    id,

    lineItems,
    amounts,
    status,
  } = vBillData;
  const vBillInvoiceUrl = useVBillUrl({ id: vBillData.id, status: vBillData.status });

  const totalItemsAmount = (lineItems ?? []).reduce(
    (acc, val) => (!isNil(val.amount) ? CHDecimal.add(acc, val.amount || 0).toNumber() : acc),
    0,
  );

  return (
    <>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: COLORS.lightestGrey,
          paddingBottom: "20px",
          marginBottom: "20px",
        }}
      >
        <Box component="a" href={`${vBillInvoiceUrl}`} target="_blank" rel="noreferrer" sx={{ textDecoration: "none" }}>
          <Typography fontSize="22px" fontWeight="600" color={COLORS.logoBlue}>
            Bill # {id}
            <OpenInNewIcon sx={{ marginLeft: "10px" }} />
          </Typography>
        </Box>
      </Box>

      {/* <SummaryInvoiceDetails /> */}

      {/* <SummaryInvoiceMappings vBillData={vBillData} vBillAdditionalMappingsSettings={vBillAdditionalMappingsSettings} /> */}
      {/* 
      <SummaryLineItems
        vBillData={vBillData}
        vBillAdditionalMappingsSettings={vBillAdditionalMappingsSettings}
        lineItemsExpanded={invoiceLineItemsExpanded}
        onLineItemsExpandedChange={(value) => setInvoiceLineItemsExpanded(value)}
      /> */}

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderTop: "1px solid #DFE7F2",
          backgroundColor: COLORS.white,
          padding: "20px 0px",
          position: "sticky",
          bottom: 0,
          left: 0,
          right: 0,
        }}
      >
        {status === IVBillInvoiceIncomingStatus.Approved && amounts ? (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: "20px",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Tooltip arrow title="Invoice Amount" placement="top">
                <Box sx={{ textAlign: "left" }}>
                  <Typography sx={{ fontSize: "16px" }}>Amount</Typography>
                  <Typography sx={{ fontSize: "18px", fontWeight: 600, marginTop: "5px" }}>
                    {formatCurrency(Number(amounts.amount))}
                  </Typography>
                </Box>
              </Tooltip>

              <Tooltip arrow title="Amount in payments" placement="top">
                <Box sx={{ textAlign: "left" }}>
                  <Typography sx={{ fontSize: "16px" }}>Paid</Typography>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: 600,
                      marginTop: "5px",
                      color: Number(amounts.amountInPayments) > 0 ? COLORS.success : COLORS.black,
                    }}
                  >
                    {formatCurrency(Number(amounts.amountInPayments))}
                  </Typography>
                </Box>
              </Tooltip>

              <Tooltip arrow title="Free amount" placement="top">
                <Box sx={{ textAlign: "left" }}>
                  <Typography sx={{ fontSize: "16px" }}>Ballance</Typography>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: 600,
                      marginTop: "5px",
                      color: Number(amounts.amountFreeToPay) > 0 ? COLORS.orange : COLORS.black,
                    }}
                  >
                    {formatCurrency(Number(amounts.amountFreeToPay))}
                  </Typography>
                </Box>
              </Tooltip>
            </Box>
          </>
        ) : (
          <>
            <Box sx={{ paddingLeft: "0" }}>
              <Typography sx={{ fontSize: "22px" }}>Total Due:</Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontSize: "22px",
                  fontWeight: "600",
                  textAlign: "right",
                  color: COLORS.textGray,
                }}
              >
                {formatCurrency(totalItemsAmount)}
              </Typography>
            </Box>
          </>
        )}
      </Box>
    </>
  );
};
