import { Box } from "@mui/material";
import {
  IActionlogActionlogIoMetaAdditionalContentBlockOutput,
  IActionlogMetaContentBlockMessageType,
} from "generated/graphql.actionlog";
import React, { FunctionComponent } from "react";
import { AdditionalContentMessage } from "./AdditionalContentMessage/AdditionalContentMessage";
import { AdditionalContentTable } from "./AdditionalContentTable/AdditionalContentTable";

interface IActivityAdditionalContentProps {
  additionalContent: IActionlogActionlogIoMetaAdditionalContentBlockOutput[];
}

export const ActivityAdditionalContent: FunctionComponent<IActivityAdditionalContentProps> = ({
  additionalContent,
}) => (
  <Box>
    {additionalContent.map((additionalContent, index) => (
      <>
        {additionalContent.type === IActionlogMetaContentBlockMessageType.Table && (
          <AdditionalContentTable additionalContent={additionalContent} key={`${additionalContent.message}-${index}`} />
        )}

        {additionalContent.type === IActionlogMetaContentBlockMessageType.Message && (
          <AdditionalContentMessage
            additionalContent={additionalContent}
            key={`${additionalContent.message}-${index}`}
          />
        )}
      </>
    ))}
  </Box>
);
