import { useMemo, useState } from "react";
import styles from "./Users.module.scss";
import { Link } from "react-router-dom";
import SettingsIcon from "@mui/icons-material/Settings";

import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";

import CloseIcon from "@mui/icons-material/Close";
import { Spinner } from "../../../common/Spinner/Spinner";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";
import { useStore } from "../../../../storeContainer";

function useUsersColumns(path: string) {
  return useMemo(
    () => [
      {
        Header: "Name",
        accessor: (d: { account: { name: any } }) => d.account.name,
        Cell: ({ row }: any) => (
          <div className={styles.tableRow}>
            <div className={styles.bold}>{row.original.account.name || row.original.account.email}</div>
          </div>
        ),
      },
      {
        Header: "Email address",
        accessor: (d: { account: { email: any } }) => d.account.email,
        Cell: ({ row }: any) => {
          return (
            <div className={styles.tableRow}>
              <span>{row.original.account.email}</span>
            </div>
          );
        },
      },
      {
        Header: "Groups",
        accessor: (d: { userGroups: any }) => d.userGroups.map((group: { name: string }) => group.name).join(", "),
        Cell: ({ row }: any) => {
          if (row.original.userGroups) {
            const groups = row.original.userGroups.map((group: { name: any }) => group.name).join(", ");
            return <div>{groups}</div>;
          } else {
            return <></>;
          }
        },
      },

      {
        Header: "Status",
        accessor: (d: { account: { state: any } }) => d.account.state,
        Cell: ({ row }: any) => {
          const organizationUsersStore = useStore("OrganizationUsersStore");
          let className: any;
          switch (row.original.state) {
            case "ACTIVE":
              className = { color: "#61b54b", borderColor: "#61b54b" };
              break;
            case "INVITED":
              className = { color: "#9370dbff", borderColor: "#9370dbff" };
              break;
            case "PENDING":
              className = { color: "#f6993f", borderColor: "#f6993f" };
              break;
            default:
              className = styles.invited;
              break;
          }

          const [open, setOpen] = useState(false);
          const [action, setAction] = useState("");

          const handleClickOpen = (action: string) => {
            setAction(action);
            setOpen(true);
          };

          const handleClose = () => {
            setOpen(false);
          };

          const handleValidate = async (organization_user_id: string) => {
            await organizationUsersStore.validateUser(organization_user_id);
          };

          const handleDeleteInvite = async (invite_id: string) => {
            await organizationUsersStore.deleteInvite(invite_id);
            handleClose();
          };
          return (
            <div className={styles.tableRow}>
              <div className={className}>
                {row.original.state === "INVITED" ? (
                  <Chip
                    sx={className}
                    label={row.original.state}
                    onDelete={() => {
                      handleClickOpen("delete");
                    }}
                    deleteIcon={<DeleteIcon />}
                    variant="outlined"
                  />
                ) : row.original.state === "UNVALIDATED" ? (
                  <Chip
                    sx={className}
                    label={row.original.state}
                    onDelete={() => {
                      handleClickOpen("validate");
                    }}
                    deleteIcon={<CheckIcon />}
                    variant="outlined"
                  />
                ) : (
                  <Chip sx={className} label={row.original.state} variant="outlined" />
                )}
              </div>
              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {action === "delete" && <div>Are you sure you want to remove user {row.original.name} invite ? </div>}
                  {action === "validate" && <div>Are you sure you want to activate user {row.original.name} ? </div>}
                </DialogTitle>
                <DialogActions>
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button
                    onClick={() => {
                      if (action === "delete") {
                        handleDeleteInvite(row.original.invites_ids[0]);
                      } else if (action === "validate") {
                        handleValidate(row.original.id);
                      }
                    }}
                    autoFocus
                  >
                    {action === "delete" && <div>Remove</div>}
                    {action === "validate" && <div>Activate</div>}
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          );
        },
      },
      {
        Header: "",
        accessor: "vcard",
        disableSortBy: true,

        Cell: ({ row }: any) => {
          return (
            <div className={styles.tableRow}>
              {row && row.original.state !== "UNVALIDATED" ? (
                <Link to={`${path}/${row.original.id}`}>
                  <SettingsIcon />
                </Link>
              ) : null}
            </div>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
}

function useGroupsColumns(path: string) {
  return useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ value }: any) => (
          <div className={styles.tableRow}>
            <div>{value}</div>
          </div>
        ),
      },
      {
        Header: "Users",
        accessor: "users",
        Cell: ({ row }: any) => {
          const organizationUsersStore = useStore("OrganizationUsersStore");
          const usersData = organizationUsersStore.getOrganizationUsersAccounts();
          if (row.original.organization_users) {
            const users = usersData
              ?.filter((user: { id: string }) => {
                const found = row.original.organization_users?.find((usr: { id: string }) => usr.id === user.id);
                return !!found;
              })
              .map((user) => user.account?.name || user.account?.email)
              .join(", ");

            return <div> {users}</div>;
          } else {
            return <></>;
          }
        },
      },

      {
        Header: "",
        accessor: "vcard",
        disableSortBy: true,
        Cell: ({ row }: any) => {
          return (
            <div className={styles.tableRow}>
              {row && (
                <Link to={`${path[path.length - 1] === "/" ? path.slice(0, -1) : path}/${row.original.id}`}>
                  <SettingsIcon />
                </Link>
              )}
            </div>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
}

function useContactsColumns(handleClick: Function) {
  return useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        disableSortBy: true,
        Cell: ({ value, row }: any) => (
          <div role="button" className={styles.tableRow}>
            <Button
              variant={"text"}
              onClick={() => {
                handleClick(row.original.id);
              }}
              color="primary"
            >
              {value}
            </Button>
          </div>
        ),
      },

      {
        Header: "Phone number",
        accessor: "",
        disableSortBy: true,
        Cell: ({ row }: any) => (
          <div className={styles.tableRow}>
            {row.original.phone_number.length > 0 ? (
              <Typography>{row.original.phone_number[0].number}</Typography>
            ) : (
              <Typography>N/A</Typography>
            )}
          </div>
        ),
      },
      {
        Header: "Address",
        accessor: "",
        disableSortBy: true,
        Cell: ({ row }: any) => (
          <div className={styles.tableRow}>
            {row.original.address ? (
              <Typography>
                {row.original.address.address},{" "}
                {row.original.address.address2 ? `${row.original.address.address2}, ` : ""}
                {row.original.address.zip_code ? `${row.original.address.zip_code}, ` : ""}
                {row.original.address.city ? `${row.original.address.city}, ` : ""}
                {row.original.address.state ? `${row.original.address.state}, ` : ""}
                {row.original.address.country}
              </Typography>
            ) : (
              <Typography>N/A</Typography>
            )}
          </div>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
}

function useBankAccountsColumns(path: string) {
  return useMemo(
    () => [
      {
        Header: "",
        accessor: "id",
        Cell: () => (
          <div className={styles.tableRow}>
            <div>
              <input type="checkbox" />
            </div>
          </div>
        ),
      },
      {
        Header: "Account name",
        accessor: "name",
        Cell: ({ value }: any) => (
          <div className={styles.tableRow}>
            <Typography variant="body2" sx={{ fontWeight: 500 }}>
              {value}
            </Typography>
          </div>
        ),
      },
      {
        Header: "Account number",
        accessor: "account_number",
        Cell: ({ value }: any) => (
          <div className={styles.tableRow}>
            <div>**{value}</div>
          </div>
        ),
      },
      {
        Header: "Bank Name",
        accessor: "bank_name",
        Cell: ({ value }: any) => (
          <div className={styles.tableRow}>
            <div>{value}</div>
          </div>
        ),
      },
      {
        Header: "Date Added",
        accessor: "created_at",
        Cell: ({ value }: any) => (
          <div className={styles.tableRow}>
            <div>{new Date(value).toLocaleDateString()}</div>
          </div>
        ),
      },
      {
        Header: "Mapped to:",
        accessor: "",
        Cell: ({ row }: any) => (
          <div className={styles.tableRow}>
            <div>{row.original.glAccounts?.map((glAccount: any) => glAccount.name).join()}</div>
          </div>
        ),
      },
      {
        Header: "Deposit Only",
        disableSortBy: true,
        accessor: "",
        Cell: ({ row }: any) => (
          <div className={styles.tableRow}>
            <div>{row.original.deposit_only === true ? "Yes" : "No"}</div>
          </div>
        ),
      },
      {
        Header: "",
        accessor: "vcard",
        Cell: ({ row }: any) => {
          const [anchorEl, setAnchorEl] = useState(null);
          const open = Boolean(anchorEl);

          const handleClick = (event: any) => {
            setAnchorEl(event.currentTarget);
          };
          const handleClose = () => {
            setAnchorEl(null);
          };

          return (
            <div className={styles.tableRow}>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem color="inherit" component={Link} to={`${path}/${row.original.id}/deposits`}>
                  Auto Deposit
                </MenuItem>

                <MenuItem color="inherit" component={Link} to={`${path}/${row.original.id}/emails`}>
                  Link Email Addresses
                </MenuItem>
              </Menu>

              {row && (
                <SettingsIcon
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                  color="info"
                />
              )}
            </div>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
}

function useUserGroupsColumns(handleRemoveFromGroup: Function, loading: boolean) {
  return [
    {
      Header: "Name",
      accessor: "name",
      Cell: ({ value }: any) => (
        <div className={styles.tableRow}>
          <div>{value}</div>
        </div>
      ),
    },
    {
      Header: "",
      accessor: "vcard",
      disableSortBy: true,
      Cell: ({ row }: any) => {
        const [open, setOpen] = useState(false);

        const handleClickOpen = () => {
          setOpen(true);
        };

        const handleClose = () => {
          setOpen(false);
        };
        return (
          <div className={styles.tableRow}>
            {!loading ? <CloseIcon className={styles.closeIcon} onClick={handleClickOpen} /> : <Spinner />}
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                Are you sure you want to remove group {row.original.name} ?
              </DialogTitle>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={() => handleRemoveFromGroup(row.original.id)} autoFocus>
                  Remove
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        );
      },
    },
  ];
}

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      style={{ width: "100%" }}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export {
  useContactsColumns,
  useUsersColumns,
  useGroupsColumns,
  useUserGroupsColumns,
  useBankAccountsColumns,
  a11yProps,
  TabPanel,
};
