import { ChatChannelStore, IChatChannelStoreInit } from "./ChatChannelStore";

const __$$chatChannelStoresCache = new Map<string, ChatChannelStore>();

const initNewChatChannelStore = (data: IChatChannelStoreInit) => {
  const chatChannelStoreCacheKey = getChatChannelStoreCacheKey(data.config.baseIoChannel);
  const cachedChatChannelStore = __$$chatChannelStoresCache.get(chatChannelStoreCacheKey);

  if (cachedChatChannelStore) {
    return;
  }

  const chatChannelStore = new ChatChannelStore(data);

  __$$chatChannelStoresCache.set(chatChannelStoreCacheKey, chatChannelStore);
  const chatChannelStoreInCache = __$$chatChannelStoresCache.get(chatChannelStoreCacheKey);
  chatChannelStoreInCache?.getChannelInitialData();
};

const getChatChannelStore = (baseIoChannel: IChatChannelStoreInit["config"]["baseIoChannel"]) => {
  const chatChannelStoreCacheKey = getChatChannelStoreCacheKey(baseIoChannel);
  const chatChannelStoreInCache = __$$chatChannelStoresCache.get(chatChannelStoreCacheKey);

  return chatChannelStoreInCache;
};

const clearChatChannelStoreCache = (baseIoChannel: IChatChannelStoreInit["config"]["baseIoChannel"]) => {
  const chatChannelStoreCacheKey = getChatChannelStoreCacheKey(baseIoChannel);

  __$$chatChannelStoresCache.delete(chatChannelStoreCacheKey);
};

const getChatChannelStoreCacheKey = (baseIoChannel: IChatChannelStoreInit["config"]["baseIoChannel"]) =>
  `${baseIoChannel.parentType}_${baseIoChannel.parentKey}_${baseIoChannel.parentContext}`;

export {
  __$$chatChannelStoresCache,
  clearChatChannelStoreCache,
  getChatChannelStore,
  getChatChannelStoreCacheKey,
  initNewChatChannelStore,
};
