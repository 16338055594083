import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import { useShtarHistory } from "common/hooks/useShtarHistory";
import Grid from "components/common/Grid";
import Typography from "components/common/Typography";
import { PendingActions } from "components/widgets/PendingActions";
import { PendingTransactions } from "components/widgets/PendingTransactions";
import { definitelyFilter } from "generated/utils";
import Cookies from "js-cookie";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { InviteStatusEnum } from "../../generated/sdk";
import { useStore } from "../../storeContainer";
import { ConfirmPhone } from "./Organization/ConfirmPhone";
import { CheckQuickFiltersEnumType } from "generated/graphql";

const Enrollment: React.FunctionComponent<{ showSpacer: boolean }> = ({ showSpacer }) => {
  const SessionStore = useStore("SessionStore");
  const UndepositedChecksStore = useStore("UndepositedChecksStore");
  return (
    <Grid container spacing={5}>
      <Grid item xs={showSpacer ? 4.5 : 6}>
        <Card sx={{ p: 0 }}>
          <Typography variant="h6" sx={{ m: 3 }}>
            Send &amp; Receive Payments
          </Typography>
          <ul style={{ marginLeft: "0px", lineHeight: "30px" }}>
            <li>
              <Typography variant="body2">Send &amp; Receive payments with vChecks</Typography>
            </li>
            <li>
              <Typography variant="body2">Manage company expenses with vCards</Typography>
            </li>
            <li>
              <Typography variant="body2">User and Roles Management that fits your company’s process</Typography>
            </li>
            <li>
              <Typography variant="body2">Get cashback when you pay with vCheck Card</Typography>
            </li>
          </ul>
          <Button
            onClick={() => window.location.replace(`${window.location.origin}/bas/create/regular`)}
            sx={{ m: 3, textTransform: "capitalize" }}
            color="secondary"
            variant="contained"
            disableElevation
            endIcon={<ArrowForwardIosIcon fontSize="small" />}
            disabled={SessionStore.session.data?.has_in_progress_onboarding as boolean}
          >
            Add Company
          </Button>
        </Card>
      </Grid>

      <Grid item xs={showSpacer ? 4.5 : 6}>
        <Card sx={{ p: 0, height: "276px" }}>
          <Typography variant="h6" sx={{ m: 3 }}>
            Deposit Only
          </Typography>
          <ul style={{ marginLeft: "0px", lineHeight: "30px" }}>
            <li>
              <Typography variant="body2">Get paid with vChecks</Typography>
            </li>
            <li>
              <Typography variant="body2">Auto Deposit received payments</Typography>
            </li>
            <li>
              <Typography variant="body2">Track real time deposit status</Typography>
            </li>
          </ul>
          <Button
            onClick={() => window.location.replace(`${window.location.origin}/bas/create/deposit-only`)}
            sx={{ margin: "47px 32px 32px", textTransform: "capitalize" }}
            color="secondary"
            variant="contained"
            disableElevation
            endIcon={<ArrowForwardIosIcon fontSize="small" />}
            disabled={SessionStore.session.data?.has_in_progress_onboarding as boolean}
          >
            Add Bank Account
          </Button>
        </Card>
      </Grid>
      {showSpacer && (
        <Grid item xs={3}>
          <PendingActions
            invites={SessionStore.invites}
            unconfirmedBankAccounts={definitelyFilter(SessionStore.session.data?.unconfirmed_bank_accounts)}
          />
          <PendingTransactions undepositedChecks={UndepositedChecksStore.undepositedChecks} />
        </Grid>
      )}
    </Grid>
  );
};

export const MainDashboard: React.FunctionComponent = observer(function MainDashboard() {
  const history = useShtarHistory();

  const SessionStore = useStore("SessionStore");
  // const ContextStore = useStore("ContextStore");
  const UndepositedChecksStore = useStore("UndepositedChecksStore");
  // const { selectedOrganization } = ContextStore;

  const organizations = SessionStore.organizations;

  const depositAccountsCount = SessionStore.session.data?.deposit_accounts || 0;

  const phoneConfirmed = SessionStore.session.data?.account?.phone_number_verified;

  useEffect(() => {
    SessionStore.getInvites();
    UndepositedChecksStore.getUndepositedChecks();
  }, [SessionStore, UndepositedChecksStore]);

  const lastOrgCookie = Cookies.get("lastOrg");

  useEffect(() => {
    if (lastOrgCookie) {
      history.push(`/org/${lastOrgCookie}/vchecks/payments/${CheckQuickFiltersEnumType.PendingSignature}`);
    }
    if (phoneConfirmed) {
      if (organizations.length > 0) {
        let firstOrganization = organizations[0];

        if (firstOrganization?.id) {
          history.push(`/org/${firstOrganization.id}/vchecks/payments/${CheckQuickFiltersEnumType.PendingSignature}`);
        }
      } else if (depositAccountsCount > 0) {
        history.push(`/depositAccounts/ledger/all`);
      }
    }
  }, [depositAccountsCount, history, lastOrgCookie, organizations, phoneConfirmed]);

  return !phoneConfirmed || isNil(phoneConfirmed) ? (
    <ConfirmPhone />
  ) : lastOrgCookie ? null : (
    <Grid container spacing={2} marginTop="25px" maxWidth="1400px">
      <Grid item xs={12}>
        <Typography
          variant="h1"
          component={"div"}
          sx={{ justifyContent: "center", display: "flex" }}
          color="primary"
          mb={6}
        >
          Welcome to Cherry
        </Typography>
        <Enrollment
          showSpacer={
            (SessionStore.invites.data?.filter((invite) => invite.status === InviteStatusEnum.Active).length || 0) !==
              0 || (UndepositedChecksStore.undepositedChecks.data?.undeposited_checks?.count || 0) !== 0
          }
        />
      </Grid>
    </Grid>
  );
});
