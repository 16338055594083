import { Box, Button, Tooltip } from "@mui/material";
import { IVBillBillsFiltersMappingInput, IVBillStoreIoDataType } from "generated/sdk.vbill";
import { observer } from "mobx-react";
import { useMemo, useState } from "react";
import { useStore } from "storeContainer";
import { COLORS } from "themes/default";
import { AdditionalMappingsDialog } from "../../../common/VBill/FilterComponents/VBillAdditionalMappingsFilter/AdditionalMappingsDialog/AdditionalMappingsDialog";
import { blackBtnStyles } from "../summariesStyles";

export const SummariesVendorsMapping = observer(function SummariesVendorsMapping() {
  const vBillBatchDetailsStore = useStore("VBillBatchDetailsStore");
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const { batchDetails, vBillBatchAdditionalMappingsSettings } = vBillBatchDetailsStore;
  const batchDetailsData = batchDetails.data;
  const additionalMappingsSettingsData = vBillBatchAdditionalMappingsSettings.data?.invoice;
  const vendorSettings = useMemo(
    () => (additionalMappingsSettingsData ?? []).find((map) => map.isContact),
    [additionalMappingsSettingsData],
  );
  const selectedVendorMappings = useMemo(
    () => batchDetailsData?.batchFilters?.mappings?.find(({ key }) => key === vendorSettings?.key),
    [batchDetailsData?.batchFilters?.mappings, vendorSettings?.key],
  );

  const handleAdditionalMappingChange = async (value: IVBillBillsFiltersMappingInput) => {
    if (!batchDetailsData) {
      return;
    }

    const currentMappings = batchDetailsData?.batchFilters?.mappings ?? [];
    const newMappings = (
      currentMappings.find(({ key }) => key === value.key)
        ? currentMappings.map((mapping) => (mapping.key === value.key ? value : mapping))
        : [...currentMappings, value]
    ).filter(({ valuesIn }) => valuesIn.length > 0);

    await vBillBatchDetailsStore.updateBatchDetails({
      batchId: batchDetailsData.id,
      batchData: {
        name: batchDetailsData.name,
        organizationId: batchDetailsData.organizationId,
        batchFilters: { ...batchDetailsData.batchFilters, mappings: newMappings },
      },
    });

    setIsDialogOpen(false);
  };

  if (!vendorSettings) {
    return null;
  }

  const { storeDataType, key } = vendorSettings;

  return (
    <>
      <Tooltip title="Edit vendors" arrow>
        <Button onClick={() => setIsDialogOpen(true)} sx={blackBtnStyles}>
          <Box sx={{ color: COLORS.logoBlue }}>Bills vendors:</Box>{" "}
          <strong>({selectedVendorMappings?.valuesIn?.length ?? 0})</strong>
        </Button>
      </Tooltip>

      {isDialogOpen && (
        <AdditionalMappingsDialog
          suggestionsComponent="dialog"
          storeType={storeDataType as unknown as IVBillStoreIoDataType}
          storeKey={key}
          mapping={selectedVendorMappings}
          batchCompanyIds={batchDetailsData?.batchFilters?.companyIds}
          onCloseDialog={() => setIsDialogOpen(false)}
          searchPlaceholder="Search vendors"
          onMappingSave={handleAdditionalMappingChange}
          dialogTitle="Vendors"
          actionsBtnSmallHeight
        />
      )}
    </>
  );
});
