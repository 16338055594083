import { Box, Container, Grid, StyledEngineProvider, ThemeProvider, Typography } from "@mui/material";
import { useState } from "react";
import CherryWhiteLogo from "../../../icons/svg/cherry_white_logo.svg?react";
import ConfirmAndContinueBox from "./ConfirmAndContinueBox";
import PaymentOptionCard from "./PaymentOptionCard";
import styles from "./PaymentOptions.module.scss";

import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import axios from "axios";
import { useViewportWidth } from "common/hooks/useViewportWidth";
import React from "react";
import { useSearchParam } from "react-use";
import { defaultTheme } from "themes";
import { ThankYouPage } from "./ThankYouPage";
import CreditCardIcon from "./icons/CreditCardIcon";
import DirectDepositIcon from "./icons/DirectDepositIcon";
import PaperCheckIcon from "./icons/PaperCheckIcon";

const cache = createCache({
  key: "my-cache-prefix-key",
  nonce: (window as any).nonce || "",
  prepend: true,
});

export const PaymentOptions: React.FunctionComponent = () => {
  const [visible, setVisible] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [canContinue, setCanContinue] = useState<boolean>(false);
  const [thankYouState, setThankYouState] = useState(false);
  const handleCardClick = (name: string) => {
    if (visible === name) {
      setVisible("");
      setSelectedOption("");
      setCanContinue(false);
    } else {
      setVisible(name);
      setSelectedOption(name);
      setCanContinue(false);
    }
  };
  const param = useSearchParam("success");
  const size = useViewportWidth();

  const sendRequest = async () => {
    const url = window.location.pathname;
    await axios
      .post(url, { option: selectedOption })
      .then(() => {
        setThankYouState(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  if (param === "1") {
    return (
      <StyledEngineProvider injectFirst>
        <CacheProvider value={cache}>
          <ThemeProvider theme={defaultTheme}>
            <Box
              sx={{
                backgroundColor: "#001C3C",
                color: "#fff",
                minHeight: "10%",
                display: "flex",
                flexGrow: 1,
                alignItems: "center",
              }}
            >
              <Container maxWidth={"xl"}>
                <Grid padding={3} item xs>
                  <Box
                    sx={{
                      display: "flex",
                      flexGrow: 1,
                      justifyContent: "center",
                      alignItems: "center",
                      color: "#fff",
                    }}
                  >
                    <a href={"/org"}>
                      <CherryWhiteLogo />
                    </a>
                  </Box>
                </Grid>
              </Container>
            </Box>
            <ThankYouPage />
          </ThemeProvider>
        </CacheProvider>
      </StyledEngineProvider>
    );
  }

  return (
    <>
      <StyledEngineProvider injectFirst>
        <CacheProvider value={cache}>
          <ThemeProvider theme={defaultTheme}>
            <Box
              sx={{
                backgroundColor: "#001C3C",
                color: "#fff",
                minHeight: "10%",
                display: "flex",
                flexGrow: 1,
                alignItems: "center",
              }}
            >
              <Container maxWidth={"xl"}>
                <Grid padding={3} item xs>
                  <Box
                    sx={{
                      display: "flex",
                      flexGrow: 1,
                      justifyContent: "center",
                      alignItems: "center",
                      color: "#fff",
                    }}
                  >
                    <a href={"/org"}>
                      <CherryWhiteLogo />
                    </a>
                  </Box>
                </Grid>
              </Container>
            </Box>

            {!thankYouState ? (
              <Grid
                container
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  backgroundColor: "COLORS.mercury",
                  minHeight: "90%",
                }}
              >
                <Grid
                  item
                  container
                  direction={"row"}
                  maxWidth={1400}
                  maxHeight={700}
                  sx={{
                    backgroundColor: "white",
                    mt: 7,
                    borderRadius: "3px",
                    p: 7,
                  }}
                >
                  <Grid height={90} item>
                    <Typography variant={"h1"} sx={{ fontWeight: "600", marginBottom: 5 }}>
                      Please choose a payment method
                    </Typography>
                  </Grid>
                  <Grid item container xs={12} className={styles.optionsContainer} justifyContent={"space-between"}>
                    <Grid>
                      <PaymentOptionCard
                        onClick={() => handleCardClick("credit-card")}
                        title={"Credit Card"}
                        subtitle={"Get paid right away"}
                        visible={visible}
                        mostPopular
                        visibleCondition="credit-card"
                      >
                        <CreditCardIcon
                          size={size.width! > 1080 ? 80 : 60}
                          bg={visible === "credit-card" ? "#0180FF" : "white"}
                          color={visible !== "credit-card" ? "#0180FF" : "white"}
                        />
                      </PaymentOptionCard>
                      {size.width! > 1080 && (
                        <ConfirmAndContinueBox
                          title={"You selected to accept payment by credit card"}
                          onClick={sendRequest}
                          canContinue={canContinue}
                          setCanContinue={setCanContinue}
                          visible={visible}
                          visibleCondition="credit-card"
                        />
                      )}
                    </Grid>
                    <Grid>
                      <PaymentOptionCard
                        onClick={() => handleCardClick("direct-deposit")}
                        title={"Direct Deposit"}
                        subtitle={"Within 5-7 Business Days"}
                        visible={visible}
                        visibleCondition="direct-deposit"
                      >
                        <DirectDepositIcon
                          bg={visible === "direct-deposit" ? "#0180FF" : "white"}
                          color={visible !== "direct-deposit" ? "#0180FF" : "white"}
                          size={size.width! > 1080 ? 80 : 60}
                        />
                      </PaymentOptionCard>
                      {size.width! > 1080 && (
                        <ConfirmAndContinueBox
                          title={"You selected to accept payment by direct deposit"}
                          onClick={sendRequest}
                          canContinue={canContinue}
                          setCanContinue={setCanContinue}
                          visible={visible}
                          visibleCondition="direct-deposit"
                        />
                      )}
                    </Grid>
                    <Grid>
                      <PaymentOptionCard
                        onClick={() => handleCardClick("paper-check")}
                        title={"Paper Check"}
                        subtitle={"Within 10 Days"}
                        visible={visible}
                        visibleCondition="paper-check"
                      >
                        {/* <CreditCardIcon
													className={styles.cardIcon}
													style={visible == "paper-check" ? { fill: "white" } : {}}
												/> */}
                        <PaperCheckIcon
                          bg={visible === "paper-check" ? "#0180FF" : "white"}
                          color={visible !== "paper-check" ? "#0180FF" : "white"}
                          size={size.width! > 1080 ? 80 : 60}
                        />
                      </PaymentOptionCard>
                      {size.width! > 1080 && (
                        <ConfirmAndContinueBox
                          title={"You selected to receive a check by 	mail"}
                          onClick={sendRequest}
                          canContinue={canContinue}
                          setCanContinue={setCanContinue}
                          visible={visible}
                          visibleCondition="paper-check"
                        />
                      )}
                    </Grid>
                    {size.width! < 1080 && (
                      <Box>
                        {!visible && (
                          <ConfirmAndContinueBox
                            onClick={() => {
                              sendRequest();
                            }}
                            hidden
                            visible={visible}
                            canContinue={canContinue}
                            setCanContinue={setCanContinue}
                            title={"You selected to accept payment by credit card"}
                          />
                        )}
                        {visible ? (
                          <ConfirmAndContinueBox
                            onClick={() => {
                              sendRequest();
                            }}
                            visible={visible}
                            canContinue={canContinue}
                            setCanContinue={setCanContinue}
                            title={
                              visible === "credit-card"
                                ? "You selected to accept payment by credit card"
                                : visible === "direct-deposit"
                                  ? "You selected to accept payment by direct deposit"
                                  : "You selected to receive a check by mail"
                            }
                          />
                        ) : (
                          <Grid xs={3.5}></Grid>
                        )}
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <ThankYouPage />
            )}
          </ThemeProvider>
        </CacheProvider>
      </StyledEngineProvider>
    </>
  );
};
