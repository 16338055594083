import { SvgIcon, SvgIconProps } from "@mui/material";

export const HistoryIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <svg>
        <path d="M16.8731 8.99995C16.8732 10.5396 16.3873 12.0399 15.4847 13.2872C14.5821 14.5345 13.3089 15.4651 11.8464 15.9464C10.3839 16.4276 8.80683 16.435 7.33992 15.9675C5.87301 15.4999 4.5911 14.5813 3.67687 13.3424L4.5825 12.6731C5.40872 13.7952 6.58952 14.6056 7.93369 14.973C9.27787 15.3404 10.7067 15.2433 11.9888 14.6974C13.271 14.1516 14.3313 13.1889 14.9981 11.9654C15.665 10.7418 15.8992 9.32895 15.663 7.95564C15.4269 6.58232 14.734 5.32893 13.6967 4.39846C12.6594 3.46798 11.3384 2.91488 9.94756 2.82874C8.55674 2.74261 7.17757 3.12847 6.03338 3.92384C4.88919 4.71921 4.04697 5.87752 3.64312 7.2112L4.20562 6.84557L4.83562 7.77932L3.14812 8.90432C3.04869 8.97136 2.93044 9.00486 2.81062 8.99995C2.71849 9.00009 2.62772 8.97759 2.54632 8.93443C2.46491 8.89128 2.39535 8.82878 2.34375 8.75245L1.21875 7.06495L2.1525 6.43495L2.535 6.99745C3.02304 5.30668 4.10444 3.84863 5.58075 2.89084C7.05706 1.93305 8.82926 1.53978 10.5721 1.7832C12.315 2.02661 13.9116 2.89038 15.0691 4.216C16.2265 5.54162 16.867 7.24017 16.8731 8.99995ZM8.99812 5.06245V8.99995C8.9977 9.07398 9.01188 9.14736 9.03988 9.21589C9.06787 9.28443 9.10912 9.34676 9.16125 9.39932L10.8487 11.0868L11.6475 10.2881L10.1231 8.76932V5.06245H8.99812Z" />
      </svg>
    </SvgIcon>
  );
};
