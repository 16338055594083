import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { useCheckFilters } from "common/hooks/useCheckFilters";
import { GetCheckBatchesFiltersType } from "generated/sdk";
import React, { useState } from "react";
import { useHistory, useParams } from "react-router";
import { useStore } from "storeContainer";
import { COLORS } from "themes/default";

const ConfirmDeleteBatchModal: React.FC<{
  isConfirmDeleteModalOpen: boolean;
  setIsDeleteConfirmModalOpen: (state: boolean) => void;
  selectedBatchId?: string;
  setSelectedBatchId?: React.Dispatch<React.SetStateAction<string | undefined>>;
  onDeleteNavigateToLedger?: boolean;
}> = ({
  isConfirmDeleteModalOpen,
  setIsDeleteConfirmModalOpen,
  selectedBatchId,
  setSelectedBatchId,
  onDeleteNavigateToLedger,
}) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [errorFeedback, setErrorFeedback] = useState("");
  const { filters, pagination } = useCheckFilters<GetCheckBatchesFiltersType>();
  const { organizationId } = useParams<{ organizationId: string }>();
  const BudgetManagementStore = useStore("BudgetManagementStore");
  const { deleteCheckBatch, fetchCheckBatches } = BudgetManagementStore;
  const handleSubmit = async () => {
    setIsLoading(true);
    setErrorFeedback("");
    try {
      if (selectedBatchId) await deleteCheckBatch(selectedBatchId);
    } catch (err) {
      setErrorFeedback("An error encountered while processing your request.");
      return;
    }

    await fetchCheckBatches(organizationId, filters, pagination, true);

    if (onDeleteNavigateToLedger) {
      history.push(`/org/${organizationId}/bchecks`);
    }

    setIsLoading(false);
    setIsDeleteConfirmModalOpen(false);
    setSelectedBatchId?.(undefined);
  };
  const handleClose = () => {
    setSelectedBatchId?.(undefined);
    setIsDeleteConfirmModalOpen(false);
  };
  return (
    <Dialog
      open={isConfirmDeleteModalOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title-batch"
      aria-describedby="alert-dialog-description-batch"
    >
      <DialogTitle id="alert-dialog-title-batch">Delete Batch</DialogTitle>
      <DialogContent>
        <Grid container direction="column">
          <DialogContentText>Are you sure you want to delete this batch?</DialogContentText>
        </Grid>
      </DialogContent>
      <Divider />
      <Typography color={COLORS.darkRed} textAlign={"center"}>
        {errorFeedback}
      </Typography>
      <DialogActions>
        <Button
          onClick={() => {
            setIsDeleteConfirmModalOpen(false);
          }}
        >
          Cancel
        </Button>
        <LoadingButton
          loading={isLoading}
          variant="outlined"
          onClick={handleSubmit}
          sx={{ color: COLORS.darkRed, fontWeight: 500, borderColor: `${COLORS.darkRed} !important` }}
        >
          Delete Batch
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDeleteBatchModal;
