import Button from "components/common/Button";
import { Form } from "components/common/Forms";
import { PendingChecksFilterInput } from "generated/sdk";
import { FunctionComponent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { RouteComponentProps, useHistory } from "react-router";
import { useSetState } from "react-use";
import { graphqlClient } from "../../../../common/graphqlClient";
import {
  Check,
  GeneralLedgerAccount,
  GeneralLedgerAccountPendingChecks,
  Integration,
  Pagination,
  useArchivePendingChecksMutation,
} from "../../../../generated/graphql";
import { Spinner } from "../../../common/Spinner/Spinner";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import styles from "./CompanyPendingChecks.module.scss";
import { PendingChecksPagination } from "./PendingChecksPagination/PendingChecksPagination";
import { useCompanyPendingChecksColumns } from "./utils";
import { Box, InputLabel } from "@mui/material";
import Table from "components/common/Table";
import { definitelyFilter } from "generated/utils";
import Cookies from "js-cookie";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { useStore } from "storeContainer";
import { NumberParam, useQueryParam, withDefault } from "use-query-params";
import { AddPermissionsModal } from "./PendingChecksActionsModals/AddPermissionsModal";
import { ArchiveCheckModal } from "./PendingChecksActionsModals/ArchiveCheckModal";
import { MapAccountModal } from "./PendingChecksActionsModals/MapAccountModal";
import { MapUserModal } from "./PendingChecksActionsModals/MapUserModal";
import Filters from "./PendingChecksFilters/Filters";

interface ICompanyPendingChecksProps extends RouteComponentProps<{ companyId: string }> {}

interface IPendingCheckGlExtended extends GeneralLedgerAccountPendingChecks {
  associated_gl?: GeneralLedgerAccount;
}

export interface IPendingCheck extends Check {
  isArchived?: boolean;
  isImported?: boolean;
  gl_account: IPendingCheckGlExtended;
}

export type PendingCheckAction = "import" | "archive";

export const CompanyPendingChecks: React.FunctionComponent = observer(function CompanyPendingChecks() {
  const history = useHistory();
  const { companyId } = useParams<{ companyId: string }>();
  const [currentCompany, setCurrentCompany] = useState<any>(
    window.location.pathname.split("/")[window.location.pathname.split("/").length - 1],
  );
  const initialPerPage = withDefault(NumberParam, 10);
  const initialPageNumber = withDefault(NumberParam, 1);
  const [perPage, setPerPage] = useQueryParam("perPage", initialPerPage);
  const [pageNumber, setPageNumber] = useQueryParam("page", initialPageNumber);
  const [selectedChecksIds, setSelectedChecksIds] = useState<string[]>([]);

  const [companiesWithIntegrationsIds, setCompaniesWithIntegrationsIds] = useState<any[]>([]);
  const [integrationIdState, setIntegrationIdState] = useState<string>("");
  const [signer, setSigner] = useState<string>("ok");
  const [organizationUserId, setOrganizationUserId] = useState<string>("");

  const [mapUserModalOpen, setMapUserModalOpen] = useState(false);
  const [mapAccountModalOpen, setMapAccountModalOpen] = useState(false);
  const [addPermissionsModalOpen, setAddPermissionsModalOpen] = useState(false);
  const [archiveModalOpen, setArchiveModalOpen] = useState(false);
  const [checksToBeArchived, setChecksToBeArchived] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [archiveLoading, setArchiveLoading] = useState(false);
  const [currentGlAccount, setCurrentGlAccount] = useState("");
  const [successfullArchiveModal, setSuccessfullArchiveModal] = useState(false);
  const [checksForTable, setChecksForTable] = useState<any>();
  const [filters, setFilters] = useState<PendingChecksFilterInput>(); //useQueryParam("filters", JsonParam);
  const [loadingFiltersApplying, setLoadingFiltersApplying] = useState(false);
  const [amountChipLabel, setAmountChipLabel] = useState("");
  const [errorFeedback, setErrorFeedBack] = useState("");
  useEffect(() => {
    setFilters({
      amount_max: null,
      amount_min: 0,
      contact: null,
      error_type: null,
      from_date: null,
      to_date: null,
      search: null,
    });
  }, []);
  useEffect(() => {
    if (filters?.amount_max || filters?.amount_max) setAmountChipLabel(filters?.amount_min + "-" + filters?.amount_max);
  }, [filters]);

  const OrganizationStore = useStore("OrganizationStore");
  const sessionStore = useStore("SessionStore");
  const contextStore = useStore("ContextStore");
  const integrationsStore = useStore("IntegrationsStore");
  const UndepositedChecksStore = useStore("UndepositedChecksStore");

  const { pendingChecks } = UndepositedChecksStore;
  const { selectedOrganizationId } = contextStore;
  const { pendingActionsAndChecksCount } = OrganizationStore;

  let bankAccounts = integrationsStore.companyIntegrationsList.data?.bank_accounts;
  let generalLedgerAccounts = integrationsStore.generalLedgerAccountsList.data;
  const bankAccountsWithGl =
    bankAccounts?.map((ba) => ({
      ...ba,
      options: generalLedgerAccounts || [],
      readOnly: false,
    })) || [];

  useEffect(() => {
    if (
      companyId &&
      sessionStore.acl.isPermissionGroupLoaded &&
      !sessionStore.acl.canListPendingImportPending(companyId)
    ) {
      history.replace("/access-restricted");
    }
    if (sessionStore.acl.canListPendingImportPending(companyId)) fetchCompanies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextStore.selectedOrganizationId, pendingActionsAndChecksCount.data?.companies_pending_checks_count?.length]);

  useEffect(() => {
    setTimeout(() => {
      setSuccessfullArchiveModal(false);
    }, 2000);
  }, [successfullArchiveModal]);

  const fetchCompanies = async () => {
    const companiesIntegrationIdArray: any = [];
    if (contextStore.selectedOrganizationId) {
      if (pendingActionsAndChecksCount.data?.companies_pending_checks_count) {
        for (const company of pendingActionsAndChecksCount.data?.companies_pending_checks_count) {
          if (company.pending_checks_count !== 0) companiesIntegrationIdArray.push(company);
        }
        setCompaniesWithIntegrationsIds(companiesIntegrationIdArray);
      }
    }
  };

  const startUserMapping = () => {
    setMapUserModalOpen(true);
  };
  const startAccountMapping = (gl_account: any) => {
    setCurrentGlAccount(gl_account);
    setMapAccountModalOpen(true);
  };
  const startAddingPermissions = () => {
    setAddPermissionsModalOpen(true);
  };
  const closeUserModal = () => {
    setMapUserModalOpen(false);
  };
  const closeAccountModal = async () => {
    setMapAccountModalOpen(false);
    paginationFetcher({ page: 1, per_page: 10 });
  };
  const closePermissionsModal = () => {
    setAddPermissionsModalOpen(false);
  };
  const closeArchiveModal = () => {
    setArchiveModalOpen(false);
  };
  const closeSuccessfullArchiveModal = () => {
    setSuccessfullArchiveModal(false);
  };
  const initialStateState = {
    total: 0,
    pendingChecks: {} as Check[],
    errors: {} as Record<string, string[]>,
    error: null as string | null,
    isLoading: true,
    eligibleIntegrations: [] as Integration[],
  };
  const [state, setState] = useSetState(initialStateState);

  /*  useEffect(() => {
    setChecksForTable(currentPendingChecks);
  }, [currentPendingChecks]); */
  const formMethods = useForm({
    defaultValues: { integrationId: "", rows: {} as Record<string, any>, all: false },
  });
  const { mutateAsync: doArchive } = useArchivePendingChecksMutation(graphqlClient);
  const handleInit2 = async (passedFilters?: PendingChecksFilterInput) => {
    setLoadingFiltersApplying(true);
    await OrganizationStore.loadPendingActions();
    await integrationsStore.loadCompanyIntegrations(currentCompany);
    const integrations = integrationsStore.companyIntegrationsList.data;
    const eligibleIntegrations = integrations?.integrations?.filter((integration) =>
      integration?.integration?.scopes?.includes("pending-checks"),
    );
    if (!eligibleIntegrations?.length) return;
    const initialIntegrationId = eligibleIntegrations[0]?.integration?.integration_id!;
    setIntegrationIdState(initialIntegrationId);
    if (initialIntegrationId)
      try {
        await UndepositedChecksStore.getPendingChecksFunction(
          currentCompany,
          initialIntegrationId,
          {
            page: pageNumber,
            per_page: perPage,
          },
          false,
          passedFilters || filters,
        );
      } catch (err) {
        console.log("erorare");
      }
    setState({
      pendingChecks: pendingChecks.data?.checks as Check[],
      total: pendingChecks?.data?.total!,
      isLoading: false,
      eligibleIntegrations: eligibleIntegrations as Integration[],
    });
    if (pendingChecks.data) {
      setChecksForTable(pendingChecks.data?.checks);
      setErrorFeedBack("");
    }
    if (pendingChecks.error) {
      setErrorFeedBack("An error occured on this particular company.");
      setChecksForTable([]);
      setState({ ...initialStateState, isLoading: false });
    }
    setLoadingFiltersApplying(false);
  };
  useEffect(() => {
    handleInit2();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCompany, window.location.search, filters?.error_type, filters?.search]);

  const depositedChecksColumns = useCompanyPendingChecksColumns(
    "/org",
    startAddingPermissions,
    startAccountMapping,
    startUserMapping,
    currentCompany || companyId,
    bankAccountsWithGl,
    integrationIdState,
    setArchiveModalOpen,
    setChecksToBeArchived,
    selectedOrganizationId,
    setOrganizationUserId,
    setSigner,
  );

  useEffect(() => {
    contextStore.setSelectedOrganizationId(Cookies.get("lastOrg"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextStore.selectedCompanyId, currentCompany]);
  useEffect(() => {
    if (companyId) {
      integrationsStore.load(companyId).then(() => {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  async function paginationFetcher(pagination: Pagination) {
    const newPendingChecks = await UndepositedChecksStore.getPendingChecksFunction(
      currentCompany,
      integrationIdState,
      pagination,
      true,
      filters,
    );

    setState({
      ...state,
      pendingChecks: newPendingChecks.data?.checks as Check[],
      total: newPendingChecks?.data?.total!,
      isLoading: false,
    });
    setChecksForTable(newPendingChecks.data?.checks);
  }
  const handleCompanyChange = (e: any) => {
    setPageNumber(1);
    setCurrentCompany(e.target.value);
    history.push(`/org/${contextStore.selectedOrganizationId}/import/${e.target.value}`);
  };
  const archiveCheck = async (checksToBeArchived: string) => {
    setLoading(true);
    setArchiveLoading(true);

    const response = await doArchive({
      companyId: companyId,
      integrationId: integrationIdState,
      checks: checksToBeArchived,
    });
    await paginationFetcher({ page: 1, per_page: 10 });
    setArchiveLoading(false);
    setLoading(false);
    if (response.ArchivePendingChecks?.valueOf) {
      setSuccessfullArchiveModal(true);
    }
    setArchiveModalOpen(false);
  };
  const archiveMultipleChecks = () => {
    setArchiveModalOpen(true);
    setChecksToBeArchived(selectedChecksIds);
  };
  if (!companyId) {
    return null;
  }

  const { error, isLoading, total } = state;

  if (error && checksForTable?.length === 0) {
    return (
      <>
        {companiesWithIntegrationsIds.length ? (
          <>
            <InputLabel htmlFor="company">Company</InputLabel>
            <Select
              id="company-select"
              name="company"
              defaultValue={currentCompany}
              value={currentCompany}
              onChange={(e) => {
                handleCompanyChange(e);
              }}
            >
              {companiesWithIntegrationsIds?.map((company) => {
                return (
                  <MenuItem key={company.id} value={company.id}>
                    {company.name}
                  </MenuItem>
                );
              })}
            </Select>
          </>
        ) : (
          <Box sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
            <Spinner />
          </Box>
        )}
        <ComponentContainer>
          <strong>{error}</strong>
        </ComponentContainer>
      </>
    );
  }

  if (isLoading) {
    return (
      <ComponentContainer>
        <Grid container>
          <Spinner withSpaceAround size={30} />
        </Grid>
      </ComponentContainer>
    );
  }

  return (
    <ComponentContainer>
      {/* <PendingChecksHeader
        companiesWithIntegrationsIds={companiesWithIntegrationsIds}
        currentCompany={currentCompany}
        handleCompanyChange={handleCompanyChange}
      /> */}
      <div style={{ marginTop: "2%" }}>
        {!loading ? (
          <Form formMethods={formMethods}>
            <div className={styles.tableContainer}>
              <Box>
                {!loading ? (
                  <>
                    <Filters
                      filters={filters}
                      setFilters={setFilters}
                      filtersFetcher={handleInit2}
                      amountChipLabel={amountChipLabel}
                      companiesWithIntegrationsIds={companiesWithIntegrationsIds}
                      currentCompany={currentCompany}
                      handleCompanyChange={handleCompanyChange}
                    />
                    <Grid padding={"0 20px 10px 20px"}>
                      {Boolean(selectedChecksIds?.length) && (
                        <Button
                          onClick={archiveMultipleChecks}
                          variant="contained"
                        >{`Archive(${selectedChecksIds.length})`}</Button>
                      )}
                    </Grid>
                    {!loadingFiltersApplying ? (
                      <>
                        <Table
                          columns={depositedChecksColumns}
                          data={definitelyFilter(checksForTable)}
                          setSelectedRows={setSelectedChecksIds}
                          getRowId={(row) => row.entity_ref}
                          multipleSelect
                        />
                        <Typography textAlign={"center"} color={"red"}>
                          {errorFeedback}
                        </Typography>
                      </>
                    ) : (
                      <Box sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
                        <Spinner />
                      </Box>
                    )}
                  </>
                ) : (
                  <Box sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
                    <Spinner />
                  </Box>
                )}
                <PendingChecksPagination
                  pageNumber={pageNumber}
                  total={total}
                  per_page={perPage!}
                  setLoading={setLoading}
                  updatePageNumber={(pn) => setPageNumber(pn)}
                  pendingChecksFetcher={paginationFetcher}
                  setPerPage={setPerPage}
                />
              </Box>
            </div>
          </Form>
        ) : (
          <Box sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
            <Spinner />
          </Box>
        )}
      </div>
      <Modal
        onClose={closeUserModal}
        style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
        open={mapUserModalOpen}
      >
        <div>
          <MapUserModal
            companyId={currentCompany || companyId}
            integrationId={integrationIdState}
            signer={signer}
            closeModal={closeUserModal}
          />
        </div>
      </Modal>
      <Modal
        sx={{ overflow: "scroll" }}
        onClose={closeAccountModal}
        style={{ display: "flex", justifyContent: "center" }}
        open={mapAccountModalOpen}
      >
        <div>
          <MapAccountModal
            closeModal={closeAccountModal}
            currentGlAccount={currentGlAccount}
            companyId={currentCompany || companyId}
            organizationId={Cookies.get("lastOrg")}
            integrationId={integrationIdState}
          />
        </div>
      </Modal>
      <Modal
        onClose={closePermissionsModal}
        style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
        open={addPermissionsModalOpen}
      >
        <div>
          <AddPermissionsModal organizationUserId={organizationUserId} entity_type={"company"} user_type={"user"} />
        </div>
      </Modal>
      <ArchiveCheckModal
        archiveCheck={archiveCheck}
        archiveLoading={archiveLoading}
        archiveModalOpen={archiveModalOpen}
        checksToBeArchived={checksToBeArchived}
        closeArchiveModal={closeArchiveModal}
      />
      <Modal
        onClose={closeSuccessfullArchiveModal}
        style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
        open={successfullArchiveModal}
      >
        <div>
          <Paper sx={{ padding: "20px" }}>
            <Typography fontSize={18}>The check was successfully archived</Typography>
          </Paper>
        </div>
      </Modal>
    </ComponentContainer>
  );
});

const ComponentContainer: FunctionComponent = function ComponentContainer({ children }) {
  return <div className={styles.box}>{children}</div>;
};
