import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, IconButton, Tooltip, Typography } from "@mui/material";
import { ExpandLessIcon, ExpandMoreIcon } from "components/common/icons";
import { IVBillSingleSelectAutocompleteSuggestion } from "components/pages/common/VBill/VBillSuggestionsAutocomplete/VBillSuggestionsSingleSelectAutocomplete/VBillSuggestionsSingleSelectAutocomplete";
import { VBillMappingAutocompleteSelector } from "components/pages/VBill/common/Forms/VBillMappingAutocompleteSelector/VBillMappingAutocompleteSelector";
import { IVBillIntegrationSettingsVBillAdditionalMappingsEntryOutput } from "generated/sdk.vbill";
import { useState } from "react";

interface IVBillPayBillStoreItemSelectorProps {
  additionalMappings: IVBillIntegrationSettingsVBillAdditionalMappingsEntryOutput;
  invoiceCoreOrgId: string;
  invoiceCompanyId: string;
  selectedStoreItem?: IVBillSingleSelectAutocompleteSuggestion;
  onSelectStoreItem: (option?: IVBillSingleSelectAutocompleteSuggestion) => void;
  errorMsg?: string;
  btnSmallHeight?: boolean;
}

// TODO: investigate selected not showing in list

export const VBillPayBillStoreItemSelector = ({
  invoiceCoreOrgId,
  invoiceCompanyId,
  additionalMappings,
  selectedStoreItem,
  onSelectStoreItem,
  errorMsg,
  btnSmallHeight,
}: IVBillPayBillStoreItemSelectorProps) => {
  const [autocompletePopoverAnchorEl, setAutocompletePopoverAnchorEl] = useState<HTMLElement>();
  const autocompletePopoverVisible = Boolean(autocompletePopoverAnchorEl);

  const handleAutocompleteSelect = async (option: IVBillSingleSelectAutocompleteSuggestion) => {
    setAutocompletePopoverAnchorEl(undefined);
    onSelectStoreItem(option);
  };

  const handleClearBtnClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    onSelectStoreItem(undefined);
  };

  return (
    <>
      <Button
        variant="outlined"
        onClick={(event) => setAutocompletePopoverAnchorEl(event.currentTarget)}
        endIcon={
          <Box sx={{ display: "flex" }}>
            {selectedStoreItem && (
              <Tooltip arrow title="Clear value">
                <IconButton onClick={handleClearBtnClick} sx={{ padding: 0 }}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip arrow title="Open">
              <IconButton sx={{ padding: 0 }}>
                {autocompletePopoverVisible ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            </Tooltip>
          </Box>
        }
        sx={{
          justifyContent: "space-between",
          textAlign: "left",
          width: "100%",
          ...(btnSmallHeight ? { height: "32px" } : {}),
          ...(errorMsg ? { border: "1px solid #B00020 !important" } : {}),
        }}
      >
        <Typography noWrap>
          {selectedStoreItem
            ? `${selectedStoreItem.name} ${selectedStoreItem.label ? `- ${selectedStoreItem.label}` : ""}`
            : "Select"}
        </Typography>
      </Button>

      {errorMsg && (
        <Typography sx={{ color: "#B00020", fontSize: "12px", margin: "3px 14px 0 " }}>{errorMsg}</Typography>
      )}

      {autocompletePopoverVisible && (
        <VBillMappingAutocompleteSelector
          suggestionsComponent="popover"
          suggestionsPopoverAnchorEl={autocompletePopoverAnchorEl}
          additionalMappings={additionalMappings}
          invoiceCoreOrgId={invoiceCoreOrgId}
          invoiceCompanyId={invoiceCompanyId}
          onCloseAutocomplete={() => setAutocompletePopoverAnchorEl(undefined)}
          onAutocompleteSelect={handleAutocompleteSelect}
          autocompleteSelectedSearchTerm={selectedStoreItem?.name ?? ""}
        />
      )}
    </>
  );
};
