import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Spinner } from "components/common/Spinner/Spinner";
import { CompanyPendingChecksCount, VCheckUndepositedChecksQueryResult } from "generated/sdk";
import { observer } from "mobx-react-lite";
import { useEffect, useMemo, useState } from "react";
import { DataContainerObsevable, useStore } from "storeContainer";
import { COLORS } from "themes/default";
import { PendingChecksComponent } from "./PendingChecksComponent";
import styles from "./Widgets.module.scss";

interface Props {
  undepositedChecks: DataContainerObsevable<VCheckUndepositedChecksQueryResult>;
  basicCompanies?: CompanyPendingChecksCount[] | undefined | null;
  handleClose?: () => void;
}

const typographyStyle = {
  variant: "h3",
  mb: "15px",
  mt: "30px",
  fontSize: "16px",
  fontWeight: 500,
  display: "flex",
  alignItems: "center",
  color: COLORS.newPrimaryColor,
  paddingLeft: "20px",
  className: styles.pendingTransactionsHeaderText,
};

export const PendingTransactions: React.FunctionComponent<Props> = observer(function PendingTransactions({
  undepositedChecks,
  basicCompanies,
  handleClose = () => {},
}) {
  const [companyLoading, setCompanyLoading] = useState(true);
  const [currentCompany, setCurrentCompany] = useState<string>();
  const [totalAmountOfCompanies, setTotalAmountOfCompanies] = useState<number>(0);
  const [totalAmountOfChecks, setTotalAmounOfChecks] = useState<number>(0);
  const OrganizationStore = useStore("OrganizationStore");
  const { companiesWithBankAccountsAndAggregatedData } = OrganizationStore;
  const sessionStore = useStore("SessionStore");
  const contextStore = useStore("ContextStore");

  const showNumberOfcompaniesAndNumberOfChecks = () => {
    let nrOfChecks = 0;
    let nrOfCompanies = 0;
    let lastCompanyId;
    basicCompanies?.forEach((company) => {
      if (company.pending_checks_count) {
        nrOfCompanies++;
        nrOfChecks += company.pending_checks_count;
        lastCompanyId = company.id;
      }
    });
    setTotalAmounOfChecks(nrOfChecks);
    setTotalAmountOfCompanies(nrOfCompanies);
    if (lastCompanyId) setCurrentCompany(lastCompanyId);
    setCompanyLoading(false);
  };

  useEffect(() => {
    showNumberOfcompaniesAndNumberOfChecks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companiesWithBankAccountsAndAggregatedData.data]);
  const showTitle = undepositedChecks.data?.checks?.length || totalAmountOfCompanies;

  const pendingChecksUrl = useMemo(
    () =>
      contextStore.selectedOrganizationId
        ? `/org/${contextStore.selectedOrganizationId}/vchecks/deposits/ready_to_deposit`
        : `/depositAccounts/ledger/ready_to_deposit`,
    [contextStore.selectedOrganizationId],
  );

  return (
    <>
      {showTitle ? <Typography sx={typographyStyle}>Pending Transactions</Typography> : null}
      {undepositedChecks.isFetching || undepositedChecks.isLoading ? (
        <Spinner />
      ) : undepositedChecks.data?.undeposited_checks?.count! > 0 || totalAmountOfCompanies ? (
        <>
          {undepositedChecks.data?.checks?.length ? (
            <PendingChecksComponent
              checkText="Ready to deposit"
              numberOfChecks={undepositedChecks.data?.undeposited_checks?.count!}
              undepositedChecks={undepositedChecks.data}
              url={pendingChecksUrl}
              handleClose={handleClose}
            />
          ) : null}
          {companyLoading ? (
            <Grid sx={{ marginTop: "10px" }} container justifyContent={"center"} alignItems={"center"}>
              <Spinner />
            </Grid>
          ) : null}
          {!companyLoading &&
            Boolean(totalAmountOfCompanies) &&
            sessionStore.acl.canListPendingImportPending(currentCompany ?? "") && (
              <PendingChecksComponent
                checkText="Import Errors"
                numberOfChecks={totalAmountOfChecks}
                url={`/org/${contextStore.selectedOrganizationId}/import/${currentCompany}`}
              />
            )}
        </>
      ) : (
        <Typography sx={typographyStyle}>No Pending Transactions</Typography>
      )}
    </>
  );
});
