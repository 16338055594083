import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, FormHelperText, IconButton, Tooltip, Typography } from "@mui/material";
import { ExpandLessIcon, ExpandMoreIcon } from "components/common/icons";
import { IVBillSingleSelectAutocompleteSuggestion } from "components/pages/common/VBill/VBillSuggestionsAutocomplete/VBillSuggestionsSingleSelectAutocomplete/VBillSuggestionsSingleSelectAutocomplete";
import { VBillMappingAutocompleteSelector } from "components/pages/VBill/common/Forms/VBillMappingAutocompleteSelector/VBillMappingAutocompleteSelector";
import { TVBillFormAutoComOption } from "components/pages/VBill/types";
import { IVBillIntegrationSettingsVBillAdditionalMappingsEntryOutput } from "generated/sdk.vbill";
import { useState } from "react";
import { useController } from "react-hook-form";
import { COLORS } from "themes/default";

interface ISelectSingleMappingFieldSelectorProps {
  additionalMappings: IVBillIntegrationSettingsVBillAdditionalMappingsEntryOutput;
  invoiceCoreOrgId: string;
  invoiceCompanyId: string;
}

export const SelectSingleMappingFieldSelector = ({
  invoiceCoreOrgId,
  invoiceCompanyId,
  additionalMappings: { label, storeDataType, key, required },
  additionalMappings,
}: ISelectSingleMappingFieldSelectorProps) => {
  const {
    field: { value: formFieldValue = null, onChange: formFieldOnChange },
    fieldState: { error },
  } = useController({
    name: `lineItems.${0}.${additionalMappings.storeDataType}`,
    rules: { required: required ?? false },
  });

  const [isAutocompleteDialogOpen, setIsAutocompleteDialogOpen] = useState(false);

  const handleAutocompleteSelect = async (option: IVBillSingleSelectAutocompleteSuggestion) => {
    setIsAutocompleteDialogOpen(false);

    const currentValue: TVBillFormAutoComOption = {
      label: option?.name ?? "",
      label2: option.label,
      addMappingSettingKey: key,
      key: option?.id ?? "",
    };

    formFieldOnChange(currentValue);
  };

  const handleClearBtnClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    formFieldOnChange(null);
  };

  return (
    <Box sx={{ marginBottom: "10px" }}>
      <Typography sx={{ marginBottom: "5px", color: COLORS.logoBlue }}>
        {label ?? storeDataType}:{" "}
        {required && (
          <Tooltip arrow title={`${label ?? storeDataType} is required`}>
            <Box component="span" sx={{ fontSize: "16px", color: COLORS.error, marginLeft: "2px" }}>
              *
            </Box>
          </Tooltip>
        )}
      </Typography>

      <Button
        variant="outlined"
        onClick={() => setIsAutocompleteDialogOpen(true)}
        endIcon={
          <Box sx={{ display: "flex" }}>
            {formFieldValue && (
              <Tooltip arrow title="Clear value">
                <IconButton onClick={handleClearBtnClick} sx={{ padding: 0 }}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip arrow title="Open">
              <IconButton sx={{ padding: 0 }}>
                {isAutocompleteDialogOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            </Tooltip>
          </Box>
        }
        sx={{
          width: "100%",
          justifyContent: "space-between",
          textAlign: "left",
          height: "32px",
          ...(error ? { borderColor: "rgb(176, 0, 32) !important" } : {}),
        }}
      >
        <Typography noWrap>
          {formFieldValue
            ? `${formFieldValue.label} ${formFieldValue.label2 ? `- ${formFieldValue.label2}` : ""}`
            : "Select"}
        </Typography>
      </Button>

      {error && error.type !== "required" && (
        <FormHelperText error sx={{ margin: 0, lineHeight: "15px" }}>
          {error.message}.
        </FormHelperText>
      )}

      {isAutocompleteDialogOpen && (
        <VBillMappingAutocompleteSelector
          suggestionsComponent="dialog"
          additionalMappings={additionalMappings}
          invoiceCoreOrgId={invoiceCoreOrgId}
          invoiceCompanyId={invoiceCompanyId}
          onCloseAutocomplete={() => setIsAutocompleteDialogOpen(false)}
          onAutocompleteSelect={handleAutocompleteSelect}
          autocompleteSelectedSearchTerm={formFieldValue?.label ?? ""}
        />
      )}
    </Box>
  );
};
