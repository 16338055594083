export const chatStaticEmojiList = [
  {
    name: "thumbsup",
    hex: "&#x1F44D;",
  },
  {
    name: "thumbsdown",
    hex: "&#x1F44E;",
  },
  {
    name: "exclamation",
    hex: "&#x2757;",
  },
];
