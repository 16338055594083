import Card from "components/common/old/Card";
import { sum } from "lodash";
import { useStore } from "storeContainer";
import styles from "./Integration.module.scss";
import MappingHeader from "./MappingHeader";
import Table from "../../../../common/Table";
import { useBankAccountsColumns } from "./utils";
import { useEffect, useState } from "react";

export const IntegrationBankAccountMapping = ({
  defaultExpanded,
  readOnly,
}: {
  defaultExpanded?: Boolean;
  readOnly?: boolean;
}) => {
  const integrationsStore = useStore("IntegrationsStore");
  const [bankAccountsWithGl, setBankAccountsWithGl] = useState<any[]>([]);
  const [idOfLoadindRow, setIdOfLoadingRow] = useState<string>();

  let setup_status = integrationsStore.companyIntegrationsList.data?.setup_status;
  let generalLedgerAccounts = integrationsStore.generalLedgerAccountsList.data;

  const mapBankAccountToGlAccount = async (params: { bankAccountId: string; glAccountsIds: string }) => {
    let alreadyMappedBankAccount = null;
    let sameBankAccount = false;
    let hasMapping = false;
    setIdOfLoadingRow(params.bankAccountId);
    bankAccountsWithGl.forEach((element) => {
      if (element.id === params.bankAccountId && element.gl_accounts.length > 0) {
        hasMapping = true;
      }
      element.gl_accounts.forEach(async (glAccount: any) => {
        if (glAccount.id === params.glAccountsIds) {
          alreadyMappedBankAccount = element.id;
          sameBankAccount = params.bankAccountId === element.id;
        }
      });
    });
    // we treat the special case when we select the None Checkbox and all the Global Accounts must be unmapped
    // none is added in components/pages/Settings/Integrations/Integration/utils.tsx:42
    try {
      if (params.glAccountsIds === "none" && hasMapping) {
        await integrationsStore.setBankAccountGlAccountMapping(params.bankAccountId, undefined);
      } else if (alreadyMappedBankAccount) {
        await integrationsStore.setBankAccountGlAccountMapping(alreadyMappedBankAccount, undefined);
      }
      if (!sameBankAccount && params.glAccountsIds !== "none") {
        await integrationsStore.setBankAccountGlAccountMapping(params.bankAccountId, params.glAccountsIds);
      }
    } catch (e) {
      console.log(e);
    }
    setIdOfLoadingRow(undefined);
  };

  useEffect(() => {
    setBankAccountsWithGl(
      integrationsStore.companyIntegrationsList.data?.bank_accounts?.map((ba) => ({
        ...ba,
        options: generalLedgerAccounts || [],
        readOnly: false,
      })) || [],
    );
  }, [generalLedgerAccounts, integrationsStore.companyIntegrationsList.data?.bank_accounts]);

  const bankAccountColumns = useBankAccountsColumns(readOnly ?? false, mapBankAccountToGlAccount, idOfLoadindRow);

  return (
    <>
      <Card
        className={styles.mappingCard}
        title={`Bank Accounts (${setup_status?.mapped_bank_accounts}/${sum([
          setup_status?.mapped_bank_accounts,
          setup_status?.unmapped_bank_accounts,
        ])})`}
        defaultExpanded={defaultExpanded}
        headerProps={{
          mapped: setup_status?.mapped_bank_accounts,
          unmapped: setup_status?.unmapped_bank_accounts,
          "data-element-id": "bank_accounts",
        }}
        components={{ Header: MappingHeader }}
      >
        <Table columns={bankAccountColumns} data={bankAccountsWithGl} />
      </Card>
    </>
  );
};
