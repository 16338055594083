import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Paper, Table, TableContainer, TablePagination, Typography } from "@mui/material";
import { formatCurrency } from "common/helpers/utils";
import { IVBillBatchStatus } from "generated/sdk.vbill";
import { observer } from "mobx-react";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { Droppable } from "react-beautiful-dnd";
import { useParams } from "react-router";
import { useStore } from "storeContainer";
import { COLORS } from "themes/default";
import { VBillActiveFilters } from "../../../common/VBill/VBillActiveFilters/VBillActiveFilters";
import { VBillDroppableArea } from "../../utils";
import { IBillsAccordianExpanded } from "../BatchVBills";
import {
  BatchVBillsAccordian,
  BatchVBillsAccordianDetails,
  BatchVBillsAccordianSummary,
} from "../BatchVBillsAccordian/BatchVBillsAccordian";
import { BatchVBillsDraggingOverlay } from "../BatchVBillsDraggingOverlay/BatchVBillsDraggingOverlay";
import { BatchVBillsHeader } from "../BatchVBillsHeader/BatchVBillsHeader";
import { VBillsSelectedFilters } from "./VBillsSelectedFilters/VBillsSelectedFilters";
import { VBillsSelectedList } from "./VBillsSelectedList/VBillsSelectedList";

interface IBatchVBillsSelectedProps {
  onDeleteVBillsFromBatch: (billIds: number[]) => Promise<void>;
  showDraggingOverlay?: boolean;
  billsAccordianExpanded: IBillsAccordianExpanded;
  setBillsAccordianExpanded: Dispatch<SetStateAction<Partial<IBillsAccordianExpanded>>>;
}

export const BatchVBillsSelected = observer(function BatchVBillsSelected({
  onDeleteVBillsFromBatch,
  showDraggingOverlay,
  billsAccordianExpanded,
  setBillsAccordianExpanded,
}: IBatchVBillsSelectedProps) {
  const vBillBatchDetailsStore = useStore("VBillBatchDetailsStore");
  const [selectedVBillsToDelete, setSelectedVBillsToDelete] = useState<number[]>([]);
  const [isBatchFetching, setIsBatchFetching] = useState(false);
  const [selectedFiltersVisible, setSelectedFiltersVisible] = useState(false);
  const { batchId } = useParams<{ batchId: string }>();
  const { batchSelectedVBills, batchFilters, batchDetails, statsFiltersAppliedForBills } = vBillBatchDetailsStore;
  const batchSelectedVBillsData = batchSelectedVBills.data;
  const batchDetailsData = batchDetails.data;

  const youSelectedAmount = useMemo(
    () =>
      batchSelectedVBillsData?.items
        .filter(({ id }) => selectedVBillsToDelete.includes(id))
        .reduce((result, bill) => (result = result + Number(bill.amount ?? 0)), 0) ?? 0,
    [batchSelectedVBillsData?.items, selectedVBillsToDelete],
  );
  const totalBatchSelectedVBills = useMemo(
    () => batchSelectedVBills.data?.items.length ?? 0,
    [batchSelectedVBills.data?.items.length],
  );
  const billsHeaderSelectAllCheckboxChecked = useMemo(
    () => (totalBatchSelectedVBills === 0 ? false : selectedVBillsToDelete.length === totalBatchSelectedVBills),
    [selectedVBillsToDelete.length, totalBatchSelectedVBills],
  );
  const billsHeaderSelectAllCheckboxIndeterminate = useMemo(
    () => (selectedVBillsToDelete.length ? selectedVBillsToDelete.length < totalBatchSelectedVBills : false),
    [selectedVBillsToDelete.length, totalBatchSelectedVBills],
  );
  const selectedBillsAccordianExpanded = useMemo(
    () => billsAccordianExpanded.selectedBills,
    [billsAccordianExpanded.selectedBills],
  );
  const isBatchClosed = useMemo(
    () =>
      batchDetailsData?.status === IVBillBatchStatus.Closed || batchDetailsData?.status === IVBillBatchStatus.Deleted,
    [batchDetailsData?.status],
  );
  const formattedYouSelectedAmount = useMemo(() => formatCurrency(youSelectedAmount), [youSelectedAmount]);
  const showVBillsHeaderSelectAllCheckbox = useMemo(
    () => !!(batchSelectedVBillsData?.items ?? []).length && !isBatchClosed,
    [batchSelectedVBillsData?.items, isBatchClosed],
  );

  useEffect(() => {
    setSelectedFiltersVisible(false);
  }, [batchId]);

  useEffect(() => {
    setSelectedFiltersVisible(statsFiltersAppliedForBills === "selected");
  }, [statsFiltersAppliedForBills]);

  const handleVBillCheckboxChange = (checked: boolean, invoiceId: number) => {
    if (checked) {
      setSelectedVBillsToDelete((prev) => [...prev, invoiceId]);
    } else {
      setSelectedVBillsToDelete((prev) => [...prev.filter((id) => id !== invoiceId)]);
    }
  };

  const handleSelectAllVBillCheckboxCLick = () => {
    if (billsHeaderSelectAllCheckboxChecked || billsHeaderSelectAllCheckboxIndeterminate) {
      setSelectedVBillsToDelete([]);
    } else {
      setSelectedVBillsToDelete((batchSelectedVBillsData?.items ?? []).map(({ id }) => id));
    }
  };

  const handleAddVBillsToBatch = async () => {
    setIsBatchFetching(true);
    await onDeleteVBillsFromBatch(selectedVBillsToDelete);
    setIsBatchFetching(false);
    setSelectedVBillsToDelete([]);
  };

  return (
    <Paper sx={{ padding: "10px", marginBottom: "30px" }}>
      <BatchVBillsAccordian
        expanded={selectedBillsAccordianExpanded}
        onChange={() => setBillsAccordianExpanded((prev) => ({ selectedBills: !prev.selectedBills }))}
      >
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "10px" }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <Typography sx={{ fontSize: "18px", color: COLORS.logoBlue }}>
              Added bills{" "}
              <Box component="strong" sx={{ fontSize: "14px" }}>
                ({batchSelectedVBillsData?.total ?? 0})
              </Box>
            </Typography>

            {selectedBillsAccordianExpanded && (
              <Button
                onClick={() => setSelectedFiltersVisible((prev) => !prev)}
                sx={{ ":hover": { textDecoration: "underline" }, height: "30px" }}
              >
                {selectedFiltersVisible ? "Hide" : "Show"} filters
              </Button>
            )}
          </Box>

          <BatchVBillsAccordianSummary billsAccordianExpanded={selectedBillsAccordianExpanded} />
        </Box>

        <BatchVBillsAccordianDetails>
          {selectedFiltersVisible && (
            <>
              <VBillsSelectedFilters />

              <VBillActiveFilters
                chipSmallHeight
                showCompanies
                filters={batchFilters.vbill_sel_fil}
                onFiltersChange={(values) =>
                  vBillBatchDetailsStore.setBatchFilters((prev) => ({
                    vbill_sel_fil: Object.keys(values).length ? { ...prev.vbill_sel_fil, ...values } : undefined,
                  }))
                }
              />
            </>
          )}
          {!isBatchClosed && selectedVBillsToDelete.length > 0 && (
            <Box sx={{ display: "flex", alignItems: "center", gap: "15px", margin: "10px 0" }}>
              <LoadingButton
                loading={isBatchFetching}
                variant="contained"
                startIcon={<RemoveCircleOutlineIcon />}
                onClick={handleAddVBillsToBatch}
                sx={{ height: "30px" }}
              >
                Remove
              </LoadingButton>

              <Typography variant="body2">
                You have selected {selectedVBillsToDelete?.length} bill(s):{" "}
                <strong>{formattedYouSelectedAmount}</strong> on this page.
              </Typography>
            </Box>
          )}

          <Droppable droppableId={VBillDroppableArea.SELECTED}>
            {(provided) => (
              <TableContainer
                ref={provided.innerRef}
                {...provided.droppableProps}
                sx={{
                  position: "relative",
                  marginTop: "5px",
                }}
              >
                {showDraggingOverlay && <BatchVBillsDraggingOverlay />}

                <Table>
                  <BatchVBillsHeader
                    selectAllCheckboxChecked={billsHeaderSelectAllCheckboxChecked}
                    selectAllCheckboxIndeterminate={billsHeaderSelectAllCheckboxIndeterminate}
                    onSelectAllVBillCheckboxClick={handleSelectAllVBillCheckboxCLick}
                    sortVBillsValues={batchFilters.vbill_sel_fil}
                    setSortVBillsValues={(key, value) =>
                      vBillBatchDetailsStore.setBatchFilters((prev) => ({
                        // TODO: on sort reset others
                        vbill_sel_fil: { ...prev.vbill_sel_fil, [key]: value },
                      }))
                    }
                    showSelectAllCheckbox={showVBillsHeaderSelectAllCheckbox}
                  />

                  <VBillsSelectedList
                    billsAccordianExpanded={billsAccordianExpanded}
                    droppableProvidedPlaceholder={provided.placeholder}
                    onBatchVBillCheckboxChange={handleVBillCheckboxChange}
                    selectedVBillsToDelete={selectedVBillsToDelete}
                  />
                </Table>
              </TableContainer>
            )}
          </Droppable>

          <TablePagination
            component="div"
            showFirstButton
            showLastButton
            page={(batchFilters.vbill_sel_pag.page ?? 1) - 1}
            rowsPerPage={batchFilters.vbill_sel_pag.per_page ?? 10}
            rowsPerPageOptions={[10, 25, 50, 100]}
            count={batchSelectedVBillsData?.total ?? 10}
            onRowsPerPageChange={(event) =>
              vBillBatchDetailsStore.setBatchFilters({
                vbill_sel_pag: { per_page: Number(event.target.value), page: 1 },
              })
            }
            onPageChange={(_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) =>
              vBillBatchDetailsStore.setBatchFilters((prev) => ({
                vbill_sel_pag: { ...prev.vbill_sel_pag, page: newPage + 1 },
              }))
            }
          />
        </BatchVBillsAccordianDetails>
      </BatchVBillsAccordian>
    </Paper>
  );
});
