import { LimitCreateVChecksRule } from "../../../../../../generated/sdk";
import { AllBasePermissionType } from "../../../../../../storesMobx/AclStore";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { COLORS } from "../../../../../../themes/default";
import StarOutlineIcon from "@mui/icons-material/StarOutline";

import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import { AclSourceLine } from "./AclSource";
import { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { ModalPanel } from "components/common/ModalPanel/ModalPanel";
import { AclPermLimitCreateCheckRulesInput } from "./AclPermLimitCreateCheckRulesInput";
import { observer } from "mobx-react-lite";
import { useStore } from "storeContainer";

export const AclPermLimitCreateCheckRulesView = observer(function AclPermLimitCreateCheckRulesView({
  value,
  onChange,
  outlined,
  user_id,
  orgDirectPermissions,
}: {
  value: LimitCreateVChecksRule[];
  onChange?: (value: LimitCreateVChecksRule[]) => void;
  outlined?: true;
  user_id?: string;
  orgDirectPermissions?: any;
}) {
  const maxShowWhenCollapsed = 2;
  const sortedValue = value.slice().sort((a, b) => (a?.above || 0) - (b?.above || 0));
  const [maxNumber, setMaxNumber] = useState(outlined ? value.length : Math.min(value.length, maxShowWhenCollapsed));
  const [modalOpened, setModalOpened] = useState(false);
  const sessionStore = useStore("SessionStore");
  useEffect(() => {
    setMaxNumber(outlined ? value.length : Math.min(value.length, maxShowWhenCollapsed));
  }, [outlined, value]);

  return (
    <>
      {onChange && (
        <ModalPanel
          style={{ overlay: { zIndex: 3 } }}
          isOpen={modalOpened}
          onRequestClose={() => setModalOpened(false)}
        >
          <Box sx={{ outline: 0, width: 500, p: 3 }}>
            <Typography variant="h2" sx={{ mb: 1 }}>
              Create vCheck Rules
            </Typography>
            <AclPermLimitCreateCheckRulesInput
              value={value}
              onChange={(value) => {
                onChange(value);
                setModalOpened(false);
              }}
              showButtons={true}
              onCancel={() => setModalOpened(false)}
            />
          </Box>
        </ModalPanel>
      )}
      <Grid
        container
        rowSpacing={1}
        columnSpacing={1}
        sx={{ mt: 1, outline: 1, width: "200px", border: "1px solid rgba(224,224,224,1)", ml: "-3px" }}
      >
        {sortedValue.slice(0, maxNumber).map((rule, idx) => {
          const isLast = idx === value.length - 1;
          const ruleCount = +rule.count! || 0;
          const nextRule = sortedValue[idx + 1];
          const nextAbove = nextRule?.above;
          const row = (
            <Grid
              item
              marginLeft={3}
              xs={idx === sortedValue.slice(0, maxNumber).length - 1 ? 6 : 12}
              marginBottom={idx === sortedValue.slice(0, maxNumber).length - 1 ? 1 : 0}
              key={idx}
            >
              <Typography variant="body2" color={COLORS.dark} sx={{ fontWeight: 500 }}>
                {!isLast ? (
                  <>
                    ${rule.above}-${nextAbove}
                  </>
                ) : (
                  <>&gt;&nbsp;${rule.above}</>
                )}
              </Typography>
              <Typography variant="body2">
                {ruleCount === 0 ? "Auto approve" : ""}
                {ruleCount === 1 ? "Approval required" : ""}
                {ruleCount > 1 ? <>{rule.count}&nbsp;Approvals</> : ""}
              </Typography>
            </Grid>
          );

          return row;
        })}
        {maxNumber < value.length && (
          <Grid item xs={2}>
            <Button size={"small"} onClick={() => setMaxNumber(value.length)}>
              Show {value.length - maxNumber} more
            </Button>
          </Grid>
        )}
        {onChange && (
          <Grid item xs={2}>
            <Button
              disabled={
                sessionStore.selectedOrganizationUserId === user_id &&
                !!!orgDirectPermissions["ORGANIZATION_COMPANY_MANAGER"]
              }
              onClick={() => setModalOpened(true)}
            >
              <EditIcon />
            </Button>
          </Grid>
        )}
      </Grid>
    </>
  );
});

export const AclPermLimitCreateCheckRules = ({
  value,
  rowPermission,
  outlined,
}: {
  value: LimitCreateVChecksRule[];
  rowPermission: AllBasePermissionType | undefined;
  outlined: true | undefined;
}) => {
  return (
    <div>
      {rowPermission ? (
        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          {rowPermission?.direct === true ? (
            <StarOutlineIcon fontSize={"small"} sx={{ color: COLORS.mediumGrey }} />
          ) : rowPermission?.inherited === true ? (
            <SubdirectoryArrowRightIcon fontSize={"small"} sx={{ color: COLORS.mediumGrey }} />
          ) : null}
          <AclSourceLine rowPermission={rowPermission} />
        </Box>
      ) : null}

      <AclPermLimitCreateCheckRulesView value={value} outlined={outlined} />
      {/* <pre>{JSON.stringify(value)}</pre> */}
    </div>
  );
};
