import { LoadingButton } from "@mui/lab";
import { Box, Button, Dialog, Divider, IconButton, Tooltip, Typography } from "@mui/material";
import { CloseIcon } from "components/common/icons";
import { useChatChannelStore } from "modules/Chat/hooks/hooks";
import { FunctionComponent, useState } from "react";

interface IChatMarkPrivateProps {
  onCloseDialog: () => void;
}

export const ChatMarkPrivate: FunctionComponent<IChatMarkPrivateProps> = ({ onCloseDialog }) => {
  const chatChannelStore = useChatChannelStore();
  const [isMakePrivateChannelReqLoading, setIsMakePrivateChannelReqLoading] = useState(false);

  const handleMarkPrivateOkBtnClick = () => {
    setIsMakePrivateChannelReqLoading(true);
    chatChannelStore?.setMakePrivateChannel().then(() => {
      setIsMakePrivateChannelReqLoading(false);
      onCloseDialog();
    });
  };

  return (
    <Dialog onClose={onCloseDialog} open PaperProps={{ sx: { padding: "15px", position: "relative", width: 300 } }}>
      <Tooltip arrow title="Close">
        <IconButton onClick={onCloseDialog} sx={{ position: "absolute", top: "5px", right: "5px", zIndex: 1 }}>
          <CloseIcon sx={{ fontSize: "20px" }} />
        </IconButton>
      </Tooltip>

      <Typography sx={{ fontSize: "20px", marginBottom: "20px" }}>Make this channel private?</Typography>

      <Divider sx={{ margin: "20px 0 10px" }} />

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "15px",
        }}
      >
        <LoadingButton
          sx={{ height: "30px" }}
          variant="contained"
          onClick={handleMarkPrivateOkBtnClick}
          loading={isMakePrivateChannelReqLoading}
        >
          Ok
        </LoadingButton>

        <Button variant="outlined" onClick={onCloseDialog} sx={{ height: "30px" }}>
          Cancel
        </Button>
      </Box>
    </Dialog>
  );
};
