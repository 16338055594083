import Box from "@mui/material/Box";

import { observer } from "mobx-react-lite";
import { ReactElement } from "react";
import { VChecks } from "../../index";
import { CheckFiltersProvider } from "common/hooks/useCheckFilters";

const DepositAccountsLedger = (): ReactElement => {
  return (
    <Box sx={{ width: "100%", paddingBottom: "10%", maxWidth: "1400px" }}>
      <CheckFiltersProvider prefix="check">
        <VChecks unvalidatedLedger />
      </CheckFiltersProvider>
    </Box>
  );
};

export default observer(DepositAccountsLedger);
