import { TableHead, TableRow, Tooltip } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";
import { vBillLedgerTHeadSx } from "components/pages/common/VBill/styles";
import { IVBillSortOrderType } from "generated/sdk.vbill";
import { CodingHeaderDatesSorter } from "./CodingHeaderDatesSorter/CodingHeaderDatesSorter";
import { useTableHeadCells } from "./useTableHeadCells";

export const CodingHeader = () => {
  const tableHeadCells = useTableHeadCells();

  return (
    <TableHead>
      <TableRow sx={{ cursor: "default" }}>
        {tableHeadCells.map((headerCell) =>
          headerCell.sortable ? (
            <TableCell
              key={headerCell.id}
              sx={{ ...vBillLedgerTHeadSx, ...(headerCell.id === "amount" ? { textAlign: "right" } : {}) }}
              sortDirection={headerCell.value === IVBillSortOrderType.Desc ? "asc" : "desc"} // Set aria-sort direction.
            >
              <Tooltip
                arrow
                title={`Sort by ${headerCell.label}: ${headerCell.value === IVBillSortOrderType.Desc ? "Asc" : "Desc"}`}
              >
                <TableSortLabel
                  active={!!headerCell.value}
                  direction={headerCell.value === IVBillSortOrderType.Asc ? "desc" : "asc"}
                  sx={{
                    ...(headerCell.id === "amount" ? { flexDirection: "row-reverse" } : {}),
                    ...(!!headerCell.value ? { textDecoration: "underline" } : {}),
                  }}
                  onClick={() =>
                    headerCell.onChange?.(
                      headerCell.value === IVBillSortOrderType.Desc
                        ? IVBillSortOrderType.Asc
                        : IVBillSortOrderType.Desc,
                    )
                  }
                >
                  {headerCell.label}
                </TableSortLabel>
              </Tooltip>
            </TableCell>
          ) : headerCell.id === "dates" ? (
            <CodingHeaderDatesSorter key={headerCell.id} />
          ) : (
            <TableCell sx={vBillLedgerTHeadSx} key={headerCell.id}>
              {headerCell.label}
            </TableCell>
          ),
        )}
      </TableRow>
    </TableHead>
  );
};
