import { LoadingButton } from "@mui/lab";
import { Box, Button, Dialog, Divider, Typography } from "@mui/material";
import { formatCurrency } from "common/helpers/utils";
import { VBillPriceAmount } from "components/pages/common/VBill/VBillPriceAmount/VBillPriceAmount";
import { IVBillVBillInvoicesQuery } from "generated/graphql.vbill";
import { observer } from "mobx-react";
import { useMemo, useState } from "react";
import { useParams } from "react-router";
import { useStore } from "storeContainer";
import { COLORS } from "themes/default";

interface IVBillChangeAmountDialogProps {
  onCloseDialog: () => void;
  vBill: IVBillVBillInvoicesQuery["invoices"]["items"][number];
}

export const VBillChangeAmountDialog = observer(function VBillChangeAmountDialog({
  onCloseDialog,
  vBill,
}: IVBillChangeAmountDialogProps) {
  const vBillBatchDetailsStore = useStore("VBillBatchDetailsStore");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const amount = useMemo(() => vBill.amounts?.amountFreeToPay ?? "0", [vBill.amounts?.amountFreeToPay]);
  const [vBillAmount, setVBillAmount] = useState(amount);
  const [hasVBillAmountError, setHasVBillAmountError] = useState(false);
  const { batchId, organizationId } = useParams<{ batchId: string; organizationId: string }>();
  const { batchFilters } = vBillBatchDetailsStore;
  const vBillMemoInBatch = useMemo(
    () => vBill.amounts?.amountInBatchesBreakdown.find((amount) => amount?.batchId === Number(batchId))?.memo,
    [batchId, vBill.amounts?.amountInBatchesBreakdown],
  );
  const amounts = vBill.amounts;
  const maxAmountToChange = useMemo(
    () =>
      Number(amount) -
      (amounts?.amountInBatchesBreakdown ?? [])
        .filter((batchBreakdown) => `${batchBreakdown.batchId}` !== batchId && batchBreakdown.invoiceId !== vBill.id) // TODO: move inside reduce
        .reduce((result, amount) => (result = result + Number(amount.batchAmountForInvoice ?? 0)), 0),
    [amount, amounts?.amountInBatchesBreakdown, batchId, vBill.id],
  );

  const handleSaveDialog = async () => {
    setIsLoading(true);
    setHasVBillAmountError(false);

    if (Number(vBillAmount) > maxAmountToChange) {
      setHasVBillAmountError(true);
      setIsLoading(false);
      return;
    }

    await vBillBatchDetailsStore.addVBillToBatch({
      batchId: Number(batchId),
      batchInvoices: { invoiceId: vBill.id, batchAmountForInvoice: vBillAmount, memo: vBillMemoInBatch },
    });
    vBillBatchDetailsStore.getBatchSelectedVBills(
      {
        pagination: batchFilters.vbill_sel_pag,
        filters: { ...batchFilters.vbill_sel_fil, organizationId, batch_id_in: [Number(batchId)] },
      },
      true,
    );

    setIsLoading(false);
    onCloseDialog();
  };

  return (
    <Dialog open onClose={onCloseDialog} PaperProps={{ sx: { padding: "15px" } }}>
      <Box sx={{ width: "200px" }}>
        <Typography sx={{ fontSize: "18px", marginBottom: "20px" }}>Set new amount:</Typography>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSaveDialog();
          }}
        >
          <VBillPriceAmount
            value={vBillAmount}
            setValue={(value) => setVBillAmount(value)}
            placeholder={String(maxAmountToChange)}
            sx={{ width: "100%" }}
          />

          {hasVBillAmountError && (
            <Typography sx={{ textAlign: "center", color: COLORS.darkRed, marginTop: "15px" }}>
              Error: Amount should be less then {formatCurrency(maxAmountToChange)}
            </Typography>
          )}

          <Divider sx={{ margin: "20px 0" }} />

          <Box sx={{ display: "flex", justifyContent: "end", gap: "15px" }}>
            <LoadingButton loading={isLoading} variant="contained" sx={{ height: "30px" }} type="submit">
              Save
            </LoadingButton>
            <Button variant="outlined" type="button" onClick={onCloseDialog} sx={{ height: "30px" }}>
              Cancel
            </Button>
          </Box>
        </form>
      </Box>
    </Dialog>
  );
});
