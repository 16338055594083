import { Box } from "@mui/material";
import { IVBillVBillInvoiceQuery } from "generated/sdk.vbill";
import { observer } from "mobx-react";
import { useMemo } from "react";
import { useStore } from "storeContainer";
import { FileItem } from "./FileItem/FileItem";

export type TVBillFileInfo = NonNullable<IVBillVBillInvoiceQuery["invoice"]["files"]>[number];

interface IFilesSelectorProps {
  selectedFile: TVBillFileInfo;
  onChangeFileBtnClick: (file: TVBillFileInfo) => void;
}

export const FilesSelector = observer(({ selectedFile, onChangeFileBtnClick }: IFilesSelectorProps) => {
  const vBillStore = useStore("VBillStore");
  const sessionStore = useStore("SessionStore");
  const { invoice } = vBillStore;
  const invoiceData = useMemo(() => invoice.data, [invoice.data]);
  const canMoveAttachmentsToNewBill = useMemo(
    () => sessionStore.acl.canMoveAttachmentToNewBill(invoiceData),
    [invoiceData],
  );
  const allowMoveToNewBill = useMemo(
    () => canMoveAttachmentsToNewBill && (invoiceData?.files ?? []).filter((file) => !file.movedToInvoice).length > 1,
    [canMoveAttachmentsToNewBill, invoiceData?.files],
  );

  return (
    <Box sx={{ marginBottom: "20px", display: "flex", flexWrap: "wrap", gap: "10px" }}>
      {invoiceData?.files?.map((file) => (
        <FileItem
          key={file.id}
          file={file}
          selected={file.id === selectedFile?.id}
          onChangeFileBtnClick={onChangeFileBtnClick}
          allowMoveToNewBill={allowMoveToNewBill}
          reloadData={() => invoice.reload?.({ markAsLoading: false })}
        />
      ))}
    </Box>
  );
});
