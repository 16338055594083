import { makeObservable, observable, action } from "mobx";
import { StoreBase } from "./StoreBase";
import { createObservableContainer } from "storeContainer";
import {
  AutoDepositRuleGraphQlType,
  ConditionGraphQlType,
  GetAutoDepositRulesForEmailAddressQueryQuery,
  getSdk,
} from "generated/sdk";
import { definitely } from "generated/utils";

import { graphqlClient } from "common/graphqlClient";
const {
  GetAutoDepositRulesForEmailAddressQuery,
  CreateAutoDepositRuleMutation,
  DeleteAutoDepositRuleMutation,
  UpdateAutoDepositRuleMutation,
  UpdateAutoDepositRulePriorityMutation,
} = getSdk(graphqlClient);
interface Email {
  id: string;
  address: string;
  rules: Rule[];
}

export interface Rule {
  id: string;
  emailId: string;
  accountId: string;
  memoFilter: string;
}

class AutoDepositRulesStore extends StoreBase {
  autodepositRules =
    createObservableContainer<
      GetAutoDepositRulesForEmailAddressQueryQuery["GetAutoDepositRulesForEmailAddressQuery"]
    >();

  async fetchAutoDepositRoles(
    email_address_id: string,
  ): Promise<GetAutoDepositRulesForEmailAddressQueryQuery["GetAutoDepositRulesForEmailAddressQuery"]> {
    return (await GetAutoDepositRulesForEmailAddressQuery({ email_address_id }))
      .GetAutoDepositRulesForEmailAddressQuery;
  }
  async getAutoDepositRules(email_address_id: string, forceUpdate?: boolean) {
    return this.autodepositRules.cachedLoad(() => this.fetchAutoDepositRoles(email_address_id), [email_address_id], {
      forceUpdate,
    });
  }
  async createAutoDepositRule(bank_account_id: string, conditions: ConditionGraphQlType, rule_priority: string[]) {
    return await CreateAutoDepositRuleMutation({ bank_account_id, conditions, rule_priority });
  }
  async updateAutoDepositRule(
    auto_deposit_rule_id: string,
    bank_account_id: string,
    conditions: ConditionGraphQlType,
    rule_priority: string[],
  ) {
    return await UpdateAutoDepositRuleMutation({ auto_deposit_rule_id, bank_account_id, conditions, rule_priority });
  }
  async UpdateAutoDepositRulePriority(email_address_id: string, rule_priority: string[]) {
    return await UpdateAutoDepositRulePriorityMutation({ email_address_id, rule_priority });
  }
  async deleteAutodepositRule(auto_deposit_rule_id: string) {
    return await DeleteAutoDepositRuleMutation({ auto_deposit_rule_id });
  }
  /*  addRule = (emailId: string, accountId: string, memoFilter: string) => {
    const id = ""; 
    const rule = { id, emailId, accountId, memoFilter };
    this.rules.push(rule);
    // Send mutation to server to create new rule
  };
 
  updateRule = (ruleId: string, accountId: string, memoFilter: string) => {
    const rule = this.rules.find((r) => r.id === ruleId);
    if (rule) {
      rule.accountId = accountId;
      rule.memoFilter = memoFilter;
      // Send mutation to server to update rule
    }
  };

  deleteRule = (ruleId: string) => {
    this.rules = this.rules.filter((r) => r.id !== ruleId);
    // Send mutation to server to delete rule
  };

  getRulesForEmail = (emailId: string) => {
    return this.rules.filter((r) => r.emailId === emailId);
  };

  handleRuleConflict = (emailId: string, conflictingRules: Rule[]) => {
    // Show conflict resolution modal
  }; */
}

export default AutoDepositRulesStore;
