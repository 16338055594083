import { COLORS } from "themes/default";

export const vBillToolbarOuterSx = {
  borderBottom: 1,
  borderColor: COLORS.lightestGrey,
  padding: "10px",
  position: "sticky",
  top: "0",
  backgroundColor: COLORS.white,
  zIndex: 1000,
};

export const vBillToolbarInnerSx = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "15px",
};

export const vBillToolbarLeftColSx = {
  display: "flex",
  gap: "10px",
  alignItems: "center",
};
