import { Box, SxProps, Theme, Typography } from "@mui/material";
import { formatCurrency } from "common/helpers/utils";
import { BatchProcessingIcon } from "components/common/icons";
import { getVBillFormattedDate } from "components/pages/common/VBill/utils";
import { format } from "date-fns";
import {
  IVBillBillsFiltersInput,
  IVBillReactionParentType,
  IVBillVBillGetBatchListQuery,
  IVBillVBillGetReactionsStatsQuery,
} from "generated/graphql.vbill";
import { isNil } from "lodash";
import { useMemo } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { useStore } from "storeContainer";
import { fns } from "../../utils";
import { BatchLineMenu } from "../BatchLineMenu/BatchLineMenu";
import { LightTooltip } from "../LightTooltip/LightTooltip";
import { Reactions } from "../Reactions/Reactions";

const tooltipTypographyStyles = { fontSize: "14px", padding: "5px" };
const tooltipDefaultProps = {
  disableFocusListener: true,
  disableTouchListener: true,
  arrow: true,
};

interface IBatchAmountsLineChartProps {
  batch?: NonNullable<IVBillVBillGetBatchListQuery["getBatchList"]["data"]>["items"][number];
  showMenu?: boolean;
  showTitle?: boolean;
  showMainTooltip?: boolean;
  withLink?: boolean;
  onLineMenuAddEditBatchSubmit?: () => void;
  onLineMenuFiltersChange?: (batchFilters: IVBillBillsFiltersInput) => Promise<void>;
  onLineMenuConfirmDeleteBatchSubmit?: () => void;
  onLineMenuMembersChange?: () => void;
  onLineMenuRemoveBillsSubmit?: () => void;
  reactionsStatsGroups?: IVBillVBillGetReactionsStatsQuery["getReactionsStats"]["groups"];
  onReactionClick?: () => void;
  showReactions?: boolean;
  sx?: SxProps<Theme> | undefined;
  onLinkClick?: () => void;
}

const opacBlueColor = "#B2C9D8";
const opacRedColor = "#F99794";
const opacGrayColor = "#DEE0E2";

export const BatchAmountsLineChart = ({
  batch,
  showMainTooltip,
  showMenu,
  showTitle,
  withLink,
  onLineMenuAddEditBatchSubmit,
  onLineMenuFiltersChange,
  onLineMenuConfirmDeleteBatchSubmit,
  onLineMenuMembersChange,
  onLineMenuRemoveBillsSubmit,
  reactionsStatsGroups,
  onReactionClick,
  showReactions,
  onLinkClick,
  sx,
}: IBatchAmountsLineChartProps) => {
  const sessionStore = useStore("SessionStore");
  const { organizationId } = useParams<{ organizationId: string }>();

  const companyNames = useMemo(
    () =>
      (batch?.batchFilters?.companyIds ?? [])
        .map(
          (compId) =>
            (
              sessionStore.session.data?.organizationUsers?.find(
                (orgUser) => orgUser.organization.id === organizationId,
              )?.organization.companies ?? []
            ).find(({ id }) => id === compId)?.name,
        )
        .join(", "),
    [sessionStore.session.data?.organizationUsers, organizationId, batch?.batchFilters?.companyIds],
  );

  const totalBatchCompanies = (batch?.batchFilters?.companyIds ?? []).length;
  const invoicesAmount = Number(batch?.invoicesAmount ?? 0);
  const budgetAmount = Number(batch?.budget ?? 0);
  const isOverBudget = invoicesAmount > budgetAmount;
  const reactionsStats = reactionsStatsGroups?.find(
    (group) => group.parentKey === `${batch?.id ?? ""}` && group.parentType === IVBillReactionParentType.VbillBatch,
  )?.stats;

  return (
    <Box
      sx={{
        display: "flex",
        gap: "5px",
        ...sx,
      }}
    >
      {showMainTooltip && (
        <LightTooltip
          {...tooltipDefaultProps}
          title={
            <Typography sx={tooltipTypographyStyles}>
              {invoicesAmount > 0 && (
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  Used: <strong>{formatCurrency(invoicesAmount)}</strong>
                </Box>
              )}

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {isOverBudget ? "Over budget" : "Remaining"}:{" "}
                <strong style={{ marginLeft: "5px" }}>{formatCurrency(Math.abs(budgetAmount - invoicesAmount))}</strong>
              </Box>
              {batch?.budget && (
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  Budget amount: <strong>{formatCurrency(budgetAmount)}</strong>
                </Box>
              )}
            </Typography>
          }
        >
          <Link
            onClick={!withLink ? (e) => e.preventDefault() : undefined}
            to={`/org/${organizationId}/vbillbatch/${batch?.id ?? 0}`}
          >
            <BatchProcessingIcon
              sx={{ color: isOverBudget ? opacRedColor : opacBlueColor, fontSize: "20px", marginTop: "22px" }}
            />
          </Link>
        </LightTooltip>
      )}

      <Box sx={{ flexGrow: 1 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            gap: "5px",
            ...(showTitle ? { justifyContent: "space-between" } : {}),
          }}
        >
          {showTitle ? (
            <LightTooltip
              {...tooltipDefaultProps}
              title={
                <Typography sx={tooltipTypographyStyles}>
                  <div>
                    Created by: <strong>@{batch?.createdByName}</strong>{" "}
                    {!isNil(batch?.createdAt) && (
                      <>
                        at <strong>{format(new Date(batch?.createdAt), "MM-dd-yy hh:mm")}</strong>
                      </>
                    )}
                  </div>
                  {(batch?.batchFilters?.date_due_from || batch?.batchFilters?.date_due_to) && (
                    <div>
                      Date:{" "}
                      {batch?.batchFilters?.date_due_from && (
                        <>
                          <strong>{getVBillFormattedDate(batch?.batchFilters.date_due_from)}</strong>
                        </>
                      )}
                      {batch?.batchFilters?.date_due_to && (
                        <>
                          {" "}
                          to: <strong>{getVBillFormattedDate(batch?.batchFilters.date_due_to)}</strong>
                        </>
                      )}
                    </div>
                  )}
                  <div>
                    {totalBatchCompanies === 0 && "All companies"}
                    {totalBatchCompanies === 1 && "Company"}
                    {totalBatchCompanies > 1 && "Companies"}
                    {totalBatchCompanies > 0 && (
                      <>
                        : <strong>{companyNames}</strong>{" "}
                      </>
                    )}
                  </div>
                </Typography>
              }
            >
              <Link
                to={`/org/${organizationId}/vbillbatch/${batch?.id ?? 0}`}
                onClick={() => onLinkClick?.()}
                style={{ textDecoration: "none" }}
              >
                <Typography sx={{ marginBottom: "5px", lineHeight: "13px", display: "inline-block" }}>
                  {batch?.name}
                </Typography>
              </Link>
            </LightTooltip>
          ) : (
            <div />
          )}

          <Box sx={{ display: "flex", alignItems: "center" }}>
            {showReactions && (
              <Reactions
                reactionsStats={reactionsStats}
                onReactionClick={() => (onReactionClick ? onReactionClick : undefined)}
                parentKey={`${batch?.id ?? ""}`}
                parentType={IVBillReactionParentType.VbillBatch}
              />
            )}

            {showMenu && (
              <BatchLineMenu
                batch={batch}
                onAddEditBatchSubmit={onLineMenuAddEditBatchSubmit}
                onFiltersChange={onLineMenuFiltersChange} // TODO:
                onConfirmDeleteBatchSubmit={onLineMenuConfirmDeleteBatchSubmit}
                onMembersChange={onLineMenuMembersChange}
                onRemoveBillsSubmit={onLineMenuRemoveBillsSubmit}
                showFiltersOption
                menuIconSx={{ fontSize: "16px" }}
              />
            )}
          </Box>
        </Box>

        <Box
          sx={{
            position: "relative",
            height: "20px",
            width: "100%",
            backgroundColor: isOverBudget ? opacRedColor : opacGrayColor,
            textAlign: "right",
          }}
        >
          <LightTooltip
            {...tooltipDefaultProps}
            title={
              <div>
                <div>
                  Budget amount: <strong>{formatCurrency(budgetAmount)}</strong>
                </div>
                <div>
                  {isOverBudget ? "Over" : "Left"} budget:{" "}
                  <strong>{formatCurrency(Math.abs(budgetAmount - invoicesAmount))}</strong>
                </div>
              </div>
            }
          >
            <Typography
              sx={{
                fontSize: "12px",
                lineHeight: "20px",
                display: "inline-block",
                paddingRight: "5px",
                position: "relative",
                zIndex: 2,
                whiteSpace: "nowrap",
              }}
            >
              {isOverBudget
                ? `${fns(invoicesAmount - budgetAmount)} over`
                : `${fns(budgetAmount - invoicesAmount)} left`}
            </Typography>
          </LightTooltip>

          <Box
            sx={{
              position: "absolute",
              top: "0",
              bottom: "0",
              left: 0,
              width: `${(isOverBudget
                ? (budgetAmount * 100) / invoicesAmount
                : (invoicesAmount * 100) / budgetAmount
              ).toFixed(2)}%`,
              zIndex: 1,
              backgroundColor: isOverBudget ? opacBlueColor : opacBlueColor,
              textAlign: "left",
            }}
          >
            <LightTooltip
              {...tooltipDefaultProps}
              title={
                <>
                  Invoices amount: <strong>{formatCurrency(invoicesAmount)}</strong>
                </>
              }
            >
              <Typography
                sx={{
                  fontSize: "12px",
                  lineHeight: "20px",
                  display: "inline-block",
                  padding: "0 5px",
                  whiteSpace: "nowrap",
                }}
              >
                {fns(invoicesAmount)} spent
              </Typography>
            </LightTooltip>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
