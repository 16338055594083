import { Box, Chip, Skeleton } from "@mui/material";
import Typography from "@mui/material/Typography";
import { formatCurrency } from "common/helpers/utils";
import { useCheckFilters } from "common/hooks/useCheckFilters";
import { CheckQuickFiltersEnumType } from "generated/sdk";
import { ActionRequired } from "icons/svg/ActionRequired";
import { AllIcon } from "icons/svg/AllIcon";
import { Completed } from "icons/svg/Completed";
import { Processing } from "icons/svg/Processing";
import { ReadyToDeposit } from "icons/svg/ReadyToDeposit";
import { observer } from "mobx-react-lite";
import { FunctionComponent, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { IQuickFiltersProps } from "./types";
import { useStore } from "storeContainer";

export const UnvalidatedQuickFilters: FunctionComponent<IQuickFiltersProps> = observer(
  function UnvalidatedQuickFilters({
    processingSum,
    processingCount,
    readyToDepositSum,
    readyToDepositCount,
    actionsRequiredCount,
    actionsRequiredSum,
    isLoading,
  }) {
    const vChecksStore = useStore("VChecksStore");

    const { selectedQuickFilter } = useParams<{ selectedQuickFilter?: string }>();
    const history = useHistory();
    const [disabled, setDisabled] = useState(vChecksStore.checksList?.isLoading);

    useEffect(() => {
      setDisabled(vChecksStore.checksList.isLoading);
    }, [vChecksStore.checksList.isLoaded, vChecksStore.checksList.isLoading]);

    return (
      <Box display="flex" gap="10px" position="sticky" top="0" paddingY="10px" sx={{ background: "white" }} zIndex="3">
        <Chip
          label={<Typography>All</Typography>}
          component="a"
          href="/depositAccounts/ledger/all"
          onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
            event.preventDefault();
            // resetFilters();
            history.push("/depositAccounts/ledger/all");
          }}
          icon={<AllIcon fill="#B5ECA6" stroke="#6FD055" />}
          variant={selectedQuickFilter === "all" ? "awesome" : "awesome-filled"}
          sx={{ width: "120px" }}
          disabled={disabled}
          clickable
        />

        <Chip
          label={
            <>
              <Typography> Ready To Deposit {isLoading ? "" : `(${readyToDepositCount})`}</Typography>
              <Typography>
                {isLoading ? <Skeleton variant="text" /> : formatCurrency(readyToDepositSum ?? 0)}
              </Typography>
            </>
          }
          component="a"
          href={`/depositAccounts/ledger/${CheckQuickFiltersEnumType.ReadyToDeposit}`}
          onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
            event.preventDefault();
            // resetFilters();
            history.push(`/depositAccounts/ledger/${CheckQuickFiltersEnumType.ReadyToDeposit}`);
          }}
          icon={
            <ReadyToDeposit
              fill={(readyToDepositCount as number) > 0 ? "#FFF3E1" : "#B5ECA6"}
              stroke={(readyToDepositCount as number) > 0 ? "#ECBB71" : "#6FD055"}
            />
          }
          sx={{ flex: 1, maxWidth: "100vw" }}
          variant={selectedQuickFilter === CheckQuickFiltersEnumType.ReadyToDeposit ? "awesome" : "awesome-filled"}
          disabled={disabled}
          clickable
        />

        <Chip
          label={
            <>
              <Typography>Processing {isLoading ? "" : `(${processingCount})`}</Typography>
              <Typography>{isLoading ? <Skeleton variant="text" /> : formatCurrency(processingSum)}</Typography>
            </>
          }
          component="a"
          href={`/depositAccounts/ledger/${CheckQuickFiltersEnumType.Processing}`}
          onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
            event.preventDefault();
            // resetFilters();
            history.push(`/depositAccounts/ledger/${CheckQuickFiltersEnumType.Processing}`);
          }}
          icon={<Processing fill="#B5ECA6" stroke="#6FD055" />}
          sx={{ flex: 1, maxWidth: "100vw" }}
          variant={selectedQuickFilter === CheckQuickFiltersEnumType.Processing ? "awesome" : "awesome-filled"}
          disabled={disabled}
          clickable
        />

        <Chip
          label={
            <>
              <Typography>Actions required {isLoading ? "" : `(${actionsRequiredCount})`}</Typography>
              <Typography>
                {isLoading ? <Skeleton variant="text" /> : formatCurrency(actionsRequiredSum ?? 0)}
              </Typography>
            </>
          }
          component="a"
          href={`/depositAccounts/ledger/${CheckQuickFiltersEnumType.ActionRequired}`}
          onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
            event.preventDefault();
            // resetFilters();
            history.push(`/depositAccounts/ledger/${CheckQuickFiltersEnumType.ActionRequired}`);
          }}
          icon={
            <ActionRequired
              fill={(actionsRequiredCount as number) > 0 ? "#FFF3E1" : "#B5ECA6"}
              stroke={(actionsRequiredCount as number) > 0 ? "#ECBB71" : "#6FD055"}
            />
          }
          sx={{ flex: 1, maxWidth: "100vw" }}
          variant={selectedQuickFilter === CheckQuickFiltersEnumType.ActionRequired ? "awesome" : "awesome-filled"}
          disabled={disabled}
          clickable
        />

        <Chip
          label={
            <>
              <Typography>Completed</Typography>
            </>
          }
          component="a"
          href={`/depositAccounts/ledger/${CheckQuickFiltersEnumType.Completed}`}
          onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
            event.preventDefault();
            // resetFilters();
            history.push(`/depositAccounts/ledger/${CheckQuickFiltersEnumType.Completed}`);
          }}
          icon={
            <Completed
              fill={(processingCount as number) > 0 ? "#FFF3E1" : "#B5ECA6"}
              stroke={(processingCount as number) > 0 ? "#ECBB71" : "#6FD055"}
            />
          }
          sx={{ flex: 1, maxWidth: "100vw" }}
          variant={selectedQuickFilter === CheckQuickFiltersEnumType.Completed ? "awesome" : "awesome-filled"}
          disabled={disabled}
          clickable
        />
      </Box>
    );
  },
);
