import { Box, SxProps, Theme, Typography } from "@mui/material";
import { format } from "date-fns";
import { IActionlogActionlogFeOutput } from "generated/graphql.actionlog";
import { FunctionComponent, useMemo } from "react";
import { COLORS } from "themes/default";
import { ActionTitle } from "../ActionTitle/ActionTitle";

interface IActionHeaderProps {
  actionLog: IActionlogActionlogFeOutput;
  sx?: SxProps<Theme>;
}

export const ActionHeader: FunctionComponent<IActionHeaderProps> = ({
  actionLog,
  actionLog: { actionTimestamp },
  sx,
}) => {
  const formattedDate = useMemo(() => format(new Date(actionTimestamp), "MM/dd/yy hh:mmaaa"), [actionTimestamp]);

  return (
    <Box sx={sx}>
      <Box sx={{ display: "flex", flexWrap: "wrap", flexDirection: "column", gap: 0.5 }}>
        <ActionTitle actionLog={actionLog} />
      </Box>
      <Typography sx={{ color: COLORS.textGray, fontSize: 11 }}>{formattedDate}</Typography>
    </Box>
  );
};
