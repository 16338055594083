import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Spinner } from "components/common/Spinner/Spinner";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { CheckBatchStateEnum, CheckBatchType, GetCheckBatchesQueryQuery } from "generated/sdk";
import { observer } from "mobx-react-lite";

import { formatCurrency } from "common/helpers/utils";
import styles from "./BudgetManagement.module.scss";
import { headerCells } from "./utils";
import { definitelyFilter } from "generated/utils";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router";
import { useStore } from "storeContainer";
import { formatDate } from "../DepositAccounts/DepositAccountsLedger/utils";

const BudgetManagementTable = ({
  batches,
  isLoading = false,
  rowsPerPage,
  handleEditBatch,
  handleConfirmOpenDeleteBatchModal,
}: {
  batches: GetCheckBatchesQueryQuery["GetCheckBatchesQuery"];
  isLoading: boolean;
  rowsPerPage: number;
  handleEditBatch: (batch: CheckBatchType) => void;
  handleConfirmOpenDeleteBatchModal: (batchId: string) => void;
}) => {
  const { organizationId } = useParams<{ organizationId?: string }>();
  const history = useHistory();
  const BudgetManagementStore = useStore("BudgetManagementStore");
  const { fetchCheckBatch } = BudgetManagementStore;

  return (
    <TableContainer sx={{ paddingBottom: rowsPerPage > 10 ? "100px" : "none" }}>
      <Table>
        <TableHead>
          <TableRow>
            {headerCells.map((headerCell) => (
              <TableCell key={headerCell.id} variant="head">
                {headerCell.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {isLoading ? (
          <TableBody>
            <TableRow sx={{ position: "relative", height: "100px" }}>
              <TableCell sx={{ position: "absolute", left: "50%", top: "50%", border: "none" }} align="center">
                <Spinner />
              </TableCell>
            </TableRow>
          </TableBody>
        ) : batches?.rows?.length === 0 ? (
          <TableBody>
            <TableRow sx={{ position: "relative", height: "100px" }}>
              <TableCell sx={{ position: "absolute", top: "10%", border: "none" }} align="center">
                <Typography>No results found</Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        ) : (
          <TableBody>
            {definitelyFilter(batches?.rows).map((batch: CheckBatchType) => {
              return (
                <TableRow
                  hover
                  key={batch.id}
                  sx={{
                    backgroundColor: "white",
                    "&.Mui-selected:hover": {
                      backgroundColor: "#EBF4FF",
                    },
                    "&.Mui-selected": {
                      backgroundColor: "#EBF4FF",
                    },
                    "&.MuiTableRow-hover:hover": {
                      backgroundColor: "#EBF4FF",
                    },
                    cursor: "pointer",
                  }}
                  onClick={async () => {
                    await fetchCheckBatch(batch?.id!, undefined, { page: 1, per_page: 10 }, true);
                    history.push(`/org/${organizationId}/bchecks/${batch.id}`);
                  }}
                >
                  <TableCell>
                    <Typography>{batch.name}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{batch.company?.legal_name}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{formatCurrency(batch.budget || 0)}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {batch.items_count
                        ? `${batch.items_count}/${formatCurrency(batch.items_amount || 0)}`
                        : "No Checks"}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{batch.members_count || "No Members"}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography className={batch.state === CheckBatchStateEnum.Open ? styles.open : styles.close}>
                      {batch.state}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{batch.created_by_organization_user?.account?.name}</Typography>
                    <Typography>{formatDate(new Date(batch.created_at as string))}</Typography>
                  </TableCell>
                  <TableCell sx={{ display: "flex" }}>
                    <Tooltip title={batch.state === CheckBatchStateEnum.Closed && "You can't edit a CLOSED batch"}>
                      <Box>
                        <IconButton
                          disabled={batch.state === CheckBatchStateEnum.Closed}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEditBatch(batch!);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                      </Box>
                    </Tooltip>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        handleConfirmOpenDeleteBatchModal(batch?.id!);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
};

export default observer(BudgetManagementTable);
