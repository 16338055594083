import { AlertColor } from "@mui/material";
import { observer } from "mobx-react-lite";

import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import Paper from "@mui/material/Paper";
import Snackbar from "@mui/material/Snackbar";
import Typography from "@mui/material/Typography";

import AddIcon from "@mui/icons-material/Add";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import CloseIcon from "@mui/icons-material/Close";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import { LoadingButton } from "@mui/lab";
import Card from "@mui/material/Card";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { UserGroup } from "../../../../../../generated/sdk";
import { useStore } from "../../../../../../storeContainer";
import { COLORS } from "../../../../../../themes/default";
import { Permissions2UtilEntity } from "./Permisssion2Utils";

const drawerWidth = 460;

export const AddUserToGroupView: React.FunctionComponent<{
  user: any | undefined;
  onClose: () => void;
  addUserToGroup: (group: any) => void;
  removeUserFromGroup: (group: any) => void;
  groups: any[];
  error: string | undefined;
}> = observer(function AddUserToGroupView({ onClose, addUserToGroup, removeUserFromGroup, groups, error }) {
  const aclStore2 = useStore("AclStore2");

  const [searchKeywords, setSearchKeywords] = useState("");
  const [loadingGroup, setLoadingGroup] = useState<any | undefined>(undefined);
  const { organizationGroupList } = aclStore2;

  useEffect(() => {
    setLoadingGroup(undefined);
  }, [groups, error]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          textAlign: "center",
          gap: 2,
        }}
      >
        <IconButton
          size={"small"}
          color={"primary"}
          sx={{
            border: 1,
            borderColor: COLORS.logoBlue,
          }}
          onClick={onClose}
        >
          <ArrowBackIosRoundedIcon />
        </IconButton>
        <Typography variant={"h6"} sx={{ my: 1, fontSize: "18px", fontWeight: 500 }}>
          Add to user group
        </Typography>
      </Box>

      <Card
        variant={"outlined"}
        component="form"
        sx={{ my: 3, p: 1, display: "flex", alignItems: "center", width: 260 }}
      >
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Search"
          value={searchKeywords}
          onChange={(event) => {
            setSearchKeywords(event.target.value);
          }}
        />
      </Card>

      <Divider sx={{ borderColor: COLORS.borderColor }} />

      {organizationGroupList
        .filter((group) => {
          if (searchKeywords.length === 0) return true;
          if (group.name) {
            return group.name?.search(new RegExp(searchKeywords, "i")) > -1;
          }

          return false;
        })
        .map((group, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{ p: 1, display: "flex", justifyContent: "space-between", alignItems: "center", borderRadius: "5px" }}
            >
              <Typography variant={"body1"} sx={{ fontWeight: 500, color: COLORS.logoBlue }}>
                {group.name}
              </Typography>
              {groups.find((userGroup) => userGroup.id === group.id) !== undefined ? (
                <LoadingButton
                  loading={loadingGroup?.id === group.id}
                  onClick={() => {
                    setLoadingGroup(group);
                    removeUserFromGroup(group);
                  }}
                  sx={{ textTransform: "none", fontWeight: 400 }}
                  variant={"text"}
                  size={"small"}
                  color={"warning"}
                  disabled={loadingGroup !== undefined}
                >
                  Remove
                </LoadingButton>
              ) : (
                <LoadingButton
                  loading={loadingGroup?.id === group.id}
                  onClick={() => {
                    setLoadingGroup(group);
                    addUserToGroup(group);
                  }}
                  sx={{ textTransform: "none", fontWeight: 400 }}
                  variant={"outlined"}
                  size={"small"}
                  color={"secondary"}
                  disabled={loadingGroup !== undefined}
                >
                  Add
                </LoadingButton>
              )}
            </Box>
            <Divider sx={{ borderColor: COLORS.borderColor }} />
          </Box>
        ))}
    </>
  );
});

export const SummaryView: React.FunctionComponent<{
  user: any | undefined;
  onAddRoleClicked: () => void;
  removeUserFromGroup: (group: any) => void;
  groups: any[];
  error: string | undefined;
}> = observer(function SummaryView({ user, onAddRoleClicked, removeUserFromGroup, groups, error }) {
  const [loadingGroup, setLoadingGroup] = useState<any | undefined>(undefined);
  const { selectedOrganizationId } = useStore("ContextStore");

  const aclStore2 = useStore("AclStore2");
  const directPermissionsList = user?.id
    ? aclStore2.directPermissionsList
        .filter((dp) => dp.isUser && dp.user_id === user?.id)
        .sort((a, b) => {
          const typeOrder = {
            ORGANIZATION: 0,
            COMPANY: 1,
            BANK_ACCOUNT: 2,
          };

          const typeA = a.entity_type;
          const typeB = b.entity_type;

          if (typeOrder[typeA] < typeOrder[typeB]) {
            return -1;
          } else if (typeOrder[typeA] > typeOrder[typeB]) {
            return 1;
          } else {
            if (typeA === "BANK_ACCOUNT") {
              const nameA = a.entity_name;
              const nameB = b.entity_name;
              const companyNameA = nameA.split(" *")[0];
              const companyNameB = nameB.split(" *")[0];
              if (companyNameA < companyNameB) {
                return -1;
              } else if (companyNameA > companyNameB) {
                return 1;
              }
            }

            return 0;
          }
        })
    : [];
  useEffect(() => {
    setLoadingGroup(undefined);
  }, [groups, error]);

  return (
    <>
      <>
        <Typography variant={"body1"} sx={{ fontWeight: 500 }} color={"primary"}>
          User:
        </Typography>
        <Typography variant={"h6"} sx={{ my: 1, fontSize: "20px", fontWeight: 600 }}>
          {user?.name}
        </Typography>
        <Typography variant={"body2"}>{user?.email}</Typography>

        <Link
          to={`/org/${selectedOrganizationId}/users2/permissions/user/${user?.id}`}
          style={{ textDecoration: "none" }}
        >
          <Button sx={{ mt: 3, textTransform: "none" }} variant={"outlined"} color={"secondary"}>
            View user permissions
          </Button>
        </Link>
      </>
      {/* Pemrmissions */}
      <>
        <Typography sx={{ mt: 3, mb: 1 }} color={"primary"} variant={"body2"}>
          Permissions:
        </Typography>
        {!directPermissionsList.length ? (
          <Typography sx={{ mt: 3, mb: 1, pl: 1 }} color={"primary"} variant={"body2"}>
            no permissions
          </Typography>
        ) : null}
        {directPermissionsList.map((dp, index) => (
          <Box
            key={index}
            sx={{
              p: 1,
              mb: 1,
              backgroundColor: COLORS.selago,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderRadius: "5px",
            }}
          >
            <Typography
              variant={"body1"}
              sx={{ fontWeight: 500, maxWidth: "80%", wordBreak: "break-all" }}
              color={"primary"}
            >
              <Permissions2UtilEntity entity_name={dp.entity_name} entity_type={dp.entity_type} />
            </Typography>
            <Link
              to={
                `/org/${selectedOrganizationId}/users2/permissions/` +
                `${true ? "user" : "group"}/${user?.id}/` +
                `${dp.entity_type.toLocaleLowerCase()}/${dp.entity_id}`
              }
              style={{ textDecoration: "none" }}
            >
              <LoadingButton
                sx={{ textTransform: "none", fontWeight: 400 }}
                variant={"text"}
                size={"small"}
                color={"warning"}
              >
                Edit
              </LoadingButton>
            </Link>
          </Box>
        ))}
        <Link
          to={`/org/${selectedOrganizationId}/users2/permissions/add/${true ? "user" : "group"}/${user?.id}`}
          style={{ textDecoration: "none" }}
        >
          <Button sx={{ textTransform: "none", mt: 1 }} color={"secondary"} variant={"text"} startIcon={<AddIcon />}>
            Add Permission
          </Button>
        </Link>
        {/* <pre>{JSON.stringify(toJS(directPermissionsList), null, 2)}</pre> */}
      </>
      {/* Roles */}
      <>
        <Typography sx={{ mt: 3, mb: 1 }} color={"primary"} variant={"body2"}>
          User Groups:
        </Typography>

        {!groups.length ? (
          <Typography sx={{ mt: 3, mb: 1, pl: 1 }} color={"primary"} variant={"body2"}>
            no user groups
          </Typography>
        ) : null}
        {groups.map((userGroup, index) => (
          <Box
            key={index}
            sx={{
              p: 1,
              mb: 1,
              backgroundColor: COLORS.selago,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderRadius: "5px",
            }}
          >
            <Typography variant={"body1"} sx={{ fontWeight: 500 }} color={"primary"}>
              {userGroup.name}
            </Typography>
            <LoadingButton
              loading={loadingGroup?.id === userGroup.id}
              onClick={() => {
                setLoadingGroup(userGroup);
                removeUserFromGroup(userGroup);
              }}
              sx={{ textTransform: "none", fontWeight: 400 }}
              variant={"text"}
              size={"small"}
              color={"warning"}
              disabled={loadingGroup !== undefined}
            >
              Remove
            </LoadingButton>
          </Box>
        ))}
        <Button
          sx={{ textTransform: "none", mt: 1 }}
          color={"secondary"}
          onClick={onAddRoleClicked}
          variant={"text"}
          startIcon={<AddIcon />}
        >
          Add User Group
        </Button>
      </>
    </>
  );
});

export const UserDetailsModal: React.FunctionComponent<{ onClose: () => void; user: any | undefined }> = observer(
  ({ onClose, user }) => {
    const userGroupsStore = useStore("UserGroupsStore");

    const [showAddUserToGroup, setShowAddUserToGroup] = useState(false);

    const [groups, setGroups] = useState<UserGroup[]>([]);

    const [error, setError] = useState<string | undefined>(undefined);

    //Snackbar
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarText, setSnackbarText] = useState("Test");
    const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor | undefined>("success");

    const toggleAddRole = () => {
      setShowAddUserToGroup(!showAddUserToGroup);
    };

    const onAddUserToGroup = async (group: UserGroup) => {
      try {
        await userGroupsStore.addUserToGroup(group.id || "", user?.id || "");
        setGroups((oldGroups) => [...oldGroups, group]);
        setOpenSnackbar(true);
        setSnackbarSeverity("success");
        setSnackbarText("User Group added successfully");
      } catch (e: any) {
        setError(e.response.errors?.message);
        setSnackbarText(e.response.errors?.message);
        setSnackbarSeverity("error");
        setOpenSnackbar(true);
      }
    };

    const onRemoveUserFromGroup = async (group: UserGroup) => {
      try {
        await userGroupsStore.removeUserFromGroup(group.id || "", user?.id || "");
        setGroups(groups.filter((_group) => _group.id !== group.id));
        setOpenSnackbar(true);
        setSnackbarSeverity("success");
        setSnackbarText("User group removed successfully");
      } catch (e: any) {
        setError(e.response.errors?.message);
        setSnackbarText(e.response.errors?.message);
        setSnackbarSeverity("error");
        setOpenSnackbar(true);
      }
    };

    const onSave = async () => {
      onClose();
    };

    useEffect(() => {
      setShowAddUserToGroup(false);
      setGroups(user?.user_groups || []);
    }, [user]);

    return (
      <Drawer
        anchor={"right"}
        open={user !== undefined}
        onClose={onClose}
        sx={{
          display: { xs: "none", sm: "block" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between", flexDirection: "column", height: "100%" }}>
          <Box sx={{ p: 2, flexDirection: "column" }}>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Box>
            {showAddUserToGroup ? (
              <AddUserToGroupView
                onClose={toggleAddRole}
                addUserToGroup={onAddUserToGroup}
                removeUserFromGroup={onRemoveUserFromGroup}
                user={user}
                groups={groups}
                error={error}
              />
            ) : (
              <SummaryView
                onAddRoleClicked={toggleAddRole}
                removeUserFromGroup={onRemoveUserFromGroup}
                user={user}
                groups={groups}
                error={error}
              />
            )}
          </Box>
          <Paper sx={{ display: "flex", justifyContent: "flex-end", gap: 2, p: 2, backgroundColor: COLORS.selago }}>
            <Button onClick={onClose} variant={"outlined"}>
              Cancel
            </Button>
            <Button onClick={onSave} variant={"contained"} color={"secondary"} endIcon={<NavigateNextIcon />}>
              Save
            </Button>
          </Paper>

          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={openSnackbar}
            autoHideDuration={3000}
            onClose={() => setOpenSnackbar(false)}
          >
            <Alert onClose={() => setOpenSnackbar(false)} severity={snackbarSeverity} sx={{ width: "100%" }}>
              {snackbarText}
            </Alert>
          </Snackbar>
        </Box>
      </Drawer>
    );
  },
);
