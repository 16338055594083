import { IVBillIPaymentFiltersSortValues, IVBillPaymentListFiltersInput } from "generated/sdk.vbill";
import { useMemo } from "react";
import { usePaymentsLedgerFilters, useVBillPaymentsLedgerChildRoute } from "../hooks";

interface ITableHeadCell {
  id: keyof IVBillPaymentListFiltersInput | string;
  sortable?: boolean;
  label: string;
  value?: IVBillIPaymentFiltersSortValues | null;
  onChange?: (value: IVBillIPaymentFiltersSortValues) => void;
}

export function useTableHeadCells() {
  const [paymenstLedgerFilters, setPayLedgerFilters] = usePaymentsLedgerFilters();
  const { isAllChildRoute, isProcessingChildRoute, isCompletedChildRoute, isVoidedChildRoute } =
    useVBillPaymentsLedgerChildRoute();

  const tableHeadCells: ITableHeadCell[] = useMemo(
    () => [
      {
        id: "payment_id_sort",
        sortable: true,
        label: "Payment #",
        value: paymenstLedgerFilters.filters?.payment_id_sort,
        onChange: (value) =>
          setPayLedgerFilters((prev) => ({
            filters: {
              ...prev.filters,
              payment_date_sort: undefined,
              payment_amount_sort: undefined,
              payment_status_sort: undefined,
              payment_id_sort: value,
            },
          })),
      },
      // {
      //   id: "vCheck",
      //   sortable: false,
      //   label: "vCheck",
      // },
      ...(isAllChildRoute || isProcessingChildRoute || isCompletedChildRoute || isVoidedChildRoute
        ? [
            {
              id: "payment_status",
              label: "Status",
            },
          ]
        : []),
      {
        id: "company",
        sortable: false,
        label: "Company",
      },
      {
        id: "payment_amount_sort",
        sortable: true,
        label: "Amount",
        value: paymenstLedgerFilters.filters?.payment_amount_sort,
        onChange: (value) =>
          setPayLedgerFilters((prev) => ({
            filters: {
              ...prev.filters,
              payment_date_sort: undefined,
              payment_status_sort: undefined,
              payment_id_sort: undefined,
              payment_amount_sort: value,
            },
          })),
      },
      {
        id: "payment_date_sort",
        sortable: true,
        label: "Date",
        value: paymenstLedgerFilters.filters?.payment_date_sort,
        onChange: (value) =>
          setPayLedgerFilters((prev) => ({
            filters: {
              ...prev.filters,
              payment_status_sort: undefined,
              payment_id_sort: undefined,
              payment_amount_sort: undefined,
              payment_date_sort: value,
            },
          })),
      },
      {
        id: "vendor",
        sortable: false,
        label: "Vendor",
      },
      {
        id: "gl_account",
        sortable: false,
        label: "GL Account",
      },
      // {
      //   id: "actions",
      //   label: "Actions",
      // },
      // {
      //   id: "date_due_sort",
      //   sortable: true,
      //   label: "Due Date",
      //   value: paymenstLedgerFilters.filters?.date_due_sort,
      //   onChange: (value) =>
      //     setPayLedgerFilters((prev) => ({
      //       filters: { ...prev.filters, vbill_number_sort: undefined, amount_sort: undefined, date_due_sort: value },
      //     })),
      // },
      // {
      //   id: "vendors",
      //   label: "Vendor",
      // },
      // {
      //   id: "pay_from",
      //   label: "Pay from",
      // },
      // {
      //   id: "properties",
      //   label: "Properties",
      // },
    ],
    [
      isAllChildRoute,
      isCompletedChildRoute,
      isProcessingChildRoute,
      paymenstLedgerFilters.filters?.payment_amount_sort,
      paymenstLedgerFilters.filters?.payment_date_sort,
      paymenstLedgerFilters.filters?.payment_id_sort,
      setPayLedgerFilters,
    ],
  );

  return tableHeadCells;
}
