import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import SearchIcon from "@mui/icons-material/Search";

import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";

import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import Typography from "@mui/material/Typography";

import { Spinner } from "components/common/Spinner/Spinner";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useStore } from "storeContainer";
import { StringParam, useQueryParam } from "use-query-params";
import { Pagination } from "../../../../generated/sdk";
import Table from "../../../common/Table";
import { AccessRestricted } from "../../AccessRestricted";
import { useContactsColumns } from "../Users/utils";

export const Vendors: React.FunctionComponent = observer(function Vendors() {
  const contextStore = useStore("ContextStore");
  const vendorsStore = useStore("VendorsStore");
  const { vendorsList, paginationResponse } = vendorsStore;

  const history = useHistory();

  const {
    acl: { aclDone, canListCompanyVendors },
  } = useStore("SessionStore");

  const handleClick = (vendorId: string) => {
    history.push(
      `/org/${contextStore.selectedOrganizationId}/company/${contextStore.selectedCompanyId}/vendors/${vendorId}`,
    );
  };

  const contactsColumns = useContactsColumns(handleClick);

  //Filtering
  const [contactSearchTerm, setContactSearchTerm] = useQueryParam("search", StringParam);

  //Pagination

  const initialPaginationState = {
    per_page: 10,
    page: 1,
  };

  const [pagination, setPagination] = useState<Pagination>(initialPaginationState);

  const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPagination({ ...pagination, page: newPage + 1 });
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPagination({ ...pagination, per_page: parseInt(event.target.value, 10), page: 1 });
  };

  const loadContacts = (pagination: Pagination) => {
    vendorsStore.loadVendors(true, pagination, contactSearchTerm || "");
  };

  useEffect(() => {
    if (aclDone && canListCompanyVendors) {
      loadContacts(pagination);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination, aclDone, canListCompanyVendors]);

  if (!canListCompanyVendors) {
    return <AccessRestricted />;
  }

  if (vendorsList.isLoading || vendorsList.isFetching) {
    return <Spinner withSpaceAround />;
  }

  return (
    <Box sx={{ padding: "20px", width: "1052px" }}>
      <Typography variant="h5">Contacts</Typography>

      {vendorsList.data?.length ? (
        <>
          <Paper component="form" sx={{ p: 1, display: "flex", alignItems: "center", width: 260, mt: 2 }}>
            <SearchIcon />
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search contacts"
              value={contactSearchTerm}
              onChange={(event) => {
                setContactSearchTerm(event.target.value);
              }}
              onKeyPress={(ev) => {
                if (ev.key === "Enter") {
                  // Do code here
                  ev.preventDefault();
                  setPagination(initialPaginationState);
                }
              }}
            />
            <IconButton
              onClick={() => {
                setPagination(initialPaginationState);
              }}
            >
              <KeyboardReturnIcon />
            </IconButton>
          </Paper>
          <Paper sx={{ mt: 2, p: 0 }}>
            <Table pagination={false} columns={contactsColumns} data={vendorsList.data} />
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <TablePagination
                showFirstButton={true}
                showLastButton={true}
                count={paginationResponse?.data?.total || 0}
                page={(pagination?.page || 1) - 1}
                onRowsPerPageChange={handleChangeRowsPerPage}
                onPageChange={handleChangePage}
                rowsPerPage={pagination?.per_page || 10}
              />
            </Box>
          </Paper>
        </>
      ) : (
        <Typography variant="subtitle1">There are currently no contacts</Typography>
      )}
    </Box>
  );
});

export default Vendors;
