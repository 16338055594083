import { COLORS } from "themes/default";

export const tooltipTypographyStyles = { fontSize: "14px", padding: "5px" };
export const tooltipDefaultProps = {
  disableFocusListener: true,
  disableTouchListener: true,
  arrow: true,
};
const baseBtnStyles = { ":hover": { textDecoration: "underline" }, flexDirection: "column" };
export const blackBtnStyles = { ...baseBtnStyles, color: COLORS.newPrimaryColor };
export const blueBtnStyles = { ...baseBtnStyles };
export const typographyAsBtnStyles = { padding: "6px 8px", textAlign: "center" };
