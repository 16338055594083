import { Box, Button, Divider, Grid, Input, InputBase, Link, TextField, Tooltip } from "@mui/material";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import { useStore } from "storeContainer";
import { COLORS } from "themes/default";
import ReplayIcon from "@mui/icons-material/Replay";
import { LoadingButton } from "@mui/lab";
const EditOrganizationNameModal: React.FC<{ open: boolean; setOpen: Function }> = ({ open, setOpen }) => {
  const ctxStore = useStore("ContextStore");
  const orgStore = useStore("OrganizationStore");
  const [errorFeedback, setErrorFeedback] = useState("");
  const { selectedOrganization } = ctxStore;
  const { updateOrganizationName } = orgStore;
  const sesStore = useStore("SessionStore");
  const [inputOrgName, setInputOrgName] = useState(selectedOrganization?.name!);
  const [isLoading, setIsLoading] = useState(false);
  const checkDifference = () => {
    return selectedOrganization?.name !== inputOrgName && inputOrgName?.at(inputOrgName.length - 1) !== " ";
  };
  const handleResetName = () => {
    setInputOrgName(selectedOrganization?.name!);
  };
  const handleUpdateOrgName = async () => {
    setIsLoading(true);
    try {
      setErrorFeedback("");
      await updateOrganizationName(selectedOrganization?.id!, inputOrgName);
    } catch (e) {
      setErrorFeedback("You don't have the required permissions!");
    }
    await sesStore.loadSession();
    setIsLoading(false);
    setOpen(false);
  };
  return (
    <Modal
      style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      onClose={() => setOpen(false)}
      open={open}
    >
      <Paper sx={{ padding: "15px" }}>
        <Grid>
          <Typography variant="h1" color={COLORS.darkestBlue}>
            Edit organization name
          </Typography>
          <Grid marginTop={5} item>
            <Typography fontWeight={500} fontSize={14} marginBottom={1} color={COLORS.darkGrey}>
              Name
            </Typography>
          </Grid>
          <Grid item>
            <TextField
              inputProps={{ style: { fontWeight: 900, fontSize: 16, color: COLORS.darkestBlue, height: "15px" } }}
              value={inputOrgName}
              onChange={(e) => {
                setInputOrgName(e.target.value);
              }}
              fullWidth
            />
          </Grid>
          <Link
            sx={{
              margin: 0,
              textDecoration: checkDifference() ? "" : "none",
              ":hover": { cursor: checkDifference() ? "pointer" : "default" },
              background: "red",
            }}
            onClick={handleResetName}
          >
            <Grid marginTop={1} container item alignItems={"center"}>
              {selectedOrganization?.name !== inputOrgName ? (
                <ReplayIcon sx={{ transform: "scale(0.6)", color: COLORS.darkGrey }} />
              ) : null}
              <Typography
                sx={{
                  margin: 0,
                }}
                fontSize={14}
                marginBottom={1}
                color={COLORS.darkGrey}
              >
                {selectedOrganization?.name}
              </Typography>
            </Grid>
          </Link>
          <Divider sx={{ marginTop: 4, marginBottom: 2 }} />
          <Typography sx={{ color: "red" }} textAlign={"center"}>
            {errorFeedback}
          </Typography>
          <Grid justifyContent={"flex-end"} container item>
            <Button
              onClick={() => {
                setOpen(false);
                handleResetName();
              }}
              sx={{ borderRadius: 0, paddingX: 3, paddingY: 1, marginRight: "10px" }}
            >
              Cancel
            </Button>
            <Tooltip
              PopperProps={{ placement: "top", style: { textAlign: "center" } }}
              title={!checkDifference() ? "Organization name can't be the same or end in an empty space." : ""}
            >
              <Box>
                <LoadingButton
                  loading={isLoading}
                  disabled={!checkDifference()}
                  color="secondary"
                  sx={{ borderRadius: 0, paddingX: 3, paddingY: 1 }}
                  variant="contained"
                  onClick={handleUpdateOrgName}
                >
                  Save
                </LoadingButton>
              </Box>
            </Tooltip>
          </Grid>
        </Grid>
      </Paper>
    </Modal>
  );
};

export default EditOrganizationNameModal;
