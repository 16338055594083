import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import MultipleActionComponent from "components/common/VChecksCommonComponents/MultipleActionComponent";

import { observer } from "mobx-react-lite";
import { COLORS } from "themes/default";
import { formatCurrency } from "../../../../common/helpers/utils";
import styles from "./BulkActions.module.scss";
import { IBulkActionsProps } from "./types";

const BulkActions = ({
  selectedRowIds,
  setSelectedRowIds,
  checks,
  selectedRowIdsShouldIncludeRole = false,
  handleButtonAction,
}: IBulkActionsProps) => {
  const selectedChecksValue = checks
    ?.filter((check) =>
      selectedRowIds.includes(selectedRowIdsShouldIncludeRole ? `${check.id}-${check.role}` : check.id),
    )
    ?.map((check) => check.amount!)
    ?.reduce((previousValue, currentValue) => previousValue + currentValue, 0);

  return (
    <Paper
      sx={{
        padding: "20px",
        marginBottom: "20px",
        width: "100vw",
        height: "100px",
        position: "fixed",
        top: "calc(100vh - 154px)",
        left: "50%",
        right: "50%",
        marginLeft: "-50vw",
        marginRight: "-50vw",
        zIndex: 2,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      elevation={4}
      className="bulk-actions"
    >
      <Grid
        container
        display="flex"
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ paddingLeft: "65px", paddingRight: "85px", maxWidth: "1512px" }}
      >
        <Grid item display="flex">
          <Button
            variant={"text"}
            onClick={() => {
              if (setSelectedRowIds) {
                setSelectedRowIds([]);
              }
            }}
          >
            Deselect
          </Button>
        </Grid>
        <Grid item display="flex">
          <Grid item>
            <Typography className={styles.selected} sx={{ color: COLORS.newPrimaryColor }}>
              {`${selectedRowIds.length} vChecks selected`}
            </Typography>

            <Typography textAlign="right" sx={{ color: COLORS.newPrimaryColor }}>
              Total: {formatCurrency(selectedChecksValue!)}
            </Typography>
          </Grid>
          <MultipleActionComponent
            handleButtonAction={handleButtonAction}
            checks={checks}
            selectedRowIds={selectedRowIds}
            selectedRowIdsShouldIncludeRole={selectedRowIdsShouldIncludeRole}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default observer(BulkActions);
