import { Box, Divider, SxProps, Theme } from "@mui/material";
import { TBaseIoChannel } from "modules/Chat/stores/ChatChannelStore";
import { FunctionComponent } from "react";
import { ChatChannelStoreProvider } from "../ChatChannelStoreProvider/ChatChannelStoreProvider";
import { ChatForm } from "./ChatForm/ChatForm";
import { ChatMessageReply } from "./ChatMessageReply/ChatMessageReply";
import { ChatMessages } from "./ChatMessages/ChatMessages";
import { ChatToolbar } from "./ChatToolbar/ChatToolbar";

interface IChatViewChannelProps {
  baseIoChannel: TBaseIoChannel;
  sx?: SxProps<Theme>;
}

export const ChatViewChannel: FunctionComponent<IChatViewChannelProps> = ({ baseIoChannel, sx }) => {
  return (
    <ChatChannelStoreProvider baseIoChannel={baseIoChannel}>
      <Box sx={sx}>
        <ChatToolbar />

        <Divider />

        <ChatMessages />

        <ChatMessageReply />

        <Divider />

        <ChatForm />
      </Box>
    </ChatChannelStoreProvider>
  );
};
