import { graphqlVBillClient } from "common/graphqlClient";
import { getSdk, IVBillInvoiceRelationType, IVBillRelatedInvoiceSchema } from "generated/sdk.vbill";
import { ReactElement, useState } from "react";
import { IInvoiceProps } from "../../types";
import { DuplicatesModal } from "./DuplicatesModal";

const { VBillRelatedInvoices } = getSdk(graphqlVBillClient);

interface IDuplicatesLoaderProps extends IInvoiceProps {
  relationTypes: IVBillInvoiceRelationType[];
  children: (loading: boolean, onClick: () => void) => ReactElement;
  ModalActionsComponent?: React.ComponentType<
    IInvoiceProps & {
      onClose: () => void;
      reloadInvoicesList: () => void;
      onStartCodingBtnClick?: () => void;
    }
  >;
  reloadInvoicesList: () => void;
  onModalActionsStartCodingBtnClick?: () => void;
}

export const DuplicatesLoader: React.FunctionComponent<IDuplicatesLoaderProps> = ({
  invoice,
  relationTypes,
  children,
  ModalActionsComponent,
  reloadInvoicesList,
  onModalActionsStartCodingBtnClick,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [duplicates, setDuplicates] = useState<IVBillRelatedInvoiceSchema[]>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const fetch = async () => {
    if (!invoice) {
      return null;
    }

    setLoading(true);

    const duplicates = await VBillRelatedInvoices({
      invoiceId: invoice.id,
      filters: {
        type: relationTypes,
      },
    });

    setLoading(false);
    setDuplicates(duplicates.relatedInvoices as IVBillRelatedInvoiceSchema[]);
    setIsModalOpen(true);
  };

  return (
    <>
      {children(loading, fetch)}
      <DuplicatesModal
        invoiceId={invoice.id}
        isOpen={isModalOpen}
        duplicates={duplicates}
        onClose={() => setIsModalOpen(false)}
      >
        {ModalActionsComponent && (
          <ModalActionsComponent
            invoice={invoice}
            onClose={() => {
              setIsModalOpen(false);
            }}
            reloadInvoicesList={reloadInvoicesList}
            onStartCodingBtnClick={() => {
              if (onModalActionsStartCodingBtnClick) {
                onModalActionsStartCodingBtnClick();
              }
            }}
          />
        )}
      </DuplicatesModal>
    </>
  );
};
