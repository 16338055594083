import { IVBillBillsFiltersInput, IVBillInvoicePaginationInput } from "generated/sdk.vbill";
import { useParams, useRouteMatch } from "react-router";
import { VBillPayLedgerBasePath } from "routes/routes";
import { JsonParam, QueryParamConfig, useQueryParams, withDefault } from "use-query-params";
import { VBillPayLedgerChildRoutes } from "./utils";

export const payLedgerFiltersInitialPaginationParams: IVBillInvoicePaginationInput = { page: 1, per_page: 50 };
const initialQsPaginationParams = withDefault<IVBillInvoicePaginationInput, IVBillInvoicePaginationInput>(
  JsonParam,
  payLedgerFiltersInitialPaginationParams,
);

export function usePayLedgerFilters() {
  return useQueryParams<{
    pagination: QueryParamConfig<IVBillInvoicePaginationInput, IVBillInvoicePaginationInput>;
    filters: QueryParamConfig<IVBillBillsFiltersInput, IVBillBillsFiltersInput>;
  }>(
    {
      pagination: initialQsPaginationParams,
      filters: JsonParam,
    },
    { removeDefaultsFromUrl: true },
  );
}

export interface vBillPayLedgerChildRoute {
  isAllChildRoute: boolean;
  isDueToPayChildRoute: boolean;
  isOverDueChildRoute: boolean;
  isPendingChildRoute: boolean;
  isPaidChildRoute: boolean;
  // isVoidedChildRoute: boolean;
}

export function useVBillPayLedgerChildRoute(): vBillPayLedgerChildRoute {
  const isAllChildRoute = !!useRouteMatch({ path: VBillPayLedgerBasePath, exact: true });
  const isDueToPayChildRoute = !!useRouteMatch(`${VBillPayLedgerBasePath}/${VBillPayLedgerChildRoutes.DUE_TO_PAY}`);
  const isOverDueChildRoute = !!useRouteMatch(`${VBillPayLedgerBasePath}/${VBillPayLedgerChildRoutes.OVER_DUE}`);
  const isPendingChildRoute = !!useRouteMatch(`${VBillPayLedgerBasePath}/${VBillPayLedgerChildRoutes.PENDING}`);
  const isPaidChildRoute = !!useRouteMatch(`${VBillPayLedgerBasePath}/${VBillPayLedgerChildRoutes.PAID}`);
  // const isVoidedChildRoute = !!useRouteMatch(`${VBillPayLedgerBasePath}/${VBillPayLedgerChildRoutes.VOIDED}`);

  return {
    isAllChildRoute,
    isDueToPayChildRoute,
    isOverDueChildRoute,
    isPendingChildRoute,
    isPaidChildRoute,
    // isVoidedChildRoute,
  };
}

export function useVBillPayLedgerQuickFilterUrl(route: VBillPayLedgerChildRoutes) {
  const { organizationId } = useParams<{ organizationId: string }>();

  return `/org/${organizationId}/vbillpayledger${route === VBillPayLedgerChildRoutes.ALL ? "" : `/${route}`}`;
}
