import { Popover } from "@mui/material";
import { CHDecimal } from "common/helpers/decimal";
import { TVBillFormFields, TVBillFormLineFields } from "components/pages/VBill/types";
import { ENABLE_COMPUTED_LINE_ITEM_AMOUNTS, emptyOCRFormLineFields } from "components/pages/VBill/utils";
import { isValid } from "date-fns";
import { observer } from "mobx-react";
import { FunctionComponent, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useStore } from "storeContainer";
import { WidgetForm } from "./WidgetForm/WidgetForm";
import { WidgetInput } from "./WidgetInput/WidgetInput";
import styles from "./imageMenuWidget.module.scss";
import { cleanupStringToNumber } from "./utils";

interface IImageMenuWidgetProps {
  imageText: string;
}

export const ImageMenuWidget: FunctionComponent<IImageMenuWidgetProps> = observer(({ imageText }) => {
  const vBillStore = useStore("VBillStore");
  const [popoverAnchorEl, setPopoverAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [widgetInputText, setWidgetInputText] = useState(imageText);
  const [widgetInputError, setWidgetInputError] = useState<string>();
  const formMethods = useFormContext<TVBillFormFields>();

  const handleAddLineFieldBtnClick = (index: number, fieldName: keyof TVBillFormLineFields) => {
    const vBillFormLineItems = formMethods.getValues().lineItems;
    const vBillFormLineValues = vBillFormLineItems?.[index];

    if (!vBillFormLineItems?.length) {
      return;
    }

    // trust me, it's there
    const lineElementToScroll = document.getElementById(`vbill-line-item-${index}`) as HTMLDivElement;
    const scrollableLeftPannel = document.getElementById("vbill-details-scrollable-left-pannel") as HTMLDivElement;
    const halfWindowHeight = window.innerHeight / 2;

    if (fieldName === "amount" || fieldName === "qty" || fieldName === "rate") {
      const cleanAmount = cleanupStringToNumber(widgetInputText);

      if (ENABLE_COMPUTED_LINE_ITEM_AMOUNTS) {
        if (fieldName === "qty" && cleanAmount.length && cleanAmount !== "-") {
          const amountValue = new CHDecimal(cleanAmount).times(vBillFormLineValues.rate);
          const amountFixed = amountValue.decimalPlaces() === 0 ? amountValue.toString() : amountValue.toFixed(2);

          formMethods.setValue(`lineItems.${index}`, {
            ...vBillFormLineItems[index],
            amount: amountFixed,
            [fieldName]: cleanAmount,
          });
        }

        if (fieldName === "rate" && cleanAmount.length && cleanAmount !== "-") {
          const amountValue = new CHDecimal(cleanAmount).times(vBillFormLineValues.qty);
          const amountFixed = amountValue.decimalPlaces() === 0 ? amountValue.toString() : amountValue.toFixed(2);

          formMethods.setValue(`lineItems.${index}`, {
            ...vBillFormLineItems[index],
            amount: amountFixed,
            [fieldName]: cleanAmount,
          });
        }

        if (fieldName === "amount" && cleanAmount.length && cleanAmount !== "-") {
          const rateValue = new CHDecimal(cleanAmount).dividedBy(vBillFormLineValues.qty);
          const rateFixed = rateValue.decimalPlaces() === 0 ? rateValue.toString() : rateValue.toFixed(2);

          formMethods.setValue(`lineItems.${index}`, {
            ...vBillFormLineItems[index],
            rate: rateFixed,
            [fieldName]: cleanAmount,
          });
        }
      } else {
        formMethods.setValue(`lineItems.${index}`, {
          ...vBillFormLineItems[index],
          [fieldName]: cleanAmount,
        });
      }

      vBillStore.setIncomingLineItemHighlightedId(index);
      scrollableLeftPannel.scrollTo({
        behavior: "smooth",
        top: lineElementToScroll.getBoundingClientRect().top + scrollableLeftPannel.scrollTop - halfWindowHeight,
      });
      setPopoverAnchorEl(null);
    } else {
      setWidgetInputError(undefined);
      formMethods.setValue(`lineItems.${index}`, {
        ...vBillFormLineItems[index],
        [fieldName]: widgetInputText,
      });
      vBillStore.setIncomingLineItemHighlightedId(index);
      scrollableLeftPannel.scrollTo({
        behavior: "smooth",
        top: lineElementToScroll.getBoundingClientRect().top + scrollableLeftPannel.scrollTop - halfWindowHeight,
      });
      setPopoverAnchorEl(null);
    }
  };

  const handleAddNewLineBtnClick = () => {
    const formValues = {
      ...formMethods.getValues(),
      lineItems: [...formMethods.getValues().lineItems, emptyOCRFormLineFields],
    };

    formMethods.reset(formValues);
  };

  const handleAddInvoiceFieldBtnClick = (fieldName: keyof TVBillFormFields) => {
    if (fieldName === "invoiceDate" || fieldName === "invoiceDateDue") {
      const isNotValidDate = !isValid(new Date(widgetInputText));

      if (isNotValidDate) {
        setWidgetInputError("Invalid Date");
      } else {
        formMethods.setValue(fieldName, widgetInputText);
        setPopoverAnchorEl(null);
      }
    } else if (fieldName === "amount") {
      const cleanAmount = cleanupStringToNumber(widgetInputText);

      formMethods.setValue(fieldName, cleanAmount);
      setPopoverAnchorEl(null);
    } else {
      formMethods.setValue(fieldName, widgetInputText);
      setPopoverAnchorEl(null);
    }
  };

  const handleWidgetInputChange = (value: string) => {
    if (widgetInputError) {
      setWidgetInputError(undefined);
    }

    setWidgetInputText(value);
  };

  return (
    <>
      <button onClick={(event) => setPopoverAnchorEl(event.currentTarget)} className={styles.imageMenuBtn} />

      <Popover
        open={!!popoverAnchorEl}
        anchorEl={popoverAnchorEl}
        onClose={() => setPopoverAnchorEl(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <div className={styles.popoverInner}>
          <WidgetInput
            inputText={widgetInputText}
            onInputChange={handleWidgetInputChange}
            inputError={widgetInputError}
          />

          <WidgetForm
            onAddInvoiceFieldBtnClick={handleAddInvoiceFieldBtnClick}
            onAddLineFieldBtnClick={handleAddLineFieldBtnClick}
            onAddNewLineBtnClick={handleAddNewLineBtnClick}
          />
        </div>
      </Popover>
    </>
  );
});
