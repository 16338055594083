import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useHistory, useParams } from "react-router";
import { VBillRoutes } from "routes/routes";
import { useStore } from "storeContainer";

interface IVBillRouteGuardRedirectProps {
  route: VBillRoutes;
}

export const VBillRouteGuardRedirect = observer(({ route }: IVBillRouteGuardRedirectProps) => {
  const organizationStore = useStore("OrganizationStore");
  const { organizationId } = useParams<{ organizationId: string }>();
  const history = useHistory();
  const {
    organizationVBillEnabledCapabilities,
    organizationDefaultDashboard,
    organizationVBillCodingLedgerEnabled,
    organizationVBillPayLedgerEnabled,
    organizationVBillPaymentsLedgerEnabled,
    organizationVBillBatchLedgerEnabled,
  } = organizationStore;

  useEffect(() => {
    if (!organizationDefaultDashboard.data || !organizationVBillEnabledCapabilities.data) {
      return;
    }

    if (!organizationDefaultDashboard.data?.vbill_enabled) {
      history.push(`/org/${organizationId}/vbills-inactive`);

      return;
    }

    if ((route === "CODING_DETAILS" || route === "CODING_LEDGER") && !organizationVBillCodingLedgerEnabled) {
      history.push(`/org/${organizationId}/vbills-inactive`);

      return;
    }

    if (route === "PAY_LEDGER" && !organizationVBillPayLedgerEnabled) {
      history.push(`/org/${organizationId}/vbills-inactive`);

      return;
    }

    if (route === "PAYMENTS_LEDGER" && !organizationVBillPaymentsLedgerEnabled) {
      history.push(`/org/${organizationId}/vbills-inactive`);

      return;
    }

    if ((route === "BATCH_DETAILS" || route === "BATCH_LEDGER") && !organizationVBillBatchLedgerEnabled) {
      history.push(`/org/${organizationId}/vbills-inactive`);

      return;
    }
  }, [
    history,
    organizationDefaultDashboard.data,
    organizationId,
    organizationVBillBatchLedgerEnabled,
    organizationVBillCodingLedgerEnabled,
    organizationVBillEnabledCapabilities.data,
    organizationVBillPayLedgerEnabled,
    organizationVBillPaymentsLedgerEnabled,
    route,
  ]);

  return null;
});
