import { Box, Typography } from "@mui/material";
import { stringSimilarityCompareTwoStrings } from "common/helpers/string-similarity.lib";
import { findAll } from "highlight-words-core";

import { Fragment, useMemo } from "react";
import { COLORS } from "themes/default";

interface IVBillVendorDifferenceLineProps {
  rowName: string;
  integrationText: string;
  codingText: string;
}

export const VBillVendorDifferenceLine = ({
  integrationText,
  codingText,
  rowName,
}: IVBillVendorDifferenceLineProps) => {
  const compareResult = useMemo(
    () => stringSimilarityCompareTwoStrings(integrationText, codingText),
    [integrationText, codingText],
  );
  const codingTextHighlighted = useMemo(
    () => highlightMatchingText(integrationText.split(" "), codingText),
    [compareResult, integrationText, codingText],
  );

  return (
    <>
      <tr>
        <td>
          <Typography sx={{ color: COLORS.textGray }}>{rowName}</Typography>
        </td>
        <td />
      </tr>
      <tr>
        <td>
          <Typography sx={{ marginRight: "10px" }}>{integrationText}</Typography>
        </td>
        <td>
          <Typography>
            {"->"}{" "}
            <Box component="span" sx={{ ...(compareResult < 0.9 ? { textDecoration: "line-through" } : {}) }}>
              {" "}
              {codingTextHighlighted}
            </Box>
          </Typography>
        </td>
      </tr>
    </>
  );
};

const highlightMatchingText = (searchWords: string[], textToHighlight: string) => {
  const chunks = findAll({
    caseSensitive: false,
    searchWords,
    textToHighlight,
  });

  return chunks.map((chunk) => {
    const { end, highlight, start } = chunk;
    const text = textToHighlight.slice(start, end);

    if (highlight) {
      return <mark key={`${start}-${end}`}>{text}</mark>;
    } else {
      return <Fragment key={`${start}-${end}`}>{text}</Fragment>;
    }
  });
};
