import { Box, Button, Tooltip } from "@mui/material";
import { getVBillFormattedDate } from "components/pages/common/VBill/utils";
import { VBillRelativeDatesSelectorDialog } from "components/pages/common/VBill/VBillRelativeDatesSelector/VBillRelativeDatesSelectorDialog";
import { IVBillBatchDateUnitType, IVBillTemporality } from "generated/sdk.vbill";
import { pick } from "lodash";
import { observer } from "mobx-react-lite";
import { useMemo, useState } from "react";
import { useStore } from "storeContainer";
import { COLORS } from "themes/default";
import {
  IVBillRelativeDatesSelectorProps,
  relatedDatesGetRelativeDate,
} from "../../../common/VBill/VBillRelativeDatesSelector/VBillRelativeDatesSelector";
import { blackBtnStyles } from "../summariesStyles";

export const SummariesDueDate = observer(function SummariesDueDate() {
  const vBillBatchDetailsStore = useStore("VBillBatchDetailsStore");
  const [isRelativeDueDateDialogOpen, setIsRelativeDueDateDialogOpen] = useState(false);

  const { batchDetails } = vBillBatchDetailsStore;
  const batchData = batchDetails.data;

  const formattedDateDueFromDate = useMemo(() => {
    if (!batchData?.batchFilters?.relativeReferenceDate || !batchData?.batchFilters?.relativeDateDueFrom) {
      return "-";
    }

    const { relativeReferenceDate, relativeDateDueFrom } = batchData.batchFilters;
    const newLocalRelativeReferenceDate = new Date(
      relativeReferenceDate?.customDate ? relativeReferenceDate.customDate : new Date() /* today */,
    ).toString();
    const value = `${relativeDateDueFrom.value}`;
    const temporality = relativeDateDueFrom.temporality ? relativeDateDueFrom.temporality : IVBillTemporality.Before;
    const newSelectedDateUnitType = relativeDateDueFrom?.unit ? relativeDateDueFrom.unit : IVBillBatchDateUnitType.Day;
    const relativeDateValue = relatedDatesGetRelativeDate(
      newLocalRelativeReferenceDate,
      value,
      temporality,
      newSelectedDateUnitType,
    );

    return getVBillFormattedDate(relativeDateValue);
  }, [batchData?.batchFilters]);

  const formattedDateDueToDate = useMemo(() => {
    if (!batchData?.batchFilters?.relativeReferenceDate || !batchData?.batchFilters?.relativeDateDueTo) {
      return "-";
    }

    const { relativeReferenceDate, relativeDateDueTo } = batchData.batchFilters;
    const newLocalRelativeReferenceDate = new Date(
      relativeReferenceDate?.customDate ? relativeReferenceDate.customDate : new Date() /* today */,
    ).toString();
    const value = `${relativeDateDueTo.value}`;
    const temporality = relativeDateDueTo.temporality ? relativeDateDueTo.temporality : IVBillTemporality.Before;
    const newSelectedDateUnitType = relativeDateDueTo?.unit ? relativeDateDueTo.unit : IVBillBatchDateUnitType.Day;
    const relativeDateValue = relatedDatesGetRelativeDate(
      newLocalRelativeReferenceDate,
      value,
      temporality,
      newSelectedDateUnitType,
    );

    return getVBillFormattedDate(relativeDateValue);
  }, [batchData?.batchFilters]);

  const handleSummariesDueDateSubmit: IVBillRelativeDatesSelectorProps["onDatesSubmit"] = async (dates) => {
    if (!batchData) {
      return;
    }

    await vBillBatchDetailsStore.updateBatchDetails({
      batchId: batchData.id,
      batchData: {
        name: batchData.name,
        organizationId: batchData.organizationId,
        batchFilters: {
          ...batchData.batchFilters,
          ...dates,
        },
      },
    });
  };

  return (
    <>
      <Tooltip title="Edit bills due date range" arrow>
        <Button onClick={() => setIsRelativeDueDateDialogOpen(true)} sx={{ ...blackBtnStyles, textTransform: "none" }}>
          <Box sx={{ color: COLORS.logoBlue }}>Bills due date range:</Box>
          <div>
            <strong>{formattedDateDueFromDate}</strong>
            <span> to </span>
            <strong>{formattedDateDueToDate}</strong>
          </div>
        </Button>
      </Tooltip>

      {isRelativeDueDateDialogOpen && (
        <VBillRelativeDatesSelectorDialog
          dates={pick(batchData?.batchFilters, ["relativeReferenceDate", "relativeDateDueFrom", "relativeDateDueTo"])}
          onDatesSubmit={handleSummariesDueDateSubmit}
          onCloseDialog={() => setIsRelativeDueDateDialogOpen(false)}
          enableCurrentDateSelector
          title="Relative to date:"
          noCurrentDateText="Please select relative batch date"
          actionsBtnSmallHeight
        />
      )}
    </>
  );
});
