import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import LanguageIcon from "@mui/icons-material/Language";
import SyncIcon from "@mui/icons-material/Sync";
import { Box, Chip, Skeleton, Typography } from "@mui/material";
import { CHDecimal } from "common/helpers/decimal";
import { formatCurrency } from "common/helpers/utils";
import { vBillQuickFilterCircleSx } from "components/pages/common/VBill/styles";
import { IVBillPaymentsGroupingStatus, IVBillPaymentsGroupingType } from "generated/sdk.vbill";
import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { useStore } from "storeContainer";
import { useVBillPaymentsLedgerChildRoute, useVBillPaymentsLedgerQuickFilterUrl } from "../hooks";
import { VBillPaymentsLedgerChildRoutes } from "../utils";

export const PaymentsQuickFilters = observer(function PaymentsQuickFilters() {
  const vBillPaymentsLedgerStore = useStore("VBillPaymentsLedgerStore");
  const {
    isAllChildRoute,
    isProcessingChildRoute,
    // isActionRequiredChildRoute,
    isCompletedChildRoute,
    isVoidedChildRoute,
  } = useVBillPaymentsLedgerChildRoute();
  const allRouteUrl = useVBillPaymentsLedgerQuickFilterUrl(VBillPaymentsLedgerChildRoutes.ALL);
  const processingRouteUrl = useVBillPaymentsLedgerQuickFilterUrl(VBillPaymentsLedgerChildRoutes.PROCESSING);
  // const actionRequiredRouteUrl = useVBillPaymentsLedgerQuickFilterUrl(VBillPaymentsLedgerChildRoutes.ACTION_REQUIRED);
  const completedRouteUrl = useVBillPaymentsLedgerQuickFilterUrl(VBillPaymentsLedgerChildRoutes.COMPLETED);
  const voidedRouteUrl = useVBillPaymentsLedgerQuickFilterUrl(VBillPaymentsLedgerChildRoutes.VOIDED);

  const { paymentsStats } = vBillPaymentsLedgerStore;
  const paymentsStatsData = paymentsStats.data;
  const allStats = useMemo(
    () => paymentsStatsData?.stats?.groupStats.find((group) => group.groupingType === IVBillPaymentsGroupingType.All),
    [paymentsStatsData?.stats?.groupStats],
  );
  const processingStats = useMemo(
    () =>
      paymentsStatsData?.stats?.groupStats?.find(
        (group) => group.value.STATUS === IVBillPaymentsGroupingStatus.Processing,
      ),
    [paymentsStatsData?.stats?.groupStats],
  );
  // const actionRequiredStats = useMemo(
  //   () =>
  //     paymentsStatsData?.stats?.groupStats?.find((group) => group.value.STATUS === IVBillPaymentsGroupingStatus.Error),
  //   [paymentsStatsData?.stats?.groupStats],
  // );
  const completedStats = useMemo(
    () =>
      paymentsStatsData?.stats?.groupStats?.find(
        (group) => group.value.STATUS === IVBillPaymentsGroupingStatus.Completed,
      ),
    [paymentsStatsData?.stats?.groupStats],
  );
  const voidedStats = useMemo(
    () =>
      paymentsStatsData?.stats?.groupStats?.reduce(
        (result, group) => {
          if (
            group.value.STATUS === IVBillPaymentsGroupingStatus.Error ||
            group.value.STATUS === IVBillPaymentsGroupingStatus.Voided
          ) {
            result = {
              count: CHDecimal.add(result.count, group.count).toFixed(2),
              amount: CHDecimal.add(result.amount, group.amount).toFixed(2),
            };
          }

          return result;
        },
        { count: "0", amount: "0" },
      ),
    [paymentsStatsData?.stats?.groupStats],
  );

  return (
    <Box
      sx={{
        display: "flex",
        gap: "10px",
        padding: "20px 0 10px",
      }}
    >
      <Chip
        label={
          <div>
            {vBillPaymentsLedgerStore.paymentsStats.isLoading ? (
              <Skeleton variant="rounded" sx={{ height: "22px", width: "100px" }} />
            ) : (
              <>
                <Typography>All ({Number(allStats?.count ?? 0)})</Typography>
                <Typography>{formatCurrency(Number(allStats?.amount ?? 0))}</Typography>
              </>
            )}
          </div>
        }
        clickable
        component={Link}
        to={allRouteUrl}
        icon={
          <Box
            sx={{
              ...vBillQuickFilterCircleSx,
              backgroundColor: "#B5ECA6",
              border: `1px solid #6FD055`,
            }}
          >
            <LanguageIcon sx={{ color: "#6FD055" }} />
          </Box>
        }
        variant={isAllChildRoute ? "awesome" : "awesome-filled"}
        sx={{ flex: 1, maxWidth: "none" }}
      />

      <Chip
        label={
          <div>
            {vBillPaymentsLedgerStore.paymentsStats.isLoading ? (
              <Skeleton variant="rounded" sx={{ height: "22px", width: "100px" }} />
            ) : (
              <>
                <Typography>Processing ({Number(processingStats?.count ?? 0)})</Typography>
                <Typography>{formatCurrency(Number(processingStats?.amount ?? 0))}</Typography>
              </>
            )}
          </div>
        }
        clickable
        component={Link}
        to={processingRouteUrl}
        icon={
          <Box
            sx={{
              ...vBillQuickFilterCircleSx,
              backgroundColor: Number(processingStats?.count ?? 0) > 0 ? "#FFF3E1" : "#B5ECA6",
              border: `1px solid ${Number(processingStats?.count ?? 0) > 0 ? "#ECBB71" : "#6FD055"}`,
            }}
          >
            <SyncIcon sx={{ color: Number(processingStats?.count ?? 0) > 0 ? "#ECBB71" : "#6FD055" }} />
          </Box>
        }
        variant={isProcessingChildRoute ? "awesome" : "awesome-filled"}
        sx={{ flex: 1, maxWidth: "none" }}
      />

      {/* <Chip
        label={
          <div>
            {vBillPaymentsLedgerStore.paymentsStats.isLoading ? (
              <Skeleton variant="rounded" sx={{ height: "22px", width: "100px" }} />
            ) : (
              <>
                <Typography>Action Required({actionRequiredStats?.count ?? 0})</Typography>
                <Typography>{formatCurrency(Number(actionRequiredStats?.amount ?? 0))}</Typography>
              </>
            )}
          </div>
        }
        clickable
        component={Link}
        to={actionRequiredRouteUrl}
        icon={
          <Box
            sx={{
              ...vBillQuickFilterCircleSx,
              backgroundColor: Number(actionRequiredStats?.count ?? 0) > 0 ? "#FFF3E1" : "#B5ECA6",
              border: `1px solid ${Number(actionRequiredStats?.count ?? 0) > 0 ? "#ECBB71" : "#6FD055"}`,
            }}
          >
            <PsychologyIcon sx={{ color: Number(actionRequiredStats?.count ?? 0) > 0 ? "#ECBB71" : "#6FD055" }} />
          </Box>
        }
        variant={isActionRequiredChildRoute ? "awesome" : "awesome-filled"}
        sx={{ flex: 1, maxWidth: "none" }}
      /> */}

      <Chip
        label={
          <div>
            {vBillPaymentsLedgerStore.paymentsStats.isLoading ? (
              <Skeleton variant="rounded" sx={{ height: "22px", width: "100px" }} />
            ) : (
              <>
                <Typography>Completed ({completedStats?.count ?? 0})</Typography>
                <Typography>{formatCurrency(Number(completedStats?.amount ?? 0))}</Typography>
              </>
            )}
          </div>
        }
        clickable
        component={Link}
        to={completedRouteUrl}
        icon={
          <Box
            sx={{
              ...vBillQuickFilterCircleSx,
              backgroundColor: Number(completedStats?.count ?? 0) > 0 ? "#FFF3E1" : "#B5ECA6",
              border: `1px solid ${Number(completedStats?.count ?? 0) > 0 ? "#ECBB71" : "#6FD055"}`,
            }}
          >
            <DoneIcon sx={{ color: Number(completedStats?.count ?? 0) > 0 ? "#ECBB71" : "#6FD055" }} />
          </Box>
        }
        variant={isCompletedChildRoute ? "awesome" : "awesome-filled"}
        sx={{ flex: 1, maxWidth: "none" }}
      />

      <Chip
        label={
          <div>
            {vBillPaymentsLedgerStore.paymentsStats.isLoading ? (
              <Skeleton variant="rounded" sx={{ height: "22px", width: "100px" }} />
            ) : (
              <>
                <Typography>Voided ({voidedStats?.count ?? 0})</Typography>
                <Typography>{formatCurrency(Number(voidedStats?.amount ?? 0))}</Typography>
              </>
            )}
          </div>
        }
        clickable
        component={Link}
        to={voidedRouteUrl}
        icon={
          <Box
            sx={{
              ...vBillQuickFilterCircleSx,
              backgroundColor: Number(voidedStats?.count ?? 0) > 0 ? "#FFF3E1" : "#B5ECA6",
              border: `1px solid ${Number(voidedStats?.count ?? 0) > 0 ? "#ECBB71" : "#6FD055"}`,
            }}
          >
            <CloseIcon sx={{ color: Number(voidedStats?.count ?? 0) > 0 ? "#ECBB71" : "#6FD055" }} />
          </Box>
        }
        variant={isVoidedChildRoute ? "awesome" : "awesome-filled"}
        sx={{ flex: 1, maxWidth: "none" }}
      />
    </Box>
  );
});
