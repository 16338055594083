import { Typography } from "@mui/material";
import { formatCurrency } from "common/helpers/utils";
import { useMemo } from "react";
import { useWatch } from "react-hook-form";
import { IPayVBillsFormFields } from "../../../PayVBillsDialog";

interface IPayVBillsGroupTotalProps {
  groupIndex: number;
}

export const PayVBillsGroupTotal = ({ groupIndex }: IPayVBillsGroupTotalProps) => {
  const groupsBillsWatcher = useWatch({
    name: `groups.${groupIndex}.bills`,
  }) as IPayVBillsFormFields["groups"][number]["bills"];
  const totalBillsAmountsValue = useMemo(() => {
    const billsAmounts = (groupsBillsWatcher ?? []).reduce(
      (result, field) => (result = result + Number(field.amount ?? 0)),
      0,
    );

    return formatCurrency(billsAmounts);
  }, [groupsBillsWatcher]);

  return (
    <Typography sx={{ padding: "0 10px" }}>
      <strong>Total: </strong>
      {totalBillsAmountsValue}
    </Typography>
  );
};
