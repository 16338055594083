import { Box, Dialog, IconButton, Tooltip } from "@mui/material";
import { graphqlClient } from "common/graphqlClient";
import { CloseIcon } from "components/common/icons";
import { Spinner } from "components/common/Spinner/Spinner";
import VCheckCheck from "components/pages/VChecks/VCheckCheck";
import { getSdk as getCoreSdk, VCheckCheckQueryQuery } from "generated/sdk";
import { useEffect, useState } from "react";
import { COLORS } from "themes/default";

const { VCheckCheckQuery } = getCoreSdk(graphqlClient);

interface IPaymentVCheckDialogProps {
  ledgerCheck?: NonNullable<VCheckCheckQueryQuery["VCheckCheckQuery"]>["ledger_check"];
  vCheckId?: string | null;
  onCloseDialog: () => void;
}

export const PaymentVCheckDialog = ({ ledgerCheck, vCheckId, onCloseDialog }: IPaymentVCheckDialogProps) => {
  const [vCheckLedgerCheckData, setVCheckLedgerCheckData] =
    useState<NonNullable<VCheckCheckQueryQuery["VCheckCheckQuery"]>["ledger_check"]>(ledgerCheck);

  useEffect(() => {
    if (!ledgerCheck && vCheckId) {
      fetchVCheckCheckQuery(vCheckId);
    }
  }, [ledgerCheck, vCheckId]);

  const fetchVCheckCheckQuery = async (vCheckId: string) => {
    const resp = await VCheckCheckQuery({ id: vCheckId });

    setVCheckLedgerCheckData(resp.VCheckCheckQuery?.ledger_check);
  };

  return (
    <Dialog open onClose={onCloseDialog} fullWidth maxWidth="lg">
      <Box sx={{ backgroundColor: COLORS.white, position: "relative" }}>
        <Tooltip arrow title="Close">
          <IconButton onClick={onCloseDialog} sx={{ position: "absolute", top: "5px", right: "5px", zIndex: 1 }}>
            <CloseIcon sx={{ fontSize: "20px" }} />
          </IconButton>
        </Tooltip>

        {vCheckLedgerCheckData ? <VCheckCheck check={vCheckLedgerCheckData} isModal={false} /> : <Spinner />}
      </Box>
    </Dialog>
  );
};
