import { Box, Divider, Typography } from "@mui/material";
import { observer } from "mobx-react";
import { useMemo } from "react";
import { useStore } from "storeContainer";
import { COLORS } from "themes/default";

export const IncomingCompanyInfo = observer(() => {
  const vBillStore = useStore("VBillStore");
  const organizationStore = useStore("OrganizationStore");
  const invoiceData = useMemo(() => vBillStore.invoice.data, [vBillStore.invoice.data]);
  const invoiceCompanyName = useMemo(
    () =>
      organizationStore.organizationVBillEnabledCompanies.data?.items.find(
        ({ companyId }) => companyId === invoiceData?.companyId,
      )?.name,
    [organizationStore.organizationVBillEnabledCompanies.data?.items, invoiceData?.companyId],
  );

  if (!invoiceCompanyName) {
    return null;
  }

  return (
    <>
      <Box sx={{ marginBottom: "10px" }}>
        <Typography sx={{ fontSize: 16, marginBottom: "5px", fontWeight: 500, color: COLORS.logoBlue }}>
          COMPANY:
        </Typography>

        <Typography sx={{ wordWrap: "break-word" }}>{invoiceCompanyName}</Typography>
      </Box>

      <Divider sx={{ marginBottom: "10px" }} />
    </>
  );
});
