import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import classNames from "classnames";
import { VCheckUndepositedChecksQueryResult } from "generated/sdk";
import { useHistory } from "react-router-dom";
import { formatCurrency } from "../../common/helpers/utils";
import styles from "./Widgets.module.scss";

export const PendingChecksComponent: React.FunctionComponent<{
  undepositedChecks?: VCheckUndepositedChecksQueryResult;
  checkText: string;
  amount?: number;
  numberOfChecks: string | number;
  url: string;
  handleClose?: () => void;
}> = ({ checkText, amount, url, numberOfChecks, handleClose = () => {} }) => {
  const isImportErrorOrReadyToDeposit = url.includes("import");
  const history = useHistory();
  return (
    <Box
      className={classNames(styles.pendingCardContainer, {
        [styles.importErrors]: isImportErrorOrReadyToDeposit,
        [styles.readyToDeposit]: !isImportErrorOrReadyToDeposit,
      })}
      onClick={() => {
        history.replace(url);
        handleClose();
      }}
    >
      <Typography className={styles.pendinTransactionCardHeaderText}>{`${checkText} (${numberOfChecks})`}</Typography>
      {amount && (
        <Typography className={styles.pendinTransactionCardSubHeaderText}>{formatCurrency(amount)}</Typography>
      )}
    </Box>
  );
};
