import {
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  ListSubheader,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useCheckFilters } from "common/hooks/useCheckFilters";
import { CheckBatchStateEnum, GetCheckBatchesFiltersType } from "generated/sdk";
import { observer } from "mobx-react-lite";
import { useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router";
import { VariableSizeList as List } from "react-window";
import { useStore } from "storeContainer";
import BudgetManagementSearchFilter from "./BudgetManagementSearchFilter";
import { definitelyFilter } from "generated/utils";

const BudgetManagementFilters = () => {
  const { filters, setFilters } = useCheckFilters<GetCheckBatchesFiltersType>();
  const [selectedCompanies, setSelectedCompanies] = useState<string[]>([""]);
  console.log("🍒 ~ selectedCompanies:", selectedCompanies);
  const [searchTermCompany, setSearchTermCompany] = useState<string>("");
  const [companyItems, setCompanyItems] = useState<Record<string, any>>();
  const sessionStore = useStore("SessionStore");
  const { acl } = sessionStore;
  const { organizationId } = useParams<{ organizationId?: string }>();
  const listRef = useRef<any>(null);

  const States = useMemo(() => {
    return Object.values(CheckBatchStateEnum).map((value) => ({
      value,
    }));
  }, []);
  const aclCompanies = useMemo(
    () => sessionStore.companiesOfOrganization(organizationId!).filter((company) => acl.canListCompany(company.id)),
    [sessionStore.companiesOfOrganization(organizationId!)],
  );
  useEffect(() => {
    setCompanyItems(aclCompanies);
  }, []);

  const handleChangeCompanies = (value: string) => {
    const newSelectedCompanies = selectedCompanies;
    if (newSelectedCompanies.includes(value)) {
      newSelectedCompanies.splice(newSelectedCompanies.indexOf(value), 1);
    } else {
      newSelectedCompanies.push(value);
    }
    setSelectedCompanies(newSelectedCompanies);
    listRef.current?.resetAfterIndex(0);
    setFilters((prev) => {
      return { ...prev, company_ids: newSelectedCompanies };
    });
  };

  const handleChangeState = (event: SelectChangeEvent<string[]>) => {
    const { value } = event.target;
    if (value.includes("all")) {
      if (value.length === 3) {
        setFilters((prev) => {
          return { ...prev, state: [] };
        });
      } else {
        setFilters((prev) => {
          return { ...prev, state: [CheckBatchStateEnum.Closed, CheckBatchStateEnum.Open] };
        });
      }
    } else {
      setFilters((prev) => {
        return { ...prev, state: value as CheckBatchStateEnum[] };
      });
    }
  };

  const handleSearchCompany = (searchTerm: string) => {
    setSearchTermCompany(searchTerm);
    const newItems = aclCompanies
      .filter((company) => {
        const companyMatches = new RegExp(searchTerm, "gi").test(company.name);
        return searchTerm === "" || companyMatches;
      })
      .map((company) => ({ id: company.id, name: company.name, search: searchTerm }));
    setCompanyItems(newItems);
  };

  const Row = ({ index, style }: { index: number; style: Record<string, any> }) => {
    const item = companyItems?.[index];
    console.log("🍒 ~ item:", item.id, selectedCompanies);

    return (
      <div style={style}>
        <Box display="flex" paddingX="15px" gap="5px">
          <MenuItem
            sx={{ width: "100%" }}
            onClick={(e) => {
              e.preventDefault();
              handleChangeCompanies(item.id);
            }}
            key={item.id}
            value={item.id}
          >
            <FormControlLabel
              control={<Checkbox checked={selectedCompanies.includes(item.id)} />}
              label={
                <Typography variant="body1" sx={{ marginLeft: 0.5 }}>
                  {item.name}
                </Typography>
              }
            />
          </MenuItem>
        </Box>
      </div>
    );
  };

  useEffect(() => {
    if (filters?.company_ids) {
      setSelectedCompanies(definitelyFilter(filters.company_ids));
    }
  }, [filters?.company_ids]);

  return (
    <>
      <Grid container margin="20px 0 20px 0" alignItems="center" justifyContent="space-between">
        <Box display="flex" justifyContent="space-between" width="100%" flexWrap="wrap">
          <Box display="flex" justifyContent="flex-start" gap="20px" flexWrap="wrap" alignItems="flex-end">
            <BudgetManagementSearchFilter />
            <FormControl>
              <Typography sx={{ marginBottom: "5px" }} variant="body2">
                Select Companies
              </Typography>

              <Select
                labelId="multiple-select-label"
                multiple
                displayEmpty
                value={selectedCompanies!}
                renderValue={(selected) => {
                  return (selected?.length as number) > 0
                    ? `${selected?.length} ${selected?.length > 1 ? "Companies" : "Company"}`
                    : "All";
                }}
                style={{ minWidth: 200 }}
              >
                <ListSubheader sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                  <TextField
                    placeholder="Search"
                    value={searchTermCompany}
                    onChange={(e) => handleSearchCompany(e.target.value)}
                  />
                  <Divider sx={{ width: "100%", marginY: "10px" }} />
                </ListSubheader>
                <List ref={listRef} height={300} itemCount={companyItems?.length!} itemSize={() => 35} width={300}>
                  {Row}
                </List>
              </Select>
            </FormControl>
            <FormControl>
              <Typography sx={{ marginBottom: "5px" }} variant="body2">
                State
              </Typography>
              <Select
                labelId="multiple-select-label-state"
                multiple
                value={(filters?.state as CheckBatchStateEnum[]) ?? []}
                onChange={(e) => {
                  e.preventDefault();
                  handleChangeState(e);
                }}
                displayEmpty
                renderValue={(selected: CheckBatchStateEnum[]) =>
                  (selected?.length as number) === (States?.length as number) || !selected?.length
                    ? "All"
                    : selected.includes(CheckBatchStateEnum.Open)
                      ? "Open"
                      : "Closed"
                }
                style={{ minWidth: 200 }}
              >
                {States.map((state) => (
                  <MenuItem key={state.value} value={state.value}>
                    <Box style={{ display: "flex", alignItems: "center" }}>
                      <Checkbox checked={filters?.state?.includes(state.value)} />
                      <Typography marginLeft={0.5} variant="body1">
                        {state.value.charAt(0).toUpperCase() + state.value.slice(1)}
                      </Typography>
                    </Box>
                  </MenuItem>
                ))}
                <MenuItem value="all">
                  <Box style={{ display: "flex", alignItems: "center" }}>
                    <Checkbox checked={filters?.state?.length === States.length} />
                    <Typography marginLeft={0.5} variant="body1">
                      All
                    </Typography>
                  </Box>
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>
      </Grid>
    </>
  );
};

export default observer(BudgetManagementFilters);
