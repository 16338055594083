import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { CloseIcon } from "components/common/icons";
import { observer } from "mobx-react";
import { useChatChannelStore } from "modules/Chat/hooks/hooks";
import { FunctionComponent } from "react";
import { ChatMessageBlockContent } from "../ChatMessages/ChatMessage/ChatMessageBlockContent/ChatMessageBlockContent";
export const ChatMessageReply: FunctionComponent = observer(() => {
  const chatChannelStore = useChatChannelStore();

  if (!chatChannelStore?.replyToMessage) {
    return null;
  }

  const { memberId, content } = chatChannelStore?.replyToMessage;

  const isMessageOwner = memberId === chatChannelStore?.currentSessionMember?.id;
  const member = chatChannelStore?.members?.find(({ id }) => id === memberId);

  return (
    <Box
      sx={{
        marginBottom: "20px",
        padding: "20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: "20px",
        backgroundColor: isMessageOwner ? "#E5E5E5" : "#CADBE2",
      }}
    >
      <Box>
        <Typography sx={{ marginBottom: "10px" }}>
          Reply to:{" "}
          <strong style={{ textDecoration: "underline" }}>{isMessageOwner ? "You" : member?.name ?? ""}</strong>
        </Typography>

        <ChatMessageBlockContent content={content} isMessageOwner={isMessageOwner} />
      </Box>

      <Tooltip title="Clear Reply">
        <IconButton onClick={() => chatChannelStore?.setReplyToMessage()}>
          <CloseIcon color="primary" />
        </IconButton>
      </Tooltip>
    </Box>
  );
});
