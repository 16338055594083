import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Link, Paper } from "@mui/material";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListSubheader from "@mui/material/ListSubheader";
import Typography from "@mui/material/Typography";
import { Company } from "generated/sdk";
import { observer } from "mobx-react-lite";
import { useMemo, useState } from "react";
import { useParams } from "react-router";
import { useSearchParam } from "react-use";
import { useStore } from "storeContainer";
import { COLORS } from "themes/default";

// interface TabPanelProps {
//   children?: React.ReactNode;
//   index: number;
//   value: number;
// }

// const TabPanel = (props: TabPanelProps) => {
//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`vertical-tabpanel-${index}`}
//       aria-labelledby={`vertical-tab-${index}`}
//       {...other}
//     >
//       {value === index && (
//         <Box sx={{ p: "30px" }}>
//           <Typography>{children}</Typography>
//         </Box>
//       )}
//     </div>
//   );
// };

// const a11yProps = (index: number) => {
//   return {
//     id: `vertical-tab-${index}`,
//     "aria-controls": `vertical-tabpanel-${index}`,
//   };
// };

export const IntegrationLanding = () => {
  const { integrationSlug } = useParams<{ integrationSlug: string }>();
  const sessionStore = useStore("SessionStore");
  const integrationsStore = useStore("IntegrationsStore");
  const companyidentifier = useSearchParam("companyidentifier");
  const companyname = useSearchParam("companyname") || "";
  const [done, setDone] = useState(false);
  // const [value, setValue] = useState(0);

  // const handleChange = (event: React.SyntheticEvent, newValue: number) => {
  //   setValue(newValue);
  // };

  const integrate = async (orgId: string, companyId: string) => {
    const integrations = await integrationsStore.fetchIntegrations(companyId);
    const integration = integrations.find((i) => i.slug === integrationSlug);

    if (!integration) {
      alert(`Invalid slug`);
      return;
    }
    const authHookUrl = integration.webhooks?.find((w) => w.scope === "authorization")?.url;

    const ci = await integrationsStore.fetchCompanyIntegrations(companyId);
    if (ci?.integrations?.length) {
      alert(`Already integrated`);

      return;
    }

    console.log("Will integrate with", integration);
    let authCode;
    try {
      authCode = await integrationsStore.fetchIntegrationAuthCode(integration, companyname, companyId);
    } catch (err) {
      console.log("Error", err);
    }
    if (!authCode) {
      alert(`Failed to generate auth code`);
      return;
    }

    const params = [
      `companyId=${encodeURIComponent(companyId)}`,
      authCode && `authCode=${authCode}`,
      companyidentifier && `companyIdentifier=${companyidentifier}`,
      companyname && `companyName=${encodeURIComponent(companyname)}`,
    ].filter((p) => p).join("&");
    const url = `${authHookUrl}?${params}`;
    console.log("Url", url);

    setDone(true);
    setTimeout(() => {
      (window as any).location = url;
    }, 500);
  };

  const visibleOrganizationsAndCompanies = useMemo(() => {
    if (companyidentifier) {
      return sessionStore.organizations
        .filter((organization) => organization.companies?.find((comp) => comp.id === companyidentifier))
        .map((organization) => ({
          ...organization,
          companies: (organization.companies ?? []).filter((comp): comp is Company => comp.id === companyidentifier),
        }));
    } else {
      return sessionStore.organizations
        .filter(
          (organization) => organization.companies?.every((comp) => !(comp.company_integrations ?? []).length) ?? false,
        )
        .map((organization) => ({
          ...organization,
          companies: (organization.companies ?? []).filter((comp) => !comp.company_integrations?.length),
        }));
    }
  }, [companyidentifier, sessionStore.organizations]);

  return (
    <>
      <Box>
        {done ? (
          <Typography variant="h2" marginY="30px">
            Please continue in the plugin ...
          </Typography>
        ) : (
          <>
            <Typography variant="h2" marginY="30px">
              Please select a company to integrate
            </Typography>
            {/* <Box
              sx={{
                flexGrow: 1,
                bgcolor: "background.paper",
                display: "flex",
                height: "60vh",
                width: "100vw",
                maxWidth: "calc(100vw - 550px)",
                borderRadius: "4px",
                ".Mui-selected": {
                  background: "#EBF4FF",
                  color: "#052048",
                },
              }}
            >
              <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Please select a company to integrate"
                sx={{ borderRight: 1, borderColor: "divider", width: "250px" }}
              >
                {sessionStore.organizations.map((organization, index) => {
                  return <Tab label={organization.name} {...a11yProps(index)} />;
                })}
              </Tabs>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(3, 1fr)",
                  gap: "15px",
                  padding: "30px",
                  overflow: "auto",
                }}
              >
                {sessionStore.organizations[value].companies?.map((company) => {
                  return (
                    <Button endIcon={<OpenInNewIcon />} size="small" variant="text" sx={{ height: "42px" }}>
                      {company.name}
                    </Button>
                  );
                })}
              </Box>
            </Box> */}
            <Paper sx={{ overflow: "auto", maxHeight: "calc(100vh - 300px)" }} elevation={3}>
              <List
                sx={{
                  "& ul": { padding: 0 },
                }}
                subheader={<li />}
              >
                {visibleOrganizationsAndCompanies.map((organization) => (
                  <li key={`section-${organization.id}`}>
                    <ul>
                      <ListSubheader
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          backgroundColor: " #EBF4FF",
                          color: "#052048",
                          paddingX: "30px",
                        }}
                      >
                        {/* <AccountBalanceIcon sx={{ fontSize: "1rem", marginRight: ".5rem" }} /> */}
                        {organization.name}
                      </ListSubheader>
                      {organization.companies?.map((company) => (
                        <ListItem
                          key={`item-${organization.id}-${company.id}`}
                          alignItems="flex-start"
                          sx={{ paddingX: "30px" }}
                        >
                          <OpenInNewIcon
                            sx={{ fontSize: "1rem", marginRight: ".5rem", pointer: "cursor" }}
                            htmlColor={COLORS.newSecondaryColor}
                            onClick={() => integrate(organization.id, company.id)}
                          />
                          <Link
                            // href="#"
                            component="button"
                            variant="body2"
                            onClick={() => integrate(organization.id, company.id)}
                            title={company.company_integrations
                              ?.map((int) => `${int.integration?.slug} - ${int.integration?.integration_id}`)
                              .join("; ")}
                          >
                            {company.name}
                          </Link>
                        </ListItem>
                      ))}
                    </ul>
                  </li>
                ))}
              </List>
            </Paper>
          </>
        )}
      </Box>
    </>
  );
};

export default observer(IntegrationLanding);
