import { graphqlClient } from "common/graphqlClient";
import { FeatureEnumType, getSdk } from "generated/sdk";
import { StoreBase } from "./StoreBase";

// It's intended to be a separate store, because we might need to expand it in the future.
const { CreateFeatureAccessRequestMutation } = getSdk(graphqlClient);

export class FeatureAccessRequestStore extends StoreBase {
  async createFeatureAccessRequest(feature: FeatureEnumType, organizationId: string) {
    await CreateFeatureAccessRequestMutation({
      feature,
      organization_id: organizationId,
    });
  }
}
