import { BankAccountWithAggregatedData } from "generated/sdk";
import { definitelyFilter } from "generated/utils";
import { computed, makeObservable, observable, runInAction } from "mobx";
import { matchPath } from "react-router-dom";
import { StoreBase } from "storesMobx/StoreBase";

export class ContextStore extends StoreBase {
  constructor() {
    super();
    makeObservable(this);
    this._selectedOrganizationId = matchPath<{ organizationId?: string }>(window.location.pathname, {
      path: "/org/:organizationId",
    })?.params?.organizationId;
  }

  @observable private _selectedOrganizationId?: string;
  @observable private _selectedCompanyId?: string;

  @computed get selectedOrganizationId() {
    return this._selectedOrganizationId;
  }
  // TODO:
  @computed get selectedOrganization() {
    return this.storeContainer?.SessionStore.organizations.find((o) => o.id === this._selectedOrganizationId);
  }

  async setSelectedOrganizationId(organizationId: string | undefined) {
    if (organizationId) {
      await runInAction(async () => {
        const prevOrganizationId = this._selectedOrganizationId;
        if (prevOrganizationId !== organizationId) {
          this._selectedOrganizationId = organizationId;
        }
      });
    } else {
      await runInAction(async () => {
        this._selectedOrganizationId = undefined;
      });
    }

    this.storeContainer?.SessionStore.loadSessionDependencies();
  }

  @computed get selectedCompanyId() {
    return this._selectedCompanyId;
  }
  setSelectedCompanyId(companyId: string | undefined) {
    runInAction(() => {
      if (companyId !== this._selectedCompanyId) {
        this._selectedCompanyId = companyId;
      }
    });
  }

  @computed get selectedCompany() {
    return this.storeContainer?.OrganizationStore.organizationCompanies.data?.find(
      (c) => c.id === this.selectedCompanyId,
    );
  }

  @computed get selectedCompanyBanksAccounts(): BankAccountWithAggregatedData[] {
    return definitelyFilter(this.selectedCompany?.bank_accounts);
  }

  @computed get isLoading() {
    return this.storeContainer?.OrganizationStore.organizationCompanies.isLoading;
  }

  @computed get isFetching() {
    return this.storeContainer?.OrganizationStore.organizationCompanies.isFetching;
  }
}
