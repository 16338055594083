import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { graphqlVBillClient } from "common/graphqlClient";
import { getVBillUrl } from "components/pages/common/VBill/utils";
import { IVBillVBillSplitInvoiceFileMutation, getSdk } from "generated/sdk.vbill";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSetState } from "react-use";
import { useStore } from "storeContainer";
import { TVBillFileInfo } from "../FilesSelector";

interface IMoveFileToNewBillModalProps {
  isOpen: boolean;
  onClose: Function;
  file: TVBillFileInfo;
  reloadData?: () => any;
}

const { VBillSplitInvoiceFile } = getSdk(graphqlVBillClient);

enum MoveFileToNewBillModalStateEnum {
  INITIAL,
  LOADING,
  ERROR,
  SUCCESS,
}

export const MoveFileToNewBillModal: React.FunctionComponent<IMoveFileToNewBillModalProps> = ({
  isOpen,
  onClose,
  file,
  reloadData,
}) => {
  const [state, setState] = useSetState<{
    status: MoveFileToNewBillModalStateEnum;
    data?: IVBillVBillSplitInvoiceFileMutation;
  }>({
    status: MoveFileToNewBillModalStateEnum.INITIAL,
  });
  const { organizationId } = useParams<{ organizationId: string }>();
  const vBillStore = useStore("VBillStore");

  useEffect(() => {
    if (!isOpen && state.status !== MoveFileToNewBillModalStateEnum.INITIAL) {
      setState({
        status: MoveFileToNewBillModalStateEnum.INITIAL,
        data: undefined,
      });
    }
  }, [isOpen]);

  const splitInvoiceFile = async () => {
    setState({ status: MoveFileToNewBillModalStateEnum.LOADING });

    try {
      const response = await VBillSplitInvoiceFile({
        invoiceId: file!.invoiceId!,
        fileId: file!.id,
      });

      setState({ status: MoveFileToNewBillModalStateEnum.SUCCESS, data: response });

      if (reloadData) {
        await reloadData();
      }
    } catch (e) {
      setState({ status: MoveFileToNewBillModalStateEnum.ERROR });
    }
  };
  return (
    <Dialog
      open={isOpen}
      onClose={(e: any) => {
        onClose();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      PaperProps={{ sx: { width: "500px" } }}
    >
      <DialogTitle id="alert-dialog-title">Move file to new bill</DialogTitle>
      <DialogContent>
        {state.status === MoveFileToNewBillModalStateEnum.SUCCESS && (
          <DialogContentText>The file was successfully moved to a new bill.</DialogContentText>
        )}
        {state.status === MoveFileToNewBillModalStateEnum.ERROR && (
          <DialogContentText>There was an error moving the file to a new bill.</DialogContentText>
        )}
        {[MoveFileToNewBillModalStateEnum.INITIAL, MoveFileToNewBillModalStateEnum.LOADING].includes(state.status) && (
          <DialogContentText>
            Are you sure you want to move <strong>{file?.name}</strong> to a new bill?
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions sx={{ gap: "10px" }}>
        {[MoveFileToNewBillModalStateEnum.INITIAL, MoveFileToNewBillModalStateEnum.LOADING].includes(state.status) && (
          <>
            <LoadingButton
              disabled={state.status === MoveFileToNewBillModalStateEnum.LOADING}
              variant="outlined"
              onClick={() => onClose()}
            >
              Cancel
            </LoadingButton>
            <LoadingButton
              loading={state.status === MoveFileToNewBillModalStateEnum.LOADING}
              variant="contained"
              onClick={() => splitInvoiceFile()}
            >
              Yes
            </LoadingButton>
          </>
        )}
        {state.status === MoveFileToNewBillModalStateEnum.SUCCESS && (
          <>
            <LoadingButton variant="outlined" onClick={() => onClose()}>
              Close
            </LoadingButton>
            <Button
              variant="contained"
              href={getVBillUrl(organizationId, state.data!.splitInvoiceFile.id, state.data!.splitInvoiceFile.status)}
            >
              Go to new bill
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};
