import { Box, Typography } from "@mui/material";
import { useMemo } from "react";
import { ListChildComponentProps } from "react-window";
import { highlightMatchingText } from "../../common/SuggestionsSearchField/utils";
import { IVBillSingleSelectAutocompleteSuggestion } from "../VBillSuggestionsSingleSelectAutocomplete";

interface IVirtualizedSuggestionLineProps {
  suggestions: IVBillSingleSelectAutocompleteSuggestion[];
  onSuggestionClick: (suggestion: IVBillSingleSelectAutocompleteSuggestion) => void;
  searchTerm?: string;
}

export const VirtualizedSuggestionLine = ({
  index,
  style,
  data: { suggestions, searchTerm = "", onSuggestionClick },
}: ListChildComponentProps<IVirtualizedSuggestionLineProps>) => {
  const suggestion = useMemo(() => suggestions[index], [index, suggestions]);
  const formattedLabel = useMemo(
    () => highlightMatchingText(`${suggestion.name} ${suggestion.label ? `- ${suggestion.label}` : ""}`, searchTerm),
    [searchTerm, suggestion.label, suggestion.name],
  );

  return (
    <Box style={style} sx={{ padding: "10px 0px" }} title={suggestion.name}>
      <Box
        component="button"
        onClick={() => onSuggestionClick(suggestion)}
        sx={{
          background: "none",
          border: "none",
          textAlign: "left",
          padding: 0,
          width: "100%",
          "&:hover": { cursor: "pointer", backgroundColor: "#f5f5f5" },
        }}
      >
        <Typography
          sx={{ width: "295px", lineHeight: "28px" }}
          noWrap
          title={`${suggestion.name} ${suggestion.label ? `- ${suggestion.label}` : ""}`}
        >
          {formattedLabel}
        </Typography>
      </Box>
    </Box>
  );
};
