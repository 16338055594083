import { Box, FormControlLabel, FormGroup, Skeleton, Switch, Typography } from "@mui/material";
import { graphqlVBillClient } from "common/graphqlClient";
import { formatCurrency } from "common/helpers/utils";
import { getSdk, IVBillBillsFiltersInput, IVBillVBillGetBillsStatsQuery } from "generated/sdk.vbill";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { labelTextStyles } from "../VBillAddFullBatchDialog";

const { VBillGetBillsStats } = getSdk(graphqlVBillClient);

interface IAddFullBatchSummaryProps {
  batchFilters?: IVBillBillsFiltersInput | null;
  onAddBillsSwitcherChange: (checked: boolean) => void;
  addBillsSwitcherChecked: boolean;
}

export const AddFullBatchSummary = ({
  batchFilters,
  onAddBillsSwitcherChange,
  addBillsSwitcherChecked,
}: IAddFullBatchSummaryProps) => {
  const { organizationId } = useParams<{ organizationId: string }>();
  const [localBillsStats, setLocalBillsStats] = useState<IVBillVBillGetBillsStatsQuery["getBillsStats"]>();

  useEffect(() => {
    setLocalBillsStats(undefined);
    VBillGetBillsStats({ filters: { organizationId, ...batchFilters } }).then((resp) =>
      setLocalBillsStats(resp.getBillsStats),
    );
  }, [batchFilters, organizationId]);

  return (
    <Box sx={{ marginBottom: "15px" }}>
      <Typography sx={labelTextStyles}>
        Available bills{" "}
        <Box component="sup" sx={{ fontSize: "10px" }}>
          ({localBillsStats?.generalStats?.count ?? 0})
        </Box>
      </Typography>

      <FormGroup>
        <FormControlLabel
          disabled={(localBillsStats?.generalStats.count ?? 0) === 0}
          control={
            <Switch
              checked={(localBillsStats?.generalStats.count ?? 0) === 0 ? false : addBillsSwitcherChecked}
              onChange={(e) => onAddBillsSwitcherChange(e.target.checked)}
            />
          }
          label={<Typography>Automatically add bills to batch</Typography>}
        />
      </FormGroup>

      {!localBillsStats ? (
        <Skeleton variant="rounded" sx={{ height: "20px" }} />
      ) : (
        <Typography sx={{ fontSize: "12px" }}>
          There are a total of <strong>{localBillsStats?.generalStats.count} bill(s)</strong> matching:{" "}
          <strong>{formatCurrency(Number(localBillsStats?.generalStats.amount ?? 0))}</strong>
        </Typography>
      )}
    </Box>
  );
};
