import { Box } from "@mui/material";
import { VBillAmountFilter } from "components/pages/common/VBill/FilterComponents/VBillAmountFilter/VBillAmountFilter";
import { VBillDateFilter } from "components/pages/common/VBill/FilterComponents/VBillDateFilter/VBillDateFilter";
import { VBillSearchFilter } from "components/pages/common/VBill/FilterComponents/VBillSearchFilter/VBillSearchFilter";
import {
  IStatusOption,
  VBillStatusFilter,
} from "components/pages/common/VBill/FilterComponents/VBillStatusFilter/VBillStatusFilter";
import { friendlyBatchStatusNames } from "components/pages/common/VBill/utils";
import { IVBillBatchStatus } from "generated/sdk.vbill";
import { useParams } from "react-router";
import { useBatchLedgerFilters, useVBillBatchLedgerChildRoute } from "../hooks";
import { AddBatchBtn } from "./AddBatchBtn/AddBatchBtn";
import { AddFullBatchBtn } from "./AddFullBatchBtn/AddFullBatchBtn";

const statusFilterOptions: IStatusOption[] = Object.values(IVBillBatchStatus).map((value) => ({
  label: friendlyBatchStatusNames[value],
  value,
}));

export const BatchListFilters = () => {
  const { organizationId } = useParams<{ organizationId: string }>();
  const [batchLedgerFilters, setBatchLedgerFilters] = useBatchLedgerFilters();
  const { isAllChildRoute } = useVBillBatchLedgerChildRoute();

  return (
    <Box sx={{ display: "flex", flexWrap: "wrap", gap: "10px", alignItems: "center", padding: "10px 0" }}>
      <VBillSearchFilter
        term={batchLedgerFilters.filters?.search}
        onTermChange={(term) =>
          setBatchLedgerFilters((prev) => ({
            filters: { organizationId, search: term?.length ? term : undefined },
            pagination: { page: 1, per_page: prev.pagination.per_page },
          }))
        }
      />

      {isAllChildRoute && (
        <VBillStatusFilter
          status={batchLedgerFilters.filters?.status}
          onStatusChange={(value) =>
            setBatchLedgerFilters((prev) => ({
              filters: {
                ...prev.filters,
                status: value as IVBillBatchStatus,
              },
              pagination: { page: 1, per_page: prev.pagination.per_page },
            }))
          }
          options={statusFilterOptions}
        />
      )}

      <VBillAmountFilter
        amount={{
          amount_from: batchLedgerFilters.filters?.budget_from,
          amount_to: batchLedgerFilters.filters?.budget_to,
        }}
        onAmountChange={({ amount_from, amount_to }) =>
          setBatchLedgerFilters((prev) => ({
            filters: {
              ...prev.filters,
              budget_from: amount_from,
              budget_to: amount_to,
            },
            pagination: { page: 1, per_page: prev.pagination.per_page },
          }))
        }
        btnText="Budget"
      />

      <VBillDateFilter
        date={{
          date: batchLedgerFilters.filters?.date_from,
          date_to: batchLedgerFilters.filters?.date_to,
        }}
        onDateChange={({ date, date_to }) =>
          setBatchLedgerFilters((prev) => ({
            filters: { ...prev.filters, date_from: date, date_to },
            pagination: { page: 1, per_page: prev.pagination.per_page },
          }))
        }
        btnText="Date"
      />

      <AddBatchBtn />

      <AddFullBatchBtn />
    </Box>
  );
};
