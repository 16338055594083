import Box from "components/common/Box";
import Divider from "components/common/Divider";
import LoadingButton from "components/common/LoadingButton";
import Paper from "components/common/Paper";
import TextField from "components/common/TextField";
import Typography from "components/common/Typography";
import { Save } from "components/common/icons";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useStore } from "storeContainer";

const emailRegEx = /^[\w.+-]+@([\w-]+\.)+[\w-]{2,4}$/;

const LinkedAddressDetails = ({ className, onClose }: Props) => {
  const { accountId } = useParams<{ accountId: string }>();
  const [validationError, setValidationError] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const accountsStore = useStore("AccountsStore");

  const history = useHistory();

  const { acl } = useStore("SessionStore");
  const SessionStore = useStore("SessionStore");
  const handleChange = useCallback((event) => {
    setValidationError("");
    setEmail(event.target.value);
  }, []);

  const handleSave = async () => {
    if (emailRegEx.test(email)) {
      setValidationError("");
      try {
        await accountsStore.addLinkedAddress(accountId, email);
        onClose();
      } catch (e: any) {
        const errors = Object.values(e.response.errors?.extensions?.validation).join("\n");
        setValidationError(errors);
      }
      setEmail("");
    } else {
      setValidationError("Invalid email address");
    }
    SessionStore.loadSession();
  };

  useEffect(() => {
    if (acl.aclDone && accountId) {
      if (!acl.canAddBankAccountLinkedEmailAddresses(accountId)) {
        history.replace("/accessRestricted");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acl.aclDone, accountId]);

  return (
    <Box marginTop="25px" marginLeft="20px" width="1052px">
      <Typography color="primary" component="h1" sx={{ my: 2.5 }} variant="h5">
        Link Email Address
      </Typography>
      <Paper>
        <Typography sx={{ p: 2 }} variant="h6">
          Link Email Address Form
        </Typography>
        <Divider />
        <Box alignItems="center" display="flex" flexWrap="wrap" gap={3} justifyContent="center" px={2} py={6}>
          <TextField
            FormHelperTextProps={{
              sx: { bottom: 0, left: 0, position: "absolute", transform: "translateY(125%)" },
            }}
            error={!!validationError}
            helperText={validationError}
            label="E-mail Address"
            sx={{ minWidth: "300px" }}
            onChange={handleChange}
          />
          <LoadingButton
            color="primary"
            disabled={!email}
            loadingPosition="start"
            size="large"
            startIcon={<Save />}
            variant="outlined"
            onClick={handleSave}
          >
            Link Address
          </LoadingButton>
        </Box>
      </Paper>
    </Box>
  );
};

interface Props {
  className?: string;
  onClose: () => void;
}

export default observer(LinkedAddressDetails);
