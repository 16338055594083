import { Box } from "@mui/material";
import { COLORS } from "themes/default";

export const BatchVBillsDraggingOverlay = () => (
  <Box
    sx={{
      border: `2px solid ${COLORS.logoBlue}`,
      borderRadius: "6px",
      backgroundColor: `rgba(22, 69, 139, 0.2)`,
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 1,
    }}
  />
);
