import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import InputBase from "@mui/material/InputBase";
import { useState } from "react";
import styles from "../UndepositedChecks.module.scss";
import SearchIcon from "@mui/icons-material/Search";

export const SuggestedBankAccounts = ({}: {}) => {
  const [search, setSearch] = useState<string>("");

  return (
    <Box>
      <Paper sx={{ padding: "0 20px 20px 20px" }}>
        <Grid className={styles.scrollbarDiv} sx={{ justifyContent: "center" }} maxWidth={340} container>
          <Grid xs={12} item>
            <Typography marginBottom={"30px"} fontSize={20} fontWeight={500}>
              Select Bank Account:
            </Typography>
          </Grid>
          <Grid xs={12} item>
            <Paper sx={{ p: 1, display: "flex", alignItems: "center", width: "100%", border: "1px solid grey" }}>
              <InputBase
                onChange={(e) => setSearch(e.target.value)}
                sx={{ ml: 1, flex: 1 }}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
                placeholder="Search company"
                id="search"
                fullWidth
                name="search"
              />
            </Paper>
          </Grid>
          <Box
            sx={{
              width: "100%",
              paddingRight: "10px",
              maxHeight: "40vh",
              marginTop: "10px",
              overflowX: "hidden",
              overflowY: "auto",
              scrollbarWidth: "1px",
            }}
          ></Box>
          <Divider
            sx={{
              borderBottomWidth: 2,
              width: "380px",
              height: "2px",
              marginTop: "10px",
            }}
          />
        </Grid>
      </Paper>
    </Box>
  );
};
