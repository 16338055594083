import { useContext, useMemo } from "react";
import { ChatChannelStoreContext } from "../components/common/ChatChannelStoreProvider/ChatChannelStoreProvider";

function useChatChannelStore() {
  const storeContext = useContext(ChatChannelStoreContext);
  const memoizedStoreContext = useMemo(() => storeContext, [storeContext]);

  return memoizedStoreContext;
}

export { useChatChannelStore };
