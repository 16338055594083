import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { IntegrationStatus } from "components/pages/Company/IntegrationStatus";
import { CompanyIntegration } from "generated/sdk";
import { useHistory } from "react-router-dom";
import { useStore } from "storeContainer";

const statusColors = {
  success: "green",
  error: "red",
  in_progress: "blue",
  expired: "orange",
  unknown: "gray",
} as const;

export const IntegrationNameAndStatus: React.FunctionComponent<{
  integration: CompanyIntegration;
}> = ({ integration }) => {
  const age = integration.settings?.sync_time
    ? (integration.settings?.current_time || Math.round(+new Date() / 1000)) - integration.settings?.sync_time
    : -1;
  const expired = age === -1 || !integration.settings?.sync_expire ? false : age > integration.settings?.sync_expire;

  const status = expired ? "expired" : integration.settings?.sync_status || "unknown";
  const color = statusColors[status as keyof typeof statusColors] ?? statusColors["unknown"];

  const contextStore = useStore("ContextStore");
  const history = useHistory();

  const openIntegrationMapping = () => {
    if (integration) {
      history.push(
        `/org/${contextStore.selectedOrganizationId}/company/${contextStore.selectedCompanyId}/integrations/${integration.integration?.integration_id}`,
      );
    }
  };

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <img
          alt={integration.integration?.name || ""}
          style={{ height: "20px" }}
          src={integration.integration?.logo ?? ""}
        />{" "}
        <Typography
          sx={{
            color: color,
          }}
          variant="body1"
        >
          {integration.integration?.name}
        </Typography>
      </Box>
      <Typography variant="body2" sx={{ display: "flex", mt: 1 }}>
        Last sync: <IntegrationStatus {...(integration.settings as any)} />
      </Typography>
      <Button sx={{ mt: 1, px: 0 }} onClick={openIntegrationMapping} endIcon={<ArrowForwardIosIcon />}>
        Mapping
      </Button>
    </>
  );
};
