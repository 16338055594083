import { Integration } from "generated/sdk";
import _ from "lodash";
import { toJS } from "mobx";
import { IStoreContainer } from "storesMobx";
export class QaTools {
  constructor(public storeContainer: IStoreContainer) {}

  logJson(data: any) {
    console.log(toJS(data));
  }

  async companiesOfThisOrg() {
    await this.storeContainer.AclStore2.reload();
    return this.storeContainer.AclStore2.organizationEntitiesList.filter((e) => e.type === "COMPANY");
  }

  async createAuthCodeForNonIntegratedCompanies(limit: number = 20, integrationSlug: string = "fake") {
    const co = await this.companiesOfThisOrg();
    const authCodes: string[] = [];
    let x = 0;
    for (let c of co) {
      const ci = await this.storeContainer.IntegrationsStore.fetchCompanyIntegrations(c.id);
      if (!ci?.integrations?.length) {
        const i = await this.storeContainer.IntegrationsStore.fetchIntegrations(c.id);
        const is: Integration | undefined = i.find((e) => e.slug === integrationSlug);
        if (is) {
          const auth = await this.storeContainer.IntegrationsStore.fetchIntegrationAuthCode(is, "Auto generated", c.id);
          if (auth) {
            console.log(toJS({ c, is, auth }));
            authCodes.push(auth);
            if (x++ > limit) break;
          }
        }
      }
    }
    // console.log(`RUN`, `app.modules.fakeConnect.connectBulk("${authCodes.join(",")}")`);
    console.log(
      `RUN`,
      authCodes.map((authCode) => `app.modules.fakeConnect.connectBulk("${authCode}")`).join("\n") + "\n",
    );

    //.then(cl => cl.map(c => c.id).forEach(cid => qa.storeContainer.IntegrationsStore.fetchCompanyIntegrations(cid).then(ci => qa.logJson({cid, ci: ci.integrations}))))
  }

  async mapBa(limit: number = 20, integrationSlug: string = "fake") {
    const co = await this.companiesOfThisOrg();
    let l = 0;
    for (let c of co) {
      const ci = await this.storeContainer.IntegrationsStore.fetchCompanyIntegrations(c.id);
      if (ci?.integrations?.length) {
        const i = ci.integrations.find((i) => i.integration?.slug === integrationSlug);
        if (!i) continue;
        const gl = await this.storeContainer.IntegrationsStore.fetchGeneralLedgerAccounts(c.id);
        const bas = _.sortBy(ci.bank_accounts, "name");
        const gls = _.sortBy(
          gl?.filter((g) => !!g.account_number),
          "name",
        );
        const cnt = Math.min(bas.length, gls.length);
        const bamap: { bankAccountId: string; glAccountId: string }[] = [];
        for (let x = 0; x < cnt; x++) {
          bamap.push({ bankAccountId: bas[x].id, glAccountId: gls[x].id });
        }
        await Promise.all(
          bamap.map((m) =>
            this.storeContainer.IntegrationsStore.setBankAccountGlAccountMapping(m.bankAccountId, m.glAccountId).catch(
              (err) => console.log("ERR MAP", m, err),
            ),
          ),
        );
        console.log({ ci, gl, bas, gls, bamap });
        if (l++ > limit) break;
      }
    }
  }

  async mapU(limit: number = 20, integrationSlug: string = "fake") {
    const co = await this.companiesOfThisOrg();
    const ouid = this.storeContainer.SessionStore.selectedOrganizationUserId;
    if (!ouid) return;
    let l = 0;
    for (let c of co) {
      const ci = await this.storeContainer.IntegrationsStore.fetchCompanyIntegrations(c.id);
      if (ci?.integrations?.length) {
        const i = ci.integrations.find((i) => i.integration?.slug === integrationSlug);
        if (!i) continue;
        if (!i.integration?.integration_id) continue;

        await Promise.all([
          this.storeContainer.IntegrationsStore.createExternalAutoSigner(
            "***",
            ouid,
            i.integration?.integration_id,
            c.id,
          ).catch((err) => console.log("ERR map", "***", ouid, i.integration?.integration_id, c.id, err)),
          this.storeContainer.IntegrationsStore.createExternalAutoSigner(
            "",
            ouid,
            i.integration?.integration_id,
            c.id,
          ).catch((err) => console.log("ERR map", "***", ouid, i.integration?.integration_id, c.id, err)),
        ]);
        console.log("mapU", ouid, i.integration?.integration_id, c.id);
        if (l++ > limit) break;
      }
    }
  }
}
