import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Spinner } from "components/common/Spinner/Spinner";
import { CompanyIntegrationsQuery } from "generated/sdk";
import { bindMenu, bindTrigger, usePopupState } from "material-ui-popup-state/hooks";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useStore } from "storeContainer";

export const CompanySettingsDropDownMenu: React.FunctionComponent<{
  bankAccountsNumber: number;
  organizationId: string | undefined;
  companyId: string;
}> = ({ bankAccountsNumber, organizationId, companyId }) => {
  const history = useHistory();
  const { acl } = useStore("SessionStore");
  const integrationsStore = useStore("IntegrationsStore");
  const popupState = usePopupState({
    variant: "popover",
    popupId: "settingsCompanies",
  });
  const [localIntegrationsState, setLocalIntegrationsState] =
    useState<CompanyIntegrationsQuery["CompanyIntegrations"]>();
  const [isIntegrationsLoading, setIsIntegrationsLoading] = useState(false);
  const hasDetailsMenuOption = acl.canListCompany(companyId);
  const hasBaMenuOption = acl.canListCompanyBankAccounts(companyId);
  const hasContactsMenuOption = acl.canListCompanyContacts(companyId);
  const canManageIntegrationOnThisCompany = acl.canManageIntegrationsOnThisCompany(companyId);
  const canAddIntegrationOnThisCompany = acl.canManageIntegrationsOnThisCompany(companyId);

  const fetchLocalIntegrations = useCallback(async () => {
    if (canManageIntegrationOnThisCompany) {
      setIsIntegrationsLoading(true);
      const integrations = await integrationsStore.fetchCompanyIntegrations(companyId);
      setLocalIntegrationsState(integrations);
      setIsIntegrationsLoading(false);
    }
  }, [canManageIntegrationOnThisCompany, companyId, integrationsStore]);

  useEffect(() => {
    if (popupState.isOpen && !localIntegrationsState) {
      fetchLocalIntegrations();
    }
  }, [fetchLocalIntegrations, localIntegrationsState, popupState.isOpen]);

  if (!hasDetailsMenuOption && !hasBaMenuOption && !hasContactsMenuOption) {
    return null;
  }

  const goToBankAccounts = () => {
    history.push(`/org/${organizationId}/company/${companyId}/bas`);
  };

  const goToCompanyDetails = () => {
    history.push(`/org/${organizationId}/company/${companyId}`);
  };

  const goToVendors = () => {
    history.push(`/org/${organizationId}/company/${companyId}/vendors`);
  };

  const goToIntegrations = (isMapping?: boolean) => {
    history.push(
      `/org/${organizationId}/company/${companyId}/integrations/${
        isMapping ? localIntegrationsState?.integrations?.[0]?.integration?.integration_id : ""
      }`,
    );
  };

  return (
    <>
      <Button
        sx={{
          textTransform: "capitalize",
          width: "190px",
          height: "36px",
          fontSize: "13px",
          padding: "0",
          border: "1px solid #D4DEED",
          color: "#707070",
          marginRight: "5px",
        }}
        disableRipple
        disableFocusRipple
        endIcon={<KeyboardArrowDownIcon />}
        {...bindTrigger(popupState)}
      >
        Company Settings
      </Button>
      <Menu
        sx={{ width: "220px" }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        {...bindMenu(popupState)}
      >
        {hasDetailsMenuOption && (
          <MenuItem
            dense
            onClick={() => {
              popupState.close();
              goToCompanyDetails();
            }}
            sx={{ width: "220px" }}
          >
            Company Details
          </MenuItem>
        )}
        {!!bankAccountsNumber && (
          <MenuItem
            dense
            onClick={() => {
              popupState.close();
              goToBankAccounts();
            }}
            sx={{ width: "220px" }}
          >
            Bank Accounts ({bankAccountsNumber})
          </MenuItem>
        )}

        {isIntegrationsLoading && (
          <MenuItem>
            <Spinner />
          </MenuItem>
        )}
        {canManageIntegrationOnThisCompany && !!localIntegrationsState?.integrations?.length && (
          <MenuItem
            dense
            onClick={() => {
              popupState.close();
              goToIntegrations(true);
            }}
            sx={{ width: "220px" }}
          >
            {isIntegrationsLoading ? <Spinner /> : "Integration mapping"}
          </MenuItem>
        )}
        {canAddIntegrationOnThisCompany && localIntegrationsState?.integrations?.length === 0 && (
          <MenuItem
            dense
            onClick={() => {
              popupState.close();
              goToIntegrations(false);
            }}
            sx={{ width: "220px" }}
          >
            Add Integration
          </MenuItem>
        )}
        {hasContactsMenuOption && false && (
          //!TEMPORARILY DISABLED
          <MenuItem
            dense
            onClick={() => {
              popupState.close();
              goToVendors();
            }}
            sx={{ width: "220px" }}
          >
            Contacts
          </MenuItem>
        )}
      </Menu>
    </>
  );
};
