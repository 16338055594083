import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import InputAdornment from "@mui/material/InputAdornment";
import InputBase from "@mui/material/InputBase";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import Modal from "@mui/material/Modal";
import { CompanyBasic, LedgerCheck, OrganizationBasic } from "generated/sdk";
import { useState } from "react";
import { useStore } from "storeContainer";
import { AddBankAccountSource } from "storesMobx/UiStore";
import styles from "../UndepositedChecks.module.scss";

const GrayText: React.FunctionComponent<{ name: string; name2?: string }> = ({ name, name2 }) => {
  return (
    <Grid container>
      <Typography sx={{ marginBottom: "8px", marginTop: "20px" }} fontSize={12} marginTop={1} color="GrayText">
        {`${name}`}&nbsp;
      </Typography>
      {name2 !== "Deposit Accounts" ? (
        <Typography
          sx={{ marginBottom: "8px", marginTop: "20px", textTransform: "uppercase" }}
          fontSize={15}
          marginTop={1}
          color="GrayText"
        >
          {` - ${name2}`}
        </Typography>
      ) : (
        ""
      )}
    </Grid>
  );
};
const OptionForBankAccounts = ({
  allChecks,
  availableBankAccounts,
  search,
  setSelectedBankAccountId,
  setSelectedOrganizationId,
}: {
  allChecks: LedgerCheck[];
  availableBankAccounts: OrganizationBasic[];
  search: string;
  setSelectedBankAccountId: Function;
  setSelectedOrganizationId?: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const storeSelectedBankAccount = (e: any) => {
    setSelectedBankAccountId(e.target.value);
  };

  return (
    <FormControl sx={{ marginLeft: "15px" }} fullWidth>
      <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
        {availableBankAccounts.map((org: OrganizationBasic) => {
          return org.companies
            .filter(
              (company) => company.bank_accounts?.length && company.name.toLowerCase().includes(search.toLowerCase()),
            )
            ?.sort(function (currentCompany, nextCompany) {
              const current = currentCompany.name.toUpperCase();
              const next = nextCompany.name.toUpperCase();
              return current < next ? -1 : current > next ? 1 : 0;
            })
            .map((company: CompanyBasic) => (
              <Box key={company.id} width="100%">
                <GrayText name={company.name} name2={org.name} />
                {company?.bank_accounts?.map((bank_account) => (
                  <FormControlLabel
                    key={bank_account?.id}
                    sx={{
                      border: "1px solid #CADBE2",
                      borderRadius: "5px",
                      padding: "5px 10px",
                      marginBottom: "5px",
                      width: "100%",
                    }}
                    value={bank_account?.id}
                    control={<Radio disabled={allChecks.some((check) => check.sender?.id === bank_account?.id)} />}
                    onChange={(e) => {
                      if (setSelectedOrganizationId) {
                        setSelectedOrganizationId(org?.id!);
                      }
                      storeSelectedBankAccount(e);
                    }}
                    label={bank_account?.name + "*" + bank_account?.account_number}
                  />
                ))}
              </Box>
            ));
        })}
      </RadioGroup>
    </FormControl>
  );
};

export const SelectBankAccount = ({
  allChecks,
  availableBankAccounts,
  close,
  openSelectBanckAccountModal,
  selectedBankAccount,
  setSelectedBankAccountId,
  setOpenReviewAndConfirm,
  setOpenSelectBankAccountModal,
  handleCloseDepositAll,
  setSelectedOrganizationId,
}: {
  allChecks: LedgerCheck[];
  availableBankAccounts: any;
  close: Function;
  openSelectBanckAccountModal: boolean;
  selectedBankAccount?: string;
  setSelectedBankAccountId: Function;
  setOpenReviewAndConfirm?: Function;
  setOpenSelectBankAccountModal: Function;
  handleCloseDepositAll: () => void;
  setSelectedOrganizationId?: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const openReviewAndConfirm = () => {
    if (selectedBankAccount) {
      setOpenReviewAndConfirm!(true);
      setOpenSelectBankAccountModal(false);
    }
  };
  const UiStore = useStore("UiStore");

  const [search, setSearch] = useState<string>("");

  return (
    <Modal
      style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      open={openSelectBanckAccountModal}
      onClose={handleCloseDepositAll}
    >
      <Box>
        <Paper sx={{ padding: "0 20px 20px 20px" }}>
          <Grid xs={12} justifyContent={"flex-end"} container item>
            <Grid item>
              <IconButton onClick={() => close()} sx={{ padding: "5px", marginTop: "10px" }} children={<CloseIcon />} />
            </Grid>
          </Grid>
          <Grid className={styles.scrollbarDiv} sx={{ justifyContent: "center" }} maxWidth={340} container>
            <Grid xs={12} item>
              <Typography marginBottom={"30px"} fontSize={20} fontWeight={500}>
                Select Bank Account:
              </Typography>
            </Grid>
            <Grid xs={12} item>
              <Paper sx={{ p: 1, display: "flex", alignItems: "center", width: "100%", border: "1px solid grey" }}>
                <InputBase
                  onChange={(e) => setSearch(e.target.value)}
                  sx={{ ml: 1, flex: 1 }}
                  startAdornment={
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  }
                  placeholder="Search company"
                  id="search"
                  fullWidth
                  name="search"
                />
              </Paper>
            </Grid>
            <Box
              sx={{
                width: "100%",
                paddingRight: "10px",
                maxHeight: "40vh",
                marginTop: "10px",
                overflowX: "hidden",
                overflowY: "auto",
                scrollbarWidth: "1px",
              }}
            >
              <Grid alignItems={"center"} justifyContent="center" xs={12} item>
                <OptionForBankAccounts
                  allChecks={allChecks}
                  availableBankAccounts={availableBankAccounts}
                  search={search}
                  setSelectedBankAccountId={setSelectedBankAccountId}
                  setSelectedOrganizationId={setSelectedOrganizationId}
                />
              </Grid>
            </Box>
            <Divider
              sx={{
                borderBottomWidth: 2,
                width: "380px",
                height: "2px",
                marginTop: "10px",
              }}
            />
            <Grid sx={{ paddingTop: "10px" }} xs={12} justifyContent={"space-between"} container item>
              <Grid xs={3} item>
                <Button
                  fullWidth
                  sx={{ padding: "10px 15px", fontSize: 13, textTransform: "capitalize", height: "100%" }}
                  color="secondary"
                  variant="outlined"
                  onClick={() => {
                    UiStore.showAddBankAccountModal = AddBankAccountSource.Unknown;
                  }}
                >
                  <AddIcon fontSize="small" /> Add
                </Button>
              </Grid>

              <Grid xs={7} item>
                <Button
                  onClick={openReviewAndConfirm}
                  fullWidth
                  sx={
                    selectedBankAccount
                      ? { padding: "10px 15px", fontWeight: "600", fontSize: 16, textTransform: "capitalize" }
                      : {
                          padding: "10px 15px",
                          fontWeight: "600",
                          fontSize: 16,
                          textTransform: "capitalize",
                          opacity: ".5",
                        }
                  }
                  color="secondary"
                  variant="contained"
                >
                  Review & Confirm
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Modal>
  );
};
