import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { AclEntityTypeEnum } from "generated/sdk";
import { useStore } from "storeContainer";
import { AclStore2, PermissionGroupWithSettings } from "storesMobx/AclStore2";
import { Permissions2PermissionGroupName, Permissions2ViewLimitsAndAccounts } from "./Permissions2View";

interface IPermissionTableProps {
  permissionGroups: PermissionGroupWithSettings[];
  isUser: boolean;
  user_id: string;
  aggregatedPermissions: Awaited<ReturnType<typeof AclStore2.prototype.getAggregatedPermissions>>;
}

export const PermissionsTable: React.FunctionComponent<IPermissionTableProps> = ({
  permissionGroups,
  isUser,
  user_id,
  aggregatedPermissions,
}) => {
  const acl2 = useStore("AclStore2");
  const entity_types = [AclEntityTypeEnum.Organization, AclEntityTypeEnum.Company, AclEntityTypeEnum.BankAccount];

  return (
    <TableContainer component={Paper}>
      <Table
        sx={{
          minWidth: 650,
          ...(acl2.isFetching
            ? {
                cursor: "wait",
                filter: "blur(4px)",
              }
            : {}),
        }}
        aria-label="simple table"
      >
        {entity_types
          .filter((entity_type_row) => permissionGroups.some((pg) => pg.appliesTo.includes(entity_type_row)))
          .map((entity_type_row) => {
            const pgse = permissionGroups.filter((pg) => pg.appliesTo.includes(entity_type_row));
            const pgsel = permissionGroups.filter(
              (pg) => pg.appliesTo.includes(entity_type_row) && pg.settings.hasLimits,
            );

            return (
              <>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ textTransform: "capitalize", width: "300px" }}>
                      <span style={{ textTransform: "capitalize" }}>
                        {entity_type_row.toLowerCase().replace("_", " ")}
                      </span>{" "}
                      level access
                    </TableCell>
                    <TableCell width={"150px"}>Allow</TableCell>
                    <TableCell width={"500px"} align="left">
                      {!!pgsel.length ? <>Limits & Approvals</> : null}
                    </TableCell>
                    <TableCell>
                      {!!pgse.filter((pg) => pg.appliesTo.includes(AclEntityTypeEnum.Organization))
                        .length ? null : !!pgsel.length ? (
                        <>Accounts</>
                      ) : (
                        <>Companies</>
                      )}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {permissionGroups
                    .filter((pg) => pg.appliesTo.indexOf(entity_type_row) !== -1)
                    .sort((a, b) => a.settings.order - b.settings.order)

                    .map((permGroup) =>
                      permGroup.internal_alias ? (
                        <TableRow key={permGroup.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                          <TableCell component="th" scope="row">
                            <Permissions2PermissionGroupName permGroup={permGroup} />
                          </TableCell>

                          <TableCell align="left" colSpan={3} sx={{ p: 0 }}>
                            <Permissions2ViewLimitsAndAccounts
                              isUser={isUser}
                              user_id={user_id}
                              permissions={aggregatedPermissions[permGroup.internal_alias] || []}
                              permissionGroup={permGroup}
                            />

                            {/* {!!directPermissions[permGroup.internal_alias] && permGroup.settings.hasLimits ? (
					<Permissions2Limit
					  pgName={permGroup.internal_alias}
					  limits={extractLimits(directPermissions[permGroup.internal_alias])}
					/>
				  ) : null} */}
                          </TableCell>
                        </TableRow>
                      ) : null,
                    )}
                </TableBody>
              </>
            );
          })}
      </Table>
    </TableContainer>
  );
};
