import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import React from "react";

export const Unresolved: React.FC<SvgIconProps> = ({ fill, stroke, ...props }) => {
  return (
    <SvgIcon {...props} width="44" height="44" viewBox="0 0 44 44" fill="none">
      <rect width="44" height="44" rx="22" fill={fill} />
      <path
        stroke={stroke}
        d="M20 2H4c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-7 9h-2V5h2v6zm0 4h-2v-2h2v2z"
        transform="translate(10,10)"
      />
    </SvgIcon>
  );
};
