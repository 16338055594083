import { ErrorContainer } from "./ErrorContainer";

export const NotFound: React.FunctionComponent = () => {
  return (
    <ErrorContainer title="Page not found">
      <p>Oops... seems like you've reached a dead end.</p>
      <p>
        We couldn't find the page you were looking for. This could be because it doesn't exist or you might not have the
        right permissions to view it.
      </p>
      <p>
        If you have an account with us, <a href="/login">logging in</a> might resolve this issue.
      </p>
    </ErrorContainer>
  );
};
