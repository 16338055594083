import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Button } from "@mui/material";
import { VBillAddFullBatchDialog } from "components/pages/common/VBill/VBillAddFullBatchDialog/VBillAddFullBatchDialog";
import React, { useState } from "react";

export const AddFullBatchBtn = () => {
  const [isAddBatchDialogOpen, setIsAddBatchDialogOpen] = useState(false);

  return (
    <>
      <Button variant="contained" onClick={() => setIsAddBatchDialogOpen(true)} startIcon={<AddCircleIcon />}>
        Add Full Batch
      </Button>

      {isAddBatchDialogOpen && <VBillAddFullBatchDialog onDialogClose={() => setIsAddBatchDialogOpen(false)} />}
    </>
  );
};
