import { Dialog, IconButton, Tooltip } from "@mui/material";
import { CloseIcon } from "components/common/icons";
import { IVBillRelativeDatesSelectorProps, VBillRelativeDatesSelector } from "./VBillRelativeDatesSelector";

export const VBillRelativeDatesSelectorDialog = (props: IVBillRelativeDatesSelectorProps) => {
  return (
    <Dialog
      onClose={props.onCloseDialog}
      open
      PaperProps={{ sx: { padding: "15px", position: "relative", width: "670px", maxWidth: "none" } }}
    >
      <Tooltip arrow title="Close">
        <IconButton onClick={props.onCloseDialog} sx={{ position: "absolute", top: "5px", right: "5px", zIndex: 1 }}>
          <CloseIcon sx={{ fontSize: "20px" }} />
        </IconButton>
      </Tooltip>

      <VBillRelativeDatesSelector {...props} />
    </Dialog>
  );
};
