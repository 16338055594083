import { SvgIcon, SvgIconProps } from "@mui/material";

export const ImageIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.75 3.50012C3.33579 3.50012 3 3.83591 3 4.25012V14.7501C3 15.0717 3.20243 15.3461 3.48682 15.4526L11.4697 7.46979C11.7626 7.1769 12.2374 7.1769 12.5303 7.46979L15 9.93946V4.25012C15 3.83591 14.6642 3.50012 14.25 3.50012H3.75ZM16.5 11.7494V4.25012C16.5 3.00748 15.4926 2.00012 14.25 2.00012H3.75C2.50736 2.00012 1.5 3.00748 1.5 4.25012V14.7501C1.5 15.9928 2.50736 17.0001 3.75 17.0001H14.25C15.4926 17.0001 16.5 15.9928 16.5 14.7501V11.7509C16.5 11.7504 16.5 11.7499 16.5 11.7494ZM15 12.0608L12 9.06078L5.56066 15.5001H14.25C14.6642 15.5001 15 15.1643 15 14.7501V12.0608ZM6.375 6.50012C6.16789 6.50012 6 6.66802 6 6.87512C6 7.08223 6.16789 7.25012 6.375 7.25012C6.58211 7.25012 6.75 7.08223 6.75 6.87512C6.75 6.66802 6.58211 6.50012 6.375 6.50012ZM4.5 6.87512C4.5 5.83959 5.33947 5.00012 6.375 5.00012C7.41053 5.00012 8.25 5.83959 8.25 6.87512C8.25 7.91066 7.41053 8.75012 6.375 8.75012C5.33947 8.75012 4.5 7.91066 4.5 6.87512Z"
        fill="#697281"
        stroke="white"
        strokeWidth="0.35"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
