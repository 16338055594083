import { createObservableContainer } from "storeContainer";
import { StoreBase } from "./StoreBase";
import { getSdk, Invite, OrganizationUsersQuery } from "../generated/sdk";
import { definitely, definitelyFilter } from "../generated/utils";
import { graphqlClient } from "../common/graphqlClient";

const { OrganizationUsers, CreateInvite, LeaveOrganization, DeleteInvite, PromoteUnvalidatedUser } =
  getSdk(graphqlClient);

export class OrganizationUsersStore extends StoreBase {
  organizationUsersList = createObservableContainer<NonNullable<OrganizationUsersQuery["OrganizationUsers"]>>();

  inviteUserResponse = createObservableContainer<Invite>();

  async fetchOrganizationUsers(organization_id: string) {
    return definitelyFilter((await OrganizationUsers({ organization_id })).OrganizationUsers);
  }

  async loadOrganizationUsers(forceUpdate?: boolean) {
    const organization_id = this.storeContainer?.ContextStore.selectedOrganizationId;
    if (organization_id) {
      await this.organizationUsersList.cachedLoad(
        () => this.fetchOrganizationUsers(organization_id),
        [organization_id],
        { forceUpdate },
      );
    }
  }

  getOrganizationUsersAccounts() {
    return this.organizationUsersList.data?.map((user) => user);
  }

  getOrganizationUser(organizationUserId: string) {
    this.loadOrganizationUsers();
    return this.organizationUsersList.data?.find((ug) => ug?.id === organizationUserId);
  }

  private async _fetchInviteResponse(email: string, organization_id: string): Promise<Invite | undefined> {
    const response = await CreateInvite({ email, organization_id });
    return definitely(response.CreateInvite);
  }

  async createInvite(email: string) {
    const organization_id = this.storeContainer?.ContextStore.selectedOrganizationId;
    if (organization_id) {
      await this.inviteUserResponse.load(this._fetchInviteResponse(email, organization_id));
      await this.loadOrganizationUsers(true);
    }
  }

  async deleteInvite(invite_id: string) {
    await DeleteInvite({ invite_id });
    await this.loadOrganizationUsers(true);
  }

  async validateUser(organization_user_id: string) {
    await PromoteUnvalidatedUser({ organization_user_id });
    await this.loadOrganizationUsers(true);
  }

  flushInviteResponse() {
    this.inviteUserResponse.flush();
  }

  async leaveOrganization(organization_user_id: string) {
    await LeaveOrganization({ organization_user_id });
    await this.loadOrganizationUsers(true);
  }

  findOrganizationUserById(organization_user_id: string) {
    return this.organizationUsersList._dataObsevable?.find((ou) => ou.id === organization_user_id);
  }
}
