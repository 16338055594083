import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Box, Button, Drawer, Tooltip, Typography } from "@mui/material";
import { formatCurrency } from "common/helpers/utils";
import { observer } from "mobx-react";
import { useMemo } from "react";
import { useStore } from "storeContainer";
import { COLORS } from "themes/default";

export interface IPayBillsForm {
  groups: Array<{ vendor: string; bills: Array<{ paymentAmount: string; invoiceId: number }> }>;
}

interface IPayVBillsDrawerProps {
  onPayBillsBtnClick: () => void;
}

export const PayVBillsDrawer = observer(function PayVBillsDrawer({ onPayBillsBtnClick }: IPayVBillsDrawerProps) {
  const vBillPayLedgerStore = useStore("VBillPayLedgerStore");
  const organizationStore = useStore("OrganizationStore");
  const { selectedBillIdsToPay } = vBillPayLedgerStore;

  const selectedBillsToPay = useMemo(
    () => (vBillPayLedgerStore.billsLedgerList.data?.items ?? []).filter(({ id }) => selectedBillIdsToPay.includes(id)),
    [selectedBillIdsToPay, vBillPayLedgerStore.billsLedgerList.data?.items],
  );
  const youSelectedAmount = useMemo(
    () =>
      formatCurrency(
        selectedBillsToPay.reduce((result, bill) => (result = result + Number(bill.amounts?.amountFreeToPay ?? 0)), 0),
      ),
    [selectedBillsToPay],
  );

  return (
    <Drawer open anchor="bottom" variant="persistent">
      <Box
        sx={{
          width: "100%",
          maxWidth: "1400px",
          margin: "0 auto",
          display: "flex",
          justifyContent: "space-between",
          gap: "15px",
          alignItems: "center",
          padding: "29px 20px",
          marginBottom: "53px",
        }}
      >
        <Button onClick={() => vBillPayLedgerStore.updateSelectedBillIdsToPay([])}>Deselect</Button>

        <Box sx={{ display: "flex", gap: "20px", alignItems: "center" }}>
          <Box sx={{ textAlign: "right" }}>
            <Typography sx={{ fontSize: "20px", fontWeight: "700" }}>
              {selectedBillsToPay.length} {selectedBillsToPay.length === 1 ? "Bill" : "Bills"} selected
            </Typography>

            <Typography sx={{ fontSize: "14px" }}>
              Total: <strong>{youSelectedAmount}</strong>
            </Typography>
          </Box>
          {!organizationStore.organizationVBillPayBtnEnabled ? (
            <Tooltip title="Payment must be enabled in integration">
              <ErrorOutlineIcon sx={{ color: COLORS.orange, fontSize: "24px" }} />
            </Tooltip>
          ) : (
            <Button variant="contained" onClick={onPayBillsBtnClick}>
              Pay {selectedBillsToPay.length === 1 ? "Bill" : "Bills"} ({selectedBillsToPay.length})
            </Button>
          )}
        </Box>
      </Box>
    </Drawer>
  );
});
