import { LoadingButton } from "@mui/lab";
import { Box, Button } from "@mui/material";
import { COLORS } from "themes/default";
import { useMarkAsDuplicate } from "./hooks";
import { IDuplicateModalActionsProps } from "./types";

export const DuplicatesModalSavingActions: React.FunctionComponent<IDuplicateModalActionsProps> = ({
  invoice,
  onClose,
  reloadInvoicesList,
}) => {
  // const { handleFormSubmit, isReqPending } = useUpdateNewVBillForm(invoice, true, true);

  // const saveAction = async () => {
  //   await handleFormSubmit();
  //   onClose();
  // };

  const { isLoading, markAsDuplicate: _markAsDuplicate } = useMarkAsDuplicate(invoice, reloadInvoicesList);
  const markAsDuplicate = async () => {
    await _markAsDuplicate();
    onClose();
  };

  return (
    <Box sx={{ marginTop: "15px", display: "flex", gap: "10px" }}>
      {/* <LoadingButton variant="contained" loading={isReqPending} onClick={saveAction}>
        Save anyway
      </LoadingButton> */}
      <LoadingButton variant="contained" loading={isLoading} onClick={() => markAsDuplicate()}>
        Discard as duplicate
      </LoadingButton>

      <Button
        variant="outlined"
        sx={{
          border: "1px solid rgba(40, 119, 236, 0.25) !important",
          color: COLORS.newSecondaryColor,
          fontWeight: "500",
        }}
        onClick={onClose}
      >
        Edit Coding
      </Button>
    </Box>
  );
};
