import React from "react";
import { NotFoundException, RedirectException } from "../../../common/exceptions/errors";
import { GenericError } from "./GenericError";
import { NotFound } from "./NotFound";

interface IErrorBoundaryState {
  hasError: boolean;
  error?: any;
}

export class ErrorBoundary extends React.Component<any, IErrorBoundaryState> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    window.reportError(error);

    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  render() {
    if (this.state.hasError) {
      if (this.state.error instanceof RedirectException) {
        window.location.href = this.state.error.location;
        return null;
      }

      if (this.state.error instanceof NotFoundException) {
        return <NotFound />;
      }

      return <GenericError />;
    } else {
      return <>{this.props.children}</>;
    }
  }
}
