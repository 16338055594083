import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import { Box, IconButton, Tooltip } from "@mui/material";
import React, { useState } from "react";
import { COLORS } from "themes/default";

interface IVBillCopyTextToClipboardProps {
  textToCopy: string;
}

export const VBillCopyTextToClipboard = ({ textToCopy }: IVBillCopyTextToClipboardProps) => {
  const [copied, setCopied] = useState(false);

  const handleCopyBtnClick = () => {
    window.navigator.clipboard.writeText(textToCopy);
    setCopied(true);
    const timeoutId = setTimeout(() => {
      setCopied(false);
      clearTimeout(timeoutId);
    }, 2500);
  };

  return (
    <Tooltip title={copied ? `Copied: "${textToCopy}"` : `Copy "${textToCopy}" to clipboard`} arrow>
      {copied ? (
        <Box component="span" sx={{ padding: "6.5px 8px" }}>
          <DoneOutlineIcon sx={{ color: COLORS.success }} />
        </Box>
      ) : (
        <IconButton onClick={handleCopyBtnClick}>
          <ContentCopyIcon />
        </IconButton>
      )}
    </Tooltip>
  );
};
