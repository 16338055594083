import { Box, Button, Dialog, FormControlLabel, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { format } from "date-fns";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import { useStore } from "storeContainer";

const weekOfDayBtnStyles = {
  padding: "7px 10px",
  height: "32px",
  width: "32px",
  minWidth: "auto",
  borderRadius: "20px",
};

interface IRecurringBatchCustomPickerProps {
  onCloseDialog: () => void;
  currentDateSelected?: string | null;
}

const timeRanges = ["day", "week", "month", "year"] as const;
const dayRanges = ["mo", "tu", "we", "th", "fr", "sa", "su"] as const;

export const RecurringBatchCustomPicker = observer(function RecurringBatchCustomPicker({
  onCloseDialog,
  currentDateSelected,
}: IRecurringBatchCustomPickerProps) {
  const vBillBatchDetailsStore = useStore("VBillBatchDetailsStore");

  const [selectedTimeRange, setSelectedTimeRange] = useState<(typeof timeRanges)[number]>(timeRanges[0]);
  const [everyInputValue, setEveryInputValue] = useState("1");
  const [selectedDayRange, setSelectedDayRange] = useState<(typeof dayRanges)[number]>(dayRanges[0]);
  const [monthlyValue, setMonthlyValue] = useState<number>(0);

  const [ends, setEnds] = useState("never");

  useEffect(() => {
    // if (range === "week") {
    //   setIsWeekOfDayPickerVisible(true);
    //   setWeekOfDay(dayRanges[0]);
    // } else {
    //   setIsWeekOfDayPickerVisible(false);
    // }
  }, [selectedTimeRange]);

  const { batchDetails } = vBillBatchDetailsStore;
  const batchData = batchDetails.data;

  return (
    <Dialog open onClose={onCloseDialog}>
      <Box sx={{ padding: "20px" }}>
        <Typography sx={{ whiteSpace: "nowrap", fontSize: "20px", margin: "0 0 30px" }}>Custom recurrence</Typography>

        <Box sx={{ display: "flex", alignItems: "center", gap: "10px", marginBottom: "20px" }}>
          <Typography sx={{ whiteSpace: "nowrap" }}>Repeat every</Typography>

          <NumericFormat
            allowNegative={false}
            placeholder="Every:"
            value={everyInputValue}
            onValueChange={(values) => setEveryInputValue(values.value)}
            decimalScale={0}
            customInput={TextField}
          />

          <FormControl fullWidth>
            <InputLabel id="range-select-label">Range</InputLabel>
            <Select
              labelId="range-select-label"
              id="range-select"
              value={selectedTimeRange}
              label="Range"
              onChange={(e) => setSelectedTimeRange(e.target.value as (typeof timeRanges)[number])}
            >
              {timeRanges.map((range) => (
                <MenuItem key={range} value={range}>
                  {range}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        {selectedTimeRange === "week" && (
          <Box sx={{ marginBottom: "20px" }}>
            <Typography sx={{ whiteSpace: "nowrap", marginBottom: "10px" }}>Repeat on</Typography>
            <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
              {dayRanges.map((day) => (
                <Button
                  key={day}
                  sx={weekOfDayBtnStyles}
                  onClick={() => setSelectedDayRange(day)}
                  variant={selectedDayRange === day ? "contained" : "outlined"}
                >
                  {day.charAt(0).toLowerCase()}
                </Button>
              ))}
            </Box>
          </Box>
        )}

        {selectedTimeRange === "month" && (
          <Select
            labelId="month-select-label"
            id="month-selectselect"
            value={monthlyValue}
            onChange={(e) => setMonthlyValue(Number(e.target.value))}
          >
            <MenuItem value={0}>
              Monthly on day {format(currentDateSelected ? new Date(currentDateSelected) : new Date(), "d")}
            </MenuItem>

            <MenuItem value={1}>
              Monthly on the last {format(currentDateSelected ? new Date(currentDateSelected) : new Date(), "EEEE")}
            </MenuItem>
          </Select>
        )}

        <Typography sx={{ marginTop: "20px" }}>Ends</Typography>

        <FormControl>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={ends}
            onChange={(e) => setEnds(e.target.value)}
          >
            <FormControlLabel value="never" control={<Radio color="secondary" />} label="Never" />
            <FormControlLabel value="on" control={<Radio color="secondary" />} label="On" />
            <FormControlLabel value="after" control={<Radio color="secondary" />} label="After" />
          </RadioGroup>
        </FormControl>

        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: "10px", marginTop: "20px" }}>
          <Button variant="contained" onClick={() => onCloseDialog()} sx={{ height: "32px" }}>
            Save
          </Button>
          <Button variant="outlined" onClick={() => onCloseDialog()} sx={{ height: "32px" }}>
            Cancel
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
});
