import { Box } from "@mui/material";
import { VBillAdditionalMappingsFilter } from "components/pages/common/VBill/FilterComponents/VBillAdditionalMappingsFilter/VBillAdditionalMappingsFilter";
import { VBillAmountFilter } from "components/pages/common/VBill/FilterComponents/VBillAmountFilter/VBillAmountFilter";
import { VBillSearchFilter } from "components/pages/common/VBill/FilterComponents/VBillSearchFilter/VBillSearchFilter";
import {
  IStatusOption,
  VBillStatusFilter,
} from "components/pages/common/VBill/FilterComponents/VBillStatusFilter/VBillStatusFilter";
import {
  friendlyInvoiceActionRequiredStatusNames,
  friendlyInvoiceQuickFilterStatusNames,
} from "components/pages/common/VBill/utils";
import {
  IVBillBillsFiltersMappingInput,
  IVBillInvoiceActionRequiredStatus,
  IVBillInvoiceQuickFilterStatus,
  IVBillStoreIoDataType,
} from "generated/sdk.vbill";
import { isNil } from "lodash";
import { observer } from "mobx-react";
import { useMemo } from "react";
import { useStore } from "storeContainer";
import { useCodingLedgerFilters, useVBillCodingLedgerChildRoute } from "../hooks";
import { CodingDateFilter } from "./CodingDateFilter/CodingDateFilter";
import { CodingSyncBtn } from "./CodingSyncBtn/CodingSyncBtn";

const codingStatusFilterOptions: IStatusOption[] = Object.values(IVBillInvoiceQuickFilterStatus)
  .filter((group) => group === IVBillInvoiceQuickFilterStatus.New || group === IVBillInvoiceQuickFilterStatus.Mapped)
  .map((value) => ({
    label: friendlyInvoiceQuickFilterStatusNames[value],
    value,
  }));
const actionsRequiredStatusFilterOptions: IStatusOption[] = Object.values(IVBillInvoiceActionRequiredStatus)
  .filter((value) => value !== IVBillInvoiceActionRequiredStatus.None)
  .map((value) => ({
    label: friendlyInvoiceActionRequiredStatusNames[value],
    value,
  }));
const completedStatusFilterOptions: IStatusOption[] = Object.values(IVBillInvoiceQuickFilterStatus)
  .filter(
    (group) =>
      group === IVBillInvoiceQuickFilterStatus.PushDone ||
      group === IVBillInvoiceQuickFilterStatus.PushPending ||
      group === IVBillInvoiceQuickFilterStatus.Declined,
  )
  .map((value) => ({
    label: friendlyInvoiceQuickFilterStatusNames[value],
    value,
  }));

export const CodingListFilters = observer(() => {
  const vBillCodingLedgerStore = useStore("VBillCodingLedgerStore");
  const [codingLedgerFilters, setCodingLedgerFilters] = useCodingLedgerFilters();
  const { isCodingChildRoute, isActionRequiredChildRoute, isCompletedChildRoute, isPendingReviewChildRoute } =
    useVBillCodingLedgerChildRoute();
  const { companiesSettings } = vBillCodingLedgerStore;
  const vendorAdditionalMapping = useMemo(
    () => companiesSettings.data?.invoice.find(({ isContact }) => isContact),
    [companiesSettings.data?.invoice],
  );

  const handleCodingStatusFilterChange = (value: string, checked?: boolean | undefined) => {
    setCodingLedgerFilters((prev) => {
      const quickFilterStatusListValues = checked
        ? [...(prev.filters?.quickFilterStatusList ?? []), value]
        : [...(prev.filters?.quickFilterStatusList ?? []).filter((val) => val !== value)];

      return {
        filters: {
          ...prev.filters,
          invoice_number_sort: undefined,
          invoice_id_sort: undefined,
          amount_sort: undefined,
          dates_sort: undefined,
          quickFilterStatusList: quickFilterStatusListValues.length
            ? (quickFilterStatusListValues as IVBillInvoiceQuickFilterStatus[])
            : undefined,
        },
        pagination: { page: 1, per_page: prev.pagination.per_page },
      };
    });
  };

  const handleActionsRequiredStatusFilterChange = (value: string, checked?: boolean | undefined) => {
    setCodingLedgerFilters((prev) => {
      const actionRequiredStatusList = checked
        ? [...(prev.filters?.actionRequiredStatusList ?? []), value]
        : [...(prev.filters?.actionRequiredStatusList ?? []).filter((val) => val !== value)];

      return {
        filters: {
          ...prev.filters,
          invoice_number_sort: undefined,
          invoice_id_sort: undefined,
          amount_sort: undefined,
          dates_sort: undefined,
          actionRequiredStatusList: actionRequiredStatusList.length
            ? (actionRequiredStatusList as IVBillInvoiceActionRequiredStatus[])
            : undefined,
        },
        pagination: { page: 1, per_page: prev.pagination.per_page },
      };
    });
  };

  const handleCompletedStatusFilterChange = (value: string, checked?: boolean | undefined) => {
    setCodingLedgerFilters((prev) => {
      const quickFilterStatusListValues = checked
        ? [...(prev.filters?.quickFilterStatusList ?? []), value]
        : [...(prev.filters?.quickFilterStatusList ?? []).filter((val) => val !== value)];

      return {
        filters: {
          ...prev.filters,
          invoice_number_sort: undefined,
          invoice_id_sort: undefined,
          amount_sort: undefined,
          dates_sort: undefined,
          quickFilterStatusList: quickFilterStatusListValues.length
            ? (quickFilterStatusListValues as IVBillInvoiceQuickFilterStatus[])
            : undefined,
        },
        pagination: { page: 1, per_page: prev.pagination.per_page },
      };
    });
  };

  const handleAdditionalMappingChange = async (value: IVBillBillsFiltersMappingInput) => {
    const currentMappings = codingLedgerFilters.filters?.mappings ?? [];
    const newMappings = currentMappings.find(({ key }) => key === value.key)
      ? currentMappings.map((mapping) => (mapping.key === value.key ? value : mapping))
      : [...currentMappings, value];
    const newMappingsExcludeEmptyValuesIn = newMappings.filter(({ valuesIn }) => valuesIn.length > 0);

    setCodingLedgerFilters((prev) => ({
      filters: {
        ...prev.filters,
        invoice_number_sort: undefined,
        invoice_id_sort: undefined,
        amount_sort: undefined,
        dates_sort: undefined,
        mappings: newMappingsExcludeEmptyValuesIn,
      },
      pagination: { page: 1, per_page: prev.pagination.per_page },
    }));
  };

  return (
    <Box
      sx={{ display: "flex", justifyContent: "space-between", gap: "10px", padding: "10px 0", alignItems: "center" }}
    >
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: "10px", alignItems: "center" }}>
        <VBillSearchFilter
          term={codingLedgerFilters.filters?.search}
          onTermChange={(term) => {
            setCodingLedgerFilters((prev) => ({
              filters: {
                invoice_number_sort: undefined,
                invoice_id_sort: undefined,
                amount_sort: undefined,
                dates_sort: undefined,
                search: term?.length ? term : undefined,
              },
              pagination: { page: 1, per_page: prev.pagination.per_page },
            }));
          }}
          placeholder="Search Bill#, Vendor"
        />

        {!isPendingReviewChildRoute && vendorAdditionalMapping && (
          <VBillAdditionalMappingsFilter
            storeType={vendorAdditionalMapping.storeDataType as unknown as IVBillStoreIoDataType}
            storeKey={vendorAdditionalMapping.key}
            // batchCompanyIds={paymentLedgerFilters.filters?.companyIds ?? []}
            batchCompanyIds={[]}
            searchPlaceholder="Search Vendor"
            inputLabel="Vendor"
            mapping={codingLedgerFilters.filters?.mappings?.find(
              (mapping) => mapping.key === vendorAdditionalMapping.key,
            )}
            onMappingChange={handleAdditionalMappingChange}
            actionsBtnSmallHeight
          />
        )}

        {isCodingChildRoute && (
          <VBillStatusFilter
            status={codingLedgerFilters.filters?.quickFilterStatusList}
            multiSelect
            onStatusChange={handleCodingStatusFilterChange}
            options={codingStatusFilterOptions}
          />
        )}

        {isActionRequiredChildRoute && (
          <VBillStatusFilter
            status={codingLedgerFilters.filters?.actionRequiredStatusList}
            multiSelect
            onStatusChange={handleActionsRequiredStatusFilterChange}
            options={actionsRequiredStatusFilterOptions}
          />
        )}

        {isCompletedChildRoute && (
          <VBillStatusFilter
            status={codingLedgerFilters.filters?.quickFilterStatusList}
            multiSelect
            onStatusChange={handleCompletedStatusFilterChange}
            options={completedStatusFilterOptions}
          />
        )}

        <VBillAmountFilter
          amount={{
            amount_from: !isNil(codingLedgerFilters.filters?.amount_from)
              ? `${codingLedgerFilters.filters.amount_from}`
              : undefined,
            amount_to: !isNil(codingLedgerFilters.filters?.amount_to)
              ? `${codingLedgerFilters.filters.amount_to}`
              : undefined,
          }}
          onAmountChange={({ amount_from, amount_to }) =>
            setCodingLedgerFilters((prev) => ({
              filters: {
                ...prev.filters,
                amount_sort: undefined,
                vbill_number_sort: undefined,
                amount_from: Number(amount_from),
                amount_to: Number(amount_to),
              },
              pagination: { page: 1, per_page: prev.pagination.per_page },
            }))
          }
          actionsBtnSmallHeight
        />

        <CodingDateFilter />
      </Box>

      <CodingSyncBtn />
    </Box>
  );
});
