import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import Card from "components/common/Card";
import { Spinner } from "components/common/Spinner/Spinner";
import { observer } from "mobx-react-lite";
import { CompanyDetailsQuery } from "../../../../../generated/sdk";

export const CompanyDetailsCard: React.FunctionComponent<{
  isLoading: boolean;
  companyDetails: CompanyDetailsQuery["CompanyDetails"];
}> = observer(function CompanyDetailsCard({ isLoading, companyDetails }) {
  if (isLoading) {
    return (
      <Card
        sx={{ mt: 4 }}
        title={
          <Typography sx={{ fontSize: "18px" }} variant="h5">
            Company Details
          </Typography>
        }
        headerProps={{
          sx: { mb: 0, pb: 0 },
        }}
      >
        <Spinner />
      </Card>
    );
  }

  if (companyDetails) {
    return (
      <Card
        title={<Typography variant="h5">Company Details</Typography>}
        sx={{ overflow: "visible", marginTop: "20px" }}
      >
        <Typography variant="subtitle2">{companyDetails.name}</Typography>
        <Typography variant="body2">{companyDetails.phone_number}</Typography>
        <Typography variant="body2" display="flex" gap="8px">
          {companyDetails?.physical_address?.address} {companyDetails?.physical_address?.address2}{" "}
          {companyDetails?.physical_address?.city} {companyDetails?.physical_address?.state}{" "}
          {companyDetails?.physical_address?.zip_code} {companyDetails?.physical_address?.country}
        </Typography>
        <Typography variant="body2">{companyDetails.website_url}</Typography>
      </Card>
    );
  }

  return (
    <Card
      sx={{ mt: 4 }}
      title={
        <Typography sx={{ fontSize: "18px" }} variant="h5">
          Company Details
        </Typography>
      }
    >
      <Typography variant="subtitle2">No Data</Typography>
    </Card>
  );
});
