import CloseIcon from "@mui/icons-material/Close";
import { Dialog, IconButton, Tooltip, Typography } from "@mui/material";
import { graphqlVBillClient } from "common/graphqlClient";
import { IVBillSingleSelectAutocompleteSuggestion } from "components/pages/common/VBill/VBillSuggestionsAutocomplete/VBillSuggestionsSingleSelectAutocomplete/VBillSuggestionsSingleSelectAutocomplete";
import {
  getSdk,
  IVBillIIntegrationIntegrationSettingsVBillAdditionalMappingsEntryShowIn,
  IVBillStoreIoDataType,
  IVBillPayableInvoiceInput,
} from "generated/sdk.vbill";
import { groupBy } from "lodash";
import { observer } from "mobx-react";
import { useEffect, useMemo, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useParams } from "react-router";
import { useStore } from "storeContainer";
import { PayBillsGroups } from "./PayBillsGroups/PayBillsGroups";
import { usePayVBillsCompaniesMappingsSettings } from "./PayVBillsCompaniesMappingsSettings";
import { PayVBillsTotalAndSubmit } from "./PayVBillsTotalAndSubmit/PayVBillsTotalAndSubmit";

const { VBillCreateBillsPayments } = getSdk(graphqlVBillClient);

export interface IPayVBillsFormFields {
  groups: {
    vendorName: string;
    companyId: string;
    paymentFromGlAccount?: IVBillSingleSelectAutocompleteSuggestion; // keep this here for group bills mapping, just for decoration
    bills: {
      billId: number;
      amount?: string;
      memo?: string;
      paymentFromGlAccount?: IVBillSingleSelectAutocompleteSuggestion;
    }[];
  }[];
}

export interface IPayVBillsDialogProps {
  onCloseDialog: () => void;
}

export const PayVBillsDialog = observer(function PayVBillsDialog({ onCloseDialog }: IPayVBillsDialogProps) {
  const vBillPayLedgerStore = useStore("VBillPayLedgerStore");
  const { organizationId } = useParams<{ organizationId: string }>();
  const payVBillsForm = useForm<IPayVBillsFormFields>();
  const [isSubmitReqLoading, setIsSubmitReqLoading] = useState(false);
  const [hasSubmitReqError, setHasSubmitReqError] = useState(false);
  const payVBillsCompaniesMappingsSettings = usePayVBillsCompaniesMappingsSettings();

  const { selectedBillIdsToPay, companiesSettings, billsLedgerList } = vBillPayLedgerStore;
  const additionalMappingsSettingsData = companiesSettings.data?.invoice;

  const billsByVendorAndCompanyGroups = useMemo(() => {
    const vendorMappingKey = additionalMappingsSettingsData?.find(({ isContact }) => isContact)?.key;
    const selectedBillsToPay = (billsLedgerList.data?.items ?? []).filter(({ id }) =>
      selectedBillIdsToPay.includes(id),
    );
    const billsGroupedByVendorMapping = groupBy(selectedBillsToPay, ({ additionalMappings, companyId }) => {
      const vendorMapping = additionalMappings?.find(({ key }) => key === vendorMappingKey);

      return `${vendorMapping?.label ?? ""}${vendorMapping?.label2 ? ` - ${vendorMapping?.label2}` : ""}__${companyId}`;
    });
    const formFields: IPayVBillsFormFields["groups"] = Object.keys(billsGroupedByVendorMapping).map((key) => ({
      vendorName: key.split("__")[0] ?? "",
      companyId: key.split("__")[1] ?? "",
      bills: billsGroupedByVendorMapping[key].map(({ id, amounts }) => ({
        billId: id,
        amount: amounts?.amountFreeToPay ?? "0",
      })),
    }));

    return formFields;
    // selectedBillIdsToPay just on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [additionalMappingsSettingsData, billsLedgerList.data?.items]);

  useEffect(() => {
    payVBillsForm.reset({ groups: billsByVendorAndCompanyGroups });
  }, [billsByVendorAndCompanyGroups, payVBillsForm]);

  const handlePayDialogSubmit: SubmitHandler<IPayVBillsFormFields> = async (data) => {
    setIsSubmitReqLoading(true);
    setHasSubmitReqError(false);

    const payableBills = data.groups.reduce<IVBillPayableInvoiceInput[]>((result, group) => {
      const companiesMappingsSettings = payVBillsCompaniesMappingsSettings.data
        ?.get(group.companyId)
        ?.find(
          ({ showIn }) => showIn === IVBillIIntegrationIntegrationSettingsVBillAdditionalMappingsEntryShowIn.Payment,
        );
      result = [
        ...result,
        ...group.bills.map((bill) => ({
          invoiceId: bill.billId,
          memo: bill.memo?.length ? bill.memo : undefined,
          paymentAmount: bill.amount?.length ? bill.amount : undefined,
          ...(companiesMappingsSettings && bill.paymentFromGlAccount
            ? {
                paymentFromGlAccount: {
                  settingsKey: companiesMappingsSettings.key,
                  storeType: companiesMappingsSettings.storeDataType as unknown as IVBillStoreIoDataType,
                  key: bill.paymentFromGlAccount.id,
                },
              }
            : {}),
        })),
      ];

      return result;
    }, []);

    try {
      await VBillCreateBillsPayments({ organizationId, payableBills, grouping: { byPayerAndPayee: true } });
      vBillPayLedgerStore.updateSelectedBillIdsToPay([]);
      onCloseDialog();
      vBillPayLedgerStore.billsLedgerList.reload?.({ markAsLoading: true });
    } catch (_err) {
      setHasSubmitReqError(true);
    } finally {
      setIsSubmitReqLoading(false);
    }
  };

  return (
    <Dialog open onClose={onCloseDialog} PaperProps={{ sx: { padding: "15px 15px 0", maxWidth: "none" } }}>
      <Tooltip arrow title="Close">
        <IconButton onClick={onCloseDialog} sx={{ position: "absolute", top: "5px", right: "5px", zIndex: 1 }}>
          <CloseIcon sx={{ fontSize: "20px" }} />
        </IconButton>
      </Tooltip>

      <Typography sx={{ fontSize: "20px", marginBottom: "20px" }}>Pay bills</Typography>

      <form onSubmit={payVBillsForm.handleSubmit(handlePayDialogSubmit)}>
        <FormProvider {...payVBillsForm}>
          <PayBillsGroups onCloseDialog={onCloseDialog} />

          <PayVBillsTotalAndSubmit
            onCancelBtnClick={onCloseDialog}
            payBtnLoading={isSubmitReqLoading}
            hasSubmitReqError={hasSubmitReqError}
          />
        </FormProvider>
      </form>
    </Dialog>
  );
});
