import { memo } from "react";
import classnames from "classnames";
import styles from "./MappingHeader.module.scss";
import { IMappingHeaderProps } from "./types";
import { Add, Check, Remove } from "../../../../../common/icons";

const MappingHeader = ({
  className,
  expanded,
  title,
  spacingClass,
  onExpandToggle,
  mapped,
  unmapped,
  ...rest
}: IMappingHeaderProps) => {
  return (
    <div className={classnames(styles.header, className, spacingClass)} {...rest} onClick={onExpandToggle}>
      <div className={styles.infoContainer}>
        <div className={styles.expandIcon}>{expanded ? <Remove /> : <Add />}</div>
        {title && <div className={styles.title}>{title}</div>}
      </div>
      {unmapped === 0 ? (
        <Check className={styles.checkIcon} />
      ) : (
        <span className={styles.unmappedContainer}>{unmapped} unmapped</span>
      )}
    </div>
  );
};

export default memo(MappingHeader);
