import { Divider, Skeleton } from "@mui/material";
import { IVBillIIntegrationIntegrationSettingsVBillAdditionalMappingsEntryShowIn } from "generated/sdk.vbill";
import { observer } from "mobx-react";
import { useMemo } from "react";
import { useStore } from "storeContainer";
import { InvoiceMappingField } from "../../common/Forms/InvoiceMappingField/InvoiceMappingField";

export const NewSelectVendorMapping = observer(() => {
  const vBillStore = useStore("VBillStore");
  const invoiceVendorMapping = useMemo(
    () =>
      (vBillStore.settings.data?.vBill.additionalMappings.invoice ?? []).find(
        (additionalMapping) =>
          additionalMapping.showIn !==
            IVBillIIntegrationIntegrationSettingsVBillAdditionalMappingsEntryShowIn.Payment &&
          additionalMapping.showIn !== IVBillIIntegrationIntegrationSettingsVBillAdditionalMappingsEntryShowIn.None &&
          additionalMapping.isContact,
      ),
    [vBillStore.settings.data?.vBill.additionalMappings.invoice],
  );
  const invoiceVendorTitle = useMemo(
    () => `${vBillStore.settings.data?.info?.type ? `${vBillStore.settings.data.info.type} ` : " "}VENDOR`,
    [vBillStore.settings.data?.info?.type],
  );
  const invoiceData = useMemo(() => vBillStore.invoice.data, [vBillStore.invoice.data]);
  const hasCompanyId = useMemo(() => !!invoiceData?.companyId, [invoiceData?.companyId]);
  const showSelectVendorMappingSkeleton = useMemo(
    () => hasCompanyId && vBillStore.settings.isLoading,
    [hasCompanyId, vBillStore.settings.isLoading],
  );

  return (
    <>
      {showSelectVendorMappingSkeleton ? (
        <Skeleton variant="rounded" sx={{ height: "80px" }} />
      ) : (
        invoiceVendorMapping &&
        invoiceData && (
          <>
            <InvoiceMappingField
              additionalMapping={invoiceVendorMapping}
              // TODO: v2
              // suggestion={suggestions?.additionalMapping?.find(
              //   (addMapSuggestion) =>
              //     addMapSuggestion.type ===
              //     (additionalMapping.storeDataType as unknown as IVBillStoreIoDataType)
              // )}
              invoice={invoiceData}
              title={invoiceVendorTitle}
            />

            <Divider sx={{ margin: "10px 0" }} />
          </>
        )
      )}
    </>
  );
});
