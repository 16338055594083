import { useStore } from "storeContainer";
import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
export const IntegrationBankAccountMappingModal = ({
  currentGlAccount,
  companyId,
  integrationId,
  closeModal,
}: {
  currentGlAccount: any;
  companyId: string;
  integrationId: string;
  closeModal: Function;
  defaultExpanded?: Boolean;
}) => {
  const integrationsStore = useStore("IntegrationsStore");

  let bankAccounts = integrationsStore.companyIntegrationsList.data?.bank_accounts;
  let generalLedgerAccounts = integrationsStore.generalLedgerAccountsList.data;
  const [GlToBeMapped, setGlToBeMapped] = useState<any>();
  const [bankAccountToBeMapped, setBankAccountToBeMapped] = useState<string>();
  // FIXME: this is VERY inefficient

  const saveAction = async () => {
    try {
      if (bankAccountToBeMapped && GlToBeMapped)
        await integrationsStore.setBankAccountGlAccountMapping(bankAccountToBeMapped, GlToBeMapped.id);
      closeModal();
    } catch (e: any) {
      console.error(e.response.errors?.message);
    }
  };
  const bankAccountsWithGl =
    bankAccounts?.map((ba) => ({
      ...ba,
      options: generalLedgerAccounts || [],
      readOnly: false,
    })) || [];
  const showGL = () => {
    //TODO may move this into store logic
    bankAccountsWithGl.forEach((b) => {
      b.options?.forEach((gl) => {
        if (gl?.external_ref === currentGlAccount?.external_ref) setGlToBeMapped(gl);
      });
    });
  };
  const changeBankAccountSelected = (e: any) => {
    setBankAccountToBeMapped(e.target.value);
  };
  useEffect(() => {
    showGL();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bankAccountsWithGl]);
  return (
    <>
      <Grid width={450} container alignItems={"center"} justifyContent={"center"}>
        <Grid xs={12} item padding={2}>
          <Typography lineHeight={1} textAlign={"center"} fontSize={25}>
            Map GL Account: {GlToBeMapped?.name}
          </Typography>
        </Grid>
        <Grid paddingLeft={1} paddingBottom={0.5} xs={12} item>
          <Typography fontSize={12} marginTop={1} color="GrayText">
            Bank Accounts:
          </Typography>
        </Grid>
      </Grid>
      <Grid>
        <FormControl sx={{ marginLeft: "15px" }} fullWidth>
          <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
            {bankAccountsWithGl.map((bank_account: any) => {
              return (
                <>
                  <FormControlLabel
                    sx={{
                      border: "1px solid #CADBE2",
                      borderRadius: "5px",
                      padding: "5px 10px",
                      marginBottom: "5px",
                    }}
                    value={bank_account.id}
                    control={<Radio />}
                    onChange={changeBankAccountSelected}
                    label={bank_account.name + "*" + bank_account.account_number}
                  />
                </>
              );
            })}
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid justifyContent={"space-around"} sx={{ marginTop: "10px" }} container>
        <Link
          style={{ textDecoration: "none" }}
          to={`/org/${Cookies.get("lastOrg")}/company/${companyId}/integrations/${integrationId}`}
        >
          <Button sx={{ padding: "10px" }} variant="contained">
            Go To Mapping
          </Button>
        </Link>
        <Grid>
          <Button
            sx={{ padding: "10px 20px" }}
            onClick={() => {
              closeModal();
            }}
            variant="text"
          >
            Cancel
          </Button>
          <Button sx={{ padding: "10px 20px", marginLeft: "30px" }} onClick={saveAction} variant="contained">
            Save
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
