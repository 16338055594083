import { graphqlVBillClient } from "common/graphqlClient";
import { getSdk, IVBillBillsFiltersMappingInput, IVBillStoreIoDataType } from "generated/sdk.vbill";
import { debounce } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import { useUpdateEffect } from "react-use";
import { IVBillMultiSelectAutocompleteSuggestion } from "../../../VBillSuggestionsAutocomplete/VBillSuggestionsMultiSelectAutocomplete/VBillSuggestionsMultiSelectAutocomplete";
import {
  TVBillSuggestionsMultiSelectAutocompleteConditionalComp,
  VBillSuggestionsMultiSelectAutocompleteComp,
} from "../../../VBillSuggestionsAutocomplete/VBillSuggestionsMultiSelectAutocomplete/VBillSuggestionsMultiSelectAutocompleteComp";

const { VBillStoreItemsForOrganization } = getSdk(graphqlVBillClient);

export type ISuggestionWithSource = IVBillMultiSelectAutocompleteSuggestion & { source: string };

export type IAdditionalMappingsDialogProps = TVBillSuggestionsMultiSelectAutocompleteConditionalComp & {
  storeType: IVBillStoreIoDataType;
  storeKey: string;
  batchCompanyIds?: string[] | null;
  searchPlaceholder: string;
  mapping?: IVBillBillsFiltersMappingInput;
  onMappingSave: (value: IVBillBillsFiltersMappingInput) => Promise<void>;
  actionsBtnSmallHeight?: boolean;
  onCloseDialog: () => void;
  dialogTitle?: string;
};

export const AdditionalMappingsDialog = ({
  storeType,
  storeKey,
  batchCompanyIds,
  searchPlaceholder,
  mapping,
  actionsBtnSmallHeight,
  onCloseDialog,
  onMappingSave,
  dialogTitle,
  ...props
}: IAdditionalMappingsDialogProps) => {
  const { organizationId } = useParams<{ organizationId: string }>();
  const [searchTerm, setSearchTerm] = useState("");
  const [isStoreItemsFetching, setIsStoreItemsFetching] = useState(false);
  const [searchedStoreItems, setSearchedStoreItems] = useState<ISuggestionWithSource[]>([]);
  const [selectedStoreItems, setSelectedStoreItems] = useState<ISuggestionWithSource[]>([]);
  const [autocompleteShowMoreBtnVisible, setAutocompleteShowMoreBtnVisible] = useState(false);
  const [storeItemsOffset, setStoreItemsOffset] = useState(0);

  useEffect(() => {
    fetchSelectedStoreItems(mapping, batchCompanyIds);
    fetchSearchedStoreItems("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapping, batchCompanyIds]);

  const fetchSelectedStoreItems = async (
    mapping?: IVBillBillsFiltersMappingInput,
    batchCompanyIds?: string[] | null,
  ) => {
    const keySourcePairs =
      mapping?.valuesIn?.map(({ source, value }) => ({ key: value ?? "", source: source ?? "" })) ?? undefined;

    if (!keySourcePairs?.length) {
      return;
    }

    setIsStoreItemsFetching(true);

    const storeItemsResp = await VBillStoreItemsForOrganization({
      type: storeType,
      organizationId,
      companyIds: batchCompanyIds?.length ? batchCompanyIds : undefined,
      keySourcePairs,
      limit: 20,
      offset: 0,
    });

    if (storeItemsResp.storeItemsForOrganization) {
      const storeItems = storeItemsResp.storeItemsForOrganization.map(({ key, label, source }) => ({
        id: key,
        name: label ?? "",
        source: source ?? "",
        selected: true,
      }));

      setSelectedStoreItems(storeItems);
    }

    setIsStoreItemsFetching(false);
  };

  const fetchSearchedStoreItems = async (searchValue: string, offset: number = 0) => {
    setIsStoreItemsFetching(true);
    setAutocompleteShowMoreBtnVisible(false);
    setStoreItemsOffset(offset);

    const storeItemsResp = await VBillStoreItemsForOrganization({
      type: storeType,
      organizationId,
      companyIds: batchCompanyIds?.length ? batchCompanyIds : undefined,
      search: `${searchValue}%`,
      limit: 20,
      offset: offset,
    });

    if (storeItemsResp.storeItemsForOrganization) {
      const storeItems = storeItemsResp.storeItemsForOrganization.map(({ key, label, source }) => ({
        id: key,
        name: label ?? "",
        source: source ?? "",
      }));

      setSearchedStoreItems((prev) => (offset ? [...prev, ...storeItems] : storeItems));
      setAutocompleteShowMoreBtnVisible(storeItems.length === 20);
    }

    setIsStoreItemsFetching(false);
  };

  useUpdateEffect(() => {
    debouncedFetchStoreItems(searchTerm);
  }, [searchTerm]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetchStoreItems = useCallback(debounce(fetchSearchedStoreItems, 500), []);
  const autocompleteSuggestions = useMemo(
    () => [
      ...selectedStoreItems,
      ...searchedStoreItems.filter(({ id }) => !selectedStoreItems.find((selected) => selected.id === id)),
    ],
    [searchedStoreItems, selectedStoreItems],
  );

  const handleAutocompleteOptionChange = (suggestion: IVBillMultiSelectAutocompleteSuggestion, value: boolean) => {
    setSelectedStoreItems((prev) => {
      if (value) {
        const newSelectedOption = searchedStoreItems.find((option) => option.id === suggestion.id);

        return [...prev, ...(newSelectedOption ? [{ ...newSelectedOption, selected: true }] : [])];
      }
      return prev.filter((option) => option.id !== suggestion.id);
    });
  };

  const handleSaveDialog = async () => {
    await onMappingSave({
      key: storeKey,
      valuesIn: selectedStoreItems.map(({ source, id }) => ({ source, value: id })),
    });
    onCloseDialog();
  };

  const handleAutocompleteShowMoreBtnClick = () => {
    fetchSearchedStoreItems(searchTerm, storeItemsOffset + 20);
  };

  return (
    <VBillSuggestionsMultiSelectAutocompleteComp
      title={dialogTitle}
      suggestions={autocompleteSuggestions}
      onSuggestionChange={handleAutocompleteOptionChange}
      onClose={onCloseDialog}
      onSave={handleSaveDialog}
      onSearchTermChange={setSearchTerm}
      searchPlaceholder={searchPlaceholder}
      showSearchLoader={isStoreItemsFetching}
      actionBtnsSmallHeight={actionsBtnSmallHeight}
      showMoreBtnVisible={autocompleteShowMoreBtnVisible}
      onShowMoreBtnClick={handleAutocompleteShowMoreBtnClick}
      showSelectedGroup
      {...props}
    />
  );
};
