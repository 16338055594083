import { Box } from "@mui/material";
import { SidebarDraggableSeparator } from "components/common/SidebarDraggableSeparator/SidebarDraggableSeparator";
import React, { FunctionComponent, useRef } from "react";
import { useResizable } from "react-resizable-layout";
import { ISidebarTabsRef, SidebarTabs } from "./SidebarTabs/SidebarTabs";

export const CheckBatchDetailsSidebar: FunctionComponent = () => {
  const {
    isDragging: isResizableDragging,
    position: resizableWidth,
    separatorProps: resizableSeparatorProps,
  } = useResizable({
    axis: "x",
    initial: 400,
    min: 300,
    max: 800,
    reverse: true,
    onResizeEnd: handleResizableDragEnd,
  });
  const sidebarTabsRef = useRef<ISidebarTabsRef>(null);

  function handleResizableDragEnd() {
    sidebarTabsRef.current?.tabsUpdateIndicator();
  }

  return (
    <>
      <SidebarDraggableSeparator isDragging={isResizableDragging} {...resizableSeparatorProps} />

      <Box
        sx={{
          flexGrow: 0,
          flexShrink: 0,
          flexBasis: "auto",
          ...(isResizableDragging ? { filter: "blur(5px)" } : {}),
        }}
        style={{ width: resizableWidth }}
      >
        <SidebarTabs ref={sidebarTabsRef} />
      </Box>
    </>
  );
};
