import { Box } from "@mui/material";
import { useMemo } from "react";
import { useStore } from "../../../../../storeContainer";
import { Spinner } from "../../../../common/Spinner/Spinner";
import styles from "./Integration.module.scss";
import { IntegrationUserAndBankAccountMappingSelector } from "./IntegrationUserMapping";

function useBankAccountsColumns(readOnly: boolean, mapBankAccountToGlAccount: Function, idOfLoadindRow?: string) {
  return useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ value }: any) => <div className={styles.textAlignLeft}>{value}</div>,
      },
      {
        Header: "Bank name",
        accessor: "bank_name",
        Cell: ({ value }: any) => <div className={styles.textAlignLeft}>{value}</div>,
      },
      {
        Header: "Account number",
        accessor: "account_number",
        Cell: ({ value }: any) => <div className={styles.textAlignLeft}>**{value}</div>,
      },
      {
        Header: "Status",
        accessor: "verification_status",
        Cell: ({ value }: any) => <div className={styles.textAlignLeft}>{value}</div>,
      },
      {
        Header: "GL Account",
        accessor: "glAccount",
        Cell: ({ row }: any) => {
          const integrationsStore = useStore("IntegrationsStore");
          return (
            <Box height="52px" display="flex" alignItems="center">
              {!integrationsStore.generalLedgerAccountsList.data ? (
                <Spinner />
              ) : integrationsStore.generalLedgerAccountsList.data ? (
                <>
                  {idOfLoadindRow === row.original.id ? (
                    <Spinner />
                  ) : (
                    <IntegrationUserAndBankAccountMappingSelector
                      options={[
                        { label: "None", value: "none" },
                        ...integrationsStore.generalLedgerAccountsList.data.map((glAcc) => ({
                          value: glAcc.id,
                          label: `${glAcc.account_number} - ${glAcc.name} (${glAcc.external_ref})`,
                        })),
                      ]}
                      initialSelectedValues={row.original.gl_accounts.map((gl_account: { id: any }) => gl_account.id)}
                      onChange={(selectedValues) => {
                        mapBankAccountToGlAccount({
                          glAccountsIds: selectedValues[selectedValues.length - 1],
                          bankAccountId: row.original.id,
                        });
                      }}
                      readOnly={readOnly}
                      isLoading={false}
                    />
                  )}
                </>
              ) : null}
            </Box>
          );
        },
      },
    ],
    [idOfLoadindRow, mapBankAccountToGlAccount, readOnly],
  );
}

export { useBankAccountsColumns };
