import { Skeleton, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import { observer } from "mobx-react";
import { useStore } from "storeContainer";
import { PayVBill } from "../PayVBill/PayVBill";

const emptySkeletonList = new Array(50).fill("");

export const PayLedgerList = observer(function PayLedgerList() {
  const vBillPayLedgerStore = useStore("VBillPayLedgerStore");

  const billsLedgerListData = vBillPayLedgerStore.billsLedgerList.data;

  return (
    <TableBody>
      {vBillPayLedgerStore.billsLedgerList.isLoading ? (
        <>
          {emptySkeletonList.map((_, index) => (
            <TableRow key={index} sx={{ cursor: "default" }}>
              <TableCell colSpan={11} sx={{ padding: "12px" }}>
                <Skeleton variant="rounded" sx={{ height: "34px" }} />
              </TableCell>
            </TableRow>
          ))}
        </>
      ) : (billsLedgerListData?.items ?? []).length === 0 ? (
        <TableRow>
          <TableCell colSpan={12} sx={{ textAlign: "center" }}>
            <Typography>No results found</Typography>
          </TableCell>
        </TableRow>
      ) : (
        billsLedgerListData?.items.map((vBill) => <PayVBill key={vBill.id} vBill={vBill} />)
      )}
    </TableBody>
  );
});
