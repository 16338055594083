import { graphqlVBillClient } from "common/graphqlClient";
import {
  IVBillVBillCompaniesSettingsQuery,
  IVBillVBillCompaniesSettingsQueryVariables,
  IVBillVBillGetBillsQuery,
  IVBillVBillGetBillsQueryVariables,
} from "generated/graphql.vbill";
import {
  getSdk,
  IVBillInvoiceIncomingStatus,
  IVBillVBillGetBillsStatsQuery,
  IVBillVBillGetBillsStatsQueryVariables,
} from "generated/sdk.vbill";
import { action, makeObservable, observable } from "mobx";
import { createObservableContainer } from "storeContainer";
import { StoreBase } from "./StoreBase";

const { VBillGetBills, VBillCompaniesSettings, VBillGetBillsStats } = getSdk(graphqlVBillClient);

export class VBillPayLedgerStore extends StoreBase {
  constructor() {
    super();
    makeObservable(this, {
      selectedBillIdsToPay: observable,
      updateSelectedBillIdsToPay: action,
    });
  }

  billsLedgerList = createObservableContainer<IVBillVBillGetBillsQuery["getBills"]>();

  billsLedgerGeneralStats = createObservableContainer<IVBillVBillGetBillsStatsQuery["getBillsStats"]>();
  billsLedgerOverDueStats = createObservableContainer<IVBillVBillGetBillsStatsQuery["getBillsStats"]>();
  billsLedgerDueToPayStats = createObservableContainer<IVBillVBillGetBillsStatsQuery["getBillsStats"]>();

  companiesSettings =
    createObservableContainer<IVBillVBillCompaniesSettingsQuery["companiesSettings"]["vBill"]["additionalMappings"]>();
  selectedBillIdsToPay: number[] = [];

  loadBillsLedgerList = (data: IVBillVBillGetBillsQueryVariables, forceUpdate?: boolean) => {
    this.billsLedgerList.cachedLoad(
      async () =>
        (
          await VBillGetBills({
            filters: { ...data.filters, status: IVBillInvoiceIncomingStatus.Approved },
            pagination: data.pagination,
          })
        ).getBills,
      [data],
      {
        forceUpdate,
      },
    );
  };

  loadBillsLedgerGeneralStats = (data: IVBillVBillGetBillsStatsQueryVariables, forceUpdate?: boolean) => {
    this.billsLedgerGeneralStats.cachedLoad(async () => (await VBillGetBillsStats(data)).getBillsStats, [data], {
      forceUpdate,
    });
  };

  loadBillsLedgerOverDueStats = (data: IVBillVBillGetBillsStatsQueryVariables, forceUpdate?: boolean) => {
    this.billsLedgerOverDueStats.cachedLoad(async () => (await VBillGetBillsStats(data)).getBillsStats, [data], {
      forceUpdate,
    });
  };

  loadBillsLedgerDueToPayStats = (data: IVBillVBillGetBillsStatsQueryVariables, forceUpdate?: boolean) => {
    this.billsLedgerDueToPayStats.cachedLoad(async () => (await VBillGetBillsStats(data)).getBillsStats, [data], {
      forceUpdate,
    });
  };

  loadCompaniesSettings = (data: IVBillVBillCompaniesSettingsQueryVariables, forceUpdate?: boolean) => {
    this.companiesSettings.cachedLoad(
      async () => await (await VBillCompaniesSettings(data)).companiesSettings.vBill.additionalMappings,
      [data],
      {
        forceUpdate,
      },
    );
  };

  updateSelectedBillIdsToPay = (billIds: number[]) => {
    this.selectedBillIdsToPay = billIds;
  };
}
