import { Box, debounce, Paper } from "@mui/material";
import Typography from "@mui/material/Typography";
import Cookies from "js-cookie";
import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";
import { definitelyFilter } from "../../../generated/utils";
import { useStore } from "../../../storeContainer";
import { COLORS } from "../../../themes/default";
import { Spinner } from "../../common/Spinner/Spinner";
import { BankAccountsList } from "../Settings/Accounts/components/BankAccountsList";

export const DepositAccounts = observer(function DepositAccounts() {
  const organizationStore = useStore("OrganizationStore");
  const { companiesWithBankAccountsAndAggregatedData } = organizationStore;
  const [orgDashboardFilters, setOrgDashboardFilters] = useState<{ search: string }>({ search: "" });

  const SessionStore = useStore("SessionStore");
  const UndepositedChecksStore = useStore("UndepositedChecksStore");
  const companyStore = useStore("CompanyStore");

  const [onlyWithTransactions, setOnlyWithTransactions] = useState(false);
  const debouncedSearch = useRef(
    debounce(async (criteria) => {
      setOrgDashboardFilters(criteria);
    }, 500),
  ).current;
  useEffect(() => {
    if (Cookies.get("lastOrg")) {
      Cookies.remove("lastOrg");
      window.location.reload();
    }

    organizationStore.loadOrganizationDashboard(undefined, undefined, orgDashboardFilters);
    SessionStore.getInvites();
    UndepositedChecksStore.getUndepositedChecks();
  }, [SessionStore, UndepositedChecksStore, organizationStore, orgDashboardFilters]);

  const handleChangeBankAccountName = async (id: string, name: string) => {
    await companyStore.changeBankAccountName(id, name);
  };

  const bankAccounts = definitelyFilter(companiesWithBankAccountsAndAggregatedData?.data?.[0]?.bank_accounts)?.filter(
    (bank_account) => {
      if (onlyWithTransactions) {
        return (
          (bank_account.incoming_checks_stats?.count || 0) > 0 ||
          (bank_account.outgoing_checks_stats?.count || 0) > 0 ||
          (bank_account.pending_approval_checks_stats?.count || 0) > 0
        );
      } else {
        return true;
      }
    },
  );
  return (
    <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", maxWidth: "1400px" }}>
      <Box paddingTop="40px">
        {/* <Header
          // onlyWithTransactions={onlyWithTransactions}
          depositAccounts={true}
          searchKeywords={orgDashboardFilters.search}
          handleChange={(searchKeywords) => {
            debouncedSearch({ search: searchKeywords });
          }}
          handleToggleChange={(_onlyWithTransactions) => {
            setOnlyWithTransactions(_onlyWithTransactions);
          }}
        /> */}
        {companiesWithBankAccountsAndAggregatedData.isFetching && <Spinner withSpaceAround />}

        {companiesWithBankAccountsAndAggregatedData.data && (
          <Paper sx={{ padding: "20px", marginTop: "25px" }}>
            <BankAccountsList
              handleChangeBankAccountName={handleChangeBankAccountName}
              companyId={companiesWithBankAccountsAndAggregatedData.data[0]?.id}
              depositAccounts={true}
              backgroundColor={COLORS.white}
              bankAccounts={bankAccounts}
            />

            {companiesWithBankAccountsAndAggregatedData.data[0]?.bank_accounts?.length === 0 && (
              <Typography sx={{ textAlign: "center", color: "black", fontSize: "16px" }}>
                There is no bank account with that name.
              </Typography>
            )}
          </Paper>

          // <CompaniesList
          //   searchKeywords={searchKeywords}
          //   companies={companiesWithBankAccountsAndAggregatedData.data}
          // />
        )}
      </Box>
    </Box>
  );
});
