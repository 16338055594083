import { IconButton, TableCell, TableRow, Tooltip } from "@mui/material";
import { CHDecimal } from "common/helpers/decimal";
import { TrashIcon } from "components/common/icons";
import { FormInputNumber } from "components/pages/VBill/common/Forms/FormInputNumber/FormInputNumber";
import { FormInputText } from "components/pages/VBill/common/Forms/FormInputText/FormInputText";
import { TVBillFormFields } from "components/pages/VBill/types";
import { ENABLE_COMPUTED_LINE_ITEM_AMOUNTS } from "components/pages/VBill/utils";
import { observer } from "mobx-react-lite";
import { useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useStore } from "storeContainer";

const defaultTableCellSx = {
  padding: "8px 5px",
};

interface IIncomingLineItemProps {
  index: number;
  onRemoveBtnClick: () => void;
}

export const IncomingLineItem = observer(({ index, onRemoveBtnClick }: IIncomingLineItemProps) => {
  const vBillStore = useStore("VBillStore");
  const { setValue: setVBillFormValue, getValues: getVBillFormValues } = useFormContext<TVBillFormFields>();
  const lineItemHighlighted = useMemo(
    () => vBillStore.incomingLineItemHighlightedId === index,
    [vBillStore.incomingLineItemHighlightedId, index],
  );

  useEffect(() => {
    if (lineItemHighlighted) {
      setTimeout(() => {
        vBillStore.setIncomingLineItemHighlightedId(null);
      }, 3000);
    }
  }, [lineItemHighlighted]);

  const handleAmountFormFieldChange = (type: "qty" | "rate" | "amount", value: string) => {
    const vBillFormLineValues = getVBillFormValues("lineItems")?.[index];

    if (type === "qty" && value.length && value !== "-") {
      const amountValue = new CHDecimal(value).times(vBillFormLineValues.rate);
      const amountFixed = amountValue.decimalPlaces() === 0 ? amountValue.toString() : amountValue.toFixed(2);

      setVBillFormValue(`lineItems.${index}.amount`, amountFixed);

      return;
    }

    if (type === "rate" && value.length && value !== "-") {
      const amountValue = new CHDecimal(value).times(vBillFormLineValues.qty);
      const amountFixed = amountValue.decimalPlaces() === 0 ? amountValue.toString() : amountValue.toFixed(2);

      setVBillFormValue(`lineItems.${index}.amount`, amountFixed);

      return;
    }

    if (type === "amount" && value.length && value !== "-") {
      const rateValue = new CHDecimal(value).dividedBy(vBillFormLineValues.qty);
      const rateFixed = rateValue.decimalPlaces() === 0 ? rateValue.toString() : rateValue.toFixed(2);

      setVBillFormValue(`lineItems.${index}.rate`, rateFixed);
    }
  };

  return (
    <TableRow
      id={`vbill-line-item-${index}`}
      sx={{ ...(lineItemHighlighted ? { backgroundColor: "#fff4ea" } : {}), cursor: "default" }}
    >
      <TableCell sx={{ ...defaultTableCellSx, width: "40px" }}>#{index + 1}</TableCell>

      <TableCell sx={{ ...defaultTableCellSx, verticalAlign: "top" }}>
        <FormInputText
          name={`lineItems.${index}.description` as unknown as keyof TVBillFormFields}
          label="Description:"
          rules={{ required: "Description is required" }}
          insideForm
          multiline
        />
      </TableCell>

      <TableCell sx={{ ...defaultTableCellSx, verticalAlign: "top", width: "85px" }}>
        <FormInputNumber
          name={`lineItems.${index}.qty` as unknown as keyof TVBillFormFields}
          label="Qty:"
          insideForm
          allowNegative
          {...(ENABLE_COMPUTED_LINE_ITEM_AMOUNTS
            ? {
                rules: {
                  required: "Qty is required",
                },
                onChange: (value) => handleAmountFormFieldChange("qty", value),
              }
            : {})}
        />
      </TableCell>

      <TableCell sx={{ ...defaultTableCellSx, verticalAlign: "top", width: "85px" }}>
        <FormInputNumber
          name={`lineItems.${index}.rate` as unknown as keyof TVBillFormFields}
          label="Rate:"
          insideForm
          allowNegative
          {...(ENABLE_COMPUTED_LINE_ITEM_AMOUNTS
            ? {
                rules: {
                  required: "Rate is required",
                },
                onChange: (value) => handleAmountFormFieldChange("rate", value),
              }
            : {})}
        />
      </TableCell>

      <TableCell sx={{ ...defaultTableCellSx, verticalAlign: "top", width: "110px" }}>
        <FormInputNumber
          name={`lineItems.${index}.amount` as unknown as keyof TVBillFormFields}
          label="Total:"
          priceField
          insideForm
          allowNegative
          decimalScale={2}
          {...(ENABLE_COMPUTED_LINE_ITEM_AMOUNTS
            ? {
                rules: {
                  required: "Amount is required",
                },
                onChange: (value) => handleAmountFormFieldChange("amount", value),
              }
            : {})}
        />
      </TableCell>

      <TableCell sx={{ ...defaultTableCellSx, width: "50px" }}>
        <Tooltip title="Delete line" placement="top-start" arrow>
          <IconButton onClick={onRemoveBtnClick} aria-label="delete">
            <TrashIcon sx={{ fontSize: "18px" }} />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
});
