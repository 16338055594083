import { Box, Button, SxProps, Theme } from "@mui/material";
import { ExpandLessIcon, ExpandMoreIcon } from "components/common/icons";
import { useState } from "react";
import { DateFilterPopover, DateValues } from "./DateFilterPopover/DateFilterPopover";

const expandIconStyles: SxProps<Theme> = { fontSize: "16px !important", marginRight: "4px" };

interface IVBillDateFilterProps {
  date: DateValues;
  onDateChange: (values: DateValues) => void;
  smallHeight?: boolean;
  actionsBtnSmallHeight?: boolean;
  sx?: SxProps<Theme>;
  btnText?: string;
}

export const VBillDateFilter = ({
  date,
  onDateChange,
  smallHeight,
  actionsBtnSmallHeight,
  btnText,
  sx,
}: IVBillDateFilterProps) => {
  const [popoverAnchorEl, setPopoverAnchorEl] = useState<null | HTMLElement>(null);

  const popoverVisible = Boolean(popoverAnchorEl);
  const popoverId = popoverVisible ? "amount-filter" : undefined;

  return (
    <Box sx={sx}>
      <Button
        onClick={(event) => setPopoverAnchorEl(event.currentTarget)}
        aria-describedby={popoverId}
        variant={date.date || date.date_to ? "containedFilters" : "outlined"}
        endIcon={popoverVisible ? <ExpandLessIcon sx={expandIconStyles} /> : <ExpandMoreIcon sx={expandIconStyles} />}
        sx={{ ...(smallHeight ? { height: "32px" } : {}) }}
      >
        {btnText ?? "Date"}
      </Button>

      {popoverVisible && (
        <DateFilterPopover
          popoverId={popoverId}
          actionsBtnSmallHeight={actionsBtnSmallHeight}
          date={date}
          onDateChange={onDateChange}
          onClosePopover={() => setPopoverAnchorEl(null)}
          popoverAnchorEl={popoverAnchorEl}
        />
      )}
    </Box>
  );
};
