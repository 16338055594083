import CommentIcon from "@mui/icons-material/Comment";
import ForumIcon from "@mui/icons-material/Forum";
import PaymentIcon from "@mui/icons-material/Payment";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import { Box, Paper, Skeleton } from "@mui/material";
import Tabs, { TabsActions } from "@mui/material/Tabs";
import { ActivityLog } from "components/common/ActivityLog/ActivityLog";
import { ChatIcon, HistoryIcon } from "components/common/icons";
import { IActionlogObjectType } from "generated/graphql.actionlog";
import { observer } from "mobx-react";
import {
  ChatDetailDashboard,
  IChatChannelParentType,
  IChatContentBlockMessageRelatedType,
  TBaseIoChannel,
} from "modules/Chat";
import { forwardRef, SyntheticEvent, useImperativeHandle, useMemo, useRef } from "react";
import { useParams } from "react-router";
import { useStore } from "storeContainer";
import { StringParam, useQueryParam, withDefault } from "use-query-params";
import { SidebarPayments } from "./SidebarPayments/SidebarPayments";
import { SidebarStatsGlobalSimulator } from "./SidebarStatsGlobalSimulator/SidebarStatsGlobalSimulator";
import { a11yProps, SidebarTabBtn, SidebarTabBtnSmall } from "./SidebarTabBtn/SidebarTabBtn";
import { SidebarTabPanel } from "./SidebarTabPanel/SidebarTabPanel";

export const vBillBatchSidebarPanelQSKey = "sidebar-tab-panel";
export enum vBillBatchSidebarPanels {
  "CHAT" = "chat",
  "ACTIVITY_LOG" = "activity_log",
  "STATS" = "stats",
  "PAYMENTS" = "payments",
}
export const vBillBatchChatPanelQSKey = "chat_panel";
export enum vBillBatchChatPanels {
  "SR_CHAT" = "sr_chat",
  "SUPPORT_CHAT" = "support_chat",
  "RS_CHAT" = "rs_chat",
}

export interface ISidebarTabsRef {
  tabsUpdateIndicator: () => void;
}

export const SidebarTabs = observer(
  forwardRef<ISidebarTabsRef>((_props, ref) => {
    const vBillBatchDetailsStore = useStore("VBillBatchDetailsStore");
    const sessionStore = useStore("SessionStore");
    const { batchId, organizationId } = useParams<{ batchId: string; organizationId: string }>();
    const organizationName = useMemo(
      () =>
        sessionStore.session.data?.organizationUsers?.find(({ organization }) => organization.id === organizationId)
          ?.organization.name ?? "",
      [organizationId, sessionStore.session.data?.organizationUsers],
    );
    const sessionAccountId = useMemo(
      () => sessionStore.session.data?.account?.id ?? "",
      [sessionStore.session.data?.account?.id],
    );
    const tabsDomAction = useRef<TabsActions>(null);
    const [sidebarPanel, setSidebarPanel] = useQueryParam<vBillBatchSidebarPanels>(
      vBillBatchSidebarPanelQSKey,
      withDefault<any, vBillBatchSidebarPanels>(StringParam, vBillBatchSidebarPanels.PAYMENTS),
      { removeDefaultsFromUrl: true },
    );
    const [chatPanel, setChatPanel] = useQueryParam<vBillBatchChatPanels>(
      vBillBatchChatPanelQSKey,
      withDefault<any, vBillBatchChatPanels>(StringParam, vBillBatchChatPanels.SUPPORT_CHAT),
      { removeDefaultsFromUrl: true },
    );

    useImperativeHandle(ref, () => ({
      tabsUpdateIndicator() {
        tabsDomAction.current?.updateIndicator();
      },
    }));

    const activityLogFilters = useMemo(
      () => ({ type: IActionlogObjectType.Billbatch, key: batchId, organizationId }),
      [batchId, organizationId],
    );
    // SidebarTabPanels.SUPPORT_CHAT
    const supportChatDetailDashboardBaseIoChannel: TBaseIoChannel = useMemo(
      () => ({
        parentContext: "sending-admin",
        parentKey: batchId,
        parentType: IChatChannelParentType.VbillBatch,
      }),
      [batchId],
    );

    //SidebarTabPanels.SR_CHAT
    const srChatDetailDashboardBaseIoChannel: TBaseIoChannel = useMemo(
      () => ({
        parentContext: "sending",
        parentKey: batchId,
        parentType: IChatChannelParentType.VbillBatch,
      }),
      [batchId],
    );

    const { batchSelectedVBills, batchDetails } = vBillBatchDetailsStore;

    const chatRelatedItemsBySelectedVBill = useMemo(
      () =>
        (batchSelectedVBills.data?.items ?? []).map(({ id, amount }) => ({
          key: `${id ?? ""}`,
          related: {
            key: `${id ?? ""}`,
            label: `VBill #: ${id ?? ""} - Amount: ${amount}`,
            type: IChatContentBlockMessageRelatedType.Bill,
          },
        })),
      [batchSelectedVBills.data?.items],
    );

    return (
      <Paper
        sx={{
          padding: "0 10px 10px",
        }}
      >
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            textColor="secondary"
            indicatorColor="secondary"
            variant="scrollable"
            scrollButtons="auto"
            value={sidebarPanel}
            onChange={(_event: SyntheticEvent<Element, Event>, value: vBillBatchSidebarPanels) => {
              if (value !== vBillBatchSidebarPanels.CHAT) {
                setChatPanel(vBillBatchChatPanels.SUPPORT_CHAT);
              }
              setSidebarPanel(value);
            }}
            aria-label="invoice tabs"
            action={tabsDomAction}
          >
            <SidebarTabBtn
              label="Payments"
              iconPosition="start"
              icon={<PaymentIcon />}
              value={vBillBatchSidebarPanels.PAYMENTS}
              {...a11yProps(0)}
            />
            <SidebarTabBtn
              label="Chat"
              iconPosition="start"
              icon={<CommentIcon />}
              value={vBillBatchSidebarPanels.CHAT}
              {...a11yProps(1)}
            />
            <SidebarTabBtn
              label="Activity log"
              iconPosition="start"
              icon={<HistoryIcon />}
              value={vBillBatchSidebarPanels.ACTIVITY_LOG}
              {...a11yProps(2)}
            />
            <SidebarTabBtn
              label="Stats"
              iconPosition="start"
              icon={<QueryStatsIcon />}
              value={vBillBatchSidebarPanels.STATS}
              {...a11yProps(3)}
            />
          </Tabs>
        </Box>

        {batchDetails.isLoading ? (
          <Skeleton variant="rounded" sx={{ height: "400px", margin: "20px" }} />
        ) : (
          <>
            <SidebarTabPanel activePannel={sidebarPanel} panelName={vBillBatchSidebarPanels.STATS}>
              <SidebarStatsGlobalSimulator />
              {/* <SidebarStatsSimulator /> */}
            </SidebarTabPanel>

            <SidebarTabPanel activePannel={sidebarPanel} panelName={vBillBatchSidebarPanels.CHAT}>
              <Tabs
                textColor="primary"
                indicatorColor="primary"
                centered
                value={chatPanel}
                onChange={(_event: SyntheticEvent<Element, Event>, value: vBillBatchChatPanels) => setChatPanel(value)}
                aria-label="chat tabs"
                action={tabsDomAction}
                sx={{ minHeight: "auto" }}
              >
                <SidebarTabBtnSmall
                  icon={<ChatIcon />}
                  iconPosition="start"
                  label="Cherry"
                  {...a11yProps(0)}
                  value={vBillBatchChatPanels.SUPPORT_CHAT}
                />
                <SidebarTabBtnSmall
                  icon={<ForumIcon />}
                  iconPosition="start"
                  label={organizationName}
                  {...a11yProps(1)}
                  value={vBillBatchChatPanels.SR_CHAT}
                />
              </Tabs>

              <SidebarTabPanel
                activePannel={chatPanel}
                panelName={vBillBatchChatPanels.SUPPORT_CHAT}
                sx={{ padding: "0" }}
              >
                <ChatDetailDashboard
                  config={{
                    baseIoChannel: supportChatDetailDashboardBaseIoChannel,
                    organizationId: organizationId ?? "",
                    sessionAccountId: sessionAccountId,
                  }}
                  settings={{
                    forcePublic: true,
                    // channelComingSoon: isNil(organizationId),
                  }}
                  relatedItems={chatRelatedItemsBySelectedVBill}
                  sx={{ height: `calc(100vh - 376px)` }}
                  channelSx={{ height: "100%", display: "flex", flexDirection: "column" }}
                />
              </SidebarTabPanel>
              <SidebarTabPanel activePannel={chatPanel} panelName={vBillBatchChatPanels.SR_CHAT} sx={{ padding: "0" }}>
                <ChatDetailDashboard
                  config={{
                    baseIoChannel: srChatDetailDashboardBaseIoChannel,
                    organizationId: organizationId ?? "",
                    sessionAccountId: sessionAccountId,
                  }}
                  settings={{
                    // showMembers: true,
                    showVisibility: true,
                    // channelComingSoon: isNil(organizationId),
                  }}
                  relatedItems={chatRelatedItemsBySelectedVBill}
                  sx={{ height: `calc(100vh - 376px)` }}
                  channelSx={{ height: "100%", display: "flex", flexDirection: "column" }}
                />
              </SidebarTabPanel>
            </SidebarTabPanel>

            <SidebarTabPanel activePannel={sidebarPanel} panelName={vBillBatchSidebarPanels.ACTIVITY_LOG}>
              <ActivityLog filters={activityLogFilters} reloadObserver={batchDetails.data} />
            </SidebarTabPanel>

            <SidebarTabPanel activePannel={sidebarPanel} panelName={vBillBatchSidebarPanels.PAYMENTS}>
              <SidebarPayments />
            </SidebarTabPanel>
          </>
        )}
      </Paper>
    );
  }),
);
