import { SvgIcon, SvgIconProps } from "@mui/material";

export const CopyOutlineIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.5">
          <path
            d="M13.1339 3.5H5.86607C4.83547 3.5 4 4.41142 4 5.53571V13.4643C4 14.5886 4.83547 15.5 5.86607 15.5H13.1339C14.1645 15.5 15 14.5886 15 13.4643V5.53571C15 4.41142 14.1645 3.5 13.1339 3.5Z"
            stroke="#697281"
            strokeLinejoin="round"
          />
          <path
            d="M11.9836 3.11905L12 3.33333C11.9986 2.84753 11.805 2.38202 11.4615 2.0385C11.118 1.69498 10.6525 1.50138 10.1667 1.5H3.09524C2.54005 1.50164 2.00807 1.72292 1.61549 2.11549C1.22292 2.50807 1.00164 3.04005 1 3.59524V10.6667C1.00138 11.1525 1.19498 11.618 1.5385 11.9615C1.88202 12.305 2.34753 12.4986 2.83333 12.5H3.61905"
            stroke="#697281"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    </SvgIcon>
  );
};
