import { IVBillBatchListFiltersInput, IVBillBatchPaginationInput } from "generated/sdk.vbill";
import { useParams, useRouteMatch } from "react-router-dom";
import { VBillBatchLedgerBasePath } from "routes/routes";
import { JsonParam, QueryParamConfig, useQueryParams, withDefault } from "use-query-params";
import { VBillBatchLedgerChildRoutes } from "./utils";

const initialPaginationParams: IVBillBatchPaginationInput = { page: 1, per_page: 50 };
const initialQsPaginationParams = withDefault<IVBillBatchPaginationInput, IVBillBatchPaginationInput>(
  JsonParam,
  initialPaginationParams,
);

function useBatchLedgerFilters() {
  return useQueryParams<{
    pagination: QueryParamConfig<IVBillBatchPaginationInput, IVBillBatchPaginationInput>;
    filters: QueryParamConfig<
      Omit<IVBillBatchListFiltersInput, "organizationId">,
      Omit<IVBillBatchListFiltersInput, "organizationId">
    >;
  }>(
    {
      pagination: initialQsPaginationParams,
      filters: JsonParam,
    },
    { removeDefaultsFromUrl: true },
  );
}

export { useBatchLedgerFilters };

export interface vBillBatchLedgerChildRoute {
  isAllChildRoute: boolean;
  isOpenChildRoute: boolean;
  isClosedChildRoute: boolean;
  isDeletedChildRoute: boolean;
}

export function useVBillBatchLedgerChildRoute(): vBillBatchLedgerChildRoute {
  const isAllChildRoute = !!useRouteMatch({ path: VBillBatchLedgerBasePath, exact: true });
  const isOpenChildRoute = !!useRouteMatch(`${VBillBatchLedgerBasePath}/${VBillBatchLedgerChildRoutes.OPEN}`);
  const isClosedChildRoute = !!useRouteMatch(`${VBillBatchLedgerBasePath}/${VBillBatchLedgerChildRoutes.CLOSED}`);
  const isDeletedChildRoute = !!useRouteMatch(`${VBillBatchLedgerBasePath}/${VBillBatchLedgerChildRoutes.DELETED}`);

  return {
    isAllChildRoute,
    isOpenChildRoute,
    isClosedChildRoute,
    isDeletedChildRoute,
  };
}

export function useVBillBatchLedgerQuickFilterUrl(route: VBillBatchLedgerChildRoutes) {
  const { organizationId } = useParams<{ organizationId: string }>();

  return `/org/${organizationId}/vbillbatchledger${route === VBillBatchLedgerChildRoutes.ALL ? "" : `/${route}`}`;
}
