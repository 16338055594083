import { Button } from "@mui/material";
import { IVBillBatchStatus } from "generated/sdk.vbill";
import { observer } from "mobx-react-lite";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useStore } from "storeContainer";
import { ConfirmBatchActionDialog } from "../../common/ConfirmBatchActionDialog/ConfirmBatchActionDialog";

export const SummariesPayBatch = observer(function SummariesPayBatch() {
  const [isPayBatchDialogOpen, setIsPayBatchDialogOpen] = useState(false);
  const vBillBatchDetailsStore = useStore("VBillBatchDetailsStore");
  const { batchId } = useParams<{ batchId: string }>();

  const { batchDetails, batchSelectedVBills } = vBillBatchDetailsStore;
  const batchSelectedVBillsData = batchSelectedVBills.data;
  const batchDetailsData = batchDetails.data;
  const hasBatchSelectedBills = useMemo(
    () => (batchSelectedVBillsData?.items ?? []).length > 0,
    [batchSelectedVBillsData?.items],
  );
  const canPayBatch = useMemo(
    () => hasBatchSelectedBills && batchDetailsData?.status === IVBillBatchStatus.Open,
    [batchDetailsData?.status, hasBatchSelectedBills],
  );

  const handleConfirmPayBatchSubmit = async () => {
    await vBillBatchDetailsStore.createBatchPayments({ payableBatch: { batchId: Number(batchId) } });
    setIsPayBatchDialogOpen(false);
  };

  if (!canPayBatch) {
    return null;
  }

  return (
    <>
      <Button
        variant="contained"
        onClick={() => setIsPayBatchDialogOpen(true)}
        sx={{ height: "30px", whiteSpace: "nowrap" }}
      >
        Pay
      </Button>

      {isPayBatchDialogOpen && (
        <ConfirmBatchActionDialog
          title="Pay batch"
          text="Are you sure you want to pay this batch?"
          confirmBtnText="Pay batch"
          onCloseDialog={() => setIsPayBatchDialogOpen(false)}
          actionsBtnSmallHeight
          onConfirmSubmit={handleConfirmPayBatchSubmit}
        />
      )}
    </>
  );
});
