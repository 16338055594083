import { Box, Skeleton } from "@mui/material";

export const DetailsDrawerSkeleton = () => (
  <Box sx={{ display: "flex", flexWrap: "wrap" }}>
    <Box sx={{ padding: "0 10px 10px", width: "100%" }}>
      <Skeleton variant="rounded" sx={{ height: "20px" }} />
    </Box>

    <Box sx={{ padding: "10px", width: "33.333%" }}>
      <Skeleton variant="rounded" sx={{ height: "260px" }} />
    </Box>
    <Box sx={{ padding: "10px", width: "33.333%" }}>
      <Skeleton variant="rounded" sx={{ height: "260px" }} />
    </Box>
    <Box sx={{ padding: "10px", width: "33.333%" }}>
      <Skeleton variant="rounded" sx={{ height: "260px" }} />
    </Box>

    <Box sx={{ padding: "10px", width: "100%" }}>
      <Skeleton variant="rounded" sx={{ height: "60px" }} />
    </Box>

    <Box sx={{ padding: "10px", width: "100%" }}>
      <Skeleton variant="rounded" sx={{ height: "60px" }} />
    </Box>
  </Box>
);
