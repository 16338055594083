import { Box, Button, Skeleton, Typography } from "@mui/material";
import {
  IVBillBillsFiltersMappingInput,
  IVBillIIntegrationIntegrationSettingsVBillAdditionalMappingsEntryShowIn,
  IVBillIntegrationSettingsVBillAdditionalMappingsEntryOutput,
  IVBillStoreIoDataType,
  IVBillVBillCompaniesSettingsQuery,
} from "generated/sdk.vbill";
import { useEffect, useMemo, useState } from "react";
import { AdditionalMappingsDialog } from "../../FilterComponents/VBillAdditionalMappingsFilter/AdditionalMappingsDialog/AdditionalMappingsDialog";
import { labelTextStyles } from "../VBillAddFullBatchDialog";
import {
  CompaniesAccordian,
  CompaniesAccordianDetails,
  CompaniesAccordianSummary,
} from "./CategoriesAccordian/CategoriesAccordian";

interface IAddFullBatchCategoriesProps {
  additionalMappingsSettingsData?:
    | IVBillVBillCompaniesSettingsQuery["companiesSettings"]["vBill"]["additionalMappings"]["invoice"]
    | null;
  mappings?: IVBillBillsFiltersMappingInput[] | null;
  onMappingsSave: (value: IVBillBillsFiltersMappingInput) => void;
  companyIds?: string[] | null;
}

export const AddFullBatchCategories = ({
  additionalMappingsSettingsData,
  mappings,
  onMappingsSave,
  companyIds,
}: IAddFullBatchCategoriesProps) => {
  const [accordianExpanded, setAccordianExpanded] = useState(false);
  const additionalMappingsSettings = useMemo(
    () =>
      (additionalMappingsSettingsData ?? []).filter(
        (additionalMapping) =>
          !additionalMapping.isContact &&
          additionalMapping.showIn !==
            IVBillIIntegrationIntegrationSettingsVBillAdditionalMappingsEntryShowIn.Payment &&
          additionalMapping.showIn !== IVBillIIntegrationIntegrationSettingsVBillAdditionalMappingsEntryShowIn.None,
      ),
    [additionalMappingsSettingsData],
  );
  const totalAdditionalMappingsSettingsValues = useMemo(
    () =>
      additionalMappingsSettings.reduce(
        (result, mapping) =>
          (result = result + (mappings?.find(({ key }) => key === mapping.key)?.valuesIn?.length ?? 0)),
        0,
      ),
    [additionalMappingsSettings, mappings],
  );
  const [selectedAdditionalMapping, setSelectedAdditionalMapping] =
    useState<IVBillIntegrationSettingsVBillAdditionalMappingsEntryOutput>();

  useEffect(() => {
    if (!additionalMappingsSettingsData) {
      setAccordianExpanded(false);
    }
  }, [additionalMappingsSettingsData]);

  const handleAdditionalMappingChange = async (value: IVBillBillsFiltersMappingInput) => {
    onMappingsSave(value);
    setSelectedAdditionalMapping(undefined);
  };

  return (
    <Box sx={{ marginBottom: "15px" }}>
      <CompaniesAccordian expanded={accordianExpanded} onChange={() => setAccordianExpanded((prev) => !prev)}>
        {!additionalMappingsSettingsData ? (
          <Skeleton variant="rounded" sx={{ height: "20px", margin: "6px 0" }} />
        ) : (
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <Typography sx={labelTextStyles}>
              Bills Categories{" "}
              <Box component="sup" sx={{ fontSize: "10px" }}>
                ({totalAdditionalMappingsSettingsValues})
              </Box>
            </Typography>

            <CompaniesAccordianSummary accordianExpanded={accordianExpanded} />
          </Box>
        )}

        <CompaniesAccordianDetails>
          {additionalMappingsSettings.map((mapping) => (
            <Box
              key={mapping.key}
              sx={{ display: "flex", gap: "10px", alignItems: "center", justifyContent: "space-between" }}
            >
              <Typography sx={{ whiteSpace: "nowrap" }}>
                {mapping.label}:{" "}
                <Box component="sup" sx={{ fontSize: "10px", fontWeight: "600" }}>
                  ({mappings?.find(({ key }) => key === mapping.key)?.valuesIn?.length ?? 0})
                </Box>
              </Typography>

              <Button
                onClick={() => {
                  setSelectedAdditionalMapping(mapping);
                }}
                sx={{
                  ":hover": { textDecoration: "underline" },
                  flexDirection: "column",
                  fontSize: "12px",
                  height: "20px",
                }}
              >
                Add/Remove
              </Button>
            </Box>
          ))}
        </CompaniesAccordianDetails>
      </CompaniesAccordian>

      {selectedAdditionalMapping && (
        <AdditionalMappingsDialog
          suggestionsComponent="dialog"
          storeType={selectedAdditionalMapping.storeDataType as unknown as IVBillStoreIoDataType}
          storeKey={selectedAdditionalMapping.key}
          mapping={mappings?.find(({ key }) => key === selectedAdditionalMapping.key)}
          batchCompanyIds={companyIds}
          onCloseDialog={() => setSelectedAdditionalMapping(undefined)}
          searchPlaceholder={`Search ${selectedAdditionalMapping.label ?? ""}`}
          onMappingSave={handleAdditionalMappingChange}
          dialogTitle={selectedAdditionalMapping.label ?? ""}
          actionsBtnSmallHeight
        />
      )}
    </Box>
  );
};
