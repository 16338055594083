import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Typography from "@mui/material/Typography";
import Divider from "components/common/Divider";
import { CompanyBasic, LedgerCheck, OrganizationBasic } from "generated/sdk";
import { useEffect, useRef } from "react";
import { VariableSizeList } from "react-window";
import { COLORS } from "themes/default";
import styles from "../UndepositedChecks.module.scss";

const BlueText: React.FunctionComponent<{ name: string }> = ({ name }) => {
  return (
    <Grid container>
      <Typography
        sx={{ marginBottom: "8px", marginTop: "20px", color: COLORS.darkestBlue }}
        fontSize={16}
        marginTop={1}
        color="GrayText"
        textTransform={"capitalize"}
      >
        {name}
      </Typography>
    </Grid>
  );
};

export const CompaniesWithBankAccountsOfOrg = ({
  allChecks,
  availableBankAccounts,

  selectedBankAccount,
  setSelectedBankAccountId,
}: {
  allChecks: LedgerCheck[];
  availableBankAccounts: OrganizationBasic;

  selectedBankAccount?: string;
  setSelectedBankAccountId: Function;
}) => {
  const storeSelectedBankAccount = (e: any) => {
    setSelectedBankAccountId(e.target.value);
  };
  const filteredCompanies = availableBankAccounts.companies
    .filter((company) => company.bank_accounts?.length)
    .sort(function (currentCompany, nextCompany) {
      const current = currentCompany.name.toUpperCase();
      const next = nextCompany.name.toUpperCase();
      return current < next ? -1 : current > next ? 1 : 0;
    })
    .map((company: CompanyBasic) => company);
  const listRef = useRef(null);
  //useEffect to recalculate item height whenever the filtered companies change
  useEffect(() => {
    (listRef.current as any).resetAfterIndex(0);
  }, [filteredCompanies]);
  const optionStyle = {
    height: "54px",
    borderRadius: "5px",
    padding: "5px 10px",
    paddingLeft: 0,
    marginBottom: "5px",
    marginLeft: 0,
    width: "98%",
    fontWeight: "700",
  };
  const renderRow = ({ index, style }: { index: any; style: any }) => {
    const company = filteredCompanies[index];
    return (
      <Box key={company.id} style={{ ...style }} width="90%" overflow={"hidden"}>
        <BlueText name={company.name} />
        {company?.bank_accounts?.map((bank_account) => (
          <FormControlLabel
            key={bank_account?.id}
            sx={{
              ...optionStyle,
              color: bank_account?.id === selectedBankAccount ? COLORS.newSecondaryColor : COLORS.darkGrey,
              height: "54px",
              border: "1px solid #CADBE2",
              borderRadius: "5px",
              padding: "5px 10px",
              marginBottom: "5px",
              marginLeft: 0,
              width: "98%",
              ".MuiFormControlLabel-label": {
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              },
            }}
            value={bank_account?.id}
            control={
              <Radio
                sx={{
                  "&.Mui-checked": {
                    color: COLORS.newSecondaryColor,
                  },
                }}
                color="secondary"
                checked={bank_account?.id === selectedBankAccount}
                disabled={allChecks.some((check) => check.sender?.id === bank_account?.id)}
              />
            }
            onChange={(e) => {
              storeSelectedBankAccount(e);
            }}
            label={
              <Typography sx={{ fontWeight: 500, wordBreak: "break-all" }}>
                {bank_account?.name + "*" + bank_account?.account_number}
              </Typography>
            }
            title={bank_account?.name + "*" + bank_account?.account_number}
          />
        ))}
        <Divider sx={{ left: 0, width: "380px" }} />
      </Box>
    );
  };
  const getItemHeight = (index: number) => {
    const company = filteredCompanies[index];
    return company.bank_accounts?.length! * 59 + 48;
    //!In case of modifying the size of elements in the list, the above values also need to be modified
  };
  return (
    <Box sx={{ overflow: "hidden" }}>
      <Box>
        <Grid className={styles.scrollbarDiv} sx={{ justifyContent: "center" }} container>
          <Grid alignItems={"center"} justifyContent="center" xs={12} item>
            <FormControl fullWidth>
              <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
                <VariableSizeList
                  height={500}
                  itemCount={filteredCompanies.length}
                  itemSize={getItemHeight}
                  width="100%"
                  ref={listRef}
                >
                  {renderRow}
                </VariableSizeList>
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
