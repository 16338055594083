import Typography from "@mui/material/Typography";
import Autocomplete from "components/common/Autocomplete";
import type { Company } from "generated/sdk";
import { observer } from "mobx-react-lite";
import { useCallback } from "react";
import { useHistory } from "react-router";

import { useStore } from "storeContainer";
import { useSelectedOrganizationCompanyId } from "./useSelectedOrganizationCompanyId";

const getOptionLabel = (option: string | Company): string => {
  if (typeof option === "string") {
    return option;
  }
  return option.name;
};

export const OrganizationCompanySelector = observer(function OrganizationCompanySelector(props: any) {
  const { organizationId, companyId } = useSelectedOrganizationCompanyId();
  const session = useStore("SessionStore");
  const { acl } = session;
  const companies = organizationId ? session.companiesOfOrganization(organizationId) : [];

  const aclCompanies = companies.filter((company) => acl.canListCompany(company.id));
  const selectedCompany = aclCompanies.find((c) => c.id === companyId);

  if (aclCompanies.length === 0) {
    return null;
  }

  return organizationId && aclCompanies.length > 1 ? (
    <OrganizationCompanySelectorComponent
      organizationId={organizationId}
      companies={aclCompanies}
      company={selectedCompany}
      {...props}
    />
  ) : null;
});

const OrganizationCompanySelectorComponent = observer(function OrganizationCompanySelectorComponent({
  className,
  organizationId,
  company,
  companies,
}: {
  className?: string;
  organizationId: string;
  company?: Company;
  companies: Company[];
}) {
  const history = useHistory();
  const handleSelection = useCallback(
    (_, option) => history.push(`/org/${organizationId}/company/${option.id}`),
    [history, organizationId],
  );

  return (
    <Autocomplete
      className={className}
      disableClearable={true}
      getOptionLabel={getOptionLabel}
      noOptionsText="No Company"
      options={companies?.sort(function (currentCompany, nextCompany) {
        const current = currentCompany.name.toUpperCase();
        const next = nextCompany.name.toUpperCase();
        return current < next ? -1 : current > next ? 1 : 0;
      })}
      size="small"
      sx={{ maxWidth: "300px", padding: "15px" }}
      value={company}
      onChange={handleSelection}
    />
  );
});
