import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import LinkIcon from "@mui/icons-material/Link";
import { Box, Button, Checkbox, IconButton, TableCell, TableRow, Tooltip, Typography } from "@mui/material";
import { getSODAsUTCIsoString } from "common/helpers/utils";
import { useVBillUrl } from "components/pages/common/VBill/hooks";
import {
  friendlyIntegrationStatusNames,
  getIntegrationStatusStyleColor,
  getVBillFormattedDate,
} from "components/pages/common/VBill/utils";
import { VBillPayBillDialog } from "components/pages/common/VBill/VBillPayBillDialog/VBillPayBillDialog";
import { formatDistanceStrict } from "date-fns";
import {
  IVBillIIntegrationDataType,
  IVBillInvoiceIntegrationStatus,
  IVBillVBillGetBillsQuery,
} from "generated/sdk.vbill";
import { observer } from "mobx-react";
import { CSSProperties, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useStore } from "storeContainer";
import { COLORS } from "themes/default";
import { useVBillPayLedgerChildRoute } from "../hooks";
import { PayVBillAmounts } from "./PayVBillAmounts/PayVBillAmounts";
import { PayVBillPayments } from "./PayVBillPayments/PayVBillPayments";

const defaultTableCellSx = {
  padding: "0",
};
const defaultLinkStyles: CSSProperties = {
  minHeight: "58px",
  textDecoration: "none",
  color: "inherit",
  padding: "5px 16px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  fontSize: "14px",
  fontWeight: "500",
};

interface IPayVBillProps {
  vBill: IVBillVBillGetBillsQuery["getBills"]["items"][number];
}

export const PayVBill = observer(function PayVBill({ vBill }: IPayVBillProps) {
  const vBillPayLedgerStore = useStore("VBillPayLedgerStore");
  const organizationStore = useStore("OrganizationStore");
  const vBillInvoiceUrl = useVBillUrl(vBill);
  const [isPayBillDialogOpen, setIsPayBillDialogOpen] = useState(false);
  const { isAllChildRoute } = useVBillPayLedgerChildRoute();

  const { companiesSettings, selectedBillIdsToPay } = vBillPayLedgerStore;
  const {
    amounts,
    canBePaid,
    invoiceDateDue,
    id,
    companyId,
    additionalMappings,
    invoiceDate,
    invoiceNumber,
    integrationStatus,
  } = vBill;
  const allCompanies = useMemo(
    () =>
      (organizationStore.organizationVBillEnabledCompanies.data?.items ?? []).map(({ name, companyId }) => ({
        name,
        id: companyId,
      })),
    [organizationStore.organizationVBillEnabledCompanies.data?.items],
  );
  const companyName = useMemo(() => allCompanies.find(({ id }) => id === companyId)?.name, [companyId, allCompanies]);
  const additionalMappingsSettingsData = companiesSettings.data?.invoice;
  const vendorMapping = useMemo(
    () =>
      (additionalMappings ?? []).find(
        ({ key }) =>
          additionalMappingsSettingsData
            ?.filter(({ isContact }) => isContact)
            .map(({ key }) => key)
            .includes(key),
      ),
    [additionalMappingsSettingsData, additionalMappings],
  );
  // const payFromMapping = useMemo(
  //   () =>
  //     (additionalMappings ?? []).find(
  //       ({ key }) => key === additionalMappingsSettingsData?.find(({ isGlAccount }) => isGlAccount)?.key,
  //     ),
  //   [additionalMappingsSettingsData, additionalMappings],
  // );
  const restOfAdditionalMappings = useMemo(
    () =>
      (additionalMappings ?? []).filter(
        (additionalMapping) =>
          !!additionalMappingsSettingsData?.find(({ isContact, key }) => !isContact && key === additionalMapping?.key),
      ),

    [additionalMappings, additionalMappingsSettingsData],
  );

  const isSelectToPay = useMemo(() => selectedBillIdsToPay.includes(id), [selectedBillIdsToPay, id]);
  const overDueDistance = useMemo(
    () =>
      formatDistanceStrict(
        getSODAsUTCDate(new Date(vBill?.invoiceDateDue ?? new Date())),
        getSODAsUTCDate(new Date()),
        {
          unit: "day",
          roundingMethod: "floor",
        },
      ),
    [vBill?.invoiceDateDue],
  );
  const dueInDistance = useMemo(
    () =>
      formatDistanceStrict(
        getSODAsUTCDate(new Date(vBill?.invoiceDateDue ?? new Date())),
        getSODAsUTCDate(new Date()),
        {
          unit: "day",
          roundingMethod: "ceil",
        },
      ),
    [vBill?.invoiceDateDue],
  );
  const canAmountFreeToPayBill = useMemo(() => Number(amounts?.amountFreeToPay ?? 0) > 0, [amounts?.amountFreeToPay]);
  const formattedInvoiceDate = useMemo(() => getVBillFormattedDate(invoiceDate), [invoiceDate]);
  const statusName = useMemo(
    () => friendlyIntegrationStatusNames[integrationStatus ?? IVBillInvoiceIntegrationStatus.InvoiceDraft],
    [integrationStatus],
  );
  const statusColor = useMemo(() => getIntegrationStatusStyleColor(integrationStatus), [integrationStatus]);

  const showSelectToPayCheckbox = useMemo(
    () => canAmountFreeToPayBill && canBePaid,
    [canAmountFreeToPayBill, canBePaid],
  );
  const showPayBtn = useMemo(
    () => showSelectToPayCheckbox && !selectedBillIdsToPay.length,
    [showSelectToPayCheckbox, selectedBillIdsToPay.length],
  );
  const isDueToday = useMemo(
    () => getSODAsUTCIsoString(new Date(invoiceDateDue ?? new Date())) === getSODAsUTCIsoString(new Date()),
    [invoiceDateDue],
  );
  const isOverDue = useMemo(
    () => new Date(invoiceDateDue ?? new Date()).getTime() < getSODAsUTCDate(new Date()).getTime(),
    [invoiceDateDue],
  );
  const formattedDateDue = useMemo(() => getVBillFormattedDate(invoiceDateDue), [invoiceDateDue]);

  const handlePayBillSubmitEnd = () => {
    vBillPayLedgerStore.billsLedgerList.reload?.({ markAsLoading: true });
  };

  const handleSelectToPayCheckboxChange = (checked: boolean) => {
    const selectedBillIds = checked
      ? [...selectedBillIdsToPay, id]
      : selectedBillIdsToPay.filter((vbillId) => vbillId !== id);

    vBillPayLedgerStore.updateSelectedBillIdsToPay(selectedBillIds);
  };

  return (
    <>
      <TableRow sx={{ cursor: "default" }}>
        <TableCell sx={{ padding: "5px 16px" }}>
          {showSelectToPayCheckbox && (
            <Tooltip title={isSelectToPay ? "Remove from payment" : "Add to payment"}>
              <Checkbox
                color="primary"
                checked={isSelectToPay}
                onChange={(e) => handleSelectToPayCheckboxChange(e.target.checked)}
              />
            </Tooltip>
          )}
        </TableCell>

        <TableCell sx={defaultTableCellSx}>
          <Box component={Link} to={vBillInvoiceUrl} sx={defaultLinkStyles}>
            <Box sx={{ color: COLORS.newSecondaryColor }}>
              {invoiceNumber && <div>#{invoiceNumber}</div>}
              <Typography sx={{ color: COLORS.textGray, fontSize: "12px" }}>Bill #{id}</Typography>
              <Typography sx={{ color: COLORS.textGray, fontSize: "12px" }}>{formattedInvoiceDate}</Typography>
            </Box>
          </Box>
        </TableCell>

        {isAllChildRoute && (
          <TableCell sx={defaultTableCellSx}>
            <Box component={Link} to={vBillInvoiceUrl} sx={{ ...defaultLinkStyles, color: statusColor }}>
              {statusName}
            </Box>
          </TableCell>
        )}

        <TableCell sx={defaultTableCellSx}>
          <Box component={Link} to={vBillInvoiceUrl} sx={defaultLinkStyles}>
            {companyName}
          </Box>
        </TableCell>

        <TableCell sx={defaultTableCellSx}>
          <Box
            component={Link}
            to={vBillInvoiceUrl}
            sx={{ ...defaultLinkStyles, textAlign: "right", whiteSpace: "nowrap" }}
          >
            <PayVBillAmounts amounts={amounts} />
          </Box>
        </TableCell>

        <TableCell sx={defaultTableCellSx}>
          <Box
            component={Link}
            to={vBillInvoiceUrl}
            sx={{ ...defaultLinkStyles, textAlign: "right", whiteSpace: "nowrap" }}
          >
            <PayVBillPayments amounts={amounts} />
          </Box>
        </TableCell>

        <TableCell sx={defaultTableCellSx}>
          <Box component={Link} to={vBillInvoiceUrl} sx={defaultLinkStyles}>
            {invoiceDateDue ? (
              <>
                <Box>{formattedDateDue}</Box>
                <Box sx={{ fontSize: "12px", fontWeight: "400" }}>
                  {isDueToday ? (
                    <Box sx={{ color: COLORS.orange }}>Due today</Box>
                  ) : isOverDue ? (
                    <Box sx={{ color: COLORS.orange }}>Overdue: {overDueDistance}</Box>
                  ) : (
                    <Box sx={{ color: COLORS.textGray }}>Due in: {dueInDistance}</Box>
                  )}
                </Box>
              </>
            ) : (
              "-"
            )}
          </Box>
        </TableCell>

        <TableCell sx={defaultTableCellSx}>
          <Box
            component={Link}
            to={vBillInvoiceUrl}
            sx={{
              ...defaultLinkStyles,
              maxWidth: "200px",
              wordBreak: "break-word",
              fontSize: "12px",
            }}
          >
            {vendorMapping
              ? `${vendorMapping.label ?? ""} ${vendorMapping.label2 ? `- ${vendorMapping.label2}` : ""}`
              : "-"}
          </Box>
        </TableCell>

        {/* <TableCell sx={defaultTableCellSx}>
          <Box component={Link} to={vBillInvoiceUrl} sx={defaultLinkStyles}>
            {payFromMapping
              ? `${payFromMapping.label ?? ""} ${payFromMapping.label2 ? `- ${payFromMapping.label2}` : ""}`
              : "-"}
          </Box>
        </TableCell> */}

        <TableCell sx={defaultTableCellSx}>
          <Box
            component={Link}
            to={vBillInvoiceUrl}
            sx={{
              ...defaultLinkStyles,
              maxWidth: "200px",
              wordBreak: "break-word",
              fontSize: "12px",
              color: COLORS.textGray,
            }}
          >
            {restOfAdditionalMappings.length
              ? restOfAdditionalMappings.map(({ key, label, label2, type }, index) => (
                  <Box key={key}>
                    {
                      additionalMappingsSettingsData?.find(
                        (addMapping) => addMapping.storeDataType === (type as unknown as IVBillIIntegrationDataType),
                      )?.label
                    }
                    :{" "}
                    <Box component="span" sx={{ color: COLORS.colorNewPrimary }}>
                      {label ?? ""} {label2 ? `- ${label2}` : ""}
                    </Box>
                  </Box>
                ))
              : "-"}
          </Box>
        </TableCell>
        <TableCell sx={{ padding: "5px 16px" }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <Box component="a" href={vBillInvoiceUrl} target="_blank">
              <Tooltip title="Open invoice in a new tab">
                <IconButton>
                  <LinkIcon />
                </IconButton>
              </Tooltip>
            </Box>

            {!organizationStore.organizationVBillPayBtnEnabled ? (
              <Tooltip title="Payment must be enabled in integration">
                <ErrorOutlineIcon sx={{ color: COLORS.orange, fontSize: "20px" }} />
              </Tooltip>
            ) : showPayBtn ? (
              <Button
                onClick={() => setIsPayBillDialogOpen(true)}
                variant="outlined"
                sx={{
                  color: COLORS.newSecondaryColor,
                  fontWeight: 500,
                  border: `1px solid ${COLORS.newSecondaryColor}`,
                }}
              >
                Pay
              </Button>
            ) : null}
          </Box>
        </TableCell>
      </TableRow>

      {isPayBillDialogOpen && (
        <VBillPayBillDialog
          vBill={vBill}
          onCloseDialog={() => setIsPayBillDialogOpen(false)}
          onSubmitEnd={handlePayBillSubmitEnd}
        />
      )}
    </>
  );
});

export const getSODAsUTCDate = (date: Date) => {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
  );
};
