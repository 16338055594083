import { Badge, Box, IconButton, SxProps, Theme } from "@mui/material";
import { useDocumentVisibilityChange } from "common/hooks/useDocumentVisibilityChange";
import { ChatBubbleOutlineIcon } from "components/common/icons";
import { observer } from "mobx-react";
import { chatMemberStore } from "modules/Chat";
import { IGetChatNotificationUrlArgs } from "modules/Chat/utils/utils";
import { useEffect, useState } from "react";
import { COLORS } from "themes/default";
import { ChatMemberLatestMessages } from "./ChatMemberLatestMessages/ChatMemberLatestMessages";
import { ChatMemberNotificationsStats } from "./ChatMemberNotificationsStats/ChatMemberNotificationsStats";

interface IChatSummaryBadgeProps {
  organizationId?: string;
  showMemberLatestMessages?: boolean;
  onChannelNotificationClick: (channel: IGetChatNotificationUrlArgs) => void;
  sx?: SxProps<Theme>;
  chatIconSx?: SxProps<Theme>;
  chatBadgeSx?: SxProps<Theme>;
}

export const ChatSummaryBadge = observer(function ChatSummaryBadge({
  organizationId,
  showMemberLatestMessages,
  onChannelNotificationClick,
  sx,
  chatIconSx,
  chatBadgeSx,
}: IChatSummaryBadgeProps) {
  const [chatNotificationsAnchorEl, setChatNotificationsAnchorEl] = useState<HTMLButtonElement | null>(null);
  const chatNotificationsOpen = Boolean(chatNotificationsAnchorEl);
  const documentVisible = useDocumentVisibilityChange();

  useEffect(() => {
    if (documentVisible) {
      chatMemberStore.subscribeToNotifications();
    } else {
      chatMemberStore.unSubscribeToNotifications();
    }
  }, [documentVisible]);

  useEffect(() => {
    chatMemberStore.setOrganizationId(organizationId);
    chatMemberStore.loadInitialMemberLatestMessages();
  }, [organizationId]);

  const handleChannelNotificationClick = (channel: IGetChatNotificationUrlArgs) => {
    setChatNotificationsAnchorEl(null);
    onChannelNotificationClick(channel);
  };

  const menuOpen = Boolean(chatNotificationsAnchorEl);
  const memberNotificationsStats = chatMemberStore.memberNotificationsStats;

  return (
    <Box sx={sx}>
      <IconButton
        id="chat-badge-notifications-btn"
        aria-controls={chatNotificationsOpen ? "chat-badge-notifications-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={chatNotificationsOpen ? "true" : undefined}
        onClick={(event) => setChatNotificationsAnchorEl(event.currentTarget)}
        disableRipple
      >
        <Badge
          badgeContent={memberNotificationsStats?.totalCount}
          sx={{
            "& .MuiBadge-badge": {
              color: COLORS.white,
              backgroundColor: COLORS.logoBlue,
            },
            ...chatBadgeSx,
          }}
        >
          <ChatBubbleOutlineIcon sx={{ fontSize: "24px", color: COLORS.logoBlue, ...chatIconSx }} />
        </Badge>
      </IconButton>

      {menuOpen && (
        <>
          {showMemberLatestMessages ? (
            <ChatMemberLatestMessages
              menuAnchorEl={chatNotificationsAnchorEl}
              menuOnClose={() => setChatNotificationsAnchorEl(null)}
              onChannelNotificationClick={handleChannelNotificationClick}
            />
          ) : (
            <ChatMemberNotificationsStats
              menuAnchorEl={chatNotificationsAnchorEl}
              menuOnClose={() => setChatNotificationsAnchorEl(null)}
              onChannelNotificationClick={handleChannelNotificationClick}
            />
          )}

          {/* <Backdrop
            open
            onClick={() => setChatNotificationsAnchorEl(null)}
            sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
          /> */}
        </>
      )}
    </Box>
  );
});
