import { autorun } from "mobx";
import { useEffect, useState } from "react";
import { useStore } from "storeContainer";

function useUndepositedChecks() {
  const { undepositedChecks } = useStore("UndepositedChecksStore");
  const [hasUndepositedChecks, setHasUndepositedChecks] = useState(false);
  const [totalUndepositedChecks, setTotalUndepositedChecks] = useState(0);

  useEffect(() => {
    const disposer = autorun(() => {
      const count = undepositedChecks.data?.undeposited_checks?.count || 0;
      setHasUndepositedChecks(count > 0);
      setTotalUndepositedChecks(count);
    });

    return () => disposer();
  }, [undepositedChecks]);

  return { hasUndepositedChecks, totalUndepositedChecks };
}

export default useUndepositedChecks;
