import { Box, Typography } from "@mui/material";
import { chatMessageMentionTemplateTest } from "common/helpers/utils";
import { chatStaticEmojiList } from "common/static/chatStaticEmojiList";
import {
  IActionlogActionlogIoMetaAdditionalContentBlockOutput,
  IActionlogObjectType,
} from "generated/graphql.actionlog";
import { IChatContentBlockMessageRelatedType } from "generated/graphql.chat";
import { CHAT_MSG_REACTION_INLINE_PREFIX } from "modules/Chat/utils/utils";
import { FunctionComponent } from "react";

interface IAdditionalContentMessageProps {
  additionalContent: IActionlogActionlogIoMetaAdditionalContentBlockOutput;
}

export const AdditionalContentMessage: FunctionComponent<IAdditionalContentMessageProps> = ({
  additionalContent: { message, related, template },
}) => {
  const messageBlocks = message?.split(chatMessageMentionTemplateTest);

  return (
    <Box>
      {template && <Typography sx={{ fontSize: 14 }}>{template}</Typography>}

      <Typography sx={{ fontSize: 14 }}>
        {(messageBlocks ?? []).map((block, index) => {
          if (block.startsWith(`{{${IChatContentBlockMessageRelatedType.Account}:`) && block.endsWith("}}")) {
            const accountIdMatch = block.substring(
              3 + IChatContentBlockMessageRelatedType.Account.length,
              block.length - 2,
            );

            const member = related
              ?.filter(
                ({ related }) =>
                  // IActionlogObjectType' and 'IChatContentBlockMessageRelatedType' have no overlap.
                  related.type === (IChatContentBlockMessageRelatedType.Account as unknown as IActionlogObjectType),
              )
              .find(({ related }) => related.key === accountIdMatch);

            return (
              member && (
                <Box key={`${block}-${index}`} component="span">
                  @{member?.related.label}
                </Box>
              )
            );
          }

          if (block.startsWith(`{{${IChatContentBlockMessageRelatedType.Bill}:`) && block.endsWith("}}")) {
            const accountIdMatch = block.substring(
              3 + IChatContentBlockMessageRelatedType.Bill.length,
              block.length - 2,
            );
            const bill = related
              ?.filter(
                ({ related }) =>
                  // IActionlogObjectType' and 'IChatContentBlockMessageRelatedType' have no overlap.
                  related.type === (IChatContentBlockMessageRelatedType.Bill as unknown as IActionlogObjectType),
              )
              ?.find(({ related }) => related.key === accountIdMatch);

            return (
              bill && (
                <Box key={`${block}-${index}`} component="span">
                  #{bill?.related.label}
                </Box>
              )
            );
          }

          if (block.startsWith(`{{${CHAT_MSG_REACTION_INLINE_PREFIX}:`) && block.endsWith("}}")) {
            const accountIdMatch = block.substring(`{{${CHAT_MSG_REACTION_INLINE_PREFIX}:`.length, block.length - 2);
            const reaction = related
              ?.filter(
                ({ related }) =>
                  // IActionlogObjectType' and 'IChatContentBlockMessageRelatedType' have no overlap.
                  related.type === (IChatContentBlockMessageRelatedType.Reaction as unknown as IActionlogObjectType),
              )
              ?.find(({ related }) => related.key === accountIdMatch);

            return (
              reaction && (
                <Box
                  key={`${block}-${index}`}
                  component="span"
                  role="img"
                  dangerouslySetInnerHTML={{
                    __html: chatStaticEmojiList.find(({ name }) => name === reaction.related.label)?.hex ?? "",
                  }}
                />
              )
            );
          }

          return <span key={`${block}-${index}`}>{block}</span>;
        })}
      </Typography>
    </Box>
  );
};
