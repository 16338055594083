import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import ProfileMenu from "components/layout/ProfileMenu";
import { AutoDepositRuleGraphQlType } from "generated/sdk";
import { SortIcon } from "icons/svg/SortIcon";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useStore } from "storeContainer";
import AddEditRuleDrawer from "./AddEditRuleDrawer";
import style from "./AddRulePage.module.scss";
import ConfirmDeleteModal from "./ConfirmDeleteModal";

const AutoDepositRules = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedRule, setSelectedRule] = useState<AutoDepositRuleGraphQlType>();
  const [ruleIdToBeDeleted, setRuleIdToBeDeleted] = useState<string>("");
  const [showReviewRules, setShowReviewRules] = useState(false);
  const [deleteRuleLoading, setDeleteRuleLoading] = useState(false);
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState(false);
  const [drawerSource, setDrawerSource] = useState("");
  const [errorFeedback, setErrorFeedback] = useState("");
  const SessionStore = useStore("SessionStore");
  const AutoDepositRulesStore = useStore("AutoDepositRulesStore");

  const { session } = SessionStore;

  const history = useHistory();
  const phoneConfirmed = session.data?.account?.phone_number_verified;

  const handleEditRule = async (rule: AutoDepositRuleGraphQlType) => {
    setSelectedRule(rule);
    setIsDrawerOpen(true);
    setDrawerSource("edit");
  };
  const handleAddRule = () => {
    setIsDrawerOpen(true);
    setSelectedRule(undefined);
    setDrawerSource("add");
  };
  const handleReprioritizeRule = () => {
    setIsDrawerOpen(true);
    setShowReviewRules(true);
    setDrawerSource("reprioritize");
  };
  const handleConfirmOpenDeleteRuleModal = (id: string) => {
    setRuleIdToBeDeleted(id);
    setIsConfirmDeleteModalOpen(true);
  };
  const handleDeleteRule = async () => {
    setDeleteRuleLoading(true);
    try {
      await AutoDepositRulesStore.deleteAutodepositRule(ruleIdToBeDeleted);
      await AutoDepositRulesStore.getAutoDepositRules(session.data?.account?.email_address_id!, true);
    } catch (err) {
      setErrorFeedback("An error occurred while processing your request.");
      setDeleteRuleLoading(false);
      return;
    }

    setDeleteRuleLoading(false);
    setSelectedRule(undefined);
    setIsConfirmDeleteModalOpen(false);
    setRuleIdToBeDeleted("");
    setDrawerSource("");
    setErrorFeedback("");
    setIsDrawerOpen(false);
  };

  const shouldReprioritizeMainView = () => {
    function hasMainRule() {
      return AutoDepositRulesStore.autodepositRules.data?.auto_deposit_rules?.some((item) => {
        return (
          item?.conditions.pttoos?.length === 0 &&
          item?.conditions.senders?.length === 0 &&
          item?.conditions.memos?.length === 0
        );
      });
    }

    if (AutoDepositRulesStore.autodepositRules.data?.auto_deposit_rules?.length! < 2) {
      return false;
    } else if (hasMainRule() && AutoDepositRulesStore.autodepositRules.data?.auto_deposit_rules?.length! === 2) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (!phoneConfirmed) {
      history.push("/org");
    }
  }, [history, phoneConfirmed]);

  useEffect(() => {
    AutoDepositRulesStore.getAutoDepositRules(session.data?.account?.email_address_id!);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid
      style={{
        maxWidth: "1440px",
        margin: "auto",
        marginTop: "50px",
        height: "100%",
      }}
      alignItems={"stretch"}
      container
      item
    >
      <Grid height={"100%"} alignSelf={"flex-start"} xs={2} item>
        <ProfileMenu />
      </Grid>
      <Grid sx={{ maxWidth: "100px" }} xs={10} item>
        <Box paddingX="10%" marginTop="100px" display="flex" flexDirection="column">
          <Box display="flex" flexDirection={"column"} gap="10px">
            <Typography variant="h1" fontWeight="600" fontSize="24px" lineHeight="33px" color="#16458B">
              Auto Deposit {`(${AutoDepositRulesStore.autodepositRules.data?.auto_deposit_rules?.length ?? 0})`}
            </Typography>

            <Typography marginY="10px" variant="body1" lineHeight="18px" color="#697281">
              Manage personal Auto Deposit rules for your email address. The rules will be applied, in order, from the
              first down to the last and when a rule is matched, the check will get deposited to the matching bank
              account.
            </Typography>
          </Box>
          <Box display="flex" alignItems={"flex-end"} justifyContent={"flex-end"} marginTop="40px" marginBottom="20px">
            {shouldReprioritizeMainView() && (
              <Button
                sx={{
                  marginRight: "40px",
                  display: "flex",
                  alignItems: "center",
                }}
                variant="outlined"
                onClick={handleReprioritizeRule}
                startIcon={<SortIcon sx={{ fontSize: "12px" }} />}
              >
                <Typography variant="h4" color="#2877EC">
                  Reprioritize Rules
                </Typography>
              </Button>
            )}
            <Box display="flex" flexDirection={"column"} justifyContent="space-between">
              <Tooltip
                title={
                  AutoDepositRulesStore.autodepositRules.data?.auto_deposit_rules?.length! >= 250
                    ? "You are only allowed to have a maximum of 250 rules!"
                    : ""
                }
              >
                <Box sx={{ height: "fit-content" }}>
                  <Button
                    variant="contained"
                    disabled={AutoDepositRulesStore.autodepositRules.data?.auto_deposit_rules?.length! >= 250}
                    onClick={handleAddRule}
                  >
                    <AddIcon
                      sx={{
                        color: "white",
                        transform: "scale(1.2)",
                        marginRight: "10px",
                      }}
                    />
                    <Typography color={"white"}>Add rule</Typography>
                  </Button>
                </Box>
              </Tooltip>
            </Box>
          </Box>
          <TableContainer style={{ marginTop: "16px" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant="h4" color="#697281" textTransform="capitalize">
                      Email Address
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h4" color="#697281" textTransform="capitalize">
                      Criteria
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h4" color="#697281" textTransform="capitalize">
                      Auto deposit Account
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h4" color="#697281" textTransform="capitalize">
                      Actions
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!AutoDepositRulesStore.autodepositRules.isLoading &&
                  AutoDepositRulesStore.autodepositRules.data?.auto_deposit_rules
                    ?.slice()
                    // `observableArray.sort()` mutates the array in-place, which is not allowed inside a derivation so we use slice first.
                    ?.sort((a, b) => {
                      const isMasterRuleA =
                        !a?.conditions.memos?.length && !a?.conditions.senders?.length && !a?.conditions.pttoos?.length;
                      const isMasterRuleB =
                        !b?.conditions.memos?.length && !b?.conditions.senders?.length && !b?.conditions.pttoos?.length;

                      return (isMasterRuleA ? 1 : 0) - (isMasterRuleB ? 1 : 0);
                    })
                    ?.map((rule, index) => {
                      const isMasterRule =
                        !rule?.conditions.memos?.length &&
                        !rule?.conditions.senders?.length &&
                        !rule?.conditions.pttoos?.length;
                      return (
                        <TableRow key={`${rule?.bank_account.id}-${index}`}>
                          <TableCell>
                            <Typography variant="body1" sx={{ marginBottom: "5px" }}>
                              {rule?.email_address.email}
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={{
                              wordBreak: "break-all",
                            }}
                          >
                            {!!rule?.conditions.pttoos?.length && (
                              <Box display="flex" gap="5px">
                                <Typography style={{ whiteSpace: "nowrap" }} variant="body1" fontWeight="500">
                                  PTTOO =
                                </Typography>
                                <Typography variant="body1" fontWeight="400">{` ${rule?.conditions.pttoos?.join(
                                  " OR ",
                                )}`}</Typography>
                              </Box>
                            )}
                            {!!rule?.conditions.senders?.length && (
                              <Box display="flex" gap="5px">
                                <Typography variant="body1" fontWeight="500">
                                  FROM ={" "}
                                </Typography>
                                <Typography variant="body1" fontWeight="400">{`${rule?.conditions.senders?.join(
                                  " OR ",
                                )}`}</Typography>
                              </Box>
                            )}
                            {!!rule?.conditions.memos?.length && (
                              <Box display="flex" gap="5px">
                                <Typography variant="body1" fontWeight="500">
                                  MEMO ={" "}
                                </Typography>
                                <Typography variant="body1" fontWeight="400">{`${rule?.conditions.memos?.join(
                                  " OR ",
                                )}`}</Typography>
                              </Box>
                            )}
                            {isMasterRule && (
                              <Box display="flex" gap="5px">
                                <Typography variant="h4">Main rule</Typography>
                                <Typography
                                  variant="body1"
                                  fontSize="13px"
                                  lineHeight="16px"
                                  fontWeight="400"
                                  color="#697281"
                                >
                                  (default for all vChecks)
                                </Typography>
                              </Box>
                            )}
                          </TableCell>
                          <TableCell
                            sx={{
                              wordBreak: "break-all",
                              maxWidth: "330px",
                            }}
                          >
                            <Typography variant="h4" color="#052048">
                              {rule?.bank_account?.name} ** {rule?.bank_account.account_number}
                            </Typography>
                            {rule?.organization_id && rule?.company_id && (
                              <Box display="flex" gap="5px" marginTop="5px">
                                <Typography variant="h5" color="#052048">
                                  {rule?.organization_name}
                                </Typography>
                                {">"}
                                <Link
                                  className={style.orgLink}
                                  to={`/org/${rule?.organization_id}/company/${rule?.company_id}`}
                                >
                                  {rule?.company_name}
                                </Link>
                              </Box>
                            )}
                          </TableCell>
                          <TableCell>
                            <Box display="flex">
                              <IconButton onClick={() => handleEditRule(rule!)}>
                                <EditIcon />
                              </IconButton>
                              <IconButton onClick={() => handleConfirmOpenDeleteRuleModal(rule?.id!)}>
                                <DeleteIcon />
                              </IconButton>
                            </Box>
                          </TableCell>
                        </TableRow>
                      );
                    })}
              </TableBody>
            </Table>
          </TableContainer>
          <ConfirmDeleteModal
            handleSubmit={handleDeleteRule}
            loading={deleteRuleLoading}
            ruleIdToBeDeleted={ruleIdToBeDeleted}
            isConfirmDeleteModalOpen={isConfirmDeleteModalOpen}
            setIsDeleteConfirmModalOpen={setIsConfirmDeleteModalOpen}
            errorFeedback={errorFeedback}
            setErrorFeedback={setErrorFeedback}
          />
          <AddEditRuleDrawer
            handleConfirmOpenDeleteRuleModal={handleConfirmOpenDeleteRuleModal}
            isDrawerOpen={isDrawerOpen}
            setIsDrawerOpen={setIsDrawerOpen}
            rule={selectedRule}
            setSelectedRule={setSelectedRule}
            setShowReviewRules={setShowReviewRules}
            showReviewRules={showReviewRules}
            drawerSource={drawerSource}
            showConfirmButton={!shouldReprioritizeMainView()}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default observer(AutoDepositRules);
