import gql from "graphql-tag";
import { GraphQLClient } from "graphql-request";
import { RequestInit } from "graphql-request/dist/types.dom";
import { useQuery, useMutation, UseQueryOptions, UseMutationOptions } from "@tanstack/react-query";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never };

function fetcher<TData, TVariables extends { [key: string]: any }>(
  client: GraphQLClient,
  query: string,
  variables?: TVariables,
  requestHeaders?: RequestInit["headers"],
) {
  return async (): Promise<TData> =>
    client.request({
      document: query,
      variables,
      requestHeaders,
    });
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /**
   * The `Upload` special type represents a file to be uploaded in the same HTTP request as specified by
   *  [graphql-multipart-request-spec](https://github.com/jaydenseric/graphql-multipart-request-spec).
   */
  Upload: { input: any; output: any };
};

/** The VCard Transaction status values */
export enum AclEntityTypeEnum {
  BankAccount = "BANK_ACCOUNT",
  Company = "COMPANY",
  Organization = "ORGANIZATION",
}

/** An ACL object */
export type AclInput = {
  /** ACL permission id (required from removing or updating) */
  acl_permission_id?: InputMaybe<Scalars["String"]["input"]>;
  /** Can be "add" or "remove" */
  action: Scalars["String"]["input"];
  /** The entity id */
  entity_id: Scalars["String"]["input"];
  /** The entity type */
  entity_type: AclEntityTypeEnum;
  /** The group id */
  group_alias: Scalars["String"]["input"];
  /** The company user Id */
  organization_user_id?: InputMaybe<Scalars["String"]["input"]>;
  /** User group id */
  user_group_id?: InputMaybe<Scalars["String"]["input"]>;
};

/** An ACLPermission object */
export type AclPermission = {
  __typename?: "ACLPermission";
  /** Bank account this permission applies to (only if entity_type = BankAccount) */
  bank_account?: Maybe<BankAccountBasic>;
  /** Company this permission applies to (only if entity_type = Company) */
  company?: Maybe<Company>;
  /** Entity type */
  entity_type: Scalars["String"]["output"];
  /** ID */
  id: Scalars["String"]["output"];
  /** Organization this permission applies to (always present) */
  organization?: Maybe<Organization>;
  /** OrganizationUser object (present if permission is applied directly to a user) */
  organization_user?: Maybe<OrganizationUser>;
  /** Permission group */
  permission_group?: Maybe<PermissionGroup>;
  /** User Group (present if permission is applied to a user group) */
  user_group?: Maybe<UserGroup>;
  /** VCard this permission applies to (only if entity_type = VCard) */
  vcard?: Maybe<VCard>;
  /** VCardCompanyAccount this permission applies to (only if entity_type = VCardCompanyAccount) */
  vcard_company_account?: Maybe<VCardCompanyAccount>;
};

/** An ACLPermission object */
export type AclPermissionFlat = {
  __typename?: "ACLPermissionFlat";
  /** Bank account this permission applies to (only if entity_type = BankAccount) */
  bank_account_id?: Maybe<Scalars["String"]["output"]>;
  /** Company this permission applies to (only if entity_type = Company) */
  company_id?: Maybe<Scalars["String"]["output"]>;
  limit_approve_vchecks_max_amount?: Maybe<Scalars["Float"]["output"]>;
  limit_create_vchecks_rules?: Maybe<Array<LimitCreateVChecksRule>>;
  limit_delete_vchecks_max_amount?: Maybe<Scalars["Float"]["output"]>;
  limit_ledger_access_max_amount?: Maybe<Scalars["Float"]["output"]>;
  limit_manage_vchecks_max_amount?: Maybe<Scalars["Float"]["output"]>;
  limit_update_memo_vchecks_max_amount?: Maybe<Scalars["Float"]["output"]>;
  limit_update_notes_vchecks_max_amount?: Maybe<Scalars["Float"]["output"]>;
  limit_update_pttoo_vchecks_max_amount?: Maybe<Scalars["Float"]["output"]>;
  limit_void_vchecks_max_amount?: Maybe<Scalars["Float"]["output"]>;
  /** Organization this permission applies to (always present) */
  organization_id: Scalars["String"]["output"];
  organization_user_id: Scalars["String"]["output"];
  /** Permission */
  permission: AclPermissionTypeEnum;
  /** Permission scope */
  permission_scope: AclEntityTypeEnum;
};

/** Permission types status values */
export enum AclPermissionTypeEnum {
  BankAccountAutoDepositAddressesCreate = "BANK_ACCOUNT_AUTO_DEPOSIT_ADDRESSES_CREATE",
  BankAccountAutoDepositAddressesList = "BANK_ACCOUNT_AUTO_DEPOSIT_ADDRESSES_LIST",
  BankAccountAutoDepositAddressesRemove = "BANK_ACCOUNT_AUTO_DEPOSIT_ADDRESSES_REMOVE",
  BankAccountChecksDelete = "BANK_ACCOUNT_CHECKS_DELETE",
  BankAccountChecksDeposit = "BANK_ACCOUNT_CHECKS_DEPOSIT",
  BankAccountChecksListOther = "BANK_ACCOUNT_CHECKS_LIST_OTHER",
  BankAccountChecksListOwn = "BANK_ACCOUNT_CHECKS_LIST_OWN",
  BankAccountChecksSign = "BANK_ACCOUNT_CHECKS_SIGN",
  BankAccountChecksUpdateMemo = "BANK_ACCOUNT_CHECKS_UPDATE_MEMO",
  BankAccountChecksUpdateNotes = "BANK_ACCOUNT_CHECKS_UPDATE_NOTES",
  BankAccountChecksUpdatePttoo = "BANK_ACCOUNT_CHECKS_UPDATE_PTTOO",
  BankAccountChecksVoidOther = "BANK_ACCOUNT_CHECKS_VOID_OTHER",
  BankAccountChecksVoidOwn = "BANK_ACCOUNT_CHECKS_VOID_OWN",
  BankAccountChecksWrite = "BANK_ACCOUNT_CHECKS_WRITE",
  BankAccountLinkedAddressesCreate = "BANK_ACCOUNT_LINKED_ADDRESSES_CREATE",
  BankAccountLinkedAddressesList = "BANK_ACCOUNT_LINKED_ADDRESSES_LIST",
  BankAccountLinkedAddressesRemove = "BANK_ACCOUNT_LINKED_ADDRESSES_REMOVE",
  BankAccountList = "BANK_ACCOUNT_LIST",
  BankAccountManage = "BANK_ACCOUNT_MANAGE",
  BankAccountUpgrade = "BANK_ACCOUNT_UPGRADE",
  CompanyContactsList = "COMPANY_CONTACTS_LIST",
  CompanyGlAccountsList = "COMPANY_GL_ACCOUNTS_LIST",
  CompanyIntegrationCreate = "COMPANY_INTEGRATION_CREATE",
  CompanyIntegrationImportPendingArchive = "COMPANY_INTEGRATION_IMPORT_PENDING_ARCHIVE",
  CompanyIntegrationList = "COMPANY_INTEGRATION_LIST",
  CompanyIntegrationMapBa = "COMPANY_INTEGRATION_MAP_BA",
  CompanyIntegrationMapBaList = "COMPANY_INTEGRATION_MAP_BA_LIST",
  CompanyIntegrationMapCards = "COMPANY_INTEGRATION_MAP_CARDS",
  CompanyIntegrationMapCardsList = "COMPANY_INTEGRATION_MAP_CARDS_LIST",
  CompanyIntegrationMapUsers = "COMPANY_INTEGRATION_MAP_USERS",
  CompanyIntegrationMapUsersList = "COMPANY_INTEGRATION_MAP_USERS_LIST",
  CompanyIntegrationRemove = "COMPANY_INTEGRATION_REMOVE",
  CompanyIntegrationUpdateSettings = "COMPANY_INTEGRATION_UPDATE_SETTINGS",
  CompanyList = "COMPANY_LIST",
  CompanyNotifications = "COMPANY_NOTIFICATIONS",
  CompanyVbillEdit = "COMPANY_VBILL_EDIT",
  CompanyVbillList = "COMPANY_VBILL_LIST",
  CompanyVbillMapAdditional = "COMPANY_VBILL_MAP_ADDITIONAL",
  CompanyVbillMapGl = "COMPANY_VBILL_MAP_GL",
  CompanyVbillMapVendor = "COMPANY_VBILL_MAP_VENDOR",
  CompanyVbillPay = "COMPANY_VBILL_PAY",
  CompanyVbillPayList = "COMPANY_VBILL_PAY_LIST",
  OrganizationBankAccountAdd = "ORGANIZATION_BANK_ACCOUNT_ADD",
  OrganizationCompanyAdd = "ORGANIZATION_COMPANY_ADD",
  OrganizationManage = "ORGANIZATION_MANAGE",
  OrganizationManageCompanyGroups = "ORGANIZATION_MANAGE_COMPANY_GROUPS",
  OrganizationUsersList = "ORGANIZATION_USERS_LIST",
  OrganizationUsersManage = "ORGANIZATION_USERS_MANAGE",
  OrganizationVbillMapCompany = "ORGANIZATION_VBILL_MAP_COMPANY",
}

/** Breakdown ACL Source */
export enum AclSourceEnum {
  Aggregated = "aggregated",
  Direct = "direct",
  Inherited = "inherited",
}

/** An Account object. */
export type Account = {
  __typename?: "Account";
  /** The email address linked to this account */
  email: Scalars["String"]["output"];
  /** The email address id linked to this account */
  email_address_id: Scalars["String"]["output"];
  /** Specifies if the Account Email Address has been verified by the User. */
  email_verified?: Maybe<Scalars["Boolean"]["output"]>;
  /** Account id */
  id: Scalars["String"]["output"];
  is_vendor?: Maybe<Scalars["Boolean"]["output"]>;
  /** The name on the account */
  name: Scalars["String"]["output"];
  /** The phone number linked to this account */
  phone_number?: Maybe<Scalars["String"]["output"]>;
  /** Specifies if the Account Phone Number has been verified by the User. */
  phone_number_verified?: Maybe<Scalars["Boolean"]["output"]>;
  /** The username */
  username: Scalars["String"]["output"];
};

/** An Account Details object. */
export type AccountDetails = {
  __typename?: "AccountDetails";
  /** The address for this account */
  address?: Maybe<Scalars["String"]["output"]>;
  /** Email Addresses linked to this Account */
  email?: Maybe<EmailAddress>;
  /** Account id */
  id: Scalars["String"]["output"];
  /** The name of the account */
  name: Scalars["String"]["output"];
  /** The phone number linked to this account */
  phone_number?: Maybe<Scalars["String"]["output"]>;
  /** The username of the account */
  username: Scalars["String"]["output"];
};

/** The State */
export enum AccountStateEnum {
  Active = "ACTIVE",
  Created = "CREATED",
  Deleted = "DELETED",
  DeleteRequested = "DELETE_REQUESTED",
  Guest = "GUEST",
  Invited = "INVITED",
  Locked = "LOCKED",
}

export type AccountType = {
  __typename?: "AccountType";
  email?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  name?: Maybe<Scalars["String"]["output"]>;
};

/** The Filters Input object */
export type AclPermissionsFiltersInput = {
  /** The Organization User id */
  organization_user_id?: InputMaybe<Scalars["String"]["input"]>;
  /** The Permission Group id */
  permission_group_id?: InputMaybe<Scalars["String"]["input"]>;
  /** The User Group id */
  user_group_id?: InputMaybe<Scalars["String"]["input"]>;
};

/** An Address object. */
export type Address = {
  __typename?: "Address";
  /** The address */
  address?: Maybe<Scalars["String"]["output"]>;
  /** The address second line */
  address2?: Maybe<Scalars["String"]["output"]>;
  /** City */
  city?: Maybe<Scalars["String"]["output"]>;
  /** The country */
  country?: Maybe<Scalars["String"]["output"]>;
  /** The id of the address */
  id: Scalars["String"]["output"];
  /** The governing district */
  state?: Maybe<Scalars["String"]["output"]>;
  /** The postal area */
  zip_code?: Maybe<Scalars["String"]["output"]>;
};

/** An Address object. */
export type AddressInput = {
  /** The address */
  address: Scalars["String"]["input"];
  /** The address second line */
  address2?: InputMaybe<Scalars["String"]["input"]>;
  /** The municipality */
  city: Scalars["String"]["input"];
  /** The state (2-character code) */
  state: Scalars["String"]["input"];
  /** The zip code (5 digits) */
  zip_code: Scalars["String"]["input"];
};

/** A Company Notification object */
export type AppNotifications = {
  __typename?: "AppNotifications";
  account_id?: Maybe<Scalars["String"]["output"]>;
  /** The notification action url. */
  action_url?: Maybe<Scalars["String"]["output"]>;
  /** The notification channel. */
  channel?: Maybe<Scalars["String"]["output"]>;
  company_id?: Maybe<Scalars["String"]["output"]>;
  /** Created at. */
  created_at?: Maybe<Scalars["String"]["output"]>;
  /** The id of the Company Notification. */
  id: Scalars["String"]["output"];
  /** The notification level. */
  level?: Maybe<Scalars["String"]["output"]>;
  /** The notification long description. */
  long_desc?: Maybe<Scalars["String"]["output"]>;
  /** The notification option 1 title. */
  option_1_title?: Maybe<Scalars["String"]["output"]>;
  /** The notification option 1 url. */
  option_1_url?: Maybe<Scalars["String"]["output"]>;
  /** The notification option 2title. */
  option_2_title?: Maybe<Scalars["String"]["output"]>;
  /** The notification option 2 url. */
  option_2_url?: Maybe<Scalars["String"]["output"]>;
  /** The notification short description. */
  short_desc: Scalars["String"]["output"];
  /** The notification title. */
  title?: Maybe<Scalars["String"]["output"]>;
  /** If the notification was viewed. */
  viewed?: Maybe<Scalars["String"]["output"]>;
};

/** An Aplied Permission Group object */
export type AppliedPermissionGroup = {
  __typename?: "AppliedPermissionGroup";
  /** The list of permissions for this permission group */
  BANK_ACCOUNT_CHECK_CREATE?: Maybe<Array<BankAccountCheckCreateAclPermission>>;
  /** The list of permissions for this permission group */
  BANK_ACCOUNT_CHECK_MANAGE?: Maybe<Array<BankAccountManageCheckAclPermission>>;
  /** The list of permissions for this permission group */
  BANK_ACCOUNT_CHECK_SIGNER?: Maybe<Array<BankAccountCheckSignerAclPermission>>;
  /** The list of permissions for this permission group */
  BANK_ACCOUNT_LEDGER_ACCESS?: Maybe<Array<BankAccountLedgerAccessAclPermission>>;
  /** The list of permissions for this permission group */
  BANK_ACCOUNT_MANAGE?: Maybe<Array<BasePermissionType>>;
  /** The list of permissions for this permission group */
  BANK_ACCOUNT_VIEW?: Maybe<Array<BasePermissionType>>;
  /** The list of permissions for this permission group */
  COMPANY_INTEGRATION_MANAGER?: Maybe<Array<BasePermissionType>>;
  /** The list of permissions for this permission group */
  COMPANY_VBILL_ACCESS?: Maybe<Array<BasePermissionType>>;
  /** The list of permissions for this permission group */
  COMPANY_VBILL_EDIT_MAP?: Maybe<Array<BasePermissionType>>;
  /** The list of permissions for this permission group */
  COMPANY_VBILL_PAYER?: Maybe<Array<BasePermissionType>>;
  /** The list of permissions for this permission group */
  COMPANY_VIEW?: Maybe<Array<BasePermissionType>>;
  /** The list of permissions for this permission group */
  ORGANIZATION_COMPANY_MANAGER?: Maybe<Array<BasePermissionType>>;
  /** The list of permissions for this permission group */
  ORGANIZATION_USER_MANAGER?: Maybe<Array<BasePermissionType>>;
  /** The list of permissions for this permission group */
  ORGANIZATION_VBILL_COMPANY_MAPPER?: Maybe<Array<BasePermissionType>>;
};

/** ApplyACL response object */
export type ApplyAclResponse = {
  __typename?: "ApplyACLResponse";
  /** The error message for the failed operation */
  error?: Maybe<Scalars["String"]["output"]>;
  /** Whether the operation was successful */
  success?: Maybe<Scalars["Boolean"]["output"]>;
};

/** Approval Resolution */
export enum ApprovalResolutionEnum {
  Negative = "NEGATIVE",
  Positive = "POSITIVE",
}

/** Approval types */
export enum ApprovalTypeEnum {
  Approval = "APPROVAL",
  Review = "REVIEW",
}

export enum AsyncProcessingRequestCommandEnum {
  BulkCheckSign = "bulk_check_sign",
}

export enum AsyncProcessingRequestItemStateEnum {
  Completed = "completed",
  Error = "error",
  Pending = "pending",
  Processing = "processing",
}

export type AsyncProcessingRequestItemType = {
  __typename?: "AsyncProcessingRequestItemType";
  id?: Maybe<Scalars["Int"]["output"]>;
  payload?: Maybe<Scalars["String"]["output"]>;
  state?: Maybe<AsyncProcessingRequestItemStateEnum>;
};

export enum AsyncProcessingRequestStateEnum {
  Completed = "completed",
  Error = "error",
  Pending = "pending",
  Processing = "processing",
}

export type AsyncProcessingRequestType = {
  __typename?: "AsyncProcessingRequestType";
  command?: Maybe<AsyncProcessingRequestCommandEnum>;
  id?: Maybe<Scalars["Int"]["output"]>;
  items?: Maybe<Array<Maybe<AsyncProcessingRequestItemType>>>;
  payload?: Maybe<Scalars["String"]["output"]>;
  progress?: Maybe<Scalars["Int"]["output"]>;
  state?: Maybe<AsyncProcessingRequestStateEnum>;
};

/** A Attachment */
export type AttachmentInput = {
  /** Name */
  name: Scalars["String"]["input"];
  /** Full Name */
  path: Scalars["String"]["input"];
};

export type AttachmentType = {
  __typename?: "AttachmentType";
  context?: Maybe<Scalars["Int"]["output"]>;
  media?: Maybe<MediaType>;
  show_download?: Maybe<Scalars["Boolean"]["output"]>;
  sort_order?: Maybe<Scalars["Int"]["output"]>;
};

/** An auto deposit rule */
export type AutoDepositRuleGraphQlType = {
  __typename?: "AutoDepositRuleGraphQLType";
  /** bank account */
  bank_account: BankAccountBasic;
  /** The id of the company */
  company_id?: Maybe<Scalars["String"]["output"]>;
  /** The name of the company */
  company_name?: Maybe<Scalars["String"]["output"]>;
  /** The conditions for the Auto Deposit Rule */
  conditions: ConditionGraphQlType;
  /** email address */
  email_address: EmailAddress;
  /** The id of the email */
  id?: Maybe<Scalars["String"]["output"]>;
  /** The id of the organization */
  organization_id?: Maybe<Scalars["String"]["output"]>;
  /** The name of the organization */
  organization_name?: Maybe<Scalars["String"]["output"]>;
};

/** List of AutoDepositRules with priority */
export type AutoDepositRulesForEmailAddressGraphQlType = {
  __typename?: "AutoDepositRulesForEmailAddressGraphQLType";
  /** List of Auto Deposit Rules */
  auto_deposit_rules?: Maybe<Array<Maybe<AutoDepositRuleGraphQlType>>>;
  /** Auto Deposit Rules Priority */
  rule_priority?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

/** A BankAccount object */
export type BankAccount = {
  __typename?: "BankAccount";
  /** Bank Account creation date. */
  account?: Maybe<BasicAccount>;
  /** The bank account number */
  account_number?: Maybe<Scalars["String"]["output"]>;
  /** List of auto deposit addresses */
  auto_deposit_addresses?: Maybe<Array<BankAccountAutoDepositAddress>>;
  /** The bank name of this bank account */
  bank_name: Scalars["String"]["output"];
  /** The company owning the bank account */
  company_id?: Maybe<Scalars["String"]["output"]>;
  /** Bank Account creation date. */
  created_at?: Maybe<Scalars["String"]["output"]>;
  /** Whether the bank account is deposit-only */
  deposit_only: Scalars["Boolean"]["output"];
  /** General ledger accounts linked to this bank account */
  gl_accounts?: Maybe<Array<GeneralLedgerAccount>>;
  /** The id of the bank account */
  id: Scalars["String"]["output"];
  /** The legal first name of the Bank Account */
  legal_first_name?: Maybe<Scalars["String"]["output"]>;
  /** The legal last name of the Bank Account */
  legal_last_name?: Maybe<Scalars["String"]["output"]>;
  /** List of linked addresses */
  linked_addresses?: Maybe<Array<LinkedAddress>>;
  /** The bank account name */
  name: Scalars["String"]["output"];
  /** The bank account routing number */
  routing_number?: Maybe<Scalars["String"]["output"]>;
  /** A link to upload verification documents */
  verification_link?: Maybe<Scalars["String"]["output"]>;
  /** The state of the bank account verification */
  verification_status: Scalars["String"]["output"];
};

/** A bank account auto deposit address */
export type BankAccountAutoDepositAddress = {
  __typename?: "BankAccountAutoDepositAddress";
  /** The user who linked the address */
  created_by?: Maybe<Scalars["String"]["output"]>;
  /** The address */
  email?: Maybe<Scalars["String"]["output"]>;
  /** The id of the email */
  id?: Maybe<Scalars["String"]["output"]>;
  /** The source of the address */
  type?: Maybe<Scalars["String"]["output"]>;
};

/** A Bank Account Basic object */
export type BankAccountBasic = {
  __typename?: "BankAccountBasic";
  /** The bank account number */
  account_number: Scalars["String"]["output"];
  /** The bank name of this bank account */
  bank_name: Scalars["String"]["output"];
  company_id?: Maybe<Scalars["String"]["output"]>;
  company_name?: Maybe<Scalars["String"]["output"]>;
  /** The id of the bank account */
  id: Scalars["String"]["output"];
  /** The bank account name */
  name: Scalars["String"]["output"];
  organization_id?: Maybe<Scalars["String"]["output"]>;
  organization_name?: Maybe<Scalars["String"]["output"]>;
  /** The bank account routing number */
  routing_number: Scalars["String"]["output"];
};

/** A Bank Account Basic object */
export type BankAccountBasicWithMappedGeneralLedgerAccounts = {
  __typename?: "BankAccountBasicWithMappedGeneralLedgerAccounts";
  /** The bank account number */
  account_number: Scalars["String"]["output"];
  /** The bank name of this bank account */
  bank_name: Scalars["String"]["output"];
  /** General ledger accounts linked to this bank account */
  gl_accounts?: Maybe<Array<GeneralLedgerAccount>>;
  /** The id of the bank account */
  id: Scalars["String"]["output"];
  /** The bank account name */
  name: Scalars["String"]["output"];
  /** The bank account routing number */
  routing_number: Scalars["String"]["output"];
};

/** A Bank Account Basic object */
export type BankAccountBasicWithNickname = {
  __typename?: "BankAccountBasicWithNickname";
  /** The bank account number */
  account_number: Scalars["String"]["output"];
  /** The bank name of this bank account */
  bank_name: Scalars["String"]["output"];
  /** The id of the bank account */
  id: Scalars["String"]["output"];
  /** The bank account name */
  name: Scalars["String"]["output"];
  /** The bank account nickname */
  nickname?: Maybe<Scalars["String"]["output"]>;
  /** The bank account routing number */
  routing_number: Scalars["String"]["output"];
};

/** An ACL Permission object for Create vChecks */
export type BankAccountCheckCreateAclPermission = {
  __typename?: "BankAccountCheckCreateACLPermission";
  /** The permission source */
  acl_source?: Maybe<AclSourceEnum>;
  /** The entity id */
  entity_id?: Maybe<Scalars["String"]["output"]>;
  /** The entity type */
  entity_type?: Maybe<AclEntityTypeEnum>;
  /** The limit create vChecks rules */
  limit_create_vchecks_rules?: Maybe<Array<LimitCreateVChecksRule>>;
  /** The org user Id */
  organization_user_id?: Maybe<Scalars["String"]["output"]>;
  /** Original entity id */
  orig_entity_id?: Maybe<Scalars["String"]["output"]>;
  /** The original entity type */
  orig_entity_type?: Maybe<AclEntityTypeEnum>;
  /** User group id */
  user_group_id?: Maybe<Scalars["String"]["output"]>;
};

/** An ACL Permission object for Create vChecks */
export type BankAccountCheckCreateAclPermissionInput = {
  /** Whether the permission is enabled or not */
  enabled: Scalars["Boolean"]["input"];
  /** The entity id */
  entity_id: Scalars["String"]["input"];
  /** The entity type */
  entity_type: AclEntityTypeEnum;
  /** The limit create vChecks rules */
  limit_create_vchecks_rules: Array<InputMaybe<LimitCreateVChecksRuleInput>>;
  /** The org user Id */
  organization_user_id?: InputMaybe<Scalars["String"]["input"]>;
  /** User group id */
  user_group_id?: InputMaybe<Scalars["String"]["input"]>;
};

/** An ACL Permission object for Check Signer */
export type BankAccountCheckSignerAclPermission = {
  __typename?: "BankAccountCheckSignerACLPermission";
  /** The permission source */
  acl_source?: Maybe<AclSourceEnum>;
  /** The entity id */
  entity_id?: Maybe<Scalars["String"]["output"]>;
  /** The entity type */
  entity_type?: Maybe<AclEntityTypeEnum>;
  /** The maximum amount limit for approving a vCheck */
  limit_approve_vchecks_max_amount?: Maybe<Scalars["Float"]["output"]>;
  /** The org user Id */
  organization_user_id?: Maybe<Scalars["String"]["output"]>;
  /** Original entity id */
  orig_entity_id?: Maybe<Scalars["String"]["output"]>;
  /** The original entity type */
  orig_entity_type?: Maybe<AclEntityTypeEnum>;
  /** User group id */
  user_group_id?: Maybe<Scalars["String"]["output"]>;
};

/** An ACL Permission object for Check Signer */
export type BankAccountCheckSignerAclPermissionInput = {
  /** Whether the permission is enabled or not */
  enabled: Scalars["Boolean"]["input"];
  /** The entity id */
  entity_id: Scalars["String"]["input"];
  /** The entity type */
  entity_type: AclEntityTypeEnum;
  /** The maximum amount limit for approving a vCheck */
  limit_approve_vchecks_max_amount: Scalars["Float"]["input"];
  /** The org user Id */
  organization_user_id?: InputMaybe<Scalars["String"]["input"]>;
  /** User group id */
  user_group_id?: InputMaybe<Scalars["String"]["input"]>;
};

export type BankAccountInfoType = {
  __typename?: "BankAccountInfoType";
  account_number?: Maybe<Scalars["String"]["output"]>;
  bank_name?: Maybe<Scalars["String"]["output"]>;
  routing_number?: Maybe<Scalars["String"]["output"]>;
};

/** An ACL Permission object for Ledger Access */
export type BankAccountLedgerAccessAclPermission = {
  __typename?: "BankAccountLedgerAccessACLPermission";
  /** The permission source */
  acl_source?: Maybe<AclSourceEnum>;
  /** The entity id */
  entity_id?: Maybe<Scalars["String"]["output"]>;
  /** The entity type */
  entity_type?: Maybe<AclEntityTypeEnum>;
  /** The maximum amount limit for the permission */
  limit_ledger_access_max_amount?: Maybe<Scalars["Float"]["output"]>;
  /** The org user Id */
  organization_user_id?: Maybe<Scalars["String"]["output"]>;
  /** Original entity id */
  orig_entity_id?: Maybe<Scalars["String"]["output"]>;
  /** The original entity type */
  orig_entity_type?: Maybe<AclEntityTypeEnum>;
  /** User group id */
  user_group_id?: Maybe<Scalars["String"]["output"]>;
};

/** An ACL Permission object for Ledger Access */
export type BankAccountLedgerAccessAclPermissionInput = {
  /** Whether the permission is enabled or not */
  enabled: Scalars["Boolean"]["input"];
  /** The entity id */
  entity_id: Scalars["String"]["input"];
  /** The entity type */
  entity_type: AclEntityTypeEnum;
  /** The maximum amount limit for the permission */
  limit_ledger_access_max_amount: Scalars["Float"]["input"];
  /** The org user Id */
  organization_user_id?: InputMaybe<Scalars["String"]["input"]>;
  /** User group id */
  user_group_id?: InputMaybe<Scalars["String"]["input"]>;
};

/** A bank account linked address */
export type BankAccountLinkedAddress = {
  __typename?: "BankAccountLinkedAddress";
  /** Linked Address creation time */
  created_at?: Maybe<Scalars["String"]["output"]>;
  /** The user who linked the address */
  created_by?: Maybe<Scalars["String"]["output"]>;
  /** The address */
  email?: Maybe<Scalars["String"]["output"]>;
  /** The id of the linked address */
  id?: Maybe<Scalars["String"]["output"]>;
  /** The verification status of the address */
  status?: Maybe<Scalars["String"]["output"]>;
};

/** An ACL Permission object for Bank Account Manage Check */
export type BankAccountManageCheckAclPermission = {
  __typename?: "BankAccountManageCheckACLPermission";
  /** The permission source */
  acl_source?: Maybe<AclSourceEnum>;
  /** The entity id */
  entity_id?: Maybe<Scalars["String"]["output"]>;
  /** The entity type */
  entity_type?: Maybe<AclEntityTypeEnum>;
  /** The maximum amount limit for the permission */
  limit_manage_vchecks_max_amount?: Maybe<Scalars["Float"]["output"]>;
  /** The org user Id */
  organization_user_id?: Maybe<Scalars["String"]["output"]>;
  /** Original entity id */
  orig_entity_id?: Maybe<Scalars["String"]["output"]>;
  /** The original entity type */
  orig_entity_type?: Maybe<AclEntityTypeEnum>;
  /** User group id */
  user_group_id?: Maybe<Scalars["String"]["output"]>;
};

/** An ACL Permission object for Bank Account Manage Check */
export type BankAccountManageCheckAclPermissionInput = {
  /** Whether the permission is enabled or not */
  enabled: Scalars["Boolean"]["input"];
  /** The entity id */
  entity_id: Scalars["String"]["input"];
  /** The entity type */
  entity_type: AclEntityTypeEnum;
  /** The maximum amount limit for the permission */
  limit_manage_vchecks_max_amount: Scalars["Float"]["input"];
  /** The org user Id */
  organization_user_id?: InputMaybe<Scalars["String"]["input"]>;
  /** User group id */
  user_group_id?: InputMaybe<Scalars["String"]["input"]>;
};

export type BankAccountType = {
  __typename?: "BankAccountType";
  id?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  on_current_batch?: Maybe<Scalars["Boolean"]["output"]>;
};

/** A Bank Account with aggregated data object */
export type BankAccountWithAggregatedData = {
  __typename?: "BankAccountWithAggregatedData";
  /** The bank account number */
  account_number?: Maybe<Scalars["String"]["output"]>;
  /** The bank name of this bank account */
  bank_name: Scalars["String"]["output"];
  /** Can upgrade */
  can_upgrade?: Maybe<Scalars["Boolean"]["output"]>;
  /** Bank Account creation date. */
  created_at?: Maybe<Scalars["String"]["output"]>;
  /** Whether the bank account is deposit-only */
  deposit_only: Scalars["Boolean"]["output"];
  /** The id of the bank account */
  id: Scalars["String"]["output"];
  /** The stats of outgoing checks */
  incoming_checks_stats?: Maybe<VChecksStats>;
  /** The bank account name */
  name: Scalars["String"]["output"];
  /** The bank account nickname */
  nickname?: Maybe<Scalars["String"]["output"]>;
  /** The stats of outgoing checks */
  outgoing_checks_stats?: Maybe<VChecksStats>;
  /** The stats of outgoing checks */
  pending_approval_checks_stats?: Maybe<VChecksStats>;
  /** The bank account routing number */
  routing_number?: Maybe<Scalars["String"]["output"]>;
  /** A link to upload verification documents */
  verification_link?: Maybe<Scalars["String"]["output"]>;
  /** The state of the bank account verification */
  verification_status: Scalars["String"]["output"];
};

export type BankAccountsPaginatedType = {
  __typename?: "BankAccountsPaginatedType";
  pagination?: Maybe<PaginationResponseType>;
  rows?: Maybe<Array<Maybe<BankAccountType>>>;
};

/** The base ACL Permission Type */
export type BasePermissionInput = {
  /** Whether the permission is enabled or not */
  enabled: Scalars["Boolean"]["input"];
  /** The entity id */
  entity_id: Scalars["String"]["input"];
  /** The entity type */
  entity_type: AclEntityTypeEnum;
  /** The org user Id */
  organization_user_id?: InputMaybe<Scalars["String"]["input"]>;
  /** User group id */
  user_group_id?: InputMaybe<Scalars["String"]["input"]>;
};

/** The base ACL Permission Type */
export type BasePermissionType = {
  __typename?: "BasePermissionType";
  /** The permission source */
  acl_source?: Maybe<AclSourceEnum>;
  /** The entity id */
  entity_id?: Maybe<Scalars["String"]["output"]>;
  /** The entity type */
  entity_type?: Maybe<AclEntityTypeEnum>;
  /** The org user Id */
  organization_user_id?: Maybe<Scalars["String"]["output"]>;
  /** Original entity id */
  orig_entity_id?: Maybe<Scalars["String"]["output"]>;
  /** The original entity type */
  orig_entity_type?: Maybe<AclEntityTypeEnum>;
  /** User group id */
  user_group_id?: Maybe<Scalars["String"]["output"]>;
};

/** An Account object. */
export type BasicAccount = {
  __typename?: "BasicAccount";
  /** The email address linked to this account */
  email?: Maybe<Scalars["String"]["output"]>;
  /** Account id */
  id: Scalars["String"]["output"];
  /** The name on the account */
  name?: Maybe<Scalars["String"]["output"]>;
  /** The state of the Account */
  state?: Maybe<AccountStateEnum>;
};

/** A Biller object. */
export type Biller = {
  __typename?: "Biller";
  /** The list of Biller Addresses for this Biller */
  addresses?: Maybe<Array<BillerAddress>>;
  /** Biller email */
  email: Scalars["String"]["output"];
  /** Biller username */
  name?: Maybe<Scalars["String"]["output"]>;
};

/** A Biller Address object. */
export type BillerAddress = {
  __typename?: "BillerAddress";
  /** Address city */
  city?: Maybe<Scalars["String"]["output"]>;
  /** Address Line 1 */
  line_1?: Maybe<Scalars["String"]["output"]>;
  /** Address Line 2 */
  line_2?: Maybe<Scalars["String"]["output"]>;
  /** Address postal code */
  postal_code?: Maybe<Scalars["String"]["output"]>;
  /** Address state code */
  state_code?: Maybe<Scalars["String"]["output"]>;
};

export enum ChatActivityEnumType {
  Active = "active",
  Inactive = "inactive",
}

export type ChatDetailsType = {
  __typename?: "ChatDetailsType";
  chat_activity?: Maybe<ChatActivityEnumType>;
  chat_status?: Maybe<ChatStatusEnumType>;
};

export enum ChatStatusEnumType {
  Closed = "closed",
  Open = "open",
}

/** A check object */
export type Check = {
  __typename?: "Check";
  /** The primary action */
  action?: Maybe<CheckActionType>;
  amount?: Maybe<Scalars["Float"]["output"]>;
  /** Pending Check Attachments */
  attachments?: Maybe<Array<PendingCheckAttachment>>;
  /** Contact */
  contact?: Maybe<ContactPendingCheck>;
  created_at?: Maybe<Scalars["String"]["output"]>;
  date?: Maybe<Scalars["String"]["output"]>;
  deposited_at?: Maybe<Scalars["String"]["output"]>;
  /** Entity ref */
  entity_ref?: Maybe<Scalars["String"]["output"]>;
  /** Pending Check Errors object */
  errors?: Maybe<Array<PendingCheckErrors>>;
  /** General Ledger object */
  gl_account?: Maybe<GeneralLedgerAccountPendingChecks>;
  id: Scalars["String"]["output"];
  memo?: Maybe<Scalars["String"]["output"]>;
  /** List of meta */
  meta?: Maybe<Array<CheckMeta>>;
  number?: Maybe<Scalars["String"]["output"]>;
  options?: Maybe<Array<CheckActionType>>;
  processor?: Maybe<CheckProcessorEnumType>;
  pttoo?: Maybe<Scalars["String"]["output"]>;
  /** The recipient Bank Account object */
  recipient?: Maybe<BankAccountBasic>;
  recipient_email?: Maybe<Scalars["String"]["output"]>;
  /** The related transactions */
  related_transactions?: Maybe<Array<LedgerCheck>>;
  /** List of remittances */
  remittance?: Maybe<Array<RemittanceRecordType>>;
  required_actions?: Maybe<Array<Maybe<CheckRequiredActionType>>>;
  /** The role this user has to the check */
  role?: Maybe<Scalars["String"]["output"]>;
  /** The sender Bank Account object */
  sender?: Maybe<BankAccountBasic>;
  signed_at?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<CheckStatusType>;
  voided_at?: Maybe<Scalars["String"]["output"]>;
};

/** check action errors */
export type CheckActionErrors = {
  __typename?: "CheckActionErrors";
  /** Check id */
  check_id?: Maybe<Scalars["String"]["output"]>;
  /** Message */
  message?: Maybe<Scalars["String"]["output"]>;
};

/** result of check action mutation */
export type CheckActionMutationResult = {
  __typename?: "CheckActionMutationResult";
  /** Errors */
  errors: Array<CheckActionErrors>;
  /** Successes */
  successes?: Maybe<Scalars["Int"]["output"]>;
};

export type CheckActionType = {
  __typename?: "CheckActionType";
  action?: Maybe<Scalars["String"]["output"]>;
  disabled?: Maybe<Scalars["Boolean"]["output"]>;
  label?: Maybe<Scalars["String"]["output"]>;
  message?: Maybe<Scalars["String"]["output"]>;
  priority?: Maybe<Scalars["Int"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
};

export type CheckActivityType = {
  __typename?: "CheckActivityType";
  attachments?: Maybe<Array<AttachmentType>>;
  can_manage: Scalars["Boolean"]["output"];
  context?: Maybe<Scalars["Int"]["output"]>;
  created_at?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  note?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  type: Scalars["Int"]["output"];
  username?: Maybe<Scalars["String"]["output"]>;
};

export type CheckBatchBankAccountType = {
  __typename?: "CheckBatchBankAccountType";
  bank_account?: Maybe<BankAccountType>;
  id?: Maybe<Scalars["String"]["output"]>;
};

export type CheckBatchBankAccountsPaginatedType = {
  __typename?: "CheckBatchBankAccountsPaginatedType";
  pagination?: Maybe<PaginationResponseType>;
  rows?: Maybe<Array<Maybe<CheckBatchBankAccountType>>>;
};

export enum CheckBatchCreatedWithEnum {
  CheckDetails = "check_details",
  LedgerResult = "ledger_result",
}

export enum CheckBatchItemCreatedWithEnum {
  Filter = "filter",
  Id = "id",
}

export type CheckBatchItemReactionType = {
  __typename?: "CheckBatchItemReactionType";
  id?: Maybe<Scalars["String"]["output"]>;
  organization_user_id?: Maybe<Scalars["String"]["output"]>;
  reaction: ReactionEnumType;
};

export type CheckBatchItemType = {
  __typename?: "CheckBatchItemType";
  check?: Maybe<CheckType>;
  created_with?: Maybe<CheckBatchItemCreatedWithEnum>;
  id?: Maybe<Scalars["String"]["output"]>;
  reactions?: Maybe<Array<Maybe<CheckBatchItemReactionType>>>;
};

export type CheckBatchItemsPaginatedType = {
  __typename?: "CheckBatchItemsPaginatedType";
  pagination?: Maybe<PaginationResponseType>;
  rows?: Maybe<Array<Maybe<CheckBatchItemType>>>;
};

export enum CheckBatchStateEnum {
  Closed = "closed",
  Open = "open",
}

export type CheckBatchType = {
  __typename?: "CheckBatchType";
  budget?: Maybe<Scalars["Float"]["output"]>;
  company?: Maybe<CompanyType>;
  created_at?: Maybe<Scalars["String"]["output"]>;
  created_by_organization_user?: Maybe<OrganizationUserType>;
  created_with?: Maybe<CheckBatchCreatedWithEnum>;
  current_user_may_sign?: Maybe<Scalars["Boolean"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  items?: Maybe<CheckBatchItemsPaginatedType>;
  items_amount?: Maybe<Scalars["Float"]["output"]>;
  items_count?: Maybe<Scalars["Int"]["output"]>;
  members_count?: Maybe<Scalars["Int"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  state?: Maybe<CheckBatchStateEnum>;
  unsigned_amount?: Maybe<Scalars["Float"]["output"]>;
  unsigned_count?: Maybe<Scalars["Int"]["output"]>;
};

export type CheckBatchesPaginatedType = {
  __typename?: "CheckBatchesPaginatedType";
  pagination?: Maybe<PaginationResponseType>;
  rows?: Maybe<Array<Maybe<CheckBatchType>>>;
};

export enum CheckContextEnumType {
  Deposits = "deposits",
  Unvalidated = "unvalidated",
  Withdrawals = "withdrawals",
}

export enum CheckDetailsScopeEnum {
  AvailableChecks = "available_checks",
  InBatch = "in_batch",
}

export type CheckDetailsType = {
  __typename?: "CheckDetailsType";
  activity?: Maybe<Array<CheckActivityType>>;
  ledger_check?: Maybe<LedgerCheck>;
  meta?: Maybe<Array<CheckMeta>>;
  other_context_available?: Maybe<Scalars["Boolean"]["output"]>;
  other_context_organization_id?: Maybe<Scalars["String"]["output"]>;
  related_transactions?: Maybe<Array<LedgerCheck>>;
  remittances?: Maybe<Array<RemittanceRecordType>>;
  timeline?: Maybe<CheckTimelineType>;
};

export enum CheckFiltersDateContextEnumType {
  CreatedAt = "created_at",
  Date = "date",
  SignedAt = "signed_at",
}

export enum CheckFiltersDatePresetEnumType {
  LastDay = "last_day",
  LastMonth = "last_month",
  LastWeek = "last_week",
  LastYear = "last_year",
}

export type CheckFiltersDateType = {
  context?: InputMaybe<CheckFiltersDateContextEnumType>;
  from?: InputMaybe<Scalars["String"]["input"]>;
  preset?: InputMaybe<CheckFiltersDatePresetEnumType>;
  to?: InputMaybe<Scalars["String"]["input"]>;
};

export type CheckFiltersProcessingType = {
  processor?: InputMaybe<CheckProcessorEnumType>;
  sub_status?: InputMaybe<Array<InputMaybe<CheckStateSubStatusEnumType>>>;
};

export type CheckFiltersType = {
  /** The minimum amount */
  amount_from?: InputMaybe<Scalars["Float"]["input"]>;
  amount_sort?: InputMaybe<SortEnumType>;
  /** The maximum amount */
  amount_to?: InputMaybe<Scalars["Float"]["input"]>;
  /** The bank accounts ids */
  bank_accounts?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  chat_activity?: InputMaybe<ChatActivityEnumType>;
  check_id?: InputMaybe<Scalars["String"]["input"]>;
  companies?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  created_at_sort?: InputMaybe<SortEnumType>;
  date?: InputMaybe<CheckFiltersDateType>;
  date_sort?: InputMaybe<SortEnumType>;
  memo?: InputMaybe<Scalars["String"]["input"]>;
  number_sort?: InputMaybe<SortEnumType>;
  processing_type?: InputMaybe<Array<InputMaybe<CheckFiltersProcessingType>>>;
  quick_filter?: InputMaybe<CheckQuickFiltersEnumType>;
  required_action?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  search?: InputMaybe<CheckSearchInputType>;
  state?: InputMaybe<Array<InputMaybe<CheckStateEnumType>>>;
  status?: InputMaybe<Array<InputMaybe<CheckQuickFiltersEnumType>>>;
  /** Comma separated tags */
  tags?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<CheckContextEnumType>;
};

export type CheckInterface = {
  /** The primary action */
  action?: Maybe<CheckActionType>;
  amount?: Maybe<Scalars["Float"]["output"]>;
  created_at?: Maybe<Scalars["String"]["output"]>;
  date?: Maybe<Scalars["String"]["output"]>;
  deposited_at?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  memo?: Maybe<Scalars["String"]["output"]>;
  number?: Maybe<Scalars["String"]["output"]>;
  options?: Maybe<Array<CheckActionType>>;
  processor?: Maybe<CheckProcessorEnumType>;
  pttoo?: Maybe<Scalars["String"]["output"]>;
  recipient_email?: Maybe<Scalars["String"]["output"]>;
  required_actions?: Maybe<Array<Maybe<CheckRequiredActionType>>>;
  signed_at?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<CheckStatusType>;
  voided_at?: Maybe<Scalars["String"]["output"]>;
};

/** Input check meta object. */
export type CheckMeta = {
  __typename?: "CheckMeta";
  /** The record label */
  label?: Maybe<Scalars["String"]["output"]>;
  /** The amount */
  order?: Maybe<Scalars["Int"]["output"]>;
  /** Value */
  value?: Maybe<Scalars["String"]["output"]>;
};

export enum CheckProcessorEnumType {
  Ach = "ach",
  AchDirect = "ach_direct",
  Card = "card",
  Print = "print",
}

export enum CheckQuickFiltersEnumType {
  ActionRequired = "action_required",
  Blocked = "blocked",
  Completed = "completed",
  PendingSignature = "pending_signature",
  Processing = "processing",
  ReadyToDeposit = "ready_to_deposit",
  UnresolvedChats = "unresolved_chats",
}

export enum CheckRequiredActionActionEnumType {
  AchCorporateCustomerAdvisesNotAuthorized = "ach_corporate_customer_advises_not_authorized",
  AchInsufficientFunds = "ach_insufficient_funds",
  AchReturnOther = "ach_return_other",
  AchUnableToLocateAccount = "ach_unable_to_locate_account",
  BankAccountLocked = "bank_account_locked",
  CardNotRevealed = "card_not_revealed",
  CardPartiallyCharged = "card_partially_charged",
  CardWasSentNotCharged = "card_was_sent_not_charged",
  CheckDeliveredNotDeposited = "check_delivered_not_deposited",
  CheckWasSignedNotDeposited = "check_was_signed_not_deposited",
  ExpiredPaperChecks = "expired_paper_checks",
  InvoiceDiscrepancy = "invoice_discrepancy",
  MailedNotDelivered = "mailed_not_delivered",
  MissingInformation = "missing_information",
  PayeeNameDoesNotMatchBankAccountName = "payee_name_does_not_match_bank_account_name",
  PaymentDiscrepancy = "payment_discrepancy",
  PendingBankEmailConfirmation = "pending_bank_email_confirmation",
  PendingBankVerification = "pending_bank_verification",
  StopPayment = "stop_payment",
  VoidRequested = "void_requested",
  WrongBankInformation = "wrong_bank_information",
}

export type CheckRequiredActionType = {
  __typename?: "CheckRequiredActionType";
  action?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  is_blocker?: Maybe<Scalars["Boolean"]["output"]>;
  key?: Maybe<CheckRequiredActionActionEnumType>;
  type?: Maybe<CheckRequiredActionTypeEnumType>;
};

export enum CheckRequiredActionTypeEnumType {
  Error = "error",
  Warning = "warning",
}

export type CheckSearchInputType = {
  additional_search_input?: InputMaybe<Scalars["String"]["input"]>;
  additional_search_options?: InputMaybe<Array<InputMaybe<CheckSearchOptionsEnumType>>>;
  search_input?: InputMaybe<Scalars["String"]["input"]>;
  search_options?: InputMaybe<Array<InputMaybe<CheckSearchOptionsEnumType>>>;
};

export enum CheckSearchOptionsEnumType {
  CheckNumber = "check_number",
  Recipient = "recipient",
  Sender = "sender",
}

export enum CheckStateEnumType {
  Blocked = "BLOCKED",
  Deleted = "DELETED",
  Paid = "PAID",
  PendingSignature = "PENDING_SIGNATURE",
  Processing = "PROCESSING",
  Signed = "SIGNED",
  Voided = "VOIDED",
}

export enum CheckStateSubStatusEnumType {
  CardCharged = "card_charged",
  CardIssued = "card_issued",
  CardPartiallyCharged = "card_partially_charged",
  ChargedBack = "charged_back",
  Delivered = "delivered",
  EmailedVendor = "emailed_vendor",
  InTransit = "in_transit",
  PendingDeposit = "pending_deposit",
  Sent = "sent",
  VendorAccepted = "vendor_accepted",
  WaitingToBeSent = "waiting_to_be_sent",
}

export type CheckStatusType = {
  __typename?: "CheckStatusType";
  state?: Maybe<CheckStateEnumType>;
  status?: Maybe<Scalars["String"]["output"]>;
  sub_status?: Maybe<Scalars["String"]["output"]>;
};

export type CheckTimelineEventDescriptionType = {
  __typename?: "CheckTimelineEventDescriptionType";
  description?: Maybe<Scalars["String"]["output"]>;
  static?: Maybe<Scalars["Boolean"]["output"]>;
  url?: Maybe<Scalars["String"]["output"]>;
};

export enum CheckTimelineEventStatusEnumType {
  Finished = "finished",
  InProgress = "in_progress",
  Pending = "pending",
}

export type CheckTimelineEventType = {
  __typename?: "CheckTimelineEventType";
  date?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Array<Maybe<CheckTimelineEventDescriptionType>>>;
  flags?: Maybe<Array<Maybe<FlagWithLabelType>>>;
  images?: Maybe<Array<Maybe<ImageWithLabelType>>>;
  label?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<CheckTimelineEventStatusEnumType>;
};

export type CheckTimelineType = {
  __typename?: "CheckTimelineType";
  events?: Maybe<Array<Maybe<CheckTimelineEventType>>>;
};

export type CheckType = {
  __typename?: "CheckType";
  /** The primary action */
  action?: Maybe<CheckActionType>;
  activity?: Maybe<Array<CheckActivityType>>;
  amount?: Maybe<Scalars["Float"]["output"]>;
  created_at?: Maybe<Scalars["String"]["output"]>;
  date?: Maybe<Scalars["String"]["output"]>;
  deposited_at?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  may_not_add_to_batch?: Maybe<MayNotAddToBatchType>;
  memo?: Maybe<Scalars["String"]["output"]>;
  number?: Maybe<Scalars["String"]["output"]>;
  options?: Maybe<Array<CheckActionType>>;
  processor?: Maybe<CheckProcessorEnumType>;
  pttoo?: Maybe<Scalars["String"]["output"]>;
  recipient_email?: Maybe<Scalars["String"]["output"]>;
  recipient_subtitle?: Maybe<Scalars["String"]["output"]>;
  recipient_title?: Maybe<Scalars["String"]["output"]>;
  remittance?: Maybe<Array<RemittanceRecordType>>;
  required_actions?: Maybe<Array<Maybe<CheckRequiredActionType>>>;
  sender_subtitle?: Maybe<Scalars["String"]["output"]>;
  sender_title?: Maybe<Scalars["String"]["output"]>;
  signed_at?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<CheckStatusType>;
  voided_at?: Maybe<Scalars["String"]["output"]>;
};

export enum CheckTypeEnumType {
  Check = "check",
  Reimbursement = "reimbursement",
}

export type ChecksAddedType = {
  __typename?: "ChecksAddedType";
  checks_added?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  invalid_checks?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type ChecksPaginatedType = {
  __typename?: "ChecksPaginatedType";
  pagination?: Maybe<PaginationResponseType>;
  rows?: Maybe<Array<Maybe<CheckType>>>;
};

/** A company object */
export type Company = CompanyInterface &
  CompanyVBillInterfaceGraphQlType & {
    __typename?: "Company";
    /** The Company Integrations list. */
    company_integrations?: Maybe<Array<CompanyIntegration>>;
    /** Company description */
    description?: Maybe<Scalars["String"]["output"]>;
    /** The company id */
    id: Scalars["String"]["output"];
    /** Indicates whether the user making the request is an admin on a company bank account. */
    is_admin?: Maybe<Scalars["Boolean"]["output"]>;
    /** Indicates whether the user making the request is a manager on a company bank account */
    is_manager?: Maybe<Scalars["Boolean"]["output"]>;
    /** Shows if the account is subscribed to receive notification */
    is_subscribed_to_company_notifications?: Maybe<Scalars["Boolean"]["output"]>;
    /** The name of the company */
    name: Scalars["String"]["output"];
    /** vBill email address for this company (if onboarded) */
    vbill_email?: Maybe<Scalars["String"]["output"]>;
    /** vBill onboarding status */
    vbill_enabled: Scalars["Boolean"]["output"];
    /** Linked vCard Company Account */
    vcards_account?: Maybe<VCardCompanyAccount>;
    /** A link to upload verification documents */
    verification_link?: Maybe<Scalars["String"]["output"]>;
    /** The state of the bank account verification */
    verification_status?: Maybe<Scalars["String"]["output"]>;
  };

/** A company basic object */
export type CompanyBasic = CompanyInterface & {
  __typename?: "CompanyBasic";
  /** The bank accounts of the company */
  bank_accounts?: Maybe<Array<Maybe<BankAccountBasic>>>;
  /** Company description */
  description?: Maybe<Scalars["String"]["output"]>;
  /** The company id */
  id: Scalars["String"]["output"];
  /** The name of the company */
  name: Scalars["String"]["output"];
};

/** A company details object */
export type CompanyDetails = CompanyInterface &
  CompanyVBillInterfaceGraphQlType & {
    __typename?: "CompanyDetails";
    /** The Company Integrations list. */
    company_integrations?: Maybe<Array<CompanyIntegration>>;
    /** The company id */
    id: Scalars["String"]["output"];
    /** Shows if the account is subscribed to receive notification */
    is_subscribed_to_company_notifications?: Maybe<Scalars["Boolean"]["output"]>;
    /** The name of the company */
    name: Scalars["String"]["output"];
    /** Company website */
    phone_number?: Maybe<Scalars["String"]["output"]>;
    /** The physical address object. */
    physical_address?: Maybe<Address>;
    /** vBill email address for this company (if onboarded) */
    vbill_email?: Maybe<Scalars["String"]["output"]>;
    /** vBill onboarding status */
    vbill_enabled: Scalars["Boolean"]["output"];
    /** Company website */
    website_url?: Maybe<Scalars["String"]["output"]>;
  };

export type CompanyGroupType = {
  __typename?: "CompanyGroupType";
  company_ids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  id: Scalars["String"]["output"];
  label: Scalars["String"]["output"];
};

/** A Company Integration object */
export type CompanyIntegration = {
  __typename?: "CompanyIntegration";
  /** Token creation date. */
  created_at?: Maybe<Scalars["String"]["output"]>;
  /** Token expiration date. */
  expires_at?: Maybe<Scalars["String"]["output"]>;
  /** The External Auto Signer object */
  external_auto_signers?: Maybe<Array<ExternalAutoSigner>>;
  /** The integration object */
  integration?: Maybe<Integration>;
  /** The date when the token was last used. */
  last_seen?: Maybe<Scalars["String"]["output"]>;
  /** The token scopes. */
  scopes?: Maybe<Array<Scalars["String"]["output"]>>;
  /** IntegrationSettings for a Company */
  settings?: Maybe<IntegrationSettings>;
};

/** A Company Integrations output object. */
export type CompanyIntegrations = {
  __typename?: "CompanyIntegrations";
  /** The Bank Accounts list. */
  bank_accounts?: Maybe<Array<BankAccount>>;
  /** The Integrations list. */
  integrations?: Maybe<Array<CompanyIntegration>>;
  /** Company Integration Setup Status */
  setup_status?: Maybe<CompanyIntegrationsSetupStatus>;
};

/** A Company Integration Setup Status */
export type CompanyIntegrationsSetupStatus = {
  __typename?: "CompanyIntegrationsSetupStatus";
  /** The mapped Bank Accounts. */
  mapped_bank_accounts?: Maybe<Scalars["Int"]["output"]>;
  /** The mapped Organization Users. */
  mapped_organization_users?: Maybe<Scalars["Int"]["output"]>;
  /** The unmapped Bank Accounts. */
  unmapped_bank_accounts?: Maybe<Scalars["Int"]["output"]>;
  /** The unmapped Organization Users. */
  unmapped_organization_users?: Maybe<Scalars["Int"]["output"]>;
};

/** Company interface. */
export type CompanyInterface = {
  /** The company id */
  id: Scalars["String"]["output"];
  /** The name of the company */
  name: Scalars["String"]["output"];
};

/** A Company Notification object */
export type CompanyNotification = {
  __typename?: "CompanyNotification";
  /** The notification action url. */
  action_url?: Maybe<Scalars["String"]["output"]>;
  /** The notification channel. */
  channel?: Maybe<Scalars["String"]["output"]>;
  company_id: Scalars["String"]["output"];
  /** Created at. */
  created_at?: Maybe<Scalars["String"]["output"]>;
  /** The id of the Company Notification. */
  id: Scalars["String"]["output"];
  /** The notification issuer client id. */
  issuer_client_id?: Maybe<Scalars["String"]["output"]>;
  /** The notification issuer type. */
  issuer_type: Scalars["String"]["output"];
  /** The notification level. */
  level?: Maybe<Scalars["String"]["output"]>;
  /** The notification long description. */
  long_desc?: Maybe<Scalars["String"]["output"]>;
  /** The notification option 1 title. */
  option_1_title?: Maybe<Scalars["String"]["output"]>;
  /** The notification option 1 url. */
  option_1_url?: Maybe<Scalars["String"]["output"]>;
  /** The notification option 2title. */
  option_2_title?: Maybe<Scalars["String"]["output"]>;
  /** The notification option 2 url. */
  option_2_url?: Maybe<Scalars["String"]["output"]>;
  ref_url: Scalars["String"]["output"];
  /** The notification short description. */
  short_desc: Scalars["String"]["output"];
  /** The notification title. */
  title?: Maybe<Scalars["String"]["output"]>;
  /** If the notification was viewed. */
  viewed?: Maybe<Scalars["String"]["output"]>;
};

/** Returns the company owner */
export type CompanyOwner = {
  __typename?: "CompanyOwner";
  /** The id of the General Ledger Account. */
  id: Scalars["String"]["output"];
  /** Owner's name */
  name?: Maybe<Scalars["String"]["output"]>;
};

/** The pending checks count for a company */
export type CompanyPendingChecksCount = CompanyInterface & {
  __typename?: "CompanyPendingChecksCount";
  /** The id of the company */
  id: Scalars["String"]["output"];
  /** The name of the company */
  name: Scalars["String"]["output"];
  /** The organization id */
  organization_id?: Maybe<Scalars["String"]["output"]>;
  /** The Integration pending checks count. */
  pending_checks_count?: Maybe<Scalars["Int"]["output"]>;
};

export type CompanyType = {
  __typename?: "CompanyType";
  dba?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  legal_name?: Maybe<Scalars["String"]["output"]>;
};

/** Company interface (vBill fields). */
export type CompanyVBillInterfaceGraphQlType = {
  /** vBill email address for this company (if onboarded) */
  vbill_email?: Maybe<Scalars["String"]["output"]>;
  /** vBill onboarding status */
  vbill_enabled: Scalars["Boolean"]["output"];
};

/** A company with bank accounts and aggregated data object */
export type CompanyWithBankAccountsAndAggregatedData = CompanyInterface &
  CompanyVBillInterfaceGraphQlType & {
    __typename?: "CompanyWithBankAccountsAndAggregatedData";
    /** The bank accounts of the company */
    bank_accounts?: Maybe<Array<BankAccountWithAggregatedData>>;
    /** Company description */
    description?: Maybe<Scalars["String"]["output"]>;
    /** The company id */
    id: Scalars["String"]["output"];
    /** The name of the company */
    name: Scalars["String"]["output"];
    /** The organization id */
    organization_id?: Maybe<Scalars["String"]["output"]>;
    /** If the user is subscribed to this company notifications. */
    user_subscribed_to_company_notifications?: Maybe<Scalars["Boolean"]["output"]>;
    /** vBill email address for this company (if onboarded) */
    vbill_email?: Maybe<Scalars["String"]["output"]>;
    /** vBill onboarding status */
    vbill_enabled: Scalars["Boolean"]["output"];
    /** A link to upload verification documents */
    verification_link?: Maybe<Scalars["String"]["output"]>;
    /** The state of the bank account verification */
    verification_status?: Maybe<Scalars["String"]["output"]>;
  };

/** Condition object */
export type ConditionGraphQlType = {
  __typename?: "ConditionGraphQLType";
  /** Optional array of strings for memos */
  memos?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  /** Optional array of strings for pttoos */
  pttoos?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  /** Optional array of strings for senders */
  senders?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

/** Condition object */
export type ConditionInputGraphQlType = {
  /** Optional array of strings for memos */
  memos?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Optional array of strings for pttoos */
  pttoos?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Optional array of strings for senders */
  senders?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

/** A Contact object */
export type Contact = {
  __typename?: "Contact";
  /** Active status */
  active?: Maybe<Scalars["Boolean"]["output"]>;
  /** List of Addresses */
  address?: Maybe<Array<Address>>;
  /** The time of creation */
  created_at?: Maybe<Scalars["String"]["output"]>;
  /** Number */
  external_ref: Scalars["String"]["output"];
  /** The id of the Contact. */
  id: Scalars["String"]["output"];
  /** Metadata json string */
  metadata?: Maybe<Scalars["String"]["output"]>;
  /** Name */
  name: Scalars["String"]["output"];
  /** Vendor phone number object */
  phone_number?: Maybe<Array<ContactPhoneNumber>>;
};

/** A Contact object */
export type ContactPendingCheck = {
  __typename?: "ContactPendingCheck";
  /** Active status */
  active?: Maybe<Scalars["Boolean"]["output"]>;
  /** Email. */
  emails?: Maybe<Array<Scalars["String"]["output"]>>;
  external_ref?: Maybe<Scalars["String"]["output"]>;
  /** Name */
  name?: Maybe<Scalars["String"]["output"]>;
};

/** An Contact Phone Number object. */
export type ContactPhoneNumber = {
  __typename?: "ContactPhoneNumber";
  /** The description */
  description?: Maybe<Scalars["String"]["output"]>;
  /** The External Auto Signer id */
  id: Scalars["String"]["output"];
  /** The phone number */
  number?: Maybe<Scalars["String"]["output"]>;
  /** The type */
  type?: Maybe<Scalars["String"]["output"]>;
};

/** A Paginated list of contacts. */
export type ContactsQueryResult = {
  __typename?: "ContactsQueryResult";
  /** A list of contact */
  contacts?: Maybe<Array<Contact>>;
  /** Pagination data */
  pagination?: Maybe<PaginationResult>;
};

/** Return format for multi create checks */
export type CreateCheck = {
  __typename?: "CreateCheck";
  /** Check */
  check?: Maybe<Check>;
  /** Entity reference */
  entity_ref?: Maybe<Scalars["String"]["output"]>;
  /** Errors */
  errors: Array<ErrorCreateCheck>;
  /** Success */
  success?: Maybe<Scalars["Boolean"]["output"]>;
};

export type CreateCheckBatchMembersType = {
  __typename?: "CreateCheckBatchMembersType";
  success_ids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

/** A delegation  object */
export type Delegation = {
  __typename?: "Delegation";
  delegate: OrganizationUser;
  id: Scalars["String"]["output"];
  role: DelegationRoleEnum;
};

/** A delegation group object */
export type DelegationGroup = DelegationGroupInterface & {
  __typename?: "DelegationGroup";
  approvals_required: Scalars["Int"]["output"];
  bill_id?: Maybe<Scalars["String"]["output"]>;
  check_id?: Maybe<Scalars["String"]["output"]>;
  company_id?: Maybe<Scalars["String"]["output"]>;
  delegations: Array<Delegation>;
  delegator: OrganizationUser;
  id: Scalars["String"]["output"];
  is_root: Scalars["Boolean"]["output"];
  labeling_category?: Maybe<LabelingCategory>;
  name?: Maybe<Scalars["String"]["output"]>;
  organization_id: Scalars["String"]["output"];
  requirement_type: DelegationGroupRequirementTypeEnum;
  reviews_required: Scalars["Int"]["output"];
  scope: DelegationGroupScopeEnum;
  type: DelegationGroupTypeEnum;
  vendor_id?: Maybe<Scalars["String"]["output"]>;
};

/** DelegationGroup interface */
export type DelegationGroupInterface = {
  approvals_required: Scalars["Int"]["output"];
  bill_id?: Maybe<Scalars["String"]["output"]>;
  check_id?: Maybe<Scalars["String"]["output"]>;
  company_id?: Maybe<Scalars["String"]["output"]>;
  delegations: Array<Delegation>;
  delegator: OrganizationUser;
  id: Scalars["String"]["output"];
  is_root: Scalars["Boolean"]["output"];
  labeling_category?: Maybe<LabelingCategory>;
  name?: Maybe<Scalars["String"]["output"]>;
  organization_id: Scalars["String"]["output"];
  requirement_type: DelegationGroupRequirementTypeEnum;
  reviews_required: Scalars["Int"]["output"];
  scope: DelegationGroupScopeEnum;
  type: DelegationGroupTypeEnum;
  vendor_id?: Maybe<Scalars["String"]["output"]>;
};

/** Delegation group requirement type */
export enum DelegationGroupRequirementTypeEnum {
  Anyone = "ANYONE",
  Custom = "CUSTOM",
  Everyone = "EVERYONE",
}

/** A delegation tree object */
export type DelegationGroupResolved = DelegationGroupInterface & {
  __typename?: "DelegationGroupResolved";
  approvals_required: Scalars["Int"]["output"];
  bill_id?: Maybe<Scalars["String"]["output"]>;
  check_id?: Maybe<Scalars["String"]["output"]>;
  company_id?: Maybe<Scalars["String"]["output"]>;
  delegations: Array<Delegation>;
  delegator: OrganizationUser;
  id: Scalars["String"]["output"];
  is_root: Scalars["Boolean"]["output"];
  labeling_category?: Maybe<LabelingCategory>;
  name?: Maybe<Scalars["String"]["output"]>;
  organization_id: Scalars["String"]["output"];
  overall_approvals_met: Scalars["Boolean"]["output"];
  overall_reviews_met: Scalars["Boolean"]["output"];
  parent_group_id?: Maybe<Scalars["String"]["output"]>;
  requirement_type: DelegationGroupRequirementTypeEnum;
  reviews_required: Scalars["Int"]["output"];
  scope: DelegationGroupScopeEnum;
  type: DelegationGroupTypeEnum;
  vendor_id?: Maybe<Scalars["String"]["output"]>;
};

/** Delegation group scope */
export enum DelegationGroupScopeEnum {
  VbillApprove = "VBILL_APPROVE",
  VcheckApprove = "VCHECK_APPROVE",
}

/** Delegation group type */
export enum DelegationGroupTypeEnum {
  Approval = "APPROVAL",
  Review = "REVIEW",
  ReviewRetainApproval = "REVIEW_RETAIN_APPROVAL",
}

/** A delegation group response object */
export type DelegationGroupsResponse = {
  __typename?: "DelegationGroupsResponse";
  delegation_groups: Array<DelegationGroup>;
  is_root_delegator: Scalars["Boolean"]["output"];
};

/** Delegation roles */
export enum DelegationRoleEnum {
  Approver = "APPROVER",
  Reviewer = "REVIEWER",
}

/** An email address object */
export type EmailAddress = {
  __typename?: "EmailAddress";
  /** The email */
  email?: Maybe<Scalars["String"]["output"]>;
  /** The id of the email */
  id?: Maybe<Scalars["String"]["output"]>;
};

/** An error object for multi create checks. */
export type ErrorCreateCheck = {
  __typename?: "ErrorCreateCheck";
  /** Messages */
  message: Scalars["String"]["output"];
  /** Type of error */
  type: Scalars["String"]["output"];
};

/** An ExternalAutoSigner object */
export type ExternalAutoSigner = {
  __typename?: "ExternalAutoSigner";
  /** The company id */
  company_id?: Maybe<Scalars["String"]["output"]>;
  /** The External Auto Signer id */
  id: Scalars["String"]["output"];
  /** Signer name */
  name?: Maybe<Scalars["String"]["output"]>;
  /** The Organization User id */
  organization_user_id?: Maybe<Scalars["String"]["output"]>;
};

export type FeatureAccessRequestType = {
  __typename?: "FeatureAccessRequestType";
  feature?: Maybe<FeatureEnumType>;
};

export enum FeatureEnumType {
  Bills = "bills",
  BudgetManagement = "budget_management",
  Invoices = "invoices",
}

export type FlagWithLabelType = {
  __typename?: "FlagWithLabelType";
  flag?: Maybe<Scalars["Boolean"]["output"]>;
  label?: Maybe<Scalars["String"]["output"]>;
};

export enum FrontendSettingsContextEnum {
  Account = "Account",
  OrganizationUser = "OrganizationUser",
}

/** A general ledger account */
export type GeneralLedgerAccount = {
  __typename?: "GeneralLedgerAccount";
  /** Account Number. */
  account_number?: Maybe<Scalars["String"]["output"]>;
  /** Account Type */
  account_type?: Maybe<Scalars["String"]["output"]>;
  /** Active status */
  active?: Maybe<Scalars["Boolean"]["output"]>;
  /** List of bank accounts linked to this general ledger account */
  bank_account?: Maybe<BankAccountBasic>;
  /** Category */
  category?: Maybe<Scalars["String"]["output"]>;
  /** Description */
  description?: Maybe<Scalars["String"]["output"]>;
  /** external_ref */
  external_ref: Scalars["String"]["output"];
  /** The id of the General Ledger Account. */
  id: Scalars["String"]["output"];
  /** Name */
  name: Scalars["String"]["output"];
  /** Number */
  number?: Maybe<Scalars["String"]["output"]>;
};

/** A General Ledger object */
export type GeneralLedgerAccountPendingChecks = {
  __typename?: "GeneralLedgerAccountPendingChecks";
  /** Active status */
  active?: Maybe<Scalars["Boolean"]["output"]>;
  /** Name */
  description?: Maybe<Scalars["String"]["output"]>;
  external_ref?: Maybe<Scalars["String"]["output"]>;
  /** Full Name */
  full_name?: Maybe<Scalars["String"]["output"]>;
  /** Name */
  name?: Maybe<Scalars["String"]["output"]>;
};

export type GenericFilterType = {
  __typename?: "GenericFilterType";
  name?: Maybe<Scalars["String"]["output"]>;
  values?: Maybe<Array<Maybe<ValueWithLabelType>>>;
};

export type GetCheckBatchesFiltersType = {
  company_ids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<Array<InputMaybe<CheckBatchStateEnum>>>;
};

export type GlobalVendorType = {
  __typename?: "GlobalVendorType";
  address?: Maybe<Scalars["String"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

export type ImageWithLabelType = {
  __typename?: "ImageWithLabelType";
  id?: Maybe<Scalars["String"]["output"]>;
  label?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  path?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
};

/** Import Pending Checks */
export type ImportPendingChecks = {
  /** The check attachments */
  attachments?: InputMaybe<Array<InputMaybe<AttachmentInput>>>;
  /** The recipient's email address. */
  email: Scalars["String"]["input"];
  /** Entity reference (your internal check ID) */
  entity_ref: Scalars["String"]["input"];
  /** The memo */
  memo?: InputMaybe<Scalars["String"]["input"]>;
  /** List of meta */
  meta?: InputMaybe<Array<InputMaybe<InputCheckMeta>>>;
  /** PTTOO */
  pttoo: Scalars["String"]["input"];
  /** List of remittances */
  remittance?: InputMaybe<Array<InputMaybe<InputRemittance>>>;
};

/** Input check meta object. */
export type InputCheckMeta = {
  /** The record label */
  label?: InputMaybe<Scalars["String"]["input"]>;
  /** The amount */
  order?: InputMaybe<Scalars["Int"]["input"]>;
  /** Value */
  value?: InputMaybe<Scalars["String"]["input"]>;
};

/** A delegation input object */
export type InputDelegation = {
  /** Organization user id of delegate */
  delegate_user_id: Scalars["String"]["input"];
  /** Company id */
  role: DelegationRoleEnum;
};

/** A DelegationGroupEntityContext object */
export type InputDelegationGroupEntityContext = {
  bill_id?: InputMaybe<Scalars["String"]["input"]>;
  check_id?: InputMaybe<Scalars["String"]["input"]>;
};

/** Input remittance object. */
export type InputRemittance = {
  /** The amount */
  amount?: InputMaybe<Scalars["Float"]["input"]>;
  /** The date of the remittance */
  date?: InputMaybe<Scalars["String"]["input"]>;
  /** Entity reference */
  entity_ref?: InputMaybe<Scalars["String"]["input"]>;
  /** Entity type */
  entity_type?: InputMaybe<Scalars["String"]["input"]>;
  /** Remittance meta */
  meta?: InputMaybe<Array<InputMaybe<InputRemittanceMeta>>>;
};

/** Input remittance meta object. */
export type InputRemittanceMeta = {
  /** The record label */
  label?: InputMaybe<Scalars["String"]["input"]>;
  /** The amount */
  order?: InputMaybe<Scalars["Int"]["input"]>;
  /** Value */
  value?: InputMaybe<Scalars["String"]["input"]>;
};

/** An integration object */
export type Integration = {
  __typename?: "Integration";
  /** Integration display(short) name */
  display_name?: Maybe<Scalars["String"]["output"]>;
  /** The id of the client */
  integration_id: Scalars["String"]["output"];
  /** The Integration logo */
  logo?: Maybe<Scalars["String"]["output"]>;
  /** Integration name */
  name?: Maybe<Scalars["String"]["output"]>;
  /** Integration scopes */
  scopes?: Maybe<Array<Scalars["String"]["output"]>>;
  /** Integration slug */
  slug?: Maybe<Scalars["String"]["output"]>;
  /** Integration Webhooks */
  webhooks?: Maybe<Array<IntegrationWebhook>>;
};

/** The integration configuration object. */
export type IntegrationConfigurationInput = {
  /** The option name */
  configuration: Scalars["String"]["input"];
  /** The option value */
  value: Scalars["Boolean"]["input"];
};

/** Integration Settings */
export type IntegrationSettings = {
  __typename?: "IntegrationSettings";
  /** The auto import checks feature status. */
  auto_import?: Maybe<Scalars["Boolean"]["output"]>;
  /** The current server time. */
  current_time?: Maybe<Scalars["Int"]["output"]>;
  /** The Integration description */
  description?: Maybe<Scalars["String"]["output"]>;
  /** The Integration pending checks count. */
  pending_checks_count?: Maybe<Scalars["Int"]["output"]>;
  /** The Integration sync status expiration time. */
  sync_expire?: Maybe<Scalars["Int"]["output"]>;
  /** The Integration sync status message. */
  sync_message?: Maybe<Scalars["String"]["output"]>;
  /** The Integration sync status. */
  sync_status?: Maybe<Scalars["String"]["output"]>;
  /** The Integration sync status last seen time. */
  sync_time?: Maybe<Scalars["Int"]["output"]>;
};

/** The Integration Settings Input object. */
export type IntegrationSettingsInput = {
  /** The auto import checks status. */
  auto_import?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The integration description (Integrated Company Name) */
  description?: InputMaybe<Scalars["String"]["input"]>;
};

/** An Integration Webhook object */
export type IntegrationWebhook = {
  __typename?: "IntegrationWebhook";
  /** The webhook scope */
  scope?: Maybe<Scalars["String"]["output"]>;
  /** The webhook url */
  url?: Maybe<Scalars["String"]["output"]>;
};

/** An Invite object */
export type Invite = {
  __typename?: "Invite";
  /** The bank account users email */
  email?: Maybe<Scalars["String"]["output"]>;
  /** The id of the vCard rule */
  id?: Maybe<Scalars["String"]["output"]>;
  /** Inviter account */
  inviter?: Maybe<BasicAccount>;
  /** The organization id */
  organization_id?: Maybe<Scalars["String"]["output"]>;
  /** The organization name */
  organization_name?: Maybe<Scalars["String"]["output"]>;
  /** Organization user */
  organization_user_id?: Maybe<Scalars["String"]["output"]>;
  /** Invite status */
  status?: Maybe<InviteStatusEnum>;
};

/** The Status */
export enum InviteStatusEnum {
  Accepted = "ACCEPTED",
  Active = "ACTIVE",
  Declined = "DECLINED",
  Deleted = "DELETED",
}

/** A labeling category object */
export type LabelingCategory = {
  __typename?: "LabelingCategory";
  /** Labeling category id */
  id: Scalars["String"]["output"];
  /** Labeling category name */
  name: Scalars["String"]["output"];
};

export type LedgerCheck = {
  __typename?: "LedgerCheck";
  /** The primary action */
  action?: Maybe<CheckActionType>;
  amount?: Maybe<Scalars["Float"]["output"]>;
  bank_account_info?: Maybe<BankAccountInfoType>;
  /** The Chat Details Type */
  chat_details?: Maybe<ChatDetailsType>;
  check_type?: Maybe<CheckTypeEnumType>;
  created_at?: Maybe<Scalars["String"]["output"]>;
  date?: Maybe<Scalars["String"]["output"]>;
  deposited_at?: Maybe<Scalars["String"]["output"]>;
  global_vendor?: Maybe<GlobalVendorType>;
  id: Scalars["String"]["output"];
  memo?: Maybe<Scalars["String"]["output"]>;
  number?: Maybe<Scalars["String"]["output"]>;
  options?: Maybe<Array<CheckActionType>>;
  processor?: Maybe<CheckProcessorEnumType>;
  pttoo?: Maybe<Scalars["String"]["output"]>;
  /** The recipient Bank Account object */
  recipient?: Maybe<BankAccountBasic>;
  recipient_email?: Maybe<Scalars["String"]["output"]>;
  /** The recipient subtitle */
  recipient_subtitle?: Maybe<Scalars["String"]["output"]>;
  /** The recipient title */
  recipient_title?: Maybe<Scalars["String"]["output"]>;
  /** How the vCheck is related */
  related_by?: Maybe<Scalars["String"]["output"]>;
  required_actions?: Maybe<Array<Maybe<CheckRequiredActionType>>>;
  /** The role this user has to the check */
  role?: Maybe<Scalars["String"]["output"]>;
  /** The sender Bank Account object */
  sender?: Maybe<BankAccountBasic>;
  /** The sender subtitle */
  sender_subtitle?: Maybe<Scalars["String"]["output"]>;
  /** The sender title */
  sender_title?: Maybe<Scalars["String"]["output"]>;
  signed_at?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<CheckStatusType>;
  /** If the check is mapped to a global vendor */
  vendor_is_valid?: Maybe<Scalars["Boolean"]["output"]>;
  voided_at?: Maybe<Scalars["String"]["output"]>;
};

/** LimitCreateVChecksRule object */
export type LimitCreateVChecksRule = {
  __typename?: "LimitCreateVChecksRule";
  /** The permissionis is required above this amount limit */
  above?: Maybe<Scalars["Float"]["output"]>;
  /** The permissionis is required for this number of approvers */
  count?: Maybe<Scalars["Int"]["output"]>;
};

/** LimitCreateVChecksRuleInput object */
export type LimitCreateVChecksRuleInput = {
  /** The permissionis is required above this amount limit */
  above?: InputMaybe<Scalars["Float"]["input"]>;
  /** The permissionis is required for this number of approvers */
  count?: InputMaybe<Scalars["Int"]["input"]>;
};

/** A Bank Account Linked Address */
export type LinkedAddress = {
  __typename?: "LinkedAddress";
  /** Timestamp of when the address was linked */
  created_at?: Maybe<Scalars["String"]["output"]>;
  /** The user who linked the address */
  created_by?: Maybe<Scalars["String"]["output"]>;
  /** The address */
  email: Scalars["String"]["output"];
  /** The linked address id */
  id: Scalars["String"]["output"];
  /** The state of the linked address */
  state?: Maybe<LinkedAddressStateEnum>;
  /** Is the linked address verified */
  verified: Scalars["Boolean"]["output"];
};

/** The State */
export enum LinkedAddressStateEnum {
  Deleted = "DELETED",
  Unverified = "UNVERIFIED",
  Verified = "VERIFIED",
}

export type MayNotAddToBatchType = {
  __typename?: "MayNotAddToBatchType";
  check_batch_id?: Maybe<Scalars["String"]["output"]>;
  check_batch_name?: Maybe<Scalars["String"]["output"]>;
  message?: Maybe<Scalars["String"]["output"]>;
};

export type MediaType = {
  __typename?: "MediaType";
  filename?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  path?: Maybe<Scalars["String"]["output"]>;
  size?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
};

export type Mutation = {
  __typename?: "Mutation";
  /** Accept invite user */
  AcceptInvite?: Maybe<Scalars["Boolean"]["output"]>;
  /** Request a Phone Number Verification Code for a Cherry™ Account. */
  AccountPhoneNumberVerificationCodeRequest?: Maybe<Scalars["Boolean"]["output"]>;
  /** Verify an Account Phone Number Verification Code */
  AccountPhoneNumberVerificationCodeVerify?: Maybe<Scalars["Boolean"]["output"]>;
  /** Update an Account password. */
  AccountUpdatePassword?: Maybe<Scalars["Boolean"]["output"]>;
  AddToProcessingMutation?: Maybe<AsyncProcessingRequestType>;
  /** Add a User to a Group. */
  AddUserToGroup?: Maybe<Scalars["Boolean"]["output"]>;
  /** Apply ACL rule */
  ApplyACL?: Maybe<ApplyAclResponse>;
  /** Archive Pending Checks from a given integration */
  ArchivePendingChecks?: Maybe<Scalars["Boolean"]["output"]>;
  AssignGroupToCompanyMutation?: Maybe<Array<Maybe<CompanyGroupType>>>;
  /** Performs an action on multiple checks */
  CheckActionMutation?: Maybe<CheckActionMutationResult>;
  /** Create an auto deposit rule for a personal account */
  CreateAutoDepositRuleMutation?: Maybe<AutoDepositRuleGraphQlType>;
  /** Create a bank account auto deposit address */
  CreateBankAccountAutoDepositAddress?: Maybe<BankAccountAutoDepositAddress>;
  /** Create a new bank account linked address */
  CreateBankAccountLinkedAddress?: Maybe<LinkedAddress>;
  CreateCheckBatchBankAccountsMutation?: Maybe<Array<Maybe<CheckBatchBankAccountType>>>;
  CreateCheckBatchItemsMutation?: Maybe<ChecksAddedType>;
  CreateCheckBatchMembersMutation?: Maybe<CreateCheckBatchMembersType>;
  CreateCheckBatchMutation?: Maybe<CheckBatchType>;
  /** Create a Check Note */
  CreateCheckNote: Note;
  CreateCompanyGroupMutation?: Maybe<Array<Maybe<CompanyGroupType>>>;
  /** Create an External Auto Signer. */
  CreateExternalAutoSigner?: Maybe<ExternalAutoSigner>;
  CreateFeatureAccessRequestMutation: Scalars["Boolean"]["output"];
  /** Create invite */
  CreateInvite?: Maybe<Invite>;
  /** Create a User Group */
  CreateUserGroup?: Maybe<UserGroup>;
  /** Delete an auto deposit rule for a personal account */
  DeleteAutoDepositRuleMutation?: Maybe<Scalars["Boolean"]["output"]>;
  /** Delete a bank account auto deposit address */
  DeleteBankAccountAutoDepositAddress?: Maybe<Scalars["Boolean"]["output"]>;
  /** Delete a bank account linked address */
  DeleteBankAccountLinkedAddress?: Maybe<Scalars["Boolean"]["output"]>;
  DeleteCheckBatchBankAccountsMutation?: Maybe<Scalars["Boolean"]["output"]>;
  DeleteCheckBatchItemsMutation?: Maybe<Scalars["Boolean"]["output"]>;
  DeleteCheckBatchMembersMutation?: Maybe<Scalars["Boolean"]["output"]>;
  DeleteCheckBatchMutation?: Maybe<Scalars["Boolean"]["output"]>;
  /** Delete a Check Note */
  DeleteCheckNote?: Maybe<Scalars["Boolean"]["output"]>;
  /** Delete a Check Note Attachment */
  DeleteCheckNoteAttachment?: Maybe<Scalars["Boolean"]["output"]>;
  /** Delete a Check Tag */
  DeleteCheckTag?: Maybe<Scalars["Boolean"]["output"]>;
  DeleteCompanyGroupMutation?: Maybe<Array<Maybe<CompanyGroupType>>>;
  /** Deletes a delegation group */
  DeleteDelegationGroup?: Maybe<Scalars["Boolean"]["output"]>;
  /** Deletes External Auto Signer */
  DeleteExternalAutoSigner?: Maybe<Scalars["Boolean"]["output"]>;
  /** Delete Invite */
  DeleteInvite?: Maybe<Scalars["Boolean"]["output"]>;
  /** Delete a User Group */
  DeleteUserGroup?: Maybe<Scalars["Boolean"]["output"]>;
  /** Deposit multiple vChecks */
  DepositChecksMutation?: Maybe<CheckActionMutationResult>;
  EditCompanyGroupMutation?: Maybe<Array<Maybe<CompanyGroupType>>>;
  GetIntegrationAuthCode?: Maybe<Scalars["String"]["output"]>;
  /** Import Pending Checks from a given integration */
  ImportPendingChecks: Array<CreateCheck>;
  IntegrationConfiguration?: Maybe<Scalars["Boolean"]["output"]>;
  /** Leave Organization (deletes organization user and all bank account users associated) */
  LeaveOrganization?: Maybe<Scalars["Boolean"]["output"]>;
  /** Promote an unvalidated user. */
  PromoteUnvalidatedUser?: Maybe<Scalars["Boolean"]["output"]>;
  /** Reject invite */
  RejectInvite?: Maybe<Scalars["Boolean"]["output"]>;
  /** Remove a User to a Groups. */
  RemoveUserFromGroup?: Maybe<Scalars["Boolean"]["output"]>;
  /** Revoke access token */
  RevokeAccessToken?: Maybe<Scalars["Boolean"]["output"]>;
  /** Saves an approval or review */
  SaveApproval?: Maybe<Scalars["Boolean"]["output"]>;
  /** Saves an approval or review */
  SaveDelegationGroup?: Maybe<Scalars["Boolean"]["output"]>;
  SaveFrontendSettingsMutation?: Maybe<Scalars["String"]["output"]>;
  /** Saves a labeling category */
  SaveLabelingCategory: LabelingCategory;
  SaveSharedFiltersMutation?: Maybe<Scalars["String"]["output"]>;
  /** Map a Bank Account to a General Ledger Account. */
  SetBankAccountGlAccountMapping?: Maybe<BankAccountBasicWithMappedGeneralLedgerAccounts>;
  SignCheckBatchMutation?: Maybe<SignCheckBatchType>;
  /** Unmap a Bank Account to a General Ledger Account. */
  UnmapBankAccountGlAccountMapping?: Maybe<Scalars["Boolean"]["output"]>;
  /** Update an auto deposit rule for a personal account */
  UpdateAutoDepositRuleMutation?: Maybe<AutoDepositRuleGraphQlType>;
  /** Update the priority for auto deposit rules */
  UpdateAutoDepositRulePriorityMutation: AutoDepositRulesForEmailAddressGraphQlType;
  /** Update a Bank Account details */
  UpdateBankAccount?: Maybe<BankAccountBasicWithNickname>;
  /** Update the check memo or pttoo */
  UpdateCheck?: Maybe<Check>;
  UpdateCheckBatchItemsReactionMutation?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  UpdateCheckBatchMutation?: Maybe<CheckBatchType>;
  /** Update a Check Note */
  UpdateCheckNote: Note;
  /** Update a company notification subscription */
  UpdateCompanyNotificationSubscription?: Maybe<NotificationSubscriptions>;
  /** Updates External Auto Signer */
  UpdateExternalAutoSigner?: Maybe<ExternalAutoSigner>;
  /** Update Notification */
  UpdateNotification?: Maybe<Scalars["Int"]["output"]>;
  /** Update the organization */
  UpdateOrganization?: Maybe<Organization>;
  /** Update an unconfirmed bank account */
  UpdateUnconfirmedBankAccount?: Maybe<Scalars["Boolean"]["output"]>;
  /** Update a User Group */
  UpdateUserGroup?: Maybe<UserGroup>;
};

export type MutationAcceptInviteArgs = {
  invite_id: Scalars["String"]["input"];
};

export type MutationAccountPhoneNumberVerificationCodeRequestArgs = {
  channel?: InputMaybe<Scalars["String"]["input"]>;
  phone_number?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationAccountPhoneNumberVerificationCodeVerifyArgs = {
  code: Scalars["String"]["input"];
};

export type MutationAccountUpdatePasswordArgs = {
  password: Scalars["String"]["input"];
  password_confirmation: Scalars["String"]["input"];
  password_current: Scalars["String"]["input"];
};

export type MutationAddToProcessingMutationArgs = {
  command: AsyncProcessingRequestCommandEnum;
  payload?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationAddUserToGroupArgs = {
  organization_id: Scalars["String"]["input"];
  organization_user_id: Scalars["String"]["input"];
  user_group_id: Scalars["String"]["input"];
};

export type MutationApplyAclArgs = {
  acls?: InputMaybe<PermissionGroupInput>;
};

export type MutationArchivePendingChecksArgs = {
  checks: Array<InputMaybe<Scalars["String"]["input"]>>;
  company_id: Scalars["String"]["input"];
  integration_id: Scalars["String"]["input"];
};

export type MutationAssignGroupToCompanyMutationArgs = {
  company_id: Scalars["String"]["input"];
  group_id?: InputMaybe<Scalars["String"]["input"]>;
  organization_id: Scalars["String"]["input"];
};

export type MutationCheckActionMutationArgs = {
  action: Scalars["String"]["input"];
  checks: Array<Scalars["String"]["input"]>;
  organization_id?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationCreateAutoDepositRuleMutationArgs = {
  bank_account_id: Scalars["String"]["input"];
  conditions: ConditionInputGraphQlType;
  rule_priority?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type MutationCreateBankAccountAutoDepositAddressArgs = {
  bank_account_id: Scalars["String"]["input"];
  email_address_id: Scalars["String"]["input"];
};

export type MutationCreateBankAccountLinkedAddressArgs = {
  bank_account_id: Scalars["String"]["input"];
  email_address: Scalars["String"]["input"];
};

export type MutationCreateCheckBatchBankAccountsMutationArgs = {
  bank_accounts: Array<InputMaybe<Scalars["String"]["input"]>>;
  check_batch_id: Scalars["String"]["input"];
};

export type MutationCreateCheckBatchItemsMutationArgs = {
  check_batch_id: Scalars["String"]["input"];
  checks?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  filters?: InputMaybe<CheckFiltersType>;
};

export type MutationCreateCheckBatchMembersMutationArgs = {
  check_batch_id: Scalars["String"]["input"];
  organization_user_ids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type MutationCreateCheckBatchMutationArgs = {
  budget?: InputMaybe<Scalars["Float"]["input"]>;
  company_id: Scalars["String"]["input"];
  created_with?: InputMaybe<CheckBatchCreatedWithEnum>;
  name: Scalars["String"]["input"];
};

export type MutationCreateCheckNoteArgs = {
  attachments?: InputMaybe<Array<InputMaybe<Scalars["Upload"]["input"]>>>;
  check_id: Scalars["String"]["input"];
  context: Scalars["Int"]["input"];
  note?: InputMaybe<Scalars["String"]["input"]>;
  shared?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationCreateCompanyGroupMutationArgs = {
  label: Scalars["String"]["input"];
  organization_id: Scalars["String"]["input"];
};

export type MutationCreateExternalAutoSignerArgs = {
  company_id: Scalars["String"]["input"];
  integration_id: Scalars["String"]["input"];
  name?: InputMaybe<Scalars["String"]["input"]>;
  organization_user_id: Scalars["String"]["input"];
};

export type MutationCreateFeatureAccessRequestMutationArgs = {
  feature?: InputMaybe<FeatureEnumType>;
  organization_id: Scalars["String"]["input"];
};

export type MutationCreateInviteArgs = {
  email: Scalars["String"]["input"];
  organization_id: Scalars["String"]["input"];
};

export type MutationCreateUserGroupArgs = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  organization_id: Scalars["String"]["input"];
};

export type MutationDeleteAutoDepositRuleMutationArgs = {
  auto_deposit_rule_id: Scalars["String"]["input"];
};

export type MutationDeleteBankAccountAutoDepositAddressArgs = {
  bank_account_id: Scalars["String"]["input"];
  email_address_id: Scalars["String"]["input"];
};

export type MutationDeleteBankAccountLinkedAddressArgs = {
  linked_address_id: Scalars["String"]["input"];
};

export type MutationDeleteCheckBatchBankAccountsMutationArgs = {
  bank_accounts: Array<InputMaybe<Scalars["String"]["input"]>>;
  check_batch_id: Scalars["String"]["input"];
};

export type MutationDeleteCheckBatchItemsMutationArgs = {
  check_batch_id: Scalars["String"]["input"];
  checks: Array<InputMaybe<Scalars["String"]["input"]>>;
};

export type MutationDeleteCheckBatchMembersMutationArgs = {
  check_batch_id: Scalars["String"]["input"];
  organization_user_ids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type MutationDeleteCheckBatchMutationArgs = {
  check_batch_id: Scalars["String"]["input"];
};

export type MutationDeleteCheckNoteArgs = {
  note_id: Scalars["String"]["input"];
};

export type MutationDeleteCheckNoteAttachmentArgs = {
  attachment_id: Scalars["String"]["input"];
};

export type MutationDeleteCheckTagArgs = {
  tag_id: Scalars["String"]["input"];
};

export type MutationDeleteCompanyGroupMutationArgs = {
  group_id: Scalars["String"]["input"];
  organization_id: Scalars["String"]["input"];
};

export type MutationDeleteDelegationGroupArgs = {
  company_id?: InputMaybe<Scalars["String"]["input"]>;
  delegation_group_id?: InputMaybe<Scalars["String"]["input"]>;
  organization_id: Scalars["String"]["input"];
  scope: DelegationGroupScopeEnum;
  vendor_id?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationDeleteExternalAutoSignerArgs = {
  company_id: Scalars["String"]["input"];
  external_signer_id: Scalars["String"]["input"];
};

export type MutationDeleteInviteArgs = {
  invite_id: Scalars["String"]["input"];
};

export type MutationDeleteUserGroupArgs = {
  user_group_id: Scalars["String"]["input"];
};

export type MutationDepositChecksMutationArgs = {
  bank_account_id: Scalars["String"]["input"];
  checks: Array<Scalars["String"]["input"]>;
};

export type MutationEditCompanyGroupMutationArgs = {
  group_id: Scalars["String"]["input"];
  label?: InputMaybe<Scalars["String"]["input"]>;
  organization_id: Scalars["String"]["input"];
};

export type MutationGetIntegrationAuthCodeArgs = {
  company_id: Scalars["String"]["input"];
  integration_id: Scalars["String"]["input"];
  integration_settings?: InputMaybe<IntegrationSettingsInput>;
};

export type MutationImportPendingChecksArgs = {
  checks?: InputMaybe<Array<InputMaybe<ImportPendingChecks>>>;
  company_id: Scalars["String"]["input"];
  integration_id: Scalars["String"]["input"];
};

export type MutationIntegrationConfigurationArgs = {
  company_id: Scalars["String"]["input"];
  integration_configuration: Array<InputMaybe<IntegrationConfigurationInput>>;
  integration_id: Scalars["String"]["input"];
};

export type MutationLeaveOrganizationArgs = {
  organization_user_id: Scalars["String"]["input"];
};

export type MutationPromoteUnvalidatedUserArgs = {
  organization_user_id: Scalars["String"]["input"];
};

export type MutationRejectInviteArgs = {
  invite_id: Scalars["String"]["input"];
};

export type MutationRemoveUserFromGroupArgs = {
  organization_id: Scalars["String"]["input"];
  organization_user_id: Scalars["String"]["input"];
  user_group_id: Scalars["String"]["input"];
};

export type MutationRevokeAccessTokenArgs = {
  company_id: Scalars["String"]["input"];
  integration_id: Scalars["String"]["input"];
};

export type MutationSaveApprovalArgs = {
  bill_id?: InputMaybe<Scalars["String"]["input"]>;
  check_id?: InputMaybe<Scalars["String"]["input"]>;
  organization_id: Scalars["String"]["input"];
  resolution: ApprovalResolutionEnum;
  type: ApprovalTypeEnum;
};

export type MutationSaveDelegationGroupArgs = {
  approvals_required: Scalars["Int"]["input"];
  bill_id?: InputMaybe<Scalars["String"]["input"]>;
  check_id?: InputMaybe<Scalars["String"]["input"]>;
  company_id?: InputMaybe<Scalars["String"]["input"]>;
  delegation_group_id?: InputMaybe<Scalars["String"]["input"]>;
  delegations: Array<InputDelegation>;
  entity_context?: InputMaybe<InputDelegationGroupEntityContext>;
  is_root: Scalars["Boolean"]["input"];
  labeling_category_id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  organization_id: Scalars["String"]["input"];
  requirement_type: DelegationGroupRequirementTypeEnum;
  reviews_required: Scalars["Int"]["input"];
  root_delegator_id?: InputMaybe<Scalars["String"]["input"]>;
  scope: DelegationGroupScopeEnum;
  type: DelegationGroupTypeEnum;
  vendor_id?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationSaveFrontendSettingsMutationArgs = {
  context: FrontendSettingsContextEnum;
  id: Scalars["String"]["input"];
  path: Scalars["String"]["input"];
  value?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationSaveLabelingCategoryArgs = {
  company_id?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  organization_id: Scalars["String"]["input"];
};

export type MutationSaveSharedFiltersMutationArgs = {
  context: Scalars["String"]["input"];
  filters: Scalars["String"]["input"];
  organization_id?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationSetBankAccountGlAccountMappingArgs = {
  bank_account_id: Scalars["String"]["input"];
  gl_account_id: Scalars["String"]["input"];
};

export type MutationSignCheckBatchMutationArgs = {
  check_batch_id: Scalars["String"]["input"];
};

export type MutationUnmapBankAccountGlAccountMappingArgs = {
  bank_account_id: Scalars["String"]["input"];
};

export type MutationUpdateAutoDepositRuleMutationArgs = {
  auto_deposit_rule_id: Scalars["String"]["input"];
  bank_account_id: Scalars["String"]["input"];
  conditions: ConditionInputGraphQlType;
  rule_priority: Array<InputMaybe<Scalars["String"]["input"]>>;
};

export type MutationUpdateAutoDepositRulePriorityMutationArgs = {
  email_address_id: Scalars["String"]["input"];
  rule_priority: Array<InputMaybe<Scalars["String"]["input"]>>;
};

export type MutationUpdateBankAccountArgs = {
  bank_account_id: Scalars["String"]["input"];
  nickname?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationUpdateCheckArgs = {
  id: Scalars["String"]["input"];
  memo?: InputMaybe<Scalars["String"]["input"]>;
  pttoo?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationUpdateCheckBatchItemsReactionMutationArgs = {
  check_batch_item_ids?: InputMaybe<Array<Scalars["String"]["input"]>>;
  reaction: ReactionEnumType;
};

export type MutationUpdateCheckBatchMutationArgs = {
  budget?: InputMaybe<Scalars["Float"]["input"]>;
  check_batch_id: Scalars["String"]["input"];
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationUpdateCheckNoteArgs = {
  note?: InputMaybe<Scalars["String"]["input"]>;
  note_id: Scalars["String"]["input"];
  shared?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationUpdateCompanyNotificationSubscriptionArgs = {
  company_id: Scalars["String"]["input"];
  email_notification: Scalars["Boolean"]["input"];
};

export type MutationUpdateExternalAutoSignerArgs = {
  company_id: Scalars["String"]["input"];
  external_signer_id: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
};

export type MutationUpdateNotificationArgs = {
  notification_id: Scalars["String"]["input"];
};

export type MutationUpdateOrganizationArgs = {
  name?: InputMaybe<Scalars["String"]["input"]>;
  organization_id: Scalars["String"]["input"];
};

export type MutationUpdateUnconfirmedBankAccountArgs = {
  action: Scalars["String"]["input"];
  bank_account_id: Scalars["String"]["input"];
};

export type MutationUpdateUserGroupArgs = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  user_group_id: Scalars["String"]["input"];
};

/** A Note */
export type Note = {
  __typename?: "Note";
  /** A list of Attachments */
  attachments?: Maybe<Array<AttachmentType>>;
  /** Can manage */
  can_manage: Scalars["Boolean"]["output"];
  /** Note context */
  context: Scalars["Int"]["output"];
  /** Name */
  created_at?: Maybe<Scalars["String"]["output"]>;
  /** Name */
  note?: Maybe<Scalars["String"]["output"]>;
  /** Note type */
  type: Scalars["Int"]["output"];
};

/** A notification subscription object */
export type NotificationSubscriptions = {
  __typename?: "NotificationSubscriptions";
  /** The account id */
  account_id: Scalars["String"]["output"];
  /** The company id */
  company_id: Scalars["String"]["output"];
  /** Email Notification */
  email_notification?: Maybe<Scalars["Boolean"]["output"]>;
  /** The notification subscription id */
  id: Scalars["String"]["output"];
};

export type Notifications = {
  __typename?: "Notifications";
  /** Last 20 notifications */
  notifications?: Maybe<Array<AppNotifications>>;
  /** Number of unread notifications */
  unread_count?: Maybe<Scalars["Int"]["output"]>;
};

/** An organization object */
export type Organization = {
  __typename?: "Organization";
  /** Companies in this organization */
  companies?: Maybe<Array<Company>>;
  feature_access_requests?: Maybe<Array<Maybe<FeatureAccessRequestType>>>;
  /** The organization id */
  id: Scalars["String"]["output"];
  /** Organization name */
  name: Scalars["String"]["output"];
};

/** An organization basic object */
export type OrganizationBasic = {
  __typename?: "OrganizationBasic";
  /** Companies in this organization */
  companies: Array<CompanyBasic>;
  /** The organization id */
  id: Scalars["String"]["output"];
  /** Organization name */
  name: Scalars["String"]["output"];
};

/** A Paginated list of companies. */
export type OrganizationDashboard = {
  __typename?: "OrganizationDashboard";
  /** Whether the organization has Check Batching enabled */
  check_batching_enabled?: Maybe<Scalars["Boolean"]["output"]>;
  /** The list of companies */
  companies?: Maybe<Array<CompanyWithBankAccountsAndAggregatedData>>;
  companies_pending_checks_count?: Maybe<Array<CompanyPendingChecksCount>>;
  /** Pagination data */
  pagination?: Maybe<PaginationResult>;
  /** A list of companies and bank accounts pending actions */
  pending_actions?: Maybe<Array<PendingAction>>;
  unvalidated_dashboard?: Maybe<UnvalidatedDashboardType>;
  /** The vBill email address for this organization (if onboarded) */
  vbill_email?: Maybe<Scalars["String"]["output"]>;
  /** Whether the organization has companies onboarded to vBill */
  vbill_enabled?: Maybe<Scalars["Boolean"]["output"]>;
};

/** OrganizationDashboardFilterInput object */
export type OrganizationDashboardFilterInput = {
  company_ids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** The search string */
  search?: InputMaybe<Scalars["String"]["input"]>;
};

/** An organization user */
export type OrganizationUser = {
  __typename?: "OrganizationUser";
  /** Account object */
  account?: Maybe<BasicAccount>;
  /** ACLPermission object */
  acl_permissions?: Maybe<Array<AclPermission>>;
  /** The organization user id */
  id: Scalars["String"]["output"];
  /** List of invites ids */
  invites_ids?: Maybe<Array<Scalars["String"]["output"]>>;
  /** Organization object */
  organization: Organization;
  /** The state of the OrganizationUser */
  state?: Maybe<OrganizationUserEnum>;
  /** List of UserGroups this user is a member of */
  user_groups?: Maybe<Array<UserGroup>>;
};

/** The State */
export enum OrganizationUserEnum {
  Active = "ACTIVE",
  Deleted = "DELETED",
  Invited = "INVITED",
  Unvalidated = "UNVALIDATED",
}

export type OrganizationUserType = {
  __typename?: "OrganizationUserType";
  account?: Maybe<AccountType>;
  id?: Maybe<Scalars["String"]["output"]>;
};

export type OrganizationUsersWithPaginatedType = {
  __typename?: "OrganizationUsersWithPaginatedType";
  pagination?: Maybe<PaginationResponseType>;
  rows?: Maybe<Array<Maybe<OrganizationUserType>>>;
};

export type Pagination = {
  /** The page */
  page?: InputMaybe<Scalars["Int"]["input"]>;
  /** Results per page */
  per_page?: InputMaybe<Scalars["Int"]["input"]>;
};

export type PaginationRequestType = {
  page?: InputMaybe<Scalars["Int"]["input"]>;
  per_page?: InputMaybe<Scalars["Int"]["input"]>;
};

export type PaginationResponseType = {
  __typename?: "PaginationResponseType";
  current_page?: Maybe<Scalars["Int"]["output"]>;
  first_item?: Maybe<Scalars["Int"]["output"]>;
  has_more_pages?: Maybe<Scalars["Boolean"]["output"]>;
  has_pages?: Maybe<Scalars["Boolean"]["output"]>;
  last_item?: Maybe<Scalars["Int"]["output"]>;
  last_page?: Maybe<Scalars["Int"]["output"]>;
  on_first_page?: Maybe<Scalars["Boolean"]["output"]>;
  per_page?: Maybe<Scalars["Int"]["output"]>;
  total?: Maybe<Scalars["Int"]["output"]>;
};

/** Pagination Result object */
export type PaginationResult = {
  __typename?: "PaginationResult";
  /** Current page number */
  current_page?: Maybe<Scalars["Int"]["output"]>;
  /** First item number */
  first_item?: Maybe<Scalars["Int"]["output"]>;
  /** Does pagination have more pages */
  has_more_pages?: Maybe<Scalars["Boolean"]["output"]>;
  /** Does pagination have pages */
  has_pages?: Maybe<Scalars["Boolean"]["output"]>;
  /** Last item number */
  last_item?: Maybe<Scalars["Int"]["output"]>;
  /** Last page number */
  last_page?: Maybe<Scalars["Int"]["output"]>;
  /** Is on first page */
  on_first_page?: Maybe<Scalars["Boolean"]["output"]>;
  /** Number of items per page */
  per_page?: Maybe<Scalars["Int"]["output"]>;
  /** Total number of items */
  total?: Maybe<Scalars["Int"]["output"]>;
};

/** A PendingAction object. */
export type PendingAction = {
  __typename?: "PendingAction";
  /** The bank account number */
  bank_account_account_number?: Maybe<Scalars["String"]["output"]>;
  /** The bank account name */
  bank_account_name?: Maybe<Scalars["String"]["output"]>;
  /** The company name */
  company_name?: Maybe<Scalars["String"]["output"]>;
  /** The company or bank account verification link */
  verification_link?: Maybe<Scalars["String"]["output"]>;
};

/** A Pending Check Attachment */
export type PendingCheckAttachment = {
  __typename?: "PendingCheckAttachment";
  /** Name */
  name: Scalars["String"]["output"];
  /** Full Name */
  path: Scalars["String"]["output"];
};

/** A PendingCheckErrors object */
export type PendingCheckErrors = {
  __typename?: "PendingCheckErrors";
  /** Json with data */
  data?: Maybe<Scalars["String"]["output"]>;
  /** Date */
  message?: Maybe<Scalars["String"]["output"]>;
  /** PTTOO */
  type?: Maybe<Scalars["String"]["output"]>;
};

/** A Pending Checks object */
export type PendingChecks = {
  __typename?: "PendingChecks";
  /** List of checks */
  checks?: Maybe<Array<Check>>;
  /** total number of checks */
  total?: Maybe<Scalars["Int"]["output"]>;
};

/** Error type */
export enum PendingChecksErrorTypesEnum {
  BankAccountNotFound = "BANK_ACCOUNT_NOT_FOUND",
  DuplicateCheckNumber = "DUPLICATE_CHECK_NUMBER",
  DuplicateEntityRef = "DUPLICATE_ENTITY_REF",
  GlAccountNotFound = "GL_ACCOUNT_NOT_FOUND",
  InsufficientPermission = "INSUFFICIENT_PERMISSION",
  InvalidBiller = "INVALID_BILLER",
  InvalidBillPayment = "INVALID_BILL_PAYMENT",
  InvalidContact = "INVALID_CONTACT",
  InvalidRecipientEmail = "INVALID_RECIPIENT_EMAIL",
  MissingBankAccount = "MISSING_BANK_ACCOUNT",
  MissingContactExternalRef = "MISSING_CONTACT_EXTERNAL_REF",
  MissingGlAccountOrBankAccount = "MISSING_GL_ACCOUNT_OR_BANK_ACCOUNT",
  NoPostalAddress = "NO_POSTAL_ADDRESS",
  RecipientEmailLocked = "RECIPIENT_EMAIL_LOCKED",
  UnmappedGlAccount = "UNMAPPED_GL_ACCOUNT",
  UnmappedUser = "UNMAPPED_USER",
  UserUnableToWriteThisCheck = "USER_UNABLE_TO_WRITE_THIS_CHECK",
  Validation = "VALIDATION",
}

/** PendingChecksFilterInput object */
export type PendingChecksFilterInput = {
  /** Max amount */
  amount_max?: InputMaybe<Scalars["Float"]["input"]>;
  /** Min amount */
  amount_min?: InputMaybe<Scalars["Float"]["input"]>;
  /** Contact external ref */
  contact?: InputMaybe<Scalars["String"]["input"]>;
  /** Error Type */
  error_type?: InputMaybe<PendingChecksErrorTypesEnum>;
  /** From date */
  from_date?: InputMaybe<Scalars["String"]["input"]>;
  /** Search for string, minimum 3 characters */
  search?: InputMaybe<Scalars["String"]["input"]>;
  /** To date */
  to_date?: InputMaybe<Scalars["String"]["input"]>;
};

/** A PermissionGroup object */
export type PermissionGroup = {
  __typename?: "PermissionGroup";
  /** Permission group category */
  category?: Maybe<Scalars["String"]["output"]>;
  /** Permission group name */
  group_name: Scalars["String"]["output"];
  /** The Permission Group id */
  id: Scalars["String"]["output"];
  /** Permission group internal name */
  internal_alias?: Maybe<Scalars["String"]["output"]>;
  /** The PermissionType object */
  permission_types?: Maybe<Array<PermissionType>>;
};

/** An ACL Permission Group Input object */
export type PermissionGroupInput = {
  /** The list of permissions for this permission group */
  BANK_ACCOUNT_CHECK_CREATE?: InputMaybe<Array<InputMaybe<BankAccountCheckCreateAclPermissionInput>>>;
  /** The list of permissions for this permission group */
  BANK_ACCOUNT_CHECK_MANAGE?: InputMaybe<Array<InputMaybe<BankAccountManageCheckAclPermissionInput>>>;
  /** The list of permissions for this permission group */
  BANK_ACCOUNT_CHECK_SIGNER?: InputMaybe<Array<InputMaybe<BankAccountCheckSignerAclPermissionInput>>>;
  /** The list of permissions for this permission group */
  BANK_ACCOUNT_LEDGER_ACCESS?: InputMaybe<Array<InputMaybe<BankAccountLedgerAccessAclPermissionInput>>>;
  /** The list of permissions for this permission group */
  BANK_ACCOUNT_MANAGE?: InputMaybe<Array<InputMaybe<BasePermissionInput>>>;
  /** The list of permissions for this permission group */
  BANK_ACCOUNT_VIEW?: InputMaybe<Array<InputMaybe<BasePermissionInput>>>;
  /** The list of permissions for this permission group */
  COMPANY_INTEGRATION_MANAGER?: InputMaybe<Array<InputMaybe<BasePermissionInput>>>;
  /** The list of permissions for this permission group */
  COMPANY_VBILL_ACCESS?: InputMaybe<Array<InputMaybe<BasePermissionInput>>>;
  /** The list of permissions for this permission group */
  COMPANY_VBILL_EDIT_MAP?: InputMaybe<Array<InputMaybe<BasePermissionInput>>>;
  /** The list of permissions for this permission group */
  COMPANY_VBILL_PAYER?: InputMaybe<Array<InputMaybe<BasePermissionInput>>>;
  /** The list of permissions for this permission group */
  COMPANY_VIEW?: InputMaybe<Array<InputMaybe<BasePermissionInput>>>;
  /** The list of permissions for this permission group */
  ORGANIZATION_COMPANY_MANAGER?: InputMaybe<Array<InputMaybe<BasePermissionInput>>>;
  /** The list of permissions for this permission group */
  ORGANIZATION_USER_MANAGER?: InputMaybe<Array<InputMaybe<BasePermissionInput>>>;
  /** The list of permissions for this permission group */
  ORGANIZATION_VBILL_COMPANY_MAPPER?: InputMaybe<Array<InputMaybe<BasePermissionInput>>>;
};

/** A PermissionType object */
export type PermissionType = {
  __typename?: "PermissionType";
  /** Description */
  description?: Maybe<Scalars["String"]["output"]>;
  /** Permission name */
  display_name: Scalars["String"]["output"];
  /** The Permission type id */
  id: Scalars["String"]["output"];
  /** Permission name */
  permission: AclPermissionTypeEnum;
};

export type Query = {
  __typename?: "Query";
  ACLPermissionAggregatedLimits?: Maybe<AppliedPermissionGroup>;
  ACLPermissions?: Maybe<AppliedPermissionGroup>;
  ACLPermissionsFlat: Array<AclPermissionFlat>;
  AccountDetails: AccountDetails;
  AccountInvites?: Maybe<Array<Invite>>;
  /** A query to get the auto deposit addresses for a bank account */
  BankAccountAutoDepositAddresses?: Maybe<Array<BankAccountAutoDepositAddress>>;
  /** A query to get the available auto deposit addresses for a bank account */
  BankAccountAvailableAutoDepositAddresses?: Maybe<Array<EmailAddress>>;
  /** A query to get the linked addresses for a bank account */
  BankAccountLinkedAddresses?: Maybe<Array<BankAccountLinkedAddress>>;
  BillerDirectory?: Maybe<Array<Biller>>;
  CompanyDetails?: Maybe<CompanyDetails>;
  CompanyIntegrations?: Maybe<CompanyIntegrations>;
  /** Get the list of Contacts. */
  Contacts?: Maybe<ContactsQueryResult>;
  DelegationGroupResolution: Array<DelegationGroupResolved>;
  DelegationGroups: DelegationGroupsResponse;
  /** Get all bank accounts where organizations user is active on. */
  DepositBankAccounts?: Maybe<Array<Maybe<OrganizationBasic>>>;
  /** Get the General Ledger Accounts list. */
  GeneralLedgerAccounts?: Maybe<Array<GeneralLedgerAccount>>;
  GetAllKnownExtSignerNames?: Maybe<Array<Scalars["String"]["output"]>>;
  GetAsyncRequestQuery?: Maybe<AsyncProcessingRequestType>;
  /** Get the auto deposit rules for an email address */
  GetAutoDepositRulesForEmailAddressQuery: AutoDepositRulesForEmailAddressGraphQlType;
  GetAvailableBankAccountsForBatchQuery?: Maybe<BankAccountsPaginatedType>;
  GetAvailableChecksForBatchQuery?: Maybe<ChecksPaginatedType>;
  GetCheckBatchAvailableMembersQuery?: Maybe<OrganizationUsersWithPaginatedType>;
  GetCheckBatchBankAccountsQuery?: Maybe<CheckBatchBankAccountsPaginatedType>;
  GetCheckBatchMembersQuery?: Maybe<OrganizationUsersWithPaginatedType>;
  GetCheckBatchQuery?: Maybe<CheckBatchType>;
  GetCheckBatchesQuery?: Maybe<CheckBatchesPaginatedType>;
  GetCheckDetailsQuery?: Maybe<CheckType>;
  GetCompanyGroupsQuery?: Maybe<Array<Maybe<CompanyGroupType>>>;
  GetFrontendSettingsQuery?: Maybe<Scalars["String"]["output"]>;
  GetNotProcessedRequestsQuery?: Maybe<Array<Maybe<AsyncProcessingRequestType>>>;
  GetNotifications: Notifications;
  GetSharedFiltersQuery?: Maybe<SharedFiltersType>;
  Integrations?: Maybe<Array<Integration>>;
  LabelingCategories: Array<LabelingCategory>;
  OrganizationDashboard: OrganizationDashboard;
  OrganizationUsers: Array<OrganizationUser>;
  /** Get list of pending checks */
  PendingChecks?: Maybe<PendingChecks>;
  PermissionsGroups: Array<PermissionGroup>;
  Ping?: Maybe<Scalars["Boolean"]["output"]>;
  SessionAccount: SessionAccountData;
  /** Get all User Groups. */
  UserGroups: Array<UserGroup>;
  VCheckCheckQuery?: Maybe<CheckDetailsType>;
  VCheckChecks?: Maybe<VCheckChecksQueryResult>;
  /** Get the list of Undeposited vChecks. */
  VCheckUndepositedChecks?: Maybe<VCheckUndepositedChecksQueryResult>;
};

export type QueryAclPermissionAggregatedLimitsArgs = {
  acl_source?: InputMaybe<AclSourceEnum>;
  organization_id?: InputMaybe<Scalars["String"]["input"]>;
  organization_user_id?: InputMaybe<Scalars["String"]["input"]>;
  user_group_id?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryAclPermissionsArgs = {
  filters: AclPermissionsFiltersInput;
};

export type QueryAclPermissionsFlatArgs = {
  organization_user_id: Scalars["String"]["input"];
};

export type QueryBankAccountAutoDepositAddressesArgs = {
  bank_account_id: Scalars["String"]["input"];
};

export type QueryBankAccountAvailableAutoDepositAddressesArgs = {
  bank_account_id: Scalars["String"]["input"];
};

export type QueryBankAccountLinkedAddressesArgs = {
  bank_account_id: Scalars["String"]["input"];
};

export type QueryBillerDirectoryArgs = {
  address?: InputMaybe<Scalars["String"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  zip?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryCompanyDetailsArgs = {
  company_id: Scalars["String"]["input"];
};

export type QueryCompanyIntegrationsArgs = {
  company_id: Scalars["String"]["input"];
};

export type QueryContactsArgs = {
  company_id: Scalars["String"]["input"];
  pagination?: InputMaybe<Pagination>;
  search?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryDelegationGroupResolutionArgs = {
  bill_id?: InputMaybe<Scalars["String"]["input"]>;
  check_id?: InputMaybe<Scalars["String"]["input"]>;
  organization_id?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryDelegationGroupsArgs = {
  bill_id?: InputMaybe<Scalars["String"]["input"]>;
  check_id?: InputMaybe<Scalars["String"]["input"]>;
  company_id?: InputMaybe<Scalars["String"]["input"]>;
  delegator_user_id?: InputMaybe<Scalars["String"]["input"]>;
  is_root?: InputMaybe<Scalars["Boolean"]["input"]>;
  match_any?: InputMaybe<Scalars["Boolean"]["input"]>;
  organization_id: Scalars["String"]["input"];
  scope: DelegationGroupScopeEnum;
  vendor_id?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryGeneralLedgerAccountsArgs = {
  account_types?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  company_id?: InputMaybe<Scalars["String"]["input"]>;
  pagination?: InputMaybe<Pagination>;
  vcard_company_account_id?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryGetAllKnownExtSignerNamesArgs = {
  company_id: Scalars["String"]["input"];
};

export type QueryGetAsyncRequestQueryArgs = {
  id: Scalars["String"]["input"];
};

export type QueryGetAutoDepositRulesForEmailAddressQueryArgs = {
  email_address_id: Scalars["String"]["input"];
};

export type QueryGetAvailableBankAccountsForBatchQueryArgs = {
  check_batch_id: Scalars["String"]["input"];
  pagination?: InputMaybe<PaginationRequestType>;
  search?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryGetAvailableChecksForBatchQueryArgs = {
  check_batch_id: Scalars["String"]["input"];
  filters?: InputMaybe<CheckFiltersType>;
  pagination?: InputMaybe<PaginationRequestType>;
};

export type QueryGetCheckBatchAvailableMembersQueryArgs = {
  check_batch_id: Scalars["String"]["input"];
  pagination?: InputMaybe<PaginationRequestType>;
  search?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryGetCheckBatchBankAccountsQueryArgs = {
  check_batch_id: Scalars["String"]["input"];
  pagination?: InputMaybe<PaginationRequestType>;
};

export type QueryGetCheckBatchMembersQueryArgs = {
  check_batch_id: Scalars["String"]["input"];
  pagination?: InputMaybe<PaginationRequestType>;
  search?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryGetCheckBatchQueryArgs = {
  check_batch_id: Scalars["String"]["input"];
  filters?: InputMaybe<CheckFiltersType>;
  pagination?: InputMaybe<PaginationRequestType>;
};

export type QueryGetCheckBatchesQueryArgs = {
  filters?: InputMaybe<GetCheckBatchesFiltersType>;
  organization_id: Scalars["String"]["input"];
  pagination?: InputMaybe<PaginationRequestType>;
};

export type QueryGetCheckDetailsQueryArgs = {
  check_batch_id: Scalars["String"]["input"];
  check_details_scope?: InputMaybe<CheckDetailsScopeEnum>;
  check_id: Scalars["String"]["input"];
};

export type QueryGetCompanyGroupsQueryArgs = {
  organization_id: Scalars["String"]["input"];
};

export type QueryGetFrontendSettingsQueryArgs = {
  context: FrontendSettingsContextEnum;
  id: Scalars["String"]["input"];
};

export type QueryGetSharedFiltersQueryArgs = {
  token: Scalars["String"]["input"];
};

export type QueryIntegrationsArgs = {
  company_id?: InputMaybe<Scalars["String"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryLabelingCategoriesArgs = {
  company_id?: InputMaybe<Scalars["String"]["input"]>;
  organization_id: Scalars["String"]["input"];
};

export type QueryOrganizationDashboardArgs = {
  filters?: InputMaybe<OrganizationDashboardFilterInput>;
  get_unvalidated_dashboard?: InputMaybe<Scalars["Boolean"]["input"]>;
  organization_id?: InputMaybe<Scalars["String"]["input"]>;
  pagination?: InputMaybe<Pagination>;
};

export type QueryOrganizationUsersArgs = {
  organization_id: Scalars["String"]["input"];
  organization_user_id?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryPendingChecksArgs = {
  checks?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  company_id: Scalars["String"]["input"];
  filters?: InputMaybe<PendingChecksFilterInput>;
  integration_id: Scalars["String"]["input"];
  pagination?: InputMaybe<Pagination>;
};

export type QueryUserGroupsArgs = {
  filters?: InputMaybe<UserGroupsFiltersInput>;
  organization_id: Scalars["String"]["input"];
};

export type QueryVCheckCheckQueryArgs = {
  bank_account_id?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["String"]["input"];
};

export type QueryVCheckChecksArgs = {
  filters?: InputMaybe<CheckFiltersType>;
  organization_id?: InputMaybe<Scalars["String"]["input"]>;
  pagination?: InputMaybe<Pagination>;
  quick_filters?: InputMaybe<Array<InputMaybe<CheckQuickFiltersEnumType>>>;
};

export type QueryVCheckUndepositedChecksArgs = {
  filters?: InputMaybe<VCheckUndepositedChecksFilterInput>;
  pagination?: InputMaybe<Pagination>;
};

export enum ReactionEnumType {
  Downvote = "DOWNVOTE",
  Upvote = "UPVOTE",
  Warning = "WARNING",
}

export type RemittanceRecordMetaType = {
  __typename?: "RemittanceRecordMetaType";
  label?: Maybe<Scalars["String"]["output"]>;
  order?: Maybe<Scalars["Int"]["output"]>;
  value?: Maybe<Scalars["String"]["output"]>;
};

export type RemittanceRecordType = {
  __typename?: "RemittanceRecordType";
  amount?: Maybe<Scalars["Float"]["output"]>;
  created_at?: Maybe<Scalars["String"]["output"]>;
  date?: Maybe<Scalars["String"]["output"]>;
  entity?: Maybe<Scalars["String"]["output"]>;
  entity_ref?: Maybe<Scalars["String"]["output"]>;
  meta?: Maybe<Array<RemittanceRecordMetaType>>;
};

/** The filter for query Checks. */
export type ReviewAndSignChecks = {
  bank_account_id?: InputMaybe<Scalars["String"]["input"]>;
  /** Date from */
  date?: InputMaybe<Scalars["String"]["input"]>;
  date_sort?: InputMaybe<Scalars["String"]["input"]>;
  /** Date to */
  date_to?: InputMaybe<Scalars["String"]["input"]>;
  /** Search for text */
  for_text?: InputMaybe<Scalars["String"]["input"]>;
  /** The check memo. */
  memo?: InputMaybe<Scalars["String"]["input"]>;
  tags?: InputMaybe<Scalars["String"]["input"]>;
};

export type SessionAccountData = {
  __typename?: "SessionAccountData";
  /** Account object */
  account?: Maybe<Account>;
  /** Auto deposit bank account */
  auto_deposit_bank_account?: Maybe<BankAccountBasic>;
  /** Number of deposit accounts */
  deposit_accounts?: Maybe<Scalars["Int"]["output"]>;
  has_in_progress_onboarding?: Maybe<Scalars["Boolean"]["output"]>;
  has_pending_invites?: Maybe<Scalars["Boolean"]["output"]>;
  /** OrganizationUser object */
  organizationUsers?: Maybe<Array<OrganizationUser>>;
  /** List of organization id's where the user is a manager */
  organizations_where_is_manager?: Maybe<Array<Scalars["String"]["output"]>>;
  /** Unconfirmed bank accounts */
  unconfirmed_bank_accounts?: Maybe<Array<BankAccountBasic>>;
};

export type SharedFiltersType = {
  __typename?: "SharedFiltersType";
  context: Scalars["String"]["output"];
  filters: Scalars["String"]["output"];
  token: Scalars["String"]["output"];
  usages: Scalars["Int"]["output"];
};

export type SignCheckBatchType = {
  __typename?: "SignCheckBatchType";
  checks_available_for_signing?: Maybe<Scalars["Int"]["output"]>;
  signed_checks?: Maybe<Scalars["Int"]["output"]>;
};

export enum SortEnumType {
  Asc = "asc",
  Desc = "desc",
}

/** Success object */
export type Success = {
  __typename?: "Success";
  /** Whether the call was successfull */
  success: Scalars["Boolean"]["output"];
};

export type UnvalidatedDashboardType = {
  __typename?: "UnvalidatedDashboardType";
  /** Whether the organization has Check Batching enabled */
  check_batching_enabled?: Maybe<Scalars["Boolean"]["output"]>;
  /** The list of companies */
  companies?: Maybe<Array<CompanyWithBankAccountsAndAggregatedData>>;
  companies_pending_checks_count?: Maybe<Array<CompanyPendingChecksCount>>;
  /** Pagination data */
  pagination?: Maybe<PaginationResult>;
  /** A list of companies and bank accounts pending actions */
  pending_actions?: Maybe<Array<PendingAction>>;
  /** The vBill email address for this organization (if onboarded) */
  vbill_email?: Maybe<Scalars["String"]["output"]>;
  /** Whether the organization has companies onboarded to vBill */
  vbill_enabled?: Maybe<Scalars["Boolean"]["output"]>;
};

/** A User Group object */
export type UserGroup = {
  __typename?: "UserGroup";
  /** The User Group description */
  description?: Maybe<Scalars["String"]["output"]>;
  /** The id of the email */
  id?: Maybe<Scalars["String"]["output"]>;
  /** The User Group name */
  name?: Maybe<Scalars["String"]["output"]>;
  /** The Organization User object */
  organization_users?: Maybe<Array<OrganizationUser>>;
};

/** A User Groups Filters Input object */
export type UserGroupsFiltersInput = {
  /** The User Group id */
  user_group_id?: InputMaybe<Scalars["String"]["input"]>;
};

/** A VCard object */
export type VCard = {
  __typename?: "VCard";
  /** Date of card activation */
  active_since?: Maybe<Scalars["String"]["output"]>;
  /** The card expiry date */
  expire_date?: Maybe<Scalars["String"]["output"]>;
  /** The id of the vCard */
  id: Scalars["String"]["output"];
  /** Account who created the vCard invite */
  inviter?: Maybe<BasicAccount>;
  /** Last four digits of the vCard */
  last_four_digits: Scalars["String"]["output"];
  /** The state of the vCard */
  state: Scalars["String"]["output"];
  /** The vCard Company Account id */
  vcard_company_account_id: Scalars["String"]["output"];
};

/** A VCardCompanyAccount object */
export type VCardCompanyAccount = {
  __typename?: "VCardCompanyAccount";
  /** The vCard Company Account id */
  id: Scalars["String"]["output"];
  /** List of linked vCards */
  vcards?: Maybe<Array<VCard>>;
};

export type VCheckChecksQueryResult = {
  __typename?: "VCheckChecksQueryResult";
  checks?: Maybe<Array<LedgerCheck>>;
  pagination?: Maybe<PaginationResult>;
  quick_filters?: Maybe<Array<Maybe<VChecksStats>>>;
};

/** The State */
export enum VCheckContext {
  Recipient = "RECIPIENT",
  Sender = "SENDER",
}

/** VCheckUndepositedChecksFilterInput object */
export type VCheckUndepositedChecksFilterInput = {
  /** The minimum amount */
  amount_from?: InputMaybe<Scalars["Float"]["input"]>;
  /** The amount sort order: asc | desc */
  amount_sort?: InputMaybe<Scalars["String"]["input"]>;
  /** The maximum amount */
  amount_to?: InputMaybe<Scalars["Float"]["input"]>;
  /** The check id */
  check_id?: InputMaybe<Scalars["String"]["input"]>;
  /** The check created at sort order: asc | desc */
  created_at_sort?: InputMaybe<Scalars["String"]["input"]>;
  /** The date from (Y-m-d) */
  date?: InputMaybe<Scalars["String"]["input"]>;
  /** The date sort order: asc | desc */
  date_sort?: InputMaybe<Scalars["String"]["input"]>;
  /** The date to (Y-m-d) */
  date_to?: InputMaybe<Scalars["String"]["input"]>;
  /** The memo */
  memo?: InputMaybe<Scalars["String"]["input"]>;
  /** The check number sort order: asc | desc */
  number_sort?: InputMaybe<Scalars["String"]["input"]>;
  /** The search string */
  search?: InputMaybe<Scalars["String"]["input"]>;
  /** Comma separated tags */
  tags?: InputMaybe<Scalars["String"]["input"]>;
};

/** A Paginated list of Undeposited vChecks. */
export type VCheckUndepositedChecksQueryResult = {
  __typename?: "VCheckUndepositedChecksQueryResult";
  /** A list of vChecks */
  checks?: Maybe<Array<LedgerCheck>>;
  /** Bank Holiday delay message */
  delay_bank_holiday?: Maybe<Scalars["String"]["output"]>;
  /** Weekend delay message */
  delay_weekend?: Maybe<Scalars["String"]["output"]>;
  /** Pagination data */
  pagination?: Maybe<PaginationResult>;
  /** The stats of all undeposited vChecks */
  undeposited_checks?: Maybe<VChecksStats>;
};

export type VChecksStats = {
  __typename?: "VChecksStats";
  /** The count of vChecks */
  count?: Maybe<Scalars["Int"]["output"]>;
  quick_filter?: Maybe<Scalars["String"]["output"]>;
  /** The sum of vChecks */
  sum?: Maybe<Scalars["Float"]["output"]>;
};

export type ValueWithLabelType = {
  __typename?: "ValueWithLabelType";
  label?: Maybe<Scalars["String"]["output"]>;
  value?: Maybe<Scalars["String"]["output"]>;
};

export type AclOrganizationAggregatedLimitsQueryVariables = Exact<{
  organization_id: Scalars["String"]["input"];
  organization_user_id?: InputMaybe<Scalars["String"]["input"]>;
  user_group_id?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type AclOrganizationAggregatedLimitsQuery = {
  __typename?: "Query";
  ACLPermissionAggregatedLimits?: {
    __typename?: "AppliedPermissionGroup";
    BANK_ACCOUNT_LEDGER_ACCESS?: Array<{
      __typename?: "BankAccountLedgerAccessACLPermission";
      organization_user_id?: string | null;
      user_group_id?: string | null;
      entity_type?: AclEntityTypeEnum | null;
      entity_id?: string | null;
      acl_source?: AclSourceEnum | null;
      orig_entity_type?: AclEntityTypeEnum | null;
      orig_entity_id?: string | null;
      limit_ledger_access_max_amount?: number | null;
    }> | null;
    BANK_ACCOUNT_CHECK_CREATE?: Array<{
      __typename?: "BankAccountCheckCreateACLPermission";
      organization_user_id?: string | null;
      user_group_id?: string | null;
      entity_type?: AclEntityTypeEnum | null;
      entity_id?: string | null;
      acl_source?: AclSourceEnum | null;
      orig_entity_type?: AclEntityTypeEnum | null;
      orig_entity_id?: string | null;
      limit_create_vchecks_rules?: Array<{
        __typename?: "LimitCreateVChecksRule";
        count?: number | null;
        above?: number | null;
      }> | null;
    }> | null;
    ORGANIZATION_USER_MANAGER?: Array<{
      __typename?: "BasePermissionType";
      organization_user_id?: string | null;
      user_group_id?: string | null;
      entity_type?: AclEntityTypeEnum | null;
      entity_id?: string | null;
      acl_source?: AclSourceEnum | null;
      orig_entity_type?: AclEntityTypeEnum | null;
      orig_entity_id?: string | null;
    }> | null;
    COMPANY_INTEGRATION_MANAGER?: Array<{
      __typename?: "BasePermissionType";
      organization_user_id?: string | null;
      user_group_id?: string | null;
      entity_type?: AclEntityTypeEnum | null;
      entity_id?: string | null;
      acl_source?: AclSourceEnum | null;
      orig_entity_type?: AclEntityTypeEnum | null;
      orig_entity_id?: string | null;
    }> | null;
    ORGANIZATION_COMPANY_MANAGER?: Array<{
      __typename?: "BasePermissionType";
      organization_user_id?: string | null;
      user_group_id?: string | null;
      entity_type?: AclEntityTypeEnum | null;
      entity_id?: string | null;
      acl_source?: AclSourceEnum | null;
      orig_entity_type?: AclEntityTypeEnum | null;
      orig_entity_id?: string | null;
    }> | null;
    BANK_ACCOUNT_CHECK_MANAGE?: Array<{
      __typename?: "BankAccountManageCheckACLPermission";
      organization_user_id?: string | null;
      user_group_id?: string | null;
      entity_type?: AclEntityTypeEnum | null;
      entity_id?: string | null;
      acl_source?: AclSourceEnum | null;
      orig_entity_type?: AclEntityTypeEnum | null;
      orig_entity_id?: string | null;
      limit_manage_vchecks_max_amount?: number | null;
    }> | null;
    BANK_ACCOUNT_CHECK_SIGNER?: Array<{
      __typename?: "BankAccountCheckSignerACLPermission";
      organization_user_id?: string | null;
      user_group_id?: string | null;
      entity_type?: AclEntityTypeEnum | null;
      entity_id?: string | null;
      acl_source?: AclSourceEnum | null;
      orig_entity_type?: AclEntityTypeEnum | null;
      orig_entity_id?: string | null;
      limit_approve_vchecks_max_amount?: number | null;
    }> | null;
    COMPANY_VIEW?: Array<{
      __typename?: "BasePermissionType";
      organization_user_id?: string | null;
      user_group_id?: string | null;
      entity_type?: AclEntityTypeEnum | null;
      entity_id?: string | null;
      acl_source?: AclSourceEnum | null;
      orig_entity_type?: AclEntityTypeEnum | null;
      orig_entity_id?: string | null;
    }> | null;
    BANK_ACCOUNT_VIEW?: Array<{
      __typename?: "BasePermissionType";
      organization_user_id?: string | null;
      user_group_id?: string | null;
      entity_type?: AclEntityTypeEnum | null;
      entity_id?: string | null;
      acl_source?: AclSourceEnum | null;
      orig_entity_type?: AclEntityTypeEnum | null;
      orig_entity_id?: string | null;
    }> | null;
    BANK_ACCOUNT_MANAGE?: Array<{
      __typename?: "BasePermissionType";
      organization_user_id?: string | null;
      user_group_id?: string | null;
      entity_type?: AclEntityTypeEnum | null;
      entity_id?: string | null;
      acl_source?: AclSourceEnum | null;
      orig_entity_type?: AclEntityTypeEnum | null;
      orig_entity_id?: string | null;
    }> | null;
    COMPANY_VBILL_EDIT_MAP?: Array<{
      __typename?: "BasePermissionType";
      organization_user_id?: string | null;
      user_group_id?: string | null;
      entity_type?: AclEntityTypeEnum | null;
      entity_id?: string | null;
      acl_source?: AclSourceEnum | null;
      orig_entity_type?: AclEntityTypeEnum | null;
      orig_entity_id?: string | null;
    }> | null;
    COMPANY_VBILL_ACCESS?: Array<{
      __typename?: "BasePermissionType";
      organization_user_id?: string | null;
      user_group_id?: string | null;
      entity_type?: AclEntityTypeEnum | null;
      entity_id?: string | null;
      acl_source?: AclSourceEnum | null;
      orig_entity_type?: AclEntityTypeEnum | null;
      orig_entity_id?: string | null;
    }> | null;
    ORGANIZATION_VBILL_COMPANY_MAPPER?: Array<{
      __typename?: "BasePermissionType";
      organization_user_id?: string | null;
      user_group_id?: string | null;
      entity_type?: AclEntityTypeEnum | null;
      entity_id?: string | null;
      acl_source?: AclSourceEnum | null;
      orig_entity_type?: AclEntityTypeEnum | null;
      orig_entity_id?: string | null;
    }> | null;
    COMPANY_VBILL_PAYER?: Array<{
      __typename?: "BasePermissionType";
      organization_user_id?: string | null;
      user_group_id?: string | null;
      entity_type?: AclEntityTypeEnum | null;
      entity_id?: string | null;
      acl_source?: AclSourceEnum | null;
      orig_entity_type?: AclEntityTypeEnum | null;
      orig_entity_id?: string | null;
    }> | null;
  } | null;
};

export type AclOrganizationDirectLimitsQueryVariables = Exact<{
  organization_id: Scalars["String"]["input"];
}>;

export type AclOrganizationDirectLimitsQuery = {
  __typename?: "Query";
  ACLPermissionAggregatedLimits?: {
    __typename?: "AppliedPermissionGroup";
    BANK_ACCOUNT_LEDGER_ACCESS?: Array<{
      __typename?: "BankAccountLedgerAccessACLPermission";
      organization_user_id?: string | null;
      user_group_id?: string | null;
      entity_type?: AclEntityTypeEnum | null;
      entity_id?: string | null;
      acl_source?: AclSourceEnum | null;
      orig_entity_type?: AclEntityTypeEnum | null;
      orig_entity_id?: string | null;
      limit_ledger_access_max_amount?: number | null;
    }> | null;
    BANK_ACCOUNT_CHECK_CREATE?: Array<{
      __typename?: "BankAccountCheckCreateACLPermission";
      organization_user_id?: string | null;
      user_group_id?: string | null;
      entity_type?: AclEntityTypeEnum | null;
      entity_id?: string | null;
      acl_source?: AclSourceEnum | null;
      orig_entity_type?: AclEntityTypeEnum | null;
      orig_entity_id?: string | null;
      limit_create_vchecks_rules?: Array<{
        __typename?: "LimitCreateVChecksRule";
        count?: number | null;
        above?: number | null;
      }> | null;
    }> | null;
    ORGANIZATION_USER_MANAGER?: Array<{
      __typename?: "BasePermissionType";
      organization_user_id?: string | null;
      user_group_id?: string | null;
      entity_type?: AclEntityTypeEnum | null;
      entity_id?: string | null;
      acl_source?: AclSourceEnum | null;
      orig_entity_type?: AclEntityTypeEnum | null;
      orig_entity_id?: string | null;
    }> | null;
    COMPANY_INTEGRATION_MANAGER?: Array<{
      __typename?: "BasePermissionType";
      organization_user_id?: string | null;
      user_group_id?: string | null;
      entity_type?: AclEntityTypeEnum | null;
      entity_id?: string | null;
      acl_source?: AclSourceEnum | null;
      orig_entity_type?: AclEntityTypeEnum | null;
      orig_entity_id?: string | null;
    }> | null;
    ORGANIZATION_COMPANY_MANAGER?: Array<{
      __typename?: "BasePermissionType";
      organization_user_id?: string | null;
      user_group_id?: string | null;
      entity_type?: AclEntityTypeEnum | null;
      entity_id?: string | null;
      acl_source?: AclSourceEnum | null;
      orig_entity_type?: AclEntityTypeEnum | null;
      orig_entity_id?: string | null;
    }> | null;
    BANK_ACCOUNT_CHECK_MANAGE?: Array<{
      __typename?: "BankAccountManageCheckACLPermission";
      organization_user_id?: string | null;
      user_group_id?: string | null;
      entity_type?: AclEntityTypeEnum | null;
      entity_id?: string | null;
      acl_source?: AclSourceEnum | null;
      orig_entity_type?: AclEntityTypeEnum | null;
      orig_entity_id?: string | null;
      limit_manage_vchecks_max_amount?: number | null;
    }> | null;
    BANK_ACCOUNT_CHECK_SIGNER?: Array<{
      __typename?: "BankAccountCheckSignerACLPermission";
      organization_user_id?: string | null;
      user_group_id?: string | null;
      entity_type?: AclEntityTypeEnum | null;
      entity_id?: string | null;
      acl_source?: AclSourceEnum | null;
      orig_entity_type?: AclEntityTypeEnum | null;
      orig_entity_id?: string | null;
      limit_approve_vchecks_max_amount?: number | null;
    }> | null;
    COMPANY_VIEW?: Array<{
      __typename?: "BasePermissionType";
      organization_user_id?: string | null;
      user_group_id?: string | null;
      entity_type?: AclEntityTypeEnum | null;
      entity_id?: string | null;
      acl_source?: AclSourceEnum | null;
      orig_entity_type?: AclEntityTypeEnum | null;
      orig_entity_id?: string | null;
    }> | null;
    BANK_ACCOUNT_VIEW?: Array<{
      __typename?: "BasePermissionType";
      organization_user_id?: string | null;
      user_group_id?: string | null;
      entity_type?: AclEntityTypeEnum | null;
      entity_id?: string | null;
      acl_source?: AclSourceEnum | null;
      orig_entity_type?: AclEntityTypeEnum | null;
      orig_entity_id?: string | null;
    }> | null;
    BANK_ACCOUNT_MANAGE?: Array<{
      __typename?: "BasePermissionType";
      organization_user_id?: string | null;
      user_group_id?: string | null;
      entity_type?: AclEntityTypeEnum | null;
      entity_id?: string | null;
      acl_source?: AclSourceEnum | null;
      orig_entity_type?: AclEntityTypeEnum | null;
      orig_entity_id?: string | null;
    }> | null;
    COMPANY_VBILL_EDIT_MAP?: Array<{
      __typename?: "BasePermissionType";
      organization_user_id?: string | null;
      user_group_id?: string | null;
      entity_type?: AclEntityTypeEnum | null;
      entity_id?: string | null;
      acl_source?: AclSourceEnum | null;
      orig_entity_type?: AclEntityTypeEnum | null;
      orig_entity_id?: string | null;
    }> | null;
    COMPANY_VBILL_ACCESS?: Array<{
      __typename?: "BasePermissionType";
      organization_user_id?: string | null;
      user_group_id?: string | null;
      entity_type?: AclEntityTypeEnum | null;
      entity_id?: string | null;
      acl_source?: AclSourceEnum | null;
      orig_entity_type?: AclEntityTypeEnum | null;
      orig_entity_id?: string | null;
    }> | null;
    ORGANIZATION_VBILL_COMPANY_MAPPER?: Array<{
      __typename?: "BasePermissionType";
      organization_user_id?: string | null;
      user_group_id?: string | null;
      entity_type?: AclEntityTypeEnum | null;
      entity_id?: string | null;
      acl_source?: AclSourceEnum | null;
      orig_entity_type?: AclEntityTypeEnum | null;
      orig_entity_id?: string | null;
    }> | null;
    COMPANY_VBILL_PAYER?: Array<{
      __typename?: "BasePermissionType";
      organization_user_id?: string | null;
      user_group_id?: string | null;
      entity_type?: AclEntityTypeEnum | null;
      entity_id?: string | null;
      acl_source?: AclSourceEnum | null;
      orig_entity_type?: AclEntityTypeEnum | null;
      orig_entity_id?: string | null;
    }> | null;
  } | null;
};

export type AclOrganizationInheritedLimitsQueryVariables = Exact<{
  organization_id: Scalars["String"]["input"];
  organization_user_id?: InputMaybe<Scalars["String"]["input"]>;
  user_group_id?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type AclOrganizationInheritedLimitsQuery = {
  __typename?: "Query";
  ACLPermissionAggregatedLimits?: {
    __typename?: "AppliedPermissionGroup";
    BANK_ACCOUNT_LEDGER_ACCESS?: Array<{
      __typename?: "BankAccountLedgerAccessACLPermission";
      organization_user_id?: string | null;
      user_group_id?: string | null;
      entity_type?: AclEntityTypeEnum | null;
      entity_id?: string | null;
      acl_source?: AclSourceEnum | null;
      orig_entity_type?: AclEntityTypeEnum | null;
      orig_entity_id?: string | null;
      limit_ledger_access_max_amount?: number | null;
    }> | null;
    BANK_ACCOUNT_CHECK_CREATE?: Array<{
      __typename?: "BankAccountCheckCreateACLPermission";
      organization_user_id?: string | null;
      user_group_id?: string | null;
      entity_type?: AclEntityTypeEnum | null;
      entity_id?: string | null;
      acl_source?: AclSourceEnum | null;
      orig_entity_type?: AclEntityTypeEnum | null;
      orig_entity_id?: string | null;
      limit_create_vchecks_rules?: Array<{
        __typename?: "LimitCreateVChecksRule";
        count?: number | null;
        above?: number | null;
      }> | null;
    }> | null;
    ORGANIZATION_USER_MANAGER?: Array<{
      __typename?: "BasePermissionType";
      organization_user_id?: string | null;
      user_group_id?: string | null;
      entity_type?: AclEntityTypeEnum | null;
      entity_id?: string | null;
      acl_source?: AclSourceEnum | null;
      orig_entity_type?: AclEntityTypeEnum | null;
      orig_entity_id?: string | null;
    }> | null;
    COMPANY_INTEGRATION_MANAGER?: Array<{
      __typename?: "BasePermissionType";
      organization_user_id?: string | null;
      user_group_id?: string | null;
      entity_type?: AclEntityTypeEnum | null;
      entity_id?: string | null;
      acl_source?: AclSourceEnum | null;
      orig_entity_type?: AclEntityTypeEnum | null;
      orig_entity_id?: string | null;
    }> | null;
    ORGANIZATION_COMPANY_MANAGER?: Array<{
      __typename?: "BasePermissionType";
      organization_user_id?: string | null;
      user_group_id?: string | null;
      entity_type?: AclEntityTypeEnum | null;
      entity_id?: string | null;
      acl_source?: AclSourceEnum | null;
      orig_entity_type?: AclEntityTypeEnum | null;
      orig_entity_id?: string | null;
    }> | null;
    BANK_ACCOUNT_CHECK_MANAGE?: Array<{
      __typename?: "BankAccountManageCheckACLPermission";
      organization_user_id?: string | null;
      user_group_id?: string | null;
      entity_type?: AclEntityTypeEnum | null;
      entity_id?: string | null;
      acl_source?: AclSourceEnum | null;
      orig_entity_type?: AclEntityTypeEnum | null;
      orig_entity_id?: string | null;
      limit_manage_vchecks_max_amount?: number | null;
    }> | null;
    BANK_ACCOUNT_CHECK_SIGNER?: Array<{
      __typename?: "BankAccountCheckSignerACLPermission";
      organization_user_id?: string | null;
      user_group_id?: string | null;
      entity_type?: AclEntityTypeEnum | null;
      entity_id?: string | null;
      acl_source?: AclSourceEnum | null;
      orig_entity_type?: AclEntityTypeEnum | null;
      orig_entity_id?: string | null;
      limit_approve_vchecks_max_amount?: number | null;
    }> | null;
    COMPANY_VIEW?: Array<{
      __typename?: "BasePermissionType";
      organization_user_id?: string | null;
      user_group_id?: string | null;
      entity_type?: AclEntityTypeEnum | null;
      entity_id?: string | null;
      acl_source?: AclSourceEnum | null;
      orig_entity_type?: AclEntityTypeEnum | null;
      orig_entity_id?: string | null;
    }> | null;
    BANK_ACCOUNT_VIEW?: Array<{
      __typename?: "BasePermissionType";
      organization_user_id?: string | null;
      user_group_id?: string | null;
      entity_type?: AclEntityTypeEnum | null;
      entity_id?: string | null;
      acl_source?: AclSourceEnum | null;
      orig_entity_type?: AclEntityTypeEnum | null;
      orig_entity_id?: string | null;
    }> | null;
    BANK_ACCOUNT_MANAGE?: Array<{
      __typename?: "BasePermissionType";
      organization_user_id?: string | null;
      user_group_id?: string | null;
      entity_type?: AclEntityTypeEnum | null;
      entity_id?: string | null;
      acl_source?: AclSourceEnum | null;
      orig_entity_type?: AclEntityTypeEnum | null;
      orig_entity_id?: string | null;
    }> | null;
    COMPANY_VBILL_EDIT_MAP?: Array<{
      __typename?: "BasePermissionType";
      organization_user_id?: string | null;
      user_group_id?: string | null;
      entity_type?: AclEntityTypeEnum | null;
      entity_id?: string | null;
      acl_source?: AclSourceEnum | null;
      orig_entity_type?: AclEntityTypeEnum | null;
      orig_entity_id?: string | null;
    }> | null;
    COMPANY_VBILL_ACCESS?: Array<{
      __typename?: "BasePermissionType";
      organization_user_id?: string | null;
      user_group_id?: string | null;
      entity_type?: AclEntityTypeEnum | null;
      entity_id?: string | null;
      acl_source?: AclSourceEnum | null;
      orig_entity_type?: AclEntityTypeEnum | null;
      orig_entity_id?: string | null;
    }> | null;
    ORGANIZATION_VBILL_COMPANY_MAPPER?: Array<{
      __typename?: "BasePermissionType";
      organization_user_id?: string | null;
      user_group_id?: string | null;
      entity_type?: AclEntityTypeEnum | null;
      entity_id?: string | null;
      acl_source?: AclSourceEnum | null;
      orig_entity_type?: AclEntityTypeEnum | null;
      orig_entity_id?: string | null;
    }> | null;
    COMPANY_VBILL_PAYER?: Array<{
      __typename?: "BasePermissionType";
      organization_user_id?: string | null;
      user_group_id?: string | null;
      entity_type?: AclEntityTypeEnum | null;
      entity_id?: string | null;
      acl_source?: AclSourceEnum | null;
      orig_entity_type?: AclEntityTypeEnum | null;
      orig_entity_id?: string | null;
    }> | null;
  } | null;
};

export type AclPermissionAggregatedLimitsQueryVariables = Exact<{
  organization_user_id: Scalars["String"]["input"];
}>;

export type AclPermissionAggregatedLimitsQuery = {
  __typename?: "Query";
  ACLPermissionAggregatedLimits?: {
    __typename?: "AppliedPermissionGroup";
    BANK_ACCOUNT_LEDGER_ACCESS?: Array<{
      __typename?: "BankAccountLedgerAccessACLPermission";
      organization_user_id?: string | null;
      user_group_id?: string | null;
      entity_type?: AclEntityTypeEnum | null;
      entity_id?: string | null;
      acl_source?: AclSourceEnum | null;
      orig_entity_type?: AclEntityTypeEnum | null;
      orig_entity_id?: string | null;
      limit_ledger_access_max_amount?: number | null;
    }> | null;
    BANK_ACCOUNT_CHECK_CREATE?: Array<{
      __typename?: "BankAccountCheckCreateACLPermission";
      organization_user_id?: string | null;
      user_group_id?: string | null;
      entity_type?: AclEntityTypeEnum | null;
      entity_id?: string | null;
      acl_source?: AclSourceEnum | null;
      orig_entity_type?: AclEntityTypeEnum | null;
      orig_entity_id?: string | null;
      limit_create_vchecks_rules?: Array<{
        __typename?: "LimitCreateVChecksRule";
        count?: number | null;
        above?: number | null;
      }> | null;
    }> | null;
    ORGANIZATION_USER_MANAGER?: Array<{
      __typename?: "BasePermissionType";
      organization_user_id?: string | null;
      user_group_id?: string | null;
      entity_type?: AclEntityTypeEnum | null;
      entity_id?: string | null;
      acl_source?: AclSourceEnum | null;
      orig_entity_type?: AclEntityTypeEnum | null;
      orig_entity_id?: string | null;
    }> | null;
    COMPANY_INTEGRATION_MANAGER?: Array<{
      __typename?: "BasePermissionType";
      organization_user_id?: string | null;
      user_group_id?: string | null;
      entity_type?: AclEntityTypeEnum | null;
      entity_id?: string | null;
      acl_source?: AclSourceEnum | null;
      orig_entity_type?: AclEntityTypeEnum | null;
      orig_entity_id?: string | null;
    }> | null;
    ORGANIZATION_COMPANY_MANAGER?: Array<{
      __typename?: "BasePermissionType";
      organization_user_id?: string | null;
      user_group_id?: string | null;
      entity_type?: AclEntityTypeEnum | null;
      entity_id?: string | null;
      acl_source?: AclSourceEnum | null;
      orig_entity_type?: AclEntityTypeEnum | null;
      orig_entity_id?: string | null;
    }> | null;
    BANK_ACCOUNT_CHECK_MANAGE?: Array<{
      __typename?: "BankAccountManageCheckACLPermission";
      organization_user_id?: string | null;
      user_group_id?: string | null;
      entity_type?: AclEntityTypeEnum | null;
      entity_id?: string | null;
      acl_source?: AclSourceEnum | null;
      orig_entity_type?: AclEntityTypeEnum | null;
      orig_entity_id?: string | null;
      limit_manage_vchecks_max_amount?: number | null;
    }> | null;
    BANK_ACCOUNT_CHECK_SIGNER?: Array<{
      __typename?: "BankAccountCheckSignerACLPermission";
      organization_user_id?: string | null;
      user_group_id?: string | null;
      entity_type?: AclEntityTypeEnum | null;
      entity_id?: string | null;
      acl_source?: AclSourceEnum | null;
      orig_entity_type?: AclEntityTypeEnum | null;
      orig_entity_id?: string | null;
      limit_approve_vchecks_max_amount?: number | null;
    }> | null;
    COMPANY_VIEW?: Array<{
      __typename?: "BasePermissionType";
      organization_user_id?: string | null;
      user_group_id?: string | null;
      entity_type?: AclEntityTypeEnum | null;
      entity_id?: string | null;
      acl_source?: AclSourceEnum | null;
      orig_entity_type?: AclEntityTypeEnum | null;
      orig_entity_id?: string | null;
    }> | null;
    BANK_ACCOUNT_VIEW?: Array<{
      __typename?: "BasePermissionType";
      organization_user_id?: string | null;
      user_group_id?: string | null;
      entity_type?: AclEntityTypeEnum | null;
      entity_id?: string | null;
      acl_source?: AclSourceEnum | null;
      orig_entity_type?: AclEntityTypeEnum | null;
      orig_entity_id?: string | null;
    }> | null;
    BANK_ACCOUNT_MANAGE?: Array<{
      __typename?: "BasePermissionType";
      organization_user_id?: string | null;
      user_group_id?: string | null;
      entity_type?: AclEntityTypeEnum | null;
      entity_id?: string | null;
      acl_source?: AclSourceEnum | null;
      orig_entity_type?: AclEntityTypeEnum | null;
      orig_entity_id?: string | null;
    }> | null;
    COMPANY_VBILL_EDIT_MAP?: Array<{
      __typename?: "BasePermissionType";
      organization_user_id?: string | null;
      user_group_id?: string | null;
      entity_type?: AclEntityTypeEnum | null;
      entity_id?: string | null;
      acl_source?: AclSourceEnum | null;
      orig_entity_type?: AclEntityTypeEnum | null;
      orig_entity_id?: string | null;
    }> | null;
    COMPANY_VBILL_ACCESS?: Array<{
      __typename?: "BasePermissionType";
      organization_user_id?: string | null;
      user_group_id?: string | null;
      entity_type?: AclEntityTypeEnum | null;
      entity_id?: string | null;
      acl_source?: AclSourceEnum | null;
      orig_entity_type?: AclEntityTypeEnum | null;
      orig_entity_id?: string | null;
    }> | null;
    ORGANIZATION_VBILL_COMPANY_MAPPER?: Array<{
      __typename?: "BasePermissionType";
      organization_user_id?: string | null;
      user_group_id?: string | null;
      entity_type?: AclEntityTypeEnum | null;
      entity_id?: string | null;
      acl_source?: AclSourceEnum | null;
      orig_entity_type?: AclEntityTypeEnum | null;
      orig_entity_id?: string | null;
    }> | null;
    COMPANY_VBILL_PAYER?: Array<{
      __typename?: "BasePermissionType";
      organization_user_id?: string | null;
      user_group_id?: string | null;
      entity_type?: AclEntityTypeEnum | null;
      entity_id?: string | null;
      acl_source?: AclSourceEnum | null;
      orig_entity_type?: AclEntityTypeEnum | null;
      orig_entity_id?: string | null;
    }> | null;
  } | null;
};

export type AclPermissionsOfOrganizationUserQueryVariables = Exact<{
  organization_user_id: Scalars["String"]["input"];
}>;

export type AclPermissionsOfOrganizationUserQuery = {
  __typename?: "Query";
  ACLPermissions?: {
    __typename?: "AppliedPermissionGroup";
    BANK_ACCOUNT_LEDGER_ACCESS?: Array<{
      __typename?: "BankAccountLedgerAccessACLPermission";
      organization_user_id?: string | null;
      user_group_id?: string | null;
      entity_type?: AclEntityTypeEnum | null;
      entity_id?: string | null;
      acl_source?: AclSourceEnum | null;
      orig_entity_type?: AclEntityTypeEnum | null;
      orig_entity_id?: string | null;
      limit_ledger_access_max_amount?: number | null;
    }> | null;
    BANK_ACCOUNT_CHECK_CREATE?: Array<{
      __typename?: "BankAccountCheckCreateACLPermission";
      organization_user_id?: string | null;
      user_group_id?: string | null;
      entity_type?: AclEntityTypeEnum | null;
      entity_id?: string | null;
      acl_source?: AclSourceEnum | null;
      orig_entity_type?: AclEntityTypeEnum | null;
      orig_entity_id?: string | null;
      limit_create_vchecks_rules?: Array<{
        __typename?: "LimitCreateVChecksRule";
        count?: number | null;
        above?: number | null;
      }> | null;
    }> | null;
    ORGANIZATION_USER_MANAGER?: Array<{
      __typename?: "BasePermissionType";
      organization_user_id?: string | null;
      user_group_id?: string | null;
      entity_type?: AclEntityTypeEnum | null;
      entity_id?: string | null;
      acl_source?: AclSourceEnum | null;
      orig_entity_type?: AclEntityTypeEnum | null;
      orig_entity_id?: string | null;
    }> | null;
    COMPANY_INTEGRATION_MANAGER?: Array<{
      __typename?: "BasePermissionType";
      organization_user_id?: string | null;
      user_group_id?: string | null;
      entity_type?: AclEntityTypeEnum | null;
      entity_id?: string | null;
      acl_source?: AclSourceEnum | null;
      orig_entity_type?: AclEntityTypeEnum | null;
      orig_entity_id?: string | null;
    }> | null;
    ORGANIZATION_COMPANY_MANAGER?: Array<{
      __typename?: "BasePermissionType";
      organization_user_id?: string | null;
      user_group_id?: string | null;
      entity_type?: AclEntityTypeEnum | null;
      entity_id?: string | null;
      acl_source?: AclSourceEnum | null;
      orig_entity_type?: AclEntityTypeEnum | null;
      orig_entity_id?: string | null;
    }> | null;
    BANK_ACCOUNT_CHECK_MANAGE?: Array<{
      __typename?: "BankAccountManageCheckACLPermission";
      organization_user_id?: string | null;
      user_group_id?: string | null;
      entity_type?: AclEntityTypeEnum | null;
      entity_id?: string | null;
      acl_source?: AclSourceEnum | null;
      orig_entity_type?: AclEntityTypeEnum | null;
      orig_entity_id?: string | null;
      limit_manage_vchecks_max_amount?: number | null;
    }> | null;
    BANK_ACCOUNT_CHECK_SIGNER?: Array<{
      __typename?: "BankAccountCheckSignerACLPermission";
      organization_user_id?: string | null;
      user_group_id?: string | null;
      entity_type?: AclEntityTypeEnum | null;
      entity_id?: string | null;
      acl_source?: AclSourceEnum | null;
      orig_entity_type?: AclEntityTypeEnum | null;
      orig_entity_id?: string | null;
      limit_approve_vchecks_max_amount?: number | null;
    }> | null;
    COMPANY_VIEW?: Array<{
      __typename?: "BasePermissionType";
      organization_user_id?: string | null;
      user_group_id?: string | null;
      entity_type?: AclEntityTypeEnum | null;
      entity_id?: string | null;
      acl_source?: AclSourceEnum | null;
      orig_entity_type?: AclEntityTypeEnum | null;
      orig_entity_id?: string | null;
    }> | null;
    BANK_ACCOUNT_VIEW?: Array<{
      __typename?: "BasePermissionType";
      organization_user_id?: string | null;
      user_group_id?: string | null;
      entity_type?: AclEntityTypeEnum | null;
      entity_id?: string | null;
      acl_source?: AclSourceEnum | null;
      orig_entity_type?: AclEntityTypeEnum | null;
      orig_entity_id?: string | null;
    }> | null;
    BANK_ACCOUNT_MANAGE?: Array<{
      __typename?: "BasePermissionType";
      organization_user_id?: string | null;
      user_group_id?: string | null;
      entity_type?: AclEntityTypeEnum | null;
      entity_id?: string | null;
      acl_source?: AclSourceEnum | null;
      orig_entity_type?: AclEntityTypeEnum | null;
      orig_entity_id?: string | null;
    }> | null;
    COMPANY_VBILL_EDIT_MAP?: Array<{
      __typename?: "BasePermissionType";
      organization_user_id?: string | null;
      user_group_id?: string | null;
      entity_type?: AclEntityTypeEnum | null;
      entity_id?: string | null;
      acl_source?: AclSourceEnum | null;
      orig_entity_type?: AclEntityTypeEnum | null;
      orig_entity_id?: string | null;
    }> | null;
    COMPANY_VBILL_ACCESS?: Array<{
      __typename?: "BasePermissionType";
      organization_user_id?: string | null;
      user_group_id?: string | null;
      entity_type?: AclEntityTypeEnum | null;
      entity_id?: string | null;
      acl_source?: AclSourceEnum | null;
      orig_entity_type?: AclEntityTypeEnum | null;
      orig_entity_id?: string | null;
    }> | null;
    ORGANIZATION_VBILL_COMPANY_MAPPER?: Array<{
      __typename?: "BasePermissionType";
      organization_user_id?: string | null;
      user_group_id?: string | null;
      entity_type?: AclEntityTypeEnum | null;
      entity_id?: string | null;
      acl_source?: AclSourceEnum | null;
      orig_entity_type?: AclEntityTypeEnum | null;
      orig_entity_id?: string | null;
    }> | null;
    COMPANY_VBILL_PAYER?: Array<{
      __typename?: "BasePermissionType";
      organization_user_id?: string | null;
      user_group_id?: string | null;
      entity_type?: AclEntityTypeEnum | null;
      entity_id?: string | null;
      acl_source?: AclSourceEnum | null;
      orig_entity_type?: AclEntityTypeEnum | null;
      orig_entity_id?: string | null;
    }> | null;
  } | null;
};

export type AclPermissionsOfUserGroupQueryVariables = Exact<{
  user_group_id: Scalars["String"]["input"];
}>;

export type AclPermissionsOfUserGroupQuery = {
  __typename?: "Query";
  ACLPermissions?: {
    __typename?: "AppliedPermissionGroup";
    BANK_ACCOUNT_LEDGER_ACCESS?: Array<{
      __typename?: "BankAccountLedgerAccessACLPermission";
      organization_user_id?: string | null;
      user_group_id?: string | null;
      entity_type?: AclEntityTypeEnum | null;
      entity_id?: string | null;
      acl_source?: AclSourceEnum | null;
      orig_entity_type?: AclEntityTypeEnum | null;
      orig_entity_id?: string | null;
      limit_ledger_access_max_amount?: number | null;
    }> | null;
    BANK_ACCOUNT_CHECK_CREATE?: Array<{
      __typename?: "BankAccountCheckCreateACLPermission";
      organization_user_id?: string | null;
      user_group_id?: string | null;
      entity_type?: AclEntityTypeEnum | null;
      entity_id?: string | null;
      acl_source?: AclSourceEnum | null;
      orig_entity_type?: AclEntityTypeEnum | null;
      orig_entity_id?: string | null;
      limit_create_vchecks_rules?: Array<{
        __typename?: "LimitCreateVChecksRule";
        count?: number | null;
        above?: number | null;
      }> | null;
    }> | null;
    ORGANIZATION_USER_MANAGER?: Array<{
      __typename?: "BasePermissionType";
      organization_user_id?: string | null;
      user_group_id?: string | null;
      entity_type?: AclEntityTypeEnum | null;
      entity_id?: string | null;
      acl_source?: AclSourceEnum | null;
      orig_entity_type?: AclEntityTypeEnum | null;
      orig_entity_id?: string | null;
    }> | null;
    COMPANY_INTEGRATION_MANAGER?: Array<{
      __typename?: "BasePermissionType";
      organization_user_id?: string | null;
      user_group_id?: string | null;
      entity_type?: AclEntityTypeEnum | null;
      entity_id?: string | null;
      acl_source?: AclSourceEnum | null;
      orig_entity_type?: AclEntityTypeEnum | null;
      orig_entity_id?: string | null;
    }> | null;
    ORGANIZATION_COMPANY_MANAGER?: Array<{
      __typename?: "BasePermissionType";
      organization_user_id?: string | null;
      user_group_id?: string | null;
      entity_type?: AclEntityTypeEnum | null;
      entity_id?: string | null;
      acl_source?: AclSourceEnum | null;
      orig_entity_type?: AclEntityTypeEnum | null;
      orig_entity_id?: string | null;
    }> | null;
    BANK_ACCOUNT_CHECK_MANAGE?: Array<{
      __typename?: "BankAccountManageCheckACLPermission";
      organization_user_id?: string | null;
      user_group_id?: string | null;
      entity_type?: AclEntityTypeEnum | null;
      entity_id?: string | null;
      acl_source?: AclSourceEnum | null;
      orig_entity_type?: AclEntityTypeEnum | null;
      orig_entity_id?: string | null;
      limit_manage_vchecks_max_amount?: number | null;
    }> | null;
    BANK_ACCOUNT_CHECK_SIGNER?: Array<{
      __typename?: "BankAccountCheckSignerACLPermission";
      organization_user_id?: string | null;
      user_group_id?: string | null;
      entity_type?: AclEntityTypeEnum | null;
      entity_id?: string | null;
      acl_source?: AclSourceEnum | null;
      orig_entity_type?: AclEntityTypeEnum | null;
      orig_entity_id?: string | null;
      limit_approve_vchecks_max_amount?: number | null;
    }> | null;
    COMPANY_VIEW?: Array<{
      __typename?: "BasePermissionType";
      organization_user_id?: string | null;
      user_group_id?: string | null;
      entity_type?: AclEntityTypeEnum | null;
      entity_id?: string | null;
      acl_source?: AclSourceEnum | null;
      orig_entity_type?: AclEntityTypeEnum | null;
      orig_entity_id?: string | null;
    }> | null;
    BANK_ACCOUNT_VIEW?: Array<{
      __typename?: "BasePermissionType";
      organization_user_id?: string | null;
      user_group_id?: string | null;
      entity_type?: AclEntityTypeEnum | null;
      entity_id?: string | null;
      acl_source?: AclSourceEnum | null;
      orig_entity_type?: AclEntityTypeEnum | null;
      orig_entity_id?: string | null;
    }> | null;
    BANK_ACCOUNT_MANAGE?: Array<{
      __typename?: "BasePermissionType";
      organization_user_id?: string | null;
      user_group_id?: string | null;
      entity_type?: AclEntityTypeEnum | null;
      entity_id?: string | null;
      acl_source?: AclSourceEnum | null;
      orig_entity_type?: AclEntityTypeEnum | null;
      orig_entity_id?: string | null;
    }> | null;
    COMPANY_VBILL_EDIT_MAP?: Array<{
      __typename?: "BasePermissionType";
      organization_user_id?: string | null;
      user_group_id?: string | null;
      entity_type?: AclEntityTypeEnum | null;
      entity_id?: string | null;
      acl_source?: AclSourceEnum | null;
      orig_entity_type?: AclEntityTypeEnum | null;
      orig_entity_id?: string | null;
    }> | null;
    COMPANY_VBILL_ACCESS?: Array<{
      __typename?: "BasePermissionType";
      organization_user_id?: string | null;
      user_group_id?: string | null;
      entity_type?: AclEntityTypeEnum | null;
      entity_id?: string | null;
      acl_source?: AclSourceEnum | null;
      orig_entity_type?: AclEntityTypeEnum | null;
      orig_entity_id?: string | null;
    }> | null;
    ORGANIZATION_VBILL_COMPANY_MAPPER?: Array<{
      __typename?: "BasePermissionType";
      organization_user_id?: string | null;
      user_group_id?: string | null;
      entity_type?: AclEntityTypeEnum | null;
      entity_id?: string | null;
      acl_source?: AclSourceEnum | null;
      orig_entity_type?: AclEntityTypeEnum | null;
      orig_entity_id?: string | null;
    }> | null;
    COMPANY_VBILL_PAYER?: Array<{
      __typename?: "BasePermissionType";
      organization_user_id?: string | null;
      user_group_id?: string | null;
      entity_type?: AclEntityTypeEnum | null;
      entity_id?: string | null;
      acl_source?: AclSourceEnum | null;
      orig_entity_type?: AclEntityTypeEnum | null;
      orig_entity_id?: string | null;
    }> | null;
  } | null;
};

export type ApplyAclMutationVariables = Exact<{
  acls?: InputMaybe<PermissionGroupInput>;
}>;

export type ApplyAclMutation = {
  __typename?: "Mutation";
  ApplyACL?: { __typename?: "ApplyACLResponse"; success?: boolean | null; error?: string | null } | null;
};

export type CheckActionMutationVariables = Exact<{
  organization_id?: InputMaybe<Scalars["String"]["input"]>;
  checks: Array<Scalars["String"]["input"]> | Scalars["String"]["input"];
  action: Scalars["String"]["input"];
}>;

export type CheckActionMutation = {
  __typename?: "Mutation";
  CheckActionMutation?: {
    __typename?: "CheckActionMutationResult";
    successes?: number | null;
    errors: Array<{ __typename?: "CheckActionErrors"; check_id?: string | null; message?: string | null }>;
  } | null;
};

export type CreateInviteMutationVariables = Exact<{
  email: Scalars["String"]["input"];
  organization_id: Scalars["String"]["input"];
}>;

export type CreateInviteMutation = {
  __typename?: "Mutation";
  CreateInvite?: { __typename?: "Invite"; id?: string | null; organization_user_id?: string | null } | null;
};

export type OrganizationCompaniesWithBankaccountsQueryVariables = Exact<{
  organization_id: Scalars["String"]["input"];
}>;

export type OrganizationCompaniesWithBankaccountsQuery = {
  __typename?: "Query";
  OrganizationDashboard: {
    __typename?: "OrganizationDashboard";
    companies?: Array<{
      __typename?: "CompanyWithBankAccountsAndAggregatedData";
      organization_id?: string | null;
      id: string;
      name: string;
      bank_accounts?: Array<{
        __typename?: "BankAccountWithAggregatedData";
        id: string;
        name: string;
        account_number?: string | null;
        bank_name: string;
      }> | null;
    }> | null;
  };
};

export type OrganizationUserGroupsQueryVariables = Exact<{
  organization_id: Scalars["String"]["input"];
  organization_user_id: Scalars["String"]["input"];
}>;

export type OrganizationUserGroupsQuery = {
  __typename?: "Query";
  OrganizationUsers: Array<{
    __typename?: "OrganizationUser";
    id: string;
    user_groups?: Array<{ __typename?: "UserGroup"; id?: string | null; name?: string | null }> | null;
  }>;
};

export type PermissionsGroupsQueryVariables = Exact<{ [key: string]: never }>;

export type PermissionsGroupsQuery = {
  __typename?: "Query";
  PermissionsGroups: Array<{
    __typename?: "PermissionGroup";
    id: string;
    group_name: string;
    internal_alias?: string | null;
    category?: string | null;
    permission_types?: Array<{
      __typename?: "PermissionType";
      id: string;
      permission: AclPermissionTypeEnum;
      display_name: string;
      description?: string | null;
    }> | null;
  }>;
};

export type UnmapBankAccountGlAccountMappingMutationVariables = Exact<{
  bank_account_id: Scalars["String"]["input"];
}>;

export type UnmapBankAccountGlAccountMappingMutation = {
  __typename?: "Mutation";
  UnmapBankAccountGlAccountMapping?: boolean | null;
};

export type UserGroupUsersQueryVariables = Exact<{
  organization_id: Scalars["String"]["input"];
  user_group_id: Scalars["String"]["input"];
}>;

export type UserGroupUsersQuery = {
  __typename?: "Query";
  UserGroups: Array<{
    __typename?: "UserGroup";
    id?: string | null;
    name?: string | null;
    organization_users?: Array<{
      __typename?: "OrganizationUser";
      id: string;
      account?: {
        __typename?: "BasicAccount";
        id: string;
        name?: string | null;
        email?: string | null;
        state?: AccountStateEnum | null;
      } | null;
    }> | null;
  }>;
};

export type CreateFeatureAccessRequestMutationMutationVariables = Exact<{
  feature: FeatureEnumType;
  organization_id: Scalars["String"]["input"];
}>;

export type CreateFeatureAccessRequestMutationMutation = {
  __typename?: "Mutation";
  CreateFeatureAccessRequestMutation: boolean;
};

export type DelegationGroupResolutionQueryVariables = Exact<{
  organization_id: Scalars["String"]["input"];
  bill_id?: InputMaybe<Scalars["String"]["input"]>;
  check_id?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type DelegationGroupResolutionQuery = {
  __typename?: "Query";
  DelegationGroupResolution: Array<{
    __typename?: "DelegationGroupResolved";
    id: string;
    scope: DelegationGroupScopeEnum;
    type: DelegationGroupTypeEnum;
    requirement_type: DelegationGroupRequirementTypeEnum;
    organization_id: string;
    company_id?: string | null;
    vendor_id?: string | null;
    bill_id?: string | null;
    check_id?: string | null;
    is_root: boolean;
    approvals_required: number;
    reviews_required: number;
    parent_group_id?: string | null;
    overall_approvals_met: boolean;
    overall_reviews_met: boolean;
    delegator: {
      __typename?: "OrganizationUser";
      id: string;
      account?: { __typename?: "BasicAccount"; id: string; name?: string | null } | null;
    };
    delegations: Array<{
      __typename?: "Delegation";
      id: string;
      role: DelegationRoleEnum;
      delegate: {
        __typename?: "OrganizationUser";
        id: string;
        account?: { __typename?: "BasicAccount"; name?: string | null } | null;
      };
    }>;
    labeling_category?: { __typename?: "LabelingCategory"; id: string; name: string } | null;
  }>;
};

export type DelegationGroupsQueryVariables = Exact<{
  scope: DelegationGroupScopeEnum;
  organization_id: Scalars["String"]["input"];
  company_id?: InputMaybe<Scalars["String"]["input"]>;
  vendor_id?: InputMaybe<Scalars["String"]["input"]>;
  bill_id?: InputMaybe<Scalars["String"]["input"]>;
  check_id?: InputMaybe<Scalars["String"]["input"]>;
  delegator_user_id?: InputMaybe<Scalars["String"]["input"]>;
  match_any?: InputMaybe<Scalars["Boolean"]["input"]>;
  is_root?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type DelegationGroupsQuery = {
  __typename?: "Query";
  DelegationGroups: {
    __typename?: "DelegationGroupsResponse";
    is_root_delegator: boolean;
    delegation_groups: Array<{
      __typename?: "DelegationGroup";
      id: string;
      scope: DelegationGroupScopeEnum;
      type: DelegationGroupTypeEnum;
      name?: string | null;
      requirement_type: DelegationGroupRequirementTypeEnum;
      organization_id: string;
      company_id?: string | null;
      vendor_id?: string | null;
      bill_id?: string | null;
      check_id?: string | null;
      is_root: boolean;
      approvals_required: number;
      reviews_required: number;
      delegator: { __typename?: "OrganizationUser"; id: string };
      delegations: Array<{
        __typename?: "Delegation";
        id: string;
        role: DelegationRoleEnum;
        delegate: {
          __typename?: "OrganizationUser";
          id: string;
          account?: { __typename?: "BasicAccount"; name?: string | null } | null;
        };
      }>;
      labeling_category?: { __typename?: "LabelingCategory"; id: string; name: string } | null;
    }>;
  };
};

export type DeleteDelegationGroupMutationVariables = Exact<{
  scope: DelegationGroupScopeEnum;
  organization_id: Scalars["String"]["input"];
  delegation_group_id?: InputMaybe<Scalars["String"]["input"]>;
  company_id?: InputMaybe<Scalars["String"]["input"]>;
  vendor_id?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type DeleteDelegationGroupMutation = { __typename?: "Mutation"; DeleteDelegationGroup?: boolean | null };

export type LabelingCategoriesQueryVariables = Exact<{
  organization_id: Scalars["String"]["input"];
  company_id?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type LabelingCategoriesQuery = {
  __typename?: "Query";
  LabelingCategories: Array<{ __typename?: "LabelingCategory"; id: string; name: string }>;
};

export type SaveApprovalMutationVariables = Exact<{
  organization_id: Scalars["String"]["input"];
  bill_id?: InputMaybe<Scalars["String"]["input"]>;
  check_id?: InputMaybe<Scalars["String"]["input"]>;
  type: ApprovalTypeEnum;
  resolution: ApprovalResolutionEnum;
}>;

export type SaveApprovalMutation = { __typename?: "Mutation"; SaveApproval?: boolean | null };

export type SaveDelegationGroupMutationVariables = Exact<{
  delegation_group_id?: InputMaybe<Scalars["String"]["input"]>;
  scope: DelegationGroupScopeEnum;
  type: DelegationGroupTypeEnum;
  name?: InputMaybe<Scalars["String"]["input"]>;
  requirement_type: DelegationGroupRequirementTypeEnum;
  organization_id: Scalars["String"]["input"];
  company_id?: InputMaybe<Scalars["String"]["input"]>;
  vendor_id?: InputMaybe<Scalars["String"]["input"]>;
  bill_id?: InputMaybe<Scalars["String"]["input"]>;
  check_id?: InputMaybe<Scalars["String"]["input"]>;
  is_root: Scalars["Boolean"]["input"];
  root_delegator_id?: InputMaybe<Scalars["String"]["input"]>;
  approvals_required: Scalars["Int"]["input"];
  reviews_required: Scalars["Int"]["input"];
  delegations: Array<InputDelegation> | InputDelegation;
  labeling_category_id?: InputMaybe<Scalars["String"]["input"]>;
  entity_context?: InputMaybe<InputDelegationGroupEntityContext>;
}>;

export type SaveDelegationGroupMutation = { __typename?: "Mutation"; SaveDelegationGroup?: boolean | null };

export type SaveLabelingCategoryMutationVariables = Exact<{
  organization_id: Scalars["String"]["input"];
  company_id?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
}>;

export type SaveLabelingCategoryMutation = {
  __typename?: "Mutation";
  SaveLabelingCategory: { __typename?: "LabelingCategory"; id: string };
};

export type CreateAutoDepositRuleMutationMutationVariables = Exact<{
  bank_account_id: Scalars["String"]["input"];
  conditions: ConditionInputGraphQlType;
  rule_priority?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>> | InputMaybe<Scalars["String"]["input"]>>;
}>;

export type CreateAutoDepositRuleMutationMutation = {
  __typename?: "Mutation";
  CreateAutoDepositRuleMutation?: {
    __typename?: "AutoDepositRuleGraphQLType";
    id?: string | null;
    email_address: { __typename?: "EmailAddress"; id?: string | null; email?: string | null };
    bank_account: {
      __typename?: "BankAccountBasic";
      id: string;
      name: string;
      account_number: string;
      routing_number: string;
      bank_name: string;
    };
  } | null;
};

export type DeleteAutoDepositRuleMutationMutationVariables = Exact<{
  auto_deposit_rule_id: Scalars["String"]["input"];
}>;

export type DeleteAutoDepositRuleMutationMutation = {
  __typename?: "Mutation";
  DeleteAutoDepositRuleMutation?: boolean | null;
};

export type GetAutoDepositRulesForEmailAddressQueryQueryVariables = Exact<{
  email_address_id: Scalars["String"]["input"];
}>;

export type GetAutoDepositRulesForEmailAddressQueryQuery = {
  __typename?: "Query";
  GetAutoDepositRulesForEmailAddressQuery: {
    __typename?: "AutoDepositRulesForEmailAddressGraphQLType";
    rule_priority?: Array<string | null> | null;
    auto_deposit_rules?: Array<{
      __typename?: "AutoDepositRuleGraphQLType";
      id?: string | null;
      organization_name?: string | null;
      organization_id?: string | null;
      company_name?: string | null;
      company_id?: string | null;
      email_address: { __typename?: "EmailAddress"; id?: string | null; email?: string | null };
      bank_account: {
        __typename?: "BankAccountBasic";
        id: string;
        name: string;
        account_number: string;
        routing_number: string;
        bank_name: string;
      };
      conditions: {
        __typename?: "ConditionGraphQLType";
        pttoos?: Array<string | null> | null;
        senders?: Array<string | null> | null;
        memos?: Array<string | null> | null;
      };
    } | null> | null;
  };
};

export type UpdateAutoDepositRuleMutationMutationVariables = Exact<{
  auto_deposit_rule_id: Scalars["String"]["input"];
  bank_account_id: Scalars["String"]["input"];
  conditions: ConditionInputGraphQlType;
  rule_priority: Array<InputMaybe<Scalars["String"]["input"]>> | InputMaybe<Scalars["String"]["input"]>;
}>;

export type UpdateAutoDepositRuleMutationMutation = {
  __typename?: "Mutation";
  UpdateAutoDepositRuleMutation?: {
    __typename?: "AutoDepositRuleGraphQLType";
    id?: string | null;
    email_address: { __typename?: "EmailAddress"; id?: string | null; email?: string | null };
    bank_account: {
      __typename?: "BankAccountBasic";
      id: string;
      name: string;
      account_number: string;
      routing_number: string;
      bank_name: string;
    };
  } | null;
};

export type UpdateAutoDepositRulePriorityMutationMutationVariables = Exact<{
  email_address_id: Scalars["String"]["input"];
  rule_priority: Array<InputMaybe<Scalars["String"]["input"]>> | InputMaybe<Scalars["String"]["input"]>;
}>;

export type UpdateAutoDepositRulePriorityMutationMutation = {
  __typename?: "Mutation";
  UpdateAutoDepositRulePriorityMutation: {
    __typename?: "AutoDepositRulesForEmailAddressGraphQLType";
    rule_priority?: Array<string | null> | null;
  };
};

export type UpdateBankAccountMutationVariables = Exact<{
  bank_account_id: Scalars["String"]["input"];
  nickname?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type UpdateBankAccountMutation = {
  __typename?: "Mutation";
  UpdateBankAccount?: {
    __typename?: "BankAccountBasicWithNickname";
    id: string;
    name: string;
    nickname?: string | null;
    account_number: string;
    routing_number: string;
    bank_name: string;
  } | null;
};

export type UpdateUnconfirmedBankAccountMutationVariables = Exact<{
  bank_account_id: Scalars["String"]["input"];
  action: Scalars["String"]["input"];
}>;

export type UpdateUnconfirmedBankAccountMutation = {
  __typename?: "Mutation";
  UpdateUnconfirmedBankAccount?: boolean | null;
};

export type CreateCheckBatchItemsMutationMutationVariables = Exact<{
  check_batch_id: Scalars["String"]["input"];
  checks?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>> | InputMaybe<Scalars["String"]["input"]>>;
  filters?: InputMaybe<CheckFiltersType>;
}>;

export type CreateCheckBatchItemsMutationMutation = {
  __typename?: "Mutation";
  CreateCheckBatchItemsMutation?: {
    __typename?: "ChecksAddedType";
    checks_added?: Array<string | null> | null;
    invalid_checks?: Array<string | null> | null;
  } | null;
};

export type CreateCheckBatchMembersMutationMutationVariables = Exact<{
  check_batch_id: Scalars["String"]["input"];
  organization_user_ids?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>> | InputMaybe<Scalars["String"]["input"]>
  >;
}>;

export type CreateCheckBatchMembersMutationMutation = {
  __typename?: "Mutation";
  CreateCheckBatchMembersMutation?: {
    __typename?: "CreateCheckBatchMembersType";
    success_ids?: Array<string | null> | null;
  } | null;
};

export type CreateCheckBatchMutationMutationVariables = Exact<{
  company_id: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  budget?: InputMaybe<Scalars["Float"]["input"]>;
}>;

export type CreateCheckBatchMutationMutation = {
  __typename?: "Mutation";
  CreateCheckBatchMutation?: {
    __typename?: "CheckBatchType";
    id?: string | null;
    name?: string | null;
    budget?: number | null;
    state?: CheckBatchStateEnum | null;
    created_with?: CheckBatchCreatedWithEnum | null;
    items_amount?: number | null;
    items_count?: number | null;
    company?: {
      __typename?: "CompanyType";
      id?: string | null;
      legal_name?: string | null;
      dba?: string | null;
    } | null;
    created_by_organization_user?: {
      __typename?: "OrganizationUserType";
      id?: string | null;
      account?: { __typename?: "AccountType"; id: string; name?: string | null; email?: string | null } | null;
    } | null;
    items?: {
      __typename?: "CheckBatchItemsPaginatedType";
      rows?: Array<{
        __typename?: "CheckBatchItemType";
        id?: string | null;
        check?: {
          __typename?: "CheckType";
          id: string;
          number?: string | null;
          date?: string | null;
          amount?: number | null;
          memo?: string | null;
          pttoo?: string | null;
        } | null;
        reactions?: Array<{
          __typename?: "CheckBatchItemReactionType";
          id?: string | null;
          organization_user_id?: string | null;
          reaction: ReactionEnumType;
        } | null> | null;
      } | null> | null;
      pagination?: {
        __typename?: "PaginationResponseType";
        total?: number | null;
        per_page?: number | null;
        on_first_page?: boolean | null;
        current_page?: number | null;
        last_page?: number | null;
        has_pages?: boolean | null;
        has_more_pages?: boolean | null;
        first_item?: number | null;
        last_item?: number | null;
      } | null;
    } | null;
  } | null;
};

export type DeleteCheckBatchItemsMutationMutationVariables = Exact<{
  check_batch_id: Scalars["String"]["input"];
  checks: Array<InputMaybe<Scalars["String"]["input"]>> | InputMaybe<Scalars["String"]["input"]>;
}>;

export type DeleteCheckBatchItemsMutationMutation = {
  __typename?: "Mutation";
  DeleteCheckBatchItemsMutation?: boolean | null;
};

export type DeleteCheckBatchMembersMutationMutationVariables = Exact<{
  check_batch_id: Scalars["String"]["input"];
  organization_user_ids?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>> | InputMaybe<Scalars["String"]["input"]>
  >;
}>;

export type DeleteCheckBatchMembersMutationMutation = {
  __typename?: "Mutation";
  DeleteCheckBatchMembersMutation?: boolean | null;
};

export type DeleteCheckBatchMutationMutationVariables = Exact<{
  check_batch_id: Scalars["String"]["input"];
}>;

export type DeleteCheckBatchMutationMutation = { __typename?: "Mutation"; DeleteCheckBatchMutation?: boolean | null };

export type GetAvailableBankAccountsForBatchQueryQueryVariables = Exact<{
  check_batch_id: Scalars["String"]["input"];
  pagination?: InputMaybe<PaginationRequestType>;
  search?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetAvailableBankAccountsForBatchQueryQuery = {
  __typename?: "Query";
  GetAvailableBankAccountsForBatchQuery?: {
    __typename?: "BankAccountsPaginatedType";
    rows?: Array<{
      __typename?: "BankAccountType";
      id?: string | null;
      name?: string | null;
      on_current_batch?: boolean | null;
    } | null> | null;
    pagination?: {
      __typename?: "PaginationResponseType";
      total?: number | null;
      per_page?: number | null;
      on_first_page?: boolean | null;
      current_page?: number | null;
      last_page?: number | null;
      has_pages?: boolean | null;
      has_more_pages?: boolean | null;
      first_item?: number | null;
      last_item?: number | null;
    } | null;
  } | null;
};

export type GetAvailableChecksForBatchQueryQueryVariables = Exact<{
  check_batch_id: Scalars["String"]["input"];
  filters?: InputMaybe<CheckFiltersType>;
  pagination?: InputMaybe<PaginationRequestType>;
}>;

export type GetAvailableChecksForBatchQueryQuery = {
  __typename?: "Query";
  GetAvailableChecksForBatchQuery?: {
    __typename?: "ChecksPaginatedType";
    rows?: Array<{
      __typename?: "CheckType";
      id: string;
      number?: string | null;
      date?: string | null;
      amount?: number | null;
      memo?: string | null;
      pttoo?: string | null;
      recipient_email?: string | null;
      sender_title?: string | null;
      sender_subtitle?: string | null;
      recipient_title?: string | null;
      recipient_subtitle?: string | null;
      status?: {
        __typename?: "CheckStatusType";
        status?: string | null;
        state?: CheckStateEnumType | null;
        sub_status?: string | null;
      } | null;
      required_actions?: Array<{
        __typename?: "CheckRequiredActionType";
        action?: string | null;
        description?: string | null;
        type?: CheckRequiredActionTypeEnumType | null;
        key?: CheckRequiredActionActionEnumType | null;
      } | null> | null;
      action?: {
        __typename?: "CheckActionType";
        type?: string | null;
        label?: string | null;
        action?: string | null;
        disabled?: boolean | null;
        priority?: number | null;
        message?: string | null;
      } | null;
      options?: Array<{
        __typename?: "CheckActionType";
        type?: string | null;
        label?: string | null;
        action?: string | null;
        disabled?: boolean | null;
        priority?: number | null;
        message?: string | null;
      }> | null;
      remittance?: Array<{
        __typename?: "RemittanceRecordType";
        entity?: string | null;
        entity_ref?: string | null;
        date?: string | null;
        amount?: number | null;
        created_at?: string | null;
        meta?: Array<{
          __typename?: "RemittanceRecordMetaType";
          label?: string | null;
          order?: number | null;
          value?: string | null;
        }> | null;
      }> | null;
      activity?: Array<{
        __typename?: "CheckActivityType";
        id?: string | null;
        title?: string | null;
        note?: string | null;
        type: number;
        context?: number | null;
        can_manage: boolean;
        created_at?: string | null;
        username?: string | null;
        attachments?: Array<{
          __typename?: "AttachmentType";
          context?: number | null;
          sort_order?: number | null;
          media?: {
            __typename?: "MediaType";
            id?: string | null;
            name?: string | null;
            type?: string | null;
            size?: string | null;
            path?: string | null;
            filename?: string | null;
          } | null;
        }> | null;
      }> | null;
      may_not_add_to_batch?: {
        __typename?: "MayNotAddToBatchType";
        check_batch_id?: string | null;
        message?: string | null;
      } | null;
    } | null> | null;
    pagination?: {
      __typename?: "PaginationResponseType";
      total?: number | null;
      per_page?: number | null;
      on_first_page?: boolean | null;
      current_page?: number | null;
      last_page?: number | null;
      has_pages?: boolean | null;
      has_more_pages?: boolean | null;
      first_item?: number | null;
      last_item?: number | null;
    } | null;
  } | null;
};

export type GetCheckBatchAvailableMembersQueryQueryVariables = Exact<{
  check_batch_id: Scalars["String"]["input"];
  pagination?: InputMaybe<PaginationRequestType>;
  search?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetCheckBatchAvailableMembersQueryQuery = {
  __typename?: "Query";
  GetCheckBatchAvailableMembersQuery?: {
    __typename?: "OrganizationUsersWithPaginatedType";
    rows?: Array<{
      __typename?: "OrganizationUserType";
      id?: string | null;
      account?: { __typename?: "AccountType"; id: string; name?: string | null; email?: string | null } | null;
    } | null> | null;
    pagination?: {
      __typename?: "PaginationResponseType";
      total?: number | null;
      per_page?: number | null;
      on_first_page?: boolean | null;
      current_page?: number | null;
      last_page?: number | null;
      has_pages?: boolean | null;
      has_more_pages?: boolean | null;
      first_item?: number | null;
      last_item?: number | null;
    } | null;
  } | null;
};

export type GetCheckBatchMembersQueryQueryVariables = Exact<{
  check_batch_id: Scalars["String"]["input"];
  pagination?: InputMaybe<PaginationRequestType>;
  search?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetCheckBatchMembersQueryQuery = {
  __typename?: "Query";
  GetCheckBatchMembersQuery?: {
    __typename?: "OrganizationUsersWithPaginatedType";
    rows?: Array<{
      __typename?: "OrganizationUserType";
      id?: string | null;
      account?: { __typename?: "AccountType"; id: string; name?: string | null; email?: string | null } | null;
    } | null> | null;
    pagination?: {
      __typename?: "PaginationResponseType";
      total?: number | null;
      per_page?: number | null;
      on_first_page?: boolean | null;
      current_page?: number | null;
      last_page?: number | null;
      has_pages?: boolean | null;
      has_more_pages?: boolean | null;
      first_item?: number | null;
      last_item?: number | null;
    } | null;
  } | null;
};

export type GetCheckBatchQueryQueryVariables = Exact<{
  check_batch_id: Scalars["String"]["input"];
  filters?: InputMaybe<CheckFiltersType>;
  pagination?: InputMaybe<PaginationRequestType>;
}>;

export type GetCheckBatchQueryQuery = {
  __typename?: "Query";
  GetCheckBatchQuery?: {
    __typename?: "CheckBatchType";
    id?: string | null;
    name?: string | null;
    budget?: number | null;
    state?: CheckBatchStateEnum | null;
    created_at?: string | null;
    created_with?: CheckBatchCreatedWithEnum | null;
    items_amount?: number | null;
    items_count?: number | null;
    unsigned_amount?: number | null;
    unsigned_count?: number | null;
    members_count?: number | null;
    current_user_may_sign?: boolean | null;
    company?: {
      __typename?: "CompanyType";
      id?: string | null;
      legal_name?: string | null;
      dba?: string | null;
    } | null;
    created_by_organization_user?: {
      __typename?: "OrganizationUserType";
      id?: string | null;
      account?: { __typename?: "AccountType"; id: string; name?: string | null; email?: string | null } | null;
    } | null;
    items?: {
      __typename?: "CheckBatchItemsPaginatedType";
      rows?: Array<{
        __typename?: "CheckBatchItemType";
        id?: string | null;
        created_with?: CheckBatchItemCreatedWithEnum | null;
        check?: {
          __typename?: "CheckType";
          id: string;
          number?: string | null;
          date?: string | null;
          amount?: number | null;
          memo?: string | null;
          pttoo?: string | null;
          recipient_email?: string | null;
          sender_title?: string | null;
          sender_subtitle?: string | null;
          recipient_title?: string | null;
          recipient_subtitle?: string | null;
          status?: {
            __typename?: "CheckStatusType";
            status?: string | null;
            state?: CheckStateEnumType | null;
            sub_status?: string | null;
          } | null;
          required_actions?: Array<{
            __typename?: "CheckRequiredActionType";
            action?: string | null;
            description?: string | null;
            type?: CheckRequiredActionTypeEnumType | null;
            key?: CheckRequiredActionActionEnumType | null;
          } | null> | null;
          action?: {
            __typename?: "CheckActionType";
            type?: string | null;
            label?: string | null;
            action?: string | null;
            disabled?: boolean | null;
            priority?: number | null;
            message?: string | null;
          } | null;
          options?: Array<{
            __typename?: "CheckActionType";
            type?: string | null;
            label?: string | null;
            action?: string | null;
            disabled?: boolean | null;
            priority?: number | null;
            message?: string | null;
          }> | null;
          remittance?: Array<{
            __typename?: "RemittanceRecordType";
            entity?: string | null;
            entity_ref?: string | null;
            date?: string | null;
            amount?: number | null;
            created_at?: string | null;
            meta?: Array<{
              __typename?: "RemittanceRecordMetaType";
              label?: string | null;
              order?: number | null;
              value?: string | null;
            }> | null;
          }> | null;
          activity?: Array<{
            __typename?: "CheckActivityType";
            id?: string | null;
            title?: string | null;
            note?: string | null;
            type: number;
            can_manage: boolean;
            created_at?: string | null;
            username?: string | null;
            attachments?: Array<{
              __typename?: "AttachmentType";
              sort_order?: number | null;
              media?: {
                __typename?: "MediaType";
                id?: string | null;
                name?: string | null;
                type?: string | null;
                size?: string | null;
                path?: string | null;
                filename?: string | null;
              } | null;
            }> | null;
          }> | null;
          may_not_add_to_batch?: {
            __typename?: "MayNotAddToBatchType";
            check_batch_id?: string | null;
            message?: string | null;
          } | null;
        } | null;
        reactions?: Array<{
          __typename?: "CheckBatchItemReactionType";
          id?: string | null;
          organization_user_id?: string | null;
          reaction: ReactionEnumType;
        } | null> | null;
      } | null> | null;
      pagination?: {
        __typename?: "PaginationResponseType";
        total?: number | null;
        per_page?: number | null;
        on_first_page?: boolean | null;
        current_page?: number | null;
        last_page?: number | null;
        has_pages?: boolean | null;
        has_more_pages?: boolean | null;
        first_item?: number | null;
        last_item?: number | null;
      } | null;
    } | null;
  } | null;
};

export type GetCheckBatchesQueryQueryVariables = Exact<{
  organization_id: Scalars["String"]["input"];
  filters?: InputMaybe<GetCheckBatchesFiltersType>;
  pagination?: InputMaybe<PaginationRequestType>;
}>;

export type GetCheckBatchesQueryQuery = {
  __typename?: "Query";
  GetCheckBatchesQuery?: {
    __typename?: "CheckBatchesPaginatedType";
    rows?: Array<{
      __typename?: "CheckBatchType";
      id?: string | null;
      name?: string | null;
      budget?: number | null;
      state?: CheckBatchStateEnum | null;
      created_at?: string | null;
      created_with?: CheckBatchCreatedWithEnum | null;
      items_amount?: number | null;
      items_count?: number | null;
      members_count?: number | null;
      company?: {
        __typename?: "CompanyType";
        id?: string | null;
        legal_name?: string | null;
        dba?: string | null;
      } | null;
      created_by_organization_user?: {
        __typename?: "OrganizationUserType";
        id?: string | null;
        account?: { __typename?: "AccountType"; id: string; name?: string | null; email?: string | null } | null;
      } | null;
      items?: {
        __typename?: "CheckBatchItemsPaginatedType";
        rows?: Array<{
          __typename?: "CheckBatchItemType";
          id?: string | null;
          check?: {
            __typename?: "CheckType";
            id: string;
            number?: string | null;
            date?: string | null;
            amount?: number | null;
            memo?: string | null;
            pttoo?: string | null;
          } | null;
          reactions?: Array<{
            __typename?: "CheckBatchItemReactionType";
            id?: string | null;
            organization_user_id?: string | null;
            reaction: ReactionEnumType;
          } | null> | null;
        } | null> | null;
        pagination?: {
          __typename?: "PaginationResponseType";
          total?: number | null;
          per_page?: number | null;
          on_first_page?: boolean | null;
          current_page?: number | null;
          last_page?: number | null;
          has_pages?: boolean | null;
          has_more_pages?: boolean | null;
          first_item?: number | null;
          last_item?: number | null;
        } | null;
      } | null;
    } | null> | null;
    pagination?: {
      __typename?: "PaginationResponseType";
      total?: number | null;
      per_page?: number | null;
      on_first_page?: boolean | null;
      current_page?: number | null;
      last_page?: number | null;
      has_pages?: boolean | null;
      has_more_pages?: boolean | null;
      first_item?: number | null;
      last_item?: number | null;
    } | null;
  } | null;
};

export type GetCheckDetailsQueryQueryVariables = Exact<{
  check_batch_id: Scalars["String"]["input"];
  check_id: Scalars["String"]["input"];
  check_details_scope?: InputMaybe<CheckDetailsScopeEnum>;
}>;

export type GetCheckDetailsQueryQuery = {
  __typename?: "Query";
  GetCheckDetailsQuery?: {
    __typename?: "CheckType";
    id: string;
    number?: string | null;
    date?: string | null;
    amount?: number | null;
    memo?: string | null;
    pttoo?: string | null;
    recipient_email?: string | null;
    sender_title?: string | null;
    sender_subtitle?: string | null;
    recipient_title?: string | null;
    recipient_subtitle?: string | null;
    status?: {
      __typename?: "CheckStatusType";
      status?: string | null;
      state?: CheckStateEnumType | null;
      sub_status?: string | null;
    } | null;
    required_actions?: Array<{
      __typename?: "CheckRequiredActionType";
      action?: string | null;
      description?: string | null;
      type?: CheckRequiredActionTypeEnumType | null;
      key?: CheckRequiredActionActionEnumType | null;
    } | null> | null;
    action?: {
      __typename?: "CheckActionType";
      type?: string | null;
      label?: string | null;
      action?: string | null;
      disabled?: boolean | null;
      priority?: number | null;
      message?: string | null;
    } | null;
    options?: Array<{
      __typename?: "CheckActionType";
      type?: string | null;
      label?: string | null;
      action?: string | null;
      disabled?: boolean | null;
      priority?: number | null;
      message?: string | null;
    }> | null;
    remittance?: Array<{
      __typename?: "RemittanceRecordType";
      entity?: string | null;
      entity_ref?: string | null;
      date?: string | null;
      amount?: number | null;
      created_at?: string | null;
      meta?: Array<{
        __typename?: "RemittanceRecordMetaType";
        label?: string | null;
        order?: number | null;
        value?: string | null;
      }> | null;
    }> | null;
    activity?: Array<{
      __typename?: "CheckActivityType";
      id?: string | null;
      title?: string | null;
      note?: string | null;
      type: number;
      can_manage: boolean;
      created_at?: string | null;
      username?: string | null;
      attachments?: Array<{
        __typename?: "AttachmentType";
        sort_order?: number | null;
        media?: {
          __typename?: "MediaType";
          id?: string | null;
          name?: string | null;
          type?: string | null;
          size?: string | null;
          path?: string | null;
          filename?: string | null;
        } | null;
      }> | null;
    }> | null;
    may_not_add_to_batch?: {
      __typename?: "MayNotAddToBatchType";
      check_batch_id?: string | null;
      message?: string | null;
    } | null;
  } | null;
};

export type SignCheckBatchMutationMutationVariables = Exact<{
  check_batch_id: Scalars["String"]["input"];
}>;

export type SignCheckBatchMutationMutation = {
  __typename?: "Mutation";
  SignCheckBatchMutation?: {
    __typename?: "SignCheckBatchType";
    checks_available_for_signing?: number | null;
    signed_checks?: number | null;
  } | null;
};

export type UpdateCheckBatchItemsReactionMutationMutationVariables = Exact<{
  check_batch_item_ids?: InputMaybe<Array<Scalars["String"]["input"]> | Scalars["String"]["input"]>;
  reaction: ReactionEnumType;
}>;

export type UpdateCheckBatchItemsReactionMutationMutation = {
  __typename?: "Mutation";
  UpdateCheckBatchItemsReactionMutation?: Array<string | null> | null;
};

export type UpdateCheckBatchMutationMutationVariables = Exact<{
  check_batch_id: Scalars["String"]["input"];
  name?: InputMaybe<Scalars["String"]["input"]>;
  budget?: InputMaybe<Scalars["Float"]["input"]>;
}>;

export type UpdateCheckBatchMutationMutation = {
  __typename?: "Mutation";
  UpdateCheckBatchMutation?: { __typename?: "CheckBatchType"; id?: string | null } | null;
};

export type BankAccountLinkedAddressesQueryVariables = Exact<{
  bank_account_id: Scalars["String"]["input"];
}>;

export type BankAccountLinkedAddressesQuery = {
  __typename?: "Query";
  BankAccountLinkedAddresses?: Array<{
    __typename?: "BankAccountLinkedAddress";
    id?: string | null;
    email?: string | null;
    status?: string | null;
    created_by?: string | null;
    created_at?: string | null;
  }> | null;
};

export type BankAccountAutoDepositAddressesQueryVariables = Exact<{
  bank_account_id: Scalars["String"]["input"];
}>;

export type BankAccountAutoDepositAddressesQuery = {
  __typename?: "Query";
  BankAccountAutoDepositAddresses?: Array<{
    __typename?: "BankAccountAutoDepositAddress";
    id?: string | null;
    email?: string | null;
    type?: string | null;
    created_by?: string | null;
  }> | null;
};

export type BankAccountAvailableAutoDepositAddressesQueryVariables = Exact<{
  bankAccountId: Scalars["String"]["input"];
}>;

export type BankAccountAvailableAutoDepositAddressesQuery = {
  __typename?: "Query";
  BankAccountAvailableAutoDepositAddresses?: Array<{
    __typename?: "EmailAddress";
    id?: string | null;
    email?: string | null;
  }> | null;
};

export type CreateBankAccountAutoDepositAddressMutationVariables = Exact<{
  bankAccountId: Scalars["String"]["input"];
  emailAddressId: Scalars["String"]["input"];
}>;

export type CreateBankAccountAutoDepositAddressMutation = {
  __typename?: "Mutation";
  CreateBankAccountAutoDepositAddress?: {
    __typename?: "BankAccountAutoDepositAddress";
    id?: string | null;
    email?: string | null;
    type?: string | null;
    created_by?: string | null;
  } | null;
};

export type DeleteBankAccountAutoDepositAddressMutationVariables = Exact<{
  bankAccountId: Scalars["String"]["input"];
  emailAddressId: Scalars["String"]["input"];
}>;

export type DeleteBankAccountAutoDepositAddressMutation = {
  __typename?: "Mutation";
  DeleteBankAccountAutoDepositAddress?: boolean | null;
};

export type CreateBankAccountLinkedAddressMutationVariables = Exact<{
  bankAccountId: Scalars["String"]["input"];
  email: Scalars["String"]["input"];
}>;

export type CreateBankAccountLinkedAddressMutation = {
  __typename?: "Mutation";
  CreateBankAccountLinkedAddress?: {
    __typename?: "LinkedAddress";
    id: string;
    email: string;
    verified: boolean;
    state?: LinkedAddressStateEnum | null;
    created_at?: string | null;
    created_by?: string | null;
  } | null;
};

export type DeleteBankAccountLinkedAddressMutationVariables = Exact<{
  addressId: Scalars["String"]["input"];
}>;

export type DeleteBankAccountLinkedAddressMutation = {
  __typename?: "Mutation";
  DeleteBankAccountLinkedAddress?: boolean | null;
};

export type CompanyDetailsQueryVariables = Exact<{
  company_id: Scalars["String"]["input"];
}>;

export type CompanyDetailsQuery = {
  __typename?: "Query";
  CompanyDetails?: {
    __typename?: "CompanyDetails";
    id: string;
    name: string;
    website_url?: string | null;
    vbill_enabled: boolean;
    vbill_email?: string | null;
    phone_number?: string | null;
    physical_address?: {
      __typename?: "Address";
      id: string;
      address?: string | null;
      address2?: string | null;
      city?: string | null;
      state?: string | null;
      zip_code?: string | null;
      country?: string | null;
    } | null;
    company_integrations?: Array<{
      __typename?: "CompanyIntegration";
      scopes?: Array<string> | null;
      expires_at?: string | null;
      created_at?: string | null;
      last_seen?: string | null;
      integration?: {
        __typename?: "Integration";
        integration_id: string;
        name?: string | null;
        display_name?: string | null;
        slug?: string | null;
        scopes?: Array<string> | null;
        logo?: string | null;
      } | null;
      settings?: {
        __typename?: "IntegrationSettings";
        description?: string | null;
        auto_import?: boolean | null;
        sync_status?: string | null;
        sync_message?: string | null;
        sync_expire?: number | null;
        sync_time?: number | null;
        current_time?: number | null;
      } | null;
      external_auto_signers?: Array<{
        __typename?: "ExternalAutoSigner";
        id: string;
        name?: string | null;
        organization_user_id?: string | null;
      }> | null;
    }> | null;
  } | null;
};

export type AssignGroupToCompanyMutationVariables = Exact<{
  organization_id: Scalars["String"]["input"];
  company_id: Scalars["String"]["input"];
  group_id?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type AssignGroupToCompanyMutation = {
  __typename?: "Mutation";
  AssignGroupToCompanyMutation?: Array<{
    __typename?: "CompanyGroupType";
    id: string;
    label: string;
    company_ids?: Array<string | null> | null;
  } | null> | null;
};

export type CreateCompanyGroupMutationVariables = Exact<{
  organization_id: Scalars["String"]["input"];
  label: Scalars["String"]["input"];
}>;

export type CreateCompanyGroupMutation = {
  __typename?: "Mutation";
  CreateCompanyGroupMutation?: Array<{
    __typename?: "CompanyGroupType";
    id: string;
    label: string;
    company_ids?: Array<string | null> | null;
  } | null> | null;
};

export type DeleteCompanyGroupMutationVariables = Exact<{
  organization_id: Scalars["String"]["input"];
  group_id: Scalars["String"]["input"];
}>;

export type DeleteCompanyGroupMutation = {
  __typename?: "Mutation";
  DeleteCompanyGroupMutation?: Array<{
    __typename?: "CompanyGroupType";
    id: string;
    label: string;
    company_ids?: Array<string | null> | null;
  } | null> | null;
};

export type EditCompanyGroupMutationVariables = Exact<{
  organization_id: Scalars["String"]["input"];
  group_id: Scalars["String"]["input"];
  label: Scalars["String"]["input"];
}>;

export type EditCompanyGroupMutation = {
  __typename?: "Mutation";
  EditCompanyGroupMutation?: Array<{
    __typename?: "CompanyGroupType";
    id: string;
    label: string;
    company_ids?: Array<string | null> | null;
  } | null> | null;
};

export type GetCompanyGroupsQueryVariables = Exact<{
  organization_id: Scalars["String"]["input"];
}>;

export type GetCompanyGroupsQuery = {
  __typename?: "Query";
  GetCompanyGroupsQuery?: Array<{
    __typename?: "CompanyGroupType";
    id: string;
    label: string;
    company_ids?: Array<string | null> | null;
  } | null> | null;
};

export type ContactsQueryVariables = Exact<{
  companyId: Scalars["String"]["input"];
  pagination?: InputMaybe<Pagination>;
  search?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type ContactsQuery = {
  __typename?: "Query";
  Contacts?: {
    __typename?: "ContactsQueryResult";
    pagination?: {
      __typename?: "PaginationResult";
      total?: number | null;
      per_page?: number | null;
      on_first_page?: boolean | null;
      current_page?: number | null;
      last_page?: number | null;
      has_pages?: boolean | null;
      has_more_pages?: boolean | null;
      first_item?: number | null;
      last_item?: number | null;
    } | null;
    contacts?: Array<{
      __typename?: "Contact";
      id: string;
      external_ref: string;
      name: string;
      active?: boolean | null;
      created_at?: string | null;
      address?: Array<{
        __typename?: "Address";
        id: string;
        address?: string | null;
        address2?: string | null;
        city?: string | null;
        state?: string | null;
        zip_code?: string | null;
        country?: string | null;
      }> | null;
      phone_number?: Array<{
        __typename?: "ContactPhoneNumber";
        id: string;
        number?: string | null;
        type?: string | null;
        description?: string | null;
      }> | null;
    }> | null;
  } | null;
};

export type DepositBankAccountsQueryVariables = Exact<{ [key: string]: never }>;

export type DepositBankAccountsQuery = {
  __typename?: "Query";
  DepositBankAccounts?: Array<{
    __typename?: "OrganizationBasic";
    id: string;
    name: string;
    companies: Array<{
      __typename?: "CompanyBasic";
      id: string;
      name: string;
      description?: string | null;
      bank_accounts?: Array<{
        __typename?: "BankAccountBasic";
        id: string;
        name: string;
        account_number: string;
        routing_number: string;
        bank_name: string;
      } | null> | null;
    }>;
  } | null> | null;
};

export type GetFrontendSettingsQueryQueryVariables = Exact<{
  context: FrontendSettingsContextEnum;
  id: Scalars["String"]["input"];
}>;

export type GetFrontendSettingsQueryQuery = { __typename?: "Query"; GetFrontendSettingsQuery?: string | null };

export type SaveFrontendSettingsMutationMutationVariables = Exact<{
  context: FrontendSettingsContextEnum;
  id: Scalars["String"]["input"];
  path: Scalars["String"]["input"];
  value: Scalars["String"]["input"];
}>;

export type SaveFrontendSettingsMutationMutation = {
  __typename?: "Mutation";
  SaveFrontendSettingsMutation?: string | null;
};

export type GeneralLedgerAccountsQueryVariables = Exact<{
  companyId?: InputMaybe<Scalars["String"]["input"]>;
  vcard_company_account_id?: InputMaybe<Scalars["String"]["input"]>;
  account_types?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>> | InputMaybe<Scalars["String"]["input"]>>;
}>;

export type GeneralLedgerAccountsQuery = {
  __typename?: "Query";
  GeneralLedgerAccounts?: Array<{
    __typename?: "GeneralLedgerAccount";
    id: string;
    external_ref: string;
    name: string;
    number?: string | null;
    category?: string | null;
    description?: string | null;
    account_type?: string | null;
    account_number?: string | null;
    active?: boolean | null;
    bank_account?: {
      __typename?: "BankAccountBasic";
      id: string;
      bank_name: string;
      name: string;
      account_number: string;
    } | null;
  }> | null;
};

export type SetBankAccountGlAccountMappingMutationVariables = Exact<{
  bankAccountId: Scalars["String"]["input"];
  glAccountId: Scalars["String"]["input"];
}>;

export type SetBankAccountGlAccountMappingMutation = {
  __typename?: "Mutation";
  SetBankAccountGlAccountMapping?: {
    __typename?: "BankAccountBasicWithMappedGeneralLedgerAccounts";
    id: string;
    name: string;
    account_number: string;
    routing_number: string;
    bank_name: string;
    gl_accounts?: Array<{
      __typename?: "GeneralLedgerAccount";
      id: string;
      name: string;
      external_ref: string;
      account_number?: string | null;
    }> | null;
  } | null;
};

export type CompanyIntegrationsQueryVariables = Exact<{
  companyId: Scalars["String"]["input"];
}>;

export type CompanyIntegrationsQuery = {
  __typename?: "Query";
  CompanyIntegrations?: {
    __typename?: "CompanyIntegrations";
    integrations?: Array<{
      __typename?: "CompanyIntegration";
      scopes?: Array<string> | null;
      expires_at?: string | null;
      created_at?: string | null;
      last_seen?: string | null;
      integration?: {
        __typename?: "Integration";
        integration_id: string;
        display_name?: string | null;
        logo?: string | null;
        name?: string | null;
        slug?: string | null;
        scopes?: Array<string> | null;
        webhooks?: Array<{ __typename?: "IntegrationWebhook"; url?: string | null; scope?: string | null }> | null;
      } | null;
      settings?: {
        __typename?: "IntegrationSettings";
        description?: string | null;
        auto_import?: boolean | null;
        sync_status?: string | null;
        sync_message?: string | null;
        sync_expire?: number | null;
        sync_time?: number | null;
        current_time?: number | null;
        pending_checks_count?: number | null;
      } | null;
      external_auto_signers?: Array<{
        __typename?: "ExternalAutoSigner";
        id: string;
        name?: string | null;
        organization_user_id?: string | null;
        company_id?: string | null;
      }> | null;
    }> | null;
    setup_status?: {
      __typename?: "CompanyIntegrationsSetupStatus";
      mapped_organization_users?: number | null;
      mapped_bank_accounts?: number | null;
      unmapped_organization_users?: number | null;
      unmapped_bank_accounts?: number | null;
    } | null;
    bank_accounts?: Array<{
      __typename?: "BankAccount";
      id: string;
      company_id?: string | null;
      bank_name: string;
      name: string;
      account_number?: string | null;
      routing_number?: string | null;
      legal_first_name?: string | null;
      legal_last_name?: string | null;
      verification_status: string;
      gl_accounts?: Array<{
        __typename?: "GeneralLedgerAccount";
        id: string;
        name: string;
        external_ref: string;
      }> | null;
    }> | null;
  } | null;
};

export type GetAllKnownExtSignerNamesQueryVariables = Exact<{
  company_id: Scalars["String"]["input"];
}>;

export type GetAllKnownExtSignerNamesQuery = { __typename?: "Query"; GetAllKnownExtSignerNames?: Array<string> | null };

export type GetIntegrationAuthCodeMutationVariables = Exact<{
  integrationId: Scalars["String"]["input"];
  companyId: Scalars["String"]["input"];
  integration_settings?: InputMaybe<IntegrationSettingsInput>;
}>;

export type GetIntegrationAuthCodeMutation = { __typename?: "Mutation"; GetIntegrationAuthCode?: string | null };

export type IntegrationsQueryVariables = Exact<{
  companyId?: InputMaybe<Scalars["String"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type IntegrationsQuery = {
  __typename?: "Query";
  Integrations?: Array<{
    __typename?: "Integration";
    integration_id: string;
    name?: string | null;
    slug?: string | null;
    display_name?: string | null;
    scopes?: Array<string> | null;
    logo?: string | null;
    webhooks?: Array<{ __typename?: "IntegrationWebhook"; url?: string | null; scope?: string | null }> | null;
  }> | null;
};

export type RevokeAccessTokenMutationVariables = Exact<{
  companyId: Scalars["String"]["input"];
  integrationId: Scalars["String"]["input"];
}>;

export type RevokeAccessTokenMutation = { __typename?: "Mutation"; RevokeAccessToken?: boolean | null };

export type ArchivePendingChecksMutationVariables = Exact<{
  companyId: Scalars["String"]["input"];
  integrationId: Scalars["String"]["input"];
  checks: Array<InputMaybe<Scalars["String"]["input"]>> | InputMaybe<Scalars["String"]["input"]>;
}>;

export type ArchivePendingChecksMutation = { __typename?: "Mutation"; ArchivePendingChecks?: boolean | null };

export type CreateExternalAutoSignerMutationVariables = Exact<{
  integration_id: Scalars["String"]["input"];
  organization_user_id: Scalars["String"]["input"];
  name?: InputMaybe<Scalars["String"]["input"]>;
  company_id: Scalars["String"]["input"];
}>;

export type CreateExternalAutoSignerMutation = {
  __typename?: "Mutation";
  CreateExternalAutoSigner?: {
    __typename?: "ExternalAutoSigner";
    id: string;
    name?: string | null;
    organization_user_id?: string | null;
    company_id?: string | null;
  } | null;
};

export type DeleteExternalAutoSignerMutationVariables = Exact<{
  external_signer_id: Scalars["String"]["input"];
  company_id: Scalars["String"]["input"];
}>;

export type DeleteExternalAutoSignerMutation = { __typename?: "Mutation"; DeleteExternalAutoSigner?: boolean | null };

export type GetPendingChecksQueryVariables = Exact<{
  companyId: Scalars["String"]["input"];
  integrationId: Scalars["String"]["input"];
  pagination?: InputMaybe<Pagination>;
  checks?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>> | InputMaybe<Scalars["String"]["input"]>>;
  filters?: InputMaybe<PendingChecksFilterInput>;
}>;

export type GetPendingChecksQuery = {
  __typename?: "Query";
  PendingChecks?: {
    __typename?: "PendingChecks";
    total?: number | null;
    checks?: Array<{
      __typename?: "Check";
      id: string;
      recipient_email?: string | null;
      pttoo?: string | null;
      date?: string | null;
      number?: string | null;
      amount?: number | null;
      memo?: string | null;
      entity_ref?: string | null;
      role?: string | null;
      status?: {
        __typename?: "CheckStatusType";
        status?: string | null;
        state?: CheckStateEnumType | null;
        sub_status?: string | null;
      } | null;
      required_actions?: Array<{
        __typename?: "CheckRequiredActionType";
        action?: string | null;
        description?: string | null;
        type?: CheckRequiredActionTypeEnumType | null;
        key?: CheckRequiredActionActionEnumType | null;
      } | null> | null;
      sender?: {
        __typename?: "BankAccountBasic";
        id: string;
        name: string;
        account_number: string;
        routing_number: string;
        bank_name: string;
      } | null;
      recipient?: {
        __typename?: "BankAccountBasic";
        id: string;
        name: string;
        account_number: string;
        routing_number: string;
        bank_name: string;
      } | null;
      action?: {
        __typename?: "CheckActionType";
        type?: string | null;
        label?: string | null;
        action?: string | null;
        disabled?: boolean | null;
      } | null;
      options?: Array<{
        __typename?: "CheckActionType";
        type?: string | null;
        label?: string | null;
        action?: string | null;
        disabled?: boolean | null;
      }> | null;
      related_transactions?: Array<{
        __typename?: "LedgerCheck";
        id: string;
        recipient_email?: string | null;
        pttoo?: string | null;
        date?: string | null;
        number?: string | null;
        amount?: number | null;
      }> | null;
      remittance?: Array<{
        __typename?: "RemittanceRecordType";
        date?: string | null;
        amount?: number | null;
        created_at?: string | null;
      }> | null;
      meta?: Array<{
        __typename?: "CheckMeta";
        label?: string | null;
        order?: number | null;
        value?: string | null;
      }> | null;
      gl_account?: {
        __typename?: "GeneralLedgerAccountPendingChecks";
        external_ref?: string | null;
        name?: string | null;
        full_name?: string | null;
        active?: boolean | null;
        description?: string | null;
      } | null;
      errors?: Array<{
        __typename?: "PendingCheckErrors";
        type?: string | null;
        message?: string | null;
        data?: string | null;
      }> | null;
    }> | null;
  } | null;
};

export type ImportPendingChecksMutationVariables = Exact<{
  companyId: Scalars["String"]["input"];
  integrationId: Scalars["String"]["input"];
  checks: Array<InputMaybe<ImportPendingChecks>> | InputMaybe<ImportPendingChecks>;
}>;

export type ImportPendingChecksMutation = {
  __typename?: "Mutation";
  ImportPendingChecks: Array<{
    __typename?: "CreateCheck";
    entity_ref?: string | null;
    success?: boolean | null;
    errors: Array<{ __typename?: "ErrorCreateCheck"; message: string }>;
  }>;
};

export type SetIntegrationConfigurationMutationVariables = Exact<{
  integrationId: Scalars["String"]["input"];
  companyId: Scalars["String"]["input"];
  configuration: Array<InputMaybe<IntegrationConfigurationInput>> | InputMaybe<IntegrationConfigurationInput>;
}>;

export type SetIntegrationConfigurationMutation = {
  __typename?: "Mutation";
  IntegrationConfiguration?: boolean | null;
};

export type UpdateExternalAutoSignerMutationVariables = Exact<{
  external_signer_id: Scalars["String"]["input"];
  company_id: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
}>;

export type UpdateExternalAutoSignerMutation = {
  __typename?: "Mutation";
  UpdateExternalAutoSigner?: {
    __typename?: "ExternalAutoSigner";
    id: string;
    name?: string | null;
    organization_user_id?: string | null;
  } | null;
};

export type GetNotificationsQueryVariables = Exact<{ [key: string]: never }>;

export type GetNotificationsQuery = {
  __typename?: "Query";
  GetNotifications: {
    __typename?: "Notifications";
    unread_count?: number | null;
    notifications?: Array<{
      __typename?: "AppNotifications";
      id: string;
      account_id?: string | null;
      viewed?: string | null;
      title?: string | null;
      short_desc: string;
      long_desc?: string | null;
      level?: string | null;
      action_url?: string | null;
      channel?: string | null;
      option_1_title?: string | null;
      option_1_url?: string | null;
      option_2_title?: string | null;
      option_2_url?: string | null;
      company_id?: string | null;
      created_at?: string | null;
    }> | null;
  };
};

export type UpdateNotificationMutationVariables = Exact<{
  notification_id: Scalars["String"]["input"];
}>;

export type UpdateNotificationMutation = { __typename?: "Mutation"; UpdateNotification?: number | null };

export type OrganizationDashboardQueryVariables = Exact<{
  organization_id?: InputMaybe<Scalars["String"]["input"]>;
  pagination?: InputMaybe<Pagination>;
  filters?: InputMaybe<OrganizationDashboardFilterInput>;
  get_unvalidated_dashboard?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type OrganizationDashboardQuery = {
  __typename?: "Query";
  OrganizationDashboard: {
    __typename?: "OrganizationDashboard";
    check_batching_enabled?: boolean | null;
    vbill_enabled?: boolean | null;
    vbill_email?: string | null;
    companies?: Array<{
      __typename?: "CompanyWithBankAccountsAndAggregatedData";
      organization_id?: string | null;
      id: string;
      name: string;
      description?: string | null;
      verification_status?: string | null;
      verification_link?: string | null;
      user_subscribed_to_company_notifications?: boolean | null;
      vbill_enabled: boolean;
      vbill_email?: string | null;
      bank_accounts?: Array<{
        __typename?: "BankAccountWithAggregatedData";
        id: string;
        name: string;
        nickname?: string | null;
        account_number?: string | null;
        routing_number?: string | null;
        deposit_only: boolean;
        verification_status: string;
        verification_link?: string | null;
        can_upgrade?: boolean | null;
        outgoing_checks_stats?: { __typename?: "VChecksStats"; count?: number | null; sum?: number | null } | null;
        incoming_checks_stats?: { __typename?: "VChecksStats"; count?: number | null; sum?: number | null } | null;
        pending_approval_checks_stats?: {
          __typename?: "VChecksStats";
          count?: number | null;
          sum?: number | null;
        } | null;
      }> | null;
    }> | null;
    unvalidated_dashboard?: {
      __typename?: "UnvalidatedDashboardType";
      companies?: Array<{
        __typename?: "CompanyWithBankAccountsAndAggregatedData";
        organization_id?: string | null;
        id: string;
        name: string;
        description?: string | null;
        verification_status?: string | null;
        verification_link?: string | null;
        user_subscribed_to_company_notifications?: boolean | null;
        vbill_enabled: boolean;
        vbill_email?: string | null;
        bank_accounts?: Array<{
          __typename?: "BankAccountWithAggregatedData";
          id: string;
          name: string;
          nickname?: string | null;
          account_number?: string | null;
          routing_number?: string | null;
          deposit_only: boolean;
          verification_status: string;
          verification_link?: string | null;
          can_upgrade?: boolean | null;
          outgoing_checks_stats?: { __typename?: "VChecksStats"; count?: number | null; sum?: number | null } | null;
          incoming_checks_stats?: { __typename?: "VChecksStats"; count?: number | null; sum?: number | null } | null;
          pending_approval_checks_stats?: {
            __typename?: "VChecksStats";
            count?: number | null;
            sum?: number | null;
          } | null;
        }> | null;
      }> | null;
      pagination?: {
        __typename?: "PaginationResult";
        total?: number | null;
        per_page?: number | null;
        on_first_page?: boolean | null;
        current_page?: number | null;
        last_page?: number | null;
        has_pages?: boolean | null;
        has_more_pages?: boolean | null;
        first_item?: number | null;
        last_item?: number | null;
      } | null;
    } | null;
    pagination?: {
      __typename?: "PaginationResult";
      total?: number | null;
      per_page?: number | null;
      on_first_page?: boolean | null;
      current_page?: number | null;
      last_page?: number | null;
      has_pages?: boolean | null;
      has_more_pages?: boolean | null;
      first_item?: number | null;
      last_item?: number | null;
    } | null;
  };
};

export type PendingActionsAndChecksCountQueryVariables = Exact<{
  organization_id?: InputMaybe<Scalars["String"]["input"]>;
  pagination?: InputMaybe<Pagination>;
  filters?: InputMaybe<OrganizationDashboardFilterInput>;
}>;

export type PendingActionsAndChecksCountQuery = {
  __typename?: "Query";
  OrganizationDashboard: {
    __typename?: "OrganizationDashboard";
    pending_actions?: Array<{
      __typename?: "PendingAction";
      company_name?: string | null;
      bank_account_name?: string | null;
      bank_account_account_number?: string | null;
      verification_link?: string | null;
    }> | null;
    companies_pending_checks_count?: Array<{
      __typename?: "CompanyPendingChecksCount";
      organization_id?: string | null;
      id: string;
      name: string;
      pending_checks_count?: number | null;
    }> | null;
  };
};

export type OrganizationStructureQueryVariables = Exact<{
  organization_id?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type OrganizationStructureQuery = {
  __typename?: "Query";
  OrganizationDashboard: {
    __typename?: "OrganizationDashboard";
    companies?: Array<{
      __typename?: "CompanyWithBankAccountsAndAggregatedData";
      organization_id?: string | null;
      id: string;
      name: string;
      description?: string | null;
      vbill_enabled: boolean;
      vbill_email?: string | null;
      bank_accounts?: Array<{
        __typename?: "BankAccountWithAggregatedData";
        id: string;
        name: string;
        nickname?: string | null;
        bank_name: string;
        account_number?: string | null;
        deposit_only: boolean;
        verification_status: string;
        verification_link?: string | null;
      }> | null;
    }> | null;
  };
};

export type UpdateCompanyNotificationSubscriptionMutationVariables = Exact<{
  company_id: Scalars["String"]["input"];
  email_notification: Scalars["Boolean"]["input"];
}>;

export type UpdateCompanyNotificationSubscriptionMutation = {
  __typename?: "Mutation";
  UpdateCompanyNotificationSubscription?: {
    __typename?: "NotificationSubscriptions";
    id: string;
    company_id: string;
    account_id: string;
    email_notification?: boolean | null;
  } | null;
};

export type UpdateOrganizationMutationVariables = Exact<{
  organizationId: Scalars["String"]["input"];
  name?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type UpdateOrganizationMutation = {
  __typename?: "Mutation";
  UpdateOrganization?: { __typename?: "Organization"; id: string; name: string } | null;
};

export type PingQueryVariables = Exact<{ [key: string]: never }>;

export type PingQuery = { __typename?: "Query"; Ping?: boolean | null };

export type SessionAccountQueryVariables = Exact<{ [key: string]: never }>;

export type SessionAccountQuery = {
  __typename?: "Query";
  SessionAccount: {
    __typename?: "SessionAccountData";
    deposit_accounts?: number | null;
    has_in_progress_onboarding?: boolean | null;
    has_pending_invites?: boolean | null;
    organizations_where_is_manager?: Array<string> | null;
    account?: {
      __typename?: "Account";
      id: string;
      username: string;
      name: string;
      email: string;
      email_address_id: string;
      phone_number?: string | null;
      email_verified?: boolean | null;
      phone_number_verified?: boolean | null;
    } | null;
    organizationUsers?: Array<{
      __typename?: "OrganizationUser";
      id: string;
      organization: {
        __typename?: "Organization";
        id: string;
        name: string;
        companies?: Array<{
          __typename?: "Company";
          id: string;
          name: string;
          vbill_enabled: boolean;
          company_integrations?: Array<{
            __typename?: "CompanyIntegration";
            scopes?: Array<string> | null;
            integration?: { __typename?: "Integration"; integration_id: string; slug?: string | null } | null;
          }> | null;
        }> | null;
        feature_access_requests?: Array<{
          __typename?: "FeatureAccessRequestType";
          feature?: FeatureEnumType | null;
        } | null> | null;
      };
      user_groups?: Array<{ __typename?: "UserGroup"; id?: string | null; name?: string | null }> | null;
    }> | null;
    unconfirmed_bank_accounts?: Array<{
      __typename?: "BankAccountBasic";
      id: string;
      name: string;
      account_number: string;
      routing_number: string;
      bank_name: string;
    }> | null;
  };
};

export type GetSharedFiltersQueryQueryVariables = Exact<{
  token: Scalars["String"]["input"];
}>;

export type GetSharedFiltersQueryQuery = {
  __typename?: "Query";
  GetSharedFiltersQuery?: {
    __typename?: "SharedFiltersType";
    token: string;
    filters: string;
    context: string;
    usages: number;
  } | null;
};

export type SaveSharedFiltersMutationMutationVariables = Exact<{
  organization_id?: InputMaybe<Scalars["String"]["input"]>;
  context: Scalars["String"]["input"];
  filters: Scalars["String"]["input"];
}>;

export type SaveSharedFiltersMutationMutation = { __typename?: "Mutation"; SaveSharedFiltersMutation?: string | null };

export type AccountUpdatePasswordMutationVariables = Exact<{
  password_current: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
  password_confirmation: Scalars["String"]["input"];
}>;

export type AccountUpdatePasswordMutation = { __typename?: "Mutation"; AccountUpdatePassword?: boolean | null };

export type AcceptInviteMutationVariables = Exact<{
  invite_id: Scalars["String"]["input"];
}>;

export type AcceptInviteMutation = { __typename?: "Mutation"; AcceptInvite?: boolean | null };

export type AccountInvitesQueryVariables = Exact<{ [key: string]: never }>;

export type AccountInvitesQuery = {
  __typename?: "Query";
  AccountInvites?: Array<{
    __typename?: "Invite";
    id?: string | null;
    email?: string | null;
    organization_name?: string | null;
    status?: InviteStatusEnum | null;
    inviter?: { __typename?: "BasicAccount"; id: string; name?: string | null; email?: string | null } | null;
  }> | null;
};

export type AccountPhoneNumberVerificationCodeRequestMutationVariables = Exact<{
  phone_number: Scalars["String"]["input"];
  channel: Scalars["String"]["input"];
}>;

export type AccountPhoneNumberVerificationCodeRequestMutation = {
  __typename?: "Mutation";
  AccountPhoneNumberVerificationCodeRequest?: boolean | null;
};

export type AccountPhoneNumberVerificationCodeVerifyMutationVariables = Exact<{
  code: Scalars["String"]["input"];
}>;

export type AccountPhoneNumberVerificationCodeVerifyMutation = {
  __typename?: "Mutation";
  AccountPhoneNumberVerificationCodeVerify?: boolean | null;
};

export type AddUserToGroupMutationVariables = Exact<{
  organization_id: Scalars["String"]["input"];
  organization_user_id: Scalars["String"]["input"];
  user_group_id: Scalars["String"]["input"];
}>;

export type AddUserToGroupMutation = { __typename?: "Mutation"; AddUserToGroup?: boolean | null };

export type CreateUserGroupMutationVariables = Exact<{
  name: Scalars["String"]["input"];
  description?: InputMaybe<Scalars["String"]["input"]>;
  organization_id: Scalars["String"]["input"];
}>;

export type CreateUserGroupMutation = {
  __typename?: "Mutation";
  CreateUserGroup?: {
    __typename?: "UserGroup";
    id?: string | null;
    name?: string | null;
    description?: string | null;
    organization_users?: Array<{
      __typename?: "OrganizationUser";
      id: string;
      organization: { __typename?: "Organization"; id: string };
      account?: { __typename?: "BasicAccount"; id: string } | null;
      acl_permissions?: Array<{ __typename?: "ACLPermission"; id: string }> | null;
      user_groups?: Array<{ __typename?: "UserGroup"; id?: string | null }> | null;
    }> | null;
  } | null;
};

export type DeleteInviteMutationVariables = Exact<{
  invite_id: Scalars["String"]["input"];
}>;

export type DeleteInviteMutation = { __typename?: "Mutation"; DeleteInvite?: boolean | null };

export type DeleteUserGroupMutationVariables = Exact<{
  user_group_id: Scalars["String"]["input"];
}>;

export type DeleteUserGroupMutation = { __typename?: "Mutation"; DeleteUserGroup?: boolean | null };

export type LeaveOrganizationMutationVariables = Exact<{
  organization_user_id: Scalars["String"]["input"];
}>;

export type LeaveOrganizationMutation = { __typename?: "Mutation"; LeaveOrganization?: boolean | null };

export type OrganizationUsersQueryVariables = Exact<{
  organization_id: Scalars["String"]["input"];
}>;

export type OrganizationUsersQuery = {
  __typename?: "Query";
  OrganizationUsers: Array<{
    __typename?: "OrganizationUser";
    id: string;
    state?: OrganizationUserEnum | null;
    invites_ids?: Array<string> | null;
    account?: {
      __typename?: "BasicAccount";
      id: string;
      name?: string | null;
      email?: string | null;
      state?: AccountStateEnum | null;
    } | null;
    user_groups?: Array<{ __typename?: "UserGroup"; id?: string | null; name?: string | null }> | null;
  }>;
};

export type PromoteUnvalidatedUserMutationVariables = Exact<{
  organization_user_id: Scalars["String"]["input"];
}>;

export type PromoteUnvalidatedUserMutation = { __typename?: "Mutation"; PromoteUnvalidatedUser?: boolean | null };

export type RejectInviteMutationVariables = Exact<{
  invite_id: Scalars["String"]["input"];
}>;

export type RejectInviteMutation = { __typename?: "Mutation"; RejectInvite?: boolean | null };

export type RemoveUserFromGroupMutationVariables = Exact<{
  organization_id: Scalars["String"]["input"];
  organization_user_id: Scalars["String"]["input"];
  user_group_id: Scalars["String"]["input"];
}>;

export type RemoveUserFromGroupMutation = { __typename?: "Mutation"; RemoveUserFromGroup?: boolean | null };

export type UpdateUserGroupMutationVariables = Exact<{
  name: Scalars["String"]["input"];
  description?: InputMaybe<Scalars["String"]["input"]>;
  user_group_id: Scalars["String"]["input"];
}>;

export type UpdateUserGroupMutation = {
  __typename?: "Mutation";
  UpdateUserGroup?: {
    __typename?: "UserGroup";
    id?: string | null;
    name?: string | null;
    description?: string | null;
    organization_users?: Array<{
      __typename?: "OrganizationUser";
      id: string;
      organization: { __typename?: "Organization"; id: string; name: string };
      account?: { __typename?: "BasicAccount"; id: string; name?: string | null; email?: string | null } | null;
      acl_permissions?: Array<{ __typename?: "ACLPermission"; id: string; entity_type: string }> | null;
      user_groups?: Array<{
        __typename?: "UserGroup";
        id?: string | null;
        name?: string | null;
        organization_users?: Array<{ __typename?: "OrganizationUser"; id: string }> | null;
      }> | null;
    }> | null;
  } | null;
};

export type UserGroupsQueryVariables = Exact<{
  organization_id: Scalars["String"]["input"];
}>;

export type UserGroupsQuery = {
  __typename?: "Query";
  UserGroups: Array<{
    __typename?: "UserGroup";
    id?: string | null;
    name?: string | null;
    description?: string | null;
    organization_users?: Array<{ __typename?: "OrganizationUser"; id: string }> | null;
  }>;
};

export type CreateCheckNoteMutationVariables = Exact<{
  check_id: Scalars["String"]["input"];
  note?: InputMaybe<Scalars["String"]["input"]>;
  shared?: InputMaybe<Scalars["Boolean"]["input"]>;
  context: Scalars["Int"]["input"];
  attachments?: InputMaybe<Array<InputMaybe<Scalars["Upload"]["input"]>> | InputMaybe<Scalars["Upload"]["input"]>>;
}>;

export type CreateCheckNoteMutation = {
  __typename?: "Mutation";
  CreateCheckNote: {
    __typename?: "Note";
    note?: string | null;
    type: number;
    context: number;
    can_manage: boolean;
    created_at?: string | null;
    attachments?: Array<{
      __typename?: "AttachmentType";
      context?: number | null;
      sort_order?: number | null;
      media?: {
        __typename?: "MediaType";
        id?: string | null;
        name?: string | null;
        path?: string | null;
        filename?: string | null;
        size?: string | null;
        type?: string | null;
      } | null;
    }> | null;
  };
};

export type DepositChecksMutationMutationVariables = Exact<{
  bank_account_id: Scalars["String"]["input"];
  checks: Array<Scalars["String"]["input"]> | Scalars["String"]["input"];
}>;

export type DepositChecksMutationMutation = {
  __typename?: "Mutation";
  DepositChecksMutation?: {
    __typename?: "CheckActionMutationResult";
    successes?: number | null;
    errors: Array<{ __typename?: "CheckActionErrors"; message?: string | null; check_id?: string | null }>;
  } | null;
};

export type UpdateCheckMutationVariables = Exact<{
  id: Scalars["String"]["input"];
  pttoo?: InputMaybe<Scalars["String"]["input"]>;
  memo?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type UpdateCheckMutation = {
  __typename?: "Mutation";
  UpdateCheck?: {
    __typename?: "Check";
    id: string;
    recipient_email?: string | null;
    pttoo?: string | null;
    date?: string | null;
    number?: string | null;
    amount?: number | null;
    memo?: string | null;
    entity_ref?: string | null;
    role?: string | null;
    status?: {
      __typename?: "CheckStatusType";
      status?: string | null;
      state?: CheckStateEnumType | null;
      sub_status?: string | null;
    } | null;
    required_actions?: Array<{
      __typename?: "CheckRequiredActionType";
      action?: string | null;
      description?: string | null;
      type?: CheckRequiredActionTypeEnumType | null;
      key?: CheckRequiredActionActionEnumType | null;
    } | null> | null;
    sender?: {
      __typename?: "BankAccountBasic";
      id: string;
      name: string;
      account_number: string;
      routing_number: string;
      bank_name: string;
    } | null;
    recipient?: {
      __typename?: "BankAccountBasic";
      id: string;
      name: string;
      account_number: string;
      routing_number: string;
      bank_name: string;
    } | null;
    action?: {
      __typename?: "CheckActionType";
      type?: string | null;
      label?: string | null;
      action?: string | null;
      disabled?: boolean | null;
      priority?: number | null;
      message?: string | null;
    } | null;
    options?: Array<{
      __typename?: "CheckActionType";
      type?: string | null;
      label?: string | null;
      action?: string | null;
      disabled?: boolean | null;
      priority?: number | null;
      message?: string | null;
    }> | null;
    related_transactions?: Array<{
      __typename?: "LedgerCheck";
      id: string;
      recipient_email?: string | null;
      pttoo?: string | null;
      date?: string | null;
      number?: string | null;
      amount?: number | null;
      memo?: string | null;
      role?: string | null;
      sender_title?: string | null;
      sender_subtitle?: string | null;
      recipient_title?: string | null;
      recipient_subtitle?: string | null;
      status?: {
        __typename?: "CheckStatusType";
        status?: string | null;
        state?: CheckStateEnumType | null;
        sub_status?: string | null;
      } | null;
      sender?: {
        __typename?: "BankAccountBasic";
        id: string;
        name: string;
        account_number: string;
        routing_number: string;
        bank_name: string;
      } | null;
      recipient?: {
        __typename?: "BankAccountBasic";
        id: string;
        name: string;
        account_number: string;
        routing_number: string;
        bank_name: string;
      } | null;
      action?: {
        __typename?: "CheckActionType";
        type?: string | null;
        label?: string | null;
        action?: string | null;
        disabled?: boolean | null;
      } | null;
      options?: Array<{
        __typename?: "CheckActionType";
        type?: string | null;
        label?: string | null;
        action?: string | null;
        disabled?: boolean | null;
      }> | null;
    }> | null;
    remittance?: Array<{
      __typename?: "RemittanceRecordType";
      date?: string | null;
      amount?: number | null;
      created_at?: string | null;
      meta?: Array<{
        __typename?: "RemittanceRecordMetaType";
        label?: string | null;
        order?: number | null;
        value?: string | null;
      }> | null;
    }> | null;
    meta?: Array<{
      __typename?: "CheckMeta";
      label?: string | null;
      order?: number | null;
      value?: string | null;
    }> | null;
  } | null;
};

export type VCheckCheckQueryQueryVariables = Exact<{
  id: Scalars["String"]["input"];
  bank_account_id?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type VCheckCheckQueryQuery = {
  __typename?: "Query";
  VCheckCheckQuery?: {
    __typename?: "CheckDetailsType";
    other_context_available?: boolean | null;
    other_context_organization_id?: string | null;
    meta?: Array<{
      __typename?: "CheckMeta";
      label?: string | null;
      order?: number | null;
      value?: string | null;
    }> | null;
    ledger_check?: {
      __typename?: "LedgerCheck";
      id: string;
      recipient_email?: string | null;
      pttoo?: string | null;
      date?: string | null;
      number?: string | null;
      amount?: number | null;
      memo?: string | null;
      voided_at?: string | null;
      role?: string | null;
      sender_title?: string | null;
      sender_subtitle?: string | null;
      recipient_title?: string | null;
      recipient_subtitle?: string | null;
      vendor_is_valid?: boolean | null;
      related_by?: string | null;
      check_type?: CheckTypeEnumType | null;
      status?: {
        __typename?: "CheckStatusType";
        status?: string | null;
        state?: CheckStateEnumType | null;
        sub_status?: string | null;
      } | null;
      required_actions?: Array<{
        __typename?: "CheckRequiredActionType";
        action?: string | null;
        description?: string | null;
        type?: CheckRequiredActionTypeEnumType | null;
        key?: CheckRequiredActionActionEnumType | null;
        is_blocker?: boolean | null;
      } | null> | null;
      sender?: {
        __typename?: "BankAccountBasic";
        id: string;
        name: string;
        account_number: string;
        routing_number: string;
        bank_name: string;
        company_id?: string | null;
        company_name?: string | null;
        organization_id?: string | null;
        organization_name?: string | null;
      } | null;
      recipient?: {
        __typename?: "BankAccountBasic";
        id: string;
        name: string;
        account_number: string;
        routing_number: string;
        bank_name: string;
        company_id?: string | null;
        company_name?: string | null;
        organization_id?: string | null;
        organization_name?: string | null;
      } | null;
      action?: {
        __typename?: "CheckActionType";
        type?: string | null;
        label?: string | null;
        action?: string | null;
        disabled?: boolean | null;
        priority?: number | null;
        message?: string | null;
      } | null;
      options?: Array<{
        __typename?: "CheckActionType";
        type?: string | null;
        label?: string | null;
        action?: string | null;
        disabled?: boolean | null;
        priority?: number | null;
        message?: string | null;
      }> | null;
      chat_details?: {
        __typename?: "ChatDetailsType";
        chat_status?: ChatStatusEnumType | null;
        chat_activity?: ChatActivityEnumType | null;
      } | null;
      bank_account_info?: {
        __typename?: "BankAccountInfoType";
        account_number?: string | null;
        routing_number?: string | null;
        bank_name?: string | null;
      } | null;
      global_vendor?: {
        __typename?: "GlobalVendorType";
        name?: string | null;
        email?: string | null;
        address?: string | null;
      } | null;
    } | null;
    remittances?: Array<{
      __typename?: "RemittanceRecordType";
      entity?: string | null;
      entity_ref?: string | null;
      date?: string | null;
      amount?: number | null;
      created_at?: string | null;
      meta?: Array<{
        __typename?: "RemittanceRecordMetaType";
        label?: string | null;
        order?: number | null;
        value?: string | null;
      }> | null;
    }> | null;
    activity?: Array<{
      __typename?: "CheckActivityType";
      id?: string | null;
      title?: string | null;
      note?: string | null;
      type: number;
      context?: number | null;
      can_manage: boolean;
      created_at?: string | null;
      username?: string | null;
      attachments?: Array<{
        __typename?: "AttachmentType";
        context?: number | null;
        sort_order?: number | null;
        show_download?: boolean | null;
        media?: {
          __typename?: "MediaType";
          id?: string | null;
          name?: string | null;
          type?: string | null;
          size?: string | null;
          path?: string | null;
          filename?: string | null;
        } | null;
      }> | null;
    }> | null;
    timeline?: {
      __typename?: "CheckTimelineType";
      events?: Array<{
        __typename?: "CheckTimelineEventType";
        label?: string | null;
        date?: string | null;
        status?: CheckTimelineEventStatusEnumType | null;
        description?: Array<{
          __typename?: "CheckTimelineEventDescriptionType";
          description?: string | null;
          static?: boolean | null;
          url?: string | null;
        } | null> | null;
        flags?: Array<{ __typename?: "FlagWithLabelType"; flag?: boolean | null; label?: string | null } | null> | null;
        images?: Array<{
          __typename?: "ImageWithLabelType";
          id?: string | null;
          label?: string | null;
          name?: string | null;
          path?: string | null;
          type?: string | null;
        } | null> | null;
      } | null> | null;
    } | null;
  } | null;
};

export type VCheckChecksQueryVariables = Exact<{
  organization_id?: InputMaybe<Scalars["String"]["input"]>;
  filters?: InputMaybe<CheckFiltersType>;
  pagination?: InputMaybe<Pagination>;
}>;

export type VCheckChecksQuery = {
  __typename?: "Query";
  VCheckChecks?: {
    __typename?: "VCheckChecksQueryResult";
    checks?: Array<{
      __typename?: "LedgerCheck";
      id: string;
      pttoo?: string | null;
      date?: string | null;
      number?: string | null;
      amount?: number | null;
      memo?: string | null;
      deposited_at?: string | null;
      created_at?: string | null;
      signed_at?: string | null;
      role?: string | null;
      sender_title?: string | null;
      sender_subtitle?: string | null;
      recipient_title?: string | null;
      recipient_subtitle?: string | null;
      recipient_email?: string | null;
      vendor_is_valid?: boolean | null;
      check_type?: CheckTypeEnumType | null;
      status?: {
        __typename?: "CheckStatusType";
        status?: string | null;
        state?: CheckStateEnumType | null;
        sub_status?: string | null;
      } | null;
      required_actions?: Array<{
        __typename?: "CheckRequiredActionType";
        action?: string | null;
        description?: string | null;
        type?: CheckRequiredActionTypeEnumType | null;
        key?: CheckRequiredActionActionEnumType | null;
        is_blocker?: boolean | null;
      } | null> | null;
      sender?: {
        __typename?: "BankAccountBasic";
        id: string;
        name: string;
        account_number: string;
        routing_number: string;
        bank_name: string;
        company_id?: string | null;
        company_name?: string | null;
        organization_id?: string | null;
        organization_name?: string | null;
      } | null;
      recipient?: {
        __typename?: "BankAccountBasic";
        id: string;
        name: string;
        account_number: string;
        routing_number: string;
        bank_name: string;
        company_id?: string | null;
        company_name?: string | null;
        organization_id?: string | null;
        organization_name?: string | null;
      } | null;
      action?: {
        __typename?: "CheckActionType";
        type?: string | null;
        label?: string | null;
        action?: string | null;
        disabled?: boolean | null;
        priority?: number | null;
        message?: string | null;
      } | null;
      options?: Array<{
        __typename?: "CheckActionType";
        type?: string | null;
        label?: string | null;
        action?: string | null;
        disabled?: boolean | null;
        priority?: number | null;
        message?: string | null;
      }> | null;
      chat_details?: {
        __typename?: "ChatDetailsType";
        chat_status?: ChatStatusEnumType | null;
        chat_activity?: ChatActivityEnumType | null;
      } | null;
      bank_account_info?: {
        __typename?: "BankAccountInfoType";
        account_number?: string | null;
        routing_number?: string | null;
        bank_name?: string | null;
      } | null;
      global_vendor?: {
        __typename?: "GlobalVendorType";
        name?: string | null;
        email?: string | null;
        address?: string | null;
      } | null;
    }> | null;
    pagination?: {
      __typename?: "PaginationResult";
      total?: number | null;
      per_page?: number | null;
      on_first_page?: boolean | null;
      current_page?: number | null;
      last_page?: number | null;
      has_pages?: boolean | null;
      has_more_pages?: boolean | null;
      first_item?: number | null;
      last_item?: number | null;
    } | null;
  } | null;
};

export type VCheckRelatedTransactionsQueryVariables = Exact<{
  id: Scalars["String"]["input"];
  bank_account_id?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type VCheckRelatedTransactionsQuery = {
  __typename?: "Query";
  VCheckCheckQuery?: {
    __typename?: "CheckDetailsType";
    related_transactions?: Array<{
      __typename?: "LedgerCheck";
      id: string;
      recipient_email?: string | null;
      pttoo?: string | null;
      date?: string | null;
      number?: string | null;
      amount?: number | null;
      memo?: string | null;
      voided_at?: string | null;
      role?: string | null;
      sender_title?: string | null;
      sender_subtitle?: string | null;
      recipient_title?: string | null;
      recipient_subtitle?: string | null;
      related_by?: string | null;
      status?: {
        __typename?: "CheckStatusType";
        status?: string | null;
        state?: CheckStateEnumType | null;
        sub_status?: string | null;
      } | null;
      required_actions?: Array<{
        __typename?: "CheckRequiredActionType";
        action?: string | null;
        description?: string | null;
        type?: CheckRequiredActionTypeEnumType | null;
        key?: CheckRequiredActionActionEnumType | null;
      } | null> | null;
      sender?: {
        __typename?: "BankAccountBasic";
        id: string;
        name: string;
        account_number: string;
        routing_number: string;
        bank_name: string;
      } | null;
      recipient?: {
        __typename?: "BankAccountBasic";
        id: string;
        name: string;
        account_number: string;
        routing_number: string;
        bank_name: string;
      } | null;
      action?: {
        __typename?: "CheckActionType";
        type?: string | null;
        label?: string | null;
        action?: string | null;
        disabled?: boolean | null;
        priority?: number | null;
        message?: string | null;
      } | null;
      options?: Array<{
        __typename?: "CheckActionType";
        type?: string | null;
        label?: string | null;
        action?: string | null;
        disabled?: boolean | null;
        priority?: number | null;
        message?: string | null;
      }> | null;
    }> | null;
  } | null;
};

export type VCheckUndepositedChecksQueryVariables = Exact<{
  filters?: InputMaybe<VCheckUndepositedChecksFilterInput>;
  pagination?: InputMaybe<Pagination>;
}>;

export type VCheckUndepositedChecksQuery = {
  __typename?: "Query";
  VCheckUndepositedChecks?: {
    __typename?: "VCheckUndepositedChecksQueryResult";
    delay_bank_holiday?: string | null;
    delay_weekend?: string | null;
    checks?: Array<{
      __typename?: "LedgerCheck";
      id: string;
      recipient_email?: string | null;
      pttoo?: string | null;
      date?: string | null;
      number?: string | null;
      amount?: number | null;
      memo?: string | null;
      role?: string | null;
      sender_title?: string | null;
      sender_subtitle?: string | null;
      recipient_title?: string | null;
      recipient_subtitle?: string | null;
      related_by?: string | null;
      status?: {
        __typename?: "CheckStatusType";
        status?: string | null;
        state?: CheckStateEnumType | null;
        sub_status?: string | null;
      } | null;
      required_actions?: Array<{
        __typename?: "CheckRequiredActionType";
        action?: string | null;
        description?: string | null;
        type?: CheckRequiredActionTypeEnumType | null;
        key?: CheckRequiredActionActionEnumType | null;
      } | null> | null;
      sender?: {
        __typename?: "BankAccountBasic";
        id: string;
        name: string;
        account_number: string;
        routing_number: string;
        bank_name: string;
      } | null;
      recipient?: {
        __typename?: "BankAccountBasic";
        id: string;
        name: string;
        account_number: string;
        routing_number: string;
        bank_name: string;
      } | null;
      action?: {
        __typename?: "CheckActionType";
        type?: string | null;
        label?: string | null;
        action?: string | null;
        disabled?: boolean | null;
        priority?: number | null;
        message?: string | null;
      } | null;
      options?: Array<{
        __typename?: "CheckActionType";
        type?: string | null;
        label?: string | null;
        action?: string | null;
        disabled?: boolean | null;
        priority?: number | null;
        message?: string | null;
      }> | null;
    }> | null;
    undeposited_checks?: { __typename?: "VChecksStats"; count?: number | null; sum?: number | null } | null;
    pagination?: {
      __typename?: "PaginationResult";
      total?: number | null;
      per_page?: number | null;
      on_first_page?: boolean | null;
      current_page?: number | null;
      last_page?: number | null;
      has_pages?: boolean | null;
      has_more_pages?: boolean | null;
      first_item?: number | null;
      last_item?: number | null;
    } | null;
  } | null;
};

export type VCheckChecksStatsQueryVariables = Exact<{
  organization_id?: InputMaybe<Scalars["String"]["input"]>;
  filters?: InputMaybe<CheckFiltersType>;
  quick_filters?: InputMaybe<Array<InputMaybe<CheckQuickFiltersEnumType>> | InputMaybe<CheckQuickFiltersEnumType>>;
}>;

export type VCheckChecksStatsQuery = {
  __typename?: "Query";
  VCheckChecks?: {
    __typename?: "VCheckChecksQueryResult";
    quick_filters?: Array<{
      __typename?: "VChecksStats";
      count?: number | null;
      sum?: number | null;
      quick_filter?: string | null;
    } | null> | null;
  } | null;
};

export const AclOrganizationAggregatedLimits = gql`
  query ACLOrganizationAggregatedLimits(
    $organization_id: String!
    $organization_user_id: String
    $user_group_id: String
  ) {
    ACLPermissionAggregatedLimits(
      organization_id: $organization_id
      organization_user_id: $organization_user_id
      user_group_id: $user_group_id
      acl_source: aggregated
    ) {
      BANK_ACCOUNT_LEDGER_ACCESS {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
        limit_ledger_access_max_amount
      }
      BANK_ACCOUNT_CHECK_CREATE {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
        limit_create_vchecks_rules {
          count
          above
        }
      }
      ORGANIZATION_USER_MANAGER {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
      }
      COMPANY_INTEGRATION_MANAGER {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
      }
      ORGANIZATION_COMPANY_MANAGER {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
      }
      BANK_ACCOUNT_CHECK_MANAGE {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
        limit_manage_vchecks_max_amount
      }
      BANK_ACCOUNT_CHECK_SIGNER {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
        limit_approve_vchecks_max_amount
      }
      ORGANIZATION_USER_MANAGER {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
      }
      COMPANY_VIEW {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
      }
      BANK_ACCOUNT_VIEW {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
      }
      BANK_ACCOUNT_MANAGE {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
      }
      COMPANY_VBILL_EDIT_MAP {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
      }
      COMPANY_VBILL_ACCESS {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
      }
      ORGANIZATION_VBILL_COMPANY_MAPPER {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
      }
      COMPANY_VBILL_PAYER {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
      }
    }
  }
`;
export const AclOrganizationDirectLimits = gql`
  query ACLOrganizationDirectLimits($organization_id: String!) {
    ACLPermissionAggregatedLimits(organization_id: $organization_id, acl_source: direct) {
      BANK_ACCOUNT_LEDGER_ACCESS {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
        limit_ledger_access_max_amount
      }
      BANK_ACCOUNT_CHECK_CREATE {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
        limit_create_vchecks_rules {
          count
          above
        }
      }
      ORGANIZATION_USER_MANAGER {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
      }
      COMPANY_INTEGRATION_MANAGER {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
      }
      ORGANIZATION_COMPANY_MANAGER {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
      }
      BANK_ACCOUNT_CHECK_MANAGE {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
        limit_manage_vchecks_max_amount
      }
      BANK_ACCOUNT_CHECK_SIGNER {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
        limit_approve_vchecks_max_amount
      }
      ORGANIZATION_USER_MANAGER {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
      }
      COMPANY_VIEW {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
      }
      BANK_ACCOUNT_VIEW {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
      }
      BANK_ACCOUNT_MANAGE {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
      }
      COMPANY_VBILL_EDIT_MAP {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
      }
      COMPANY_VBILL_ACCESS {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
      }
      ORGANIZATION_VBILL_COMPANY_MAPPER {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
      }
      COMPANY_VBILL_PAYER {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
      }
    }
  }
`;
export const AclOrganizationInheritedLimits = gql`
  query ACLOrganizationInheritedLimits(
    $organization_id: String!
    $organization_user_id: String
    $user_group_id: String
  ) {
    ACLPermissionAggregatedLimits(
      organization_id: $organization_id
      organization_user_id: $organization_user_id
      user_group_id: $user_group_id
      acl_source: inherited
    ) {
      BANK_ACCOUNT_LEDGER_ACCESS {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
        limit_ledger_access_max_amount
      }
      BANK_ACCOUNT_CHECK_CREATE {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
        limit_create_vchecks_rules {
          count
          above
        }
      }
      ORGANIZATION_USER_MANAGER {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
      }
      COMPANY_INTEGRATION_MANAGER {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
      }
      ORGANIZATION_COMPANY_MANAGER {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
      }
      BANK_ACCOUNT_CHECK_MANAGE {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
        limit_manage_vchecks_max_amount
      }
      BANK_ACCOUNT_CHECK_SIGNER {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
        limit_approve_vchecks_max_amount
      }
      ORGANIZATION_USER_MANAGER {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
      }
      COMPANY_VIEW {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
      }
      BANK_ACCOUNT_VIEW {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
      }
      BANK_ACCOUNT_MANAGE {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
      }
      COMPANY_VBILL_EDIT_MAP {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
      }
      COMPANY_VBILL_ACCESS {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
      }
      ORGANIZATION_VBILL_COMPANY_MAPPER {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
      }
      COMPANY_VBILL_PAYER {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
      }
    }
  }
`;
export const AclPermissionAggregatedLimits = gql`
  query ACLPermissionAggregatedLimits($organization_user_id: String!) {
    ACLPermissionAggregatedLimits(organization_user_id: $organization_user_id) {
      BANK_ACCOUNT_LEDGER_ACCESS {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
        limit_ledger_access_max_amount
      }
      BANK_ACCOUNT_CHECK_CREATE {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
        limit_create_vchecks_rules {
          count
          above
        }
      }
      ORGANIZATION_USER_MANAGER {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
      }
      COMPANY_INTEGRATION_MANAGER {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
      }
      ORGANIZATION_COMPANY_MANAGER {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
      }
      BANK_ACCOUNT_CHECK_MANAGE {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
        limit_manage_vchecks_max_amount
      }
      BANK_ACCOUNT_CHECK_SIGNER {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
        limit_approve_vchecks_max_amount
      }
      ORGANIZATION_USER_MANAGER {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
      }
      COMPANY_VIEW {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
      }
      BANK_ACCOUNT_VIEW {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
      }
      BANK_ACCOUNT_MANAGE {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
      }
      COMPANY_VBILL_EDIT_MAP {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
      }
      COMPANY_VBILL_ACCESS {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
      }
      ORGANIZATION_VBILL_COMPANY_MAPPER {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
      }
      COMPANY_VBILL_PAYER {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
      }
    }
  }
`;
export const AclPermissionsOfOrganizationUser = gql`
  query AclPermissionsOfOrganizationUser($organization_user_id: String!) {
    ACLPermissions(filters: { organization_user_id: $organization_user_id }) {
      BANK_ACCOUNT_LEDGER_ACCESS {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
        limit_ledger_access_max_amount
      }
      BANK_ACCOUNT_CHECK_CREATE {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
        limit_create_vchecks_rules {
          count
          above
        }
      }
      ORGANIZATION_USER_MANAGER {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
      }
      COMPANY_INTEGRATION_MANAGER {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
      }
      ORGANIZATION_COMPANY_MANAGER {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
      }
      BANK_ACCOUNT_CHECK_MANAGE {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
        limit_manage_vchecks_max_amount
      }
      BANK_ACCOUNT_CHECK_SIGNER {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
        limit_approve_vchecks_max_amount
      }
      ORGANIZATION_USER_MANAGER {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
      }
      COMPANY_VIEW {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
      }
      BANK_ACCOUNT_VIEW {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
      }
      BANK_ACCOUNT_MANAGE {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
      }
      COMPANY_VBILL_EDIT_MAP {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
      }
      COMPANY_VBILL_ACCESS {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
      }
      ORGANIZATION_VBILL_COMPANY_MAPPER {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
      }
      COMPANY_VBILL_PAYER {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
      }
    }
  }
`;
export const AclPermissionsOfUserGroup = gql`
  query AclPermissionsOfUserGroup($user_group_id: String!) {
    ACLPermissions(filters: { user_group_id: $user_group_id }) {
      BANK_ACCOUNT_LEDGER_ACCESS {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
        limit_ledger_access_max_amount
      }
      BANK_ACCOUNT_CHECK_CREATE {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
        limit_create_vchecks_rules {
          count
          above
        }
      }
      ORGANIZATION_USER_MANAGER {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
      }
      COMPANY_INTEGRATION_MANAGER {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
      }
      ORGANIZATION_COMPANY_MANAGER {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
      }
      BANK_ACCOUNT_CHECK_MANAGE {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
        limit_manage_vchecks_max_amount
      }
      BANK_ACCOUNT_CHECK_SIGNER {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
        limit_approve_vchecks_max_amount
      }
      ORGANIZATION_USER_MANAGER {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
      }
      COMPANY_VIEW {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
      }
      BANK_ACCOUNT_VIEW {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
      }
      BANK_ACCOUNT_MANAGE {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
      }
      COMPANY_VBILL_EDIT_MAP {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
      }
      COMPANY_VBILL_ACCESS {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
      }
      ORGANIZATION_VBILL_COMPANY_MAPPER {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
      }
      COMPANY_VBILL_PAYER {
        organization_user_id
        user_group_id
        entity_type
        entity_id
        acl_source
        orig_entity_type
        orig_entity_id
      }
    }
  }
`;
export const ApplyAcl = gql`
  mutation ApplyACL($acls: PermissionGroupInput) {
    ApplyACL(acls: $acls) {
      success
      error
    }
  }
`;
export const CheckAction = gql`
  mutation CheckAction($organization_id: String, $checks: [String!]!, $action: String!) {
    CheckActionMutation(organization_id: $organization_id, checks: $checks, action: $action) {
      successes
      errors {
        check_id
        message
      }
    }
  }
`;
export const CreateInvite = gql`
  mutation CreateInvite($email: String!, $organization_id: String!) {
    CreateInvite(email: $email, organization_id: $organization_id) {
      id
      organization_user_id
    }
  }
`;
export const OrganizationCompaniesWithBankaccounts = gql`
  query OrganizationCompaniesWithBankaccounts($organization_id: String!) {
    OrganizationDashboard(organization_id: $organization_id) {
      companies {
        organization_id
        id
        name
        bank_accounts {
          id
          name
          account_number
          bank_name
        }
      }
    }
  }
`;
export const OrganizationUserGroups = gql`
  query OrganizationUserGroups($organization_id: String!, $organization_user_id: String!) {
    OrganizationUsers(organization_id: $organization_id, organization_user_id: $organization_user_id) {
      id
      user_groups {
        id
        name
      }
    }
  }
`;
export const PermissionsGroups = gql`
  query PermissionsGroups {
    PermissionsGroups {
      id
      group_name
      internal_alias
      category
      permission_types {
        id
        permission
        display_name
        description
      }
    }
  }
`;
export const UnmapBankAccountGlAccountMapping = gql`
  mutation UnmapBankAccountGlAccountMapping($bank_account_id: String!) {
    UnmapBankAccountGlAccountMapping(bank_account_id: $bank_account_id)
  }
`;
export const UserGroupUsers = gql`
  query UserGroupUsers($organization_id: String!, $user_group_id: String!) {
    UserGroups(organization_id: $organization_id, filters: { user_group_id: $user_group_id }) {
      id
      name
      organization_users {
        id
        account {
          id
          name
          email
          state
        }
      }
    }
  }
`;
export const CreateFeatureAccessRequestMutation = gql`
  mutation CreateFeatureAccessRequestMutation($feature: FeatureEnumType!, $organization_id: String!) {
    CreateFeatureAccessRequestMutation(feature: $feature, organization_id: $organization_id)
  }
`;
export const DelegationGroupResolution = gql`
  query DelegationGroupResolution($organization_id: String!, $bill_id: String, $check_id: String) {
    DelegationGroupResolution(organization_id: $organization_id, bill_id: $bill_id, check_id: $check_id) {
      id
      scope
      type
      requirement_type
      organization_id
      company_id
      vendor_id
      bill_id
      check_id
      is_root
      delegator {
        id
        account {
          id
          name
        }
      }
      approvals_required
      reviews_required
      delegations {
        id
        role
        delegate {
          id
          account {
            name
          }
        }
      }
      labeling_category {
        id
        name
      }
      parent_group_id
      overall_approvals_met
      overall_reviews_met
    }
  }
`;
export const DelegationGroups = gql`
  query DelegationGroups(
    $scope: DelegationGroupScopeEnum!
    $organization_id: String!
    $company_id: String
    $vendor_id: String
    $bill_id: String
    $check_id: String
    $delegator_user_id: String
    $match_any: Boolean
    $is_root: Boolean
  ) {
    DelegationGroups(
      scope: $scope
      organization_id: $organization_id
      company_id: $company_id
      vendor_id: $vendor_id
      bill_id: $bill_id
      check_id: $check_id
      delegator_user_id: $delegator_user_id
      match_any: $match_any
      is_root: $is_root
    ) {
      is_root_delegator
      delegation_groups {
        id
        scope
        type
        name
        requirement_type
        organization_id
        company_id
        vendor_id
        bill_id
        check_id
        is_root
        delegator {
          id
        }
        approvals_required
        reviews_required
        delegations {
          id
          role
          delegate {
            id
            account {
              name
            }
          }
        }
        labeling_category {
          id
          name
        }
      }
    }
  }
`;
export const DeleteDelegationGroup = gql`
  mutation DeleteDelegationGroup(
    $scope: DelegationGroupScopeEnum!
    $organization_id: String!
    $delegation_group_id: String
    $company_id: String
    $vendor_id: String
  ) {
    DeleteDelegationGroup(
      scope: $scope
      organization_id: $organization_id
      delegation_group_id: $delegation_group_id
      company_id: $company_id
      vendor_id: $vendor_id
    )
  }
`;
export const LabelingCategories = gql`
  query LabelingCategories($organization_id: String!, $company_id: String) {
    LabelingCategories(organization_id: $organization_id, company_id: $company_id) {
      id
      name
    }
  }
`;
export const SaveApproval = gql`
  mutation SaveApproval(
    $organization_id: String!
    $bill_id: String
    $check_id: String
    $type: ApprovalTypeEnum!
    $resolution: ApprovalResolutionEnum!
  ) {
    SaveApproval(
      organization_id: $organization_id
      bill_id: $bill_id
      check_id: $check_id
      type: $type
      resolution: $resolution
    )
  }
`;
export const SaveDelegationGroup = gql`
  mutation SaveDelegationGroup(
    $delegation_group_id: String
    $scope: DelegationGroupScopeEnum!
    $type: DelegationGroupTypeEnum!
    $name: String
    $requirement_type: DelegationGroupRequirementTypeEnum!
    $organization_id: String!
    $company_id: String
    $vendor_id: String
    $bill_id: String
    $check_id: String
    $is_root: Boolean!
    $root_delegator_id: String
    $approvals_required: Int!
    $reviews_required: Int!
    $delegations: [InputDelegation!]!
    $labeling_category_id: String
    $entity_context: InputDelegationGroupEntityContext
  ) {
    SaveDelegationGroup(
      delegation_group_id: $delegation_group_id
      scope: $scope
      type: $type
      name: $name
      requirement_type: $requirement_type
      organization_id: $organization_id
      company_id: $company_id
      vendor_id: $vendor_id
      bill_id: $bill_id
      check_id: $check_id
      is_root: $is_root
      root_delegator_id: $root_delegator_id
      approvals_required: $approvals_required
      reviews_required: $reviews_required
      delegations: $delegations
      labeling_category_id: $labeling_category_id
      entity_context: $entity_context
    )
  }
`;
export const SaveLabelingCategory = gql`
  mutation SaveLabelingCategory($organization_id: String!, $company_id: String, $name: String!) {
    SaveLabelingCategory(organization_id: $organization_id, company_id: $company_id, name: $name) {
      id
    }
  }
`;
export const CreateAutoDepositRuleMutation = gql`
  mutation CreateAutoDepositRuleMutation(
    $bank_account_id: String!
    $conditions: ConditionInputGraphQLType!
    $rule_priority: [String]
  ) {
    CreateAutoDepositRuleMutation(
      bank_account_id: $bank_account_id
      conditions: $conditions
      rule_priority: $rule_priority
    ) {
      id
      email_address {
        id
        email
      }
      bank_account {
        id
        name
        account_number
        routing_number
        bank_name
      }
    }
  }
`;
export const DeleteAutoDepositRuleMutation = gql`
  mutation DeleteAutoDepositRuleMutation($auto_deposit_rule_id: String!) {
    DeleteAutoDepositRuleMutation(auto_deposit_rule_id: $auto_deposit_rule_id)
  }
`;
export const GetAutoDepositRulesForEmailAddressQuery = gql`
  query GetAutoDepositRulesForEmailAddressQuery($email_address_id: String!) {
    GetAutoDepositRulesForEmailAddressQuery(email_address_id: $email_address_id) {
      auto_deposit_rules {
        id
        email_address {
          id
          email
        }
        bank_account {
          id
          name
          account_number
          routing_number
          bank_name
        }
        conditions {
          pttoos
          senders
          memos
        }
        organization_name
        organization_id
        company_name
        company_id
      }
      rule_priority
    }
  }
`;
export const UpdateAutoDepositRuleMutation = gql`
  mutation UpdateAutoDepositRuleMutation(
    $auto_deposit_rule_id: String!
    $bank_account_id: String!
    $conditions: ConditionInputGraphQLType!
    $rule_priority: [String]!
  ) {
    UpdateAutoDepositRuleMutation(
      auto_deposit_rule_id: $auto_deposit_rule_id
      bank_account_id: $bank_account_id
      conditions: $conditions
      rule_priority: $rule_priority
    ) {
      id
      email_address {
        id
        email
      }
      bank_account {
        id
        name
        account_number
        routing_number
        bank_name
      }
    }
  }
`;
export const UpdateAutoDepositRulePriorityMutation = gql`
  mutation UpdateAutoDepositRulePriorityMutation($email_address_id: String!, $rule_priority: [String]!) {
    UpdateAutoDepositRulePriorityMutation(email_address_id: $email_address_id, rule_priority: $rule_priority) {
      rule_priority
    }
  }
`;
export const UpdateBankAccount = gql`
  mutation UpdateBankAccount($bank_account_id: String!, $nickname: String) {
    UpdateBankAccount(bank_account_id: $bank_account_id, nickname: $nickname) {
      id
      name
      nickname
      account_number
      routing_number
      bank_name
    }
  }
`;
export const UpdateUnconfirmedBankAccount = gql`
  mutation UpdateUnconfirmedBankAccount($bank_account_id: String!, $action: String!) {
    UpdateUnconfirmedBankAccount(bank_account_id: $bank_account_id, action: $action)
  }
`;
export const CreateCheckBatchItemsMutation = gql`
  mutation CreateCheckBatchItemsMutation($check_batch_id: String!, $checks: [String], $filters: CheckFiltersType) {
    CreateCheckBatchItemsMutation(check_batch_id: $check_batch_id, checks: $checks, filters: $filters) {
      checks_added
      invalid_checks
    }
  }
`;
export const CreateCheckBatchMembersMutation = gql`
  mutation CreateCheckBatchMembersMutation($check_batch_id: String!, $organization_user_ids: [String]) {
    CreateCheckBatchMembersMutation(check_batch_id: $check_batch_id, organization_user_ids: $organization_user_ids) {
      success_ids
    }
  }
`;
export const CreateCheckBatchMutation = gql`
  mutation CreateCheckBatchMutation($company_id: String!, $name: String!, $budget: Float) {
    CreateCheckBatchMutation(company_id: $company_id, name: $name, budget: $budget) {
      id
      name
      budget
      company {
        id
        legal_name
        dba
      }
      state
      created_by_organization_user {
        id
        account {
          id
          name
          email
        }
      }
      created_with
      items {
        rows {
          id
          check {
            id
            number
            date
            amount
            memo
            pttoo
          }
          reactions {
            id
            organization_user_id
            reaction
          }
        }
        pagination {
          total
          per_page
          on_first_page
          current_page
          last_page
          has_pages
          has_more_pages
          first_item
          last_item
        }
      }
      items_amount
      items_count
    }
  }
`;
export const DeleteCheckBatchItemsMutation = gql`
  mutation DeleteCheckBatchItemsMutation($check_batch_id: String!, $checks: [String]!) {
    DeleteCheckBatchItemsMutation(check_batch_id: $check_batch_id, checks: $checks)
  }
`;
export const DeleteCheckBatchMembersMutation = gql`
  mutation DeleteCheckBatchMembersMutation($check_batch_id: String!, $organization_user_ids: [String]) {
    DeleteCheckBatchMembersMutation(check_batch_id: $check_batch_id, organization_user_ids: $organization_user_ids)
  }
`;
export const DeleteCheckBatchMutation = gql`
  mutation DeleteCheckBatchMutation($check_batch_id: String!) {
    DeleteCheckBatchMutation(check_batch_id: $check_batch_id)
  }
`;
export const GetAvailableBankAccountsForBatchQuery = gql`
  query GetAvailableBankAccountsForBatchQuery(
    $check_batch_id: String!
    $pagination: PaginationRequestType
    $search: String
  ) {
    GetAvailableBankAccountsForBatchQuery(check_batch_id: $check_batch_id, pagination: $pagination, search: $search) {
      rows {
        id
        name
        on_current_batch
      }
      pagination {
        total
        per_page
        on_first_page
        current_page
        last_page
        has_pages
        has_more_pages
        first_item
        last_item
      }
    }
  }
`;
export const GetAvailableChecksForBatchQuery = gql`
  query GetAvailableChecksForBatchQuery(
    $check_batch_id: String!
    $filters: CheckFiltersType
    $pagination: PaginationRequestType
  ) {
    GetAvailableChecksForBatchQuery(check_batch_id: $check_batch_id, filters: $filters, pagination: $pagination) {
      rows {
        id
        number
        date
        amount
        memo
        pttoo
        recipient_email
        status {
          status
          state
          sub_status
        }
        required_actions {
          action
          description
          type
          key
        }
        action {
          type
          label
          action
          disabled
          priority
          message
        }
        options {
          type
          label
          action
          disabled
          priority
          message
        }
        sender_title
        sender_subtitle
        recipient_title
        recipient_subtitle
        remittance {
          entity
          entity_ref
          date
          amount
          meta {
            label
            order
            value
          }
          created_at
        }
        activity {
          id
          title
          note
          type
          context
          can_manage
          attachments {
            context
            sort_order
            media {
              id
              name
              type
              size
              path
              filename
            }
          }
          created_at
          username
        }
        may_not_add_to_batch {
          check_batch_id
          message
        }
      }
      pagination {
        total
        per_page
        on_first_page
        current_page
        last_page
        has_pages
        has_more_pages
        first_item
        last_item
      }
    }
  }
`;
export const GetCheckBatchAvailableMembersQuery = gql`
  query GetCheckBatchAvailableMembersQuery(
    $check_batch_id: String!
    $pagination: PaginationRequestType
    $search: String
  ) {
    GetCheckBatchAvailableMembersQuery(check_batch_id: $check_batch_id, pagination: $pagination, search: $search) {
      rows {
        id
        account {
          id
          name
          email
        }
      }
      pagination {
        total
        per_page
        on_first_page
        current_page
        last_page
        has_pages
        has_more_pages
        first_item
        last_item
      }
    }
  }
`;
export const GetCheckBatchMembersQuery = gql`
  query GetCheckBatchMembersQuery($check_batch_id: String!, $pagination: PaginationRequestType, $search: String) {
    GetCheckBatchMembersQuery(check_batch_id: $check_batch_id, pagination: $pagination, search: $search) {
      rows {
        id
        account {
          id
          name
          email
        }
      }
      pagination {
        total
        per_page
        on_first_page
        current_page
        last_page
        has_pages
        has_more_pages
        first_item
        last_item
      }
    }
  }
`;
export const GetCheckBatchQuery = gql`
  query GetCheckBatchQuery($check_batch_id: String!, $filters: CheckFiltersType, $pagination: PaginationRequestType) {
    GetCheckBatchQuery(check_batch_id: $check_batch_id, filters: $filters, pagination: $pagination) {
      id
      name
      budget
      company {
        id
        legal_name
        dba
      }
      state
      created_at
      created_by_organization_user {
        id
        account {
          id
          name
          email
        }
      }
      created_with
      items {
        rows {
          id
          check {
            id
            number
            date
            amount
            memo
            pttoo
            recipient_email
            status {
              status
              state
              sub_status
            }
            required_actions {
              action
              description
              type
              key
            }
            action {
              type
              label
              action
              disabled
              priority
              message
            }
            options {
              type
              label
              action
              disabled
              priority
              message
            }
            sender_title
            sender_subtitle
            recipient_title
            recipient_subtitle
            remittance {
              entity
              entity_ref
              date
              amount
              meta {
                label
                order
                value
              }
              created_at
            }
            activity {
              id
              title
              note
              type
              can_manage
              attachments {
                sort_order
                media {
                  id
                  name
                  type
                  size
                  path
                  filename
                }
              }
              created_at
              username
            }
            may_not_add_to_batch {
              check_batch_id
              message
            }
          }
          created_with
          reactions {
            id
            organization_user_id
            reaction
          }
        }
        pagination {
          total
          per_page
          on_first_page
          current_page
          last_page
          has_pages
          has_more_pages
          first_item
          last_item
        }
      }
      items_amount
      items_count
      unsigned_amount
      unsigned_count
      members_count
      current_user_may_sign
    }
  }
`;
export const GetCheckBatchesQuery = gql`
  query GetCheckBatchesQuery(
    $organization_id: String!
    $filters: GetCheckBatchesFiltersType
    $pagination: PaginationRequestType
  ) {
    GetCheckBatchesQuery(organization_id: $organization_id, filters: $filters, pagination: $pagination) {
      rows {
        id
        name
        budget
        company {
          id
          legal_name
          dba
        }
        state
        created_at
        created_by_organization_user {
          id
          account {
            id
            name
            email
          }
        }
        created_with
        items {
          rows {
            id
            check {
              id
              number
              date
              amount
              memo
              pttoo
            }
            reactions {
              id
              organization_user_id
              reaction
            }
          }
          pagination {
            total
            per_page
            on_first_page
            current_page
            last_page
            has_pages
            has_more_pages
            first_item
            last_item
          }
        }
        items_amount
        items_count
        members_count
      }
      pagination {
        total
        per_page
        on_first_page
        current_page
        last_page
        has_pages
        has_more_pages
        first_item
        last_item
      }
    }
  }
`;
export const GetCheckDetailsQuery = gql`
  query GetCheckDetailsQuery(
    $check_batch_id: String!
    $check_id: String!
    $check_details_scope: CheckDetailsScopeEnum
  ) {
    GetCheckDetailsQuery(
      check_batch_id: $check_batch_id
      check_id: $check_id
      check_details_scope: $check_details_scope
    ) {
      id
      number
      date
      amount
      memo
      pttoo
      recipient_email
      status {
        status
        state
        sub_status
      }
      required_actions {
        action
        description
        type
        key
      }
      action {
        type
        label
        action
        disabled
        priority
        message
      }
      options {
        type
        label
        action
        disabled
        priority
        message
      }
      sender_title
      sender_subtitle
      recipient_title
      recipient_subtitle
      remittance {
        entity
        entity_ref
        date
        amount
        meta {
          label
          order
          value
        }
        created_at
      }
      activity {
        id
        title
        note
        type
        can_manage
        attachments {
          sort_order
          media {
            id
            name
            type
            size
            path
            filename
          }
        }
        created_at
        username
      }
      may_not_add_to_batch {
        check_batch_id
        message
      }
    }
  }
`;
export const SignCheckBatchMutation = gql`
  mutation SignCheckBatchMutation($check_batch_id: String!) {
    SignCheckBatchMutation(check_batch_id: $check_batch_id) {
      checks_available_for_signing
      signed_checks
    }
  }
`;
export const UpdateCheckBatchItemsReactionMutation = gql`
  mutation UpdateCheckBatchItemsReactionMutation($check_batch_item_ids: [String!], $reaction: ReactionEnumType!) {
    UpdateCheckBatchItemsReactionMutation(check_batch_item_ids: $check_batch_item_ids, reaction: $reaction)
  }
`;
export const UpdateCheckBatchMutation = gql`
  mutation UpdateCheckBatchMutation($check_batch_id: String!, $name: String, $budget: Float) {
    UpdateCheckBatchMutation(check_batch_id: $check_batch_id, name: $name, budget: $budget) {
      id
    }
  }
`;
export const BankAccountLinkedAddresses = gql`
  query BankAccountLinkedAddresses($bank_account_id: String!) {
    BankAccountLinkedAddresses(bank_account_id: $bank_account_id) {
      id
      email
      status
      created_by
      created_at
    }
  }
`;
export const BankAccountAutoDepositAddresses = gql`
  query BankAccountAutoDepositAddresses($bank_account_id: String!) {
    BankAccountAutoDepositAddresses(bank_account_id: $bank_account_id) {
      id
      email
      type
      created_by
    }
  }
`;
export const BankAccountAvailableAutoDepositAddresses = gql`
  query BankAccountAvailableAutoDepositAddresses($bankAccountId: String!) {
    BankAccountAvailableAutoDepositAddresses(bank_account_id: $bankAccountId) {
      id
      email
    }
  }
`;
export const CreateBankAccountAutoDepositAddress = gql`
  mutation CreateBankAccountAutoDepositAddress($bankAccountId: String!, $emailAddressId: String!) {
    CreateBankAccountAutoDepositAddress(bank_account_id: $bankAccountId, email_address_id: $emailAddressId) {
      id
      email
      type
      created_by
    }
  }
`;
export const DeleteBankAccountAutoDepositAddress = gql`
  mutation DeleteBankAccountAutoDepositAddress($bankAccountId: String!, $emailAddressId: String!) {
    DeleteBankAccountAutoDepositAddress(bank_account_id: $bankAccountId, email_address_id: $emailAddressId)
  }
`;
export const CreateBankAccountLinkedAddress = gql`
  mutation CreateBankAccountLinkedAddress($bankAccountId: String!, $email: String!) {
    CreateBankAccountLinkedAddress(bank_account_id: $bankAccountId, email_address: $email) {
      id
      email
      verified
      state
      created_at
      created_by
    }
  }
`;
export const DeleteBankAccountLinkedAddress = gql`
  mutation DeleteBankAccountLinkedAddress($addressId: String!) {
    DeleteBankAccountLinkedAddress(linked_address_id: $addressId)
  }
`;
export const CompanyDetails = gql`
  query CompanyDetails($company_id: String!) {
    CompanyDetails(company_id: $company_id) {
      id
      name
      website_url
      vbill_enabled
      vbill_email
      physical_address {
        id
        address
        address2
        city
        state
        zip_code
        country
      }
      phone_number
      company_integrations {
        scopes
        expires_at
        created_at
        last_seen
        integration {
          integration_id
          name
          display_name
          slug
          scopes
          logo
        }
        settings {
          description
          auto_import
          sync_status
          sync_message
          sync_expire
          sync_time
          current_time
        }
        external_auto_signers {
          id
          name
          organization_user_id
        }
      }
    }
  }
`;
export const AssignGroupToCompany = gql`
  mutation AssignGroupToCompany($organization_id: String!, $company_id: String!, $group_id: String) {
    AssignGroupToCompanyMutation(organization_id: $organization_id, company_id: $company_id, group_id: $group_id) {
      id
      label
      company_ids
    }
  }
`;
export const CreateCompanyGroup = gql`
  mutation CreateCompanyGroup($organization_id: String!, $label: String!) {
    CreateCompanyGroupMutation(organization_id: $organization_id, label: $label) {
      id
      label
      company_ids
    }
  }
`;
export const DeleteCompanyGroup = gql`
  mutation DeleteCompanyGroup($organization_id: String!, $group_id: String!) {
    DeleteCompanyGroupMutation(organization_id: $organization_id, group_id: $group_id) {
      id
      label
      company_ids
    }
  }
`;
export const EditCompanyGroup = gql`
  mutation EditCompanyGroup($organization_id: String!, $group_id: String!, $label: String!) {
    EditCompanyGroupMutation(organization_id: $organization_id, group_id: $group_id, label: $label) {
      id
      label
      company_ids
    }
  }
`;
export const GetCompanyGroups = gql`
  query GetCompanyGroups($organization_id: String!) {
    GetCompanyGroupsQuery(organization_id: $organization_id) {
      id
      label
      company_ids
    }
  }
`;
export const Contacts = gql`
  query Contacts($companyId: String!, $pagination: Pagination, $search: String) {
    Contacts(company_id: $companyId, pagination: $pagination, search: $search) {
      pagination {
        total
        per_page
        on_first_page
        current_page
        last_page
        has_pages
        has_more_pages
        first_item
        last_item
      }
      contacts {
        id
        external_ref
        name
        active
        created_at
        address {
          id
          address
          address2
          city
          state
          zip_code
          country
        }
        phone_number {
          id
          number
          type
          description
        }
      }
    }
  }
`;
export const DepositBankAccounts = gql`
  query DepositBankAccounts {
    DepositBankAccounts {
      id
      name
      companies {
        id
        name
        description
        bank_accounts {
          id
          name
          account_number
          routing_number
          bank_name
        }
      }
    }
  }
`;
export const GetFrontendSettingsQuery = gql`
  query GetFrontendSettingsQuery($context: FrontendSettingsContextEnum!, $id: String!) {
    GetFrontendSettingsQuery(context: $context, id: $id)
  }
`;
export const SaveFrontendSettingsMutation = gql`
  mutation SaveFrontendSettingsMutation(
    $context: FrontendSettingsContextEnum!
    $id: String!
    $path: String!
    $value: String!
  ) {
    SaveFrontendSettingsMutation(context: $context, id: $id, path: $path, value: $value)
  }
`;
export const GeneralLedgerAccounts = gql`
  query GeneralLedgerAccounts($companyId: String, $vcard_company_account_id: String, $account_types: [String]) {
    GeneralLedgerAccounts(
      company_id: $companyId
      vcard_company_account_id: $vcard_company_account_id
      account_types: $account_types
      pagination: { per_page: 100000 }
    ) {
      id
      external_ref
      name
      number
      category
      description
      account_type
      account_number
      active
      bank_account {
        id
        bank_name
        name
        account_number
      }
    }
  }
`;
export const SetBankAccountGlAccountMapping = gql`
  mutation setBankAccountGlAccountMapping($bankAccountId: String!, $glAccountId: String!) {
    SetBankAccountGlAccountMapping(bank_account_id: $bankAccountId, gl_account_id: $glAccountId) {
      id
      name
      account_number
      routing_number
      bank_name
      gl_accounts {
        id
        name
        external_ref
        account_number
      }
    }
  }
`;
export const CompanyIntegrations = gql`
  query CompanyIntegrations($companyId: String!) {
    CompanyIntegrations(company_id: $companyId) {
      integrations {
        scopes
        expires_at
        created_at
        last_seen
        integration {
          integration_id
          display_name
          logo
          name
          slug
          scopes
          webhooks {
            url
            scope
          }
        }
        settings {
          description
          auto_import
          sync_status
          sync_message
          sync_expire
          sync_time
          current_time
          pending_checks_count
        }
        external_auto_signers {
          id
          name
          organization_user_id
          company_id
        }
        created_at
      }
      setup_status {
        mapped_organization_users
        mapped_bank_accounts
        unmapped_organization_users
        unmapped_bank_accounts
      }
      bank_accounts {
        id
        company_id
        bank_name
        name
        account_number
        routing_number
        legal_first_name
        legal_last_name
        verification_status
        gl_accounts {
          id
          name
          external_ref
        }
      }
    }
  }
`;
export const GetAllKnownExtSignerNames = gql`
  query GetAllKnownExtSignerNames($company_id: String!) {
    GetAllKnownExtSignerNames(company_id: $company_id)
  }
`;
export const GetIntegrationAuthCode = gql`
  mutation GetIntegrationAuthCode(
    $integrationId: String!
    $companyId: String!
    $integration_settings: IntegrationSettingsInput
  ) {
    GetIntegrationAuthCode(
      integration_id: $integrationId
      company_id: $companyId
      integration_settings: $integration_settings
    )
  }
`;
export const Integrations = gql`
  query Integrations($companyId: String, $slug: String) {
    Integrations(company_id: $companyId, slug: $slug) {
      integration_id
      name
      slug
      display_name
      scopes
      logo
      webhooks {
        url
        scope
      }
    }
  }
`;
export const RevokeAccessToken = gql`
  mutation RevokeAccessToken($companyId: String!, $integrationId: String!) {
    RevokeAccessToken(company_id: $companyId, integration_id: $integrationId)
  }
`;
export const ArchivePendingChecks = gql`
  mutation archivePendingChecks($companyId: String!, $integrationId: String!, $checks: [String]!) {
    ArchivePendingChecks(company_id: $companyId, integration_id: $integrationId, checks: $checks)
  }
`;
export const CreateExternalAutoSigner = gql`
  mutation createExternalAutoSigner(
    $integration_id: String!
    $organization_user_id: String!
    $name: String = ""
    $company_id: String!
  ) {
    CreateExternalAutoSigner(
      integration_id: $integration_id
      organization_user_id: $organization_user_id
      name: $name
      company_id: $company_id
    ) {
      id
      name
      organization_user_id
      company_id
    }
  }
`;
export const DeleteExternalAutoSigner = gql`
  mutation deleteExternalAutoSigner($external_signer_id: String!, $company_id: String!) {
    DeleteExternalAutoSigner(external_signer_id: $external_signer_id, company_id: $company_id)
  }
`;
export const GetPendingChecks = gql`
  query getPendingChecks(
    $companyId: String!
    $integrationId: String!
    $pagination: Pagination
    $checks: [String]
    $filters: PendingChecksFilterInput
  ) {
    PendingChecks(
      company_id: $companyId
      integration_id: $integrationId
      pagination: $pagination
      checks: $checks
      filters: $filters
    ) {
      total
      checks {
        id
        recipient_email
        pttoo
        date
        number
        amount
        memo
        status {
          status
          state
          sub_status
        }
        required_actions {
          action
          description
          type
          key
        }
        entity_ref
        role
        sender {
          id
          name
          account_number
          routing_number
          bank_name
        }
        recipient {
          id
          name
          account_number
          routing_number
          bank_name
        }
        action {
          type
          label
          action
          disabled
        }
        options {
          type
          label
          action
          disabled
        }
        related_transactions {
          id
          recipient_email
          pttoo
          date
          number
          amount
        }
        remittance {
          date
          amount
          created_at
        }
        meta {
          label
          order
          value
        }
        gl_account {
          external_ref
          name
          full_name
          active
          description
        }
        errors {
          type
          message
          data
        }
      }
    }
  }
`;
export const ImportPendingChecks = gql`
  mutation importPendingChecks($companyId: String!, $integrationId: String!, $checks: [ImportPendingChecks]!) {
    ImportPendingChecks(company_id: $companyId, integration_id: $integrationId, checks: $checks) {
      entity_ref
      success
      errors {
        message
      }
    }
  }
`;
export const SetIntegrationConfiguration = gql`
  mutation setIntegrationConfiguration(
    $integrationId: String!
    $companyId: String!
    $configuration: [IntegrationConfigurationInput]!
  ) {
    IntegrationConfiguration(
      integration_id: $integrationId
      company_id: $companyId
      integration_configuration: $configuration
    )
  }
`;
export const UpdateExternalAutoSigner = gql`
  mutation updateExternalAutoSigner($external_signer_id: String!, $company_id: String!, $name: String!) {
    UpdateExternalAutoSigner(external_signer_id: $external_signer_id, company_id: $company_id, name: $name) {
      id
      name
      organization_user_id
    }
  }
`;
export const GetNotifications = gql`
  query GetNotifications {
    GetNotifications {
      notifications {
        id
        account_id
        viewed
        title
        short_desc
        long_desc
        level
        action_url
        channel
        option_1_title
        option_1_url
        option_2_title
        option_2_url
        company_id
        created_at
      }
      unread_count
    }
  }
`;
export const UpdateNotification = gql`
  mutation UpdateNotification($notification_id: String!) {
    UpdateNotification(notification_id: $notification_id)
  }
`;
export const OrganizationDashboard = gql`
  query OrganizationDashboard(
    $organization_id: String
    $pagination: Pagination
    $filters: OrganizationDashboardFilterInput
    $get_unvalidated_dashboard: Boolean
  ) {
    OrganizationDashboard(
      organization_id: $organization_id
      pagination: $pagination
      filters: $filters
      get_unvalidated_dashboard: $get_unvalidated_dashboard
    ) {
      check_batching_enabled
      vbill_enabled
      vbill_email
      companies {
        organization_id
        id
        name
        description
        verification_status
        verification_link
        user_subscribed_to_company_notifications
        vbill_enabled
        vbill_email
        bank_accounts {
          id
          name
          nickname
          account_number
          routing_number
          deposit_only
          verification_status
          verification_link
          can_upgrade
          outgoing_checks_stats {
            count
            sum
          }
          incoming_checks_stats {
            count
            sum
          }
          pending_approval_checks_stats {
            count
            sum
          }
        }
      }
      unvalidated_dashboard {
        companies {
          organization_id
          id
          name
          description
          verification_status
          verification_link
          user_subscribed_to_company_notifications
          vbill_enabled
          vbill_email
          bank_accounts {
            id
            name
            nickname
            account_number
            routing_number
            deposit_only
            verification_status
            verification_link
            can_upgrade
            outgoing_checks_stats {
              count
              sum
            }
            incoming_checks_stats {
              count
              sum
            }
            pending_approval_checks_stats {
              count
              sum
            }
          }
        }
        pagination {
          total
          per_page
          on_first_page
          current_page
          last_page
          has_pages
          has_more_pages
          first_item
          last_item
        }
      }
      pagination {
        total
        per_page
        on_first_page
        current_page
        last_page
        has_pages
        has_more_pages
        first_item
        last_item
      }
    }
  }
`;
export const PendingActionsAndChecksCount = gql`
  query PendingActionsAndChecksCount(
    $organization_id: String
    $pagination: Pagination
    $filters: OrganizationDashboardFilterInput
  ) {
    OrganizationDashboard(organization_id: $organization_id, pagination: $pagination, filters: $filters) {
      pending_actions {
        company_name
        bank_account_name
        bank_account_account_number
        verification_link
      }
      companies_pending_checks_count {
        organization_id
        id
        name
        pending_checks_count
      }
    }
  }
`;
export const OrganizationStructure = gql`
  query OrganizationStructure($organization_id: String) {
    OrganizationDashboard(organization_id: $organization_id) {
      companies {
        organization_id
        id
        name
        description
        vbill_enabled
        vbill_email
        bank_accounts {
          id
          name
          nickname
          bank_name
          account_number
          deposit_only
          verification_status
          verification_link
        }
      }
    }
  }
`;
export const UpdateCompanyNotificationSubscription = gql`
  mutation UpdateCompanyNotificationSubscription($company_id: String!, $email_notification: Boolean!) {
    UpdateCompanyNotificationSubscription(company_id: $company_id, email_notification: $email_notification) {
      id
      company_id
      account_id
      email_notification
    }
  }
`;
export const UpdateOrganization = gql`
  mutation UpdateOrganization($organizationId: String!, $name: String) {
    UpdateOrganization(organization_id: $organizationId, name: $name) {
      id
      name
    }
  }
`;
export const Ping = gql`
  query Ping {
    Ping
  }
`;
export const SessionAccount = gql`
  query SessionAccount {
    SessionAccount {
      account {
        id
        username
        name
        email
        email_address_id
        phone_number
        email_verified
        phone_number_verified
      }
      deposit_accounts
      organizationUsers {
        id
        organization {
          id
          name
          companies {
            id
            name
            vbill_enabled
            company_integrations {
              scopes
              integration {
                integration_id
                slug
              }
            }
          }
          feature_access_requests {
            feature
          }
        }
        user_groups {
          id
          name
        }
      }
      unconfirmed_bank_accounts {
        id
        name
        account_number
        routing_number
        bank_name
      }
      has_in_progress_onboarding
      has_pending_invites
      organizations_where_is_manager
    }
  }
`;
export const GetSharedFiltersQuery = gql`
  query GetSharedFiltersQuery($token: String!) {
    GetSharedFiltersQuery(token: $token) {
      token
      filters
      context
      usages
    }
  }
`;
export const SaveSharedFiltersMutation = gql`
  mutation SaveSharedFiltersMutation($organization_id: String, $context: String!, $filters: String!) {
    SaveSharedFiltersMutation(organization_id: $organization_id, context: $context, filters: $filters)
  }
`;
export const AccountUpdatePassword = gql`
  mutation AccountUpdatePassword($password_current: String!, $password: String!, $password_confirmation: String!) {
    AccountUpdatePassword(
      password_current: $password_current
      password: $password
      password_confirmation: $password_confirmation
    )
  }
`;
export const AcceptInvite = gql`
  mutation AcceptInvite($invite_id: String!) {
    AcceptInvite(invite_id: $invite_id)
  }
`;
export const AccountInvites = gql`
  query AccountInvites {
    AccountInvites {
      id
      email
      organization_name
      inviter {
        id
        name
        email
      }
      status
    }
  }
`;
export const AccountPhoneNumberVerificationCodeRequest = gql`
  mutation AccountPhoneNumberVerificationCodeRequest($phone_number: String!, $channel: String!) {
    AccountPhoneNumberVerificationCodeRequest(phone_number: $phone_number, channel: $channel)
  }
`;
export const AccountPhoneNumberVerificationCodeVerify = gql`
  mutation AccountPhoneNumberVerificationCodeVerify($code: String!) {
    AccountPhoneNumberVerificationCodeVerify(code: $code)
  }
`;
export const AddUserToGroup = gql`
  mutation AddUserToGroup($organization_id: String!, $organization_user_id: String!, $user_group_id: String!) {
    AddUserToGroup(
      organization_id: $organization_id
      organization_user_id: $organization_user_id
      user_group_id: $user_group_id
    )
  }
`;
export const CreateUserGroup = gql`
  mutation CreateUserGroup($name: String!, $description: String, $organization_id: String!) {
    CreateUserGroup(name: $name, description: $description, organization_id: $organization_id) {
      id
      name
      description
      organization_users {
        id
        organization {
          id
        }
        account {
          id
        }
        acl_permissions {
          id
        }
        user_groups {
          id
        }
      }
    }
  }
`;
export const DeleteInvite = gql`
  mutation DeleteInvite($invite_id: String!) {
    DeleteInvite(invite_id: $invite_id)
  }
`;
export const DeleteUserGroup = gql`
  mutation DeleteUserGroup($user_group_id: String!) {
    DeleteUserGroup(user_group_id: $user_group_id)
  }
`;
export const LeaveOrganization = gql`
  mutation LeaveOrganization($organization_user_id: String!) {
    LeaveOrganization(organization_user_id: $organization_user_id)
  }
`;
export const OrganizationUsers = gql`
  query OrganizationUsers($organization_id: String!) {
    OrganizationUsers(organization_id: $organization_id) {
      id
      state
      account {
        id
        name
        email
        state
      }
      user_groups {
        id
        name
      }
      invites_ids
    }
  }
`;
export const PromoteUnvalidatedUser = gql`
  mutation PromoteUnvalidatedUser($organization_user_id: String!) {
    PromoteUnvalidatedUser(organization_user_id: $organization_user_id)
  }
`;
export const RejectInvite = gql`
  mutation RejectInvite($invite_id: String!) {
    RejectInvite(invite_id: $invite_id)
  }
`;
export const RemoveUserFromGroup = gql`
  mutation RemoveUserFromGroup($organization_id: String!, $organization_user_id: String!, $user_group_id: String!) {
    RemoveUserFromGroup(
      organization_id: $organization_id
      organization_user_id: $organization_user_id
      user_group_id: $user_group_id
    )
  }
`;
export const UpdateUserGroup = gql`
  mutation UpdateUserGroup($name: String!, $description: String, $user_group_id: String!) {
    UpdateUserGroup(name: $name, description: $description, user_group_id: $user_group_id) {
      id
      name
      description
      organization_users {
        id
        organization {
          id
          name
        }
        account {
          id
          name
          email
        }
        acl_permissions {
          id
          entity_type
        }
        user_groups {
          id
          name
          organization_users {
            id
          }
        }
      }
    }
  }
`;
export const UserGroups = gql`
  query UserGroups($organization_id: String!) {
    UserGroups(organization_id: $organization_id) {
      id
      name
      description
      organization_users {
        id
      }
    }
  }
`;
export const CreateCheckNote = gql`
  mutation CreateCheckNote(
    $check_id: String!
    $note: String
    $shared: Boolean
    $context: Int!
    $attachments: [Upload]
  ) {
    CreateCheckNote(check_id: $check_id, note: $note, shared: $shared, context: $context, attachments: $attachments) {
      note
      type
      context
      can_manage
      attachments {
        context
        sort_order
        media {
          id
          name
          path
          filename
          size
          type
        }
      }
      created_at
    }
  }
`;
export const DepositChecksMutation = gql`
  mutation DepositChecksMutation($bank_account_id: String!, $checks: [String!]!) {
    DepositChecksMutation(bank_account_id: $bank_account_id, checks: $checks) {
      successes
      errors {
        message
        check_id
      }
    }
  }
`;
export const UpdateCheck = gql`
  mutation UpdateCheck($id: String!, $pttoo: String, $memo: String) {
    UpdateCheck(id: $id, pttoo: $pttoo, memo: $memo) {
      id
      recipient_email
      pttoo
      date
      number
      amount
      memo
      status {
        status
        state
        sub_status
      }
      required_actions {
        action
        description
        type
        key
      }
      entity_ref
      role
      sender {
        id
        name
        account_number
        routing_number
        bank_name
      }
      recipient {
        id
        name
        account_number
        routing_number
        bank_name
      }
      action {
        type
        label
        action
        disabled
        priority
        message
      }
      options {
        type
        label
        action
        disabled
        priority
        message
      }
      related_transactions {
        id
        recipient_email
        pttoo
        date
        number
        amount
        memo
        status {
          status
          state
          sub_status
        }
        role
        sender {
          id
          name
          account_number
          routing_number
          bank_name
        }
        sender_title
        sender_subtitle
        recipient {
          id
          name
          account_number
          routing_number
          bank_name
        }
        recipient_title
        recipient_subtitle
        action {
          type
          label
          action
          disabled
        }
        options {
          type
          label
          action
          disabled
        }
      }
      remittance {
        date
        amount
        created_at
        meta {
          label
          order
          value
        }
      }
      meta {
        label
        order
        value
      }
    }
  }
`;
export const VCheckCheckQuery = gql`
  query VCheckCheckQuery($id: String!, $bank_account_id: String) {
    VCheckCheckQuery(id: $id, bank_account_id: $bank_account_id) {
      meta {
        label
        order
        value
      }
      ledger_check {
        id
        recipient_email
        pttoo
        date
        number
        amount
        memo
        voided_at
        status {
          status
          state
          sub_status
        }
        required_actions {
          action
          description
          type
          key
          is_blocker
        }
        role
        sender {
          id
          name
          account_number
          routing_number
          bank_name
          company_id
          company_name
          organization_id
          organization_name
        }
        sender_title
        sender_subtitle
        recipient {
          id
          name
          account_number
          routing_number
          bank_name
          company_id
          company_name
          organization_id
          organization_name
        }
        recipient_title
        recipient_subtitle
        vendor_is_valid
        action {
          type
          label
          action
          disabled
          priority
          message
        }
        options {
          type
          label
          action
          disabled
          priority
          message
        }
        related_by
        chat_details {
          chat_status
          chat_activity
        }
        bank_account_info {
          account_number
          routing_number
          bank_name
        }
        global_vendor {
          name
          email
          address
        }
        check_type
      }
      remittances {
        entity
        entity_ref
        date
        amount
        meta {
          label
          order
          value
        }
        created_at
      }
      activity {
        id
        title
        note
        type
        context
        can_manage
        attachments {
          context
          sort_order
          show_download
          media {
            id
            name
            type
            size
            path
            filename
          }
        }
        created_at
        username
      }
      timeline {
        events {
          label
          date
          status
          description {
            description
            static
            url
          }
          flags {
            flag
            label
          }
          images {
            id
            label
            name
            path
            type
          }
        }
      }
      other_context_available
      other_context_organization_id
    }
  }
`;
export const VCheckChecks = gql`
  query VCheckChecks($organization_id: String, $filters: CheckFiltersType, $pagination: Pagination) {
    VCheckChecks(organization_id: $organization_id, filters: $filters, pagination: $pagination) {
      checks {
        id
        pttoo
        date
        number
        amount
        memo
        status {
          status
          state
          sub_status
        }
        required_actions {
          action
          description
          type
          key
          is_blocker
        }
        deposited_at
        created_at
        signed_at
        role
        sender {
          id
          name
          account_number
          routing_number
          bank_name
          company_id
          company_name
          organization_id
          organization_name
        }
        recipient {
          id
          name
          account_number
          routing_number
          bank_name
          company_id
          company_name
          organization_id
          organization_name
        }
        action {
          type
          label
          action
          disabled
          priority
          message
        }
        options {
          type
          label
          action
          disabled
          priority
          message
        }
        sender_title
        sender_subtitle
        recipient_title
        recipient_subtitle
        recipient_email
        vendor_is_valid
        chat_details {
          chat_status
          chat_activity
        }
        bank_account_info {
          account_number
          routing_number
          bank_name
        }
        global_vendor {
          name
          email
          address
        }
        check_type
      }
      pagination {
        total
        per_page
        on_first_page
        current_page
        last_page
        has_pages
        has_more_pages
        first_item
        last_item
      }
    }
  }
`;
export const VCheckRelatedTransactions = gql`
  query VCheckRelatedTransactions($id: String!, $bank_account_id: String) {
    VCheckCheckQuery(id: $id, bank_account_id: $bank_account_id) {
      related_transactions {
        id
        recipient_email
        pttoo
        date
        number
        amount
        memo
        voided_at
        status {
          status
          state
          sub_status
        }
        required_actions {
          action
          description
          type
          key
        }
        role
        sender {
          id
          name
          account_number
          routing_number
          bank_name
        }
        sender_title
        sender_subtitle
        recipient {
          id
          name
          account_number
          routing_number
          bank_name
        }
        recipient_title
        recipient_subtitle
        action {
          type
          label
          action
          disabled
          priority
          message
        }
        options {
          type
          label
          action
          disabled
          priority
          message
        }
        related_by
      }
    }
  }
`;
export const VCheckUndepositedChecks = gql`
  query VCheckUndepositedChecks($filters: VCheckUndepositedChecksFilterInput, $pagination: Pagination) {
    VCheckUndepositedChecks(filters: $filters, pagination: $pagination) {
      checks {
        id
        recipient_email
        pttoo
        date
        number
        amount
        memo
        status {
          status
          state
          sub_status
        }
        required_actions {
          action
          description
          type
          key
        }
        role
        sender {
          id
          name
          account_number
          routing_number
          bank_name
        }
        recipient {
          id
          name
          account_number
          routing_number
          bank_name
        }
        action {
          type
          label
          action
          disabled
          priority
          message
        }
        options {
          type
          label
          action
          disabled
          priority
          message
        }
        sender_title
        sender_subtitle
        recipient_title
        recipient_subtitle
        related_by
      }
      undeposited_checks {
        count
        sum
      }
      delay_bank_holiday
      delay_weekend
      pagination {
        total
        per_page
        on_first_page
        current_page
        last_page
        has_pages
        has_more_pages
        first_item
        last_item
      }
    }
  }
`;
export const VCheckChecksStats = gql`
  query VCheckChecksStats(
    $organization_id: String
    $filters: CheckFiltersType
    $quick_filters: [CheckQuickFiltersEnumType]
  ) {
    VCheckChecks(organization_id: $organization_id, filters: $filters, quick_filters: $quick_filters) {
      quick_filters {
        count
        sum
        quick_filter
      }
    }
  }
`;

export const AclOrganizationAggregatedLimitsDocument = `
    query ACLOrganizationAggregatedLimits($organization_id: String!, $organization_user_id: String, $user_group_id: String) {
  ACLPermissionAggregatedLimits(
    organization_id: $organization_id
    organization_user_id: $organization_user_id
    user_group_id: $user_group_id
    acl_source: aggregated
  ) {
    BANK_ACCOUNT_LEDGER_ACCESS {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
      limit_ledger_access_max_amount
    }
    BANK_ACCOUNT_CHECK_CREATE {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
      limit_create_vchecks_rules {
        count
        above
      }
    }
    ORGANIZATION_USER_MANAGER {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
    }
    COMPANY_INTEGRATION_MANAGER {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
    }
    ORGANIZATION_COMPANY_MANAGER {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
    }
    BANK_ACCOUNT_CHECK_MANAGE {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
      limit_manage_vchecks_max_amount
    }
    BANK_ACCOUNT_CHECK_SIGNER {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
      limit_approve_vchecks_max_amount
    }
    ORGANIZATION_USER_MANAGER {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
    }
    COMPANY_VIEW {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
    }
    BANK_ACCOUNT_VIEW {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
    }
    BANK_ACCOUNT_MANAGE {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
    }
    COMPANY_VBILL_EDIT_MAP {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
    }
    COMPANY_VBILL_ACCESS {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
    }
    ORGANIZATION_VBILL_COMPANY_MAPPER {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
    }
    COMPANY_VBILL_PAYER {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
    }
  }
}
    `;

export const useAclOrganizationAggregatedLimitsQuery = <TData = AclOrganizationAggregatedLimitsQuery, TError = unknown>(
  client: GraphQLClient,
  variables: AclOrganizationAggregatedLimitsQueryVariables,
  options?: UseQueryOptions<AclOrganizationAggregatedLimitsQuery, TError, TData>,
  headers?: RequestInit["headers"],
) => {
  return useQuery<AclOrganizationAggregatedLimitsQuery, TError, TData>(
    ["ACLOrganizationAggregatedLimits", variables],
    fetcher<AclOrganizationAggregatedLimitsQuery, AclOrganizationAggregatedLimitsQueryVariables>(
      client,
      AclOrganizationAggregatedLimitsDocument,
      variables,
      headers,
    ),
    options,
  );
};

export const AclOrganizationDirectLimitsDocument = `
    query ACLOrganizationDirectLimits($organization_id: String!) {
  ACLPermissionAggregatedLimits(
    organization_id: $organization_id
    acl_source: direct
  ) {
    BANK_ACCOUNT_LEDGER_ACCESS {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
      limit_ledger_access_max_amount
    }
    BANK_ACCOUNT_CHECK_CREATE {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
      limit_create_vchecks_rules {
        count
        above
      }
    }
    ORGANIZATION_USER_MANAGER {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
    }
    COMPANY_INTEGRATION_MANAGER {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
    }
    ORGANIZATION_COMPANY_MANAGER {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
    }
    BANK_ACCOUNT_CHECK_MANAGE {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
      limit_manage_vchecks_max_amount
    }
    BANK_ACCOUNT_CHECK_SIGNER {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
      limit_approve_vchecks_max_amount
    }
    ORGANIZATION_USER_MANAGER {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
    }
    COMPANY_VIEW {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
    }
    BANK_ACCOUNT_VIEW {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
    }
    BANK_ACCOUNT_MANAGE {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
    }
    COMPANY_VBILL_EDIT_MAP {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
    }
    COMPANY_VBILL_ACCESS {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
    }
    ORGANIZATION_VBILL_COMPANY_MAPPER {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
    }
    COMPANY_VBILL_PAYER {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
    }
  }
}
    `;

export const useAclOrganizationDirectLimitsQuery = <TData = AclOrganizationDirectLimitsQuery, TError = unknown>(
  client: GraphQLClient,
  variables: AclOrganizationDirectLimitsQueryVariables,
  options?: UseQueryOptions<AclOrganizationDirectLimitsQuery, TError, TData>,
  headers?: RequestInit["headers"],
) => {
  return useQuery<AclOrganizationDirectLimitsQuery, TError, TData>(
    ["ACLOrganizationDirectLimits", variables],
    fetcher<AclOrganizationDirectLimitsQuery, AclOrganizationDirectLimitsQueryVariables>(
      client,
      AclOrganizationDirectLimitsDocument,
      variables,
      headers,
    ),
    options,
  );
};

export const AclOrganizationInheritedLimitsDocument = `
    query ACLOrganizationInheritedLimits($organization_id: String!, $organization_user_id: String, $user_group_id: String) {
  ACLPermissionAggregatedLimits(
    organization_id: $organization_id
    organization_user_id: $organization_user_id
    user_group_id: $user_group_id
    acl_source: inherited
  ) {
    BANK_ACCOUNT_LEDGER_ACCESS {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
      limit_ledger_access_max_amount
    }
    BANK_ACCOUNT_CHECK_CREATE {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
      limit_create_vchecks_rules {
        count
        above
      }
    }
    ORGANIZATION_USER_MANAGER {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
    }
    COMPANY_INTEGRATION_MANAGER {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
    }
    ORGANIZATION_COMPANY_MANAGER {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
    }
    BANK_ACCOUNT_CHECK_MANAGE {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
      limit_manage_vchecks_max_amount
    }
    BANK_ACCOUNT_CHECK_SIGNER {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
      limit_approve_vchecks_max_amount
    }
    ORGANIZATION_USER_MANAGER {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
    }
    COMPANY_VIEW {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
    }
    BANK_ACCOUNT_VIEW {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
    }
    BANK_ACCOUNT_MANAGE {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
    }
    COMPANY_VBILL_EDIT_MAP {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
    }
    COMPANY_VBILL_ACCESS {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
    }
    ORGANIZATION_VBILL_COMPANY_MAPPER {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
    }
    COMPANY_VBILL_PAYER {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
    }
  }
}
    `;

export const useAclOrganizationInheritedLimitsQuery = <TData = AclOrganizationInheritedLimitsQuery, TError = unknown>(
  client: GraphQLClient,
  variables: AclOrganizationInheritedLimitsQueryVariables,
  options?: UseQueryOptions<AclOrganizationInheritedLimitsQuery, TError, TData>,
  headers?: RequestInit["headers"],
) => {
  return useQuery<AclOrganizationInheritedLimitsQuery, TError, TData>(
    ["ACLOrganizationInheritedLimits", variables],
    fetcher<AclOrganizationInheritedLimitsQuery, AclOrganizationInheritedLimitsQueryVariables>(
      client,
      AclOrganizationInheritedLimitsDocument,
      variables,
      headers,
    ),
    options,
  );
};

export const AclPermissionAggregatedLimitsDocument = `
    query ACLPermissionAggregatedLimits($organization_user_id: String!) {
  ACLPermissionAggregatedLimits(organization_user_id: $organization_user_id) {
    BANK_ACCOUNT_LEDGER_ACCESS {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
      limit_ledger_access_max_amount
    }
    BANK_ACCOUNT_CHECK_CREATE {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
      limit_create_vchecks_rules {
        count
        above
      }
    }
    ORGANIZATION_USER_MANAGER {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
    }
    COMPANY_INTEGRATION_MANAGER {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
    }
    ORGANIZATION_COMPANY_MANAGER {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
    }
    BANK_ACCOUNT_CHECK_MANAGE {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
      limit_manage_vchecks_max_amount
    }
    BANK_ACCOUNT_CHECK_SIGNER {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
      limit_approve_vchecks_max_amount
    }
    ORGANIZATION_USER_MANAGER {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
    }
    COMPANY_VIEW {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
    }
    BANK_ACCOUNT_VIEW {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
    }
    BANK_ACCOUNT_MANAGE {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
    }
    COMPANY_VBILL_EDIT_MAP {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
    }
    COMPANY_VBILL_ACCESS {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
    }
    ORGANIZATION_VBILL_COMPANY_MAPPER {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
    }
    COMPANY_VBILL_PAYER {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
    }
  }
}
    `;

export const useAclPermissionAggregatedLimitsQuery = <TData = AclPermissionAggregatedLimitsQuery, TError = unknown>(
  client: GraphQLClient,
  variables: AclPermissionAggregatedLimitsQueryVariables,
  options?: UseQueryOptions<AclPermissionAggregatedLimitsQuery, TError, TData>,
  headers?: RequestInit["headers"],
) => {
  return useQuery<AclPermissionAggregatedLimitsQuery, TError, TData>(
    ["ACLPermissionAggregatedLimits", variables],
    fetcher<AclPermissionAggregatedLimitsQuery, AclPermissionAggregatedLimitsQueryVariables>(
      client,
      AclPermissionAggregatedLimitsDocument,
      variables,
      headers,
    ),
    options,
  );
};

export const AclPermissionsOfOrganizationUserDocument = `
    query AclPermissionsOfOrganizationUser($organization_user_id: String!) {
  ACLPermissions(filters: {organization_user_id: $organization_user_id}) {
    BANK_ACCOUNT_LEDGER_ACCESS {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
      limit_ledger_access_max_amount
    }
    BANK_ACCOUNT_CHECK_CREATE {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
      limit_create_vchecks_rules {
        count
        above
      }
    }
    ORGANIZATION_USER_MANAGER {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
    }
    COMPANY_INTEGRATION_MANAGER {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
    }
    ORGANIZATION_COMPANY_MANAGER {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
    }
    BANK_ACCOUNT_CHECK_MANAGE {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
      limit_manage_vchecks_max_amount
    }
    BANK_ACCOUNT_CHECK_SIGNER {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
      limit_approve_vchecks_max_amount
    }
    ORGANIZATION_USER_MANAGER {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
    }
    COMPANY_VIEW {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
    }
    BANK_ACCOUNT_VIEW {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
    }
    BANK_ACCOUNT_MANAGE {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
    }
    COMPANY_VBILL_EDIT_MAP {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
    }
    COMPANY_VBILL_ACCESS {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
    }
    ORGANIZATION_VBILL_COMPANY_MAPPER {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
    }
    COMPANY_VBILL_PAYER {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
    }
  }
}
    `;

export const useAclPermissionsOfOrganizationUserQuery = <
  TData = AclPermissionsOfOrganizationUserQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables: AclPermissionsOfOrganizationUserQueryVariables,
  options?: UseQueryOptions<AclPermissionsOfOrganizationUserQuery, TError, TData>,
  headers?: RequestInit["headers"],
) => {
  return useQuery<AclPermissionsOfOrganizationUserQuery, TError, TData>(
    ["AclPermissionsOfOrganizationUser", variables],
    fetcher<AclPermissionsOfOrganizationUserQuery, AclPermissionsOfOrganizationUserQueryVariables>(
      client,
      AclPermissionsOfOrganizationUserDocument,
      variables,
      headers,
    ),
    options,
  );
};

export const AclPermissionsOfUserGroupDocument = `
    query AclPermissionsOfUserGroup($user_group_id: String!) {
  ACLPermissions(filters: {user_group_id: $user_group_id}) {
    BANK_ACCOUNT_LEDGER_ACCESS {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
      limit_ledger_access_max_amount
    }
    BANK_ACCOUNT_CHECK_CREATE {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
      limit_create_vchecks_rules {
        count
        above
      }
    }
    ORGANIZATION_USER_MANAGER {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
    }
    COMPANY_INTEGRATION_MANAGER {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
    }
    ORGANIZATION_COMPANY_MANAGER {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
    }
    BANK_ACCOUNT_CHECK_MANAGE {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
      limit_manage_vchecks_max_amount
    }
    BANK_ACCOUNT_CHECK_SIGNER {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
      limit_approve_vchecks_max_amount
    }
    ORGANIZATION_USER_MANAGER {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
    }
    COMPANY_VIEW {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
    }
    BANK_ACCOUNT_VIEW {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
    }
    BANK_ACCOUNT_MANAGE {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
    }
    COMPANY_VBILL_EDIT_MAP {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
    }
    COMPANY_VBILL_ACCESS {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
    }
    ORGANIZATION_VBILL_COMPANY_MAPPER {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
    }
    COMPANY_VBILL_PAYER {
      organization_user_id
      user_group_id
      entity_type
      entity_id
      acl_source
      orig_entity_type
      orig_entity_id
    }
  }
}
    `;

export const useAclPermissionsOfUserGroupQuery = <TData = AclPermissionsOfUserGroupQuery, TError = unknown>(
  client: GraphQLClient,
  variables: AclPermissionsOfUserGroupQueryVariables,
  options?: UseQueryOptions<AclPermissionsOfUserGroupQuery, TError, TData>,
  headers?: RequestInit["headers"],
) => {
  return useQuery<AclPermissionsOfUserGroupQuery, TError, TData>(
    ["AclPermissionsOfUserGroup", variables],
    fetcher<AclPermissionsOfUserGroupQuery, AclPermissionsOfUserGroupQueryVariables>(
      client,
      AclPermissionsOfUserGroupDocument,
      variables,
      headers,
    ),
    options,
  );
};

export const ApplyAclDocument = `
    mutation ApplyACL($acls: PermissionGroupInput) {
  ApplyACL(acls: $acls) {
    success
    error
  }
}
    `;

export const useApplyAclMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<ApplyAclMutation, TError, ApplyAclMutationVariables, TContext>,
  headers?: RequestInit["headers"],
) => {
  return useMutation<ApplyAclMutation, TError, ApplyAclMutationVariables, TContext>(
    ["ApplyACL"],
    (variables?: ApplyAclMutationVariables) =>
      fetcher<ApplyAclMutation, ApplyAclMutationVariables>(client, ApplyAclDocument, variables, headers)(),
    options,
  );
};

export const CheckActionDocument = `
    mutation CheckAction($organization_id: String, $checks: [String!]!, $action: String!) {
  CheckActionMutation(
    organization_id: $organization_id
    checks: $checks
    action: $action
  ) {
    successes
    errors {
      check_id
      message
    }
  }
}
    `;

export const useCheckActionMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<CheckActionMutation, TError, CheckActionMutationVariables, TContext>,
  headers?: RequestInit["headers"],
) => {
  return useMutation<CheckActionMutation, TError, CheckActionMutationVariables, TContext>(
    ["CheckAction"],
    (variables?: CheckActionMutationVariables) =>
      fetcher<CheckActionMutation, CheckActionMutationVariables>(client, CheckActionDocument, variables, headers)(),
    options,
  );
};

export const CreateInviteDocument = `
    mutation CreateInvite($email: String!, $organization_id: String!) {
  CreateInvite(email: $email, organization_id: $organization_id) {
    id
    organization_user_id
  }
}
    `;

export const useCreateInviteMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<CreateInviteMutation, TError, CreateInviteMutationVariables, TContext>,
  headers?: RequestInit["headers"],
) => {
  return useMutation<CreateInviteMutation, TError, CreateInviteMutationVariables, TContext>(
    ["CreateInvite"],
    (variables?: CreateInviteMutationVariables) =>
      fetcher<CreateInviteMutation, CreateInviteMutationVariables>(client, CreateInviteDocument, variables, headers)(),
    options,
  );
};

export const OrganizationCompaniesWithBankaccountsDocument = `
    query OrganizationCompaniesWithBankaccounts($organization_id: String!) {
  OrganizationDashboard(organization_id: $organization_id) {
    companies {
      organization_id
      id
      name
      bank_accounts {
        id
        name
        account_number
        bank_name
      }
    }
  }
}
    `;

export const useOrganizationCompaniesWithBankaccountsQuery = <
  TData = OrganizationCompaniesWithBankaccountsQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables: OrganizationCompaniesWithBankaccountsQueryVariables,
  options?: UseQueryOptions<OrganizationCompaniesWithBankaccountsQuery, TError, TData>,
  headers?: RequestInit["headers"],
) => {
  return useQuery<OrganizationCompaniesWithBankaccountsQuery, TError, TData>(
    ["OrganizationCompaniesWithBankaccounts", variables],
    fetcher<OrganizationCompaniesWithBankaccountsQuery, OrganizationCompaniesWithBankaccountsQueryVariables>(
      client,
      OrganizationCompaniesWithBankaccountsDocument,
      variables,
      headers,
    ),
    options,
  );
};

export const OrganizationUserGroupsDocument = `
    query OrganizationUserGroups($organization_id: String!, $organization_user_id: String!) {
  OrganizationUsers(
    organization_id: $organization_id
    organization_user_id: $organization_user_id
  ) {
    id
    user_groups {
      id
      name
    }
  }
}
    `;

export const useOrganizationUserGroupsQuery = <TData = OrganizationUserGroupsQuery, TError = unknown>(
  client: GraphQLClient,
  variables: OrganizationUserGroupsQueryVariables,
  options?: UseQueryOptions<OrganizationUserGroupsQuery, TError, TData>,
  headers?: RequestInit["headers"],
) => {
  return useQuery<OrganizationUserGroupsQuery, TError, TData>(
    ["OrganizationUserGroups", variables],
    fetcher<OrganizationUserGroupsQuery, OrganizationUserGroupsQueryVariables>(
      client,
      OrganizationUserGroupsDocument,
      variables,
      headers,
    ),
    options,
  );
};

export const PermissionsGroupsDocument = `
    query PermissionsGroups {
  PermissionsGroups {
    id
    group_name
    internal_alias
    category
    permission_types {
      id
      permission
      display_name
      description
    }
  }
}
    `;

export const usePermissionsGroupsQuery = <TData = PermissionsGroupsQuery, TError = unknown>(
  client: GraphQLClient,
  variables?: PermissionsGroupsQueryVariables,
  options?: UseQueryOptions<PermissionsGroupsQuery, TError, TData>,
  headers?: RequestInit["headers"],
) => {
  return useQuery<PermissionsGroupsQuery, TError, TData>(
    variables === undefined ? ["PermissionsGroups"] : ["PermissionsGroups", variables],
    fetcher<PermissionsGroupsQuery, PermissionsGroupsQueryVariables>(
      client,
      PermissionsGroupsDocument,
      variables,
      headers,
    ),
    options,
  );
};

export const UnmapBankAccountGlAccountMappingDocument = `
    mutation UnmapBankAccountGlAccountMapping($bank_account_id: String!) {
  UnmapBankAccountGlAccountMapping(bank_account_id: $bank_account_id)
}
    `;

export const useUnmapBankAccountGlAccountMappingMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    UnmapBankAccountGlAccountMappingMutation,
    TError,
    UnmapBankAccountGlAccountMappingMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    UnmapBankAccountGlAccountMappingMutation,
    TError,
    UnmapBankAccountGlAccountMappingMutationVariables,
    TContext
  >(
    ["UnmapBankAccountGlAccountMapping"],
    (variables?: UnmapBankAccountGlAccountMappingMutationVariables) =>
      fetcher<UnmapBankAccountGlAccountMappingMutation, UnmapBankAccountGlAccountMappingMutationVariables>(
        client,
        UnmapBankAccountGlAccountMappingDocument,
        variables,
        headers,
      )(),
    options,
  );
};

export const UserGroupUsersDocument = `
    query UserGroupUsers($organization_id: String!, $user_group_id: String!) {
  UserGroups(
    organization_id: $organization_id
    filters: {user_group_id: $user_group_id}
  ) {
    id
    name
    organization_users {
      id
      account {
        id
        name
        email
        state
      }
    }
  }
}
    `;

export const useUserGroupUsersQuery = <TData = UserGroupUsersQuery, TError = unknown>(
  client: GraphQLClient,
  variables: UserGroupUsersQueryVariables,
  options?: UseQueryOptions<UserGroupUsersQuery, TError, TData>,
  headers?: RequestInit["headers"],
) => {
  return useQuery<UserGroupUsersQuery, TError, TData>(
    ["UserGroupUsers", variables],
    fetcher<UserGroupUsersQuery, UserGroupUsersQueryVariables>(client, UserGroupUsersDocument, variables, headers),
    options,
  );
};

export const CreateFeatureAccessRequestMutationDocument = `
    mutation CreateFeatureAccessRequestMutation($feature: FeatureEnumType!, $organization_id: String!) {
  CreateFeatureAccessRequestMutation(
    feature: $feature
    organization_id: $organization_id
  )
}
    `;

export const useCreateFeatureAccessRequestMutationMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    CreateFeatureAccessRequestMutationMutation,
    TError,
    CreateFeatureAccessRequestMutationMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    CreateFeatureAccessRequestMutationMutation,
    TError,
    CreateFeatureAccessRequestMutationMutationVariables,
    TContext
  >(
    ["CreateFeatureAccessRequestMutation"],
    (variables?: CreateFeatureAccessRequestMutationMutationVariables) =>
      fetcher<CreateFeatureAccessRequestMutationMutation, CreateFeatureAccessRequestMutationMutationVariables>(
        client,
        CreateFeatureAccessRequestMutationDocument,
        variables,
        headers,
      )(),
    options,
  );
};

export const DelegationGroupResolutionDocument = `
    query DelegationGroupResolution($organization_id: String!, $bill_id: String, $check_id: String) {
  DelegationGroupResolution(
    organization_id: $organization_id
    bill_id: $bill_id
    check_id: $check_id
  ) {
    id
    scope
    type
    requirement_type
    organization_id
    company_id
    vendor_id
    bill_id
    check_id
    is_root
    delegator {
      id
      account {
        id
        name
      }
    }
    approvals_required
    reviews_required
    delegations {
      id
      role
      delegate {
        id
        account {
          name
        }
      }
    }
    labeling_category {
      id
      name
    }
    parent_group_id
    overall_approvals_met
    overall_reviews_met
  }
}
    `;

export const useDelegationGroupResolutionQuery = <TData = DelegationGroupResolutionQuery, TError = unknown>(
  client: GraphQLClient,
  variables: DelegationGroupResolutionQueryVariables,
  options?: UseQueryOptions<DelegationGroupResolutionQuery, TError, TData>,
  headers?: RequestInit["headers"],
) => {
  return useQuery<DelegationGroupResolutionQuery, TError, TData>(
    ["DelegationGroupResolution", variables],
    fetcher<DelegationGroupResolutionQuery, DelegationGroupResolutionQueryVariables>(
      client,
      DelegationGroupResolutionDocument,
      variables,
      headers,
    ),
    options,
  );
};

export const DelegationGroupsDocument = `
    query DelegationGroups($scope: DelegationGroupScopeEnum!, $organization_id: String!, $company_id: String, $vendor_id: String, $bill_id: String, $check_id: String, $delegator_user_id: String, $match_any: Boolean, $is_root: Boolean) {
  DelegationGroups(
    scope: $scope
    organization_id: $organization_id
    company_id: $company_id
    vendor_id: $vendor_id
    bill_id: $bill_id
    check_id: $check_id
    delegator_user_id: $delegator_user_id
    match_any: $match_any
    is_root: $is_root
  ) {
    is_root_delegator
    delegation_groups {
      id
      scope
      type
      name
      requirement_type
      organization_id
      company_id
      vendor_id
      bill_id
      check_id
      is_root
      delegator {
        id
      }
      approvals_required
      reviews_required
      delegations {
        id
        role
        delegate {
          id
          account {
            name
          }
        }
      }
      labeling_category {
        id
        name
      }
    }
  }
}
    `;

export const useDelegationGroupsQuery = <TData = DelegationGroupsQuery, TError = unknown>(
  client: GraphQLClient,
  variables: DelegationGroupsQueryVariables,
  options?: UseQueryOptions<DelegationGroupsQuery, TError, TData>,
  headers?: RequestInit["headers"],
) => {
  return useQuery<DelegationGroupsQuery, TError, TData>(
    ["DelegationGroups", variables],
    fetcher<DelegationGroupsQuery, DelegationGroupsQueryVariables>(
      client,
      DelegationGroupsDocument,
      variables,
      headers,
    ),
    options,
  );
};

export const DeleteDelegationGroupDocument = `
    mutation DeleteDelegationGroup($scope: DelegationGroupScopeEnum!, $organization_id: String!, $delegation_group_id: String, $company_id: String, $vendor_id: String) {
  DeleteDelegationGroup(
    scope: $scope
    organization_id: $organization_id
    delegation_group_id: $delegation_group_id
    company_id: $company_id
    vendor_id: $vendor_id
  )
}
    `;

export const useDeleteDelegationGroupMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<DeleteDelegationGroupMutation, TError, DeleteDelegationGroupMutationVariables, TContext>,
  headers?: RequestInit["headers"],
) => {
  return useMutation<DeleteDelegationGroupMutation, TError, DeleteDelegationGroupMutationVariables, TContext>(
    ["DeleteDelegationGroup"],
    (variables?: DeleteDelegationGroupMutationVariables) =>
      fetcher<DeleteDelegationGroupMutation, DeleteDelegationGroupMutationVariables>(
        client,
        DeleteDelegationGroupDocument,
        variables,
        headers,
      )(),
    options,
  );
};

export const LabelingCategoriesDocument = `
    query LabelingCategories($organization_id: String!, $company_id: String) {
  LabelingCategories(organization_id: $organization_id, company_id: $company_id) {
    id
    name
  }
}
    `;

export const useLabelingCategoriesQuery = <TData = LabelingCategoriesQuery, TError = unknown>(
  client: GraphQLClient,
  variables: LabelingCategoriesQueryVariables,
  options?: UseQueryOptions<LabelingCategoriesQuery, TError, TData>,
  headers?: RequestInit["headers"],
) => {
  return useQuery<LabelingCategoriesQuery, TError, TData>(
    ["LabelingCategories", variables],
    fetcher<LabelingCategoriesQuery, LabelingCategoriesQueryVariables>(
      client,
      LabelingCategoriesDocument,
      variables,
      headers,
    ),
    options,
  );
};

export const SaveApprovalDocument = `
    mutation SaveApproval($organization_id: String!, $bill_id: String, $check_id: String, $type: ApprovalTypeEnum!, $resolution: ApprovalResolutionEnum!) {
  SaveApproval(
    organization_id: $organization_id
    bill_id: $bill_id
    check_id: $check_id
    type: $type
    resolution: $resolution
  )
}
    `;

export const useSaveApprovalMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<SaveApprovalMutation, TError, SaveApprovalMutationVariables, TContext>,
  headers?: RequestInit["headers"],
) => {
  return useMutation<SaveApprovalMutation, TError, SaveApprovalMutationVariables, TContext>(
    ["SaveApproval"],
    (variables?: SaveApprovalMutationVariables) =>
      fetcher<SaveApprovalMutation, SaveApprovalMutationVariables>(client, SaveApprovalDocument, variables, headers)(),
    options,
  );
};

export const SaveDelegationGroupDocument = `
    mutation SaveDelegationGroup($delegation_group_id: String, $scope: DelegationGroupScopeEnum!, $type: DelegationGroupTypeEnum!, $name: String, $requirement_type: DelegationGroupRequirementTypeEnum!, $organization_id: String!, $company_id: String, $vendor_id: String, $bill_id: String, $check_id: String, $is_root: Boolean!, $root_delegator_id: String, $approvals_required: Int!, $reviews_required: Int!, $delegations: [InputDelegation!]!, $labeling_category_id: String, $entity_context: InputDelegationGroupEntityContext) {
  SaveDelegationGroup(
    delegation_group_id: $delegation_group_id
    scope: $scope
    type: $type
    name: $name
    requirement_type: $requirement_type
    organization_id: $organization_id
    company_id: $company_id
    vendor_id: $vendor_id
    bill_id: $bill_id
    check_id: $check_id
    is_root: $is_root
    root_delegator_id: $root_delegator_id
    approvals_required: $approvals_required
    reviews_required: $reviews_required
    delegations: $delegations
    labeling_category_id: $labeling_category_id
    entity_context: $entity_context
  )
}
    `;

export const useSaveDelegationGroupMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<SaveDelegationGroupMutation, TError, SaveDelegationGroupMutationVariables, TContext>,
  headers?: RequestInit["headers"],
) => {
  return useMutation<SaveDelegationGroupMutation, TError, SaveDelegationGroupMutationVariables, TContext>(
    ["SaveDelegationGroup"],
    (variables?: SaveDelegationGroupMutationVariables) =>
      fetcher<SaveDelegationGroupMutation, SaveDelegationGroupMutationVariables>(
        client,
        SaveDelegationGroupDocument,
        variables,
        headers,
      )(),
    options,
  );
};

export const SaveLabelingCategoryDocument = `
    mutation SaveLabelingCategory($organization_id: String!, $company_id: String, $name: String!) {
  SaveLabelingCategory(
    organization_id: $organization_id
    company_id: $company_id
    name: $name
  ) {
    id
  }
}
    `;

export const useSaveLabelingCategoryMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<SaveLabelingCategoryMutation, TError, SaveLabelingCategoryMutationVariables, TContext>,
  headers?: RequestInit["headers"],
) => {
  return useMutation<SaveLabelingCategoryMutation, TError, SaveLabelingCategoryMutationVariables, TContext>(
    ["SaveLabelingCategory"],
    (variables?: SaveLabelingCategoryMutationVariables) =>
      fetcher<SaveLabelingCategoryMutation, SaveLabelingCategoryMutationVariables>(
        client,
        SaveLabelingCategoryDocument,
        variables,
        headers,
      )(),
    options,
  );
};

export const CreateAutoDepositRuleMutationDocument = `
    mutation CreateAutoDepositRuleMutation($bank_account_id: String!, $conditions: ConditionInputGraphQLType!, $rule_priority: [String]) {
  CreateAutoDepositRuleMutation(
    bank_account_id: $bank_account_id
    conditions: $conditions
    rule_priority: $rule_priority
  ) {
    id
    email_address {
      id
      email
    }
    bank_account {
      id
      name
      account_number
      routing_number
      bank_name
    }
  }
}
    `;

export const useCreateAutoDepositRuleMutationMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    CreateAutoDepositRuleMutationMutation,
    TError,
    CreateAutoDepositRuleMutationMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    CreateAutoDepositRuleMutationMutation,
    TError,
    CreateAutoDepositRuleMutationMutationVariables,
    TContext
  >(
    ["CreateAutoDepositRuleMutation"],
    (variables?: CreateAutoDepositRuleMutationMutationVariables) =>
      fetcher<CreateAutoDepositRuleMutationMutation, CreateAutoDepositRuleMutationMutationVariables>(
        client,
        CreateAutoDepositRuleMutationDocument,
        variables,
        headers,
      )(),
    options,
  );
};

export const DeleteAutoDepositRuleMutationDocument = `
    mutation DeleteAutoDepositRuleMutation($auto_deposit_rule_id: String!) {
  DeleteAutoDepositRuleMutation(auto_deposit_rule_id: $auto_deposit_rule_id)
}
    `;

export const useDeleteAutoDepositRuleMutationMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    DeleteAutoDepositRuleMutationMutation,
    TError,
    DeleteAutoDepositRuleMutationMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    DeleteAutoDepositRuleMutationMutation,
    TError,
    DeleteAutoDepositRuleMutationMutationVariables,
    TContext
  >(
    ["DeleteAutoDepositRuleMutation"],
    (variables?: DeleteAutoDepositRuleMutationMutationVariables) =>
      fetcher<DeleteAutoDepositRuleMutationMutation, DeleteAutoDepositRuleMutationMutationVariables>(
        client,
        DeleteAutoDepositRuleMutationDocument,
        variables,
        headers,
      )(),
    options,
  );
};

export const GetAutoDepositRulesForEmailAddressQueryDocument = `
    query GetAutoDepositRulesForEmailAddressQuery($email_address_id: String!) {
  GetAutoDepositRulesForEmailAddressQuery(email_address_id: $email_address_id) {
    auto_deposit_rules {
      id
      email_address {
        id
        email
      }
      bank_account {
        id
        name
        account_number
        routing_number
        bank_name
      }
      conditions {
        pttoos
        senders
        memos
      }
      organization_name
      organization_id
      company_name
      company_id
    }
    rule_priority
  }
}
    `;

export const useGetAutoDepositRulesForEmailAddressQueryQuery = <
  TData = GetAutoDepositRulesForEmailAddressQueryQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables: GetAutoDepositRulesForEmailAddressQueryQueryVariables,
  options?: UseQueryOptions<GetAutoDepositRulesForEmailAddressQueryQuery, TError, TData>,
  headers?: RequestInit["headers"],
) => {
  return useQuery<GetAutoDepositRulesForEmailAddressQueryQuery, TError, TData>(
    ["GetAutoDepositRulesForEmailAddressQuery", variables],
    fetcher<GetAutoDepositRulesForEmailAddressQueryQuery, GetAutoDepositRulesForEmailAddressQueryQueryVariables>(
      client,
      GetAutoDepositRulesForEmailAddressQueryDocument,
      variables,
      headers,
    ),
    options,
  );
};

export const UpdateAutoDepositRuleMutationDocument = `
    mutation UpdateAutoDepositRuleMutation($auto_deposit_rule_id: String!, $bank_account_id: String!, $conditions: ConditionInputGraphQLType!, $rule_priority: [String]!) {
  UpdateAutoDepositRuleMutation(
    auto_deposit_rule_id: $auto_deposit_rule_id
    bank_account_id: $bank_account_id
    conditions: $conditions
    rule_priority: $rule_priority
  ) {
    id
    email_address {
      id
      email
    }
    bank_account {
      id
      name
      account_number
      routing_number
      bank_name
    }
  }
}
    `;

export const useUpdateAutoDepositRuleMutationMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    UpdateAutoDepositRuleMutationMutation,
    TError,
    UpdateAutoDepositRuleMutationMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    UpdateAutoDepositRuleMutationMutation,
    TError,
    UpdateAutoDepositRuleMutationMutationVariables,
    TContext
  >(
    ["UpdateAutoDepositRuleMutation"],
    (variables?: UpdateAutoDepositRuleMutationMutationVariables) =>
      fetcher<UpdateAutoDepositRuleMutationMutation, UpdateAutoDepositRuleMutationMutationVariables>(
        client,
        UpdateAutoDepositRuleMutationDocument,
        variables,
        headers,
      )(),
    options,
  );
};

export const UpdateAutoDepositRulePriorityMutationDocument = `
    mutation UpdateAutoDepositRulePriorityMutation($email_address_id: String!, $rule_priority: [String]!) {
  UpdateAutoDepositRulePriorityMutation(
    email_address_id: $email_address_id
    rule_priority: $rule_priority
  ) {
    rule_priority
  }
}
    `;

export const useUpdateAutoDepositRulePriorityMutationMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    UpdateAutoDepositRulePriorityMutationMutation,
    TError,
    UpdateAutoDepositRulePriorityMutationMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    UpdateAutoDepositRulePriorityMutationMutation,
    TError,
    UpdateAutoDepositRulePriorityMutationMutationVariables,
    TContext
  >(
    ["UpdateAutoDepositRulePriorityMutation"],
    (variables?: UpdateAutoDepositRulePriorityMutationMutationVariables) =>
      fetcher<UpdateAutoDepositRulePriorityMutationMutation, UpdateAutoDepositRulePriorityMutationMutationVariables>(
        client,
        UpdateAutoDepositRulePriorityMutationDocument,
        variables,
        headers,
      )(),
    options,
  );
};

export const UpdateBankAccountDocument = `
    mutation UpdateBankAccount($bank_account_id: String!, $nickname: String) {
  UpdateBankAccount(bank_account_id: $bank_account_id, nickname: $nickname) {
    id
    name
    nickname
    account_number
    routing_number
    bank_name
  }
}
    `;

export const useUpdateBankAccountMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<UpdateBankAccountMutation, TError, UpdateBankAccountMutationVariables, TContext>,
  headers?: RequestInit["headers"],
) => {
  return useMutation<UpdateBankAccountMutation, TError, UpdateBankAccountMutationVariables, TContext>(
    ["UpdateBankAccount"],
    (variables?: UpdateBankAccountMutationVariables) =>
      fetcher<UpdateBankAccountMutation, UpdateBankAccountMutationVariables>(
        client,
        UpdateBankAccountDocument,
        variables,
        headers,
      )(),
    options,
  );
};

export const UpdateUnconfirmedBankAccountDocument = `
    mutation UpdateUnconfirmedBankAccount($bank_account_id: String!, $action: String!) {
  UpdateUnconfirmedBankAccount(bank_account_id: $bank_account_id, action: $action)
}
    `;

export const useUpdateUnconfirmedBankAccountMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    UpdateUnconfirmedBankAccountMutation,
    TError,
    UpdateUnconfirmedBankAccountMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    UpdateUnconfirmedBankAccountMutation,
    TError,
    UpdateUnconfirmedBankAccountMutationVariables,
    TContext
  >(
    ["UpdateUnconfirmedBankAccount"],
    (variables?: UpdateUnconfirmedBankAccountMutationVariables) =>
      fetcher<UpdateUnconfirmedBankAccountMutation, UpdateUnconfirmedBankAccountMutationVariables>(
        client,
        UpdateUnconfirmedBankAccountDocument,
        variables,
        headers,
      )(),
    options,
  );
};

export const CreateCheckBatchItemsMutationDocument = `
    mutation CreateCheckBatchItemsMutation($check_batch_id: String!, $checks: [String], $filters: CheckFiltersType) {
  CreateCheckBatchItemsMutation(
    check_batch_id: $check_batch_id
    checks: $checks
    filters: $filters
  ) {
    checks_added
    invalid_checks
  }
}
    `;

export const useCreateCheckBatchItemsMutationMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    CreateCheckBatchItemsMutationMutation,
    TError,
    CreateCheckBatchItemsMutationMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    CreateCheckBatchItemsMutationMutation,
    TError,
    CreateCheckBatchItemsMutationMutationVariables,
    TContext
  >(
    ["CreateCheckBatchItemsMutation"],
    (variables?: CreateCheckBatchItemsMutationMutationVariables) =>
      fetcher<CreateCheckBatchItemsMutationMutation, CreateCheckBatchItemsMutationMutationVariables>(
        client,
        CreateCheckBatchItemsMutationDocument,
        variables,
        headers,
      )(),
    options,
  );
};

export const CreateCheckBatchMembersMutationDocument = `
    mutation CreateCheckBatchMembersMutation($check_batch_id: String!, $organization_user_ids: [String]) {
  CreateCheckBatchMembersMutation(
    check_batch_id: $check_batch_id
    organization_user_ids: $organization_user_ids
  ) {
    success_ids
  }
}
    `;

export const useCreateCheckBatchMembersMutationMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    CreateCheckBatchMembersMutationMutation,
    TError,
    CreateCheckBatchMembersMutationMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    CreateCheckBatchMembersMutationMutation,
    TError,
    CreateCheckBatchMembersMutationMutationVariables,
    TContext
  >(
    ["CreateCheckBatchMembersMutation"],
    (variables?: CreateCheckBatchMembersMutationMutationVariables) =>
      fetcher<CreateCheckBatchMembersMutationMutation, CreateCheckBatchMembersMutationMutationVariables>(
        client,
        CreateCheckBatchMembersMutationDocument,
        variables,
        headers,
      )(),
    options,
  );
};

export const CreateCheckBatchMutationDocument = `
    mutation CreateCheckBatchMutation($company_id: String!, $name: String!, $budget: Float) {
  CreateCheckBatchMutation(company_id: $company_id, name: $name, budget: $budget) {
    id
    name
    budget
    company {
      id
      legal_name
      dba
    }
    state
    created_by_organization_user {
      id
      account {
        id
        name
        email
      }
    }
    created_with
    items {
      rows {
        id
        check {
          id
          number
          date
          amount
          memo
          pttoo
        }
        reactions {
          id
          organization_user_id
          reaction
        }
      }
      pagination {
        total
        per_page
        on_first_page
        current_page
        last_page
        has_pages
        has_more_pages
        first_item
        last_item
      }
    }
    items_amount
    items_count
  }
}
    `;

export const useCreateCheckBatchMutationMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    CreateCheckBatchMutationMutation,
    TError,
    CreateCheckBatchMutationMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<CreateCheckBatchMutationMutation, TError, CreateCheckBatchMutationMutationVariables, TContext>(
    ["CreateCheckBatchMutation"],
    (variables?: CreateCheckBatchMutationMutationVariables) =>
      fetcher<CreateCheckBatchMutationMutation, CreateCheckBatchMutationMutationVariables>(
        client,
        CreateCheckBatchMutationDocument,
        variables,
        headers,
      )(),
    options,
  );
};

export const DeleteCheckBatchItemsMutationDocument = `
    mutation DeleteCheckBatchItemsMutation($check_batch_id: String!, $checks: [String]!) {
  DeleteCheckBatchItemsMutation(check_batch_id: $check_batch_id, checks: $checks)
}
    `;

export const useDeleteCheckBatchItemsMutationMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    DeleteCheckBatchItemsMutationMutation,
    TError,
    DeleteCheckBatchItemsMutationMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    DeleteCheckBatchItemsMutationMutation,
    TError,
    DeleteCheckBatchItemsMutationMutationVariables,
    TContext
  >(
    ["DeleteCheckBatchItemsMutation"],
    (variables?: DeleteCheckBatchItemsMutationMutationVariables) =>
      fetcher<DeleteCheckBatchItemsMutationMutation, DeleteCheckBatchItemsMutationMutationVariables>(
        client,
        DeleteCheckBatchItemsMutationDocument,
        variables,
        headers,
      )(),
    options,
  );
};

export const DeleteCheckBatchMembersMutationDocument = `
    mutation DeleteCheckBatchMembersMutation($check_batch_id: String!, $organization_user_ids: [String]) {
  DeleteCheckBatchMembersMutation(
    check_batch_id: $check_batch_id
    organization_user_ids: $organization_user_ids
  )
}
    `;

export const useDeleteCheckBatchMembersMutationMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    DeleteCheckBatchMembersMutationMutation,
    TError,
    DeleteCheckBatchMembersMutationMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    DeleteCheckBatchMembersMutationMutation,
    TError,
    DeleteCheckBatchMembersMutationMutationVariables,
    TContext
  >(
    ["DeleteCheckBatchMembersMutation"],
    (variables?: DeleteCheckBatchMembersMutationMutationVariables) =>
      fetcher<DeleteCheckBatchMembersMutationMutation, DeleteCheckBatchMembersMutationMutationVariables>(
        client,
        DeleteCheckBatchMembersMutationDocument,
        variables,
        headers,
      )(),
    options,
  );
};

export const DeleteCheckBatchMutationDocument = `
    mutation DeleteCheckBatchMutation($check_batch_id: String!) {
  DeleteCheckBatchMutation(check_batch_id: $check_batch_id)
}
    `;

export const useDeleteCheckBatchMutationMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    DeleteCheckBatchMutationMutation,
    TError,
    DeleteCheckBatchMutationMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<DeleteCheckBatchMutationMutation, TError, DeleteCheckBatchMutationMutationVariables, TContext>(
    ["DeleteCheckBatchMutation"],
    (variables?: DeleteCheckBatchMutationMutationVariables) =>
      fetcher<DeleteCheckBatchMutationMutation, DeleteCheckBatchMutationMutationVariables>(
        client,
        DeleteCheckBatchMutationDocument,
        variables,
        headers,
      )(),
    options,
  );
};

export const GetAvailableBankAccountsForBatchQueryDocument = `
    query GetAvailableBankAccountsForBatchQuery($check_batch_id: String!, $pagination: PaginationRequestType, $search: String) {
  GetAvailableBankAccountsForBatchQuery(
    check_batch_id: $check_batch_id
    pagination: $pagination
    search: $search
  ) {
    rows {
      id
      name
      on_current_batch
    }
    pagination {
      total
      per_page
      on_first_page
      current_page
      last_page
      has_pages
      has_more_pages
      first_item
      last_item
    }
  }
}
    `;

export const useGetAvailableBankAccountsForBatchQueryQuery = <
  TData = GetAvailableBankAccountsForBatchQueryQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables: GetAvailableBankAccountsForBatchQueryQueryVariables,
  options?: UseQueryOptions<GetAvailableBankAccountsForBatchQueryQuery, TError, TData>,
  headers?: RequestInit["headers"],
) => {
  return useQuery<GetAvailableBankAccountsForBatchQueryQuery, TError, TData>(
    ["GetAvailableBankAccountsForBatchQuery", variables],
    fetcher<GetAvailableBankAccountsForBatchQueryQuery, GetAvailableBankAccountsForBatchQueryQueryVariables>(
      client,
      GetAvailableBankAccountsForBatchQueryDocument,
      variables,
      headers,
    ),
    options,
  );
};

export const GetAvailableChecksForBatchQueryDocument = `
    query GetAvailableChecksForBatchQuery($check_batch_id: String!, $filters: CheckFiltersType, $pagination: PaginationRequestType) {
  GetAvailableChecksForBatchQuery(
    check_batch_id: $check_batch_id
    filters: $filters
    pagination: $pagination
  ) {
    rows {
      id
      number
      date
      amount
      memo
      pttoo
      recipient_email
      status {
        status
        state
        sub_status
      }
      required_actions {
        action
        description
        type
        key
      }
      action {
        type
        label
        action
        disabled
        priority
        message
      }
      options {
        type
        label
        action
        disabled
        priority
        message
      }
      sender_title
      sender_subtitle
      recipient_title
      recipient_subtitle
      remittance {
        entity
        entity_ref
        date
        amount
        meta {
          label
          order
          value
        }
        created_at
      }
      activity {
        id
        title
        note
        type
        context
        can_manage
        attachments {
          context
          sort_order
          media {
            id
            name
            type
            size
            path
            filename
          }
        }
        created_at
        username
      }
      may_not_add_to_batch {
        check_batch_id
        message
      }
    }
    pagination {
      total
      per_page
      on_first_page
      current_page
      last_page
      has_pages
      has_more_pages
      first_item
      last_item
    }
  }
}
    `;

export const useGetAvailableChecksForBatchQueryQuery = <TData = GetAvailableChecksForBatchQueryQuery, TError = unknown>(
  client: GraphQLClient,
  variables: GetAvailableChecksForBatchQueryQueryVariables,
  options?: UseQueryOptions<GetAvailableChecksForBatchQueryQuery, TError, TData>,
  headers?: RequestInit["headers"],
) => {
  return useQuery<GetAvailableChecksForBatchQueryQuery, TError, TData>(
    ["GetAvailableChecksForBatchQuery", variables],
    fetcher<GetAvailableChecksForBatchQueryQuery, GetAvailableChecksForBatchQueryQueryVariables>(
      client,
      GetAvailableChecksForBatchQueryDocument,
      variables,
      headers,
    ),
    options,
  );
};

export const GetCheckBatchAvailableMembersQueryDocument = `
    query GetCheckBatchAvailableMembersQuery($check_batch_id: String!, $pagination: PaginationRequestType, $search: String) {
  GetCheckBatchAvailableMembersQuery(
    check_batch_id: $check_batch_id
    pagination: $pagination
    search: $search
  ) {
    rows {
      id
      account {
        id
        name
        email
      }
    }
    pagination {
      total
      per_page
      on_first_page
      current_page
      last_page
      has_pages
      has_more_pages
      first_item
      last_item
    }
  }
}
    `;

export const useGetCheckBatchAvailableMembersQueryQuery = <
  TData = GetCheckBatchAvailableMembersQueryQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables: GetCheckBatchAvailableMembersQueryQueryVariables,
  options?: UseQueryOptions<GetCheckBatchAvailableMembersQueryQuery, TError, TData>,
  headers?: RequestInit["headers"],
) => {
  return useQuery<GetCheckBatchAvailableMembersQueryQuery, TError, TData>(
    ["GetCheckBatchAvailableMembersQuery", variables],
    fetcher<GetCheckBatchAvailableMembersQueryQuery, GetCheckBatchAvailableMembersQueryQueryVariables>(
      client,
      GetCheckBatchAvailableMembersQueryDocument,
      variables,
      headers,
    ),
    options,
  );
};

export const GetCheckBatchMembersQueryDocument = `
    query GetCheckBatchMembersQuery($check_batch_id: String!, $pagination: PaginationRequestType, $search: String) {
  GetCheckBatchMembersQuery(
    check_batch_id: $check_batch_id
    pagination: $pagination
    search: $search
  ) {
    rows {
      id
      account {
        id
        name
        email
      }
    }
    pagination {
      total
      per_page
      on_first_page
      current_page
      last_page
      has_pages
      has_more_pages
      first_item
      last_item
    }
  }
}
    `;

export const useGetCheckBatchMembersQueryQuery = <TData = GetCheckBatchMembersQueryQuery, TError = unknown>(
  client: GraphQLClient,
  variables: GetCheckBatchMembersQueryQueryVariables,
  options?: UseQueryOptions<GetCheckBatchMembersQueryQuery, TError, TData>,
  headers?: RequestInit["headers"],
) => {
  return useQuery<GetCheckBatchMembersQueryQuery, TError, TData>(
    ["GetCheckBatchMembersQuery", variables],
    fetcher<GetCheckBatchMembersQueryQuery, GetCheckBatchMembersQueryQueryVariables>(
      client,
      GetCheckBatchMembersQueryDocument,
      variables,
      headers,
    ),
    options,
  );
};

export const GetCheckBatchQueryDocument = `
    query GetCheckBatchQuery($check_batch_id: String!, $filters: CheckFiltersType, $pagination: PaginationRequestType) {
  GetCheckBatchQuery(
    check_batch_id: $check_batch_id
    filters: $filters
    pagination: $pagination
  ) {
    id
    name
    budget
    company {
      id
      legal_name
      dba
    }
    state
    created_at
    created_by_organization_user {
      id
      account {
        id
        name
        email
      }
    }
    created_with
    items {
      rows {
        id
        check {
          id
          number
          date
          amount
          memo
          pttoo
          recipient_email
          status {
            status
            state
            sub_status
          }
          required_actions {
            action
            description
            type
            key
          }
          action {
            type
            label
            action
            disabled
            priority
            message
          }
          options {
            type
            label
            action
            disabled
            priority
            message
          }
          sender_title
          sender_subtitle
          recipient_title
          recipient_subtitle
          remittance {
            entity
            entity_ref
            date
            amount
            meta {
              label
              order
              value
            }
            created_at
          }
          activity {
            id
            title
            note
            type
            can_manage
            attachments {
              sort_order
              media {
                id
                name
                type
                size
                path
                filename
              }
            }
            created_at
            username
          }
          may_not_add_to_batch {
            check_batch_id
            message
          }
        }
        created_with
        reactions {
          id
          organization_user_id
          reaction
        }
      }
      pagination {
        total
        per_page
        on_first_page
        current_page
        last_page
        has_pages
        has_more_pages
        first_item
        last_item
      }
    }
    items_amount
    items_count
    unsigned_amount
    unsigned_count
    members_count
    current_user_may_sign
  }
}
    `;

export const useGetCheckBatchQueryQuery = <TData = GetCheckBatchQueryQuery, TError = unknown>(
  client: GraphQLClient,
  variables: GetCheckBatchQueryQueryVariables,
  options?: UseQueryOptions<GetCheckBatchQueryQuery, TError, TData>,
  headers?: RequestInit["headers"],
) => {
  return useQuery<GetCheckBatchQueryQuery, TError, TData>(
    ["GetCheckBatchQuery", variables],
    fetcher<GetCheckBatchQueryQuery, GetCheckBatchQueryQueryVariables>(
      client,
      GetCheckBatchQueryDocument,
      variables,
      headers,
    ),
    options,
  );
};

export const GetCheckBatchesQueryDocument = `
    query GetCheckBatchesQuery($organization_id: String!, $filters: GetCheckBatchesFiltersType, $pagination: PaginationRequestType) {
  GetCheckBatchesQuery(
    organization_id: $organization_id
    filters: $filters
    pagination: $pagination
  ) {
    rows {
      id
      name
      budget
      company {
        id
        legal_name
        dba
      }
      state
      created_at
      created_by_organization_user {
        id
        account {
          id
          name
          email
        }
      }
      created_with
      items {
        rows {
          id
          check {
            id
            number
            date
            amount
            memo
            pttoo
          }
          reactions {
            id
            organization_user_id
            reaction
          }
        }
        pagination {
          total
          per_page
          on_first_page
          current_page
          last_page
          has_pages
          has_more_pages
          first_item
          last_item
        }
      }
      items_amount
      items_count
      members_count
    }
    pagination {
      total
      per_page
      on_first_page
      current_page
      last_page
      has_pages
      has_more_pages
      first_item
      last_item
    }
  }
}
    `;

export const useGetCheckBatchesQueryQuery = <TData = GetCheckBatchesQueryQuery, TError = unknown>(
  client: GraphQLClient,
  variables: GetCheckBatchesQueryQueryVariables,
  options?: UseQueryOptions<GetCheckBatchesQueryQuery, TError, TData>,
  headers?: RequestInit["headers"],
) => {
  return useQuery<GetCheckBatchesQueryQuery, TError, TData>(
    ["GetCheckBatchesQuery", variables],
    fetcher<GetCheckBatchesQueryQuery, GetCheckBatchesQueryQueryVariables>(
      client,
      GetCheckBatchesQueryDocument,
      variables,
      headers,
    ),
    options,
  );
};

export const GetCheckDetailsQueryDocument = `
    query GetCheckDetailsQuery($check_batch_id: String!, $check_id: String!, $check_details_scope: CheckDetailsScopeEnum) {
  GetCheckDetailsQuery(
    check_batch_id: $check_batch_id
    check_id: $check_id
    check_details_scope: $check_details_scope
  ) {
    id
    number
    date
    amount
    memo
    pttoo
    recipient_email
    status {
      status
      state
      sub_status
    }
    required_actions {
      action
      description
      type
      key
    }
    action {
      type
      label
      action
      disabled
      priority
      message
    }
    options {
      type
      label
      action
      disabled
      priority
      message
    }
    sender_title
    sender_subtitle
    recipient_title
    recipient_subtitle
    remittance {
      entity
      entity_ref
      date
      amount
      meta {
        label
        order
        value
      }
      created_at
    }
    activity {
      id
      title
      note
      type
      can_manage
      attachments {
        sort_order
        media {
          id
          name
          type
          size
          path
          filename
        }
      }
      created_at
      username
    }
    may_not_add_to_batch {
      check_batch_id
      message
    }
  }
}
    `;

export const useGetCheckDetailsQueryQuery = <TData = GetCheckDetailsQueryQuery, TError = unknown>(
  client: GraphQLClient,
  variables: GetCheckDetailsQueryQueryVariables,
  options?: UseQueryOptions<GetCheckDetailsQueryQuery, TError, TData>,
  headers?: RequestInit["headers"],
) => {
  return useQuery<GetCheckDetailsQueryQuery, TError, TData>(
    ["GetCheckDetailsQuery", variables],
    fetcher<GetCheckDetailsQueryQuery, GetCheckDetailsQueryQueryVariables>(
      client,
      GetCheckDetailsQueryDocument,
      variables,
      headers,
    ),
    options,
  );
};

export const SignCheckBatchMutationDocument = `
    mutation SignCheckBatchMutation($check_batch_id: String!) {
  SignCheckBatchMutation(check_batch_id: $check_batch_id) {
    checks_available_for_signing
    signed_checks
  }
}
    `;

export const useSignCheckBatchMutationMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    SignCheckBatchMutationMutation,
    TError,
    SignCheckBatchMutationMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<SignCheckBatchMutationMutation, TError, SignCheckBatchMutationMutationVariables, TContext>(
    ["SignCheckBatchMutation"],
    (variables?: SignCheckBatchMutationMutationVariables) =>
      fetcher<SignCheckBatchMutationMutation, SignCheckBatchMutationMutationVariables>(
        client,
        SignCheckBatchMutationDocument,
        variables,
        headers,
      )(),
    options,
  );
};

export const UpdateCheckBatchItemsReactionMutationDocument = `
    mutation UpdateCheckBatchItemsReactionMutation($check_batch_item_ids: [String!], $reaction: ReactionEnumType!) {
  UpdateCheckBatchItemsReactionMutation(
    check_batch_item_ids: $check_batch_item_ids
    reaction: $reaction
  )
}
    `;

export const useUpdateCheckBatchItemsReactionMutationMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    UpdateCheckBatchItemsReactionMutationMutation,
    TError,
    UpdateCheckBatchItemsReactionMutationMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    UpdateCheckBatchItemsReactionMutationMutation,
    TError,
    UpdateCheckBatchItemsReactionMutationMutationVariables,
    TContext
  >(
    ["UpdateCheckBatchItemsReactionMutation"],
    (variables?: UpdateCheckBatchItemsReactionMutationMutationVariables) =>
      fetcher<UpdateCheckBatchItemsReactionMutationMutation, UpdateCheckBatchItemsReactionMutationMutationVariables>(
        client,
        UpdateCheckBatchItemsReactionMutationDocument,
        variables,
        headers,
      )(),
    options,
  );
};

export const UpdateCheckBatchMutationDocument = `
    mutation UpdateCheckBatchMutation($check_batch_id: String!, $name: String, $budget: Float) {
  UpdateCheckBatchMutation(
    check_batch_id: $check_batch_id
    name: $name
    budget: $budget
  ) {
    id
  }
}
    `;

export const useUpdateCheckBatchMutationMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    UpdateCheckBatchMutationMutation,
    TError,
    UpdateCheckBatchMutationMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<UpdateCheckBatchMutationMutation, TError, UpdateCheckBatchMutationMutationVariables, TContext>(
    ["UpdateCheckBatchMutation"],
    (variables?: UpdateCheckBatchMutationMutationVariables) =>
      fetcher<UpdateCheckBatchMutationMutation, UpdateCheckBatchMutationMutationVariables>(
        client,
        UpdateCheckBatchMutationDocument,
        variables,
        headers,
      )(),
    options,
  );
};

export const BankAccountLinkedAddressesDocument = `
    query BankAccountLinkedAddresses($bank_account_id: String!) {
  BankAccountLinkedAddresses(bank_account_id: $bank_account_id) {
    id
    email
    status
    created_by
    created_at
  }
}
    `;

export const useBankAccountLinkedAddressesQuery = <TData = BankAccountLinkedAddressesQuery, TError = unknown>(
  client: GraphQLClient,
  variables: BankAccountLinkedAddressesQueryVariables,
  options?: UseQueryOptions<BankAccountLinkedAddressesQuery, TError, TData>,
  headers?: RequestInit["headers"],
) => {
  return useQuery<BankAccountLinkedAddressesQuery, TError, TData>(
    ["BankAccountLinkedAddresses", variables],
    fetcher<BankAccountLinkedAddressesQuery, BankAccountLinkedAddressesQueryVariables>(
      client,
      BankAccountLinkedAddressesDocument,
      variables,
      headers,
    ),
    options,
  );
};

export const BankAccountAutoDepositAddressesDocument = `
    query BankAccountAutoDepositAddresses($bank_account_id: String!) {
  BankAccountAutoDepositAddresses(bank_account_id: $bank_account_id) {
    id
    email
    type
    created_by
  }
}
    `;

export const useBankAccountAutoDepositAddressesQuery = <TData = BankAccountAutoDepositAddressesQuery, TError = unknown>(
  client: GraphQLClient,
  variables: BankAccountAutoDepositAddressesQueryVariables,
  options?: UseQueryOptions<BankAccountAutoDepositAddressesQuery, TError, TData>,
  headers?: RequestInit["headers"],
) => {
  return useQuery<BankAccountAutoDepositAddressesQuery, TError, TData>(
    ["BankAccountAutoDepositAddresses", variables],
    fetcher<BankAccountAutoDepositAddressesQuery, BankAccountAutoDepositAddressesQueryVariables>(
      client,
      BankAccountAutoDepositAddressesDocument,
      variables,
      headers,
    ),
    options,
  );
};

export const BankAccountAvailableAutoDepositAddressesDocument = `
    query BankAccountAvailableAutoDepositAddresses($bankAccountId: String!) {
  BankAccountAvailableAutoDepositAddresses(bank_account_id: $bankAccountId) {
    id
    email
  }
}
    `;

export const useBankAccountAvailableAutoDepositAddressesQuery = <
  TData = BankAccountAvailableAutoDepositAddressesQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables: BankAccountAvailableAutoDepositAddressesQueryVariables,
  options?: UseQueryOptions<BankAccountAvailableAutoDepositAddressesQuery, TError, TData>,
  headers?: RequestInit["headers"],
) => {
  return useQuery<BankAccountAvailableAutoDepositAddressesQuery, TError, TData>(
    ["BankAccountAvailableAutoDepositAddresses", variables],
    fetcher<BankAccountAvailableAutoDepositAddressesQuery, BankAccountAvailableAutoDepositAddressesQueryVariables>(
      client,
      BankAccountAvailableAutoDepositAddressesDocument,
      variables,
      headers,
    ),
    options,
  );
};

export const CreateBankAccountAutoDepositAddressDocument = `
    mutation CreateBankAccountAutoDepositAddress($bankAccountId: String!, $emailAddressId: String!) {
  CreateBankAccountAutoDepositAddress(
    bank_account_id: $bankAccountId
    email_address_id: $emailAddressId
  ) {
    id
    email
    type
    created_by
  }
}
    `;

export const useCreateBankAccountAutoDepositAddressMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    CreateBankAccountAutoDepositAddressMutation,
    TError,
    CreateBankAccountAutoDepositAddressMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    CreateBankAccountAutoDepositAddressMutation,
    TError,
    CreateBankAccountAutoDepositAddressMutationVariables,
    TContext
  >(
    ["CreateBankAccountAutoDepositAddress"],
    (variables?: CreateBankAccountAutoDepositAddressMutationVariables) =>
      fetcher<CreateBankAccountAutoDepositAddressMutation, CreateBankAccountAutoDepositAddressMutationVariables>(
        client,
        CreateBankAccountAutoDepositAddressDocument,
        variables,
        headers,
      )(),
    options,
  );
};

export const DeleteBankAccountAutoDepositAddressDocument = `
    mutation DeleteBankAccountAutoDepositAddress($bankAccountId: String!, $emailAddressId: String!) {
  DeleteBankAccountAutoDepositAddress(
    bank_account_id: $bankAccountId
    email_address_id: $emailAddressId
  )
}
    `;

export const useDeleteBankAccountAutoDepositAddressMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    DeleteBankAccountAutoDepositAddressMutation,
    TError,
    DeleteBankAccountAutoDepositAddressMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    DeleteBankAccountAutoDepositAddressMutation,
    TError,
    DeleteBankAccountAutoDepositAddressMutationVariables,
    TContext
  >(
    ["DeleteBankAccountAutoDepositAddress"],
    (variables?: DeleteBankAccountAutoDepositAddressMutationVariables) =>
      fetcher<DeleteBankAccountAutoDepositAddressMutation, DeleteBankAccountAutoDepositAddressMutationVariables>(
        client,
        DeleteBankAccountAutoDepositAddressDocument,
        variables,
        headers,
      )(),
    options,
  );
};

export const CreateBankAccountLinkedAddressDocument = `
    mutation CreateBankAccountLinkedAddress($bankAccountId: String!, $email: String!) {
  CreateBankAccountLinkedAddress(
    bank_account_id: $bankAccountId
    email_address: $email
  ) {
    id
    email
    verified
    state
    created_at
    created_by
  }
}
    `;

export const useCreateBankAccountLinkedAddressMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    CreateBankAccountLinkedAddressMutation,
    TError,
    CreateBankAccountLinkedAddressMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    CreateBankAccountLinkedAddressMutation,
    TError,
    CreateBankAccountLinkedAddressMutationVariables,
    TContext
  >(
    ["CreateBankAccountLinkedAddress"],
    (variables?: CreateBankAccountLinkedAddressMutationVariables) =>
      fetcher<CreateBankAccountLinkedAddressMutation, CreateBankAccountLinkedAddressMutationVariables>(
        client,
        CreateBankAccountLinkedAddressDocument,
        variables,
        headers,
      )(),
    options,
  );
};

export const DeleteBankAccountLinkedAddressDocument = `
    mutation DeleteBankAccountLinkedAddress($addressId: String!) {
  DeleteBankAccountLinkedAddress(linked_address_id: $addressId)
}
    `;

export const useDeleteBankAccountLinkedAddressMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    DeleteBankAccountLinkedAddressMutation,
    TError,
    DeleteBankAccountLinkedAddressMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    DeleteBankAccountLinkedAddressMutation,
    TError,
    DeleteBankAccountLinkedAddressMutationVariables,
    TContext
  >(
    ["DeleteBankAccountLinkedAddress"],
    (variables?: DeleteBankAccountLinkedAddressMutationVariables) =>
      fetcher<DeleteBankAccountLinkedAddressMutation, DeleteBankAccountLinkedAddressMutationVariables>(
        client,
        DeleteBankAccountLinkedAddressDocument,
        variables,
        headers,
      )(),
    options,
  );
};

export const CompanyDetailsDocument = `
    query CompanyDetails($company_id: String!) {
  CompanyDetails(company_id: $company_id) {
    id
    name
    website_url
    vbill_enabled
    vbill_email
    physical_address {
      id
      address
      address2
      city
      state
      zip_code
      country
    }
    phone_number
    company_integrations {
      scopes
      expires_at
      created_at
      last_seen
      integration {
        integration_id
        name
        display_name
        slug
        scopes
        logo
      }
      settings {
        description
        auto_import
        sync_status
        sync_message
        sync_expire
        sync_time
        current_time
      }
      external_auto_signers {
        id
        name
        organization_user_id
      }
    }
  }
}
    `;

export const useCompanyDetailsQuery = <TData = CompanyDetailsQuery, TError = unknown>(
  client: GraphQLClient,
  variables: CompanyDetailsQueryVariables,
  options?: UseQueryOptions<CompanyDetailsQuery, TError, TData>,
  headers?: RequestInit["headers"],
) => {
  return useQuery<CompanyDetailsQuery, TError, TData>(
    ["CompanyDetails", variables],
    fetcher<CompanyDetailsQuery, CompanyDetailsQueryVariables>(client, CompanyDetailsDocument, variables, headers),
    options,
  );
};

export const AssignGroupToCompanyDocument = `
    mutation AssignGroupToCompany($organization_id: String!, $company_id: String!, $group_id: String) {
  AssignGroupToCompanyMutation(
    organization_id: $organization_id
    company_id: $company_id
    group_id: $group_id
  ) {
    id
    label
    company_ids
  }
}
    `;

export const useAssignGroupToCompanyMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<AssignGroupToCompanyMutation, TError, AssignGroupToCompanyMutationVariables, TContext>,
  headers?: RequestInit["headers"],
) => {
  return useMutation<AssignGroupToCompanyMutation, TError, AssignGroupToCompanyMutationVariables, TContext>(
    ["AssignGroupToCompany"],
    (variables?: AssignGroupToCompanyMutationVariables) =>
      fetcher<AssignGroupToCompanyMutation, AssignGroupToCompanyMutationVariables>(
        client,
        AssignGroupToCompanyDocument,
        variables,
        headers,
      )(),
    options,
  );
};

export const CreateCompanyGroupDocument = `
    mutation CreateCompanyGroup($organization_id: String!, $label: String!) {
  CreateCompanyGroupMutation(organization_id: $organization_id, label: $label) {
    id
    label
    company_ids
  }
}
    `;

export const useCreateCompanyGroupMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<CreateCompanyGroupMutation, TError, CreateCompanyGroupMutationVariables, TContext>,
  headers?: RequestInit["headers"],
) => {
  return useMutation<CreateCompanyGroupMutation, TError, CreateCompanyGroupMutationVariables, TContext>(
    ["CreateCompanyGroup"],
    (variables?: CreateCompanyGroupMutationVariables) =>
      fetcher<CreateCompanyGroupMutation, CreateCompanyGroupMutationVariables>(
        client,
        CreateCompanyGroupDocument,
        variables,
        headers,
      )(),
    options,
  );
};

export const DeleteCompanyGroupDocument = `
    mutation DeleteCompanyGroup($organization_id: String!, $group_id: String!) {
  DeleteCompanyGroupMutation(
    organization_id: $organization_id
    group_id: $group_id
  ) {
    id
    label
    company_ids
  }
}
    `;

export const useDeleteCompanyGroupMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<DeleteCompanyGroupMutation, TError, DeleteCompanyGroupMutationVariables, TContext>,
  headers?: RequestInit["headers"],
) => {
  return useMutation<DeleteCompanyGroupMutation, TError, DeleteCompanyGroupMutationVariables, TContext>(
    ["DeleteCompanyGroup"],
    (variables?: DeleteCompanyGroupMutationVariables) =>
      fetcher<DeleteCompanyGroupMutation, DeleteCompanyGroupMutationVariables>(
        client,
        DeleteCompanyGroupDocument,
        variables,
        headers,
      )(),
    options,
  );
};

export const EditCompanyGroupDocument = `
    mutation EditCompanyGroup($organization_id: String!, $group_id: String!, $label: String!) {
  EditCompanyGroupMutation(
    organization_id: $organization_id
    group_id: $group_id
    label: $label
  ) {
    id
    label
    company_ids
  }
}
    `;

export const useEditCompanyGroupMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<EditCompanyGroupMutation, TError, EditCompanyGroupMutationVariables, TContext>,
  headers?: RequestInit["headers"],
) => {
  return useMutation<EditCompanyGroupMutation, TError, EditCompanyGroupMutationVariables, TContext>(
    ["EditCompanyGroup"],
    (variables?: EditCompanyGroupMutationVariables) =>
      fetcher<EditCompanyGroupMutation, EditCompanyGroupMutationVariables>(
        client,
        EditCompanyGroupDocument,
        variables,
        headers,
      )(),
    options,
  );
};

export const GetCompanyGroupsDocument = `
    query GetCompanyGroups($organization_id: String!) {
  GetCompanyGroupsQuery(organization_id: $organization_id) {
    id
    label
    company_ids
  }
}
    `;

export const useGetCompanyGroupsQuery = <TData = GetCompanyGroupsQuery, TError = unknown>(
  client: GraphQLClient,
  variables: GetCompanyGroupsQueryVariables,
  options?: UseQueryOptions<GetCompanyGroupsQuery, TError, TData>,
  headers?: RequestInit["headers"],
) => {
  return useQuery<GetCompanyGroupsQuery, TError, TData>(
    ["GetCompanyGroups", variables],
    fetcher<GetCompanyGroupsQuery, GetCompanyGroupsQueryVariables>(
      client,
      GetCompanyGroupsDocument,
      variables,
      headers,
    ),
    options,
  );
};

export const ContactsDocument = `
    query Contacts($companyId: String!, $pagination: Pagination, $search: String) {
  Contacts(company_id: $companyId, pagination: $pagination, search: $search) {
    pagination {
      total
      per_page
      on_first_page
      current_page
      last_page
      has_pages
      has_more_pages
      first_item
      last_item
    }
    contacts {
      id
      external_ref
      name
      active
      created_at
      address {
        id
        address
        address2
        city
        state
        zip_code
        country
      }
      phone_number {
        id
        number
        type
        description
      }
    }
  }
}
    `;

export const useContactsQuery = <TData = ContactsQuery, TError = unknown>(
  client: GraphQLClient,
  variables: ContactsQueryVariables,
  options?: UseQueryOptions<ContactsQuery, TError, TData>,
  headers?: RequestInit["headers"],
) => {
  return useQuery<ContactsQuery, TError, TData>(
    ["Contacts", variables],
    fetcher<ContactsQuery, ContactsQueryVariables>(client, ContactsDocument, variables, headers),
    options,
  );
};

export const DepositBankAccountsDocument = `
    query DepositBankAccounts {
  DepositBankAccounts {
    id
    name
    companies {
      id
      name
      description
      bank_accounts {
        id
        name
        account_number
        routing_number
        bank_name
      }
    }
  }
}
    `;

export const useDepositBankAccountsQuery = <TData = DepositBankAccountsQuery, TError = unknown>(
  client: GraphQLClient,
  variables?: DepositBankAccountsQueryVariables,
  options?: UseQueryOptions<DepositBankAccountsQuery, TError, TData>,
  headers?: RequestInit["headers"],
) => {
  return useQuery<DepositBankAccountsQuery, TError, TData>(
    variables === undefined ? ["DepositBankAccounts"] : ["DepositBankAccounts", variables],
    fetcher<DepositBankAccountsQuery, DepositBankAccountsQueryVariables>(
      client,
      DepositBankAccountsDocument,
      variables,
      headers,
    ),
    options,
  );
};

export const GetFrontendSettingsQueryDocument = `
    query GetFrontendSettingsQuery($context: FrontendSettingsContextEnum!, $id: String!) {
  GetFrontendSettingsQuery(context: $context, id: $id)
}
    `;

export const useGetFrontendSettingsQueryQuery = <TData = GetFrontendSettingsQueryQuery, TError = unknown>(
  client: GraphQLClient,
  variables: GetFrontendSettingsQueryQueryVariables,
  options?: UseQueryOptions<GetFrontendSettingsQueryQuery, TError, TData>,
  headers?: RequestInit["headers"],
) => {
  return useQuery<GetFrontendSettingsQueryQuery, TError, TData>(
    ["GetFrontendSettingsQuery", variables],
    fetcher<GetFrontendSettingsQueryQuery, GetFrontendSettingsQueryQueryVariables>(
      client,
      GetFrontendSettingsQueryDocument,
      variables,
      headers,
    ),
    options,
  );
};

export const SaveFrontendSettingsMutationDocument = `
    mutation SaveFrontendSettingsMutation($context: FrontendSettingsContextEnum!, $id: String!, $path: String!, $value: String!) {
  SaveFrontendSettingsMutation(
    context: $context
    id: $id
    path: $path
    value: $value
  )
}
    `;

export const useSaveFrontendSettingsMutationMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    SaveFrontendSettingsMutationMutation,
    TError,
    SaveFrontendSettingsMutationMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    SaveFrontendSettingsMutationMutation,
    TError,
    SaveFrontendSettingsMutationMutationVariables,
    TContext
  >(
    ["SaveFrontendSettingsMutation"],
    (variables?: SaveFrontendSettingsMutationMutationVariables) =>
      fetcher<SaveFrontendSettingsMutationMutation, SaveFrontendSettingsMutationMutationVariables>(
        client,
        SaveFrontendSettingsMutationDocument,
        variables,
        headers,
      )(),
    options,
  );
};

export const GeneralLedgerAccountsDocument = `
    query GeneralLedgerAccounts($companyId: String, $vcard_company_account_id: String, $account_types: [String]) {
  GeneralLedgerAccounts(
    company_id: $companyId
    vcard_company_account_id: $vcard_company_account_id
    account_types: $account_types
    pagination: {per_page: 100000}
  ) {
    id
    external_ref
    name
    number
    category
    description
    account_type
    account_number
    active
    bank_account {
      id
      bank_name
      name
      account_number
    }
  }
}
    `;

export const useGeneralLedgerAccountsQuery = <TData = GeneralLedgerAccountsQuery, TError = unknown>(
  client: GraphQLClient,
  variables?: GeneralLedgerAccountsQueryVariables,
  options?: UseQueryOptions<GeneralLedgerAccountsQuery, TError, TData>,
  headers?: RequestInit["headers"],
) => {
  return useQuery<GeneralLedgerAccountsQuery, TError, TData>(
    variables === undefined ? ["GeneralLedgerAccounts"] : ["GeneralLedgerAccounts", variables],
    fetcher<GeneralLedgerAccountsQuery, GeneralLedgerAccountsQueryVariables>(
      client,
      GeneralLedgerAccountsDocument,
      variables,
      headers,
    ),
    options,
  );
};

export const SetBankAccountGlAccountMappingDocument = `
    mutation setBankAccountGlAccountMapping($bankAccountId: String!, $glAccountId: String!) {
  SetBankAccountGlAccountMapping(
    bank_account_id: $bankAccountId
    gl_account_id: $glAccountId
  ) {
    id
    name
    account_number
    routing_number
    bank_name
    gl_accounts {
      id
      name
      external_ref
      account_number
    }
  }
}
    `;

export const useSetBankAccountGlAccountMappingMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    SetBankAccountGlAccountMappingMutation,
    TError,
    SetBankAccountGlAccountMappingMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    SetBankAccountGlAccountMappingMutation,
    TError,
    SetBankAccountGlAccountMappingMutationVariables,
    TContext
  >(
    ["setBankAccountGlAccountMapping"],
    (variables?: SetBankAccountGlAccountMappingMutationVariables) =>
      fetcher<SetBankAccountGlAccountMappingMutation, SetBankAccountGlAccountMappingMutationVariables>(
        client,
        SetBankAccountGlAccountMappingDocument,
        variables,
        headers,
      )(),
    options,
  );
};

export const CompanyIntegrationsDocument = `
    query CompanyIntegrations($companyId: String!) {
  CompanyIntegrations(company_id: $companyId) {
    integrations {
      scopes
      expires_at
      created_at
      last_seen
      integration {
        integration_id
        display_name
        logo
        name
        slug
        scopes
        webhooks {
          url
          scope
        }
      }
      settings {
        description
        auto_import
        sync_status
        sync_message
        sync_expire
        sync_time
        current_time
        pending_checks_count
      }
      external_auto_signers {
        id
        name
        organization_user_id
        company_id
      }
      created_at
    }
    setup_status {
      mapped_organization_users
      mapped_bank_accounts
      unmapped_organization_users
      unmapped_bank_accounts
    }
    bank_accounts {
      id
      company_id
      bank_name
      name
      account_number
      routing_number
      legal_first_name
      legal_last_name
      verification_status
      gl_accounts {
        id
        name
        external_ref
      }
    }
  }
}
    `;

export const useCompanyIntegrationsQuery = <TData = CompanyIntegrationsQuery, TError = unknown>(
  client: GraphQLClient,
  variables: CompanyIntegrationsQueryVariables,
  options?: UseQueryOptions<CompanyIntegrationsQuery, TError, TData>,
  headers?: RequestInit["headers"],
) => {
  return useQuery<CompanyIntegrationsQuery, TError, TData>(
    ["CompanyIntegrations", variables],
    fetcher<CompanyIntegrationsQuery, CompanyIntegrationsQueryVariables>(
      client,
      CompanyIntegrationsDocument,
      variables,
      headers,
    ),
    options,
  );
};

export const GetAllKnownExtSignerNamesDocument = `
    query GetAllKnownExtSignerNames($company_id: String!) {
  GetAllKnownExtSignerNames(company_id: $company_id)
}
    `;

export const useGetAllKnownExtSignerNamesQuery = <TData = GetAllKnownExtSignerNamesQuery, TError = unknown>(
  client: GraphQLClient,
  variables: GetAllKnownExtSignerNamesQueryVariables,
  options?: UseQueryOptions<GetAllKnownExtSignerNamesQuery, TError, TData>,
  headers?: RequestInit["headers"],
) => {
  return useQuery<GetAllKnownExtSignerNamesQuery, TError, TData>(
    ["GetAllKnownExtSignerNames", variables],
    fetcher<GetAllKnownExtSignerNamesQuery, GetAllKnownExtSignerNamesQueryVariables>(
      client,
      GetAllKnownExtSignerNamesDocument,
      variables,
      headers,
    ),
    options,
  );
};

export const GetIntegrationAuthCodeDocument = `
    mutation GetIntegrationAuthCode($integrationId: String!, $companyId: String!, $integration_settings: IntegrationSettingsInput) {
  GetIntegrationAuthCode(
    integration_id: $integrationId
    company_id: $companyId
    integration_settings: $integration_settings
  )
}
    `;

export const useGetIntegrationAuthCodeMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    GetIntegrationAuthCodeMutation,
    TError,
    GetIntegrationAuthCodeMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<GetIntegrationAuthCodeMutation, TError, GetIntegrationAuthCodeMutationVariables, TContext>(
    ["GetIntegrationAuthCode"],
    (variables?: GetIntegrationAuthCodeMutationVariables) =>
      fetcher<GetIntegrationAuthCodeMutation, GetIntegrationAuthCodeMutationVariables>(
        client,
        GetIntegrationAuthCodeDocument,
        variables,
        headers,
      )(),
    options,
  );
};

export const IntegrationsDocument = `
    query Integrations($companyId: String, $slug: String) {
  Integrations(company_id: $companyId, slug: $slug) {
    integration_id
    name
    slug
    display_name
    scopes
    logo
    webhooks {
      url
      scope
    }
  }
}
    `;

export const useIntegrationsQuery = <TData = IntegrationsQuery, TError = unknown>(
  client: GraphQLClient,
  variables?: IntegrationsQueryVariables,
  options?: UseQueryOptions<IntegrationsQuery, TError, TData>,
  headers?: RequestInit["headers"],
) => {
  return useQuery<IntegrationsQuery, TError, TData>(
    variables === undefined ? ["Integrations"] : ["Integrations", variables],
    fetcher<IntegrationsQuery, IntegrationsQueryVariables>(client, IntegrationsDocument, variables, headers),
    options,
  );
};

export const RevokeAccessTokenDocument = `
    mutation RevokeAccessToken($companyId: String!, $integrationId: String!) {
  RevokeAccessToken(company_id: $companyId, integration_id: $integrationId)
}
    `;

export const useRevokeAccessTokenMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<RevokeAccessTokenMutation, TError, RevokeAccessTokenMutationVariables, TContext>,
  headers?: RequestInit["headers"],
) => {
  return useMutation<RevokeAccessTokenMutation, TError, RevokeAccessTokenMutationVariables, TContext>(
    ["RevokeAccessToken"],
    (variables?: RevokeAccessTokenMutationVariables) =>
      fetcher<RevokeAccessTokenMutation, RevokeAccessTokenMutationVariables>(
        client,
        RevokeAccessTokenDocument,
        variables,
        headers,
      )(),
    options,
  );
};

export const ArchivePendingChecksDocument = `
    mutation archivePendingChecks($companyId: String!, $integrationId: String!, $checks: [String]!) {
  ArchivePendingChecks(
    company_id: $companyId
    integration_id: $integrationId
    checks: $checks
  )
}
    `;

export const useArchivePendingChecksMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<ArchivePendingChecksMutation, TError, ArchivePendingChecksMutationVariables, TContext>,
  headers?: RequestInit["headers"],
) => {
  return useMutation<ArchivePendingChecksMutation, TError, ArchivePendingChecksMutationVariables, TContext>(
    ["archivePendingChecks"],
    (variables?: ArchivePendingChecksMutationVariables) =>
      fetcher<ArchivePendingChecksMutation, ArchivePendingChecksMutationVariables>(
        client,
        ArchivePendingChecksDocument,
        variables,
        headers,
      )(),
    options,
  );
};

export const CreateExternalAutoSignerDocument = `
    mutation createExternalAutoSigner($integration_id: String!, $organization_user_id: String!, $name: String = "", $company_id: String!) {
  CreateExternalAutoSigner(
    integration_id: $integration_id
    organization_user_id: $organization_user_id
    name: $name
    company_id: $company_id
  ) {
    id
    name
    organization_user_id
    company_id
  }
}
    `;

export const useCreateExternalAutoSignerMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    CreateExternalAutoSignerMutation,
    TError,
    CreateExternalAutoSignerMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<CreateExternalAutoSignerMutation, TError, CreateExternalAutoSignerMutationVariables, TContext>(
    ["createExternalAutoSigner"],
    (variables?: CreateExternalAutoSignerMutationVariables) =>
      fetcher<CreateExternalAutoSignerMutation, CreateExternalAutoSignerMutationVariables>(
        client,
        CreateExternalAutoSignerDocument,
        variables,
        headers,
      )(),
    options,
  );
};

export const DeleteExternalAutoSignerDocument = `
    mutation deleteExternalAutoSigner($external_signer_id: String!, $company_id: String!) {
  DeleteExternalAutoSigner(
    external_signer_id: $external_signer_id
    company_id: $company_id
  )
}
    `;

export const useDeleteExternalAutoSignerMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    DeleteExternalAutoSignerMutation,
    TError,
    DeleteExternalAutoSignerMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<DeleteExternalAutoSignerMutation, TError, DeleteExternalAutoSignerMutationVariables, TContext>(
    ["deleteExternalAutoSigner"],
    (variables?: DeleteExternalAutoSignerMutationVariables) =>
      fetcher<DeleteExternalAutoSignerMutation, DeleteExternalAutoSignerMutationVariables>(
        client,
        DeleteExternalAutoSignerDocument,
        variables,
        headers,
      )(),
    options,
  );
};

export const GetPendingChecksDocument = `
    query getPendingChecks($companyId: String!, $integrationId: String!, $pagination: Pagination, $checks: [String], $filters: PendingChecksFilterInput) {
  PendingChecks(
    company_id: $companyId
    integration_id: $integrationId
    pagination: $pagination
    checks: $checks
    filters: $filters
  ) {
    total
    checks {
      id
      recipient_email
      pttoo
      date
      number
      amount
      memo
      status {
        status
        state
        sub_status
      }
      required_actions {
        action
        description
        type
        key
      }
      entity_ref
      role
      sender {
        id
        name
        account_number
        routing_number
        bank_name
      }
      recipient {
        id
        name
        account_number
        routing_number
        bank_name
      }
      action {
        type
        label
        action
        disabled
      }
      options {
        type
        label
        action
        disabled
      }
      related_transactions {
        id
        recipient_email
        pttoo
        date
        number
        amount
      }
      remittance {
        date
        amount
        created_at
      }
      meta {
        label
        order
        value
      }
      gl_account {
        external_ref
        name
        full_name
        active
        description
      }
      errors {
        type
        message
        data
      }
    }
  }
}
    `;

export const useGetPendingChecksQuery = <TData = GetPendingChecksQuery, TError = unknown>(
  client: GraphQLClient,
  variables: GetPendingChecksQueryVariables,
  options?: UseQueryOptions<GetPendingChecksQuery, TError, TData>,
  headers?: RequestInit["headers"],
) => {
  return useQuery<GetPendingChecksQuery, TError, TData>(
    ["getPendingChecks", variables],
    fetcher<GetPendingChecksQuery, GetPendingChecksQueryVariables>(
      client,
      GetPendingChecksDocument,
      variables,
      headers,
    ),
    options,
  );
};

export const ImportPendingChecksDocument = `
    mutation importPendingChecks($companyId: String!, $integrationId: String!, $checks: [ImportPendingChecks]!) {
  ImportPendingChecks(
    company_id: $companyId
    integration_id: $integrationId
    checks: $checks
  ) {
    entity_ref
    success
    errors {
      message
    }
  }
}
    `;

export const useImportPendingChecksMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<ImportPendingChecksMutation, TError, ImportPendingChecksMutationVariables, TContext>,
  headers?: RequestInit["headers"],
) => {
  return useMutation<ImportPendingChecksMutation, TError, ImportPendingChecksMutationVariables, TContext>(
    ["importPendingChecks"],
    (variables?: ImportPendingChecksMutationVariables) =>
      fetcher<ImportPendingChecksMutation, ImportPendingChecksMutationVariables>(
        client,
        ImportPendingChecksDocument,
        variables,
        headers,
      )(),
    options,
  );
};

export const SetIntegrationConfigurationDocument = `
    mutation setIntegrationConfiguration($integrationId: String!, $companyId: String!, $configuration: [IntegrationConfigurationInput]!) {
  IntegrationConfiguration(
    integration_id: $integrationId
    company_id: $companyId
    integration_configuration: $configuration
  )
}
    `;

export const useSetIntegrationConfigurationMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    SetIntegrationConfigurationMutation,
    TError,
    SetIntegrationConfigurationMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    SetIntegrationConfigurationMutation,
    TError,
    SetIntegrationConfigurationMutationVariables,
    TContext
  >(
    ["setIntegrationConfiguration"],
    (variables?: SetIntegrationConfigurationMutationVariables) =>
      fetcher<SetIntegrationConfigurationMutation, SetIntegrationConfigurationMutationVariables>(
        client,
        SetIntegrationConfigurationDocument,
        variables,
        headers,
      )(),
    options,
  );
};

export const UpdateExternalAutoSignerDocument = `
    mutation updateExternalAutoSigner($external_signer_id: String!, $company_id: String!, $name: String!) {
  UpdateExternalAutoSigner(
    external_signer_id: $external_signer_id
    company_id: $company_id
    name: $name
  ) {
    id
    name
    organization_user_id
  }
}
    `;

export const useUpdateExternalAutoSignerMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    UpdateExternalAutoSignerMutation,
    TError,
    UpdateExternalAutoSignerMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<UpdateExternalAutoSignerMutation, TError, UpdateExternalAutoSignerMutationVariables, TContext>(
    ["updateExternalAutoSigner"],
    (variables?: UpdateExternalAutoSignerMutationVariables) =>
      fetcher<UpdateExternalAutoSignerMutation, UpdateExternalAutoSignerMutationVariables>(
        client,
        UpdateExternalAutoSignerDocument,
        variables,
        headers,
      )(),
    options,
  );
};

export const GetNotificationsDocument = `
    query GetNotifications {
  GetNotifications {
    notifications {
      id
      account_id
      viewed
      title
      short_desc
      long_desc
      level
      action_url
      channel
      option_1_title
      option_1_url
      option_2_title
      option_2_url
      company_id
      created_at
    }
    unread_count
  }
}
    `;

export const useGetNotificationsQuery = <TData = GetNotificationsQuery, TError = unknown>(
  client: GraphQLClient,
  variables?: GetNotificationsQueryVariables,
  options?: UseQueryOptions<GetNotificationsQuery, TError, TData>,
  headers?: RequestInit["headers"],
) => {
  return useQuery<GetNotificationsQuery, TError, TData>(
    variables === undefined ? ["GetNotifications"] : ["GetNotifications", variables],
    fetcher<GetNotificationsQuery, GetNotificationsQueryVariables>(
      client,
      GetNotificationsDocument,
      variables,
      headers,
    ),
    options,
  );
};

export const UpdateNotificationDocument = `
    mutation UpdateNotification($notification_id: String!) {
  UpdateNotification(notification_id: $notification_id)
}
    `;

export const useUpdateNotificationMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<UpdateNotificationMutation, TError, UpdateNotificationMutationVariables, TContext>,
  headers?: RequestInit["headers"],
) => {
  return useMutation<UpdateNotificationMutation, TError, UpdateNotificationMutationVariables, TContext>(
    ["UpdateNotification"],
    (variables?: UpdateNotificationMutationVariables) =>
      fetcher<UpdateNotificationMutation, UpdateNotificationMutationVariables>(
        client,
        UpdateNotificationDocument,
        variables,
        headers,
      )(),
    options,
  );
};

export const OrganizationDashboardDocument = `
    query OrganizationDashboard($organization_id: String, $pagination: Pagination, $filters: OrganizationDashboardFilterInput, $get_unvalidated_dashboard: Boolean) {
  OrganizationDashboard(
    organization_id: $organization_id
    pagination: $pagination
    filters: $filters
    get_unvalidated_dashboard: $get_unvalidated_dashboard
  ) {
    check_batching_enabled
    vbill_enabled
    vbill_email
    companies {
      organization_id
      id
      name
      description
      verification_status
      verification_link
      user_subscribed_to_company_notifications
      vbill_enabled
      vbill_email
      bank_accounts {
        id
        name
        nickname
        account_number
        routing_number
        deposit_only
        verification_status
        verification_link
        can_upgrade
        outgoing_checks_stats {
          count
          sum
        }
        incoming_checks_stats {
          count
          sum
        }
        pending_approval_checks_stats {
          count
          sum
        }
      }
    }
    unvalidated_dashboard {
      companies {
        organization_id
        id
        name
        description
        verification_status
        verification_link
        user_subscribed_to_company_notifications
        vbill_enabled
        vbill_email
        bank_accounts {
          id
          name
          nickname
          account_number
          routing_number
          deposit_only
          verification_status
          verification_link
          can_upgrade
          outgoing_checks_stats {
            count
            sum
          }
          incoming_checks_stats {
            count
            sum
          }
          pending_approval_checks_stats {
            count
            sum
          }
        }
      }
      pagination {
        total
        per_page
        on_first_page
        current_page
        last_page
        has_pages
        has_more_pages
        first_item
        last_item
      }
    }
    pagination {
      total
      per_page
      on_first_page
      current_page
      last_page
      has_pages
      has_more_pages
      first_item
      last_item
    }
  }
}
    `;

export const useOrganizationDashboardQuery = <TData = OrganizationDashboardQuery, TError = unknown>(
  client: GraphQLClient,
  variables?: OrganizationDashboardQueryVariables,
  options?: UseQueryOptions<OrganizationDashboardQuery, TError, TData>,
  headers?: RequestInit["headers"],
) => {
  return useQuery<OrganizationDashboardQuery, TError, TData>(
    variables === undefined ? ["OrganizationDashboard"] : ["OrganizationDashboard", variables],
    fetcher<OrganizationDashboardQuery, OrganizationDashboardQueryVariables>(
      client,
      OrganizationDashboardDocument,
      variables,
      headers,
    ),
    options,
  );
};

export const PendingActionsAndChecksCountDocument = `
    query PendingActionsAndChecksCount($organization_id: String, $pagination: Pagination, $filters: OrganizationDashboardFilterInput) {
  OrganizationDashboard(
    organization_id: $organization_id
    pagination: $pagination
    filters: $filters
  ) {
    pending_actions {
      company_name
      bank_account_name
      bank_account_account_number
      verification_link
    }
    companies_pending_checks_count {
      organization_id
      id
      name
      pending_checks_count
    }
  }
}
    `;

export const usePendingActionsAndChecksCountQuery = <TData = PendingActionsAndChecksCountQuery, TError = unknown>(
  client: GraphQLClient,
  variables?: PendingActionsAndChecksCountQueryVariables,
  options?: UseQueryOptions<PendingActionsAndChecksCountQuery, TError, TData>,
  headers?: RequestInit["headers"],
) => {
  return useQuery<PendingActionsAndChecksCountQuery, TError, TData>(
    variables === undefined ? ["PendingActionsAndChecksCount"] : ["PendingActionsAndChecksCount", variables],
    fetcher<PendingActionsAndChecksCountQuery, PendingActionsAndChecksCountQueryVariables>(
      client,
      PendingActionsAndChecksCountDocument,
      variables,
      headers,
    ),
    options,
  );
};

export const OrganizationStructureDocument = `
    query OrganizationStructure($organization_id: String) {
  OrganizationDashboard(organization_id: $organization_id) {
    companies {
      organization_id
      id
      name
      description
      vbill_enabled
      vbill_email
      bank_accounts {
        id
        name
        nickname
        bank_name
        account_number
        deposit_only
        verification_status
        verification_link
      }
    }
  }
}
    `;

export const useOrganizationStructureQuery = <TData = OrganizationStructureQuery, TError = unknown>(
  client: GraphQLClient,
  variables?: OrganizationStructureQueryVariables,
  options?: UseQueryOptions<OrganizationStructureQuery, TError, TData>,
  headers?: RequestInit["headers"],
) => {
  return useQuery<OrganizationStructureQuery, TError, TData>(
    variables === undefined ? ["OrganizationStructure"] : ["OrganizationStructure", variables],
    fetcher<OrganizationStructureQuery, OrganizationStructureQueryVariables>(
      client,
      OrganizationStructureDocument,
      variables,
      headers,
    ),
    options,
  );
};

export const UpdateCompanyNotificationSubscriptionDocument = `
    mutation UpdateCompanyNotificationSubscription($company_id: String!, $email_notification: Boolean!) {
  UpdateCompanyNotificationSubscription(
    company_id: $company_id
    email_notification: $email_notification
  ) {
    id
    company_id
    account_id
    email_notification
  }
}
    `;

export const useUpdateCompanyNotificationSubscriptionMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    UpdateCompanyNotificationSubscriptionMutation,
    TError,
    UpdateCompanyNotificationSubscriptionMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    UpdateCompanyNotificationSubscriptionMutation,
    TError,
    UpdateCompanyNotificationSubscriptionMutationVariables,
    TContext
  >(
    ["UpdateCompanyNotificationSubscription"],
    (variables?: UpdateCompanyNotificationSubscriptionMutationVariables) =>
      fetcher<UpdateCompanyNotificationSubscriptionMutation, UpdateCompanyNotificationSubscriptionMutationVariables>(
        client,
        UpdateCompanyNotificationSubscriptionDocument,
        variables,
        headers,
      )(),
    options,
  );
};

export const UpdateOrganizationDocument = `
    mutation UpdateOrganization($organizationId: String!, $name: String) {
  UpdateOrganization(organization_id: $organizationId, name: $name) {
    id
    name
  }
}
    `;

export const useUpdateOrganizationMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<UpdateOrganizationMutation, TError, UpdateOrganizationMutationVariables, TContext>,
  headers?: RequestInit["headers"],
) => {
  return useMutation<UpdateOrganizationMutation, TError, UpdateOrganizationMutationVariables, TContext>(
    ["UpdateOrganization"],
    (variables?: UpdateOrganizationMutationVariables) =>
      fetcher<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>(
        client,
        UpdateOrganizationDocument,
        variables,
        headers,
      )(),
    options,
  );
};

export const PingDocument = `
    query Ping {
  Ping
}
    `;

export const usePingQuery = <TData = PingQuery, TError = unknown>(
  client: GraphQLClient,
  variables?: PingQueryVariables,
  options?: UseQueryOptions<PingQuery, TError, TData>,
  headers?: RequestInit["headers"],
) => {
  return useQuery<PingQuery, TError, TData>(
    variables === undefined ? ["Ping"] : ["Ping", variables],
    fetcher<PingQuery, PingQueryVariables>(client, PingDocument, variables, headers),
    options,
  );
};

export const SessionAccountDocument = `
    query SessionAccount {
  SessionAccount {
    account {
      id
      username
      name
      email
      email_address_id
      phone_number
      email_verified
      phone_number_verified
    }
    deposit_accounts
    organizationUsers {
      id
      organization {
        id
        name
        companies {
          id
          name
          vbill_enabled
          company_integrations {
            scopes
            integration {
              integration_id
              slug
            }
          }
        }
        feature_access_requests {
          feature
        }
      }
      user_groups {
        id
        name
      }
    }
    unconfirmed_bank_accounts {
      id
      name
      account_number
      routing_number
      bank_name
    }
    has_in_progress_onboarding
    has_pending_invites
    organizations_where_is_manager
  }
}
    `;

export const useSessionAccountQuery = <TData = SessionAccountQuery, TError = unknown>(
  client: GraphQLClient,
  variables?: SessionAccountQueryVariables,
  options?: UseQueryOptions<SessionAccountQuery, TError, TData>,
  headers?: RequestInit["headers"],
) => {
  return useQuery<SessionAccountQuery, TError, TData>(
    variables === undefined ? ["SessionAccount"] : ["SessionAccount", variables],
    fetcher<SessionAccountQuery, SessionAccountQueryVariables>(client, SessionAccountDocument, variables, headers),
    options,
  );
};

export const GetSharedFiltersQueryDocument = `
    query GetSharedFiltersQuery($token: String!) {
  GetSharedFiltersQuery(token: $token) {
    token
    filters
    context
    usages
  }
}
    `;

export const useGetSharedFiltersQueryQuery = <TData = GetSharedFiltersQueryQuery, TError = unknown>(
  client: GraphQLClient,
  variables: GetSharedFiltersQueryQueryVariables,
  options?: UseQueryOptions<GetSharedFiltersQueryQuery, TError, TData>,
  headers?: RequestInit["headers"],
) => {
  return useQuery<GetSharedFiltersQueryQuery, TError, TData>(
    ["GetSharedFiltersQuery", variables],
    fetcher<GetSharedFiltersQueryQuery, GetSharedFiltersQueryQueryVariables>(
      client,
      GetSharedFiltersQueryDocument,
      variables,
      headers,
    ),
    options,
  );
};

export const SaveSharedFiltersMutationDocument = `
    mutation SaveSharedFiltersMutation($organization_id: String, $context: String!, $filters: String!) {
  SaveSharedFiltersMutation(
    organization_id: $organization_id
    context: $context
    filters: $filters
  )
}
    `;

export const useSaveSharedFiltersMutationMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    SaveSharedFiltersMutationMutation,
    TError,
    SaveSharedFiltersMutationMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<SaveSharedFiltersMutationMutation, TError, SaveSharedFiltersMutationMutationVariables, TContext>(
    ["SaveSharedFiltersMutation"],
    (variables?: SaveSharedFiltersMutationMutationVariables) =>
      fetcher<SaveSharedFiltersMutationMutation, SaveSharedFiltersMutationMutationVariables>(
        client,
        SaveSharedFiltersMutationDocument,
        variables,
        headers,
      )(),
    options,
  );
};

export const AccountUpdatePasswordDocument = `
    mutation AccountUpdatePassword($password_current: String!, $password: String!, $password_confirmation: String!) {
  AccountUpdatePassword(
    password_current: $password_current
    password: $password
    password_confirmation: $password_confirmation
  )
}
    `;

export const useAccountUpdatePasswordMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<AccountUpdatePasswordMutation, TError, AccountUpdatePasswordMutationVariables, TContext>,
  headers?: RequestInit["headers"],
) => {
  return useMutation<AccountUpdatePasswordMutation, TError, AccountUpdatePasswordMutationVariables, TContext>(
    ["AccountUpdatePassword"],
    (variables?: AccountUpdatePasswordMutationVariables) =>
      fetcher<AccountUpdatePasswordMutation, AccountUpdatePasswordMutationVariables>(
        client,
        AccountUpdatePasswordDocument,
        variables,
        headers,
      )(),
    options,
  );
};

export const AcceptInviteDocument = `
    mutation AcceptInvite($invite_id: String!) {
  AcceptInvite(invite_id: $invite_id)
}
    `;

export const useAcceptInviteMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<AcceptInviteMutation, TError, AcceptInviteMutationVariables, TContext>,
  headers?: RequestInit["headers"],
) => {
  return useMutation<AcceptInviteMutation, TError, AcceptInviteMutationVariables, TContext>(
    ["AcceptInvite"],
    (variables?: AcceptInviteMutationVariables) =>
      fetcher<AcceptInviteMutation, AcceptInviteMutationVariables>(client, AcceptInviteDocument, variables, headers)(),
    options,
  );
};

export const AccountInvitesDocument = `
    query AccountInvites {
  AccountInvites {
    id
    email
    organization_name
    inviter {
      id
      name
      email
    }
    status
  }
}
    `;

export const useAccountInvitesQuery = <TData = AccountInvitesQuery, TError = unknown>(
  client: GraphQLClient,
  variables?: AccountInvitesQueryVariables,
  options?: UseQueryOptions<AccountInvitesQuery, TError, TData>,
  headers?: RequestInit["headers"],
) => {
  return useQuery<AccountInvitesQuery, TError, TData>(
    variables === undefined ? ["AccountInvites"] : ["AccountInvites", variables],
    fetcher<AccountInvitesQuery, AccountInvitesQueryVariables>(client, AccountInvitesDocument, variables, headers),
    options,
  );
};

export const AccountPhoneNumberVerificationCodeRequestDocument = `
    mutation AccountPhoneNumberVerificationCodeRequest($phone_number: String!, $channel: String!) {
  AccountPhoneNumberVerificationCodeRequest(
    phone_number: $phone_number
    channel: $channel
  )
}
    `;

export const useAccountPhoneNumberVerificationCodeRequestMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    AccountPhoneNumberVerificationCodeRequestMutation,
    TError,
    AccountPhoneNumberVerificationCodeRequestMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    AccountPhoneNumberVerificationCodeRequestMutation,
    TError,
    AccountPhoneNumberVerificationCodeRequestMutationVariables,
    TContext
  >(
    ["AccountPhoneNumberVerificationCodeRequest"],
    (variables?: AccountPhoneNumberVerificationCodeRequestMutationVariables) =>
      fetcher<
        AccountPhoneNumberVerificationCodeRequestMutation,
        AccountPhoneNumberVerificationCodeRequestMutationVariables
      >(client, AccountPhoneNumberVerificationCodeRequestDocument, variables, headers)(),
    options,
  );
};

export const AccountPhoneNumberVerificationCodeVerifyDocument = `
    mutation AccountPhoneNumberVerificationCodeVerify($code: String!) {
  AccountPhoneNumberVerificationCodeVerify(code: $code)
}
    `;

export const useAccountPhoneNumberVerificationCodeVerifyMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    AccountPhoneNumberVerificationCodeVerifyMutation,
    TError,
    AccountPhoneNumberVerificationCodeVerifyMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    AccountPhoneNumberVerificationCodeVerifyMutation,
    TError,
    AccountPhoneNumberVerificationCodeVerifyMutationVariables,
    TContext
  >(
    ["AccountPhoneNumberVerificationCodeVerify"],
    (variables?: AccountPhoneNumberVerificationCodeVerifyMutationVariables) =>
      fetcher<
        AccountPhoneNumberVerificationCodeVerifyMutation,
        AccountPhoneNumberVerificationCodeVerifyMutationVariables
      >(client, AccountPhoneNumberVerificationCodeVerifyDocument, variables, headers)(),
    options,
  );
};

export const AddUserToGroupDocument = `
    mutation AddUserToGroup($organization_id: String!, $organization_user_id: String!, $user_group_id: String!) {
  AddUserToGroup(
    organization_id: $organization_id
    organization_user_id: $organization_user_id
    user_group_id: $user_group_id
  )
}
    `;

export const useAddUserToGroupMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<AddUserToGroupMutation, TError, AddUserToGroupMutationVariables, TContext>,
  headers?: RequestInit["headers"],
) => {
  return useMutation<AddUserToGroupMutation, TError, AddUserToGroupMutationVariables, TContext>(
    ["AddUserToGroup"],
    (variables?: AddUserToGroupMutationVariables) =>
      fetcher<AddUserToGroupMutation, AddUserToGroupMutationVariables>(
        client,
        AddUserToGroupDocument,
        variables,
        headers,
      )(),
    options,
  );
};

export const CreateUserGroupDocument = `
    mutation CreateUserGroup($name: String!, $description: String, $organization_id: String!) {
  CreateUserGroup(
    name: $name
    description: $description
    organization_id: $organization_id
  ) {
    id
    name
    description
    organization_users {
      id
      organization {
        id
      }
      account {
        id
      }
      acl_permissions {
        id
      }
      user_groups {
        id
      }
    }
  }
}
    `;

export const useCreateUserGroupMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<CreateUserGroupMutation, TError, CreateUserGroupMutationVariables, TContext>,
  headers?: RequestInit["headers"],
) => {
  return useMutation<CreateUserGroupMutation, TError, CreateUserGroupMutationVariables, TContext>(
    ["CreateUserGroup"],
    (variables?: CreateUserGroupMutationVariables) =>
      fetcher<CreateUserGroupMutation, CreateUserGroupMutationVariables>(
        client,
        CreateUserGroupDocument,
        variables,
        headers,
      )(),
    options,
  );
};

export const DeleteInviteDocument = `
    mutation DeleteInvite($invite_id: String!) {
  DeleteInvite(invite_id: $invite_id)
}
    `;

export const useDeleteInviteMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<DeleteInviteMutation, TError, DeleteInviteMutationVariables, TContext>,
  headers?: RequestInit["headers"],
) => {
  return useMutation<DeleteInviteMutation, TError, DeleteInviteMutationVariables, TContext>(
    ["DeleteInvite"],
    (variables?: DeleteInviteMutationVariables) =>
      fetcher<DeleteInviteMutation, DeleteInviteMutationVariables>(client, DeleteInviteDocument, variables, headers)(),
    options,
  );
};

export const DeleteUserGroupDocument = `
    mutation DeleteUserGroup($user_group_id: String!) {
  DeleteUserGroup(user_group_id: $user_group_id)
}
    `;

export const useDeleteUserGroupMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<DeleteUserGroupMutation, TError, DeleteUserGroupMutationVariables, TContext>,
  headers?: RequestInit["headers"],
) => {
  return useMutation<DeleteUserGroupMutation, TError, DeleteUserGroupMutationVariables, TContext>(
    ["DeleteUserGroup"],
    (variables?: DeleteUserGroupMutationVariables) =>
      fetcher<DeleteUserGroupMutation, DeleteUserGroupMutationVariables>(
        client,
        DeleteUserGroupDocument,
        variables,
        headers,
      )(),
    options,
  );
};

export const LeaveOrganizationDocument = `
    mutation LeaveOrganization($organization_user_id: String!) {
  LeaveOrganization(organization_user_id: $organization_user_id)
}
    `;

export const useLeaveOrganizationMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<LeaveOrganizationMutation, TError, LeaveOrganizationMutationVariables, TContext>,
  headers?: RequestInit["headers"],
) => {
  return useMutation<LeaveOrganizationMutation, TError, LeaveOrganizationMutationVariables, TContext>(
    ["LeaveOrganization"],
    (variables?: LeaveOrganizationMutationVariables) =>
      fetcher<LeaveOrganizationMutation, LeaveOrganizationMutationVariables>(
        client,
        LeaveOrganizationDocument,
        variables,
        headers,
      )(),
    options,
  );
};

export const OrganizationUsersDocument = `
    query OrganizationUsers($organization_id: String!) {
  OrganizationUsers(organization_id: $organization_id) {
    id
    state
    account {
      id
      name
      email
      state
    }
    user_groups {
      id
      name
    }
    invites_ids
  }
}
    `;

export const useOrganizationUsersQuery = <TData = OrganizationUsersQuery, TError = unknown>(
  client: GraphQLClient,
  variables: OrganizationUsersQueryVariables,
  options?: UseQueryOptions<OrganizationUsersQuery, TError, TData>,
  headers?: RequestInit["headers"],
) => {
  return useQuery<OrganizationUsersQuery, TError, TData>(
    ["OrganizationUsers", variables],
    fetcher<OrganizationUsersQuery, OrganizationUsersQueryVariables>(
      client,
      OrganizationUsersDocument,
      variables,
      headers,
    ),
    options,
  );
};

export const PromoteUnvalidatedUserDocument = `
    mutation PromoteUnvalidatedUser($organization_user_id: String!) {
  PromoteUnvalidatedUser(organization_user_id: $organization_user_id)
}
    `;

export const usePromoteUnvalidatedUserMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    PromoteUnvalidatedUserMutation,
    TError,
    PromoteUnvalidatedUserMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<PromoteUnvalidatedUserMutation, TError, PromoteUnvalidatedUserMutationVariables, TContext>(
    ["PromoteUnvalidatedUser"],
    (variables?: PromoteUnvalidatedUserMutationVariables) =>
      fetcher<PromoteUnvalidatedUserMutation, PromoteUnvalidatedUserMutationVariables>(
        client,
        PromoteUnvalidatedUserDocument,
        variables,
        headers,
      )(),
    options,
  );
};

export const RejectInviteDocument = `
    mutation RejectInvite($invite_id: String!) {
  RejectInvite(invite_id: $invite_id)
}
    `;

export const useRejectInviteMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<RejectInviteMutation, TError, RejectInviteMutationVariables, TContext>,
  headers?: RequestInit["headers"],
) => {
  return useMutation<RejectInviteMutation, TError, RejectInviteMutationVariables, TContext>(
    ["RejectInvite"],
    (variables?: RejectInviteMutationVariables) =>
      fetcher<RejectInviteMutation, RejectInviteMutationVariables>(client, RejectInviteDocument, variables, headers)(),
    options,
  );
};

export const RemoveUserFromGroupDocument = `
    mutation RemoveUserFromGroup($organization_id: String!, $organization_user_id: String!, $user_group_id: String!) {
  RemoveUserFromGroup(
    organization_id: $organization_id
    organization_user_id: $organization_user_id
    user_group_id: $user_group_id
  )
}
    `;

export const useRemoveUserFromGroupMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<RemoveUserFromGroupMutation, TError, RemoveUserFromGroupMutationVariables, TContext>,
  headers?: RequestInit["headers"],
) => {
  return useMutation<RemoveUserFromGroupMutation, TError, RemoveUserFromGroupMutationVariables, TContext>(
    ["RemoveUserFromGroup"],
    (variables?: RemoveUserFromGroupMutationVariables) =>
      fetcher<RemoveUserFromGroupMutation, RemoveUserFromGroupMutationVariables>(
        client,
        RemoveUserFromGroupDocument,
        variables,
        headers,
      )(),
    options,
  );
};

export const UpdateUserGroupDocument = `
    mutation UpdateUserGroup($name: String!, $description: String, $user_group_id: String!) {
  UpdateUserGroup(
    name: $name
    description: $description
    user_group_id: $user_group_id
  ) {
    id
    name
    description
    organization_users {
      id
      organization {
        id
        name
      }
      account {
        id
        name
        email
      }
      acl_permissions {
        id
        entity_type
      }
      user_groups {
        id
        name
        organization_users {
          id
        }
      }
    }
  }
}
    `;

export const useUpdateUserGroupMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<UpdateUserGroupMutation, TError, UpdateUserGroupMutationVariables, TContext>,
  headers?: RequestInit["headers"],
) => {
  return useMutation<UpdateUserGroupMutation, TError, UpdateUserGroupMutationVariables, TContext>(
    ["UpdateUserGroup"],
    (variables?: UpdateUserGroupMutationVariables) =>
      fetcher<UpdateUserGroupMutation, UpdateUserGroupMutationVariables>(
        client,
        UpdateUserGroupDocument,
        variables,
        headers,
      )(),
    options,
  );
};

export const UserGroupsDocument = `
    query UserGroups($organization_id: String!) {
  UserGroups(organization_id: $organization_id) {
    id
    name
    description
    organization_users {
      id
    }
  }
}
    `;

export const useUserGroupsQuery = <TData = UserGroupsQuery, TError = unknown>(
  client: GraphQLClient,
  variables: UserGroupsQueryVariables,
  options?: UseQueryOptions<UserGroupsQuery, TError, TData>,
  headers?: RequestInit["headers"],
) => {
  return useQuery<UserGroupsQuery, TError, TData>(
    ["UserGroups", variables],
    fetcher<UserGroupsQuery, UserGroupsQueryVariables>(client, UserGroupsDocument, variables, headers),
    options,
  );
};

export const CreateCheckNoteDocument = `
    mutation CreateCheckNote($check_id: String!, $note: String, $shared: Boolean, $context: Int!, $attachments: [Upload]) {
  CreateCheckNote(
    check_id: $check_id
    note: $note
    shared: $shared
    context: $context
    attachments: $attachments
  ) {
    note
    type
    context
    can_manage
    attachments {
      context
      sort_order
      media {
        id
        name
        path
        filename
        size
        type
      }
    }
    created_at
  }
}
    `;

export const useCreateCheckNoteMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<CreateCheckNoteMutation, TError, CreateCheckNoteMutationVariables, TContext>,
  headers?: RequestInit["headers"],
) => {
  return useMutation<CreateCheckNoteMutation, TError, CreateCheckNoteMutationVariables, TContext>(
    ["CreateCheckNote"],
    (variables?: CreateCheckNoteMutationVariables) =>
      fetcher<CreateCheckNoteMutation, CreateCheckNoteMutationVariables>(
        client,
        CreateCheckNoteDocument,
        variables,
        headers,
      )(),
    options,
  );
};

export const DepositChecksMutationDocument = `
    mutation DepositChecksMutation($bank_account_id: String!, $checks: [String!]!) {
  DepositChecksMutation(bank_account_id: $bank_account_id, checks: $checks) {
    successes
    errors {
      message
      check_id
    }
  }
}
    `;

export const useDepositChecksMutationMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<DepositChecksMutationMutation, TError, DepositChecksMutationMutationVariables, TContext>,
  headers?: RequestInit["headers"],
) => {
  return useMutation<DepositChecksMutationMutation, TError, DepositChecksMutationMutationVariables, TContext>(
    ["DepositChecksMutation"],
    (variables?: DepositChecksMutationMutationVariables) =>
      fetcher<DepositChecksMutationMutation, DepositChecksMutationMutationVariables>(
        client,
        DepositChecksMutationDocument,
        variables,
        headers,
      )(),
    options,
  );
};

export const UpdateCheckDocument = `
    mutation UpdateCheck($id: String!, $pttoo: String, $memo: String) {
  UpdateCheck(id: $id, pttoo: $pttoo, memo: $memo) {
    id
    recipient_email
    pttoo
    date
    number
    amount
    memo
    status {
      status
      state
      sub_status
    }
    required_actions {
      action
      description
      type
      key
    }
    entity_ref
    role
    sender {
      id
      name
      account_number
      routing_number
      bank_name
    }
    recipient {
      id
      name
      account_number
      routing_number
      bank_name
    }
    action {
      type
      label
      action
      disabled
      priority
      message
    }
    options {
      type
      label
      action
      disabled
      priority
      message
    }
    related_transactions {
      id
      recipient_email
      pttoo
      date
      number
      amount
      memo
      status {
        status
        state
        sub_status
      }
      role
      sender {
        id
        name
        account_number
        routing_number
        bank_name
      }
      sender_title
      sender_subtitle
      recipient {
        id
        name
        account_number
        routing_number
        bank_name
      }
      recipient_title
      recipient_subtitle
      action {
        type
        label
        action
        disabled
      }
      options {
        type
        label
        action
        disabled
      }
    }
    remittance {
      date
      amount
      created_at
      meta {
        label
        order
        value
      }
    }
    meta {
      label
      order
      value
    }
  }
}
    `;

export const useUpdateCheckMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<UpdateCheckMutation, TError, UpdateCheckMutationVariables, TContext>,
  headers?: RequestInit["headers"],
) => {
  return useMutation<UpdateCheckMutation, TError, UpdateCheckMutationVariables, TContext>(
    ["UpdateCheck"],
    (variables?: UpdateCheckMutationVariables) =>
      fetcher<UpdateCheckMutation, UpdateCheckMutationVariables>(client, UpdateCheckDocument, variables, headers)(),
    options,
  );
};

export const VCheckCheckQueryDocument = `
    query VCheckCheckQuery($id: String!, $bank_account_id: String) {
  VCheckCheckQuery(id: $id, bank_account_id: $bank_account_id) {
    meta {
      label
      order
      value
    }
    ledger_check {
      id
      recipient_email
      pttoo
      date
      number
      amount
      memo
      voided_at
      status {
        status
        state
        sub_status
      }
      required_actions {
        action
        description
        type
        key
        is_blocker
      }
      role
      sender {
        id
        name
        account_number
        routing_number
        bank_name
        company_id
        company_name
        organization_id
        organization_name
      }
      sender_title
      sender_subtitle
      recipient {
        id
        name
        account_number
        routing_number
        bank_name
        company_id
        company_name
        organization_id
        organization_name
      }
      recipient_title
      recipient_subtitle
      vendor_is_valid
      action {
        type
        label
        action
        disabled
        priority
        message
      }
      options {
        type
        label
        action
        disabled
        priority
        message
      }
      related_by
      chat_details {
        chat_status
        chat_activity
      }
      bank_account_info {
        account_number
        routing_number
        bank_name
      }
      global_vendor {
        name
        email
        address
      }
      check_type
    }
    remittances {
      entity
      entity_ref
      date
      amount
      meta {
        label
        order
        value
      }
      created_at
    }
    activity {
      id
      title
      note
      type
      context
      can_manage
      attachments {
        context
        sort_order
        show_download
        media {
          id
          name
          type
          size
          path
          filename
        }
      }
      created_at
      username
    }
    timeline {
      events {
        label
        date
        status
        description {
          description
          static
          url
        }
        flags {
          flag
          label
        }
        images {
          id
          label
          name
          path
          type
        }
      }
    }
    other_context_available
    other_context_organization_id
  }
}
    `;

export const useVCheckCheckQueryQuery = <TData = VCheckCheckQueryQuery, TError = unknown>(
  client: GraphQLClient,
  variables: VCheckCheckQueryQueryVariables,
  options?: UseQueryOptions<VCheckCheckQueryQuery, TError, TData>,
  headers?: RequestInit["headers"],
) => {
  return useQuery<VCheckCheckQueryQuery, TError, TData>(
    ["VCheckCheckQuery", variables],
    fetcher<VCheckCheckQueryQuery, VCheckCheckQueryQueryVariables>(
      client,
      VCheckCheckQueryDocument,
      variables,
      headers,
    ),
    options,
  );
};

export const VCheckChecksDocument = `
    query VCheckChecks($organization_id: String, $filters: CheckFiltersType, $pagination: Pagination) {
  VCheckChecks(
    organization_id: $organization_id
    filters: $filters
    pagination: $pagination
  ) {
    checks {
      id
      pttoo
      date
      number
      amount
      memo
      status {
        status
        state
        sub_status
      }
      required_actions {
        action
        description
        type
        key
        is_blocker
      }
      deposited_at
      created_at
      signed_at
      role
      sender {
        id
        name
        account_number
        routing_number
        bank_name
        company_id
        company_name
        organization_id
        organization_name
      }
      recipient {
        id
        name
        account_number
        routing_number
        bank_name
        company_id
        company_name
        organization_id
        organization_name
      }
      action {
        type
        label
        action
        disabled
        priority
        message
      }
      options {
        type
        label
        action
        disabled
        priority
        message
      }
      sender_title
      sender_subtitle
      recipient_title
      recipient_subtitle
      recipient_email
      vendor_is_valid
      chat_details {
        chat_status
        chat_activity
      }
      bank_account_info {
        account_number
        routing_number
        bank_name
      }
      global_vendor {
        name
        email
        address
      }
      check_type
    }
    pagination {
      total
      per_page
      on_first_page
      current_page
      last_page
      has_pages
      has_more_pages
      first_item
      last_item
    }
  }
}
    `;

export const useVCheckChecksQuery = <TData = VCheckChecksQuery, TError = unknown>(
  client: GraphQLClient,
  variables?: VCheckChecksQueryVariables,
  options?: UseQueryOptions<VCheckChecksQuery, TError, TData>,
  headers?: RequestInit["headers"],
) => {
  return useQuery<VCheckChecksQuery, TError, TData>(
    variables === undefined ? ["VCheckChecks"] : ["VCheckChecks", variables],
    fetcher<VCheckChecksQuery, VCheckChecksQueryVariables>(client, VCheckChecksDocument, variables, headers),
    options,
  );
};

export const VCheckRelatedTransactionsDocument = `
    query VCheckRelatedTransactions($id: String!, $bank_account_id: String) {
  VCheckCheckQuery(id: $id, bank_account_id: $bank_account_id) {
    related_transactions {
      id
      recipient_email
      pttoo
      date
      number
      amount
      memo
      voided_at
      status {
        status
        state
        sub_status
      }
      required_actions {
        action
        description
        type
        key
      }
      role
      sender {
        id
        name
        account_number
        routing_number
        bank_name
      }
      sender_title
      sender_subtitle
      recipient {
        id
        name
        account_number
        routing_number
        bank_name
      }
      recipient_title
      recipient_subtitle
      action {
        type
        label
        action
        disabled
        priority
        message
      }
      options {
        type
        label
        action
        disabled
        priority
        message
      }
      related_by
    }
  }
}
    `;

export const useVCheckRelatedTransactionsQuery = <TData = VCheckRelatedTransactionsQuery, TError = unknown>(
  client: GraphQLClient,
  variables: VCheckRelatedTransactionsQueryVariables,
  options?: UseQueryOptions<VCheckRelatedTransactionsQuery, TError, TData>,
  headers?: RequestInit["headers"],
) => {
  return useQuery<VCheckRelatedTransactionsQuery, TError, TData>(
    ["VCheckRelatedTransactions", variables],
    fetcher<VCheckRelatedTransactionsQuery, VCheckRelatedTransactionsQueryVariables>(
      client,
      VCheckRelatedTransactionsDocument,
      variables,
      headers,
    ),
    options,
  );
};

export const VCheckUndepositedChecksDocument = `
    query VCheckUndepositedChecks($filters: VCheckUndepositedChecksFilterInput, $pagination: Pagination) {
  VCheckUndepositedChecks(filters: $filters, pagination: $pagination) {
    checks {
      id
      recipient_email
      pttoo
      date
      number
      amount
      memo
      status {
        status
        state
        sub_status
      }
      required_actions {
        action
        description
        type
        key
      }
      role
      sender {
        id
        name
        account_number
        routing_number
        bank_name
      }
      recipient {
        id
        name
        account_number
        routing_number
        bank_name
      }
      action {
        type
        label
        action
        disabled
        priority
        message
      }
      options {
        type
        label
        action
        disabled
        priority
        message
      }
      sender_title
      sender_subtitle
      recipient_title
      recipient_subtitle
      related_by
    }
    undeposited_checks {
      count
      sum
    }
    delay_bank_holiday
    delay_weekend
    pagination {
      total
      per_page
      on_first_page
      current_page
      last_page
      has_pages
      has_more_pages
      first_item
      last_item
    }
  }
}
    `;

export const useVCheckUndepositedChecksQuery = <TData = VCheckUndepositedChecksQuery, TError = unknown>(
  client: GraphQLClient,
  variables?: VCheckUndepositedChecksQueryVariables,
  options?: UseQueryOptions<VCheckUndepositedChecksQuery, TError, TData>,
  headers?: RequestInit["headers"],
) => {
  return useQuery<VCheckUndepositedChecksQuery, TError, TData>(
    variables === undefined ? ["VCheckUndepositedChecks"] : ["VCheckUndepositedChecks", variables],
    fetcher<VCheckUndepositedChecksQuery, VCheckUndepositedChecksQueryVariables>(
      client,
      VCheckUndepositedChecksDocument,
      variables,
      headers,
    ),
    options,
  );
};

export const VCheckChecksStatsDocument = `
    query VCheckChecksStats($organization_id: String, $filters: CheckFiltersType, $quick_filters: [CheckQuickFiltersEnumType]) {
  VCheckChecks(
    organization_id: $organization_id
    filters: $filters
    quick_filters: $quick_filters
  ) {
    quick_filters {
      count
      sum
      quick_filter
    }
  }
}
    `;

export const useVCheckChecksStatsQuery = <TData = VCheckChecksStatsQuery, TError = unknown>(
  client: GraphQLClient,
  variables?: VCheckChecksStatsQueryVariables,
  options?: UseQueryOptions<VCheckChecksStatsQuery, TError, TData>,
  headers?: RequestInit["headers"],
) => {
  return useQuery<VCheckChecksStatsQuery, TError, TData>(
    variables === undefined ? ["VCheckChecksStats"] : ["VCheckChecksStats", variables],
    fetcher<VCheckChecksStatsQuery, VCheckChecksStatsQueryVariables>(
      client,
      VCheckChecksStatsDocument,
      variables,
      headers,
    ),
    options,
  );
};
