import { LoadingButton } from "@mui/lab";
import { Box, Tooltip } from "@mui/material";
import { CHDecimal } from "common/helpers/decimal";
import { IVBillInvoiceUserActionShowIn, IVBillInvoiceUserActionType } from "generated/sdk.vbill";
import { observer } from "mobx-react";
import { useMemo, useState } from "react";
import { SubmitHandler, useFormContext } from "react-hook-form";
import { useHistory, useParams } from "react-router";
import { useStore } from "storeContainer";
import { COLORS } from "themes/default";
import { VBillToolbarBackBtn } from "../../common/VBillToolbar/VBillToolbarBackBtn/VBillToolbarBackBtn";
import { VBillToolbarBillIdInfo } from "../../common/VBillToolbar/VBillToolbarBillIdInfo/VBillToolbarBillIdInfo";
import { VBillToolbarFormErrors } from "../../common/VBillToolbar/VBillToolbarFormErrors/VBillToolbarFormErrors";
import { vBillToolbarInnerSx, vBillToolbarLeftColSx, vBillToolbarOuterSx } from "../../common/VBillToolbar/styles";
import { TVBillFormFields } from "../../types";
import { IncomingToolbarTotalDifferenceAlert } from "./IncomingToolbarTotalDifferenceAlert/IncomingToolbarTotalDifferenceAlert";
import { adaptIncomingVBillFormToInvoiceInputType, invoiceDateMoreThenFieldErrorMsg } from "./utils";

export const IncomingToolbar = observer(() => {
  const vBillStore = useStore("VBillStore");
  const { organizationId } = useParams<{ organizationId: string }>();
  const { invoice } = vBillStore;
  const invoiceData = useMemo(() => invoice.data, [invoice.data]);
  const formMethods = useFormContext<TVBillFormFields>();
  const history = useHistory();
  const [isSaveDraftReqPending, setIsSaveDraftReqPending] = useState(false);
  const [isCompleteOcrReqPending, setIsCompleteOcrReqPending] = useState(false);
  const [totalDifferenceAlertVisible, setTotalDifferenceAlertVisible] = useState(false);
  const submitBtns = useMemo(
    () =>
      (invoiceData?.nextUserActions ?? [])
        .filter(
          (action) =>
            action.showIn === IVBillInvoiceUserActionShowIn.InvoiceDetails &&
            (action.type === IVBillInvoiceUserActionType.CompleteOcr ||
              action.type === IVBillInvoiceUserActionType.SaveDraft),
        )
        .sort((a) => (a.type === IVBillInvoiceUserActionType.SaveDraft ? -1 : 1)),
    [invoiceData?.nextUserActions],
  );

  const updateInvoiceAndMappingsWithoutValidation = async (formFields: TVBillFormFields, saveDraft?: boolean) => {
    const toSendInvoiceData = adaptIncomingVBillFormToInvoiceInputType(formFields, invoiceData?.id ?? 0);
    return await vBillStore.updateInvoiceAndMappings({
      invoiceId: invoiceData?.id ?? 0,
      invoiceData: toSendInvoiceData,
      skipAdditionalMappingsValidations: true,
      saveDraft,
    });
  };

  const handleSaveDraftSubmit = async () => {
    const formFields = formMethods.getValues();

    setIsSaveDraftReqPending(true);
    await updateInvoiceAndMappingsWithoutValidation(formFields, true);
    setIsSaveDraftReqPending(false);
    formMethods.clearErrors();
  };

  const handleCompleteOcrSubmit: SubmitHandler<TVBillFormFields> = async (formFields) => {
    const invoiceDateTime = new Date(formFields.invoiceDate ?? 0).getTime();
    const invoiceDateDueTime = new Date(formFields.invoiceDateDue ?? 0).getTime();

    if (invoiceDateDueTime < invoiceDateTime) {
      return formMethods.setError("invoiceDateDue", {
        type: "custom",
        message: invoiceDateMoreThenFieldErrorMsg,
      });
    }

    const totalFormItemsAmountTwoDec = new CHDecimal(
      (formFields.lineItems ?? []).reduce((acc, val) => {
        if (val.amount === "-") {
          return acc;
        }

        return (acc = CHDecimal.add(acc, val.amount.length ? val.amount : "0").toString());
      }, "0"),
    ).toFixed(2);
    const amountTwoDec = new CHDecimal(formFields.amount ?? "0").toFixed(2);

    if (formFields.hasLineItems && totalFormItemsAmountTwoDec !== amountTwoDec) {
      setTotalDifferenceAlertVisible(true);

      return;
    }

    setIsCompleteOcrReqPending(true);

    const updateInvoiceAndMappingsResp = await updateInvoiceAndMappingsWithoutValidation(formFields);

    setIsCompleteOcrReqPending(false);

    if (updateInvoiceAndMappingsResp) {
      const navigateToUrl = `/org/${organizationId}/vbill/${invoiceData?.id ?? ""}/new`;

      history.push(navigateToUrl);
    }
  };

  const handleTotalDifferentAlertSubmit = async () => {
    const formFields = formMethods.getValues();
    const totalFormItemsAmount = (formFields.lineItems ?? []).reduce((acc, val) => {
      if (val.amount === "-") {
        return acc;
      }

      return (acc = CHDecimal.add(acc, val.amount.length ? val.amount : "0").toString());
    }, "0");
    const updateInvoiceAndMappingsResp = await updateInvoiceAndMappingsWithoutValidation({
      ...formFields,
      amount: totalFormItemsAmount,
    });

    if (updateInvoiceAndMappingsResp) {
      const navigateToUrl = `/org/${organizationId}/vbill/${invoiceData?.id ?? ""}/new`;

      history.push(navigateToUrl);
    }
  };

  return (
    <>
      <Box sx={vBillToolbarOuterSx}>
        <Box sx={vBillToolbarInnerSx}>
          <Box sx={vBillToolbarLeftColSx}>
            <VBillToolbarBackBtn />

            <VBillToolbarBillIdInfo />
          </Box>
          <Box sx={{ flexGrow: "1", display: "flex", justifyContent: "space-between", gap: "10px" }}>
            {submitBtns.map((action) => (
              <Tooltip arrow title={action.description} key={action.type}>
                <LoadingButton
                  loading={
                    action.type === IVBillInvoiceUserActionType.SaveDraft
                      ? isSaveDraftReqPending
                      : isCompleteOcrReqPending
                  }
                  onClick={() =>
                    action.type === IVBillInvoiceUserActionType.SaveDraft
                      ? handleSaveDraftSubmit()
                      : formMethods.handleSubmit(handleCompleteOcrSubmit)()
                  }
                  variant={action.type === IVBillInvoiceUserActionType.SaveDraft ? "outlined" : "contained"}
                  sx={{
                    ...(action.type === IVBillInvoiceUserActionType.SaveDraft
                      ? {
                          border: "1px solid rgba(40, 119, 236, 0.25) !important",
                          color: COLORS.newSecondaryColor,
                          fontWeight: "500",
                        }
                      : {}),
                    height: "32px",
                  }}
                >
                  {action.displayName}
                </LoadingButton>
              </Tooltip>
            ))}
          </Box>
        </Box>

        <VBillToolbarFormErrors />
      </Box>

      {totalDifferenceAlertVisible && (
        <IncomingToolbarTotalDifferenceAlert
          onCloseDialog={() => setTotalDifferenceAlertVisible(false)}
          onSubmitDialog={handleTotalDifferentAlertSubmit}
        />
      )}
    </>
  );
});
