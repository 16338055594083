import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Button, Tooltip } from "@mui/material";
import { IVBillInvoiceIncomingStatus } from "generated/sdk.vbill";
import { observer } from "mobx-react";
import { useMemo, useState } from "react";
import { useStore } from "storeContainer";
import { COLORS } from "themes/default";
import { useQueryParam } from "use-query-params";
import { VBillPayBillDialog } from "../../../../common/VBill/VBillPayBillDialog/VBillPayBillDialog";
import { vBillSidebarPanelQSKey, vBillSidebarPanels } from "../../../VBillSidebar/SidebarTabs/SidebarTabs";

export const SummaryToolbarPayBill = observer(() => {
  const vBillStore = useStore("VBillStore");
  const organizationStore = useStore("OrganizationStore");
  const { invoice } = vBillStore;
  const invoiceData = useMemo(() => invoice.data, [invoice.data]);
  const [isPayBillDialogOpen, setIsPayBillDialogOpen] = useState(false);
  const [, setSidebarTabPanel] = useQueryParam<vBillSidebarPanels>(vBillSidebarPanelQSKey);

  const canAmountFreeToPayBill = Number(invoiceData?.amounts?.amountFreeToPay ?? 0) > 0;

  const handlePayBillSubmitEnd = async () => {
    await vBillStore.loadInvoice({ invoiceId: invoiceData?.id ?? 0 }, true);
    setSidebarTabPanel(vBillSidebarPanels.BILL_PAYMENTS);
  };

  if (
    !(
      invoiceData?.status === IVBillInvoiceIncomingStatus.Approved &&
      organizationStore.organizationVBillPayLedgerEnabled
    )
  ) {
    return null;
  }

  return (
    <>
      {!organizationStore.organizationVBillPayBtnEnabled ? (
        <Tooltip title="Payment must be enabled in integration">
          <ErrorOutlineIcon sx={{ color: COLORS.orange, fontSize: "20px" }} />
        </Tooltip>
      ) : canAmountFreeToPayBill && invoiceData.canBePaid ? (
        <Button sx={{ height: "32px" }} onClick={() => setIsPayBillDialogOpen(true)} variant="contained">
          Pay
        </Button>
      ) : null}

      {isPayBillDialogOpen && invoiceData && (
        <VBillPayBillDialog
          vBill={invoiceData}
          onSubmitEnd={handlePayBillSubmitEnd}
          onCloseDialog={() => setIsPayBillDialogOpen(false)}
        />
      )}
    </>
  );
});
