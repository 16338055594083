import { Box, Typography } from "@mui/material";
import { IVBillBatchStatus } from "generated/sdk.vbill";
import { observer } from "mobx-react";
import { useStore } from "storeContainer";
import { COLORS } from "themes/default";
import { typographyAsBtnStyles } from "../summariesStyles";

const friendlyStatusNames: { [key in IVBillBatchStatus]: string } = {
  [IVBillBatchStatus.Open]: "Open",
  [IVBillBatchStatus.Closed]: "Closed",
  [IVBillBatchStatus.Deleted]: "Deleted",
};

export const SummariesStatus = observer(function SummariesStatus() {
  const vBillBatchDetailsStore = useStore("VBillBatchDetailsStore");

  const { batchDetails } = vBillBatchDetailsStore;
  const batchData = batchDetails.data;

  return (
    <Typography sx={typographyAsBtnStyles}>
      <Box sx={{ color: COLORS.logoBlue }}>Status:</Box>
      <strong>{batchData?.status ? friendlyStatusNames[batchData.status] : "-"}</strong>
    </Typography>
  );
});
