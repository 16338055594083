import { LoadingButton } from "@mui/lab";
import { Box, Button, Dialog, Divider, TextField, Typography } from "@mui/material";
import { IVBillVBillInvoicesQuery } from "generated/sdk.vbill";
import { observer } from "mobx-react";
import { useMemo, useState } from "react";
import { useParams } from "react-router";
import { useStore } from "storeContainer";

interface IVBillChangeMemoDialogProps {
  vBill: IVBillVBillInvoicesQuery["invoices"]["items"][number];
  onCloseDialog: () => void;
}
export const VBillChangeMemoDialog = observer(function VBillChangeMemoDialog({
  onCloseDialog,
  vBill,
}: IVBillChangeMemoDialogProps) {
  const vBillBatchDetailsStore = useStore("VBillBatchDetailsStore");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { batchId, organizationId } = useParams<{ batchId: string; organizationId: string }>();
  const vBillMemoInBatch = useMemo(
    () => vBill.amounts?.amountInBatchesBreakdown.find((amount) => amount?.batchId === Number(batchId))?.memo,
    [batchId, vBill.amounts?.amountInBatchesBreakdown],
  );
  const [vBillMemo, setVBillMemo] = useState(vBillMemoInBatch ?? "");
  const { batchFilters } = vBillBatchDetailsStore;
  const amount = useMemo(() => vBill.amounts?.amountFreeToPay ?? "0", [vBill.amounts?.amountFreeToPay]);

  const handleSaveDialog = async () => {
    if (!vBillMemo?.length || vBillMemoInBatch === vBillMemo) {
      return;
    }

    setIsLoading(true);

    await vBillBatchDetailsStore.addVBillToBatch({
      batchId: Number(batchId),
      batchInvoices: { invoiceId: vBill.id, memo: vBillMemo, batchAmountForInvoice: amount },
    });
    vBillBatchDetailsStore.getBatchSelectedVBills(
      {
        pagination: batchFilters.vbill_sel_pag,
        filters: { ...batchFilters.vbill_sel_fil, organizationId, batch_id_in: [Number(batchId)] },
      },
      true,
    );

    setIsLoading(false);
    onCloseDialog();
  };

  return (
    <Dialog open onClose={onCloseDialog} PaperProps={{ sx: { padding: "15px" } }}>
      <Box sx={{ width: "200px" }}>
        <Typography sx={{ fontSize: "18px", marginBottom: "20px" }}>Set Memo:</Typography>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSaveDialog();
          }}
        >
          <TextField
            value={vBillMemo}
            onChange={(e) => setVBillMemo(e.target.value)}
            placeholder="Memo:"
            size="small"
            sx={{ width: "100%" }}
          />

          <Divider sx={{ margin: "20px 0" }} />

          <Box sx={{ display: "flex", justifyContent: "end", gap: "15px" }}>
            <LoadingButton loading={isLoading} variant="contained" sx={{ height: "30px" }} type="submit">
              Save
            </LoadingButton>
            <Button variant="outlined" type="button" onClick={onCloseDialog} sx={{ height: "30px" }}>
              Cancel
            </Button>
          </Box>
        </form>
      </Box>
    </Dialog>
  );
});
