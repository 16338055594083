import { Box } from "@mui/material";
import { IVBillKInvoiceFilesOcrState } from "generated/graphql.vbill";
import { IVBillVBillInvoicesQuery } from "generated/sdk.vbill";
import { COLORS } from "themes/default";

interface IVBillProcessingOverlayProps {
  filesOcrState: IVBillVBillInvoicesQuery["invoices"]["items"][number]["filesOcrState"];
}

export const VBillProcessingOverlay = ({ filesOcrState }: IVBillProcessingOverlayProps) => {
  if (filesOcrState !== IVBillKInvoiceFilesOcrState.Pending) {
    return null;
  }

  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        zIndex: 1,
        backgroundColor: `rgba(22, 69, 139, 0.5)`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box sx={{ fontSize: "16px", color: COLORS.white, fontWeight: "600" }}>Processing...</Box>
    </Box>
  );
};
