import {
  Box,
  TableCell,
  TableCellProps,
  TablePagination,
  TablePaginationProps,
  TableRow,
  TableRowProps,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import IconButton from "@mui/material/IconButton";
import { TablePaginationActionsProps } from "@mui/material/TablePagination/TablePaginationActions";
export const scrollbarStyles = {
  "&::-webkit-scrollbar": {
    width: "3px",
  },
  "&::-webkit-scrollbar-track": {
    boxShadow: "inset 0 0 10px rgb(219, 219, 219)",
    borderRadius: "10px",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "rgba(5, 32, 72, 0.5)",
    borderRadius: "4px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    opacity: 1,
  },
};

export const CheckTableRow = styled(TableRow)<TableRowProps>(() => ({
  height: "80px",
  backgroundColor: "#ffffff",
  padding: "20px 0px",
  "&:hover": {
    backgroundColor: "#f5f5f5", // Update with your color
  },
}));

export const CheckTableRowHead = styled(TableRow)<TableRowProps>(() => ({
  height: "60px",
  backgroundColor: "#ffffff",
  padding: "20px 0px",
}));

export const CheckTableCellHead = styled(TableCell)<TableCellProps & { color?: string; maxWidth?: string }>(
  ({ color, maxWidth }) => ({
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "1.5rem",
    color: "#052048",
    letterSpacing: "0.01071em",
    display: "table-cell",
    verticalAlign: "inherit",
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
    textAlign: "left",
    padding: "16px",
    background: "white",
  }),
);

export const CheckTableCell = styled(TableCell)<TableCellProps & { color?: string; maxWidth?: string }>(
  ({ color, maxWidth }) => ({
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "15px",
    color: "#052048",
    letterSpacing: "0.01071em",
    display: "table-cell",
    verticalAlign: "inherit",
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
    textAlign: "left",
    padding: "16px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    cursor: "pointer",
    ...(color && { color }),
  }),
);

export const CheckTablePagination = styled(TablePagination)<TablePaginationProps>(() => ({
  width: "100vw",
  position: "absolute",
  bottom: "0px",
  background: "white",
  borderTop: "1px solid #DFE7F2",
  left: "0",
  height: "54px",
  padding: "0px 290px !important",
}));

export const TablePaginationActions = (props: TablePaginationActionsProps & { description?: string }) => {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange, description } = props;

  const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <>
      <Typography variant="h4" sx={{ position: "absolute", left: "10px" }}>
        {description}
      </Typography>

      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
          {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    </>
  );
};
