import { LoadingButton } from "@mui/lab";
import { Box, Button } from "@mui/material";
import { COLORS } from "themes/default";
import { useMarkAsDuplicate } from "./hooks";
import { IDuplicateModalActionsProps } from "./types";

export const DuplicatesModalIncomingActions: React.FunctionComponent<IDuplicateModalActionsProps> = ({
  invoice,
  onClose,
  reloadInvoicesList,
  onStartCodingBtnClick,
}) => {
  const { isLoading, markAsDuplicate: _markAsDuplicate } = useMarkAsDuplicate(invoice, reloadInvoicesList);
  const markAsDuplicate = async () => {
    await _markAsDuplicate();
    onClose();
  };

  return (
    <Box sx={{ marginTop: "15px", display: "flex", gap: "10px" }}>
      <LoadingButton variant="contained" loading={isLoading} onClick={() => markAsDuplicate()}>
        Discard as duplicate
      </LoadingButton>
      <Button
        onClick={onStartCodingBtnClick}
        variant="outlined"
        sx={{
          border: "1px solid rgba(40, 119, 236, 0.25) !important",
          color: COLORS.newSecondaryColor,
          fontWeight: "500",
        }}
      >
        Not a duplicate start coding
      </Button>
    </Box>
  );
};
