import { LoadingButton } from "@mui/lab";
import { Box, Tooltip } from "@mui/material";
import { ApprovalButtons } from "components/common/Approval/ApprovalButtons";
import { useVBillCodingLedgerQuickFilterUrl } from "components/pages/VBillCodingLedger/hooks";
import { VBillCodingLedgerChildRoutes } from "components/pages/VBillCodingLedger/utils";
import { DelegationGroupScopeEnum } from "generated/sdk";
import {
  IVBillInvoiceIncomingStatus,
  IVBillInvoiceUserActionShowIn,
  IVBillInvoiceUserActionType,
} from "generated/sdk.vbill";
import { observer } from "mobx-react";
import { useMemo, useState } from "react";
import { useHistory } from "react-router";
import { useStore } from "storeContainer";
import { ToolbarActionsMenu } from "../../common/VBillToolbar/ToolbarActionsMenu/ToolbarActionsMenu";
import { VBillToolbarBackBtn } from "../../common/VBillToolbar/VBillToolbarBackBtn/VBillToolbarBackBtn";
import { VBillToolbarBillIdInfo } from "../../common/VBillToolbar/VBillToolbarBillIdInfo/VBillToolbarBillIdInfo";
import { VBillToolbarFormErrors } from "../../common/VBillToolbar/VBillToolbarFormErrors/VBillToolbarFormErrors";
import { VBillToolbarStatusDetails } from "../../common/VBillToolbar/VBillToolbarStatusDetails/VBillToolbarStatusDetails";
import { vBillToolbarInnerSx, vBillToolbarLeftColSx, vBillToolbarOuterSx } from "../../common/VBillToolbar/styles";
import { SummaryToolbarPayBill } from "./SummaryToolbarPayBill/SummaryToolbarPayBill";
import { useVBillInvoiceVendorId } from "./useVBillInvoiceVendorId";

export const SummaryToolbar = observer(() => {
  const vBillStore = useStore("VBillStore");
  const { invoice, settings } = vBillStore;
  const invoiceData = useMemo(() => invoice.data, [invoice.data]);
  const settingsData = useMemo(() => settings.data, [settings.data]);
  const history = useHistory();
  const [isStartApprovalReqPending, setIsStartApprovalReqPending] = useState(false);
  const vbillCodingLedgerCodingUrl = useVBillCodingLedgerQuickFilterUrl(VBillCodingLedgerChildRoutes.CODING);
  const vbillCodingLedgerAllUrl = useVBillCodingLedgerQuickFilterUrl(VBillCodingLedgerChildRoutes.ALL);
  const submitBtns = useMemo(
    () =>
      invoiceData?.status === IVBillInvoiceIncomingStatus.Mapped
        ? (invoiceData.nextUserActions ?? []).filter(
            (action) =>
              action.showIn === IVBillInvoiceUserActionShowIn.InvoiceDetails &&
              action.type === IVBillInvoiceUserActionType.StartApproval,
          )
        : [],
    [invoiceData?.nextUserActions],
  );
  const vendorId = useVBillInvoiceVendorId(invoiceData, settingsData);

  const handleStartApprovalSubmit = async () => {
    setIsStartApprovalReqPending(true);
    const startApprovalResponse = await vBillStore.startApprovalForInvoice({ invoiceId: invoiceData?.id ?? 0 });

    if (startApprovalResponse?.status !== IVBillInvoiceIncomingStatus.PendingApproval) {
      setIsStartApprovalReqPending(false);

      return;
    }

    setIsStartApprovalReqPending(false);
    history.push(vbillCodingLedgerCodingUrl);
  };

  const handleApprovalButtonsReloadParent = () => {
    vBillStore.loadInvoice({ invoiceId: invoiceData?.id ?? 0 }, true);
    history.push(vbillCodingLedgerAllUrl);
  };

  return (
    <>
      <Box sx={vBillToolbarOuterSx}>
        <Box sx={vBillToolbarInnerSx}>
          <Box sx={vBillToolbarLeftColSx}>
            <VBillToolbarBackBtn />

            <VBillToolbarBillIdInfo />

            <VBillToolbarStatusDetails />
          </Box>
          <Box sx={{ flexGrow: "1", display: "flex", justifyContent: "flex-end", gap: "10px" }}>
            {submitBtns.map((action) => (
              <Tooltip arrow title={action.description} key={action.type}>
                <LoadingButton
                  loading={isStartApprovalReqPending}
                  onClick={handleStartApprovalSubmit}
                  variant="contained"
                  sx={{
                    height: "32px",
                  }}
                >
                  {action.displayName}
                </LoadingButton>
              </Tooltip>
            ))}
          </Box>

          {vendorId && (
            <ApprovalButtons
              scope={DelegationGroupScopeEnum.VbillApprove}
              entityParams={{
                organizationId: invoiceData?.coreOrgId ?? "",
                companyId: invoiceData?.company?.companyId,
                vendorId,
                billId: invoiceData?.hashedId,
              }}
              approvalStatus={invoiceData?.approvalStatus}
              showAssign={true}
              showApproval={invoiceData?.status === IVBillInvoiceIncomingStatus.PendingApproval}
              showDecline={
                invoiceData?.status === IVBillInvoiceIncomingStatus.New ||
                invoiceData?.status === IVBillInvoiceIncomingStatus.Mapped ||
                invoiceData?.status === IVBillInvoiceIncomingStatus.PendingApproval
              }
              reloadParent={handleApprovalButtonsReloadParent}
            />
          )}

          <SummaryToolbarPayBill />

          <ToolbarActionsMenu />
        </Box>

        <VBillToolbarFormErrors />
      </Box>
    </>
  );
});
