import { Skeleton, TableRow, Typography } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import { observer } from "mobx-react";
import { useStore } from "storeContainer";
import { CodingVBill } from "../CodingVBill/CodingVBill";

const emptySkeletonList = new Array(50).fill("");

export const CodingLedgerList = observer(function CodingLedgerList() {
  const vBillCodingLedgerStore = useStore("VBillCodingLedgerStore");

  const invoicesList = vBillCodingLedgerStore.invoicesList;
  const invoicesListData = invoicesList.data;

  return (
    <TableBody>
      {invoicesList.isLoading ? (
        <>
          {emptySkeletonList.map((_, index) => (
            <TableRow key={index} sx={{ cursor: "default" }}>
              <TableCell colSpan={12} sx={{ padding: "12px" }}>
                <Skeleton variant="rounded" sx={{ height: "34px" }} />
              </TableCell>
            </TableRow>
          ))}
        </>
      ) : (invoicesListData?.items ?? []).length === 0 ? (
        <TableRow>
          <TableCell colSpan={12} sx={{ textAlign: "center" }}>
            <Typography>No results found</Typography>
          </TableCell>
        </TableRow>
      ) : (
        invoicesListData?.items?.map((invoice) => <CodingVBill key={invoice.id} invoice={invoice} />)
      )}
    </TableBody>
  );
});
