import { useEffect, useState } from "react";
import { PermissionGroupWithSettings } from "storesMobx/AclStore2";

export function usePermissionGroupsByCategory(pgs: PermissionGroupWithSettings[]) {
  return pgs.reduce(
    (agg, pg) => {
      const cat = pg.category ?? "MANAGEMENT";

      agg[cat] = agg[cat] ?? [];
      agg[cat].push(pg);

      return agg;
    },
    {} as { [key: string]: PermissionGroupWithSettings[] },
  );
}
