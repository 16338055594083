import { IVBillLineItemInput, IVBillNullableInvoiceInput } from "generated/sdk.vbill";
import { TVBillFormFields, TVBillFormLineFields } from "../../types";
import { ENABLE_COMPUTED_LINE_ITEM_AMOUNTS, emptyOCRFormLineFields } from "../../utils";

export const adaptIncomingVBillFormToInvoiceInputType = (
  formFields: TVBillFormFields,
  invoiceId: number,
): IVBillNullableInvoiceInput => {
  const {
    amount,
    invoiceDate,
    invoiceDateDue,
    invoiceNumber,
    senderName,
    senderEmail,
    senderAddress,
    senderPhone,
    mainFile,
    lineItems,
    hasLineItems,
  } = formFields;

  const lineItemsInput: IVBillLineItemInput[] = (lineItems ?? []).map((lineItem, index) => ({
    ...formLineFieldsToLineItem(lineItem),
    invoiceId,
    orderIndex: index,
  }));
  const invoiceAmount = amount.length ? amount : "0";
  const emptyLineItem = {
    ...formLineFieldsToLineItem({
      ...emptyOCRFormLineFields,
      amount: invoiceAmount,
      ...(ENABLE_COMPUTED_LINE_ITEM_AMOUNTS ? { rate: invoiceAmount } : {}),
    }),
    invoiceId,
    orderIndex: 0,
    amount: invoiceAmount,
  };
  const invoiceData: IVBillNullableInvoiceInput = {
    amount: invoiceAmount,
    invoiceDate: invoiceDate?.length ? invoiceDate : null,
    invoiceDateDue: invoiceDateDue?.length ? invoiceDateDue : null,
    invoiceNumber: invoiceNumber.length ? invoiceNumber : null,
    senderName: senderName.length ? senderName : null,
    senderEmail: senderEmail.length ? senderEmail : null,
    senderAddress: senderAddress.length ? senderAddress : null,
    senderPhone: senderPhone.length ? senderPhone : null,
    mainFileId: mainFile?.value ? mainFile.value : null,
    lineItems: hasLineItems && lineItemsInput.length ? lineItemsInput : [emptyLineItem],
    hasLineItems,
  };

  return invoiceData;
};

const formLineFieldsToLineItem = (
  lineField: TVBillFormLineFields,
): Pick<IVBillLineItemInput, "description" | "qty" | "rate" | "amount" | "id"> => ({
  description: lineField.description.length ? lineField.description : "",
  qty: lineField.qty.length ? lineField.qty : "1",
  rate: lineField.rate.length ? lineField.rate : "0",
  amount: lineField.amount.length ? lineField.amount : "0",
  id: lineField.lineItemId.length ? Number(lineField.lineItemId) : undefined,
});

export const invoiceDateMoreThenFieldErrorMsg = "Bill Due should be more then Bill Date.";
