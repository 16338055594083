import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import StoreIcon from "@mui/icons-material/Store";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { Spinner } from "components/common/Spinner/Spinner";
import { CompanyMenu, CompanyMenuItem } from "components/layout/CompanyMenu";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { useStore } from "storeContainer";
import styles from "./CompanyDetails.module.scss";
import { AccountingApplication } from "./CompanyDetails/AccountingApplication";
import { CompanyDetailsCard } from "./CompanyDetails/CompanyDetailsCard";
import { VBillDetails } from "./VBillDetails/VBillDetails";

const CompanyDetailsWidget: React.FunctionComponent<{
  item: CompanyMenuItem;
}> = ({ item }) => {
  const history = useHistory();

  return (
    <Paper
      onClick={() => {
        if (item.url) history.push(item.url);
      }}
      sx={{
        display: "flex",
        height: "100%",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        cursor: "pointer",
        p: 4,
      }}
    >
      {item.icon}
      <Typography variant="subtitle2" data-element-id={item.title.toLowerCase().replace(/\s+/g, "_")}>
        {item.title}
      </Typography>
    </Paper>
  );
};

export const CompanyDetails: React.FunctionComponent = observer(function CompanyDetails() {
  const contextStore = useStore("ContextStore");
  const companyStore = useStore("CompanyStore");
  const organizationStore = useStore("OrganizationStore");
  const history = useHistory();
  const {
    acl: { canAddIntegration, canManageIntegration, canListSelectedCompanyContacts: canListContacts },
    acl,
  } = useStore("SessionStore");

  const { companyId, organizationId } = useParams<{ companyId: string; organizationId: string }>();

  const canSeeBa = acl.canListCompanyBankAccounts(companyId);

  const { integrations, companyDetails } = companyStore;

  const { selectedCompanyId, selectedCompany, selectedCompanyBanksAccounts, isFetching } = contextStore;

  useEffect(() => {
    organizationStore.loadOrganizationStructure(organizationId);
  }, [organizationId, organizationStore]);

  useEffect(() => {
    if (acl.aclDone && selectedCompanyId) {
      if (acl.canListCompany(selectedCompanyId)) {
        if (canManageIntegration) {
          companyStore.loadIntegrations();
        }
        companyStore.loadCompanyDetails();
      } else {
        history.replace("/accessRestricted");
      }
    }
  }, [selectedCompanyId, companyStore, acl.aclDone, acl, canManageIntegration, history]);

  const bankAccountsNo = selectedCompanyBanksAccounts.length;
  return isFetching ? (
    <Spinner withSpaceAround />
  ) : (
    <Box display="flex" width="100%" justifyContent="space-between" gap="130px" maxWidth="1400px">
      <CompanyMenu />
      <Box sx={{ display: "flex", flexDirection: "column", padding: "20px", width: "100%" }}>
        <Typography variant="h1" marginY="20px">
          {selectedCompany?.name}
        </Typography>
        <Box sx={{ display: "flex", marginTop: "15px" }}>
          {canSeeBa && (
            <Box className={styles.companyDetails_box_margin}>
              <CompanyDetailsWidget
                item={{
                  title: `Bank Accounts (${bankAccountsNo})`,
                  url: `/org/${contextStore.selectedOrganizationId}/company/${contextStore.selectedCompanyId}/bas`,
                  subItems: [],
                  icon: <AccountBalanceIcon sx={{ fontSize: 18 }} />,
                }}
              />
            </Box>
          )}

          {integrations.data && integrations.data.length > 0 && canManageIntegration && (
            <Box className={styles.companyDetails_box_margin}>
              <CompanyDetailsWidget
                item={{
                  title: "Integration Mapping",
                  url: `/org/${contextStore.selectedOrganizationId}/company/${contextStore.selectedCompanyId}/integrations/${integrations.data[0].integration?.integration_id}`,
                  subItems: [],
                  icon: <SyncAltIcon sx={{ fontSize: 18 }} />,
                }}
              />
            </Box>
          )}

          {integrations.data && integrations.data.length === 0 && canAddIntegration && (
            <Box className={styles.companyDetails_box_margin}>
              <CompanyDetailsWidget
                item={{
                  title: "Add Integration",
                  url: `/org/${contextStore.selectedOrganizationId}/company/${contextStore.selectedCompanyId}/integrations/`,
                  subItems: [],
                  icon: <SyncAltIcon sx={{ fontSize: 18 }} />,
                }}
              />
            </Box>
          )}

          {canListContacts &&
            false && ( //!TEMPORARILY DISABLED
              <Box className={styles.companyDetails_box_margin}>
                <CompanyDetailsWidget
                  item={{
                    title: "Contacts",
                    url: `/org/${contextStore.selectedOrganizationId}/company/${contextStore.selectedCompanyId}/vendors`,
                    subItems: [],
                    icon: <StoreIcon sx={{ fontSize: 18 }} />,
                  }}
                />
              </Box>
            )}
        </Box>
        <CompanyDetailsCard
          isLoading={companyDetails.isFetching || companyDetails.isLoading}
          companyDetails={companyDetails.data}
        />
        {canManageIntegration && (
          <AccountingApplication
            isLoading={integrations.isFetching || integrations.isLoading}
            integrations={integrations.data}
          />
        )}
        {companyStore.companyDetails.data?.vbill_enabled && (
          <VBillDetails
            isCompanyDetailsLoading={companyDetails.isFetching || companyDetails.isLoading}
            companyDetails={companyDetails.data}
          />
        )}
      </Box>
    </Box>
  );
});
