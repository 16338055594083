import classNames from "classnames";
import { useRouteMatch } from "react-router";
import { NavLink, NavLinkProps } from "react-router-dom";

/**
 * Returns a simple <a> link on legacy pages, <NavLink> otherwise.
 * We can get rid of this once we upgrade to react-router 6
 */

type ICompatNavLink = Omit<NavLinkProps, "to"> & { to: string };

export const CompatNavLink = (navLinkProps: ICompatNavLink) => {
  const { children, to, className, style, activeClassName, activeStyle, exact, strict } = navLinkProps;

  const active = useRouteMatch({ path: to, exact, strict });

  if ((window as any).isLegacyPage) {
    return (
      <a
        href={to}
        className={classNames([className, active && activeClassName])}
        style={{ height: "40px", ...style, ...(active && activeStyle) }}
      >
        {children}
      </a>
    );
  } else {
    return <NavLink style={{ height: "40px" }} {...navLinkProps} />;
  }
};
