import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

export const BankAccountName: React.FunctionComponent<{
  bankAccountName: string;
  bankAccountNickname?: string;
  user?: any;
  depositAccounts?: any;
  canEdit: boolean;
  onNameChanged: (name?: any) => void;
}> = ({ bankAccountName, bankAccountNickname, canEdit, onNameChanged, depositAccounts }) => {
  const [name, setName] = useState(bankAccountName);
  const [tempName, setTempName] = useState(bankAccountNickname ?? "");
  const [changed, setChanged] = useState(name.includes("(") || tempName.includes("("));
  const [error, setError] = useState("");

  //Confirm Dialog
  const [openConfirm, setOpenConfirm] = useState(false);

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };
  const handleCancelConfirm = () => {
    setOpenConfirm(false);
  };
  const handleDelete = () => {
    setOpenChangeName(false);
    setOpenConfirm(false);
    if (!depositAccounts) {
      const nameSplit = name.slice(-1) === ")" ? name.split("(").pop()?.slice(0, -1) : name;
      setName(nameSplit!);
    }
    onNameChanged(null);
    setTempName("");
  };

  //Main Dialog
  const [openChangeName, setOpenChangeName] = useState(false);

  const handleCancel = () => {
    setOpenChangeName(false);
  };

  const handleSave = () => {
    if (tempName.length > 0 && tempName.replace(/\s/g, "").length === 0) {
      setError("Invalid name");
    } else if (tempName.length < 5) {
      setError("Length must be more than 5");
    } else if (tempName.length > 50) {
      setError("Length must be less than 50");
    } else {
      setError("");
      setOpenChangeName(false);
      if (!depositAccounts) {
        if (!changed) {
          setName(`${tempName} (${name})`);
        } else {
          const nameSplit = name.slice(-1) === ")" ? name.split("(").pop() : name;
          setName(`${tempName} (${name.slice(-1) === ")" ? nameSplit?.slice(0, -1) : nameSplit})`);
        }
      } else {
        setName(tempName);
      }
      onNameChanged(tempName);
      setChanged(true);
    }
  };

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography
          variant="h3"
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
          maxWidth="300px"
          display="block"
          title={name}
        >
          {name}
        </Typography>
        {canEdit && (
          <IconButton
            title="Customize bank account"
            onClick={() => {
              setOpenChangeName(true);
            }}
            size="small"
          >
            <EditIcon fontSize="small" />
          </IconButton>
        )}
      </Box>

      <Dialog
        open={openChangeName}
        onClose={() => {
          setOpenChangeName(false);
        }}
      >
        <DialogTitle>Set bank account alias</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Bank Account Alias"
            type="text"
            error={!!error}
            helperText={error}
            value={tempName || ""}
            onChange={(e) => {
              setTempName(e.target.value);
            }}
            fullWidth
            variant="standard"
          />
          <Typography variant="caption">*This change is only visible to you</Typography>
        </DialogContent>
        <DialogActions>
          <IconButton aria-label="delete" onClick={handleOpenConfirm} disabled={depositAccounts}>
            <DeleteIcon />
          </IconButton>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openConfirm}
        onClose={() => {
          setOpenConfirm(false);
        }}
      >
        <DialogTitle>Are you sure you want to remove this nickname ?</DialogTitle>
        <DialogActions>
          <Button onClick={handleCancelConfirm}>Cancel</Button>
          <Button onClick={handleDelete}>Delete</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
