import React, { DetailedHTMLProps, FunctionComponent, ReactNode } from "react";
import { FormProvider, UseFormReturn } from "react-hook-form";

interface IForm extends DetailedHTMLProps<React.FormHTMLAttributes<HTMLFormElement>, HTMLFormElement> {
  formMethods: UseFormReturn<any>;
  onSubmit?: (data: { [key: string]: any }) => void;
  children: ReactNode;
}

const Form: FunctionComponent<IForm> = ({ children, formMethods, onSubmit, ...htmlFormProps }) => {
  return (
    <FormProvider {...formMethods}>
      <form {...htmlFormProps} {...(onSubmit && { onSubmit: formMethods.handleSubmit(onSubmit) })}>
        {children}
      </form>
    </FormProvider>
  );
};

export default Form;
