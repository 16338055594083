import { createObservableContainer } from "../storeContainer";
import { getSdk, UserGroupsFiltersInput, UserGroupsQuery, UserGroupUsersQuery } from "../generated/sdk";
import { definitelyFilter } from "../generated/utils";
import { graphqlClient } from "../common/graphqlClient";
import _ from "lodash";
import { StoreBase } from "storesMobx/StoreBase";

const {
  UserGroups,
  UserGroupUsers,
  AddUserToGroup,
  RemoveUserFromGroup,
  UpdateUserGroup,
  CreateUserGroup,
  DeleteUserGroup,
} = getSdk(graphqlClient);

const fetchUserGroups = async (organization_id: string, filters?: UserGroupsFiltersInput) => {
  return definitelyFilter((await UserGroups({ organization_id })).UserGroups);
};

const fetchUserGroupUsers = async (organization_id: string, user_group_id: string) => {
  return definitelyFilter(
    (await UserGroupUsers({ organization_id: organization_id, user_group_id: user_group_id })).UserGroups,
  );
};

export class UserGroupsStore extends StoreBase {
  userGroupsList = createObservableContainer<NonNullable<UserGroupsQuery["UserGroups"]>>();
  userGroupUsersList = createObservableContainer<NonNullable<UserGroupUsersQuery["UserGroups"]>>();

  onOrganizationIdChange() {
    this.userGroupUsersList.flush();
  }

  // Load all UserGroups
  async loadUserGroups(forceUpdate?: boolean) {
    const organization_id = this.storeContainer?.ContextStore.selectedOrganizationId;
    if (organization_id) {
      await this.userGroupsList.cachedLoad(() => fetchUserGroups(organization_id), [organization_id], { forceUpdate });
    }
  }

  // Load all Oranization Users in a User Group
  async loadUserGroupUsers(user_group_id: string, forceUpdate?: boolean) {
    const organization_id = this.storeContainer?.ContextStore.selectedOrganizationId;
    this.storeContainer?.OrganizationUsersStore?.loadOrganizationUsers((forceUpdate = true));
    this.storeContainer?.UserGroupsStore?.loadUserGroups((forceUpdate = true));
    if (organization_id) {
      await this.userGroupUsersList.cachedLoad(
        () => fetchUserGroupUsers(organization_id, user_group_id),
        [user_group_id],
        { forceUpdate },
      );
    }
  }

  // reloadUsersAndGroups() {
  //   this.loadUserGroups();
  //   this.loadUserGroupUsers();
  // }

  async addUserToGroup(user_group_id: string, organization_user_id: string) {
    const organization_id = this.storeContainer?.ContextStore.selectedOrganizationId;
    if (organization_id) {
      await AddUserToGroup({ organization_id, organization_user_id, user_group_id });
      await this.loadUserGroupUsers(user_group_id);
    }
  }

  async removeUserFromGroup(user_group_id: string, organization_user_id: string) {
    const organization_id = this.storeContainer?.ContextStore.selectedOrganizationId;
    if (organization_id) {
      await RemoveUserFromGroup({ organization_id, organization_user_id, user_group_id });
      await this.loadUserGroupUsers(user_group_id);
    }
  }

  async updateUserGroup(name: string, description: string, user_group_id: string) {
    await UpdateUserGroup({ name, description, user_group_id });
    await this.loadUserGroups(true);
  }

  async createUserGroup(name: string, description: string) {
    const organization_id = this.storeContainer?.ContextStore.selectedOrganizationId;
    if (organization_id) {
      const groupId = await CreateUserGroup({ name, description, organization_id });
      await this.loadUserGroups(true);
      return groupId;
    }
  }

  async deleteUserGroup(user_group_id: string) {
    await DeleteUserGroup({ user_group_id });
    await this.loadUserGroups(true);
  }

  // FIXME: use state and resolve async
  getGroup(user_group_id: string) {
    this.loadUserGroups();
    const list = this.userGroupsList.data?.find((ug) => ug?.id === user_group_id);
    return _.pick(list, ["id", "name", "description"]);
  }
}
