import { Box, Typography } from "@mui/material";
import { IVBillExpenseType } from "generated/sdk.vbill";
import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { useStore } from "storeContainer";
import { COLORS } from "themes/default";
import { FormInputDate } from "../../common/Forms/FormInputDate/FormInputDate";

export const NewBillInfo = observer(() => {
  const vBillStore = useStore("VBillStore");
  const { invoice } = vBillStore;
  const invoiceData = useMemo(() => invoice.data, [invoice.data]);

  if (invoiceData?.expenseType !== IVBillExpenseType.Bill) {
    return null;
  }

  return (
    <Box sx={{ marginBottom: "10px" }}>
      <Typography sx={{ fontSize: 16, marginBottom: "5px", fontWeight: 500, color: COLORS.logoBlue }}>
        BILL INFO:
      </Typography>

      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        <Box sx={{ flex: "1" }}>
          <FormInputDate
            name="invoiceDate"
            label="Bill Date:"
            rules={{
              required: "Bill Date is required",
              // validate: (value) => isValid(new Date(value)) || "Invalid Date",
            }}
          />
        </Box>
        <Box sx={{ flex: "1" }}>
          <FormInputDate
            name="invoiceDateDue"
            label="Bill Due:"
            rules={{
              required: "Bill Due is required",
              // validate: (value) => isValid(new Date(value)) || "Invalid Date",
            }}
          />
        </Box>
      </Box>
    </Box>
  );
});
