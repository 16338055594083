import { Box } from "@mui/material";
import { FunctionComponent, ReactNode } from "react";
import { SidebarTabPanels } from "../SidebarTabs";

interface ISidebarTabPanelProps {
  children: ReactNode;
  panelName: SidebarTabPanels;
  activePannel: SidebarTabPanels;
}

export const SidebarTabPanel: FunctionComponent<ISidebarTabPanelProps> = ({ children, panelName, activePannel }) => (
  <div
    role="tabpanel"
    hidden={activePannel !== panelName}
    id={`batch-details-sidebar-tabpanel-${panelName}`}
    aria-labelledby={`batch-details-sidebar-tab-${panelName}`}
  >
    {activePannel === panelName && <Box sx={{ padding: "10px" }}>{children}</Box>}
  </div>
);
