import { Box, Tooltip, Typography } from "@mui/material";
import { formatCurrency } from "common/helpers/utils";
import { IVBillInvoiceIncomingStatus } from "generated/sdk.vbill";
import { observer } from "mobx-react";
import { useMemo } from "react";
import { useStore } from "storeContainer";
import { COLORS } from "themes/default";

export const VBillItemsTotalDetails = observer(() => {
  const vBillStore = useStore("VBillStore");
  const organizationStore = useStore("OrganizationStore");
  const { invoice } = vBillStore;
  const invoiceData = useMemo(() => invoice.data, [invoice.data]);
  const showPayments = useMemo(
    () =>
      invoiceData?.status === IVBillInvoiceIncomingStatus.Approved &&
      !!invoiceData?.amounts &&
      !!organizationStore.organizationVBillPayLedgerEnabled,
    [invoiceData?.amounts, invoiceData?.status, organizationStore.organizationVBillPayLedgerEnabled],
  );

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        gap: "20px",
        position: "sticky",
        bottom: 0,
        backgroundColor: COLORS.white,
        // zIndex: 1202, // 1200 has Drawer
        borderTop: "1px solid #DFE7F2",
        padding: "20px 10px",
      }}
    >
      {showPayments ? (
        <>
          <Box
            sx={{ display: "flex", justifyContent: "space-between", gap: "20px", alignItems: "center", width: "100%" }}
          >
            <Tooltip arrow title="Invoice Amount" placement="top">
              <Box sx={{ textAlign: "left" }}>
                <Typography sx={{ fontSize: "16px" }}>Amount</Typography>
                <Typography sx={{ fontSize: "18px", fontWeight: 600, marginTop: "5px" }}>
                  {formatCurrency(Number(invoiceData?.amounts?.amount ?? 0))}
                </Typography>
              </Box>
            </Tooltip>

            <Tooltip arrow title="Amount in payments" placement="top">
              <Box sx={{ textAlign: "left" }}>
                <Typography sx={{ fontSize: "16px" }}>Paid</Typography>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 600,
                    marginTop: "5px",
                    color: Number(invoiceData?.amounts?.amountInPayments ?? 0) > 0 ? COLORS.success : COLORS.black,
                  }}
                >
                  {formatCurrency(Number(invoiceData?.amounts?.amountInPayments ?? 0))}
                </Typography>
              </Box>
            </Tooltip>

            <Tooltip arrow title="Free amount" placement="top">
              <Box sx={{ textAlign: "left" }}>
                <Typography sx={{ fontSize: "16px" }}>Ballance</Typography>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 600,
                    marginTop: "5px",
                    color: Number(invoiceData?.amounts?.amountFreeToPay ?? 0) > 0 ? COLORS.orange : COLORS.black,
                  }}
                >
                  {formatCurrency(Number(invoiceData?.amounts?.amountFreeToPay ?? 0))}
                </Typography>
              </Box>
            </Tooltip>
          </Box>
        </>
      ) : (
        <>
          <Box sx={{ paddingLeft: "0" }}>
            <Typography sx={{ fontSize: "22px" }}>Total Due:</Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                fontSize: "22px",
                fontWeight: "600",
                textAlign: "right",
                color: COLORS.textGray,
              }}
            >
              {formatCurrency(Number(invoiceData?.amount ?? 0))}
            </Typography>
          </Box>
        </>
      )}
    </Box>
  );
});
