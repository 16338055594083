import { Skeleton } from "@mui/lab";
import { Box } from "@mui/material";
import classNames from "classnames";
import Cookies from "js-cookie";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { useStore } from "storeContainer";
import CompanyHeader from "./CompanyHeader";
import styles from "./Header.module.scss";
import OrganizationHeader from "./OrganizationHeader";
import { SubHeader } from "./SubHeader";

export enum HeaderTypes {
  ORGANIZATION,
  COMPANY_SETTINGS,
  ORGANIZATION_SETTINGS,
  NO_ORGANIZATION_HEADER,
  UNDEPOSITED,
  IMPORT_ERRORS,
  PROFILE,
}
const Header = observer(function Header({ headerType }: { headerType: HeaderTypes }) {
  const { organizationId } = useParams<{ organizationId?: string }>();
  const lastOrgCookie = Cookies.get("lastOrg");
  const currentOrgId = organizationId ?? lastOrgCookie;
  const { selectedOrganizationId } = useStore("ContextStore");
  const vChecksStore = useStore("VChecksStore");

  const isCheckDetailsPage =
    window.location.pathname.includes("/check/") || window.location.pathname.includes("/ledger/");

  return (
    <header
      className={classNames(styles.header, {
        [styles.hide]:
          !currentOrgId &&
          (headerType === HeaderTypes.ORGANIZATION ||
            headerType === HeaderTypes.COMPANY_SETTINGS ||
            headerType === HeaderTypes.PROFILE) &&
          !window.location.pathname.includes("/check/"),
      })}
    >
      {headerType === HeaderTypes.ORGANIZATION_SETTINGS && (
        <Box maxWidth="1400px" height="60px" width="100%" position="relative">
          <CompanyHeader organizationId={currentOrgId} title={"Organization Settings"} />
        </Box>
      )}
      {(headerType === HeaderTypes.ORGANIZATION || headerType === HeaderTypes.COMPANY_SETTINGS) &&
        (selectedOrganizationId || organizationId) &&
        !isCheckDetailsPage && (
          <Box maxWidth="1400px" height="40px" width="100%" position="relative">
            <OrganizationHeader />
          </Box>
        )}
      {isCheckDetailsPage &&
        (vChecksStore.check.isLoading ? (
          <Skeleton variant="text" />
        ) : (
          <SubHeader isCheckDetailsPage title={`Check # ${vChecksStore.check.data?.ledger_check?.number}`} />
        ))}
      {headerType === HeaderTypes.UNDEPOSITED && <SubHeader title={"Deposit vChecks"} />}
      {headerType === HeaderTypes.IMPORT_ERRORS && <SubHeader title={"Import Errors"} />}
    </header>
  );
});

export default Header;
