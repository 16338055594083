import CloseIcon from "@mui/icons-material/Close";
import { Box, Drawer, IconButton, Tooltip } from "@mui/material";
import React from "react";
import { PaymentDetails } from "./PaymentDetails";

interface IPaymentDetailsDrawerProps {
  paymentId: number;
  onDrawerClose: () => void;
  drawerAnchor?: "left" | "right";
}

export const PaymentDetailsDrawer = ({
  paymentId,
  onDrawerClose,
  drawerAnchor = "left",
}: IPaymentDetailsDrawerProps) => {
  return (
    <Drawer
      anchor={drawerAnchor}
      open
      onClose={onDrawerClose}
      sx={{
        zIndex: 1203, // 1202 has table pagination
      }}
    >
      <Box sx={{ padding: "50px 20px", position: "relative", minWidth: "600px", maxWidth: "1000px" }}>
        <Tooltip arrow title="Close">
          <IconButton onClick={onDrawerClose} sx={{ position: "absolute", top: "5px", right: "5px", zIndex: 1 }}>
            <CloseIcon sx={{ fontSize: "20px" }} />
          </IconButton>
        </Tooltip>

        <PaymentDetails paymentId={paymentId} onDrawerClose={onDrawerClose} />
      </Box>
    </Drawer>
  );
};
