import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary";
import { styled } from "@mui/material/styles";
import { ExpandLess, ExpandMoreIcon } from "components/common/icons";
import { omit } from "lodash";
import { COLORS } from "themes/default";

const expandIconStyle = { fontSize: "22px", color: COLORS.logoBlue };

export const VBillLineItemsAccordian = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))({ background: "none", ":before": { display: "none" } });

export const VBillLineItemsAccordianSummary = styled(
  (
    props: AccordionSummaryProps & {
      accordianExpanded: boolean;
      headingTotalLineItems: number;
    },
  ) => (
    <MuiAccordionSummary {...omit(props, ["accordianExpanded", "headingTotalLineItems"])}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Typography sx={{ fontSize: 16, fontWeight: 500, color: COLORS.logoBlue }}>
          ITEMS{" "}
          <Box component="strong" sx={{ fontSize: "14px", fontWeight: 600 }}>
            ({props.headingTotalLineItems})
          </Box>
        </Typography>

        <Tooltip arrow title={`${props.accordianExpanded ? "Hide" : "Show"} line items`}>
          <IconButton>
            {props.accordianExpanded ? <ExpandLess sx={expandIconStyle} /> : <ExpandMoreIcon sx={expandIconStyle} />}
          </IconButton>
        </Tooltip>
      </Box>
    </MuiAccordionSummary>
  ),
)(() => ({
  padding: 0,
  minHeight: "auto",
  ".MuiAccordionSummary-content": {
    margin: 0,
  },
}));

export const VBillLineItemsAccordianDetails = styled(MuiAccordionDetails)(() => ({
  padding: 0,
}));
