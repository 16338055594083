import { LoadingButton } from "@mui/lab";
import { Box, Button, Divider, Typography } from "@mui/material";
import { sortBy } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { FixedSizeList } from "react-window";
import { SuggestionsSearchField } from "../common/SuggestionsSearchField/SuggestionsSearchField";
import { VirtualizedSuggestionCheckbox } from "./VirtualizedSuggestionCheckbox/VirtualizedSuggestionCheckbox";
import styles from "./vBillSuggestionsMultiSelectAutocomplete.module.scss";

export interface IVBillMultiSelectAutocompleteSuggestion {
  id: string;
  name: string;
  label?: string | null;
  selected?: boolean;
}

export interface IVBillSuggestionsMultiSelectAutocompleteProps {
  title?: string;
  searchTerm?: string;
  searchPlaceholder: string;
  onSearchTermChange?: (value: string) => void;
  showSearchLoader?: boolean;
  actionBtnsSmallHeight?: boolean;
  onClose: () => void;
  onSave: () => Promise<void>;
  saveBtnDisabled?: boolean;
  showSelectedGroup?: boolean;
  suggestions: IVBillMultiSelectAutocompleteSuggestion[];
  onSuggestionChange: (suggestion: IVBillMultiSelectAutocompleteSuggestion, value: boolean) => void;
  showMoreBtnVisible?: boolean;
  onShowMoreBtnClick?: () => void;
}

export const VBillSuggestionsMultiSelectAutocomplete = ({
  onClose,
  suggestions,
  onSuggestionChange,
  searchTerm,
  onSearchTermChange,
  onSave,
  title,
  saveBtnDisabled,
  searchPlaceholder,
  showSearchLoader,
  actionBtnsSmallHeight,
  showSelectedGroup,
  showMoreBtnVisible,
  onShowMoreBtnClick,
}: IVBillSuggestionsMultiSelectAutocompleteProps) => {
  const [localSearchTerm, setLocalSearchTerm] = useState(searchTerm ?? "");
  const [showSaveLoader, setShowSaveLoader] = useState(false);
  const [localSuggestions, setLocalSuggestions] = useState<IVBillMultiSelectAutocompleteSuggestion[]>(suggestions);
  const selectedSuggestions = useMemo(
    () => sortBy(localSuggestions, (suggestion) => suggestion.name).filter(({ selected }) => selected),
    [localSuggestions],
  );
  const searchedSuggestions = useMemo(
    () => (showSelectedGroup ? localSuggestions.filter(({ selected }) => !selected) : localSuggestions),
    [localSuggestions, showSelectedGroup],
  );

  useEffect(() => {
    setLocalSearchTerm(searchTerm ?? "");
  }, [searchTerm]);

  useEffect(() => {
    if (localSearchTerm.length) {
      const currentSuggestions = showSelectedGroup
        ? suggestions
        : suggestions.filter(({ name }) => new RegExp(localSearchTerm, "i").test(name));

      setLocalSuggestions(currentSuggestions);
    } else {
      setLocalSuggestions(suggestions);
    }
  }, [localSearchTerm, showSelectedGroup, suggestions]);

  const handleSearchTermChange = (value: string) => {
    setLocalSearchTerm(value);

    if (onSearchTermChange) {
      onSearchTermChange(value);
    }
  };

  const handleSaveBtnClick = async () => {
    setShowSaveLoader(true);
    await onSave();
    setShowSaveLoader(false);
  };

  return (
    <Box sx={{ padding: "10px" }}>
      {title && (
        <Typography noWrap sx={{ fontSize: "20px", margin: "0 10px 20px 0" }}>
          {title}
        </Typography>
      )}

      <SuggestionsSearchField
        showLoader={showSearchLoader}
        searchTerm={localSearchTerm}
        onSearchTermChange={handleSearchTermChange}
        placeholder={searchPlaceholder}
      />

      {showSelectedGroup && (
        <>
          <Typography sx={{ fontSize: "13px", marginTop: "10px" }}>
            Selected:{" "}
            <Box component="strong" sx={{ fontSize: "11px" }}>
              ({(selectedSuggestions ?? []).length})
            </Box>
          </Typography>

          {!!selectedSuggestions.length && (
            <FixedSizeList
              height={
                selectedSuggestions.length === 1
                  ? 48
                  : selectedSuggestions.length === 2
                    ? 96
                    : selectedSuggestions.length === 3
                      ? 144
                      : 165
              }
              itemCount={selectedSuggestions.length}
              itemSize={48}
              width={300}
              itemData={{
                suggestions: selectedSuggestions,
                onSuggestionChange,
              }}
              className={styles.fixedListContainer}
            >
              {VirtualizedSuggestionCheckbox}
            </FixedSizeList>
          )}
          <Divider sx={{ margin: "10px 0" }} />

          <Typography sx={{ fontSize: "13px" }}>
            Searched:{" "}
            <Box component="strong" sx={{ fontSize: "11px" }}>
              ({(searchedSuggestions ?? []).length})
            </Box>
          </Typography>
        </>
      )}

      <FixedSizeList
        height={showSelectedGroup ? 300 : 400}
        itemCount={searchedSuggestions.length}
        itemSize={48}
        width={300}
        itemData={{
          suggestions: searchedSuggestions,
          searchTerm: localSearchTerm,
          onSuggestionChange,
        }}
        className={styles.fixedListContainer}
      >
        {VirtualizedSuggestionCheckbox}
      </FixedSizeList>

      {showMoreBtnVisible && (
        <Button
          onClick={onShowMoreBtnClick}
          sx={{ padding: 0, height: "12px", "&:hover": { textDecoration: "underline" } }}
        >
          Show more
        </Button>
      )}

      <Divider sx={{ margin: actionBtnsSmallHeight ? "15px 0" : "20px 0" }} />

      <Box sx={{ display: "flex", justifyContent: "end", gap: "15px" }}>
        <LoadingButton
          variant="contained"
          loading={showSaveLoader}
          onClick={handleSaveBtnClick}
          disabled={saveBtnDisabled}
          sx={{ ...(actionBtnsSmallHeight ? { height: "32px" } : {}) }}
        >
          Save
        </LoadingButton>
        <Button variant="outlined" onClick={onClose} sx={{ ...(actionBtnsSmallHeight ? { height: "32px" } : {}) }}>
          Cancel
        </Button>
      </Box>
    </Box>
  );
};
