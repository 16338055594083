import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import TuneIcon from "@mui/icons-material/Tune";
import { Box, IconButton, TextField, Tooltip, Typography } from "@mui/material";
import { formatCurrency } from "common/helpers/utils";
import { TrashIcon } from "components/common/icons";
import { IVBillBillsFiltersInput } from "generated/sdk.vbill";
import { useState } from "react";
import { Controller } from "react-hook-form";
import { ListChildComponentProps } from "react-window";
import { COLORS } from "themes/default";
import { BatchMembersDialog } from "../../common/BatchMembersDialog/BatchMembersDialog";
import { BundleFilters } from "../../common/BundleFilters/BundleFilters";

interface IWindowedBatchBundleLineProps {
  onRemoveLineBtnClick: (index: number) => void;
}
export const WindowedBatchBundleLine = ({
  style,
  index,
  data: { onRemoveLineBtnClick },
}: ListChildComponentProps<IWindowedBatchBundleLineProps>) => {
  const [isMembersDialogOpen, setIsMembersDialogOpen] = useState(false);
  const [isCompaniesDialogOpen, setIsCompaniesDialogOpen] = useState(false);
  const [isFiltersDialogOpen, setIsFiltersDialogOpen] = useState(false);

  return (
    <Box style={style}>
      <Box
        sx={{
          borderBottom: `1px solid ${COLORS.borderColor}`,
          display: "flex",
          alignItems: "flex-start",
          width: "760px",
        }}
      >
        <Box sx={{ padding: "10px" }}>
          <Controller
            name={`bundleLines.${index}.name`}
            rules={{ required: "Batch Name is required." }}
            render={({ field, fieldState }) => (
              <>
                <TextField {...field} sx={{ width: "250px" }} />
                {fieldState.error && (
                  <Typography sx={{ color: COLORS.error, marginTop: "5px" }}>{fieldState.error.message}</Typography>
                )}
              </>
            )}
          />
        </Box>
        <Box sx={{ padding: "10px" }}>
          <Controller
            name={`bundleLines.${index}.budget`}
            rules={{ required: "Batch buget is required." }}
            render={({ field, fieldState }) => (
              <>
                <TextField {...field} sx={{ width: "250px" }} />
                {fieldState.error && (
                  <Typography sx={{ color: COLORS.error, marginTop: "5px" }}>{fieldState.error.message}</Typography>
                )}
              </>
            )}
          />
          <div>
            <Controller
              name={`bundleLines.${index}.count`}
              render={({ field, fieldState }) => (field.value ? <>{field.value} bills. </> : <></>)}
            />

            <Controller
              name={`bundleLines.${index}.amount`}
              render={({ field, fieldState }) =>
                field.value ? <>Total value {formatCurrency(field.value ?? 0)}</> : <></>
              }
            />
          </div>
        </Box>

        <Box sx={{ padding: "10px", width: "70px" }}>
          <Controller
            name={`bundleLines.${index}.filters`}
            render={({ field }) => (
              <>
                <Tooltip arrow title="Filters">
                  <IconButton type="button" onClick={() => setIsFiltersDialogOpen(true)}>
                    <TuneIcon sx={{ fontSize: "20px", color: COLORS.logoBlue }} />
                  </IconButton>
                </Tooltip>

                {isFiltersDialogOpen && (
                  <BundleFilters
                    onFiltersChange={(values: IVBillBillsFiltersInput) => field.onChange(values)}
                    filters={field.value}
                    onCloseDialog={() => setIsFiltersDialogOpen(false)}
                  />
                )}
              </>
            )}
          />
        </Box>

        {/* <Box sx={{padding: "10px"}}>
        <Controller
          name={`bundleLines.${index}.companyIds`}
          
          render={({ field }) => (
            <>
              <Tooltip arrow title="Companies">
                <IconButton type="button" onClick={() => setIsCompaniesDialogOpen(true)}>
                  <StoreIcon sx={{ fontSize: "20px", color: COLORS.logoBlue }} />
                </IconButton>
              </Tooltip>

              {isCompaniesDialogOpen && (
                <BatchCompaniesDialog
                  batchCompanies={field.value}
                  onSaveCompanies={async (companyIds: string[]) => {
                    field.onChange(companyIds);
                    setIsCompaniesDialogOpen(false);
                    await Promise.resolve();
                  }}
                  onCloseDialog={() => setIsCompaniesDialogOpen(false)}
                />
              )}
            </>
          )}
        />
      </Box sx={{padding: "10px"}}> */}

        <Box sx={{ padding: "10px", width: "82px" }}>
          <Controller
            name={`bundleLines.${index}.memberIds`}
            render={({ field }) => (
              <>
                <Tooltip arrow title="Members">
                  <IconButton type="button" onClick={() => setIsMembersDialogOpen(true)}>
                    <PeopleAltIcon sx={{ fontSize: "20px", color: COLORS.logoBlue }} />
                  </IconButton>
                </Tooltip>

                {isMembersDialogOpen && (
                  <BatchMembersDialog
                    suggestionsComponent="dialog"
                    selectedMembers={field.value}
                    onMembersSave={async (memberIds: string[]) => {
                      field.onChange(memberIds);
                      setIsMembersDialogOpen(false);
                      await Promise.resolve();
                    }}
                    onDialogClose={() => setIsMembersDialogOpen(false)}
                  />
                )}
              </>
            )}
          />
        </Box>
        <Box sx={{ padding: "10px" }}>
          <Tooltip arrow title="Delete line">
            <IconButton type="button" onClick={() => onRemoveLineBtnClick(index)}>
              <TrashIcon sx={{ fontSize: "20px", color: COLORS.logoBlue }} />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </Box>
  );
};
