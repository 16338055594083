import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { useStore } from "../../storeContainer";
import { COLORS } from "../../themes/default";

export const Component: React.FunctionComponent<{
  checkText: string;
  companyOrBa: string;
  validateOrConfirm: string;
  url?: any;
  id?: string;
  color: string;
}> = ({ checkText = "", url, color, validateOrConfirm, companyOrBa, id }) => {
  const OrganizationStore = useStore("OrganizationStore");

  const [loading, setLoading] = useState({ confirm: false, reject: false });

  const handleButton = async (bank_account_id: string, action: string) => {
    setLoading({ ...loading, [action]: true });
    await OrganizationStore.updateUnconfirmedBankAccount(bank_account_id, action);
    setLoading({ ...loading, [action]: true });
  };

  const cardStyles = {
    mt: 2,
    width: "340px",
    background: "#FFFFFF",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.08)",
    borderRadius: "3px",
    position: "relative",
  };
  const linkStyles = { textDecoration: "none", color: "#C0CBCE" };
  return (
    <Box sx={cardStyles}>
      <Link style={linkStyles} href={url}>
        <Grid padding={"20px 0px"} alignItems={"center"} justifyContent="center" container>
          <Grid xs={9} container item>
            <Grid xs={12} item>
              <Typography
                sx={{
                  fontFamily: "'Inter'",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "14px",
                  lineHeight: "17px",
                  color: "#2877EC",
                }}
              >
                {validateOrConfirm === "validate"
                  ? `Complete ${companyOrBa === "company" ? "company" : "bank account"} validation`
                  : `Complete bank account confirmation`}
              </Typography>
            </Grid>
            <Grid xs={12} item>
              <Typography
                color={COLORS.darkGrey}
                sx={{
                  fontFamily: "'Inter'",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "14px",
                  lineHeight: "17px",
                  color: "#697281",
                  marginTop: "8px",
                }}
              >
                {checkText}
              </Typography>
            </Grid>
            {validateOrConfirm === "confirm" && (
              <Grid xs={12} item mt={2}>
                <LoadingButton
                  loading={loading.confirm}
                  sx={{ marginRight: "15px" }}
                  variant={"outlined"}
                  onClick={() => {
                    handleButton(id ?? "", "confirm");
                  }}
                >
                  Confirm
                </LoadingButton>
                <LoadingButton
                  loading={loading.reject}
                  variant={"outlined"}
                  onClick={() => {
                    handleButton(id ?? "", "reject");
                  }}
                >
                  Delete
                </LoadingButton>
              </Grid>
            )}
          </Grid>
          {validateOrConfirm === "validate" && (
            <Grid xs={1} item>
              <ChevronRightIcon htmlColor="#052048" sx={{ position: "absolute", right: "10px", top: "30px" }} />
            </Grid>
          )}
        </Grid>
      </Link>
    </Box>
  );
};
