import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { Button, Typography, Menu, Divider, MenuItem, Box, TextField, CircularProgress } from "@mui/material";
import { BankAccountBasic, DepositBankAccountsQuery, Maybe } from "generated/sdk";
import { COLORS } from "themes/default";
import { VariableSizeList as List } from "react-window";
import CorporateFareOutlinedIcon from "@mui/icons-material/CorporateFareOutlined";
import StoreOutlinedIcon from "@mui/icons-material/StoreOutlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import { useEffect, useState } from "react";
import { useStore } from "storeContainer";
interface SelectBankAccountProps {
  anchorEl: HTMLButtonElement | null;
  handleSelectBaClose: () => void;
  handleClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  orgWithCompaniesAndBAs: DepositBankAccountsQuery["DepositBankAccounts"];
  selectedAccount: string;
  handleMenuItemClick: (
    account: Maybe<
      {
        __typename?: "BankAccountBasic" | undefined;
      } & Pick<BankAccountBasic, "name" | "id" | "account_number" | "routing_number" | "bank_name">
    >,
  ) => void;
}

export const SelectBankAccount = ({
  handleClick,
  handleSelectBaClose,
  handleMenuItemClick,
  anchorEl,
  orgWithCompaniesAndBAs,
  selectedAccount,
}: SelectBankAccountProps) => {
  const AccountsStore = useStore("AccountsStore");

  const [search, setSearch] = useState("");
  const [items, setItems] = useState<Record<string, any>[]>([]);

  const handleSearch = (searchTerm: string) => {
    setSearch(searchTerm);

    const newItems: Record<string, any>[] = [];

    if (searchTerm === "") {
      orgWithCompaniesAndBAs?.forEach((org) => {
        newItems.push({ type: "org", name: org?.name, search: searchTerm });
        org?.companies.forEach((company) => {
          newItems.push({ type: "company", name: company.name, search: searchTerm });
          company?.bank_accounts?.forEach((account) => {
            newItems.push({ type: "account", search: searchTerm, ...account });
          });
        });
      });
    } else {
      for (let i = 0; i < orgWithCompaniesAndBAs?.length!; i++) {
        let org = orgWithCompaniesAndBAs?.[i];
        let orgMatches = new RegExp(searchTerm, "gi").test(org?.name!);

        if (orgMatches) {
          newItems.push({ type: "org", name: org?.name, search: searchTerm });
        }

        for (let j = 0; j < org?.companies?.length!; j++) {
          let company = org?.companies[j];
          let companyMatches = new RegExp(searchTerm, "gi").test(company?.name!);
          let accountMatches = company?.bank_accounts?.some((account) =>
            new RegExp(searchTerm, "gi").test(account?.name!),
          );

          if (companyMatches || accountMatches || orgMatches) {
            if (companyMatches) {
              newItems.push({ type: "company", name: company?.name, search: searchTerm });
            }
            for (let k = 0; k < company?.bank_accounts?.length!; k++) {
              let account = company?.bank_accounts?.[k];
              if (new RegExp(searchTerm, "gi").test(account?.name!)) {
                newItems.push({ type: "account", search: searchTerm, ...account });
              }
            }
          }
        }
      }
    }

    setItems(newItems);
  };

  const getItemSize = (index: number) => {
    switch (items[index].type) {
      case "org":
        return 45;
      case "company":
        return 35;
      case "account":
        return 45;
      default:
        return 50;
    }
  };

  const Row = ({ index, style }: { index: number; style: Record<string, any> }) => {
    const item = items?.[index];
    let highlighted = item.name;
    if (search) {
      const parts = (item.name as string).split(new RegExp(`(${search})`, "gi"));
      highlighted = parts.map((part, i) =>
        new RegExp(search, "gi").test(part) ? (
          <span key={i} style={{ backgroundColor: "yellow" }}>
            {part}
          </span>
        ) : (
          part
        ),
      );
    }
    return (
      <div style={style}>
        {item.type === "org" && (
          <>
            <Box display="flex" paddingLeft="5px" alignItems="center" justifyContent={"flex-start"} gap="5px">
              <CorporateFareOutlinedIcon htmlColor="#CC3232" />
              <Typography
                color={COLORS.newPrimaryColor}
                fontWeight={600}
                fontSize={"14px"}
                variant="h3"
                maxWidth="200px"
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
                sx={{ fontWeight: "bold" }}
                title={item?.name}
              >
                {highlighted}
              </Typography>
            </Box>
            <Divider sx={{ marginTop: "15px" }} />
          </>
        )}
        {item.type === "company" && (
          <Box display="flex" paddingLeft="15px" gap="5px">
            <StoreOutlinedIcon htmlColor="#DB8C15" />
            <Typography
              color={COLORS.textGray}
              variant="h4"
              fontWeight={500}
              maxWidth="200px"
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
              title={item.name}
            >
              {highlighted}
            </Typography>
          </Box>
        )}
        {item.type === "account" && (
          <MenuItem
            onClick={() =>
              handleMenuItemClick({
                id: item?.id!,
                name: item?.name!,
                routing_number: item.routing_number!,
                account_number: item?.account_number!,
                bank_name: item?.bank_name!,
              })
            }
            key={item?.id}
            sx={{
              paddingY: "10px",
              paddingLeft: "25px",
              display: "flex",
              gap: "5px",
            }}
          >
            <AccountBalanceOutlinedIcon htmlColor="#61B54B" />
            <Typography
              color={COLORS.newSecondaryColor}
              fontWeight={400}
              variant="body1"
              maxWidth="200px"
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
              title={`${item?.name} **${item?.account_number}`}
            >
              {highlighted} **{item?.account_number}
            </Typography>
          </MenuItem>
        )}
      </div>
    );
  };

  useEffect(() => {
    if (Boolean(anchorEl)) {
      handleSearch("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Boolean(anchorEl)]);

  const endIcon = !AccountsStore.isSyncing ? (
    Boolean(anchorEl) ? (
      <ArrowDropUpIcon />
    ) : (
      <ArrowDropDownIcon />
    )
  ) : (
    <CircularProgress size={12} />
  );
  return (
    <div>
      <Button
        variant="outlined"
        endIcon={endIcon}
        onClick={handleClick}
        fullWidth
        sx={{ width: "260px", display: "flex", justifyContent: "space-between" }}
        disabled={AccountsStore.isSyncing}
      >
        <Typography
          variant="body1"
          maxWidth="200px"
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
          title={selectedAccount}
        >
          {selectedAccount || "Select Account"}
        </Typography>
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleSelectBaClose}
        sx={{ ".MuiList-root": { maxWidth: "260px", maxHeight: "500px" } }}
      >
        <TextField
          placeholder="Search"
          value={search}
          onChange={(e) => handleSearch(e.target.value)}
          sx={{ marginLeft: "15px", marginBottom: "15px" }}
        />

        <List height={400} itemCount={items?.length!} itemSize={getItemSize} width={300}>
          {Row}
        </List>
      </Menu>
    </div>
  );
};
