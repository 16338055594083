import { Paper, Tooltip } from "@mui/material";
import Box from "components/common/Box";
import Button from "components/common/Button";
import { Spinner } from "components/common/Spinner/Spinner";
import Table from "components/common/Table";
import Typography from "components/common/Typography";
import { CompanyMenu } from "components/layout/CompanyMenu";
import type { BankAccountLinkedAddressesQuery } from "generated/sdk";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useHistory } from "react-router";
import { useRouteMatch } from "react-router-dom";
import { useStore } from "storeContainer";
import useColumns from "./useColumns";

const headerStyle = {
  alignItems: "center",
  display: "flex",
  flexWrap: "wrap",
  gap: 2,
  justifyContent: "space-between",
  my: 2.5,
};

export interface ILinkedAddressesProps {
  bankAccountId: string;
  addresses: BankAccountLinkedAddressesQuery[];
  className?: string;
  error: any | null;
  loading: boolean;
}

const LinkedAddresses: React.FunctionComponent<ILinkedAddressesProps> = ({
  bankAccountId,
  addresses,
  className,
  error,
  loading,
}) => {
  const { url } = useRouteMatch();
  const { acl } = useStore("SessionStore");
  const canAdd = acl.canAddBankAccountLinkedEmailAddresses(bankAccountId);
  const canRemove = acl.canRemoveBankAccountLinkedEmailAddresses(bankAccountId);
  const tableColumns = useColumns(canRemove);
  const history = useHistory();
  useEffect(() => {
    error && console.error("error:", error);
  }, [error]);
  const goToLinkAddressPage = () => {
    history.push(`${url}/add`);
  };
  return (
    <Box display="flex" width="100%" justifyContent="space-between" gap="130px" maxWidth="1400px">
      <CompanyMenu />
      <Box width="100%">
        <Box sx={headerStyle}>
          <Typography variant="h1" marginY="20px">
            Linked Email Addresses
          </Typography>
          <Tooltip
            title={!canAdd ? "You don't have the required permissions to link a new address." : ""}
            PopperProps={{ style: { textAlign: "center" } }}
          >
            <Box>
              <Button disabled={!canAdd} onClick={goToLinkAddressPage} variant="outlined">
                Link New Address
              </Button>
            </Box>
          </Tooltip>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", height: "100%" }}>
          <Box padding="20px">
            Link email addresses with which to receive vChecks for this bank account. A linked email address cannot be
            claimed as a Cherry™ Account.
          </Box>
          {addresses ? (
            <Box display="flex" width="100%" height="400px">
              <Table pagination columns={tableColumns} data={addresses} loading={loading} />
            </Box>
          ) : (
            <Box padding={2}>
              <Spinner />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default observer(LinkedAddresses);
