import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Dialog, Divider, IconButton, Typography } from "@mui/material";
import { graphqlVBillClient } from "common/graphqlClient";
import { getSdk } from "generated/sdk.vbill";
import { useState } from "react";
import { COLORS } from "themes/default";

const { VBillDeleteBatch } = getSdk(graphqlVBillClient);
// TODO: Remove this comp we have <ConfirmBatchActionDialog />
interface IConfirmDeleteBatchDialogProps {
  batchId: number;
  onCloseDialog: () => void;
  onConfirmDeleteBatchSubmit?: () => void;
  actionsBtnSmallHeight?: boolean;
}
export const ConfirmDeleteBatchDialog = ({
  onCloseDialog,
  onConfirmDeleteBatchSubmit,
  batchId,
  actionsBtnSmallHeight,
}: IConfirmDeleteBatchDialogProps) => {
  const [hasErrorFeedback, setHasErrorFeedback] = useState<boolean>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // TODO: cleanup handleDeleteBatchBtnClick
  const handleDeleteBatchBtnClick = async () => {
    setIsLoading(true);
    setHasErrorFeedback(false);

    try {
      const resp = await VBillDeleteBatch({ batchId });

      if (resp.deleteBatch.error) {
        throw Error();
      }
    } catch (err) {
      setHasErrorFeedback(true);

      return;
    } finally {
      setIsLoading(false);
    }

    onCloseDialog();
    onConfirmDeleteBatchSubmit?.();
  };

  return (
    <Dialog
      open
      onClose={onCloseDialog}
      PaperProps={{
        sx: { padding: "15px", width: 350, position: "relative" },
      }}
    >
      <IconButton onClick={onCloseDialog} sx={{ position: "absolute", top: "5px", right: "5px", zIndex: 1 }}>
        <CloseIcon sx={{ fontSize: "20px" }} />
      </IconButton>

      <Typography sx={{ fontSize: "20px", marginBottom: "20px" }}>Delete Batch</Typography>

      <Typography>Are you sure you want to delete this batch?</Typography>

      {hasErrorFeedback && (
        <Typography color={COLORS.darkRed} textAlign={"center"}>
          An error ocurred while deleting your batch.
        </Typography>
      )}

      <Divider sx={{ marginTop: 4, marginBottom: 2 }} />

      <Box sx={{ display: "flex", justifyContent: "end", gap: "15px" }}>
        <LoadingButton
          loading={isLoading}
          variant="contained"
          onClick={handleDeleteBatchBtnClick}
          sx={{ ...(actionsBtnSmallHeight ? { height: "30px" } : {}) }}
        >
          Delete Batch
        </LoadingButton>

        <Button
          variant="outlined"
          onClick={onCloseDialog}
          sx={{ ...(actionsBtnSmallHeight ? { height: "30px" } : {}) }}
        >
          Cancel
        </Button>
      </Box>
    </Dialog>
  );
};
