import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { RootDelegationGroupWidget } from "components/common/Approval/RootDelegationGroupWidget";
import { Spinner } from "components/common/Spinner/Spinner";
import { debounce } from "lodash";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useParams, useRouteMatch } from "react-router";
import { useStore } from "storeContainer";
import {
  CompanyWithBankAccountsAndAggregatedData,
  DelegationGroupScopeEnum,
  Pagination,
} from "../../../../generated/sdk";
import { CompaniesList } from "./components/CompaniesList";
import { Header } from "./components/Header";
import styles from "./components/Widget.module.scss";

export const OrganizationAccounts: React.FunctionComponent = observer(function OrganizationAccounts() {
  const SessionStore = useStore("SessionStore");
  const OrganizationStore = useStore("OrganizationStore");
  const UndepositedChecksStore = useStore("UndepositedChecksStore");
  const contextStore = useStore("ContextStore");
  const userSettingsStore = useStore("UserSettingsStore");
  const { companiesSelectedIds } = userSettingsStore;
  const { organizationId } = useParams<{ organizationId: string }>();

  const [companiesData, setCompaniesData] = useState<any[]>([]);
  const [orgDashboardFilters, setOrgDashboardFilters] = useState<{ search: string }>({ search: "" });
  const [newSearchLoading, setNewSearchLoading] = useState(false);
  const depositAccountsOnly = !!useRouteMatch("/organization-accounts");
  const [selectedContextTab, setSelectedContextTab] = useState(
    depositAccountsOnly ? "deposit_accounts" : "all_accounts",
  );
  const [currentPageState, setCurrentPageState] = useState(1);
  const { organizationCompaniesWithPagination } = OrganizationStore;
  const currentPage = organizationCompaniesWithPagination.data?.pagination?.current_page || 1;
  const requestData = organizationCompaniesWithPagination;
  const allAccounts = selectedContextTab === "all_accounts";
  const onlyWithTransactions = selectedContextTab === "only_transactions";
  const showDepositAccounts = selectedContextTab === "deposit_accounts";
  const handleAccountsChange = (_: unknown, newValue: string) => {
    setSelectedContextTab(newValue);
  };

  const loadMoreCompanies = useCallback(
    (pagination: Pagination) => {
      setCurrentPageState((prev) => prev + 1);
      if (!requestData.isFetching) {
        OrganizationStore.loadCompanies(
          pagination,
          false,
          { ...orgDashboardFilters, company_ids: companiesSelectedIds },
          organizationId,
          showDepositAccounts,
        ).then(() => {
          setCompaniesData((prevCompaniesData: any) => {
            const newData = [...(prevCompaniesData ?? []), ...(requestData.data?.companies ?? [])];

            return [...new Set(newData)];
          });
        });
      }
    },
    [
      requestData.isFetching,
      requestData.data?.companies,
      OrganizationStore,
      orgDashboardFilters,
      organizationId,
      showDepositAccounts,
    ],
  );

  const debouncedSearch = useRef(
    debounce(async (criteria) => {
      setOrgDashboardFilters(criteria);
    }, 500),
  ).current;

  const fetchBasedOnSearch = useCallback(async () => {
    setNewSearchLoading(true);
    await OrganizationStore.loadCompanies(
      { page: currentPageState, per_page: 1000 },
      false,
      { ...orgDashboardFilters, company_ids: companiesSelectedIds },
      organizationId,
      showDepositAccounts,
    );
  }, [
    OrganizationStore,
    currentPageState,
    orgDashboardFilters,
    organizationId,
    showDepositAccounts,
    companiesSelectedIds,
  ]);

  const filteredCompanies = ((companiesData as CompanyWithBankAccountsAndAggregatedData[]) ?? []).filter((company) => {
    if (onlyWithTransactions) {
      return (
        (company.bank_accounts?.filter((bank_account) => {
          return (
            (bank_account.incoming_checks_stats?.count || 0) > 0 ||
            (bank_account.outgoing_checks_stats?.count || 0) > 0 ||
            (bank_account.pending_approval_checks_stats?.count || 0) > 0
          );
        })?.length || 0) > 0
      );
    } else {
      return true;
    }
  });

  const displayText = useMemo(() => {
    const totalCompanies = companiesSelectedIds.length || filteredCompanies.length;

    if (onlyWithTransactions) {
      return `Showing ${filteredCompanies.length} out of ${totalCompanies}`;
    }

    if (allAccounts) {
      return `${totalCompanies} Companies`;
    }
  }, [allAccounts, companiesSelectedIds.length, filteredCompanies.length, onlyWithTransactions]);

  useEffect(() => {
    contextStore.setSelectedOrganizationId(organizationId);
    UndepositedChecksStore.getUndepositedChecks();
    SessionStore.getInvites({ forceUpdate: false });
    OrganizationStore.loadCompanies(
      { per_page: 1000, page: 1 },
      false,
      { search: "", company_ids: companiesSelectedIds },
      organizationId,
      showDepositAccounts,
    );
    OrganizationStore.loadPendingActions({ per_page: 10, page: 1 });
  }, [OrganizationStore, SessionStore, UndepositedChecksStore, contextStore, organizationId, showDepositAccounts]);

  useEffect(() => {
    if (currentPageState === 1) {
      fetchBasedOnSearch().then(() => {
        if (!organizationCompaniesWithPagination.isFetching && organizationCompaniesWithPagination.isLoaded) {
          setCompaniesData(
            showDepositAccounts
              ? organizationCompaniesWithPagination.data?.unvalidated_dashboard?.companies || []
              : organizationCompaniesWithPagination?.data?.companies || [],
          );
          setNewSearchLoading(false);
        }
      });
    }
  }, [
    currentPageState,
    fetchBasedOnSearch,
    organizationCompaniesWithPagination.data?.companies,
    organizationCompaniesWithPagination.data?.unvalidated_dashboard?.companies,
    organizationCompaniesWithPagination.isFetching,
    organizationCompaniesWithPagination.isLoaded,
    showDepositAccounts,
  ]);

  useEffect(() => {
    if (organizationCompaniesWithPagination.data?.companies && currentPage === 1) {
      setCompaniesData(
        showDepositAccounts
          ? organizationCompaniesWithPagination.data?.unvalidated_dashboard?.companies || []
          : organizationCompaniesWithPagination?.data?.companies || [],
      );
    }
  }, [currentPage, organizationCompaniesWithPagination.data?.companies]);

  useEffect(() => {
    if (!requestData.isFetching) {
      OrganizationStore.loadCompanies(
        { page: 1, per_page: 10 },
        true,
        { ...orgDashboardFilters, company_ids: companiesSelectedIds },
        organizationId,
        showDepositAccounts,
      ).then(() => {
        setCompaniesData((prevCompaniesData: any) => {
          const newData = [
            ...(prevCompaniesData ?? []),
            ...(showDepositAccounts
              ? requestData.data?.unvalidated_dashboard?.companies ?? []
              : requestData.data?.companies ?? []),
          ];

          return [...new Set(newData)];
        });
      });
    }
  }, [companiesSelectedIds, showDepositAccounts]);

  const showDefaultApprover =
    OrganizationStore.organizationDefaultDashboard.data?.vbill_enabled && SessionStore.acl.canManageOrganization;

  return (
    <Box className={styles.organizationAccountContainer}>
      <Box className={styles.companiesListContainer}>
        <Box alignItems="center" display="flex">
          <Header
            selectedContextTab={selectedContextTab}
            depositAccounts={undefined}
            searchKeywords={orgDashboardFilters.search}
            handleChange={(searchKeywords) => {
              debouncedSearch({ search: searchKeywords });
            }}
            setCurrentPageState={setCurrentPageState}
            handleToggleChange={handleAccountsChange}
            depositAccountsOnly={depositAccountsOnly}
          />
        </Box>
        {showDefaultApprover && (
          <div style={{ marginTop: "20px" }}>
            <Typography sx={{ marginTop: "20px", fontSize: "16px" }}>Default bill approver:</Typography>
            <RootDelegationGroupWidget
              scope={DelegationGroupScopeEnum.VbillApprove}
              organizationId={organizationId}
            />{" "}
          </div>
        )}
        <div style={{ marginTop: "20px" }}>
          {(!!filteredCompanies.length || !!companiesSelectedIds.length) && displayText}
        </div>
        {!newSearchLoading ? (
          <CompaniesList
            showDepositAccounts={showDepositAccounts}
            onlyWithTransactions={onlyWithTransactions}
            searchKeywords={orgDashboardFilters.search}
            companies={companiesData as CompanyWithBankAccountsAndAggregatedData[]}
            isFetching={requestData.isFetching}
            hasMore={
              showDepositAccounts
                ? !!requestData.data?.unvalidated_dashboard?.pagination?.has_more_pages
                : !!requestData.data?.pagination?.has_more_pages
            }
            currentPage={currentPage}
            loadCompanies={loadMoreCompanies}
          />
        ) : (
          <Spinner />
        )}

        {companiesData?.length === 0 && !requestData?.isLoading ? (
          <Typography variant="h4" sx={{ display: "flex", m: 2, justifyContent: "center" }}>
            No companies in this organization
          </Typography>
        ) : null}
      </Box>
    </Box>
  );
});
