import { Box, Button, Divider } from "@mui/material";
import { observer } from "mobx-react";
import { useChatChannelStore } from "modules/Chat/hooks/hooks";
import { ChatEditNotifications } from "../ChatEditNotifications/ChatEditNotifications";
import { ToolbarChangeMembers } from "./ToolbarChangeMembers/ToolbarChangeMembers";
import { ToolbarMarkPrivate } from "./ToolbarMarkPrivate/ToolbarMarkPrivate";

export const ChatToolbar = observer(function ChatToolbar() {
  const chatChannelStore = useChatChannelStore();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "5px 10px",
      }}
    >
      <ChatEditNotifications />

      <Box sx={{ display: "flex", alignItems: "center" }}>
        {chatChannelStore?.showVisibility && <ToolbarMarkPrivate />}

        {chatChannelStore?.showMembers && (
          <>
            <Divider orientation="vertical" flexItem sx={{ marginRight: "10px" }} />
            <ToolbarChangeMembers />
          </>
        )}

        {chatChannelStore?.channel?.exists && chatChannelStore?.channel?.canBeClosed && (
          <Button variant="contained" sx={{ height: "32px" }} onClick={() => chatChannelStore?.setCloseChannel()}>
            Resolved
          </Button>
        )}
      </Box>
    </Box>
  );
});
