import { Box, Button, ListItemText, Menu, MenuItem } from "@mui/material";
import { Spinner } from "components/common/Spinner/Spinner";
import { observer } from "mobx-react";
import { chatMemberStore } from "modules/Chat";
import { IGetChatNotificationUrlArgs } from "modules/Chat/utils/utils";
import { useMemo, useState } from "react";
import { useStore } from "storeContainer";
import { LatestMessagesNotification } from "./LatestMessagesNotification/LatestMessagesNotification";

interface IChatMemberLatestMessagesProps {
  menuAnchorEl: HTMLButtonElement | null;
  menuOnClose: () => void;
  onChannelNotificationClick: (channel: IGetChatNotificationUrlArgs) => void;
}

export const ChatMemberLatestMessages = observer(function ChatMemberLatestMessages({
  menuAnchorEl,
  menuOnClose,
  onChannelNotificationClick,
}: IChatMemberLatestMessagesProps) {
  const sessionStore = useStore("SessionStore");
  const sessionAccountId = useMemo(
    () => sessionStore.session.data?.account?.id ?? "",
    [sessionStore.session.data?.account?.id],
  );
  const [isFetchingMoreLatestMessages, setIsFetchingMoreLatestMessages] = useState(false);

  const handleShowMoreBtnClick = async () => {
    await chatMemberStore.loadMoreMemberLatestMessages();
    setIsFetchingMoreLatestMessages(false);
  };

  const memberNotificationsStats = chatMemberStore.memberNotificationsStats;
  const memberLatestMessages = chatMemberStore.memberLatestMessages;

  return (
    <Menu
      id="chat-badge-notifications-menu"
      anchorEl={menuAnchorEl}
      open
      onClose={menuOnClose}
      MenuListProps={{
        "aria-labelledby": "chat-badge-notifications-btn",
      }}
      BackdropProps={{ invisible: false }}
    >
      <Box
        sx={{
          width: "350px",
          maxHeight: "500px",
          overflowY: "auto",
          "&::-webkit-scrollbar": {
            width: "3px",
          },
          "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 10px rgb(219, 219, 219)",
            borderRadius: "10px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "rgba(5, 32, 72, 0.5)",
            borderRadius: "4px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            opacity: 1,
          },
        }}
      >
        {memberLatestMessages?.items.length ? (
          <>
            {memberLatestMessages.items.map((latestMessage) => (
              <LatestMessagesNotification
                key={latestMessage.id}
                latestMessage={latestMessage}
                onChannelNotificationClick={onChannelNotificationClick}
                notificationsCount={
                  memberNotificationsStats?.perChannelCount?.find(
                    ({ channelId }) => channelId === latestMessage.channelId,
                  )?.count
                }
                isMessageOwner={sessionAccountId === latestMessage.member.accountId}
              />
            ))}

            {memberLatestMessages.hasMore && (
              <MenuItem>
                {isFetchingMoreLatestMessages ? (
                  <Spinner />
                ) : (
                  <Button
                    onClick={handleShowMoreBtnClick}
                    sx={{ padding: 0, height: "12px", "&:hover": { textDecoration: "underline" } }}
                  >
                    Show more
                  </Button>
                )}
              </MenuItem>
            )}
          </>
        ) : (
          <MenuItem disabled>
            <ListItemText primary="No Chat Notifications" />
          </MenuItem>
        )}
      </Box>
    </Menu>
  );
});
