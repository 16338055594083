import { Box, Grid } from "@mui/material";
import React from "react";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import Typography from "components/common/Typography";
import { BankAccountBasic, ConditionInputGraphQlType } from "generated/sdk";
import { LockedDragIndicator } from "icons/svg/LockedDragIndicator";
import style from "./AddRulePage.module.scss";
import { Link } from "react-router-dom";

const DraggableRule: React.FC<{
  criteria: ConditionInputGraphQlType;
  bankAccount: BankAccountBasic;
  organizationId?: string;
  organizationName?: string;
  companyId?: string;
  companyName?: string;
  isNewRule?: boolean;
  isSelectedRule?: boolean;
}> = ({
  criteria,
  bankAccount,
  organizationId,
  organizationName,
  isNewRule,
  companyId,
  companyName,
  isSelectedRule,
}) => {
  const isDefaultRule = !criteria.memos?.length && !criteria.senders?.length && !criteria.pttoos?.length;
  return (
    <Grid
      alignItems={"center"}
      container
      flexDirection={"row"}
      sx={{ backgroundColor: isSelectedRule || isNewRule ? "#F5FAFF" : "initial", width: "100%" }}
    >
      <Grid item xs={1}>
        {isDefaultRule ? (
          <LockedDragIndicator sx={{ transform: "scale(1.5)" }} />
        ) : (
          <DragIndicatorIcon color="disabled" sx={{ transform: "scale(1.5)" }} />
        )}
      </Grid>
      <Grid xs={11} justifyContent="space-between" container item>
        <Grid alignItems={"center"} xs={6} item container flexDirection={"row"}>
          {!!criteria.pttoos?.length && (
            <Grid item alignItems={"center"} xs={12} container flexDirection={"row"}>
              <Typography variant="body1" fontWeight="500">
                PTTOOS =
              </Typography>
              {criteria.pttoos?.map((item, index) => (
                <Grid alignItems={"center"} width={"auto"} container item key={`pttoo-${item}-${index}`}>
                  <Typography variant="body1" fontWeight="400">
                    {item}
                  </Typography>
                  {index !== (criteria.pttoos?.length as number) - 1 && (
                    <Typography marginX={0.5} variant="body2" fontSize={9} textTransform={"uppercase"}>
                      or
                    </Typography>
                  )}
                </Grid>
              ))}
            </Grid>
          )}
          {!!criteria.senders?.length && (
            <Grid item alignItems={"center"} xs={12} container flexDirection={"row"}>
              <Typography variant="body1" fontWeight="500">
                SENDERS =
              </Typography>
              {criteria.senders?.map((item, index) => (
                <Grid alignItems={"center"} width={"auto"} container item key={`sender-${item}-${index}`}>
                  <Typography variant="body1" fontWeight="400">
                    {item}
                  </Typography>
                  {index !== (criteria.senders?.length as number) - 1 && (
                    <Typography marginX={0.5} variant="body2" fontSize={9} textTransform={"uppercase"}>
                      or
                    </Typography>
                  )}
                </Grid>
              ))}
            </Grid>
          )}
          {!!criteria.memos?.length && (
            <Grid item alignItems={"center"} xs={12} container flexDirection={"row"}>
              <Typography variant="body1" fontWeight="500">
                MEMOS =
              </Typography>
              {criteria.memos?.map((item, index) => (
                <Grid alignItems={"center"} width={"auto"} container item key={`memo-${item}-${index}`}>
                  <Typography variant="body1" fontWeight="400">
                    {item}
                  </Typography>
                  {index !== (criteria.memos?.length as number) - 1 && (
                    <Typography marginX={0.5} variant="body2" fontSize={9} textTransform={"uppercase"}>
                      or
                    </Typography>
                  )}
                </Grid>
              ))}
            </Grid>
          )}
          {isDefaultRule && (
            <Box display="flex" flexDirection="column">
              <Typography variant="h4">Main rule</Typography>
              <Typography variant="body1">(default for all vChecks)</Typography>
            </Box>
          )}
        </Grid>
        <Grid xs={6} item>
          {isNewRule && (
            <Typography variant="h5" color="#2877EC">
              New rule
            </Typography>
          )}
          <Box display="flex" gap="5px" marginTop="5px" flexDirection="column">
            <Typography variant="h4" color="#052048">
              {bankAccount?.name} ** {bankAccount.account_number}
            </Typography>
            {organizationId && companyId && (
              <Box display="flex" gap="5px" marginTop="5px">
                <Typography variant="h5" color="#052048">
                  {organizationName}
                </Typography>
                {">"}
                <Link className={style.orgLink} to={`/org/${organizationId}/company/${companyId}`}>
                  {companyName}
                </Link>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DraggableRule;
