import ClearIcon from "@mui/icons-material/Clear";
import {
  Box,
  Button,
  Dialog,
  Divider,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { getSODAsUTCIsoString } from "common/helpers/utils";
import { CloseIcon } from "components/common/icons";
import { IVBillBillsFiltersOutput } from "generated/sdk.vbill";
import { useEffect, useState } from "react";

interface ISummariesDateDialogProps {
  date?: IVBillBillsFiltersOutput["date"];
  onDateChange: (date?: string) => void;
  onCloseDialog: () => void;
}

export const SummariesDateDialog = ({ date, onCloseDialog, onDateChange }: ISummariesDateDialogProps) => {
  const [localDate, setLocalDate] = useState<IVBillBillsFiltersOutput["date"]>(date ?? null);
  useEffect(() => {
    setLocalDate(date ?? null);
  }, [date]);

  const handleSaveDialog = () => {
    onDateChange(localDate ? getSODAsUTCIsoString(new Date(localDate)) : undefined);
  };

  return (
    <Dialog onClose={onCloseDialog} open PaperProps={{ sx: { padding: "15px", position: "relative", width: "250px" } }}>
      <Tooltip arrow title="Close">
        <IconButton onClick={onCloseDialog} sx={{ position: "absolute", top: "5px", right: "5px", zIndex: 1 }}>
          <CloseIcon sx={{ fontSize: "20px" }} />
        </IconButton>
      </Tooltip>

      <Typography sx={{ fontSize: "20px", marginBottom: "40px" }}>Batch date:</Typography>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSaveDialog();
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Select date"
            value={localDate}
            onChange={(date) => {
              setLocalDate(date);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                sx={{ width: "100%" }}
                {...(localDate && {
                  InputProps: {
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title="Clear" arrow>
                          <IconButton onClick={() => setLocalDate(null)} sx={{ marginRight: "-12px" }}>
                            <ClearIcon />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  },
                })}
              />
            )}
          />
        </LocalizationProvider>

        <Divider sx={{ margin: "20px 0" }} />

        <Box sx={{ display: "flex", justifyContent: "end", gap: "15px" }}>
          <Button variant="contained" type="submit" sx={{ height: "30px" }}>
            Save
          </Button>
          <Button variant="outlined" onClick={onCloseDialog} sx={{ height: "30px" }}>
            Cancel
          </Button>
        </Box>
      </form>
    </Dialog>
  );
};
