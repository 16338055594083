import { Skeleton, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import { observer } from "mobx-react";
import { useStore } from "storeContainer";
import { Payment } from "../Payment/Payment";

const emptySkeletonList = new Array(50).fill("");

export const PaymentsLedgerList = observer(function PaymentsLedgerList() {
  const vBillPaymentsLedgerStore = useStore("VBillPaymentsLedgerStore");

  const { paymentsLedgerList } = vBillPaymentsLedgerStore;
  const paymentsLedgerListData = paymentsLedgerList.data;

  return (
    <TableBody>
      {paymentsLedgerList.isLoading ? (
        <>
          {emptySkeletonList.map((_, index) => (
            <TableRow key={index} sx={{ cursor: "default" }}>
              <TableCell colSpan={11} sx={{ padding: "12px" }}>
                <Skeleton variant="rounded" sx={{ height: "31px" }} />
              </TableCell>
            </TableRow>
          ))}
        </>
      ) : (paymentsLedgerListData?.items ?? []).length === 0 ? (
        <TableRow>
          <TableCell colSpan={12} sx={{ textAlign: "center" }}>
            <Typography>No results found</Typography>
          </TableCell>
        </TableRow>
      ) : (
        paymentsLedgerListData?.items.map((payment) => <Payment key={payment.paymentId} payment={payment} />)
      )}
    </TableBody>
  );
});
