import { graphqlVBillClient } from "common/graphqlClient";
import {
  getSdk,
  IVBillVBillCompaniesSettingsQuery,
  IVBillVBillCompaniesSettingsQueryVariables,
  IVBillVBillInvoicesQuery,
  IVBillVBillInvoicesQueryVariables,
  IVBillVBillInvoicesStatsQuery,
  IVBillVBillInvoicesStatsQueryVariables,
} from "generated/sdk.vbill";
import { action, makeObservable, observable } from "mobx";
import { createObservableContainer } from "storeContainer";
import { StoreBase } from "./StoreBase";

const { VBillInvoices, VBillInvoicesStats, VBillCompaniesSettings } = getSdk(graphqlVBillClient);

export class VBillCodingLedgerStore extends StoreBase {
  constructor() {
    super();
    makeObservable(this, {
      showVBillRequestErrorToast: observable,
      setShowVBillRequestErrorToast: action,
    });
  }

  showVBillRequestErrorToast: boolean = false;

  invoicesList = createObservableContainer<NonNullable<IVBillVBillInvoicesQuery["invoices"]>>();
  invoicesStats = createObservableContainer<NonNullable<IVBillVBillInvoicesStatsQuery["invoices"]>>();
  companiesSettings =
    createObservableContainer<IVBillVBillCompaniesSettingsQuery["companiesSettings"]["vBill"]["additionalMappings"]>();

  // persist selected filters when you came back from invoice
  ledgerRecordedNavigation?: string;

  setLedgerRecordedNavigation(val: string) {
    this.ledgerRecordedNavigation = val;
  }

  loadInvoicesList(data: IVBillVBillInvoicesQueryVariables, force?: boolean) {
    this._preGqlReqWithErrorToast(() =>
      this.invoicesList.cachedLoad(async () => (await VBillInvoices(data)).invoices, [data], {
        forceUpdate: force,
        catchError: false,
      }),
    );
  }

  loadInvoicesStats(data: IVBillVBillInvoicesStatsQueryVariables, force?: boolean) {
    this._preGqlReqWithErrorToast(() =>
      this.invoicesStats.cachedLoad(async () => (await VBillInvoicesStats(data)).invoices, [data], {
        forceUpdate: force,
        catchError: false,
      }),
    );
  }

  loadCompaniesSettings = (data: IVBillVBillCompaniesSettingsQueryVariables, force?: boolean) => {
    this._preGqlReqWithErrorToast(() =>
      this.companiesSettings.cachedLoad(
        async () => await (await VBillCompaniesSettings(data)).companiesSettings.vBill.additionalMappings,
        [data],
        {
          forceUpdate: force,
          catchError: false,
        },
      ),
    );
  };

  setShowVBillRequestErrorToast = (value: boolean) => {
    this.showVBillRequestErrorToast = value;
  };

  _preGqlReqWithErrorToast = async <T>(promiseFunc: () => Promise<T>) => {
    try {
      return await promiseFunc();
    } catch (_e) {
      this.setShowVBillRequestErrorToast(true);

      return Promise.resolve(undefined);
    }
  };
}
