import { useEffect, useMemo, useRef, useState } from "react";

export function useMessageInViewportOnce() {
  const domElementRef = useRef<HTMLDivElement>(null);
  const [isInView, setIsInView] = useState(false);

  const observer = useMemo(
    () => new IntersectionObserver(([entry]) => handleIntersectionObserverEntry(entry), { threshold: 1 }),
    [],
  );

  const handleIntersectionObserverEntry = (entry: IntersectionObserverEntry) => {
    if (entry.isIntersecting && !isInView) {
      setIsInView(entry.isIntersecting);
      observer.disconnect();
    }
  };

  useEffect(() => {
    if (!domElementRef.current) {
      return;
    }

    observer.observe(domElementRef.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  return { isInView, domElementRef };
}
