import { Table, TableBody, TableContainer } from "@mui/material";
import { cloneDeep } from "lodash";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import { useMemo, useState } from "react";
import { useStore } from "storeContainer";
import {
  VBillLineItemsAccordian,
  VBillLineItemsAccordianDetails,
  VBillLineItemsAccordianSummary,
} from "../../common/VBillLineItemsAccordian/VBillLineItemsAccordian";
import { VBillLineItemsHead } from "../../common/VBillLineItemsHead/VBillLineItemsHead";
import { SummaryLineItem } from "./SummaryLineItem/SummaryLineItem";

export const SummaryLineItems = observer(() => {
  const vBillStore = useStore("VBillStore");
  const [lineItemsExpanded, setLineItemsExpanded] = useState(false);
  const invoiceData = useMemo(() => vBillStore.invoice.data, [vBillStore.invoice.data]);
  const invoiceLineItemsData = useMemo(
    () => cloneDeep(toJS(invoiceData?.lineItems ?? [])).sort((a, b) => (a.orderIndex ?? 0) - (b.orderIndex ?? 0)),
    [invoiceData?.lineItems],
  );
  const hasLineItems = invoiceData?.hasLineItems;

  if (!hasLineItems) {
    return null;
  }

  return (
    <VBillLineItemsAccordian
      expanded={lineItemsExpanded}
      onChange={() => setLineItemsExpanded((expanded) => !expanded)}
    >
      <VBillLineItemsAccordianSummary
        accordianExpanded={lineItemsExpanded}
        headingTotalLineItems={invoiceLineItemsData.length}
      />
      <VBillLineItemsAccordianDetails>
        <TableContainer sx={{ marginBottom: "20px" }}>
          <Table>
            <VBillLineItemsHead />

            <TableBody>
              {invoiceLineItemsData.map((lineItem, index) => (
                <SummaryLineItem key={lineItem.id} lineItem={lineItem} index={index} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </VBillLineItemsAccordianDetails>
    </VBillLineItemsAccordian>
  );
});
