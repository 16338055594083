import CloseIcon from "@mui/icons-material/Close";
import { Box, Dialog, IconButton, Tooltip } from "@mui/material";
import { graphqlVBillClient } from "common/graphqlClient";
import { Spinner } from "components/common/Spinner/Spinner";
import { IVBillVBillGetBatchListQuery } from "generated/graphql.vbill";
import { getSdk } from "generated/sdk.vbill";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { MoveBatchList } from "./MoveBatchList/MoveBatchList";

const { VBillGetBatchList } = getSdk(graphqlVBillClient);

interface IMoveInsideAnoterBatchDialogProps {
  onCloseDialog: () => void;
  batch: NonNullable<IVBillVBillGetBatchListQuery["getBatchList"]["data"]>["items"][number];
}

export const MoveInsideAnoterBatchDialog = ({ onCloseDialog, batch }: IMoveInsideAnoterBatchDialogProps) => {
  const { organizationId } = useParams<{ organizationId: string }>();
  const [batchList, setBatchList] = useState<
    NonNullable<IVBillVBillGetBatchListQuery["getBatchList"]["data"]>["items"]
  >([]);
  const [isBatchListLoading, setIsBatchListLoading] = useState(false);

  useEffect(() => {
    setIsBatchListLoading(true);
    VBillGetBatchList({ filters: { organizationId }, pagination: { page: 1, per_page: 10 } }).then((resp) => {
      if (resp.getBatchList.data) {
        setBatchList(resp.getBatchList.data.items);
      }

      setIsBatchListLoading(false);
    });
  }, [organizationId]);

  return (
    <Dialog
      onClose={onCloseDialog}
      open
      PaperProps={{
        sx: { padding: "15px", width: 350, position: "relative" },
      }}
    >
      <Tooltip arrow title="Close">
        <IconButton onClick={onCloseDialog} sx={{ position: "absolute", top: "5px", right: "5px", zIndex: 1 }}>
          <CloseIcon sx={{ fontSize: "20px" }} />
        </IconButton>
      </Tooltip>

      {isBatchListLoading ? (
        <Box sx={{ textAlign: "center", padding: "50px 0" }}>
          <Spinner />
        </Box>
      ) : (
        <MoveBatchList batch={batch} onCloseDialog={onCloseDialog} ledgerList={batchList} />
      )}
    </Dialog>
  );
};
