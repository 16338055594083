import { Box, Drawer, IconButton, Tooltip } from "@mui/material";
import { graphqlVBillClient } from "common/graphqlClient";
import { CloseIcon } from "components/common/icons";
import { getSdk, IVBillVBillInvoiceQuery } from "generated/sdk.vbill";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useStore } from "storeContainer";
import { COLORS } from "themes/default";
import { DetailsDrawerSkeleton } from "./DetailsDrawerSkeleton/DetailsDrawerSkeleton";
import { VBillDetails } from "./VBillDetails/VBillDetails";

const { VBillInvoice } = getSdk(graphqlVBillClient);

interface IVBillDetailsDrawerProps {
  vBillId: number;
  onCloseDrawer: () => void;
}

export const VBillDetailsDrawer = observer(({ onCloseDrawer, vBillId }: IVBillDetailsDrawerProps) => {
  const vBillBatchDetailsStore = useStore("VBillBatchDetailsStore");
  const [vBillData, setVBillData] = useState<IVBillVBillInvoiceQuery["invoice"]>();

  useEffect(() => {
    VBillInvoice({ invoiceId: vBillId }).then((resp) => {
      setVBillData(resp.invoice);
    });
  }, [vBillId]);

  useEffect(() => {
    if (!vBillData?.companyId) {
      return;
    }

    vBillBatchDetailsStore.getVBillAdditionalMappingsSettings({ companyId: vBillData.companyId });
  }, [vBillBatchDetailsStore, vBillData?.companyId]);

  return (
    <Drawer anchor="right" open onClose={onCloseDrawer}>
      <Box sx={{ backgroundColor: COLORS.white, width: "800px", padding: "20px 30px", position: "relative" }}>
        <Tooltip arrow title="Close">
          <IconButton onClick={onCloseDrawer} sx={{ position: "absolute", top: "5px", right: "5px", zIndex: 1 }}>
            <CloseIcon sx={{ fontSize: "20px" }} />
          </IconButton>
        </Tooltip>

        {!vBillData || vBillBatchDetailsStore.vBillAdditionalMappingsSettings.isLoading ? (
          <DetailsDrawerSkeleton />
        ) : (
          <VBillDetails
            vBillAdditionalMappingsSettings={vBillBatchDetailsStore.vBillAdditionalMappingsSettings.data}
            vBillData={vBillData}
          />
        )}
      </Box>
    </Drawer>
  );
});
