import { memo } from "react";
import classnames from "classnames";
import styles from "./Content.module.scss";
import { IContentProps } from "./types";

function Content({ children, className, spacingClass, ...rest }: IContentProps) {
  return (
    <div className={classnames(styles.content, spacingClass, className)} {...rest}>
      {children}
    </div>
  );
}

export default memo(Content);
