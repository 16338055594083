import { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useHistory, useParams } from "react-router";
import { useStore } from "storeContainer";
import DepositAddressDetails from "./DepositAddressDetails";

const DepositAddressDetailsContainer = () => {
  const [autoDepositAvailableAddresses, setAutoDepositAvailableAddresses] = useState<any>();
  const [autoDepositAddresses, setAutoDepositAddresses] = useState<any>();
  const [autoDepositAddressLoading, setAutoDepositAddressesLoading] = useState(false);
  const history = useHistory();
  const { accountId } = useParams<{ accountId: string }>();
  const { addAddress, error, isSyncing, getAutoDepositAvailableAddresses, getAutoDepositAddresses } =
    useStore("AccountsStore");
  const { acl } = useStore("SessionStore");

  const isReadOnly = !acl.canAddBankAccountAutoDepositAddresses(accountId);

  const handleSave = useCallback(
    (emailId) => {
      addAddress(accountId, emailId);
    },
    [accountId, addAddress],
  );

  const fetchAutoDepositAvailableAddresses = useCallback(async () => {
    setAutoDepositAddressesLoading(true);
    setAutoDepositAvailableAddresses(
      (await getAutoDepositAvailableAddresses(accountId)).BankAccountAvailableAutoDepositAddresses,
    );
    setAutoDepositAddressesLoading(false);
  }, [getAutoDepositAvailableAddresses]);

  const fetchAutoDepositAddresses = useCallback(async () => {
    setAutoDepositAddressesLoading(true);
    setAutoDepositAddresses((await getAutoDepositAddresses(accountId)).BankAccountAutoDepositAddresses);
    setAutoDepositAddressesLoading(false);
  }, [getAutoDepositAddresses]);

  useEffect(() => {
    if (acl.aclDone) {
      if (accountId && !isReadOnly) {
        fetchAutoDepositAvailableAddresses();
        fetchAutoDepositAddresses();
      }
    }
  }, [accountId, isReadOnly, acl.aclDone, acl, history, fetchAutoDepositAddresses, fetchAutoDepositAvailableAddresses]);

  return (
    <DepositAddressDetails
      error={error}
      autoDepositAddresses={autoDepositAddresses}
      autoDepositAvailableAddresses={autoDepositAvailableAddresses}
      syncing={isSyncing}
      accountId={accountId}
      onSave={handleSave}
      isReadOnly={isReadOnly}
      fetchAutoDepositAddresses={fetchAutoDepositAddresses}
      fetchAutoDepositAvailableAddresses={fetchAutoDepositAvailableAddresses}
      autoDepositAddressLoading={autoDepositAddressLoading}
      setAutoDepositAddressesLoading={setAutoDepositAddressesLoading}
    />
  );
};

export default observer(DepositAddressDetailsContainer);
