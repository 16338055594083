import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Dialog, Divider, IconButton, TextField, Tooltip } from "@mui/material";
import Typography from "@mui/material/Typography";
import { VBillPriceAmount } from "components/pages/common/VBill/VBillPriceAmount/VBillPriceAmount";
import { IVBillVBillGetBatchListQuery } from "generated/sdk.vbill";
import { useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { COLORS } from "themes/default";

const inputTitleStyles = { color: COLORS.darkGrey, fontWeight: 500, margin: "20px 0 10px" };

export interface IVBillAddEditBatchSubmitPayload {
  budget?: string | null;
  name?: string | null;
}

export interface IVBillAddEditBatchDialogProps {
  showNamePicker?: boolean;
  showBudgetPicker?: boolean;
  batchData?: NonNullable<IVBillVBillGetBatchListQuery["getBatchList"]["data"]>["items"][number] | null;
  onDialogClose: () => void;
  actionsBtnSmallHeight?: boolean;
  onSubmit: (payload: IVBillAddEditBatchSubmitPayload) => Promise<void>; // TODO: Rename
}

export const VBillAddEditBatchDialog = ({
  showNamePicker,
  showBudgetPicker,
  onDialogClose,
  batchData,
  actionsBtnSmallHeight,
  onSubmit,
}: IVBillAddEditBatchDialogProps) => {
  const { handleSubmit, control } = useForm<IVBillAddEditBatchSubmitPayload>({
    defaultValues: {
      name: batchData?.name,
      budget: batchData?.budget,
    },
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onFormSubmit: SubmitHandler<IVBillAddEditBatchSubmitPayload> = async ({ name, budget }) => {
    setIsLoading(true);
    await onSubmit({ name, budget });
    setIsLoading(false);
  };

  return (
    <Dialog onClose={onDialogClose} open PaperProps={{ sx: { padding: "15px", position: "relative", width: "350px" } }}>
      <Tooltip arrow title="Close">
        <IconButton onClick={onDialogClose} sx={{ position: "absolute", top: "5px", right: "5px", zIndex: 1 }}>
          <CloseIcon sx={{ fontSize: "20px" }} />
        </IconButton>
      </Tooltip>

      <form onSubmit={handleSubmit(onFormSubmit)}>
        <Typography sx={{ fontSize: "20px", marginBottom: "20px", maxWidth: "285px" }}>
          {batchData ? (
            <>
              Edit batch: <strong>{batchData?.name ?? "-"}</strong>
            </>
          ) : (
            "Add batch:"
          )}
        </Typography>

        {showNamePicker && (
          <>
            <Typography sx={inputTitleStyles}>Batch name:</Typography>
            <Controller
              name="name"
              control={control}
              rules={{ required: "Batch Name is required." }}
              render={({ field, fieldState }) => (
                <TextField
                  placeholder="Name"
                  fullWidth
                  {...field}
                  error={Boolean(fieldState.error?.message)}
                  helperText={fieldState.error?.message ? fieldState.error.message : undefined}
                />
              )}
            />
          </>
        )}

        {showBudgetPicker && (
          <>
            <Typography sx={inputTitleStyles}>Budget:</Typography>
            <Controller
              name="budget"
              control={control}
              rules={{
                required: "Budget is required.",
              }}
              render={({ field, fieldState }) => (
                <>
                  <VBillPriceAmount
                    sx={{ width: "100%" }}
                    value={field.value ?? ""}
                    setValue={field.onChange}
                    errorMsg={fieldState.error?.message}
                    placeholder="Budget"
                  />
                </>
              )}
            />
          </>
        )}

        <Divider sx={{ marginTop: 4, marginBottom: 2 }} />

        <Box sx={{ display: "flex", justifyContent: "end", gap: "15px" }}>
          <LoadingButton
            loading={isLoading}
            variant="contained"
            type="submit"
            sx={{ ...(actionsBtnSmallHeight ? { height: "32px" } : {}) }}
          >
            Save
          </LoadingButton>

          <Button
            variant="outlined"
            onClick={onDialogClose}
            sx={{ ...(actionsBtnSmallHeight ? { height: "32px" } : {}) }}
          >
            Cancel
          </Button>
        </Box>
      </form>
    </Dialog>
  );
};
