import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TreeItem from "@mui/lab/TreeItem";
import TreeView from "@mui/lab/TreeView";
import { FormControlLabel, InputLabel, Paper, Popover, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import { ExtendedCheckFiltersType1, useCheckFilters } from "common/hooks/useCheckFilters";
import {
  LedgerFilterInputType,
  ProcessingOption,
  ProcessingTypeFilterConfig,
  getFiltersConfig,
} from "components/Filter/types";
import { useEffect, useState } from "react";
import { useParams, useRouteMatch } from "react-router-dom";
import { clearFiltersBtnStyle } from "./ActiveFilters";

interface SelectedOption {
  processingType: string;
  subselect: string[];
}

export const ProcessingTypeFilters = () => {
  const { filters, setFilters } = useCheckFilters<ExtendedCheckFiltersType1>();
  const [selected, setSelected] = useState<SelectedOption[]>([]);
  const [expanded, setExpanded] = useState<string[]>([]);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleToggle = (event: React.ChangeEvent<{}>, nodeIds: string[]) => {
    setExpanded(alwaysExpanded);
  };

  const handleParentClick = (parentValue: string) => {
    setSelected((prevSelected) => {
      const parentIndex = prevSelected.findIndex((item) => item.processingType === parentValue);
      if (parentIndex !== -1) {
        // Remove the parent
        return prevSelected.filter((_, index) => index !== parentIndex);
      } else {
        // Add the parent and remove its children
        return [...prevSelected, { processingType: parentValue, subselect: [] }];
      }
    });
  };

  const handleChildClick = (parentValue: string, childValue: string) => {
    setSelected((prevSelected) => {
      const parentIndex = prevSelected.findIndex((item) => item.processingType === parentValue);
      if (parentIndex !== -1) {
        const newSelected = [...prevSelected];
        const parent = { ...newSelected[parentIndex] }; // Clone to avoid mutation
        const childIndex = parent.subselect.indexOf(childValue);

        if (childIndex !== -1) {
          // Remove the child
          parent.subselect = parent.subselect.filter((_, index) => index !== childIndex);
        } else {
          // Add the child
          parent.subselect = [...parent.subselect, childValue];
        }

        // Update the parent's children without removing the parent
        newSelected[parentIndex] = parent;

        return newSelected;
      } else {
        // Add the child and remove the parent
        return [...prevSelected, { processingType: parentValue, subselect: [childValue] }];
      }
    });
  };

  const clearAll = () => {
    setSelected([]);
  };
  const { path } = useRouteMatch();
  const { selectedQuickFilter } = useParams<{ selectedQuickFilter: string }>();

  const formConfig = (getFiltersConfig(path, selectedQuickFilter) as any)?.[
    LedgerFilterInputType.ProcessingTypeFilter
  ] as ProcessingTypeFilterConfig;

  const alwaysExpanded = formConfig?.processingOptions.map((option: ProcessingOption) => option.value);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    if (selected.length || filters[formConfig?.keyName]?.length) {
      saveFilters();
    }
    setAnchorEl(null);
  };

  const popoverOpen = Boolean(anchorEl);
  const selectedItemsString = selected.map((item) => item.processingType);
  const saveFilters = () => {
    const newProcessingType = selected.map((item) => ({
      [formConfig?.keyNameInput]: item.processingType,
      [formConfig?.keyNameOptions]: item.subselect,
    }));

    setFilters((prevFilters) => {
      const filtersObject = {
        ...prevFilters,
        [formConfig?.keyName]: newProcessingType.length > 0 ? newProcessingType : undefined,
      };

      return filtersObject;
    });
  };

  useEffect(() => {
    if (filters?.[formConfig?.keyName]) {
      const initialSelected = filters[formConfig?.keyName].map((item: any) => ({
        processingType: item[formConfig?.keyNameInput],
        subselect: item[formConfig?.keyNameOptions],
      }));
      setSelected(initialSelected);
    } else {
      setSelected([]);
    }
  }, [filters, formConfig?.keyName, formConfig?.keyNameInput, formConfig?.keyNameOptions]);

  useEffect(() => {
    if (formConfig) {
      setExpanded(formConfig?.processingOptions.map((option) => option.value));
    }
  }, [formConfig]);

  return !formConfig ? null : (
    <Box alignSelf={"flex-end"}>
      <Button
        variant={filters?.[formConfig?.keyName] ? "containedFilters" : "outlined"}
        onClick={handleClick}
        endIcon={<ExpandMoreIcon />}
        sx={{ fontSize: "12px", width: "150px" }}
      >
        {selectedItemsString.length === 1
          ? selectedItemsString[0]
          : selectedItemsString.length > 1
            ? `${selectedItemsString.length} Payment Types`
            : "Type"}
      </Button>
      <Popover
        sx={{ top: "5px" }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={handleClose}
        anchorEl={anchorEl}
        open={popoverOpen}
      >
        <Paper sx={{ display: "flex", flexDirection: "column", padding: "10px" }}>
          <Box>
            <TreeView expanded={expanded} onNodeToggle={handleToggle}>
              {formConfig?.processingOptions.map((parent: any) => (
                <TreeItem
                  key={parent.value}
                  nodeId={parent.value}
                  label={
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="secondary"
                          checked={selected.some((item) => item.processingType === parent.value)}
                          onChange={() => handleParentClick(parent.value)}
                        />
                      }
                      label={parent.label}
                    />
                  }
                  sx={{ padding: 1 }}
                >
                  {parent.options.map((child: any) => (
                    <TreeItem
                      key={child.value}
                      nodeId={child.value}
                      label={
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={selected.some(
                                (item) => item.processingType === parent.value && item.subselect.includes(child.value),
                              )}
                              onChange={() => handleChildClick(parent.value, child.value)}
                            />
                          }
                          label={child.label}
                        />
                      }
                      sx={{ padding: 1 }}
                    />
                  ))}
                </TreeItem>
              ))}
            </TreeView>
          </Box>

          <Box display="flex" justifyContent="space-between" alignItems={"center"} marginTop="10px">
            <Typography
              sx={clearFiltersBtnStyle}
              onClick={() => {
                clearAll();
              }}
            >
              Clear
            </Typography>

            <Button sx={{ marginTop: "10px" }} onClick={handleClose} variant="contained">
              Done
            </Button>
          </Box>
        </Paper>
      </Popover>
    </Box>
  );
};
