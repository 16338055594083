import { Avatar, Box, IconButton, Tooltip, Typography } from "@mui/material";
import { ReplyIcon } from "components/common/icons";
import { format, formatDistance } from "date-fns";
import { IChatChatIoMessageOutputSchema, IChatMemberType } from "generated/sdk.chat";
import { isNil } from "lodash";
import { observer } from "mobx-react";
import { useChatChannelStore } from "modules/Chat/hooks/hooks";
import { FunctionComponent, useEffect } from "react";
import { ChatMessageBlockContent } from "./ChatMessageBlockContent/ChatMessageBlockContent";
import { MessageContentReply } from "./MessageContentReply/MessageContentReply";
import { MessageReactionsList } from "./MessageReactionsList/MessageReactionsList";
import { MessageReactionsPicker } from "./MessageReactionsPicker/MessageReactionsPicker";
import { MessageRealTimeAgo } from "./MessageRealTimeAgo/MessageRealTimeAgo";
import { useMessageInViewportOnce } from "./useMessageInViewportOnce";

interface IChatMessageProps {
  message: IChatChatIoMessageOutputSchema;
}

// TODO: maybe not observer? test with lots of messages
export const ChatMessage: FunctionComponent<IChatMessageProps> = observer(
  ({ message: { id, memberId, content, createdAt }, message }) => {
    const chatChannelStore = useChatChannelStore();
    const { domElementRef, isInView } = useMessageInViewportOnce();

    useEffect(() => {
      if (id && isInView && chatChannelStore?.channelMessageNotification?.lastMessageId === id) {
        chatChannelStore?.setUpdateLastSeenMessage(id);
      }
    }, [isInView, chatChannelStore?.channelMessageNotification?.lastMessageId, id, chatChannelStore]);

    useEffect(() => {
      const hasReaction = chatChannelStore?.channelReactionNotification?.unseenReactionIds?.find(
        (reactionMsgId) => reactionMsgId === id,
      );

      if (!isNil(id) && hasReaction) {
        chatChannelStore?.setUpdateLastSeenReaction(id);
      }
    }, [chatChannelStore?.channelReactionNotification?.unseenReactionIds, id, chatChannelStore]);

    const isMessageOwner = memberId === chatChannelStore?.currentSessionMember?.id;
    const memberNameInChannel = chatChannelStore?.members?.find(({ id }) => id === memberId);
    const currentMemberName = memberNameInChannel?.name ?? "";

    return (
      <Box
        sx={{
          display: "flex",
          gap: "10px",
          marginBottom:
            chatChannelStore?.orderedMessages[chatChannelStore?.orderedMessages.length - 1].id !== id ? "30px" : "0px",
          ...(isMessageOwner ? { flexDirection: "row-reverse" } : {}),
        }}
      >
        {!isMessageOwner && <Avatar {...stringAvatar(currentMemberName)} />}

        <Box ref={domElementRef}>
          <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
            {!isMessageOwner && (
              <Typography sx={{ fontWeight: 500, fontSize: 14, marginRight: "5px" }}>
                {currentMemberName} {memberNameInChannel?.type === IChatMemberType.Admin ? "(Cherry)" : ""}
              </Typography>
            )}
            <Tooltip title={format(new Date(createdAt), "h:maaa MM/dd/yyyy")}>
              <Typography sx={{ fontSize: 12 }}>
                <MessageRealTimeAgo getFormattedTime={() => formatDistance(new Date(createdAt), new Date())} />
              </Typography>
            </Tooltip>
          </Box>
          <Box sx={{ marginBottom: "5px" }}>
            <MessageContentReply message={message} />
            <ChatMessageBlockContent content={content} isMessageOwner={isMessageOwner} />
          </Box>

          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box sx={{ display: "flex" }}>
              {chatChannelStore?.channel?.isMember && <MessageReactionsPicker message={message} />}

              <MessageReactionsList message={message} />
            </Box>
            {chatChannelStore?.channel?.isMember && (
              <Tooltip title="Reply">
                <IconButton onClick={() => chatChannelStore?.setReplyToMessage(message)}>
                  <ReplyIcon color="primary" />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </Box>
      </Box>
    );
  },
);

// demo avatar, taken from stack overflow
const stringToColor = (string: string) => {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
};

const stringAvatar = (name: string) => {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(" ")[0][0] ?? ""}${name.split(" ")[1] ? name.split(" ")[1][0] : ""}`,
  };
};
