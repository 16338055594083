import { FunctionComponent } from "react";

export interface IIntegrationStatus {
  sync_expire?: number | null;
  sync_message?: string | null;
  sync_status?: string | null;
  sync_time?: number | null;
  current_time: number | null;
  [key: string]: any;
}

export const IntegrationStatus: FunctionComponent<IIntegrationStatus> = ({
  sync_expire,
  sync_message,
  sync_status,
  sync_time,
  current_time,
}) => {
  const age = sync_time ? (current_time || Math.round(+new Date() / 1000)) - sync_time : -1;
  const expired = age === -1 || !sync_expire ? false : age > sync_expire;

  const status = expired ? "expired" : sync_status || "unknown";
  const color =
    status === "success"
      ? "green"
      : status === "error"
        ? "red"
        : sync_status === "in_progress"
          ? "blue"
          : status === "expired"
            ? "orange"
            : "gray";

  return (
    <span
      style={{ marginTop: "5px" }}
      title={`Status ${status || "n/a"} ${age >= 0 ? `${age} seconds ago` : `never updated`}`}
    >
      <span
        style={{
          display: "block",
          borderRadius: "10px",
          width: "10px",
          height: "10px",
          backgroundColor: color,
          float: "left",
          margin: "3px 6px",
        }}
      ></span>
      {sync_message || "n/a"}
    </span>
  );
};
