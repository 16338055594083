import { Button, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import styles from "./Layout.module.scss";

import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
const ProfileMenu = () => {
  const history = useHistory();
  const route = useLocation();
  const getEnterTab = () => {
    if (route.pathname.includes("profile")) return "profile";
    else if (route.pathname.includes("rules")) return "rules";
  };
  const getTitle = () => {
    if (route.pathname.includes("profile")) return "Profile";
    else if (route.pathname.includes("rules")) return "Auto Deposit";
  };
  const [tabActive, setTabActive] = useState(getEnterTab);

  const handleTabChange = (e: React.MouseEvent<HTMLElement>) => {
    const target = e.target as HTMLButtonElement;
    setTabActive(target.id);
    history.push(`/account/${target.id}`);
  };
  return (
    <Grid container flexDirection={"column"}>
      <Grid paddingLeft={"15px"} item xs={2}>
        <Typography sx={{ whiteSpace: "nowrap" }} variant="h1">
          {getTitle()}
        </Typography>
      </Grid>
      <Grid marginTop={"50px"} item container xs={10}>
        <Grid width={"100%"}>
          <Button
            id={"profile"}
            onClick={handleTabChange}
            className={`${styles.navItem} ${tabActive === "profile" ? styles.active : ""}`}
            fullWidth
            color="info"
          >
            Profile
          </Button>
        </Grid>
        <Grid width={"100%"}>
          <Button
            id={"rules"}
            onClick={handleTabChange}
            className={`${styles.navItem} ${tabActive === "rules" ? styles.active : ""}`}
            fullWidth
            color="info"
          >
            Auto deposit
          </Button>
        </Grid>
        {/*  <Grid width={"100%"}>

          <Button
            id={"organizations"}
            onClick={handleTabChange}
            className={`${styles.navItem} ${tabActive === "organizations" ? styles.active : ""}`}
            fullWidth
            color="info"
          >
            Organizations
          </Button>
        </Grid>
        <Grid width={"100%"}>
          <Button
            id={"deposit-accounts"}
            onClick={handleTabChange}
            className={`${styles.navItem} ${tabActive === "deposit-accounts" ? styles.active : ""}`}
            fullWidth
            color="info"
          >
            Deposit Accounts
          </Button>
        </Grid>
        <Grid width={"100%"}>
          <Button
            id={"billing"}
            onClick={handleTabChange}
            className={`${styles.navItem} ${tabActive === "billing" ? styles.active : ""}`}
            fullWidth
            color="info"
          >
            Billing
          </Button>
        </Grid>
        */}
      </Grid>
    </Grid>
  );
};

export default ProfileMenu;
