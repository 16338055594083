import { Box } from "@mui/material";
import { ChevronLeftIcon, ChevronRightIcon } from "components/common/icons";
import { IActionlogActionlogIoMetaAdditionalContentBlockOutput } from "generated/graphql.actionlog";
import { FunctionComponent } from "react";
import styles from "./additionalContentTable.module.scss";

interface IAdditionalContentTableProps {
  additionalContent: IActionlogActionlogIoMetaAdditionalContentBlockOutput;
}

export const AdditionalContentTable: FunctionComponent<IAdditionalContentTableProps> = ({
  additionalContent: { message, columns, rows, template },
}) => (
  <Box sx={{ overflowX: "auto" }}>
    <p className={styles.title}>{template ? template : message}</p>

    <table className={styles.table}>
      <thead>
        <tr>
          {columns?.map(({ message }, index) => (
            <th key={`${message}-${index}`} className={styles.tableCell}>
              {!!message?.length ? message : "-"}
            </th>
          ))}
        </tr>
      </thead>

      <tbody>
        {rows?.map(({ cells }, index) => (
          <tr key={index}>
            {cells?.map(({ message }, index) => (
              <td key={index} className={styles.tableCell}>
                {getTableCellTemplateValue(message)}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  </Box>
);

const getTableCellTemplateValue = (value: string | undefined | null) => {
  if (value === "ICON_RIGHT") {
    return <ChevronRightIcon />;
  }

  if (value === "ICON_LEFT") {
    return <ChevronLeftIcon />;
  }

  return value;
};
