import { SvgIcon, SvgIconProps } from "@mui/material";

export const LogoMini = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 20 24" fill="none" {...props}>
      <path
        d="M16.9927 7.32888L17.0972 7.26024L16.9927 7.32888C17.1045 7.49891 17.333 7.5554 17.51 7.44608L19.6677 6.11329C19.8434 6.0048 19.9 5.77242 19.786 5.59628C17.6204 2.24961 13.8477 0.0697149 9.60683 0.212834C5.637 0.346805 2.21177 2.48523 0.204137 5.61665C0.0908886 5.79329 0.148555 6.02539 0.324648 6.13313L2.48806 7.45674C2.66549 7.56529 2.89375 7.50788 3.00477 7.33738C4.47088 5.08574 6.91853 3.59163 9.71767 3.49717C12.708 3.39625 15.4081 4.91733 16.9927 7.32888Z"
        fill="#16458B"
        stroke="#16458B"
        strokeWidth="0.25"
      />
      <path
        d="M16.9927 16.6711L17.0972 16.7398L16.9927 16.6711C17.1045 16.5011 17.333 16.4446 17.51 16.5539L19.6677 17.8867C19.8434 17.9952 19.9 18.2276 19.786 18.4037C17.6204 21.7504 13.8477 23.9303 9.60683 23.7872C5.637 23.6532 2.21177 21.5148 0.204137 18.3833C0.0908886 18.2067 0.148555 17.9746 0.324648 17.8669L2.48806 16.5433C2.66549 16.4347 2.89375 16.4921 3.00477 16.6626C4.47088 18.9143 6.91853 20.4084 9.71767 20.5028C12.708 20.6037 15.4081 19.0827 16.9927 16.6711Z"
        fill="#16458B"
        stroke="#16458B"
        strokeWidth="0.25"
      />
    </SvgIcon>
  );
};
