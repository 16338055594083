import { AccountCircleRounded, ArrowBackIos, Clear, PersonRemove } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import { graphqlClient } from "common/graphqlClient";
import {
  DelegationGroupRequirementTypeEnum,
  DelegationGroupTypeEnum,
  DelegationRoleEnum,
  OrganizationUserEnum,
  SaveDelegationGroupMutationVariables,
  getSdk,
} from "generated/sdk";
import { uniq } from "lodash";
import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";
import { useSetState } from "react-use";
import { useStore } from "storeContainer";
import { Spinner } from "../Spinner/Spinner";
import { Check, CloseIcon, Delete, InfoOutlinedIcon } from "../icons";
import { DelegationGroup, IApprovalEntityParams, PartialDelegationGroup } from "./types";

interface IEditDelegationGroupProps {
  closeModal: () => void;

  initialData: PartialDelegationGroup;
  entityParams: IApprovalEntityParams;
  typesAllowed?: DelegationGroupTypeEnum[];
  goBack?: () => void;
}

interface EditState {
  name: string;
  selectedDelegateUserIds: string[];
  type: DelegationGroupTypeEnum;
  requirementType: DelegationGroupRequirementTypeEnum;
  saveForVendor: boolean;
  selectedLabelingCategoryId?: string;
}

const sdk = getSdk(graphqlClient);

export const EditDelegationGroup: React.FunctionComponent<IEditDelegationGroupProps> = observer(
  ({
    closeModal,
    initialData,
    entityParams,
    typesAllowed = [DelegationGroupTypeEnum.ReviewRetainApproval, DelegationGroupTypeEnum.Approval],
    goBack,
  }) => {
    const [state, setState] = useSetState<EditState>(getInitialState(initialData, typesAllowed));

    const sessionStore = useStore("SessionStore");
    const approvalStore = useStore("ApprovalStore");
    const vBillStore = useStore("VBillStore");
    const organizationUsersStore = useStore("OrganizationUsersStore");
    const newLabelingCategoryRef = useRef<HTMLInputElement>();
    const [newLabelingCategoryName, setNewLabelingCategoryName] = useState<string>();
    const [addNewLabelingCategoryVisible, setAddNewLabelingCategoryVisible] = useState<boolean>(false);
    const [hasSaveError, setHasSaveError] = useState<boolean>(false);
    const [isSaveLoading, setIsSaveLoading] = useState<boolean>(false);

    const { labelingCategories } = approvalStore;

    useEffect(() => {
      setState(getInitialState(initialData, typesAllowed));
    }, [initialData]);

    useEffect(() => {
      organizationUsersStore.loadOrganizationUsers();
      approvalStore.loadLabelingCategories({ organization_id: initialData.organization_id });
    }, []);

    const addDelegatedUserId = (e: SelectChangeEvent<string>) => {
      const orgUserId = e.target.value;

      if (orgUserId) {
        setState({ selectedDelegateUserIds: uniq([...state.selectedDelegateUserIds, orgUserId]) });
      }
    };

    const removeDelegateUserId = (orgUserId: string) => {
      setState({
        selectedDelegateUserIds: state.selectedDelegateUserIds.filter(
          (selectedDelegateUserId) => selectedDelegateUserId !== orgUserId,
        ),
      });
    };

    const handleDelegationRequirementChange = (
      e: React.MouseEvent<HTMLElement>,
      delegationRequirementType: string | null,
    ) => {
      if (delegationRequirementType !== null) {
        setState({ requirementType: delegationRequirementType as DelegationGroupRequirementTypeEnum });
      }
    };

    const handleTypeChange = (e: React.MouseEvent<HTMLElement>, delegationType: string | null) => {
      if (delegationType !== null) {
        setState({ type: delegationType as DelegationGroupTypeEnum });
      }
    };

    const handleNameChange = (name: string) => setState({ name });

    const handleSaveNewLabelingCategory = () => {
      console.log(
        { newLabelingCategoryRef },
        { current: newLabelingCategoryRef.current },
        { value: newLabelingCategoryRef.current?.value },
      );
      if (newLabelingCategoryRef.current) {
        setNewLabelingCategoryName(newLabelingCategoryRef.current.value);
        setState({
          selectedLabelingCategoryId: "__NEW__",
        });

        setAddNewLabelingCategoryVisible(false);
      }
    };

    const canSaveDelegationGroup = !!state.selectedDelegateUserIds.length;

    const labelingCategoriesInclNew = [
      ...(newLabelingCategoryName
        ? [
            {
              id: "__NEW__",
              name: newLabelingCategoryName,
            },
          ]
        : []),
      ...(labelingCategories.data ?? []),
    ];

    const handleSubmit = async () => {
      if (!canSaveDelegationGroup) {
        return;
      }

      setHasSaveError(false);
      setIsSaveLoading(true);

      let approvals_required: number, reviews_required: number, delegations: any[];
      if (state.type === DelegationGroupTypeEnum.Approval) {
        delegations = state.selectedDelegateUserIds.map((selectedDelegateUserId) => ({
          delegate_user_id: selectedDelegateUserId,
          role: DelegationRoleEnum.Approver,
        }));

        if (state.requirementType === DelegationGroupRequirementTypeEnum.Everyone) {
          approvals_required = state.selectedDelegateUserIds.length;
          reviews_required = 0;
        } else {
          approvals_required = 1;
          reviews_required = 0;
        }
      } else if (state.type === DelegationGroupTypeEnum.ReviewRetainApproval) {
        delegations = state.selectedDelegateUserIds.map((selectedDelegateUserId) => ({
          delegate_user_id: selectedDelegateUserId,
          role: DelegationRoleEnum.Reviewer,
        }));

        if (state.requirementType === DelegationGroupRequirementTypeEnum.Everyone) {
          approvals_required = 1;
          reviews_required = state.selectedDelegateUserIds.length;
        } else {
          approvals_required = 1;
          reviews_required = 1;
        }
      } else {
        delegations = state.selectedDelegateUserIds.map((selectedDelegateUserId) => ({
          delegate_user_id: selectedDelegateUserId,
          role: DelegationRoleEnum.Reviewer,
        }));

        if (state.requirementType === DelegationGroupRequirementTypeEnum.Everyone) {
          approvals_required = 0;
          reviews_required = state.selectedDelegateUserIds.length;
        } else {
          approvals_required = 0;
          reviews_required = 1;
        }
      }

      try {
        let labelingCategoryId = state.selectedLabelingCategoryId;
        if (state.selectedLabelingCategoryId === "__NEW__" && newLabelingCategoryName) {
          const labelingCategoryResponse = await sdk.SaveLabelingCategory({
            organization_id: initialData.organization_id,
            company_id: initialData.company_id,
            name: newLabelingCategoryName,
          });

          labelingCategoryId = labelingCategoryResponse.SaveLabelingCategory.id;
        }

        const params: SaveDelegationGroupMutationVariables = {
          scope: initialData.scope,
          type: state.type,
          name: state.name,
          requirement_type: state.requirementType,
          delegation_group_id: initialData.id,
          organization_id: entityParams.organizationId,
          company_id: entityParams.companyId,
          vendor_id: entityParams.vendorId,
          bill_id: state.saveForVendor ? undefined : entityParams.billId,
          check_id: state.saveForVendor ? undefined : entityParams.checkId,
          is_root: false,
          approvals_required,
          reviews_required,
          delegations,
          entity_context: {
            bill_id: entityParams.billId,
            check_id: entityParams.checkId,
          },
          labeling_category_id: labelingCategoryId,
        };

        const response = await approvalStore.saveDelegationGroup(params);

        if (!response.SaveDelegationGroup) {
          setHasSaveError(true);
        } else {
          if (vBillStore.invoice.reload) {
            await vBillStore.invoice.reload({ markAsLoading: false });
          }

          closeModal();
        }
      } catch (e) {
        setHasSaveError(true);
      } finally {
        setIsSaveLoading(false);
      }
    };

    const deleteDelegationGroup = async () => {
      await approvalStore.deleteDelegationGroup({
        scope: initialData.scope,
        organization_id: initialData.organization_id,
        delegation_group_id: initialData.id,
      });

      if (vBillStore.invoice.reload) {
        await vBillStore.invoice.reload({ markAsLoading: false });
      }

      closeModal();
    };

    const showDelete = !!initialData.id;

    return (
      <Box sx={{ padding: "15px", width: "350px" }}>
        {labelingCategories.isLoading || organizationUsersStore.organizationUsersList.isLoading ? (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Spinner />
          </Box>
        ) : (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: goBack ? "space-between" : "right",
                marginBottom: "20px",
              }}
            >
              {goBack && <ArrowBackIos onClick={goBack} />}
              <CloseIcon onClick={closeModal} />
            </Box>
            <Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: "10px", marginBottom: "20px" }}>
                <ToggleButtonGroup exclusive fullWidth value={state.type} onChange={handleTypeChange} size="small">
                  {typesAllowed.includes(DelegationGroupTypeEnum.ReviewRetainApproval) && (
                    <ToggleButton value={DelegationGroupTypeEnum.ReviewRetainApproval}>Assign review</ToggleButton>
                  )}

                  {typesAllowed.includes(DelegationGroupTypeEnum.Approval) && (
                    <ToggleButton value={DelegationGroupTypeEnum.Approval}>Assign approval</ToggleButton>
                  )}

                  {/* FIXME: wording? */}
                  {typesAllowed.includes(DelegationGroupTypeEnum.Review) && (
                    <ToggleButton value={DelegationGroupTypeEnum.Review}>Assign review</ToggleButton>
                  )}
                </ToggleButtonGroup>
                <Tooltip
                  arrow
                  placement="top"
                  title={
                    <Box>
                      <Typography color="white" fontSize={11} marginBottom={1}>
                        <strong>Assign Review:</strong> will still require your approval.
                      </Typography>
                      <Typography color="white" fontSize={11}>
                        <strong>Assing Approval:</strong> will not require your additional approval.
                      </Typography>
                    </Box>
                  }
                >
                  <InfoOutlinedIcon />
                </Tooltip>
              </Box>

              <Box>
                <Box sx={{ marginBottom: "10px", fontSize: "12px" }}>Rule name</Box>

                <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                  <TextField
                    placeholder="Add a new category"
                    fullWidth
                    sx={{ marginLeft: 0 }}
                    value={state.name}
                    onChange={(e) => handleNameChange(e.target.value)}
                  />
                </Box>
              </Box>

              <Box>
                <Box sx={{ marginBottom: "10px", marginTop: "10px", fontSize: "12px" }}>Choose one or more users</Box>
                <FormControl fullWidth>
                  <InputLabel>Select...</InputLabel>

                  <Select onChange={addDelegatedUserId} value="">
                    {organizationUsersStore.organizationUsersList._dataObsevable
                      ?.filter(
                        (orgUser) =>
                          orgUser.state === OrganizationUserEnum.Active &&
                          orgUser.id !== sessionStore.selectedOrganizationUserId,
                      )
                      .map((orgUser) => <MenuItem value={orgUser.id}>{orgUser.account?.name}</MenuItem>)}
                  </Select>
                </FormControl>
              </Box>

              <Box sx={{ display: "flex", alignItems: "center", gap: "10px", marginTop: "10px" }}>
                <ToggleButtonGroup
                  exclusive
                  value={state.requirementType}
                  onChange={handleDelegationRequirementChange}
                  size="small"
                >
                  <ToggleButton value={DelegationGroupRequirementTypeEnum.Everyone}>AND</ToggleButton>
                  <ToggleButton value={DelegationGroupRequirementTypeEnum.Anyone}>OR</ToggleButton>
                </ToggleButtonGroup>
                <Tooltip
                  arrow
                  placement="top"
                  title={
                    <Box>
                      <Typography color="white" fontSize={11} marginBottom={1}>
                        <strong>And:</strong> User response required, will receive a notification when it's their turn
                        to review/approve.
                      </Typography>
                      <Typography color="white" fontSize={11}>
                        <strong>Or:</strong> Single response required from any selected users. Simulatenous
                        notifications for all.
                      </Typography>
                    </Box>
                  }
                >
                  <InfoOutlinedIcon />
                </Tooltip>
              </Box>

              <Box>
                <List>
                  {state.selectedDelegateUserIds.map((orgUserId) => (
                    <ListItem
                      key={orgUserId}
                      secondaryAction={
                        <IconButton aria-label="remove" onClick={() => removeDelegateUserId(orgUserId)}>
                          <PersonRemove />
                        </IconButton>
                      }
                      sx={{ gap: "3px", alignItems: "center" }}
                      dense
                    >
                      <AccountCircleRounded color="info" />
                      <ListItemText>
                        {organizationUsersStore.findOrganizationUserById(orgUserId)?.account?.name ??
                          `Unknown user (${orgUserId})`}
                      </ListItemText>
                    </ListItem>
                  ))}
                </List>
              </Box>

              <Box>
                <Box sx={{ marginBottom: "10px", fontSize: "12px" }}>Assign a category</Box>

                {!addNewLabelingCategoryVisible && (
                  <>
                    <FormControl fullWidth>
                      <InputLabel>Select...</InputLabel>
                      <Select
                        onChange={(e) => setState({ selectedLabelingCategoryId: e.target.value })}
                        value={state.selectedLabelingCategoryId}
                        fullWidth
                      >
                        {labelingCategoriesInclNew.map((labelingCategory) => (
                          <MenuItem key={labelingCategory.id} value={labelingCategory.id}>
                            {labelingCategory.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <Button onClick={() => setAddNewLabelingCategoryVisible(true)}>Add new</Button>
                  </>
                )}

                {addNewLabelingCategoryVisible && (
                  <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                    <TextField
                      inputRef={newLabelingCategoryRef}
                      placeholder="Add a new category"
                      fullWidth
                      sx={{ marginLeft: 0 }}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          handleSaveNewLabelingCategory();
                        }
                      }}
                    />
                    <Button
                      onClick={(e) => {
                        setAddNewLabelingCategoryVisible(false);
                        setNewLabelingCategoryName(undefined);
                      }}
                      variant="outlined"
                      color="error"
                    >
                      <Clear />
                    </Button>
                    <Button
                      onClick={(e) => {
                        if (newLabelingCategoryRef.current) {
                          handleSaveNewLabelingCategory();
                        }
                      }}
                      variant="contained"
                      color="success"
                    >
                      Add
                    </Button>
                  </Box>
                )}
              </Box>

              <Box sx={{ marginTop: "20px" }}>
                <FormGroup>
                  <FormControlLabel
                    sx={{ marginLeft: 0 }}
                    control={
                      <Checkbox
                        checked={state.saveForVendor}
                        onChange={(e) => setState({ saveForVendor: e.target.checked })}
                        disabled={!entityParams.vendorId}
                      />
                    }
                    label="Remember my selection for this vendor"
                    componentsProps={{ typography: { fontSize: "12px", marginLeft: "10px" } }}
                  />
                </FormGroup>
              </Box>
            </Box>
            {hasSaveError && (
              <Alert severity="error" sx={{ width: "100%", mt: "10px" }}>
                There was an error saving your request. Please try again later.
              </Alert>
            )}
            <Box
              sx={{
                display: "flex",
                justifyContent: showDelete ? "space-between" : "right",
                alignItems: "center",
                marginTop: "30px",
              }}
            >
              {showDelete && (
                <Box>
                  <IconButton onClick={(e) => deleteDelegationGroup()}>
                    <Delete />
                  </IconButton>
                </Box>
              )}
              <Box sx={{ display: "flex", justifyContent: "right" }}>
                <Button size="small" onClick={closeModal}>
                  Cancel
                </Button>
                <LoadingButton
                  loading={isSaveLoading}
                  disabled={!canSaveDelegationGroup}
                  variant="contained"
                  size="small"
                  startIcon={<Check />}
                  onClick={() => handleSubmit()}
                >
                  OK
                </LoadingButton>
              </Box>
            </Box>
          </>
        )}
      </Box>
    );
  },
);

const getInitialState = (
  initialData?: Partial<DelegationGroup> &
    Pick<DelegationGroup, "scope" | "organization_id" | "company_id" | "vendor_id">,
  typesAllowed?: DelegationGroupTypeEnum[],
) => {
  let defaultType: DelegationGroupTypeEnum = DelegationGroupTypeEnum.ReviewRetainApproval;
  if (typesAllowed && !typesAllowed.includes(DelegationGroupTypeEnum.ReviewRetainApproval)) {
    defaultType = typesAllowed[0];
  }

  const state: EditState = {
    name: "",
    selectedDelegateUserIds: [],
    type: defaultType,
    requirementType: DelegationGroupRequirementTypeEnum.Everyone,
    saveForVendor: false,
  };

  if (initialData?.name) {
    state.name = initialData.name;
  }

  if (initialData?.delegations) {
    state.selectedDelegateUserIds = initialData.delegations?.map((delegation) => delegation.delegate.id);
  }

  if (initialData?.type) {
    state.type = initialData.type;
  }

  if (initialData?.requirement_type) {
    state.requirementType = initialData.requirement_type;
  }

  state.saveForVendor = Boolean(initialData?.vendor_id && !initialData.bill_id && !initialData.check_id);

  if (initialData?.labeling_category) {
    state.selectedLabelingCategoryId = initialData.labeling_category.id;
  }

  return state;
};
