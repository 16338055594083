import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
// import ChecksStatusDialog from "../Dialogs/ChecksStatusDialog";
import { useCheckFilters } from "common/hooks/useCheckFilters";
import { TreeSelect } from "components/common/TreeSelect/TreeSelect";
import { CheckFiltersType } from "generated/sdk";
import { useParams } from "react-router-dom";
import { VCheckChecksAllQuickFiltersEnum } from "../types";
import { ActiveFilters } from "./ActiveFilters";
import { ActivityDropdownSelectFilter } from "./ActivityDropdownSelectFilter";
import { AmountFiltersPopup } from "./AmountFilters";
import { BankAccountsFilter } from "./BankAccountsFilter";
import { DateContextFilters } from "./DateContextFilters";
import { PaymentsActionRequiredFilter } from "./PaymentsActionRequiredFilter";
import { ProcessingTypeFilters } from "./ProcessingTypeFilters";
import SearchInput from "./SearchInput";
import StateOrActionOrStatusFilter from "./StateOrActionOrStatusFilter";
import { IFiltersProps } from "./types";

export const Filters = ({ exportCSV, isLoadingCompanies, unvalidatedLedger = false }: IFiltersProps) => {
  const { filters, setFilters } = useCheckFilters<CheckFiltersType>();

  const { selectedQuickFilter } = useParams<{
    selectedQuickFilter: VCheckChecksAllQuickFiltersEnum;
    organizationId: string;
    sharedToken?: string;
  }>();

  const isAllQuickFilterSelected = selectedQuickFilter === "all";

  return (
    <>
      <Grid container justifyContent={"space-between"} alignItems={"center"}></Grid>
      <Grid container margin="10px 0 10px 0" alignItems={"flex-start"} justifyContent={"space-between"}>
        <Box display="flex" justifyContent="space-between" width="100%" flexWrap="wrap">
          <Box display="flex" justifyContent="flex-start" columnGap="10px" rowGap="5px" flexWrap="wrap">
            <SearchInput />
            <DateContextFilters />
            <AmountFiltersPopup filtersPayload={filters} />
            {isAllQuickFilterSelected && !unvalidatedLedger && (
              <TreeSelect
                isLoading={isLoadingCompanies}
                insideDrawer={false}
                filters={filters}
                setFilters={setFilters}
              />
            )}
            <BankAccountsFilter />
            <StateOrActionOrStatusFilter label={"state"} />
            <StateOrActionOrStatusFilter label={"required_action"} />
            <StateOrActionOrStatusFilter label={"status"} />
            <PaymentsActionRequiredFilter />
            <ProcessingTypeFilters />
            <ActivityDropdownSelectFilter />
          </Box>
          <Box display="flex">
            <Box display="flex" alignItems="flex-end">
              {exportCSV()}
            </Box>
          </Box>
        </Box>
        <ActiveFilters />
      </Grid>
    </>
  );
};
