import { Box } from "@mui/material";
import { VBillAmountFilter } from "components/pages/common/VBill/FilterComponents/VBillAmountFilter/VBillAmountFilter";
import { VBillCompanyListFilter } from "components/pages/common/VBill/FilterComponents/VBillCompanyListFilter/VBillCompanyListFilter";
import { VBillDateFilter } from "components/pages/common/VBill/FilterComponents/VBillDateFilter/VBillDateFilter";
import { VBillRelativeDueDateFilter } from "components/pages/common/VBill/FilterComponents/VBillRelativeDueDateFilter/VBillRelativeDueDateFilter";
import { VBillSearchFilter } from "components/pages/common/VBill/FilterComponents/VBillSearchFilter/VBillSearchFilter";
import { pick } from "lodash";
import { observer } from "mobx-react";
import { useStore } from "storeContainer";

export const VBillsSelectedFilters = observer(function VBillsSelectedFilters() {
  const vBillBatchDetailsStore = useStore("VBillBatchDetailsStore");
  const { batchFilters } = vBillBatchDetailsStore;

  return (
    <Box sx={{ display: "flex", flexWrap: "wrap", gap: "10px", alignItems: "center", marginBottom: "5px" }}>
      <VBillSearchFilter
        smallHeight
        term={batchFilters.vbill_sel_fil?.search}
        onTermChange={(term) => {
          vBillBatchDetailsStore.setBatchFilters((prev) => ({
            vbill_sel_fil: { search: term?.length ? term : undefined },
            vbill_sel_pag: { page: 1, per_page: prev.vbill_sel_pag.per_page },
          }));
        }}
      />
      <VBillCompanyListFilter
        smallHeight
        actionsBtnSmallHeight
        companies={batchFilters.vbill_sel_fil?.companyIds ?? []}
        onCompaniesChange={async (values) =>
          await vBillBatchDetailsStore.setBatchFilters((prev) => ({
            vbill_sel_fil: { ...prev.vbill_sel_fil, companyIds: values },
          }))
        }
      />
      <VBillAmountFilter
        amount={{
          amount_from: batchFilters.vbill_sel_fil?.amount_from,
          amount_to: batchFilters.vbill_sel_fil?.amount_to,
        }}
        onAmountChange={({ amount_from, amount_to }) =>
          vBillBatchDetailsStore.setBatchFilters((prev) => ({
            vbill_sel_fil: { ...prev.vbill_sel_fil, amount_from, amount_to },
          }))
        }
        smallHeight
        actionsBtnSmallHeight
      />
      <VBillDateFilter
        date={{
          date: batchFilters.vbill_sel_fil?.date,
          date_to: batchFilters.vbill_sel_fil?.date_to,
        }}
        onDateChange={({ date, date_to }) =>
          vBillBatchDetailsStore.setBatchFilters((prev) => ({
            vbill_sel_fil: { ...prev.vbill_sel_fil, date, date_to },
          }))
        }
        smallHeight
        actionsBtnSmallHeight
      />
      {/* <VBillDueDateFilter
        smallHeight
        actionsBtnSmallHeight
        dueDate={{
          date_due_from: batchFilters.vbill_sel_fil?.date_due_from,
          date_due_to: batchFilters.vbill_sel_fil?.date_due_to,
        }}
        onDueDateChange={({ date_due_from, date_due_to }) =>
          vBillBatchDetailsStore.setBatchFilters((prev) => ({
            vbill_sel_fil: { ...prev.vbill_sel_fil, date_due_from, date_due_to },
          }))
        }
      /> */}

      <VBillRelativeDueDateFilter
        btnText="Due date range"
        btnSmallHeight
        onDatesSubmit={async (dates) =>
          vBillBatchDetailsStore.setBatchFilters((prev) => ({
            vbill_sel_fil: { ...prev.vbill_sel_fil, ...dates },
          }))
        }
        dates={pick(batchFilters.vbill_sel_fil, ["relativeReferenceDate", "relativeDateDueFrom", "relativeDateDueTo"])}
        dialogTitle="Bills relative to "
      />
    </Box>
  );
});
