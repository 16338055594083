import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import {
  checkActionRequiredDropdownCherryResponseOptions,
  checkActionRequiredDropdownCherryVendorOptions,
  checkActionRequiredDropdownCustomerResponseOptions,
} from "components/Filter/types";
import { CheckContextEnumType, CheckQuickFiltersEnumType, LedgerCheck } from "generated/sdk";
import { capitalize } from "lodash";
import { ComponentProps, useState } from "react";
import { COLORS } from "themes/default";
import styles from "./VChecks.module.scss";

interface IPopoverUtilProps extends ComponentProps<any> {
  check: any;
  handleAction?: Function;
}

const PopoverUtil = ({ check, handleAction }: IPopoverUtilProps) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverClick = (e: any) => {
    setAnchorEl(e.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <div className={styles.moreContainer}>
      <MoreHorizIcon className={styles.settingsIcon} fontSize={"large"} onClick={handlePopoverClick} />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
      >
        <div className={styles.popover}>
          {check.options ? (
            check.options?.map((option: any, index: number) => (
              <div
                key={index}
                onClick={(_e: any) => {
                  if (handleAction) {
                    handleAction(check, option, true);
                  }
                  handlePopoverClose();
                }}
              >
                {option.label}
              </div>
            ))
          ) : (
            <div className={styles.noAvailableOptions}>No available options</div>
          )}
        </div>
      </Popover>
    </div>
  );
};

export const formatDateToDesign = (date: string) => {
  if (date) {
    const dateArr = date.split("-");
    return `${dateArr[1]}/${dateArr[2].substring(0, 2)}/${dateArr[0].substring(2, 4)}`;
  }
  return null;
};

export const getStyles = (status: string) => {
  let style = styles.SIGNED;
  switch (status) {
    case "Signed":
      style = styles.SIGNED;
      break;
    case "Completed":
      style = styles.COMPLETED;
      break;
    case "Processing":
      style = styles.PROCESSING;
      break;
    case "Blocked":
      style = styles.BLOCKED;
      break;
    case "Deposited":
      style = styles.DEPOSITED;
      break;
    case "Sent to Recipient":
      style = styles.SENT_TO_RECIPIENT;
      break;
    case "Pending Signature":
      style = styles.PENDING_SIGNATURE;
      break;
    case "Cancelled":
      style = styles.CANCELLED;
      break;
    case "Deleted":
      style = styles.DELETED;
      break;
    case "Undeposited":
      style = styles.undeposited;
      break;
    default:
      style = styles.COMPLETED;
  }
  return style;
};

export const getRequiredActionMsgTextColor = (key?: string | null) => {
  if (!key) {
    return;
  }

  const formattedKey = key.replaceAll("_", "-");

  if (checkActionRequiredDropdownCherryResponseOptions.includes(formattedKey)) {
    return COLORS.darkRed;
  }

  if (checkActionRequiredDropdownCustomerResponseOptions.includes(formattedKey)) {
    return COLORS.newSecondaryColor;
  }

  if (checkActionRequiredDropdownCherryVendorOptions.includes(formattedKey)) {
    return COLORS.success;
  }
};

export const formatWords = (str?: string): string => {
  // Replace underscores with spaces
  const words = str?.replace(/[-_]/g, " ") ?? "";

  // Split the string into an array of words
  const parts = words.split(/\W+/);
  // Join the words with spaces and return the result
  return parts.join(" ");
};

export const shouldShowDifferentStatuses = (
  field1: string | null | undefined,
  field2: string | null | undefined,
): boolean => {
  const formattedField1 = formatWords(field1 ?? "").toLowerCase();
  const formattedField2 = formatWords(field2 ?? "").toLowerCase();
  return formattedField1 !== formattedField2;
};

export const getAdditionalFields = (check?: LedgerCheck | null, matchPayments?: boolean) => {
  if (!check) return null;

  return (
    <>
      {shouldShowDifferentStatuses(check.status?.status, check.status?.state) && (
        <Typography fontSize="13px" fontWeight="400">
          {capitalize(formatWords(check.status?.state ?? "")?.toLowerCase())}
        </Typography>
      )}
      {check?.required_actions?.map((action, index) => {
        return (
          <Typography
            key={`${action?.description}-${index}`}
            className={!matchPayments ? getStyles(check.status?.status!) : ""}
            fontSize="12px"
            fontWeight="400"
            sx={{
              color: matchPayments
                ? getRequiredActionMsgTextColor(action?.key)
                : action?.is_blocker
                  ? COLORS.darkRed
                  : undefined,
            }}
          >
            {capitalize(formatWords(action?.description as string).toLowerCase())}
          </Typography>
        );
      })}
    </>
  );
};

const formatDateMMDDYY = (date: Date) => {
  if (!date) return "";
  const dt = new Date(date);
  let month = "" + (dt.getMonth() + 1),
    day = "" + dt.getDate(),
    year = dt.getFullYear().toString().substring(2);

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [month, day, year].join("/");
};

const formatDateToDateTime = (date: any) => {
  if (!date) return "";
  const dt = new Date(date);
  var hours = dt.getHours();
  var minutes: string | number = dt.getMinutes();
  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return dt.getMonth() + 1 + "/" + dt.getDate() + "/" + dt.getFullYear().toString().substring(-2) + ", " + strTime;
};

const truncate = (str: string, n: any) => {
  return str.length > n ? str.substr(0, n - 1) + "&hellip;" : str;
};

enum StatusCategory {
  PROCESSING = "Processing",
  PENDING_SIGNATURE = "Pending Signature",
  SENT_TO_RECIPIENT = "Sent to recipient",
  DEPOSITED = "Deposited",
  POST_DATED = "Post Dated",
  VOIDED = "Voided",
  CANCELLED = "Cancelled",
  COMPLETED = "Completed",
}

const statusList = [
  {
    label: "All",
    value: Object.values(CheckQuickFiltersEnumType),
    role: ["deposits", "withdrawals"],
    main: false,
    displayForUnvalidated: true,
    displayForReceivingType: true,
  },
  {
    label: "Pending Signature",
    value: [CheckQuickFiltersEnumType.PendingSignature],
    role: ["withdrawals"],
    main: false,
    displayForUnvalidated: false,
    displayForReceivingType: false,
  },
  // {
  //   label: "In Queue",
  //   value: "in-queue",
  //   role: ["withdrawals"],
  //   main: false,
  //   displayForUnvalidated: false,
  //   displayForReceivingType: false,
  // },
  // {
  //   label: "Sent to recipient",
  //   value: "SIGNED",
  //   role: ["withdrawals"],
  //   main: false,
  //   displayForUnvalidated: false,
  //   displayForReceivingType: false,
  // },
  {
    label: " Ready to Deposit",
    value: [CheckQuickFiltersEnumType.ReadyToDeposit],
    role: ["withdrawals"],
    main: false,
    displayForUnvalidated: false,
  },
  {
    label: "Processing",
    value: [CheckQuickFiltersEnumType.Processing],
    role: ["deposits", "withdrawals"],
    main: false,
    displayForUnvalidated: true,
    displayForReceivingType: true,
  },
  {
    label: "Completed",
    value: [CheckQuickFiltersEnumType.Completed],
    role: ["deposits", "withdrawals"],
    main: false,
    displayForUnvalidated: true,
    displayForReceivingType: true,
  },
  {
    label: "Unresolved Chats",
    value: [CheckQuickFiltersEnumType.UnresolvedChats],
    role: ["deposits", "withdrawals"],
    main: false,
    displayForUnvalidated: true,
    displayForReceivingType: true,
  },
  {
    label: "Actions Required",
    value: [CheckQuickFiltersEnumType.ActionRequired],
    role: ["deposits", "withdrawals"],
    main: false,
    displayForUnvalidated: true,
    displayForReceivingType: true,
  },
  // {
  //   label: "Post dated",
  //   value: "post-dated",
  //   role: ["deposits", "withdrawals"],
  //   main: false,
  //   displayForUnvalidated: false,
  //   displayForReceivingType: false,
  // },
  // { label: "Voided", value: "CANCELLED", role: ["withdrawals"] },

  //new values below
  // {
  //   label: "Digital vChecks",
  //   value: "0-ach",
  //   role: ["withdrawals"],
  //   main: true,
  //   displayForUnvalidated: false,
  // },
  // {
  //   label: " Processing",
  //   value: "6-ach",
  //   role: ["withdrawals"],
  //   main: false,
  //   displayForUnvalidated: false,
  // },
  // { label: " Deposited", value: "1-ach", role: ["withdrawals"], main: false, displayForUnvalidated: false },
  // { label: "Printed Checks", value: "0-print", role: ["withdrawals"], main: true, displayForUnvalidated: false },
  // { label: " Processing", value: "6-print", role: ["withdrawals"], main: false, displayForUnvalidated: false },
  // { label: " Deposited", value: "1-print", role: ["withdrawals"], main: false, displayForUnvalidated: false },
  // { label: "Card Payments", value: "0-card", role: ["withdrawals"], main: true, displayForUnvalidated: false },
  // { label: " Payment Accepted", value: "6-card", role: ["withdrawals"], main: false, displayForUnvalidated: false },
  // { label: " Payment Completed", value: "1-card", role: ["withdrawals"], main: false, displayForUnvalidated: false },
];

const typesList = [
  { label: "Sent & Received", value: undefined },
  { label: "Sent", value: CheckContextEnumType.Withdrawals },
  { label: "Received", value: CheckContextEnumType.Deposits },
];

const sortList = [
  { label: "Newest", value: "desc" },
  { label: "Oldest", value: "asc" },
];

const headerCells = [
  {
    id: "number",
    sortable: true,
    label: "Check #",
  },
  {
    id: "date",
    sortable: true,
    label: "Date",
  },
  {
    id: "from",
    sortable: false,
    label: "From",
  },
  {
    id: "to",
    sortable: false,
    label: "To",
  },
  {
    id: "amount",
    sortable: true,
    label: "Amount",
  },
  {
    id: "status",
    sortable: false,
    label: "Status",
  },
  {
    id: "action",
    sortable: false,
    label: "Action",
  },
  {
    id: "more",
    sortable: false,
    label: "Options",
  },
];

export {
  PopoverUtil,
  StatusCategory,
  formatDateMMDDYY,
  formatDateToDateTime,
  headerCells,
  sortList,
  statusList,
  truncate,
  typesList,
};
