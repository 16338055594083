import { TableCell, TableHead, TableRow } from "@mui/material";
import { IVBillIIntegrationIntegrationSettingsVBillAdditionalMappingsEntryShowIn } from "generated/sdk.vbill";
import { partition } from "lodash";
import { observer } from "mobx-react";
import { useMemo } from "react";
import { useStore } from "storeContainer";

const defaultTableCellSx = {
  padding: "10px 5px",
  lineHeight: "15px",
};

export const VBillLineItemsHead = observer(() => {
  const vBillStore = useStore("VBillStore");
  const { invoice, settings } = vBillStore;
  const settingsData = useMemo(() => settings.data, [settings.data]);
  const invoiceData = useMemo(() => invoice.data, [invoice.data]);

  // TODO: no neeed of partition
  const [glMapping, addMappings] = useMemo(() => {
    const lineAdditionalMappings = (settingsData?.vBill.additionalMappings.line ?? []).filter(
      (additionalMapping) =>
        additionalMapping.showIn !== IVBillIIntegrationIntegrationSettingsVBillAdditionalMappingsEntryShowIn.Payment &&
        additionalMapping.showIn !== IVBillIIntegrationIntegrationSettingsVBillAdditionalMappingsEntryShowIn.None,
    );

    return invoiceData?.hasLineItems ? partition(lineAdditionalMappings, (map) => map.isGlAccount) : [[], []];
  }, [settingsData?.vBill.additionalMappings.line, invoiceData?.hasLineItems]);

  return (
    <TableHead>
      <TableRow sx={{ cursor: "default" }}>
        <TableCell sx={defaultTableCellSx} />

        <TableCell sx={defaultTableCellSx}>Description</TableCell>
        <TableCell sx={defaultTableCellSx}>{glMapping.length ? "GL Account" : ""}</TableCell>

        {addMappings.length
          ? addMappings.map((additionalMapping) => (
              <TableCell key={additionalMapping.key} sx={defaultTableCellSx}>
                {additionalMapping.label}
              </TableCell>
            ))
          : null}

        <TableCell sx={defaultTableCellSx}>Qty</TableCell>
        <TableCell sx={defaultTableCellSx}>Rate</TableCell>
        <TableCell sx={{ ...defaultTableCellSx, textAlign: "right" }}>Total</TableCell>
      </TableRow>
    </TableHead>
  );
});
