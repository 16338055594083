import Add from "@mui/icons-material/Add";
import { Box, Button, Grid, IconButton, TextField, Typography } from "@mui/material";
import React from "react";
import { COLORS } from "themes/default";
import ClearIcon from "@mui/icons-material/Clear";
import DoneIcon from "@mui/icons-material/Done";
const CriteriaComponent: React.FC<{
  title: string;
  description: string;
  id: string;
  criteria?: { name: string | null; saved: boolean }[];
  handleAddFilter: React.MouseEventHandler<HTMLButtonElement>;
  handleFilterInputChange?: any;
  handleFilterInputSave?: any;
  handleRemoveFilter?: any;
}> = ({
  description,
  title,
  id,
  criteria,
  handleAddFilter = () => {},
  handleFilterInputChange,
  handleFilterInputSave,
  handleRemoveFilter,
}) => {
  return (
    <Grid marginBottom={"30px"} flexDirection={"column"} container>
      <Grid item>
        <Typography color={"GrayText"} variant="h4">
          {title}
        </Typography>
      </Grid>
      <Grid marginTop={"5px"} item>
        <Typography color={"darkgray"} variant="h5">
          {description}
        </Typography>
      </Grid>
      <Grid marginTop={"10px"} container item>
        {criteria?.length ? (
          criteria.map((item, index) => {
            return (
              <Box display={"flex"} justifyContent="center" alignItems={"center"}>
                {item.saved ? (
                  <Box
                    display={"flex"}
                    justifyContent="center"
                    alignItems={"center"}
                    bgcolor={COLORS.newBg}
                    padding={1}
                    paddingY={0.4}
                  >
                    <Typography fontWeight={500} color={COLORS.newPrimaryColor}>
                      {item.name}
                    </Typography>
                    <IconButton id={id} onClick={(e) => handleRemoveFilter(e, index)}>
                      <ClearIcon />
                    </IconButton>
                  </Box>
                ) : (
                  <Box
                    display={"flex"}
                    justifyContent="center"
                    alignItems={"center"}
                    bgcolor={COLORS.newBg}
                    padding={1}
                    paddingY={0.4}
                  >
                    <TextField
                      sx={{ background: "transparent" }}
                      InputProps={{ style: { background: "transparent" } }}
                      variant="standard"
                      id={id}
                      onChange={(e) => handleFilterInputChange(e, index)}
                      onBlur={(e) => handleFilterInputSave(e, index)}
                    />
                    <IconButton id={id} onClick={(e) => handleRemoveFilter(e, index)}>
                      <ClearIcon />
                    </IconButton>
                    <IconButton id={id} onClick={(e) => handleFilterInputSave(e, index)}>
                      <DoneIcon />
                    </IconButton>
                  </Box>
                )}
                {index === criteria.length - 1 ? (
                  <IconButton onClick={handleAddFilter} id={id} sx={{ transform: "scale(1.2)" }} color="secondary">
                    <Add />
                  </IconButton>
                ) : (
                  <Typography marginX={0.5} variant="body2" textTransform={"uppercase"}>
                    or
                  </Typography>
                )}
              </Box>
            );
          })
        ) : (
          <Button
            onClick={handleAddFilter}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              paddingLeft: 0,

              fontWeight: 300,
            }}
            color="secondary"
            id={id}
          >
            <Add sx={{ transform: "scale(1.2)", marginRight: "2px" }} /> Add filter
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default CriteriaComponent;
