import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

import { LedgerCheck } from "generated/sdk";

const MultipleActionComponent: React.FC<{
  checks?: LedgerCheck[];
  handleButtonAction: any;
  selectedRowIds: string[];
  selectedRowIdsShouldIncludeRole: boolean;
}> = ({ handleButtonAction, checks, selectedRowIds, selectedRowIdsShouldIncludeRole = false }) => {
  const disabled = { sign: true, cancelDeposit: true, void: true, resend: true, deposit: true };
  const selectedChecks = checks?.filter((check) =>
    selectedRowIds.includes(`${check.id}${selectedRowIdsShouldIncludeRole ? `-${check.role}` : ""}`),
  );

  selectedChecks?.forEach((selectedCheck) => {
    if (selectedCheck.action?.action === "resend") {
      disabled.resend = selectedChecks
        .filter((selectedCheck) => selectedCheck.action?.action === "resend")
        .every((selectedCheck) => selectedCheck.action?.disabled === true);
    }

    if (selectedCheck.options?.[0].action === "void") {
      disabled.void = false;
    }

    if (selectedCheck.action?.action === "sign") {
      disabled.sign = selectedChecks
        .filter((selectedCheck) => selectedCheck.action?.action === "sign")
        .every((selectedCheck) => !!selectedCheck.action?.disabled === true);
    }

    if (selectedCheck.action?.action === "deposit") {
      disabled.deposit = selectedChecks
        .filter((selectedCheck) => selectedCheck.action?.action === "deposit")
        .every((selectedCheck) => !!selectedCheck.action?.disabled === true);
    }

    if (selectedCheck.options?.[0].action === "cancel-deposit") {
      disabled.cancelDeposit = false;
    }
  });

  const actionArray = [
    { action: "sign", label: "Sign", disabled: disabled.sign },
    { action: "resend", label: "Send reminder", disabled: disabled.resend },
    { action: "deposit", label: "Deposit", disabled: disabled.deposit },
  ];

  const optionsArray = [
    { action: "cancel-deposit", label: "Cancel deposit", disabled: disabled.cancelDeposit },
    { action: "void", label: "Void", disabled: disabled.void },
  ];

  const chooseSuitableAction = (key: any) => {
    switch (key.action) {
      case "deposit":
        handleButtonAction("deposit");
        break;
      case "void":
        handleButtonAction(key.action, key.label);
        break;
      case "sign":
        handleButtonAction(key.action, key.label);
        break;
      case "cancel-deposit":
        handleButtonAction(key.action, key.label);
        break;
      case "resend":
        handleButtonAction(key.action, key.label);

        break;
      default:
        break;
    }
  };

  return (
    <Grid item>
      {optionsArray.map((option: any, index: any) => {
        const checkOptionCount = selectedChecks?.filter(
          (selectedCheck) => selectedCheck.options?.[0].action === option.action,
        )?.length;

        return !checkOptionCount ? null : (
          <Button
            key={`${option.action}-${index}`}
            variant={"outlined"}
            sx={{ marginLeft: "20px" }}
            onClick={() => {
              chooseSuitableAction(option);
            }}
            disabled={option.disabled}
          >
            {option.label} ({checkOptionCount})
          </Button>
        );
      })}
      {actionArray.map((action: any, index: any) => {
        const checkActionCount = selectedChecks?.filter(
          (selectedCheck) => selectedCheck.action?.action === action.action && !selectedCheck.action?.disabled,
        )?.length;

        return !checkActionCount ? null : (
          <Button
            key={`${action.action}-${index}`}
            variant={"contained"}
            color="secondary"
            sx={{ marginLeft: "20px" }}
            onClick={() => {
              chooseSuitableAction(action);
            }}
            disabled={action.disabled}
          >
            {action.label} ({checkActionCount})
          </Button>
        );
      })}
    </Grid>
  );
};

export default MultipleActionComponent;
