import { Skeleton, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import { IVBillBatchStatus } from "generated/sdk.vbill";
import { observer } from "mobx-react";
import { ReactNode, useMemo } from "react";
import { useStore } from "storeContainer";
import { BatchVBill } from "../../BatchVBill/BatchVBill";
import { IBillsAccordianExpanded } from "../../BatchVBills";

const emptySkeletonList = new Array(10).fill("");

interface IVBillsAvailableListProps {
  onBatchVBillCheckboxChange: (checked: boolean, invoiceId: number) => void;
  billsAccordianExpanded: IBillsAccordianExpanded;
  droppableProvidedPlaceholder: ReactNode;
  selectedVBillsToAdd: number[];
}

export const VBillsAvailableList = observer(function VBillsAvailableList({
  onBatchVBillCheckboxChange,
  billsAccordianExpanded,
  droppableProvidedPlaceholder,
  selectedVBillsToAdd,
}: IVBillsAvailableListProps) {
  const vBillBatchDetailsStore = useStore("VBillBatchDetailsStore");
  const organizationStore = useStore("OrganizationStore");

  const { batchAvailableVBills, batchDetails, vBillBatchAdditionalMappingsSettings } = vBillBatchDetailsStore;
  const batchAvailableVBillsData = batchAvailableVBills.data;
  const batchDetailsData = batchDetails.data;
  const vBillBatchAdditionalMappingsSettingsData = vBillBatchAdditionalMappingsSettings.data;

  const vBillEnabledCompanies = useMemo(
    () =>
      (organizationStore.organizationVBillEnabledCompanies.data?.items ?? []).map(({ name, companyId }) => ({
        name,
        id: companyId,
      })),
    [organizationStore.organizationVBillEnabledCompanies.data?.items],
  );
  const isBatchClosed = useMemo(
    () =>
      batchDetailsData?.status === IVBillBatchStatus.Closed || batchDetailsData?.status === IVBillBatchStatus.Deleted,
    [batchDetailsData?.status],
  );

  return (
    <TableBody>
      {batchAvailableVBills.isLoading ? (
        <>
          {emptySkeletonList.map((_, index) => (
            <TableRow key={index} sx={{ cursor: "default" }}>
              <TableCell colSpan={8} sx={{ padding: "12px" }}>
                <Skeleton variant="rounded" sx={{ height: "30px" }} />
              </TableCell>
            </TableRow>
          ))}
        </>
      ) : (batchAvailableVBillsData?.items ?? []).length === 0 ? (
        <TableRow sx={{ cursor: "default" }}>
          <TableCell colSpan={12} sx={{ textAlign: "center" }}>
            <Typography>No results found</Typography>
          </TableCell>
        </TableRow>
      ) : (
        <>
          {batchAvailableVBillsData?.items.map((vBill, index) => (
            <BatchVBill
              index={index}
              key={vBill.id}
              vBill={vBill}
              checkboxSelected={selectedVBillsToAdd.includes(vBill.id)}
              onCheckboxChange={(checked) => onBatchVBillCheckboxChange(checked, vBill.id)}
              isDragDisabled={!billsAccordianExpanded.selectedBills || !billsAccordianExpanded.availableBills}
              isBatchClosed={isBatchClosed}
              additionalMappingsSettings={vBillBatchAdditionalMappingsSettingsData}
              vBillEnabledCompanies={vBillEnabledCompanies}
            />
          ))}
          {droppableProvidedPlaceholder}
        </>
      )}
    </TableBody>
  );
});
