import { SvgIcon, SvgIconProps } from "@mui/material";

export const BatchProcessingIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 256 256" {...props}>
      <g>
        <path d="M60.6,101.3v104.4c0,4.4,3.3,7.8,7.8,7.8h133.8c4.4,0,7.8-3.3,7.8-7.8V101.3c0-4.4-3.3-7.8-7.8-7.8H68.4C63.9,93.6,60.6,97.5,60.6,101.3L60.6,101.3z M76.1,109.1L76.1,109.1h118.2v88.8H76.1V109.1L76.1,109.1z M17.3,162.4L17.3,162.4c4.4,0,7.8-3.3,7.8-7.8V58h126c4.4,0,7.8-3.3,7.8-7.8c0-4.5-3.3-7.8-7.8-7.8H17.8l0,0c-4.4,0-7.8,3.3-7.8,7.8v104.4C9.5,158.5,12.9,162.4,17.3,162.4L17.3,162.4z M42.8,68L42.8,68c-4.4,0-7.8,3.3-7.8,7.8v104.4c0,4.4,3.3,7.8,7.8,7.8c4.4,0,7.8-3.3,7.8-7.8V83.6h126c4.4,0,7.8-3.3,7.8-7.8s-3.3-7.8-7.8-7.8H42.8L42.8,68L42.8,68z" />
        <path d="M104.5,124.7l-16.1,12.2c0,0,41.6,35,64.4,67.7c0,0,14.4-57.2,93.3-128.2l-3.3-8.3c0,0-46.1,21.6-99.9,89.4C142.2,157.4,128.3,143.5,104.5,124.7z" />
      </g>
    </SvgIcon>
  );
};
