import { Box, Button } from "@mui/material";
import { ExpandLessIcon, ExpandMoreIcon } from "components/common/icons";
import { ReactNode } from "react";
import { NavLink } from "react-router-dom";
import { COLORS } from "themes/default";

const compActiveSx = { opacity: 1, borderBottom: `1px solid ${COLORS.logoBlue}` };
const expandIconStyles = { fontSize: "16px !important", marginRight: "4px" };

interface IVBillOrgMenuItemProps {
  children: ReactNode;
  to?: string;
  active?: boolean;
  asButton?: boolean;
  onButtonClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  menuOpen?: boolean;
  insideMenu?: boolean;
}

export const VBillOrgMenuItem = ({
  children,
  to,
  active,
  asButton,
  onButtonClick,
  menuOpen,
  insideMenu,
}: IVBillOrgMenuItemProps) => (
  <Box
    sx={{
      color: COLORS.logoBlue,
      fontWeight: "500",
      opacity: "0.65",
      textDecoration: "none",
      transition: "opacity 0.5s ease-in-out",
      ...(asButton ? { borderRadius: 0, padding: 0, justifyContent: "space-between" } : {}),
      ...(insideMenu
        ? { width: "100%", padding: "10px 16px", ...(active ? { opacity: 1 } : {}) }
        : { height: "40px", ...(active ? compActiveSx : {}), "&:hover": compActiveSx }),
      display: "flex",
      alignItems: "center",
    }}
    component={asButton ? Button : NavLink}
    {...(asButton
      ? {
          onClick: onButtonClick,
          endIcon: menuOpen ? <ExpandLessIcon sx={expandIconStyles} /> : <ExpandMoreIcon sx={expandIconStyles} />,
        }
      : { to })}
  >
    {children}
  </Box>
);
