import { Button } from "@mui/material";
import { useState } from "react";
import { SummariesMenuDialog } from "./SummariesMenuDialog";

export const SummariesMenu = () => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

  return (
    <>
      <Button variant="outlined" onClick={(e) => setMenuAnchorEl(e.currentTarget)} sx={{ height: "30px" }}>
        Menu
      </Button>

      <SummariesMenuDialog menuAnchorEl={menuAnchorEl} onCloseMenu={() => setMenuAnchorEl(null)} />
    </>
  );
};
