import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Popover,
  Radio,
  RadioGroup,
  Tooltip,
} from "@mui/material";
import { EditNotificationsIcon } from "components/common/icons";
import { IChatNotificationSubscriptionType } from "generated/sdk.chat";
import { observer } from "mobx-react";
import { useChatChannelStore } from "modules/Chat/hooks/hooks";
import { ChangeEvent, FunctionComponent, useEffect, useState } from "react";

const editNotifRadioList = [
  {
    name: IChatNotificationSubscriptionType.Mention,
    label: "Mention",
  },
  {
    name: IChatNotificationSubscriptionType.All,
    label: "All",
  },
  {
    name: IChatNotificationSubscriptionType.None,
    label: "None",
  },
];

export const ChatEditNotifications: FunctionComponent = observer(() => {
  const chatChannelStore = useChatChannelStore();
  const [popoverAnchorEl, setPopoverAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isUpdateNotoifReqLoading, setIsUpdateNotoifReqLoading] = useState(false);
  const [editRadioGroupValue, setEditRadioGroupValue] = useState<IChatNotificationSubscriptionType>();

  const popoverOpen = Boolean(popoverAnchorEl);

  useEffect(() => {
    if (!popoverOpen) {
      return;
    }

    setEditRadioGroupValue(chatChannelStore?.currentSessionMember?.notificationSubscription);
  }, [chatChannelStore?.currentSessionMember, popoverOpen, setEditRadioGroupValue]);

  const handleClosePopover = () => {
    setPopoverAnchorEl(null);
  };

  const handleEditNotifRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setEditRadioGroupValue(value as IChatNotificationSubscriptionType);
  };

  const handleSaveCurrentNotifBtnClick = async () => {
    setIsUpdateNotoifReqLoading(true);

    if (editRadioGroupValue) {
      await chatChannelStore?.setUpdateNotificationSubscription(editRadioGroupValue);
    }

    setIsUpdateNotoifReqLoading(false);
    handleClosePopover();
  };

  return (
    <Box sx={{}}>
      <Tooltip title="Edit Notifications">
        <IconButton
          aria-describedby={popoverOpen ? "chat-notifications-subscriptions-popover" : undefined}
          onClick={(event) => setPopoverAnchorEl(event.currentTarget)}
        >
          <EditNotificationsIcon color="primary" sx={{ fontSize: "24px" }} />
        </IconButton>
      </Tooltip>

      <Popover
        id="chat-notifications-subscriptions-popover"
        open={popoverOpen}
        anchorEl={popoverAnchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <Box sx={{ width: "250px", padding: "20px" }}>
          <FormControl>
            <FormLabel id="chat-notifications-radio-buttons-group">Set Notifications</FormLabel>
            <RadioGroup
              aria-labelledby="chat-notifications-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={editRadioGroupValue}
              onChange={handleEditNotifRadioChange}
            >
              {editNotifRadioList.map(({ name, label }) => (
                <FormControlLabel value={name} key={name} control={<Radio />} label={label} />
              ))}
            </RadioGroup>
          </FormControl>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              paddingTop: "15px",
            }}
          >
            <Button variant="outlined" onClick={handleClosePopover}>
              Cancel
            </Button>

            <LoadingButton
              loading={isUpdateNotoifReqLoading}
              variant="contained"
              onClick={handleSaveCurrentNotifBtnClick}
            >
              Save
            </LoadingButton>
          </Box>
        </Box>
      </Popover>
    </Box>
  );
});
