import { Box, TablePagination } from "@mui/material";
import { observer } from "mobx-react";
import { useStore } from "storeContainer";
import { COLORS } from "themes/default";
import { useCodingLedgerFilters } from "../hooks";

export const CodingFooter = observer(function CodingFooter() {
  const vBillCodingLedgerStore = useStore("VBillCodingLedgerStore");
  const [codingLedgerFilters, setCodingLedgerFilters] = useCodingLedgerFilters();

  const invoicesList = vBillCodingLedgerStore.invoicesList;
  const invoicesListData = invoicesList.data;

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1202, // 1200 has Drawer
        borderTop: "1px solid #DFE7F2",
      }}
    >
      <TablePagination
        sx={{ maxWidth: "1400px", margin: "0 auto", backgroundColor: COLORS.white }}
        component="div"
        showFirstButton
        showLastButton
        page={(codingLedgerFilters.pagination.page ?? 1) - 1}
        rowsPerPage={codingLedgerFilters.pagination.per_page ?? 10}
        rowsPerPageOptions={[10, 25, 50, 100]}
        count={invoicesListData?.total ?? 10}
        onRowsPerPageChange={(event) =>
          setCodingLedgerFilters({ pagination: { per_page: Number(event.target.value), page: 1 } })
        }
        onPageChange={(_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) =>
          setCodingLedgerFilters((prev) => ({ pagination: { ...prev.pagination, page: newPage + 1 } }))
        }
      />
    </Box>
  );
});
