import { Box, Divider, IconButton, Skeleton, TextField, Tooltip, Typography } from "@mui/material";
import { formatCurrency } from "common/helpers/utils";
import { CloseIcon } from "components/common/icons";
import { useVBillFormattedDate } from "components/pages/common/VBill/hooks";
import { VBillPayBillStoreItemSelector } from "components/pages/common/VBill/VBillPayBillStoreItemSelector/VBillPayBillStoreItemSelector";
import { VBillPriceAmount } from "components/pages/common/VBill/VBillPriceAmount/VBillPriceAmount";
import { IVBillIIntegrationIntegrationSettingsVBillAdditionalMappingsEntryShowIn } from "generated/sdk.vbill";
import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { Controller, useWatch } from "react-hook-form";
import { useStore } from "storeContainer";
import { COLORS } from "themes/default";
import { usePayVBillsCompaniesMappingsSettings } from "../../../PayVBillsCompaniesMappingsSettings";
import { IPayVBillsFormFields } from "../../../PayVBillsDialog";

interface IPayVBillLineProps {
  onRemoveBill: () => void;
  onPayFromSelectorChange: () => void;
  groupIndex: number;
  billIndex: number;
}

export const PayVBillLine = observer(function PayVBillLine({
  onRemoveBill,
  onPayFromSelectorChange,
  groupIndex,
  billIndex,
}: IPayVBillLineProps) {
  const organizationStore = useStore("OrganizationStore");
  const vBillPayLedgerStore = useStore("VBillPayLedgerStore");
  const { selectedBillIdsToPay } = vBillPayLedgerStore;
  const billIdFormFieldWatcher = useWatch({
    name: `groups.${groupIndex}.bills.${billIndex}.billId`,
  }) as IPayVBillsFormFields["groups"][number]["bills"][number]["billId"];
  const payVBillsCompaniesMappingsSettings = usePayVBillsCompaniesMappingsSettings();

  const { billsLedgerList } = vBillPayLedgerStore;

  const billDetails = useMemo(
    () => billsLedgerList?.data?.items.find(({ id }) => id === billIdFormFieldWatcher),
    [billIdFormFieldWatcher, billsLedgerList?.data?.items],
  );
  const invoiceDate = useVBillFormattedDate(billDetails?.invoiceDate ?? "");
  const payFromAdditionalMapping = useMemo(
    () =>
      payVBillsCompaniesMappingsSettings.data
        ?.get(billDetails?.companyId ?? "")
        ?.find(
          ({ showIn }) => showIn === IVBillIIntegrationIntegrationSettingsVBillAdditionalMappingsEntryShowIn.Payment,
        ),
    [billDetails?.companyId, payVBillsCompaniesMappingsSettings],
  );
  const companyName = useMemo(
    () =>
      (organizationStore.organizationVBillEnabledCompanies.data?.items ?? []).find(
        ({ companyId }) => companyId === billDetails?.companyId,
      )?.name,
    [billDetails?.companyId, organizationStore.organizationVBillEnabledCompanies.data?.items],
  );

  const handleRemoveBillBtnClick = () => {
    const currentSelectedBillIdsToPay = selectedBillIdsToPay.filter((id) => id !== billIdFormFieldWatcher);

    onRemoveBill();
    vBillPayLedgerStore.updateSelectedBillIdsToPay(currentSelectedBillIdsToPay);
  };

  if (!billDetails) {
    return null;
  }

  return (
    <>
      <Box sx={{ padding: "0 20px" }}>
        <Box
          sx={{
            display: "flex",
            gap: "20px",
            alignItems: "flex-start",
          }}
        >
          <Box sx={{ width: "200px" }}>
            <Typography sx={{ fontSize: "14px", color: COLORS.newSecondaryColor }} noWrap>
              #{billDetails.invoiceNumber}
            </Typography>
            <Typography sx={{ fontSize: "12px", color: COLORS.textGray }}>Bill #{billDetails.id}</Typography>
            <Typography sx={{ fontSize: "12px", color: COLORS.textGray }}>{invoiceDate}</Typography>
            <Typography sx={{ fontSize: "14px" }}>Company:</Typography>
            <Typography sx={{ color: COLORS.mediumGrey, fontSize: "12px" }} noWrap>
              {companyName}
            </Typography>
          </Box>
          <Box sx={{ width: "200px" }}>
            <Typography sx={{ marginBottom: "5px" }}>Amount: </Typography>
            <Controller
              name={`groups.${groupIndex}.bills.${billIndex}.amount`}
              rules={{
                required: "Amount is required.",
                validate: (value) =>
                  Number(value) > Number(billDetails.amounts?.amountFreeToPay ?? 0)
                    ? `Amount should be less then ${formatCurrency(Number(billDetails.amounts?.amountFreeToPay ?? 0))}`
                    : undefined,
              }}
              render={({ field, fieldState, formState }) => (
                <VBillPriceAmount
                  value={field.value}
                  setValue={field.onChange}
                  errorMsg={fieldState.error?.message}
                  textFieldSx={{ width: "100%" }}
                  textFieldInputProps={{ sx: { height: "30px" } }}
                />
              )}
            />
          </Box>

          <Box sx={{ width: "200px" }}>
            <Typography sx={{ marginBottom: "5px" }}>Memo: </Typography>
            <Controller
              name={`groups.${groupIndex}.bills.${billIndex}.memo`}
              render={({ field }) => (
                <TextField
                  placeholder="Memo"
                  {...field}
                  sx={{ width: "100%" }}
                  InputProps={{ sx: { height: "30px" } }}
                />
              )}
            />
          </Box>

          {payVBillsCompaniesMappingsSettings.isFetching ? (
            <Skeleton variant="rounded" sx={{ width: "200px", height: "30px", marginTop: "22px" }} />
          ) : (
            payFromAdditionalMapping && (
              <Box sx={{ width: "200px" }}>
                <Typography sx={{ marginBottom: "5px" }}>{payFromAdditionalMapping.label}:</Typography>
                <Controller
                  name={`groups.${groupIndex}.bills.${billIndex}.paymentFromGlAccount`}
                  rules={{
                    required: payFromAdditionalMapping.required
                      ? `${payFromAdditionalMapping.label} is required.`
                      : undefined,
                  }}
                  render={({ field, fieldState }) => (
                    <VBillPayBillStoreItemSelector
                      selectedStoreItem={field.value}
                      onSelectStoreItem={(option) => {
                        field.onChange(option);
                        onPayFromSelectorChange();
                      }}
                      additionalMappings={payFromAdditionalMapping}
                      invoiceCompanyId={billDetails.companyId ?? ""}
                      invoiceCoreOrgId={billDetails.coreOrgId}
                      errorMsg={fieldState.error?.message}
                      btnSmallHeight
                    />
                  )}
                />
              </Box>
            )
          )}

          <Tooltip title="Remove bill from group">
            <IconButton onClick={handleRemoveBillBtnClick}>
              <CloseIcon sx={{ fontSize: "12px" }} />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>

      <Divider sx={{ margin: "5px 0" }} />
    </>
  );
});
