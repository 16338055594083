import { DelegationGroupResolutionQuery } from "generated/sdk";
import { IVBillInvoiceSchema } from "generated/sdk.vbill";
import { uniq } from "lodash";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import Tree, { Point, RawNodeDatum } from "react-d3-tree";
import { useStore } from "storeContainer";
import { COLORS } from "themes/default";
import { getApprovalFlags } from "./getApprovalFlags";

interface IDelegationTreeViewProps {
  organizationId: string;
  billId?: string;
  checkId?: string;
  approvalStatus: IVBillInvoiceSchema["approvalStatus"];
  translate?: Point;
}

export const DelegationTreeView: React.FunctionComponent<IDelegationTreeViewProps> = observer(
  ({ organizationId, billId, checkId, approvalStatus, translate = { x: 200, y: 50 } }) => {
    const approvalStore = useStore("ApprovalStore");
    const { delegationGroupResolution } = approvalStore;

    useEffect(() => {
      approvalStore.loadDelegationGroupResolution(
        {
          organization_id: organizationId,
          bill_id: billId,
          check_id: checkId,
        },
        true,
      );
    }, [organizationId, billId, checkId]);

    if (delegationGroupResolution.isLoading || !delegationGroupResolution.data) {
      return null;
    }

    const usersGroupMap: {
      [userId: string]: {
        [groupId: string]: DelegationGroupResolutionQuery["DelegationGroupResolution"][0];
      };
    } = {};
    const users: any = {};
    for (const group of delegationGroupResolution.data) {
      const delegator_id = group.delegator.id;
      const group_id = group.id;

      usersGroupMap[delegator_id] = usersGroupMap[delegator_id] ?? {};
      usersGroupMap[delegator_id][group_id] = usersGroupMap[delegator_id][group_id] ?? group;

      users[delegator_id] = group.delegator.account?.name;
      group.delegations.forEach((d) => (users[d.delegate.id] = d.delegate.account?.name));
    }

    const visited: string[] = [];
    function getNodes(fromDelegatorId: string, includeRootNode: boolean = false): RawNodeDatum | RawNodeDatum[] {
      if (visited.includes(fromDelegatorId)) {
        return [];
      }

      visited.push(fromDelegatorId);

      const userGroups = usersGroupMap[fromDelegatorId];

      if (!userGroups) {
        return [];
      }

      const node = {
        name: users[fromDelegatorId],
        children: [] as any,
        attributes: {
          isUser: true,
          userId: fromDelegatorId,
          ...getApprovalFlags(approvalStatus!, fromDelegatorId),
        },
      };
      for (const group_id in userGroups) {
        const group = delegationGroupResolution.data?.find((t) => t.id === group_id)!;
        const groupNode: any = {
          name: ``,
          attributes: {
            isUser: false,
            groupId: group.id,
            overallApprovalsMet: group.overall_approvals_met,
            overallReviewsMet: group.overall_reviews_met,
          },
          children: group?.delegations.map((del) => ({
            attributes: {
              isUser: true,
              role: del.role,
              userId: del.delegate.id,
              ...getApprovalFlags(approvalStatus!, del.delegate.id),
            },
            name: users[del.delegate.id],
            children: getNodes(del.delegate.id),
          })),
        };
        node.children.push(groupNode);
      }

      return includeRootNode ? node : node.children;
    }

    function cleanUpNodes(node: RawNodeDatum): RawNodeDatum {
      if (node.attributes && node.attributes?.isUser && node.children?.length === 1) {
        node.attributes = {
          ...node.attributes,
          isCollapsedGroup: true,
          overallApprovalsMet: node.children[0].attributes?.overallApprovalsMet!,
          overallReviewsMet: node.children[0].attributes?.overallReviewsMet!,
        };
        node.children = node.children[0].children;
      }

      node.children?.forEach(cleanUpNodes);

      return node;
    }

    function findRootDelegators() {
      // groups we don't know of (ie, parent is not included in our list)
      return uniq(
        delegationGroupResolution
          .data!.filter(
            (treeNode) =>
              !delegationGroupResolution.data!.find((otherTreeNode) => otherTreeNode.id === treeNode.parent_group_id),
          )
          .map((treeNode) => treeNode.delegator.id),
      );
    }

    const rootDelegatorIds = findRootDelegators();
    let nodes: RawNodeDatum;
    if (rootDelegatorIds.length === 1) {
      nodes = getNodes(rootDelegatorIds[0], true) as RawNodeDatum;
    } else if (rootDelegatorIds.length > 1) {
      // if we have more than one root delegator, make all of them children of "Other delegators"
      // react-d3-tree does not support multiple roots
      nodes = {
        name: "Other delegators",
        children: rootDelegatorIds.map((rootDelegatorId) => getNodes(rootDelegatorId, true) as RawNodeDatum),
        attributes: { isUser: false },
      };
    } else {
      nodes = {
        name: "You",
        children: [],
        attributes: { isUser: true },
      };
    }

    const renderForeignObjectNode = (p: { nodeDatum: RawNodeDatum; toggleNode: any }) => {
      let circleColor = COLORS.darkGrey;
      if (p.nodeDatum.attributes?.hasUserApproved || p.nodeDatum.attributes?.overallApprovalsMet) {
        circleColor = COLORS.success;
      } else if (p.nodeDatum.attributes?.hasUserReviewed || p.nodeDatum.attributes?.overallReviewsMet) {
        circleColor = COLORS.orange;
      } else if (p.nodeDatum.attributes?.hasUserDeclined) {
        circleColor = COLORS.error;
      }

      return (
        <g>
          <circle r={5} color={circleColor} fill={circleColor} style={{ stroke: "#333", strokeWidth: 1 }}></circle>
          <foreignObject width={150} height={50} x={-75} y={10}>
            <div>
              <div
                style={{
                  fontSize: "12px",
                  textAlign: "center",
                  background: "#fff",
                  opacity: 0.85,
                  fontWeight: "bold",
                  lineHeight: "1.1em",
                }}
              >
                {p.nodeDatum.name}
                <br />
                <span style={{ fontSize: "10px", color: "#555", fontWeight: "normal" }}>
                  {p.nodeDatum.attributes?.role}
                </span>
              </div>
            </div>
          </foreignObject>
        </g>
      );
    };

    return (
      <Tree
        // zoom={0.85}
        translate={translate}
        nodeSize={{ x: 120, y: 100 }}
        orientation="vertical"
        renderCustomNodeElement={renderForeignObjectNode}
        data={cleanUpNodes(nodes as RawNodeDatum)}
      />
    );
  },
);
