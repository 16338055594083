import { observer } from "mobx-react-lite";
import { useParams } from "react-router";
import { useStore } from "../../../../../storeContainer";
import { useEffect } from "react";
import styles from "./Vendor.module.scss";
import Card from "../../../../common/Card";

const Vendor = () => {
  const { vendorId } = useParams<{ vendorId: string }>();
  const vendorsStore = useStore("VendorsStore");
  useEffect(() => {
    vendorsStore.loadVendors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const vendor = vendorsStore.getVendor(vendorId);
  return (
    <div>
      {vendor ? (
        <Card className={styles.card}>
          <p>Name: {vendor.name} </p>
          <p>External Ref: {vendor.external_ref} </p>
          <p>Active: {vendor.active!.toString()} </p>
        </Card>
      ) : null}
    </div>
  );
};

export default observer(Vendor);
