import { Box } from "@mui/material";
import { IChatChatIoMessageOutputSchema } from "generated/graphql.chat";
import { observer } from "mobx-react";
import { useChatChannelStore } from "modules/Chat/hooks/hooks";
import { FunctionComponent, useEffect } from "react";
import { COLORS } from "themes/default";
import { ChatMessageBlockContent } from "../ChatMessage/ChatMessageBlockContent/ChatMessageBlockContent";
import { useMessageInViewportOnce } from "../ChatMessage/useMessageInViewportOnce";

interface ISystemMessageProps {
  message: IChatChatIoMessageOutputSchema;
}

export const SystemMessage: FunctionComponent<ISystemMessageProps> = observer(({ message: { content, id } }) => {
  const chatChannelStore = useChatChannelStore();
  const { domElementRef, isInView } = useMessageInViewportOnce();

  useEffect(() => {
    if (id && isInView && chatChannelStore?.channelMessageNotification?.lastMessageId === id) {
      chatChannelStore?.setUpdateLastSeenMessage(id).finally(chatChannelStore?.loadMembers);
      chatChannelStore?.loadGetChannel();
    }
  }, [isInView, chatChannelStore?.channelMessageNotification?.lastMessageId, id, chatChannelStore]);

  return (
    <Box
      ref={domElementRef}
      sx={{
        display: "flex",
        justifyContent: "center",
        marginBottom:
          chatChannelStore?.orderedMessages[chatChannelStore?.orderedMessages.length - 1].id !== id ? "30px" : "0px",
        ...(content.message?.includes("To reopen post a new message")
          ? { borderBottom: `1px solid ${COLORS.philippineSilver}`, paddingBottom: "20px" }
          : {}),
      }}
    >
      <ChatMessageBlockContent content={content} isMessageOwner={false} sx={{ backgroundColor: COLORS.azureWhite }} />
    </Box>
  );
});
