import { IOtherUserModalProps } from "./types";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import styles from "../../../../VChecks/FiltersModal/FiltersModal.module.scss";
import React, { useState } from "react";

const style = {
  position: "absolute",
  top: "0",
  right: "0",
  width: 400,
  height: "100vh",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
};

// React.forwardRef()

const OtherUserModal = React.forwardRef(({ handleClose, handleSave, setModalOtherName }: IOtherUserModalProps, ref) => {
  const [name, setName] = useState<string>("");

  const handleNameChange = (e: any) => {
    setName(e.target.value);
    setModalOtherName(e.target.value);
  };
  return (
    <Box sx={style} ref={ref}>
      <Grid container sx={{ height: "100%" }}>
        <Grid item container direction={"column"}>
          <Grid item container justifyContent={"space-between"} alignItems={"baseline"} sx={{ marginBottom: "10px" }}>
            <Typography variant={"body1"}>Name of sender</Typography>
          </Grid>
          <Grid item sx={{ marginBottom: "20px" }}>
            <TextField id="name" fullWidth value={name || ""} name="name" onChange={handleNameChange} />
          </Grid>
        </Grid>
        <Grid item className={styles.btnContainer}>
          <div className={styles.btnBorder}>
            <Button
              variant={"outlined"}
              className={styles.cancelButton}
              sx={{ marginRight: "10px" }}
              onClick={() => handleClose()}
            >
              Cancel
            </Button>
            <Button variant={"contained"} onClick={() => handleSave()}>
              Save
            </Button>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
});

export default OtherUserModal;
