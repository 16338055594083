import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import { Box, Tooltip, Typography } from "@mui/material";
import { CheckTypeEnumType, LedgerCheck } from "generated/sdk";
import { COLORS } from "themes/default";

interface IVCheckToBankAccountInfoDetailsProps {
  bankAccountInfo: LedgerCheck["bank_account_info"];
  checkType: LedgerCheck["check_type"];
}

export const VCheckToBankAccountInfoDetails = ({
  checkType,
  bankAccountInfo,
}: IVCheckToBankAccountInfoDetailsProps) => {
  if (checkType !== CheckTypeEnumType.Reimbursement || !bankAccountInfo) {
    return null;
  }

  const { account_number, bank_name, routing_number } = bankAccountInfo;

  return (
    <Box sx={{ position: "absolute", top: "0", right: "0", zIndex: "1" }}>
      <Tooltip
        arrow
        title={
          <Box sx={{ color: COLORS.white }}>
            <Typography sx={{ color: COLORS.white }}>Bank account info:</Typography>
            <Typography sx={{ fontSize: "12px", color: COLORS.white }}>
              Account number: ***{account_number?.length ? account_number : "-"}
            </Typography>
            <Typography sx={{ fontSize: "12px", color: COLORS.white }}>
              Bank name: {bank_name?.length ? bank_name : "-"}
            </Typography>
            <Typography sx={{ fontSize: "12px", color: COLORS.white }}>
              Routing number: {routing_number?.length ? routing_number : "-"}
            </Typography>
          </Box>
        }
      >
        <AccountBalanceIcon
          sx={{
            fontSize: "14px",
          }}
        />
      </Tooltip>
    </Box>
  );
};
