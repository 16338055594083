import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { Spinner } from "components/common/Spinner/Spinner";
import { CheckActionMutation, LedgerCheck } from "generated/sdk";
import _ from "lodash";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { JsonParam, useQueryParam } from "use-query-params";
import { getPastTense } from "../../../common/helpers/utils";
import { useStore } from "../../../storeContainer";
import { formatDateToDesign } from "./utils";

const ReviewAndConfirm: React.FunctionComponent<{
  //New
  checksIds: string[];
  checks: LedgerCheck[];
  selectedRowIds?: string[];
  actionInfo: any;
  setDeselected?: Function;
  close: Function;
  selectedBankAccountId?: string;
  selectedOrganizationId?: string;
  isModal?: boolean;
  setSelectedRowIds?: Function;
  setActionSuccessVChecks?: Function;
  setIsSuccessWithErrors?: Function;
  setOpenSelectBankAccountModal?: React.Dispatch<React.SetStateAction<boolean>>;
  handleReloadChecks?: () => void;
  setIsReviewAndConfirmLoading?: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
  //New
  checksIds,
  checks,
  selectedRowIds,
  actionInfo,
  setDeselected,
  close,
  selectedBankAccountId,
  setSelectedRowIds,
  setActionSuccessVChecks,
  setIsSuccessWithErrors,
  setOpenSelectBankAccountModal,
  handleReloadChecks,
  setIsReviewAndConfirmLoading,
}) => {
  //VChecksStore init
  const vChecksStore = useStore("VChecksStore");
  const OrganizationStore = useStore("OrganizationStore");
  const UndepositedChecksStore = useStore("UndepositedChecksStore");
  const { organizationId } = useParams<{ organizationId?: string }>();
  const [filters] = useQueryParam("filters", JsonParam);
  const [pagination] = useQueryParam("pagination", JsonParam);
  const [errorsArray, setErrorsArray] = useState<
    {
      check_id: string;
      message: string;
    }[]
  >([]);
  const [actionSuccess, setActionSuccess] = useState(false);
  const numberOfChecksToBeHandled = checksIds?.length;
  const [response, setResponse] = useState<any>();

  const formatCurrency = (number: number) => {
    return Intl.NumberFormat("us-US", { style: "currency", currency: "USD" }).format(number);
  };
  const errorMessage = useCallback(
    (checkId: string) => {
      const foundObj = errorsArray.find((error) => error.check_id === checkId);
      const message = foundObj ? foundObj.message : undefined;
      return message;
    },
    [errorsArray],
  );
  const [loading, setLoading] = useState(false);
  const lastWordOfLabel = actionInfo?.label?.split(" ") ?? "";

  useEffect(() => {
    if (response) {
      if (response.errors?.length === 0) {
        setActionSuccess(true);
        if (setActionSuccessVChecks) setActionSuccessVChecks(true);
        // if (setDeselected) {
        //   setDeselected(true);
        // }
      } else if (response.errors?.length > 0) {
        let errors: any[] = [];
        response.errors?.forEach((error: { message: any }) => {
          errors.push(error);
        });
        if (errors?.length > 0) {
          setErrorsArray(errors);
        }
      }
      if (setIsSuccessWithErrors) {
        setIsSuccessWithErrors(response.successes > 0);
      }
    }
  }, [response, setActionSuccessVChecks, setDeselected, setIsSuccessWithErrors]);

  const actionsArray = ["resend", "sign", "deposit"];
  const optionsArray = ["void", "cancel-deposit"];

  const filteredChecks =
    checks?.length === 1
      ? checks
      : checks?.filter((check) => {
          if (actionsArray.includes(actionInfo.action)) {
            if (check.action?.action) {
              return check.action.action === actionInfo.action && !check.action.disabled;
            }

            if (check.options?.[0].action) {
              return check.options?.[0].action === actionInfo.action;
            }
          } else if (optionsArray.includes(actionInfo.action)) {
            if (check.options?.[0].action) {
              return check.options?.[0].action === actionInfo.action;
            }
            if (check.action?.action) {
              return check.action.action === actionInfo.action && !check.action.disabled;
            }
          }
          return false;
        });

  const totalAmount = useMemo(
    () =>
      filteredChecks?.length === 1
        ? filteredChecks?.[0]?.amount!
        : filteredChecks
            ?.filter((check) => selectedRowIds?.includes(`${check.id}-${check.role}`))
            ?.map((check) => check.amount!)
            ?.reduce((previousValue, currentValue) => previousValue + currentValue, 0),
    [filteredChecks, selectedRowIds],
  );

  const handleDepositManually = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    setLoading(true);
    setIsReviewAndConfirmLoading?.(true);
    await OrganizationStore.loadCompanies({ page: 1, per_page: 10 }, true, { search: " " }, organizationId);
    const formattedCheckIds = filteredChecks?.map((check) => check.id.split("-")[0]);
    if (actionInfo.action === "deposit" && selectedBankAccountId) {
      await UndepositedChecksStore.depositChecks(
        selectedBankAccountId,
        checksIds.map((item) => item.replace(/-recipient/g, "")),
      ).then(async (res) => {
        setResponse(res.DepositChecksMutation);
        setIsReviewAndConfirmLoading?.(false);
        setLoading(false);
        setOpenSelectBankAccountModal?.(false);
      });
    } else {
      await vChecksStore
        .checkAction(actionInfo.action, formattedCheckIds as string[], organizationId)
        .then(async (res: CheckActionMutation) => {
          setResponse(res.CheckActionMutation);
          setIsReviewAndConfirmLoading?.(false);
          setLoading(false);
          if (actionInfo.action === "cancel-deposit") {
            await UndepositedChecksStore.getUndepositedChecks({}, {}, true);
          }
        });
    }
  };

  const getStatusText = (action: string, lastWord: string = "") => {
    switch (action) {
      case "cancel-deposit":
        return "Cancelled";
      case "void":
        return "Voided";
      case "resend":
        return "Resent";
      case "deposit":
        return "Deposited";
      default:
        return getPastTense(lastWord);
    }
  };

  const statusText = getStatusText(actionInfo.action, lastWordOfLabel[lastWordOfLabel.length - 1]);

  return (
    <Paper
      onClick={(e) => {
        //TODO move review and confirm out of CheckAction, CheckOptions, VCheckTable Row, etc and then remove this workaround (sarma)
        e.stopPropagation();
      }}
    >
      {!actionSuccess ? (
        !loading || response?.errors?.length > 0 ? (
          <Grid padding="2px" width={700}>
            <Grid item>
              <Typography sx={{ background: "#F6F9FE", padding: "20px" }} fontWeight={500} fontSize={20}>
                {_.upperFirst(actionInfo.label)} {filteredChecks?.length} vCheck{checks?.length > 1 ? "s" : ""}
              </Typography>
            </Grid>

            <Grid sx={{ padding: "15px", maxHeight: "50vh", overflow: "auto" }} item>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Check #</TableCell>
                    <TableCell align="right">Account</TableCell>
                    <TableCell align="right">Recipient</TableCell>
                    <TableCell align="right">Amount</TableCell>
                    <TableCell align="right">Date</TableCell>
                    {errorsArray?.length > 0 ? (
                      <TableCell sx={{ color: "red" }} align="right">
                        Error
                      </TableCell>
                    ) : null}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredChecks?.map((row: any, index: number) => {
                    return (
                      <TableRow key={`${row.id}-${index}`} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                        <TableCell component="th" scope="row">
                          {row.number}
                        </TableCell>
                        <TableCell align="right">
                          <Typography
                            variant="body1"
                            whiteSpace="nowrap"
                            overflow="hidden"
                            textOverflow="ellipsis"
                            maxWidth="120px"
                            display="inline-block"
                            title={row.sender_title}
                          >
                            {row.sender_title}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography
                            variant="body1"
                            whiteSpace="nowrap"
                            overflow="hidden"
                            textOverflow="ellipsis"
                            maxWidth="120px"
                            display="inline-block"
                            title={row.recipient_title}
                          >
                            {row.recipient_title}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">{formatCurrency(row.amount)}</TableCell>
                        <TableCell align="right">{formatDateToDesign(row.date)}</TableCell>
                        {errorsArray?.length > 0 ? (
                          errorMessage(row.id) ? (
                            <TableCell sx={{ background: "#F9D6D5", padding: "10px" }} colSpan={5}>
                              {errorMessage(row.id)}
                            </TableCell>
                          ) : (
                            <TableCell sx={{ background: "#87ff69", padding: "10px" }} colSpan={5}>
                              Check signed
                            </TableCell>
                          )
                        ) : null}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              <Divider />
              <Grid container justifyContent={"flex-end"} sx={{ width: "100%", padding: "20px" }}>
                <Typography fontWeight="600">Total: {formatCurrency(totalAmount)}</Typography>
              </Grid>
            </Grid>
            <Grid sx={{ paddingTop: "0px", padding: "20px" }} xs={12} justifyContent={"space-between"} container item>
              <Grid xs={5} item>
                <Button
                  fullWidth
                  sx={{ padding: "10px 15px", color: "grey", fontSize: 15, textTransform: "capitalize" }}
                  color="info"
                  onClick={() => close()}
                >
                  Cancel
                </Button>
              </Grid>

              <Grid xs={5} item>
                {!errorsArray?.length ? (
                  <Button
                    fullWidth
                    sx={{ padding: "10px 15px", fontWeight: "600", fontSize: 16, textTransform: "capitalize" }}
                    color="secondary"
                    variant="contained"
                    onClick={handleDepositManually}
                  >
                    {_.upperFirst(actionInfo.label === "resend" ? "send reminder" : actionInfo.label)}
                  </Button>
                ) : (
                  <Button
                    fullWidth
                    sx={{ padding: "10px 15px", fontWeight: "600", fontSize: 16, textTransform: "capitalize" }}
                    color="secondary"
                    variant="contained"
                    onClick={async (e) => {
                      e.stopPropagation();
                      close();
                      await vChecksStore.loadChecks(organizationId, filters, pagination, true);
                    }}
                  >
                    OK
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Spinner />
        )
      ) : (
        <Grid container justifyContent={"center"} padding={12} paddingBottom={4} maxWidth={"30vw"} alignItems="center">
          <Grid alignItems={"center"} justifyContent="center" xs={12} container item>
            <CheckCircleOutlineIcon sx={{ color: "#61B54B", transform: "scale(3)", marginBottom: "10%" }} />
          </Grid>
          <Grid alignItems={"center"} justifyContent="center" container item xs={12}>
            <Typography fontSize={20} fontWeight={500} marginBottom="20px" color="#61B54B">
              {`${filteredChecks?.length} vCheck${numberOfChecksToBeHandled > 1 ? "s" : ""} ${statusText}`}
            </Typography>
          </Grid>

          <Grid marginTop={"20%"}>
            <Button
              variant="outlined"
              sx={{ textTransform: "capitalize", padding: "10px 15px" }}
              onClick={async (e) => {
                e?.stopPropagation();
                close();
                if (checksIds?.length === 1) {
                  //TODO refactor this
                  const checkId = checksIds?.[0]?.replace(/-sender/g, "");
                  if (actionInfo.action !== "cancel-deposit" && actionInfo.action !== "deposit") {
                    const check = checks[0];
                    const bankAccountId = check?.role === "sender" ? check?.sender?.id : check?.recipient?.id;

                    await vChecksStore.loadCheck(checkId, bankAccountId, true);
                    await vChecksStore.loadChecksStats(organizationId, true);
                  }
                  handleReloadChecks?.();
                  setSelectedRowIds?.([]);
                }

                if (setActionSuccessVChecks) {
                  setActionSuccessVChecks(false);
                }
                setActionSuccess(false);
                if (setSelectedRowIds) {
                  setSelectedRowIds([]);
                }
              }}
            >
              OK
            </Button>
          </Grid>
        </Grid>
      )}
    </Paper>
  );
};

export default observer(ReviewAndConfirm);
