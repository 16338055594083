import { Menu, MenuItem } from "@mui/material";
import { useVBillBatchLedgerQuickFilterUrl } from "components/pages/VBillBatchLedger/hooks";
import { VBillBatchLedgerChildRoutes } from "components/pages/VBillBatchLedger/utils";
import { useVBillCodingLedgerQuickFilterUrl } from "components/pages/VBillCodingLedger/hooks";
import { VBillCodingLedgerChildRoutes } from "components/pages/VBillCodingLedger/utils";
import { useVBillPayLedgerQuickFilterUrl } from "components/pages/VBillPayLedger/hooks";
import { VBillPayLedgerChildRoutes } from "components/pages/VBillPayLedger/utils";
import { observer } from "mobx-react";
import { useState } from "react";
import { useParams, useRouteMatch } from "react-router";
import {
  VBillBatchLedgerBasePath,
  VBillCodingLedgerBasePath,
  VBillPayLedgerBasePath,
  VBillsInactivePath,
} from "routes/routes";
import { useStore } from "storeContainer";
import { VBillOrgMenuItem } from "./VBillOrgMenuItem/VBillOrgMenuItem";

export const VBillOrgMenu = observer(() => {
  const organizationStore = useStore("OrganizationStore");
  const { organizationId } = useParams<{ organizationId: string }>();
  const isPayLedgerRoute = !!useRouteMatch(VBillPayLedgerBasePath);
  const isBatchLedgerRoute = !!useRouteMatch(VBillBatchLedgerBasePath);
  const isCodingLedgerRoute = !!useRouteMatch(VBillCodingLedgerBasePath);
  const isBillsInactiveRoute = !!useRouteMatch(VBillsInactivePath);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const vbillCodingLedgerUrl = useVBillCodingLedgerQuickFilterUrl(VBillCodingLedgerChildRoutes.ALL);
  const vbillPayLedgerUrl = useVBillPayLedgerQuickFilterUrl(VBillPayLedgerChildRoutes.ALL);
  const vbillBatchLedgerUrl = useVBillBatchLedgerQuickFilterUrl(VBillBatchLedgerChildRoutes.ALL);

  const { organizationDefaultDashboard } = organizationStore;
  const isVBillRoute = isPayLedgerRoute || isCodingLedgerRoute || isBatchLedgerRoute || isBillsInactiveRoute;
  const menuOpen = Boolean(menuAnchorEl);

  const totalEnabledCapabilities = [
    ...(organizationStore.organizationVBillCodingLedgerEnabled ? [""] : []),
    ...(organizationStore.organizationVBillPayLedgerEnabled ? [""] : []),
    ...(organizationStore.organizationVBillBatchLedgerEnabled ? [""] : []),
  ].length;

  if (!organizationDefaultDashboard.data?.vbill_enabled || totalEnabledCapabilities === 0) {
    return (
      <VBillOrgMenuItem to={`/org/${organizationId}/vbills-inactive`} active={isVBillRoute}>
        Bills
      </VBillOrgMenuItem>
    );
  }

  if (totalEnabledCapabilities === 1) {
    return (
      <VBillOrgMenuItem
        active={isVBillRoute}
        to={
          organizationStore.organizationVBillCodingLedgerEnabled
            ? vbillCodingLedgerUrl
            : organizationStore.organizationVBillPayLedgerEnabled
            ? vbillPayLedgerUrl
            : organizationStore.organizationVBillBatchLedgerEnabled
            ? vbillBatchLedgerUrl
            : ""
        }
      >
        {organizationStore.organizationVBillCodingLedgerEnabled
          ? "Bills Coding"
          : organizationStore.organizationVBillPayLedgerEnabled
          ? "Bills Pay"
          : organizationStore.organizationVBillBatchLedgerEnabled
          ? "Bills Batch"
          : ""}
      </VBillOrgMenuItem>
    );
  }

  if (totalEnabledCapabilities > 1) {
    return (
      <>
        <VBillOrgMenuItem
          onButtonClick={(event) => setMenuAnchorEl(event.currentTarget)}
          asButton
          menuOpen={menuOpen}
          active={isVBillRoute}
        >
          {isVBillRoute
            ? isPayLedgerRoute
              ? "Bills Pay"
              : isCodingLedgerRoute
              ? "Bills Coding"
              : isBatchLedgerRoute
              ? "Bills Batch"
              : "-"
            : "Bills"}
        </VBillOrgMenuItem>

        <Menu anchorEl={menuAnchorEl} open={menuOpen} onClose={() => setMenuAnchorEl(null)}>
          {organizationStore.organizationVBillCodingLedgerEnabled && (
            <MenuItem onClick={() => setMenuAnchorEl(null)} sx={{ padding: 0 }} selected={isCodingLedgerRoute}>
              <VBillOrgMenuItem to={vbillCodingLedgerUrl} active={isCodingLedgerRoute} insideMenu>
                Coding
              </VBillOrgMenuItem>
            </MenuItem>
          )}

          {organizationStore.organizationVBillPayLedgerEnabled && (
            <MenuItem onClick={() => setMenuAnchorEl(null)} sx={{ padding: 0 }} selected={isPayLedgerRoute}>
              <VBillOrgMenuItem to={vbillPayLedgerUrl} active={isPayLedgerRoute} insideMenu>
                Pay
              </VBillOrgMenuItem>
            </MenuItem>
          )}

          {organizationStore.organizationVBillBatchLedgerEnabled && (
            <MenuItem onClick={() => setMenuAnchorEl(null)} sx={{ padding: 0 }} selected={isBatchLedgerRoute}>
              <VBillOrgMenuItem to={vbillBatchLedgerUrl} active={isBatchLedgerRoute} insideMenu>
                Batch
              </VBillOrgMenuItem>
            </MenuItem>
          )}
        </Menu>
      </>
    );
  }

  return null;
});
