export const ChecksStatusData1 = [
  {
    title: "Pending Signature",
    body: "  Payment needs to be approved or signed off internally.",
  },
  {
    title: "Sent to recipient",
    body: "E-mail was sent to the vendor.",
  },
  {
    title: "Processing",
    body: "Payment was accepted or began auto-deposit (If recipient has set up an account and auto-deposit prior).",
  },
  {
    title: "Deposited",
    body: "Payment has been released for deposit (Sometimes It will say deposited, and only show up in vendor's account by EOD).",
  },
  {
    title: "Post-Dated",
    body: "Payment has been submitted but is post dated and cannot yet be deposited.",
  },
  {
    title: "Processing",
    body: "Payment was accepted or began auto-deposit (If recipient has set up an account and auto-deposit prior).",
  },

  {
    title: "Deposited",
    body: "vCheck has been released for deposit (Sometimes It will say deposited, and only show up in vendor's account by EOD).",
  },
];
export const ChecksStatusData2 = [
  {
    title: "Mailed",
    body: "Check has been mailed.",
  },
  {
    title: "Deposited",
    body: "Check has been received and deposited by the vendor.",
  },
  {
    title: "Payment Accepted",
    body: "Credit card was reavealed for processing.",
  },
  {
    title: "Payment Completed",
    body: "Credit card transaction was completed.",
  },
];
export const ChecksStatusData3 = [
  {
    body: "In order for a paper check to be mailed you must have a valid full mailing address set up in the vendor profile  in your accounting application.",
  },
  {
    body: "If you know your vendor ONLY wants paper checks, you can put 'print@cherrynet.com' in the vendor's email field to trigger auto-printing.",
  },
  {
    body: "If an email is listed in the vendors profile it will automatically trigger an email to be sent to accept a payment.",
  },
  {
    body: "Make sure your vendor knows to look out for an email, or ensure a phone number is listed so a Cherry Team Member can give them a Welcome Call.",
  },
  {
    body: "All vChecks and credit card payments deduct funds from YOUR bank account at the same time the payment is processed.",
  },
  {
    body: "With paper checks, the fund are deducted from YOUR account when we print and email out the payment, to ensure funds are available for deposit.",
  },
  {
    body: "Tracking information, status of delivery and deposit is listed within each vCheck transaction page.",
  },
];
export const ChecksStatusEmails = [
  {
    body: "customersupport@cherrynet.com",
  },
  {
    body: "vendorsupport@cherrynet.com",
  },
  {
    body: "techsupport@cherrynet.com",
  },
];
