import { FormHelperText, SxProps, Theme } from "@mui/material";

interface IGenericErrorMessageProps {
  error?: boolean;
  sx?: SxProps<Theme>;
}

export const GenericErrorMessage = ({ error = true, sx }: IGenericErrorMessageProps) => {
  return (
    <FormHelperText error={error} sx={sx}>
      An error occurred. Please try again later.
    </FormHelperText>
  );
};
