import { createTheme } from "@mui/material/styles";

export const COLORS = {
  cerulean: "#00B6E3",
  deepCerulean: "#007CA7",
  foam: "#D9F4FB",
  mercury: "#E5E5E5",
  dark: "#022E3D",
  selago: "#F6F9FE",
  white: "#FFFFFF",
  darkBlue: "#006B8F",
  darkGrey: "#4B5659",
  mediumGrey: "#707070",
  borderColor: "#CADBE2",
  darkestBlue: "#0C4A60",
  borderBlue: "#D6E5FC",
  black: "#000000",
  lightGrey: "#F9F9F9",
  darkText: "#022E3D",
  lightestGrey: "#DADADA",
  highLightBlue: "#F7FAFF",
  orange: "#DB8C15",
  foam25Opacity: "rgba(217, 244, 251,0.25)",
  error: "#900",
  darkRed: "#d10202",
  newPrimaryColor: "#052048",
  newSecondaryColor: "#2877EC",
  tableHeaderColor: "#F8F9F9",
  logoBlue: "#16458B",
  success: "#61B54B",
  organizationSelectorBackground: "#011431",
  textGray: "#697281",
  dividerGray: "#EFF3F9",
  secondaryBackground: "#E0EDFF",
  newBg: "#EBF4FF",
  stTropaz: "#235092",
  zumthor: "#EBF4FF",
  colorNewPrimary: "#052048",
  philippineSilver: "#b5b5b5",
  cosmicLatte: "#FFF9EB",
  ghostWhite: "#F6F9FE",
  azureWhite: "#D4DEED",
  inactiveBlue: "#94BBF6",
  hawkesBlue: "#E1EBFC",
  TopHeaderBg: "#EBF4FF",
  TopHeaderText: "#052048",
};

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: "#16458B",
      light: "#9D4EDD",
      dark: "#3700B3",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#2877EC",
      light: "#64FFDA",
      dark: "#00BFA5",
      contrastText: "#000000",
    },
    error: {
      main: "#B00020",
      light: "#E57373",
      dark: "#D32F2F",
      contrastText: "#FFFFFF",
    },
    warning: {
      main: "#FFC107",
      light: "#FFD54F",
      dark: "#FFA000",
      contrastText: "#000000",
    },
    info: {
      main: "#2196F3",
      light: "#64B5F6",
      dark: "#1976D2",
      contrastText: "#FFFFFF",
    },
    success: {
      main: "#4CAF50",
      light: "#81C784",
      dark: "#388E3C",
      contrastText: "#000000",
    },
    background: {
      default: "#F5F5F5",
      paper: "#FFFFFF",
    },
    text: {
      primary: "#052048",
      secondary: "#757575",
      disabled: "#BDBDBD",
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        disableFocusRipple: true,
        disableTouchRipple: true,
      },
      styleOverrides: {
        root: {
          borderRadius: "3px",
          fontFamily: "'Inter'",
          fontStyle: "normal",
          fontWeight: "400",
          fontSize: "14px",
          lineHeight: "17px",
          textTransform: "capitalize",
          "&:hover": {
            backgroundColor: "transparent",
          },
          height: "42px",
        },
        outlined: {
          border: "0.5px solid #DFE7F2 !important",
          color: "#697281",
          background: "white",
          "&:hover": {
            backgroundColor: "transparent",
          },
        },
        contained: {
          background: "#2877EC",
          color: "white",
          "&:hover": {
            backgroundColor: "#2877EC",
          },
        },
        text: {
          backgroundColor: "none",
          border: "none",
          boxShadow: "none",
          maxWidth: "200px",
          paddingRight: "15px",
        },

        sizeLarge: {
          height: "42px",
        },
      },
      variants: [
        {
          props: { variant: "containedFilters" },
          style: {
            border: "1.5px solid #E9EFF6",
            background: COLORS.TopHeaderBg,
            color: COLORS.TopHeaderText,
            "&:hover": {
              background: "#dcebfc",
            },
          },
        },
      ],
    },
    MuiChip: {
      variants: [
        {
          props: {
            variant: "awesome",
          },
          style: {
            backgroundColor: "rgb(239, 245, 254)",
            color: "white",
            height: "42px",
            borderRadius: "4px",
            outline: "0.1px solid #16458b",

            "& .MuiChip-icon": {
              fontSize: "2rem",
            },
            "& .MuiChip-label": {
              ".MuiTypography-root": {
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "17px",
                color: "#16458b",
                textTransform: "capitalize",
                cursor: "pointer",
              },
            },
          },
        },
        {
          props: {
            variant: "awesome-filled",
          },
          style: {
            backgroundColor: "white",
            color: "white",
            height: "42px",
            borderRadius: "4px",
            border: "none",
            boxShadow: "0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)",
            "& .MuiChip-icon": {
              fontSize: "2rem",
            },
            "& .MuiChip-label": {
              ".MuiTypography-root": {
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "17px",
                textTransform: "capitalize",
                cursor: "pointer",
              },
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          maxWidth: "237px",
          height: "38px",
          background: "#FFFFFF",
          border: "1px solid #052048",
          borderRadius: "20px",
          fontFamily: "'Inter'",
          fontStyle: "normal",
          fontWeight: "500",
          fontSize: "14px",
          lineHeight: "17px",
          color: "#052048",
          display: "flex",
          alignItems: "center",
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          width: "22px",
          height: "22px",
          "&:hover": {
            backgroundColor: "unset",
          },
          "& .MuiSvgIcon-root": {
            width: "22px",
            height: "22px",
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          height: "42px",
          background: "white",
        },
        input: {
          "&::placeholder-shown ": {
            color: "inherit",
            opacity: 0.85,
          },
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        multiline: {
          height: "100%",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          padding: "0px",
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: "16px",
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          padding: "20px",
        },
        avatar: {
          display: "flex",
          alignItems: "center",
          gap: "10px",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          background: "#FFFFFF",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.08)",
          borderRadius: "2px",
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: "0px 20px 20px 20px",
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          border: "0.5px solid #DFE7F2",
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          fontFamily: "'Inter'",
          fontStyle: "normal",
          fontWeight: "500",
          fontSize: "14px",
          lineHeight: "17px",
          color: "#16458B",
          opacity: "0.5",
          textTransform: "capitalize",
          zIndex: "1",
          border: "none",
          "&.Mui-selected": {
            position: "relative",
            backgroundColor: "transparent",
            color: "#16458B",
            opacity: "1",
            "&:hover": {
              backgroundColor: "#EFF5FE",
            },
            "&:before": {
              content: "''",
              width: "calc(100% - 6px)",
              height: "calc(100% - 6px)",
              position: "absolute",
              backgroundColor: "#EFF5FE",
              //This is a hack, but it's a CSS hack so that makes it wonderful
              zIndex: "-1",
              color: "#16458B",
            },
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          border: "0.5px solid #DFE7F2",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: "'Inter', sans-serif",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "12px",
          lineHeight: "15px",
          color: "rgba(5, 32, 72, 0.5)",
          marginBottom: "5px",
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: "white",
          textTransform: "uppercase",
          borderTop: "1px solid rgba(224, 224, 224, 1)",
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          backgroundColor: "white",
          "&.Mui-selected:hover": {
            backgroundColor: "#EBF4FF",
          },
          "&.Mui-selected": {
            backgroundColor: "#EBF4FF",
          },
          "&:hover": {
            backgroundColor: "#EBF4FF",
          },
          cursor: "pointer",
        },
      },
    },
  },
  typography: {
    h1: {
      fontFamily: "Manrope",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "34px",
      lineHeight: "41px",
      color: "#052048",
    },
    h2: {
      fontFamily: "'Inter'",
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "18px",
      lineHeight: "22px",
      display: "flex",
      alignItems: "center",
      color: "#16458B",
    },
    h3: {
      fontFamily: "'Inter'",
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "16px",
      lineHeight: "19px",
      display: "flex",
      alignItems: "center",
      color: "#697281",
    },
    h4: {
      fontFamily: "'Inter'",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "14px",
      lineHeight: "17px",
      color: "#2877EC",
    },
    h5: {
      fontFamily: "'Inter'",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "12px",
      lineHeight: "15px",
      color: "#697281",
    },
    body1: {
      fontFamily: "'Inter'",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "17px",
      color: "#052048",
    },
    body2: {
      fontFamily: "'Inter'",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "12px",
      lineHeight: "15px",
      color: "#697281",
    },
  },
});

export default defaultTheme;
