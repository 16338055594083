import styled from "@emotion/styled";
import { Tab, TabProps } from "@mui/material";

export const SidebarTabBtn = styled(Tab)<TabProps>(() => ({
  fontSize: "14px",
  padding: "12px 16px",
  minHeight: "auto",
}));

export const a11yProps = (index: number) => ({
  id: `batch-details-sidebar-tab-${index}`,
  "aria-controls": `batch-details-sidebar-tabpanel-${index}`,
});
