import AttachFileIcon from "@mui/icons-material/AttachFile";
import ClearIcon from "@mui/icons-material/Clear";
import CloseIcon from "@mui/icons-material/Close";
import { Box, FormHelperText, IconButton, Tooltip } from "@mui/material";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Spinner } from "components/common/Spinner/Spinner";
import { ChangeEvent, useEffect, useState } from "react";
import { COLORS } from "themes/default";
import { useStore } from "../../../../../storeContainer";
import styles from "./AddCommentModal.module.scss";
import { IAddCommentModalProps } from "./types";

const MAX_FILE_SIZE = 8 * 1024 * 1024; // 8MB

const AddCommentModal = ({ isModalOpened, checkId, modalClose, reload, context }: IAddCommentModalProps) => {
  const vChecksStore = useStore("VChecksStore");
  const [note, setNote] = useState<string>("");
  const [shared, setShared] = useState<boolean>();
  const [attachments, setAttachments] = useState<File[]>([]);
  const [feedback, setFeedBack] = useState<string>("");
  const [saving, setSaving] = useState<boolean>(false);
  const [exceededMaxLength, setExceededMaxLength] = useState(false);

  useEffect(() => {
    setFeedBack("");
  }, [isModalOpened]);

  const handleModalClose = () => {
    modalClose(false);
    setFeedBack("");
    setAttachments([]);
    setNote("");
    setExceededMaxLength(false);
  };

  const handleNoteChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const inputText = event.target.value;

    if (inputText.length >= 255) {
      setExceededMaxLength(true);
    } else {
      setExceededMaxLength(false);
      setNote(inputText.trimStart());
    }
  };

  const handleSave = async () => {
    setSaving(true);
    try {
      if ((note || shared || attachments.length) && !saving) {
        await vChecksStore.createCheckNote(context, checkId, note, shared, attachments);
        reload(true);
        handleModalClose();
      } else if (!saving) {
        setFeedBack("A comment or an attachment needs to be added!");
      }
    } catch (error) {
      console.error("Error saving the check note:", error);
      setFeedBack("An error occurred while saving the check note. Please try again.");
    }
    setSaving(false);
  };

  const handleUploadFiles = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target?.files?.length) {
      return;
    }

    const files = event.target.files;

    for (let index = 0; index < files.length; index++) {
      const file = files.item(index);

      if (file) {
        setAttachments((prev) => [...prev, file]);
      }
    }
  };

  const handleUploadFile = (event: ChangeEvent<HTMLInputElement>, index: number) => {
    if (!event.target?.files?.length) {
      return;
    }

    const file = event.target.files[0];

    setAttachments((prev) => prev.map((attachment, i) => (i === index ? file : attachment)));
  };

  const handleClearOneFile = (index: number) => {
    setAttachments((prev) => prev.filter((_, i) => i !== index));
  };

  const saveBtnDisabled =
    (!note.length && !attachments.length) ||
    attachments.some((attachment) => attachment.size > MAX_FILE_SIZE || attachment.type !== "application/pdf");

  return (
    <Modal
      open={isModalOpened}
      onClose={handleModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className={styles.commentContainer}>
        <div className={styles.header}>
          <span>Add Comment</span>
          <CloseIcon className={styles.closeIcon} onClick={handleModalClose} />
        </div>
        <TextField
          variant="outlined"
          placeholder="Add comment here"
          multiline
          minRows={3}
          maxRows={4}
          value={note || ""}
          onChange={handleNoteChange}
          inputProps={{
            maxLength: 255,
          }}
          error={exceededMaxLength}
        />

        {exceededMaxLength && <FormHelperText error>Maximum length exceeded: 255 characters</FormHelperText>}

        {attachments.length ? (
          attachments.map((attachment, index) => (
            <Box sx={{ margin: "5px 0" }}>
              <Button
                variant="outlined"
                startIcon={<AttachFileIcon />}
                component="label"
                sx={{ width: "100%" }}
                endIcon={
                  <Tooltip title="Clear file" arrow>
                    <IconButton onClick={() => handleClearOneFile(index)}>
                      <ClearIcon />
                    </IconButton>
                  </Tooltip>
                }
              >
                <input
                  hidden
                  multiple={false}
                  type="file"
                  onChange={(event) => handleUploadFile(event, index)}
                  accept="application/pdf"
                />
                <Tooltip title={attachment.name} arrow>
                  <Typography noWrap sx={{ maxWidth: "188px" }}>
                    {attachment.name}
                  </Typography>
                </Tooltip>
              </Button>

              {attachment.size > MAX_FILE_SIZE && (
                <Box sx={{ color: COLORS.darkRed, fontSize: "12px" }}>File Size should not exceed 8MB</Box>
              )}
              {attachment.type !== "application/pdf" && (
                <Box sx={{ color: COLORS.darkRed, fontSize: "12px" }}>File Type should be *.pdf</Box>
              )}
            </Box>
          ))
        ) : (
          <Button variant="outlined" startIcon={<AttachFileIcon />} component="label" sx={{ marginY: "15px" }}>
            <input hidden multiple type="file" onChange={handleUploadFiles} accept="application/pdf" />
            <Typography>Add attachments</Typography>
          </Button>
        )}

        <Typography sx={{ color: COLORS.darkRed, fontSize: "0.75rem", mt: 1 }}>
          PDF Files only (*.pdf) - Max File size 8MB
        </Typography>

        <div className={styles.checkboxContainer}>
          <Checkbox checked={shared || false} onChange={() => setShared(!shared)} />
          <Typography>Share with {context === 1 ? "sender" : "recipient"}</Typography>
        </div>
        <label style={{ color: "#b00000" }}>{feedback}</label>
        <div className={styles.buttonsContainer}>
          <Button onClick={handleModalClose} variant={"text"}>
            Cancel
          </Button>
          <Button variant={"contained"} disabled={saveBtnDisabled} onClick={handleSave}>
            {saving ? <Spinner /> : "Save"}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default AddCommentModal;
