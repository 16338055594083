import { LoadingButton } from "@mui/lab";
import { Box, Button, Divider, Tooltip, Typography } from "@mui/material";
import { formatCurrency } from "common/helpers/utils";
import { GenericErrorMessage } from "components/common/Errors/GenericErrorMessage";
import { useMemo } from "react";
import { useWatch } from "react-hook-form";
import { COLORS } from "themes/default";
import { IPayVBillsFormFields } from "../PayVBillsDialog";

interface IPayVBillsTotalAndSubmitProps {
  hasSubmitReqError: boolean;
  payBtnLoading: boolean;
  onCancelBtnClick: () => void;
}

export const PayVBillsTotalAndSubmit = ({
  onCancelBtnClick,
  payBtnLoading,
  hasSubmitReqError,
}: IPayVBillsTotalAndSubmitProps) => {
  const groupsWatcher = useWatch({ name: "groups" }) as IPayVBillsFormFields["groups"];
  const totalBillAmountsValue = useMemo(() => {
    const billAmounts = (groupsWatcher ?? []).reduce(
      (result, group) => (result = result + group.bills.reduce((res, bill) => (res = res + Number(bill.amount)), 0)),
      0,
    );
    return formatCurrency(billAmounts);
  }, [groupsWatcher]);

  return (
    <Box sx={{ position: "sticky", left: 0, right: 0, bottom: 0, zIndex: 1, backgroundColor: COLORS.white }}>
      <Divider />

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "15px",
          marginTop: "20px",
          paddingBottom: "15px",
        }}
      >
        <Tooltip title={`Total: ${totalBillAmountsValue}`} placement="top">
          <Typography sx={{ fontSize: "18px" }} noWrap>
            <strong>Total: </strong>
            {totalBillAmountsValue}
          </Typography>
        </Tooltip>

        <Box>
          {hasSubmitReqError && <GenericErrorMessage sx={{ marginBottom: "10px" }} />}

          <Box sx={{ display: "flex", justifyContent: "end", gap: "15px" }}>
            <LoadingButton loading={payBtnLoading} variant="contained" type="submit">
              Pay
            </LoadingButton>
            <Button variant="outlined" type="button" onClick={onCancelBtnClick}>
              Cancel
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
