import { Grid, Paper, Typography } from "@mui/material";
import React from "react";
import { COLORS } from "themes/default";
import CircleIcon from "@mui/icons-material/Circle";
const PortalStatusItemPage2: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <Grid flexDirection={"row"} flexWrap="nowrap" container>
      <Grid item marginBottom={4}>
        <Typography
          lineHeight={1.3}
          sx={{
            position: "relative",
            "&::after": {
              content: '""',
              position: "absolute",
              bottom: -3,
              left: 0,
              width: "100%",
              borderBottom: "2.3px solid #e2e8a0",
            },
          }}
          fontSize={20}
          color={"black"}
          fontWeight={400}
          display={"inline"}
        >
          {children}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default PortalStatusItemPage2;
