import { Dialog, IconButton, Popover, Tooltip } from "@mui/material";
import { CloseIcon } from "components/common/icons";
import {
  IVBillSuggestionsMultiSelectAutocompleteProps,
  VBillSuggestionsMultiSelectAutocomplete,
} from "./VBillSuggestionsMultiSelectAutocomplete";

export type TVBillSuggestionsMultiSelectAutocompleteConditionalComp =
  | { suggestionsComponent: "popover"; suggestionsPopoverAnchorEl?: HTMLElement }
  | { suggestionsComponent: "dialog"; suggestionsPopoverAnchorEl?: never };

export type IVBillSuggestionsMultiSelectAutocompleteCompProps =
  TVBillSuggestionsMultiSelectAutocompleteConditionalComp & IVBillSuggestionsMultiSelectAutocompleteProps;

export const VBillSuggestionsMultiSelectAutocompleteComp = ({
  suggestionsComponent,
  suggestionsPopoverAnchorEl,
  ...props
}: IVBillSuggestionsMultiSelectAutocompleteCompProps) => {
  if (suggestionsComponent === "dialog") {
    return (
      <Dialog
        onClose={props.onClose}
        open
        PaperProps={{
          sx: { padding: `${props.title ? "15px" : "30px"} 15px 15px 15px `, position: "relative", width: "350px" },
        }}
      >
        <CloseCompBtn onClose={props.onClose} />

        <VBillSuggestionsMultiSelectAutocomplete {...props} />
      </Dialog>
    );
  }

  if (suggestionsComponent === "popover") {
    return (
      <Popover
        open
        anchorEl={suggestionsPopoverAnchorEl}
        onClose={props.onClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        PaperProps={{
          sx: { padding: `${props.title ? "15px" : "30px"} 15px 15px 15px `, position: "relative", width: "350px" },
        }}
      >
        <CloseCompBtn onClose={props.onClose} />

        <VBillSuggestionsMultiSelectAutocomplete {...props} />
      </Popover>
    );
  }

  return null;
};

const CloseCompBtn = ({ onClose }: { onClose?: () => void }) => (
  <Tooltip arrow title="Close">
    <IconButton
      onClick={onClose ? onClose : undefined}
      sx={{ position: "absolute", top: "5px", right: "5px", zIndex: 1 }}
    >
      <CloseIcon sx={{ fontSize: "20px" }} />
    </IconButton>
  </Tooltip>
);
