import { useState } from "react";
import styles from "./Organization.module.scss";
import CloseIcon from "@mui/icons-material/Close";
import { Spinner } from "../../../common/Spinner/Spinner";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";

function useUsersColumns(handleRemoveFromGroup: Function, loading: boolean) {
  return [
    {
      Header: "Name",
      accessor: "name",
      Cell: ({ row }: any) => (
        <div className={styles.tableRow}>
          <div>{row.original.name || row.original.email}</div>
        </div>
      ),
    },
    {
      Header: "Email Address",
      accessor: "email",
    },
    {
      Header: "Status",
      accessor: "state",
      Cell: ({ row }: any) => {
        return (
          <div className={styles.tableRow}>
            <div>{row.original.state}</div>
          </div>
        );
      },
    },
    {
      Header: "",
      accessor: "vcard",
      disableSortBy: true,
      Cell: ({ row }: any) => {
        const [open, setOpen] = useState(false);

        const handleClickOpen = () => {
          setOpen(true);
        };

        const handleClose = () => {
          setOpen(false);
        };
        return (
          <div className={styles.tableRow}>
            {!loading ? <CloseIcon className={styles.closeIcon} onClick={handleClickOpen} /> : <Spinner />}
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                Are you sure you want to remove user {row.original.name} ?
              </DialogTitle>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={() => handleRemoveFromGroup(row.original.id)} autoFocus>
                  Remove
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        );
      },
    },
  ];
}

export { useUsersColumns };
