import { VBillActiveFilters } from "components/pages/common/VBill/VBillActiveFilters/VBillActiveFilters";
import { useBatchLedgerFilters } from "../hooks";

export const BatchActiveFilters = () => {
  const [batchLedgerFilters, setBatchLedgerFilters] = useBatchLedgerFilters();

  return (
    <VBillActiveFilters
      filters={{
        search: batchLedgerFilters.filters?.search,
        budget_from: batchLedgerFilters.filters?.budget_from,
        budget_to: batchLedgerFilters.filters?.budget_to,
        date: batchLedgerFilters.filters?.date_from,
        date_to: batchLedgerFilters.filters?.date_to,
        batchStatus: batchLedgerFilters.filters?.status,
      }}
      onFiltersChange={(values) =>
        setBatchLedgerFilters((prev) => ({
          filters: Object.keys(values).length
            ? {
                ...prev.filters,
                search: values.search,
                budget_from: values.budget_from,
                budget_to: values.budget_to,
                date_from: values.date,
                date_to: values.date_to,
                status: values.batchStatus,
              }
            : undefined,
          pagination: { page: 1, per_page: prev.pagination.per_page },
        }))
      }
      sx={{ margin: "0 0 10px" }}
    />
  );
};
