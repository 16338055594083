import { Box, SxProps, Theme } from "@mui/material";
import { FunctionComponent, ReactNode } from "react";
import { vBillChatPanels, vBillSidebarPanels } from "../SidebarTabs";

interface ITabPanelProps {
  children: ReactNode;
  panelName: vBillSidebarPanels | vBillChatPanels;
  activePannel: vBillSidebarPanels | vBillChatPanels;
  sx?: SxProps<Theme>;
}

export const TabPanel: FunctionComponent<ITabPanelProps> = ({ children, panelName, activePannel, sx }) => (
  <Box
    role="tabpanel"
    hidden={activePannel !== panelName}
    id={`invoice-tabpanel-${panelName}`}
    aria-labelledby={`invoice-tab-${panelName}`}
    sx={{ overflowY: "auto", paddingRight: "10px" }}
  >
    {activePannel === panelName && <Box sx={{ padding: "10px", ...sx }}>{children}</Box>}
  </Box>
);
