import { Box } from "@mui/material";
import { FunctionComponent, ReactNode } from "react";
import { vCheckChatPanels, vCheckSidebarPanels } from "../VCheckDetailsSidebar";

interface ISidebarTabPanelProps {
  children: ReactNode;
  panelName: vCheckChatPanels | vCheckSidebarPanels;
  activePannel: vCheckChatPanels | vCheckSidebarPanels;
}

export const SidebarTabPanel: FunctionComponent<ISidebarTabPanelProps> = ({ children, panelName, activePannel }) => (
  <div
    role="tabpanel"
    hidden={activePannel !== panelName}
    id={`vcheck-details-sidebar-tabpanel-${panelName}`}
    aria-labelledby={`vcheck-details-sidebar-tab-${panelName}`}
  >
    {activePannel === panelName && <Box>{children}</Box>}
  </div>
);
