import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Divider,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { graphqlChatClient } from "common/graphqlClient";
import { CheckBatchType, CheckFiltersType, GetCheckBatchesFiltersType, PaginationRequestType } from "generated/sdk";
import { getSdk, IChatChannelParentType } from "generated/sdk.chat";
import { isNil, orderBy } from "lodash";
import { observer } from "mobx-react";
import React, { useEffect, useMemo, useState } from "react";
import { NumericFormat } from "react-number-format";
import { useLocation, useParams } from "react-router";
import { useStore } from "storeContainer";
import { COLORS } from "themes/default";
import { JsonParam, useQueryParam, withDefault } from "use-query-params";
import { SidebarTabPanels, SidebarTabQSKey } from "./CheckBatchDetailsSidebar/SidebarTabs/SidebarTabs";

const NumberFormatCustom = (props: any) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.formattedValue,
          },
        });
      }}
      thousandSeparator
      isNumericString
      allowNegative={false}
      prefix="$"
      allowDecimal={true}
      decimalScale={2}
      fixedDecimalScale
      decimalSeparator="."
      format={(value: string) => new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(Number(value))}
    />
  );
};

const { ChatCreateChannel } = getSdk(graphqlChatClient);

const initialPaginationParam = withDefault(JsonParam, {
  per_page: 10,
  page: 1,
});

const AddBatchModal: React.FC<{
  selectedBatchToEdit?: CheckBatchType | null;
  setSelectedBatchToEdit?: React.Dispatch<React.SetStateAction<CheckBatchType | undefined>>;
  isAddBatchModalOpen: boolean;
  setIsAddBatchModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  ledgerFilters?: GetCheckBatchesFiltersType;
  batchFilters?: CheckFiltersType;
  reloadBatch?: boolean;
  reloadLedger?: boolean;
}> = ({
  isAddBatchModalOpen,
  setIsAddBatchModalOpen,
  selectedBatchToEdit,
  setSelectedBatchToEdit,
  ledgerFilters,
  batchFilters,
  reloadBatch,
  reloadLedger,
}) => {
  const [pagination, setPagination] = useQueryParam<PaginationRequestType>("pagination", initialPaginationParam);
  const [errorFeedback, setErrorFeedback] = useState("");
  const [inputBatchName, setInputBatchName] = useState(selectedBatchToEdit?.name || "");
  const [inputBudget, setInputBudget] = useState<string | number | null>(selectedBatchToEdit?.budget || null);
  const [selectedCompany, setSelectedCompany] = useState(selectedBatchToEdit?.company?.id || "");
  const BudgetManagementStore = useStore("BudgetManagementStore");
  const { createCheckBatch, fetchCheckBatches, fetchCheckBatch, updateCheckBatch, createdCheckBatch } =
    BudgetManagementStore;
  const [isLoading, setIsLoading] = useState(false);
  const sessionStore = useStore("SessionStore");
  const { acl } = sessionStore;
  const { organizationId } = useParams<{ organizationId: string }>();
  const companies = sessionStore.companiesOfOrganization(organizationId!);
  const aclCompanies = useMemo(() => companies.filter((company) => acl.canListCompany(company.id)), [companies]);

  // FIXME: this should be handled by be ==>
  const { pathname } = useLocation();
  // FIXME: this should be handled by be <==

  useEffect(() => {
    if (selectedBatchToEdit?.id) {
      setInputBatchName(selectedBatchToEdit?.name!);
      setInputBudget(selectedBatchToEdit?.budget!);
      setSelectedCompany(selectedBatchToEdit?.company?.id!);
    }
  }, [selectedBatchToEdit, setSelectedBatchToEdit]);
  const handleAddEditBatch = async () => {
    if (!selectedCompany) {
      setErrorFeedback("Please select a company.");
      return;
    }
    if (!inputBatchName) {
      setErrorFeedback("Please enter a batch name.");
      return;
    }
    if (inputBudget && !/^\d+(\.\d{0,2})?$/.test(inputBudget.toString())) {
      setErrorFeedback("Please enter a valid number with up to two decimal places for the budget.");
      return;
    }
    setErrorFeedback("");
    setIsLoading(true);
    const res = selectedBatchToEdit?.id
      ? await updateCheckBatch(selectedBatchToEdit.id, inputBatchName, parseFloat(inputBudget as string))
      : await createCheckBatch(selectedCompany, inputBatchName, parseFloat(inputBudget as string));
    if (res.error) {
      setErrorFeedback("An error occurred while processing your request.");
      setIsLoading(false);
      return;
    }
    // FIXME: this should be handled by be ==>
    if (isNil(selectedBatchToEdit?.id)) {
      const searchParams = new URLSearchParams();
      searchParams.set(SidebarTabQSKey, SidebarTabPanels.CHAT);
      // TODO:
      const canonicalUrl = `${pathname}/${
        createdCheckBatch.data?.CreateCheckBatchMutation?.id ?? ""
      }?${searchParams.toString()}`;

      await ChatCreateChannel({
        channelIo: {
          parentContext: "vCheckBatch_tab",
          parentKey: createdCheckBatch.data?.CreateCheckBatchMutation?.id ?? "",
          parentType: IChatChannelParentType.VcheckBatch,
          isManaged: true, // this hould be available on internal
          parentOrganizationId: organizationId ?? "",
        },
        membersIo: [],
      });
    }
    // FIXME: this should be handled by be <==

    if (reloadLedger) {
      await fetchCheckBatches(organizationId, ledgerFilters, { page: 1, per_page: pagination.per_page }, true);
    }

    if (reloadBatch && selectedBatchToEdit?.id) {
      await fetchCheckBatch(selectedBatchToEdit.id, batchFilters, { page: 1, per_page: pagination.per_page }, true);
    }

    setPagination((prev) => ({ ...prev, page: 1 }));
    setIsLoading(false);
    handleClose();
  };
  const handleClose = () => {
    setInputBatchName("");
    setInputBudget(null);
    setIsAddBatchModalOpen(false);
    setErrorFeedback("");
    setSelectedCompany("");
    setSelectedBatchToEdit?.(undefined);
  };

  const handleBudgetChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value && !/^\d+(\.\d{0,2})?$/.test(value)) {
      setErrorFeedback("Please enter a valid number with up to two decimal places for the budget.");
    } else {
      setErrorFeedback("");
    }
    setInputBudget(value);
  };

  const handleChangeCompany = (e: SelectChangeEvent<string>) => {
    setSelectedCompany(e.target.value);
  };

  const sortedAclCompanies = useMemo(() => orderBy(aclCompanies, ["name"], ["asc"]), [aclCompanies]);

  return (
    <Modal
      style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      onClose={handleClose}
      open={isAddBatchModalOpen}
    >
      <Paper sx={{ padding: "15px", width: 400, maxWidth: "90%" }}>
        <Grid>
          <Typography variant="h1" color={COLORS.darkestBlue}>
            {selectedBatchToEdit ? "Edit" : "Add"} Batch
          </Typography>
          <Grid marginTop={5} item>
            <Typography fontWeight={500} fontSize={14} marginBottom={1} color={COLORS.darkGrey}>
              Company
            </Typography>
          </Grid>
          <Grid item>
            <Select disabled={!!selectedBatchToEdit} value={selectedCompany} onChange={handleChangeCompany} fullWidth>
              {sortedAclCompanies.map((company) => (
                <MenuItem key={company.id} value={company.id}>
                  <Typography>{company.name}</Typography>
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid marginTop={3} item>
            <Typography fontWeight={500} fontSize={14} marginBottom={1} color={COLORS.darkGrey}>
              Batch Name
            </Typography>
          </Grid>
          <Grid item>
            <TextField
              inputProps={{
                style: { fontWeight: 900, fontSize: 16, color: COLORS.darkestBlue, height: "15px" },
              }}
              value={inputBatchName}
              onChange={(e) => {
                setInputBatchName(e.target.value);
              }}
              fullWidth
            />
          </Grid>
          <Grid marginTop={3} item>
            <Typography fontWeight={500} fontSize={14} marginBottom={1} color={COLORS.darkGrey}>
              Budget
            </Typography>
          </Grid>
          <Grid item>
            <TextField
              inputProps={{
                style: { fontWeight: 900, fontSize: 16, color: COLORS.darkestBlue, height: "15px" },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Typography sx={{ fontWeight: 600, fontSize: 15, color: COLORS.darkestBlue }}>$</Typography>
                  </InputAdornment>
                ),
              }}
              value={inputBudget}
              onChange={handleBudgetChange}
              fullWidth
            />
            {/* <TextField
              value={inputBudget}
              onChange={(event) => setInputBudget(event.target.value)}
              InputProps={{
                inputComponent: NumberFormatCustom as any,
                startAdornment: (
                  <InputAdornment position="start">
                    <Typography sx={{ fontWeight: 600, fontSize: 15, color: COLORS.darkestBlue }}>$</Typography>
                  </InputAdornment>
                ),
              }}
              fullWidth
            /> */}
          </Grid>
          <Typography sx={{ color: "red" }} textAlign={"center"}>
            {errorFeedback}
          </Typography>
          <Divider sx={{ marginTop: 4, marginBottom: 2 }} />

          <Grid justifyContent={"flex-end"} container item>
            <Button onClick={handleClose} sx={{ borderRadius: 0, paddingX: 3, paddingY: 1, marginRight: "10px" }}>
              Cancel
            </Button>

            <Box>
              <LoadingButton
                loading={isLoading}
                color="secondary"
                sx={{ borderRadius: 0, paddingX: 3, paddingY: 1 }}
                variant="contained"
                onClick={handleAddEditBatch}
              >
                Save
              </LoadingButton>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Modal>
  );
};

export default observer(AddBatchModal);
