import { LoadingButton } from "@mui/lab";

interface IQuickBooksBtnProps {
  loading?: boolean;
  mode?: "CONNECT" | "DISCONNECT";
  onClick?: () => void;
}

export const QuickBooksBtn = ({ loading, mode = "CONNECT", onClick }: IQuickBooksBtnProps) => (
  <LoadingButton
    loading={loading}
    variant="contained"
    onClick={onClick ? onClick : undefined}
    sx={{
      backgroundColor: "#2CA01C",
      fontWeight: 600,
      borderRadius: "5px",
      height: "36px",
      boxShadow: "none",
      padding: "6px 25px",
      textTransform: "none",
      "&:hover": { backgroundColor: "#1E900D", boxShadow: "none" },
    }}
  >
    {mode === "CONNECT" ? "Connect to" : "Disconnect from"} QuickBooks
  </LoadingButton>
);
