import { ReactNode } from "react";
import styles from "./ErrorContainer.module.scss";

interface IErrorContainerProps {
  title?: string;
  children: ReactNode;
}

export const ErrorContainer: React.FunctionComponent<IErrorContainerProps> = ({ title, children }) => {
  return (
    <div className={styles.error}>
      <div className={styles.title}>{title}</div>
      <div className={styles.body}>{children}</div>
    </div>
  );
};
