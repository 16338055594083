import { IVBillBillsFiltersInput, IVBillSortOrderType } from "generated/sdk.vbill";
import { useMemo } from "react";
import { usePayLedgerFilters, useVBillPayLedgerChildRoute } from "../hooks";

interface ITableHeadCell {
  id: keyof IVBillBillsFiltersInput | string;
  sortable?: boolean;
  label: string;
  value?: IVBillSortOrderType | null;
  onChange?: (value: IVBillSortOrderType) => void;
}

export function useTableHeadCells() {
  const [paymentLedgerFilters, setPayLedgerFilters] = usePayLedgerFilters();
  const { isAllChildRoute } = useVBillPayLedgerChildRoute();

  const tableHeadCells: ITableHeadCell[] = useMemo(
    () => [
      {
        id: "vbill_number_sort",
        sortable: true,
        label: "Bill #",
        value: paymentLedgerFilters.filters?.vbill_number_sort,
        onChange: (value) =>
          setPayLedgerFilters((prev) => ({
            filters: { ...prev.filters, amount_sort: undefined, date_due_sort: undefined, vbill_number_sort: value },
          })),
      },
      ...(isAllChildRoute
        ? [
            {
              id: "integration_status",
              label: "Status",
            },
          ]
        : []),
      {
        id: "company",
        sortable: false,
        label: "Company",
      },
      {
        id: "amount_sort",
        sortable: true,
        label: "Amount",
        value: paymentLedgerFilters.filters?.amount_sort,
        onChange: (value) =>
          setPayLedgerFilters((prev) => ({
            filters: { ...prev.filters, vbill_number_sort: undefined, date_due_sort: undefined, amount_sort: value },
          })),
      },
      {
        id: "payments",
        label: "Payments",
      },
      {
        id: "date_due_sort",
        sortable: true,
        label: "Due Date",
        value: paymentLedgerFilters.filters?.date_due_sort,
        onChange: (value) =>
          setPayLedgerFilters((prev) => ({
            filters: { ...prev.filters, vbill_number_sort: undefined, amount_sort: undefined, date_due_sort: value },
          })),
      },
      {
        id: "vendors",
        label: "Vendor",
      },
      {
        id: "properties",
        label: "Properties",
      },
    ],
    [
      paymentLedgerFilters.filters?.vbill_number_sort,
      paymentLedgerFilters.filters?.amount_sort,
      paymentLedgerFilters.filters?.date_due_sort,
      isAllChildRoute,
      setPayLedgerFilters,
    ],
  );

  return tableHeadCells;
}
