import { Box, Button, Tooltip } from "@mui/material";
import { getVBillFormattedDate } from "components/pages/common/VBill/utils";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useStore } from "storeContainer";
import { COLORS } from "themes/default";
import { blackBtnStyles } from "../summariesStyles";
import { SummariesDateDialog } from "./SummariesDateDialog/SummariesDateDialog";

export const SummariesDate = observer(function SummariesDate() {
  const vBillBatchDetailsStore = useStore("VBillBatchDetailsStore");
  const [isEditBatchDateDialogOpen, setIsEditBatchDateDialogOpen] = useState(false);

  const { batchDetails } = vBillBatchDetailsStore;
  const batchData = batchDetails.data;

  const handleDueDateChange = async (date?: string) => {
    if (!batchData) {
      return;
    }

    await vBillBatchDetailsStore.updateBatchDetails({
      batchId: batchData.id,
      batchData: {
        name: batchData.name,
        organizationId: batchData.organizationId,
        batchFilters: { ...batchData.batchFilters, date },
      },
    });
    setIsEditBatchDateDialogOpen(false);
  };

  return (
    <>
      <Tooltip title="Edit batch date" arrow>
        <Button onClick={() => setIsEditBatchDateDialogOpen(true)} sx={{ ...blackBtnStyles, textTransform: "none" }}>
          <Box sx={{ color: COLORS.logoBlue }}>Date:</Box>
          <strong> {getVBillFormattedDate(batchData?.batchFilters?.date)}</strong>
        </Button>
      </Tooltip>

      {isEditBatchDateDialogOpen && (
        <SummariesDateDialog
          date={batchData?.batchFilters?.date}
          onDateChange={handleDueDateChange}
          onCloseDialog={() => setIsEditBatchDateDialogOpen(false)}
        />
      )}
    </>
  );
});
