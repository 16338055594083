import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Typography from "@mui/material/Typography";
import { OrganizationUser, OrganizationUserEnum } from "generated/sdk";
import { useState } from "react";
import { definitelyFilter } from "generated/utils";
import { useStore } from "storeContainer";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import { Spinner } from "components/common/Spinner/Spinner";

export const MapUserModal: React.FunctionComponent<{
  signer: string;
  companyId: string;
  integrationId: string;
  closeModal: Function;
}> = ({ signer, companyId, integrationId, closeModal }) => {
  const [selectedUserId, setSelectedUserId] = useState<string>();
  const [loadingSaving, setLoadingSaving] = useState<Boolean>(false);
  const organizationUsersStore = useStore("OrganizationUsersStore");
  const integrationsStore = useStore("IntegrationsStore");
  const organizationUsers = definitelyFilter(organizationUsersStore.organizationUsersList.data).filter(
    (ou) => ou.state === OrganizationUserEnum.Active || ou.state === OrganizationUserEnum.Invited,
  ) as OrganizationUser[];

  //TODO extend the event type properly for select inputs
  const changeSelectedUser = (e: any) => {
    setSelectedUserId(e.target.value);
  };
  const saveAction = async (_e: React.MouseEvent<HTMLButtonElement>) => {
    setLoadingSaving(true);
    if (selectedUserId) {
      await integrationsStore.syncExternalAutoSigners(selectedUserId, integrationId, companyId, [signer]);
      closeModal();
    } else console.error("You need to select a user first!");
    setLoadingSaving(false);
  };
  return (
    <Grid item justifyContent={"center"} alignItems={"center"}>
      <Paper>
        <Grid sx={{ padding: 2, height: "500px", overflowY: "scroll" }}>
          <Grid width={450} container alignItems={"center"} justifyContent={"center"}>
            <Grid xs={12} item padding={2}>
              <Typography textAlign={"center"} fontSize={25}>
                User to map to Check Signer:{signer}
              </Typography>
            </Grid>
            <Grid paddingLeft={1} paddingBottom={0.5} xs={12} item>
              <Typography fontSize={12} marginTop={1} color="GrayText">
                Users:
              </Typography>
            </Grid>
          </Grid>
          <Grid>
            <FormControl sx={{ marginLeft: "15px" }} fullWidth>
              <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
                {organizationUsers.map((organizationUser: any) => {
                  return (
                    <>
                      <FormControlLabel
                        sx={{
                          border: "1px solid #CADBE2",
                          borderRadius: "5px",
                          padding: "5px 10px",
                          marginBottom: "5px",
                        }}
                        value={organizationUser?.id}
                        control={<Radio />}
                        onChange={changeSelectedUser}
                        label={
                          organizationUser?.account?.name ||
                          organizationUser?.account?.email + "*" + organizationUser?.account?.state
                        }
                      />
                    </>
                  );
                })}
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
        <Grid justifyContent={"space-around"} sx={{ marginTop: "10px", padding: "10px" }} container>
          <Link
            style={{ textDecoration: "none" }}
            to={`/org/${Cookies.get("lastOrg")}/company/${companyId}/integrations/${integrationId}`}
          >
            <Button sx={{ padding: "10px" }} variant="contained">
              Go To Mapping
            </Button>
          </Link>
          <Grid>
            <Button
              sx={{ padding: "10px 20px" }}
              onClick={() => {
                closeModal();
              }}
              variant="text"
            >
              Cancel
            </Button>
            {!loadingSaving ? (
              <Button sx={{ padding: "10px 20px", marginLeft: "30px" }} onClick={saveAction} variant="contained">
                Save
              </Button>
            ) : (
              <Button sx={{ padding: "10px 20px", marginLeft: "30px" }} variant="contained">
                <Spinner />
              </Button>
            )}
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};
