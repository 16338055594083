import { Box, SxProps, Theme, Tooltip } from "@mui/material";
import { ReactNode, useCallback, useMemo } from "react";
import { Link, useParams } from "react-router-dom";

export const defaultLinkStyles: SxProps<Theme> = {
  minHeight: "58px",
  textDecoration: "none",
  color: "inherit",
  padding: "5px 16px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  fontSize: "14px",
  fontWeight: "500",
};

interface IBatchRowLinkProps {
  batchId: number;
  hasAccess: boolean;
  children: ReactNode;
  sx?: SxProps<Theme>;
}

export const BatchRowLink = ({ batchId, hasAccess, children, sx }: IBatchRowLinkProps) => {
  const { organizationId } = useParams<{ organizationId: string }>();

  const linkToUrl = useMemo(
    () => (hasAccess ? `/org/${organizationId}/vbillbatch/${batchId}` : undefined),
    [batchId, hasAccess, organizationId],
  );
  const handleLinkClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      if (!hasAccess) {
        e.preventDefault();
      }
    },
    [hasAccess],
  );

  return (
    <Tooltip title={!hasAccess ? "No access" : undefined}>
      <Box
        component={Link}
        to={linkToUrl ?? ""}
        onClick={handleLinkClick}
        sx={{ ...defaultLinkStyles, ...sx, ...(!hasAccess ? { cursor: "default", opacity: ".5" } : {}) }}
      >
        {children}
      </Box>
    </Tooltip>
  );
};
