import { Box, Divider, Tooltip } from "@mui/material";
import {
  friendlyPaymentIntegrationStatusNames,
  friendlyPaymentPushStatusNames,
  friendlyPaymentStatusNames,
  getPaymentStatusStyleColor,
} from "components/pages/common/VBill/utils";
import { IVBillVBillGetBatchListQuery } from "generated/sdk.vbill";
import React, { useMemo } from "react";
import { COLORS } from "themes/default";

interface IPaymentGroupProps {
  payments: NonNullable<IVBillVBillGetBatchListQuery["getBatchList"]["data"]>["items"][number]["batchPayments"];
}

export const PaymentGroup = ({ payments }: IPaymentGroupProps) => {
  const paymentStatus = useMemo(() => friendlyPaymentStatusNames[payments[0].paymentStatus], [payments]);
  const paymentStatusColor = useMemo(() => getPaymentStatusStyleColor(payments[0].paymentStatus), [payments]);

  return (
    <Tooltip
      key={payments[0].paymentStatus}
      arrow
      title={
        <>
          {payments.map((payment, index) => (
            <Payment payment={payment} index={index} totalGroup={payments.length} />
          ))}
        </>
      }
    >
      <Box sx={{ marginBottom: "5px", color: paymentStatusColor }}>
        {payments.length} {paymentStatus}
      </Box>
    </Tooltip>
  );
};

interface IPaymentProps {
  payment: NonNullable<IVBillVBillGetBatchListQuery["getBatchList"]["data"]>["items"][number]["batchPayments"][number];
  index: number;
  totalGroup: number;
}

const Payment = ({ payment, index, totalGroup }: IPaymentProps) => {
  const paymentIntStatus = useMemo(
    () => friendlyPaymentIntegrationStatusNames[payment.paymentIntegrationStatus],
    [payment.paymentIntegrationStatus],
  );
  const paymentPushStatus = useMemo(
    () => friendlyPaymentPushStatusNames[payment.paymentPushStatus],
    [payment.paymentPushStatus],
  );

  return (
    <Box key={payment.paymentId}>
      paymentId: {payment.paymentId}, batchId: {payment.batchId}, invoiceId: {payment.invoiceId},
      paymentIntegrationStatus: {paymentIntStatus}, paymentPushStatus: {paymentPushStatus},{" "}
      {payment.paymentErrors.length ? `paymentErrors: ${JSON.stringify(payment.paymentErrors, null, 2)}` : ""}
      {totalGroup - 1 !== index && <Divider sx={{ borderColor: COLORS.white, margin: "5px 0" }} />}
    </Box>
  );
};
