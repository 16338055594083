import { Box } from "@mui/material";
import { CheckFiltersProvider } from "common/hooks/useCheckFilters";
import { FeatureAccessRequest } from "components/widgets/FeatureAccessRequest";
import { observer } from "mobx-react";
import { useStore } from "storeContainer";
import CheckBatchDetails from "./CheckBatchDetails";

export const CheckBatchDetailsRoute = observer(function CheckBatchDetailsRoute() {
  const organizationStore = useStore("OrganizationStore");

  const isCheckBatchingEnabled = organizationStore.organizationDefaultDashboard.data?.check_batching_enabled;

  if (isCheckBatchingEnabled) {
    return (
      <CheckFiltersProvider prefix="budgetManagement">
        <CheckBatchDetails />
      </CheckFiltersProvider>
    );
  }

  return (
    <Box sx={{ width: "100%", maxWidth: "1400px", paddingTop: "40px" }}>
      <FeatureAccessRequest />
    </Box>
  );
});
