import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import LanguageIcon from "@mui/icons-material/Language";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import SyncIcon from "@mui/icons-material/Sync";
import { Box, Chip, Skeleton, Typography } from "@mui/material";
import { formatCurrency } from "common/helpers/utils";
import { vBillQuickFilterCircleSx } from "components/pages/common/VBill/styles";
import { IVBillBatchStatus } from "generated/sdk.vbill";
import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { useStore } from "storeContainer";
import { useVBillBatchLedgerChildRoute, useVBillBatchLedgerQuickFilterUrl } from "../hooks";
import { VBillBatchLedgerChildRoutes } from "../utils";

export const BatchQuickFilters = observer(function BatchQuickFilters() {
  const vBillBatchLedgerStore = useStore("VBillBatchLedgerStore");
  const { isAllChildRoute, isOpenChildRoute, isClosedChildRoute, isDeletedChildRoute } =
    useVBillBatchLedgerChildRoute();
  const allRouteUrl = useVBillBatchLedgerQuickFilterUrl(VBillBatchLedgerChildRoutes.ALL);
  const openRouteUrl = useVBillBatchLedgerQuickFilterUrl(VBillBatchLedgerChildRoutes.OPEN);
  const closedRouteUrl = useVBillBatchLedgerQuickFilterUrl(VBillBatchLedgerChildRoutes.CLOSED);
  const deletedRouteUrl = useVBillBatchLedgerQuickFilterUrl(VBillBatchLedgerChildRoutes.DELETED);

  const { batchStats } = vBillBatchLedgerStore;
  const batchStatsData = batchStats.data;

  const allStats = useMemo(() => batchStatsData?.generalStats, [batchStatsData?.generalStats]);
  const openStats = useMemo(
    () => batchStatsData?.groupStats.find((group) => group.value?.BATCH_STATUS === IVBillBatchStatus.Open),
    [batchStatsData?.groupStats],
  );
  const closedStats = useMemo(
    () => batchStatsData?.groupStats.find((group) => group.value?.BATCH_STATUS === IVBillBatchStatus.Closed),
    [batchStatsData?.groupStats],
  );
  const deletedStats = useMemo(
    () => batchStatsData?.groupStats.find((group) => group.value?.BATCH_STATUS === IVBillBatchStatus.Deleted),
    [batchStatsData?.groupStats],
  );

  return (
    <Box
      sx={{
        display: "flex",
        gap: "10px",
        padding: "20px 0 10px",
      }}
    >
      <Chip
        label={
          <div>
            {vBillBatchLedgerStore.batchStats.isLoading ? (
              <Skeleton variant="rounded" sx={{ height: "22px", width: "100px" }} />
            ) : (
              <>
                <Typography>All ({allStats?.count ?? "0"})</Typography>
                <Typography>{formatCurrency(Number(allStats?.amount ?? 0))}</Typography>
              </>
            )}
          </div>
        }
        clickable
        component={Link}
        to={allRouteUrl}
        icon={
          <Box
            sx={{
              ...vBillQuickFilterCircleSx,
              backgroundColor: "#B5ECA6",
              border: `1px solid #6FD055`,
            }}
          >
            <LanguageIcon sx={{ color: "#6FD055" }} />
          </Box>
        }
        variant={isAllChildRoute ? "awesome" : "awesome-filled"}
        sx={{ flex: 1, maxWidth: "none" }}
      />

      <Chip
        label={
          <div>
            {vBillBatchLedgerStore.batchStats.isLoading ? (
              <Skeleton variant="rounded" sx={{ height: "22px", width: "100px" }} />
            ) : (
              <>
                <Typography>Open ({openStats?.count ?? "0"})</Typography>
                <Typography>{formatCurrency(Number(openStats?.amount ?? 0))}</Typography>
              </>
            )}
          </div>
        }
        clickable
        component={Link}
        to={openRouteUrl}
        icon={
          <Box
            sx={{
              ...vBillQuickFilterCircleSx,
              backgroundColor: Number(openStats?.count ?? 0) > 0 ? "#FFF3E1" : "#B5ECA6",
              border: `1px solid ${Number(openStats?.count ?? 0) > 0 ? "#ECBB71" : "#6FD055"}`,
            }}
          >
            <CalendarMonthIcon sx={{ color: Number(openStats?.count ?? 0) > 0 ? "#ECBB71" : "#6FD055" }} />
          </Box>
        }
        variant={isOpenChildRoute ? "awesome" : "awesome-filled"}
        sx={{ flex: 1, maxWidth: "none" }}
      />

      <Chip
        label={
          <div>
            {vBillBatchLedgerStore.batchStats.isLoading ? (
              <Skeleton variant="rounded" sx={{ height: "22px", width: "100px" }} />
            ) : (
              <>
                <Typography>Closed ({closedStats?.count ?? "0"})</Typography>
                <Typography>{formatCurrency(Number(closedStats?.amount ?? 0))}</Typography>
              </>
            )}
          </div>
        }
        clickable
        component={Link}
        to={closedRouteUrl}
        icon={
          <Box
            sx={{
              ...vBillQuickFilterCircleSx,
              backgroundColor: Number(closedStats?.count ?? 0) > 0 ? "#FFF3E1" : "#B5ECA6",
              border: `1px solid ${Number(closedStats?.count ?? 0) > 0 ? "#ECBB71" : "#6FD055"}`,
            }}
          >
            <SyncIcon sx={{ color: Number(closedStats?.count ?? 0) > 0 ? "#ECBB71" : "#6FD055" }} />
          </Box>
        }
        variant={isClosedChildRoute ? "awesome" : "awesome-filled"}
        sx={{ flex: 1, maxWidth: "none" }}
      />

      <Chip
        label={
          <div>
            {vBillBatchLedgerStore.batchStats.isLoading ? (
              <Skeleton variant="rounded" sx={{ height: "22px", width: "100px" }} />
            ) : (
              <>
                <Typography>Deleted ({deletedStats?.count ?? "0"})</Typography>
                <Typography>{formatCurrency(Number(deletedStats?.amount ?? 0))}</Typography>
              </>
            )}
          </div>
        }
        clickable
        component={Link}
        to={deletedRouteUrl}
        icon={
          <Box
            sx={{
              ...vBillQuickFilterCircleSx,
              backgroundColor: Number(deletedStats?.count ?? 0) > 0 ? "#FFF3E1" : "#B5ECA6",
              border: `1px solid ${Number(deletedStats?.count ?? 0) > 0 ? "#ECBB71" : "#6FD055"}`,
            }}
          >
            <PriorityHighIcon sx={{ color: Number(deletedStats?.count ?? 0) > 0 ? "#ECBB71" : "#6FD055" }} />
          </Box>
        }
        variant={isDeletedChildRoute ? "awesome" : "awesome-filled"}
        sx={{ flex: 1, maxWidth: "none" }}
      />
    </Box>
  );
});
