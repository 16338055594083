import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import DoneIcon from "@mui/icons-material/Done";
import LanguageIcon from "@mui/icons-material/Language";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import SyncIcon from "@mui/icons-material/Sync";
import { Box, Chip, Skeleton, Typography } from "@mui/material";
import { formatCurrency } from "common/helpers/utils";
import { vBillQuickFilterCircleSx } from "components/pages/common/VBill/styles";
import { observer } from "mobx-react";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { useStore } from "storeContainer";
import { useVBillCodingLedgerChildRoute, useVBillCodingLedgerQuickFilterUrl } from "../hooks";
import { VBillCodingLedgerChildRoutes } from "../utils";

export const CodingQuickFilters = observer(function CodingQuickFilters() {
  const vBillCodingLedgerStore = useStore("VBillCodingLedgerStore");
  const {
    isAllChildRoute,
    isPendingReviewChildRoute,
    isCodingChildRoute,
    isApprovalsChildRoute,
    isActionRequiredChildRoute,
    isCompletedChildRoute,
  } = useVBillCodingLedgerChildRoute();
  const allRouteUrl = useVBillCodingLedgerQuickFilterUrl(VBillCodingLedgerChildRoutes.ALL);
  const pendingReviewRouteUrl = useVBillCodingLedgerQuickFilterUrl(VBillCodingLedgerChildRoutes.PENDING_REVIEW);
  const codingRouteUrl = useVBillCodingLedgerQuickFilterUrl(VBillCodingLedgerChildRoutes.CODING);
  const approvalsRouteUrl = useVBillCodingLedgerQuickFilterUrl(VBillCodingLedgerChildRoutes.APPROVALS);
  const actionsRequiredRouteUrl = useVBillCodingLedgerQuickFilterUrl(VBillCodingLedgerChildRoutes.ACTIONS_REQUIRED);
  const completedRouteUrl = useVBillCodingLedgerQuickFilterUrl(VBillCodingLedgerChildRoutes.COMPLETED);

  const allStats = useMemo(
    () => vBillCodingLedgerStore.invoicesStats.data?.statusStats?.QF_ALL,
    [vBillCodingLedgerStore.invoicesStats.data?.statusStats?.QF_ALL],
  );
  const pendingReviewStats = useMemo(
    () => vBillCodingLedgerStore.invoicesStats.data?.statusStats?.QF_PENDING_REVIEW,
    [vBillCodingLedgerStore.invoicesStats.data?.statusStats?.QF_PENDING_REVIEW],
  );
  const codingReviewStats = useMemo(
    () => vBillCodingLedgerStore.invoicesStats.data?.statusStats?.QF_CODING,
    [vBillCodingLedgerStore.invoicesStats.data?.statusStats?.QF_CODING],
  );
  const approvalsReviewStats = useMemo(
    () => vBillCodingLedgerStore.invoicesStats.data?.statusStats?.QF_APPROVAL,
    [vBillCodingLedgerStore.invoicesStats.data?.statusStats?.QF_APPROVAL],
  );
  const actionsRequiredStats = useMemo(
    () => vBillCodingLedgerStore.invoicesStats.data?.statusStats?.QF_ACTION_REQUIRED,
    [vBillCodingLedgerStore.invoicesStats.data?.statusStats?.QF_ACTION_REQUIRED],
  );
  const completedStats = useMemo(
    () => vBillCodingLedgerStore.invoicesStats.data?.statusStats?.QF_COMPLETED,
    [vBillCodingLedgerStore.invoicesStats.data?.statusStats?.QF_COMPLETED],
  );

  return (
    <Box
      sx={{
        display: "flex",
        gap: "10px",
        padding: "20px 0 10px",
      }}
    >
      <Chip
        label={
          <div>
            {vBillCodingLedgerStore.invoicesStats.isLoading ? (
              <Skeleton variant="rounded" sx={{ height: "22px", width: "100px" }} />
            ) : (
              <>
                <Typography>All ({allStats?.count ?? 0})</Typography>
                <Typography>{formatCurrency(Number(allStats?.amount ?? 0))}</Typography>
              </>
            )}
          </div>
        }
        clickable
        component={Link}
        to={allRouteUrl}
        icon={
          <Box
            sx={{
              ...vBillQuickFilterCircleSx,
              backgroundColor: "#B5ECA6",
              border: `1px solid #6FD055`,
            }}
          >
            <LanguageIcon sx={{ color: "#6FD055" }} />
          </Box>
        }
        variant={isAllChildRoute ? "awesome" : "awesome-filled"}
        sx={{ flex: 1, maxWidth: "none" }}
      />

      <Chip
        label={
          <div>
            {vBillCodingLedgerStore.invoicesStats.isLoading ? (
              <Skeleton variant="rounded" sx={{ height: "22px", width: "100px" }} />
            ) : (
              <>
                <Typography>Pending Review ({pendingReviewStats?.count ?? 0})</Typography>
                <Typography>{formatCurrency(Number(pendingReviewStats?.amount ?? 0))}</Typography>
              </>
            )}
          </div>
        }
        clickable
        component={Link}
        to={pendingReviewRouteUrl}
        icon={
          <Box
            sx={{
              ...vBillQuickFilterCircleSx,
              backgroundColor: (pendingReviewStats?.count ?? 0) > 0 ? "#FFF3E1" : "#B5ECA6",
              border: `1px solid ${(pendingReviewStats?.count ?? 0) > 0 ? "#ECBB71" : "#6FD055"}`,
            }}
          >
            <CalendarMonthIcon sx={{ color: (pendingReviewStats?.count ?? 0) > 0 ? "#ECBB71" : "#6FD055" }} />
          </Box>
        }
        variant={isPendingReviewChildRoute ? "awesome" : "awesome-filled"}
        sx={{ flex: 1, maxWidth: "none" }}
      />

      <Chip
        label={
          <div>
            {vBillCodingLedgerStore.invoicesStats.isLoading ? (
              <Skeleton variant="rounded" sx={{ height: "22px", width: "100px" }} />
            ) : (
              <>
                <Typography>Coding ({codingReviewStats?.count ?? 0})</Typography>
                <Typography>{formatCurrency(Number(codingReviewStats?.amount ?? 0))}</Typography>
              </>
            )}
          </div>
        }
        clickable
        component={Link}
        to={codingRouteUrl}
        icon={
          <Box
            sx={{
              ...vBillQuickFilterCircleSx,
              backgroundColor: (codingReviewStats?.count ?? 0) > 0 ? "#FFF3E1" : "#B5ECA6",
              border: `1px solid ${(codingReviewStats?.count ?? 0) > 0 ? "#ECBB71" : "#6FD055"}`,
            }}
          >
            <SyncIcon sx={{ color: (codingReviewStats?.count ?? 0) > 0 ? "#ECBB71" : "#6FD055" }} />
          </Box>
        }
        variant={isCodingChildRoute ? "awesome" : "awesome-filled"}
        sx={{ flex: 1, maxWidth: "none" }}
      />

      <Chip
        label={
          <div>
            {vBillCodingLedgerStore.invoicesStats.isLoading ? (
              <Skeleton variant="rounded" sx={{ height: "22px", width: "100px" }} />
            ) : (
              <>
                <Typography>Approvals ({approvalsReviewStats?.count ?? 0})</Typography>
                <Typography>{formatCurrency(Number(approvalsReviewStats?.amount ?? 0))}</Typography>
              </>
            )}
          </div>
        }
        clickable
        component={Link}
        to={approvalsRouteUrl}
        icon={
          <Box
            sx={{
              ...vBillQuickFilterCircleSx,
              backgroundColor: (approvalsReviewStats?.count ?? 0) > 0 ? "#FFF3E1" : "#B5ECA6",
              border: `1px solid ${(approvalsReviewStats?.count ?? 0) > 0 ? "#ECBB71" : "#6FD055"}`,
            }}
          >
            <ContactSupportIcon sx={{ color: (approvalsReviewStats?.count ?? 0) > 0 ? "#ECBB71" : "#6FD055" }} />
          </Box>
        }
        variant={isApprovalsChildRoute ? "awesome" : "awesome-filled"}
        sx={{ flex: 1, maxWidth: "none" }}
      />

      <Chip
        label={
          <div>
            {vBillCodingLedgerStore.invoicesStats.isLoading ? (
              <Skeleton variant="rounded" sx={{ height: "22px", width: "100px" }} />
            ) : (
              <>
                <Typography>Actions Required ({actionsRequiredStats?.count ?? 0})</Typography>
                <Typography>{formatCurrency(Number(actionsRequiredStats?.amount ?? 0))}</Typography>
              </>
            )}
          </div>
        }
        clickable
        component={Link}
        to={actionsRequiredRouteUrl}
        icon={
          <Box
            sx={{
              ...vBillQuickFilterCircleSx,
              backgroundColor: (actionsRequiredStats?.count ?? 0) > 0 ? "#FFF3E1" : "#B5ECA6",
              border: `1px solid ${(actionsRequiredStats?.count ?? 0) > 0 ? "#ECBB71" : "#6FD055"}`,
            }}
          >
            <PriorityHighIcon sx={{ color: (actionsRequiredStats?.count ?? 0) > 0 ? "#ECBB71" : "#6FD055" }} />
          </Box>
        }
        variant={isActionRequiredChildRoute ? "awesome" : "awesome-filled"}
        sx={{ flex: 1, maxWidth: "none" }}
      />

      <Chip
        label={
          <div>
            {vBillCodingLedgerStore.invoicesStats.isLoading ? (
              <Skeleton variant="rounded" sx={{ height: "22px", width: "100px" }} />
            ) : (
              <>
                <Typography>Completed ({completedStats?.count ?? 0})</Typography>
                <Typography>{formatCurrency(Number(completedStats?.amount ?? 0))}</Typography>
              </>
            )}
          </div>
        }
        clickable
        component={Link}
        to={completedRouteUrl}
        icon={
          <Box
            sx={{
              ...vBillQuickFilterCircleSx,
              backgroundColor: (completedStats?.count ?? 0) > 0 ? "#FFF3E1" : "#B5ECA6",
              border: `1px solid ${(completedStats?.count ?? 0) > 0 ? "#ECBB71" : "#6FD055"}`,
            }}
          >
            <DoneIcon sx={{ color: (completedStats?.count ?? 0) > 0 ? "#ECBB71" : "#6FD055" }} />
          </Box>
        }
        variant={isCompletedChildRoute ? "awesome" : "awesome-filled"}
        sx={{ flex: 1, maxWidth: "none" }}
      />
    </Box>
  );
});
