import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { SummariesCompaniesDialog } from "components/pages/VBillBatchDetails/BatchSummaries/SummariesCompanies/SummariesCompaniesDialog/SummariesCompaniesDialog";
import { sortBy } from "lodash";
import { observer } from "mobx-react";
import { useMemo, useState } from "react";
import { useStore } from "storeContainer";
import { labelTextStyles } from "../VBillAddFullBatchDialog";

interface IAddFullBatchCompaniesProps {
  companyIds?: string[] | null;
  onCompaniesSubmit: (companyIds: string[]) => void;
}

export const AddFullBatchCompanies = observer(function AddFullBatchCompanies({
  companyIds,
  onCompaniesSubmit,
}: IAddFullBatchCompaniesProps) {
  const organizationStore = useStore("OrganizationStore");
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const companyNames = useMemo(
    () =>
      sortBy(
        (companyIds ?? []).map(
          (compId) =>
            (organizationStore.organizationVBillEnabledCompanies.data?.items ?? []).find(
              ({ companyId }) => companyId === compId,
            )?.name,
        ),
        (name) => name,
      ),
    [companyIds, organizationStore.organizationVBillEnabledCompanies.data?.items],
  );
  const totalBatchCompanies = (companyIds ?? []).length;

  const handleCompaniesDialogSave = async (companyIds: string[]) => {
    setIsDialogOpen(false);
    onCompaniesSubmit(companyIds);
  };

  return (
    <Box sx={{ marginBottom: "15px" }}>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <Typography sx={labelTextStyles}>
          Companies{" "}
          <Box component="sup" sx={{ fontSize: "10px" }}>
            ({totalBatchCompanies})
          </Box>
        </Typography>

        <Tooltip title={totalBatchCompanies < 1 ? "Add companies" : "Edit companies"} arrow>
          <IconButton onClick={() => setIsDialogOpen(true)}>
            {totalBatchCompanies < 1 ? <AddCircleOutlineIcon /> : <EditIcon />}
          </IconButton>
        </Tooltip>
      </Box>
      {totalBatchCompanies > 0 && (
        <Typography>
          Selected:{" "}
          {companyNames.map((name, index) => (
            <span key={name}>
              {name}
              {companyNames.length - 1 > index && ", "}
            </span>
          ))}
        </Typography>
      )}

      {isDialogOpen && (
        <SummariesCompaniesDialog
          onDialogClose={() => setIsDialogOpen(false)}
          selectedCompanies={companyIds ?? []}
          onCompaniesSave={handleCompaniesDialogSave}
        />
      )}
    </Box>
  );
});
