import { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { useStore } from "storeContainer";
import { InputLabel } from "@mui/material";

export const AclPermLimitSimpleMaxValueInput = ({
  value,
  onChange,
  user_id,
  outlined,
  orgDirectPermissions,
}: {
  value: number;
  onChange?: (value: number) => void;
  user_id?: string;
  outlined?: true;
  orgDirectPermissions?: any;
}) => {
  const infValue = -1;
  const infValue2 = 9999999999.99;
  const getValueAsString = (value: number) =>
    `${infValue === Math.max(value, -1) || value === infValue2 ? "" : Math.max(value, -1)}`;
  const [newValue, setNewValue] = useState<string>(getValueAsString(value));
  const [changed, setChanged] = useState(false);
  const getRealNewValue = () => Math.abs(+newValue) || -1;
  const sessionStore = useStore("SessionStore");
  // const originalValue = `${infValue === Math.max(value, -1) ? "No limit" : Math.max(value, -1)}`;
  useEffect(() => {
    const realNewValue = getRealNewValue();
    if (realNewValue !== value) {
      setChanged(true);
    } else {
      setChanged(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newValue, value]);

  //Test
  const formatValueForDecimals = (e: any) => {
    let value: string = e.target.value;
    if (value.split(".")[1]?.length > 2) {
      value = value.substring(0, value.length - 1);
    }
    setNewValue(value);
  };
  const formatOnUnfocus = (_e: any) => {
    if (!newValue.includes(".") && newValue !== "") setNewValue(newValue + ".00");
    if (newValue.includes(".")) {
      const split = newValue.split(".");
      if (split[1].length === 1) {
        setNewValue(newValue + "0");
      }
    }
  };

  useEffect(() => {
    formatOnUnfocus("");
  }, []);
  return (
    <Box sx={{ display: "flex", ml: 0 }}>
      <Box display="flex" flexDirection="column">
        <InputLabel htmlFor="max-value-input">{+newValue ? `Up to` : "No limit"}</InputLabel>
        <TextField
          disabled={
            !onChange ||
            (sessionStore.selectedOrganizationUserId === user_id &&
              !!!orgDirectPermissions["ORGANIZATION_COMPANY_MANAGER"])
          }
          sx={{}}
          id={"max-value-input"}
          placeholder={"Enter amount ..."}
          type="number"
          name="max-value-input"
          value={newValue || ""}
          variant={!onChange ? "standard" : "outlined"}
          inputProps={{
            maxLength: 13,
            step: "1",
            readOnly: !onChange,
            style: {
              cursor: !onChange ? "pointer" : undefined,
            },
            startAdornment: +newValue ? <InputAdornment position="start">$</InputAdornment> : null,
            disableUnderline: true, // <== added this
          }}
          InputProps={
            {
              disableUnderline: !onChange,
            } as any
          }
          InputLabelProps={{
            style: { color: "black" },
          }}
          onChange={(e) => formatValueForDecimals(e)}
          onBlur={(e) => {
            formatOnUnfocus(e);
          }}
        />
      </Box>
      {/* (val={value}){changed ? "*" : ""} */}
      {changed && onChange ? (
        <Box display={"flex"} justifyContent={"flex-end"} alignItems={"flex-end"} marginLeft={"10px"}>
          <Button variant="contained" onClick={() => onChange(getRealNewValue())}>
            Save
          </Button>
          <Button variant="text" onClick={() => setNewValue(getValueAsString(value))}>
            Cancel
          </Button>
        </Box>
      ) : null}
    </Box>
  );
};
