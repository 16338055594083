import ClearIcon from "@mui/icons-material/Clear";
import { Autocomplete, Box, Button, IconButton, InputAdornment, InputLabel, TextField } from "@mui/material";
import { ExtendedCheckFiltersType1, useCheckFilters } from "common/hooks/useCheckFilters";
import { debounce } from "lodash";
import { observer } from "mobx-react-lite";
import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { useStore } from "storeContainer";
import { AmountFilterInputs } from "../VChecks/Filters/AmountFilters";
import { DateContextFilters } from "../VChecks/Filters/DateContextFilters";

export const CheckBatchDetailsFilters = observer(function CheckBatchDetailsFilters() {
  const { filters, setFilters } = useCheckFilters<ExtendedCheckFiltersType1>();
  const [textFieldValue, setTextFieldValue] = useState(filters?.search ?? "");
  const inputRef = useRef<HTMLInputElement>(null);
  const { batchId } = useParams<{ batchId: string }>();
  const BudgetManagementStore = useStore("BudgetManagementStore");

  const { availableBankAccountsForBatchQuery, fetchAvailableBankAccountsForBatchQuery } = BudgetManagementStore;

  const debouncedSetFilters = useCallback(
    debounce((searchValue: string) => {
      setFilters((prevFilters) => ({
        ...prevFilters,
        search: { search_input: !searchValue.length ? undefined : searchValue },
      }));
    }, 500),
    [],
  );

  const handleTextFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value;

    setTextFieldValue(searchValue);
    debouncedSetFilters(searchValue);
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);
  useEffect(() => {
    fetchAvailableBankAccountsForBatchQuery(batchId, undefined, "");
  }, [batchId, fetchAvailableBankAccountsForBatchQuery]);

  return (
    <Box display="flex" gap="15px" alignItems="flex-end" width="100%">
      <Box>
        <InputLabel variant="standard" htmlFor="search-checks-in-batch">
          Search
        </InputLabel>
        <TextField
          id="search-checks-in-batch"
          placeholder="Search"
          variant="outlined"
          value={textFieldValue}
          onChange={handleTextFieldChange}
          sx={{ maxWidth: "300px" }}
          inputRef={inputRef}
          InputProps={{
            ...(filters?.search?.search_input?.length && {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => {
                      setFilters((prevFilters) => ({ ...prevFilters, search: { search_input: "" } }));
                      setTextFieldValue("");
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }),
          }}
        />
      </Box>
      <Box>
        <InputLabel variant="standard" htmlFor="check-batch-ba">
          Bank Accounts
        </InputLabel>
        <Autocomplete
          disablePortal
          id="check-batch-ba"
          value={
            availableBankAccountsForBatchQuery?.data?.GetAvailableBankAccountsForBatchQuery?.rows
              ?.filter((item) => item?.id === filters?.bank_accounts?.[0])
              .map((item) => ({
                label: item?.name,
                id: item?.id,
              }))[0]!
          }
          options={
            availableBankAccountsForBatchQuery?.data?.GetAvailableBankAccountsForBatchQuery?.rows?.map((item) => ({
              label: item?.name,
              id: item?.id,
            }))!
          }
          sx={{ width: 150 }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder="Bank Accounts"
              sx={{ ".MuiAutocomplete-input": { padding: "0px !important" } }}
            />
          )}
          onChange={(event, newValue) => {
            setFilters((prevFilters) => ({ ...prevFilters, bank_accounts: newValue?.id ? [newValue?.id] : undefined }));
          }}
        />
      </Box>
      <AmountFilterInputs />
      <DateContextFilters />
      <Button
        variant="text"
        sx={{ textDecoration: "underline", color: "#DC143C" }}
        onClick={() => {
          setFilters({});
          setTextFieldValue("");
        }}
      >
        Clear Filters
      </Button>
    </Box>
  );
});
