import { InputAdornment, TextField, Typography } from "@mui/material";
import { VBillCopyTextToClipboard } from "components/pages/common/VBill/VBillCopyTextToClipboard/VBillCopyTextToClipboard";
import { FunctionComponent } from "react";

interface IWidgetInputProps {
  inputText: string;
  onInputChange: (text: string) => void;
  inputError?: string;
}

export const WidgetInput: FunctionComponent<IWidgetInputProps> = ({ inputText, onInputChange, inputError }) => (
  <>
    <Typography>OCR text</Typography>
    <TextField
      error={!!inputError}
      helperText={inputError}
      value={inputText}
      onChange={(e) => onInputChange(e.target.value)}
      size="small"
      sx={{ margin: "10px 0" }}
      InputProps={{
        ...(inputText.length
          ? {
              endAdornment: (
                <InputAdornment position="end">
                  <VBillCopyTextToClipboard textToCopy={inputText} />
                </InputAdornment>
              ),
            }
          : {}),
      }}
    />
  </>
);
