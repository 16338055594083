import CloseIcon from "@mui/icons-material/Close";
import { Alert, Dialog, IconButton, Tooltip } from "@mui/material";
import { observer } from "mobx-react";
import { useEffect } from "react";
import { useStore } from "storeContainer";

export const VBillRequestErrorToast = observer(() => {
  const vBillCodingLedgerStore = useStore("VBillCodingLedgerStore");
  const vBillStore = useStore("VBillStore");

  const showVBillRequestErrorToast =
    vBillCodingLedgerStore.showVBillRequestErrorToast || vBillStore.showVBillRequestErrorToast;

  useEffect(() => {
    let timeoutId: any;
    if (showVBillRequestErrorToast) {
      timeoutId = setTimeout(() => {
        handleCloseToast();
      }, 3000);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [showVBillRequestErrorToast]);

  const handleCloseToast = () => {
    if (vBillCodingLedgerStore.showVBillRequestErrorToast) {
      vBillCodingLedgerStore.setShowVBillRequestErrorToast(false);
    }

    if (vBillStore.showVBillRequestErrorToast) {
      vBillStore.setShowVBillRequestErrorToast(false);
    }
  };

  if (!showVBillRequestErrorToast) {
    return null;
  }

  return (
    <Dialog open>
      <Alert
        sx={{ padding: "20px" }}
        severity="error"
        action={
          <Tooltip arrow title="Close">
            <IconButton onClick={handleCloseToast}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        }
      >
        There was an error processing your request. Please try again.
      </Alert>
    </Dialog>
  );
});
