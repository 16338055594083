import { ListItemText, Menu, MenuItem } from "@mui/material";
import { groupBy } from "lodash";
import { observer } from "mobx-react";
import { chatMemberStore } from "modules/Chat";
import { IGetChatNotificationUrlArgs } from "modules/Chat/utils/utils";
import { NotificationsStatsNotification } from "./NotificationsStatsNotification/NotificationsStatsNotification";

interface IChatMemberNotificationsStatsProps {
  menuAnchorEl: HTMLButtonElement | null;
  menuOnClose: () => void;
  onChannelNotificationClick: (channel: IGetChatNotificationUrlArgs) => void;
}

export const ChatMemberNotificationsStats = observer(function ChatMemberNotificationsStats({
  menuAnchorEl,
  menuOnClose,
  onChannelNotificationClick,
}: IChatMemberNotificationsStatsProps) {
  const memberNotificationsStats = chatMemberStore.memberNotificationsStats?.perChannelCount;
  const channelGroupNotifications = groupBy(memberNotificationsStats, "channelId");

  return (
    <Menu
      id="chat-badge-notifications-menu"
      anchorEl={menuAnchorEl}
      open
      onClose={menuOnClose}
      MenuListProps={{
        "aria-labelledby": "chat-badge-notifications-btn",
      }}
      BackdropProps={{ invisible: false }}
    >
      {!Object.keys(channelGroupNotifications).length ? (
        <MenuItem disabled>
          <ListItemText primary="No Chat Notifications" />
        </MenuItem>
      ) : (
        Object.keys(channelGroupNotifications).map((channelKey) => (
          <NotificationsStatsNotification
            key={channelKey}
            channelNotifications={channelGroupNotifications[channelKey]}
            onChannelNotificationClick={onChannelNotificationClick}
          />
        ))
      )}
    </Menu>
  );
});
