import { IVBillPaymentsGroupingStatus } from "generated/sdk.vbill";
import { vBillPaymentsLedgerChildRoute } from "./hooks";

export enum VBillPaymentsLedgerChildRoutes {
  ALL = "",
  PROCESSING = "processing",
  // ACTION_REQUIRED = "action-required",
  COMPLETED = "completed",
  VOIDED = "voided",
}

export function getVBillPaymentsLedgerPaymentStatusByChildRoute(childRoute: vBillPaymentsLedgerChildRoute) {
  const {
    isAllChildRoute,
    isProcessingChildRoute,
    // isActionRequiredChildRoute,
    isCompletedChildRoute,
    isVoidedChildRoute: isFinishedChildRoute,
  } = childRoute;

  if (isAllChildRoute) {
    return undefined;
  }

  if (isProcessingChildRoute) {
    return [IVBillPaymentsGroupingStatus.Processing];
  }

  // if (isActionRequiredChildRoute) {
  //   return IVBillPaymentsGroupingStatus.Error;
  // }

  if (isCompletedChildRoute) {
    return [IVBillPaymentsGroupingStatus.Completed];
  }

  if (isFinishedChildRoute) {
    return [IVBillPaymentsGroupingStatus.Error, IVBillPaymentsGroupingStatus.Voided];
  }
}
