import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { ButtonProps, styled } from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { grey, orange } from "@mui/material/colors";
import Card from "components/common/Card";
import { BankAccountWithAggregatedData } from "generated/sdk";
import { observer } from "mobx-react-lite";
import { formatCurrency } from "../../../../../common/helpers/utils";
import { useStore } from "../../../../../storeContainer";
import { COLORS } from "../../../../../themes/default";
import { BankAccountName } from "./BankAccountName";
import { Widget } from "./Widget";
import { AddBankAccountSource } from "storesMobx/UiStore";
export type BankAccountWidget = {
  icon: any;
  name: any;
  title: string;
  subtitle: string;
  url: string;
  sum: number;
};

const UpgradeButton = styled(Button)<ButtonProps>(() => ({
  color: COLORS.deepCerulean,
  backgroundColor: COLORS.foam25Opacity,
  borderColor: COLORS.foam,
  fontSize: "14px",
  fontWeight: 400,
  "&:hover": {
    backgroundColor: "#e1f5fe",
  },
}));

const ValidateBankAccount = styled(Button)<ButtonProps>(() => ({
  color: "#DB8C15",
  backgroundColor: "#FFF4E2",
  borderColor: "#FFF4E2",
  fontSize: "14px",
  fontWeight: 400,
  "&:hover": {
    backgroundColor: "#FFF4E2",
    borderColor: "#DB8C15",
  },
}));

export const BankAccountCard: React.FunctionComponent<{
  companyId: string;
  depositAccounts: boolean;
  bankAccount: BankAccountWithAggregatedData;
  backgroundColor: string;
  user?: any;
  handleChangeBankAccountName: (name: string) => void;
}> = observer(function BankAccountCard({
  bankAccount,
  backgroundColor,
  depositAccounts,
  companyId,
  handleChangeBankAccountName,
  user,
}) {
  const contextStore = useStore("ContextStore");
  const {
    acl: { orgsUserMayAddBankAccountTo },
  } = useStore("SessionStore");
  const { selectedOrganizationId } = contextStore;

  const canValidateBA = orgsUserMayAddBankAccountTo.some((org) => org.id === selectedOrganizationId);
  const UIStore = useStore("UiStore");
  const validateBankAccount = () => {
    /*  (window as any).location = companyId
      ? `/bas/create/regular/companies/${companyId}?upgrade=1`
      : "/bas/create/regular?upgrade=1"; */
    //uiStore.confirm = true;
    UIStore.showAddBankAccountModal = AddBankAccountSource.DepositOnlyUpgrade;
  };

  // const widgets: BankAccountWidget[] = [
  //   {
  //     icon: <ArrowBackIcon sx={{ fontSize: 16, color: COLORS.success, transform: "rotate(135deg)" }} />,
  //     name: (
  //       <Typography variant="body2" sx={{ p: 0, ml: 0.2, mt: 0.2 }} color={grey[600]}>
  //         Outgoing
  //       </Typography>
  //     ),
  //     title: formatCurrency(bankAccount.outgoing_checks_stats?.sum || 0),
  //     subtitle: `${bankAccount.outgoing_checks_stats?.count} vChecks`,
  //     url: selectedOrganizationId
  //       ? `/org/${selectedOrganizationId}/vchecks/payments?filters={"date":null,"date_to":null,"bank_accounts":["${bankAccount.id}"],"amount_from":0,"amount_to":0,"type":"withdrawals","date_sort":"desc","status":"PROCESSING","search":""}&pagination={"per_page":10,"page":1}`
  //       : `/depositAccounts/ledger?filters={"date":null,"date_to":null,"amount_from":0,"amount_to":0,"type":"","date_sort":"desc","status":"ALL","search":""}&pagination={"per_page":10,"page":1}`,
  //     sum: bankAccount.outgoing_checks_stats?.sum || 0,
  //   },
  //   {
  //     icon: <ArrowBackIcon sx={{ fontSize: 16, color: COLORS.cerulean, transform: "rotate(-45deg)" }} />,
  //     name: (
  //       <Typography variant="body2" sx={{ p: 0, ml: 0.2, mt: 0.2 }} color={grey[600]}>
  //         Incoming
  //       </Typography>
  //     ),
  //     title: formatCurrency(bankAccount.incoming_checks_stats?.sum || 0),
  //     subtitle: `${bankAccount.incoming_checks_stats?.count} vChecks`,
  //     url: selectedOrganizationId
  //       ? `/org/${selectedOrganizationId}/vchecks/deposits?filters={"bank_accounts":["${bankAccount.id}"],"date_sort":"desc","status":"PROCESSING"}&pagination={"per_page":10,"page":1}`
  //       : `/depositAccounts/ledger?filters={"date_sort":"desc"}&pagination={"per_page":10,"page":1}`,
  //     sum: bankAccount.incoming_checks_stats?.sum || 0,
  //   },
  //   {
  //     icon: <HourglassEmptyIcon sx={{ fontSize: 16, color: orange[600] }} />,
  //     name: (
  //       <Typography variant="body2" sx={{ p: 0, ml: 0.2, mt: 0.2 }} color={grey[600]}>
  //         Pending Signature
  //       </Typography>
  //     ),
  //     title: formatCurrency(bankAccount.pending_approval_checks_stats?.sum || 0),
  //     subtitle: `${bankAccount.pending_approval_checks_stats?.count} vChecks`,
  //     url: `/org/${selectedOrganizationId}/vchecks/deposits?filters={"bank_accounts":["${bankAccount.id}"],"date_sort":"desc","status":"PENDING_SIGNATURE"}&pagination={"per_page":10,"page":1}`,
  //     sum: bankAccount.pending_approval_checks_stats?.sum || 0,
  //   },
  // ];
  const validateLabel = (status: string, verification_link?: string | null) => {
    return (
      <label
        style={{
          marginLeft: 2,
          fontSize: 13,
          padding: 5,
          cursor: verification_link ? "pointer" : "",
          backgroundColor: status === "Pending" ? "#FFF4E2" : "transparent",
          color: status === "Pending" ? "#DB8C15" : "primary",
          display: "flex",
          alignItems: "center",
        }}
      >
        {status === "Pending" ? "Validation Pending" : status}
        {verification_link ? <OpenInNewIcon /> : null}
      </label>
    );
  };
  return (
    <Card
      title={
        <Box
          sx={{
            justifyContent: "space-between",
            display: "flex",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", paddingLeft: "10px" }}>
            <BankAccountName
              depositAccounts={depositAccounts}
              canEdit={true}
              bankAccountNickname={bankAccount.nickname ?? ""}
              user={user}
              bankAccountName={bankAccount.name}
              onNameChanged={handleChangeBankAccountName}
            />
            {`****${bankAccount.account_number}`}

            {bankAccount.deposit_only === true && (
              <Typography sx={{ margin: "0 0.5rem" }} variant="caption">
                Deposit Only
              </Typography>
            )}

            {canValidateBA &&
            bankAccount.verification_status !== "Pending" &&
            bankAccount.verification_link !== null ? (
              <Link underline="none" href={`/verifications/bas/${bankAccount.id}/upload`}>
                <ValidateBankAccount
                  sx={{ ml: 2, textTransform: "capitalize" }}
                  variant="outlined"
                  size={"small"}
                  endIcon={<NavigateNextIcon />}
                >
                  Validate bank account
                </ValidateBankAccount>
              </Link>
            ) : (
              bankAccount.verification_status !== "Verified" &&
              (bankAccount.verification_link ? (
                <Tooltip
                  title={
                    "We’re in the process of verifying the information provided and it looks like we need additional documentation. Please click the link so that we can continue with the Verification Process. "
                  }
                >
                  <a style={{ textDecoration: "none" }} href={bankAccount.verification_link}>
                    {validateLabel(bankAccount.verification_status, bankAccount.verification_link)}
                  </a>
                </Tooltip>
              ) : (
                <Tooltip
                  title={
                    "We’re in the process of verifying the Bank Account information provided. Please email security@cherrynet.com for any inquiries."
                  }
                >
                  <Typography>
                    {validateLabel(bankAccount.verification_status, bankAccount.verification_link)}
                  </Typography>
                </Tooltip>
              ))
            )}
            {depositAccounts === undefined && (
              <>
                {bankAccount.can_upgrade && (
                  <UpgradeButton
                    onClick={validateBankAccount}
                    size="small"
                    sx={{ ml: 2, textTransform: "capitalize" }}
                    variant="outlined"
                    color={"primary"}
                    endIcon={<ArrowCircleUpIcon />}
                  >
                    Upgrade
                  </UpgradeButton>
                )}
              </>
            )}
          </Box>
        </Box>
      }
      headerProps={{
        action: (
          <>
            {bankAccount.can_upgrade && (
              <Button
                onClick={validateBankAccount}
                size="small"
                sx={{ ml: 2, textTransform: "capitalize" }}
                variant="outlined"
                color={"secondary"}
                endIcon={<NavigateNextIcon />}
              >
                Upgrade
              </Button>
            )}
          </>
        ),
        sx: { padding: "25px 10px 25px 0" },
      }}
      elevation={0}
      style={{ backgroundColor: backgroundColor }}
      contentProps={{ sx: { padding: "0px 20px !important" } }}
    ></Card>
  );
});
