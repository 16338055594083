import { IconButton, Tooltip } from "@mui/material";
import { ArrowBackIosNewIcon } from "components/common/icons";
import { useVBillCodingLedgerQuickFilterUrl } from "components/pages/VBillCodingLedger/hooks";
import { VBillCodingLedgerChildRoutes } from "components/pages/VBillCodingLedger/utils";
import { IVBillInvoiceIncomingStatus, IVBillInvoicePushStatus } from "generated/sdk.vbill";
import { observer } from "mobx-react";
import { useMemo } from "react";
import { useHistory } from "react-router";
import { useStore } from "storeContainer";

export const VBillToolbarBackBtn = observer(() => {
  const vBillStore = useStore("VBillStore");
  const vBillCodingLedgerStore = useStore("VBillCodingLedgerStore");
  const { invoice } = vBillStore;
  const history = useHistory();
  const vbillCodingLedgerUrl = useVBillCodingLedgerQuickFilterUrl(VBillCodingLedgerChildRoutes.ALL);
  const invoiceData = useMemo(() => invoice.data, [invoice.data]);

  const handleBackBtnClick = () => {
    const { ledgerRecordedNavigation } = vBillCodingLedgerStore;

    if (
      invoiceData?.status === IVBillInvoiceIncomingStatus.Approved &&
      invoiceData.pushStatus === IVBillInvoicePushStatus.Done
    ) {
      history.push(vbillCodingLedgerUrl);
    } else {
      if (ledgerRecordedNavigation) {
        history.push(ledgerRecordedNavigation);
      } else {
        history.push(vbillCodingLedgerUrl);
      }
    }
  };

  return (
    <Tooltip arrow title="Click to go back">
      <IconButton onClick={handleBackBtnClick} size="medium" sx={{ backgroundColor: "#EBF4FF" }}>
        <ArrowBackIosNewIcon color="primary" />
      </IconButton>
    </Tooltip>
  );
});
