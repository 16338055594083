import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Button, Typography } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { ExtendedCheckFiltersType1, useCheckFilters } from "common/hooks/useCheckFilters";
import { LedgerFilterInputType, getFiltersConfig } from "components/Filter/types";
import { isEqual } from "lodash";
import { useMemo, useState } from "react";
import { useParams, useRouteMatch } from "react-router-dom";
import { COLORS } from "themes/default";
import { VCheckChecksAllQuickFiltersEnum } from "../types";

export interface IActivityDropdownSelectFilterMenuOption {
  value: string;
  label: string;
}

export const ActivityDropdownSelectFilter = () => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const { filters, setFilters } = useCheckFilters<ExtendedCheckFiltersType1>();
  const { selectedQuickFilter } = useParams<{ selectedQuickFilter: VCheckChecksAllQuickFiltersEnum }>();
  const { path } = useRouteMatch();
  const staticFilter = useMemo<
    | {
        keyName: string;
        options: IActivityDropdownSelectFilterMenuOption[];
      }
    | undefined
  >(() => {
    const staticFilterConfig = getFiltersConfig(path, selectedQuickFilter);

    return (staticFilterConfig as any)?.[LedgerFilterInputType.ActivityDropdownSelectFilter];
  }, [path, selectedQuickFilter]);

  const handleMenuItemClick = (value: string) => {
    if (!staticFilter) {
      return;
    }

    setFilters((prev) => ({ ...prev, [staticFilter.keyName]: value }));
    setMenuAnchorEl(null);
  };

  if (!staticFilter) {
    return null;
  }

  const menuOpen = Boolean(menuAnchorEl);
  const menuOptions = staticFilter.options;
  const selectedFilterMenuOption: string | undefined = filters[staticFilter.keyName];
  const menuOption = menuOptions?.find(({ value }) => value === selectedFilterMenuOption);
  const optionText = menuOption?.label ?? "";

  return (
    <Box>
      <Button
        id="activity-button"
        aria-haspopup="listbox"
        aria-controls="activity-menu"
        aria-label="select activity"
        aria-expanded={menuOpen ? "true" : undefined}
        onClick={(e) => setMenuAnchorEl(e.currentTarget)}
        variant={selectedFilterMenuOption ? "containedFilters" : "outlined"}
        sx={{ maxWidth: "230px" }}
        endIcon={<ExpandMoreIcon />}
      >
        <Typography noWrap sx={{ fontSize: "12px", color: "inherit" }}>
          {selectedFilterMenuOption ? optionText : <span>Activity</span>}
        </Typography>
      </Button>

      <Menu
        id="activity-menu"
        anchorEl={menuAnchorEl}
        open={menuOpen}
        onClose={() => setMenuAnchorEl(null)}
        MenuListProps={{ "aria-labelledby": "activity-button", role: "listbox" }}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        {menuOptions?.map((option) => (
          <MenuItem
            key={option.value}
            selected={isEqual(selectedFilterMenuOption, option.value)}
            onClick={() => handleMenuItemClick(option.value)}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
