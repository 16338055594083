import { LoadingButton } from "@mui/lab";
import { Box, Button, Dialog, Divider, IconButton, Tooltip, Typography } from "@mui/material";
import { graphqlVBillClient } from "common/graphqlClient";
import { AddOutlinedIcon, CloseIcon } from "components/common/icons";
import { Spinner } from "components/common/Spinner/Spinner";
import { FormErrorMsg } from "components/pages/common/VBill/VBillAddEditBatchBtn/FormErrorMsg/FormErrorMsg";
import {
  getSdk,
  IVBillBillsFiltersInput,
  IVBillBillsGroupingType,
  IVBillVBillCreateBatchMutation,
} from "generated/sdk.vbill";
import { observer } from "mobx-react";
import { useState } from "react";
import { FormProvider, SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { useParams } from "react-router";
import { VariableSizeList } from "react-window";
import { useStore } from "storeContainer";
import { COLORS } from "themes/default";
import { WindowedBatchBundleLine } from "./WindowedBatchBundleLine/WindowedBatchBundleLine";

const { VBillCreateBatch, VBillGetBillsStats } = getSdk(graphqlVBillClient);

type ActiveSplitByTagList = "MANUAL" | "COMPANY" | string;

export interface IBatchBundleFormLineInput {
  name: string;
  // batchDateDue: number[];
  // companyIds: string[];
  memberIds: string[];
  filters?: IVBillBillsFiltersInput;
  budget: string;
  count: string;
  amount: string;
}

interface IBatchBundleDialogProps {
  onCloseDialog: () => void;
}
export const BatchBundleDialog = observer(function BatchBundleDialog({ onCloseDialog }: IBatchBundleDialogProps) {
  const [isSplitByTagLoading, setIsSplitByTagLoading] = useState(false);
  const [isSaveReqLoading, setIsSaveReqLoading] = useState(false);
  const { batchId, organizationId } = useParams<{ batchId: string; organizationId: string }>();
  const [hasErrorFeedback, setHasErrorFeedback] = useState<boolean>();
  const [activeSplitByTag, setActiveSplitByTag] = useState<ActiveSplitByTagList>("MANUAL");

  const vBillBatchDetailsStore = useStore("VBillBatchDetailsStore");
  const { batchFilters } = vBillBatchDetailsStore;

  const initialDefaultBundleLine: IBatchBundleFormLineInput = {
    name: "",
    budget: "0",
    memberIds: (vBillBatchDetailsStore.batchMembers.data?.items ?? []).map(({ coreOrgUserId }) => coreOrgUserId),
    count: "0",
    amount: "0",
    filters: vBillBatchDetailsStore.batchDetails.data?.batchFilters
      ? vBillBatchDetailsStore.batchDetails.data.batchFilters
      : undefined,
  };
  const batch = vBillBatchDetailsStore.batchDetails.data;
  const batchFiltersData = batch?.batchFilters || {};

  const formMethods = useForm<{
    bundleLines: IBatchBundleFormLineInput[];
  }>({
    defaultValues: { bundleLines: [initialDefaultBundleLine] },
  });
  const { fields, append, remove } = useFieldArray({
    control: formMethods.control,
    name: "bundleLines",
  });

  const handleFormSubmit: SubmitHandler<{ bundleLines: IBatchBundleFormLineInput[] }> = async (data) => {
    setIsSaveReqLoading(true);
    setHasErrorFeedback(false);

    // const createBatchesPromise = data.bundleLines.map((data) =>
    //   VBillCreateBatch({
    //     batchData: {
    //       parentBatchId: Number(batchId),
    //       organizationId,
    //       name: data.name,
    //       budget: data.budget,
    //       memberIds: data.memberIds,
    //       batchFilters: data.filters,
    //     },
    //   }),
    // );

    // const resp = await Promise.all(createBatchesPromise);

    const createBatchesResp: IVBillVBillCreateBatchMutation[] = [];

    for (const dataD of data.bundleLines) {
      const resp = await VBillCreateBatch({
        batchData: {
          parentBatchId: Number(batchId),
          organizationId,
          name: dataD.name,
          budget: dataD.budget,
          memberIds: dataD.memberIds,
          batchFilters: dataD.filters,
        },
      });
      createBatchesResp.push(resp);
    }

    const hasRespOneError = createBatchesResp.some((resp) => resp.createBatch.error);

    if (hasRespOneError) {
      setIsSaveReqLoading(false);
      setHasErrorFeedback(true);
    } else {
      await vBillBatchDetailsStore.getchildBatchListDetails(
        {
          pagination: batchFilters.batch_list_pag,
          filters: {
            ...batchFilters.batch_list_fil,
            organizationId,
            batchId: Number(batchId),
          },
        },
        true,
      );
      setIsSaveReqLoading(false);
      onCloseDialog();
    }
  };

  const splitByManual = () => {
    setIsSplitByTagLoading(true);
    setBundleLines([initialDefaultBundleLine]);
    setActiveSplitByTag("MANUAL");
    setIsSplitByTagLoading(false);
  };

  const splitByCompany = async () => {
    setIsSplitByTagLoading(true);
    setActiveSplitByTag("COMPANY");
    const data = await VBillGetBillsStats({
      filters: { organizationId },
      grouping: { type: IVBillBillsGroupingType.CompanyId },
    });

    const memberIds = vBillBatchDetailsStore.batchMembers.data?.items.map((m) => m.coreOrgUserId) || [];
    // console.log("GGG", "members", memberIds);
    // console.log("GGG", data);
    const values: IBatchBundleFormLineInput[] = data.getBillsStats.groupStats.map((s) => {
      const value = s.value || "";
      return {
        name: batch?.name + " - " + (s.label || value),
        companyIds: [value],
        memberIds,
        filters: {
          ...batchFiltersData,
          companyIds: [value],
        },
        budget: s.amount,
        amount: s.amount,
        count: s.count.toString(),
      };
    });
    // console.log("GGG", "values", values);
    setBundleLines(values);
    setIsSplitByTagLoading(false);
  };

  const setBundleLines = (bundleLines: IBatchBundleFormLineInput[]) => {
    formMethods.reset({ bundleLines });
  };

  const splitByAdditionalMapping = async (key: string) => {
    setIsSplitByTagLoading(true);
    setActiveSplitByTag(key);
    const data = await VBillGetBillsStats({
      filters: { organizationId },
      grouping: { type: IVBillBillsGroupingType.MappingKey, value: key },
    });
    // console.log("GGG", key, data);
    const memberIds = vBillBatchDetailsStore.batchMembers.data?.items.map((m) => m.coreOrgUserId) || [];
    // console.log("GGG", "members", memberIds);

    const values: IBatchBundleFormLineInput[] = data.getBillsStats.groupStats.map((s) => {
      const value = s.value || "";
      const source = s.source || "";
      return {
        name: batch?.name + " - " + (s.label || value),
        companyIds: [value],
        memberIds,
        filters: {
          ...batchFiltersData,
          mappings: [
            ...(batchFiltersData.mappings || []).filter((amf) => amf.key !== key),
            { key, valuesIn: [{ value, source }] },
          ],
        },
        budget: s.amount,
        amount: s.amount,
        count: s.count.toString(),
      };
    });
    // console.log("GGG", "values", values);
    setBundleLines(values);
    setIsSplitByTagLoading(false);
  };

  if (!batch) return null;

  return (
    <Dialog onClose={onCloseDialog} open maxWidth="md">
      <Box sx={{ padding: "15px" }}>
        {/* {vBillBatchDetailsStore.batchNestedTree.isLoading || !batchNestedTreeData ? (
        <Box sx={{ textAlign: "center", padding: "100px 0" }}>
          <Spinner />
        </Box>
      ) : (
        <div>udiaosudiaos</div>
      )} */}
        <Typography sx={{ fontSize: "20px", marginBottom: "20px" }}>Batch Split</Typography>
        <Typography sx={{ marginBottom: "5px" }}>Split by:</Typography>
        <Tooltip arrow title="Close">
          <IconButton
            onClick={() => onCloseDialog()}
            sx={{ position: "absolute", top: "5px", right: "5px", zIndex: 1 }}
          >
            <CloseIcon sx={{ fontSize: "20px" }} />
          </IconButton>
        </Tooltip>
        <Box sx={{ display: "flex", alignItems: "center", gap: "10px", marginBottom: "10px" }}>
          <Button
            sx={{ height: "30px" }}
            variant={activeSplitByTag === "MANUAL" ? "outlined" : "text"}
            onClick={splitByManual}
          >
            {isSplitByTagLoading && activeSplitByTag === "MANUAL" ? <Spinner /> : "Manual"}
          </Button>
          <Button
            sx={{ height: "30px" }}
            variant={activeSplitByTag === "COMPANY" ? "outlined" : "text"}
            onClick={splitByCompany}
          >
            {isSplitByTagLoading && activeSplitByTag === "COMPANY" ? <Spinner /> : "Company"}
          </Button>
          {vBillBatchDetailsStore.vBillBatchAdditionalMappingsSettings.data?.invoice.map((am) => (
            <Button
              sx={{ height: "30px" }}
              variant={activeSplitByTag === am.key ? "outlined" : "text"}
              onClick={() => splitByAdditionalMapping(am.key)}
            >
              {isSplitByTagLoading && activeSplitByTag === am.key ? <Spinner /> : am.label}
            </Button>
          ))}
        </Box>
        <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit(handleFormSubmit)}>
            <Box
              sx={{
                display: "flex",
                borderBottom: `1px solid ${COLORS.borderColor}`,
                borderTop: `1px solid ${COLORS.borderColor}`,
              }}
            >
              <Box
                sx={{
                  padding: "10px",
                  fontSize: "12px",
                  textTransform: "uppercase",
                  fontWeight: "500",
                  width: "270px",
                }}
              >
                Name
              </Box>
              <Box
                sx={{
                  padding: "10px",
                  fontSize: "12px",
                  textTransform: "uppercase",
                  fontWeight: "500",
                  width: "270px",
                }}
              >
                Buget
              </Box>
              <Box
                sx={{ padding: "10px", fontSize: "12px", textTransform: "uppercase", fontWeight: "500", width: "70px" }}
              >
                Filters
              </Box>
              {/* <Box sx={{ padding: "10px", fontSize: "12px", textTransform: "upercase" }} variant="head">
                Companies
              </Box> */}
              <Box
                sx={{ padding: "10px", fontSize: "12px", textTransform: "uppercase", fontWeight: "500", width: "82px" }}
              >
                Members
              </Box>
              <Box sx={{ padding: "10px", fontSize: "12px", textTransform: "uppercase", fontWeight: "500" }} />
            </Box>

            <VariableSizeList
              height={400}
              itemCount={fields.length}
              itemSize={() => 80}
              width={780}
              itemData={{
                onRemoveLineBtnClick: (index: number) => remove(index),
              }}
            >
              {WindowedBatchBundleLine}
            </VariableSizeList>
            <Box sx={{ marginTop: "10px" }}>
              <Button type="button" onClick={() => append(initialDefaultBundleLine)} startIcon={<AddOutlinedIcon />}>
                Add line
              </Button>
            </Box>
            <Divider sx={{ margin: "10px 0 20px" }} />
            {hasErrorFeedback && (
              <FormErrorMsg fieldError={{ message: "An error ocurred while saving your batch.", type: "" }} />
            )}
            <Box sx={{ display: "flex", justifyContent: "end", gap: "15px" }}>
              <LoadingButton variant="contained" type="submit" loading={isSaveReqLoading} sx={{ height: "30px" }}>
                Save
              </LoadingButton>
              <Button variant="outlined" type="button" onClick={onCloseDialog} sx={{ height: "30px" }}>
                Cancel
              </Button>
            </Box>
          </form>
        </FormProvider>
      </Box>
    </Dialog>
  );
});
