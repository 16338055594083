import { SvgIcon, SvgIconProps } from "@mui/material";

export const SearchIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="18" height="18" viewBox="0 0 18 18" fill="none">
      <g opacity="0.75">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 8.25C3 5.3505 5.3505 3 8.25 3C11.1495 3 13.5 5.3505 13.5 8.25C13.5 9.66452 12.9406 10.9484 12.0309 11.8924C12.0053 11.9121 11.9807 11.9337 11.9572 11.9572C11.9337 11.9807 11.9121 12.0053 11.8924 12.0309C10.9484 12.9406 9.66452 13.5 8.25 13.5C5.3505 13.5 3 11.1495 3 8.25ZM12.4633 13.5239C11.3085 14.4476 9.84376 15 8.25 15C4.52208 15 1.5 11.9779 1.5 8.25C1.5 4.52208 4.52208 1.5 8.25 1.5C11.9779 1.5 15 4.52208 15 8.25C15 9.84376 14.4476 11.3085 13.5239 12.4633L16.2803 15.2197C16.5732 15.5126 16.5732 15.9875 16.2803 16.2803C15.9875 16.5732 15.5126 16.5732 15.2197 16.2803L12.4633 13.5239Z"
          fill="#697281"
        />
      </g>
    </SvgIcon>
  );
};
