import CloseIcon from "@mui/icons-material/Close";
import { Dialog, IconButton, Tooltip } from "@mui/material";
import { isEqual, sortBy } from "lodash";
import { toJS } from "mobx";
import React, { useEffect, useMemo, useState } from "react";
import { IOption } from "../MembersCheckboxList/CheckboxOption/CheckboxOption";
import { MembersCheckboxList } from "../MembersCheckboxList/MembersCheckboxList";

interface IChangeMembersDialogProps {
  onCloseDialog: () => void;
  onSaveMembers: (memberIds: string[]) => Promise<void>;
  selectedMembersIds: string[];
  allMembers: IOption[];
}

export const ChangeMembersDialog = ({
  onCloseDialog,
  onSaveMembers,
  selectedMembersIds,
  allMembers,
}: IChangeMembersDialogProps) => {
  const [selectedMemberIds, setSelectedMemberIds] = useState<string[]>([]);

  useEffect(() => {
    setSelectedMemberIds(selectedMembersIds);
  }, [selectedMembersIds]);

  // order once, on mount
  const allMembersSelectedFirst = useMemo(
    () => sortBy(allMembers, (member) => selectedMembersIds.find((selectedMemberId) => selectedMemberId === member.id)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleListOptionChange = (id: string, value: boolean) => {
    if (value) {
      setSelectedMemberIds((prev) => [...prev, id]);
    } else {
      setSelectedMemberIds((prev) => prev.filter((memberId) => memberId !== id));
    }
  };

  const isDialogSaveBtnDisabled = isEqual(toJS(selectedMembersIds), toJS(selectedMemberIds));

  return (
    <Dialog onClose={onCloseDialog} open PaperProps={{ sx: { padding: "15px", position: "relative", width: 350 } }}>
      <Tooltip arrow title="Close">
        <IconButton onClick={onCloseDialog} sx={{ position: "absolute", top: "5px", right: "5px", zIndex: 1 }}>
          <CloseIcon sx={{ fontSize: "20px" }} />
        </IconButton>
      </Tooltip>
      <MembersCheckboxList
        title="Change Members:"
        options={allMembersSelectedFirst}
        onOptionChange={handleListOptionChange}
        selectedIds={selectedMemberIds}
        onClose={onCloseDialog}
        onSave={() => onSaveMembers(selectedMemberIds)}
        saveBtnDisabled={isDialogSaveBtnDisabled}
        searchPlaceholder="Search members"
      />
    </Dialog>
  );
};
