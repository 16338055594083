import { Tooltip, Typography } from "@mui/material";
import classNames from "classnames";
import { AddIcon } from "components/common/icons";
import { TVBillFormFields, TVBillFormLineFields } from "components/pages/VBill/types";
import { FunctionComponent } from "react";
import { useWatch } from "react-hook-form";
import styles from "./widgetForm.module.scss";

interface IWidgetFormProps {
  onAddLineFieldBtnClick: (index: number, fieldName: keyof TVBillFormLineFields) => void;
  onAddInvoiceFieldBtnClick: (fieldName: keyof TVBillFormFields) => void;
  onAddNewLineBtnClick: () => void;
}

export const WidgetForm = ({
  onAddLineFieldBtnClick,
  onAddInvoiceFieldBtnClick,
  onAddNewLineBtnClick,
}: IWidgetFormProps) => {
  const vBillFormlineItemsObserver: TVBillFormLineFields[] = useWatch({ name: "lineItems" });
  const vBillFormFieldHasLineItemsObserver: TVBillFormFields["hasLineItems"] = useWatch({ name: "hasLineItems" });

  return (
    <table className={styles.widgetTable}>
      <thead>
        <tr>
          <td colSpan={2}>
            <strong>
              <Typography marginBottom="10px" fontWeight={500}>
                Apply to:
              </Typography>
            </strong>
          </td>
        </tr>
      </thead>

      <tbody>
        <tr>
          <td colSpan={1}>
            <AddButton onClick={() => onAddInvoiceFieldBtnClick("senderName")}>Name</AddButton>
          </td>

          <td colSpan={1}>
            <AddButton onClick={() => onAddInvoiceFieldBtnClick("senderAddress")}>Address</AddButton>
          </td>

          <td colSpan={1}>
            <AddButton onClick={() => onAddInvoiceFieldBtnClick("senderEmail")}>Email</AddButton>
          </td>
          <td colSpan={1}>
            <AddButton onClick={() => onAddInvoiceFieldBtnClick("senderPhone")}>Phone</AddButton>
          </td>
        </tr>
        <tr>
          <td colSpan={1}>
            <AddButton onClick={() => onAddInvoiceFieldBtnClick("invoiceNumber")}>Bill#</AddButton>
          </td>

          <td colSpan={1}>
            <AddButton onClick={() => onAddInvoiceFieldBtnClick("invoiceDate")}>Bill Date</AddButton>
          </td>

          <td colSpan={1}>
            <AddButton onClick={() => onAddInvoiceFieldBtnClick("invoiceDateDue")}>Bill Due</AddButton>
          </td>
        </tr>

        <tr>
          <td>
            <AddButton onClick={() => onAddInvoiceFieldBtnClick("amount")}>Total Due</AddButton>
          </td>
        </tr>

        {vBillFormFieldHasLineItemsObserver && (
          <>
            <tr>
              <td colSpan={5}>
                <hr />
              </td>
            </tr>

            {vBillFormlineItemsObserver.map((_, index) => (
              <tr key={index}>
                <td colSpan={1}>Line #{index + 1}</td>

                <td colSpan={1}>
                  <AddButton onClick={() => onAddLineFieldBtnClick(index, "description")}>Description</AddButton>
                </td>
                <td colSpan={1}>
                  <AddButton onClick={() => onAddLineFieldBtnClick(index, "qty")}>Qty</AddButton>
                </td>

                <td colSpan={1}>
                  <AddButton onClick={() => onAddLineFieldBtnClick(index, "rate")}>Rate</AddButton>
                </td>
                <td colSpan={1}>
                  <AddButton onClick={() => onAddLineFieldBtnClick(index, "amount")}>Total</AddButton>
                </td>
              </tr>
            ))}

            <tr>
              <td />
              <td colSpan={2}>
                <Tooltip arrow title="Add item">
                  <span>
                    <AddButton onClick={onAddNewLineBtnClick} className={styles.addLineBtn}>
                      <AddIcon sx={{ fontSize: "15px" }} />
                    </AddButton>
                  </span>
                </Tooltip>
              </td>
            </tr>
          </>
        )}
      </tbody>
    </table>
  );
};

interface IAddButtonProps {
  onClick: () => void;
  className?: string;
}

const AddButton: FunctionComponent<IAddButtonProps> = ({ children, onClick, className }) => (
  <button onClick={onClick} className={classNames(className, styles.addButton)}>
    {children}
  </button>
);
