import { Box } from "@mui/material";
import { IActionlogActionlogFeOutput } from "generated/graphql.actionlog";
import React, { FunctionComponent, useEffect, useState } from "react";
import { ActivityAdditionalContent } from "../ActivityAdditionalContent/ActivityAdditionalContent";
import {
  AdditionalContentAccordianDetails,
  AdditionalContentAccordianSummary,
  AdditionalContentAccordion,
} from "../ActivityAdditionalContent/AdditionalContentAccordian/AdditionalContentAccordian";
import { ActionComment } from "./ActionComment/ActionComment";
import { ActionHeader } from "./ActionHeader/ActionHeader";
import { ActionIcon } from "./ActionIcon/ActionIcon";
import styles from "./activityAction.module.scss";

interface IActivityActionProps {
  actionLog: IActionlogActionlogFeOutput;
  additionalContentExpanded: boolean;
}

export const ActivityAction: FunctionComponent<IActivityActionProps> = ({
  actionLog,
  actionLog: { action, meta },
  additionalContentExpanded,
}) => {
  const [accordianExpanded, setAccordianExpanded] = useState(false);

  useEffect(() => {
    setAccordianExpanded(additionalContentExpanded);
  }, [additionalContentExpanded]);

  return (
    <div className={styles.action}>
      <div className={styles.logIconCol}>
        <ActionIcon action={action} />
      </div>

      <Box sx={{ flexGrow: 1, minWidth: 0 }}>
        {!!meta?.additionalContent?.length ? (
          <AdditionalContentAccordion
            expanded={accordianExpanded}
            onChange={() => setAccordianExpanded(!accordianExpanded)}
          >
            <AdditionalContentAccordianSummary>
              <ActionHeader actionLog={actionLog} />
            </AdditionalContentAccordianSummary>

            <AdditionalContentAccordianDetails>
              <ActivityAdditionalContent additionalContent={meta.additionalContent} />
            </AdditionalContentAccordianDetails>

            {action === "COMMENT" && meta.content?.message && <ActionComment message={meta.content?.message} />}
          </AdditionalContentAccordion>
        ) : (
          <>
            <ActionHeader actionLog={actionLog} sx={{ paddingLeft: "15px" }} />
            {action === "COMMENT" && meta?.content?.message && <ActionComment message={meta.content?.message} />}
          </>
        )}
      </Box>
    </div>
  );
};
