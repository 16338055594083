import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { Spinner } from "components/common/Spinner/Spinner";
import React, { Dispatch, SetStateAction } from "react";
import { COLORS } from "themes/default";

const ConfirmDeleteModal: React.FC<{
  isConfirmDeleteModalOpen: boolean;
  setIsDeleteConfirmModalOpen: (state: boolean) => void;
  handleSubmit: () => void;
  loading: boolean;
  ruleIdToBeDeleted: string;
  errorFeedback: string;
  setErrorFeedback: Dispatch<SetStateAction<string>>;
}> = ({
  isConfirmDeleteModalOpen,
  setIsDeleteConfirmModalOpen,
  handleSubmit,
  loading,
  ruleIdToBeDeleted,
  errorFeedback,
  setErrorFeedback,
}) => {
  return (
    <Dialog
      open={isConfirmDeleteModalOpen}
      onClose={() => {
        setErrorFeedback("");
        setIsDeleteConfirmModalOpen(false);
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Delete Rule</DialogTitle>
      <DialogContent>
        <Grid container direction={"column"}>
          <DialogContentText>Are you sure you want to delete this rule?</DialogContentText>
        </Grid>
      </DialogContent>
      <Typography color={COLORS.darkRed} paddingX="24px">
        {errorFeedback}
      </Typography>
      <Divider />
      <DialogActions>
        <Button
          onClick={() => {
            setIsDeleteConfirmModalOpen(false);
          }}
        >
          Cancel
        </Button>
        {!loading ? (
          <LoadingButton
            loading={loading}
            variant="outlined"
            onClick={handleSubmit}
            sx={{ color: COLORS.darkRed, fontWeight: 500, borderColor: ` ${COLORS.darkRed} !important` }}
          >
            Delete Rule
          </LoadingButton>
        ) : (
          <Button>
            <Spinner />
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDeleteModal;
