import { Box, Skeleton } from "@mui/material";
import { CHERRY_APP_VBILL_RESIZE_DIM_KEY } from "common/helpers/localStorage";
import { SidebarDraggableSeparator } from "components/common/SidebarDraggableSeparator/SidebarDraggableSeparator";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import { useMemo, useRef } from "react";
import { ResizeCallbackArgs, useResizable } from "react-resizable-layout";
import { useStore } from "storeContainer";
import { ISidebarTabsRef, SidebarTabs } from "./SidebarTabs/SidebarTabs";

export const VBillSidebar = observer(() => {
  const vBillStore = useStore("VBillStore");

  const resizableConfigDimensions = useMemo(() => {
    const windowInnerWidth = window.innerWidth;
    const percent40WidthPx = windowInnerWidth * 0.4;
    const minRightPx = 500;
    const minLeftPx = 500;
    const vBillLSResizeDim = window.localStorage.getItem(CHERRY_APP_VBILL_RESIZE_DIM_KEY);

    return {
      min: minRightPx,
      max: windowInnerWidth - minLeftPx,
      initial: isNil(vBillLSResizeDim)
        ? percent40WidthPx < minRightPx
          ? minRightPx
          : percent40WidthPx
        : Number(vBillLSResizeDim),
    };
  }, []);
  const {
    isDragging: isResizableDragging,
    position: resizableWidth,
    separatorProps: resizableSeparatorProps,
  } = useResizable({
    ...resizableConfigDimensions,
    axis: "x",
    reverse: true,
    onResizeEnd: handleResizableDragEnd,
  });
  const sidebarTabsRef = useRef<ISidebarTabsRef>(null);

  function handleResizableDragEnd({ position }: ResizeCallbackArgs) {
    sidebarTabsRef.current?.tabsUpdateIndicator();
    window.localStorage.setItem(CHERRY_APP_VBILL_RESIZE_DIM_KEY, `${position}`);
  }

  return (
    <>
      <SidebarDraggableSeparator isDragging={isResizableDragging} sx={{ zIndex: "2" }} {...resizableSeparatorProps} />
      <Box
        sx={{
          flexGrow: 0,
          flexShrink: 0,
          flexBasis: "auto",
          ...(isResizableDragging ? { filter: "blur(5px)" } : {}),
        }}
        style={{ width: resizableWidth }}
      >
        {vBillStore.invoice.isLoading ? (
          <Skeleton variant="rounded" sx={{ margin: "10px 30px 10px 10px", height: "490px" }} />
        ) : (
          <SidebarTabs ref={sidebarTabsRef} />
        )}
      </Box>
    </>
  );
});
