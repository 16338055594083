import { Box, SxProps, Theme, Tooltip, alpha } from "@mui/material";
import { DragIndicatorIcon } from "components/common/icons";
import { FunctionComponent } from "react";
import { SeparatorProps } from "react-resizable-layout";
import { COLORS } from "themes/default";

interface ISidebarDraggableSeparatorProps extends SeparatorProps {
  isDragging: boolean;
  sx?: SxProps<Theme>;
}

export const SidebarDraggableSeparator: FunctionComponent<ISidebarDraggableSeparatorProps> = ({
  isDragging,
  sx,
  ...restOfProps
}) => (
  <Box
    tabIndex={0}
    sx={{
      position: "relative",
      flexShrink: 0,
      width: "5px",
      cursor: "col-resize",
      "&:hover": {
        backgroundColor: COLORS.newSecondaryColor,
      },
      transition: ".25s",
      ...sx,
      ...(isDragging ? { backgroundColor: COLORS.newSecondaryColor } : {}),
    }}
    {...restOfProps}
  >
    <Tooltip arrow title="Resize">
      <Box
        component="span"
        sx={{
          position: "absolute",
          top: "220px",
          left: "-6px",
          backgroundColor: COLORS.ghostWhite,
          borderRadius: "4px",
          padding: "8px 0",
          transition: ".25s",
          border: `1px solid ${alpha(COLORS.logoBlue, 0.1)}`,

          "&:hover": {
            backgroundColor: COLORS.azureWhite,
          },
        }}
      >
        <DragIndicatorIcon />
      </Box>
    </Tooltip>
  </Box>
);
