import { Alert, Box } from "@mui/material";
import { ClearVendorRulesButton } from "components/common/Approval/ClearVendorRulesButton";
import { DelegationTreeView } from "components/common/Approval/DelegationTreeView";
import { getApprovalFlags } from "components/common/Approval/getApprovalFlags";
import { useVendorGroups } from "components/common/Approval/useVendorGroups";
import { DelegationGroupScopeEnum } from "generated/sdk";
import { observer } from "mobx-react";
import { useMemo } from "react";
import { useStore } from "storeContainer";
import { vBillChatPanels, vBillSidebarPanels } from "../SidebarTabs/SidebarTabs";
import { TabPanel } from "../SidebarTabs/TabPanel/TabPanel";

interface IApprovalProps {
  activePannel: vBillSidebarPanels | vBillChatPanels;
}

export const Approval = observer(({ activePannel }: IApprovalProps) => {
  const vBillStore = useStore("VBillStore");
  const sessionStore = useStore("SessionStore");
  const { invoice } = vBillStore;
  const invoiceData = useMemo(() => invoice?.data, [invoice?.data]);
  const [vendorGroupsResponse, vendorGroupsLoading, refreshVendorRules] = useVendorGroups(invoiceData);

  const showApprovalTab = invoiceData?.approvalStatus
    ? getApprovalFlags(invoiceData?.approvalStatus, sessionStore.selectedOrganizationUserId!).showApprovalWidget
    : false;

  if (!showApprovalTab) {
    return null;
  }

  return (
    <TabPanel activePannel={activePannel} panelName={vBillSidebarPanels.APPROVAL}>
      {invoiceData?.approvalStatus ? (
        <>
          <ClearVendorRulesButton
            scope={DelegationGroupScopeEnum.VbillApprove}
            isRootDelegator={vendorGroupsResponse?.is_root_delegator!}
            refreshVendorRules={refreshVendorRules}
          />
          <Box className="tree-wrapper" sx={{ width: "610px", height: "500px", margin: "0 auto" }}>
            <DelegationTreeView
              approvalStatus={invoiceData?.approvalStatus}
              organizationId={invoiceData?.coreOrgId}
              billId={invoiceData?.hashedId}
              translate={{ x: 305, y: 50 }}
            />
          </Box>
        </>
      ) : (
        <Box>
          <Alert severity="info">
            The list of approvers will become available once the bill is ready for approval.
          </Alert>
        </Box>
      )}
    </TabPanel>
  );
});
