import { IconButton } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import React, { ReactNode, useState } from "react";

interface IButtonDialogConfirm {
  buttonText?: string;
  contentText: ReactNode;
  disabled: boolean;
  Icon?: ReactNode;
  onAgree: () => void;
  onDisagree: () => void;
  variant?: "text" | "outlined" | "contained";
  title: string;
  isIconButton?: boolean;
}

const ButtonDialogConfirm = ({
  buttonText,
  variant,
  disabled,
  title,
  contentText,
  onAgree,
  onDisagree,
  Icon,
  isIconButton,
}: IButtonDialogConfirm) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAgreeClick = () => {
    onAgree();
    handleClose();
  };
  const handleDisagreeClick = () => {
    onDisagree();
    handleClose();
  };
  return (
    <div>
      {isIconButton ? (
        <IconButton disabled={disabled} onClick={handleClickOpen} title={title}>
          {Icon}
        </IconButton>
      ) : (
        <Button
          variant={variant}
          disabled={disabled}
          onClick={handleClickOpen}
          endIcon={Icon ? Icon : null}
          title={title}
        >
          {buttonText}
        </Button>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
      >
        <DialogTitle id="confirm-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-dialog-description">{contentText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleDisagreeClick}>
            Disagree
          </Button>
          <Button variant="contained" onClick={handleAgreeClick} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default ButtonDialogConfirm;
