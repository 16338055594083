import { Button, Grid, Typography } from "@mui/material";
import { Spinner } from "components/common/Spinner/Spinner";
import { FeatureEnumType } from "generated/sdk";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import { useEffect, useMemo, useState } from "react";
import { useParams, useRouteMatch } from "react-router-dom";
import { useStore } from "storeContainer";

export const FeatureAccessRequest = observer(function FeatureAccessRequest() {
  const [requested, setRequested] = useState<boolean | undefined>(undefined);

  const sessionStore = useStore("SessionStore");
  const contextStore = useStore("ContextStore");
  const { createFeatureAccessRequest } = useStore("FeatureAccessRequestStore");
  const { path } = useRouteMatch();

  const { organizationId } = useParams<{ organizationId?: string }>();

  const feature = useMemo(() => {
    switch (true) {
      case path.includes("bchecks"):
        return { value: FeatureEnumType.BudgetManagement, label: "Budget Management" };
      case path.includes("vbills-inactive"):
        return { value: FeatureEnumType.Bills, label: "Bills" };
      case path.includes("invoice"):
        return { value: FeatureEnumType.Invoices, label: "Invoices" };
      default:
        return undefined;
    }
  }, [path]);

  const features = useMemo(() => {
    const organizationUsers = sessionStore?.session.data?.organizationUsers;

    if (!organizationUsers || !organizationId) {
      return [];
    }

    const featuresArray = organizationUsers
      .filter((orgUser) => orgUser.organization.id === organizationId)
      .flatMap((orgUser) => orgUser.organization.feature_access_requests?.map((request) => request?.feature) || []);

    return featuresArray.filter((feature) => !isNil(feature));
  }, [sessionStore?.session.data?.organizationUsers, organizationId]);

  useEffect(() => {
    if (!requested && feature && !features.includes(feature.value)) {
      setRequested(false);
    } else {
      setRequested(true);
    }
  }, [features, requested, feature]);

  // Handle the button click to send the mutation
  const handleRequestAccess = async () => {
    if (feature) {
      sessionStore.updateFeatureAccessRequest(feature.value, organizationId as string);
      await createFeatureAccessRequest(feature.value, organizationId as string);
    }
    setRequested(true);
  };

  return (
    <Grid justifyContent={"center"} container>
      <Grid
        sx={{
          border: "1.5px solid #E0F2F9",
          padding: "40px",
          height: "fit-content",
          width: "800px",
          bgcolor: "white",
          minHeight: "600px",
        }}
        flexDirection={"column"}
        item
        container
      >
        {requested !== undefined ? (
          requested ? (
            <>
              <Grid item>
                <Typography fontWeight={500} fontSize={24}>
                  {feature?.label} - Pending Activation
                </Typography>
              </Grid>
              <Grid item sx={{ width: "100%", marginTop: 5, marginBottom: 3 }}>
                <Typography>
                  A request for access to {feature?.label} on your organization’s behalf was already submitted and it’s
                  being reviewed.
                </Typography>
              </Grid>
            </>
          ) : (
            <>
              <Grid item>
                <Typography fontWeight={500} fontSize={24}>
                  {feature?.label} - Inactive
                </Typography>
              </Grid>
              <Grid item sx={{ width: "100%", marginTop: 5, marginBottom: 3 }}>
                <Typography>
                  The feature <strong>{feature?.label}</strong> is currently not enabled on your organization, you can
                  request access to it by clicking on the button below:
                </Typography>

                <Button
                  sx={{
                    marginTop: 3,
                    padding: "10px 15px",
                    fontWeight: "600",
                    fontSize: 16,
                    textTransform: "capitalize",
                  }}
                  color="secondary"
                  variant="contained"
                  onClick={handleRequestAccess}
                >
                  Request Access to {feature?.label}
                </Button>
              </Grid>
            </>
          )
        ) : (
          <Spinner />
        )}
      </Grid>
    </Grid>
  );
});
