import { Box, Button, Typography } from "@mui/material";
import { Spinner } from "components/common/Spinner/Spinner";
import { LoopIcon } from "components/common/icons";
import { IChatMessageType } from "generated/graphql.chat";
import {
  IChatChatIoMemberChannelNotificationStatsOutput,
  IChatGetMessagesRelativeToMessageType,
} from "generated/sdk.chat";
import { isEqual } from "lodash";
import { observer } from "mobx-react";
import { useChatChannelStore } from "modules/Chat/hooks/hooks";
import { Fragment, FunctionComponent, useEffect, useRef, useState } from "react";
import { useSetState } from "react-use";
import { ChatMessage } from "./ChatMessage/ChatMessage";
import { MessagesScrollEndIndicator } from "./ChatMessage/MessagesScrollEndIndicator/MessagesScrollEndIndicator";
import { SystemMessage } from "./SystemMessage/SystemMessage";

export const ChatMessages: FunctionComponent = observer(() => {
  const chatChannelStore = useChatChannelStore();
  const messagesContainerDomRef = useRef<HTMLDivElement | null>(null);
  const [scrollEndIndicator, setScrollEndIndicator] = useState(true);
  const [loadingMessagesDirection, setLoadingMessagesDirection] = useSetState({ after: false, before: false });
  const prevChannelMessageNotification = useRef<IChatChatIoMemberChannelNotificationStatsOutput | undefined>();

  useEffect(() => {
    if (
      chatChannelStore?.channelMessageNotification &&
      !isEqual(chatChannelStore?.channelMessageNotification, prevChannelMessageNotification.current)
    ) {
      setLoadingMessagesDirection({ after: true });
      chatChannelStore?.loadMessagesRelativeToMessage(IChatGetMessagesRelativeToMessageType.After).then(() => {
        setLoadingMessagesDirection({ after: false });
        prevChannelMessageNotification.current = chatChannelStore?.channelMessageNotification;
      });
    }
  }, [chatChannelStore?.channelMessageNotification, chatChannelStore, setLoadingMessagesDirection]);

  // used in MembserStore for notifications
  useEffect(() => {
    chatChannelStore?.setisChannelVisibleInViewport(true);

    return () => {
      chatChannelStore?.setisChannelVisibleInViewport(false);
    };
  }, [chatChannelStore]);

  useEffect(() => {
    if (scrollEndIndicator) {
      scrollToBottom();
    }
  }, [chatChannelStore?.orderedMessages, scrollEndIndicator]);

  const handleLoadBeforeMessages = () => {
    setLoadingMessagesDirection({ before: true });
    chatChannelStore?.loadMessagesRelativeToMessage(IChatGetMessagesRelativeToMessageType.Before).then(() => {
      setLoadingMessagesDirection({ before: false });
    });
  };

  const scrollToBottom = () => {
    if (!messagesContainerDomRef.current) {
      return;
    }

    const messagesDomScrollHeight = messagesContainerDomRef.current.scrollHeight;
    messagesContainerDomRef.current.scrollTop = messagesDomScrollHeight;
  };

  return (
    <Box
      ref={messagesContainerDomRef}
      sx={{ overflowY: "auto", padding: "20px", minHeight: "300px", position: "relative", flexGrow: 1 }}
    >
      {!chatChannelStore?.channelComingSoon &&
        !loadingMessagesDirection.after &&
        chatChannelStore?.orderedMessages.length === 0 && (
          <Typography sx={{ fontWeight: 500, fontSize: 14, textAlign: "center" }}>No Messages</Typography>
        )}

      {chatChannelStore?.hasMoreMessagesBefore && (
        <Box sx={{ textAlign: "center" }}>
          {loadingMessagesDirection.before ? (
            <Spinner />
          ) : (
            <Button onClick={handleLoadBeforeMessages} endIcon={<LoopIcon />} sx={{ padding: 0, height: "20px" }}>
              Load older messages
            </Button>
          )}
        </Box>
      )}

      {chatChannelStore?.orderedMessages.map((message) => (
        <Fragment key={message.id}>
          {message.type === IChatMessageType.Chat && <ChatMessage message={message} />}
          {message.type === IChatMessageType.System && <SystemMessage message={message} />}
        </Fragment>
      ))}

      {/* {!!chatChannelStore?.channelNotifications?.length && (
        <Box sx={{ textAlign: "center", position: "sticky", left: 0, right: 0, bottom: 0, zIndex: 1 }}>
          <Button onClick={() => scrollToBottom()} endIcon={<LoopIcon />} sx={{ padding: 0, height: "20px" }}>
            Load new messages
          </Button>
        </Box>
      )} */}

      <MessagesScrollEndIndicator onScrollEndIndicator={setScrollEndIndicator} />

      {loadingMessagesDirection.after && (
        <Box sx={{ textAlign: "center" }}>
          <Spinner />
        </Box>
      )}
    </Box>
  );
});
