import { IVBillBatchListFiltersInput, IVBillSortOrderType } from "generated/sdk.vbill";
import { useMemo } from "react";
import { useBatchLedgerFilters, useVBillBatchLedgerChildRoute } from "../hooks";

interface ITableHeadCell {
  id: keyof IVBillBatchListFiltersInput | string;
  sortable?: boolean;
  label: string;
  value?: IVBillSortOrderType | null;
  onChange?: (value: IVBillSortOrderType) => void;
}

export function useTableHeadCells() {
  const [batchLedgerFilters, setBatchLedgerFilters] = useBatchLedgerFilters();
  const { isAllChildRoute } = useVBillBatchLedgerChildRoute();

  const tableHeadCells: ITableHeadCell[] = useMemo(
    () => [
      {
        id: "vbill_batch_number_sort",
        sortable: true,
        label: "Batch #",
        value: batchLedgerFilters.filters?.vbill_batch_number_sort,
        onChange: (value) =>
          setBatchLedgerFilters((prev) => ({
            filters: { ...prev.filters, budget_sort: undefined, vbill_batch_number_sort: value },
          })),
      },
      {
        id: "name",
        sortable: false,
        label: "Name",
      },
      {
        id: "companies",
        sortable: false,
        label: "Companies",
      },
      {
        id: "bills",
        sortable: false,
        label: "Bills",
      },
      {
        id: "budget_sort",
        sortable: true,
        label: "Budget",
        value: batchLedgerFilters.filters?.budget_sort,
        onChange: (value) =>
          setBatchLedgerFilters((prev) => ({
            filters: { ...prev.filters, vbill_batch_number_sort: undefined, budget_sort: value },
          })),
      },
      {
        id: "payments",
        sortable: false,
        label: "Payments",
      },
      {
        id: "created_by",
        sortable: false,
        label: "Created",
      },
      {
        id: "date",
        sortable: false,
        label: "Date",
      },
      ...(isAllChildRoute
        ? [
            {
              id: "status",
              label: "Status",
            },
          ]
        : []),
    ],
    [
      batchLedgerFilters.filters?.vbill_batch_number_sort,
      batchLedgerFilters.filters?.budget_sort,
      isAllChildRoute,
      setBatchLedgerFilters,
    ],
  );

  return tableHeadCells;
}
