import { SxProps, Theme } from "@mui/material";

export const vBillLedgerTHeadSx: SxProps<Theme> = {
  lineHeight: "14px",
  backgroundColor: "#F5F5F5",
  whiteSpace: "nowrap",
};

export const vBillQuickFilterCircleSx: SxProps<Theme> = {
  width: "32px",
  height: "32px",
  border: "1px solid #ECBB71",
  backgroundColor: "#FFF3E1",
  borderRadius: "16px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};
