import CloseIcon from "@mui/icons-material/Close";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Button, Dialog, Divider, IconButton, Tooltip, Typography } from "@mui/material";
import { CHDecimal } from "common/helpers/decimal";
import { formatCurrency } from "common/helpers/utils";
import { TVBillFormFields, TVBillFormLineFields } from "components/pages/VBill/types";
import { useMemo, useState } from "react";
import { useWatch } from "react-hook-form";
import { COLORS } from "themes/default";

interface IIncomingToolbarTotalDifferenceAlertProps {
  onCloseDialog: () => void;
  onSubmitDialog: () => Promise<void>;
}

export const IncomingToolbarTotalDifferenceAlert = ({
  onCloseDialog,
  onSubmitDialog,
}: IIncomingToolbarTotalDifferenceAlertProps) => {
  const vBillFormlineItemsObserver: TVBillFormLineFields[] = useWatch({ name: "lineItems" });
  const vBillFormTotalObserver: TVBillFormFields["amount"] = useWatch({ name: "amount" });
  const [isSubmitReqPending, setIsSubmitReqPending] = useState(false);
  const totalFormItemsAmount = useMemo(
    () =>
      (vBillFormlineItemsObserver ?? []).reduce((acc, val) => {
        if (val.amount === "-") {
          return acc;
        }

        return (acc = CHDecimal.add(acc, val.amount.length ? val.amount : "0").toString());
      }, "0"),
    [vBillFormlineItemsObserver],
  );

  const handleAcceptBtnClick = async () => {
    setIsSubmitReqPending(true);
    await onSubmitDialog();
    setIsSubmitReqPending(false);
  };

  return (
    <Dialog
      open
      onClose={onCloseDialog}
      PaperProps={{
        sx: { padding: "15px", width: "280px", position: "relative" },
      }}
    >
      <Tooltip arrow title="Close">
        <IconButton onClick={onCloseDialog} sx={{ position: "absolute", top: "5px", right: "5px", zIndex: 1 }}>
          <CloseIcon sx={{ fontSize: "20px" }} />
        </IconButton>
      </Tooltip>
      <Typography sx={{ fontSize: "20px", marginBottom: "20px" }}>Update total amount</Typography>

      <Typography sx={{ color: COLORS.error, marginBottom: "10px" }}>
        Total line items amount is different from total bill amount.
      </Typography>

      <Typography>
        Accept new total from <strong>{formatCurrency(Number(vBillFormTotalObserver))}</strong> to{" "}
        <strong>{formatCurrency(Number(totalFormItemsAmount))}</strong> ?
      </Typography>

      <Divider sx={{ margin: "15px 0" }} />

      <Box sx={{ display: "flex", justifyContent: "end", gap: "15px" }}>
        <LoadingButton
          loading={isSubmitReqPending}
          variant="contained"
          onClick={handleAcceptBtnClick}
          sx={{ height: "30px" }}
        >
          Accept
        </LoadingButton>

        <Button variant="outlined" onClick={onCloseDialog} sx={{ height: "30px" }}>
          Cancel
        </Button>
      </Box>
    </Dialog>
  );
};
