import { Box, Divider, IconButton, Skeleton, Tooltip, Typography } from "@mui/material";
import { TrashIcon } from "components/common/icons";
import { VBillPayBillStoreItemSelector } from "components/pages/common/VBill/VBillPayBillStoreItemSelector/VBillPayBillStoreItemSelector";
import { IVBillSingleSelectAutocompleteSuggestion } from "components/pages/common/VBill/VBillSuggestionsAutocomplete/VBillSuggestionsSingleSelectAutocomplete/VBillSuggestionsSingleSelectAutocomplete";
import { IVBillIIntegrationIntegrationSettingsVBillAdditionalMappingsEntryShowIn } from "generated/sdk.vbill";
import { observer } from "mobx-react";
import { useEffect, useMemo } from "react";
import { Controller, useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useStore } from "storeContainer";
import { usePayVBillsCompaniesMappingsSettings } from "../../PayVBillsCompaniesMappingsSettings";
import { IPayVBillsFormFields } from "../../PayVBillsDialog";
import { PayVBillLine } from "./PayVBillLine/PayVBillLine";
import { PayVBillsGroupTotal } from "./PayVBillsGroupTotal/PayVBillsGroupTotal";

interface IPayVBillsGroupProps {
  groupIndex: number;
  onRemoveGroup: () => void;
}

export const PayVBillsGroup = observer(function PayVBillsGroup({ groupIndex, onRemoveGroup }: IPayVBillsGroupProps) {
  const organizationStore = useStore("OrganizationStore");
  const vBillPayLedgerStore = useStore("VBillPayLedgerStore");
  const { organizationId } = useParams<{ organizationId: string }>();
  const payVBillsForm = useFormContext<IPayVBillsFormFields>();
  const payVBillsCompaniesMappingsSettings = usePayVBillsCompaniesMappingsSettings();
  const payVBillsFormGroup = useFieldArray<IPayVBillsFormFields>({ name: `groups.${groupIndex}.bills` });
  const groupVendorNameFieldWatcher = useWatch({
    name: `groups.${groupIndex}.vendorName`,
  }) as IPayVBillsFormFields["groups"][number]["vendorName"];
  const groupCompanyIdFieldWatcher = useWatch({
    name: `groups.${groupIndex}.companyId`,
  }) as IPayVBillsFormFields["groups"][number]["companyId"];

  const companyName = useMemo(
    () =>
      (organizationStore.organizationVBillEnabledCompanies.data?.items ?? []).find(
        (comp) => comp.companyId === groupCompanyIdFieldWatcher,
      )?.name,
    [groupCompanyIdFieldWatcher, organizationStore.organizationVBillEnabledCompanies.data?.items],
  );
  const payFromAdditionalMapping = useMemo(
    () =>
      payVBillsCompaniesMappingsSettings.data
        ?.get(groupCompanyIdFieldWatcher ?? "")
        ?.find(
          ({ showIn }) => showIn === IVBillIIntegrationIntegrationSettingsVBillAdditionalMappingsEntryShowIn.Payment,
        ),
    [groupCompanyIdFieldWatcher, payVBillsCompaniesMappingsSettings],
  );

  useEffect(() => {
    if (payVBillsFormGroup.fields.length === 0) {
      onRemoveGroup();
    }
  }, [onRemoveGroup, payVBillsFormGroup.fields.length]);

  const { selectedBillIdsToPay } = vBillPayLedgerStore;

  const handleRemoveGroupBtnClick = () => {
    const formGroupBillsId = (payVBillsForm.getValues().groups[groupIndex].bills ?? []).map(({ billId }) => billId);
    const currentSelectedBillIdsToPay = selectedBillIdsToPay.filter((id) => !formGroupBillsId.includes(id));

    onRemoveGroup();
    vBillPayLedgerStore.updateSelectedBillIdsToPay(currentSelectedBillIdsToPay);
  };

  const handlePayFromSelectorChange = (option?: IVBillSingleSelectAutocompleteSuggestion) => {
    const groupCurrentValues = payVBillsForm.getValues().groups[groupIndex];

    payVBillsForm.setValue(`groups.${groupIndex}.paymentFromGlAccount`, option);

    groupCurrentValues.bills.forEach((_, billIndex) => {
      payVBillsForm.setValue(`groups.${groupIndex}.bills.${billIndex}.paymentFromGlAccount`, option, {
        shouldValidate: !!option,
      });
    });
  };

  const handleBillLinePayFromSelectorChange = () => {
    const groupCurrenPayFrom = payVBillsForm.getValues().groups[groupIndex].paymentFromGlAccount;

    if (groupCurrenPayFrom && payVBillsFormGroup.fields.length > 1) {
      payVBillsForm.setValue(`groups.${groupIndex}.paymentFromGlAccount`, undefined);
    }
  };

  return (
    <Box sx={{ marginBottom: "15px" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "10px",
          backgroundColor: "#f1f1f1",
          padding: "2px 10px",
          marginBottom: "5px",
        }}
      >
        <Box>
          <Typography>
            <strong>Vendor: </strong>
            {groupVendorNameFieldWatcher ?? "-"}
          </Typography>
          <Typography>
            <strong>Company: </strong>
            {companyName ?? "-"}
          </Typography>
        </Box>

        <Tooltip title="Remove group from payment">
          <IconButton onClick={handleRemoveGroupBtnClick}>
            <TrashIcon sx={{ fontSize: "14px" }} />
          </IconButton>
        </Tooltip>
      </Box>

      {payVBillsCompaniesMappingsSettings.isFetching ? (
        <Skeleton variant="rounded" sx={{ width: "200px", height: "30px", margin: "10px 0 10px" }} />
      ) : (
        payFromAdditionalMapping &&
        payVBillsFormGroup.fields.length > 1 && (
          <>
            <Box sx={{ padding: "0 20px 5px" }}>
              <Typography sx={{ marginBottom: "5px" }}>
                Select {payFromAdditionalMapping.label} for all bills:
              </Typography>
              <Box sx={{ width: "200px" }}>
                <Controller
                  name={`groups.${groupIndex}.paymentFromGlAccount`}
                  render={({ field }) => (
                    <VBillPayBillStoreItemSelector
                      selectedStoreItem={field.value}
                      onSelectStoreItem={handlePayFromSelectorChange}
                      additionalMappings={payFromAdditionalMapping}
                      invoiceCompanyId={groupCompanyIdFieldWatcher ?? ""}
                      invoiceCoreOrgId={organizationId}
                      btnSmallHeight
                    />
                  )}
                />
              </Box>
            </Box>

            <Divider sx={{ margin: "10px 0" }} />
          </>
        )
      )}

      {payVBillsFormGroup.fields.map((bill, index) => (
        <PayVBillLine
          key={bill.id}
          onRemoveBill={() => payVBillsFormGroup.remove(index)}
          onPayFromSelectorChange={handleBillLinePayFromSelectorChange}
          groupIndex={groupIndex}
          billIndex={index}
        />
      ))}

      {payVBillsFormGroup.fields.length > 1 && (
        <>
          <PayVBillsGroupTotal groupIndex={groupIndex} />

          <Divider sx={{ margin: "10px 0" }} />
        </>
      )}
    </Box>
  );
});
