import { Box } from "@mui/material";
import { COLORS } from "themes/default";

export const VBillReimbursementBadge = () => (
  <Box
    sx={{
      position: "absolute",
      zIndex: "1",
      right: "-40px",
      top: "-16px",
      border: `1px solid ${COLORS.newPrimaryColor}`,
      borderRadius: "4px",
      color: COLORS.newPrimaryColor,
      fontSize: "10px",
      padding: "0px 4px",
    }}
  >
    Expense
  </Box>
);
