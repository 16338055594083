import { SvgIcon, SvgIconProps } from "@mui/material";

export const MoreHorizIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g opacity="0.5">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.5 14C3.5 12.7114 4.54467 11.6667 5.83333 11.6667C7.122 11.6667 8.16667 12.7114 8.16667 14C8.16667 15.2887 7.122 16.3334 5.83333 16.3334C4.54467 16.3334 3.5 15.2887 3.5 14ZM11.6667 14C11.6667 12.7114 12.7113 11.6667 14 11.6667C15.2887 11.6667 16.3333 12.7114 16.3333 14C16.3333 15.2887 15.2887 16.3334 14 16.3334C12.7113 16.3334 11.6667 15.2887 11.6667 14ZM19.8333 14C19.8333 12.7114 20.878 11.6667 22.1667 11.6667C23.4553 11.6667 24.5 12.7114 24.5 14C24.5 15.2887 23.4553 16.3334 22.1667 16.3334C20.878 16.3334 19.8333 15.2887 19.8333 14Z"
          fill="#052048"
        />
      </g>
    </SvgIcon>
  );
};
