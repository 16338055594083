import { Box, Button, Typography } from "@mui/material";
import {
  IVBillBillsFiltersMappingOutput,
  IVBillIntegrationSettingsVBillAdditionalMappingsEntryOutput,
} from "generated/sdk.vbill";
import { blueBtnStyles, tooltipTypographyStyles } from "../../summariesStyles";

interface ISummariesAdditionalMappingProps {
  mapping: IVBillIntegrationSettingsVBillAdditionalMappingsEntryOutput;
  filtersMapping?: IVBillBillsFiltersMappingOutput;
  handleAddRemoveBtnClick: () => void;
}

export const SummariesAdditionalMapping = ({
  mapping,
  filtersMapping,
  handleAddRemoveBtnClick,
}: ISummariesAdditionalMappingProps) => {
  return (
    <Box sx={{ display: "flex", gap: "10px", alignItems: "center", justifyContent: "space-between" }}>
      <Typography sx={{ ...tooltipTypographyStyles, whiteSpace: "nowrap" }}>
        {mapping.label}: <strong>({filtersMapping?.valuesIn?.length ?? 0})</strong>
      </Typography>

      <Button onClick={handleAddRemoveBtnClick} sx={{ ...blueBtnStyles, fontSize: "12px", height: "20px" }}>
        Add/Remove
      </Button>
    </Box>
  );
};
