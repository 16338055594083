import { useCallback, useEffect, useState } from "react";

export function useDocumentVisibilityChange() {
  const [isDocumentVisible, setIsDocumentVisible] = useState(true);

  const visibilitychangeListner = useCallback(() => {
    setIsDocumentVisible(document.visibilityState === "visible");
  }, []);

  useEffect(() => {
    document.addEventListener("visibilitychange", visibilitychangeListner);

    return () => document.removeEventListener("visibilitychange", visibilitychangeListner);
  }, [visibilitychangeListner]);

  return isDocumentVisible;
}
