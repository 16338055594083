import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

import {
  AllBasePermissionType,
  IAclPermissionRow,
  IPermissionGroupWithSettings,
} from "../../../../../../storesMobx/AclStore";
import {
  AclOnChangeHanddler,
  AclPermissionGroupName,
  extractLimit,
  StyledTableCell,
  StyledTableRow,
} from "./Permissions";

import CloseIcon from "@mui/icons-material/Close";
import * as React from "react";
import { useState } from "react";
import { definitelyFilter } from "../../../../../../generated/utils";
import { COLORS } from "../../../../../../themes/default";
import { AclInheritedPermissions } from "./AclInheritedPermissions";
import { AclPermLimitEdit } from "./AclPermLimitEdit";
import { AclPermLimitView } from "./AclPermLimitView";
import { AclSource } from "./AclSource";

const updateInput = (ref: any, checked: any) => {
  const input = ref.current;
  if (input) {
    input.checked = checked;
    input.indeterminate = checked == null;
  }
};

export const ThreeStateCheckbox = ({ name, checked, onChange }: any) => {
  const inputRef = React.useRef(null);
  const checkedRef = React.useRef(checked);
  React.useEffect(() => {
    checkedRef.current = checked;
    updateInput(inputRef, checked);
  }, [checked]);
  const handleClick = () => {
    switch (checkedRef.current) {
      case true:
        checkedRef.current = false;
        break;
      case false:
        checkedRef.current = null;
        break;
      default:
        // null
        checkedRef.current = true;
        break;
    }
    updateInput(inputRef, checkedRef.current);
    if (onChange) {
      onChange(checkedRef.current);
    }
  };
  return <input ref={inputRef} type="checkbox" name={name} onClick={handleClick} />;
};

export const AclEntityRow = ({
  row,
  rowIdx,
  pg,
  onChange,
  expanded,
  expandId,
  colapseId,
}: {
  row: IAclPermissionRow;
  rowIdx: number;
  pg: IPermissionGroupWithSettings[];
  onChange: AclOnChangeHanddler;
  expanded: {
    [entyty_id: string]: true | undefined;
  };
  expandId: (entityId: string) => void;
  colapseId: (entityId: string) => void;
}) => {
  const [isModalOpened, setIsModalOpened] = useState<IPermissionGroupWithSettings | undefined>(undefined);

  const getPGNAME = (permGroup: IPermissionGroupWithSettings) => {
    return permGroup.internal_alias as AclPermissionGroupName;
  };

  const getFriendlyPGNAME = (permGroup: IPermissionGroupWithSettings) => {
    return permGroup.group_name;
  };

  const getViewValue = (permGroup: IPermissionGroupWithSettings) => {
    const rowAggPermissions = row.permAgg[getPGNAME(permGroup)];
    const rowAggPermission = rowAggPermissions ? rowAggPermissions[0] : undefined;
    return extractLimit(getPGNAME(permGroup), rowAggPermission);
  };

  const getEditValue = (permGroup: IPermissionGroupWithSettings) => {
    const rowPermissions = getRowPermissions(permGroup);
    const rowDirectPermission: AllBasePermissionType | undefined = rowPermissions.filter((p) => p.direct)[0];
    return extractLimit(getPGNAME(permGroup), rowDirectPermission);
  };

  const hasDirect = (permGroup: IPermissionGroupWithSettings) => {
    const rowPermissions = getRowPermissions(permGroup);
    const rowDirectPermission: AllBasePermissionType | undefined = rowPermissions.filter((p) => p.direct)[0];
    return !!rowDirectPermission;
  };

  const hasInherited = (permGroup: IPermissionGroupWithSettings) => {
    const rowAggPermissions = row.permAgg[getPGNAME(permGroup)];
    const rowAggPermission = rowAggPermissions ? rowAggPermissions[0] : undefined;
    return rowAggPermission?.inherited;
  };

  const getRowPermissions = (permGroup: IPermissionGroupWithSettings) => {
    return definitelyFilter(row.perm[getPGNAME(permGroup)] as AllBasePermissionType[]);
  };

  const canExpand = () => {
    if (row.entityLevelLast) {
      return false;
    }

    switch (row.entityType) {
      case "ORGANIZATION":
        return pg.filter((pg) => pg.settings.appliesToCompany || pg.settings.appliesToBankAccount).length > 0;
      case "COMPANY":
        return pg.filter((pg) => pg.settings.appliesToBankAccount).length > 0;
      case "BANK_ACCOUNT":
        return false;
    }
  };

  const isVisibile = () => {
    switch (row.entityType) {
      case "ORGANIZATION":
        return pg.filter((pg) => pg.settings.appliesToOrganization).length > 0;
      case "COMPANY":
        return pg.filter((pg) => pg.settings.appliesToCompany).length > 0;
      case "BANK_ACCOUNT":
        return pg.filter((pg) => pg.settings.appliesToBankAccount).length > 0;
    }
  };

  if (!isVisibile()) return null;

  const modal = (
    <>
      <Drawer anchor="right" open={!!isModalOpened} onClose={() => setIsModalOpened(undefined)}>
        {!!isModalOpened && (
          <Box sx={{ p: 2, display: "flex", flexDirection: "column", width: "500px", gap: 1 }}>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <Typography variant="h6" sx={{ fontWeight: 400 }} color={COLORS.dark}>
                {getFriendlyPGNAME(isModalOpened)}
              </Typography>
              <IconButton onClick={() => setIsModalOpened(undefined)}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </Box>
            <Card variant="outlined" sx={{ p: 2 }}>
              <Typography variant="body1" color={COLORS.darkGrey} sx={{ fontWeight: 500 }}>
                {row.name}
              </Typography>
              <Typography variant="body1" color={COLORS.mediumGrey} sx={{ fontWeight: 500 }}>
                {row.entityType}
              </Typography>
            </Card>
            <Typography variant="h6" sx={{ fontWeight: 400 }} color={COLORS.dark}>
              Status
            </Typography>
            {hasDirect(isModalOpened) || hasInherited(isModalOpened) ? (
              <>
                Has Access
                <AclPermLimitView
                  rowPermission={undefined}
                  PGNAME={getPGNAME(isModalOpened)}
                  value={getViewValue(isModalOpened)}
                  outlined={undefined}
                />
              </>
            ) : (
              <>No access</>
            )}
            <Divider sx={{ my: 2 }} />
            <AclPermLimitEdit
              PGNAME={getPGNAME(isModalOpened)}
              value={getEditValue(isModalOpened)}
              row={row}
              onChange={onChange}
              hasDirect={hasDirect(isModalOpened)}
            />

            <AclInheritedPermissions PGNAME={getPGNAME(isModalOpened)} row={row} />
          </Box>
        )}
      </Drawer>
    </>
  );

  return (
    <StyledTableRow key={rowIdx}>
      {modal}
      <StyledTableCell
        sx={{ paddingLeft: ` ${row.entityLevelLast ? row.entityLevel * 35 : row.entityLevel * 25}px`, width: "25%" }}
        scope="row"
      >
        <Box sx={{ display: "flex" }}>
          {canExpand() ? (
            <IconButton
              size="large"
              onClick={() => {
                if (!!expanded[row.id]) {
                  colapseId(row.id);
                } else {
                  expandId(row.id);
                }
              }}
            >
              {!!expanded[row.id] ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
            </IconButton>
          ) : (
            <div style={{ display: "block", width: "48px" }}></div>
          )}
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 500 }} color="info">
              {row.name}
            </Typography>
            {!row.entityLevelLast ? (
              <Typography variant="caption">
                {row.entityType === "ORGANIZATION" && <span>{row.childrenCompaniesCount} Companies</span>}{" "}
                {(row.entityType === "ORGANIZATION" || row.entityType === "COMPANY") && (
                  <span>{row.childrenBankaccountsCount} Bank Accounts</span>
                )}
              </Typography>
            ) : null}
          </Box>
        </Box>
      </StyledTableCell>
      {pg.map((permGroup) => (
        <>
          {permGroup.settings.hasLimits ? (
            <StyledTableCell onClick={() => setIsModalOpened(permGroup)}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: permGroup.internal_alias === "BANK_ACCOUNT_CHECK_CREATE" ? "left" : "center",
                  cursor: "pointer",
                }}
              >
                {hasDirect(permGroup) || hasInherited(permGroup) ? (
                  <>
                    <Box sx={{ mr: 1 }}>
                      <AclSource
                        hasDirect={hasDirect(permGroup)}
                        hasInherited={hasInherited(permGroup)}
                        rowPermissions={getRowPermissions(permGroup)}
                      />
                    </Box>

                    <AclPermLimitView
                      rowPermission={undefined}
                      PGNAME={getPGNAME(permGroup)}
                      value={getViewValue(permGroup)}
                      outlined={undefined}
                    />
                  </>
                ) : (
                  <Box sx={{ mr: 1 }}>
                    {/* <ThreeStateCheckbox
                      checked={hasDirect(permGroup) ? true : hasInherited(permGroup) ? null : false}
                      onChange={(checked: boolean | null) => {
                        onChange(getPGNAME(permGroup), row, checked !== false);
                      }}
                    /> */}
                    No access
                  </Box>
                )}
              </Box>
            </StyledTableCell>
          ) : (
            <StyledTableCell>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <AclSource
                  hasDirect={hasDirect(permGroup)}
                  hasInherited={hasInherited(permGroup)}
                  rowPermissions={getRowPermissions(permGroup)}
                />
                {/* <Switch
                  checked={hasDirect(permGroup)}
                  inputProps={{ "aria-label": "controlled" }}
                  onChange={(e) => {
                    onChange(getPGNAME(permGroup), row, e.target.checked);
                  }}
                />
                {hasInherited(permGroup) ? "*" : null} */}
                <label>
                  <ThreeStateCheckbox
                    checked={hasDirect(permGroup) ? true : hasInherited(permGroup) ? null : false}
                    onChange={(checked: boolean | null) => {
                      onChange(getPGNAME(permGroup), row, checked !== false);
                    }}
                  />

                  {hasDirect(permGroup) || hasInherited(permGroup) ? "Has access" : "No access"}
                </label>
              </Box>
            </StyledTableCell>
          )}
        </>
      ))}
    </StyledTableRow>
  );
};
