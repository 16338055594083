import { AclPermissionGroupName } from "./Permissions";
import { AclPermLimitSimpleMaxValue } from "./AclPermLimitSimpleMaxValue";
import { AclPermLimitCreateCheckRules } from "./AclPermLimitCreateCheckRules";
import Card from "@mui/material/Card";
import { AllBasePermissionType } from "../../../../../../storesMobx/AclStore";

export const AclPermLimitView = ({
  PGNAME,
  value,
  rowPermission,
  outlined,
}: {
  PGNAME: AclPermissionGroupName;
  value: any;
  rowPermission: AllBasePermissionType | undefined;
  outlined: true | undefined;
}) => {
  return (
    <>
      {PGNAME === "BANK_ACCOUNT_LEDGER_ACCESS" ? (
        <Card
          variant={`${outlined === true ? "outlined" : "elevation"}`}
          elevation={0}
          sx={{
            ...(outlined === true && {
              p: 2,
            }),
          }}
        >
          <AclPermLimitSimpleMaxValue outlined={outlined} rowPermission={rowPermission} value={value} />
        </Card>
      ) : null}
      {PGNAME === "BANK_ACCOUNT_CHECK_SIGNER" ? (
        <Card
          variant={`${outlined === true ? "outlined" : "elevation"}`}
          elevation={0}
          sx={{
            ...(outlined === true && {
              p: 2,
            }),
          }}
        >
          <AclPermLimitSimpleMaxValue outlined={outlined} rowPermission={rowPermission} value={value} />
        </Card>
      ) : null}
      {PGNAME === "BANK_ACCOUNT_CHECK_MANAGE" ? (
        <Card
          variant={`${outlined === true ? "outlined" : "elevation"}`}
          elevation={0}
          sx={{
            ...(outlined === true && {
              p: 2,
            }),
          }}
        >
          <AclPermLimitSimpleMaxValue outlined={outlined} rowPermission={rowPermission} value={value} />
        </Card>
      ) : null}
      {PGNAME === "BANK_ACCOUNT_CHECK_CREATE" ? (
        <Card
          variant={`${outlined === true ? "outlined" : "elevation"}`}
          elevation={0}
          sx={{
            ...(outlined === true && {
              p: 2,
            }),
          }}
        >
          <AclPermLimitCreateCheckRules outlined={outlined} rowPermission={rowPermission} value={value} />
        </Card>
      ) : null}
    </>
  );
};
