import Drawer from "@mui/material/Drawer";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect } from "react";
import { RouterChildContext, useHistory } from "react-router-dom";
import VCheckCheck from "../VCheckCheck";
import { IVCheckModalWrapperProps } from "./types";

const VCheckModalWrapper = ({
  check,
  hasNext,
  hasPrev,
  goNext,
  goPrev,
  isModalOpened,
  modalClose,
  handleStartDepositCheck,
  isCheckBatch = false,
  handleReloadChecks,
  setSelectedRowIds,
}: IVCheckModalWrapperProps) => {
  const history = useHistory<RouterChildContext["router"]["history"]>();

  const handleModalClose = useCallback(() => {
    modalClose();
  }, [modalClose]);

  useEffect(() => {
    const unlisten = history.listen((_location: any, action: any) => {
      if (action === "POP") {
        handleModalClose();
      }
    });

    return () => {
      unlisten();
    };
  }, [handleModalClose, history]);

  return (
    <Drawer
      anchor={"right"}
      open={isModalOpened}
      onClose={handleModalClose}
      ModalProps={{ sx: { width: "960px" } }}
      disablePortal
    >
      <VCheckCheck
        check={check}
        modalClose={modalClose}
        handleStartDepositCheck={handleStartDepositCheck}
        hasNext={hasNext}
        hasPrev={hasPrev}
        goPrev={goPrev}
        goNext={goNext}
        handleReloadChecks={handleReloadChecks}
        setSelectedRowIds={setSelectedRowIds}
        isModal
        isCheckBatch={isCheckBatch}
        isModalOpened={isModalOpened}
      />
    </Drawer>
  );
};

export default observer(VCheckModalWrapper);
