/* eslint-disable use-isnan */
import {
  AppliedPermissionGroup,
  BankAccountCheckCreateAclPermission,
  BankAccountCheckSignerAclPermission,
  BankAccountLedgerAccessAclPermission,
  BankAccountManageCheckAclPermission,
  LimitCreateVChecksRule,
  PermissionGroupInput,
} from "generated/sdk";
import { definitelyFilter } from "generated/utils";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useStore } from "storeContainer";

import { styled, tableCellClasses } from "@mui/material";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Tabs from "@mui/material/Tabs";

import { AllBasePermissionType, IAclPermissionRow, PermissionGroupCategory } from "storesMobx/AclStore";
import { a11yProps, TabPanel } from "../../utils";
import { AclPermissionCategory } from "./AclPermissionCategory";
import { useHistory, useParams } from "react-router-dom";

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary,
    color: theme.palette.info,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    textAlign: "left",
  },
}));

export const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {},
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

interface LinkTabProps {
  label?: string;
  href?: string;
  [key: string]: any; // FIXME: should be other props of Tab
}

function LinkTab(props: LinkTabProps) {
  const history = useHistory();
  return (
    <Tab
      component="a"
      onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        if (props.href) {
          history.push(props.href);
        }
        event.preventDefault();
      }}
      {...props}
    />
  );
}

export type AclPermissionGroupName = keyof Omit<AppliedPermissionGroup, "__typename">;

export const AclPermissions: React.FunctionComponent<{
  organizationId: string;
  organizationUserId: string | null;
  userGroupId: string | null;
  withoutRoutes?: Boolean;
}> = observer(function AclPermissions({ organizationId, organizationUserId, userGroupId, withoutRoutes }) {
  const acl = useStore("AclStore");
  const inheritedPermissions = acl.inheritedPermissions;
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [permissionGroupSelected, setPermissionGroupSelected] = useState(0);
  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setPermissionGroupSelected(newValue);
  };
  const { category } = useParams<{ category: string }>();
  useEffect(() => {
    const idx = Object.values(PermissionGroupCategory)
      .map((e) => `${e}`)
      .indexOf(category);
    if (idx !== -1) {
      setPermissionGroupSelected(idx);
    }
  }, [category]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      await acl.setContext(organizationId, organizationUserId, userGroupId);
      setIsLoading(false);
    })();
  }, [acl, organizationId, organizationUserId, userGroupId]);

  const onChange: AclOnChangeHanddler = (pgName, row, enabled, limits) => {
    limits = limits || {};
    const permsAgg: AllBasePermissionType[] = row.permAgg[pgName] || [];
    const permAgg: AllBasePermissionType | undefined = permsAgg[0] || {};
    if (pgName === "BANK_ACCOUNT_LEDGER_ACCESS") {
      (limits as any).limit_ledger_access_max_amount =
        (limits as any).limit_ledger_access_max_amount ||
        (permAgg as BankAccountLedgerAccessAclPermission).limit_ledger_access_max_amount ||
        -1;
    }
    if (pgName === "BANK_ACCOUNT_CHECK_SIGNER") {
      (limits as any).limit_approve_vchecks_max_amount =
        (limits as any).limit_approve_vchecks_max_amount ||
        (permAgg as BankAccountCheckSignerAclPermission).limit_approve_vchecks_max_amount ||
        -1;
    }
    if (pgName === "BANK_ACCOUNT_CHECK_MANAGE") {
      (limits as any).limit_manage_vchecks_max_amount =
        (limits as any).limit_manage_vchecks_max_amount ||
        (permAgg as BankAccountManageCheckAclPermission).limit_manage_vchecks_max_amount ||
        -1;
    }
    if (pgName === "BANK_ACCOUNT_CHECK_CREATE") {
      (limits as any).limit_create_vchecks_rules = ((limits as any).limit_create_vchecks_rules || []).length
        ? (limits as any).limit_create_vchecks_rules
        : ((permAgg as BankAccountCheckCreateAclPermission).limit_create_vchecks_rules || []).length
          ? (permAgg as BankAccountCheckCreateAclPermission).limit_create_vchecks_rules
          : [{ count: 1, above: 0 }];
    }

    const aclApplyData: PermissionGroupInput = {
      [pgName]: [
        {
          organization_user_id: organizationUserId,
          user_group_id: userGroupId,
          enabled,
          entity_type: row.entityType,
          entity_id: row.id,
          ...limits,
        },
      ],
    };
    setIsLoading(true);
    acl.apply(aclApplyData).finally(() => setIsLoading(false));
  };

  const urlPrefix = organizationUserId
    ? `/org/${organizationId}/users/${organizationUserId}/users2/permissions`
    : `/org/${organizationId}/groups/${userGroupId}/users2/permissions`;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        mt: 2,
        filter: isLoading ? "blur(4px)" : undefined,
        cursor: isLoading ? "wait" : undefined,
        width: "100%",
      }}
    >
      <Tabs
        centered
        value={permissionGroupSelected}
        onChange={handleTabChange}
        textColor="primary"
        indicatorColor="primary"
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          mb: 2,
          flexGrow: 1,
          display: "flex",
        }}
      >
        {Object.values(PermissionGroupCategory).map((category, index) => {
          return (
            <LinkTab
              href={!withoutRoutes ? `${urlPrefix}/${category}` : ""}
              sx={{
                alignItems: "flex-end",
              }}
              wrapped={false}
              key={index}
              label={category}
              {...a11yProps(index)}
            />
          );
        })}
        {/*{acl.PermissionGroups.map((pg, pgidx) => (*/}
        {/*  <Tab sx={{*/}
        {/*    alignItems: 'flex-end',*/}
        {/*  }} wrapped={false} key={pgidx} label={pg.group_name || pg.internal_alias} {...a11yProps(pgidx)} />*/}
        {/*))}*/}
      </Tabs>

      {Object.values(PermissionGroupCategory).map((category, index) => {
        return (
          <TabPanel value={permissionGroupSelected} index={index}>
            <AclPermissionCategory
              key={index}
              pg={acl.PermissionGroups.filter((permissionGroup) => permissionGroup.settings.category === category)}
              inheritedPermissions={inheritedPermissions}
              onChange={onChange}
            />
          </TabPanel>
        );
      })}

      {/*{acl.PermissionGroups.map((pg, pgidx) => (*/}
      {/*  <TabPanel value={permissionGroupSelected} index={pgidx}>*/}
      {/*    <AclPermissionGroup*/}
      {/*      {...{*/}
      {/*        pgidx,*/}
      {/*        pg,*/}
      {/*        onChange,*/}
      {/*        inheritedPermissions,*/}
      {/*      }}*/}
      {/*    />*/}
      {/*  </TabPanel>*/}
      {/*))}*/}
    </Box>
  );
});

export const extractLimit = (PGNAME: AclPermissionGroupName, appliedPemission: AllBasePermissionType | undefined) => {
  if (PGNAME === "BANK_ACCOUNT_LEDGER_ACCESS") {
    return ((appliedPemission || {}) as BankAccountLedgerAccessAclPermission).limit_ledger_access_max_amount;
  }
  if (PGNAME === "BANK_ACCOUNT_CHECK_SIGNER") {
    return ((appliedPemission || {}) as BankAccountCheckSignerAclPermission).limit_approve_vchecks_max_amount;
  }
  if (PGNAME === "BANK_ACCOUNT_CHECK_MANAGE") {
    return ((appliedPemission || {}) as BankAccountManageCheckAclPermission).limit_manage_vchecks_max_amount;
  }
  if (PGNAME === "BANK_ACCOUNT_CHECK_CREATE") {
    return definitelyFilter(
      ((appliedPemission || {}) as BankAccountCheckCreateAclPermission).limit_create_vchecks_rules,
    );
  }
};

export type AclOnChangeHanddler = (
  pgName: AclPermissionGroupName,
  row: IAclPermissionRow,
  enabled: boolean,
  limits?:
    | {}
    | {
        limit_ledger_access_max_amount: number;
      }
    | {
        limit_approve_vchecks_max_amount: number;
      }
    | {
        limit_manage_vchecks_max_amount: number;
      }
    | {
        limit_create_vchecks_rules: LimitCreateVChecksRule[];
      },
) => void;
