import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import WarningIcon from "@mui/icons-material/Warning";
import { Box, Button, styled } from "@mui/material";
import { ReactionEnumType } from "generated/sdk";
import { useState } from "react";

const ReactionButton = styled(Button)`
  padding: 0px;
  min-width: 0px;
  display: inline;
  height: 12px;
`;

export const CheckBatchItemsReactions = ({
  isLoading,
  isBatchClosed,
  likeCount,
  dislikeCount,
  warnCount,
  handleReactionClick,
  itemId,
}: {
  isLoading: boolean;
  isBatchClosed: boolean;
  likeCount: number;
  dislikeCount: number;
  warnCount: number;
  handleReactionClick: (check_batch_item_ids: string[], reaction: ReactionEnumType) => Promise<void>;
  itemId?: string;
}) => {
  const [disabled, setDisabled] = useState(false);
  const handleItemReactionClick = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    reaction: ReactionEnumType,
  ) => {
    setDisabled(true);
    e.preventDefault();
    e.stopPropagation();
    await handleReactionClick([itemId as string], reaction);
    setDisabled(false);
  };
  const likes = likeCount;
  const dislikes = dislikeCount;
  const warnings = warnCount;
  return (
    <Box position="absolute" left="20px" bottom="10px">
      <ReactionButton
        onClick={(e) => handleItemReactionClick(e, ReactionEnumType?.Upvote)}
        disabled={disabled || isLoading || isBatchClosed}
        title={likes?.toString()}
        startIcon={<ThumbUpIcon htmlColor={likes ? "#4CAF50" : "gray"} sx={{ fontSize: "14px" }} />}
        size="small"
      />

      <ReactionButton
        onClick={(e) => handleItemReactionClick(e, ReactionEnumType?.Downvote)}
        disabled={disabled || isLoading || isBatchClosed}
        title={dislikes?.toString()}
        startIcon={<ThumbDownIcon htmlColor={dislikes ? "#F44336" : "gray"} sx={{ fontSize: "14px" }} />}
        size="small"
      />

      <ReactionButton
        onClick={(e) => handleItemReactionClick(e, ReactionEnumType?.Warning)}
        disabled={disabled || isLoading || isBatchClosed}
        title={warnings?.toString()}
        startIcon={<WarningIcon htmlColor={warnings ? "#FF9800" : "gray"} sx={{ fontSize: "14px" }} />}
        size="small"
      />
    </Box>
  );
};
