import Box from "@mui/material/Box";
import { useMemo } from "react";
import styles from "./VCheckCheck.module.scss";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { formatCurrency } from "../../../../common/helpers/utils";

const TabPanel = (props: any) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

function useVCheckColumns() {
  return useMemo(
    () => [
      {
        Header: "Check #",
        accessor: "number",
        Cell: ({ row }: any) => {
          const value = row.original.number;
          return (
            <div className={styles.tableRow}>
              {row.original.role === "recipient" ? (
                <div className={styles.recipient}>
                  <ArrowDownwardIcon className={styles.arrow} />
                  {value}
                </div>
              ) : (
                <div className={styles.sender}>
                  <ArrowUpwardIcon className={styles.arrow} />
                  {value}
                </div>
              )}
            </div>
          );
        },
      },
      {
        Header: "Amount",
        accessor: "amount",
        Cell: ({ value }: any) => {
          return <div>{formatCurrency(value)}</div>;
        },
      },
      {
        Header: "Sender",
        accessor: "senderDummy",
        Cell: ({ row }: any) => {
          return (
            <div className={styles.info}>
              <div className={styles.bank}>{row.original.sender.name}</div>
            </div>
          );
        },
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }: any) => {
          let style = styles.SIGNED;
          switch (value) {
            case "Signed":
              style = styles.SIGNED;
              break;
            case "Completed":
              style = styles.COMPLETED;
              break;
            case "Processing":
              style = styles.PROCESSING;
              break;
            case "Blocked":
              style = styles.BLOCKED;
              break;
            case "Deposited":
              style = styles.DEPOSITED;
              break;
            case "Sent to Recipient":
              style = styles.SENT_TO_RECIPIENT;
              break;
            case "Pending Signature":
              style = styles.PENDING_SIGNATURE;
              break;
            case "Cancelled":
              style = styles.CANCELLED;
              break;
            case "Deleted":
              style = styles.DELETED;
              break;
            default:
              style = styles.COMPLETED;
          }
          return <div className={style}>{value}</div>;
        },
      },
    ],
    [],
  );
}

export { a11yProps, TabPanel, useVCheckColumns };
