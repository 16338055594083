import { Box, Typography } from "@mui/material";
import { getVBillFormattedDate } from "components/pages/common/VBill/utils";
import { observer } from "mobx-react";
import { useStore } from "storeContainer";
import { COLORS } from "themes/default";
import { LightTooltip } from "../../common/LightTooltip/LightTooltip";
import { tooltipDefaultProps, tooltipTypographyStyles, typographyAsBtnStyles } from "../summariesStyles";

export const SummariesByName = observer(function SummariesByName() {
  const vBillBatchDetailsStore = useStore("VBillBatchDetailsStore");

  const { batchDetails } = vBillBatchDetailsStore;
  const batchData = batchDetails.data;

  return (
    <LightTooltip
      {...tooltipDefaultProps}
      title={<Typography sx={tooltipTypographyStyles}>{batchData?.createdByEmail ?? "-"}</Typography>}
    >
      <Typography sx={typographyAsBtnStyles}>
        <Box sx={{ color: COLORS.logoBlue }}>Created:</Box>
        <strong>{batchData?.createdByName ?? "-"}</strong> /{" "}
        <strong>{getVBillFormattedDate(batchData?.createdAt)} </strong>
      </Typography>
    </LightTooltip>
  );
});
