import { Skeleton } from "@mui/material";
import {
  IVBillIIntegrationIntegrationSettingsVBillAdditionalMappingsEntryShowIn,
  IVBillIntegrationSettingsVBillAdditionalMappingsEntryOutput,
} from "generated/sdk.vbill";
import { observer } from "mobx-react";
import { useMemo } from "react";
import { useStore } from "storeContainer";
import { InvoiceMappingField } from "../../common/Forms/InvoiceMappingField/InvoiceMappingField";

export const NewSelectAdditionalMappings = observer(() => {
  const vBillStore = useStore("VBillStore");
  const { invoice } = vBillStore;
  const invoiceData = useMemo(() => invoice.data, [invoice.data]);
  const invoiceAdditionalMappings = useMemo(
    () =>
      (vBillStore.settings.data?.vBill.additionalMappings.invoice ?? []).filter(
        (additionalMapping) =>
          additionalMapping.showIn !==
            IVBillIIntegrationIntegrationSettingsVBillAdditionalMappingsEntryShowIn.Payment &&
          additionalMapping.showIn !== IVBillIIntegrationIntegrationSettingsVBillAdditionalMappingsEntryShowIn.None &&
          !additionalMapping.isContact,
      ),
    [vBillStore.settings.data?.vBill.additionalMappings.invoice],
  );
  const showSelectMappingsSkeleton = useMemo(
    () => !!vBillStore.invoice.data?.companyId && vBillStore.settings.isLoading,
    [vBillStore.invoice.data?.companyId, vBillStore.settings.isLoading],
  );

  return (
    <>
      {showSelectMappingsSkeleton ? (
        <Skeleton variant="rounded" sx={{ height: "100px" }} />
      ) : (
        invoiceData &&
        !!invoiceAdditionalMappings.length &&
        invoiceAdditionalMappings.map(
          (additionalMapping: IVBillIntegrationSettingsVBillAdditionalMappingsEntryOutput) => (
            <InvoiceMappingField
              key={additionalMapping.storeDataType}
              additionalMapping={additionalMapping}
              // TODO: v2
              // suggestion={suggestions?.additionalMapping?.find(
              //   (addMapSuggestion) =>
              //     addMapSuggestion.type ===
              //     (additionalMapping.storeDataType as unknown as IVBillStoreIoDataType)
              // )}
              invoice={invoiceData}
            />
          ),
        )
      )}
    </>
  );
});
