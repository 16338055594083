import { useRouteMatch } from "react-router";
import { VBillIncomingPath, VBillNewPath, VBillSummaryPath } from "routes/routes";

/*
TODO: remove before merge
export function useUpdateIncomingVBillForm(invoice?: IVBillVBillInvoiceQuery["invoice"] | null) {
  const history = useHistory();
  const { organizationId } = useParams<{ organizationId: string }>();
  const vBillStore = useStore("VBillStore");
  const [isReqPending, setIsReqPending] = useState(false);
  const formMethods = useFormContext<TVBillFormFields>();
  const [totalDifferenceAlertVisible, setTotalDifferenceAlertVisible] = useState(false);

  const handleFormSubmitWithCustomValidation: SubmitHandler<TVBillFormFields> = async (formFields) => {
    const invoiceDateTime = new Date(formFields.invoiceDate ?? 0).getTime();
    const invoiceDateDueTime = new Date(formFields.invoiceDateDue ?? 0).getTime();

    if (invoiceDateDueTime < invoiceDateTime) {
      return formMethods.setError("invoiceDateDue", {
        type: "custom",
        message: invoiceDateMoreThenFieldErrorMsg,
      });
    }

    const totalFormItemsAmount = (formFields.lineItems ?? []).reduce(
      (acc, val) => (!isNil(val.amount) ? CHDecimal.add(acc, val.amount || 0).toNumber() : acc),
      0,
    );

    if (formFields.hasLineItems && totalFormItemsAmount !== Number(formFields.amount ?? "0")) {
      setTotalDifferenceAlertVisible(true);

      return;
    }

    formMethods.handleSubmit(handleFormSubmit)();
  };

  const handleFormSubmit: SubmitHandler<TVBillFormFields> = async (formFields) => {
    setIsReqPending(true);

    if (totalDifferenceAlertVisible) {
      setTotalDifferenceAlertVisible(false);
    }

    const invoiceData = adaptIncomingVBillFormToInvoiceInputType(formFields, invoice?.id ?? 0);
    const resp = await vBillStore.updateInvoiceAndMappings({
      invoiceId: invoice?.id ?? 0,
      invoiceData,
      skipAdditionalMappingsValidations: true,
    });

    setIsReqPending(false);

    if (resp) {
      const navigateToUrl = `/org/${organizationId}/vbill/${invoice?.id ?? ""}/new`;

      history.push(navigateToUrl);
    }
  };

  return {
    handleDifferentAlertSubmit: formMethods.handleSubmit(handleFormSubmit),
    handleNextBtnFormSubmit: formMethods.handleSubmit(handleFormSubmitWithCustomValidation),
    isReqPending,
    totalDifferenceAlertVisible,
    handleCloseTotalDifferenceAlert: () => setTotalDifferenceAlertVisible(false),
  };
}

export function useUpdateNewVBillForm(invoice?: IVBillVBillInvoiceQuery["invoice"] | null) {
  const history = useHistory();
  const vBillStore = useStore("VBillStore");
  const [isReqPending, setIsReqPending] = useState(false);
  const formMethods = useFormContext<TVBillFormFields>();
  const vbillCodingLedgerCodingUrl = useVBillCodingLedgerQuickFilterUrl(VBillCodingLedgerChildRoutes.CODING);
  const [showDuplicatesModal, setShowDuplicatesModal] = useState<boolean>(false);
  const [duplicates, setDuplicates] = useState<IVBillRelatedInvoiceSchema[]>([]);

  const handleFormSubmit: VBillExtendedSubmitHandler<TVBillFormFields, boolean> = async (
    formData,
    _event,
    withStartApproval,
  ) => {
    setIsReqPending(true);

    const invoiceData = adaptNewVBillFormToInvoiceInputType(formData, invoice?.id ?? 0);
    const validateInvoiceResp = await VBillValidateInvoice({ invoiceId: invoice?.id ?? 0, invoiceData });

    // invoice
    const invoiceRequiredFieldsAndMappingsError = [
      ...(validateInvoiceResp?.validateInvoice.validationErrors?.invoice.fields.required ?? []),
      ...(validateInvoiceResp?.validateInvoice.validationErrors?.invoice.mappings.required ?? []),
    ];
    const invoiceCustomFieldsError =
      validateInvoiceResp?.validateInvoice.validationErrors?.invoice.fields.custom?.fields ?? [];
    const invoiceCustomMappingsError =
      validateInvoiceResp?.validateInvoice.validationErrors?.invoice.mappings.custom?.fields ?? [];

    Object.keys(formData).forEach((fieldKey) => {
      if (invoiceCustomFieldsError.includes(fieldKey)) {
        formMethods.setError(fieldKey as keyof TVBillFormFields, {
          type: "custom",
          message: validateInvoiceResp?.validateInvoice.validationErrors?.invoice.fields.custom?.message,
        });

        return;
      } else if (invoiceCustomMappingsError.includes(fieldKey)) {
        formMethods.setError(fieldKey as keyof TVBillFormFields, {
          type: "custom",
          message: validateInvoiceResp?.validateInvoice.validationErrors?.invoice.mappings.custom?.message,
        });

        return;
      } else if (invoiceRequiredFieldsAndMappingsError.includes(fieldKey)) {
        formMethods.setError(fieldKey as keyof TVBillFormFields, {
          type: "required",
          message: `${fieldKey} is required.`,
        });
      }
    });

    // line items

    let lineItemsError = false;
    formData.lineItems?.forEach((lineItem, index) => {
      const lineItemKeys = Object.keys(lineItem);

      for (const fieldKey of lineItemKeys) {
        const lineErrors = validateInvoiceResp.validateInvoice.validationErrors?.lineItems?.find(
          ({ key }) => key === lineItem.lineItemId,
        );

        if (!lineErrors) {
          break;
        }

        lineItemsError = true;

        const lineItemCustomFieldsError = lineErrors?.fields.custom?.fields;
        const lineItemCustomGLMappingsError = lineErrors?.glMapping.mappings.custom?.fields;
        const lineItemRequiredFieldsAndMappingsError = [
          ...(lineErrors?.fields.required ?? []),
          ...(lineErrors?.glMapping.mappings.required ?? []),
          ...(lineErrors?.glMapping.fields.required ?? []),
        ];

        if (lineItemCustomFieldsError?.includes(fieldKey)) {
          formMethods.setError(`lineItems.${index}.${fieldKey}` as unknown as keyof TVBillFormFields, {
            type: "custom",
            message: lineErrors?.fields.custom?.message,
          });

          break;
        } else if (lineItemCustomGLMappingsError?.includes(fieldKey)) {
          formMethods.setError(`lineItems.${index}.${fieldKey}` as unknown as keyof TVBillFormFields, {
            type: "custom",
            message: lineErrors?.glMapping.mappings.custom?.message,
          });

          break;
        } else if (lineItemRequiredFieldsAndMappingsError.includes(fieldKey)) {
          formMethods.setError(`lineItems.${index}.${fieldKey}` as unknown as keyof TVBillFormFields, {
            type: "required",
            message: `${fieldKey} is required.`,
          });
        }
      }
    });

    if (
      invoiceRequiredFieldsAndMappingsError.length ||
      invoiceCustomFieldsError.length ||
      invoiceCustomMappingsError.length ||
      lineItemsError
    ) {
      setIsReqPending(false);

      return;
    }

    const duplicatesResponse = await VBillCheckInvoiceForDuplicates({
      invoiceId: invoice?.id!,
      invoiceData,
    });

    if (duplicatesResponse.checkInvoiceForDuplicates.length) {
      setShowDuplicatesModal(true);
      setDuplicates(duplicatesResponse.checkInvoiceForDuplicates as IVBillRelatedInvoiceSchema[]);
      setIsReqPending(false);

      return;
    }

    const updateResponse = await vBillStore.updateInvoiceAndMappings({
      invoiceId: invoice?.id ?? 0,
      invoiceData,
      skipAdditionalMappingsValidations: false,
    });

    if (updateResponse?.status !== IVBillInvoiceIncomingStatus.Mapped) {
      setIsReqPending(false);

      return;
    }

    if (withStartApproval) {
      const startApprovalResponse = await vBillStore.startApprovalForInvoice({ invoiceId: invoice?.id ?? 0 });

      if (startApprovalResponse?.status !== IVBillInvoiceIncomingStatus.PendingApproval) {
        setIsReqPending(false);

        return;
      }
    }

    setIsReqPending(false);
    history.push(vbillCodingLedgerCodingUrl);
  };

  return {
    handleFormSubmit: (withStartApproval: boolean) =>
      formMethods.handleSubmit((...args) => handleFormSubmit(...args, withStartApproval))(),
    isReqPending,
    duplicates,
    showDuplicatesModal,
    hideDuplicatesModal: () => setShowDuplicatesModal(false),
  };
}

export enum VBillSummaryActionType {
  StartApproval,
  Pay,
}

export function useVBillStartApprovalAction(invoice?: IVBillVBillInvoiceQuery["invoice"] | null) {
  const vBillStore = useStore("VBillStore");
  const [isReqPending, setIsReqPending] = useState(false);
  const history = useHistory();
  const vbillCodingLedgerCodingUrl = useVBillCodingLedgerQuickFilterUrl(VBillCodingLedgerChildRoutes.CODING);

  const handleVbillStartApproval = async () => {
    setIsReqPending(true);

    const startApprovalResponse = await vBillStore.startApprovalForInvoice({ invoiceId: invoice?.id ?? 0 });

    if (startApprovalResponse?.status !== IVBillInvoiceIncomingStatus.PendingApproval) {
      setIsReqPending(false);

      return;
    }

    setIsReqPending(false);
    history.push(vbillCodingLedgerCodingUrl);
  };

  return {
    handleVbillStartApproval,
    isReqPending,
  };
}
*/

export function useCurrentVBillRoute() {
  const isIncomingRoute = !!useRouteMatch(VBillIncomingPath);
  const isNewRoute = !!useRouteMatch(VBillNewPath);
  const isSummaryRoute = !!useRouteMatch(VBillSummaryPath);

  return {
    isIncomingRoute,
    isNewRoute,
    isSummaryRoute,
  };
}
