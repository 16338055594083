import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import classnames from "classnames";
import { memo, useEffect, useMemo } from "react";
import { useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from "react-table";
import {
  ColumnFilter,
  Footer,
  GlobalFilter,
  LinearProgress,
  Table as BaseTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "./components";
import styles from "./Table.module.scss";
import { IColumn, ITableProps } from "./types";

// @TODO fix the types
function Table({
  className,
  columns = [],
  data = [],
  emptyLabel = "No Records to View",
  initialState,
  loading,
  pagination = false,
  search = false,
  multipleSelect = false,
  deselected,
  setDeselected,
  onSort,
  getRowId = (_row, relativeIndex, parent) => (parent ? [parent.id, relativeIndex].join(".") : relativeIndex),
  ...rest
}: ITableProps) {
  const defaultColumn = useMemo(
    () => ({
      Filter: ColumnFilter,
    }),
    [],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    setGlobalFilter,
    selectedFlatRows,
    state,
    toggleAllRowsSelected,
    ...restTableProps
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState,
      getRowId,
      autoResetSelectedRows: false,
      autoResetSortBy: pagination,
      manualSortBy: !pagination,
      manualPagination: !pagination,
      stateReducer: (newState, action) => {
        switch (action.type) {
          case "toggleAllRowsSelected":
            const _selectedRowIds = action.value
              ? Object.keys(newState.selectedRowIds).reduce(
                  (acc, el) => {
                    acc[el] = action.value;
                    return acc;
                  },
                  {} as Record<string, boolean>,
                )
              : {};

            return {
              ...newState,
              selectedRowIds: _selectedRowIds,
            };

          default:
            return newState;
        }
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: "selection",
          Header: ({ getToggleAllRowsSelectedProps }) => {
            return multipleSelect ? (
              <div>
                <Checkbox {...getToggleAllRowsSelectedProps()} />
              </div>
            ) : null;
          },
          Cell: ({ row }) =>
            multipleSelect ? (
              <div>
                <Checkbox {...row.getToggleRowSelectedProps()} />
              </div>
            ) : null,
          maxWidth: 50,
        },
        ...columns,
      ]);
    },
  );

  useEffect(() => {
    if (deselected && setDeselected) {
      toggleAllRowsSelected(false);
      setDeselected(false);
    }
  }, [deselected, setDeselected, toggleAllRowsSelected]);

  useEffect(() => {
    if (onSort) {
      onSort(state.sortBy);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.sortBy]);

  // if(deselected) {
  //   toggleAllRowsSelected();
  // }

  const checkIfSelectedRows = (row: any): boolean | undefined => {
    for (const property in state.selectedRowIds)
      if (property === row.id) {
        return true;
      }
  };

  return data.length ? (
    <TableContainer className={classnames(className, styles.container, { [styles.noAction]: loading })} {...rest}>
      {search && (
        <div className={styles.filterContainer}>
          <GlobalFilter filter={state.globalFilter} setFilter={setGlobalFilter} />
        </div>
      )}
      <BaseTable className={styles.table} stickyHeader={true} {...getTableProps()}>
        <TableHead>
          {headerGroups.map((headerGroup: any) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: IColumn) => {
                return column.id === "selection" && !multipleSelect ? null : (
                  <TableCell {...column.getHeaderProps(column.getSortByToggleProps(column.headerProps))}>
                    <div
                      className={classnames(styles.headerCellContent, {
                        [styles.sortable]: column.canSort,
                      })}
                    >
                      <span>{column.render("Header")}</span>

                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <KeyboardArrowDownIcon className={styles.sortIcon} />
                        ) : (
                          <KeyboardArrowDownIcon sx={{ transform: "rotate(180deg)" }} className={styles.sortIcon} />
                        )
                      ) : column.canSort ? (
                        <div className={styles.sortIconsContainer}>
                          <KeyboardArrowDownIcon sx={{ transform: "rotate(180deg)" }} className={styles.sortIcon} />
                          <KeyboardArrowDownIcon className={styles.sortIcon} />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
          {loading && (
            <TableRow>
              <TableCell className={styles.progressContainer} colSpan={columns.length + 1} padding="none">
                <LinearProgress className={styles.progress} color="secondary" />
              </TableCell>
            </TableRow>
          )}
        </TableHead>
        {loading !== true && (
          <TableBody className={classnames({ [styles.fade]: loading })} {...getTableBodyProps()}>
            {!data.length && (
              <TableRow>
                <TableCell align="center" colSpan={columns.length + 1} sx={{ borderBottom: "none", lineHeight: 4 }}>
                  {emptyLabel}
                </TableCell>
              </TableRow>
            )}
            {page.map((row: any) => {
              prepareRow(row);
              const key =
                row.original.role !== undefined ? `${row.original.id}-${row.original.role}` : `${row.original.id}`;
              return (
                <TableRow hover={true} selected={checkIfSelectedRows(row)} {...row.getRowProps()} key={key}>
                  {row.cells.map((cell: any) => {
                    return cell.column.id === "selection" && !multipleSelect ? null : (
                      <TableCell {...cell.getCellProps(cell.column.cellProps)}>{cell.render("Cell")}</TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        )}
      </BaseTable>
      {pagination && <Footer state={state} {...restTableProps} />}
    </TableContainer>
  ) : (
    <Grid sx={{ padding: "10px" }} justifyContent={"center"} container>
      <div>{emptyLabel}</div>
    </Grid>
  );
}

export default memo(Table);
