import { CheckFiltersType, CheckQuickFiltersEnumType, GetCheckBatchesFiltersType } from "generated/sdk";
import { observer } from "mobx-react-lite";
import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useStore } from "storeContainer";

interface ExtendedCheckFiltersType extends CheckFiltersType {
  [key: string]: any;
}

type ExtendFilterType<T> = T & {
  [key: string]: any;
};

export type ExtendedCheckFiltersType1 = ExtendFilterType<CheckFiltersType>;
export type ExtendedCheckFiltersType2 = ExtendFilterType<GetCheckBatchesFiltersType>;

type FilterUnionType = ExtendedCheckFiltersType1 | ExtendedCheckFiltersType2 | undefined;

interface CheckFiltersContextType<T = FilterUnionType> {
  filters: T;
  setFilters: React.Dispatch<React.SetStateAction<ExtendedCheckFiltersType>>;
  pagination: {
    per_page: number;
    page: number;
  };
  setPagination: React.Dispatch<
    React.SetStateAction<{
      per_page: number;
      page: number;
    }>
  >;
  token: string;
  setToken: React.Dispatch<React.SetStateAction<string>>;
  setFilterDiffWarning: React.Dispatch<React.SetStateAction<boolean>>;
  showFilterDiffWarning: boolean;
  resetFilters: () => void;
  checkTabId: string;
}

const CheckFiltersContext = createContext<CheckFiltersContextType | undefined>(undefined);

export function useCheckFilters<T = FilterUnionType>() {
  const context = useContext(CheckFiltersContext);
  if (!context) {
    throw new Error("useCheckFilters must be used within a CheckFiltersProvider");
  }
  return context as CheckFiltersContextType<T>;
}
export type VCheckChecksAllQuickFiltersEnum = CheckQuickFiltersEnumType | "all";

export const CheckFiltersProvider: React.FC<{ prefix?: string }> = observer(({ children, prefix = "check" }) => {
  const { selectedQuickFilter: quickFilterParam, organizationId } = useParams<{
    selectedQuickFilter: VCheckChecksAllQuickFiltersEnum;
    organizationId: string | undefined;
  }>();
  // const { path } = useRouteMatch();
  const userSettingsStore = useStore("UserSettingsStore");

  const selectedQuickFilter = quickFilterParam === "all" ? undefined : quickFilterParam;

  const [showFilterDiffWarning, setFilterDiffWarning] = useState(false);
  const [filters, setFilters] = useState<ExtendedCheckFiltersType>({
    quick_filter: selectedQuickFilter,
    companies: prefix !== "check" || !organizationId ? undefined : userSettingsStore.companiesSelectedIds,
  });

  const [pagination, setPagination] = useState({ per_page: 50, page: 1 });
  const [token, setToken] = useState<string>("");
  const [checkTabId, setCheckTabId] = useState<string>("");
  const history = useHistory();
  // const formConfig = getFiltersConfig(path, selectedQuickFilter!);
  // const keyName = formConfig?.keyName as string;
  // const keyNameInput = formConfig?.keyNameInput as string;
  // const keyNameOptions = formConfig?.keyNameOptions as string;
  // const keyNameAdditionalInput = formConfig?.keyNameAdditionalInput as string;
  // const keyNameAdditionalOptions = formConfig?.keyNameAdditionalOptions as string;

  const resetFilters = useCallback(() => {
    const expirationDate = new Date().getTime() + 10 * 60 * 1000;
    localStorage.setItem(
      `${prefix}Filters_${checkTabId}`,
      JSON.stringify({
        value: {
          quick_filter: selectedQuickFilter,
          companies: prefix !== "check" || !organizationId ? undefined : userSettingsStore.companiesSelectedIds,
        },
        expires: expirationDate,
      }),
    );
    localStorage.setItem(
      `${prefix}Pagination_${checkTabId}`,
      JSON.stringify({ value: pagination, expires: expirationDate }),
    );
    setFilters({
      quick_filter: selectedQuickFilter,
      companies: prefix !== "check" || !organizationId ? undefined : userSettingsStore.companiesSelectedIds,
    });
  }, [checkTabId, pagination, prefix, selectedQuickFilter, userSettingsStore.companiesSelectedIds, organizationId]);

  const clearExpiredLocalStorageItems = (prefix: string) => {
    Object.keys(localStorage).forEach((key) => {
      if (key.startsWith(`${prefix}Filters_`) || key.startsWith(`${prefix}Pagination_`)) {
        try {
          const item = localStorage.getItem(key);
          if (item) {
            const parsed = JSON.parse(item);
            const now = new Date().getTime();
            if (now >= parsed.expires) {
              localStorage.removeItem(key);
            }
          }
        } catch (error) {
          console.error(`Failed to parse and clear expired item with key ${key}:`, error);
        }
      }
    });
  };

  useEffect(() => {
    const existingTabId = sessionStorage.getItem(`${prefix}TabId`) || Math.random().toString(36).substr(2, 9);
    setCheckTabId(existingTabId);
    sessionStorage.setItem(`${prefix}TabId`, existingTabId);
  }, []);

  useEffect(() => {
    const savedFilters = localStorage.getItem(`${prefix}Filters_${checkTabId}`);
    const savedPagination = localStorage.getItem(`${prefix}Pagination_${checkTabId}`);

    try {
      if (savedFilters) {
        const parsed = JSON.parse(savedFilters);
        const now = new Date().getTime();

        if (now < parsed.expires) {
          setFilters({
            ...parsed.value,
            quick_filter: selectedQuickFilter,
            companies: prefix !== "check" || !organizationId ? undefined : userSettingsStore.companiesSelectedIds,
          });
        }
      }
    } catch (error) {
      console.error("Failed to parse saved filters:", error);
    }

    try {
      if (savedPagination) {
        const parsed = JSON.parse(savedPagination);
        const now = new Date().getTime();

        if (now < parsed.expires) {
          setPagination(parsed.value);
        }
      }
    } catch (error) {
      console.error("Failed to parse saved pagination:", error);
    }
  }, [checkTabId, prefix, selectedQuickFilter, userSettingsStore.companiesSelectedIds, organizationId]);

  useEffect(() => {
    if (checkTabId && filters && Object.keys(filters).length > 0) {
      const expirationDate = new Date().getTime() + 10 * 60 * 1000;
      localStorage.setItem(
        `${prefix}Filters_${checkTabId}`,
        JSON.stringify({ value: filters, expires: expirationDate }),
      );

      localStorage.setItem(
        `${prefix}Pagination_${checkTabId}`,
        JSON.stringify({ value: pagination, expires: expirationDate }),
      );
    }
  }, [filters, pagination, checkTabId, prefix]);

  useEffect(() => {
    clearExpiredLocalStorageItems(prefix);
  }, [prefix]);

  useEffect(() => {
    const expirationDate = new Date().getTime() + 10 * 60 * 1000;
    localStorage.setItem(
      `${prefix}Filters_${checkTabId}`,
      JSON.stringify({
        value: {
          quick_filter: selectedQuickFilter,
          companies: prefix !== "check" || !organizationId ? undefined : userSettingsStore.companiesSelectedIds,
        },
        expires: expirationDate,
      }),
    );
    localStorage.setItem(
      `${prefix}Pagination_${checkTabId}`,
      JSON.stringify({ value: pagination, expires: expirationDate }),
    );
    setFilters({
      quick_filter: selectedQuickFilter,
      companies: prefix !== "check" || !organizationId ? undefined : userSettingsStore.companiesSelectedIds,
    });
  }, [checkTabId, prefix, selectedQuickFilter, userSettingsStore.companiesSelectedIds, organizationId]);

  useEffect(() => {
    const unlisten = history.listen(() => {
      const sidebarPanel = new URLSearchParams(window.location.search).get("sidebar_panel");
      if (!sidebarPanel) {
        resetFilters();
      }
    });

    return () => {
      unlisten();
    };
  }, [history, resetFilters]);

  return (
    <CheckFiltersContext.Provider
      value={{
        filters,
        setFilters,
        pagination,
        setPagination,
        token,
        setToken,
        setFilterDiffWarning,
        showFilterDiffWarning,
        resetFilters,
        checkTabId,
      }}
    >
      {children}
    </CheckFiltersContext.Provider>
  );
});
