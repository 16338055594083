import { observer } from "mobx-react-lite";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Modal from "@mui/material/Modal";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import styles from "./Filters.module.scss";
import ReplayIcon from "@mui/icons-material/Replay";
import FilterListIcon from "@mui/icons-material/FilterList";
import React, { useState } from "react";
import { useAsyncDebounce } from "react-table";
import FiltersModal from "./FiltersModal";
import _ from "lodash";
import { PendingChecksFilterInput } from "generated/sdk";
import { Box, Chip, InputAdornment } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { SearchIcon } from "icons/svg/SearchIcon";

const Filters = ({
  filters,
  setFilters,
  filtersFetcher,
  currentCompany,
  handleCompanyChange,
  companiesWithIntegrationsIds,
}: {
  filters: PendingChecksFilterInput | undefined;
  setFilters: React.Dispatch<React.SetStateAction<PendingChecksFilterInput | undefined>>;
  filtersFetcher: any;
  amountChipLabel: any;
  currentCompany: any;
  handleCompanyChange: any;
  companiesWithIntegrationsIds: any;
}) => {
  //Filters

  const initialState: PendingChecksFilterInput = {
    amount_max: null,
    amount_min: 0,
    contact: null,
    error_type: null,
    from_date: null,
    to_date: null,
    search: null,
  };

  const [modalFiltersCount, setModalFiltersCount] = useState(0);
  const [modalFilters, setModalFilters] = useState(filters || initialState);
  const [showFiltersChips, setShowFiltersChips] = useState<{ amount: any; date: any; errorType: any; contact: any }>({
    amount: false,
    date: false,
    errorType: false,
    contact: false,
  });
  const [openFiltersModal, setOpenFiltersModal] = React.useState(false);
  const closeIconStyle = {
    height: "16x",
    width: "16px",
    cursor: "pointer",
  };
  //Use Effect
  const errorTypes = [
    { DUPLICATE_ENTITY_REF: "Duplicate entity ref" },
    { VALIDATION: "Validation" },
    { INVALID_CONTACT: "Invalid contact" },
    { BANK_ACCOUNT_NOT_FOUND: "Bank account not found" },
    { MISSING_BANK_ACCOUNT: "Missing bank account" },
    { NO_POSTAL_ADDRESS: "No postal address" },
    { INVALID_BILLER: "Invalid Biller" },
    { INVALID_BILL_PAYMENT: "Invalid bill payment" },
    { INVALID_RECIPIENT_EMAIL: "Invalid recipient email" },
    { RECIPIENT_EMAIL_LOCKED: "Recipient email locked" },
    { DUPLICATE_CHECK_NUMBER: "Duplicate check number" },
    { INSUFFICIENT_PERMISSION: "Insufficient permission" },
    { UNMAPPED_USER: "Unmapped user" },
    { UNMAPPED_GL_ACCOUNT: "Unmapped GL account" },
    { USER_UNABLE_TO_WRITE_THIS_CHECK: "User unable to write this check" },
    { GL_ACCOUNT_NOT_FOUND: "GL account not found" },
    { MISSING_GL_ACCOUNT_OR_BANK_ACCOUNT: "Missing GL account or bank account" },
  ];
  //Handle Functions
  const handleOpen = () => setOpenFiltersModal(true);
  const handleClose = () => setOpenFiltersModal(false);

  const handleFilterInputChange = (e: any) => {
    if (e.target.name.includes("date")) {
      setFilters((prevFilters) => ({ ...prevFilters, [e.target.name]: parseFloat(e.target.value) }));
    } else {
      setFilters((prevFilters) => ({ ...prevFilters, [e.target.name]: e.target.value }));
    }
  };
  const onSearchChange = useAsyncDebounce((value) => {
    setFilters({ ...filters, search: value });
  }, 300);

  const handleRemove = (field: string) => {
    if (field === "amount") {
      setShowFiltersChips({ ...showFiltersChips, amount: false });
      setFilters({ ...filters, amount_min: 0, amount_max: 0 });
      handleApplyFilters({ ...filters, amount_min: 0, amount_max: 0 });
    }
    if (field === "date") {
      setShowFiltersChips({ ...showFiltersChips, date: false });
      setFilters({ ...filters, from_date: null, to_date: null });
      handleApplyFilters({ ...filters, from_date: null, to_date: null });
    }
    if (field === "errorType") {
      setShowFiltersChips({ ...showFiltersChips, errorType: false });
      setFilters({ ...filters, error_type: null });
      handleApplyFilters({ ...filters, error_type: null });
    }
    if (field === "contact") {
      setShowFiltersChips({ ...showFiltersChips, contact: false });
      setFilters({ ...filters, contact: null });
      handleApplyFilters({ ...filters, contact: null });
    }
  };

  const handleApplyFilters = async (filters: any) => {
    filtersFetcher(filters);
    handleClose();
  };

  const handleCancel = () => {
    setModalFilters(initialState);
    setFilters(initialState);
    setModalFiltersCount(0);
    handleClose();
  };

  const handleReload = () => {
    setFilters(initialState);
    filtersFetcher(initialState);
    setShowFiltersChips({ ...showFiltersChips, date: false, amount: false });
  };

  const applyErrorTypeFilter = (e: any) => {
    if (e.target.value === "all") {
      setFilters({ ...filters, error_type: null });
    } else {
      setFilters({ ...filters, error_type: e.target.value });
    }
  };

  return (
    <>
      <Box justifyContent={"space-between"} alignItems={"center"}>
        {!_.isEqual(modalFilters, initialState) && modalFiltersCount > 0 ? (
          <Button
            variant={"text"}
            className={styles.clearFiltersButton}
            startIcon={<ReplayIcon />}
            onClick={handleCancel}
          >
            Reset Results
          </Button>
        ) : null}
      </Box>
      <Box sx={{ margin: "10px 0" }} padding={"0 20px"} alignItems={"flex-start"} justifyContent={"space-between"}>
        <Box display="flex" alignItems="end" gap="15px">
          <Box>
            <InputLabel variant="standard" htmlFor="search">
              Search
            </InputLabel>
            <TextField
              id="search"
              name="search"
              variant="outlined"
              placeholder="Search"
              value={filters?.search ?? ""}
              onChange={(e: any) => {
                handleFilterInputChange(e);
                onSearchChange(e.target.value);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {filters?.search ? (
                      <CloseIcon onClick={() => setFilters({ ...filters, search: undefined })} sx={closeIconStyle} />
                    ) : (
                      <SearchIcon sx={closeIconStyle} />
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box>
            <InputLabel variant="standard" htmlFor="Company">
              Company
            </InputLabel>
            <Select
              id="Company"
              name="Company"
              defaultValue={currentCompany}
              value={currentCompany}
              onChange={(e) => {
                handleCompanyChange(e);
              }}
            >
              {companiesWithIntegrationsIds?.map((company: any) => {
                return (
                  <MenuItem key={company.id} value={company.id}>
                    {company.name}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>
          <Box>
            <InputLabel variant="standard" htmlFor="errorType">
              Error Type
            </InputLabel>
            <Select
              value={filters?.error_type || "all"}
              name="errorType"
              onChange={(e) => {
                applyErrorTypeFilter(e);
              }}
            >
              <MenuItem value={"all"}>All</MenuItem>
              {errorTypes.map((error, index) => (
                <MenuItem key={index} value={Object.keys(error)[0]}>
                  {Object.values(error)}
                </MenuItem>
              ))}
            </Select>
          </Box>

          {modalFiltersCount === 0 ? (
            <Button variant="outlined" endIcon={<FilterListIcon />} onClick={handleOpen}>
              Filters
            </Button>
          ) : (
            <Button variant="contained" onClick={handleOpen} sx={{ height: "100%", minHeight: "56px" }}>
              Filters <div className={styles.filtersCount}> {modalFiltersCount}</div>
            </Button>
          )}
          <IconButton aria-label="delete" onClick={handleReload}>
            <ReplayIcon />
          </IconButton>
        </Box>
        <Box marginTop="15px">
          {(filters?.amount_min !== 0 || filters.amount_max !== 0) && showFiltersChips.amount && (
            <Chip
              variant="outlined"
              label={showFiltersChips.amount}
              title="Amount"
              deleteIcon={
                <CloseIcon
                  sx={{ fontSize: "12px !important", color: "#052048 !important", position: "absolute", right: "10px" }}
                />
              }
              onDelete={() => {
                handleRemove("amount");
              }}
            />
          )}
          {(filters?.from_date !== null || filters.to_date !== null) && showFiltersChips.date && (
            <Chip
              variant="outlined"
              label={showFiltersChips.amount}
              title="Date"
              deleteIcon={
                <CloseIcon
                  sx={{ fontSize: "12px !important", color: "#052048 !important", position: "absolute", right: "10px" }}
                />
              }
              onDelete={() => {
                handleRemove("date");
              }}
            />
          )}
          {filters?.contact !== null && showFiltersChips.contact && (
            <Chip
              variant="outlined"
              label={showFiltersChips.amount}
              title="Contact"
              deleteIcon={
                <CloseIcon
                  sx={{ fontSize: "12px !important", color: "#052048 !important", position: "absolute", right: "10px" }}
                />
              }
              onDelete={() => {
                handleRemove("contact");
              }}
            />
          )}
        </Box>
      </Box>
      <Modal
        open={openFiltersModal}
        onClose={handleClose}
        aria-labelledby="filters-modal"
        aria-describedby="filters-modal"
      >
        <div>
          <FiltersModal
            modalFilters={filters}
            showFiltersChips={showFiltersChips}
            setModalFilters={setFilters}
            setShowFiltersChips={setShowFiltersChips}
            modalFiltersCount={modalFiltersCount}
            setModalFiltersCount={setModalFiltersCount}
            handleCancel={handleCancel}
            handleApply={handleApplyFilters}
            handleClose={handleClose}
            handleClear={handleReload}
          />
        </div>
      </Modal>
    </>
  );
};

export default observer(Filters);
