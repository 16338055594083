import ChevronRight from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Button, Card, CardContent, CardHeader, styled } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { PendingAction } from "generated/sdk";
import { useState } from "react";
import { COLORS } from "../../themes/default";
import styles from "./Widgets.module.scss";
interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}
const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export const CollapsiblePendingActionsComponent: React.FunctionComponent<{
  checkText: string;
  pendingActions?: PendingAction[];
  id?: string;
}> = ({ checkText = "", pendingActions, id }) => {
  const [expanded, setIsExpanded] = useState(false);

  return (
    <Card sx={{ width: "340px", marginTop: "15px" }}>
      <CardHeader
        action={
          <ExpandMore
            expand={expanded}
            onClick={() => setIsExpanded(!expanded)}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        }
        title={
          <Box>
            <Box display="flex" gap="7px">
              <Typography variant="h4">
                {pendingActions?.some((action) => action.company_name != null)
                  ? "Complete company validation"
                  : "Complete bank account validation"}
              </Typography>
              <Box
                className={styles.actionCount}
                onClick={() => {
                  setIsExpanded(!expanded);
                }}
              >
                {pendingActions?.length}
              </Box>
            </Box>
            <Box marginTop="8px">
              <Typography variant="h5">{checkText}</Typography>
            </Box>
          </Box>
        }
      />

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          {pendingActions?.map((action, index) => {
            const name = action.company_name || `${action.bank_account_name}****${action.bank_account_account_number}`;
            return (
              <Box key={`${action.bank_account_account_number}-${index}`}>
                <Divider sx={!!!index ? { marginTop: "20px" } : {}} orientation="horizontal" flexItem />
                <Box display="flex" justifyContent="space-between" alignItems="center" height="60px">
                  <Box>
                    <Tooltip title={name} disableHoverListener={name?.length < 25}>
                      <Typography variant="h3">{name}</Typography>
                    </Tooltip>
                  </Box>
                  <Box>
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() => {
                        window.location.href = action.verification_link!;
                      }}
                      endIcon={<ChevronRight />}
                    >
                      Continue
                    </Button>
                  </Box>
                </Box>
              </Box>
            );
          })}
        </CardContent>
      </Collapse>
    </Card>
  );
};
