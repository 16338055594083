import { Box, Chip, Skeleton } from "@mui/material";
import Typography from "@mui/material/Typography";
import { formatCurrency } from "common/helpers/utils";
import { CheckQuickFiltersEnumType } from "generated/sdk";
import { ActionRequired } from "icons/svg/ActionRequired";
import { AllIcon } from "icons/svg/AllIcon";
import { Completed } from "icons/svg/Completed";
import { PendingApproval } from "icons/svg/PendingApproval";
import { Processing } from "icons/svg/Processing";
import { ReadyToDeposit } from "icons/svg/ReadyToDeposit";
import { Unresolved } from "icons/svg/Unresolved";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useRouteMatch } from "react-router";
import { useHistory, useParams } from "react-router-dom";
import { useStore } from "storeContainer";
import { IQuickFiltersProps } from "./types";

const QuickFilters = ({
  pendingSignatureSum,
  pendingSignatureCount,
  processingSum,
  processingCount,
  actionsRequiredSum,
  actionsRequiredCount,
  unresolvedChatsSum,
  unresolvedChatsCount,
  readyToDepositSum,
  readyToDepositCount,
  isLoading,
}: IQuickFiltersProps) => {
  const contextStore = useStore("ContextStore");
  const vChecksStore = useStore("VChecksStore");
  const isPayments = useRouteMatch("/org/:organizationId/vChecks/payments/:selectedQuickFilter");
  const isDeposits = useRouteMatch("/org/:organizationId/vChecks/deposits/:selectedQuickFilter");
  const source = isPayments ? "payments" : "deposits";
  const { selectedQuickFilter } = useParams<{ selectedQuickFilter?: string }>();
  const history = useHistory();

  const [disabled, setDisabled] = useState(vChecksStore.checksList?.isLoading);

  useEffect(() => {
    setDisabled(vChecksStore.checksList.isLoading);
  }, [vChecksStore.checksList.isLoaded, vChecksStore.checksList.isLoading]);

  return (
    <Box
      display="flex"
      gap="10px"
      position="sticky"
      top="0"
      paddingTop="20px"
      paddingBottom="10px"
      sx={{ flexDirection: "row", flexWrap: "wrap", justifyContent: "space-between", background: "white" }}
      zIndex="3"
    >
      <Chip
        label={<Typography>All</Typography>}
        component="a"
        href={`/org/${contextStore.selectedOrganizationId}/vchecks/${source}/all`}
        onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
          event.preventDefault();
          setDisabled(true);
          history.push(`/org/${contextStore.selectedOrganizationId}/vchecks/${source}/all`);
        }}
        icon={<AllIcon fill="#B5ECA6" stroke="#6FD055" />}
        variant={selectedQuickFilter === "all" ? "awesome" : "awesome-filled"}
        sx={{ width: "120px" }}
        clickable
        disabled={disabled}
      />

      {isPayments && (
        <Chip
          label={
            <>
              <Typography>Pending Signature {isLoading ? "" : `(${pendingSignatureCount})`}</Typography>
              <Typography>
                {isLoading ? <Skeleton variant="text" /> : formatCurrency(pendingSignatureSum ?? 0)}
              </Typography>
            </>
          }
          component="a"
          href={`/org/${contextStore.selectedOrganizationId}/vchecks/${source}/${CheckQuickFiltersEnumType.PendingSignature}`}
          onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
            event.preventDefault();
            setDisabled(true);
            history.push(
              `/org/${contextStore.selectedOrganizationId}/vchecks/${source}/${CheckQuickFiltersEnumType.PendingSignature}`,
            );
          }}
          icon={
            <PendingApproval
              fill={(pendingSignatureCount as number) > 0 ? "#FFF3E1" : "#B5ECA6"}
              stroke={(pendingSignatureCount as number) > 0 ? "#ECBB71" : "#6FD055"}
            />
          }
          sx={{ flex: 1, maxWidth: "100vw" }}
          variant={selectedQuickFilter === CheckQuickFiltersEnumType.PendingSignature ? "awesome" : "awesome-filled"}
          clickable
          disabled={disabled}
        />
      )}
      {isDeposits && (
        <Chip
          label={
            <>
              <Typography>Ready To Deposit {isLoading ? "" : `(${readyToDepositCount})`}</Typography>
              <Typography>{isLoading ? <Skeleton variant="text" /> : formatCurrency(readyToDepositSum)}</Typography>
            </>
          }
          component="a"
          href={`/org/${contextStore.selectedOrganizationId}/vchecks/${source}/${CheckQuickFiltersEnumType.ReadyToDeposit}`}
          onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
            event.preventDefault();
            setDisabled(true);
            history.push(
              `/org/${contextStore.selectedOrganizationId}/vchecks/${source}/${CheckQuickFiltersEnumType.ReadyToDeposit}`,
            );
          }}
          icon={
            <ReadyToDeposit
              fill={(readyToDepositCount as number) > 0 ? "#FFF3E1" : "#B5ECA6"}
              stroke={(readyToDepositCount as number) > 0 ? "#ECBB71" : "#6FD055"}
            />
          }
          sx={{ flex: 1, maxWidth: "100vw" }}
          variant={selectedQuickFilter === CheckQuickFiltersEnumType.ReadyToDeposit ? "awesome" : "awesome-filled"}
          clickable
          disabled={disabled}
        />
      )}
      <Chip
        label={
          <>
            <Typography>Processing {isLoading ? "" : `(${processingCount})`}</Typography>
            <Typography>{isLoading ? <Skeleton variant="text" /> : formatCurrency(processingSum)}</Typography>
          </>
        }
        component="a"
        href={`/org/${contextStore.selectedOrganizationId}/vchecks/${source}/${CheckQuickFiltersEnumType.Processing}`}
        onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
          event.preventDefault();
          setDisabled(true);
          history.push(
            `/org/${contextStore.selectedOrganizationId}/vchecks/${source}/${CheckQuickFiltersEnumType.Processing}`,
          );
        }}
        sx={{ flex: 1, maxWidth: "100vw" }}
        icon={<Processing fill="#B5ECA6" stroke="#6FD055" />}
        variant={selectedQuickFilter === CheckQuickFiltersEnumType.Processing ? "awesome" : "awesome-filled"}
        clickable
        disabled={disabled}
      />
      <Chip
        label={
          <>
            <Typography>Actions required {isLoading ? "" : `(${actionsRequiredCount})`}</Typography>
            <Typography>{isLoading ? <Skeleton variant="text" /> : formatCurrency(actionsRequiredSum ?? 0)}</Typography>
          </>
        }
        component="a"
        href={`/org/${contextStore.selectedOrganizationId}/vchecks/${source}/${CheckQuickFiltersEnumType.ActionRequired}`}
        onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
          event.preventDefault();
          setDisabled(true);
          history.push(
            `/org/${contextStore.selectedOrganizationId}/vchecks/${source}/${CheckQuickFiltersEnumType.ActionRequired}`,
          );
        }}
        icon={
          <ActionRequired
            fill={(actionsRequiredCount as number) > 0 ? "#FFF3E1" : "#B5ECA6"}
            stroke={(actionsRequiredCount as number) > 0 ? "#ECBB71" : "#6FD055"}
          />
        }
        sx={{ flex: 1, maxWidth: "100vw" }}
        variant={selectedQuickFilter === CheckQuickFiltersEnumType.ActionRequired ? "awesome" : "awesome-filled"}
        clickable
        disabled={disabled}
      />

      {isPayments && (
        <Chip
          label={
            <>
              <Typography>Unresolved {isLoading ? "" : `(${unresolvedChatsCount ?? 0})`}</Typography>
              <Typography>
                {isLoading ? <Skeleton variant="text" /> : formatCurrency(unresolvedChatsSum ?? 0)}
              </Typography>
            </>
          }
          component="a"
          href={`/org/${contextStore.selectedOrganizationId}/vchecks/${source}/${CheckQuickFiltersEnumType.UnresolvedChats}`}
          onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
            event.preventDefault();
            setDisabled(true);
            history.push(
              `/org/${contextStore.selectedOrganizationId}/vchecks/${source}/${CheckQuickFiltersEnumType.UnresolvedChats}`,
            );
          }}
          icon={
            <Unresolved
              fill={((unresolvedChatsCount as number) ?? 0) > 0 ? "#FFF3E1" : "#B5ECA6"}
              stroke={((unresolvedChatsCount as number) ?? 0) > 0 ? "#ECBB71" : "#6FD055"}
              sx={{ color: `${((unresolvedChatsCount as number) ?? 0) > 0 ? "#FFF3E1" : "#B5ECA6"} !important` }}
            />
          }
          sx={{ flex: 1, maxWidth: "100vw" }}
          variant={selectedQuickFilter === CheckQuickFiltersEnumType.UnresolvedChats ? "awesome" : "awesome-filled"}
          clickable
          disabled={disabled}
        />
      )}
      <Chip
        label={
          <>
            <Typography>Completed</Typography>
          </>
        }
        component="a"
        href={`/org/${contextStore.selectedOrganizationId}/vchecks/${source}/${CheckQuickFiltersEnumType.Completed}`}
        onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
          event.preventDefault();
          setDisabled(true);
          history.push(
            `/org/${contextStore.selectedOrganizationId}/vchecks/${source}/${CheckQuickFiltersEnumType.Completed}`,
          );
        }}
        icon={<Completed fill="#B5ECA6" stroke="#6FD055" />}
        sx={{ flex: 1, maxWidth: "100vw" }}
        variant={selectedQuickFilter === CheckQuickFiltersEnumType.Completed ? "awesome" : "awesome-filled"}
        clickable
        disabled={disabled}
      />
    </Box>
  );
};

export default observer(QuickFilters);
