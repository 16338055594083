import axios from "axios";
import { appConfig } from "common/appConfig";
import { GqlErrorQueue } from "common/graphqlClient";
import { QaTools } from "common/helpers/qaTools";
import { toJS } from "mobx";
import React from "react";
import ReactDOM from "react-dom";
import { IntlProvider } from "react-intl";
import Modal from "react-modal";
import { IStoreContainer } from "storesMobx";
import "tippy.js/dist/tippy.css";
import { Admin } from "./Admin";
import "./fonts/fonts.scss";

Modal.setAppElement(document.getElementById("root")!);

declare global {
  interface Window {
    reportError: (error: any) => any;
    reactAppStarted?: boolean;
    storeContainer?: IStoreContainer;
    toJS?: typeof toJS;
    gqlErrorsQueue?: {
      push: (err: GqlErrorQueue) => void;
    };
    qa: QaTools;
  }
}

window.reportError = async (error: any) =>
  axios
    .post("/api/felog", { error, message: `${error}`, stack: error?.stack })
    .catch((error) => console.log("Could not report error", error));
window.toJS = toJS;
window.gqlErrorsQueue = {
  push: (err: GqlErrorQueue) => {
    if (err.operationName && ["SessionAccount"].includes(err.operationName)) return;

    if (err.message === "This General Ledger Account is already mapped to another bank account.") return; //this error is already handled in it's component
    if (err.message === "The invite already accepted.") return;
    if (err.message === "Email already has an invitation for this company group.") return;
    if (err.message === "The invite you requested could not be found.") return;
    if (err.message === "Phone Verification has not been initialized for this Account") return;
    if (err.message === "You need to be logged in in order to perform this action") return;
    if (err.message === "You are not allowed to add yourself to a group.") return;
    if (err.responseBody?.errors?.extensions?.validation?.email_address) return;
    if (
      err.message === "You need to be logged in in order to perform this action" &&
      !window.location.pathname.includes("guest")
    ) {
      (window as any).location = "/login";
      return;
    }
    console.error(`GQL Error: ${err.message} at ${err.operationName} (${JSON.stringify(err.variables)})`);
  },
};

setTimeout(() => {
  if (!window.reactAppStarted) {
    const error = `Watchdog: react app failed to start after ${appConfig.VITE_APP_WATCHDOG_TIMEOUT}ms`;

    console.error(error);
    window.reportError(error);
  }
}, appConfig.VITE_APP_WATCHDOG_TIMEOUT);

ReactDOM.render(
  <React.StrictMode>
    <IntlProvider locale="en" defaultLocale="en">
      <Admin />
    </IntlProvider>
  </React.StrictMode>,
  document.getElementById("root"),
);
