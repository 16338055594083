import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Button } from "@mui/material";
import { graphqlChatClient, graphqlVBillClient } from "common/graphqlClient";
import { CHDecimal } from "common/helpers/decimal";
import { IChatChannelParentType, getSdk as chatGetSdk } from "generated/sdk.chat";
import { getSdk as vBillGetSdk } from "generated/sdk.vbill";
import { observer } from "mobx-react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useStore } from "storeContainer";
import {
  IVBillAddEditBatchSubmitPayload,
  VBillAddEditBatchDialog,
} from "../../../common/VBill/VBillAddEditBatchDialog/VBillAddEditBatchDialog";
import { useBatchLedgerFilters } from "../../hooks";

const { VBillCreateBatch } = vBillGetSdk(graphqlVBillClient);
const { ChatCreateChannel } = chatGetSdk(graphqlChatClient);

export const AddBatchBtn = observer(function AddBatchBtn() {
  const vBillBatchLedgerStore = useStore("VBillBatchLedgerStore");
  const userSettingsStore = useStore("UserSettingsStore");
  const { organizationId } = useParams<{ organizationId: string }>();
  const [batchLedgerFilters, setBatchLedgerFilters] = useBatchLedgerFilters();
  const [isAddBatchModalOpen, setIsAddBatchModalOpen] = useState(false); // TODO:

  const handleAddEditBatchSubmit = async ({ name, budget }: IVBillAddEditBatchSubmitPayload) => {
    const createBatchResp = await VBillCreateBatch({
      batchData: {
        organizationId,
        name: name ?? "",
        budget: new CHDecimal(budget ?? 0).toFixed(2),
        // TODO:
        // batchFilters: {
        //   companyIds: userSettingsStore.companiesSelectedIds.length
        //     ? userSettingsStore.companiesSelectedIds
        //     : undefined,
        // },
      },
    });
    await ChatCreateChannel({
      channelIo: {
        parentContext: "sending",
        parentKey: `${createBatchResp.createBatch.data?.id ?? "0"}`,
        parentType: IChatChannelParentType.VbillBatch,
        parentOrganizationId: organizationId,
      },
      membersIo: [],
    });
    setIsAddBatchModalOpen(false);
    setBatchLedgerFilters((prev) => ({
      filters: undefined,
      pagination: { page: 1, per_page: prev.pagination.per_page },
    }));
    vBillBatchLedgerStore.loadBatchLedgerList(
      { filters: { organizationId }, pagination: batchLedgerFilters.pagination },
      true,
    );
  };

  return (
    <>
      <Button variant="contained" onClick={() => setIsAddBatchModalOpen(true)} startIcon={<AddCircleIcon />}>
        Add Batch
      </Button>

      {isAddBatchModalOpen && (
        <VBillAddEditBatchDialog
          showBudgetPicker
          showNamePicker
          onDialogClose={() => setIsAddBatchModalOpen(false)}
          onSubmit={handleAddEditBatchSubmit}
        />
      )}
    </>
  );
});
