import { graphqlClient } from "common/graphqlClient";
import { FrontendSettingsContextEnum, GetFrontendSettingsQueryQuery, getSdk } from "generated/sdk";
import { computed, makeObservable } from "mobx";
import { createObservableContainer } from "storeContainer";
import { StoreBase } from "./StoreBase";

const { GetFrontendSettingsQuery, SaveFrontendSettingsMutation } = getSdk(graphqlClient);

interface IFrontendSettingsJsonDescriptor {
  companies?: {
    favorites?: string;
    selected?: string;
  };
}

enum SaveFrontendSettingsPath {
  COMPANIES_FAVORITES = "companies.favorites",
  COMPANIES_SELECTED = "companies.selected",
}

interface IFrontendSettingsData {
  companies: {
    favorites?: string[];
    selected?: string[];
  };
}

export class UserSettingsStore extends StoreBase {
  organizationUserId?: string;
  frontendSettingsObservable = createObservableContainer<GetFrontendSettingsQueryQuery["GetFrontendSettingsQuery"]>();

  constructor() {
    super();
    makeObservable(this, {
      frontendSettings: computed,
      companiesSelectedIds: computed,
      companiesFavoriteIds: computed,
    });
  }

  setOrganizationUserId = (organizationId?: string) => {
    const organization = this.storeContainer?.SessionStore?.session.data?.organizationUsers?.find(
      ({ organization }) => organization.id === organizationId,
    );

    if (!organization) {
      return;
    }

    this.organizationUserId = organization.id;
  };

  getFrontendSettings() {
    if (!this.organizationUserId) {
      return;
    }

    this.frontendSettingsObservable.cachedLoad(
      async () =>
        (
          await GetFrontendSettingsQuery({
            context: FrontendSettingsContextEnum.OrganizationUser,
            id: this.organizationUserId ?? "",
          })
        ).GetFrontendSettingsQuery,
      [],
      { forceUpdate: true },
    );
  }

  saveFrontendSettings = (path: SaveFrontendSettingsPath, value: string[]) => {
    if (!this.organizationUserId) {
      return;
    }

    this.frontendSettingsObservable.cachedLoad(
      async () =>
        (
          await SaveFrontendSettingsMutation({
            context: FrontendSettingsContextEnum.OrganizationUser,
            id: this.organizationUserId ?? "",
            path,
            value: JSON.stringify(value),
          })
        ).SaveFrontendSettingsMutation,
      [],
      { forceUpdate: true },
    );
  };

  get frontendSettings(): IFrontendSettingsData | undefined {
    if (!this.frontendSettingsObservable.data) {
      return undefined;
    }

    try {
      const jsonData = JSON.parse(this.frontendSettingsObservable.data ?? "{}") as IFrontendSettingsJsonDescriptor;
      const frontendSettings: IFrontendSettingsData = {
        companies: {
          favorites: jsonData?.companies?.favorites ? JSON.parse(jsonData?.companies?.favorites ?? "[]") : undefined,
          selected: jsonData?.companies?.selected ? JSON.parse(jsonData?.companies?.selected ?? "[]") : undefined,
        },
      };

      return frontendSettings;
    } catch (error) {
      window.reportError(`UserSettingsStore error parsing GetFrontendSettingsQuery with error: ${error}`);

      return undefined;
    }
  }

  setSelectedCompany = (companyId: string, value: boolean) => {
    const newSelectedCompanies = value
      ? [...(this.frontendSettings?.companies.selected ?? []), companyId]
      : [...(this.frontendSettings?.companies.selected ?? []).filter((compId) => compId !== companyId)];

    this.saveFrontendSettings(SaveFrontendSettingsPath.COMPANIES_SELECTED, newSelectedCompanies);
  };

  setSelectedCompanies = (companyIds: string[], value: boolean) => {
    const newSelectedCompanies = value
      ? [...(this.frontendSettings?.companies.selected ?? []), ...companyIds]
      : [...(this.frontendSettings?.companies.selected ?? []).filter((compId) => !companyIds.includes(compId))];

    this.saveFrontendSettings(SaveFrontendSettingsPath.COMPANIES_SELECTED, newSelectedCompanies);
  };

  setFavoriteCompany = (companyId: string, value: boolean) => {
    const newFavoriteCompanies = value
      ? [...(this.frontendSettings?.companies.favorites ?? []), companyId]
      : [...(this.frontendSettings?.companies.favorites ?? []).filter((compId) => compId !== companyId)];

    this.saveFrontendSettings(SaveFrontendSettingsPath.COMPANIES_FAVORITES, newFavoriteCompanies);
  };

  setAllSelectedCompanies = (companyIds: string[], value: boolean) => {
    const newSelectedCompanies = value ? companyIds : [];

    this.saveFrontendSettings(SaveFrontendSettingsPath.COMPANIES_SELECTED, newSelectedCompanies);
  };

  setAllFavoriteCompanies = () => {
    this.saveFrontendSettings(SaveFrontendSettingsPath.COMPANIES_SELECTED, this.companiesFavoriteIds);
  };

  get companiesSelectedIds() {
    return this.frontendSettings?.companies.selected ?? [];
  }

  get companiesFavoriteIds() {
    return this.frontendSettings?.companies.favorites ?? [];
  }
}
