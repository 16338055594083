import { Box, Typography } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import { ChatIcon, HistoryIcon } from "components/common/icons";
import { VCheckCheckQueryQuery } from "generated/sdk";
import { isNil } from "lodash";
import { ChatDetailDashboard, IChatChannelParentType, TBaseIoChannel } from "modules/Chat";
import { SyntheticEvent, useEffect, useMemo } from "react";
import { useStore } from "storeContainer";
import { StringParam, useQueryParam, withDefault } from "use-query-params";
import { VCheckActivityLog } from "../VCheckActivityLog/VCheckActivityLog";
import { a11yProps, SidebarTabBtn } from "./SidebarTabBtn/SidebarTabBtn";
import { SidebarTabPanel } from "./SidebarTabPanel/SidebarTabPanel";

export const vCheckSidebarPanelQSKey = "sidebar_panel";
export enum vCheckSidebarPanels {
  "CHAT" = "chat",
  "ACTIVITY_LOG" = "activity_log",
}
export const vCheckChatPanelQSKey = "chat_panel";
export enum vCheckChatPanels {
  "SR_CHAT" = "sr_chat",
  "SUPPORT_CHAT" = "support_chat",
  "RS_CHAT" = "rs_chat",
}

interface IVCheckDetailsSidebarProps {
  checkDetails: NonNullable<VCheckCheckQueryQuery["VCheckCheckQuery"]>;
  isModalOpened: boolean;
  isModal: boolean;
}

export const VCheckDetailsSidebar = ({ checkDetails, isModal, isModalOpened }: IVCheckDetailsSidebarProps) => {
  const sessionStore = useStore("SessionStore");
  const sessionAccountId = useMemo(
    () => sessionStore.session.data?.account?.id ?? "",
    [sessionStore.session.data?.account?.id],
  );
  const [sidebarPanel, setSidebarPanel] = useQueryParam<vCheckSidebarPanels>(
    vCheckSidebarPanelQSKey,
    withDefault<any, vCheckSidebarPanels>(StringParam, vCheckSidebarPanels.CHAT),
  );
  const [chatPanel, setChatPanel] = useQueryParam<vCheckChatPanels>(
    vCheckChatPanelQSKey,
    withDefault<any, vCheckChatPanels>(StringParam, vCheckChatPanels.SUPPORT_CHAT),
    { removeDefaultsFromUrl: true },
  );

  useEffect(() => {
    if (isModal && !isModalOpened) {
      setSidebarPanel(vCheckSidebarPanels.CHAT, "replaceIn");
      setChatPanel(vCheckChatPanels.SUPPORT_CHAT, "replaceIn");
    }
  }, [isModal, isModalOpened, sidebarPanel, setSidebarPanel, setChatPanel]);
  const ledgerCheckDetails = checkDetails.ledger_check;
  const organizationId =
    ledgerCheckDetails?.role === "sender"
      ? ledgerCheckDetails?.sender?.organization_id
      : ledgerCheckDetails?.recipient?.organization_id;

  //SidebarTabPanels.SR_CHAT
  const srChatDetailDashboardBaseIoChannel: TBaseIoChannel = useMemo(
    () => ({
      parentContext: `${ledgerCheckDetails?.role === "sender" ? "sending" : "receiving"}`,
      parentKey: `${ledgerCheckDetails?.id ?? ""}`,
      parentType: IChatChannelParentType.Vcheck,
    }),
    [ledgerCheckDetails?.id, ledgerCheckDetails?.role],
  );

  // SidebarTabPanels.SUPPORT_CHAT
  const supportChatDetailDashboardBaseIoChannel: TBaseIoChannel = useMemo(
    () => ({
      parentContext: `${ledgerCheckDetails?.role === "sender" ? "sending" : "receiving"}-admin`,
      parentKey: `${ledgerCheckDetails?.id ?? ""}`,
      parentType: IChatChannelParentType.Vcheck,
    }),
    [ledgerCheckDetails?.id, ledgerCheckDetails?.role],
  );

  //SidebarTabPanels.RS_CHAT
  const rsChatDetailDashboardBaseIoChannel: TBaseIoChannel = useMemo(
    () => ({
      parentContext: `${ledgerCheckDetails?.role === "sender" ? "receiving" : "sending"}`,
      parentKey: `${ledgerCheckDetails?.id ?? ""}`,
      parentType: IChatChannelParentType.Vcheck,
    }),
    [ledgerCheckDetails?.id, ledgerCheckDetails?.role],
  );

  return (
    <Box
      sx={{
        width: "500px",
        backgroundColor: "#f6f9fe",
        padding: "20px",
        boxShadow: "inset 2px 0 16px rgba(5,32,72,.06)",
      }}
    >
      <Box>
        <Tabs
          value={sidebarPanel}
          onChange={(_event: SyntheticEvent<Element, Event>, value: vCheckSidebarPanels) => {
            if (value === vCheckSidebarPanels.ACTIVITY_LOG) {
              setChatPanel(vCheckChatPanels.SUPPORT_CHAT, "replaceIn");
            }
            setSidebarPanel(value, "replaceIn");
          }}
          aria-label="vcheck details sidebar tabs"
          centered
          sx={{ border: "none" }}
        >
          <SidebarTabBtn
            label={
              <Box display="flex" alignItems="center" gap="8px">
                <ChatIcon htmlColor="#16458B" />
                <Typography>Messages</Typography>
              </Box>
            }
            {...a11yProps(0)}
            value={vCheckSidebarPanels.CHAT}
          />
          <SidebarTabBtn
            label={
              <Box display="flex" alignItems="center" gap="8px">
                <HistoryIcon htmlColor="#16458B" />
                <Typography>Activity log</Typography>
              </Box>
            }
            {...a11yProps(1)}
            value={vCheckSidebarPanels.ACTIVITY_LOG}
          />
        </Tabs>

        <SidebarTabPanel activePannel={sidebarPanel} panelName={vCheckSidebarPanels.CHAT}>
          <Tabs
            value={chatPanel}
            onChange={(_event: SyntheticEvent<Element, Event>, value: vCheckChatPanels) => {
              setChatPanel(value, "replaceIn");
            }}
            aria-label="vcheck details sidebar tabs bottom"
            sx={{ border: "none", minHeight: "30px" }}
            variant="scrollable"
            scrollButtons="auto"
          >
            <SidebarTabBtn
              small
              label={
                <Box display="flex" alignItems="center" gap="8px">
                  <ChatIcon htmlColor="#16458B" />
                  <Typography>Cherry</Typography>
                </Box>
              }
              {...a11yProps(0)}
              value={vCheckChatPanels.SUPPORT_CHAT}
            />
            <SidebarTabBtn
              small
              label={
                <Box display="flex" alignItems="center" gap="8px">
                  <ChatIcon htmlColor="#16458B" />
                  <Typography>
                    {ledgerCheckDetails?.role === "sender"
                      ? ledgerCheckDetails.sender?.organization_name
                      : ledgerCheckDetails?.recipient?.organization_name || ledgerCheckDetails?.recipient_title}
                  </Typography>
                </Box>
              }
              {...a11yProps(1)}
              value={vCheckChatPanels.SR_CHAT}
            />
            <SidebarTabBtn
              small
              label={
                <Box display="flex" alignItems="center" gap="8px">
                  <ChatIcon htmlColor="#16458B" />
                  <Typography>
                    {ledgerCheckDetails?.role !== "sender"
                      ? ledgerCheckDetails?.sender?.organization_name || ledgerCheckDetails?.sender_title
                      : ledgerCheckDetails?.recipient?.organization_name || ledgerCheckDetails?.recipient_title}
                  </Typography>
                </Box>
              }
              {...a11yProps(2)}
              value={vCheckChatPanels.RS_CHAT}
            />
          </Tabs>

          <SidebarTabPanel activePannel={chatPanel} panelName={vCheckChatPanels.SR_CHAT}>
            <ChatDetailDashboard
              config={{
                baseIoChannel: srChatDetailDashboardBaseIoChannel,
                organizationId: organizationId ?? "",
                sessionAccountId: sessionAccountId,
              }}
              settings={{
                showMembers: true,
                showVisibility: true,
                channelComingSoon: isNil(organizationId),
              }}
              // relatedItems={[
              //   {
              //     key: "1111",
              //     related: { key: "ddddd", label: "aaaaaaa", type: IChatContentBlockMessageRelatedType.Bill },
              //   },
              //   {
              //     key: "22222",
              //     related: { key: "ddddd", label: "aaaaaaa", type: IChatContentBlockMessageRelatedType.Bill },
              //   },
              //   {
              //     key: "33333",
              //     related: { key: "ddddd", label: "aaaaaaa", type: IChatContentBlockMessageRelatedType.Bill },
              //   },
              // ]}
              sx={{ height: `calc(100vh - ${isModal ? 136 : 256}px)` }}
              channelSx={{ height: "100%", display: "flex", flexDirection: "column" }}
            />
          </SidebarTabPanel>
          <SidebarTabPanel activePannel={chatPanel} panelName={vCheckChatPanels.SUPPORT_CHAT}>
            <ChatDetailDashboard
              config={{
                baseIoChannel: supportChatDetailDashboardBaseIoChannel,
                organizationId: organizationId ?? "",
                sessionAccountId: sessionAccountId,
              }}
              settings={{
                forcePublic: true,
                channelComingSoon: isNil(organizationId),
              }}
              sx={{ height: `calc(100vh - ${isModal ? 136 : 256}px)` }}
              channelSx={{ height: "100%", display: "flex", flexDirection: "column" }}
            />
          </SidebarTabPanel>
          <SidebarTabPanel activePannel={chatPanel} panelName={vCheckChatPanels.RS_CHAT}>
            <ChatDetailDashboard
              config={{
                baseIoChannel: rsChatDetailDashboardBaseIoChannel,
                organizationId: organizationId ?? "",
                sessionAccountId: sessionAccountId,
              }}
              settings={{
                showMembers: true,
                showVisibility: true,
                channelComingSoon: true,
              }}
              sx={{ height: `calc(100vh - ${isModal ? 136 : 256}px)` }}
              channelSx={{ height: "100%", display: "flex", flexDirection: "column" }}
            />
          </SidebarTabPanel>
        </SidebarTabPanel>

        <SidebarTabPanel activePannel={sidebarPanel} panelName={vCheckSidebarPanels.ACTIVITY_LOG}>
          <VCheckActivityLog />
        </SidebarTabPanel>
      </Box>
    </Box>
  );
};
