import { graphqlClient } from "common/graphqlClient";
import { BankAccount, CompanyIntegration, getSdk } from "generated/sdk";
import { definitely, definitelyFilter } from "generated/utils";
import { createObservableContainer, IDataLoaderOpts } from "storeContainer";
import { StoreBase } from "storesMobx/StoreBase";
import { CompanyDetailsQuery } from "./../generated/sdk";

const { CompanyIntegrations, CompanyDetails, UpdateCompanyNotificationSubscription, UpdateBankAccount } =
  getSdk(graphqlClient);

export class CompanyStore extends StoreBase {
  integrations = createObservableContainer<CompanyIntegration[]>();
  companyDetails = createObservableContainer<NonNullable<CompanyDetailsQuery["CompanyDetails"]>>();
  bankAccounts = createObservableContainer<NonNullable<BankAccount[]>>();

  private async fetchIntegrations(companyId: string): Promise<CompanyIntegration[]> {
    return definitelyFilter((await CompanyIntegrations({ companyId })).CompanyIntegrations?.integrations);
  }

  private async fetchCompanyDetails(company_id: string) {
    return definitely((await CompanyDetails({ company_id })).CompanyDetails);
  }

  async loadIntegrations(opts?: IDataLoaderOpts) {
    const company_id = this.storeContainer?.ContextStore?.selectedCompanyId;

    if (company_id) {
      await this.integrations.cachedLoad(() => this.fetchIntegrations(company_id), [company_id], opts);
    }
  }
  async fetchIntegrationsForCompanyId(company_id: string, opts?: IDataLoaderOpts) {
    if (company_id) {
      return await this.fetchIntegrations(company_id);
    }
  }

  async loadCompanyDetails(opts?: IDataLoaderOpts) {
    const company_id = this.storeContainer?.ContextStore?.selectedCompanyId;

    if (company_id) {
      await this.companyDetails.cachedLoad(() => this.fetchCompanyDetails(company_id), [company_id], opts);
    }
  }

  async updateCompanyNotificationSubscription(company_id: string, email_notification: boolean) {
    await UpdateCompanyNotificationSubscription({ company_id, email_notification });
  }

  async changeBankAccountName(bank_account_id: string, nickname: string) {
    await UpdateBankAccount({ bank_account_id, nickname });
  }
}
