import { observer } from "mobx-react-lite";
import { stepConnectorClasses, styled, StepIconProps } from "@mui/material";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import StepConnector from "@mui/material/StepConnector";
import StepLabel from "@mui/material/StepLabel";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import { useStore } from "../../../storeContainer";
import { LoadingButton } from "@mui/lab";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { COLORS } from "../../../themes/default";

export const ConfirmPhone: React.FunctionComponent = observer(function ConfirmPhone() {
  const SessionStore = useStore("SessionStore");
  const OrganizationStore = useStore("OrganizationStore");
  const { codeRequested, codeConfirmed } = SessionStore;
  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    backgroundColor: theme.palette.secondary.main,
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: theme.palette.grey[400],
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {},
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor: theme.palette.secondary.main,
      borderRadius: 1,
    },
  }));
  const ColorlibStepIconRoot = styled("div")<{
    ownerState: { completed?: boolean; active?: boolean };
  }>(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.secondary.main,
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    ...(ownerState.active && {
      backgroundColor: theme.palette.grey[400],
    }),
  }));
  function ColorlibStepIcon(props: StepIconProps) {
    const { active, completed, className } = props;

    return (
      <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
        <Typography variant={"h6"} sx={{ color: COLORS.white }} fontWeight={600}>
          {props.icon}
        </Typography>
      </ColorlibStepIconRoot>
    );
  }

  const steps = ["Registration", "Email verification", "Phone verification"];
  const [codeError, setCodeError] = useState("");
  const [channel, setChannel] = useState("sms");
  const [smsCode, setSmsCode] = useState(["", "", "", "", "", ""]);
  const [activeStep, setActiveStep] = useState(2);
  const [phoneNumber, setPhoneNumber] = useState(SessionStore.session.data?.account?.phone_number);
  const [phoneNumberEditable, setPhoneNumberEditable] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState<string | null>();

  const inputRefs = useRef<HTMLInputElement[]>([]);

  const isNumeric = (val: string): boolean => {
    return !isNaN(Number(val));
  };

  async function handleChange(e: React.ChangeEvent<HTMLInputElement>, index: number) {
    const value = e.target.value;
    if (value === " ") return;
    if (!isNumeric(value)) {
      return;
    }
    setSmsCode((prevSmsCode) => {
      const newSmsCode = [...prevSmsCode];
      if (value) {
        if (index < 5) {
          newSmsCode[index + 1] = "";
        }
      }
      newSmsCode[index] = value;
      return newSmsCode;
    });
    if (value) {
      if (index < 5) {
        const nextIndex = index + 1;
        const field = inputRefs.current[nextIndex];
        if (field) {
          field.focus();
        }
      }
    } else {
      const prevIndex = index - 1;
      const field = inputRefs.current[prevIndex];
      if (field) {
        field.focus();
      }
    }
  }

  const requestCode = async (channel: string) => {
    if ((phoneNumber?.length || 0) < 10) {
      setPhoneNumberError("You must enter at least 10 characters");
    } else {
      setOpenRequestCodeDialog(false);
      setOpenEnterCodeDialog(true);
      setPhoneNumberError(null);
      setChannel(channel);
      setSmsCode(["", "", "", "", "", ""]);
      try {
        await SessionStore.RequestCode(phoneNumber, channel);
      } catch (e: any) {}
    }
  };

  const confirmCode = async () => {
    const code = smsCode.join("");
    if (code.length === 6) {
      setCodeError("");
      try {
        await SessionStore.ConfirmCode(code);
      } catch (e: any) {
        setPhoneNumberError(e.response.errors?.message);
      }
    } else {
      setCodeError("The code field is required");
    }
  };

  const [openRequestCodeDialog, setOpenRequestCodeDialog] = useState(false);
  const handleRequestCodeDialogClose = () => {
    setOpenRequestCodeDialog(false);
  };

  const [openEnterCodeDialog, setOpenEnterCodeDialog] = useState(false);
  const handleEnterCodeDialogClose = () => {
    setOpenEnterCodeDialog(false);
  };

  useEffect(() => {
    if (codeRequested.error) {
      setPhoneNumberError(codeRequested.error.response.errors?.message);
    }
  }, [codeRequested.error]);

  useEffect(() => {
    if (codeRequested.data === true) {
      handleRequestCodeDialogClose();
      setOpenEnterCodeDialog(true);
      setPhoneNumberEditable(false);
    }
  }, [codeRequested.data]);

  useEffect(() => {
    if (codeConfirmed.error) {
      setSmsCode(["", "", "", "", "", ""]);
      setCodeError(codeConfirmed.error.response.errors?.message);
    }
  }, [codeConfirmed.error]);

  useEffect(() => {
    if (codeConfirmed.data === true) {
      setActiveStep(3);
      setOpenEnterCodeDialog(false);

      const loadSession = async () => {
        await SessionStore.loadSession();
      };
      loadSession();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [codeConfirmed.data]);

  useEffect(() => {
    OrganizationStore.loadOrganizationDashboard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        marginTop: "25px",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        maxWidth: "1400px",
      }}
    >
      <Typography variant={"h4"} fontWeight={500} color={"secondary"}>
        Welcome, {SessionStore.session.data?.account?.name}
      </Typography>

      <Paper sx={{ mt: 2, p: 2 }}>
        <Typography variant={"h6"} fontWeight={500}>
          Account status:
        </Typography>
        <Box>
          <Stepper connector={<ColorlibConnector />} sx={{ mt: 2 }} activeStep={activeStep} alternativeLabel>
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel StepIconComponent={ColorlibStepIcon}>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography color={`${index !== activeStep && "secondary"}`} variant={"button"} fontWeight={500}>
                      {label}
                    </Typography>
                    {index === 2 && (
                      <Box sx={{ mt: 2 }}>
                        <Button
                          onClick={() => {
                            setOpenRequestCodeDialog(true);
                          }}
                          color={"secondary"}
                          fullWidth={false}
                          component="span"
                          size={"small"}
                          variant={"contained"}
                        >
                          Verify
                        </Button>
                      </Box>
                    )}
                  </Box>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
          {/* {codeConfirmed.data === true && (
            <Box
              sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", mt: 2 }}
            >
              <Typography variant={"h6"} color={"secondary"}>
                Verification Steps Completed!
              </Typography>

              {companiesWithBankAccountsAndAggregatedData.data?.filter((data) => {
                if (data.bank_accounts) {
                  return data.bank_accounts.length > 0;
                } else {
                  return false;
                }
              }).length === 0 && (
                <Typography
                  sx={{
                    textAlign: "center",
                    mt: 1,
                  }}
                >
                  You don't seem to have any Bank Accounts setup
                  <br />
                  Get Started by linking your first Bank Accounts to Shtar&trade;.
                </Typography>
              )}

              <Button
                fullWidth={false}
                onClick={onGetStartedClicked}
                variant={"outlined"}
                color={"secondary"}
                size={"small"}
                sx={{ mt: 2 }}
              >
                Get started
              </Button>
            </Box>
          )} */}
        </Box>
      </Paper>
      <Box></Box>
      <Dialog open={openRequestCodeDialog} onClose={handleRequestCodeDialogClose}>
        <DialogTitle id="alert-dialog-title">{"SMS Verification"}</DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography sx={{ fontWeight: "bold" }}>
              Some account features are restricted until you validate your phone number
            </Typography>
            {phoneNumberEditable && (
              <Typography sx={{ mt: 4 }}>The new Phone Number will be saved to your Cherry&trade; Account</Typography>
            )}
          </DialogContentText>

          <TextField
            sx={{
              mt: 2,
              p: 0,
            }}
            fullWidth={true}
            inputProps={{ style: { fontSize: 24, textAlign: "center" } }}
            onChange={(e) => {
              setPhoneNumber(e.target.value);
            }}
            value={phoneNumber || ""}
          />

          <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
            {phoneNumberError && (
              <Typography sx={{ mb: 2 }} color={"error"}>
                {phoneNumberError}
              </Typography>
            )}

            <Box sx={{ display: "flex", flexDirection: "column", gap: "15px", marginTop: "45px" }}>
              <Box
                sx={{ display: "flex", flexDirection: "row", gap: 2, justifyContent: "center", justifyItems: "center" }}
              >
                <LoadingButton
                  loading={codeRequested.isFetching}
                  onClick={() => requestCode("sms")}
                  variant={"contained"}
                  color={"secondary"}
                >
                  Get Code by SMS
                </LoadingButton>
                <LoadingButton
                  loading={codeRequested.isFetching}
                  onClick={() => requestCode("ivr")}
                  variant={"contained"}
                  color={"secondary"}
                >
                  Get Code by Call
                </LoadingButton>
              </Box>
              <Button
                onClick={() => {
                  handleRequestCodeDialogClose();
                  setOpenEnterCodeDialog(true);
                }}
                variant={"text"}
                size={"small"}
              >
                I have a code
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog open={openEnterCodeDialog} onClose={handleEnterCodeDialogClose}>
        <DialogTitle id="alert-dialog-title">{"Enter the 6 digit OTP"}</DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            A 6 digit one time password has been sent to your phone number registered to your account
          </DialogContentText>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: 1,
              m: 2,
            }}
          >
            {smsCode.map((code, index) => (
              <TextField
                name={`sms-code-${index}`}
                key={index}
                inputProps={{ maxLength: 1, style: { fontSize: 24, textAlign: "center" } }}
                value={code || ""}
                inputRef={(ref) => (inputRefs.current[index] = ref)}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, index)}
              />
            ))}
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
            {codeError !== undefined && (
              <Typography sx={{ mb: 2 }} color={"error"}>
                {codeError}
              </Typography>
            )}
            <Box sx={{ display: "inline-flex", flexDirection: "column", gap: 2 }}>
              <LoadingButton
                fullWidth={false}
                onClick={confirmCode}
                component="span"
                color={"secondary"}
                loading={codeConfirmed.isFetching}
                variant={"contained"}
              >
                Validate
              </LoadingButton>

              <Box sx={{ display: "inline-flex", justifyContent: "center", gap: 2, alignItems: "center" }}>
                <Button
                  onClick={() => {
                    setPhoneNumberEditable(true);
                    setOpenEnterCodeDialog(false);
                    setOpenRequestCodeDialog(true);
                  }}
                  component="span"
                  size={"small"}
                  variant={"text"}
                  color={"info"}
                >
                  Change number
                </Button>
                -
                <LoadingButton
                  fullWidth={false}
                  onClick={() => requestCode(channel)}
                  component="span"
                  size={"small"}
                  color={"info"}
                  loading={codeRequested.isFetching}
                  variant={"text"}
                >
                  Resend code
                </LoadingButton>
              </Box>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
});
