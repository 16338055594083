import { FunctionComponent } from "react";
import Button from "components/common/Button";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import { Spinner } from "components/common/Spinner/Spinner";

export const ArchiveCheckModal: FunctionComponent<{
  closeArchiveModal: () => void;
  archiveModalOpen: boolean;
  archiveLoading: boolean;
  archiveCheck: Function;
  checksToBeArchived: string[];
}> = ({ closeArchiveModal, archiveModalOpen, archiveLoading, checksToBeArchived, archiveCheck }) => {
  return (
    <>
      <Modal
        onClose={closeArchiveModal}
        style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
        open={archiveModalOpen}
      >
        <div>
          <Paper sx={{ padding: "20px" }}>
            <Typography marginBottom={3} fontSize={20}>
              Are you sure you want to archive {checksToBeArchived.length > 1 ? "these" : "this"}{" "}
              {checksToBeArchived.length > 1 ? "checks" : "check"}?
            </Typography>
            <Grid justifyContent={"space-around"} sx={{ marginTop: "10px" }} container>
              <Button sx={{ padding: "10px 20px" }} onClick={closeArchiveModal} variant="text">
                Cancel
              </Button>
              <Button
                sx={{ padding: "10px 20px", marginLeft: "30px" }}
                onClick={() => (!archiveLoading ? archiveCheck(checksToBeArchived) : null)}
                variant="contained"
              >
                {archiveLoading ? <Spinner /> : "Archive"}
              </Button>
            </Grid>
          </Paper>
        </div>
      </Modal>
    </>
  );
};
