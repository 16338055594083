import { AddCircle, Create, Delete } from "@mui/icons-material";
import {
  Alert,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from "@mui/material";
import { useStore } from "storeContainer";
import { DelegationGroup } from "./types";

interface IChooseDelegationGroupProps {
  delegationGroups: DelegationGroup[];
  onCreateNew: () => void;
  onEdit: (delegationGroup: DelegationGroup) => void;
}
export const ChooseDelegationGroup: React.FunctionComponent<IChooseDelegationGroupProps> = ({
  delegationGroups,
  onCreateNew,
  onEdit,
}) => {
  const contextStore = useStore("ContextStore");
  const approvalStore = useStore("ApprovalStore");

  const handleDeleteDelegationGroup = (delegationGroup: DelegationGroup) => {
    approvalStore.deleteDelegationGroup({
      scope: delegationGroup.scope,
      organization_id: contextStore.selectedOrganizationId!,
      delegation_group_id: delegationGroup.id,
    });
  };

  return (
    <Box sx={{ padding: "15px", width: "350px" }}>
      <Alert severity="info">
        Select one of your existing assignment rules to edit or create a new one below.
        <br />
        <br />
        More specific rules will override general ones.
      </Alert>
      <List>
        {delegationGroups.map((delegationGroup) => {
          const primary = delegationGroup.bill_id
            ? "This bill"
            : delegationGroup.check_id
              ? "This check"
              : delegationGroup.vendor_id
                ? "This vendor"
                : delegationGroup.company_id
                  ? "This company"
                  : delegationGroup.organization_id
                    ? "This organization"
                    : null;
          const secondary =
            `Assigned to: ` +
            delegationGroup.delegations.map((delegation) => delegation.delegate.account?.name).join(",");

          return (
            <ListItem key={delegationGroup.id} disablePadding>
              <ListItemButton onClick={() => onEdit(delegationGroup)}>
                <ListItemIcon>
                  <Create />
                </ListItemIcon>
                <ListItemText primary={primary} secondary={secondary} />
                <ListItemSecondaryAction>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      handleDeleteDelegationGroup(delegationGroup);
                    }}
                  >
                    <Delete />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItemButton>
            </ListItem>
          );
        })}
        <ListItem key="new" disablePadding>
          <ListItemButton onClick={() => onCreateNew()}>
            <ListItemIcon>
              <AddCircle />
            </ListItemIcon>
            <ListItemText primary="Create new rule" />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );
};
