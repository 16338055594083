import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PaidIcon from "@mui/icons-material/Paid";
import { Box, Button } from "@mui/material";
import { useVBillPaymentsLedgerQuickFilterUrl } from "components/pages/VBillPaymentsLedger/hooks";
import { VBillPaymentsLedgerChildRoutes } from "components/pages/VBillPaymentsLedger/utils";
import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { Link, useParams, useRouteMatch } from "react-router-dom";
import { VBillPaymentsLedgerPath } from "routes/routes";
import { useStore } from "storeContainer";
import { COLORS } from "themes/default";

const activeRouteBtnStyles = {
  border: `1px solid ${COLORS.logoBlue} !important`, // "outlined" prop has important
  color: COLORS.logoBlue,
};
const routeBtnStyles = { height: "32px", textTransform: "none" };

export const PaymentsNavigation = observer(function PaymentsNavigation() {
  const organizationStore = useStore("OrganizationStore");
  const { organizationId } = useParams<{ organizationId: string }>();
  const paymentsLedgerUrl = useVBillPaymentsLedgerQuickFilterUrl(VBillPaymentsLedgerChildRoutes.ALL);
  const vCheckLedgerUrl = useMemo(() => `/org/${organizationId}/vchecks/payments/pending_signature`, [organizationId]);
  const isVCheckLedgerRoute = !!useRouteMatch("/org/:organizationId/vChecks/payments/:selectedQuickFilter");
  const isPaymentsLedgerRoute = !!useRouteMatch(VBillPaymentsLedgerPath);
  const { organizationDefaultDashboard, organizationVBillPaymentsLedgerEnabled } = organizationStore;

  if (
    !organizationDefaultDashboard.data?.vbill_enabled ||
    !organizationVBillPaymentsLedgerEnabled ||
    (!isPaymentsLedgerRoute && !isVCheckLedgerRoute)
  ) {
    return null;
  }

  return (
    <Box sx={{ display: "flex", gap: "20px", justifyContent: "center", padding: "10px 0 0" }}>
      <Link to={vCheckLedgerUrl}>
        <Button
          startIcon={<AttachMoneyIcon />}
          variant="outlined"
          sx={{ ...routeBtnStyles, ...(isVCheckLedgerRoute ? activeRouteBtnStyles : {}) }}
        >
          vChecks
        </Button>
      </Link>

      <Link to={paymentsLedgerUrl}>
        <Button
          startIcon={<PaidIcon />}
          variant="outlined"
          sx={{ ...routeBtnStyles, ...(isPaymentsLedgerRoute ? activeRouteBtnStyles : {}) }}
        >
          Bill Payments
        </Button>
      </Link>
    </Box>
  );
});
