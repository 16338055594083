import { Skeleton, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import { IVBillBatchStatus } from "generated/sdk.vbill";
import { observer } from "mobx-react";
import { ReactNode, useMemo } from "react";
import { useStore } from "storeContainer";
import { BatchVBill } from "../../BatchVBill/BatchVBill";
import { IBillsAccordianExpanded } from "../../BatchVBills";

const emptySkeletonList = new Array(3).fill("");

interface IVBillsSelectedListProps {
  droppableProvidedPlaceholder: ReactNode;
  onBatchVBillCheckboxChange: (checked: boolean, invoiceId: number) => void;
  billsAccordianExpanded: IBillsAccordianExpanded;
  selectedVBillsToDelete: number[];
}

export const VBillsSelectedList = observer(function VBillsSelectedList({
  droppableProvidedPlaceholder,
  onBatchVBillCheckboxChange,
  billsAccordianExpanded,
  selectedVBillsToDelete,
}: IVBillsSelectedListProps) {
  const organizationStore = useStore("OrganizationStore");
  const vBillBatchDetailsStore = useStore("VBillBatchDetailsStore");

  const { batchSelectedVBills, batchDetails, batchSelectedVBillsReactionsStats, vBillBatchAdditionalMappingsSettings } =
    vBillBatchDetailsStore;
  const batchSelectedVBillsData = batchSelectedVBills.data;
  const batchDetailsData = batchDetails.data;
  const batchSelectedVBillsReactionsStatsData = batchSelectedVBillsReactionsStats.data;
  const vBillBatchAdditionalMappingsSettingsData = vBillBatchAdditionalMappingsSettings.data;

  const isBatchClosed = useMemo(
    () =>
      batchDetailsData?.status === IVBillBatchStatus.Closed || batchDetailsData?.status === IVBillBatchStatus.Deleted,
    [batchDetailsData?.status],
  );
  const vBillEnabledCompanies = useMemo(
    () =>
      (organizationStore.organizationVBillEnabledCompanies.data?.items ?? []).map(({ name, companyId }) => ({
        name,
        id: companyId,
      })),
    [organizationStore.organizationVBillEnabledCompanies.data?.items],
  );

  return (
    <TableBody>
      {batchSelectedVBills.isLoading ? (
        <>
          {emptySkeletonList.map((_, index) => (
            <TableRow key={index} sx={{ cursor: "default" }}>
              <TableCell colSpan={8} sx={{ padding: "12px" }}>
                <Skeleton variant="rounded" sx={{ height: "30px" }} />
              </TableCell>
            </TableRow>
          ))}
        </>
      ) : (batchSelectedVBillsData?.items ?? []).length === 0 ? (
        <TableRow sx={{ cursor: "default" }}>
          <TableCell colSpan={12} sx={{ textAlign: "center" }}>
            <Typography>No results found</Typography>
          </TableCell>
        </TableRow>
      ) : (
        <>
          {batchSelectedVBillsData?.items.map((vBill, index) => (
            <BatchVBill
              index={index}
              key={vBill.id}
              vBill={vBill}
              checkboxSelected={selectedVBillsToDelete.includes(vBill.id)}
              onCheckboxChange={(checked) => onBatchVBillCheckboxChange(checked, vBill.id)}
              selectedVBill
              isDragDisabled={!billsAccordianExpanded.selectedBills || !billsAccordianExpanded.availableBills}
              isBatchClosed={isBatchClosed}
              selectedVBillsReactionsStats={batchSelectedVBillsReactionsStatsData}
              additionalMappingsSettings={vBillBatchAdditionalMappingsSettingsData}
              onReactionClick={() => vBillBatchDetailsStore.getBatchSelectedVBillsReactionsStats(true)}
              vBillEnabledCompanies={vBillEnabledCompanies}
            />
          ))}
          {droppableProvidedPlaceholder}
        </>
      )}
    </TableBody>
  );
});
