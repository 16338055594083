import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TreeItem, TreeView } from "@mui/lab";
import { Checkbox, FormControlLabel, Paper, Popover, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { ExtendedCheckFiltersType1, useCheckFilters } from "common/hooks/useCheckFilters";
import { LedgerFilterInputType, getFiltersConfig } from "components/Filter/types";
import { Spinner } from "components/common/Spinner/Spinner";
import { BankAccountBasic } from "generated/sdk";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useParams, useRouteMatch } from "react-router-dom";
import { useStore } from "storeContainer";

export const BankAccountsFilter = observer(function BankAccountsFilter() {
  const { filters, setFilters } = useCheckFilters<ExtendedCheckFiltersType1>();

  const [selected, setSelected] = useState<string[]>([]);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const AccountsStore = useStore("AccountsStore");
  const [bankAccounts, setBankAccounts] = useState<BankAccountBasic[]>([]);
  const { depositAccounts, isSyncing } = AccountsStore;

  const clearAll = () => {
    setSelected([]);
  };
  const { path } = useRouteMatch();
  const { selectedQuickFilter } = useParams<{ selectedQuickFilter: string }>();

  const formConfig = (getFiltersConfig(path, selectedQuickFilter) as any)?.[LedgerFilterInputType.BankAccountsFilter];

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    saveFilters();
    setAnchorEl(null);
  };

  const popoverOpen = Boolean(anchorEl);

  const saveFilters = () => {
    setFilters((prevFilters) => {
      const newFilters = {
        ...prevFilters,
        [formConfig?.keyName]: selected,
      };
      return newFilters;
    });
  };

  const handleCheckboxChange = (id: string, isChecked: boolean) => {
    if (isChecked) {
      setSelected((prevSelected) => [...prevSelected, id]);
    } else {
      setSelected((prevSelected) => prevSelected.filter((item) => item !== id));
    }
  };

  useEffect(() => {
    if (filters && filters[formConfig?.keyName]) {
      setSelected(filters[formConfig?.keyName]);
    } else {
      setSelected([]);
    }
  }, [filters, formConfig?.keyName]);

  useEffect(() => {
    if (depositAccounts.DepositBankAccounts) {
      depositAccounts.DepositBankAccounts?.forEach((org) => {
        if (!org?.id && org?.companies[0].bank_accounts) {
          const filteredBankAccounts = org.companies[0].bank_accounts.filter(
            (account): account is BankAccountBasic => account !== null,
          );
          setBankAccounts(filteredBankAccounts);
        }
      });
    }
  }, [depositAccounts.DepositBankAccounts]);

  return !formConfig ? null : (
    <Box alignSelf={"flex-end"}>
      <Button variant="outlined" onClick={handleClick} endIcon={<ExpandMoreIcon />} sx={{ width: "150px" }}>
        Bank Accounts
      </Button>
      <Popover
        sx={{ top: "5px" }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={handleClose}
        anchorEl={anchorEl}
        open={popoverOpen}
      >
        {
          <Paper sx={{ display: "flex", flexDirection: "column", padding: "10px" }}>
            <Typography>Bank Accounts</Typography>
            {!isSyncing ? (
              <Box>
                <TreeView>
                  {bankAccounts.map((item: any) => (
                    <TreeItem
                      key={item.id}
                      nodeId={item.id}
                      label={
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="secondary"
                              checked={selected.includes(item.id)}
                              onChange={(e) => handleCheckboxChange(item.id, e.target.checked)}
                            />
                          }
                          label={`${item.name}*${item.account_number}`}
                        />
                      }
                      sx={{ padding: 1 }}
                    ></TreeItem>
                  ))}
                </TreeView>
              </Box>
            ) : (
              <Spinner size={70} />
            )}

            <Box display="flex" justifyContent="space-between" marginTop="10px">
              <Button sx={{ marginTop: "10px" }} onClick={clearAll} variant="outlined">
                Clear
              </Button>
              <Button sx={{ marginTop: "10px" }} onClick={handleClose} variant="contained">
                Done
              </Button>
            </Box>
          </Paper>
        }
      </Popover>
    </Box>
  );
});
