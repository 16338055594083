import { Box, Tooltip } from "@mui/material";
import { formatCurrency } from "common/helpers/utils";
import { IVBillVBillGetBillsQuery } from "generated/sdk.vbill";
import { COLORS } from "themes/default";

interface IPayVBillAmountsProps {
  amounts?: IVBillVBillGetBillsQuery["getBills"]["items"][number]["amounts"];
}

export const PayVBillAmounts = ({ amounts }: IPayVBillAmountsProps) => (
  <>
    {amounts ? (
      <>
        {amounts.amountFreeToPay !== amounts.amount ? (
          <>
            <Tooltip title={`Amount to be paid: ${formatCurrency(Number(amounts.amountFreeToPay))}`}>
              <Box>{formatCurrency(Number(amounts.amountFreeToPay))}</Box>
            </Tooltip>
            <Tooltip title={`Original bill amount: ${formatCurrency(Number(amounts.amount))}`}>
              <Box sx={{ color: COLORS.textGray }}>{formatCurrency(Number(amounts.amount))}</Box>
            </Tooltip>
          </>
        ) : (
          <Tooltip title={`Bill Amount: ${formatCurrency(Number(amounts.amount))}`}>
            <Box>{formatCurrency(Number(amounts.amount))}</Box>
          </Tooltip>
        )}
      </>
    ) : (
      "-"
    )}
  </>
);
