import { IVBillMultiSelectAutocompleteSuggestion } from "components/pages/common/VBill/VBillSuggestionsAutocomplete/VBillSuggestionsMultiSelectAutocomplete/VBillSuggestionsMultiSelectAutocomplete";
import { VBillSuggestionsMultiSelectAutocompleteComp } from "components/pages/common/VBill/VBillSuggestionsAutocomplete/VBillSuggestionsMultiSelectAutocomplete/VBillSuggestionsMultiSelectAutocompleteComp";
import { sortBy } from "lodash";
import { observer } from "mobx-react";
import { useEffect, useMemo, useState } from "react";
import { useStore } from "storeContainer";

interface ISummariesCompaniesDialogProps {
  onCompaniesSave: (companyIds: string[]) => Promise<void>;
  selectedCompanies: string[];
  onDialogClose: () => void;
}

export const SummariesCompaniesDialog = observer(function SummariesCompaniesDialog({
  onCompaniesSave,
  selectedCompanies,
  onDialogClose,
}: ISummariesCompaniesDialogProps) {
  const organizationStore = useStore("OrganizationStore");
  const allCompanies = useMemo(
    () =>
      sortBy(organizationStore.organizationVBillEnabledCompanies.data?.items ?? [], (comp) => comp.name).map(
        ({ name, companyId }) => ({
          name,
          id: companyId,
        }),
      ),
    [organizationStore.organizationVBillEnabledCompanies.data?.items],
  );
  const [autocompleteSuggestions, setAutocompleteSuggestions] = useState<IVBillMultiSelectAutocompleteSuggestion[]>([]);

  useEffect(() => {
    const suggestionsSelectedFirst = sortBy(
      allCompanies.map((company) => ({ ...company, selected: selectedCompanies.includes(company.id) })),
      (comp) => !comp.selected,
    );

    setAutocompleteSuggestions(suggestionsSelectedFirst);
  }, [selectedCompanies, allCompanies]);

  const handleAutocompleteSuggestionChange = (option: IVBillMultiSelectAutocompleteSuggestion, value: boolean) => {
    setAutocompleteSuggestions((prev) =>
      prev.map((suggestion) => (suggestion.id === option.id ? { ...suggestion, selected: value } : suggestion)),
    );
  };

  const handleAutocompleteSave = async () => {
    await onCompaniesSave(autocompleteSuggestions.filter(({ selected }) => selected).map(({ id }) => id));
  };

  return (
    <VBillSuggestionsMultiSelectAutocompleteComp
      suggestionsComponent="dialog"
      suggestions={autocompleteSuggestions}
      title="Select companies"
      onSuggestionChange={handleAutocompleteSuggestionChange}
      onClose={onDialogClose}
      onSave={handleAutocompleteSave}
      searchPlaceholder="Search companies"
      actionBtnsSmallHeight
    />
  );
});
