import { IconButton, Tooltip } from "@mui/material";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary";
import { styled } from "@mui/material/styles";
import { ExpandLess, ExpandMoreIcon } from "components/common/icons";
import { omit } from "lodash";
import { COLORS } from "themes/default";

const expandIconStyle = { fontSize: "22px", color: COLORS.logoBlue };

export const BatchVBillsAccordian = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))({ background: "none", ":before": { display: "none" } });

export const BatchVBillsAccordianSummary = styled(
  (
    props: AccordionSummaryProps & {
      billsAccordianExpanded: boolean;
    },
  ) => (
    <MuiAccordionSummary {...omit(props, "billsAccordianExpanded")}>
      <Tooltip arrow title={`${props.billsAccordianExpanded ? "Hide" : "Show"} bills`}>
        <IconButton>
          {props.billsAccordianExpanded ? <ExpandLess sx={expandIconStyle} /> : <ExpandMoreIcon sx={expandIconStyle} />}
        </IconButton>
      </Tooltip>
    </MuiAccordionSummary>
  ),
)(() => ({
  padding: 0,
  minHeight: "auto",
  ".MuiAccordionSummary-content": {
    margin: 0,
  },
}));

export const BatchVBillsAccordianDetails = styled(MuiAccordionDetails)(() => ({
  padding: 0,
}));
