import styled from "@emotion/styled";
import { Tab, TabProps } from "@mui/material";

export const TabBtn = styled(Tab)<TabProps>({
  textTransform: "initial",
  fontSize: "15px",
  padding: "16.5px",
  minHeight: "auto",
});

export const TabBtnSmall = styled(Tab)<TabProps>({
  textTransform: "initial",
  fontSize: "12px",
  padding: "10px",
  minHeight: "auto",
});

export const a11yProps = (index: number) => ({
  id: `vbill-tab-${index}`,
  "aria-controls": `vbill-tabpanel-${index}`,
});
