import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import WarningIcon from "@mui/icons-material/Warning";
import { Box } from "@mui/material";
import { graphqlVBillClient } from "common/graphqlClient";
import {
  getSdk,
  IVBillReactionParentType,
  IVBillReactionType,
  IVBillVBillGetReactionsListQuery,
  IVBillVBillGetReactionsStatsQuery,
} from "generated/sdk.vbill";
import { useMemo, useState } from "react";
import { Reaction } from "./Reaction/Reaction";

const { VBillUpsertReaction, VBillGetReactionsList } = getSdk(graphqlVBillClient);

interface IReactionsProps {
  reactionsStats?: NonNullable<IVBillVBillGetReactionsStatsQuery["getReactionsStats"]["groups"]>[number]["stats"];
  parentType: IVBillReactionParentType;
  parentKey: string;
  onReactionClick: () => void;
}

export const Reactions = ({ reactionsStats, parentType, parentKey, onReactionClick }: IReactionsProps) => {
  const [reactionsList, setReactionsList] = useState<IVBillVBillGetReactionsListQuery["getReactionsList"]["items"]>();
  const [isReactionListFetching, setIsReactionListFetching] = useState(false);

  const handleReactionBtnClick = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    type: IVBillReactionType,
  ) => {
    event.stopPropagation();
    await VBillUpsertReaction({ type, parentKey, parentType });
    onReactionClick();
  };

  const handleReactionsMouseEnter = async () => {
    if (isReactionListFetching) {
      return;
    }

    setIsReactionListFetching(true);

    const resp = await VBillGetReactionsList({
      pagination: { page: 1 },
      filters: { parentKey, parentType },
    });

    if (resp.getReactionsList.items) {
      setReactionsList(resp.getReactionsList.items);
    }

    setIsReactionListFetching(false);
  };

  const likeReactionStats = useMemo(
    () => reactionsStats?.find(({ type }) => type === IVBillReactionType.Like),
    [reactionsStats],
  );
  const dislikeReactionStats = useMemo(
    () => reactionsStats?.find(({ type }) => type === IVBillReactionType.Dislike),
    [reactionsStats],
  );
  const nullReactionStats = useMemo(
    () => reactionsStats?.find(({ type }) => type === IVBillReactionType.Null),
    [reactionsStats],
  );

  const thumbUpReactionUsers = useMemo(
    () => getUsersReactionByType({ list: reactionsList, rType: IVBillReactionType.Like }),
    [reactionsList],
  );

  const thumbDownReactionUsers = useMemo(
    () => getUsersReactionByType({ list: reactionsList, rType: IVBillReactionType.Dislike }),
    [reactionsList],
  );
  const nullReactionUsers = useMemo(
    () => getUsersReactionByType({ list: reactionsList, rType: IVBillReactionType.Null }),
    [reactionsList],
  );

  return (
    <Box onMouseEnter={handleReactionsMouseEnter} sx={{ flexShrink: 0 }}>
      <Reaction
        onIconBtnClick={(e) => handleReactionBtnClick(e, IVBillReactionType.Like)}
        stats={likeReactionStats}
        userNames={thumbUpReactionUsers}
        ButtonIcon={ThumbUpIcon}
      />

      <Reaction
        onIconBtnClick={(e) => handleReactionBtnClick(e, IVBillReactionType.Dislike)}
        stats={dislikeReactionStats}
        userNames={thumbDownReactionUsers}
        ButtonIcon={ThumbDownIcon}
      />

      <Reaction
        onIconBtnClick={(e) => handleReactionBtnClick(e, IVBillReactionType.Null)}
        stats={nullReactionStats}
        userNames={nullReactionUsers}
        ButtonIcon={WarningIcon}
      />
    </Box>
  );
};

const getUsersReactionByType = ({
  list = [],
  rType,
}: {
  list?: IVBillVBillGetReactionsListQuery["getReactionsList"]["items"];
  rType: IVBillReactionType;
}) =>
  list.reduce<string[]>((result, { type, changedByName }) => {
    if (type === rType) {
      result = [...result, changedByName];
    }

    return result;
  }, []);
