import { useSelectedOrganizationCompanyId } from "components/pages/Settings/OrganizationCompanySelector/useSelectedOrganizationCompanyId";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useStore } from "storeContainer";

import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import { Permissions2UtilEntity, Permissions2UtilUser } from "./Permisssion2Utils";
import { AclEntityTypeEnum } from "generated/sdk";
import { Link, useParams } from "react-router-dom";
import UsersTabs from "./UsersTabs";

export const Permissions2Add: React.FunctionComponent = observer(function Permissions2Add() {
  const { organizationId } = useSelectedOrganizationCompanyId();
  const acl2 = useStore("AclStore2");
  const userGroupsStore = useStore("UserGroupsStore");
  const { user_type, user_id } = useParams<{
    user_type: string;
    user_id: string;
    entity_type: string;
    entity_id: string;
  }>();

  useEffect(() => {
    organizationId && acl2.reload();
  }, [acl2, organizationId]);
  const [saveEditLoading, setSaveEditLoading] = useState(false);
  const [saveFeedBack, setSaveFeedBack] = useState("");
  const [editGroupInfo, setEditGroupInfo] = useState<{
    name: string;
    description: string | undefined;
    user_group_id: string;
  }>({
    name: "",
    description: "",
    user_group_id: "",
  });
  const [selectedUser, setSelectedUser] = useState<{
    id: string;
    name: string;
    isUser?: boolean | undefined;
  } | null>(null);

  const [selectedAccount, setSelectedAccount] = useState<{
    id: string;
    name: string;
    type: AclEntityTypeEnum;
  } | null>(null);

  const organizationEntitiesList = acl2.organizationEntitiesList;
  const users = acl2.organizationUsersList;
  const groups = acl2.organizationGroupList;

  const userRoles: { id: string; name: string; isUser?: boolean }[] = [
    ...groups,
    ...users.filter((u) => u.state !== "UNVALIDATED").map((e) => ({ ...e, isUser: true })),
  ];

  useEffect(() => {
    if (user_type && user_id) {
      const foundUser =
        user_type === "user" ? users.find((u) => u.id === user_id) : groups.find((g) => g.id === user_id);
      const foundGroup =
        user_type === "group" ? groups.find((g) => g.id === user_id) : groups.find((g) => g.id === user_id);
      if (foundUser) {
        setSelectedUser({
          id: foundUser?.id,
          name: foundUser?.name,
          isUser: user_type === "user",
        });
      }
      if (foundGroup)
        setEditGroupInfo({
          user_group_id: foundGroup!.id,
          name: foundGroup?.name || "",
          description: foundGroup?.description || "",
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user_type, user_id, groups]);

  const handleSaveGroupEdit = async () => {
    setSaveEditLoading(true);
    await userGroupsStore.updateUserGroup(editGroupInfo.name, editGroupInfo.description!, editGroupInfo.user_group_id);
    setSaveFeedBack("Info Changed Successfully");
    setSaveEditLoading(false);
  };
  const handleChangeInfoChange = (e: any) => {
    setSaveFeedBack("");
    setEditGroupInfo({ ...editGroupInfo, [e.target.name]: e.target.value });
  };
  return (
    <Box sx={{ width: "100%" }} marginTop="40px">
      <UsersTabs selectedTab={1} />
      <Paper>
        <Box sx={{ p: 2 }}>
          <Typography variant="h3" sx={{ fontWeight: 500, color: "#707070", pb: 1 }}>
            Add Permission Rule
          </Typography>
        </Box>
        {/* {user_type === "group" && (
          <>
            <Box sx={{ p: 2 }}>
              <TextField
                name="name"
                onChange={handleChangeInfoChange}
                value={editGroupInfo?.name || ""}
                label="Rule Name"
              />
            </Box>
            <Box sx={{ paddingLeft: 2 }}>
              <TextField
                name="description"
                onChange={handleChangeInfoChange}
                value={editGroupInfo?.description || ""}
                label="Rule Description"
              />
            </Box>
            <Box display={"flex"} alignItems="center" sx={{ p: 2 }}>
              <Button onClick={!saveEditLoading ? handleSaveGroupEdit : () => {}} variant="contained">
                {saveEditLoading ? <Spinner /> : "save"}
              </Button>
              <Typography sx={{ marginLeft: "5px" }} color="green">
                {saveFeedBack}
              </Typography>
            </Box>
          </>
        )} */}
        <Box sx={{ p: 2 }}>
          <Autocomplete
            loading={acl2.isFetching}
            id="user-select"
            sx={{ width: 300 }}
            options={userRoles}
            autoHighlight
            getOptionLabel={(option) => option.name}
            value={selectedUser}
            renderOption={(props, option) => (
              <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props} key={option.id}>
                <Permissions2UtilUser user_name={option.name} isUser={!!option.isUser} />
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Choose a User / User Group"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password", // disable autocomplete and autofill
                }}
              />
            )}
            onChange={(_e, value) => setSelectedUser(value)}
          />
        </Box>
        <Box sx={{ p: 2 }}>
          <Autocomplete
            loading={acl2.isFetching}
            id="account-select"
            sx={{ width: 300 }}
            options={organizationEntitiesList}
            autoHighlight
            getOptionLabel={(option) => option.name}
            renderOption={(props, option) => (
              <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props} key={option.id}>
                <Permissions2UtilEntity entity_type={option.type} entity_name={option.name} />
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Choose an Account"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password", // disable autocomplete and autofill
                }}
              />
            )}
            value={selectedAccount}
            onChange={(_e, value) => setSelectedAccount(value)}
          />
        </Box>
        <Box sx={{ p: 2 }}>
          <Link
            to={`/org/${organizationId}/users2/permissions`}
            style={{ textDecoration: "none", paddingRight: "10px" }}
          >
            <Button variant="outlined">Cancel</Button>
          </Link>
          <Link
            style={{ textDecoration: "none" }}
            to={
              selectedAccount && selectedUser
                ? `/org/${organizationId}/users2/permissions/` +
                  `${selectedUser?.isUser ? "user" : "group"}/${selectedUser?.id}/` +
                  `${selectedAccount?.type.toLocaleLowerCase()}/${selectedAccount?.id}`
                : "#"
            }
          >
            <Button variant="contained" disabled={!selectedUser || !selectedAccount}>
              Next
            </Button>
          </Link>
        </Box>
        {/* <pre>organizationEntitiesList={JSON.stringify(acl2.organizationEntitiesList, null, 4)}</pre> */}
        {/* <pre>userRoles={JSON.stringify(userRoles, null, 4)}</pre> */}
        {/* <pre>groups={JSON.stringify(groups, null, 4)}</pre> */}
      </Paper>
    </Box>
  );
});
