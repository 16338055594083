import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs, { TabsActions } from "@mui/material/Tabs";
import { CheckFiltersProvider } from "common/hooks/useCheckFilters";
import { CheckBatchStateEnum } from "generated/sdk";
import { debounce } from "lodash";
import * as React from "react";
import { BudgetManagementDetailsChecksTab } from "./BudgetManagementDetailsChecksTab";
import { CheckBatchMembersTable } from "./CheckBatchMembersTable";
import { useBatchDetailsContext } from "./useBatchDetailsContext";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const CustomTabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `check-batch-tab-${index}`,
    "aria-controls": `check-batch-tabpanel-${index}`,
  };
};

export const CheckBatchDetailsTabs: React.FC<{ checkBatchState?: CheckBatchStateEnum | null }> = () => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const tabsDomRef = React.useRef<HTMLDivElement>();
  const tabsDomAction = React.useRef<TabsActions>(null);

  const {
    props: { isLoading },
  } = useBatchDetailsContext();

  const debouncedTabsUpdateIndicator = React.useMemo(
    () =>
      debounce(() => {
        tabsDomAction.current?.updateIndicator();
      }, 500),
    [],
  );

  React.useEffect(() => {
    if (!tabsDomRef.current) {
      return;
    }
    const sidebarTabsObserver = new ResizeObserver(() => {
      debouncedTabsUpdateIndicator();
    });
    sidebarTabsObserver.observe(tabsDomRef.current);

    return () => sidebarTabsObserver.disconnect();
  }, [debouncedTabsUpdateIndicator]);

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }} ref={tabsDomRef}>
        <Tabs value={value} onChange={handleChange} aria-label="check details tabs" centered action={tabsDomAction}>
          <Tab label="Checks" {...a11yProps(0)} disabled={isLoading} />
          <Tab label="Members" {...a11yProps(1)} disabled={isLoading} />
        </Tabs>
      </Box>
      <CheckFiltersProvider prefix="batchCheck">
        <CustomTabPanel value={value} index={0}>
          <BudgetManagementDetailsChecksTab />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <CheckBatchMembersTable />
        </CustomTabPanel>
      </CheckFiltersProvider>
    </Box>
  );
};
