export const highlightMatchingText = (text: string, highlightTerm: string) => {
  if (!highlightTerm?.length) {
    return text;
  }
  // Escape special characters in highlightTerm
  const escapedTerm = highlightTerm.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  const regex = new RegExp(`(${escapedTerm})`, "gi");
  const parts = text.split(regex);

  return parts.map((part, i) => (regex.test(part) ? <strong key={i}>{part}</strong> : part));
};
