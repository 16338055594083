import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { IntegrationBankAccountMappingModal } from "./ModalComponents/IntegrationBankAccountMappingModal";

export const MapAccountModal: React.FunctionComponent<{
  companyId: string;
  currentGlAccount: any;
  integrationId: string;
  closeModal: Function;
  organizationId?: string;
  organizationUserId?: string /* //TODO this thing to be deleted as this modal does not need it */;
}> = ({ companyId, currentGlAccount, integrationId, closeModal }) => {
  return (
    <Grid container justifyContent={"center"} alignItems={"center"} sx={{ padding: 4 }}>
      <Paper sx={{ padding: 2 }}>
        <IntegrationBankAccountMappingModal
          closeModal={closeModal}
          companyId={companyId}
          integrationId={integrationId}
          currentGlAccount={currentGlAccount}
        />
      </Paper>
    </Grid>
  );
};
