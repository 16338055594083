import { styled, tooltipClasses, TooltipProps } from "@mui/material";

import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

import { definitelyFilter } from "../../../../../../generated/utils";
import { AllBasePermissionType, IAclPermissionRow } from "../../../../../../storesMobx/AclStore";
import { AclPermLimitView } from "./AclPermLimitView";
import { AclPermissionGroupName, extractLimit } from "./Permissions";

export const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#FFFFFF",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 500,
    border: "1px solid #dadde9",
  },
}));

export const AclInheritedPermissions = ({
  PGNAME,
  row,
}: {
  PGNAME: AclPermissionGroupName;
  row: IAclPermissionRow;
}) => {
  const rowPermissions = definitelyFilter(row.perm[PGNAME] as AllBasePermissionType[]);

  const rowPermissionsUsed = rowPermissions
    .filter((p) => p.usedForAgg)
    .filter((rowPermission) => !rowPermission.direct);
  const rowPermissionsIgnored = rowPermissions.filter((p) => !p.usedForAgg);

  console.info("ZZZ Permissions", rowPermissions);

  if (!rowPermissionsUsed.length) return null;
  return (
    <>
      <Divider sx={{ my: 2 }} />

      {/* Details & Inherited */}
      <Typography variant="body2" sx={{ fontWeight: 500, color: "#707070" }}>
        Inherited
      </Typography>

      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        {rowPermissionsUsed.map((rowPermission, rowPermissionIdx) => (
          <AclPermLimitView
            key={rowPermissionIdx}
            rowPermission={rowPermission}
            PGNAME={PGNAME}
            outlined={true}
            value={extractLimit(PGNAME, rowPermission)}
          />
        ))}
      </Box>

      {!!rowPermissionsIgnored.length && (
        <>
          <Typography variant="body2" sx={{ fontWeight: 500, color: "#707070" }}>
            Ignored
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            {rowPermissionsIgnored.map((rowPermission, rowPermissionIdx) => (
              <AclPermLimitView
                key={rowPermissionIdx}
                rowPermission={rowPermission}
                PGNAME={PGNAME}
                outlined={true}
                value={extractLimit(PGNAME, rowPermission)}
              />
            ))}
          </Box>
        </>
      )}
    </>
  );
};
