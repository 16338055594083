import { CheckFiltersProvider } from "common/hooks/useCheckFilters";
import { observer } from "mobx-react";
import VChecks from "./VChecks";

export const VChecksRoute = observer(function VChecksRoute() {
  return (
    <CheckFiltersProvider prefix="check">
      <VChecks />
    </CheckFiltersProvider>
  );
});
