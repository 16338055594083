import { Link, LinkProps } from "react-router-dom";

export const SHLink: React.FunctionComponent<LinkProps> = ({
  component,
  to,
  replace,
  innerRef,
  children,
  ...htmlProps
}) => {
  if ((window as any).isLegacyPage) {
    const url = typeof to === "string" ? to : typeof to === "object" ? `${to.pathname}${to.search}` : "";
    return (
      <a href={url} {...htmlProps}>
        {children}
      </a>
    );
  } else {
    return (
      <Link
        to={to}
        replace={replace}
        component={component}
        innerRef={innerRef}
        style={htmlProps.style}
        className={htmlProps.className}
      >
        {children}
      </Link>
    );
  }
};
