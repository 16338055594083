import { Box, Checkbox, FormControlLabel, Tooltip, Typography } from "@mui/material";
import { CSSProperties, useMemo } from "react";
import { highlightMatchingText } from "../../common/SuggestionsSearchField/utils";
import { IVBillMultiSelectAutocompleteSuggestion } from "../VBillSuggestionsMultiSelectAutocomplete";

interface ISuggestionCheckboxProps {
  suggestion: IVBillMultiSelectAutocompleteSuggestion;
  searchTerm: string;
  style?: CSSProperties;
  onSuggestionChange: (suggestion: IVBillMultiSelectAutocompleteSuggestion, value: boolean) => void;
}

export const SuggestionCheckbox = ({
  suggestion: { name, label, selected },
  suggestion,
  searchTerm,
  style,
  onSuggestionChange,
}: ISuggestionCheckboxProps) => {
  const formattedLabel = useMemo(
    () => highlightMatchingText(`${name} ${label ? `- ${label}` : ""}`, searchTerm),
    [label, name, searchTerm],
  );

  return (
    <Box style={style} sx={{ padding: "10px 0px" }} title={name}>
      <FormControlLabel
        sx={{ margin: 0, "&:hover": { backgroundColor: "#f5f5f5" } }}
        control={
          <Tooltip arrow title={`${selected ? "Unselect" : "Select"}`}>
            <Checkbox
              onChange={(e) => onSuggestionChange(suggestion, e.target.checked)}
              checked={selected ?? false}
              sx={{ marginRight: "10px" }}
            />
          </Tooltip>
        }
        label={
          <Typography sx={{ width: "262px" }} noWrap title={`${name} ${label ? `- ${label}` : ""}`}>
            {formattedLabel}
          </Typography>
        }
      />
    </Box>
  );
};
