import CloseIcon from "@mui/icons-material/Close";
import RestoreIcon from "@mui/icons-material/Restore";
import { Box, Tooltip, Typography } from "@mui/material";
import Chip from "@mui/material/Chip";
import { formatDateAsUTC } from "common/helpers/utils";
import {
  ExtendedCheckFiltersType1,
  useCheckFilters,
  VCheckChecksAllQuickFiltersEnum,
} from "common/hooks/useCheckFilters";
import { useSharedCompanyBankAccountFilters } from "common/hooks/useSharedCompanyBankAccountsFilters";
import { findLabelByValue, getFiltersConfig, LedgerFilterInputType } from "components/Filter/types";
import { CheckFiltersDateType, CheckFiltersType, CheckSearchInputType } from "generated/sdk";
import _, { isEqual } from "lodash";
import { useMemo } from "react";
import { useHistory } from "react-router";
import { useParams, useRouteMatch } from "react-router-dom";
import { typesList } from "../utils";
import { IActivityDropdownSelectFilterMenuOption } from "./ActivityDropdownSelectFilter";
import {
  IPaymentsActionRequiredFilterMenuOption,
  paymentsActionRequiredOptionColor,
} from "./PaymentsActionRequiredFilter";
import { formatString, mapName } from "./StateOrActionOrStatusFilter";
import { presetArray } from "./types";

export const clearFiltersBtnStyle = {
  fontFamily: "'Inter'",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "17px",
  textDecorationLine: "underline",
  color: "#CD4425",
  cursor: "pointer",
};

export const areFiltersEmpty = (filters: CheckFiltersType): boolean => {
  return _.every(filters, (value, key) => {
    if (
      key === "companies" ||
      key === "quick_filter" ||
      (key === "date" &&
        !(value as CheckFiltersDateType)?.preset &&
        !(value as CheckFiltersDateType)?.to &&
        !(value as CheckFiltersDateType)?.from) ||
      (key === "search" && !(value as CheckSearchInputType).search_input) ||
      key === "number_sort" ||
      key === "date_sort" ||
      key === "amount_sort"
    ) {
      return true;
    }

    if (_.isArray(value)) {
      return _.isEmpty(value);
    } else if (_.isObject(value)) {
      return _.isEmpty(value);
    } else if (_.isNumber(value)) {
      return false;
    } else if (_.isString(value)) {
      return _.isEmpty(value);
    } else {
      return _.isNil(value);
    }
  });
};

export const ActiveFilters = () => {
  const { filters, setFilters, resetFilters } = useCheckFilters<ExtendedCheckFiltersType1>();
  const history = useHistory();
  const { path } = useRouteMatch();
  const { selectedQuickFilter: quickFilterParam, sharedToken } = useParams<{
    selectedQuickFilter: VCheckChecksAllQuickFiltersEnum;
    sharedToken: string;
  }>();

  const formConfig = getFiltersConfig(path, quickFilterParam as string);
  const dateFormConfig = formConfig?.[LedgerFilterInputType.AdvancedDateFilter];
  const amountFormConfig = formConfig?.[LedgerFilterInputType.InputAmount];
  const processingTypeFormConfig = (formConfig as any)?.[LedgerFilterInputType.ProcessingTypeFilter];
  const paymentsActionRequiredFormConfig:
    | {
        keyName: string;
        options: IPaymentsActionRequiredFilterMenuOption[];
      }
    | undefined = (formConfig as any)?.[LedgerFilterInputType.CheckActionRequiredDropdownSelectFilter];
  const paymentsActionRequired = useMemo(() => {
    const selectedFilter: string[] | undefined = filters[paymentsActionRequiredFormConfig?.keyName ?? ""];
    const formConfigOption = paymentsActionRequiredFormConfig?.options.find(({ value }) =>
      isEqual(value, selectedFilter),
    );

    return formConfigOption;
  }, [filters, paymentsActionRequiredFormConfig]);
  const paymentsActionRequiredChipText = useMemo(() => {
    if (!paymentsActionRequired) {
      return;
    }

    return (
      <>
        Required Action:{" "}
        <Box component="span" sx={{ color: paymentsActionRequiredOptionColor(paymentsActionRequired.type) }}>
          {paymentsActionRequired.label}
        </Box>
      </>
    );
  }, [paymentsActionRequired]);
  const paymentsActionRequiredChipTitle = useMemo(() => {
    if (!paymentsActionRequired) {
      return;
    }

    return `Required Action: ${paymentsActionRequired.label}`;
  }, [paymentsActionRequired]);
  const activityFormConfig:
    | {
        keyName: string;
        options: IActivityDropdownSelectFilterMenuOption[];
      }
    | undefined = (formConfig as any)?.[LedgerFilterInputType.ActivityDropdownSelectFilter];
  const activityChipTitle = useMemo(() => {
    const selectedFilter: string | undefined = filters[activityFormConfig?.keyName ?? ""];
    const formConfigOption = activityFormConfig?.options?.find(({ value }) => value === selectedFilter);

    if (formConfigOption) {
      return `Activity: ${formConfigOption?.label}`;
    }
  }, [activityFormConfig?.keyName, activityFormConfig?.options, filters]);

  const handleDateChipText = () => {
    const dateFromValue =
      filters?.[dateFormConfig?.keyNameInput as string]?.[dateFormConfig?.keyNameCustomRange?.[0] as string] || null;
    const dateToValue =
      filters?.[dateFormConfig?.keyNameInput as string]?.[dateFormConfig?.keyNameCustomRange?.[1] as string] || null;

    const isValidDate = (date: string) => !isNaN(Date.parse(date));
    // Check if both date and date_to are present
    if (dateFromValue && dateToValue && isValidDate(dateFromValue) && isValidDate(dateToValue)) {
      const startDate = formatDateAsUTC(new Date(dateFromValue), "MM/dd/yyyy");
      const endDate = formatDateAsUTC(new Date(dateToValue), "MM/dd/yyyy");
      if (startDate === endDate) {
        return `${startDate}`;
      }
      return `${startDate} - ${endDate}`;
    }
    // Check if only date is present
    if (dateFromValue && isValidDate(dateFromValue)) {
      return `From ${formatDateAsUTC(new Date(dateFromValue), "MM/dd/yyyy")}`;
    }
    // Check if only date_to is present
    if (dateToValue && isValidDate(dateToValue)) {
      return `Until ${formatDateAsUTC(new Date(dateToValue), "MM/dd/yyyy")}`;
    }

    if (filters?.date?.preset) {
      return presetArray.find((p) => p.key === filters?.date?.preset)?.value;
    }

    // If neither date nor date_to nor preset is present, return an empty string

    return "";
  };

  const handleAmountChipText = () => {
    const amountFromValue = filters?.[amountFormConfig?.keyNameMinValue as string] || null;
    const amountToValue = filters?.[amountFormConfig?.keyNameMaxValue as string] || null;

    // const { amount_from, amount_to } = filters || {};
    // Both amount_from and amount_to are present
    if (amountFromValue && amountToValue) {
      if (amountFromValue === amountToValue) {
        return `$ ${amountFromValue}`;
      }
      const formattedAmountFrom = `$ ${amountFromValue}`;
      const formattedAmountTo = `$ ${amountToValue}`;
      return `${formattedAmountFrom} - ${formattedAmountTo}`;
    }
    // Only amount_from is present
    if (amountFromValue) {
      return `From $ ${amountFromValue}`;
    }
    // Only amount_to is present
    if (amountToValue) {
      return `To $ ${amountToValue}`;
    }
    // Neither amount_from nor amount_to is present
    return "";
  };
  const generateFilterChipText = (label: "state" | "status" | "required_action") => {
    const selectedOptions = filters?.[label];
    if (selectedOptions && selectedOptions.length > 1) {
      return `${label.split("_").length < 2 ? mapName(label) : mapName(label)?.split(" ")[1]}: ${selectedOptions
        .map((item) => formatString(item as string))
        .join(", ")}`;
    } else {
      return `${mapName(label)}: ${formatString(filters?.[label]?.[0] as string)}`;
    }
  };

  const handleProcessingTypeChipText = () => {
    const keyName = processingTypeFormConfig?.keyName;
    const keyNameInput = processingTypeFormConfig?.keyNameInput;
    const keyNameOptions = processingTypeFormConfig?.keyNameOptions;

    const processingTypeArray = filters[keyName] ?? [];

    const textArray = processingTypeArray.map((item: any) => {
      const processingValue = findLabelByValue(processingTypeFormConfig, item[keyNameInput]);
      const substatusValues = item[keyNameOptions].map((value: any) =>
        findLabelByValue(processingTypeFormConfig, value),
      );

      return `${processingValue}${substatusValues.length > 0 ? `: ${substatusValues.join(", ")}` : ""}`;
    });

    return `Type: ${textArray.join(", ")}`;
  };

  const { label, hasCompaniesSelected, allCompaniesBankAccountsAreSelected, currentBankAccounts } =
    useSharedCompanyBankAccountFilters();

  const typeFilterLabel = `${typesList.find((type) => type.value === filters?.type)?.label ?? "All"}`;

  const closeIconStyle = {
    fontSize: "12px !important",
    color: "#052048 !important",
  };
  const handleClearFiltersButtonClick = () => {
    if (sharedToken) {
      const currentPath = history.location.pathname;

      const pathParts = currentPath.split("/");

      if (pathParts.length > 1) {
        pathParts.pop();
      }

      const newPath = pathParts.join("/");

      history.push(newPath);
    }
    resetFilters();
  };

  return filters ? (
    <Box marginTop="10px" display="flex" gap="15px" alignItems="center" flexWrap="wrap">
      {filters?.search?.additional_search_input && (
        <Chip
          variant="outlined"
          label={`${filters?.search?.additional_search_input} (additional search term)`}
          title="Additional Search"
          deleteIcon={<CloseIcon sx={closeIconStyle} />}
          onDelete={() => {
            setFilters((prevFilters) => ({
              ...prevFilters,
              search: { ...filters?.search, additional_search_input: undefined },
            }));
          }}
        />
      )}
      {filters?.search?.search_input && (
        <Chip
          variant="outlined"
          label={`${filters?.search?.search_input} (search term)`}
          title="Search"
          deleteIcon={<CloseIcon sx={closeIconStyle} />}
          onDelete={() => {
            setFilters({ ...filters, search: { ...filters?.search, search_input: undefined } });
          }}
        />
      )}

      {filters?.type && (
        <Chip
          variant="outlined"
          label={typeFilterLabel}
          title="Type"
          deleteIcon={<CloseIcon sx={closeIconStyle} />}
          onDelete={() => {
            setFilters({ ...filters, type: undefined });
          }}
        />
      )}
      {filters?.status && (
        <Chip
          variant="outlined"
          label={generateFilterChipText("status")}
          title={generateFilterChipText("status")}
          deleteIcon={<CloseIcon sx={closeIconStyle} />}
          onDelete={() => {
            setFilters({ ...filters, status: undefined });
          }}
        />
      )}
      {(filters?.bank_accounts?.length as number) > 0 && !allCompaniesBankAccountsAreSelected && (
        <Chip
          variant="outlined"
          label={label}
          title={label}
          deleteIcon={
            hasCompaniesSelected ? (
              <Tooltip
                placement="top"
                title={"This will restore all the bank accounts filters associated with the selected companies"}
              >
                <RestoreIcon sx={closeIconStyle} />
              </Tooltip>
            ) : (
              <CloseIcon sx={closeIconStyle} />
            )
          }
          onDelete={
            allCompaniesBankAccountsAreSelected
              ? undefined
              : () => {
                  setFilters({
                    ...filters,
                    bank_accounts: hasCompaniesSelected ? currentBankAccounts : undefined,
                  });
                }
          }
        />
      )}
      {(filters?.date?.from?.length || filters?.date?.to?.length || filters?.date?.preset) && (
        <Chip
          variant="outlined"
          label={handleDateChipText()}
          title="Date"
          deleteIcon={<CloseIcon sx={closeIconStyle} />}
          onDelete={() => {
            setFilters({ ...filters, date: { ...filters.date, from: undefined, to: undefined, preset: undefined } });
          }}
        />
      )}
      {(filters?.amount_to || filters?.amount_from) && (
        <Chip
          variant="outlined"
          label={handleAmountChipText()}
          title={"Amount"}
          deleteIcon={<CloseIcon sx={closeIconStyle} />}
          onDelete={() => {
            setFilters({ ...filters, amount_to: undefined, amount_from: undefined });
          }}
        />
      )}
      {filters?.state && (
        <Chip
          variant="outlined"
          label={generateFilterChipText("state")}
          title={generateFilterChipText("state")}
          deleteIcon={<CloseIcon sx={closeIconStyle} />}
          onDelete={() => {
            setFilters({ ...filters, state: undefined });
          }}
        />
      )}
      {filters?.required_action && !paymentsActionRequired && (
        <Chip
          variant="outlined"
          label={generateFilterChipText("required_action")}
          title={generateFilterChipText("required_action")}
          deleteIcon={<CloseIcon sx={closeIconStyle} />}
          onDelete={() => {
            setFilters({ ...filters, required_action: undefined });
          }}
        />
      )}
      {filters?.required_action && paymentsActionRequired && (
        <Chip
          variant="outlined"
          label={paymentsActionRequiredChipText}
          title={paymentsActionRequiredChipTitle}
          deleteIcon={<CloseIcon sx={closeIconStyle} />}
          onDelete={() => {
            setFilters({ ...filters, required_action: undefined });
          }}
        />
      )}
      {filters?.[processingTypeFormConfig?.keyName] && (
        <Chip
          variant="outlined"
          label={handleProcessingTypeChipText()}
          title={handleProcessingTypeChipText()}
          deleteIcon={<CloseIcon sx={closeIconStyle} />}
          onDelete={() => {
            setFilters({ ...filters, [processingTypeFormConfig?.keyName]: undefined });
          }}
        />
      )}

      {filters?.chat_activity && activityChipTitle && (
        <Chip
          variant="outlined"
          label={activityChipTitle}
          title={activityChipTitle}
          deleteIcon={<CloseIcon sx={closeIconStyle} />}
          onDelete={() => {
            setFilters({ ...filters, chat_activity: undefined });
          }}
        />
      )}

      {/* {((!isFiltersEmpty && !!selectedOrganizationId) ||
        (isFiltersEmpty &&
          (!!filters.date?.preset || filters?.date?.to || filters?.date?.from || filters?.search?.search_input)) ||
        (!isFiltersEmpty && isUnvalidated) ||
        (filters.bank_accounts && isUnvalidated)) && (
        <Typography sx={clearFiltersBtnStyle} onClick={handleClearFiltersButtonClick}>
          Clear Filters
        </Typography>
      )} */}
      {!areFiltersEmpty(filters) && !allCompaniesBankAccountsAreSelected && (
        <Typography sx={clearFiltersBtnStyle} onClick={handleClearFiltersButtonClick}>
          Clear Filters
        </Typography>
      )}
    </Box>
  ) : null;
};
