import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "components/common/Button";
import { Spinner } from "components/common/Spinner/Spinner";
import { Invite } from "generated/sdk";
import { useState } from "react";
import { useStore } from "storeContainer";
export const InvitationPendingWidget: React.FunctionComponent<{
  invite: Invite;
  setInvitesDisplayed: Function;
  invites: Invite[];
  setAcceptedInvitesIds: Function;
  acceptedInvitesIds: string[];
}> = ({ invite, setInvitesDisplayed, invites, setAcceptedInvitesIds, acceptedInvitesIds }) => {
  const SessionStore = useStore("SessionStore");
  const [acceptLoading, setAcceptLoading] = useState(false);
  const [declineLoading, setDeclineLoading] = useState(false);
  const declineInvite = async () => {
    setDeclineLoading(true);
    await SessionStore.declineInvite(invite.id!);
    setInvitesDisplayed(invites.filter((value) => value.id !== invite.id));
    await SessionStore.getInvites({ forceUpdate: true });
    setDeclineLoading(false);
  };
  const acceptInvite = async () => {
    setAcceptLoading(true);
    setAcceptedInvitesIds([...acceptedInvitesIds, invite.id]);
    await SessionStore.acceptInvite(invite.id!);
    await SessionStore.getInvites({ forceUpdate: true });
    setInvitesDisplayed((invites: Invite[]) => invites.filter((value) => value.id !== invite.id));
    setAcceptLoading(false);
  };
  return (
    <Card sx={{ mt: 2, maxWidth: "380px" }}>
      <Grid container>
        <Grid alignItems={"center"} justifyContent={"center"} container item xs={2}>
          <AccountBalanceOutlinedIcon sx={{ color: "#C0CBCE" }} fontSize="large" />
        </Grid>
        <Grid item xs={10}>
          <CardContent sx={{ paddingBottom: "0" }}>
            <Typography sx={{ display: "inline" }} color={"primary"} variant="body1">
              You’ve been invited to join <label style={{ fontWeight: "bold" }}>{invite.inviter?.name} </label>
              <Typography> {invite.organization_name}</Typography>
            </Typography>
          </CardContent>

          <CardActions>
            <Button
              onClick={acceptInvite}
              disableElevation
              variant="outlined"
              sx={{ textTransform: "capitalize", padding: "4px 12px" }}
              color="secondary"
            >
              {!acceptLoading ? "Accept & Join" : <Spinner />}
            </Button>

            <Button
              onClick={declineInvite}
              disableElevation
              sx={{ borderColor: "#C0CBCE", color: "#C0CBCE", textTransform: "capitalize", padding: "2px 12px" }}
              variant="outlined"
            >
              {!declineLoading ? "Decline" : <Spinner />}
            </Button>
          </CardActions>
        </Grid>
      </Grid>
    </Card>
  );
};
