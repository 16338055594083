// MUI Icons
import MailIcon from "@mui/icons-material/Mail";
import { LoadingButton } from "@mui/lab";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import InputAdornment from "@mui/material/InputAdornment";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
// 3rd Party Libraries
import { useStore } from "../../../../storeContainer";
import Button from "../../../common/Button";
import TextField from "../../../common/TextField";

export const InviteUser: React.FunctionComponent<{ goBack: boolean }> = observer(function InviteUser({ goBack }) {
  const organizationUsersStore = useStore("OrganizationUsersStore");
  const contextStore = useStore("ContextStore");
  const uiStore = useStore("UiStore");

  const { inviteUserResponse } = organizationUsersStore;

  const history = useHistory();

  if (inviteUserResponse.data) {
    uiStore.showInviteUserModal = undefined;
    history.replace(
      // `/org/${contextStore.selectedOrganizationId}/users/${inviteUserResponse.data?.organization_user_id}`
      `/org/${contextStore.selectedOrganizationId}/users2`,
    );
    inviteUserResponse.flush();
  }

  const [email, setEmail] = useState({
    value: "",
    error: "",
  });

  const handleChange = (e: any) => {
    setEmail({
      ...email,
      value: e.target.value,
      error: "",
    });
  };

  const onSubmit = async () => {
    if (!/^\S+@\S+\.\S+$/.test(email.value)) {
      setEmail({
        ...email,
        error: "Please enter a valid email address",
      });
      return;
    }

    await organizationUsersStore.createInvite(email.value);
    // alert("Should open user modal")
  };

  const close = () => {
    if (goBack === true) {
      uiStore.showInviteUserModal = undefined;
      history.goBack();
    } else {
      uiStore.showInviteUserModal = undefined;
    }
  };

  useEffect(() => {
    console.info("Refresh the modal");
    organizationUsersStore.flushInviteResponse();
    setEmail({ ...email, error: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog open={true} onClose={close}>
      <DialogTitle>Invite user</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Please enter the email address of the person you want to invite.
        </DialogContentText>
        <TextField
          sx={{ width: 1, mt: 2 }}
          fullWidth={true}
          value={email.value || ""}
          disabled={inviteUserResponse.isFetching}
          onChange={handleChange}
          label="Email address"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <MailIcon />
              </InputAdornment>
            ),
          }}
          error={email.error !== "" || inviteUserResponse.error?.response?.errors?.message !== undefined}
          type={"email"}
          autoFocus={true}
          helperText={email.error || inviteUserResponse.error?.response?.errors?.message}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Cancel</Button>
        <LoadingButton onClick={onSubmit} loading={inviteUserResponse.isFetching} variant="contained">
          Send invite
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
});
