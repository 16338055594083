import { forwardRef, memo, useCallback, useMemo, useState } from "react";
import type { ComponentProps, ForwardedRef, ReactNode } from "react";
import CardContent from "@mui/material/CardContent";
import Collapse from "@mui/material/Collapse";
import MuiCard from "@mui/material/Card";
import Header from "./Header";

type Props = ComponentProps<any> & {
  children?: ReactNode;
  className?: string;
  contentProps?: ComponentProps<typeof CardContent>;
  defaultExpanded?: boolean;
  headerProps?: ComponentProps<typeof Header>;
  title?: ReactNode;
};

const Card = (
  { children, className, contentProps = {}, defaultExpanded, headerProps = {}, title, ...rest }: Props,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const isExpandable = useMemo(() => defaultExpanded !== undefined, [defaultExpanded]);
  const [expanded, setExpanded] = useState(isExpandable ? defaultExpanded : true);

  const handleExpandToggle = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded, setExpanded]);

  return (
    <MuiCard {...rest} className={className} ref={ref}>
      <Header
        title={title}
        sx={{ paddingX: "25px" }}
        {...(isExpandable ? { expanded: expanded, onExpandToggle: handleExpandToggle } : {})}
        {...headerProps}
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {children && <CardContent {...contentProps}>{children}</CardContent>}
      </Collapse>
    </MuiCard>
  );
};

export default memo(forwardRef(Card));
