import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import SettingsIcon from "@mui/icons-material/Settings";
import { ButtonProps, InputAdornment, InputLabel, TextField, styled } from "@mui/material";
import Link from "@mui/material/Link";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import Box from "components/common/Box";
import Button from "components/common/Button";
import Table from "components/common/Table";
import { observer } from "mobx-react-lite";
import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { useStore } from "storeContainer";
import { StringParam, useQueryParam } from "use-query-params";
import { AddBankAccountSource } from "../../../../../storesMobx/UiStore";
import { COLORS } from "../../../../../themes/default";
import { BankAccountName } from "../../Accounts/components/BankAccountName";
import styles from "../../Users/Users.module.scss";
import { SearchIcon } from "icons/svg/SearchIcon";
import { CompanyMenu } from "components/layout/CompanyMenu";

export const Accounts: React.FunctionComponent = observer(function Accounts() {
  const contextStore = useStore("ContextStore");
  const sessionStore = useStore("SessionStore");
  const companyStore = useStore("CompanyStore");
  const organizationStore = useStore("OrganizationStore");
  const uiStore = useStore("UiStore");
  useEffect(() => {
    organizationStore.loadOrganizationStructure(contextStore.selectedOrganizationId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const history = useHistory();

  const { acl } = sessionStore;

  const bankAccounts = contextStore.selectedCompanyBanksAccounts;

  const { selectedOrganizationId, selectedCompanyId } = contextStore;

  const canValidateBA = acl.orgsUserMayAddBankAccountTo.some((org) => org.id === selectedOrganizationId);

  const { selectedCompany } = contextStore;

  const openLinkEmailAddress = (id: any) => {
    history.push(`bas/${id}/emails`);
  };

  const openAutoDeposit = (id: any) => {
    history.push(`bas/${id}/deposits`);
  };

  const UpgradeButton = styled(Button)<ButtonProps>(() => ({
    color: COLORS.deepCerulean,
    backgroundColor: COLORS.foam25Opacity,
    borderColor: COLORS.foam,
    fontSize: "14px",
    fontWeight: 400,
    "&:hover": {
      backgroundColor: "#e1f5fe",
    },
  }));

  const upgradeBankAccount = () => {
    (window as any).location = `/bas/create/regular/companies/${selectedCompany?.id}?upgrade=1`;
  };

  const handleBankAccountNameChanged = async (id: string, name: string) => {
    await companyStore.changeBankAccountName(id, name);
  };

  const bankAccountsColumns = useMemo(
    () => [
      {
        Header: "Account name",
        accessor: "name",
        Cell: ({ value, row }: any) => (
          <div className={styles.tableRow}>
            <BankAccountName
              canEdit={true}
              bankAccountName={value}
              onNameChanged={(name) => {
                handleBankAccountNameChanged(row.original.id, name);
              }}
            />
          </div>
        ),
      },
      {
        Header: "Account number",
        accessor: "account_number",
        Cell: ({ value }: any) => (
          <div className={styles.tableRow}>
            <div>**{value}</div>
          </div>
        ),
      },
      {
        Header: "Bank Name",
        accessor: "bank_name",
        Cell: ({ value }: any) => (
          <div className={styles.tableRow}>
            <div>{value}</div>
          </div>
        ),
      },
      {
        Header: "Mapped to:",
        accessor: "",
        Cell: ({ row }: any) => (
          <div className={styles.tableRow}>
            <div>{row.original.glAccounts?.map((glAccount: any) => glAccount.name).join()}</div>
          </div>
        ),
      },
      {
        Header: "Status",
        disableSortBy: true,
        accessor: "",
        Cell: ({ row }: any) =>
          canValidateBA ? (
            <div className={styles.tableRow}>
              <div>
                {row.original.deposit_only === false ? (
                  (acl.canAddBankAccountToOrg || acl.orgsUserMayAddCompanyTo) &&
                  row.original.verification_status !== "Pending" &&
                  row.original.verification_link !== null ? (
                    <Link underline={"none"} href={row.original.verification_link || ""}>
                      <Button size={"small"} variant={"text"}>
                        Validate
                      </Button>
                    </Link>
                  ) : (
                    row.original.verification_status
                  )
                ) : (
                  acl.canAddBankAccountToOrg && (
                    <UpgradeButton
                      onClick={() => {
                        upgradeBankAccount();
                      }}
                      size="small"
                      sx={{ textTransform: "capitalize" }}
                      variant="outlined"
                      color={"primary"}
                      endIcon={<ArrowCircleUpIcon />}
                    >
                      Upgrade
                    </UpgradeButton>
                  )
                )}
              </div>
            </div>
          ) : null,
      },
      {
        Header: "",
        accessor: "vcard",
        Cell: ({ row }: any) => {
          const [anchorEl, setAnchorEl] = useState(null);
          const open = Boolean(anchorEl);

          const handleClick = (event: any) => {
            setAnchorEl(event.currentTarget);
          };
          const handleClose = () => {
            setAnchorEl(null);
          };

          const canListAutoDeposit = acl.canListBankAccountAutoDepositAddresses(row.original.id);
          const canListLinkedAddresses = acl.canListBankAccountLinkedEmailAddresses(row.original.id);

          if (!canListAutoDeposit && !canListLinkedAddresses) {
            return null;
          }

          return (
            <div className={styles.tableRow}>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                {canListAutoDeposit && (
                  <MenuItem color="inherit" onClick={() => openAutoDeposit(row.original.id)}>
                    Auto Deposit
                  </MenuItem>
                )}

                {canListLinkedAddresses && (
                  <MenuItem color="inherit" onClick={() => openLinkEmailAddress(row.original.id)}>
                    Link Email Addresses
                  </MenuItem>
                )}
              </Menu>

              {row && (
                <SettingsIcon
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                  color="info"
                  sx={{ cursor: "pointer", fontSize: "24px" }}
                />
              )}
            </div>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [canValidateBA, selectedCompany],
  );
  const [search, setSearch] = useQueryParam("search", StringParam);
  const accountsTableContainerStyle = { mt: 2, p: 0, zIndex: 0, position: "relative" };
  const closeIconStyle = {
    height: "16x",
    width: "16px",
    cursor: "pointer",
  };
  return (
    <Box display="flex" width="100%" justifyContent="space-between" gap="130px" maxWidth="1400px">
      <CompanyMenu />

      <Box sx={{ padding: "20px", width: "100%" }}>
        <Typography variant="h1" marginY="20px">
          Bank Accounts
        </Typography>
        <Box>
          <Box display="flex" justifyContent="space-between" alignItems="end">
            <Box>
              <InputLabel variant="standard" htmlFor="search">
                Search
              </InputLabel>
              <TextField
                placeholder="Search"
                value={search}
                onKeyPress={(ev) => {
                  if (ev.key === "Enter") {
                    ev.preventDefault();
                  }
                }}
                onChange={(event) => {
                  setSearch(event.target.value);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {search ? (
                        <CloseIcon onClick={() => setSearch("")} sx={closeIconStyle} />
                      ) : (
                        <SearchIcon sx={closeIconStyle} />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </Box>

            <Box sx={{ display: "flex", gap: 1 }}>
              {acl.canManageUsers && (
                <>
                  {selectedCompany?.verification_link &&
                    selectedCompany?.verification_status !== "Active" &&
                    selectedCompany?.verification_status !== "Pending" && (
                      <Link underline="none" href={`/verifications/companies/${selectedCompany?.id}`}>
                        <Button color="primary" variant="contained">
                          Validate company
                        </Button>
                      </Link>
                    )}
                </>
              )}
              {acl.orgsUserMayAddBankAccountTo.some((org) => org.id === selectedOrganizationId) && (
                <Button
                  startIcon={<AddIcon />}
                  color="secondary"
                  variant="contained"
                  onClick={() => {
                    uiStore.showAddBankAccountModal = AddBankAccountSource.Company;
                  }}
                >
                  Add Bank Account
                </Button>
              )}
            </Box>
          </Box>
        </Box>
        <Paper sx={accountsTableContainerStyle}>
          <Table
            columns={bankAccountsColumns}
            loading={contextStore.isFetching}
            data={bankAccounts?.filter((val) => {
              if (search) {
                if (
                  val?.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
                  val?.bank_name.toLocaleLowerCase().includes(search.toLocaleLowerCase())
                ) {
                  return val;
                }
              } else {
                return val;
              }
              return null;
            })}
          />
        </Paper>
      </Box>
    </Box>
  );
});
