import { Spinner } from "components/common/Spinner/Spinner";
import { observer } from "mobx-react-lite";
import { FunctionComponent, useEffect } from "react";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { useStore } from "storeContainer";
import VCheckCheck from "../VCheckCheck";
export const VCheckPage: FunctionComponent = observer(function VCheckPage() {
  const { bankAccountId, checkId } = useParams<{
    bankAccountId?: string;
    checkId: string;
  }>();
  const vChecksStore = useStore("VChecksStore");
  const contextStore = useStore("ContextStore");
  const history = useHistory();
  useEffect(() => {
    vChecksStore.loadCheck(checkId, bankAccountId);
  }, [bankAccountId, checkId, contextStore, history, vChecksStore]);

  useEffect(() => {
    if (vChecksStore.check.error?.response?.errors?.message) {
      history.push("/access-restricted");
    }
  }, [vChecksStore.check.error, history]);

  if (!vChecksStore.check.isLoaded) {
    return <Spinner />;
  }
  return <VCheckCheck check={vChecksStore.check.data?.ledger_check!} isModal={false} />;
});
