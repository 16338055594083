import styled from "@emotion/styled";
import { Tab, TabProps } from "@mui/material";

export const SidebarTabBtn = styled(Tab)<TabProps & { small?: boolean }>(({ small }) => ({
  fontSize: "14px",
  padding: small ? " 10px" : "12px 16px",
  minHeight: "auto",
}));

export const a11yProps = (index: number) => ({
  id: `vcheck-details-sidebar-tab-${index}`,
  "aria-controls": `vcheck-details-sidebar-tabpanel-${index}`,
});
