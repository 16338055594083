import { Box, Button, Divider, Tooltip } from "@mui/material";
import { isNil } from "lodash";
import { observer } from "mobx-react";
import { useState } from "react";
import { useStore } from "storeContainer";
import { COLORS } from "themes/default";
import { SubBatchesDrawer } from "../../BatchSummary/BatchSubBatches/SubBatchesDrawer/SubBatchesDrawer";
import { blackBtnStyles } from "../summariesStyles";

export const SummariesSubBatches = observer(function SummariesSubBatches() {
  const vBillBatchDetailsStore = useStore("VBillBatchDetailsStore");
  const [drawerVisible, setDrawerVisible] = useState(false);

  const { childBatchListDetails: childBatchList, batchDetails } = vBillBatchDetailsStore;
  const totalChildBatches = (childBatchList.data?.items ?? []).length;

  if (!batchDetails.data?.settings?.BATCH_SUBBATCHES_ALLOWED || !isNil(batchDetails.data?.parentBatchId)) {
    return null;
  }

  return (
    <>
      <Divider orientation="vertical" variant="middle" flexItem />

      <Tooltip title="Add/Remove sub batches" arrow>
        <Button onClick={() => setDrawerVisible(true)} sx={{ ...blackBtnStyles, textTransform: "none" }}>
          <Box sx={{ color: COLORS.logoBlue }}>Sub batches:</Box> <strong>({totalChildBatches})</strong>
        </Button>
      </Tooltip>

      {drawerVisible && <SubBatchesDrawer onDrawerClose={() => setDrawerVisible(false)} />}
    </>
  );
});
