import { Box, Tooltip } from "@mui/material";
import { formatCurrency } from "common/helpers/utils";
import { getVBillFormattedDate } from "components/pages/common/VBill/utils";
import { IVBillVBillGetBillsQuery } from "generated/sdk.vbill";
import { COLORS } from "themes/default";

interface IPayVBillPaymentsProps {
  amounts?: IVBillVBillGetBillsQuery["getBills"]["items"][number]["amounts"];
}

export const PayVBillPayments = ({ amounts }: IPayVBillPaymentsProps) => (
  <>
    {amounts &&
    (Boolean(amounts.paymentsStats.successful.count) ||
      Boolean(amounts.paymentsStats.pending.count) ||
      Boolean(amounts.paymentsStats.failed.count)) ? (
      <>
        {Boolean(amounts.paymentsStats.successful.count) && (
          <Tooltip
            title={`${formatCurrency(Number(amounts.paymentsStats.successful.sum))} in 
                  ${amounts.paymentsStats.successful.count} payments`}
          >
            <Box sx={{ color: COLORS.success }}>{formatCurrency(Number(amounts.paymentsStats.successful.sum))}</Box>
          </Tooltip>
        )}
        {Boolean(amounts.paymentsStats.pending.count) && (
          <Tooltip
            title={
              <>
                <Box>
                  {formatCurrency(Number(amounts.paymentsStats.pending.sum))} in {amounts.paymentsStats.pending.count}{" "}
                  processing
                </Box>

                <Box>Min: {getVBillFormattedDate(amounts.paymentsStats.pending.minDate)}</Box>
                <Box>Max: {getVBillFormattedDate(amounts.paymentsStats.pending.maxDate)}</Box>
              </>
            }
          >
            <Box>{formatCurrency(Number(amounts.paymentsStats.pending.sum))}</Box>
          </Tooltip>
        )}
        {Boolean(amounts.paymentsStats.failed.count) && (
          <Tooltip
            title={`${formatCurrency(Number(amounts.paymentsStats.failed.sum))} in ${amounts.paymentsStats.failed.count}
                    failed`}
          >
            <Box sx={{ color: COLORS.orange }}>{formatCurrency(Number(amounts.paymentsStats.failed.sum))}</Box>
          </Tooltip>
        )}
      </>
    ) : (
      "-"
    )}
  </>
);
