import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton } from "@mui/material";
import { getChatNotificationUrl, IGetChatNotificationUrlArgs } from "modules/Chat/utils/utils";
import { useHistory, useParams } from "react-router-dom";
import { COLORS } from "themes/default";
import { ChatSummaryBadge } from "../../../modules/Chat/components/ChatSummaryBadge/ChatSummaryBadge";
import styles from "./Header.module.scss";

export const SubHeader = ({ title, isCheckDetailsPage }: { title: string; isCheckDetailsPage?: boolean }) => {
  const history = useHistory();
  const { organizationId } = useParams<{ organizationId: string }>();

  const handleChatSummaryChannelNotificationClick = (channel: IGetChatNotificationUrlArgs) => {
    const url = getChatNotificationUrl(channel);

    history.push(url);
  };

  return (
    <Box className={styles.subHeader}>
      <Box className={styles.subHeaderLeft} onClick={() => history.push("/org")}></Box>
      <Box className={styles.subHeaderCenter}>{title}</Box>
      <Box className={styles.subHeaderRight}>
        <ChatSummaryBadge
          showMemberLatestMessages
          organizationId={organizationId}
          onChannelNotificationClick={handleChatSummaryChannelNotificationClick}
          sx={{ marginRight: "15px" }}
          chatIconSx={{ color: COLORS.white }}
          chatBadgeSx={{
            "& .MuiBadge-badge": {
              color: COLORS.logoBlue,
              backgroundColor: COLORS.white,
            },
          }}
        />

        <IconButton
          className={styles.subHeaderRightButton}
          onClick={() => (isCheckDetailsPage ? history.goBack() : history.push("/org"))}
        >
          <CloseIcon htmlColor="#ffffff" sx={{ fontSize: "24px" }} />
        </IconButton>
      </Box>
    </Box>
  );
};
