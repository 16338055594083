import { Box, Typography } from "@mui/material";
import { FunctionComponent } from "react";

export const ChatRestrictedChannel: FunctionComponent = () => {
  return (
    <Box>
      <Typography>This channel is private for you. Please request access to...</Typography>
    </Box>
  );
};
