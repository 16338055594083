import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";
import CircleIcon from "@mui/icons-material/Circle";
import DownloadIcon from "@mui/icons-material/Download";
import { Box, Grid, IconButton, Paper, Popper, Tooltip, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import Step from "@mui/material/Step";
import StepConnector, { stepConnectorClasses } from "@mui/material/StepConnector";
import { StepIconProps } from "@mui/material/StepIcon";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { styled } from "@mui/material/styles";
import { CloseIcon, ContentCopyIcon } from "components/common/icons";
import { format } from "date-fns";
import { CheckRequiredActionType, CheckTimelineEventStatusEnumType, CheckTimelineEventType } from "generated/sdk";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { COLORS } from "themes/default";
import { getRequiredActionMsgTextColor } from "../utils";
import styles from "./VCheckCheck.module.scss";

const ColorlibConnector = styled(StepConnector)<{ hasReqActions: boolean }>(({ theme, hasReqActions }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: "#B5ECA6",
    },
    "&:nth-child(1)": {
      [`& .${stepConnectorClasses.line}`]: {
        background: hasReqActions ? "linear-gradient(to right, #B5ECA6 50%, #eaeaf0 50%)" : "#B5ECA6",
      },
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: "#B5ECA6",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")<{
  ownerState: { finished?: boolean; active?: boolean; inProgress?: boolean; hasReqActions?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor: ownerState.inProgress && !ownerState.hasReqActions ? COLORS.logoBlue : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  ...(ownerState.finished && {
    background: "#B5ECA6",
  }),
}));
interface ColorlibStepIconProps extends StepIconProps {
  status: string;
  requiredActions: (CheckRequiredActionType | null)[];
}

const ColorlibStepIcon = ({ status, requiredActions, ...props }: ColorlibStepIconProps) => {
  const icons: { [index: string]: React.ReactElement } = {
    checkIcon: <CheckIcon sx={{ fontSize: "40px", color: "#6FD055" }} />,
    timeIcon: <AccessTimeIcon sx={{ fontSize: "40px", color: "white" }} />,
    circleIcon: <CircleIcon sx={{ fontSize: "50px", color: "#F9FBFF" }} />,
  };

  return (
    <ColorlibStepIconRoot
      sx={{ border: "2px solid #c4c4c4" }}
      ownerState={{
        finished: status === CheckTimelineEventStatusEnumType.Finished,
        hasReqActions: !!requiredActions.length,
        inProgress: status === CheckTimelineEventStatusEnumType.InProgress,
      }}
    >
      {status === CheckTimelineEventStatusEnumType.Finished
        ? icons.checkIcon
        : status === CheckTimelineEventStatusEnumType.InProgress && !requiredActions?.length
          ? icons.timeIcon
          : icons.circleIcon}
    </ColorlibStepIconRoot>
  );
};

interface VcheckTimelineProps {
  steps?: (CheckTimelineEventType | null)[] | null;
  requiredActions?: (CheckRequiredActionType | null)[] | null;
  isModalOpened?: boolean;
  checkId?: string;
}
export const VcheckTimeline = ({ steps, requiredActions, isModalOpened, checkId }: VcheckTimelineProps) => {
  const matchPayments = !!useRouteMatch("/org/:organizationId/vChecks/payments/:selectedQuickFilter");
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | HTMLDivElement | null>(null);
  const stepRefs = useRef(
    Array.from({ length: steps?.length ?? 0 }, () => React.createRef<HTMLButtonElement | HTMLDivElement | null>()),
  );
  const [descAnchor, setDescAnchor] = useState<null | HTMLElement>(null);
  const [descIndex, setDescIndex] = useState<number | null>(null);
  const [hoveredAnchorEl, setHoveredAnchorEl] = useState<null | HTMLElement>(null);
  const [height, setHeight] = useState(0);
  const openDesc = Boolean(descAnchor);
  const popperOpen = Boolean(anchorEl);
  const hoveredOpen = Boolean(hoveredAnchorEl);
  const lastIndex = steps?.reduce((acc, item, index) => {
    if (item?.status === CheckTimelineEventStatusEnumType.Finished) {
      return index;
    }
    return acc;
  }, -1);
  useEffect(() => {
    if (stepRefs?.current.at(lastIndex || 0)) setAnchorEl(stepRefs?.current[(lastIndex ?? 0) + 1 || 0]?.current as any);
  }, [stepRefs, steps]);

  const infoRef = useRef<HTMLInputElement>(null);
  const StepperSx = {
    marginTop: `${height - 40}px`,
    "& .MuiStepConnector-root": {
      left: "calc(-50%)",
      right: "calc(50% + 10px)",
    },
    "& .MuiStepConnector-line": {
      marginTop: "20px",
    },
  };
  useEffect(() => {
    setHeight(infoRef?.current?.clientHeight || 0);
  });
  const formatDateToDesign = (dateString: string) => {
    if (!dateString) {
      return null;
    }

    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return dateString;
    }

    const formattedDate = format(date, "MM/dd/yy");

    return formattedDate;
  };

  const formatTimeToDesign = (dateString: string) => {
    if (!dateString) {
      return null;
    }

    // Check if dateString contains a time (e.g., '12:09 PM' or '11:03')
    const timePattern = /\b\d{1,2}:\d{2}\s*(AM|PM)?\b/i;
    if (!timePattern.test(dateString)) {
      return null;
    }

    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return null;
    }

    const formattedTime = format(date, "hh:mm aa");

    return formattedTime;
  };

  const typographySx = {
    zIndex: "2",
    background: "#FFF",
    display: "inline",
    position: "relative",
    fontSize: "12px",
    fontWeight: "500",
    bottom: "5px",
    whiteSpace: "nowrap",
  };

  const StepIcon = React.useCallback(
    ({
      step,
      requiredActions,
    }: {
      step: CheckTimelineEventType | null;
      requiredActions?: (CheckRequiredActionType | null)[] | null;
    }) => {
      return <ColorlibStepIcon icon={undefined} status={step?.status || ""} requiredActions={requiredActions || []} />;
    },
    [],
  );
  //this component is only used as a reference for the actual element that holds
  //the required actions so that the height can be calculated before rendering the required actions
  //lastIndex > 1 condition means that the last finished step is after index 1
  const floatingPaper = (hide: boolean) => (
    <Paper
      ref={infoRef}
      sx={{
        visibility: hide ? "hidden" : "initial",
        padding: "10px",
        position: "absolute",
        top: "0",
        width: "300px",
        transform: `translateY(-${height}px)`,
      }}
    >
      <ul>
        {requiredActions?.map((item) => (
          <li key={item?.action} style={{ marginTop: "10px" }}>
            <Typography fontSize={"14px"} variant="body2">
              {item?.description}
            </Typography>
          </li>
        ))}
      </ul>
    </Paper>
  );

  return (
    <Stack sx={StepperSx} spacing={4}>
      {floatingPaper(true)}
      <Popper
        popperOptions={{
          modifiers: [
            {
              name: "preventOverflow",
              enabled: false,
            },
            {
              name: "flip",
              enabled: false,
            },
          ],
        }}
        disablePortal
        placement="left-start"
        style={{ zIndex: 1250, bottom: "200px", maxWidth: "100vw" }}
        open={popperOpen}
        anchorEl={anchorEl}
      >
        {!!requiredActions?.length && (isModalOpened || isModalOpened === undefined) && !!height && (
          <Box
            sx={{ transform: `translate(10px,${lastIndex! > 1 ? "-60px" : "-90px"})` }}
            alignItems={"center"}
            flexDirection={"column"}
            justifyContent={"center"}
            display={"flex"}
          >
            <Grid display={"flex"} flexDirection={"column"} alignItems={"center"} justifyContent={"center"}>
              <Paper
                sx={{
                  padding: "10px",
                  position: "absolute",
                  right: lastIndex! > 1 ? "210px" : lastIndex! > 0 ? "30px" : "",
                  width: "300px",
                  transform: `translateY(-${height / 1.8}px)`,
                  boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.3)",
                  zIndex: 100,
                }}
              >
                <ul>
                  {requiredActions?.map((item) => (
                    <li key={item?.action} style={{ marginTop: "10px" }}>
                      <Typography
                        color={
                          matchPayments
                            ? getRequiredActionMsgTextColor(item?.key)
                            : item?.is_blocker
                              ? COLORS.darkRed
                              : COLORS.black
                        }
                        fontSize={"14px"}
                        variant="body2"
                      >
                        {item?.description}
                      </Typography>
                    </li>
                  ))}
                </ul>
              </Paper>
              <Box
                sx={{
                  width: "2px",
                  minWidth: "20px",
                  height: "2px",
                  background: "grey",
                  transformOrigin: "top left",
                  transform: `translateX(${lastIndex! > 1 ? 9 : -9}px) ${lastIndex! > 1 ? "scaleX(-10)" : ""}`,
                }}
              ></Box>
              <Box
                sx={{ width: "2px", minWidth: "2px", height: lastIndex! > 1 ? "90px" : "120px", background: "grey" }}
              ></Box>
            </Grid>

            <Box sx={{ cursor: "pointer" }}>
              <CancelIcon sx={{ fontSize: "20px" }} color="error" height={"32px"} />
            </Box>
          </Box>
        )}
      </Popper>
      <Stepper
        alternativeLabel
        activeStep={lastIndex! + 1}
        connector={<ColorlibConnector hasReqActions={!!requiredActions?.length} />}
      >
        {steps?.map((step, index) => {
          return (
            <Step
              ref={stepRefs.current[index] as any}
              sx={{ textAlign: "center", alignItems: "center", justifyContent: "center" }}
              key={step?.label}
              onMouseOver={(e) => {
                if (step?.label === "Estimated payment date") {
                  setHoveredAnchorEl(e.currentTarget);
                }
              }}
              onMouseLeave={(e) => {
                if (step?.label === "Estimated payment date") {
                  setHoveredAnchorEl(null);
                }
              }}
              onClick={(e) => {
                if (step?.description?.filter((item) => item)?.length || step?.images?.filter((item) => item)?.length) {
                  setDescIndex(index);
                  if (!descAnchor) setDescAnchor(e.currentTarget);
                  else if (descAnchor !== e.currentTarget) setDescAnchor(e.currentTarget);
                  else {
                    setDescAnchor(null);
                    setDescIndex(null);
                  }
                } else {
                  setDescAnchor(null);
                  setDescIndex(null);
                }
              }}
            >
              {!!steps.find((item) => item?.label === "Estimated payment date" && item.date) &&
                !!steps.find((item) => item?.label === "Paid" && item.date) && (
                  <Popper
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    style={{
                      zIndex: isModalOpened ? 1200 : 2,
                      bottom: "200px",
                      maxWidth: "100vw",
                      position: "absolute",
                    }}
                    anchorEl={hoveredAnchorEl}
                    open={hoveredOpen}
                    placement="bottom"
                    modifiers={[
                      {
                        name: "offset",
                        options: {
                          offset: [0, -40],
                        },
                      },
                      {
                        name: "preventOverflow",
                        enabled: false,
                      },
                      {
                        name: "flip",
                        enabled: false,
                      },
                    ]}
                  >
                    <Paper sx={{ padding: "20px" }}>
                      {steps.find((item) => item?.label === "Estimated payment date")?.date}
                    </Paper>
                  </Popper>
                )}

              <Popper
                onClick={(e) => {
                  e.stopPropagation();
                }}
                style={{ zIndex: isModalOpened ? 1200 : 2, bottom: "200px", maxWidth: "100vw", position: "absolute" }}
                anchorEl={descAnchor}
                open={openDesc && descIndex === index}
                placement="bottom"
                modifiers={[
                  {
                    name: "offset",
                    options: {
                      offset: [0, -40],
                    },
                  },
                  {
                    name: "preventOverflow",
                    enabled: false,
                  },
                  {
                    name: "flip",
                    enabled: false,
                  },
                ]}
              >
                {(step?.description?.some((item) => !item?.static) || step?.images?.some((image) => !!image?.path)) && (
                  <Paper
                    sx={{
                      boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.3)",
                      padding: "10px",
                      width: "300px",
                      overflow: "auto",
                    }}
                  >
                    <ul
                      style={{
                        paddingLeft: "10px",
                        marginBottom: "0",
                        display: "flex",
                        flexDirection: "column",
                        maxHeight: "600px",
                      }}
                    >
                      {step?.description
                        ?.filter((item) => !item?.static)
                        .map((descItem) => {
                          const match = descItem?.description?.match(/^Payment Confirmation Trace# \[([0-9]+)\]$/);
                          const numberToCopy = match ? match[1] : "";

                          return descItem?.url ? (
                            <Box
                              key={index}
                              component="a"
                              href={descItem.url}
                              target="_blank"
                              rel="noreferrer"
                              aria-label="Tracking Url"
                              sx={{
                                textDecoration: "none",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                sx={{
                                  ...typographySx,
                                  fontSize: "11px",
                                  whiteSpace: "normal",
                                  verticalAlign: "center",
                                  marginBottom: "5px",
                                  color: "inherit",
                                }}
                              >
                                {descItem?.description}
                              </Typography>
                            </Box>
                          ) : (
                            <Typography
                              align="center"
                              sx={{
                                ...typographySx,
                                fontSize: "11px",
                                whiteSpace: "normal",
                                verticalAlign: "center",
                                marginBottom: "5px",
                              }}
                            >
                              {descItem?.description}
                              {match && (
                                <Tooltip title="Copy Confirmation Trace # to clipboard" arrow>
                                  <IconButton
                                    onClick={() => window.navigator.clipboard.writeText(numberToCopy)}
                                    sx={{
                                      marginLeft: "2px",
                                    }}
                                  >
                                    <ContentCopyIcon />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </Typography>
                          );
                        })}
                    </ul>
                    {!!step?.images?.length && step?.images?.length > 0 && (
                      <div>
                        {step.images.map((image) => (
                          <Grid
                            key={image?.id}
                            alignItems="center"
                            justifyContent="space-between"
                            container
                            sx={{ paddingX: "35px" }}
                          >
                            <Typography fontSize={16}>{image?.label}</Typography>
                            {image?.id && (
                              <Box
                                display="flex"
                                gap="10px"
                                alignItems="start"
                                flexDirection="column"
                                sx={{ padding: "10px" }}
                              >
                                <Box>
                                  <img className={styles.imageWrapper} src={image.path!} alt="activity attachment" />
                                </Box>
                                <a href={`/checks/${checkId}/images/${image.id}`}>
                                  <IconButton>
                                    <DownloadIcon color="secondary" />
                                  </IconButton>
                                </a>
                              </Box>
                            )}
                          </Grid>
                        ))}
                      </div>
                    )}
                  </Paper>
                )}
              </Popper>
              <Typography align="center" sx={typographySx}>
                {step?.label}
              </Typography>
              <StepLabel
                sx={{
                  "&.MuiStepLabel-horizontal": {
                    ".MuiStepLabel-alternativeLabel": {
                      "&.MuiStepLabel-label": { marginTop: step?.flags?.length ? "0" : "22px" },
                    },
                  },
                }}
                StepIconComponent={() => StepIcon({ requiredActions, step })}
              >
                <Box display={"flex"} flexDirection={"row"} justifyContent={"center"} marginBottom={"10px"}>
                  {step?.flags
                    ?.filter((flag) => flag?.label === "Opened" || flag?.label === "Clicked")
                    .map((item) => (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          marginX: "2px",
                          cursor: "pointer",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "25px",
                            height: "25px",
                            background: item?.flag ? "#B5ECA6" : "white",
                            border: "2px solid #c4c4c4",
                            borderRadius: "25px",
                            marginX: "2px",
                          }}
                        >
                          {item?.flag ? (
                            <CheckIcon sx={{ color: "#6FD055" }} />
                          ) : (
                            <CloseIcon sx={{ color: "#ccc", fontSize: "20px" }} />
                          )}
                        </Box>
                        <Typography sx={{ ...typographySx, fontSize: "10px", bottom: "0px" }}>{item?.label}</Typography>
                      </Box>
                    ))}
                </Box>

                <Box minHeight={"32px"}>
                  {step?.label === "Estimated payment date" &&
                  !!steps.find((item) => item?.label === "Paid" && item.date) ? (
                    <></>
                  ) : (
                    <>
                      {step?.description
                        ?.filter((item) => item?.static)
                        ?.map((item) => (
                          <>
                            <Typography
                              align="center"
                              sx={{
                                ...typographySx,
                                fontSize: "11px",
                                whiteSpace: "normal",
                              }}
                            >
                              {item?.description}
                            </Typography>
                            <br></br>
                          </>
                        ))}
                      <Typography align="center" sx={{ ...typographySx, fontSize: "11px" }}>
                        {formatDateToDesign(step?.date || "")}
                      </Typography>
                      {!!step?.date && <br></br>}
                      <Typography align="center" sx={{ ...typographySx, fontSize: "11px" }}>
                        {formatTimeToDesign(step?.date || "")}
                      </Typography>
                      {!!step?.date && <br></br>}
                    </>
                  )}
                </Box>
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Stack>
  );
};
