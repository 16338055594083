import { VCheckCheckQueryQuery } from "generated/sdk";

export const getCheckOtherContextDetails = (checkDetails: VCheckCheckQueryQuery["VCheckCheckQuery"]) => {
  if (!checkDetails?.other_context_available || !checkDetails.ledger_check) {
    return false;
  }

  let type: string;
  let baId: string | undefined;
  if (checkDetails.ledger_check?.role === "recipient") {
    type = "sending";
    baId = checkDetails.ledger_check.sender?.id;
  } else {
    type = "receiving";
    baId = checkDetails.ledger_check.recipient?.id;
  }

  return {
    type,
    url:
      checkDetails.other_context_organization_id === null
        ? `/check/${checkDetails.ledger_check.id}`
        : `/org/${checkDetails.other_context_organization_id}/ledger/${baId}/checks/${checkDetails.ledger_check.id}`,
  };
};
