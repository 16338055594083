import { graphqlVBillClient } from "common/graphqlClient";
import { getSdk, IVBillVBillSettingsQuery } from "generated/sdk.vbill";
import { createContext, ReactNode, useContext, useEffect, useState } from "react";

const { VBillSettings } = getSdk(graphqlVBillClient);

type CompanyMappingsSettings = IVBillVBillSettingsQuery["settings"]["vBill"]["additionalMappings"]["invoice"];

const __$$payVBillsCompaniesMappingsSettingsCache = new Map<string, CompanyMappingsSettings>();
const PayVBillsCompaniesMappingsSettingsContext = createContext<{
  isFetching: boolean;
  data: Map<string, CompanyMappingsSettings> | undefined;
}>({ isFetching: false, data: __$$payVBillsCompaniesMappingsSettingsCache });

export function usePayVBillsCompaniesMappingsSettings() {
  return useContext(PayVBillsCompaniesMappingsSettingsContext);
}

interface IPayVBillsCompaniesMappingsSettingsContextProviderProps {
  children: ReactNode;
  companyIds: string[];
}

export const PayVBillsCompaniesMappingsSettingsContextProvider = ({
  children,
  companyIds,
}: IPayVBillsCompaniesMappingsSettingsContextProviderProps) => {
  const [isFetching, setIsFetching] = useState(false);
  const [companiesMappingsSettings, setCompaniesMappingsSettings] = useState<Map<string, CompanyMappingsSettings>>();

  useEffect(() => {
    fetchAndSetCompaniesMappingsSettings(companyIds);
  }, [companyIds]);

  const fetchAndSetCompaniesMappingsSettings = async (companyIds: string[]) => {
    if (!companyIds.length) {
      return;
    }
    setIsFetching(true);
    const companiesSettingsPayload = companyIds
      .filter((compId) => !__$$payVBillsCompaniesMappingsSettingsCache.has(compId))
      .map(async (compId) => ({
        compId,
        settings: (await VBillSettings({ companyId: compId })).settings.vBill.additionalMappings.invoice,
      }));
    const companiesSettingsResp = await Promise.all(companiesSettingsPayload);

    companiesSettingsResp.forEach(({ compId, settings }) => {
      __$$payVBillsCompaniesMappingsSettingsCache.set(compId, settings);
    });

    setCompaniesMappingsSettings(__$$payVBillsCompaniesMappingsSettingsCache);
    setIsFetching(false);
  };

  return (
    <PayVBillsCompaniesMappingsSettingsContext.Provider value={{ isFetching, data: companiesMappingsSettings }}>
      {children}
    </PayVBillsCompaniesMappingsSettingsContext.Provider>
  );
};
