import { normalizeIsoDateOnMidnight } from "common/helpers/dates";
import { CHDecimal } from "common/helpers/decimal";
import {
  IVBillInvoiceIncomingStatus,
  IVBillInvoiceLineItemMetaSchema,
  IVBillLineItemSchema,
  IVBillVBillInvoiceQuery,
} from "generated/sdk.vbill";
import { isNil } from "lodash";
import { TAdditionalMappings, TVBillFormFields, TVBillFormLineFields } from "./types";

export const ENABLE_COMPUTED_LINE_ITEM_AMOUNTS = Boolean(
  Number(import.meta.env.VITE_APP_ENABLE_COMPUTED_LINE_ITEM_AMOUNTS),
);

export function getVBillRouteRedirectByStatus(
  organizationId: string,
  vbillId: string,
  search: string,
  status?: IVBillInvoiceIncomingStatus,
) {
  const leadingPath = `/org/${organizationId}/vbill/${vbillId}`;
  let newPath = leadingPath;

  switch (status) {
    case IVBillInvoiceIncomingStatus.Queued:
      newPath += "/incoming";
      break;
    case IVBillInvoiceIncomingStatus.New:
      newPath += "/new";
      break;
    case IVBillInvoiceIncomingStatus.Mapped:
    case IVBillInvoiceIncomingStatus.Approved:
    case IVBillInvoiceIncomingStatus.Declined:
    case IVBillInvoiceIncomingStatus.Deleted:
    case IVBillInvoiceIncomingStatus.History:
    case IVBillInvoiceIncomingStatus.PendingApproval:
      newPath += "/summary";
      break;
    default:
      newPath += "/incoming";
  }

  if (!!search.length) {
    newPath += search;
  }

  return newPath;
}

export function getVBillFormIncomingDefaultValues(
  invoice?: IVBillVBillInvoiceQuery["invoice"],
): TVBillFormFields | undefined {
  if (!invoice) {
    return undefined;
  }

  const queuedSuggestedMainFile =
    invoice.files?.find((file) => `${file.id}` === invoice.suggestedMainFileId) ?? invoice.files?.[0];
  const queuedSuggestedMainFileMetadata = queuedSuggestedMainFile?.metadata?.GDAI;
  const invoiceData =
    invoice.status === IVBillInvoiceIncomingStatus.Queued && isNil(invoice.mainFile)
      ? queuedSuggestedMainFileMetadata
      : invoice;

  return {
    amount: !isNil(invoiceData?.amount) ? `${invoiceData.amount}` : "0",
    invoiceDate: invoiceData?.invoiceDate ? normalizeIsoDateOnMidnight(invoiceData.invoiceDate) : "",
    invoiceDateDue: invoiceData?.invoiceDateDue ? normalizeIsoDateOnMidnight(invoiceData.invoiceDateDue) : "",
    invoiceNumber: invoiceData?.invoiceNumber ?? "",
    senderName: invoiceData?.senderName ?? "",
    senderEmail: invoiceData?.senderEmail ?? "",
    senderAddress: invoiceData?.senderAddress ?? "",
    senderPhone: invoiceData?.senderPhone ?? "",
    mainFile:
      invoice.status === IVBillInvoiceIncomingStatus.Queued && isNil(invoice.mainFile)
        ? queuedSuggestedMainFile
          ? { text: queuedSuggestedMainFile.name ?? "", value: queuedSuggestedMainFile.id }
          : null
        : invoice.mainFile
          ? { text: invoice.mainFile.name ?? "", value: invoice.mainFile.id }
          : null,
    hasLineItems:
      invoice.status === IVBillInvoiceIncomingStatus.Queued && isNil(invoice.mainFile) ? false : invoice.hasLineItems,
    lineItems:
      invoice.status === IVBillInvoiceIncomingStatus.Queued && isNil(invoice.mainFile)
        ? []
        : invoice.hasLineItems
          ? invoice.lineItems?.length
            ? invoice.lineItems
                .sort((a, b) => (a.orderIndex ?? 0) - (b.orderIndex ?? 0))
                .map((lineItem) => lineItemToFormLineFields(lineItem))
            : []
          : [],
    companyId: invoice?.companyId ?? "",
  };
}

export function getVBillFormNewDefaultValues(
  invoice?: IVBillVBillInvoiceQuery["invoice"],
): TVBillFormFields | undefined {
  if (!invoice) {
    return undefined;
  }

  const currentAdditionalMappings = invoiceAdditionalMappingsToFormFields(invoice.additionalMappings);
  const currentLineItems: TVBillFormLineFields[] = (invoice.lineItems ?? [])
    .sort((a, b) => (a.orderIndex ?? 0) - (b.orderIndex ?? 0))
    .map((lineItem) => ({
      description: !isNil(lineItem.description) ? lineItem.description : "",
      lineItemId: !isNil(lineItem.id) ? `${lineItem.id}` : "",
      qty: lineItem.qty?.length ? lineItem.qty : "",
      rate: lineItem.rate?.length ? lineItem.rate : "",
      amount: lineItem.amount?.length ? lineItem.amount : "",
      ...lineItemAdditionalMappingsToFormLineFields(lineItem),
    }));

  return {
    amount: invoice.amount ?? "",
    invoiceDate: invoice.invoiceDate ? normalizeIsoDateOnMidnight(invoice.invoiceDate) : "",
    invoiceDateDue: invoice.invoiceDateDue ? normalizeIsoDateOnMidnight(invoice.invoiceDateDue) : "",
    invoiceNumber: invoice.invoiceNumber ?? "",
    senderName: invoice.senderName ?? "",
    senderEmail: invoice.senderEmail ?? "",
    senderAddress: invoice.senderAddress ?? "",
    senderPhone: invoice.senderPhone ?? "",
    companyId: invoice.companyId ?? "",
    mainFile: invoice.mainFile ? { text: invoice.mainFile.name ?? "", value: invoice.mainFile.id } : null,
    hasLineItems: invoice.hasLineItems,
    lineItems: currentLineItems,
    ...currentAdditionalMappings,
  };
}

export const invoiceAdditionalMappingsToFormFields = (
  additionalMappings: IVBillVBillInvoiceQuery["invoice"]["additionalMappings"],
) =>
  (additionalMappings ?? [])
    .map(({ label, label2, parentLabel, type, value, key }) => ({
      addMappingSettingKey: key,
      key: value,
      type: type,
      label: label ?? "",
      label2: label2 ?? "",
      parentLabel: parentLabel ?? "",
    }))
    .reduce<TAdditionalMappings>((prev, curr) => ({ ...prev, [curr.type ?? ""]: curr }), {});

export const lineItemAdditionalMappingsToFormLineFields = (lineItem?: IVBillLineItemSchema) =>
  (lineItem?.glMappings?.[0]?.additionalMappings ?? [])
    .map(({ label, label2, parentLabel, type, value, key }) => ({
      addMappingSettingKey: key,
      key: value,
      type: type,
      label: label ?? "",
      label2: label2 ?? "",
      parentLabel: parentLabel ?? "",
    }))
    .reduce<TAdditionalMappings>((prev, curr) => ({ ...prev, [curr.type ?? ""]: curr }), {});

const getLineItemAmountsComputed = ({
  qty,
  rate = "1",
  amount,
}: Pick<IVBillLineItemSchema, "qty" | "amount" | "rate">) => {
  const qtyDecimal = new CHDecimal(qty ?? "1");
  const qtyFixed = qtyDecimal.decimalPlaces() === 0 ? qtyDecimal.toString() : qtyDecimal.toFixed(2);
  const rateDecimal = new CHDecimal(rate ?? "1");
  const rateFixed = rateDecimal.decimalPlaces() === 0 ? rateDecimal.toString() : rateDecimal.toFixed(2);
  const amountDecimal = new CHDecimal(amount ?? "1");
  const amountFixed = amountDecimal.decimalPlaces() === 0 ? amountDecimal.toString() : amountDecimal.toFixed(2);

  if (isNil(qty)) {
    const qtyDecimal = new CHDecimal(amountFixed).dividedBy(rateFixed);

    return {
      qty: qtyDecimal.decimalPlaces() === 0 ? qtyDecimal.toString() : qtyDecimal.toFixed(2),
      amount: amountFixed,
      rate: rateFixed,
    };
  }

  if (isNil(amount)) {
    const amountDecimal = new CHDecimal(qtyFixed).times(rateFixed);

    return {
      amount: amountDecimal.decimalPlaces() === 0 ? amountDecimal.toString() : amountDecimal.toFixed(2),
      rate: rateFixed,
      qty: qtyFixed,
    };
  }

  const defaultQtyDecimal = new CHDecimal(amountFixed).dividedBy(rateFixed);

  return {
    qty: defaultQtyDecimal.decimalPlaces() === 0 ? defaultQtyDecimal.toString() : defaultQtyDecimal.toFixed(2),
    amount: amountFixed,
    rate: rateFixed,
  };
};

const getLineItemAmountsDefault = ({ qty, rate, amount }: Pick<IVBillLineItemSchema, "qty" | "amount" | "rate">) => {
  const amountDecimal = new CHDecimal(amount ?? "0");

  return {
    qty: qty ?? "1",
    rate: rate ?? "0",
    amount: amountDecimal.decimalPlaces() === 0 ? amountDecimal.toString() : amountDecimal.toFixed(2),
  };
};

export const lineItemToFormLineFields = (lineItem: IVBillLineItemSchema): TVBillFormLineFields => ({
  description: !isNil(lineItem.description) ? lineItem.description : "",
  lineItemId: !isNil(lineItem.id) ? `${lineItem.id}` : "",
  ...(ENABLE_COMPUTED_LINE_ITEM_AMOUNTS ? getLineItemAmountsComputed(lineItem) : getLineItemAmountsDefault(lineItem)),
});

export const lineItemMetaToFormLineFields = (lineItem: IVBillInvoiceLineItemMetaSchema): TVBillFormLineFields => ({
  description: !isNil(lineItem.description?.value) ? `${lineItem.description?.value}` : "",
  lineItemId: "",
  ...(ENABLE_COMPUTED_LINE_ITEM_AMOUNTS
    ? getLineItemAmountsComputed({
        amount: !isNil(lineItem.amount?.value) ? `${lineItem.amount.value}` : "1",
        qty: !isNil(lineItem.qty?.value) ? `${lineItem.qty.value}` : null,
        rate: !isNil(lineItem.rate?.value) ? `${lineItem.rate.value}` : null,
      })
    : getLineItemAmountsDefault({
        amount: !isNil(lineItem.amount?.value) ? `${lineItem.amount.value}` : "1",
        qty: !isNil(lineItem.qty?.value) ? `${lineItem.qty.value}` : "1",
        rate: !isNil(lineItem.rate?.value) ? `${lineItem.rate.value}` : "1",
      })),
});

export const emptyOCRFormLineFields: TVBillFormLineFields = {
  description: "",
  qty: "1",
  rate: "0",
  amount: "",
  lineItemId: "",
};

export const getVBillFormPrimaryValuesByFileId = (
  invoice?: IVBillVBillInvoiceQuery["invoice"],
  selectedFileId?: number,
  hasLineItems: boolean = false,
): TVBillFormFields | undefined => {
  if (!invoice) {
    return;
  }

  const selectedFile = invoice.files?.find(({ id }) => id === selectedFileId);
  const selectedFileMetadata = selectedFile?.metadata?.GDAI;

  return {
    amount: !isNil(selectedFileMetadata?.amount) ? `${selectedFileMetadata?.amount}` : "",
    invoiceDate: selectedFileMetadata?.invoiceDate ? normalizeIsoDateOnMidnight(selectedFileMetadata.invoiceDate) : "",
    invoiceDateDue: selectedFileMetadata?.invoiceDateDue
      ? normalizeIsoDateOnMidnight(selectedFileMetadata.invoiceDateDue)
      : "",
    invoiceNumber: selectedFileMetadata?.invoiceNumber ?? "",
    senderName: selectedFileMetadata?.senderName ?? "",
    senderEmail: selectedFileMetadata?.senderEmail ?? "",
    senderAddress: selectedFileMetadata?.senderAddress ?? "",
    senderPhone: selectedFileMetadata?.senderPhone ?? "",
    mainFile: selectedFile ? { text: selectedFile.name ?? "", value: selectedFile.id } : null,
    hasLineItems,
    lineItems: [],
    companyId: invoice?.companyId ?? "",
  };
};

export const getVBillFormPrimaryValuesByInvoice = (
  invoice?: IVBillVBillInvoiceQuery["invoice"],
): TVBillFormFields | undefined => {
  if (!invoice) {
    return;
  }

  return {
    amount: !isNil(invoice?.amount) ? `${invoice?.amount}` : "",
    invoiceDate: invoice?.invoiceDate ? normalizeIsoDateOnMidnight(invoice.invoiceDate) : "",
    invoiceDateDue: invoice?.invoiceDateDue ? normalizeIsoDateOnMidnight(invoice.invoiceDateDue) : "",
    invoiceNumber: invoice?.invoiceNumber ?? "",
    senderName: invoice?.senderName ?? "",
    senderEmail: invoice?.senderEmail ?? "",
    senderAddress: invoice?.senderAddress ?? "",
    senderPhone: invoice?.senderPhone ?? "",
    mainFile: invoice.mainFile ? { text: invoice.mainFile.name ?? "", value: invoice.mainFile.id } : null,
    hasLineItems: invoice.hasLineItems,
    lineItems: [],
    companyId: invoice?.companyId ?? "",
  };
};

export const invoiceDateMoreThenFieldErrorMsg = "Bill Due should be more then Bill Date.";
