import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import WarningIcon from "@mui/icons-material/Warning";
import {
  Box,
  Button,
  Checkbox,
  Drawer,
  IconButton,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import { useCheckFilters } from "common/hooks/useCheckFilters";
import { CheckBatchItemType, CheckDetailsScopeEnum, CheckFiltersType, ReactionEnumType } from "generated/sdk";
import { debounce, isEqual } from "lodash";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useState } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { useParams } from "react-router";
import { useDeepCompareEffect } from "react-use";
import { useStore } from "storeContainer";
import { CheckBatchItemDetails } from "./CheckBatchItemDetails";
import { CheckBatchItemTableRow } from "./CheckBatchItemTableRow";
import { TablePaginationActions } from "./CheckTable";
import { useBatchDetailsContext } from "./useBatchDetailsContext";
import { DEFAULT_PAGINATION_PER_PAGE_FOR_CHECKS_IN_BATCH } from "./utils";

export const BudgetManagementDetailsAddedChecksTable = observer(function BudgetManagementDetailsAddedChecksTable({
  isDragging = false,
}: {
  isDragging?: boolean;
}) {
  const BudgetManagementStore = useStore("BudgetManagementStore");
  const {
    fetchCheckBatch,
    fetchAvailableChecksForBatchQuery,
    deleteCheckBatchItems,
    checkBatchData,
    updateCheckBatchItemReaction,
    fetchRawCheckBatch,
    fetchAvailableBankAccountsForBatchQuery,
  } = BudgetManagementStore;

  const {
    props: { isLoading, isBatchClosed },
  } = useBatchDetailsContext();

  const { batchId } = useParams<{ organizationId: string; batchId: string }>();
  const { filters, setFilters } = useCheckFilters<CheckFiltersType>();
  const [batchQuery, setBatchQuery] = useState(checkBatchData.data?.GetCheckBatchQuery);
  const [selectAllItems, setSelectAllItems] = useState(false);
  const [indeterminateItems, setIndeterminateItems] = useState(false);
  const [selectedItems, setSelectedItems] = useState<Record<string, boolean>>({});
  const [selectedItemsIds, setSelectedItemsIds] = useState<string[]>();
  // const [selectedItemsAmount, setSelectedItemsAmount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [openCheckDetails, setOpenCheckDetails] = useState(false);
  const itemsCount = Object.values(selectedItems).length;
  const countReactions = (id: string) => {
    const item = batchQuery?.items?.rows?.find((item) => item?.id === id);
    const reactions = item?.reactions;
    let likeCount = 0;
    let dislikeCount = 0;
    let warnCount = 0;

    reactions?.forEach((reaction) => {
      switch (reaction?.reaction) {
        case ReactionEnumType.Upvote:
          likeCount++;
          break;
        case ReactionEnumType.Downvote:
          dislikeCount++;
          break;
        case ReactionEnumType.Warning:
          warnCount++;
          break;
        default:
          break;
      }
    });

    return { likeCount, dislikeCount, warnCount };
  };
  const fetchData = async (filters: CheckFiltersType) => {
    await fetchCheckBatch(batchId, filters, { per_page: rowsPerPage, page: page + 1 }, true);
    setBatchQuery(checkBatchData.data?.GetCheckBatchQuery);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetchData = useCallback(
    debounce((newValue: CheckFiltersType) => fetchData(newValue), 300),
    [],
  );
  const handleSelectAllItemsClick = () => {
    if (selectAllItems) {
      setSelectedItems({});
    } else {
      const newSelectedItems: Record<string, any> = {};
      batchQuery?.items?.rows?.forEach((item) => (newSelectedItems[item?.check?.id as string] = true));
      setSelectedItems(newSelectedItems);
    }
  };
  const handleCheckItemClick = (checkId: string) => {
    setSelectedItems((prevSelectedItems) => {
      if (Object.keys(prevSelectedItems)?.length === 1 && prevSelectedItems[checkId]) {
        setIndeterminateItems(false);
        return {};
      }
      return { ...prevSelectedItems, [checkId]: !prevSelectedItems[checkId] };
    });
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    fetchCheckBatch(batchId, filters, { page: newPage + 1, per_page: rowsPerPage }, true);
  };
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    fetchCheckBatch(batchId, filters, { page: 1, per_page: parseInt(event.target.value, 10) }, true);
  };
  const handleReactionClick = async (check_batch_item_ids: string[], reaction: ReactionEnumType) => {
    await updateCheckBatchItemReaction(check_batch_item_ids, reaction);
    await fetchCheckBatch(batchId, filters, { page: page + 1, per_page: rowsPerPage }, true);
  };
  const handleUpdateReactionMultipleItemsFromBatch = async (reaction: ReactionEnumType) => {
    if (!selectedItemsIds?.length) {
      return;
    }
    await updateCheckBatchItemReaction(selectedItemsIds, reaction, true);
    await fetchCheckBatch(
      batchId,
      filters,
      { page: page, per_page: DEFAULT_PAGINATION_PER_PAGE_FOR_CHECKS_IN_BATCH },
      true,
    );
  };
  const handleRemoveMultipleItemsFromBatch = async () => {
    await deleteCheckBatchItems(batchId, Object.keys(selectedItems), true);
    await fetchCheckBatch(
      batchId,
      filters,
      { page: 1, per_page: DEFAULT_PAGINATION_PER_PAGE_FOR_CHECKS_IN_BATCH },
      true,
    );
    await fetchAvailableChecksForBatchQuery(
      batchId,
      { page: 1, per_page: DEFAULT_PAGINATION_PER_PAGE_FOR_CHECKS_IN_BATCH },
      undefined,
      true,
    );
    setSelectAllItems(false);
    setIndeterminateItems(false);
    setSelectedItems({});
  };
  const handleSorting = (name: string) => {
    const isAsc = orderBy === name && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(name);
    const sorting = `${name}_sort`;
    const prevFilters = {
      ...filters,
      date_sort: undefined,
      number_sort: undefined,
      amount_sort: undefined,
    };
    setFilters({ ...prevFilters, [sorting]: isAsc ? "desc" : "asc" });
  };

  const selectedChecksLength = Object.values(selectedItems)?.filter((item) => item);

  useEffect(() => {
    if (checkBatchData.data?.GetCheckBatchQuery) {
      setBatchQuery(checkBatchData.data?.GetCheckBatchQuery);
    }
  }, [checkBatchData.data?.GetCheckBatchQuery]);
  useEffect(() => {
    const newSelectedItemsIds = (batchQuery?.items?.rows ?? [])
      .filter((row): row is CheckBatchItemType => {
        const checkRowId = row?.check?.id as string;

        return selectedItems[checkRowId] === true;
      })
      .map((row) => row?.id ?? "");

    setSelectedItemsIds(newSelectedItemsIds);
  }, [selectedItems, batchQuery?.items?.rows]);
  useEffect(() => {
    const allItemsSelected =
      Object.values(selectedItems).every((checked) => checked) &&
      batchQuery?.items?.rows?.length === Object.values(selectedItems)?.length &&
      Object.values(selectedItems)?.length > 0;
    const someItemsSelected = Object.values(selectedItems).some((checked) => checked);

    setSelectAllItems(allItemsSelected);
    if (Object.values(selectedItems)?.length > 0) {
      setIndeterminateItems(!allItemsSelected && someItemsSelected);
    }
  }, [batchQuery?.items?.rows?.length, selectedItems]);
  useDeepCompareEffect(() => {
    debouncedFetchData(filters);

    return () => {
      debouncedFetchData.cancel();
    };
  }, [debouncedFetchData, filters]);

  useEffect(() => {
    fetchAvailableBankAccountsForBatchQuery(batchId, undefined, "");
  }, [batchId, fetchAvailableBankAccountsForBatchQuery]);

  useEffect(() => {
    const intervalId = setInterval(async () => {
      const newBatchQuery = await fetchRawCheckBatch(batchId, filters, { page: page, per_page: rowsPerPage });
      if (!isEqual(newBatchQuery, batchQuery)) {
        setBatchQuery(newBatchQuery);
      }
    }, 5000);
    return () => {
      clearInterval(intervalId);
    };
  }, [batchId, batchQuery, fetchRawCheckBatch, filters, page, rowsPerPage]);

  return (
    <>
      <Box marginTop="20px" display="flex" flexDirection="column" gap="20px">
        <TableContainer sx={{ maxHeight: "400px", height: "calc(100% - 52px)", overflow: "auto" }}>
          <Droppable droppableId="droppableTable2" isDropDisabled={isBatchClosed}>
            {(provided, snapshot) => (
              <Table
                {...provided.droppableProps}
                ref={provided.innerRef}
                sx={{
                  ...(isDragging && {
                    border: "10px dashed #E8F4FD",
                  }),
                }}
                stickyHeader
              >
                <TableHead>
                  <TableRow
                    sx={{
                      "&:hover": {
                        background: "white",
                      },
                      ".MuiTableCell-stickyHeader": {
                        backgroundColor: "white",
                      },
                    }}
                  >
                    <TableCell variant="head" title="Select all checks ">
                      <Checkbox
                        checked={selectAllItems}
                        indeterminate={indeterminateItems}
                        onChange={handleSelectAllItemsClick}
                        disabled={isBatchClosed}
                      />
                    </TableCell>
                    <TableCell variant="head">
                      <TableSortLabel
                        active={orderBy === "number"}
                        direction={orderBy === "number" ? order : "asc"}
                        IconComponent={KeyboardArrowDownIcon}
                        onClick={() => handleSorting("number")}
                      >
                        Check #
                      </TableSortLabel>
                    </TableCell>
                    <TableCell variant="head">
                      <TableSortLabel
                        active={orderBy === "date"}
                        direction={orderBy === "date" ? order : "asc"}
                        IconComponent={KeyboardArrowDownIcon}
                        onClick={() => handleSorting("date")}
                      >
                        Date
                      </TableSortLabel>
                    </TableCell>
                    <TableCell variant="head">From</TableCell>
                    <TableCell variant="head">To</TableCell>
                    <TableCell variant="head">
                      <TableSortLabel
                        active={orderBy === "amount"}
                        direction={orderBy === "amount" ? order : "asc"}
                        IconComponent={KeyboardArrowDownIcon}
                        onClick={() => handleSorting("amount")}
                      >
                        Amount
                      </TableSortLabel>
                    </TableCell>
                    <TableCell variant="head">Status</TableCell>
                  </TableRow>
                  {selectedChecksLength?.length > 0 && (
                    <TableRow>
                      <TableCell colSpan={7}>
                        <Box display="flex" alignItems="center" gap="15px" justifyContent="center">
                          <Typography variant="body2" textTransform="capitalize">
                            You have selected {selectedChecksLength?.length} check(s)
                          </Typography>
                          <Button
                            variant="contained"
                            endIcon={<DeleteIcon />}
                            onClick={handleRemoveMultipleItemsFromBatch}
                            disabled={!itemsCount}
                          >
                            Remove
                          </Button>
                          <IconButton
                            onClick={() => handleUpdateReactionMultipleItemsFromBatch(ReactionEnumType.Upvote)}
                          >
                            <ThumbUpIcon />
                          </IconButton>
                          <IconButton
                            onClick={() => handleUpdateReactionMultipleItemsFromBatch(ReactionEnumType.Downvote)}
                          >
                            <ThumbDownIcon />
                          </IconButton>
                          <IconButton
                            onClick={() => handleUpdateReactionMultipleItemsFromBatch(ReactionEnumType.Warning)}
                          >
                            <WarningIcon />
                          </IconButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                  )}
                </TableHead>
                <TableBody>
                  {isLoading ? (
                    <TableRow>
                      <TableCell colSpan={7}>
                        <Skeleton variant="rectangular" height="300px" />
                      </TableCell>
                    </TableRow>
                  ) : batchQuery?.items?.rows?.length! > 0 ? (
                    batchQuery?.items?.rows?.map((item, index) => {
                      const handleRowClick = async () => {
                        if (isBatchClosed) {
                          return;
                        }
                        await BudgetManagementStore.fetchCheckDetails(
                          batchId,
                          item?.check?.id as string,
                          CheckDetailsScopeEnum.InBatch,
                          true,
                        );
                        setOpenCheckDetails(true);
                      };
                      const handleCheckBoxCellClick = (e: React.MouseEvent<HTMLTableCellElement, MouseEvent>) => {
                        if (isBatchClosed) {
                          return;
                        }
                        e.preventDefault();
                        e.stopPropagation();
                        handleCheckItemClick(item?.check?.id as string);
                      };
                      return (
                        <Draggable
                          key={item?.check?.id}
                          draggableId={item?.check?.id!}
                          index={index}
                          isDragDisabled={isBatchClosed}
                        >
                          {(provided, snapshot) => (
                            <CheckBatchItemTableRow
                              key={item?.id}
                              handleCheckBoxCellClick={handleCheckBoxCellClick}
                              item={item!}
                              handleReactionClick={handleReactionClick}
                              handleRowClick={handleRowClick}
                              selectedItems={selectedItems}
                              countReactions={countReactions}
                              index={index}
                              provided={provided}
                              snapshot={snapshot}
                              totalAmount={batchQuery?.items_amount as number}
                              budget={batchQuery?.budget as number}
                            />
                          )}
                        </Draggable>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={8}>
                        <Typography>No checks added to this batch</Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
                <TableFooter>{provided.placeholder}</TableFooter>
              </Table>
            )}
          </Droppable>
          <Box position="sticky" bottom="0" sx={{ background: "white" }}>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              count={batchQuery?.items?.pagination?.total ?? 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={(actionProps) => (
                <TablePaginationActions {...actionProps} description="Checks in batch" />
              )}
              component="div"
            />
          </Box>
        </TableContainer>

        <Drawer open={openCheckDetails} anchor="right" onClose={() => setOpenCheckDetails(false)}>
          <CheckBatchItemDetails />
        </Drawer>
      </Box>
    </>
  );
});
