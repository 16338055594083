import { Box, Button, TableCell, TableRow, Tooltip } from "@mui/material";
import { formatCurrency } from "common/helpers/utils";
import { useVBillFormattedDate } from "components/pages/common/VBill/hooks";
import { PaymentDetailsDrawer } from "components/pages/common/VBill/PaymentDetails/PaymentDetailsDrawer";
import {
  friendlyPaymentIntegrationStatusNames,
  friendlyPaymentPushStatusNames,
  getPaymentIntegrationStatusStyleColor,
  getPaymentPushStatusStyleColor,
} from "components/pages/common/VBill/utils";
import { IVBillPaymentPushStatus, IVBillVBillPaymentsQuery } from "generated/sdk.vbill";
import { observer } from "mobx-react";
import { CSSProperties, useMemo, useState } from "react";
import { useStore } from "storeContainer";
import { COLORS } from "themes/default";
import { useVBillPaymentsLedgerChildRoute } from "../hooks";

const defaultTableCellSx = {
  padding: "0",
};
const defaultColBoxStyles: CSSProperties = {
  padding: "5px 16px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  fontSize: "14px",
  fontWeight: "500",
};

interface IPaymentProps {
  payment: IVBillVBillPaymentsQuery["payments"]["items"][number];
}

export const Payment = observer(function Payment({ payment }: IPaymentProps) {
  const organizationStore = useStore("OrganizationStore");
  const { isAllChildRoute, isProcessingChildRoute, isCompletedChildRoute, isVoidedChildRoute } =
    useVBillPaymentsLedgerChildRoute();
  const [billPaymentDrawerVisible, setBillPaymentDrawerVisible] = useState(false);
  // const [vCheckDialogVisible, setVCheckDialogVisible] = useState(false);

  const {
    paymentId,
    companyId,
    paymentAmount,
    paymentDate,
    paymentToVendorAccount,
    paymentPushStatus,
    paymentIntegrationStatus,
    paymentFromGlAccount,
    // submittedByUserAccount,
    // vCheckId,
    // vCheckNumber,
  } = payment;

  const integrationStatus = useMemo(
    () => friendlyPaymentIntegrationStatusNames[paymentIntegrationStatus],
    [paymentIntegrationStatus],
  );
  const integrationStatusColor = useMemo(
    () => getPaymentIntegrationStatusStyleColor(paymentIntegrationStatus),
    [paymentIntegrationStatus],
  );
  const pushStatus = useMemo(() => friendlyPaymentPushStatusNames[paymentPushStatus], [paymentPushStatus]);
  const pushStatusColor = useMemo(() => getPaymentPushStatusStyleColor(paymentPushStatus), [paymentPushStatus]);
  const formattedPaymentAmount = useMemo(() => formatCurrency(Number(paymentAmount)), [paymentAmount]);
  const formattedPaymentDate = useVBillFormattedDate(paymentDate);
  const allCompanies = useMemo(
    () =>
      (organizationStore.organizationVBillEnabledCompanies.data?.items ?? []).map(({ name, companyId }) => ({
        name,
        id: companyId,
      })),
    [organizationStore.organizationVBillEnabledCompanies.data?.items],
  );
  const companyName = useMemo(() => allCompanies.find(({ id }) => id === companyId)?.name, [companyId, allCompanies]);

  return (
    <>
      <TableRow sx={{ cursor: "default" }}>
        <TableCell sx={defaultTableCellSx}>
          <Box sx={{ ...defaultColBoxStyles, color: COLORS.newSecondaryColor }}>#{paymentId}</Box>
        </TableCell>

        {/* <TableCell sx={defaultTableCellSx}>
          <Box sx={{ ...defaultColBoxStyles, whiteSpace: "nowrap" }}>
            {vCheckId || vCheckNumber ? (
              <Tooltip title={vCheckId ? `See vCheck details` : undefined} arrow>
                <Button
                  sx={{
                    justifyContent: "flex-start",
                    fontSize: "12px",
                    ...(!vCheckId ? { cursor: "default" } : {}),
                    ...(vCheckId ? { "&:hover": { textDecoration: "underline" } } : {}),
                  }}
                  onClick={() => {
                    if (vCheckId) {
                      setVCheckDialogVisible(true);
                    }
                  }}
                >
                  id: {vCheckId ?? "-"}, nr: {vCheckNumber ?? "-"}
                </Button>
              </Tooltip>
            ) : (
              "-"
            )}
          </Box>
        </TableCell> */}

        {(isAllChildRoute || isProcessingChildRoute || isCompletedChildRoute || isVoidedChildRoute) && (
          <TableCell sx={defaultTableCellSx}>
            <Box sx={defaultColBoxStyles}>
              {paymentPushStatus === IVBillPaymentPushStatus.SubmittedOk ? (
                <Box component="span" sx={{ color: integrationStatusColor }}>
                  {integrationStatus}
                </Box>
              ) : (
                <Box component="span" sx={{ color: pushStatusColor }}>
                  {pushStatus}
                </Box>
              )}
            </Box>
          </TableCell>
        )}

        <TableCell sx={defaultTableCellSx}>
          <Box sx={defaultColBoxStyles}>{companyName}</Box>
        </TableCell>

        <TableCell sx={defaultTableCellSx}>
          <Box sx={{ ...defaultColBoxStyles, textAlign: "right", whiteSpace: "nowrap" }}>{formattedPaymentAmount}</Box>
        </TableCell>

        <TableCell sx={defaultTableCellSx}>
          <Box sx={{ ...defaultColBoxStyles, whiteSpace: "nowrap" }}>{formattedPaymentDate}</Box>
        </TableCell>

        <TableCell sx={defaultTableCellSx}>
          <Box
            sx={{
              ...defaultColBoxStyles,
              maxWidth: "200px",
              wordBreak: "break-word",
              fontSize: "12px",
            }}
          >
            {paymentToVendorAccount
              ? `${paymentToVendorAccount.label ?? ""} ${
                  paymentToVendorAccount.label2 ? `- ${paymentToVendorAccount.label2}` : ""
                }`
              : "-"}
          </Box>
        </TableCell>

        <TableCell sx={defaultTableCellSx}>
          <Box
            sx={{
              ...defaultColBoxStyles,
              maxWidth: "200px",
              wordBreak: "break-word",
              fontSize: "12px",
            }}
          >
            {paymentFromGlAccount
              ? `${paymentFromGlAccount.label ?? ""} ${
                  paymentFromGlAccount.label2 ? `- ${paymentFromGlAccount.label2}` : ""
                }`
              : "-"}
          </Box>
        </TableCell>

        <TableCell sx={defaultTableCellSx}>
          <Tooltip title="Open payment details" arrow>
            <Button
              sx={{
                fontSize: "14px",
                "&:hover": { textDecoration: "underline" },
                justifyContent: "flex-start",
                color: COLORS.newSecondaryColor,
                fontWeight: "500",
              }}
              onClick={() => setBillPaymentDrawerVisible(true)}
            >
              Details
            </Button>
          </Tooltip>
        </TableCell>
      </TableRow>

      {billPaymentDrawerVisible && (
        <PaymentDetailsDrawer
          drawerAnchor="right"
          paymentId={paymentId}
          onDrawerClose={() => setBillPaymentDrawerVisible(false)}
        />
      )}

      {/* {vCheckDialogVisible && (
        <PaymentVCheckDialog vCheckId={vCheckId} onCloseDialog={() => setVCheckDialogVisible(false)} />
      )} */}
    </>
  );
});
