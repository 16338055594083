import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { LedgerCheck } from "generated/sdk";
import { FunctionComponent, useState } from "react";
import { useStore } from "storeContainer";
import ReviewAndConfirm from "../ReviewAndConfirm";

const CheckAction: FunctionComponent<{
  check: LedgerCheck;
  setDeselected?: Function;
  selectedRowIds: string[];
  setSelectedRowIds: Function;
  embedded?: boolean;
  reload: Function;
  handleAction?: (check: NonNullable<LedgerCheck>, action?: any, option?: boolean) => void;
}> = ({ check, embedded, reload, setDeselected, setSelectedRowIds, selectedRowIds, handleAction }) => {
  const [openReviewAndConfirm, setOpenReviewAndConfirm] = useState<boolean>(false);
  const UndepositedChecksStore = useStore("UndepositedChecksStore");
  const onClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    if (check.action?.action === "deposit" && handleAction) {
      handleAction(check, check.action?.action || "");
    } else {
      setOpenReviewAndConfirm(true);
    }
  };

  const handleCloseReviewAndConfirm = (event: React.ChangeEvent<HTMLInputElement>) => {
    event?.stopPropagation();
    if (check.action?.action === "deposit") {
      UndepositedChecksStore.getUndepositedChecks();
    } else {
      reload();
    }
    setOpenReviewAndConfirm(false);
  };
  return (
    <>
      <Button
        color="secondary"
        variant="outlined"
        disabled={check.action?.disabled!}
        onClick={onClick}
        size="small"
        sx={{
          textTransform: "capitalize",
          border: "1px solid rgba(40, 119, 236, 0.25) !important",
          background: "#FFF",
          color: "#2877EC",
          fontWeight: "500",
        }}
        fullWidth
      >
        {check.action?.label}
      </Button>
      {!embedded && (
        <Modal
          style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          open={openReviewAndConfirm}
          onClose={handleCloseReviewAndConfirm}
        >
          <Box>
            <ReviewAndConfirm
              checksIds={[`${check.id}-${check.role}`]}
              checks={[check]}
              actionInfo={{ action: check.action?.action, label: check.action?.label }}
              setDeselected={setDeselected}
              selectedRowIds={selectedRowIds}
              close={handleCloseReviewAndConfirm}
            />
          </Box>
        </Modal>
      )}
    </>
  );
};

export default CheckAction;
