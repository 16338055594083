import { IVBillBatchStatus } from "generated/sdk.vbill";
import { vBillBatchLedgerChildRoute } from "./hooks";

export enum VBillBatchLedgerChildRoutes {
  ALL = "",
  OPEN = "open",
  CLOSED = "closed",
  DELETED = "deleted",
}

export function getVBillBatchLedgerStatusByChildRoute(childRoute: vBillBatchLedgerChildRoute) {
  const { isAllChildRoute, isOpenChildRoute, isClosedChildRoute, isDeletedChildRoute } = childRoute;

  if (isAllChildRoute) {
    return undefined;
  }

  if (isOpenChildRoute) {
    return IVBillBatchStatus.Open;
  }

  if (isClosedChildRoute) {
    return IVBillBatchStatus.Closed;
  }

  if (isDeletedChildRoute) {
    return IVBillBatchStatus.Deleted;
  }
}
