import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { IconButton, Tooltip } from "@mui/material";
import { observer } from "mobx-react";
import { useChatChannelStore } from "modules/Chat/hooks/hooks";
import { FunctionComponent, useState } from "react";
import { COLORS } from "themes/default";
import { ChatMarkPrivate } from "./ChatMarkPrivate/ChatMarkPrivate";

export const ToolbarMarkPrivate: FunctionComponent = observer(() => {
  const chatChannelStore = useChatChannelStore();
  const [isMakePrivateDialogOpen, setIsMakePrivateDialogOpen] = useState(false);

  const isChannelPrivate = Boolean(chatChannelStore?.channel?.isPrivate);

  return (
    <>
      <Tooltip
        title={isChannelPrivate ? `This channel is private` : `This channel is public. Click to make it private`}
      >
        <IconButton
          sx={{ cursor: isChannelPrivate ? "default" : "pointer" }}
          onClick={() => (isChannelPrivate ? undefined : setIsMakePrivateDialogOpen(true))}
        >
          {isChannelPrivate ? (
            <LockIcon sx={{ color: COLORS.logoBlue, fontSize: "24px" }} />
          ) : (
            <LockOpenIcon sx={{ color: COLORS.logoBlue, fontSize: "24px" }} />
          )}
        </IconButton>
      </Tooltip>

      {isMakePrivateDialogOpen && <ChatMarkPrivate onCloseDialog={() => setIsMakePrivateDialogOpen(false)} />}
    </>
  );
});
