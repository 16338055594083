import { Box, Button, Divider, Typography } from "@mui/material";
import { graphqlChatClient, graphqlVBillClient } from "common/graphqlClient";
import { getSdk as chatGetSdk, IChatChannelParentType } from "generated/sdk.chat";
import { getSdk as vBillGetSdk } from "generated/sdk.vbill";
import { sortBy } from "lodash";
import { observer } from "mobx-react";
import { getChatChannelStore } from "modules/Chat";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useStore } from "storeContainer";
import { COLORS } from "themes/default";
import { BatchMembersDialog } from "../../common/BatchMembersDialog/BatchMembersDialog";
import { LightTooltip } from "../../common/LightTooltip/LightTooltip";
import { blueBtnStyles, tooltipDefaultProps, tooltipTypographyStyles, typographyAsBtnStyles } from "../summariesStyles";

const { VBillAddMembersToBatch, VBillRemoveMembersFromBatch } = vBillGetSdk(graphqlVBillClient);
const { ChatAddMembers, ChatRemoveMembers, ChatGetMembers } = chatGetSdk(graphqlChatClient);

export const SummariesMembers = observer(function SummariesMembers() {
  const vBillBatchDetailsStore = useStore("VBillBatchDetailsStore");
  const orgUserStore = useStore("OrganizationUsersStore");
  const sessionStore = useStore("SessionStore");
  const { batchId } = useParams<{ batchId: string }>();

  useEffect(() => {
    vBillBatchDetailsStore.getBatchMembers({ batchId: Number(batchId) });
  }, [vBillBatchDetailsStore, batchId]);

  const { batchDetails, batchMembers } = vBillBatchDetailsStore;
  const batchData = batchDetails.data;
  const { organizationUsersList } = orgUserStore;
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const memberNames = useMemo(
    () =>
      sortBy(
        (batchMembers.data?.items ?? []).reduce<string[]>((result, member) => {
          if (
            (organizationUsersList.data ?? []).find(({ id }) => id === member.coreOrgUserId)?.account?.id !==
            sessionStore.session.data?.account?.id
          ) {
            result = [
              ...result,
              (organizationUsersList.data ?? []).find(({ id }) => id === member.coreOrgUserId)?.account?.name ?? "",
            ];
          }

          return result;
        }, []),
        (name) => name,
      ),
    [batchMembers.data?.items, organizationUsersList.data, sessionStore.session.data?.account?.id],
  );
  const totalBatchMembers = useMemo(
    () =>
      (batchMembers.data?.items ?? []).filter(
        (member) =>
          (organizationUsersList.data ?? []).find(({ id }) => id === member.coreOrgUserId)?.account?.id !==
          sessionStore.session.data?.account?.id,
      ).length,
    [batchMembers.data?.items, organizationUsersList.data, sessionStore.session.data?.account?.id],
  );
  const batchMemberIds = useMemo(
    () =>
      (batchMembers.data?.items ?? [])
        .filter(
          ({ coreOrgUserId }) =>
            coreOrgUserId !==
            (organizationUsersList.data ?? []).find(
              ({ account }) => account?.id === sessionStore.session.data?.account?.id,
            )?.id,
        )
        .map(({ coreOrgUserId }) => coreOrgUserId),
    [batchMembers.data?.items, organizationUsersList.data, sessionStore.session.data?.account?.id],
  );

  const handleDialogSaveMembers = async (selectedMemberIds: string[]) => {
    if (!batchData) {
      return;
    }

    const addBatchMembersId = selectedMemberIds.filter(
      (selectedMemberId) => !batchMemberIds.find((memberId) => memberId === selectedMemberId),
    );
    const removeBatchMembersId = batchMemberIds.filter(
      (memberId) => !selectedMemberIds.find((selectedMemberId) => selectedMemberId === memberId),
    );
    const chatChannelIoData = {
      parentContext: "sending",
      parentKey: batchId,
      parentType: IChatChannelParentType.VbillBatch,
    };

    if (addBatchMembersId.length) {
      const chatAddMembersData = (organizationUsersList.data ?? [])
        .filter(({ id }) => addBatchMembersId.includes(id))
        .map(({ id, account }) => ({
          accountId: account?.id,
          email: account?.email,
          name: account?.name ?? "",
          organizationUserId: id,
        }));
      await VBillAddMembersToBatch({ batchId: batchData.id, memberIds: addBatchMembersId });
      await ChatAddMembers({ channelIo: chatChannelIoData, membersIo: chatAddMembersData });
    }

    if (removeBatchMembersId.length) {
      await VBillRemoveMembersFromBatch({ batchId: batchData.id, memberIds: removeBatchMembersId });
      const chatGetMembersResp = await ChatGetMembers({ channelIo: chatChannelIoData });
      const chatRemoveMembersData = (chatGetMembersResp.getMembers?.data ?? [])
        .filter(({ organizationUserId }) => removeBatchMembersId.includes(`${organizationUserId ?? ""}`))
        .map(({ id }) => id ?? 0);
      await ChatRemoveMembers({ channelIo: chatChannelIoData, membersIds: chatRemoveMembersData });
    }

    if (addBatchMembersId.length || removeBatchMembersId.length) {
      await vBillBatchDetailsStore.getBatchMembers({ batchId: Number(batchId) }, true);

      // if chat channel is visible reload members and messages
      const chatChannel = getChatChannelStore(chatChannelIoData);

      if (chatChannel) {
        chatChannel.loadMembers();
        chatChannel.loadInitialMessages();
      }
    }

    setIsDialogOpen(false);
  };

  return (
    <>
      <LightTooltip
        open={isTooltipOpen}
        onOpen={() => setIsTooltipOpen(true)}
        onClose={() => setIsTooltipOpen(false)}
        {...tooltipDefaultProps}
        title={
          <Box>
            {memberNames.map((name) => (
              <Typography sx={tooltipTypographyStyles} key={name}>
                {name}
              </Typography>
            ))}
            {totalBatchMembers > 0 && <Divider sx={{ margin: "5px 0" }} />}
            <Button
              onClick={() => {
                setIsTooltipOpen(false);
                setIsDialogOpen(true);
              }}
              sx={{ ...blueBtnStyles, fontSize: "12px" }}
            >
              Add/Remove
            </Button>
          </Box>
        }
      >
        <Typography sx={typographyAsBtnStyles}>
          <Box sx={{ color: COLORS.logoBlue }}>Members:</Box> <strong>({totalBatchMembers})</strong>
        </Typography>
      </LightTooltip>

      {isDialogOpen && (
        <BatchMembersDialog
          suggestionsComponent="dialog"
          selectedMembers={batchMemberIds}
          onMembersSave={handleDialogSaveMembers}
          onDialogClose={() => setIsDialogOpen(false)}
          actionBtnsSmallHeight
        />
      )}
    </>
  );
});
