import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Box, ListItemText, Menu, MenuItem, Radio, TableCell, Tooltip } from "@mui/material";
import { vBillLedgerTHeadSx } from "components/pages/common/VBill/styles";
import { IVBillBillDateSortType } from "generated/sdk.vbill";
import { useMemo, useRef, useState } from "react";
import { COLORS } from "themes/default";
import { useCodingLedgerFilters } from "../../hooks";
import { CodingDateGroups, staticCodingDateLines } from "../../utils";

const baseBtnSx = {
  border: "none",
  background: "none",
  cursor: "pointer",
  padding: "0",
  "&:hover": { color: "#757575" },
};
const baseArrowSx = {
  color: "#757575",
  fontSize: "18px",
};

export enum SortDirections {
  "ASC",
  "DESC",
}

export const CodingHeaderDatesSorter = () => {
  const [codingLedgerFilters, setCodingLedgerFilters] = useCodingLedgerFilters();
  const tableCellAnchorRef = useRef<HTMLElement>(null);
  const datesSort = useMemo(() => codingLedgerFilters.filters?.dates_sort, [codingLedgerFilters.filters?.dates_sort]);
  const datesSortActive = useMemo(() => !!datesSort, [datesSort]);
  const selectedDatesSort = useMemo(() => {
    if (datesSort === IVBillBillDateSortType.CreateDateAsc || datesSort === IVBillBillDateSortType.CreateDateDesc) {
      return CodingDateGroups.CreateDate;
    }

    if (datesSort === IVBillBillDateSortType.BillDateAsc || datesSort === IVBillBillDateSortType.BillDateDesc) {
      return CodingDateGroups.BillDate;
    }

    if (datesSort === IVBillBillDateSortType.BillDueAsc || datesSort === IVBillBillDateSortType.BillDueDesc) {
      return CodingDateGroups.BillDue;
    }
  }, [datesSort]);
  const selectedDatesSortName = useMemo(
    () => staticCodingDateLines.find(({ group }) => group === selectedDatesSort)?.label,
    [selectedDatesSort],
  );
  const selectedDirectionSort = useMemo(() => {
    if (
      datesSort === IVBillBillDateSortType.CreateDateAsc ||
      datesSort === IVBillBillDateSortType.BillDateAsc ||
      datesSort === IVBillBillDateSortType.BillDueAsc
    ) {
      return SortDirections.ASC;
    }

    if (
      datesSort === IVBillBillDateSortType.CreateDateDesc ||
      datesSort === IVBillBillDateSortType.BillDateDesc ||
      datesSort === IVBillBillDateSortType.BillDueDesc
    ) {
      return SortDirections.DESC;
    }
  }, [datesSort]);

  const [menuVisible, setMenuVisible] = useState(false);

  const closeMenu = () => {
    setMenuVisible(false);
  };

  const handleMenuItemClick = (group: CodingDateGroups) => {
    setCodingLedgerFilters((prev) => ({
      filters: {
        ...prev.filters,
        invoice_number_sort: undefined,
        invoice_id_sort: undefined,
        amount_sort: undefined,
        dates_sort:
          group === CodingDateGroups.CreateDate
            ? IVBillBillDateSortType.CreateDateDesc
            : group === CodingDateGroups.BillDate
              ? IVBillBillDateSortType.BillDateDesc
              : group === CodingDateGroups.BillDue
                ? IVBillBillDateSortType.BillDueDesc
                : undefined,
      },
      pagination: { page: 1, per_page: prev.pagination.per_page },
    }));
    closeMenu();
  };

  const handleDirectionBtnClick = (direction: SortDirections) => {
    setCodingLedgerFilters((prev) => ({
      filters: {
        ...prev.filters,
        invoice_number_sort: undefined,
        invoice_id_sort: undefined,
        amount_sort: undefined,
        dates_sort:
          selectedDatesSort === CodingDateGroups.CreateDate
            ? direction === SortDirections.ASC
              ? IVBillBillDateSortType.CreateDateAsc
              : IVBillBillDateSortType.CreateDateDesc
            : selectedDatesSort === CodingDateGroups.BillDate
              ? direction === SortDirections.ASC
                ? IVBillBillDateSortType.BillDateAsc
                : IVBillBillDateSortType.BillDateDesc
              : selectedDatesSort === CodingDateGroups.BillDue
                ? direction === SortDirections.ASC
                  ? IVBillBillDateSortType.BillDueAsc
                  : IVBillBillDateSortType.BillDueDesc
                : undefined,
      },
      pagination: { page: 1, per_page: prev.pagination.per_page },
    }));
  };

  return (
    <>
      <TableCell sx={vBillLedgerTHeadSx} ref={tableCellAnchorRef}>
        <Box sx={{ display: "flex", gap: "8px" }}>
          <Box
            component="button"
            id="vbill-dates-menu-button"
            aria-controls={menuVisible ? "vbill-dates-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={menuVisible ? "true" : undefined}
            onClick={() => setMenuVisible(true)}
            sx={{
              ...baseBtnSx,
              fontFamily: "Inter",
              color: COLORS.newPrimaryColor,
              fontSize: "12px",
              fontWeight: "500",
              textTransform: "uppercase",
              ...(datesSortActive ? { textDecoration: "underline" } : {}),
            }}
          >
            {datesSortActive ? selectedDatesSortName : "DATES"}
          </Box>

          {datesSortActive && (
            <Tooltip
              arrow
              title={`Sort By ${selectedDatesSortName}: ${selectedDirectionSort === SortDirections.ASC ? "Desc" : "Asc"}`}
            >
              <Box
                component="button"
                onClick={() =>
                  handleDirectionBtnClick(
                    selectedDirectionSort === SortDirections.ASC ? SortDirections.DESC : SortDirections.ASC,
                  )
                }
                sx={baseBtnSx}
              >
                {selectedDirectionSort === SortDirections.ASC ? (
                  <ArrowDownwardIcon sx={baseArrowSx} />
                ) : (
                  <ArrowUpwardIcon sx={baseArrowSx} />
                )}
              </Box>
            </Tooltip>
          )}
        </Box>
      </TableCell>

      {menuVisible && (
        <Menu
          id="vbill-dates-menu"
          anchorEl={tableCellAnchorRef.current}
          open
          onClose={closeMenu}
          MenuListProps={{
            "aria-labelledby": "vbill-dates-menu-button",
          }}
        >
          {staticCodingDateLines.map(({ label, group }) => (
            <MenuItem
              key={group}
              onClick={() => handleMenuItemClick(group)}
              selected={selectedDatesSort === group}
              sx={{ padding: "12px 16px" }}
            >
              <Radio checked={selectedDatesSort === group} value={group} sx={{ marginRight: "10px", padding: "3px" }} />
              <ListItemText>{label}</ListItemText>
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  );
};
