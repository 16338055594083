import { Button, SxProps, Theme } from "@mui/material";
import { ExpandLessIcon, ExpandMoreIcon } from "components/common/icons";
import { useMemo, useState } from "react";
import { useCodingLedgerFilters } from "../../hooks";
import { DateFilterPopover } from "./DateFilterPopover/DateFilterPopover";

const expandIconStyles: SxProps<Theme> = { fontSize: "16px !important", marginRight: "4px" };

export const CodingDateFilter = () => {
  const [codingLedgerFilters] = useCodingLedgerFilters();
  const [popoverAnchorEl, setPopoverAnchorEl] = useState<null | HTMLElement>(null);

  const popoverVisible = Boolean(popoverAnchorEl);
  const popoverId = popoverVisible ? "date-filter" : undefined;
  const dateFilterSelected = useMemo(() => {
    const filters = codingLedgerFilters.filters;

    return (
      !!filters?.createDateFrom ||
      !!filters?.createDateTo ||
      !!filters?.invoiceDateDueFrom ||
      !!filters?.invoiceDateDueTo ||
      !!filters?.invoiceDateFrom ||
      !!filters?.invoiceDateTo
    );
  }, [codingLedgerFilters.filters]);

  return (
    <>
      <Button
        onClick={(event) => setPopoverAnchorEl(event.currentTarget)}
        aria-describedby={popoverId}
        variant={dateFilterSelected ? "containedFilters" : "outlined"}
        endIcon={popoverVisible ? <ExpandLessIcon sx={expandIconStyles} /> : <ExpandMoreIcon sx={expandIconStyles} />}
      >
        Date
      </Button>

      {popoverVisible && (
        <DateFilterPopover
          popoverId={popoverId}
          onClosePopover={() => setPopoverAnchorEl(null)}
          popoverAnchorEl={popoverAnchorEl}
        />
      )}
    </>
  );
};
