import { FunctionComponent } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useHistory } from "react-router-dom";
import { OrganizationSelector } from "components/pages/Settings/OrganizationCompanySelector/OrganizationSelector";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";

const CompanyHeader: FunctionComponent<{ organizationId: string | undefined; title: string }> = ({
  organizationId,
  title,
}) => {
  const history = useHistory();
  return (
    <Grid
      container
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="60px"
      maxWidth="1512px"
      paddingX="80px"
    >
      <Grid xs item>
        <Grid item xs={4}>
          <OrganizationSelector />
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Typography sx={{ flexGrow: 1, textAlign: "center", color: "white" }}>{title}</Typography>
      </Grid>
      <Grid item xs sx={{ display: "flex", justifyContent: "end" }}>
        <IconButton
          onClick={() => {
            history.push(`/org/${organizationId}`);
          }}
        >
          <CloseIcon sx={{ color: "#FFFFFF" }} />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default CompanyHeader;
