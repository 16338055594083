import { IVBillTextItemSchema } from "generated/sdk.vbill";
import { FunctionComponent, useMemo, useState } from "react";
import { ImageMenuWidget } from "./ImageMenuWidget/ImageMenuWidget";
import styles from "./imageTextItem.module.scss";

export interface IImageScale {
  xScale: number;
  yScale: number;
}

interface IImageTextItemProps {
  item: IVBillTextItemSchema;
  scale: IImageScale;
}

export const ImageTextItem: FunctionComponent<IImageTextItemProps> = ({ item: { bBox, text }, scale }) => {
  const [imageOcrMenuVisible, setImageOcrMenuVisible] = useState(false);
  const {
    top = 0,
    left = 0,
    height = 0,
    width = 0,
  } = useMemo(() => {
    const { xScale, yScale } = scale;
    if (!bBox) {
      return {};
    }
    const { x0, x1, y0, y1 } = bBox;

    return {
      top: +(y0 * yScale).toFixed(2),
      left: +(x0 * xScale).toFixed(2),
      height: +((y1 - y0) * yScale).toFixed(2),
      width: +((x1 - x0) * xScale).toFixed(2),
    };
  }, [scale, bBox]);

  if (!bBox) {
    return null;
  }

  return (
    <div
      onMouseEnter={() => setImageOcrMenuVisible(true)}
      onMouseLeave={() => setImageOcrMenuVisible(false)}
      style={{
        top: `${top}px`,
        left: `${left}px`,
        height: `${height}px`,
        width: `${width}px`,
      }}
      className={styles.wordBox}
    >
      {imageOcrMenuVisible && <ImageMenuWidget imageText={text ?? ""} />}
    </div>
  );
};
