import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { ExtendedCheckFiltersType1, useCheckFilters } from "common/hooks/useCheckFilters";
import { LedgerFilterInputType, getFiltersConfig } from "components/Filter/types";
import { ValueWithLabelType } from "generated/sdk";
import React, { useEffect, useState } from "react";
import { useParams, useRouteMatch } from "react-router";
import { VCheckChecksAllQuickFiltersEnum } from "../types";
import { clearFiltersBtnStyle } from "./ActiveFilters";

export const mapName = (name: "state" | "required_action" | "status") => {
  switch (name) {
    case "state":
      return "State";
    case "required_action":
      return "Required Action";
    case "status":
      return "Status";
  }
};
export const formatString = (inputString: string) => {
  const words = inputString.split("-");
  const formattedString = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(" ");
  return formattedString;
};

const StateOrActionOrStatusFilter: React.FC<{
  label: "state" | "required_action" | "status";
}> = ({ label }) => {
  const { filters, setFilters } = useCheckFilters<ExtendedCheckFiltersType1>();
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const { selectedQuickFilter } = useParams<{ selectedQuickFilter: VCheckChecksAllQuickFiltersEnum }>();
  const [selectedOptions, setSelectedOptions] = useState<(string | undefined | null)[]>([]);
  const { path } = useRouteMatch();
  const config = getFiltersConfig(path, selectedQuickFilter);

  const targetFilter = (config as any)?.[
    label === "state"
      ? LedgerFilterInputType.CheckStateSelectFilter
      : label === "status"
        ? LedgerFilterInputType.CheckStatusSelectFilter
        : LedgerFilterInputType.CheckActionRequiredSelectFilter
  ];
  const options = targetFilter?.options as {
    value: string;
    label: string;
  }[];
  const handleOpenMenu = (event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    if (selectedOptions.length || filters[label]?.length) {
      setFilters((prevFilters) => {
        const updatedFilters = { ...prevFilters, [label]: selectedOptions.length ? selectedOptions : undefined };

        return updatedFilters;
      });
    }
    setAnchorEl(null);
  };

  useEffect(() => {
    setSelectedOptions(filters?.[label] || []);
  }, [filters]);

  const handleOptionClick = (option: ValueWithLabelType | undefined | null) => {
    const currentOptions = selectedOptions?.includes(option?.value)
      ? selectedOptions.filter((value) => value !== option?.value)
      : [...selectedOptions, option?.value];

    setSelectedOptions(currentOptions);
  };

  const generateButtonContent = () => {
    const selectedOptions = filters?.[label];
    if (selectedOptions && selectedOptions.length === 1) {
      const selectedOption = options.find((item) => item?.value === selectedOptions[0]);
      return selectedOption ? truncateString(selectedOption.label) : mapName(label);
    } else if ((selectedOptions?.length as number) > 1) {
      return `${selectedOptions?.length as number} ${mapName(label)}${label[label.length - 1] === "s" ? "es" : "s"}`;
    } else {
      return mapName(label);
    }
  };
  const generateButtonTitle = () => {
    const selectedOptions = filters?.[label];
    if (selectedOptions && selectedOptions.length > 0) {
      const selectedOptionLabels = selectedOptions.map((optionValue) => {
        const selectedOption = options.find((item) => item?.value === optionValue);
        return selectedOption ? selectedOption.label : null;
      });
      const filteredLabels = selectedOptionLabels.filter((label) => label !== null);
      return filteredLabels.join(", ");
    } else {
      return mapName(label);
    }
  };

  const truncateString = (inputString: string) => {
    const words = inputString.split("_");
    const formattedString =
      words
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(" ")
        .slice(0, 23) + (inputString.length > 23 ? "..." : "");
    return formattedString;
  };
  const handleClearMenu = () => {
    if (selectedOptions.length) {
      setFilters((prevFilters) => ({ ...prevFilters, [label]: undefined }));
      setSelectedOptions([]);
    }
    // setAnchorEl(null);
  };
  return options?.length ? (
    <Box sx={{ maxWidth: "150px" }} display="flex" flexDirection="column">
      <Button
        title={generateButtonTitle()}
        onClick={handleOpenMenu}
        variant={filters?.[label] ? "containedFilters" : "outlined"}
        fullWidth
        sx={{ fontSize: "12px", width: "150px" }}
      >
        {generateButtonContent()}
      </Button>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu}>
        <FormControl component="fieldset">
          <FormGroup>
            {options?.map((option) => (
              <MenuItem
                sx={{ padding: 1, paddingLeft: 2.5, minWidth: 220 }}
                key={option?.label}
                onClick={(e) => {
                  e.preventDefault();
                  handleOptionClick(option!);
                }}
              >
                <FormControlLabel
                  control={<Checkbox checked={selectedOptions?.includes(option?.value) || false} />}
                  label={option?.label}
                />
              </MenuItem>
            ))}
          </FormGroup>
        </FormControl>

        <Box
          display={"flex"}
          flexDirection={"row"}
          sx={{ justifyContent: "space-between" }}
          alignItems={"center"}
          padding={"10px 20px"}
        >
          <Typography
            sx={clearFiltersBtnStyle}
            onClick={() => {
              handleClearMenu();
            }}
          >
            Clear
          </Typography>

          <Button color="secondary" size="medium" variant="contained" onClick={handleCloseMenu}>
            Done
          </Button>
        </Box>
      </Menu>
    </Box>
  ) : (
    <></>
  );
};

export default StateOrActionOrStatusFilter;
