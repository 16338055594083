import { memo } from "react";
import classnames from "classnames";
import { ExpandMore } from "components/common/icons";
import styles from "./Header.module.scss";
import { IHeaderProps } from "./types";

const Header = ({ className, expanded, spacingClass, title, onExpandToggle, ...rest }: IHeaderProps) => {
  return (
    <div className={classnames(styles.header, spacingClass, className)} {...rest}>
      {title && <div>{title}</div>}
      {onExpandToggle && (
        <button
          className={classnames(styles.expandButton, {
            [styles["expandButton--collapsed"]]: !expanded,
          })}
          onClick={onExpandToggle}
        >
          <ExpandMore />
        </button>
      )}
    </div>
  );
};

export default memo(Header);
