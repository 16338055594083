import ChevronLeft from "@mui/icons-material/ChevronLeft";
import { Divider } from "@mui/material";
import Grid from "components/common/Grid";
import IconButton from "components/common/IconButton";
import Typography from "components/common/Typography";
import React, { useEffect, useRef, useState } from "react";
import DraggableRule from "./DraggableRule";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { AutoDepositRuleGraphQlType, ConditionGraphQlType } from "generated/sdk";
import { useStore } from "storeContainer";
import { observer } from "mobx-react";

const ReviewRules: React.FC<{
  setShowReviewRules: React.Dispatch<React.SetStateAction<boolean>>;
  rulePriority: AutoDepositRuleGraphQlType[];
  setRulePriority: React.Dispatch<React.SetStateAction<AutoDepositRuleGraphQlType[] | undefined>>;
  drawerSource: string;
  selectedRuleId?: string;
}> = ({ setShowReviewRules, rulePriority, setRulePriority, drawerSource, selectedRuleId }) => {
  const SessionStore = useStore("SessionStore");
  const { session } = SessionStore;
  const reorder = (list: any[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerHeight, setContainerHeight] = useState<number>(0);

  useEffect(() => {
    if (containerRef.current) {
      const height = containerRef.current.clientHeight;
      setContainerHeight(height);
    }
  }, [containerRef.current]);

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    const items = reorder(filterForDraggableRules(rulePriority)!, result.source.index, result.destination.index);
    if (getEmptyRuleIfExists(rulePriority)) setRulePriority([...items, getEmptyRuleIfExists(rulePriority)]);
    else {
      setRulePriority(items);
    }
  };
  const isConditionEmpty = (conditions: ConditionGraphQlType) => {
    if (conditions.memos?.some((item) => item?.length)) return false;
    if (conditions.senders?.some((item) => item?.length)) return false;
    if (conditions.pttoos?.some((item) => item?.length)) return false;
    return true;
  };
  const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    background: isDragging ? "#F5FAFF" : "transparent",

    padding: "20px",
    ...draggableStyle,
  });
  const getEmptyRuleIfExists = (rulePriority: AutoDepositRuleGraphQlType[]) => {
    const emptyRule = rulePriority.find((rule) => {
      if (!rule?.conditions.memos?.length && !rule?.conditions.pttoos?.length && !rule?.conditions.senders?.length)
        return true;
    });
    return emptyRule;
  };
  const filterForDraggableRules = (rulePriority: AutoDepositRuleGraphQlType[]) => {
    const filteredRules = rulePriority.filter((rule) => {
      if (rule?.conditions.memos?.length || rule?.conditions.pttoos?.length || rule?.conditions.senders?.length)
        return true;
    });
    return filteredRules;
  };
  useEffect(() => {
    setRulePriority((prevRulePriority) => [
      ...filterForDraggableRules(prevRulePriority!)!,
      getEmptyRuleIfExists(prevRulePriority!)!,
    ]);
  }, [setRulePriority]);

  return (
    <DragDropContext nonce={(window as any).nonce} onDragEnd={onDragEnd}>
      <Grid width={"45vw"} padding={"30px"} flexDirection={"column"} container>
        <Grid item>
          <Grid marginBottom={"50px"} item>
            <Typography fontWeight={600} variant="h1">
              {drawerSource === "add" ? "Add auto deposit rule" : "Reprioritize auto deposit rules"}
            </Typography>
          </Grid>
          {drawerSource === "add" && (
            <IconButton
              onClick={() => {
                setShowReviewRules(false);
              }}
              sx={{ paddingLeft: 0 }}
            >
              <ChevronLeft sx={{ transform: "scale(1.4)" }} color="secondary" />{" "}
              <Typography fontWeight={400} variant="h4">
                Edit rule
              </Typography>
            </IconButton>
          )}
          <Typography fontSize={"20px"} fontWeight={600} variant="h1">
            Review related rules
          </Typography>
          <Typography fontSize={"14px"} variant="h6">
            Review all autodeposit rules for <label style={{ fontWeight: 600 }}>{session.data?.account?.email}</label>{" "}
            and sort them in order of priority
          </Typography>
        </Grid>
        <Divider sx={{ marginTop: "35px" }} />
        <Grid justifyContent={"space-between"} marginY={"10px"} container flexDirection={"row"} item>
          <Grid item xs={1}></Grid>
          <Grid xs={11} justifyContent="space-between" container item>
            <Grid xs={6} item>
              <Typography fontSize={"14px"} fontWeight="600" variant="body1">
                Additional Criteria
              </Typography>
            </Grid>
            <Grid xs={6} item>
              <Typography fontSize={"14px"} fontWeight="600" variant="body1">
                Auto deposit Account
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Divider />
        <Grid ref={containerRef} style={containerHeight ? { height: `${containerHeight}px` } : {}}>
          <Droppable droppableId="droppable">
            {(provided: any, snapshot: any) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {filterForDraggableRules(rulePriority)?.map((item, index) => (
                  <Draggable
                    isDragDisabled={isConditionEmpty(item.conditions)}
                    key={item?.id}
                    draggableId={item?.id!}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                          ...getItemStyle(snapshot.isDragging, provided.draggableProps?.style),
                          background:
                            item.id === selectedRuleId || item.id === "priority_placeholder"
                              ? "#F5FAFF"
                              : "transparent",
                          borderBottom: "0.5px solid #E1EBFC",
                        }}
                      >
                        <DraggableRule
                          bankAccount={item?.bank_account!}
                          criteria={item?.conditions!}
                          organizationId={item.organization_id!}
                          organizationName={item.organization_name!}
                          isNewRule={item.id === "priority_placeholder"}
                          isSelectedRule={item.id === selectedRuleId}
                          companyId={item.company_id!}
                          companyName={item.company_name!}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
                {getEmptyRuleIfExists(rulePriority) && (
                  <div style={{ padding: "20px" }}>
                    <DraggableRule
                      bankAccount={getEmptyRuleIfExists(rulePriority)?.bank_account!}
                      criteria={getEmptyRuleIfExists(rulePriority)?.conditions!}
                      organizationId={getEmptyRuleIfExists(rulePriority)?.organization_id!}
                      organizationName={getEmptyRuleIfExists(rulePriority)?.organization_name!}
                      companyId={getEmptyRuleIfExists(rulePriority)?.company_id!}
                      companyName={getEmptyRuleIfExists(rulePriority)?.company_name!}
                    />
                  </div>
                )}
              </div>
            )}
          </Droppable>
        </Grid>
      </Grid>
    </DragDropContext>
  );
};

export default observer(ReviewRules);
