import { Box, Tooltip } from "@mui/material";
import { formatCurrency } from "common/helpers/utils";
import { IVBillVBillGetBatchListQuery } from "generated/sdk.vbill";
import { COLORS } from "themes/default";

interface IBillsAmountProps {
  batch: NonNullable<IVBillVBillGetBatchListQuery["getBatchList"]["data"]>["items"][number];
}

export const BillsAmount = ({ batch }: IBillsAmountProps) => {
  const invoicesAmount = Number(batch?.invoicesAmount ?? 0);
  const budgetAmount = Number(batch?.budget ?? 0);
  const isOverBudget = invoicesAmount > budgetAmount;

  return (
    <Tooltip
      arrow
      title={
        <div>
          <div>
            Bills amount: <strong>{formatCurrency(invoicesAmount)}</strong>
          </div>
          <div>
            Budget amount: <strong>{formatCurrency(budgetAmount)}</strong>
          </div>
          <div>
            {isOverBudget ? "Over" : "Left"} budget:{" "}
            <strong>{formatCurrency(Math.abs(budgetAmount - invoicesAmount))}</strong>
          </div>
        </div>
      }
    >
      <Box sx={{ color: isOverBudget ? COLORS.orange : COLORS.success }}>{formatCurrency(invoicesAmount)}</Box>
    </Tooltip>
  );
};
