import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { graphqlVBillClient } from "common/graphqlClient";
import { formatCurrency } from "common/helpers/utils";
import { Spinner } from "components/common/Spinner/Spinner";
import { addDays, format } from "date-fns";
import { getSdk } from "generated/sdk.vbill";
import { isNil } from "lodash";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useStore } from "storeContainer";
import { COLORS } from "themes/default";

const { VBillUpdateBatch, VBillGetBillsStats } = getSdk(graphqlVBillClient);

interface IDueDateOverBudgetDialogProps {
  onCloseDialog: () => void;
  leftBudgetAmount: number;
  daysBefore?: number;
  daysAfter?: number;
}
export const DueDateOverBudgetDialog = observer(function DueDateOverBudgetDialog({
  onCloseDialog,
  leftBudgetAmount,
  daysBefore,
  daysAfter,
}: IDueDateOverBudgetDialogProps) {
  const vBillBatchDetailsStore = useStore("VBillBatchDetailsStore");
  const { batchId, organizationId } = useParams<{ batchId: string; organizationId: string }>();
  const [isStatsFetching, setIsStatsFetching] = useState<boolean>(false);
  const [ddstats, setDDStats] = useState<{ daysBack: number; count?: number; amount?: string }[]>([]);

  useEffect(() => {
    computeDueDateStats();
  }, []);

  const computeDueDateStats = async () => {
    setIsStatsFetching(true);
    const ts = new Date();
    const fetchDueDate = async (daysBack: number = 0) =>
      VBillGetBillsStats({
        filters: {
          organizationId,
          date_due_from: new Date(+ts - 1000 * 3600 * 24 * daysBack).toISOString(),
          date_due_to: ts.toISOString(),
        },
      }); //FIXME: add current filters here

    const maxDays = 30;
    let daysBack = 0;

    const x: { daysBack: number; count?: number; amount?: string }[] = [];
    while (daysBack <= maxDays) {
      const data = await fetchDueDate(daysBack).catch((e) => undefined);
      if (data) {
        x.push({ daysBack, ...data.getBillsStats.generalStats });
        setDDStats(x);
      }
      daysBack++;
    }
    setIsStatsFetching(false);
  };

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open
      scroll="body"
      onClose={onCloseDialog}
      PaperProps={{ sx: { padding: "15px", position: "relative" } }}
    >
      <Tooltip arrow title="Close">
        <IconButton onClick={onCloseDialog} sx={{ position: "absolute", top: "5px", right: "5px", zIndex: 1 }}>
          <CloseIcon sx={{ fontSize: "20px" }} />
        </IconButton>
      </Tooltip>

      <Typography sx={{ fontSize: "20px", marginBottom: "20px" }}>Overbuget Simulation - Bill Due Date</Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ padding: "10px" }} variant="head" align="center">
                Due Date
              </TableCell>
              <TableCell sx={{ padding: "10px" }} variant="head" align="center">
                Bills
              </TableCell>
              <TableCell sx={{ padding: "10px" }} variant="head" align="center" colSpan={2}>
                Bills Amount
              </TableCell>
            </TableRow>
          </TableHead>
          {isStatsFetching ? (
            <TableBody>
              <TableRow sx={{ position: "relative", height: "100px" }}>
                <TableCell
                  sx={{ position: "absolute", left: "50%", top: "50%", padding: "10px" }}
                  align="center"
                  colSpan={4}
                >
                  <Spinner />
                </TableCell>
              </TableRow>
            </TableBody>
          ) : ddstats.length === 0 ? (
            <TableBody>
              <TableRow>
                <TableCell sx={{ padding: "10px" }} align="center" colSpan={4}>
                  <Typography>No results found</Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            ddstats.map(({ daysBack, count, amount }) => (
              <TableRow>
                <TableCell sx={{ padding: "10px" }} align="center">
                  <Typography sx={{ textAlign: "center" }}>
                    <Tooltip arrow title={format(addDays(new Date(), -daysBack), "MM/dd/yyyy")}>
                      <strong>{daysBack}</strong>
                    </Tooltip>
                  </Typography>
                </TableCell>
                <TableCell sx={{ padding: "10px" }} align="center">
                  <Typography>{count}</Typography>
                </TableCell>
                <TableCell sx={{ padding: "10px" }} align="right" colSpan={2}>
                  {!isNil(amount) && (
                    <Typography sx={{ color: Number(amount) > leftBudgetAmount ? COLORS.darkRed : COLORS.success }}>
                      {formatCurrency(Number(amount))}
                    </Typography>
                  )}
                </TableCell>
              </TableRow>
            ))
          )}
        </Table>
      </TableContainer>
      {/* 
      <Divider sx={{ marginTop: 4, marginBottom: 2 }} />

      <Box sx={{ display: "flex", justifyContent: "end", gap: "15px" }}>
        <LoadingButton
          variant="contained"
          // loading={isStatsFetching}
          // onClick={handleMoveBatchBtnClick}
          sx={{ height: "30px" }}
        >
          Save
        </LoadingButton>

        <Button variant="outlined" onClick={onCloseDialog} sx={{ height: "30px" }}>
          Cancel
        </Button>
      </Box> */}
    </Dialog>
  );
});
