import { Grid, Paper, Typography } from "@mui/material";
import React from "react";
import { COLORS } from "themes/default";
import CircleIcon from "@mui/icons-material/Circle";
const PortalStatusItem: React.FC<{ children: React.ReactNode; title: string }> = ({ children, title }) => {
  return (
    <Grid flexDirection={"row"} flexWrap="nowrap" container>
      <Grid item>
        <CircleIcon sx={{ transform: "scale(.3)", opacity: 0.4 }} />
      </Grid>
      <Grid item marginBottom={4}>
        <Typography
          lineHeight={1.3}
          color={COLORS.darkText}
          fontWeight={600}
          fontStyle="italic"
          display={"inline"}
          fontSize={22}
          whiteSpace={"nowrap"}
          alignItems="center"
        >
          {title}:
        </Typography>
        <Typography lineHeight={1} fontSize={20} color={"black"} fontWeight={400} marginLeft={1} display={"inline"}>
          {children}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default PortalStatusItem;
