import { TreeSelectListItem } from "./TreeSelectListItem";
import styles from "./TreeSelect.module.scss";
import { NodesWithChildren } from "./TreeSelect";
import { VariableSizeList as List } from "react-window";

type TreeSelectListProps = {
  displayedOptions: NodesWithChildren[];
  handleCheckboxChange: (id: string) => void;
  highlightTerm: string;
  listRef: React.RefObject<HTMLUListElement>;
  insideDrawer?: boolean;
  handleOnlyButtonClick: (id: string) => void;
};

export const TreeSelectList: React.FC<TreeSelectListProps> = ({
  displayedOptions,
  handleCheckboxChange,
  highlightTerm,
  listRef,
  insideDrawer = false,
  handleOnlyButtonClick,
}) => {
  const Row = ({ index, style }: { index: number; style: React.CSSProperties }) => {
    const option = displayedOptions[index];

    return (
      <TreeSelectListItem
        key={option.id}
        option={option}
        handleCheckboxChange={handleCheckboxChange}
        handleOnlyButtonClick={handleOnlyButtonClick}
        highlightTerm={highlightTerm}
        style={style}
        listRef={listRef}
        index={index}
        insideDrawer={insideDrawer}
      />
    );
  };

  const getHeight = () => {
    const hasMoreThanThreeItems = displayedOptions?.length > 3;
    if (hasMoreThanThreeItems) {
      return 400;
    } else {
      return 200;
    }
  };

  return (
    <List
      className={styles.filterList}
      itemCount={displayedOptions.length}
      height={getHeight()}
      itemSize={(index) => {
        const currentOption = displayedOptions[index];
        const numberOfChildren = displayedOptions[index]?.children?.length;
        const singleItemHeight = 65;
        const expandedItemHeight =
          numberOfChildren! > 1
            ? numberOfChildren! < 4
              ? 65 + numberOfChildren! * 50
              : numberOfChildren! * 50
            : singleItemHeight + 50;

        return currentOption.showChildren ? expandedItemHeight : singleItemHeight;
      }}
      width={insideDrawer ? "100%" : "300px"}
      ref={listRef as any}
    >
      {Row}
    </List>
  );
};
