import { Box } from "@mui/material";
import { FunctionComponent, useEffect, useMemo, useRef } from "react";

interface IMessagesScrollEndIndicatorProps {
  onScrollEndIndicator: (value: boolean) => void;
}

export const MessagesScrollEndIndicator: FunctionComponent<IMessagesScrollEndIndicatorProps> = ({
  onScrollEndIndicator,
}) => {
  const domElementRef = useRef<HTMLDivElement>(null);
  const observer = useMemo(
    () =>
      new IntersectionObserver(
        ([entry]) => {
          onScrollEndIndicator(entry.isIntersecting);
        },
        { threshold: 0.6 },
      ),
    [onScrollEndIndicator],
  );

  useEffect(() => {
    if (!domElementRef.current) {
      return;
    }

    observer.observe(domElementRef.current);

    return () => {
      observer.disconnect();
      onScrollEndIndicator(false);
    };
  }, [observer, onScrollEndIndicator]);

  return <Box ref={domElementRef} sx={{ height: "18px" }} />;
};
