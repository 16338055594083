import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import styles from "./FiltersModal.module.scss";
import ReplayIcon from "@mui/icons-material/Replay";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import { useState } from "react";
import AdapterDateFns from "@mui/lab/AdapterDateFns/AdapterDateFns";
import { InputLabel } from "@mui/material";

const style = {
  position: "absolute",
  top: "0",
  right: "0",
  width: 500,
  height: "100vh",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
};
const errorTypes = [
  { DUPLICATE_ENTITY_REF: "Duplicate entity ref" },
  { VALIDATION: "Validation" },
  { INVALID_CONTACT: "Invalid contact" },
  { BANK_ACCOUNT_NOT_FOUND: "Bank account not found" },
  { MISSING_BANK_ACCOUNT: "Missing bank account" },
  { NO_POSTAL_ADDRESS: "No postal address" },
  { INVALID_BILLER: "Invalid Biller" },
  { INVALID_BILL_PAYMENT: "Invalid bill payment" },
  { INVALID_RECIPIENT_EMAIL: "Invalid recipient email" },
  { RECIPIENT_EMAIL_LOCKED: "Recipient email locked" },
  { DUPLICATE_CHECK_NUMBER: "Duplicate check number" },
  { INSUFFICIENT_PERMISSION: "Insufficient permission" },
  { UNMAPPED_USER: "Unmapped user" },
  { UNMAPPED_GL_ACCOUNT: "Unmapped GL account" },
  { USER_UNABLE_TO_WRITE_THIS_CHECK: "User unable to write this check" },
  { GL_ACCOUNT_NOT_FOUND: "GL account not found" },
  { MISSING_GL_ACCOUNT_OR_BANK_ACCOUNT: "Missing GL account or bank account" },
];
function FiltersModal({
  modalFilters,
  showFiltersChips,
  setModalFilters,
  setShowFiltersChips,
  handleCancel,
  handleApply,
  handleClose,
  handleClear,
}: any) {
  const [validationError, setValidationError] = useState({ amount_min: "", amount_max: "" });
  const findErrorName = (errorType: any) => {
    const errorName = errorTypes.find((el) => Object.keys(el) === errorType);
    return Object.values(errorName!);
  };
  const handleApplyFilters = () => {
    let newFiltersChips = showFiltersChips;
    if (modalFilters.amount_min && modalFilters.amount_max && modalFilters.amount_max > modalFilters.amount_min) {
      newFiltersChips = { ...newFiltersChips, amount: `$${modalFilters.amount_min} - $${modalFilters.amount_max}` };
      handleApply();
    } else if (modalFilters.amount_min) {
      newFiltersChips = { ...newFiltersChips, amount: `Min $${modalFilters.amount_min}` };
      handleApply();
    } else if (modalFilters.amount_max) {
      newFiltersChips = { ...newFiltersChips, amount: `Max $${modalFilters.amount_max}` };
      handleApply();
    } else {
      setValidationError({ ...validationError, amount_min: "Must be a less than max" });
    }
    if (modalFilters.to_date || modalFilters.from_date) {
      newFiltersChips = { ...newFiltersChips, date: `${modalFilters.from_date}-${modalFilters.to_date}` };
      handleApply();
    }
    if (modalFilters.error_type) {
      findErrorName(modalFilters.error_type);
      newFiltersChips = { ...newFiltersChips, errorType: `${findErrorName(modalFilters.error_type)}` };
      handleApply();
    }
    if (modalFilters.contact) {
      newFiltersChips = { ...newFiltersChips, contact: `${modalFilters.contact}` };
      handleApply();
    }

    setShowFiltersChips({ ...newFiltersChips });
  };

  const handleCloseModal = () => {
    handleClose();
  };

  const handleReloadFilters = () => {
    handleClear();
    handleCancel();
  };

  const formatDateForFilter = (value: any) => {
    const year = new Date(value).getFullYear();
    const month = new Date(value).getMonth();
    const day = new Date(value).getDate();
    // const formattedDate = year + "-0" + month + "-0" + day;
    let d = new Date(year, month, day);
    let ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
    let mo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(d);
    let da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);
    return `${ye}-${mo}-${da}`;
  };
  return (
    <Box sx={style}>
      <Grid container sx={{ height: "100%" }}>
        <Grid item container direction={"column"}>
          {/* <Grid item>
            <Button
              variant={"text"}
              className={styles.clearFiltersButton}
              startIcon={<ReplayIcon />}
              onClick={handleReloadFilters}
            >
              Clear Filters
            </Button>
          </Grid> */}
          {/* <Grid item container justifyContent={"space-between"} alignItems={"baseline"} sx={{ marginBottom: "10px" }}>
            <Typography variant={"body1"}>Filter by date</Typography>
          </Grid>
          <Grid item container direction={"row"} justifyContent={"space-between"} sx={{ marginBottom: "20px" }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="From"
                value={modalFilters?.from_date}
                onChange={(value: any) => setModalFilters({ ...modalFilters, from_date: formatDateForFilter(value) })}
                renderInput={(params: any) => <TextField {...params} />}
              />
              <DatePicker
                label="To"
                value={modalFilters?.to_date}
                onChange={(value: any) => setModalFilters({ ...modalFilters, to_date: formatDateForFilter(value) })}
                renderInput={(params: any) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Grid> */}
          <Grid item container justifyContent={"space-between"} alignItems={"baseline"} sx={{ marginBottom: "10px" }}>
            <Typography variant={"body1"}>Contact</Typography>
          </Grid>
          <Grid item sx={{ marginBottom: "20px" }}>
            <InputLabel variant="standard" htmlFor="contact">
              Contact
            </InputLabel>
            <TextField
              id="contact"
              name="contact"
              variant="outlined"
              sx={{ marginRight: "10px", marginBottom: "10px" }}
              value={modalFilters?.contact || ""}
              onChange={(e: any) => {
                setModalFilters({ ...modalFilters, contact: e.target.value });
              }}
            />
          </Grid>
          <Grid item container justifyContent={"space-between"} alignItems={"baseline"} sx={{ marginBottom: "10px" }}>
            <Typography variant={"body1"}>Amount</Typography>
          </Grid>
          <Grid item container direction={"row"} justifyContent={"space-between"} sx={{ marginBottom: "20px" }}>
            <Box>
              <InputLabel variant="standard" htmlFor="amount_from">
                Min.
              </InputLabel>
              <TextField
                id="amount_from"
                type={"number"}
                InputProps={{
                  inputProps: {
                    min: 15,
                  },
                }}
                value={modalFilters?.amount_min || ""}
                error={!!validationError.amount_min}
                helperText={validationError.amount_min}
                name="amount_from"
                onChange={(e) => setModalFilters({ ...modalFilters, amount_min: parseFloat(e.target.value) })}
              />
            </Box>
            <Box>
              <InputLabel variant="standard" htmlFor="amount_to">
                Max.
              </InputLabel>
              <TextField
                id="amount_to"
                type={"number"}
                value={modalFilters?.amount_max || ""}
                error={!!validationError.amount_max}
                helperText={validationError.amount_max}
                name="amount_to"
                onChange={(e) => setModalFilters({ ...modalFilters, amount_max: parseFloat(e.target.value) })}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid item className={styles.btnContainer}>
          <div className={styles.btnBorder}>
            <Button
              variant={"outlined"}
              className={styles.cancelButton}
              sx={{ marginRight: "10px" }}
              onClick={handleReloadFilters}
            >
              Clear
            </Button>
            <Button variant={"contained"} onClick={handleApplyFilters}>
              Done
            </Button>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
}

export default FiltersModal;
