import { IVBillInvoiceSchema } from "generated/sdk.vbill";

export const getApprovalFlags = (
  approvalStatus: NonNullable<IVBillInvoiceSchema["approvalStatus"]>,
  organizationUserId: string,
) => {
  const canUserApprove = approvalStatus.potentialApprovers.includes(organizationUserId);
  const hasUserApproved = approvalStatus.approvedBy.includes(organizationUserId);
  const canUserReview = approvalStatus.potentialReviewers.includes(organizationUserId);
  const hasUserReviewed = approvalStatus.reviewedBy.includes(organizationUserId);
  const hasUserDeclined = approvalStatus.declinedBy.includes(organizationUserId);

  const isActionPending = !hasUserApproved && !hasUserReviewed && !hasUserDeclined;
  const showApprovalWidget = canUserApprove || hasUserApproved || canUserReview || hasUserReviewed;

  return {
    canUserApprove,
    hasUserApproved,
    canUserReview,
    hasUserReviewed,
    hasUserDeclined,
    isActionPending,
    showApprovalWidget,
  };
};
