import { graphqlClient } from "common/graphqlClient";
import {
  CompanyIntegrationsQuery,
  GeneralLedgerAccountsQuery,
  GetAllKnownExtSignerNamesQuery,
  getSdk,
  IntegrationsQuery,
} from "generated/sdk";
import { definitely, definitelyFilter } from "generated/utils";
import { action, computed, makeObservable, observable, runInAction, set } from "mobx";
import { createObservableContainer } from "storeContainer";
import { Integration } from "../generated/graphql";
import { StoreBase } from "./StoreBase";

const {
  Integrations,
  CompanyIntegrations,
  setBankAccountGlAccountMapping,
  GetAllKnownExtSignerNames,
  createExternalAutoSigner,
  deleteExternalAutoSigner,
  GetIntegrationAuthCode,
  UnmapBankAccountGlAccountMapping,
  RevokeAccessToken,
  GeneralLedgerAccounts,
} = getSdk(graphqlClient);

export class IntegrationsStore extends StoreBase {
  integrationsList = createObservableContainer<NonNullable<IntegrationsQuery["Integrations"]>>();
  companyIntegrationsList = createObservableContainer<NonNullable<CompanyIntegrationsQuery["CompanyIntegrations"]>>();
  generalLedgerAccountsList =
    createObservableContainer<NonNullable<GeneralLedgerAccountsQuery["GeneralLedgerAccounts"]>>();
  allKnownExtSignerNamesList =
    createObservableContainer<NonNullable<GetAllKnownExtSignerNamesQuery["GetAllKnownExtSignerNames"]>>();
  allowedGLAccountTypes = ["Bank"];

  integrationAuthCode = createObservableContainer<NonNullable<String>>();

  private companyId?: string;

  constructor() {
    super();
    makeObservable(this);
  }

  async fetchIntegrations(companyId: string) {
    return definitelyFilter((await Integrations({ companyId }))?.Integrations);
  }

  async fetchCompanyIntegrations(companyId: string) {
    return definitely((await CompanyIntegrations({ companyId }))?.CompanyIntegrations);
  }

  async fetchAllKnownExtSignerNames(company_id: string) {
    return definitely((await GetAllKnownExtSignerNames({ company_id }))?.GetAllKnownExtSignerNames);
  }

  async fetchGeneralLedgerAccounts(companyId: string) {
    return definitely((await GeneralLedgerAccounts({ companyId }))?.GeneralLedgerAccounts);
  }

  loadIntegrations(companyId: string, forceUpdate?: true) {
    return this.integrationsList.cachedLoad(() => this.fetchIntegrations(companyId), [companyId], {
      forceUpdate,
    });
  }

  async loadCompanyIntegrations(companyId: string, forceUpdate?: true) {
    await this.companyIntegrationsList.cachedLoad(() => this.fetchCompanyIntegrations(companyId), [companyId], {
      forceUpdate,
    });

    this.companyId = companyId;

    await this.generalLedgerAccountsList.cachedLoad(
      () => this.fetchGeneralLedgerAccounts(this.companyId!),
      [this.companyId],
      { forceUpdate }, // No need to reload all GL Accounts
    );
  }

  async loadAllKnownExtSignerNames(forceUpdate?: true) {
    const companyId = this.companyId;
    if (companyId) {
      this.allKnownExtSignerNamesList.cachedLoad(() => this.fetchAllKnownExtSignerNames(companyId), [companyId], {
        forceUpdate,
      });
    }
  }

  async load(companyId: string, force?: true) {
    await Promise.all([
      this.loadIntegrations(companyId),
      this.storeContainer?.OrganizationUsersStore.loadOrganizationUsers(),
    ]);
  }

  @action
  async setBankAccountGlAccountMapping(bankAccountId: string, glAccountId?: string): Promise<void> {
    if (glAccountId) {
      const prevBankAccount = this.companyIntegrationsList.data?.bank_accounts?.find((ba) => ba.id === bankAccountId);
      const isOverwritingGlAccount = !!prevBankAccount?.gl_accounts?.length;
      const result = await setBankAccountGlAccountMapping({ bankAccountId, glAccountId });
      runInAction(() => {
        const baAccount = this.companyIntegrationsList.data?.bank_accounts?.find((ba) => ba.id === bankAccountId);
        if (baAccount) {
          set(baAccount.gl_accounts!, result.SetBankAccountGlAccountMapping?.gl_accounts!);
          if (this.companyIntegrationsList?.data?.setup_status && !isOverwritingGlAccount) {
            this.companyIntegrationsList.data.setup_status.mapped_bank_accounts! += 1;
            this.companyIntegrationsList.data.setup_status.unmapped_bank_accounts! -= 1;
          }
        }
      });
    } else {
      const baToBeUnmapped = this.companyIntegrationsList.data?.bank_accounts?.find(
        (ba) => ba.id === bankAccountId,
      )?.name;
      if (
        window.confirm(
          `This GL account is already mapped to the bank account ${baToBeUnmapped}. Are you sure you want to remove mapping for the bank account ${baToBeUnmapped} ?`,
        )
      ) {
        await UnmapBankAccountGlAccountMapping({ bank_account_id: bankAccountId });
        runInAction(() => {
          const baAccount = this.companyIntegrationsList.data?.bank_accounts?.find((ba) => ba.id === bankAccountId);
          if (baAccount) {
            baAccount?.gl_accounts!.pop();
            if (this.companyIntegrationsList?.data?.setup_status) {
              this.companyIntegrationsList.data.setup_status.mapped_bank_accounts! -= 1;
              this.companyIntegrationsList.data.setup_status.unmapped_bank_accounts! += 1;
            }
          }
        });
      }
    }
  }

  @observable isExternalAutoSignersSaving: boolean = false;
  @action
  async syncExternalAutoSigners(
    organization_user_id: string,
    integration_id: string,
    company_id: string,
    names: string[],
  ) {
    runInAction(() => {
      this.isExternalAutoSignersSaving = true;
    });
    const integration = definitelyFilter(this.companyIntegrationsList.data?.integrations).find(
      (ci) => ci.integration?.integration_id === integration_id,
    );

    const prev = definitelyFilter(integration?.external_auto_signers).filter(
      (e) => e?.organization_user_id === organization_user_id,
    );

    const prevOverlap = definitelyFilter(integration?.external_auto_signers).filter(
      (e) =>
        e.name !== undefined &&
        e.name !== null &&
        names.includes(e.name) &&
        e.organization_user_id !== organization_user_id,
    );

    const prevOverlapIds = prevOverlap.map((o) => o.id);

    const prevValues: string[] = prev.map((p) => p.name).filter((e): e is string => e !== null);

    const dbg = { company_id, integration_id, organization_user_id };

    const newValues = names.filter((e) => !prevValues.includes(e));

    if (prevOverlap.length) {
      const msg = `This mapping is already used by another organization user. Are you sure you want to move it to this user?`;
      if (!window.confirm(msg)) {
        await this.load(company_id, true);
        runInAction(() => {
          this.isExternalAutoSignersSaving = false;
        });
        return;
      }
    }

    const delValues = prevValues.filter((e) => names.includes(e));
    const delIds = definitelyFilter(
      delValues
        .map((name) => integration?.external_auto_signers?.find((e) => e?.name === name))
        .filter((e) => !!e)
        .map((e) => e?.id),
    );

    const deleteTasks = [...prevOverlapIds, ...delIds].map((id) =>
      deleteExternalAutoSigner({ external_signer_id: id, company_id })
        .then(() => {
          runInAction(() => {
            if (integration) {
              const orgUserId = integration.external_auto_signers!.find((v) => v.id === id)!.organization_user_id;
              integration.external_auto_signers?.splice(
                integration.external_auto_signers.findIndex((v) => v.id === id),
                1,
              );
              if (
                integration.external_auto_signers!.filter((v) => v.organization_user_id === orgUserId).length === 0 &&
                this.companyIntegrationsList?.data?.setup_status
              ) {
                this.companyIntegrationsList.data.setup_status.unmapped_organization_users! += 1;
                this.companyIntegrationsList.data.setup_status.mapped_organization_users! -= 1;
              }
            }
          });
        })
        .catch((err) => console.error("ERR deleteExternalAutoSigner", err, dbg)),
    );

    await Promise.all(deleteTasks);

    await Promise.all([
      ...newValues.map((name) =>
        createExternalAutoSigner({ name, organization_user_id, integration_id, company_id })
          .then((result) => {
            runInAction(() => {
              if (integration) {
                integration.external_auto_signers!.push(result?.CreateExternalAutoSigner!);
                const orgUserId = integration.external_auto_signers!.find((v) => v.name === name)!.organization_user_id;
                if (
                  integration.external_auto_signers!.filter((v) => v.organization_user_id === orgUserId).length === 1 &&
                  this.companyIntegrationsList?.data?.setup_status
                ) {
                  this.companyIntegrationsList.data.setup_status.unmapped_organization_users! -= 1;
                  this.companyIntegrationsList.data.setup_status.mapped_organization_users! += 1;
                }
              }
            });
          })
          .catch((err) => console.error("ERR createExternalAutoSigner", err)),
      ),
    ]);

    if (prevOverlapIds.length || delIds.length || newValues.length) {
      await this.load(company_id, true);
    }

    runInAction(() => {
      this.isExternalAutoSignersSaving = false;
    });
  }

  async createExternalAutoSigner(
    name: string,
    organization_user_id: string,
    integration_id: string,
    company_id: string,
  ) {
    await createExternalAutoSigner({ name, organization_user_id, integration_id, company_id });
  }

  async deleteExternalAutoSigner(external_signer_id: string, company_id: string) {
    await deleteExternalAutoSigner({ external_signer_id, company_id });
  }

  getCompanyIntegration(integrationId: string) {
    return this.companyIntegrationsList.data?.integrations?.find(
      (integration) => integration?.integration?.integration_id === integrationId,
    );
  }

  @computed
  get hasPendingChecksIntegration() {
    return (
      definitelyFilter(
        this.companyIntegrationsList.data?.integrations?.filter((ci) =>
          ci?.integration?.scopes?.includes("pending-checks"),
        ),
      ).length > 0
    );
  }

  filterGlAccount(glAccountData: GeneralLedgerAccountsQuery | undefined): { label: string; value: string }[] {
    const glAccounts = [{ label: "None", value: "" }];
    const glAccountsList = this.allowedGLAccountTypes
      ? glAccountData?.GeneralLedgerAccounts?.filter((a) => this.allowedGLAccountTypes.includes(a?.account_type!))
      : glAccountData?.GeneralLedgerAccounts;
    glAccountsList?.forEach((glAccount) => {
      if (glAccount?.id && glAccount?.name) {
        glAccounts.push({
          label: glAccount?.name,
          value: glAccount?.id,
        });
      }
    });

    return glAccounts.sort((a, b) => a.label.localeCompare(b.label));
  }

  async addIntegration(integration: Integration, description: string) {
    this.integrationAuthCode.flush();

    const company_id = this.storeContainer?.ContextStore?.selectedCompanyId;

    if (company_id) {
      this.integrationAuthCode.load(this.fetchIntegrationAuthCode(integration, description, company_id));
    }
  }

  removeIntegrationData = createObservableContainer<NonNullable<Boolean | undefined>>();

  async removeIntegration(companyId: string, integrationId: string) {
    await this.removeIntegrationData.load(this._removeIntegration(companyId, integrationId));
    // this.storeContainer?.CompanyStore.loadIntegrations({ clearData: true, forceUpdate: true });
  }

  private async _removeIntegration(companyId: string, integrationId: string): Promise<boolean | undefined> {
    return definitely((await RevokeAccessToken({ companyId, integrationId })).RevokeAccessToken);
  }
  async removeIntegrationAuthCode() {
    this.integrationAuthCode.load(new Promise(() => undefined), true);
  }
  public async fetchIntegrationAuthCode(
    integration: Integration,
    description: string,
    company_id: string,
  ): Promise<string | undefined> {
    return definitely(
      (
        await GetIntegrationAuthCode({
          integrationId: integration.integration_id,
          companyId: company_id,
          integration_settings: {
            description: description,
          },
        })
      ).GetIntegrationAuthCode,
    );
  }
}
