import { Box } from "@mui/material";
import { IVBillBatchStatus, IVBillInvoiceIncomingStatus } from "generated/sdk.vbill";
import { observer } from "mobx-react";
import { useEffect } from "react";
import { useParams } from "react-router";
import { useStore } from "storeContainer";
import { VBillRouteGuardRedirect } from "../common/VBill/VBillRouteGuardRedirect/VBillRouteGuardRedirect";
import { BatchBackBtn } from "./BatchBackBtn/BatchBackBtn";
import { BatchSidebar } from "./BatchSidebar/BatchSidebar";
import { BatchSummaries } from "./BatchSummaries/BatchSummaries";
import { BatchVBills } from "./BatchVBills/BatchVBills";

// exclude session member is other places
// create subbatch refactor?

export const VBillBatchDetails = observer(function VBillBatchDetails() {
  const vBillBatchDetailsStore = useStore("VBillBatchDetailsStore");
  const orgUserStore = useStore("OrganizationUsersStore");
  const { batchId, organizationId } = useParams<{ batchId: string; organizationId: string }>();

  const { batchFilters, batchDetails, batchSelectedVBills } = vBillBatchDetailsStore;
  const batchDetailsData = batchDetails.data;
  const batchSelectedVBillsData = batchSelectedVBills.data;

  // load org users for SummaryInfo (orgUserStore.organizationUsersList)
  useEffect(() => {
    orgUserStore.loadOrganizationUsers();
  }, [orgUserStore]);

  // load batch details
  useEffect(() => {
    vBillBatchDetailsStore.getBatchDetails({ batchId: Number(batchId), organizationId });
  }, [vBillBatchDetailsStore, organizationId, batchId]);

  // load batch stats
  // useEffect(() => {
  //   // TODO: why?
  //   // if (!batchDetailsData?.batchFilters) {
  //   //   return;
  //   // }

  //   vBillBatchDetailsStore.getBatchStats({
  //     filters: {
  //       ...batchDetailsData?.batchFilters,
  //       organizationId,
  //     },
  //   });
  // }, [vBillBatchDetailsStore, organizationId, batchDetailsData?.batchFilters]);

  // load batch reactions
  useEffect(() => {
    if (!batchDetailsData?.settings?.BATCH_SUBBATCHES_ALLOWED || !batchDetailsData) {
      return;
    }

    vBillBatchDetailsStore.getBatchDetailsReactionsStats();
  }, [vBillBatchDetailsStore, batchDetailsData]);

  // load batch additional mappings settings
  useEffect(() => {
    if (!batchDetailsData) {
      return;
    }

    vBillBatchDetailsStore.getVBillBatchAdditionalMappingsSettings({
      companyIds: batchDetailsData?.batchFilters?.companyIds ?? [],
      organizationId,
    });
  }, [vBillBatchDetailsStore, organizationId, batchDetailsData]);

  // load child batch list
  useEffect(() => {
    if (!batchDetailsData?.settings?.BATCH_SUBBATCHES_ALLOWED) {
      return;
    }

    vBillBatchDetailsStore.getchildBatchListDetails({
      pagination: batchFilters.batch_list_pag,
      filters: {
        ...batchFilters.batch_list_fil,
        organizationId,
        batchId: Number(batchId),
      },
    });
  }, [
    vBillBatchDetailsStore,
    organizationId,
    batchId,
    batchFilters.batch_list_fil,
    batchFilters.batch_list_pag,
    batchDetailsData?.settings?.BATCH_SUBBATCHES_ALLOWED,
  ]);

  // load selected bills
  useEffect(() => {
    if (!batchDetailsData) {
      return;
    }

    vBillBatchDetailsStore.getBatchSelectedVBills(
      {
        pagination: batchFilters.vbill_sel_pag,
        filters: {
          ...batchFilters.vbill_sel_fil,
          organizationId,
          batch_id_in: [Number(batchId)],
        },
      },
      false,
      true,
    );
  }, [
    vBillBatchDetailsStore,
    organizationId,
    batchId,
    batchFilters.vbill_sel_fil,
    batchFilters.vbill_sel_pag,
    batchDetailsData,
  ]);

  // load available bills
  useEffect(() => {
    if (
      !batchDetailsData ||
      batchDetailsData?.status === IVBillBatchStatus.Closed ||
      batchDetailsData?.status === IVBillBatchStatus.Deleted
    ) {
      return;
    }

    vBillBatchDetailsStore.getBatchAvailableVBills(
      {
        pagination: batchFilters.vbill_ava_pag,
        filters: {
          ...batchFilters.vbill_ava_fil,
          organizationId,
          batch_id_in: [0],
          status: IVBillInvoiceIncomingStatus.Approved,
          free_amount_from: "0.01",
        },
      },
      false,
      true,
    );
  }, [
    vBillBatchDetailsStore,
    organizationId,
    batchFilters.vbill_ava_fil,
    batchFilters.vbill_ava_pag,
    batchDetailsData,
  ]);

  // load selected reactions
  useEffect(() => {
    if (!batchSelectedVBillsData) {
      return;
    }

    vBillBatchDetailsStore.getBatchSelectedVBillsReactionsStats();
  }, [vBillBatchDetailsStore, batchSelectedVBillsData]);

  // reset vbill available filters when batchFilters change
  useEffect(() => {
    if (!batchDetailsData?.batchFilters) {
      return;
    }
    vBillBatchDetailsStore.setBatchFilters((prev) => ({
      vbill_ava_fil: { ...batchDetailsData?.batchFilters },
    }));
  }, [vBillBatchDetailsStore, batchDetailsData?.batchFilters]);

  useEffect(() => {
    return () => {
      vBillBatchDetailsStore.batchDetails.flush();
      vBillBatchDetailsStore.resetBatchFilters();
      vBillBatchDetailsStore.vBillBatchAdditionalMappingsSettings.flush();
      vBillBatchDetailsStore.vBillBatchAdditionalMappingsSettings.flush();
      vBillBatchDetailsStore.batchMembers.flush();
      vBillBatchDetailsStore.batchAvailableVBills.flush();
      vBillBatchDetailsStore.batchSelectedVBills.flush();
      vBillBatchDetailsStore.batchSelectedVBillsReactionsStats.flush();
    };
  }, [vBillBatchDetailsStore]);

  return (
    <Box sx={{ "@media (min-width: 1400px)": { padding: "0 20px 20%" } }}>
      <VBillRouteGuardRedirect route="BATCH_DETAILS" />

      <BatchBackBtn />

      <BatchSummaries />

      <Box sx={{ display: "flex" }}>
        <BatchVBills />

        <BatchSidebar />
      </Box>
    </Box>
  );
});
