import { LoadingButton } from "@mui/lab";
import { Button, Drawer, Grid, Typography } from "@mui/material";
import {
  AutoDepositRuleGraphQlType,
  BankAccountBasic,
  ConditionGraphQlType,
  DepositBankAccountsQuery,
  Maybe,
} from "generated/sdk";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { useStore } from "storeContainer";
import { COLORS } from "themes/default";
import CriteriaComponent from "./CriteriaComponent";
import ReviewRules from "./ReviewRules";
import { SelectBankAccount } from "./SelectBankAccount";

const AddEditRuleDrawer: React.FC<{
  isDrawerOpen: boolean;
  setIsDrawerOpen: Function;
  handleConfirmOpenDeleteRuleModal: Function;
  rule: AutoDepositRuleGraphQlType | undefined;
  setSelectedRule: Dispatch<SetStateAction<AutoDepositRuleGraphQlType | undefined>>;
  setShowReviewRules: React.Dispatch<React.SetStateAction<boolean>>;
  showReviewRules: boolean;
  drawerSource: string;
  showConfirmButton: boolean;
}> = ({
  isDrawerOpen,
  setIsDrawerOpen,
  rule,
  setSelectedRule,
  handleConfirmOpenDeleteRuleModal,
  setShowReviewRules,
  showReviewRules,
  drawerSource,
  showConfirmButton = false,
}) => {
  enum criteriaType {
    pttoos = "pttoos",
    senders = "senders",
    memos = "memos",
  }
  const SessionStore = useStore("SessionStore");
  const AccountsStore = useStore("AccountsStore");
  const AutoDepositRulesStore = useStore("AutoDepositRulesStore");
  const { createAutoDepositRule, autodepositRules, updateAutoDepositRule } = AutoDepositRulesStore;
  const { getBankAccounts } = AccountsStore;
  const { session } = SessionStore;

  const [orgWithCompaniesAndBAs, setOrgWithCompaniesAndBAs] =
    useState<DepositBankAccountsQuery["DepositBankAccounts"]>();
  const [selectedBankAccount, setSelectedBankAccount] = useState<BankAccountBasic | undefined>(
    rule?.bank_account || undefined,
  );
  const [rulePriority, setRulePriority] = useState<AutoDepositRuleGraphQlType[]>();
  const [errorFeedBack, setErrorFeedBack] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [currentCriteria, setCurrentCriteria] = useState({
    pttoos: rule?.conditions.pttoos?.map((item) => {
      return { name: item, saved: true };
    }),
    memos: rule?.conditions.memos?.map((item) => {
      return { name: item, saved: true };
    }),
    senders: rule?.conditions.senders?.map((item) => {
      return { name: item, saved: true };
    }),
  });
  const [criteriaToBeSent, setCriteriaToBeSent] = useState<ConditionGraphQlType>();
  const handleAddFilter = (e: any) => {
    if (currentCriteria[e.currentTarget.id as criteriaType]?.every((item) => item.saved))
      currentCriteria[e.currentTarget.id as criteriaType]?.push({
        name: "",
        saved: false,
      });
    setCurrentCriteria({ ...currentCriteria });
  };
  const handleFilterInputChange = (e: any, index: number) => {
    if (errorFeedBack?.length) {
      setErrorFeedBack("");
    }
    currentCriteria[e.target.id as criteriaType]![index].name = e.target.value;
    setCurrentCriteria({ ...currentCriteria });
  };
  const handleFilterInputSave = (e: any, index: number) => {
    if (currentCriteria[e.currentTarget.id as criteriaType]![index].name?.startsWith(" ")) {
      setErrorFeedBack("Criteria names cannot start with a space!");
      return;
    }
    if (currentCriteria[e.currentTarget.id as criteriaType]![index].name) {
      currentCriteria[e.currentTarget.id as criteriaType]![index].saved = true;
      currentCriteria[e.currentTarget.id as criteriaType]![index].name =
        currentCriteria[e.currentTarget.id as criteriaType]![index].name!.trim();
      setCurrentCriteria({ ...currentCriteria });
    }
  };
  const handleRemoveFilter = (e: any, index: number) => {
    currentCriteria[e.currentTarget.id as criteriaType]?.splice(index, 1);
    setCurrentCriteria({ ...currentCriteria });
    setErrorFeedBack("");
  };

  const isMasterRule =
    !rule?.conditions.memos?.length &&
    !rule?.conditions.senders?.length &&
    !rule?.conditions.pttoos?.length &&
    rule?.id &&
    rule?.id !== "priority_placeholder";

  useEffect(() => {
    setCriteriaToBeSent({
      pttoos: currentCriteria?.pttoos
        ?.filter((item) => item.saved)
        .map((item) => {
          return item.name;
        }),

      senders: currentCriteria?.senders
        ?.filter((item) => item.saved)
        .map((item) => {
          return item.name;
        }),
      memos: currentCriteria?.memos
        ?.filter((item) => item.saved)
        .map((item) => {
          return item.name;
        }),
    });
  }, [currentCriteria]);

  useEffect(() => {
    if (rule?.conditions.memos && rule.conditions.senders && rule.conditions.pttoos)
      setCurrentCriteria({
        pttoos: rule?.conditions?.pttoos?.map((item) => {
          return { name: item, saved: true };
        }),
        senders: rule?.conditions?.senders?.map((item) => {
          return { name: item, saved: true };
        }),
        memos: rule?.conditions?.memos?.map((item) => {
          return { name: item, saved: true };
        }),
      });
    else {
      setCurrentCriteria({
        pttoos: [],
        memos: [],
        senders: [],
      });
    }
    if (!isDrawerOpen) {
      setCurrentCriteria({
        pttoos: [],
        memos: [],
        senders: [],
      });
    }
    setSelectedBankAccount(rule?.bank_account);
    setSelectedAccount(rule?.bank_account?.name!);
  }, [isDrawerOpen, rule?.bank_account, rule?.conditions.memos, rule?.conditions.pttoos, rule?.conditions.senders]);

  useEffect(() => {
    if (autodepositRules.data?.auto_deposit_rules) {
      if (!rule?.id && drawerSource === "add") {
        setRulePriority([
          ...(structuredClone(toJS(autodepositRules.data?.auto_deposit_rules)) as AutoDepositRuleGraphQlType[]),
          {
            id: "priority_placeholder",
            bank_account: {
              id: selectedBankAccount?.id!,
              account_number: selectedBankAccount?.account_number!,
              bank_name: selectedBankAccount?.bank_name!,
              name: selectedBankAccount?.name!,
              routing_number: selectedBankAccount?.routing_number!,
            },
            conditions: criteriaToBeSent!,
            email_address: {
              email: session.data?.account?.email,
              id: session.data?.account?.email_address_id,
            },
          },
        ]);
      } else {
        setRulePriority([
          ...(structuredClone(toJS(autodepositRules.data?.auto_deposit_rules)) as AutoDepositRuleGraphQlType[]),
        ]);
      }
    }
  }, [
    autodepositRules.data,
    selectedBankAccount,
    criteriaToBeSent,
    rule?.id,
    drawerSource,
    session.data?.account?.email,
    session.data?.account?.email_address_id,
  ]);

  const getAllBankAccounts = useCallback(async () => {
    await getBankAccounts();
  }, [getBankAccounts]);

  useEffect(() => {
    getAllBankAccounts();
  }, [getAllBankAccounts]);

  useEffect(() => {
    if (AccountsStore.depositAccounts) {
      setOrgWithCompaniesAndBAs(AccountsStore.depositAccounts.DepositBankAccounts);
    }
  }, [AccountsStore.depositAccounts]);

  const detemineIfAlreadyCreatedMainRule = () => {
    const existingEmptyRule = autodepositRules.data?.auto_deposit_rules?.find((rule) => {
      if (!rule?.conditions.memos?.length && !rule?.conditions.pttoos?.length && !rule?.conditions.senders?.length)
        return true;
    });
    return existingEmptyRule;
  };
  const getEmptyRuleIfExists = () => {
    const emptyRule =
      criteriaToBeSent?.memos?.length === 0 &&
      criteriaToBeSent.pttoos?.length === 0 &&
      criteriaToBeSent.senders?.length === 0;
    const existingEmptyRule = autodepositRules.data?.auto_deposit_rules?.find((rule) => {
      if (!rule?.conditions.memos?.length && !rule?.conditions.pttoos?.length && !rule?.conditions.senders?.length)
        return true;
    });
    return emptyRule && existingEmptyRule;
  };
  const checkIfEveryFieldIsSaved = () => {
    let notEverySaved = false;
    for (const key in currentCriteria) {
      if (currentCriteria[key as "pttoos" | "memos" | "senders"]?.some((item) => !item.saved)) {
        notEverySaved = true;
        break;
      }
    }
    return notEverySaved;
  };
  const checkIfFieldNotEmpty = () => {
    let everyCriteriaExists = false;
    for (const key in currentCriteria) {
      if (currentCriteria[key as "pttoos" | "memos" | "senders"]?.some((item) => !item.name?.length)) {
        everyCriteriaExists = true;
        break;
      }
    }
    return everyCriteriaExists;
  };
  const checkIfFieldValid = () => {
    let everyCriteriaValid = false;
    for (const key in currentCriteria) {
      if (
        currentCriteria[key as "pttoos" | "memos" | "senders"]?.some((item) => {
          return item.name?.startsWith(" ");
        })
      ) {
        everyCriteriaValid = true;
        break;
      }
    }
    return everyCriteriaValid;
  };

  checkIfEveryFieldIsSaved();
  const moveEmptyRuleToTheEnd = (array: (Maybe<string> | undefined)[], target: string) => {
    const index = array.indexOf(target);
    if (index !== -1) {
      array.splice(index, 1);
      array.push(target);
    }
    return array;
  };

  const handleSaveOrNext = async () => {
    try {
      // Determines if there's an empty rule already created
      const mainRule = detemineIfAlreadyCreatedMainRule();
      // Extracts ids from rulePriority list if the items exist
      let ids = rulePriority?.filter((item) => item)?.map((item) => item.id);
      // If there's an empty rule and ids, moves the empty rule to the end
      if (mainRule && ids) {
        ids = moveEmptyRuleToTheEnd(ids, mainRule.id!);
      }
      // If a bank account is selected, it proceeds to the next steps
      if (selectedBankAccount) {
        // If every field is saved, it proceeds to the next steps

        if (checkIfEveryFieldIsSaved()) {
          // If any field is empty, it throws an error and returns

          if (checkIfFieldNotEmpty()) {
            setErrorFeedBack("You can't have rules with empty criteria!");
            return;
          }
          if (checkIfFieldValid()) {
            setErrorFeedBack("Criteria names cannot start with a space!");
            return;
          }
          // If all fields are saved and none of them is empty,
          // it updates the criteria by marking all fields as saved
          const updatedCriteria = {
            ...currentCriteria,
            pttoos: (currentCriteria?.pttoos ?? []).map((pttoo: { saved: boolean; name: Maybe<string> }) => ({
              ...pttoo,
              saved: true,
            })),
            memos: (currentCriteria.memos ?? []).map((memo: { saved: boolean; name: Maybe<string> }) => ({
              ...memo,
              saved: true,
            })),
            senders: (currentCriteria.senders ?? []).map((sender: { saved: boolean; name: Maybe<string> }) => ({
              ...sender,
              saved: true,
            })),
          };
          setCurrentCriteria(updatedCriteria);

          // If there exists an empty rule and it's not a master rule, it throws an error and returns
        } else if (getEmptyRuleIfExists() && !isMasterRule) {
          setErrorFeedBack("You already have a rule that does not have any condition!");
          return;
        }
        const shouldReprioritize =
          autodepositRules?.data?.auto_deposit_rules?.length! >= (mainRule ? 2 : 1) &&
          !showReviewRules &&
          (drawerSource === "edit" || !rule?.id);

        // If there's more than one rule (excluding master) and auto deposit rules exist, it proceeds to the next steps

        if (shouldReprioritize) {
          // If the rule has an id, it updates the conditions and bank account for the rule in the current priority

          if (rule?.id) {
            const updatedRuleIdx = autodepositRules?.data?.auto_deposit_rules?.indexOf(
              autodepositRules?.data?.auto_deposit_rules?.find((item) => {
                return item?.id === rule.id;
              })!,
            );
            rulePriority![updatedRuleIdx!].conditions = criteriaToBeSent!;
            rulePriority![updatedRuleIdx!].bank_account = selectedBankAccount!;

            // Updates rulePriority state
            setRulePriority([...rulePriority!]);
          }

          // Shows the review rules option and clears the error feedback
          setShowReviewRules(true);
          setErrorFeedBack("");

          // If the bank account is selected and criteria are to be sent, it proceeds to the next steps
        } else if (selectedBankAccount && criteriaToBeSent) {
          setIsLoading(true);

          // If the rule has an id, it updates the auto deposit rule; otherwise, it creates a new one
          if (rule?.id) {
            await updateAutoDepositRule(rule?.id!, selectedBankAccount?.id!, criteriaToBeSent!, [...(ids as string[])]);
          } else {
            await createAutoDepositRule(selectedBankAccount?.id!, criteriaToBeSent!, [...(ids as string[])]);
          }
          // Fetches new auto deposit rules
          await AutoDepositRulesStore.getAutoDepositRules(session.data?.account?.email_address_id!, true);
          setShowReviewRules(false);
          setIsDrawerOpen(false);
          setErrorFeedBack("");
          setIsLoading(false);
        }
        // If no bank account is selected but review rules option is showing, it proceeds to the next steps
      } else if (showReviewRules) {
        setIsLoading(true);

        // Updates the auto deposit rule priority
        await AutoDepositRulesStore.UpdateAutoDepositRulePriority(session.data?.account?.email_address_id!, [
          ...(ids as string[]),
        ]);

        // Fetches new auto deposit rules
        await AutoDepositRulesStore.getAutoDepositRules(session.data?.account?.email_address_id!, true);

        // Resets the application state
        setShowReviewRules(false);
        setIsDrawerOpen(false);
        setIsLoading(false);
      } else {
        // If no bank account is selected, it throws an error
        setErrorFeedBack("You need to select a bank account first!");
      }
    } catch (error) {
      setErrorFeedBack("An error occurred while processing your request");
    }
  };
  const checkIfPriorityModified = () => {
    return (
      rulePriority
        ?.filter((item) => item)
        ?.map((item) => item.id)
        .includes("priority_placeholder") && drawerSource === "reprioritize"
    );
  };

  const handleClose = async () => {
    setSelectedRule(undefined);
    await AutoDepositRulesStore.getAutoDepositRules(session.data?.account?.email_address_id!, !!errorFeedBack);
    setErrorFeedBack("");
    setShowReviewRules(false);
    setCriteriaToBeSent(undefined);
    setSelectedAccount("");
    setIsLoading(false);
    setIsDrawerOpen(false);
  };

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [selectedAccount, setSelectedAccount] = useState("");
  const handleSelectAccountBtnClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSelectBaClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (
    account: Maybe<
      {
        __typename?: "BankAccountBasic" | undefined;
      } & Pick<BankAccountBasic, "name" | "id" | "account_number" | "routing_number" | "bank_name">
    >,
  ) => {
    setSelectedAccount(account?.name!);
    setSelectedBankAccount(account!);
    setErrorFeedBack("");
    setIsLoading(false);
    setAnchorEl(null);
  };

  const hasTwoRulesAndIsAdding =
    autodepositRules?.data?.auto_deposit_rules?.length! >= 2 &&
    drawerSource === "add" &&
    !rule?.id &&
    detemineIfAlreadyCreatedMainRule();
  const isConfirmOrNext = (showConfirmButton && !!rule?.id) || showReviewRules;

  return (
    <Drawer open={isDrawerOpen} onClose={handleClose} anchor="right">
      <Grid flexWrap={"nowrap"} height={"100%"} flexDirection="column" justifyContent={"space-between"} container item>
        {!showReviewRules && (
          <Grid width={"45vw"} padding={"30px"} flexDirection={"column"} container>
            <Grid marginBottom={"50px"} item>
              <Typography fontWeight={600} variant="h1">
                {drawerSource === "add" ? "   Add auto deposit rule" : "Edit auto deposit rule"}
              </Typography>
            </Grid>
            <Grid justifyContent={"flex-start"} flexDirection={"row"} container item>
              {
                <Grid xs={5} item>
                  <Typography marginBottom={"10px"} variant="h4" color="#052048">
                    Auto Deposit vChecks that are emailed to:
                  </Typography>
                  <Typography marginBottom={"40px"} variant="body1" color="#2877EC">
                    {session.data?.account?.email}
                  </Typography>
                  <Typography marginBottom={"10px"} fontWeight={600} variant="body1">
                    Auto deposit Bank Account
                  </Typography>
                  <Typography marginBottom={"10px"} variant="body2">
                    Bank accounts where the vChecks should be deposited
                  </Typography>
                  <SelectBankAccount
                    anchorEl={anchorEl}
                    handleClick={handleSelectAccountBtnClick}
                    handleMenuItemClick={handleMenuItemClick}
                    handleSelectBaClose={handleSelectBaClose}
                    orgWithCompaniesAndBAs={orgWithCompaniesAndBAs}
                    selectedAccount={selectedAccount}
                  />
                </Grid>
              }
            </Grid>
            <Grid marginTop={"40px"} item>
              <Typography marginBottom={"15px"} fontSize={"16px"} fontWeight={500} variant="body1">
                Additional criteria
              </Typography>
              <CriteriaComponent
                criteria={currentCriteria?.pttoos}
                title={`"Pay to the order of" name`}
                description={`Filter vChecks by the "Pay to the order of" name`}
                handleAddFilter={handleAddFilter}
                handleFilterInputChange={handleFilterInputChange}
                handleFilterInputSave={handleFilterInputSave}
                handleRemoveFilter={handleRemoveFilter}
                id={"pttoos"}
              />
              <CriteriaComponent
                criteria={currentCriteria?.senders}
                title={`Sender Name`}
                description={`Filter vChecks by the name of the sender`}
                handleAddFilter={handleAddFilter}
                handleFilterInputChange={handleFilterInputChange}
                handleFilterInputSave={handleFilterInputSave}
                handleRemoveFilter={handleRemoveFilter}
                id={"senders"}
              />
              <CriteriaComponent
                criteria={currentCriteria?.memos}
                title={`Check memos`}
                description={`Filter vChecks by the check memos`}
                handleAddFilter={handleAddFilter}
                handleFilterInputChange={handleFilterInputChange}
                handleFilterInputSave={handleFilterInputSave}
                handleRemoveFilter={handleRemoveFilter}
                id={"memos"}
              />
            </Grid>
          </Grid>
        )}
        {showReviewRules && (
          <ReviewRules
            rulePriority={rulePriority!}
            setRulePriority={setRulePriority}
            setShowReviewRules={setShowReviewRules}
            drawerSource={drawerSource}
            selectedRuleId={rule?.id!}
          />
        )}
        <Grid paddingBottom={"20px"} justifyContent={"center"} container item>
          <Typography variant="body1" color="red">
            {errorFeedBack}
          </Typography>
          <Grid container width={"100%"} sx={{ height: "1px" }} bgcolor="darkgrey" item />
          <Grid
            marginRight="40px"
            flexDirection={"row"}
            container
            alignItems={"center"}
            justifyContent="space-between"
            marginTop={"10px"}
            item
          >
            <Grid marginLeft="40px" alignItems={"center"} justifyContent="flex-end" marginTop={"10px"} item>
              {rule?.id && !showReviewRules && (
                <Button
                  variant="outlined"
                  onClick={() => {
                    handleConfirmOpenDeleteRuleModal(rule?.id!);
                  }}
                  sx={{
                    color: COLORS.darkRed,
                    fontWeight: 500,
                    borderColor: ` ${COLORS.darkRed} !important`,
                  }}
                >
                  Delete Rule
                </Button>
              )}
            </Grid>
            <Grid item>
              <Button onClick={handleClose} sx={{ paddingX: "20px" }} variant="outlined">
                Cancel
              </Button>
              <LoadingButton
                loading={isLoading}
                onClick={handleSaveOrNext}
                sx={{ marginLeft: "20px", paddingX: "25px" }}
                variant="contained"
                disabled={checkIfPriorityModified() || !!errorFeedBack?.length}
              >
                {isConfirmOrNext ? "Confirm and Save rule" : "Next"}
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Drawer>
  );
};

export default observer(AddEditRuleDrawer);
