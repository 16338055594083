import { SvgIcon, SvgIconProps } from "@mui/material";

export const EditBtnIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0833 1.85008C10.9432 1.85008 10.8045 1.87767 10.6751 1.93127C10.5457 1.98487 10.4281 2.06343 10.3291 2.16247L2.56362 9.92793L1.99798 12.0019L4.07199 11.4363L11.8375 3.67085C11.9365 3.57181 12.0151 3.45423 12.0687 3.32482C12.1223 3.19542 12.1498 3.05673 12.1498 2.91666C12.1498 2.77659 12.1223 2.6379 12.0687 2.5085C12.0151 2.37909 11.9365 2.26151 11.8375 2.16247C11.7384 2.06343 11.6208 1.98487 11.4914 1.93127C11.362 1.87767 11.2233 1.85008 11.0833 1.85008ZM10.2286 0.853407C10.4996 0.741175 10.79 0.683411 11.0833 0.683411C11.3765 0.683411 11.6669 0.741175 11.9379 0.853407C12.2088 0.965638 12.455 1.13014 12.6624 1.33751C12.8698 1.54489 13.0343 1.79108 13.1465 2.06203C13.2587 2.33298 13.3165 2.62339 13.3165 2.91666C13.3165 3.20993 13.2587 3.50034 13.1465 3.77129C13.0343 4.04224 12.8698 4.28843 12.6624 4.49581L4.78741 12.3708C4.71563 12.4426 4.62636 12.4944 4.52842 12.5211L1.32008 13.3961C1.11813 13.4512 0.902141 13.3938 0.75412 13.2458C0.606099 13.0978 0.548741 12.8818 0.60382 12.6798L1.47882 9.47151C1.50553 9.37357 1.55734 9.2843 1.62912 9.21251L9.50412 1.33751C9.71149 1.13014 9.95769 0.965638 10.2286 0.853407Z"
      />
    </SvgIcon>
  );
};
