import { useMemo } from "react";
import { ListChildComponentProps } from "react-window";
import { SuggestionCheckbox } from "../SuggestionCheckbox/SuggestionCheckbox";
import { IVBillMultiSelectAutocompleteSuggestion } from "../VBillSuggestionsMultiSelectAutocomplete";

interface IVirtualizedSuggestionCheckboxProps {
  suggestions: IVBillMultiSelectAutocompleteSuggestion[];
  onSuggestionChange: (suggestion: IVBillMultiSelectAutocompleteSuggestion, value: boolean) => void;
  searchTerm?: string;
}

export const VirtualizedSuggestionCheckbox = ({
  index,
  style,
  data: { suggestions, searchTerm = "", onSuggestionChange },
}: ListChildComponentProps<IVirtualizedSuggestionCheckboxProps>) => {
  const suggestion = useMemo(() => suggestions[index], [index, suggestions]);

  return (
    <SuggestionCheckbox
      suggestion={suggestion}
      onSuggestionChange={onSuggestionChange}
      style={style}
      searchTerm={searchTerm}
    />
  );
};
