import { IVBillVBillInvoiceQuery, IVBillVBillSettingsQuery } from "generated/sdk.vbill";
import { useEffect, useState } from "react";

export function useVBillInvoiceVendorId(
  invoice?: IVBillVBillInvoiceQuery["invoice"] | null,
  settingsData?: IVBillVBillSettingsQuery["settings"],
) {
  const [vendorId, setVendorId] = useState<undefined | string>();

  useEffect(() => {
    if (!invoice) {
      return setVendorId(undefined);
    }
    const contactKey = settingsData?.vBill.additionalMappings.invoice.find((mapping) => mapping.isContact);

    if (!contactKey) {
      return setVendorId(undefined);
    }

    const invoiceMapping = invoice?.additionalMappings?.find((mapping) => mapping.key === contactKey.key);

    if (!invoiceMapping) {
      return setVendorId(undefined);
    }

    return setVendorId(invoiceMapping.value);
  }, [invoice, settingsData]);

  return vendorId;
}
