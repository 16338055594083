import SearchOutlined from "@mui/icons-material/SearchOutlined";
import { TextField, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Modal from "@mui/material/Modal";
import OutlinedInput from "@mui/material/OutlinedInput";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Card from "components/common/old/Card";
import { CompanyIntegration, ExternalAutoSigner, OrganizationUser, OrganizationUserEnum } from "generated/sdk";
import { definitelyFilter } from "generated/utils";
import { sum } from "lodash";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { FixedSizeList as List } from "react-window";
import { useStore } from "storeContainer";
import { Spinner } from "../../../../common/Spinner/Spinner";
import styles from "./Integration.module.scss";
import MappingHeader from "./MappingHeader";
import OtherUserModal from "./OtherUserModal";

const UNMAPPED_USERS_SPECIAL_VALUE = "***";

export const IntegrationUserMapping = observer(function IntegrationUserMapping({
  companyId,
  readOnly,
  integrationIdProp,
  defaultExpanded,
}: {
  companyId: string;
  readOnly: boolean;
  integrationIdProp?: string;
  defaultExpanded?: boolean;
}) {
  const integrationsStore = useStore("IntegrationsStore");
  const organizationUsersStore = useStore("OrganizationUsersStore");
  let { integrationId, organizationId } = useParams<{ integrationId: string; organizationId: string }>();
  const [companyIntegration, setCompanyIntegration] = useState<CompanyIntegration | undefined>();
  const [external_auto_signers, set_external_auto_signers] = useState<ExternalAutoSigner[] | undefined>();

  const assignPropsIfExists = () => {
    if (integrationIdProp) integrationId = integrationIdProp;
  };
  assignPropsIfExists();
  useEffect(() => {
    integrationsStore.load(companyId);
    integrationsStore.loadAllKnownExtSignerNames();
  }, [companyId, integrationsStore]);

  useEffect(() => {
    if (companyIntegration && companyIntegration.external_auto_signers) {
      set_external_auto_signers(companyIntegration?.external_auto_signers);
    }
  }, [companyIntegration]);

  useEffect(() => {
    const integrations: CompanyIntegration[] = definitelyFilter(
      integrationsStore.companyIntegrationsList.data?.integrations,
    );
    const selectedIntegration = integrations.find((i) => i.integration?.integration_id === integrationId);
    setCompanyIntegration(selectedIntegration);
  }, [companyIntegration, integrationId, integrationsStore.companyIntegrationsList.data]);

  const organizationUsers = definitelyFilter(organizationUsersStore.organizationUsersList.data).filter(
    (ou) => ou.state === OrganizationUserEnum.Active || ou.state === OrganizationUserEnum.Invited,
  ) as OrganizationUser[];

  const [externalOptions, setExternalOptions] = useState<{ value: string; label: string; dataElementId?: string }[]>(
    [],
  );

  useEffect(() => {
    const allKnownExtSignerNamesList = definitelyFilter(integrationsStore.allKnownExtSignerNamesList.data)
      .filter((e) => !!e)
      .sort();

    const allKnownExtSignerNamesListUniq = [...new Set(allKnownExtSignerNamesList)]
      .filter((e) => e !== UNMAPPED_USERS_SPECIAL_VALUE)
      .sort();

    setExternalOptions([
      { value: UNMAPPED_USERS_SPECIAL_VALUE, label: "[Unmapped users]", dataElementId: "unmapped_users" },
      { value: "", label: "[Empty user]", dataElementId: "empty_user" },
      ...allKnownExtSignerNamesListUniq.map((e) => ({ value: e, label: e })),
    ]);
  }, [integrationsStore.allKnownExtSignerNamesList.data]);

  const handleOnChange = async (organizationUser: OrganizationUser, selectedValues: string[]) => {
    await integrationsStore.syncExternalAutoSigners(organizationUser.id, integrationId, companyId, selectedValues);
  };

  // const external_auto_signers = definitelyFilter(companyIntegration?.external_auto_signers);

  const setup_status = integrationsStore.companyIntegrationsList.data?.setup_status;

  if (!companyIntegration) return null;

  const showSpinner =
    !integrationsStore.companyIntegrationsList.isLoaded && integrationsStore.companyIntegrationsList.isFetching;
  const isLoading =
    integrationsStore.companyIntegrationsList.isFetching || integrationsStore.isExternalAutoSignersSaving;

  return (
    <Card
      className={styles.mappingCard}
      title={`Users (${setup_status?.mapped_organization_users}/${sum([
        setup_status?.mapped_organization_users,
        setup_status?.unmapped_organization_users,
      ])})`}
      defaultExpanded={defaultExpanded || false}
      headerProps={{
        mapped: setup_status?.mapped_organization_users,
        unmapped: setup_status?.unmapped_organization_users,
        "data-element-id": "users",
      }}
      components={{ Header: MappingHeader }}
    >
      <div>* Note: Users must also have vCheck Create permissions</div>
      <br />
      {showSpinner ? (
        <Spinner />
      ) : (
        <TableContainer component={Paper} sx={{ filter: isLoading ? "blur(4px)" : undefined }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Name in Cherry</TableCell>
                <TableCell align="right">Name in {companyIntegration.integration?.name}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!integrationsStore.allKnownExtSignerNamesList.isLoaded &&
              integrationsStore.allKnownExtSignerNamesList.isFetching ? (
                <Spinner />
              ) : integrationsStore.allKnownExtSignerNamesList.data ? (
                organizationUsers.map((organizationUser) => (
                  <TableRow key={organizationUser.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      <a
                        href={`/org/${organizationId}/users2/permissions/user/${organizationUser.id}`}
                        target="_blank"
                        rel="noreferrer"
                        style={{ textDecoration: "none", color: "#000" }}
                        title="Open user's permissions"
                      >
                        <strong>{organizationUser.account?.name || organizationUser.account?.email}</strong>{" "}
                        <small title={`Account state: ${organizationUser.account?.state}`}>
                          {organizationUser.state}
                        </small>
                        {/* <small>{organizationUser.account?.state}</small> */}
                        <div>{organizationUser.account?.email}</div>
                      </a>
                    </TableCell>
                    <TableCell align="right">
                      <IntegrationUserAndBankAccountMappingSelector
                        options={externalOptions}
                        initialSelectedValues={definitelyFilter(external_auto_signers)
                          .filter((e) => e.organization_user_id === organizationUser.id)
                          .map((e) => e.name)
                          .filter((n): n is string => n !== null && n !== undefined)}
                        onChange={(selectedValues) => handleOnChange(organizationUser, selectedValues)}
                        readOnly={readOnly}
                        isLoading={isLoading}
                      />
                    </TableCell>
                  </TableRow>
                ))
              ) : null}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Card>
  );
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const IntegrationUserAndBankAccountMappingSelector = ({
  options,
  initialSelectedValues,
  onChange,
  readOnly,
  isLoading,
}: {
  options: { value: string; label: string; dataElementId?: string }[];
  initialSelectedValues: string[];
  onChange: (selectedValues: string[]) => void;
  readOnly: boolean;
  isLoading: boolean;
}) => {
  const [selectedValues, setSelectedValues] = useState<string[]>(initialSelectedValues);
  const [otherNames, setOtherNames] = useState<{ value: string; label: string; dataElementId?: string }[]>([]);
  const [modalOtherName, setModalOtherName] = useState<string>("");
  const [visibileInputOther, setVisibileInputOther] = useState(false);
  const [open, setOpen] = useState(false);

  const otherNameValue = "-1";

  useEffect(() => {
    setOtherNames([]);
    setSelectedValues(initialSelectedValues);
  }, [initialSelectedValues]);

  const handleClick = (value: string) => {
    setOpen(false);

    const selected = typeof value === "string" ? value.split(",") : value;
    if (selected.indexOf(otherNameValue) !== -1) {
      setVisibileInputOther(true);
    } else {
      onChange(selected);
    }
    setSelectedValues(selected);
  };

  const handleModalClose = () => {
    setVisibileInputOther(false);
    setSelectedValues(selectedValues.filter((e) => e !== otherNameValue));
  };

  const handleModalSave = () => {
    if (modalOtherName) {
      setOtherNames([...otherNames, { label: modalOtherName, value: modalOtherName }]);
      const newSelectedValues = [...selectedValues, modalOtherName].filter((e) => e !== otherNameValue);
      setSelectedValues(newSelectedValues);
      setVisibileInputOther(false);
      onChange(newSelectedValues);
    } else handleModalClose();
    setModalOtherName("");
  };

  const allOptions = [...options, ...otherNames];
  const [displayedOptions, setDisplayedOptions] = useState(allOptions);
  const [searchedValue, setSearchedValue] = useState("");
  const handleSearchChange = (e: any) => {
    setSearchedValue(e.target.value);
    setDisplayedOptions(
      allOptions.filter((option) => option.label.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase())),
    );
  };

  // we treat the special case when we select the None Checkbox and it must not appear the value and must not be checked
  // none is added in components/pages/Settings/Integrations/Integration/utils.tsx:42
  const Row = ({ index, style }: any) => (
    <MenuItem
      key={displayedOptions?.[index]?.value}
      value={displayedOptions?.[index]?.value}
      style={{ ...style, padding: "0 20px" }}
      onClick={() => {
        handleClick(displayedOptions?.[index]?.value);
      }}
      data-element-id={displayedOptions?.[index]?.dataElementId ?? displayedOptions?.[index]?.value}
    >
      <Checkbox
        checked={
          selectedValues?.indexOf(displayedOptions?.[index]?.value) > -1 && displayedOptions?.[index]?.value !== "none"
        }
      />
      <Typography sx={{ whiteSpace: "break-spaces", overflowWrap: "break-word" }}>
        {displayedOptions?.[index]?.label}
      </Typography>
    </MenuItem>
  );
  const showList = !readOnly && !isLoading && displayedOptions.length > 0;
  return (
    <>
      <Modal open={visibileInputOther} onClose={handleModalClose}>
        <div>
          <OtherUserModal
            handleClose={handleModalClose}
            handleSave={handleModalSave}
            setModalOtherName={setModalOtherName}
          />
        </div>
      </Modal>
      <FormControl fullWidth disabled={isLoading}>
        <Select
          multiple
          sx={{
            minWidth: 200,
            fieldset: {
              border: "none",
              outline: "0.5px solid #DFE7F2",
            },
            height: selectedValues.length > 2 ? "84px" : "42px",
            width: "320px",
          }}
          value={selectedValues}
          input={<OutlinedInput />}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5, maxHeight: "80px", overflowY: "auto" }}>
              {selected
                .map((s) => allOptions.find((o) => o.value === s)?.label)
                .map((label) => (label === "None" ? null : <Chip key={label} label={label} />))}
            </Box>
          )}
          MenuProps={MenuProps}
          inputProps={{ readOnly: readOnly }}
          open={open}
          onClick={(e: any) => {
            if (e.target.type === "text") return;
            setOpen(!open);
          }}
          onClose={() => setOpen(false)}
          data-element-id="select_field"
        >
          <TextField
            fullWidth
            onKeyDown={(e) => e.stopPropagation()}
            value={searchedValue}
            onChange={(e) => {
              handleSearchChange(e);
            }}
            InputProps={{
              endAdornment: <SearchOutlined />,
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                  border: "none",
                  outline: "0.5px solid #DFE7F2",
                },
              },
              paddingX: "20px",
            }}
            data-element-id="search_field"
          />
          {showList && (
            <List
              height={150}
              itemCount={displayedOptions.length}
              itemSize={50}
              width="100%"
              className={styles.scrollbar}
            >
              {Row}
            </List>
          )}

          {!readOnly && isLoading && (
            <MenuItem>
              <Typography> Loading...</Typography>
            </MenuItem>
          )}
        </Select>
      </FormControl>
    </>
  );
};
