import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import { formatCurrency } from "common/helpers/utils";
import { AssignmentIcon, EventAvailableIcon, EventBusyIcon } from "components/common/icons";
import { format } from "date-fns";
import { CheckFiltersType } from "generated/sdk";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { FormattedPlural } from "react-intl";
import { useParams } from "react-router";
import { useStore } from "storeContainer";
import { COLORS } from "themes/default";
import { JsonParam, useQueryParam } from "use-query-params";
import AddEditBatchModal from "./AddEditBatchModal";
import ButtonDialogConfirm from "./ButtonDialogConfirm";
import ConfirmDeleteBatchModal from "./ConfirmDeleteBatchModal";
import { useBatchDetailsContext } from "./useBatchDetailsContext";

const tooltipTypographyStyles = { fontSize: "14px", color: COLORS.white, padding: "5px" };
const tooltipDefaultProps = {
  arrow: true,
  disableFocusListener: true,
  disableTouchListener: true,
};

export const CheckBatchDetailsList = observer(function CheckBatchDetailsList() {
  const BudgetManagementStore = useStore("BudgetManagementStore");
  const [filters] = useQueryParam<CheckFiltersType>("filters", JsonParam);
  const {
    fetchCheckBatch,
    fetchCheckBatches,
    checkBatchData,
    signedBatchData,
    signBatch,
    availableBankAccountsForBatchQuery,
  } = BudgetManagementStore;
  const { organizationId, batchId } = useParams<{ organizationId: string; batchId: string }>();
  const [batchQuery, setBatchQuery] = useState(checkBatchData.data?.GetCheckBatchQuery);
  const [detailsAnchorEl, setDetailsAnchorEl] = useState<null | HTMLElement>(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const detailsOpenMoreHorizMenu = Boolean(detailsAnchorEl);
  const isOverBudget = (batchQuery?.budget as number) < (batchQuery?.items_amount as number);
  const [isAddBatchModalOpen, setIsAddBatchModalOpen] = useState(false);
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState(false);
  const {
    props: { isBatchClosed, isLoading },
  } = useBatchDetailsContext();

  const handleSignBatch = async () => {
    await signBatch(batchId, true);
    await fetchCheckBatches(organizationId, undefined, { page: 1, per_page: 10 }, true);
    await fetchCheckBatch(batchId, undefined, { per_page: 10, page: 1 }, true);
    setOpenConfirmDialog(true);
  };
  const handleConfirmDialogClose = () => setOpenConfirmDialog(false);

  useEffect(() => {
    if (checkBatchData.data?.GetCheckBatchQuery) {
      setBatchQuery(checkBatchData.data?.GetCheckBatchQuery);
    }
  }, [checkBatchData.data?.GetCheckBatchQuery]);

  return (
    <>
      <Paper sx={{ marginBottom: "10px", display: "flex", justifyContent: "space-between", padding: "10px" }}>
        <Box sx={{ display: "flex", gap: "20px" }}>
          <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
            <Tooltip
              {...tooltipDefaultProps}
              title={<Typography sx={tooltipTypographyStyles}>Status: {isBatchClosed ? "Closed" : "Open"}</Typography>}
            >
              {isBatchClosed ? (
                <EventBusyIcon sx={{ fontSize: "25px", color: COLORS.black }} />
              ) : (
                <EventAvailableIcon sx={{ fontSize: "25px", color: COLORS.success }} />
              )}
            </Tooltip>
            <Box>
              <Tooltip
                {...tooltipDefaultProps}
                title={
                  <Typography sx={tooltipTypographyStyles}>
                    Created by: @{batchQuery?.created_by_organization_user?.account?.name} at{" "}
                    {format(new Date(batchQuery?.created_at ?? new Date()), "MM/dd/yy hh:mm")}
                  </Typography>
                }
              >
                <Typography>
                  Name: <strong>{batchQuery?.name}</strong>
                </Typography>
              </Tooltip>
              <Tooltip
                {...tooltipDefaultProps}
                title={<Typography sx={tooltipTypographyStyles}>Company name</Typography>}
              >
                <Typography component="span" sx={{ fontSize: "14px" }}>
                  Company: <strong>{batchQuery?.company?.legal_name}</strong>
                </Typography>
              </Tooltip>{" "}
              <Tooltip
                {...tooltipDefaultProps}
                title={
                  <Typography sx={tooltipTypographyStyles}>
                    {availableBankAccountsForBatchQuery?.data?.GetAvailableBankAccountsForBatchQuery?.rows?.map(
                      (item) => <div key={item?.id}>{item?.name}</div>,
                    )}
                  </Typography>
                }
              >
                <Typography component="span">
                  (on{" "}
                  {availableBankAccountsForBatchQuery?.data?.GetAvailableBankAccountsForBatchQuery?.pagination?.total}{" "}
                  bank accounts)
                </Typography>
              </Tooltip>
            </Box>
          </Box>

          <Box>
            <Tooltip
              {...tooltipDefaultProps}
              title={
                <Typography sx={tooltipTypographyStyles}>
                  <div>Used: {formatCurrency(batchQuery?.items_amount ?? 0)}</div>
                  <div>Remaining: {formatCurrency((batchQuery?.budget ?? 0) - (batchQuery?.items_amount ?? 0))}</div>
                  <div>Total: {formatCurrency(batchQuery?.budget ?? 0)}</div>
                </Typography>
              }
            >
              <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
                <AccountBalanceWalletIcon sx={{ fontSize: "25px" }} />
                <Box>
                  <Typography>Budget:</Typography>
                  <Typography>
                    <strong>
                      <Box component="span" sx={{ color: isOverBudget ? COLORS.darkRed : COLORS.black }}>
                        {formatCurrency(batchQuery?.items_amount ?? 0)}
                      </Box>{" "}
                      / {formatCurrency(batchQuery?.budget ?? 0)}
                    </strong>
                  </Typography>
                </Box>
              </Box>
            </Tooltip>
          </Box>

          <Box>
            <Tooltip
              {...tooltipDefaultProps}
              title={
                <Typography sx={tooltipTypographyStyles}>
                  <div>
                    Signed: {(batchQuery?.items_count ?? 0) - (batchQuery?.unsigned_count ?? 0)} /{" "}
                    {batchQuery?.items_count}{" "}
                  </div>
                  <div>
                    Checks: {formatCurrency(batchQuery?.items_amount as number)} /{" "}
                    {formatCurrency(batchQuery?.budget as number)}
                  </div>
                </Typography>
              }
            >
              <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
                <AssignmentIcon sx={{ fontSize: "25px" }} />
                <Box>
                  <Typography>Sign:</Typography>
                  <Typography>
                    <strong>
                      {(batchQuery?.items_count ?? 0) - (batchQuery?.unsigned_count ?? 0)} / {batchQuery?.items_count}
                    </strong>
                  </Typography>
                </Box>
              </Box>
            </Tooltip>
          </Box>
        </Box>

        <Box sx={{ display: "flex", gap: "20px", alignItems: "center" }}>
          <ButtonDialogConfirm
            Icon={<DoneOutlineIcon />}
            onAgree={handleSignBatch}
            disabled={
              isLoading || !batchQuery?.items?.rows?.length || isBatchClosed || !batchQuery?.current_user_may_sign
            }
            title={
              !batchQuery?.current_user_may_sign
                ? "You don't have the correct permission to sign this batch"
                : "Sign all checks in this Check Batch"
            }
            contentText={
              <>
                There <FormattedPlural one="is" other="are" value={batchQuery?.items?.pagination?.total ?? 0} />{" "}
                {batchQuery?.items?.pagination?.total}{" "}
                <FormattedPlural one="check" other="checks" value={batchQuery?.items?.pagination?.total ?? 0} />{" "}
                amounting to {formatCurrency(batchQuery?.items_amount as number)}, only the checks that you are allowed
                to sign will be signed.
              </>
            }
            buttonText="Sign Batch"
            onDisagree={() => {}}
            variant="contained"
          />

          <IconButton
            onClick={(e) => {
              setDetailsAnchorEl(e.currentTarget);
            }}
          >
            <MoreHorizIcon />
          </IconButton>
          <Menu
            id="more-batch-options"
            anchorEl={detailsAnchorEl}
            open={detailsOpenMoreHorizMenu}
            onClose={() => setDetailsAnchorEl(null)}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              onClick={() => {
                if (isBatchClosed) {
                  return;
                }
                setIsAddBatchModalOpen(true);
                setDetailsAnchorEl(null);
              }}
              disabled={isBatchClosed}
            >
              Edit Batch
            </MenuItem>
            <MenuItem
              onClick={() => {
                setIsConfirmDeleteModalOpen(true);
                setDetailsAnchorEl(null);
              }}
              sx={{ color: "red" }}
            >
              Delete Batch
            </MenuItem>
          </Menu>
        </Box>
      </Paper>

      <Dialog onClose={handleConfirmDialogClose} open={openConfirmDialog}>
        <DialogTitle>Sign batch results</DialogTitle>
        <DialogContent>
          <List>
            <ListItem>
              <ListItemAvatar>
                <BorderColorIcon />
              </ListItemAvatar>
              <ListItemText
                primary={`${
                  signedBatchData?.data?.SignCheckBatchMutation?.checks_available_for_signing ?? 0
                } checks are available for signing`}
              />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <CheckCircleOutlineIcon />
              </ListItemAvatar>
              <ListItemText
                primary={`${signedBatchData?.data?.SignCheckBatchMutation?.signed_checks ?? 0} have been signed`}
              />
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Box display="flex" width="100%" justifyContent="center">
            <Button variant="contained" disabled={isLoading} onClick={handleConfirmDialogClose}>
              Ok
            </Button>
          </Box>
        </DialogActions>
      </Dialog>

      {isAddBatchModalOpen && (
        <AddEditBatchModal
          selectedBatchToEdit={checkBatchData?.data?.GetCheckBatchQuery}
          isAddBatchModalOpen
          setIsAddBatchModalOpen={setIsAddBatchModalOpen}
          batchFilters={filters}
          reloadBatch
        />
      )}

      {isConfirmDeleteModalOpen && (
        <ConfirmDeleteBatchModal
          selectedBatchId={batchQuery?.id ?? ""}
          isConfirmDeleteModalOpen
          setIsDeleteConfirmModalOpen={setIsConfirmDeleteModalOpen}
          onDeleteNavigateToLedger
        />
      )}
    </>
  );
});
