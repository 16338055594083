import { graphqlClient } from "common/graphqlClient";

import {
  AccountStateEnum,
  AclEntityTypeEnum,
  AclSourceEnum,
  AppliedPermissionGroup,
  getSdk,
  OrganizationUserEnum,
  PermissionGroup,
  PermissionGroupInput,
} from "generated/sdk";
import { definitelyFilter, enumValues, flattenUnionOfArrayTypes, UnionToIntersection, Unpacked } from "generated/utils";
import _ from "lodash";
import { action, computed, makeObservable, observable, toJS } from "mobx";
import { createObservableContainer } from "storeContainer";
import { AclPermissionGroupSettings, defaultAclPermissionGroupsInfo, IAclPermissionGroupSettings } from "./AclStore";
import { StoreBase } from "./StoreBase";

const {
  PermissionsGroups,
  OrganizationCompaniesWithBankaccounts,
  AclPermissionsOfOrganizationUser,
  AclPermissionsOfUserGroup,
  ACLOrganizationDirectLimits,
  ACLOrganizationAggregatedLimits,
  ACLOrganizationInheritedLimits,
  ApplyACL,
} = getSdk(graphqlClient);

const fetchOrganizationCompaniesWithBankaccounts = async (organization_id: string) =>
  definitelyFilter((await OrganizationCompaniesWithBankaccounts({ organization_id })).OrganizationDashboard.companies);
const fetchPermissionGroups = async (): Promise<PermissionGroup[]> =>
  definitelyFilter((await PermissionsGroups()).PermissionsGroups);
const fetchOrganizationDirectLimits = async (organization_id: string): Promise<AppliedPermissionGroup> =>
  (await ACLOrganizationDirectLimits({ organization_id })).ACLPermissionAggregatedLimits || {};

const fetchOrganizationAggregatedLimits = async (
  organization_id: string,
  organization_user_id?: string,
  user_group_id?: string,
): Promise<AppliedPermissionGroup> =>
  (
    await ACLOrganizationAggregatedLimits({
      organization_id,
      organization_user_id,
      user_group_id,
    })
  ).ACLPermissionAggregatedLimits || {};

const fetchOrganizationInheritedLimitsOfUser = async (
  organization_id: string,
  organization_user_id?: string,
  user_group_id?: string,
): Promise<AppliedPermissionGroup> =>
  (
    await ACLOrganizationInheritedLimits({
      organization_id,
      organization_user_id,
      user_group_id,
    })
  ).ACLPermissionAggregatedLimits || {};

const fetchOrganizationUserAclPermissions = async (organization_user_id: string): Promise<AppliedPermissionGroup> =>
  (await AclPermissionsOfOrganizationUser({ organization_user_id })).ACLPermissions || {};
const fetchUserGroupAclPermissions = async (user_group_id: string): Promise<AppliedPermissionGroup> =>
  (await AclPermissionsOfUserGroup({ user_group_id })).ACLPermissions || {};

export type PermissionGroupWithSettings = PermissionGroup & {
  internal_alias: PermissionGroupTypes;
  settings: IAclPermissionGroupSettings;
  appliesTo: AclEntityTypeEnum[];
};

export type PermissionGroupTypes = keyof Omit<NonNullable<AppliedPermissionGroup>, "__typename">;

export type PermissionTypes = NonNullable<Unpacked<AppliedPermissionGroup[PermissionGroupTypes]>>;

export type PermissionTypesCommon = Omit<PermissionTypes, "__typename" | "acl_source">;
export type PermissionTypesCommonKeys = keyof PermissionTypesCommon;

export type PermissionTypesAll = Omit<UnionToIntersection<PermissionTypes>, "__typename" | "acl_source">;

export type PermissionTypesLimits = Omit<
  UnionToIntersection<PermissionTypes>,
  "__typename" | "acl_source" | PermissionTypesCommonKeys
>;
export type PermissionTypesLimitsKeys = keyof PermissionTypesLimits;

export const extractLimits = (perm?: PermissionTypesAll): PermissionTypesLimits =>
  _.omit(perm || {}, [
    "organization_user_id",
    "user_group_id",
    "entity_type",
    "entity_id",
    "orig_entity_type",
    "orig_entity_id",
    "acl_source",
  ]);
export type AppliedPermissionGroup2<T = PermissionTypes[]> = { [pg in PermissionGroupTypes]?: T };

const filterAppliedPermissionGroup = (
  apg: AppliedPermissionGroup | AppliedPermissionGroup2,
  filter?: (p: PermissionTypes) => boolean,
) => {
  const pgs = Object.keys(_.omit(apg, "__typename")) as PermissionGroupTypes[];
  const outApg: AppliedPermissionGroup2 = {};
  pgs.forEach((pg) => {
    const permissions = flattenUnionOfArrayTypes(apg[pg] || []);
    const filteredPermissions = filter ? permissions.filter(filter) : permissions;
    outApg[pg] = filteredPermissions;
  });
  return outApg;
};

const reduceAppliedPermissionGroup = <T>(
  apg: AppliedPermissionGroup | AppliedPermissionGroup2,
  reducer: (p: PermissionTypes[]) => T,
) => {
  const pgs = Object.keys(_.omit(apg, "__typename")) as PermissionGroupTypes[];
  const outApg: AppliedPermissionGroup2<T> = {};
  pgs.forEach((pg) => {
    const permissions = flattenUnionOfArrayTypes(apg[pg] || []);
    const reducedPermissions = reducer(permissions);
    outApg[pg] = reducedPermissions;
  });
  return outApg;
};

const mapAppliedPermissionGroup = <T>(
  apg: AppliedPermissionGroup | AppliedPermissionGroup2,
  map: (p: PermissionTypes) => T,
) => {
  const pgs = Object.keys(_.omit(apg, "__typename")) as PermissionGroupTypes[];
  const outApg: AppliedPermissionGroup2<T[]> = {};
  pgs.forEach((pg) => {
    const permissions = flattenUnionOfArrayTypes(apg[pg] || []);
    const mappedPermissions = permissions.map(map);
    outApg[pg] = mappedPermissions;
  });
  return outApg;
};

export class AclStore2 extends StoreBase {
  constructor() {
    super();
    makeObservable(this);
  }

  private _permissionGroups = createObservableContainer<PermissionGroup[]>();
  directAppliedPermissions = createObservableContainer<AppliedPermissionGroup2>();
  private _organizationStructure =
    createObservableContainer<Awaited<ReturnType<typeof fetchOrganizationCompaniesWithBankaccounts>>>();

  @observable applyInProgress: boolean = false;

  async reload(forceUpdate?: true) {
    const promises: Promise<any>[] = [];

    if (this.storeContainer) {
      promises.push(this.storeContainer.OrganizationUsersStore.loadOrganizationUsers());
      promises.push(this.storeContainer.UserGroupsStore.loadUserGroups());
    }
    promises.push(this._permissionGroups.cachedLoad(() => fetchPermissionGroups()));

    const { selectedOrganizationId } = this.storeContainer?.ContextStore!;
    if (selectedOrganizationId) {
      promises.push(
        this.directAppliedPermissions.cachedLoad(
          () =>
            fetchOrganizationDirectLimits(selectedOrganizationId)
              // #FIXME: Agg should not expad group permissions for each user
              .then((dap) =>
                mapAppliedPermissionGroup(dap, (p) => ({
                  ...p,
                  organization_user_id: p.user_group_id ? undefined : p.organization_user_id,
                })),
              )
              .then((dap) => reduceAppliedPermissionGroup(dap, (pl) => _.uniqWith(pl, _.isEqual))),
          [selectedOrganizationId],
          { forceUpdate },
        ),
      );
      promises.push(
        this._organizationStructure.cachedLoad(
          () => fetchOrganizationCompaniesWithBankaccounts(selectedOrganizationId),
          [selectedOrganizationId],
          // { forceUpdate }
        ),
      );
    }

    await Promise.all(promises);
  }

  async flush() {
    this.directAppliedPermissions.flush();
  }

  @computed get isFetching() {
    return (
      this.applyInProgress ||
      this._permissionGroups.isFetching ||
      this.directAppliedPermissions.isFetching ||
      this._organizationStructure.isFetching ||
      this.storeContainer?.OrganizationUsersStore.organizationUsersList.isFetching ||
      this.storeContainer?.UserGroupsStore.userGroupUsersList.isFetching
    );
  }

  @computed
  get organizationEntitiesList() {
    const selectedOrganization = this.storeContainer?.ContextStore.selectedOrganization;
    if (!selectedOrganization || !selectedOrganization.name || !this._organizationStructure.data?.length) return [];

    const entries: { id: string; name: string; type: AclEntityTypeEnum; parents: string[] }[] = [];

    entries.push({
      id: selectedOrganization.id,
      name: selectedOrganization.name,
      type: AclEntityTypeEnum.Organization,
      parents: [],
    });

    this._organizationStructure.data
      ?.filter((c) => c?.id && c.name)
      .forEach((company) => {
        entries.push({
          id: company.id,
          name: company.name,
          type: AclEntityTypeEnum.Company,
          parents: [selectedOrganization.id],
        });
        company.bank_accounts
          ?.filter((ba) => ba?.id && ba.name)
          .forEach((ba) => {
            entries.push({
              id: ba.id,
              name: `${ba.name} *${ba.account_number}`,
              type: AclEntityTypeEnum.BankAccount,
              parents: [selectedOrganization.id, company.id],
            });
          });
      });

    return entries;
  }

  @computed
  get organizationUsersList() {
    const userList = this.storeContainer?.OrganizationUsersStore?.organizationUsersList?.data
      ? this.storeContainer.OrganizationUsersStore.organizationUsersList.data
      : [];
    // console.log("ZZZ userList", toJS(userList));
    return _.sortBy(
      userList?.map((ou) => ({
        id: ou.id,
        name: ou.account?.name || ou.account?.email || ou.id,
        email: ou.account?.email || undefined,
        state: ou.state || undefined,
        accountState: ou.account?.state || undefined,
        invite_id: ou.invites_ids?.[0] || undefined,
        user_groups: ou.user_groups || undefined,
        // permissions: ou.aclPermissions || undefined,
      })) || [],
      ["name", "email"],
    );
  }

  @computed
  get organizationGroupList() {
    return _.sortBy(
      this.storeContainer?.UserGroupsStore.userGroupsList.data
        ?.filter((e) => !!e.id)
        .map((ug) => ({
          id: ug.id!,
          name: ug.name || ug.id!,
          description: ug.description || undefined,
          organization_users: _.sortBy(
            ug.organization_users?.map((ou) => ({
              id: ou.id,
              // name: ou.account?.name || ou.account?.email || ou.id,
              // email: ou.account?.email || undefined,
              // state: ou.state || undefined,
              // accountState: ou.account?.state || undefined,
              // invite_id: ou.invites_ids?.[0] || undefined,
              // userGroups: ou.userGroups || undefined,
              // permissions: undefined,
            })) || [],
            ["name", "email"],
          ),
        })) || [],
      ["name"],
    );
  }

  @action
  async apply(perm: PermissionGroupInput) {
    this.applyInProgress = true;
    await ApplyACL({ acls: perm });
    await this.reload(true);
    this.applyInProgress = false;
  }

  @computed
  get directPermissionsList(): IAcl2PermissionEntry[] {
    const map: { [path: string]: IAcl2PermissionEntry } = {};

    const dap = this.directAppliedPermissions.data || {};
    const organizationEntitiesList = this.organizationEntitiesList;
    const users = this.organizationUsersList;
    const groups = this.organizationGroupList;

    const valuesAclEntityTypeEnum = enumValues(AclEntityTypeEnum);

    _.forEach(dap, (_permissions, _pg) => {
      const permissions = flattenUnionOfArrayTypes(_permissions || []);
      permissions.forEach((permission) => {
        const organization_user_id = permission.organization_user_id || undefined;
        const user_group_id = permission.user_group_id || undefined;
        const entity_type = permission.entity_type || undefined;
        const entity_id = permission.entity_id || undefined;

        const isUser = !!organization_user_id;
        const user_id = organization_user_id || user_group_id;

        if (user_id && entity_id && entity_type) {
          const path = [isUser ? "U" : "G", user_id, entity_type, entity_id].join("-");

          if (map[path]) return;

          const user = isUser ? users?.find((u) => u.id === user_id) : undefined;
          const group = !isUser ? groups?.find((u) => u.id === user_id) : undefined;

          const user_name = (isUser ? user?.name : group?.name) || user_id;

          const entity = organizationEntitiesList.find((e) => e.id === entity_id && e.type === entity_type);
          const entity_name = entity?.name || entity_id;

          const mapEntry = {
            isUser,
            user_id,
            user_name,
            user,
            group,

            entity_id,
            entity_type,
            entity_name,
            entity,

            sort1: isUser ? 0 : 1,
            sort2: user_name,
            sort3: valuesAclEntityTypeEnum.indexOf(entity_type),
            sort4: entity_name,
          };

          map[path] = mapEntry;
        }
      });
    });

    return _.chain(map)
      .values()
      .sortBy(["sort1", "sort2", "sort3", "sort4"])
      .map((e) => _.omit(e, ["sort1", "sort2", "sort3", "sort4"]))
      .value() as IAcl2PermissionEntry[];
  }

  getDirectPermissionsOnEntity(isUser: boolean, user_id: string, entity_type: AclEntityTypeEnum, entity_id: string) {
    const dap = this.directAppliedPermissions.data || {};

    const filteredDap = filterAppliedPermissionGroup(
      dap,
      (p) =>
        (isUser ? p.organization_user_id === user_id && !p.user_group_id : p.user_group_id === user_id) &&
        p.entity_type === entity_type &&
        p.entity_id === entity_id,
    );

    const reducedDap = reduceAppliedPermissionGroup(filteredDap, (p) => {
      if (p.length > 1) {
        console.error(
          "Error multiple permissions found for",
          { isUser, user_id, entity_type, entity_id, count: p.length },
          p,
          toJS(filteredDap),
        );
      }
      return p[0];
    });

    return reducedDap;
  }

  getDirectPermissions(isUser: boolean, user_id: string) {
    const dap = this.directAppliedPermissions.data || {};

    return filterAppliedPermissionGroup(dap, (p) =>
      isUser ? p.organization_user_id === user_id && !p.user_group_id : p.user_group_id === user_id,
    );
  }

  async getAggregatedPermissions(user_id?: string, group_id?: string) {
    const { selectedOrganizationId } = this.storeContainer?.ContextStore!;
    if (selectedOrganizationId) {
      // FIXME: Agg should return for groups also
      // const permissions = group_id
      //   ? await fetchOrganizationAggregatedLimits(selectedOrganizationId, undefined, group_id)
      //   : await fetchOrganizationAggregatedLimits(selectedOrganizationId, user_id);

      const permissions = group_id
        ? mapAppliedPermissionGroup(await fetchUserGroupAclPermissions(group_id), (p) => ({
            ...p,
            acl_source: AclSourceEnum.Direct,
          }))
        : await fetchOrganizationAggregatedLimits(selectedOrganizationId, user_id);
      return permissions || {};
    }
    return {};
  }

  private _getInheritedPermissionsCache = createObservableContainer<AppliedPermissionGroup2<PermissionTypes[]>>();
  async getInheritedPermissions(user_id?: string, group_id?: string, forceUpdate?: true) {
    const { selectedOrganizationId } = this.storeContainer?.ContextStore!;
    if (selectedOrganizationId) {
      const fetcher = () => {
        if (group_id) {
          return fetchOrganizationInheritedLimitsOfUser(selectedOrganizationId, undefined, group_id)
            .then((dap) =>
              mapAppliedPermissionGroup(dap, (p) => ({
                ...p,
                organization_user_id: p.user_group_id ? undefined : p.organization_user_id,
              })),
            )
            .then((dap) => reduceAppliedPermissionGroup(dap, (pl) => _.uniqWith(pl, _.isEqual)));
        }
        return fetchOrganizationInheritedLimitsOfUser(selectedOrganizationId, user_id)
          .then((dap) =>
            mapAppliedPermissionGroup(dap, (p) => ({
              ...p,
              // organization_user_id: p.user_group_id ? undefined : p.organization_user_id,
            })),
          )
          .then((dap) => reduceAppliedPermissionGroup(dap, (pl) => _.uniqWith(pl, _.isEqual)));
      };

      return (
        (await this._getInheritedPermissionsCache.cachedLoad(fetcher, [user_id, group_id], { forceUpdate })).data || {}
      );
      // this._getInheritedPermissionsCache.data || {};
    }
    return {};
  }

  private _getDirectAppliedPermissionsCache = createObservableContainer<AppliedPermissionGroup2>();
  async getDirectAppliedPermissions(user_id?: string, group_id?: string, forceUpdate?: true) {
    const { selectedOrganizationId } = this.storeContainer?.ContextStore!;
    if (selectedOrganizationId && (user_id || group_id)) {
      const fetcher = () => {
        if (group_id) {
          // FIXME: Agg (direct) for groups should only return direct
          return fetchUserGroupAclPermissions(group_id).then((dap) =>
            mapAppliedPermissionGroup(dap, (p) => ({
              ...p,
              acl_source: AclSourceEnum.Direct,
            })),
          );
        }
        return fetchOrganizationUserAclPermissions(user_id!).then((dap) =>
          mapAppliedPermissionGroup(dap, (p) => ({
            ...p,
            acl_source: AclSourceEnum.Direct,
          })),
        );
      };
      this._getDirectAppliedPermissionsCache.cachedLoad(fetcher, [user_id, group_id], { forceUpdate });
      return this._getDirectAppliedPermissionsCache.data || {};
    }
    return {};
  }

  @computed
  get PermissionGroups(): PermissionGroupWithSettings[] {
    return definitelyFilter(
      this._permissionGroups.data
        ?.map((permissionGroup) => ({
          ...permissionGroup,
          internal_alias: permissionGroup.internal_alias as PermissionGroupTypes,
          settings: this.getAclPermissionsSettings(permissionGroup.internal_alias as any),
        }))
        .map((e) => ({
          ...e,
          appliesTo: [
            ...(e.settings.appliesToOrganization && !e.settings.appliesToCompany && !e.settings.appliesToBankAccount
              ? [AclEntityTypeEnum.Organization]
              : []),
            ...(e.settings.appliesToOrganization && e.settings.appliesToCompany && !e.settings.appliesToBankAccount
              ? [AclEntityTypeEnum.Company]
              : []),
            ...(e.settings.appliesToOrganization && e.settings.appliesToCompany && e.settings.appliesToBankAccount
              ? [AclEntityTypeEnum.BankAccount]
              : []),
          ],
        })),
    );
  }

  getAclPermissionsSettings(permissionGroup: keyof typeof AclPermissionGroupSettings): IAclPermissionGroupSettings {
    return AclPermissionGroupSettings[permissionGroup] || defaultAclPermissionGroupsInfo;
  }
}

export interface IAcl2PermissionEntry {
  isUser: boolean;
  user_id: string;
  user_name: string;
  user?: {
    id: string;
    name: string;
    email: string | undefined;
    state: OrganizationUserEnum | undefined;
    accountState: AccountStateEnum | undefined;
  };
  group?: {
    id: string;
    name: string;
    description?: string;
  };
  entity_type: AclEntityTypeEnum;
  entity_id: string;
  entity_name: string;
  entity?: {
    id: string;
    name: string;
    type: AclEntityTypeEnum;
  };
}
