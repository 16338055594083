import ChevronRight from "@mui/icons-material/ChevronRight";
import { Grid, Modal, Paper, Typography } from "@mui/material";
import IconButton from "components/common/IconButton";
import CherryBlueLogo from "icons/svg/cherry_blue_logo.svg?react";
import React, { useState } from "react";
import { COLORS } from "themes/default";
import { ChecksStatusData1, ChecksStatusData2, ChecksStatusData3, ChecksStatusEmails } from "./ChecksStatusData";
import PortalStatusItem from "./PortalStatusItem";
import PortalStatusItemPage2 from "./PortalStatusItemPage2";

const ChecksStatusDialog: React.FC<{ isStatusDialogOpen: boolean; setIsStatusDialogOpen: Function }> = ({
  isStatusDialogOpen,
  setIsStatusDialogOpen,
}) => {
  const [dialogPage, setDialogPage] = useState(1);
  return (
    <Modal
      sx={{ display: "flex", overflow: "scroll" }}
      open={isStatusDialogOpen}
      onClose={() => setIsStatusDialogOpen(false)}
    >
      <Grid width={"65%"} height="auto" margin="auto" justifyContent="center" alignItems="center" container>
        <Paper
          sx={{
            width: "100%",
            height: "100%",
            padding: 6,
            paddingTop: dialogPage % 2 === 1 ? 0 : 6,
            marginTop: 3,
            display: "flex",
          }}
        >
          <Grid>
            {dialogPage % 2 === 1 ? (
              <>
                <Grid xs={12} marginTop={10} item>
                  <Typography color={COLORS.darkText} marginBottom={6} fontSize={64}>
                    PORTAL STATUSES
                  </Typography>
                </Grid>
                <Grid justifyContent={"space-between"} container>
                  <Grid xs={5.7} container item>
                    {ChecksStatusData1.map((value, index) => {
                      return (
                        <div key={`${value}-${index}-1`}>
                          {index === 5 ? (
                            <Typography fontSize={28} marginBottom={1}>
                              DIGITAL VChecks
                            </Typography>
                          ) : null}
                          <PortalStatusItem title={value.title}>{value.body}</PortalStatusItem>
                        </div>
                      );
                    })}
                  </Grid>
                  <Grid xs={5.7} flexDirection="column" justifyContent={"space-between"} container item>
                    <Grid item container>
                      {" "}
                      {ChecksStatusData2.map((value, index) => {
                        return (
                          <div key={`${value}-${index}-2`}>
                            {index === 0 ? (
                              <Typography fontSize={28} marginBottom={1}>
                                PRINTED CHECKS
                              </Typography>
                            ) : index === 2 ? (
                              <Typography fontSize={28} marginBottom={1}>
                                CARD PAYMENTS
                              </Typography>
                            ) : null}
                            <PortalStatusItem title={value.title}>{value.body}</PortalStatusItem>
                          </div>
                        );
                      })}
                    </Grid>
                    <Grid alignSelf={"flex-end"}>
                      <CherryBlueLogo />
                    </Grid>
                  </Grid>
                </Grid>
              </>
            ) : (
              <Grid justifyContent={"start"} container>
                <Grid xs={8} flexDirection="column" container item>
                  <Typography
                    lineHeight={1.3}
                    color={COLORS.darkText}
                    fontWeight={600}
                    fontSize={25}
                    whiteSpace={"nowrap"}
                    alignItems="center"
                  >
                    Things to Keep In Mind:
                  </Typography>
                  {ChecksStatusData3.map((value, index) => {
                    return (
                      <div key={`${value}-${index}-3`}>
                        <PortalStatusItemPage2>{value.body}</PortalStatusItemPage2>
                      </div>
                    );
                  })}
                </Grid>
                <Grid xs={3} flexDirection="column" justifyContent={"space-between"} container item>
                  <Grid item container>
                    <Typography
                      lineHeight={1.3}
                      color={COLORS.darkText}
                      fontWeight={300}
                      fontSize={25}
                      whiteSpace={"nowrap"}
                      alignItems="center"
                    >
                      Emails To Know:
                    </Typography>
                    {ChecksStatusEmails.map((value, index) => {
                      return (
                        <Typography
                          lineHeight={2}
                          fontSize={17}
                          color={"black"}
                          fontWeight={400}
                          display={"inline"}
                          key={`${value}-${index}-emails`}
                        >
                          {value.body}
                        </Typography>
                      );
                    })}
                  </Grid>
                  <Grid item alignSelf={"flex-end"}>
                    <CherryBlueLogo />
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
          <IconButton
            onClick={() => setDialogPage(dialogPage + 1)}
            sx={{ height: "44px", width: "44px", alignSelf: "center" }}
          >
            <ChevronRight />
          </IconButton>
        </Paper>
      </Grid>
    </Modal>
  );
};

export default ChecksStatusDialog;
