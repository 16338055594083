import { ChatActivityEnumType, CheckRequiredActionActionEnumType, CheckSearchOptionsEnumType } from "generated/sdk";

interface IConfigLedgerFilters {
  [context: string]: {
    [status: string]: any;
  };
}
export interface ProcessingOption {
  value: string;
  label: string;
  options: Array<{ value: string; label: string }>;
}

export interface ProcessingTypeFilterConfig {
  type: LedgerFilterInputType.ProcessingTypeFilter;
  order: number;
  placeholder: string;
  label: string;
  keyName: string;
  keyNameInput: string;
  keyNameOptions: string;
  tooltip: string;
  processingOptions: ProcessingOption[];
}

export enum LedgerFilterInputType {
  AdvancedSearchFilter = "AdvancedSearchFilter",
  AdvancedDateFilter = "AdvancedDateFilter",
  InputAmount = "InputAmount",
  SimpleSelectFilter = "SimpleSelectFilter",
  CheckStateSelectFilter = "CheckStateSelectFilter",
  CheckActionRequiredSelectFilter = "CheckActionRequiredSelectFilter",
  CheckActionRequiredDropdownSelectFilter = "CheckActionRequiredDropdownSelectFilter",
  CheckStatusSelectFilter = "CheckStatusSelectFilter",
  BankAccountsFilter = "BankAccountsFilter",
  ProcessingTypeFilter = "ProcessingTypeFilter",
  ActivityDropdownSelectFilter = "UnresolvedChatsActivityDropdownSelectFilter",
}

const formatEnumValue = (value: string): string => value.replace(/_/g, "-");

export const checkActionRequiredDropdownCherryResponseOptions = [
  formatEnumValue(CheckRequiredActionActionEnumType.CardNotRevealed),
  formatEnumValue(CheckRequiredActionActionEnumType.CheckWasSignedNotDeposited),
  formatEnumValue(CheckRequiredActionActionEnumType.CheckDeliveredNotDeposited),
  formatEnumValue(CheckRequiredActionActionEnumType.BankAccountLocked),
  formatEnumValue(CheckRequiredActionActionEnumType.AchInsufficientFunds),
  formatEnumValue(CheckRequiredActionActionEnumType.StopPayment),
  formatEnumValue(CheckRequiredActionActionEnumType.CardWasSentNotCharged),
  formatEnumValue(CheckRequiredActionActionEnumType.MailedNotDelivered),
];
export const checkActionRequiredDropdownCustomerResponseOptions = [
  formatEnumValue(CheckRequiredActionActionEnumType.VoidRequested),
  formatEnumValue(CheckRequiredActionActionEnumType.InvoiceDiscrepancy),
  formatEnumValue(CheckRequiredActionActionEnumType.PaymentDiscrepancy),
  formatEnumValue(CheckRequiredActionActionEnumType.PayeeNameDoesNotMatchBankAccountName),
  formatEnumValue(CheckRequiredActionActionEnumType.MissingInformation),
  formatEnumValue(CheckRequiredActionActionEnumType.ExpiredPaperChecks),
  formatEnumValue(CheckRequiredActionActionEnumType.AchCorporateCustomerAdvisesNotAuthorized),
];
export const checkActionRequiredDropdownCherryVendorOptions = [
  formatEnumValue(CheckRequiredActionActionEnumType.PendingBankEmailConfirmation),
  formatEnumValue(CheckRequiredActionActionEnumType.PendingBankVerification),
  formatEnumValue(CheckRequiredActionActionEnumType.AchReturnOther),
  formatEnumValue(CheckRequiredActionActionEnumType.AchUnableToLocateAccount),
  formatEnumValue(CheckRequiredActionActionEnumType.WrongBankInformation),
];

const defaultAdvancedSearchFilter = {
  type: LedgerFilterInputType.AdvancedSearchFilter,
  value: "",
  placeholder: "search ",
  label: "Search text",
  keyNameInput: "search",
  keyNameOptions: "search_options",
  tooltip: "Search by the Check Number, Pay-To-The-Order-Of Name, Sender, Recipient, Memo or Remittance details",
  searchOptions: [
    { value: CheckSearchOptionsEnumType.CheckNumber, label: "Check Number" },
    { value: CheckSearchOptionsEnumType.Recipient, label: "Recipient" },
    { value: CheckSearchOptionsEnumType.Sender, label: "Sender" },
  ],
};

const defaultAdvancedDateFilter = {
  type: LedgerFilterInputType.AdvancedDateFilter,
  placeholder: "date",
  label: "Select Date",
  keyNameInput: "date",
  keyNameContextOptions: "context",
  keyNamePresetOption: "preset",
  keyNameCustomRange: ["from", "to"],
  contextOptions: [
    { value: "created_at", label: "Check Synced", default: true },
    { value: "date", label: "Check Date", default: false },
    { value: "signed_at", label: "Check Signed", default: false },
  ],
  presetOptions: [
    { value: "", label: "Any Time", default: true },
    { value: "last_day", label: "Last Day", default: false },
    { value: "last_week", label: "Last Week", default: false },
    { value: "last_month", label: "Last Month", default: false },
    { value: "last_year", label: "Last Year", default: false },
    { value: "custom_type", label: "Custom Range", default: false },
  ],
};

const defaultInputAmount = {
  type: LedgerFilterInputType.InputAmount,
  keyNameMinValue: "amount_from",
  keyNameMaxValue: "amount_to",
  label: "Amount",
};

const defaultCheckActionRequiredSelect = {
  type: LedgerFilterInputType.CheckActionRequiredSelectFilter,
  keyName: "required_action",
  options: [
    { value: "pending-bank-verification", label: "Pending Bank Verification" },
    { value: "requested-additional-documentation", label: "Requested Additional Documentation" },
    { value: "check-delivered-not-deposited", label: "Check Delivered Not Deposited" },
    { value: "no-recipient-email", label: "No Recipient Email" },
    { value: "phone-number-is-required", label: "Phone Number Is Required" },
    { value: "no-mailing-address-available", label: "No Mailing Address Available" },
    { value: "check-was-signed-not-deposited", label: "Check Was Signed Not Deposited" },
    { value: "card-was-sent-not-charged", label: "Card Was Sent Not Charged" },
  ],
  label: "Required Action",
};

export const configLedgerFilters = {
  payments: {
    all: {
      [LedgerFilterInputType.AdvancedSearchFilter]: {
        type: LedgerFilterInputType.AdvancedSearchFilter,
        order: 1,
        value: "",
        placeholder: "search ",
        label: "Search text",
        keyName: "search",
        keyNameInput: "search_input",
        keyNameOptions: "search_options",
        keyNameAdditionalInput: "additional_search_input",
        keyNameAdditionalOptions: "additional_search_options",
        tooltip: "Search by the Check Number, Sender or Recipient",
        searchOptions: [
          { value: CheckSearchOptionsEnumType.CheckNumber, label: "Check Number" },
          { value: CheckSearchOptionsEnumType.Recipient, label: "Recipient" },
          { value: CheckSearchOptionsEnumType.Sender, label: "Sender" },
        ],
        additionalSearchOptions: [
          { value: CheckSearchOptionsEnumType.CheckNumber, label: "Check Number" },
          { value: CheckSearchOptionsEnumType.Recipient, label: "Recipient" },
          { value: CheckSearchOptionsEnumType.Sender, label: "Sender" },
        ],
      },
      [LedgerFilterInputType.AdvancedDateFilter]: {
        type: LedgerFilterInputType.AdvancedDateFilter,
        order: 2,
        placeholder: "date",
        label: "Select Date",
        keyNameInput: "date",
        keyNameContextOptions: "context",
        keyNamePresetOption: "preset",
        keyNameCustomRange: ["from", "to"],
        contextOptions: [
          { value: "created_at", label: "Check Synced", default: false },
          { value: "date", label: "Check Date", default: false },
          { value: "signed_at", label: "Check Signed", default: true },
        ],
        presetOptions: [
          { value: "", label: "Any Time", default: true },
          { value: "last_day", label: "Last Day", default: false },
          { value: "last_week", label: "Last Week", default: false },
          { value: "last_month", label: "Last Month", default: false },
          { value: "last_year", label: "Last Year", default: false },
          { value: "custom_type", label: "Custom Range", default: false },
        ],
      },
      [LedgerFilterInputType.InputAmount]: {
        type: LedgerFilterInputType.InputAmount,
        order: 3,
        keyNameMinValue: "amount_from",
        keyNameMaxValue: "amount_to",
        label: "Amount",
      },
      [LedgerFilterInputType.CheckActionRequiredDropdownSelectFilter]: {
        type: LedgerFilterInputType.CheckActionRequiredDropdownSelectFilter,
        order: 3.5,
        keyName: "required_action",
        options: [
          {
            value: checkActionRequiredDropdownCherryResponseOptions,
            label: "Awaiting Cherry Response",
            type: "AWAITING_CHERRY_RESPONSE",
          },
          {
            value: checkActionRequiredDropdownCustomerResponseOptions,
            label: "Awaiting Customer Response",
            type: "AWAITING_CUSTOMER_RESPONSE",
          },
          {
            value: checkActionRequiredDropdownCherryVendorOptions,
            label: "Awaiting Vendor Response",
            type: "AWAITING_VENDOR_RESPONSE",
          },
        ],
        label: "Required Action",
      },
      // [LedgerFilterInputType.CheckActionRequiredSelectFilter]: {
      //   type: LedgerFilterInputType.CheckActionRequiredSelectFilter,
      //   order: 4,
      //   keyName: "required_action",
      //   options: [
      //     {
      //       value: formatEnumValue(CheckRequiredActionActionEnumType.PendingBankEmailConfirmation),
      //       label: "Pending Bank Email Confirmation",
      //     },
      //     { value: formatEnumValue(CheckRequiredActionActionEnumType.BankAccountLocked), label: "Bank Account Locked" },
      //     {
      //       value: formatEnumValue(CheckRequiredActionActionEnumType.MissingInformation),
      //       label: "Missing Information",
      //     },
      //     {
      //       value: formatEnumValue(CheckRequiredActionActionEnumType.WrongBankInformation),
      //       label: "Wrong Bank Information",
      //     },
      //     {
      //       value: formatEnumValue(CheckRequiredActionActionEnumType.VoidRequested),
      //       label: "Void Requested",
      //     },
      //     {
      //       value: formatEnumValue(CheckRequiredActionActionEnumType.StopPayment),
      //       label: "Stop Payment Requested",
      //     },
      //     {
      //       value: formatEnumValue(CheckRequiredActionActionEnumType.InvoiceDiscrepancy),
      //       label: "Invoice Discrepancy",
      //     },
      //     {
      //       value: formatEnumValue(CheckRequiredActionActionEnumType.CheckWasSignedNotDeposited),
      //       label: "Check Was Signed, Not Deposited",
      //     },
      //     {
      //       value: formatEnumValue(CheckRequiredActionActionEnumType.CheckDeliveredNotDeposited),
      //       label: "Check Delivered, Not Deposited",
      //     },
      //     {
      //       value: formatEnumValue(CheckRequiredActionActionEnumType.ExpiredPaperChecks),
      //       label: "Expired Paper Checks (90+Days)",
      //     },
      //     {
      //       value: formatEnumValue(CheckRequiredActionActionEnumType.CardNotRevealed),
      //       label: "Card Not Revealed",
      //     },
      //     {
      //       value: formatEnumValue(CheckRequiredActionActionEnumType.CardWasSentNotCharged),
      //       label: "Card Was Sent, Not Charged",
      //     },
      //     {
      //       value: formatEnumValue(CheckRequiredActionActionEnumType.AchInsufficientFunds),
      //       label: "ACH Insufficient Funds",
      //     },
      //     {
      //       value: formatEnumValue(CheckRequiredActionActionEnumType.AchUnableToLocateAccount),
      //       label: "ACH Unable To Locate Account",
      //     },
      //     {
      //       value: formatEnumValue(CheckRequiredActionActionEnumType.AchCorporateCustomerAdvisesNotAuthorized),
      //       label: "ACH Corporate Customer Advises Not Authorized",
      //     },
      //     {
      //       value: formatEnumValue(CheckRequiredActionActionEnumType.AchReturnOther),
      //       label: "ACH Return Other",
      //     },
      //   ],
      //   label: "Required Action",
      // },
      [LedgerFilterInputType.ProcessingTypeFilter]: {
        type: LedgerFilterInputType.ProcessingTypeFilter,
        order: 6,
        placeholder: "Processing Type...",
        label: "Processing Type",
        keyName: "processing_type",
        keyNameInput: "processor",
        keyNameOptions: "sub_status",
        tooltip: "Filter By Processing Type",
        processingOptions: [
          {
            value: "ach",
            label: "ACH",
            options: [],
          },
          {
            value: "card",
            label: "Card",
            options: [],
          },
          {
            value: "print",
            label: "Print",
            options: [],
          },
        ],
      },
    },
    pending_signature: {
      [LedgerFilterInputType.AdvancedSearchFilter]: {
        type: LedgerFilterInputType.AdvancedSearchFilter,
        order: 1,
        value: "",
        placeholder: "search ",
        label: "Search text",
        keyName: "search",
        keyNameInput: "search_input",
        keyNameOptions: "search_options",
        keyNameAdditionalInput: "additional_search_input",
        keyNameAdditionalOptions: "additional_search_options",
        tooltip: "Search by the Check Number, Sender or Recipient",
        searchOptions: [
          { value: CheckSearchOptionsEnumType.CheckNumber, label: "Check Number" },
          { value: CheckSearchOptionsEnumType.Recipient, label: "Recipient" },
          { value: CheckSearchOptionsEnumType.Sender, label: "Sender" },
        ],
        additionalSearchOptions: [
          { value: CheckSearchOptionsEnumType.CheckNumber, label: "Check Number" },
          { value: CheckSearchOptionsEnumType.Recipient, label: "Recipient" },
          { value: CheckSearchOptionsEnumType.Sender, label: "Sender" },
        ],
      },
      [LedgerFilterInputType.AdvancedDateFilter]: {
        type: LedgerFilterInputType.AdvancedDateFilter,
        order: 2,
        placeholder: "date",
        label: "Select Date",
        keyNameInput: "date",
        keyNameContextOptions: "context",
        keyNamePresetOption: "preset",
        keyNameCustomRange: ["from", "to"],
        contextOptions: [
          { value: "created_at", label: "Check Synced", default: true },
          { value: "date", label: "Check Date", default: false },
        ],
        presetOptions: [
          { value: "", label: "Any Time", default: true },
          { value: "last_day", label: "Last Day", default: false },
          { value: "last_week", label: "Last Week", default: false },
          { value: "last_month", label: "Last Month", default: false },
          { value: "last_year", label: "Last Year", default: false },
          { value: "custom_type", label: "Custom Range", default: false },
        ],
      },
      [LedgerFilterInputType.InputAmount]: {
        type: LedgerFilterInputType.InputAmount,
        order: 3,
        keyNameMinValue: "amount_from",
        keyNameMaxValue: "amount_to",
        label: "Amount",
      },
    },
    processing: {
      [LedgerFilterInputType.AdvancedSearchFilter]: {
        type: LedgerFilterInputType.AdvancedSearchFilter,
        order: 1,
        value: "",
        placeholder: "search ",
        label: "Search text",
        keyName: "search",
        keyNameInput: "search_input",
        keyNameOptions: "search_options",
        keyNameAdditionalInput: "additional_search_input",
        keyNameAdditionalOptions: "additional_search_options",
        tooltip: "Search by the Check Number, Sender or Recipient",
        searchOptions: [
          { value: CheckSearchOptionsEnumType.CheckNumber, label: "Check Number" },
          { value: CheckSearchOptionsEnumType.Recipient, label: "Recipient" },
          { value: CheckSearchOptionsEnumType.Sender, label: "Sender" },
        ],
        additionalSearchOptions: [
          { value: CheckSearchOptionsEnumType.CheckNumber, label: "Check Number" },
          { value: CheckSearchOptionsEnumType.Recipient, label: "Recipient" },
          { value: CheckSearchOptionsEnumType.Sender, label: "Sender" },
        ],
      },
      [LedgerFilterInputType.AdvancedDateFilter]: {
        type: LedgerFilterInputType.AdvancedDateFilter,
        order: 2,
        placeholder: "date",
        label: "Select Date",
        keyNameInput: "date",
        keyNameContextOptions: "context",
        keyNamePresetOption: "preset",
        keyNameCustomRange: ["from", "to"],
        contextOptions: [
          { value: "created_at", label: "Check Synced", default: false },
          { value: "date", label: "Check Date", default: false },
          { value: "signed_at", label: "Check Signed", default: true },
        ],
        presetOptions: [
          { value: "", label: "Any Time", default: true },
          { value: "last_day", label: "Last Day", default: false },
          { value: "last_week", label: "Last Week", default: false },
          { value: "last_month", label: "Last Month", default: false },
          { value: "last_year", label: "Last Year", default: false },
          { value: "custom_type", label: "Custom Range", default: false },
        ],
      },
      [LedgerFilterInputType.InputAmount]: {
        type: LedgerFilterInputType.InputAmount,
        order: 3,
        keyNameMinValue: "amount_from",
        keyNameMaxValue: "amount_to",
        label: "Amount",
      },
      [LedgerFilterInputType.ProcessingTypeFilter]: {
        type: LedgerFilterInputType.ProcessingTypeFilter,
        order: 6,
        placeholder: "Processing Type...",
        label: "Processing Type",
        keyName: "processing_type",
        keyNameInput: "processor",
        keyNameOptions: "sub_status",
        tooltip: "Filter By Processing Type",
        processingOptions: [
          {
            value: "ach",
            label: "ACH",
            options: [],
          },
          {
            value: "card",
            label: "Card",
            options: [],
          },
          {
            value: "print",
            label: "Print",
            options: [],
          },
        ],
      },
    },
    action_required: {
      [LedgerFilterInputType.AdvancedSearchFilter]: {
        type: LedgerFilterInputType.AdvancedSearchFilter,
        order: 1,
        value: "",
        placeholder: "search ",
        label: "Search text",
        keyName: "search",
        keyNameInput: "search_input",
        keyNameOptions: "search_options",
        keyNameAdditionalInput: "additional_search_input",
        keyNameAdditionalOptions: "additional_search_options",
        tooltip: "Search by the Check Number, Sender or Recipient",
        searchOptions: [
          { value: CheckSearchOptionsEnumType.CheckNumber, label: "Check Number" },
          { value: CheckSearchOptionsEnumType.Recipient, label: "Recipient" },
          { value: CheckSearchOptionsEnumType.Sender, label: "Sender" },
        ],
        additionalSearchOptions: [
          { value: CheckSearchOptionsEnumType.CheckNumber, label: "Check Number" },
          { value: CheckSearchOptionsEnumType.Recipient, label: "Recipient" },
          { value: CheckSearchOptionsEnumType.Sender, label: "Sender" },
        ],
      },
      [LedgerFilterInputType.AdvancedDateFilter]: {
        type: LedgerFilterInputType.AdvancedDateFilter,
        order: 2,
        placeholder: "date",
        label: "Select Date",
        keyNameInput: "date",
        keyNameContextOptions: "context",
        keyNamePresetOption: "preset",
        keyNameCustomRange: ["from", "to"],
        contextOptions: [
          { value: "created_at", label: "Check Synced", default: false },
          { value: "date", label: "Check Date", default: false },
          { value: "signed_at", label: "Check Signed", default: true },
        ],
        presetOptions: [
          { value: "", label: "Any Time", default: true },
          { value: "last_day", label: "Last Day", default: false },
          { value: "last_week", label: "Last Week", default: false },
          { value: "last_month", label: "Last Month", default: false },
          { value: "last_year", label: "Last Year", default: false },
          { value: "custom_type", label: "Custom Range", default: false },
        ],
      },
      [LedgerFilterInputType.InputAmount]: {
        type: LedgerFilterInputType.InputAmount,
        order: 3,
        keyNameMinValue: "amount_from",
        keyNameMaxValue: "amount_to",
        label: "Amount",
      },
      [LedgerFilterInputType.CheckActionRequiredDropdownSelectFilter]: {
        type: LedgerFilterInputType.CheckActionRequiredDropdownSelectFilter,
        order: 3.5,
        keyName: "required_action",
        options: [
          {
            value: checkActionRequiredDropdownCherryResponseOptions,
            label: "Awaiting Cherry Response",
            type: "AWAITING_CHERRY_RESPONSE",
          },
          {
            value: checkActionRequiredDropdownCustomerResponseOptions,
            label: "Awaiting Customer Response",
            type: "AWAITING_CUSTOMER_RESPONSE",
          },
          {
            value: checkActionRequiredDropdownCherryVendorOptions,
            label: "Awaiting Vendor Response",
            type: "AWAITING_VENDOR_RESPONSE",
          },
        ],
        label: "Required Action",
      },
      // [LedgerFilterInputType.CheckActionRequiredSelectFilter]: {
      //   type: LedgerFilterInputType.CheckActionRequiredSelectFilter,
      //   order: 4,
      //   keyName: "required_action",
      //   options: [
      //     {
      //       value: formatEnumValue(CheckRequiredActionActionEnumType.PendingBankEmailConfirmation),
      //       label: "Pending Bank Email Confirmation",
      //     },
      //     { value: formatEnumValue(CheckRequiredActionActionEnumType.BankAccountLocked), label: "Bank Account Locked" },
      //     {
      //       value: formatEnumValue(CheckRequiredActionActionEnumType.MissingInformation),
      //       label: "Missing Information",
      //     },
      //     {
      //       value: formatEnumValue(CheckRequiredActionActionEnumType.WrongBankInformation),
      //       label: "Wrong Bank Information",
      //     },
      //     {
      //       value: formatEnumValue(CheckRequiredActionActionEnumType.VoidRequested),
      //       label: "Void Requested",
      //     },
      //     {
      //       value: formatEnumValue(CheckRequiredActionActionEnumType.StopPayment),
      //       label: "Stop Payment Requested",
      //     },
      //     {
      //       value: formatEnumValue(CheckRequiredActionActionEnumType.InvoiceDiscrepancy),
      //       label: "Invoice Discrepancy",
      //     },
      //     {
      //       value: formatEnumValue(CheckRequiredActionActionEnumType.CheckWasSignedNotDeposited),
      //       label: "Check Was Signed, Not Deposited",
      //     },
      //     {
      //       value: formatEnumValue(CheckRequiredActionActionEnumType.CheckDeliveredNotDeposited),
      //       label: "Check Delivered, Not Deposited",
      //     },
      //     {
      //       value: formatEnumValue(CheckRequiredActionActionEnumType.ExpiredPaperChecks),
      //       label: "Expired Paper Checks (90+Days)",
      //     },
      //     {
      //       value: formatEnumValue(CheckRequiredActionActionEnumType.CardNotRevealed),
      //       label: "Card Not Revealed",
      //     },
      //     {
      //       value: formatEnumValue(CheckRequiredActionActionEnumType.CardWasSentNotCharged),
      //       label: "Card Was Sent, Not Charged",
      //     },
      //     {
      //       value: formatEnumValue(CheckRequiredActionActionEnumType.AchInsufficientFunds),
      //       label: "ACH Insufficient Funds",
      //     },
      //     {
      //       value: formatEnumValue(CheckRequiredActionActionEnumType.AchUnableToLocateAccount),
      //       label: "ACH Unable To Locate Account",
      //     },
      //     {
      //       value: formatEnumValue(CheckRequiredActionActionEnumType.AchCorporateCustomerAdvisesNotAuthorized),
      //       label: "ACH Corporate Customer Advises Not Authorized",
      //     },
      //     {
      //       value: formatEnumValue(CheckRequiredActionActionEnumType.AchReturnOther),
      //       label: "ACH Return Other",
      //     },
      //   ],
      //   label: "Required Action",
      // },
      [LedgerFilterInputType.ProcessingTypeFilter]: {
        type: LedgerFilterInputType.ProcessingTypeFilter,
        order: 6,
        placeholder: "Processing Type...",
        label: "Processing Type",
        keyName: "processing_type",
        keyNameInput: "processor",
        keyNameOptions: "sub_status",
        tooltip: "Filter By Processing Type",
        processingOptions: [
          {
            value: "ach",
            label: "ACH",
            options: [],
          },
          {
            value: "card",
            label: "Card",
            options: [],
          },
          {
            value: "print",
            label: "Print",
            options: [],
          },
        ],
      },
    },
    unresolved_chats: {
      [LedgerFilterInputType.AdvancedSearchFilter]: {
        type: LedgerFilterInputType.AdvancedSearchFilter,
        order: 1,
        value: "",
        placeholder: "search ",
        label: "Search text",
        keyName: "search",
        keyNameInput: "search_input",
        keyNameOptions: "search_options",
        keyNameAdditionalInput: "additional_search_input",
        keyNameAdditionalOptions: "additional_search_options",
        tooltip: "Search by the Check Number, Sender or Recipient",
        searchOptions: [
          { value: CheckSearchOptionsEnumType.CheckNumber, label: "Check Number" },
          { value: CheckSearchOptionsEnumType.Recipient, label: "Recipient" },
          { value: CheckSearchOptionsEnumType.Sender, label: "Sender" },
        ],
        additionalSearchOptions: [
          { value: CheckSearchOptionsEnumType.CheckNumber, label: "Check Number" },
          { value: CheckSearchOptionsEnumType.Recipient, label: "Recipient" },
          { value: CheckSearchOptionsEnumType.Sender, label: "Sender" },
        ],
      },
      [LedgerFilterInputType.AdvancedDateFilter]: {
        type: LedgerFilterInputType.AdvancedDateFilter,
        order: 2,
        placeholder: "date",
        label: "Select Date",
        keyNameInput: "date",
        keyNameContextOptions: "context",
        keyNamePresetOption: "preset",
        keyNameCustomRange: ["from", "to"],
        contextOptions: [
          { value: "created_at", label: "Check Synced", default: false },
          { value: "date", label: "Check Date", default: false },
          { value: "signed_at", label: "Check Signed", default: true },
        ],
        presetOptions: [
          { value: "", label: "Any Time", default: true },
          { value: "last_day", label: "Last Day", default: false },
          { value: "last_week", label: "Last Week", default: false },
          { value: "last_month", label: "Last Month", default: false },
          { value: "last_year", label: "Last Year", default: false },
          { value: "custom_type", label: "Custom Range", default: false },
        ],
      },
      [LedgerFilterInputType.InputAmount]: {
        type: LedgerFilterInputType.InputAmount,
        order: 3,
        keyNameMinValue: "amount_from",
        keyNameMaxValue: "amount_to",
        label: "Amount",
      },
      [LedgerFilterInputType.ActivityDropdownSelectFilter]: {
        type: LedgerFilterInputType.ActivityDropdownSelectFilter,
        order: 4,
        keyName: "chat_activity",
        options: [
          {
            value: ChatActivityEnumType.Active,
            label: "Active",
          },
          {
            value: ChatActivityEnumType.Inactive,
            label: "Inactive",
          },
        ],
        label: "Required Action",
      },
    },
    // mailed_not_delivered: {
    //   [LedgerFilterInputType.AdvancedSearchFilter]: {
    //     type: LedgerFilterInputType.AdvancedSearchFilter,
    //     order: 1,
    //     value: "",
    //     placeholder: "search ",
    //     label: "Search text",
    //     keyName: "search",
    //     keyNameInput: "search_input",
    //     keyNameOptions: "search_options",
    //     keyNameAdditionalInput: "additional_search_input",
    //     keyNameAdditionalOptions: "additional_search_options",
    //     tooltip: "Search by the Check Number, Sender or Recipient",
    //     searchOptions: [
    //       { value: CheckSearchOptionsEnumType.CheckNumber, label: "Check Number" },
    //       { value: CheckSearchOptionsEnumType.Recipient, label: "Recipient" },
    //       { value: CheckSearchOptionsEnumType.Sender, label: "Sender" },
    //     ],
    //     additionalSearchOptions: [
    //       { value: CheckSearchOptionsEnumType.CheckNumber, label: "Check Number" },
    //       { value: CheckSearchOptionsEnumType.Recipient, label: "Recipient" },
    //       { value: CheckSearchOptionsEnumType.Sender, label: "Sender" },
    //     ],
    //   },
    //   [LedgerFilterInputType.AdvancedDateFilter]: {
    //     type: LedgerFilterInputType.AdvancedDateFilter,
    //     order: 2,
    //     placeholder: "date",
    //     label: "Select Date",
    //     keyNameInput: "date",
    //     keyNameContextOptions: "context",
    //     keyNamePresetOption: "preset",
    //     keyNameCustomRange: ["from", "to"],
    //     contextOptions: [
    //       { value: "created_at", label: "Check Synced", default: false },
    //       { value: "date", label: "Check Date", default: false },
    //       { value: "signed_at", label: "Check Signed", default: true },
    //     ],
    //     presetOptions: [
    //       { value: "", label: "Any Time", default: true },
    //       { value: "last_day", label: "Last Day", default: false },
    //       { value: "last_week", label: "Last Week", default: false },
    //       { value: "last_month", label: "Last Month", default: false },
    //       { value: "last_year", label: "Last Year", default: false },
    //       { value: "custom_type", label: "Custom Range", default: false },
    //     ],
    //   },
    //   [LedgerFilterInputType.InputAmount]: {
    //     type: LedgerFilterInputType.InputAmount,
    //     order: 3,
    //     keyNameMinValue: "amount_from",
    //     keyNameMaxValue: "amount_to",
    //     label: "Amount",
    //   },
    // },
    completed: {
      [LedgerFilterInputType.AdvancedSearchFilter]: {
        type: LedgerFilterInputType.AdvancedSearchFilter,
        order: 1,
        value: "",
        placeholder: "search ",
        label: "Search text",
        keyName: "search",
        keyNameInput: "search_input",
        keyNameOptions: "search_options",
        keyNameAdditionalInput: "additional_search_input",
        keyNameAdditionalOptions: "additional_search_options",
        tooltip: "Search by the Check Number, Sender or Recipient",
        searchOptions: [
          { value: CheckSearchOptionsEnumType.CheckNumber, label: "Check Number" },
          { value: CheckSearchOptionsEnumType.Recipient, label: "Recipient" },
          { value: CheckSearchOptionsEnumType.Sender, label: "Sender" },
        ],
        additionalSearchOptions: [
          { value: CheckSearchOptionsEnumType.CheckNumber, label: "Check Number" },
          { value: CheckSearchOptionsEnumType.Recipient, label: "Recipient" },
          { value: CheckSearchOptionsEnumType.Sender, label: "Sender" },
        ],
      },
      [LedgerFilterInputType.AdvancedDateFilter]: {
        type: LedgerFilterInputType.AdvancedDateFilter,
        order: 2,
        placeholder: "date",
        label: "Select Date",
        keyNameInput: "date",
        keyNameContextOptions: "context",
        keyNamePresetOption: "preset",
        keyNameCustomRange: ["from", "to"],
        contextOptions: [
          { value: "created_at", label: "Check Synced", default: false },
          { value: "date", label: "Check Date", default: false },
          { value: "signed_at", label: "Check Signed", default: true },
        ],
        presetOptions: [
          { value: "", label: "Any Time", default: true },
          { value: "last_day", label: "Last Day", default: false },
          { value: "last_week", label: "Last Week", default: false },
          { value: "last_month", label: "Last Month", default: false },
          { value: "last_year", label: "Last Year", default: false },
          { value: "custom_type", label: "Custom Range", default: false },
        ],
      },
      [LedgerFilterInputType.InputAmount]: {
        type: LedgerFilterInputType.InputAmount,
        order: 3,
        keyNameMinValue: "amount_from",
        keyNameMaxValue: "amount_to",
        label: "Amount",
      },
      [LedgerFilterInputType.CheckStateSelectFilter]: {
        type: LedgerFilterInputType.CheckStateSelectFilter,
        order: 4,
        keyName: "state",
        options: [
          { value: "PAID", label: "Paid" },
          { value: "VOIDED", label: "Voided" },
        ],
        label: "State",
      },
      [LedgerFilterInputType.ProcessingTypeFilter]: {
        type: LedgerFilterInputType.ProcessingTypeFilter,
        order: 6,
        placeholder: "Processing Type...",
        label: "Processing Type",
        keyName: "processing_type",
        keyNameInput: "processor",
        keyNameOptions: "sub_status",
        tooltip: "Filter By Processing Type",
        processingOptions: [
          {
            value: "ach",
            label: "ACH",
            options: [],
          },
          {
            value: "card",
            label: "Card",
            options: [],
          },
          {
            value: "print",
            label: "Print",
            options: [],
          },
        ],
      },
    },
  },
  deposits: {
    all: {
      [LedgerFilterInputType.AdvancedSearchFilter]: {
        type: LedgerFilterInputType.AdvancedSearchFilter,
        order: 1,
        value: "",
        placeholder: "search ",
        label: "Search text",
        keyName: "search",
        keyNameInput: "search_input",
        keyNameOptions: "search_options",
        keyNameAdditionalInput: "additional_search_input",
        keyNameAdditionalOptions: "additional_search_options",
        tooltip: "Search by the Check Number, Sender or Recipient",
        searchOptions: [
          { value: CheckSearchOptionsEnumType.CheckNumber, label: "Check Number" },
          { value: CheckSearchOptionsEnumType.Recipient, label: "Recipient" },
          { value: CheckSearchOptionsEnumType.Sender, label: "Sender" },
        ],
        additionalSearchOptions: [
          { value: CheckSearchOptionsEnumType.CheckNumber, label: "Check Number" },
          { value: CheckSearchOptionsEnumType.Recipient, label: "Recipient" },
          { value: CheckSearchOptionsEnumType.Sender, label: "Sender" },
        ],
      },
      [LedgerFilterInputType.AdvancedDateFilter]: {
        type: LedgerFilterInputType.AdvancedDateFilter,
        order: 2,
        placeholder: "date",
        label: "Select Date",
        keyNameInput: "date",
        keyNameContextOptions: "context",
        keyNamePresetOption: "preset",
        keyNameCustomRange: ["from", "to"],
        contextOptions: [
          { value: "created_at", label: "Check Synced", default: false },
          { value: "date", label: "Check Date", default: false },
          { value: "signed_at", label: "Check Signed", default: true },
        ],
        presetOptions: [
          { value: "", label: "Any Time", default: true },
          { value: "last_day", label: "Last Day", default: false },
          { value: "last_week", label: "Last Week", default: false },
          { value: "last_month", label: "Last Month", default: false },
          { value: "last_year", label: "Last Year", default: false },
          { value: "custom_type", label: "Custom Range", default: false },
        ],
      },
      [LedgerFilterInputType.InputAmount]: {
        type: LedgerFilterInputType.InputAmount,
        order: 3,
        keyNameMinValue: "amount_from",
        keyNameMaxValue: "amount_to",
        label: "Amount",
      },
      [LedgerFilterInputType.CheckActionRequiredSelectFilter]: {
        type: LedgerFilterInputType.CheckActionRequiredSelectFilter,
        order: 4,
        keyName: "required_action",
        options: [
          {
            value: formatEnumValue(CheckRequiredActionActionEnumType.PendingBankVerification),
            label: "Pending Bank Verification",
          },
          {
            value: formatEnumValue(CheckRequiredActionActionEnumType.PendingBankEmailConfirmation),
            label: "Pending Bank Email Confirmation",
          },
          { value: formatEnumValue(CheckRequiredActionActionEnumType.BankAccountLocked), label: "Bank Account Locked" },
          {
            value: formatEnumValue(CheckRequiredActionActionEnumType.WrongBankInformation),
            label: "Wrong Bank Information",
          },
          {
            value: formatEnumValue(CheckRequiredActionActionEnumType.CheckWasSignedNotDeposited),
            label: "Check Was Signed, Not Deposited",
          },
          {
            value: formatEnumValue(CheckRequiredActionActionEnumType.ExpiredPaperChecks),
            label: "Expired Paper Checks (90+Days)",
          },
          {
            value: formatEnumValue(CheckRequiredActionActionEnumType.AchInsufficientFunds),
            label: "ACH Insufficient Funds",
          },
          {
            value: formatEnumValue(CheckRequiredActionActionEnumType.AchUnableToLocateAccount),
            label: "ACH Unable To Locate Account",
          },
          {
            value: formatEnumValue(CheckRequiredActionActionEnumType.AchCorporateCustomerAdvisesNotAuthorized),
            label: "ACH Corporate Customer Advises Not Authorized",
          },
          {
            value: formatEnumValue(CheckRequiredActionActionEnumType.AchReturnOther),
            label: "ACH Return Other",
          },
        ],
        label: "Required Action",
      },
      [LedgerFilterInputType.ProcessingTypeFilter]: {
        type: LedgerFilterInputType.ProcessingTypeFilter,
        order: 6,
        placeholder: "Processing Type...",
        label: "Processing Type",
        keyName: "processing_type",
        keyNameInput: "processor",
        keyNameOptions: "sub_status",
        tooltip: "Filter By Processing Type",
        processingOptions: [
          {
            value: "ach",
            label: "ACH",
            options: [],
          },
          {
            value: "card",
            label: "Card",
            options: [],
          },
          {
            value: "print",
            label: "Print",
            options: [],
          },
        ],
      },
    },
    ready_to_deposit: {
      [LedgerFilterInputType.AdvancedSearchFilter]: {
        type: LedgerFilterInputType.AdvancedSearchFilter,
        order: 1,
        value: "",
        placeholder: "search ",
        label: "Search text",
        keyName: "search",
        keyNameInput: "search_input",
        keyNameOptions: "search_options",
        keyNameAdditionalInput: "additional_search_input",
        keyNameAdditionalOptions: "additional_search_options",
        tooltip: "Search by the Check Number, Sender or Recipient",
        searchOptions: [
          { value: CheckSearchOptionsEnumType.CheckNumber, label: "Check Number" },
          { value: CheckSearchOptionsEnumType.Recipient, label: "Recipient" },
          { value: CheckSearchOptionsEnumType.Sender, label: "Sender" },
        ],
        additionalSearchOptions: [
          { value: CheckSearchOptionsEnumType.CheckNumber, label: "Check Number" },
          { value: CheckSearchOptionsEnumType.Recipient, label: "Recipient" },
          { value: CheckSearchOptionsEnumType.Sender, label: "Sender" },
        ],
      },
      [LedgerFilterInputType.AdvancedDateFilter]: {
        type: LedgerFilterInputType.AdvancedDateFilter,
        order: 2,
        placeholder: "date",
        label: "Select Date",
        keyNameInput: "date",
        keyNameContextOptions: "context",
        keyNamePresetOption: "preset",
        keyNameCustomRange: ["from", "to"],
        contextOptions: [
          { value: "created_at", label: "Check Synced", default: false },
          { value: "date", label: "Check Date", default: false },
          { value: "signed_at", label: "Check Signed", default: true },
        ],
        presetOptions: [
          { value: "", label: "Any Time", default: true },
          { value: "last_day", label: "Last Day", default: false },
          { value: "last_week", label: "Last Week", default: false },
          { value: "last_month", label: "Last Month", default: false },
          { value: "last_year", label: "Last Year", default: false },
          { value: "custom_type", label: "Custom Range", default: false },
        ],
      },
      [LedgerFilterInputType.InputAmount]: {
        type: LedgerFilterInputType.InputAmount,
        order: 3,
        keyNameMinValue: "amount_from",
        keyNameMaxValue: "amount_to",
        label: "Amount",
      },
      [LedgerFilterInputType.ProcessingTypeFilter]: {
        type: LedgerFilterInputType.ProcessingTypeFilter,
        order: 6,
        placeholder: "Processing Type...",
        label: "Processing Type",
        keyName: "processing_type",
        keyNameInput: "processor",
        keyNameOptions: "sub_status",
        tooltip: "Filter By Processing Type",
        processingOptions: [
          {
            value: "ach",
            label: "ACH",
            options: [],
          },
          {
            value: "card",
            label: "Card",
            options: [],
          },
          {
            value: "print",
            label: "Print",
            options: [],
          },
        ],
      },
    },
    processing: {
      [LedgerFilterInputType.AdvancedSearchFilter]: {
        type: LedgerFilterInputType.AdvancedSearchFilter,
        order: 1,
        value: "",
        placeholder: "search ",
        label: "Search text",
        keyName: "search",
        keyNameInput: "search_input",
        keyNameOptions: "search_options",
        keyNameAdditionalInput: "additional_search_input",
        keyNameAdditionalOptions: "additional_search_options",
        tooltip: "Search by the Check Number, Sender or Recipient",
        searchOptions: [
          { value: CheckSearchOptionsEnumType.CheckNumber, label: "Check Number" },
          { value: CheckSearchOptionsEnumType.Recipient, label: "Recipient" },
          { value: CheckSearchOptionsEnumType.Sender, label: "Sender" },
        ],
        additionalSearchOptions: [
          { value: CheckSearchOptionsEnumType.CheckNumber, label: "Check Number" },
          { value: CheckSearchOptionsEnumType.Recipient, label: "Recipient" },
          { value: CheckSearchOptionsEnumType.Sender, label: "Sender" },
        ],
      },
      [LedgerFilterInputType.AdvancedDateFilter]: {
        type: LedgerFilterInputType.AdvancedDateFilter,
        order: 2,
        placeholder: "date",
        label: "Select Date",
        keyNameInput: "date",
        keyNameContextOptions: "context",
        keyNamePresetOption: "preset",
        keyNameCustomRange: ["from", "to"],
        contextOptions: [
          { value: "created_at", label: "Check Synced", default: false },
          { value: "date", label: "Check Date", default: false },
          { value: "signed_at", label: "Check Signed", default: true },
        ],
        presetOptions: [
          { value: "", label: "Any Time", default: true },
          { value: "last_day", label: "Last Day", default: false },
          { value: "last_week", label: "Last Week", default: false },
          { value: "last_month", label: "Last Month", default: false },
          { value: "last_year", label: "Last Year", default: false },
          { value: "custom_type", label: "Custom Range", default: false },
        ],
      },
      [LedgerFilterInputType.InputAmount]: {
        type: LedgerFilterInputType.InputAmount,
        order: 3,
        keyNameMinValue: "amount_from",
        keyNameMaxValue: "amount_to",
        label: "Amount",
      },
      [LedgerFilterInputType.ProcessingTypeFilter]: {
        type: LedgerFilterInputType.ProcessingTypeFilter,
        order: 6,
        placeholder: "Processing Type...",
        label: "Processing Type",
        keyName: "processing_type",
        keyNameInput: "processor",
        keyNameOptions: "sub_status",
        tooltip: "Filter By Processing Type",
        processingOptions: [
          {
            value: "ach",
            label: "ACH",
            options: [],
          },
          {
            value: "card",
            label: "Card",
            options: [],
          },
          {
            value: "print",
            label: "Print",
            options: [],
          },
        ],
      },
    },
    action_required: {
      [LedgerFilterInputType.AdvancedSearchFilter]: {
        type: LedgerFilterInputType.AdvancedSearchFilter,
        order: 1,
        value: "",
        placeholder: "search ",
        label: "Search text",
        keyName: "search",
        keyNameInput: "search_input",
        keyNameOptions: "search_options",
        keyNameAdditionalInput: "additional_search_input",
        keyNameAdditionalOptions: "additional_search_options",
        tooltip: "Search by the Check Number, Sender or Recipient",
        searchOptions: [
          { value: CheckSearchOptionsEnumType.CheckNumber, label: "Check Number" },
          { value: CheckSearchOptionsEnumType.Recipient, label: "Recipient" },
          { value: CheckSearchOptionsEnumType.Sender, label: "Sender" },
        ],
        additionalSearchOptions: [
          { value: CheckSearchOptionsEnumType.CheckNumber, label: "Check Number" },
          { value: CheckSearchOptionsEnumType.Recipient, label: "Recipient" },
          { value: CheckSearchOptionsEnumType.Sender, label: "Sender" },
        ],
      },
      [LedgerFilterInputType.AdvancedDateFilter]: {
        type: LedgerFilterInputType.AdvancedDateFilter,
        order: 2,
        placeholder: "date",
        label: "Select Date",
        keyNameInput: "date",
        keyNameContextOptions: "context",
        keyNamePresetOption: "preset",
        keyNameCustomRange: ["from", "to"],
        contextOptions: [
          { value: "created_at", label: "Check Synced", default: false },
          { value: "date", label: "Check Date", default: false },
          { value: "signed_at", label: "Check Signed", default: true },
        ],
        presetOptions: [
          { value: "", label: "Any Time", default: true },
          { value: "last_day", label: "Last Day", default: false },
          { value: "last_week", label: "Last Week", default: false },
          { value: "last_month", label: "Last Month", default: false },
          { value: "last_year", label: "Last Year", default: false },
          { value: "custom_type", label: "Custom Range", default: false },
        ],
      },
      [LedgerFilterInputType.InputAmount]: {
        type: LedgerFilterInputType.InputAmount,
        order: 3,
        keyNameMinValue: "amount_from",
        keyNameMaxValue: "amount_to",
        label: "Amount",
      },
      [LedgerFilterInputType.CheckActionRequiredSelectFilter]: {
        type: LedgerFilterInputType.CheckActionRequiredSelectFilter,
        order: 4,
        keyName: "required_action",
        options: [
          {
            value: formatEnumValue(CheckRequiredActionActionEnumType.PendingBankVerification),
            label: "Pending Bank Verification",
          },
          {
            value: formatEnumValue(CheckRequiredActionActionEnumType.PendingBankEmailConfirmation),
            label: "Pending Bank Email Confirmation",
          },
          { value: formatEnumValue(CheckRequiredActionActionEnumType.BankAccountLocked), label: "Bank Account Locked" },
          {
            value: formatEnumValue(CheckRequiredActionActionEnumType.WrongBankInformation),
            label: "Wrong Bank Information",
          },
          {
            value: formatEnumValue(CheckRequiredActionActionEnumType.CheckWasSignedNotDeposited),
            label: "Check Was Signed, Not Deposited",
          },
          {
            value: formatEnumValue(CheckRequiredActionActionEnumType.ExpiredPaperChecks),
            label: "Expired Paper Checks (90+Days)",
          },
          {
            value: formatEnumValue(CheckRequiredActionActionEnumType.AchInsufficientFunds),
            label: "ACH Insufficient Funds",
          },
          {
            value: formatEnumValue(CheckRequiredActionActionEnumType.AchUnableToLocateAccount),
            label: "ACH Unable To Locate Account",
          },
          {
            value: formatEnumValue(CheckRequiredActionActionEnumType.AchCorporateCustomerAdvisesNotAuthorized),
            label: "ACH Corporate Customer Advises Not Authorized",
          },
          {
            value: formatEnumValue(CheckRequiredActionActionEnumType.AchReturnOther),
            label: "ACH Return Other",
          },
        ],
        label: "Required Action",
      },
      [LedgerFilterInputType.CheckStatusSelectFilter]: {
        type: LedgerFilterInputType.CheckStatusSelectFilter,
        order: 5,
        keyName: "status",
        options: [
          { value: "ready_to_deposit", label: "Ready to Deposit" },
          { value: "action_required", label: "Action Required" },
        ],
        label: "Status",
      },
      [LedgerFilterInputType.ProcessingTypeFilter]: {
        type: LedgerFilterInputType.ProcessingTypeFilter,
        order: 6,
        placeholder: "Processing Type...",
        label: "Processing Type",
        keyName: "processing_type",
        keyNameInput: "processor",
        keyNameOptions: "sub_status",
        tooltip: "Filter By Processing Type",
        processingOptions: [
          {
            value: "ach",
            label: "ACH",
            options: [],
          },
          {
            value: "card",
            label: "Card",
            options: [],
          },
          {
            value: "print",
            label: "Print",
            options: [],
          },
        ],
      },
    },
    completed: {
      [LedgerFilterInputType.AdvancedSearchFilter]: {
        type: LedgerFilterInputType.AdvancedSearchFilter,
        order: 1,
        value: "",
        placeholder: "search ",
        label: "Search text",
        keyName: "search",
        keyNameInput: "search_input",
        keyNameOptions: "search_options",
        keyNameAdditionalInput: "additional_search_input",
        keyNameAdditionalOptions: "additional_search_options",
        tooltip: "Search by the Check Number, Sender or Recipient",
        searchOptions: [
          { value: CheckSearchOptionsEnumType.CheckNumber, label: "Check Number" },
          { value: CheckSearchOptionsEnumType.Recipient, label: "Recipient" },
          { value: CheckSearchOptionsEnumType.Sender, label: "Sender" },
        ],
        additionalSearchOptions: [
          { value: CheckSearchOptionsEnumType.CheckNumber, label: "Check Number" },
          { value: CheckSearchOptionsEnumType.Recipient, label: "Recipient" },
          { value: CheckSearchOptionsEnumType.Sender, label: "Sender" },
        ],
      },
      [LedgerFilterInputType.AdvancedDateFilter]: {
        type: LedgerFilterInputType.AdvancedDateFilter,
        order: 2,
        placeholder: "date",
        label: "Select Date",
        keyNameInput: "date",
        keyNameContextOptions: "context",
        keyNamePresetOption: "preset",
        keyNameCustomRange: ["from", "to"],
        contextOptions: [
          { value: "created_at", label: "Check Synced", default: false },
          { value: "date", label: "Check Date", default: false },
          { value: "signed_at", label: "Check Signed", default: true },
        ],
        presetOptions: [
          { value: "", label: "Any Time", default: true },
          { value: "last_day", label: "Last Day", default: false },
          { value: "last_week", label: "Last Week", default: false },
          { value: "last_month", label: "Last Month", default: false },
          { value: "last_year", label: "Last Year", default: false },
          { value: "custom_type", label: "Custom Range", default: false },
        ],
      },
      [LedgerFilterInputType.InputAmount]: {
        type: LedgerFilterInputType.InputAmount,
        order: 3,
        keyNameMinValue: "amount_from",
        keyNameMaxValue: "amount_to",
        label: "Amount",
      },
    },
  },
  unvalidated_ledger: {
    all: {
      [LedgerFilterInputType.AdvancedSearchFilter]: {
        type: LedgerFilterInputType.AdvancedSearchFilter,
        order: 1,
        value: "",
        placeholder: "search ",
        label: "Search text",
        keyName: "search",
        keyNameInput: "search_input",
        keyNameOptions: "search_options",
        keyNameAdditionalInput: "additional_search_input",
        keyNameAdditionalOptions: "additional_search_options",
        tooltip: "Search by the Check Number, Sender or Recipient",
        searchOptions: [
          { value: CheckSearchOptionsEnumType.CheckNumber, label: "Check Number" },
          { value: CheckSearchOptionsEnumType.Recipient, label: "Recipient" },
          { value: CheckSearchOptionsEnumType.Sender, label: "Sender" },
        ],
        additionalSearchOptions: [
          { value: CheckSearchOptionsEnumType.CheckNumber, label: "Check Number" },
          { value: CheckSearchOptionsEnumType.Recipient, label: "Recipient" },
          { value: CheckSearchOptionsEnumType.Sender, label: "Sender" },
        ],
      },
      [LedgerFilterInputType.AdvancedDateFilter]: {
        type: LedgerFilterInputType.AdvancedDateFilter,
        order: 2,
        placeholder: "date",
        label: "Select Date",
        keyNameInput: "date",
        keyNameContextOptions: "context",
        keyNamePresetOption: "preset",
        keyNameCustomRange: ["from", "to"],
        contextOptions: [
          { value: "created_at", label: "Check Synced", default: false },
          { value: "date", label: "Check Date", default: false },
          { value: "signed_at", label: "Check Signed", default: true },
        ],
        presetOptions: [
          { value: "", label: "Any Time", default: true },
          { value: "last_day", label: "Last Day", default: false },
          { value: "last_week", label: "Last Week", default: false },
          { value: "last_month", label: "Last Month", default: false },
          { value: "last_year", label: "Last Year", default: false },
          { value: "custom_type", label: "Custom Range", default: false },
        ],
      },
      [LedgerFilterInputType.InputAmount]: {
        type: LedgerFilterInputType.InputAmount,
        order: 3,
        keyNameMinValue: "amount_from",
        keyNameMaxValue: "amount_to",
        label: "Amount",
      },
      [LedgerFilterInputType.CheckActionRequiredSelectFilter]: {
        type: LedgerFilterInputType.CheckActionRequiredSelectFilter,
        order: 4,
        keyName: "required_action",
        options: [
          {
            value: formatEnumValue(CheckRequiredActionActionEnumType.PendingBankVerification),
            label: "Pending Bank Verification",
          },
          {
            value: formatEnumValue(CheckRequiredActionActionEnumType.PendingBankEmailConfirmation),
            label: "Pending Bank Email Confirmation",
          },
          { value: formatEnumValue(CheckRequiredActionActionEnumType.BankAccountLocked), label: "Bank Account Locked" },
          {
            value: formatEnumValue(CheckRequiredActionActionEnumType.WrongBankInformation),
            label: "Wrong Bank Information",
          },
          {
            value: formatEnumValue(CheckRequiredActionActionEnumType.CheckWasSignedNotDeposited),
            label: "Check Was Signed, Not Deposited",
          },
          {
            value: formatEnumValue(CheckRequiredActionActionEnumType.ExpiredPaperChecks),
            label: "Expired Paper Checks (90+Days)",
          },
          {
            value: formatEnumValue(CheckRequiredActionActionEnumType.AchInsufficientFunds),
            label: "ACH Insufficient Funds",
          },
          {
            value: formatEnumValue(CheckRequiredActionActionEnumType.AchUnableToLocateAccount),
            label: "ACH Unable To Locate Account",
          },
          {
            value: formatEnumValue(CheckRequiredActionActionEnumType.AchCorporateCustomerAdvisesNotAuthorized),
            label: "ACH Corporate Customer Advises Not Authorized",
          },
          {
            value: formatEnumValue(CheckRequiredActionActionEnumType.AchReturnOther),
            label: "ACH Return Other",
          },
        ],
        label: "Required Action",
      },
      [LedgerFilterInputType.BankAccountsFilter]: {
        type: LedgerFilterInputType.BankAccountsFilter,
        order: 6,
        placeholder: "Bank Accounts",
        label: "Bank Accounts",
        keyName: "bank_accounts",
        tooltip: "Filter By Bank Accounts",
      },
      [LedgerFilterInputType.ProcessingTypeFilter]: {
        type: LedgerFilterInputType.ProcessingTypeFilter,
        order: 6,
        placeholder: "Processing Type...",
        label: "Processing Type",
        keyName: "processing_type",
        keyNameInput: "processor",
        keyNameOptions: "sub_status",
        tooltip: "Filter By Processing Type",
        processingOptions: [
          {
            value: "ach",
            label: "ACH",
            options: [],
          },
          {
            value: "card",
            label: "Card",
            options: [],
          },
          {
            value: "print",
            label: "Print",
            options: [],
          },
        ],
      },
    },
    ready_to_deposit: {
      [LedgerFilterInputType.AdvancedSearchFilter]: {
        type: LedgerFilterInputType.AdvancedSearchFilter,
        order: 1,
        value: "",
        placeholder: "search ",
        label: "Search text",
        keyName: "search",
        keyNameInput: "search_input",
        keyNameOptions: "search_options",
        keyNameAdditionalInput: "additional_search_input",
        keyNameAdditionalOptions: "additional_search_options",
        tooltip: "Search by the Check Number, Sender or Recipient",
        searchOptions: [
          { value: CheckSearchOptionsEnumType.CheckNumber, label: "Check Number" },
          { value: CheckSearchOptionsEnumType.Recipient, label: "Recipient" },
          { value: CheckSearchOptionsEnumType.Sender, label: "Sender" },
        ],
        additionalSearchOptions: [
          { value: CheckSearchOptionsEnumType.CheckNumber, label: "Check Number" },
          { value: CheckSearchOptionsEnumType.Recipient, label: "Recipient" },
          { value: CheckSearchOptionsEnumType.Sender, label: "Sender" },
        ],
      },
      [LedgerFilterInputType.AdvancedDateFilter]: {
        type: LedgerFilterInputType.AdvancedDateFilter,
        order: 2,
        placeholder: "date",
        label: "Select Date",
        keyNameInput: "date",
        keyNameContextOptions: "context",
        keyNamePresetOption: "preset",
        keyNameCustomRange: ["from", "to"],
        contextOptions: [
          { value: "created_at", label: "Check Synced", default: false },
          { value: "date", label: "Check Date", default: false },
          { value: "signed_at", label: "Check Signed", default: true },
        ],
        presetOptions: [
          { value: "", label: "Any Time", default: true },
          { value: "last_day", label: "Last Day", default: false },
          { value: "last_week", label: "Last Week", default: false },
          { value: "last_month", label: "Last Month", default: false },
          { value: "last_year", label: "Last Year", default: false },
          { value: "custom_type", label: "Custom Range", default: false },
        ],
      },
      [LedgerFilterInputType.InputAmount]: {
        type: LedgerFilterInputType.InputAmount,
        order: 3,
        keyNameMinValue: "amount_from",
        keyNameMaxValue: "amount_to",
        label: "Amount",
      },
      [LedgerFilterInputType.ProcessingTypeFilter]: {
        type: LedgerFilterInputType.ProcessingTypeFilter,
        order: 6,
        placeholder: "Processing Type...",
        label: "Processing Type",
        keyName: "processing_type",
        keyNameInput: "processor",
        keyNameOptions: "sub_status",
        tooltip: "Filter By Processing Type",
        processingOptions: [
          {
            value: "ach",
            label: "ACH",
            options: [],
          },
          {
            value: "card",
            label: "Card",
            options: [],
          },
          {
            value: "print",
            label: "Print",
            options: [],
          },
        ],
      },
    },
    processing: {
      [LedgerFilterInputType.AdvancedSearchFilter]: {
        type: LedgerFilterInputType.AdvancedSearchFilter,
        order: 1,
        value: "",
        placeholder: "search ",
        label: "Search text",
        keyName: "search",
        keyNameInput: "search_input",
        keyNameOptions: "search_options",
        keyNameAdditionalInput: "additional_search_input",
        keyNameAdditionalOptions: "additional_search_options",
        tooltip: "Search by the Check Number, Sender or Recipient",
        searchOptions: [
          { value: CheckSearchOptionsEnumType.CheckNumber, label: "Check Number" },
          { value: CheckSearchOptionsEnumType.Recipient, label: "Recipient" },
          { value: CheckSearchOptionsEnumType.Sender, label: "Sender" },
        ],
        additionalSearchOptions: [
          { value: CheckSearchOptionsEnumType.CheckNumber, label: "Check Number" },
          { value: CheckSearchOptionsEnumType.Recipient, label: "Recipient" },
          { value: CheckSearchOptionsEnumType.Sender, label: "Sender" },
        ],
      },
      [LedgerFilterInputType.AdvancedDateFilter]: {
        type: LedgerFilterInputType.AdvancedDateFilter,
        order: 2,
        placeholder: "date",
        label: "Select Date",
        keyNameInput: "date",
        keyNameContextOptions: "context",
        keyNamePresetOption: "preset",
        keyNameCustomRange: ["from", "to"],
        contextOptions: [
          { value: "created_at", label: "Check Synced", default: false },
          { value: "date", label: "Check Date", default: false },
          { value: "signed_at", label: "Check Signed", default: true },
        ],
        presetOptions: [
          { value: "", label: "Any Time", default: true },
          { value: "last_day", label: "Last Day", default: false },
          { value: "last_week", label: "Last Week", default: false },
          { value: "last_month", label: "Last Month", default: false },
          { value: "last_year", label: "Last Year", default: false },
          { value: "custom_type", label: "Custom Range", default: false },
        ],
      },
      [LedgerFilterInputType.InputAmount]: {
        type: LedgerFilterInputType.InputAmount,
        order: 3,
        keyNameMinValue: "amount_from",
        keyNameMaxValue: "amount_to",
        label: "Amount",
      },
      [LedgerFilterInputType.ProcessingTypeFilter]: {
        type: LedgerFilterInputType.ProcessingTypeFilter,
        order: 6,
        placeholder: "Processing Type...",
        label: "Processing Type",
        keyName: "processing_type",
        keyNameInput: "processor",
        keyNameOptions: "sub_status",
        tooltip: "Filter By Processing Type",
        processingOptions: [
          {
            value: "ach",
            label: "ACH",
            options: [],
          },
          {
            value: "card",
            label: "Card",
            options: [],
          },
          {
            value: "print",
            label: "Print",
            options: [],
          },
        ],
      },
    },
    action_required: {
      [LedgerFilterInputType.AdvancedSearchFilter]: {
        type: LedgerFilterInputType.AdvancedSearchFilter,
        order: 1,
        value: "",
        placeholder: "search ",
        label: "Search text",
        keyName: "search",
        keyNameInput: "search_input",
        keyNameOptions: "search_options",
        keyNameAdditionalInput: "additional_search_input",
        keyNameAdditionalOptions: "additional_search_options",
        tooltip: "Search by the Check Number, Sender or Recipient",
        searchOptions: [
          { value: CheckSearchOptionsEnumType.CheckNumber, label: "Check Number" },
          { value: CheckSearchOptionsEnumType.Recipient, label: "Recipient" },
          { value: CheckSearchOptionsEnumType.Sender, label: "Sender" },
        ],
        additionalSearchOptions: [
          { value: CheckSearchOptionsEnumType.CheckNumber, label: "Check Number" },
          { value: CheckSearchOptionsEnumType.Recipient, label: "Recipient" },
          { value: CheckSearchOptionsEnumType.Sender, label: "Sender" },
        ],
      },
      [LedgerFilterInputType.AdvancedDateFilter]: {
        type: LedgerFilterInputType.AdvancedDateFilter,
        order: 2,
        placeholder: "date",
        label: "Select Date",
        keyNameInput: "date",
        keyNameContextOptions: "context",
        keyNamePresetOption: "preset",
        keyNameCustomRange: ["from", "to"],
        contextOptions: [
          { value: "created_at", label: "Check Synced", default: false },
          { value: "date", label: "Check Date", default: false },
          { value: "signed_at", label: "Check Signed", default: true },
        ],
        presetOptions: [
          { value: "", label: "Any Time", default: true },
          { value: "last_day", label: "Last Day", default: false },
          { value: "last_week", label: "Last Week", default: false },
          { value: "last_month", label: "Last Month", default: false },
          { value: "last_year", label: "Last Year", default: false },
          { value: "custom_type", label: "Custom Range", default: false },
        ],
      },
      [LedgerFilterInputType.InputAmount]: {
        type: LedgerFilterInputType.InputAmount,
        order: 3,
        keyNameMinValue: "amount_from",
        keyNameMaxValue: "amount_to",
        label: "Amount",
      },
      [LedgerFilterInputType.CheckActionRequiredSelectFilter]: {
        type: LedgerFilterInputType.CheckActionRequiredSelectFilter,
        order: 4,
        keyName: "required_action",
        options: [
          {
            value: formatEnumValue(CheckRequiredActionActionEnumType.PendingBankVerification),
            label: "Pending Bank Verification",
          },
          {
            value: formatEnumValue(CheckRequiredActionActionEnumType.PendingBankEmailConfirmation),
            label: "Pending Bank Email Confirmation",
          },
          { value: formatEnumValue(CheckRequiredActionActionEnumType.BankAccountLocked), label: "Bank Account Locked" },
          {
            value: formatEnumValue(CheckRequiredActionActionEnumType.WrongBankInformation),
            label: "Wrong Bank Information",
          },
          {
            value: formatEnumValue(CheckRequiredActionActionEnumType.CheckWasSignedNotDeposited),
            label: "Check Was Signed, Not Deposited",
          },
          {
            value: formatEnumValue(CheckRequiredActionActionEnumType.ExpiredPaperChecks),
            label: "Expired Paper Checks (90+Days)",
          },
          {
            value: formatEnumValue(CheckRequiredActionActionEnumType.AchInsufficientFunds),
            label: "ACH Insufficient Funds",
          },
          {
            value: formatEnumValue(CheckRequiredActionActionEnumType.AchUnableToLocateAccount),
            label: "ACH Unable To Locate Account",
          },
          {
            value: formatEnumValue(CheckRequiredActionActionEnumType.AchCorporateCustomerAdvisesNotAuthorized),
            label: "ACH Corporate Customer Advises Not Authorized",
          },
          {
            value: formatEnumValue(CheckRequiredActionActionEnumType.AchReturnOther),
            label: "ACH Return Other",
          },
        ],
        label: "Required Action",
      },
      [LedgerFilterInputType.CheckStatusSelectFilter]: {
        type: LedgerFilterInputType.CheckStatusSelectFilter,
        order: 5,
        keyName: "status",
        options: [
          { value: "ready_to_deposit", label: "Ready to Deposit" },
          { value: "action_required", label: "Action Required" },
        ],
        label: "Status",
      },
      [LedgerFilterInputType.ProcessingTypeFilter]: {
        type: LedgerFilterInputType.ProcessingTypeFilter,
        order: 6,
        placeholder: "Processing Type...",
        label: "Processing Type",
        keyName: "processing_type",
        keyNameInput: "processor",
        keyNameOptions: "sub_status",
        tooltip: "Filter By Processing Type",
        processingOptions: [
          {
            value: "ach",
            label: "ACH",
            options: [],
          },
          {
            value: "card",
            label: "Card",
            options: [],
          },
          {
            value: "print",
            label: "Print",
            options: [],
          },
        ],
      },
    },
    completed: {
      [LedgerFilterInputType.AdvancedSearchFilter]: {
        type: LedgerFilterInputType.AdvancedSearchFilter,
        order: 1,
        value: "",
        placeholder: "search ",
        label: "Search text",
        keyName: "search",
        keyNameInput: "search_input",
        keyNameOptions: "search_options",
        keyNameAdditionalInput: "additional_search_input",
        keyNameAdditionalOptions: "additional_search_options",
        tooltip: "Search by the Check Number, Sender or Recipient",
        searchOptions: [
          { value: CheckSearchOptionsEnumType.CheckNumber, label: "Check Number" },
          { value: CheckSearchOptionsEnumType.Recipient, label: "Recipient" },
          { value: CheckSearchOptionsEnumType.Sender, label: "Sender" },
        ],
        additionalSearchOptions: [
          { value: CheckSearchOptionsEnumType.CheckNumber, label: "Check Number" },
          { value: CheckSearchOptionsEnumType.Recipient, label: "Recipient" },
          { value: CheckSearchOptionsEnumType.Sender, label: "Sender" },
        ],
      },
      [LedgerFilterInputType.AdvancedDateFilter]: {
        type: LedgerFilterInputType.AdvancedDateFilter,
        order: 2,
        placeholder: "date",
        label: "Select Date",
        keyNameInput: "date",
        keyNameContextOptions: "context",
        keyNamePresetOption: "preset",
        keyNameCustomRange: ["from", "to"],
        contextOptions: [
          { value: "created_at", label: "Check Synced", default: false },
          { value: "date", label: "Check Date", default: false },
          { value: "signed_at", label: "Check Signed", default: true },
        ],
        presetOptions: [
          { value: "", label: "Any Time", default: true },
          { value: "last_day", label: "Last Day", default: false },
          { value: "last_week", label: "Last Week", default: false },
          { value: "last_month", label: "Last Month", default: false },
          { value: "last_year", label: "Last Year", default: false },
          { value: "custom_type", label: "Custom Range", default: false },
        ],
      },
      [LedgerFilterInputType.InputAmount]: {
        type: LedgerFilterInputType.InputAmount,
        order: 3,
        keyNameMinValue: "amount_from",
        keyNameMaxValue: "amount_to",
        label: "Amount",
      },
    },
  },
  budget_management: {
    all: {
      [LedgerFilterInputType.AdvancedSearchFilter]: {
        type: LedgerFilterInputType.AdvancedSearchFilter,
        order: 1,
        value: "",
        placeholder: "search ",
        label: "Search text",
        keyName: "search",
        keyNameInput: "search_input",
        keyNameOptions: "search_options",
        keyNameAdditionalInput: "additional_search_input",
        keyNameAdditionalOptions: "additional_search_options",
        tooltip: "Search by the Check Number, Sender or Recipient",
        searchOptions: [
          { value: CheckSearchOptionsEnumType.CheckNumber, label: "Check Number" },
          { value: CheckSearchOptionsEnumType.Recipient, label: "Recipient" },
          { value: CheckSearchOptionsEnumType.Sender, label: "Sender" },
        ],
        additionalSearchOptions: [
          { value: CheckSearchOptionsEnumType.CheckNumber, label: "Check Number" },
          { value: CheckSearchOptionsEnumType.Recipient, label: "Recipient" },
          { value: CheckSearchOptionsEnumType.Sender, label: "Sender" },
        ],
      },
      [LedgerFilterInputType.AdvancedDateFilter]: {
        type: LedgerFilterInputType.AdvancedDateFilter,
        order: 2,
        placeholder: "date",
        label: "Select Date",
        keyNameInput: "date",
        keyNameContextOptions: "context",
        keyNamePresetOption: "preset",
        keyNameCustomRange: ["from", "to"],
        contextOptions: [
          { value: "created_at", label: "Check Synced", default: false },
          { value: "date", label: "Check Date", default: false },
          { value: "signed_at", label: "Check Signed", default: true },
        ],
        presetOptions: [
          { value: "", label: "Any Time", default: true },
          { value: "last_day", label: "Last Day", default: false },
          { value: "last_week", label: "Last Week", default: false },
          { value: "last_month", label: "Last Month", default: false },
          { value: "last_year", label: "Last Year", default: false },
          { value: "custom_type", label: "Custom Range", default: false },
        ],
      },
      [LedgerFilterInputType.InputAmount]: {
        type: LedgerFilterInputType.InputAmount,
        order: 3,
        keyNameMinValue: "amount_from",
        keyNameMaxValue: "amount_to",
        label: "Amount",
      },
    },
  },
};

type ContextKeys = keyof typeof configLedgerFilters;

const getDynamicContext = (path: string) => {
  if (path?.toLowerCase().includes("/vchecks/payments")) {
    return "payments";
  }
  if (path?.toLowerCase().includes("/vchecks/deposits")) {
    return "deposits";
  }
  if (path?.toLowerCase().includes("/bchecks")) {
    return "budget_management";
  }

  return "unvalidated_ledger";
};

export function getFiltersConfig(path: string, dynamicStatus: string) {
  const dynamicContext = getDynamicContext(path);
  let filtersConfig;

  if (dynamicContext === "budget_management") {
    return configLedgerFilters[dynamicContext]["all"];
  }

  if (dynamicContext in configLedgerFilters) {
    const subKeys = configLedgerFilters[dynamicContext as ContextKeys];
    if (dynamicStatus in subKeys) {
      filtersConfig = subKeys[dynamicStatus as keyof typeof subKeys];
    }
  }

  return filtersConfig;
}

export const findLabelByValue = (formConfig: any, value: string | number): string | null => {
  switch (formConfig.type) {
    case "CheckStateSelectFilter":
      return (
        formConfig.options.find((option: { value: string; label: string }) => option.value === value)?.label || null
      );

    case "AdvancedDateFilter":
      return (
        formConfig.contextOptions.find((option: { value: string; label: string }) => option.value === value)?.label ||
        formConfig.presetOptions.find((option: { value: string; label: string }) => option.value === value)?.label ||
        null
      );

    case "ProcessingTypeFilter":
      const parentOption = formConfig.processingOptions.find(
        (option: { value: string; label: string }) => option.value === value,
      );
      if (parentOption) {
        return parentOption.label;
      }

      for (const option of formConfig.processingOptions) {
        for (const subOption of option.options) {
          if (subOption.value === value) {
            return subOption.label;
          }
        }
      }
      return null;

    default:
      return null;
  }
};
