import { debounce } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

export function useVBillDomElementWidth() {
  const domElementRef = useRef<HTMLDivElement | null>(null);
  const [elementWidth, setElementWidth] = useState<number | undefined>(undefined);

  const resizeObserver = useMemo(
    () =>
      new ResizeObserver((entries) => {
        const firstEntryWidth = entries?.[0].borderBoxSize?.[0].inlineSize.toFixed(2) ?? "";
        debouncedSetElementWidth(Number(firstEntryWidth));
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetElementWidth = useCallback(debounce(setElementWidth, 250), []);

  useEffect(() => {
    const element = domElementRef?.current;

    if (!element) {
      return;
    }

    resizeObserver.observe(element);

    return () => {
      resizeObserver.unobserve(element);
      debouncedSetElementWidth.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    domElementRef,
    width: elementWidth,
  };
}
