import { IAclPermissionRow } from "../../../../../../storesMobx/AclStore";
import { AclOnChangeHanddler, AclPermissionGroupName } from "./Permissions";
import { AclPermLimitSimpleMaxValueInput } from "./AclPermLimitSimpleMaxValueInput";
import { AclPermLimitCreateCheckRulesInput } from "./AclPermLimitCreateCheckRulesInput";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { COLORS } from "../../../../../../themes/default";

export const AclPermLimitEdit = ({
  PGNAME,
  value,
  onChange,
  row,
  hasDirect,
}: {
  PGNAME: AclPermissionGroupName;
  row: IAclPermissionRow;
  onChange: AclOnChangeHanddler;
  value: any;
  hasDirect: boolean;
}) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Typography variant="body2" color={COLORS.dark} sx={{ fontWeight: 500, mb: 2 }}>
        <label>
          <input
            type="checkbox"
            checked={hasDirect}
            onChange={(e) => {
              onChange(PGNAME, row, e.target.checked);
            }}
          />
          Override{" "}
        </label>
      </Typography>
      {hasDirect ? (
        <>
          {PGNAME === "BANK_ACCOUNT_LEDGER_ACCESS" ? (
            <AclPermLimitSimpleMaxValueInput
              value={value}
              onChange={(limit_ledger_access_max_amount) =>
                onChange(PGNAME, row, true, { limit_ledger_access_max_amount })
              }
            />
          ) : null}
          {PGNAME === "BANK_ACCOUNT_CHECK_SIGNER" ? (
            <AclPermLimitSimpleMaxValueInput
              value={value}
              onChange={(limit_approve_vchecks_max_amount) =>
                onChange(PGNAME, row, true, { limit_approve_vchecks_max_amount })
              }
            />
          ) : null}
          {PGNAME === "BANK_ACCOUNT_CHECK_MANAGE" ? (
            <AclPermLimitSimpleMaxValueInput
              value={value}
              onChange={(limit_manage_vchecks_max_amount) =>
                onChange(PGNAME, row, true, { limit_manage_vchecks_max_amount })
              }
            />
          ) : null}
          {PGNAME === "BANK_ACCOUNT_CHECK_CREATE" ? (
            <AclPermLimitCreateCheckRulesInput
              value={value}
              onChange={(limit_create_vchecks_rules) => onChange(PGNAME, row, true, { limit_create_vchecks_rules })}
            />
          ) : null}
        </>
      ) : null}
    </Box>
  );
};
