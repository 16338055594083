import { IVBillInvoiceIncomingStatus, IVBillVBillInvoicesQuery } from "generated/sdk.vbill";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { getVBillFormattedDate, getVBillUrl } from "./utils";

export function useVBillUrl(invoice?: Pick<IVBillVBillInvoicesQuery["invoices"]["items"][number], "id" | "status">) {
  const { organizationId } = useParams<{ organizationId: string }>();
  const url = useMemo(
    () => getVBillUrl(organizationId, invoice?.id ?? 0, invoice?.status ?? IVBillInvoiceIncomingStatus.Queued),
    [invoice?.id, invoice?.status, organizationId],
  );

  if (!invoice) {
    return "";
  }

  return url;
}

export function useVBillFormattedDate(dateString?: string | null) {
  const formattedDate = useMemo(() => getVBillFormattedDate(dateString), [dateString]);

  return formattedDate;
}
