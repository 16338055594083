import CloseIcon from "@mui/icons-material/Close";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import { Backdrop, InputAdornment, Menu, TextField, Tooltip, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import classNames from "classnames";
import useUndepositedChecks from "common/hooks/useUndepositedChecks";
import { CheckQuickFiltersEnumType } from "generated/sdk";
import { SearchIcon } from "icons/svg/SearchIcon";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useHistory, useParams } from "react-router-dom";
import { useStore } from "storeContainer";
import { CompatNavLink } from "../../common/CompatNavLink/CompatNavLink";
import styles from "./Header.module.scss";
import { VBillOrgMenu } from "./VBillOrgMenu/VBillOrgMenu";

const OrganizationHeader = observer(function OrganizationHeader() {
  const sessionStore = useStore("SessionStore");
  const contextStore = useStore("ContextStore");
  const { acl } = sessionStore;
  const history = useHistory();
  const [companySearchValue, setCompanySearchValue] = useState("");
  const organizationStore = useStore("OrganizationStore");
  const { companyId, organizationId } = useParams<{ companyId?: string; organizationId: string }>();
  const companies = organizationId ? sessionStore.companiesOfOrganization(organizationId) : [];
  const canSeeAccountManagement = companies.some((company) => acl.canListCompany(company.id));
  const aclCompanies = companies.filter((company) => acl.canListCompany(company.id));
  const { hasUndepositedChecks, totalUndepositedChecks } = useUndepositedChecks();

  const { pathname } = useLocation();
  const closeIconStyle = {
    height: "16x",
    width: "16px",
    cursor: "pointer",
  };
  const [anchorElAccount, setAnchorElAccount] = React.useState<null | HTMLElement>(null);
  const openAccount = Boolean(anchorElAccount);
  const handleCloseAccount = () => {
    setAnchorElAccount(null);
  };
  useEffect(() => {
    if (organizationId) {
      organizationStore.loadDefaultOrganizationDashboard(organizationId);
      contextStore.setSelectedOrganizationId(organizationId);
    }
  }, [contextStore, organizationId, organizationStore]);

  return (
    <Box display="flex" alignItems="center" width="100%" justifyContent="center" gap={"70px"}>
      <CompatNavLink
        to={`/org/${organizationId}/vchecks/payments/${CheckQuickFiltersEnumType.PendingSignature}`}
        className={classNames([
          styles.link,
          (pathname.includes("/vchecks/payments") || pathname.includes("/vbillpaymentsledger")) && styles.active, // TODO: better route url "/vbillpaymentsledger"
        ])}
        activeClassName={styles.active}
      >
        Payments
      </CompatNavLink>

      <CompatNavLink
        to={`/org/${organizationId}/vchecks/deposits/${CheckQuickFiltersEnumType.ReadyToDeposit}`}
        className={classNames([styles.link, pathname.includes("/vchecks/deposits") && styles.active])}
        activeClassName={styles.active}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {hasUndepositedChecks && (
            <Tooltip arrow title={`Ready to deposit: (${totalUndepositedChecks})`}>
              <PriorityHighIcon
                sx={{
                  border: "1px solid #ff9b19",
                  color: "#ff9b19",
                  borderRadius: "9px",
                  width: "18px",
                  height: "18px",
                  padding: "2px",
                  marginRight: "8px",
                }}
              />
            </Tooltip>
          )}
          Deposits
        </Box>
      </CompatNavLink>

      <CompatNavLink to={`/org/${organizationId}/bchecks`} className={styles.link} activeClassName={styles.active}>
        Budget Management
      </CompatNavLink>

      <VBillOrgMenu />
      {/* <CompatNavLink
          to={`/org/${organizationId}/vbillbatchledger`}
          className={styles.link}
          activeClassName={styles.active}
        >
          vBill Batch
        </CompatNavLink> */}

      <CompatNavLink to={`/org/${organizationId}/invoice`} className={styles.link} activeClassName={styles.active}>
        Invoices
      </CompatNavLink>

      {(canSeeAccountManagement || acl.canListCompanies) && (
        <Box display={"flex"} alignItems={"center"} height="40px">
          <CompatNavLink
            to={`/org/${organizationId}/organization-accounts`}
            className={styles.link}
            activeClassName={styles.active}
          >
            Settings
          </CompatNavLink>

          <Menu
            id="basic-menu"
            anchorEl={anchorElAccount}
            open={openAccount}
            onClose={handleCloseAccount}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            PaperProps={{
              className: styles.menuListContainer,
            }}
            BackdropComponent={Backdrop}
          >
            {/* TODO investigate what is causing the Select company: text to be focused when the first search character is "s", it only seems to do this when the text is on top, so I've done a small css workaround (sarma), this will display the menu in the same way as before, but from an order standpoint the text is now after the input and that seems to solve the focus issue, but I have no ideea why. Also if you change the text to elect company: than the bug happens when the first char is "e" */}
            <Box display="flex" flexDirection="column-reverse">
              {aclCompanies?.length > 5 && (
                <TextField
                  sx={{ marginTop: "15px" }}
                  placeholder="Search"
                  value={companySearchValue}
                  onChange={(event) => {
                    setCompanySearchValue(event.target.value);
                  }}
                  onKeyPress={(ev) => {
                    if (ev.key === "Enter") {
                      ev.preventDefault();
                    }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {companySearchValue ? (
                          <CloseIcon
                            onClick={() => {
                              setCompanySearchValue("");
                            }}
                            sx={closeIconStyle}
                          />
                        ) : (
                          <SearchIcon sx={closeIconStyle} />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              )}
              <Typography
                variant="h2"
                fontSize="14px"
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
                maxWidth="162px"
              >
                Select Company:
              </Typography>
            </Box>
            <Box className={styles.overflowBox}>
              {aclCompanies
                ?.filter((company) => company.name.toLowerCase().includes(companySearchValue.toLocaleLowerCase()))
                ?.sort(function (currentCompany, nextCompany) {
                  const current = currentCompany.name.toUpperCase();
                  const next = nextCompany.name.toUpperCase();
                  return current < next ? -1 : current > next ? 1 : 0;
                })
                ?.map((company) => {
                  const isSelected = companyId === company.id;
                  return (
                    <Typography
                      key={company.id}
                      className={classNames(styles.menuItemContainer, {
                        [styles.menuItemContainerSelected]: isSelected,
                      })}
                      onClick={() => {
                        history.push(`/org/${organizationId}/company/${company.id}/`);
                        setAnchorElAccount(null);
                      }}
                      title={company.name}
                    >
                      {company.name}
                    </Typography>
                  );
                })}
            </Box>
          </Menu>
        </Box>
      )}
    </Box>
  );
});
export default OrganizationHeader;
