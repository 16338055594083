import { Box, TextField, Tooltip, Typography } from "@mui/material";
import { useCheckFilters } from "common/hooks/useCheckFilters";
import { GetCheckBatchesFiltersType } from "generated/sdk";
import { debounce } from "lodash";
import React, { useEffect, useRef, useState } from "react";

const tooltipTitle = "Search by the Name";

const BudgetManagementSearchFilter: React.FC<{}> = () => {
  const { filters, setFilters } = useCheckFilters<GetCheckBatchesFiltersType>();
  const [value, setValue] = useState(filters?.name || "");
  const debouncedSearch = useRef(
    debounce((query: string) => {
      setFilters((prev: GetCheckBatchesFiltersType) => {
        return { ...prev, name: !query.length ? undefined : query };
      });
    }, 500),
  ).current;

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newValue = event.target.value;
    setValue(newValue);
    debouncedSearch(newValue);
  };

  useEffect(() => {
    if (!filters?.name) {
      setValue("");
    }
  }, [filters?.name]);

  return (
    <Box display="flex" flexDirection="column">
      <Typography sx={{ marginBottom: "5px" }} variant="body2">
        Search
      </Typography>
      <Box>
        <Tooltip title={tooltipTitle}>
          <TextField
            placeholder={"Search by Name"}
            id="search"
            name="search"
            value={value}
            onChange={handleSearchChange}
          />
        </Tooltip>
      </Box>
    </Box>
  );
};

export default BudgetManagementSearchFilter;
