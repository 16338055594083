import gql from "graphql-tag";
import { GraphQLClient } from "graphql-request";
import { RequestInit } from "graphql-request/dist/types.dom";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never };

function fetcher<TData, TVariables extends { [key: string]: any }>(
  client: GraphQLClient,
  query: string,
  variables?: TVariables,
  requestHeaders?: RequestInit["headers"],
) {
  return async (): Promise<TData> =>
    client.request({
      document: query,
      variables,
      requestHeaders,
    });
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: { input: any; output: any };
};

export type IActionlogActionlogFeListOutput = {
  __typename?: "ActionlogFeListOutput";
  error?: Maybe<Scalars["String"]["output"]>;
  hasMore: Scalars["Boolean"]["output"];
  items: Array<IActionlogActionlogFeOutput>;
  success: Scalars["Boolean"]["output"];
  total: Scalars["Int"]["output"];
};

export type IActionlogActionlogFeOutput = {
  __typename?: "ActionlogFeOutput";
  action: Scalars["String"]["output"];
  actionId?: Maybe<Scalars["String"]["output"]>;
  actionTimestamp?: Maybe<Scalars["DateTime"]["output"]>;
  changedBy: IActionlogActionlogIoChangedByOutput;
  id: Scalars["Float"]["output"];
  initiator: Scalars["String"]["output"];
  key: Scalars["String"]["output"];
  meta?: Maybe<IActionlogActionlogIoMetaOutput>;
  path?: Maybe<Array<IActionlogActionlogIoPathOrRelatedOutput>>;
  related?: Maybe<Array<IActionlogActionlogIoPathOrRelatedOutput>>;
  scope: Scalars["String"]["output"];
  type: IActionlogObjectType;
};

export type IActionlogActionlogIoChangedByOutput = {
  __typename?: "ActionlogIOChangedByOutput";
  accountId?: Maybe<Scalars["String"]["output"]>;
  emailAddress?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  organizationUserId?: Maybe<Scalars["String"]["output"]>;
};

export type IActionlogActionlogIoMetaAdditionalContentBlockOutput = {
  __typename?: "ActionlogIOMetaAdditionalContentBlockOutput";
  bindings?: Maybe<Array<IActionlogActionlogIoMetaContentBlockTemplateBindingsOutput>>;
  columns?: Maybe<Array<IActionlogActionlogIoMetaContentBlockTableCellOutput>>;
  message?: Maybe<Scalars["String"]["output"]>;
  related?: Maybe<Array<IActionlogActionlogIoMetaContentBlockMessageRelatedOutput>>;
  rows?: Maybe<Array<IActionlogActionlogIoMetaContentBlockTableRowOutput>>;
  template?: Maybe<Scalars["String"]["output"]>;
  type: IActionlogMetaContentBlockMessageType;
};

export type IActionlogActionlogIoMetaContentBlockMessageOutput = {
  __typename?: "ActionlogIOMetaContentBlockMessageOutput";
  bindings?: Maybe<Array<IActionlogActionlogIoMetaContentBlockTemplateBindingsOutput>>;
  message?: Maybe<Scalars["String"]["output"]>;
  related?: Maybe<Array<IActionlogActionlogIoMetaContentBlockMessageRelatedOutput>>;
  template?: Maybe<Scalars["String"]["output"]>;
  type: IActionlogMetaContentBlockMessageType;
};

export type IActionlogActionlogIoMetaContentBlockMessageRelatedOutput = {
  __typename?: "ActionlogIOMetaContentBlockMessageRelatedOutput";
  key: Scalars["String"]["output"];
  related: IActionlogActionlogIoPathOrRelatedOutput;
};

export type IActionlogActionlogIoMetaContentBlockTableCellOutput = {
  __typename?: "ActionlogIOMetaContentBlockTableCellOutput";
  bindings?: Maybe<Array<IActionlogActionlogIoMetaContentBlockTemplateBindingsOutput>>;
  icon?: Maybe<IActionlogMetaContentBlockTableIcon>;
  message?: Maybe<Scalars["String"]["output"]>;
  template?: Maybe<Scalars["String"]["output"]>;
};

export type IActionlogActionlogIoMetaContentBlockTableRowOutput = {
  __typename?: "ActionlogIOMetaContentBlockTableRowOutput";
  cells?: Maybe<Array<IActionlogActionlogIoMetaContentBlockTableCellOutput>>;
};

export type IActionlogActionlogIoMetaContentBlockTemplateBindingsOutput = {
  __typename?: "ActionlogIOMetaContentBlockTemplateBindingsOutput";
  key: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type IActionlogActionlogIoMetaOutput = {
  __typename?: "ActionlogIOMetaOutput";
  additionalContent?: Maybe<Array<IActionlogActionlogIoMetaAdditionalContentBlockOutput>>;
  content?: Maybe<IActionlogActionlogIoMetaContentBlockMessageOutput>;
};

export type IActionlogActionlogIoPathOrRelatedOutput = {
  __typename?: "ActionlogIOPathOrRelatedOutput";
  key: Scalars["String"]["output"];
  label?: Maybe<Scalars["String"]["output"]>;
  type: IActionlogObjectType;
};

export type IActionlogActionlogListFiltersInput = {
  actionId?: InputMaybe<Scalars["String"]["input"]>;
  key: Scalars["String"]["input"];
  level?: InputMaybe<IActionlogLevelType>;
  organizationId: Scalars["String"]["input"];
  transactionId?: InputMaybe<Scalars["String"]["input"]>;
  type: IActionlogObjectType;
};

export type IActionlogActionlogListPaginationInput = {
  page: Scalars["Float"]["input"];
  per_page?: InputMaybe<Scalars["Float"]["input"]>;
};

export enum IActionlogLevelType {
  Public = "public",
  Silent = "silent",
}

export enum IActionlogMetaContentBlockMessageType {
  Message = "MESSAGE",
  Table = "TABLE",
}

export enum IActionlogMetaContentBlockTableIcon {
  IconRight = "ICON_RIGHT",
}

export enum IActionlogObjectType {
  Account = "account",
  Bankaccount = "bankaccount",
  Bill = "bill",
  Billbatch = "billbatch",
  Check = "check",
  Checkbatch = "checkbatch",
  Company = "company",
  Department = "department",
  Organization = "organization",
  Payment = "payment",
}

export type IActionlogQuery = {
  __typename?: "Query";
  getActions: IActionlogActionlogFeListOutput;
};

export type IActionlogQueryGetActionsArgs = {
  filters: IActionlogActionlogListFiltersInput;
  pagination: IActionlogActionlogListPaginationInput;
};

export type IActionlogActionLogGetActionsQueryVariables = Exact<{
  pagination: IActionlogActionlogListPaginationInput;
  filters: IActionlogActionlogListFiltersInput;
}>;

export type IActionlogActionLogGetActionsQuery = {
  __typename?: "Query";
  getActions: {
    __typename?: "ActionlogFeListOutput";
    total: number;
    hasMore: boolean;
    success: boolean;
    items: Array<{
      __typename?: "ActionlogFeOutput";
      id: number;
      key: string;
      type: IActionlogObjectType;
      initiator: string;
      scope: string;
      action: string;
      actionId?: string | null;
      actionTimestamp?: any | null;
      path?: Array<{
        __typename?: "ActionlogIOPathOrRelatedOutput";
        key: string;
        type: IActionlogObjectType;
        label?: string | null;
      }> | null;
      related?: Array<{
        __typename?: "ActionlogIOPathOrRelatedOutput";
        key: string;
        type: IActionlogObjectType;
        label?: string | null;
      }> | null;
      changedBy: {
        __typename?: "ActionlogIOChangedByOutput";
        accountId?: string | null;
        organizationUserId?: string | null;
        name?: string | null;
        emailAddress?: string | null;
      };
      meta?: {
        __typename?: "ActionlogIOMetaOutput";
        content?: {
          __typename?: "ActionlogIOMetaContentBlockMessageOutput";
          type: IActionlogMetaContentBlockMessageType;
          message?: string | null;
          template?: string | null;
          bindings?: Array<{
            __typename?: "ActionlogIOMetaContentBlockTemplateBindingsOutput";
            key: string;
            value: string;
          }> | null;
          related?: Array<{
            __typename?: "ActionlogIOMetaContentBlockMessageRelatedOutput";
            key: string;
            related: {
              __typename?: "ActionlogIOPathOrRelatedOutput";
              key: string;
              type: IActionlogObjectType;
              label?: string | null;
            };
          }> | null;
        } | null;
        additionalContent?: Array<{
          __typename?: "ActionlogIOMetaAdditionalContentBlockOutput";
          type: IActionlogMetaContentBlockMessageType;
          message?: string | null;
          template?: string | null;
          bindings?: Array<{
            __typename?: "ActionlogIOMetaContentBlockTemplateBindingsOutput";
            key: string;
            value: string;
          }> | null;
          related?: Array<{
            __typename?: "ActionlogIOMetaContentBlockMessageRelatedOutput";
            key: string;
            related: {
              __typename?: "ActionlogIOPathOrRelatedOutput";
              key: string;
              type: IActionlogObjectType;
              label?: string | null;
            };
          }> | null;
          columns?: Array<{
            __typename?: "ActionlogIOMetaContentBlockTableCellOutput";
            icon?: IActionlogMetaContentBlockTableIcon | null;
            message?: string | null;
            template?: string | null;
          }> | null;
          rows?: Array<{
            __typename?: "ActionlogIOMetaContentBlockTableRowOutput";
            cells?: Array<{
              __typename?: "ActionlogIOMetaContentBlockTableCellOutput";
              icon?: IActionlogMetaContentBlockTableIcon | null;
              message?: string | null;
              template?: string | null;
            }> | null;
          }> | null;
        }> | null;
      } | null;
    }>;
  };
};

export const ActionLogGetActions = gql`
  query ActionLogGetActions($pagination: ActionlogListPaginationInput!, $filters: ActionlogListFiltersInput!) {
    getActions(pagination: $pagination, filters: $filters) {
      items {
        id
        key
        type
        path {
          key
          type
          label
        }
        related {
          key
          type
          label
        }
        changedBy {
          accountId
          organizationUserId
          name
          emailAddress
        }
        initiator
        scope
        action
        actionId
        actionTimestamp
        meta {
          content {
            type
            message
            template
            bindings {
              key
              value
            }
            related {
              key
              related {
                key
                type
                label
              }
            }
          }
          additionalContent {
            type
            message
            template
            bindings {
              key
              value
            }
            related {
              key
              related {
                key
                type
                label
              }
            }
            columns {
              icon
              message
              template
            }
            rows {
              cells {
                icon
                message
                template
              }
            }
          }
        }
      }
      total
      hasMore
      success
    }
  }
`;

export const ActionLogGetActionsDocument = `
    query ActionLogGetActions($pagination: ActionlogListPaginationInput!, $filters: ActionlogListFiltersInput!) {
  getActions(pagination: $pagination, filters: $filters) {
    items {
      id
      key
      type
      path {
        key
        type
        label
      }
      related {
        key
        type
        label
      }
      changedBy {
        accountId
        organizationUserId
        name
        emailAddress
      }
      initiator
      scope
      action
      actionId
      actionTimestamp
      meta {
        content {
          type
          message
          template
          bindings {
            key
            value
          }
          related {
            key
            related {
              key
              type
              label
            }
          }
        }
        additionalContent {
          type
          message
          template
          bindings {
            key
            value
          }
          related {
            key
            related {
              key
              type
              label
            }
          }
          columns {
            icon
            message
            template
          }
          rows {
            cells {
              icon
              message
              template
            }
          }
        }
      }
    }
    total
    hasMore
    success
  }
}
    `;

export const useActionLogGetActionsQuery = <TData = IActionlogActionLogGetActionsQuery, TError = unknown>(
  client: GraphQLClient,
  variables: IActionlogActionLogGetActionsQueryVariables,
  options?: UseQueryOptions<IActionlogActionLogGetActionsQuery, TError, TData>,
  headers?: RequestInit["headers"],
) => {
  return useQuery<IActionlogActionLogGetActionsQuery, TError, TData>(
    ["ActionLogGetActions", variables],
    fetcher<IActionlogActionLogGetActionsQuery, IActionlogActionLogGetActionsQueryVariables>(
      client,
      ActionLogGetActionsDocument,
      variables,
      headers,
    ),
    options,
  );
};
