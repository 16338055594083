import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Collapse, Menu, MenuItem } from "@mui/material";
import { graphqlVBillClient } from "common/graphqlClient";
import { useVBillBatchLedgerQuickFilterUrl } from "components/pages/VBillBatchLedger/hooks";
import { VBillBatchLedgerChildRoutes } from "components/pages/VBillBatchLedger/utils";
import { getSdk, IVBillBatchStatus } from "generated/sdk.vbill";
import { pick } from "lodash";
import { observer } from "mobx-react-lite";
import { useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useStore } from "storeContainer";
import { MoveInsideAnoterBatchDialog } from "../../common/BatchLineMenu/MoveInsideAnoterBatchDialog/MoveInsideAnoterBatchDialog";
import { ConfirmBatchActionDialog } from "../../common/ConfirmBatchActionDialog/ConfirmBatchActionDialog";

const { VBillDeleteBatch, VBillUpdateBatchStatus } = getSdk(graphqlVBillClient);

interface ISummariesMenuDialogProps {
  menuAnchorEl: null | HTMLElement;
  onCloseMenu: () => void;
}

export const SummariesMenuDialog = observer(function SummariesMenu({
  menuAnchorEl,
  onCloseMenu,
}: ISummariesMenuDialogProps) {
  const vBillBatchDetailsStore = useStore("VBillBatchDetailsStore");
  const history = useHistory();
  const vbillBatchLedgerUrl = useVBillBatchLedgerQuickFilterUrl(VBillBatchLedgerChildRoutes.ALL);
  const { batchId, organizationId } = useParams<{ batchId: string; organizationId: string }>();
  const [moveBatchSubmenuOpen, setMoveBatchSubmenuOpen] = useState(false);
  const [isMoveInsideAnotherBatchDialogOpen, setIsMoveInsideAnotherBatchDialogOpen] = useState(false);
  const [isMoveAtTopLevelBatchDialogOpen, setIsMoveAtTopLevelBatchDialogOpen] = useState(false);
  const [isConfirmChangeBatchStatusDialogOpen, setIsConfirmChangeBatchStatusDialogOpen] = useState(false);
  const [confirmChangeBatchStatus, setConfirmChangeBatchStatus] = useState<IVBillBatchStatus>();
  const [isRemoveBillsDialogOpen, setIsRemoveBillsDialogOpen] = useState(false);
  const { batchDetails, batchFilters, batchSelectedVBills } = vBillBatchDetailsStore;
  const batchSelectedVBillsData = batchSelectedVBills.data;
  const batchDetailsData = batchDetails.data;
  const currentStatusMenuOptions = useMemo(
    () => Object.values(IVBillBatchStatus).filter((status) => status !== batchDetailsData?.status),
    [batchDetailsData?.status],
  );
  const hasBatchSelectedBills = useMemo(
    () => (batchSelectedVBillsData?.items ?? []).length > 0,
    [batchSelectedVBillsData?.items],
  );

  const handleConfirmChangeBatchStatusSubmit = async (status: IVBillBatchStatus) => {
    if (status === IVBillBatchStatus.Deleted) {
      await VBillDeleteBatch({ batchId: Number(batchId) });
    } else {
      await VBillUpdateBatchStatus({ batchId: Number(batchId), batchStatus: { status } });
    }

    setIsConfirmChangeBatchStatusDialogOpen(false);
    setConfirmChangeBatchStatus(undefined);

    history.push(vbillBatchLedgerUrl);
  };

  const handleConfirmRemoveBillsSubmit = async () => {
    await vBillBatchDetailsStore.removeVBillFromBatch({
      batchId: Number(batchId),
      invoiceIds: (batchSelectedVBillsData?.items ?? []).map(({ id }) => id),
    });
    await vBillBatchDetailsStore.getBatchSelectedVBills(
      {
        pagination: batchFilters.vbill_sel_pag,
        filters: { ...batchFilters.vbill_sel_fil, organizationId, batch_id_in: [Number(batchId)] },
      },
      true,
    );
    await vBillBatchDetailsStore.getBatchAvailableVBills(
      {
        pagination: batchFilters.vbill_ava_pag,
        filters: {
          ...batchFilters.vbill_ava_fil,
          organizationId,
          batch_id_in: [0],
        },
      },
      true,
    );
    setIsRemoveBillsDialogOpen(false);
  };

  const handleConfirmMoveBatchAsTopLvlSubmit = async () => {
    if (!batchDetailsData) {
      return;
    }

    await vBillBatchDetailsStore.updateBatchDetails({
      batchId: Number(batchId),
      batchData: {
        ...pick(batchDetailsData, ["batchDateDue", "batchFilters", "budget", "name", "organizationId"]),
        parentBatchId: null,
      },
    });
    setIsMoveAtTopLevelBatchDialogOpen(false);
  };

  const isMoreMenuOpen = Boolean(menuAnchorEl);

  return (
    <>
      <Menu
        id="summaries-menu"
        anchorEl={menuAnchorEl}
        open={isMoreMenuOpen}
        onClose={() => {
          onCloseMenu();
        }}
        MenuListProps={{
          "aria-labelledby": "summaries-menu-button",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {hasBatchSelectedBills && (
          <MenuItem
            onClick={() => {
              onCloseMenu();
              setMoveBatchSubmenuOpen(false);
              setIsRemoveBillsDialogOpen(true);
            }}
          >
            Remove added bills
          </MenuItem>
        )}

        {batchDetailsData?.settings?.BATCH_SUBBATCHES_ALLOWED && (
          <>
            <MenuItem
              onClick={() => {
                setMoveBatchSubmenuOpen((prev) => !prev);
              }}
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              Move Batch
              {moveBatchSubmenuOpen ? <ExpandLess /> : <ExpandMore />}
            </MenuItem>

            <Collapse in={moveBatchSubmenuOpen} sx={{ paddingLeft: "10px" }}>
              <MenuItem
                onClick={() => {
                  onCloseMenu();
                  setMoveBatchSubmenuOpen(false);
                  setIsMoveAtTopLevelBatchDialogOpen(true);
                }}
              >
                As top level
              </MenuItem>
              <MenuItem
                onClick={() => {
                  onCloseMenu();
                  setMoveBatchSubmenuOpen(false);
                  setIsMoveInsideAnotherBatchDialogOpen(true);
                }}
              >
                Inside another batch
              </MenuItem>
            </Collapse>
          </>
        )}

        {currentStatusMenuOptions.map((status) => (
          <MenuItem
            key={status}
            onClick={() => {
              setMoveBatchSubmenuOpen(false);
              onCloseMenu();
              setConfirmChangeBatchStatus(status);
              setIsConfirmChangeBatchStatusDialogOpen(true);
            }}
          >
            {status === IVBillBatchStatus.Open
              ? "Open"
              : status === IVBillBatchStatus.Closed
              ? "Close"
              : status === IVBillBatchStatus.Deleted
              ? "Delete"
              : undefined}{" "}
            Batch
          </MenuItem>
        ))}
      </Menu>

      {/* TODO: text messages */}
      {isConfirmChangeBatchStatusDialogOpen && confirmChangeBatchStatus && (
        <ConfirmBatchActionDialog
          title={`${
            confirmChangeBatchStatus === IVBillBatchStatus.Open
              ? "Open"
              : confirmChangeBatchStatus === IVBillBatchStatus.Closed
              ? "Close"
              : confirmChangeBatchStatus === IVBillBatchStatus.Deleted
              ? "Delete"
              : ""
          } batch`}
          text={`Are you sure you want to ${
            confirmChangeBatchStatus === IVBillBatchStatus.Open
              ? "open"
              : confirmChangeBatchStatus === IVBillBatchStatus.Closed
              ? "close"
              : confirmChangeBatchStatus === IVBillBatchStatus.Deleted
              ? "delete"
              : ""
          } this batch?`}
          confirmBtnText={`${
            confirmChangeBatchStatus === IVBillBatchStatus.Open
              ? "Open"
              : confirmChangeBatchStatus === IVBillBatchStatus.Closed
              ? "Close"
              : confirmChangeBatchStatus === IVBillBatchStatus.Deleted
              ? "Delete"
              : ""
          } batch`}
          onCloseDialog={() => setIsConfirmChangeBatchStatusDialogOpen(false)}
          actionsBtnSmallHeight
          onConfirmSubmit={() => handleConfirmChangeBatchStatusSubmit(confirmChangeBatchStatus)}
        />
      )}

      {isMoveInsideAnotherBatchDialogOpen && batchDetailsData && (
        <MoveInsideAnoterBatchDialog
          batch={batchDetailsData}
          onCloseDialog={() => setIsMoveInsideAnotherBatchDialogOpen(false)}
        />
      )}

      {isMoveAtTopLevelBatchDialogOpen && (
        <ConfirmBatchActionDialog
          title="Move batch"
          text="Are you sure you want to move this batch as top level?"
          confirmBtnText="Move batch"
          onCloseDialog={() => setIsMoveAtTopLevelBatchDialogOpen(false)}
          actionsBtnSmallHeight
          onConfirmSubmit={handleConfirmMoveBatchAsTopLvlSubmit}
        />
      )}

      {isRemoveBillsDialogOpen && (
        <ConfirmBatchActionDialog
          title="Remove added bills"
          text="Are you sure you want to remove added bills?"
          confirmBtnText="Remove bills"
          onCloseDialog={() => setIsRemoveBillsDialogOpen(false)}
          actionsBtnSmallHeight
          onConfirmSubmit={handleConfirmRemoveBillsSubmit}
        />
      )}
    </>
  );
});
