import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { LedgerCheck, OrganizationBasic } from "generated/sdk";
import { useState } from "react";
import { useStore } from "storeContainer";
import { AddBankAccountSource } from "storesMobx/UiStore";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import styles from "../UndepositedChecks.module.scss";
import Modal from "@mui/material/Modal";
import { COLORS } from "themes/default";
import { SuggestedBankAccounts } from "./SuggestedBankAccounts";
import { BrowseAccounts } from "./BrowseAccounts";
import { Spinner } from "../../../common/Spinner/Spinner";
import { Backdrop } from "@mui/material";

export const DepositCheckModal = ({
  allChecks,
  availableBankAccounts,
  close,
  openSelectBankAccountModal,
  selectedBankAccount,
  setSelectedBankAccountId,
  setOpenReviewAndConfirm,
  handleCloseDepositAll,
}: {
  allChecks: LedgerCheck[];
  availableBankAccounts: (OrganizationBasic & { aggKeys: string })[];
  close: Function;
  openSelectBankAccountModal: boolean;
  selectedBankAccount?: string;
  setSelectedBankAccountId: Function;
  setOpenReviewAndConfirm?: Function;
  handleCloseDepositAll: () => void;
}) => {
  const UiStore = useStore("UiStore");
  const [selectedTab, setSelectedTab] = useState("browseAccounts");
  const handleChangeTab = (e: React.SyntheticEvent<Element, Event>, value: string) => {
    setSelectedBankAccountId(undefined);
    setSelectedTab(value);
  };

  return (
    <Modal
      style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      open={openSelectBankAccountModal}
      onClose={(event, reason) => {
        if (setOpenReviewAndConfirm) {
          handleCloseDepositAll();
        }
      }}
      disableEscapeKeyDown
    >
      <Box width={"500px"}>
        <Paper sx={{ padding: "0 20px 20px 20px" }}>
          <Grid xs={12} justifyContent={"flex-end"} container item>
            <Grid item>
              <IconButton onClick={() => close()} sx={{ padding: "5px", marginTop: "10px" }} children={<CloseIcon />} />
            </Grid>
          </Grid>
          <Grid className={styles.scrollbarDiv} sx={{ justifyContent: "center" }} container>
            <Grid xs={12} item>
              <Typography color={COLORS.logoBlue} marginBottom={"30px"} fontSize={25} fontWeight={500}>
                Deposit VChecks
              </Typography>
            </Grid>
            {availableBankAccounts === undefined ? (
              <Spinner />
            ) : (
              <>
                <Grid xs={12} item>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    {/*
                //! TEMP DISABLED
                <Tabs
                  textColor="secondary"
                  indicatorColor="secondary"
                  value={selectedTab}
                  onChange={handleChangeTab}
                  aria-label="basic tabs example"
                >
                  <Tab
                    sx={{ fontSize: 18, textTransform: "capitalize", color: COLORS.inactiveBlue }}
                    value={"suggested"}
                    label="Suggested"
                  />
                  <Tab
                    sx={{ fontSize: 18, textTransform: "capitalize", color: COLORS.inactiveBlue }}
                    value={"browseAccounts"}
                    label="Browse Accounts"
                  />
                </Tabs> */}
                  </Box>
                </Grid>
                <Box
                  sx={{
                    width: "100%",
                    paddingRight: "0px",
                    marginTop: "10px",
                    overflowX: "hidden",
                    overflowY: "auto",
                    scrollbarWidth: "1px",
                    maxHeight: "700px",
                  }}
                >
                  {selectedTab === "suggested" && <SuggestedBankAccounts />}
                  {selectedTab === "browseAccounts" && (
                    <BrowseAccounts
                      allChecks={allChecks}
                      availableBankAccounts={availableBankAccounts}
                      selectedBankAccount={selectedBankAccount}
                      setSelectedBankAccountId={setSelectedBankAccountId}
                      setOpenReviewAndConfirm={setOpenReviewAndConfirm}
                      handleCloseDepositAll={handleCloseDepositAll}
                    />
                  )}
                </Box>

                <Divider
                  sx={{
                    borderBottomWidth: 2,
                    width: "380px",
                    height: "2px",
                    marginTop: "10px",
                  }}
                />
                <Grid sx={{ paddingTop: "10px" }} xs={12} justifyContent={"space-between"} container item>
                  <Grid xs={3} item>
                    <Button
                      fullWidth
                      sx={{ padding: "10px 15px", fontSize: 13, textTransform: "capitalize", height: "100%" }}
                      color="secondary"
                      variant="outlined"
                      onClick={() => {
                        UiStore.showAddBankAccountModal = AddBankAccountSource.Unknown;
                      }}
                    >
                      <AddIcon fontSize="small" /> Add
                    </Button>
                  </Grid>

                  <Grid xs={7} item>
                    <Button
                      disabled={!Boolean(selectedBankAccount)}
                      onClick={() => {
                        setOpenReviewAndConfirm!(true);
                      }}
                      fullWidth
                      sx={{ padding: "10px 15px", fontWeight: "600", fontSize: 16, textTransform: "capitalize" }}
                      color="secondary"
                      variant="contained"
                    >
                      Review & Confirm
                    </Button>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </Paper>
      </Box>
    </Modal>
  );
};
