import { FunctionComponent, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { Spinner } from "components/common/Spinner/Spinner";
import { Pagination } from "generated/graphql";
import styles from "./PendingChecksPagination.module.scss";
import TablePagination from "@mui/material/TablePagination";

interface IPendingChecksPaginationProps {
  pageNumber: number;
  total: number;
  per_page: number;
  updatePageNumber: (nr: number) => void;
  setPerPage: Function;
  pendingChecksFetcher: (pagination: Pagination) => Promise<void>;
  setLoading: Function;
}

export const PendingChecksPagination: FunctionComponent<IPendingChecksPaginationProps> = ({
  pageNumber,
  total,
  per_page,
  updatePageNumber,
  pendingChecksFetcher,
  setPerPage,
  setLoading,
}) => {
  if (!total) {
    return null;
  }
  const handlePageChange = async (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    setLoading(true);
    updatePageNumber(page + 1);
    await pendingChecksFetcher({ page: page + 1, per_page });
    setLoading(false);
  };
  const changeRowsPerPage = async (e: any) => {
    setLoading(true);
    updatePageNumber(1);
    setPerPage(e.target.value);
    await pendingChecksFetcher({ page: 1, per_page: e.target.value });
    setLoading(false);
  };
  return (
    <div className={styles.pagination}>
      <TablePagination
        onRowsPerPageChange={changeRowsPerPage}
        count={total}
        rowsPerPage={per_page}
        page={pageNumber - 1}
        onPageChange={handlePageChange}
        showLastButton
        showFirstButton
      />
    </div>
  );
};
