import { uniq } from "lodash";
import { observer } from "mobx-react";
import { useMemo } from "react";
import { useStore } from "storeContainer";
import { PayVBillsCompaniesMappingsSettingsContextProvider } from "./PayVBillsCompaniesMappingsSettings";
import { IPayVBillsDialogProps, PayVBillsDialog } from "./PayVBillsDialog";

export const PayVBillsWithCompaniesSettings = observer(function PayVBillsWithCompaniesSettings({
  ...props
}: IPayVBillsDialogProps) {
  const vBillPayLedgerStore = useStore("VBillPayLedgerStore");
  const { selectedBillIdsToPay, billsLedgerList } = vBillPayLedgerStore;

  const billsCompanyIds = useMemo(() => {
    const companyIds = (billsLedgerList.data?.items ?? [])
      .filter(({ id }) => selectedBillIdsToPay.includes(id))
      .map(({ companyId }) => companyId ?? "");

    return uniq(companyIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billsLedgerList.data?.items]);

  return (
    <PayVBillsCompaniesMappingsSettingsContextProvider companyIds={billsCompanyIds}>
      <PayVBillsDialog {...props} />
    </PayVBillsCompaniesMappingsSettingsContextProvider>
  );
});
