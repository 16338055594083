import React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export const Completed: React.FC<SvgIconProps> = ({ fill, stroke, ...props }) => {
  return (
    <SvgIcon width="44" height="44" viewBox="0 0 44 44" fill="none" {...props}>
      <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="44" height="44" rx="22" fill={fill} />
        <path
          d="M9 18.4167H23.375"
          stroke={stroke}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14 28.4167H16.5"
          stroke={stroke}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.625 28.4167H24.625"
          stroke={stroke}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M34 21.5792V27.9292C34 32.3167 32.8875 33.4167 28.45 33.4167H14.55C10.1125 33.4167 9 32.3167 9 27.9292V17.6542C9 13.2667 10.1125 12.1667 14.55 12.1667H23.375"
          stroke={stroke}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M27.125 15.2917L29 17.1667L34 12.1667"
          stroke={stroke}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};
