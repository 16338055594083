import IconButton from "components/common/IconButton";
import { Delete } from "components/common/icons";
import { useMemo } from "react";
import { useStore } from "storeContainer";

const useColumns = (canRemoveBankAccountLinkedEmailAddresses: boolean) => {
  const { deleteLinkedAddress } = useStore("AccountsStore");

  const SessionStore = useStore("SessionStore");
  return useMemo(
    () => [
      {
        Header: "EMAIL",
        accessor: "email",
        headerProps: { width: "35%" },
      },
      {
        Header: "ADDED",
        accessor: "created_at",
        Cell: ({ row }: any) => new Date(row.values.created_at).toLocaleDateString(),
        headerProps: { width: "20%" },
      },
      {
        Header: "ADDED BY",
        accessor: "created_by",
        headerProps: { width: "20%" },
      },
      {
        Header: "STATUS",
        accessor: "status",
        headerProps: { width: "20%" },
      },
      ...(canRemoveBankAccountLinkedEmailAddresses
        ? [
            {
              accessor: "delete",
              disableSortBy: true,
              Cell: ({ row }: any) => (
                <IconButton
                  color="error"
                  onClick={async () => {
                    await deleteLinkedAddress(row.original.id);
                    await SessionStore.loadSession();
                  }}
                >
                  <Delete />
                </IconButton>
              ),
              cellProps: { align: "center", size: "small" },
              headerProps: { align: "center", width: "5%" },
            },
          ]
        : []),
    ],
    [SessionStore, canRemoveBankAccountLinkedEmailAddresses, deleteLinkedAddress],
  );
};

export default useColumns;
