import { TableCell, TableRow, Tooltip } from "@mui/material";
import { FormInputNumber } from "components/pages/VBill/common/Forms/FormInputNumber/FormInputNumber";
import { FormInputText } from "components/pages/VBill/common/Forms/FormInputText/FormInputText";
import { TVBillFormFields } from "components/pages/VBill/types";
import { IVBillIIntegrationIntegrationSettingsVBillAdditionalMappingsEntryShowIn } from "generated/sdk.vbill";
import { partition } from "lodash";
import { observer } from "mobx-react";
import { FunctionComponent, useMemo } from "react";
import { useStore } from "storeContainer";
import { LineItemMappingFieldSelector } from "./LineItemMappingFieldSelector/LineItemMappingFieldSelector";

const defaultTableCellSx = {
  padding: "8px 5px",
  verticalAlign: "top",
};

interface INewLineItemProps {
  index: number;
}

export const NewLineItem: FunctionComponent<INewLineItemProps> = observer(({ index }) => {
  const vBillStore = useStore("VBillStore");
  const { invoice, settings } = vBillStore;
  const settingsData = useMemo(() => settings.data, [settings.data]);
  const invoiceData = useMemo(() => invoice.data, [invoice.data]);
  const [glAccMapping, addMappings] = useMemo(() => {
    const lineAdditionalMappings = (settingsData?.vBill.additionalMappings.line ?? []).filter(
      (additionalMapping) =>
        additionalMapping.showIn !== IVBillIIntegrationIntegrationSettingsVBillAdditionalMappingsEntryShowIn.Payment &&
        additionalMapping.showIn !== IVBillIIntegrationIntegrationSettingsVBillAdditionalMappingsEntryShowIn.None,
    );

    return invoiceData?.hasLineItems ? partition(lineAdditionalMappings, (map) => map.isGlAccount) : [[], []];
  }, [settingsData?.vBill.additionalMappings.line]);

  return (
    <TableRow sx={{ cursor: "default" }}>
      <TableCell sx={defaultTableCellSx}>
        <Tooltip arrow title="Line number">
          <span>#{index + 1}</span>
        </Tooltip>
      </TableCell>

      <TableCell sx={{ ...defaultTableCellSx, width: "200px" }}>
        <Tooltip arrow title="Description">
          <span>
            <FormInputText name={`lineItems.${index}.description` as unknown as keyof TVBillFormFields} />
          </span>
        </Tooltip>
      </TableCell>

      <TableCell sx={{ ...defaultTableCellSx }}>
        {/* <Typography sx={{ color: COLORS.textGray, marginBottom: "8px" }}>Gl Account:</Typography> */}

        {glAccMapping.length
          ? glAccMapping.map((additionalMapping) => (
              <LineItemMappingFieldSelector
                key={additionalMapping.storeDataType}
                formFieldName={`lineItems.${index}.${additionalMapping.storeDataType}`}
                additionalMappings={additionalMapping}
                invoiceCoreOrgId={invoiceData?.coreOrgId ?? ""}
                invoiceCompanyId={invoiceData?.companyId ?? ""}
              />
            ))
          : null}
      </TableCell>

      {/* <TableCell sx={{ ...defaultTableCellSx,  }}>
        <Typography sx={{ color: COLORS.textGray, marginBottom: "8px" }}>Additional Fields:</Typography>

        {addMappings.length
          ? addMappings.map((additionalMapping) => (
              <LineItemMappingFieldSelector
                key={additionalMapping.storeDataType}
                formFieldName={`lineItems.${index}.${additionalMapping.storeDataType}`}
                additionalMappings={additionalMapping}
                invoiceCoreOrgId={invoiceData?.coreOrgId ?? ""}
                invoiceCompanyId={invoiceData?.companyId ?? ""}
              />
            ))
          : "-"}
      </TableCell> */}

      {addMappings.length
        ? addMappings.map((additionalMapping) => (
            <TableCell key={additionalMapping.storeDataType} sx={{ ...defaultTableCellSx }}>
              <LineItemMappingFieldSelector
                key={additionalMapping.storeDataType}
                formFieldName={`lineItems.${index}.${additionalMapping.storeDataType}`}
                additionalMappings={additionalMapping}
                invoiceCoreOrgId={invoiceData?.coreOrgId ?? ""}
                invoiceCompanyId={invoiceData?.companyId ?? ""}
              />
            </TableCell>
          ))
        : null}

      <TableCell sx={defaultTableCellSx}>
        <Tooltip arrow title="Qty">
          <span>
            <FormInputNumber name={`lineItems.${index}.qty` as unknown as keyof TVBillFormFields} />
          </span>
        </Tooltip>
      </TableCell>

      <TableCell sx={defaultTableCellSx}>
        <Tooltip arrow title="Rate">
          <span>
            <FormInputNumber name={`lineItems.${index}.rate` as unknown as keyof TVBillFormFields} />
          </span>
        </Tooltip>
      </TableCell>

      <TableCell sx={{ ...defaultTableCellSx, textAlign: "right" }}>
        <Tooltip arrow title="Total">
          <span>
            <FormInputNumber name={`lineItems.${index}.amount` as unknown as keyof TVBillFormFields} priceField />
          </span>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
});
