import ForumIcon from "@mui/icons-material/Forum";
import MarkChatReadIcon from "@mui/icons-material/MarkChatRead";
import Tabs, { TabsActions } from "@mui/material/Tabs";
import { ChatIcon } from "components/common/icons";
import { observer } from "mobx-react-lite";
import {
  ChatDetailDashboard,
  IChatChannelParentType,
  IChatContentBlockMessageRelatedType,
  TBaseIoChannel,
} from "modules/Chat";
import { SyntheticEvent, forwardRef, useImperativeHandle, useMemo, useRef } from "react";
import { useParams } from "react-router";
import { useStore } from "storeContainer";
import { StringParam, useQueryParam, withDefault } from "use-query-params";
import { vBillChatPanelQSKey, vBillChatPanels } from "../SidebarTabs/SidebarTabs";
import { TabBtnSmall, a11yProps } from "../SidebarTabs/TabBtn/TabBtn";
import { TabPanel } from "../SidebarTabs/TabPanel/TabPanel";

export interface IChatListRef {
  tabsUpdateIndicator: () => void;
}

export const ChatList = observer(
  forwardRef<IChatListRef>((_, ref) => {
    const vBillStore = useStore("VBillStore");
    const sessionStore = useStore("SessionStore");
    const { organizationId } = useParams<{ organizationId: string }>();
    const [chatPanel, setChatPanel] = useQueryParam<vBillChatPanels>(
      vBillChatPanelQSKey,
      withDefault<any, vBillChatPanels>(StringParam, vBillChatPanels.SUPPORT_CHAT),
      { removeDefaultsFromUrl: true },
    );
    const chatTabsRefAction = useRef<TabsActions>(null);
    const { invoice } = vBillStore;
    const invoiceData = useMemo(() => invoice?.data, [invoice?.data]);
    const sessionAccountId = useMemo(
      () => sessionStore.session.data?.account?.id ?? "",
      [sessionStore.session.data?.account?.id],
    );
    const organizationName = useMemo(
      () =>
        sessionStore.session.data?.organizationUsers?.find(({ organization }) => organization.id === organizationId)
          ?.organization.name ?? "",
      [organizationId, sessionStore.session.data?.organizationUsers],
    );
    const chatRelatedItems = useMemo(
      () =>
        invoiceData?.lineItems?.map(({ id, description, amount }) => ({
          key: `${id ?? ""}`,
          related: {
            key: `${id ?? ""}`,
            label: `${id ?? ""} - ${description} - ${amount}`,
            type: IChatContentBlockMessageRelatedType.Bill,
          },
        })),
      [invoiceData?.lineItems],
    );

    useImperativeHandle(ref, () => ({
      tabsUpdateIndicator() {
        chatTabsRefAction.current?.updateIndicator();
      },
    }));

    // SidebarTabPanels.SUPPORT_CHAT
    const supportChatDetailDashboardBaseIoChannel: TBaseIoChannel = useMemo(
      () => ({
        parentContext: "sending-admin",
        parentKey: `${invoiceData?.id ?? ""}`,
        parentType: IChatChannelParentType.Vbill,
      }),
      [invoiceData?.id],
    );

    //SidebarTabPanels.SR_CHAT
    const srChatDetailDashboardBaseIoChannel: TBaseIoChannel = useMemo(
      () => ({
        parentContext: "sending",
        parentKey: `${invoiceData?.id ?? ""}`,
        parentType: IChatChannelParentType.Vbill,
      }),
      [invoiceData?.id],
    );

    //SidebarTabPanels.RS_CHAT
    const rsChatDetailDashboardBaseIoChannel: TBaseIoChannel = useMemo(
      () => ({
        parentContext: "sending-receiving",
        parentKey: `${invoiceData?.id ?? ""}`,
        parentType: IChatChannelParentType.Vbill,
      }),
      [invoiceData?.id],
    );

    return (
      <>
        <Tabs
          textColor="primary"
          indicatorColor="primary"
          centered
          value={chatPanel}
          onChange={(_event: SyntheticEvent<Element, Event>, value: vBillChatPanels) => setChatPanel(value)}
          aria-label="chat tabs"
          action={chatTabsRefAction}
          sx={{ minHeight: "auto" }}
        >
          <TabBtnSmall
            icon={<ChatIcon />}
            iconPosition="start"
            label="Cherry"
            {...a11yProps(0)}
            value={vBillChatPanels.SUPPORT_CHAT}
          />
          <TabBtnSmall
            icon={<ForumIcon />}
            iconPosition="start"
            label={organizationName}
            {...a11yProps(1)}
            value={vBillChatPanels.SR_CHAT}
          />
          <TabBtnSmall
            icon={<MarkChatReadIcon />}
            iconPosition="start"
            label={invoiceData?.senderName ?? ""}
            {...a11yProps(2)}
            value={vBillChatPanels.RS_CHAT}
          />
        </Tabs>

        <TabPanel activePannel={chatPanel} panelName={vBillChatPanels.SUPPORT_CHAT} sx={{ padding: "0" }}>
          <ChatDetailDashboard
            config={{
              baseIoChannel: supportChatDetailDashboardBaseIoChannel,
              organizationId: organizationId ?? "",
              sessionAccountId: sessionAccountId,
            }}
            settings={{
              forcePublic: true,
              // channelComingSoon: isNil(organizationId),
            }}
            relatedItems={chatRelatedItems}
            sx={{ height: `calc(100vh - 210px)` }}
            channelSx={{ height: "100%", display: "flex", flexDirection: "column" }}
          />
        </TabPanel>
        <TabPanel activePannel={chatPanel} panelName={vBillChatPanels.SR_CHAT} sx={{ padding: "0" }}>
          <ChatDetailDashboard
            config={{
              baseIoChannel: srChatDetailDashboardBaseIoChannel,
              organizationId: organizationId ?? "",
              sessionAccountId: sessionAccountId,
            }}
            settings={{
              showMembers: true,
              showVisibility: true,
              // channelComingSoon: isNil(organizationId),
            }}
            relatedItems={chatRelatedItems}
            sx={{ height: `calc(100vh - 210px)` }}
            channelSx={{ height: "100%", display: "flex", flexDirection: "column" }}
          />
        </TabPanel>
        <TabPanel activePannel={chatPanel} panelName={vBillChatPanels.RS_CHAT} sx={{ padding: "0" }}>
          <ChatDetailDashboard
            config={{
              baseIoChannel: rsChatDetailDashboardBaseIoChannel,
              organizationId: organizationId ?? "",
              sessionAccountId: sessionAccountId,
            }}
            settings={{
              showMembers: true,
              showVisibility: true,
              channelComingSoon: true,
            }}
            relatedItems={chatRelatedItems}
            sx={{ height: `calc(100vh - 210px)` }}
            channelSx={{ height: "100%", display: "flex", flexDirection: "column" }}
          />
        </TabPanel>
      </>
    );
  }),
);
