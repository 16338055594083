import LinkIcon from "@mui/icons-material/Link";
import PreviewIcon from "@mui/icons-material/Preview";
import { Box, Button, Checkbox, IconButton, TableCell, TableRow, Tooltip, Typography } from "@mui/material";
import { formatCurrency, getSODAsUTCIsoString } from "common/helpers/utils";
import { EditIcon } from "components/common/icons";
import { useVBillUrl } from "components/pages/common/VBill/hooks";
import { getVBillFormattedDate } from "components/pages/common/VBill/utils";
import { formatDistanceStrict } from "date-fns";
import {
  IVBillIIntegrationDataType,
  IVBillReactionParentType,
  IVBillVBillCompaniesSettingsQuery,
  IVBillVBillGetReactionsStatsQuery,
  IVBillVBillInvoicesQuery,
} from "generated/sdk.vbill";
import { useMemo, useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { useParams } from "react-router";
import { COLORS } from "themes/default";
import { Reactions } from "../../common/Reactions/Reactions";
import { VBillChangeAmountDialog } from "./VBillChangeAmountDialog/VBillChangeAmountDialog";
import { VBillChangeMemoDialog } from "./VBillChangeMemoDialog/VBillChangeMemoDialog";
import { VBillDetailsDrawer } from "./VBillDetailsDrawer/VBillDetailsDrawer";

const defaultTableCellSx = {
  padding: "5px",
  fontSize: "12px",
  lineHeight: "14px",
  fontWeight: "500",
};

interface IBatchVBillProps {
  index: number;
  vBill: IVBillVBillInvoicesQuery["invoices"]["items"][number];
  checkboxSelected: boolean;
  onCheckboxChange: (checked: boolean) => void;
  selectedVBill?: boolean;
  isDragDisabled?: boolean;
  isBatchClosed?: boolean;
  selectedVBillsReactionsStats?: IVBillVBillGetReactionsStatsQuery["getReactionsStats"]["groups"];
  additionalMappingsSettings?: IVBillVBillCompaniesSettingsQuery["companiesSettings"]["vBill"]["additionalMappings"];
  onReactionClick?: () => void;
  vBillEnabledCompanies: { name: string; id: string }[];
}

export const BatchVBill = ({
  index,
  vBill,
  checkboxSelected,
  onCheckboxChange,
  selectedVBill,
  isDragDisabled,
  isBatchClosed,
  selectedVBillsReactionsStats,
  additionalMappingsSettings,
  onReactionClick,
  vBillEnabledCompanies,
}: IBatchVBillProps) => {
  const [openDetailsDrawer, setOpenDetailsDrawer] = useState(false);
  const [openChangeAmountDialog, setOpenChangeAmountDialog] = useState(false);
  const [openChangeMemoDialog, setOpenChangeMemoDialog] = useState(false);
  const { batchId } = useParams<{ batchId: string }>();
  const vBillUrl = useVBillUrl(vBill);
  const vendorMapping = useMemo(
    () =>
      (vBill.additionalMappings ?? []).find(
        ({ key }) =>
          additionalMappingsSettings?.invoice
            ?.filter(({ isContact }) => isContact)
            .map(({ key }) => key)
            .includes(key),
      ),
    [additionalMappingsSettings?.invoice, vBill.additionalMappings],
  );
  const restOfAdditionalMappings = useMemo(
    () =>
      (vBill.additionalMappings ?? []).filter(
        (additionalMapping) =>
          !!additionalMappingsSettings?.invoice?.find(
            ({ isContact, key }) => !isContact && key === additionalMapping?.key,
          ),
      ),

    [additionalMappingsSettings?.invoice, vBill.additionalMappings],
  );

  const vBillMemoInBatch = useMemo(
    () => vBill.amounts?.amountInBatchesBreakdown.find((amount) => amount?.batchId === Number(batchId))?.memo,
    [batchId, vBill.amounts?.amountInBatchesBreakdown],
  );
  const companyName = useMemo(
    () => vBillEnabledCompanies.find(({ id }) => id === vBill.companyId)?.name,
    [vBill.companyId, vBillEnabledCompanies],
  );
  const reactionsStats = useMemo(
    () =>
      selectedVBillsReactionsStats?.find(
        (group) => group.parentKey === `${vBill.id}` && group.parentType === IVBillReactionParentType.Vbill,
      )?.stats,
    [selectedVBillsReactionsStats, vBill.id],
  );
  const amountInThisBatch = useMemo(
    () => vBill.amounts?.amountInBatchesBreakdown?.find((am) => am.batchId === Number(batchId))?.batchAmountForInvoice,
    [batchId, vBill.amounts?.amountInBatchesBreakdown],
  );
  const selectedAmountToChange = useMemo(
    () =>
      Number(
        (vBill.amounts?.amountInBatchesBreakdown ?? []).find(
          (batchBreakdown) => `${batchBreakdown.batchId}` === batchId && batchBreakdown.invoiceId === vBill.id,
        )?.batchAmountForInvoice ?? 0,
      ),
    [batchId, vBill.amounts?.amountInBatchesBreakdown, vBill.id],
  );
  const availableLeftAmountToAdd = useMemo(
    () => Number(vBill.amounts?.amountFreeToPay || 0),
    [vBill.amounts?.amountFreeToPay],
  );
  const overDueDistance = useMemo(
    () =>
      formatDistanceStrict(
        getSODAsUTCDate(new Date(vBill?.invoiceDateDue ?? new Date())),
        getSODAsUTCDate(new Date()),
        {
          unit: "day",
          roundingMethod: "floor",
        },
      ),
    [vBill?.invoiceDateDue],
  );
  const dueInDistance = useMemo(
    () =>
      formatDistanceStrict(
        getSODAsUTCDate(new Date(vBill?.invoiceDateDue ?? new Date())),
        getSODAsUTCDate(new Date()),
        {
          unit: "day",
          roundingMethod: "ceil",
        },
      ),
    [vBill?.invoiceDateDue],
  );
  const isDueToday = useMemo(
    () => getSODAsUTCIsoString(new Date(vBill?.invoiceDateDue ?? new Date())) === getSODAsUTCIsoString(new Date()),
    [vBill?.invoiceDateDue],
  );
  const isOverDue = useMemo(
    () => new Date(vBill?.invoiceDateDue ?? new Date()).getTime() < getSODAsUTCDate(new Date()).getTime(),
    [vBill?.invoiceDateDue],
  );
  const formattedDateDue = useMemo(() => getVBillFormattedDate(vBill?.invoiceDateDue), [vBill?.invoiceDateDue]);

  const formattedInvoiceDate = useMemo(() => getVBillFormattedDate(vBill.invoiceDate), [vBill.invoiceDate]);
  const formattedSelectedAmountToChange = useMemo(
    () => formatCurrency(selectedAmountToChange),
    [selectedAmountToChange],
  );
  const formattedAvailableLeftAmountToAdd = useMemo(
    () => formatCurrency(availableLeftAmountToAdd),
    [availableLeftAmountToAdd],
  );
  const formattedVBillAmount = useMemo(() => formatCurrency(Number(vBill.amount)), [vBill.amount]);

  return (
    <>
      <Draggable
        key={vBill.id}
        draggableId={`${vBill.id}`}
        index={index}
        isDragDisabled={isDragDisabled || isBatchClosed}
      >
        {(provided, snapshot) => (
          <TableRow
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            sx={{
              ...(snapshot.isDragging
                ? { backgroundColor: "#D1DAE7", border: `2px solid ${COLORS.logoBlue}`, borderRadius: "6px" }
                : {}),
            }}
          >
            {!isBatchClosed && (
              <TableCell sx={defaultTableCellSx}>
                <Checkbox checked={checkboxSelected} onChange={(e) => onCheckboxChange(e.target.checked)} />
              </TableCell>
            )}
            <TableCell sx={defaultTableCellSx}>
              <Box sx={{ color: COLORS.newSecondaryColor }}>
                {vBill.invoiceNumber && <div>#{vBill.invoiceNumber}</div>}
                <Typography sx={{ color: COLORS.textGray, fontSize: "10px" }}>Bill #{vBill.id}</Typography>
                <Typography sx={{ color: COLORS.textGray, fontSize: "10px" }}>{formattedInvoiceDate}</Typography>
              </Box>

              {selectedVBill && (
                <Tooltip arrow title={vBillMemoInBatch?.length ? `Memo: ${vBillMemoInBatch}` : "Set Memo"}>
                  <Button
                    variant="text"
                    onClick={() => setOpenChangeMemoDialog(true)}
                    startIcon={<EditIcon sx={{ fontSize: "12px !important" }} />}
                    sx={{
                      padding: 0,
                      justifyContent: "flex-start",
                      height: "auto",

                      "&:hover": { textDecoration: "underline" },
                    }}
                  >
                    <Typography
                      noWrap
                      maxWidth="80px"
                      sx={{ color: COLORS.logoBlue, fontSize: "12px", fontWeight: "500" }}
                    >
                      {vBillMemoInBatch?.length ? vBillMemoInBatch : "Set Memo"}
                    </Typography>
                  </Button>
                </Tooltip>
              )}
            </TableCell>

            <TableCell sx={defaultTableCellSx}>{companyName ? companyName : "-"}</TableCell>

            <TableCell sx={{ ...defaultTableCellSx, textAlign: "right" }}>
              <Box sx={{ display: "inline-block" }}>
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                  {selectedVBill ? (
                    <>
                      <Tooltip
                        arrow
                        title={
                          <>
                            <div>Amount in this batch: {formattedSelectedAmountToChange}</div>
                            <div>Amount to be paid: {formattedAvailableLeftAmountToAdd}</div>
                          </>
                        }
                      >
                        <Button
                          variant="text"
                          onClick={() => setOpenChangeAmountDialog(true)}
                          startIcon={<EditIcon sx={{ fontSize: "12px !important" }} />}
                          sx={{
                            padding: 0,
                            justifyContent: "flex-start",
                            height: "auto",
                            fontSize: "12px",
                            fontWeight: "500",
                            "&:hover": { textDecoration: "underline" },
                            color:
                              selectedAmountToChange < availableLeftAmountToAdd
                                ? COLORS.success
                                : selectedAmountToChange > availableLeftAmountToAdd
                                ? COLORS.orange
                                : COLORS.newSecondaryColor,
                          }}
                        >
                          {formattedSelectedAmountToChange}
                        </Button>
                      </Tooltip>
                    </>
                  ) : (
                    <>
                      <Tooltip arrow title={`Amount to be paid: ${formattedAvailableLeftAmountToAdd}`}>
                        <Box sx={{ color: COLORS.black }}>{formattedAvailableLeftAmountToAdd}</Box>
                      </Tooltip>
                    </>
                  )}

                  {(!selectedVBill && vBill.amount !== vBill.amounts?.amountFreeToPay) ||
                  (selectedVBill && amountInThisBatch !== vBill.amounts?.amountFreeToPay) ? (
                    <Tooltip arrow title={`Original bill amount: ${formattedVBillAmount}`}>
                      <Box sx={{ color: COLORS.textGray }}>{formattedVBillAmount}</Box>
                    </Tooltip>
                  ) : null}
                </Box>
              </Box>

              {selectedVBill && (
                <Reactions
                  reactionsStats={reactionsStats}
                  onReactionClick={onReactionClick ? onReactionClick : () => undefined}
                  parentKey={`${vBill.id}`}
                  parentType={IVBillReactionParentType.Vbill}
                />
              )}
            </TableCell>

            <TableCell sx={defaultTableCellSx}>
              {vBill.invoiceDateDue ? (
                <>
                  <Box>{formattedDateDue}</Box>
                  <Box sx={{ fontSize: "10px", fontWeight: "400" }}>
                    {isDueToday ? (
                      <Box sx={{ color: COLORS.orange }}>Due today</Box>
                    ) : isOverDue ? (
                      <Box sx={{ color: COLORS.orange }}>Overdue: {overDueDistance}</Box>
                    ) : (
                      <Box sx={{ color: COLORS.newSecondaryColor }}>Due in: {dueInDistance}</Box>
                    )}
                  </Box>
                </>
              ) : (
                "-"
              )}
            </TableCell>

            <TableCell sx={{ ...defaultTableCellSx, maxWidth: "200px", wordBreak: "break-word" }}>
              {vendorMapping
                ? `${vendorMapping.label ?? ""} ${vendorMapping.label2 ? `- ${vendorMapping.label2}` : ""}`
                : "-"}
            </TableCell>

            <TableCell
              sx={{
                defaultTableCellSx,
                maxWidth: "200px",
                wordBreak: "break-word",
                fontSize: "10px",
                color: COLORS.textGray,
              }}
            >
              {restOfAdditionalMappings.length
                ? restOfAdditionalMappings.map(({ key, label, label2, type }, index) => (
                    <Box key={key}>
                      {
                        additionalMappingsSettings?.invoice?.find(
                          (addMapping) => addMapping.storeDataType === (type as unknown as IVBillIIntegrationDataType),
                        )?.label
                      }
                      :{" "}
                      <Box component="span" sx={{ color: COLORS.colorNewPrimary }}>
                        {label ?? ""} {label2 ? `- ${label2}` : ""}
                      </Box>
                    </Box>
                  ))
                : "-"}
            </TableCell>
            <TableCell sx={{ ...defaultTableCellSx, whiteSpace: "nowrap" }}>
              <Tooltip arrow title="Preview">
                <IconButton onClick={() => setOpenDetailsDrawer(true)}>
                  <PreviewIcon />
                </IconButton>
              </Tooltip>

              <Tooltip arrow title="Details">
                <a href={vBillUrl} target="_blank" rel="noreferrer">
                  <IconButton>
                    <LinkIcon />
                  </IconButton>
                </a>
              </Tooltip>
            </TableCell>
          </TableRow>
        )}
      </Draggable>

      {openDetailsDrawer && <VBillDetailsDrawer vBillId={vBill.id} onCloseDrawer={() => setOpenDetailsDrawer(false)} />}

      {openChangeAmountDialog && (
        <VBillChangeAmountDialog vBill={vBill} onCloseDialog={() => setOpenChangeAmountDialog(false)} />
      )}

      {openChangeMemoDialog && (
        <VBillChangeMemoDialog vBill={vBill} onCloseDialog={() => setOpenChangeMemoDialog(false)} />
      )}
    </>
  );
};

export const getSODAsUTCDate = (date: Date) => {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
  );
};
