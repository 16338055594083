import { isEqual } from "lodash";
import { action, computed, makeObservable, observable, runInAction, toJS } from "mobx";
import { createContext, useContext, useState } from "react";
import { IStoreContainer } from "storesMobx";

interface CachedLoadOpts {
  clearData?: boolean;
  catchError?: boolean;
  forceUpdate?: boolean;
  markAsLoading?: boolean;
}

export class DataContainer<T> {
  // Initial load
  @observable isLoading: boolean = false;
  // Any loading process
  @observable isFetching: boolean = false;
  // After data is loaded
  @observable isLoaded: boolean = false;
  @observable loadCount: number = 0;
  @observable error?: any;
  _data: T | undefined;
  reload?: (opts?: Omit<CachedLoadOpts, "forceUpdate">) => Promise<DataContainer<T> | undefined>;

  constructor(initialData: T | undefined) {
    // FIXME: avoid flickering for flags
    makeObservable(this);
    if (initialData) this.load(Promise.resolve(initialData));
  }

  promise?: Promise<T | undefined>;
  private _currentDeps: any[] | null = null;

  load(
    dataLoader: Promise<T | undefined>,
    clearData: boolean = false,
    catchError: boolean = true,
  ): Promise<T | undefined> {
    // we don't want to mix loadSafe with load, but if it happens, reset currentDeps to null
    this._currentDeps = null;

    this.promise = new Promise((res, rej) => {
      runInAction(() => {
        if (clearData) {
          this.setData(undefined);
          this.isLoaded = false;
        }
        this.isFetching = true;
        this.isLoading = true;
        this.error = undefined;
      });
      this.reload = undefined;
      dataLoader
        .then((data) => {
          runInAction(() => {
            this.loadCount++;
            this.isFetching = false;
            this.isLoading = false;
            this.isLoaded = true;
            this.setData(data);
            console.info("DC DONE", data);
            res(data);
          });
        })
        .catch((err) => {
          console.error("Catch error", catchError);
          console.error("DC ERROR", err);

          window.reportError(err);

          runInAction(() => {
            this.error = err;
            this.isFetching = false;
            if (catchError) res(undefined);
            else rej(err);
          });
        });
    });

    return this.promise;
  }

  async cachedLoad(dataLoader: () => Promise<T | undefined>, newDeps: any[] | null = [], opts?: CachedLoadOpts) {
    const defaults = { clearData: false, catchError: true, forceUpdate: false, markAsLoading: true };
    const { clearData, catchError, forceUpdate, markAsLoading } = { ...defaults, ...opts };

    if (this._depsAreEqual(newDeps) && !forceUpdate) {
      return this;
    }

    this._currentDeps = newDeps;

    runInAction(() => {
      if (clearData) {
        this.setData(undefined);
        this.isLoaded = false;
      }

      if (markAsLoading) {
        this.isFetching = true;
        this.isLoading = true;
      }

      this.error = undefined;
    });

    this.reload = (opts) => this.cachedLoad(dataLoader, newDeps, { ...opts, forceUpdate: true });
    this.promise = dataLoader();

    try {
      const data = await this.promise;

      if (!this._depsAreEqual(newDeps)) {
        // deps have changed while this promise was settling, this is a stale response
        return this;
      }

      runInAction(() => {
        this.loadCount++;
        this.isFetching = false;
        this.isLoading = false;
        this.isLoaded = true;
        this.setData(data);
      });
    } catch (err: any) {
      window.reportError(err);

      if (!this._depsAreEqual(newDeps)) {
        // deps have changed while this promise was settling, this is a stale response
        return this;
      }

      if (err.response?.errors?.message !== "Phone Verification has not been initialized for this Account") {
        console.error("Catch error", catchError);
        console.error("DC ERROR", err);
      }

      runInAction(() => {
        this.error = err;
        this.isFetching = false;

        // might break existing code
        this.isLoading = false;
        this.isLoaded = true;

        if (!catchError) {
          throw err;
        }
      });
    }

    return this;
  }

  @action
  flush() {
    this.isLoaded = false;
    this.isFetching = false;
    this.error = undefined;
    this.isLoading = false;
    this._currentDeps = null;
    this.setData(undefined);
  }

  protected setData(value: T | undefined) {
    this._data = value;
  }

  get data() {
    return this._data;
  }

  json() {
    return this._data;
  }

  log(name?: string) {
    console.log("DC", name, this._data);
  }

  private _depsAreEqual(newDeps: any[] | null) {
    if (!this._currentDeps || !newDeps) {
      return false;
    }

    if (this._currentDeps.length !== newDeps.length) {
      return false;
    }

    for (let i in this._currentDeps) {
      // Ideally this would be Object.is (React's default), but we need to make sure we use immutable objects
      if (!isEqual(this._currentDeps[i], newDeps[i])) {
        return false;
      }
    }

    return true;
  }
}

export class DataContainerObsevable<T> extends DataContainer<T> {
  @observable _dataObsevable: T | undefined;

  constructor(initialData: T | undefined) {
    super(initialData);
    makeObservable(this);
  }

  protected setData(value: T | undefined) {
    this._dataObsevable = value;
  }

  get data() {
    return this._dataObsevable;
  }

  log(name?: string) {
    console.log("DC", name, toJS(this._dataObsevable));
  }

  json() {
    return toJS(this._dataObsevable);
  }
}

export function createContainerAndLoad<T>(loader: () => Promise<T | undefined>) {
  const container = createContainer<T>();
  container.load(loader());
  return container;
}

export function createObservableContainerAndLoad<T>(loader: () => Promise<T | undefined>) {
  const container = createObservableContainer<T>();
  container.load(loader());
  return container;
}

export function createContainer<T>(initialData?: T) {
  return new DataContainer<T>(initialData);
}

export function createObservableContainer<T>(initialData?: T) {
  return new DataContainerObsevable<T>(initialData);
}

export type StoreNames = keyof IStoreContainer;

export const StoreContainerContext = createContext<IStoreContainer>({} as IStoreContainer);

export function useStore<T extends StoreNames>(storeName: T): IStoreContainer[T] {
  return useContext(StoreContainerContext)[storeName];
}

interface Data1 {
  a: number;
  b: boolean;
  c: Date;
}

export class TestStore {
  data = createContainer<Data1>();
  data2 = createObservableContainer<Data1>();

  async fetch1(): Promise<Data1> {
    return { a: 1, b: false, c: new Date() };
  }
  async fetch2(n: number): Promise<Data1> {
    return { a: n, b: false, c: new Date() };
  }

  async load() {
    await this.data.load(this.fetch1()).catch(() => {});
    const n = this.data.data?.a;
    if (n) await this.data2.load(this.fetch2(n));

    this.data.log("data1");
    this.data.log("data2");
  }
}

export function useStoreMutation<T, P extends any[]>(mutation: (...args: P) => DataContainer<T>) {
  const [dataContainer, setDc] = useState<ReturnType<typeof mutation>>();

  return {
    dataContainer,
    execute: async (...args: P) => {
      const dc = mutation(...args);
      setDc(dc);
      await dc.promise;
      return dc;
    },
  };
}

export interface IDataLoaderOpts {
  clearData?: boolean;
  catchError?: boolean;
  forceUpdate?: boolean;
}

export interface IDataLoader<T> {
  container: DataContainer<T>;
  dataLoader: () => Promise<T | undefined>;
  newDeps: any[] | null;
  opts: IDataLoaderOpts;
}

export function useDataLoaders(loaders: IDataLoader<any>[]) {
  const containers = loaders.map((loader) => {
    loader.container.cachedLoad(loader.dataLoader, loader.newDeps, loader.opts);
    return loader.container;
  });

  return computed(() => ({
    isLoaded: containers.every((container) => container.isLoaded),
    isLoading: containers.some((container) => container.isLoading),
    isFetching: containers.some((container) => container.isFetching),
    hasErrors: containers.some((container) => Boolean(container.error)),
  })).get();
}
