import DeleteIcon from "@mui/icons-material/Delete";
import { Alert, AlertColor, Box, Button, IconButton, Paper, Snackbar, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { QuickBooksBtn } from "components/common/QuickBooksBtn/QuickBooksBtn";
import { Spinner } from "components/common/Spinner/Spinner";
import { CompanyMenu } from "components/layout/CompanyMenu";
import { CompanyIntegration, IntegrationWebhook } from "generated/sdk";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useStore } from "storeContainer";
import styles from "./Integration.module.scss";
import { IntegrationBankAccountMapping } from "./IntegrationBankAccountMapping";
import IntegrationHeader from "./IntegrationHeader";
import { IntegrationUserMapping } from "./IntegrationUserMapping";

const Integration = () => {
  // Hooks
  const history = useHistory();

  const { integrationId, organizationId, companyId } = useParams<{
    integrationId: string;
    organizationId: string;
    companyId: string;
  }>();

  const integrationsStore = useStore("IntegrationsStore");
  const sessionStore = useStore("SessionStore");

  const [openRemoveDialog, setOpenRemoveDialog] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarText, setSnackbarText] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor | undefined>("success");
  const [companyIntegration, setCompanyIntegration] = useState<CompanyIntegration | undefined>({});
  const [dataLoading, setDataLoading] = useState(false);
  const [integrationTabs, setIntegrationTabs] = useState<IntegrationWebhook[]>([]);
  const [selectedTab, setSelectedTab] = useState<number>(0);

  // Constants
  const {
    canRemoveIntegration,
    canListIntegrationMappedBankAccounts,
    canListIntegrationMappedUsers,
    canMapIntegrationBankAccounts,
    canMapIntegrationUsers,
    canManageIntegration,
    aclDone,
  } = sessionStore.acl;

  const showSpinner =
    (!integrationsStore.companyIntegrationsList.isLoaded && integrationsStore.companyIntegrationsList.isLoading) ||
    (!integrationsStore.removeIntegrationData.isLoaded && integrationsStore.removeIntegrationData.isLoading) ||
    dataLoading;

  const title = companyIntegration?.integration?.display_name || companyIntegration?.integration?.name;

  // Handles
  const handleRemoveDialogOpen = () => {
    setOpenRemoveDialog(true);
  };

  const handleRemoveDialogClose = () => {
    setOpenRemoveDialog(false);
  };

  const handleDeleteIntegration = async () => {
    if (companyId) {
      setOpenRemoveDialog(false);
      await integrationsStore.removeIntegration(companyId, integrationId);

      const integration = integrationsStore.getCompanyIntegration(integrationId)?.integration;
      if (!integration) return;
      const intWebhookDisconnRedirectUrl = integration.webhooks?.find(({ scope }) => scope === "disconnect")?.url;
      if (intWebhookDisconnRedirectUrl) {
        window.location.href = intWebhookDisconnRedirectUrl;
      }
    }
  };

  useEffect(() => {
    if (companyId) {
      setDataLoading(true);
      if (canManageIntegration) {
        integrationsStore.load(companyId).then(() => {
          setDataLoading(false);

          if (sessionStore.acl.canMapIntegrationUsers) {
            integrationsStore.loadAllKnownExtSignerNames(true);
          }
        });
      }
    }
  }, [companyId, canManageIntegration, integrationsStore, sessionStore.acl.canMapIntegrationUsers]);

  useEffect(() => {
    if (!integrationsStore.companyIntegrationsList.data) {
      return;
    }

    const integration = integrationsStore.getCompanyIntegration(integrationId);
    const tabs = integration?.integration?.webhooks?.filter((webhook: IntegrationWebhook) =>
      ["status", "settings"].includes(webhook?.scope ?? ""),
    )!;

    setCompanyIntegration(integration);
    setIntegrationTabs(tabs?.length > 0 ? [{ scope: title ?? "", url: "" }, ...tabs] : tabs);
  }, [integrationsStore.companyIntegrationsList.data, integrationsStore, integrationId, title]);

  useEffect(() => {
    if (integrationsStore.removeIntegrationData.error) {
      setSnackbarText(integrationsStore.removeIntegrationData.error.response.errors?.message);
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
    }
  }, [integrationsStore.removeIntegrationData.error]);

  useEffect(() => {
    if (integrationsStore.removeIntegrationData.data === true) {
      history.push(`/org/${organizationId}/company/${companyId}`);
    }
  }, [companyId, history, integrationsStore.removeIntegrationData.data, organizationId]);

  useEffect(() => {
    if (aclDone) {
      if (canManageIntegration === false) {
        history.replace("/accessRestricted");
      }
    }
  }, [aclDone, canManageIntegration, history]);

  return (
    <Box display="flex" width="100%" justifyContent="space-between" gap="130px" maxWidth="1400px">
      <CompanyMenu />
      <Box width="100%">
        {showSpinner ? (
          <Box sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
            <Spinner />
          </Box>
        ) : (
          <Box width="100%">
            <Typography variant="h1" marginY="40px">
              Integration Mapping
            </Typography>
            <Paper className={styles.card}>
              {dataLoading ? (
                <Box sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
                  <Spinner />
                </Box>
              ) : (
                <>
                  <IntegrationHeader
                    settings={companyIntegration?.settings}
                    title={title}
                    tabs={integrationTabs}
                    setSelectedTab={setSelectedTab}
                    selectedTab={selectedTab}
                  />
                  <div className={styles.cardContent}>
                    <span>{companyIntegration?.integration?.name} info</span>
                    {canRemoveIntegration &&
                      (companyIntegration?.integration?.slug?.toLowerCase().includes("qbo") ? (
                        <QuickBooksBtn onClick={handleRemoveDialogOpen} mode="DISCONNECT" />
                      ) : (
                        <IconButton aria-label="delete" size="large" onClick={handleRemoveDialogOpen}>
                          <DeleteIcon color="error" />
                        </IconButton>
                      ))}
                  </div>
                </>
              )}
            </Paper>
            {integrationTabs && selectedTab > 0 && (
              <Paper sx={{ marginBottom: "40px" }}>
                <iframe
                  src={integrationTabs[selectedTab]?.url!}
                  height="600px"
                  width="100%"
                  title={integrationTabs[selectedTab]?.scope!}
                />
              </Paper>
            )}
            {(canListIntegrationMappedBankAccounts || canListIntegrationMappedUsers) && selectedTab === 0 ? (
              <Paper sx={{ padding: "18px 20px" }}>
                <Typography variant={"h6"}>Mapping</Typography>
                {canListIntegrationMappedBankAccounts ? (
                  <IntegrationBankAccountMapping defaultExpanded={false} readOnly={!canMapIntegrationBankAccounts} />
                ) : null}
                {canListIntegrationMappedUsers ? (
                  <IntegrationUserMapping companyId={companyId!} readOnly={!canMapIntegrationUsers} />
                ) : null}
              </Paper>
            ) : null}
          </Box>
        )}
      </Box>

      <Dialog
        open={openRemoveDialog}
        onClose={handleRemoveDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to remove integration{" "}
          {companyIntegration?.integration?.display_name || companyIntegration?.integration?.name} ?
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleRemoveDialogClose}>Cancel</Button>
          <Button onClick={handleDeleteIntegration} autoFocus>
            Remove
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert onClose={() => setOpenSnackbar(false)} severity={snackbarSeverity} sx={{ width: "100%" }}>
          {snackbarText}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default observer(Integration);
