import { graphqlVBillClient } from "common/graphqlClient";
import { IVBillSingleSelectAutocompleteSuggestion } from "components/pages/common/VBill/VBillSuggestionsAutocomplete/VBillSuggestionsSingleSelectAutocomplete/VBillSuggestionsSingleSelectAutocomplete";
import {
  TVBillSuggestionsSingleSelectAutocompleteConditionalComp,
  VBillSuggestionsSingleSelectAutocompleteComp,
} from "components/pages/common/VBill/VBillSuggestionsAutocomplete/VBillSuggestionsSingleSelectAutocomplete/VBillSuggestionsSingleSelectAutocompleteComp";
import {
  IVBillIntegrationSettingsVBillAdditionalMappingsEntryOutput,
  IVBillStoreIoDataType,
  IVBillStoreItemSchema,
  getSdk,
} from "generated/sdk.vbill";
import { debounce } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useUpdateEffect } from "react-use";

const { VBillStoreItemsForCompany } = getSdk(graphqlVBillClient);

// TODO: conditional type for VBillStoreItemsForCompany | VBillStoreItemsForOrganization
type IVBillMappingAutocompleteSelectorProps = TVBillSuggestionsSingleSelectAutocompleteConditionalComp & {
  additionalMappings: IVBillIntegrationSettingsVBillAdditionalMappingsEntryOutput;
  invoiceCoreOrgId: string;
  invoiceCompanyId: string;
  onAutocompleteSelect: (option: IVBillSingleSelectAutocompleteSuggestion) => void;
  onCloseAutocomplete: () => void;
  autocompleteSelectedSearchTerm: string;
};

export const VBillMappingAutocompleteSelector = ({
  additionalMappings: {
    label,
    storeDataType, // also as form field key name
    displayKeys,
    key,
  },
  invoiceCoreOrgId,
  invoiceCompanyId,
  onAutocompleteSelect,
  onCloseAutocomplete,
  autocompleteSelectedSearchTerm,
  ...props
}: IVBillMappingAutocompleteSelectorProps) => {
  const [autocompleteSearchTerm, setAutocompleteSearchTerm] = useState(autocompleteSelectedSearchTerm);
  const [storeItems, setStoreItems] = useState<IVBillStoreItemSchema[]>([]);
  const [isAutocompleteFetching, setIsAutocompleteFetching] = useState(false);
  const [autocompleteShowMoreBtnVisible, setAutocompleteShowMoreBtnVisible] = useState(false);
  const [storeItemsOffset, setStoreItemsOffset] = useState(0);
  const autocompleteSuggestions = useMemo(
    () =>
      storeItems.map((storeItem) => {
        const { key, label, label2, ...rest } = storeItem;

        return displayKeys?.length
          ? {
              id: storeItem.key,
              name: (storeItem[displayKeys[0] as keyof IVBillStoreItemSchema] ?? "") as string,
              label: (storeItem[displayKeys[1] as keyof IVBillStoreItemSchema] ?? "") as string,
              ...rest,
            }
          : {
              id: key,
              name: label ?? "",
              label: label2,
              ...rest,
            };
      }),
    [storeItems],
  );

  useEffect(() => {
    fetchStoreItems(autocompleteSelectedSearchTerm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autocompleteSelectedSearchTerm]);

  useUpdateEffect(() => {
    setIsAutocompleteFetching(true);
    setAutocompleteShowMoreBtnVisible(false);
    debouncedFetchStoreItems(autocompleteSearchTerm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autocompleteSearchTerm]);

  const fetchStoreItems = async (searchValue: string, offset: number = 0) => {
    setStoreItemsOffset(offset);

    const storeItemsResp = await VBillStoreItemsForCompany({
      type: storeDataType as unknown as IVBillStoreIoDataType, // IVBillIIntegrationDataType === IVBillStoreIoDataType
      settingsKey: key,
      organizationId: invoiceCoreOrgId, //FIXME: this should not be optional
      companyId: invoiceCompanyId,
      limit: 20,
      offset: offset,
      search: `${searchValue}%`,
    });

    if (storeItemsResp.storeItemsForCompany) {
      setStoreItems((prev) =>
        offset ? [...prev, ...storeItemsResp.storeItemsForCompany] : storeItemsResp.storeItemsForCompany,
      );
      setAutocompleteShowMoreBtnVisible(storeItemsResp.storeItemsForCompany.length === 20);
    }
    setIsAutocompleteFetching(false);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetchStoreItems = useCallback(debounce(fetchStoreItems, 500), []);

  const handleAutocompleteShowMoreBtnClick = () => {
    fetchStoreItems(autocompleteSearchTerm, storeItemsOffset + 20);
  };

  return (
    <VBillSuggestionsSingleSelectAutocompleteComp
      suggestions={autocompleteSuggestions}
      title={`Select ${label ?? storeDataType}`}
      onSuggestionClick={onAutocompleteSelect}
      onClose={onCloseAutocomplete}
      searchTerm={autocompleteSearchTerm}
      onSearchTermChange={setAutocompleteSearchTerm}
      searchPlaceholder={`Select ${label ?? storeDataType}`}
      showSearchLoader={isAutocompleteFetching}
      showMoreBtnVisible={autocompleteShowMoreBtnVisible}
      onShowMoreBtnClick={handleAutocompleteShowMoreBtnClick}
      {...props}
    />
  );
};
