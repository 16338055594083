import { Typography } from "@mui/material";
import { FunctionComponent } from "react";
import { COLORS } from "themes/default";

interface IToolbarStatusBadgeProps {
  color?: "blue" | "orange";
}

export const ToolbarStatusBadge: FunctionComponent<IToolbarStatusBadgeProps> = ({ color = "blue", children }) => (
  <Typography
    sx={{
      backgroundColor: color === "orange" ? "#FAEFDF" : "#EBF4FF",
      color: color === "orange" ? "#E09626" : COLORS.newSecondaryColor,
      fontSize: "12px",
      borderRadius: "10px",
      fontWeight: 500,
      lineHeight: "22px",
      padding: "0px 12px",
    }}
  >
    {children}
  </Typography>
);
