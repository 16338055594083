import { Box } from "@mui/material";
import Cookies from "js-cookie";
import React, { useEffect, useRef } from "react";
import { matchRoutes, renderRoutes, RouteConfig } from "react-router-config";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useEffectOnce } from "react-use";
import { CheckBatchDetailsPath, VBillBatchDetailsPath, VBillLeadingPath } from "routes/routes";
import { useStore } from "storeContainer";

const RoutesContent = React.memo(({ routes }: { routes: RouteConfig[] }) => {
  return renderRoutes(routes);
});

export const OrganizationLayout = (p: any) => {
  const history = useHistory();
  const session = useStore("SessionStore");
  const contextStore = useStore("ContextStore");
  const organizationStore = useStore("OrganizationStore");

  // the matched route is /org and subroutes will only be rendered later, so useParams() will not return organizationId
  const match = matchRoutes<{ organizationId?: string }>([{ path: "/org/:organizationId" }], window.location.pathname);
  const organizationId = Boolean(match.length) && match[0].match.params.organizationId;

  const matchDepositAccountsLedger = matchRoutes<{ organizationId?: string }>(
    [{ path: "/org/undefined/ledger" }],
    window.location.pathname,
  );

  const lastOrg = Cookies.get().lastOrg;
  const isInvalidOrgIdInUrl = organizationId && !session.isOrganizationIdInSession(organizationId);
  const isInvalidOrgIdInCookie = lastOrg && !session.isOrganizationIdInSession(lastOrg);
  const isFullWidthLayout = !!useRouteMatch([VBillLeadingPath, CheckBatchDetailsPath, VBillBatchDetailsPath]);

  useEffectOnce(() => {
    if (!organizationId) {
      Cookies.remove("lastOrg");
    }
    if (!matchDepositAccountsLedger && (isInvalidOrgIdInUrl || isInvalidOrgIdInCookie)) {
      Cookies.remove("lastOrg");
      history.push("/accessRestricted");
    }
  });

  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;

    if (organizationId && session.isLoggedIn) {
      if (organizationId && organizationId !== "undefined") {
        Cookies.set("lastOrg", organizationId);
        // Check if the component is still mounted
        if (isMounted.current) {
          contextStore.setSelectedOrganizationId(organizationId);
        }
      } else {
        Cookies.remove("lastOrg");
        // Check if the component is still mounted
        if (isMounted.current) {
          contextStore.setSelectedOrganizationId(undefined);
        }
      }
    }

    if (organizationId) {
      organizationStore.loadDefaultOrganizationDashboard(organizationId);
    }

    // Cleanup function
    return () => {
      isMounted.current = false;
    };
  }, [contextStore, organizationId, session.isLoggedIn]);

  return (
    <Box sx={{ ...(isFullWidthLayout ? { width: "100%" } : { maxWidth: "1400px", width: "100%" }) }}>
      <RoutesContent routes={p.route.routes as RouteConfig[]} />
    </Box>
  );
};
