import { TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";
import { vBillLedgerTHeadSx } from "components/pages/common/VBill/styles";
import { IVBillSortOrderType } from "generated/sdk.vbill";
import { useTableHeadCells } from "./useTableHeadCells";

export const BatchHeader = () => {
  const tableHeadCells = useTableHeadCells();

  return (
    <TableHead>
      <TableRow sx={{ cursor: "default" }}>
        {tableHeadCells.map((headerCell) =>
          headerCell.sortable ? (
            <TableCell
              key={headerCell.id}
              sx={{ ...vBillLedgerTHeadSx, ...(headerCell.id === "budget_sort" ? { textAlign: "right" } : {}) }}
              sortDirection={headerCell.value === IVBillSortOrderType.Asc ? "asc" : "desc"}
            >
              <TableSortLabel
                active={headerCell.value === IVBillSortOrderType.Asc}
                direction={headerCell.value === IVBillSortOrderType.Asc ? "asc" : "desc"}
                sx={{ ...(headerCell.id === "budget_sort" ? { flexDirection: "row-reverse" } : {}) }}
                onClick={() =>
                  headerCell.onChange?.(
                    headerCell.value === IVBillSortOrderType.Asc ? IVBillSortOrderType.Desc : IVBillSortOrderType.Asc,
                  )
                }
              >
                {headerCell.label}
              </TableSortLabel>
            </TableCell>
          ) : (
            <TableCell
              sx={{ ...vBillLedgerTHeadSx, ...(headerCell.id === "bills" ? { textAlign: "right" } : {}) }}
              key={headerCell.id}
            >
              {headerCell.label}
            </TableCell>
          ),
        )}

        {/* empty col */}
        <TableCell sx={vBillLedgerTHeadSx} />
      </TableRow>
    </TableHead>
  );
};
