import { SvgIcon, SvgIconProps } from "@mui/material";

export const SortIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="17" height="15" viewBox="0 0 17 15" fill="none" {...props}>
      <path
        d="M4.5 2V10.5H1L5.375 14L9.75 10.5H6.25V2H4.5ZM8 1V2.75H16V1H8ZM8 4.5V6.25H11.125H16V4.5H8ZM8 8V9.25H16V8H8Z"
        fill="#2877EC"
        stroke="white"
        strokeWidth="0.25"
      />
    </SvgIcon>
  );
};
