import styled from "@emotion/styled";
import { Tab, TabProps } from "@mui/material";

export const SidebarTabBtn = styled(Tab)<TabProps>(() => ({
  textTransform: "initial",
  fontSize: "15px",
  padding: "16.5px",
  minHeight: "auto",
}));

export const SidebarTabBtnSmall = styled(Tab)<TabProps>(() => ({
  textTransform: "initial",
  fontSize: "12px",
  padding: "10px",
  minHeight: "auto",
}));

export const a11yProps = (index: number) => ({
  id: `batch-details-sidebar-tab-${index}`,
  "aria-controls": `batch-details-sidebar-tabpanel-${index}`,
});
