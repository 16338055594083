import React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export const AllIcon: React.FC<SvgIconProps> = ({ fill, stroke, ...props }) => {
  return (
    <SvgIcon width="44" height="44" viewBox="0 0 44 44" fill="none" {...props}>
      <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="44" height="44" rx="22" fill={fill} />
        <path
          d="M14.4027 16.5561C13.3181 16.8978 12.5529 17.6185 12.1443 18.6734L11.9511 19.1637L11.9288 23.3761L11.9065 27.5958H13.0581H14.217V25.9243V24.2527H15.3314H16.4458V25.9243V27.5958H17.5601H18.6745V23.5915C18.6745 21.1845 18.6448 19.4312 18.6002 19.1935C18.3699 17.9751 17.4636 16.9573 16.2897 16.5932C15.7697 16.4298 14.8559 16.4149 14.4027 16.5561ZM15.8514 18.8443C16.3789 19.2083 16.4086 19.2975 16.4309 20.7313L16.4606 22.024H15.3388H14.217V20.7387C14.217 19.5946 14.2319 19.4386 14.3656 19.2157C14.685 18.6957 15.376 18.5248 15.8514 18.8443Z"
          fill={stroke}
        />
        <path
          d="M20.9033 22.024V27.5958H23.6892H26.4751V26.4815V25.3671H24.8036H23.132V20.9096V16.4521H22.0176H20.9033V22.024Z"
          fill={stroke}
        />
        <path
          d="M28.7039 22.024V27.5958H31.527H34.3501V26.4815V25.3671H32.6413H30.9326V20.9096V16.4521H29.8183H28.7039V22.024Z"
          fill={stroke}
        />
        <circle cx="22" cy="22" r="19.25" stroke={stroke} strokeWidth="1.5" />
      </svg>
    </SvgIcon>
  );
};
