import { Box, TablePagination } from "@mui/material";
import { observer } from "mobx-react";
import { useStore } from "storeContainer";
import { COLORS } from "themes/default";
import { usePaymentsLedgerFilters } from "../hooks";

export const PaymentsFooter = observer(function PaymentsFooter() {
  const [paymenstLedgerFilters, setPayLedgerFilters] = usePaymentsLedgerFilters();
  const vBillPaymentsLedgerStore = useStore("VBillPaymentsLedgerStore");

  const { paymentsLedgerList } = vBillPaymentsLedgerStore;
  const paymentsLedgerListData = paymentsLedgerList.data;

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1202, // 1200 has Drawer
        borderTop: "1px solid #DFE7F2",
      }}
    >
      <TablePagination
        sx={{ maxWidth: "1400px", margin: "0 auto", backgroundColor: COLORS.white }}
        component="div"
        showFirstButton
        showLastButton
        page={(paymenstLedgerFilters.pagination.page ?? 1) - 1}
        rowsPerPage={paymenstLedgerFilters.pagination.per_page ?? 10}
        rowsPerPageOptions={[10, 25, 50, 100]}
        count={paymentsLedgerListData?.total ?? 10}
        onRowsPerPageChange={(event) =>
          setPayLedgerFilters({ pagination: { per_page: Number(event.target.value), page: 1 } })
        }
        onPageChange={(_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) =>
          setPayLedgerFilters((prev) => ({ pagination: { ...prev.pagination, page: newPage + 1 } }))
        }
      />
    </Box>
  );
});
