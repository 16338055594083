import { graphqlClient, graphqlVBillClient } from "common/graphqlClient";
import {
  CompanyWithBankAccountsAndAggregatedData,
  OrganizationDashboardFilterInput,
  OrganizationDashboardQuery,
  Pagination,
  PendingActionsAndChecksCountQuery,
  getSdk,
} from "generated/sdk";
import {
  IVBillIntegrationSettingsCapabilities,
  IVBillVBillCompaniesQuery,
  IVBillVBillCompaniesQueryVariables,
  IVBillVBillCompaniesSettingsEnabledCapabilitiesQuery,
  IVBillVBillCompaniesSettingsEnabledCapabilitiesQueryVariables,
  getSdk as getVBillSdk,
} from "generated/sdk.vbill";
import { definitely, definitelyFilter } from "generated/utils";
import { computed } from "mobx";
import { createObservableContainer } from "storeContainer";
import { StoreBase } from "./StoreBase";

const { VBillCompaniesSettingsEnabledCapabilities, VBillCompanies } = getVBillSdk(graphqlVBillClient);
const {
  OrganizationDashboard,
  OrganizationStructure,
  UpdateUnconfirmedBankAccount,
  PendingActionsAndChecksCount,
  OrganizationCompaniesWithBankaccounts,
  UpdateOrganization,
} = getSdk(graphqlClient);

const fetchBasicOrganizationCompaniesWithBankaccounts = async (organization_id: string) => {
  return definitelyFilter(
    (await OrganizationCompaniesWithBankaccounts({ organization_id })).OrganizationDashboard
      .companies as CompanyWithBankAccountsAndAggregatedData[],
  );
};

const fetchOrganizationCompaniesWithBankaccounts = async (
  organization_id?: string | undefined,
  filters?: OrganizationDashboardFilterInput,
) => {
  return definitelyFilter(
    (await OrganizationDashboard({ organization_id, filters })).OrganizationDashboard
      .companies as CompanyWithBankAccountsAndAggregatedData[],
  );
};

const fetchOrganizationCompaniesWithBankaccountsWithPagination = async (
  organization_id: string | undefined,
  pagination: Pagination,
  filters: OrganizationDashboardFilterInput,
  get_unvalidated_dashboard?: boolean,
) => {
  return definitely(
    (
      await OrganizationDashboard({
        organization_id,
        pagination,
        filters,
        get_unvalidated_dashboard: get_unvalidated_dashboard || false,
      })
    ).OrganizationDashboard as OrganizationDashboardQuery["OrganizationDashboard"],
  );
};

const fetchPendingActionsAndChecksCount = async (
  organization_id: string | undefined,
  pagination?: Pagination,
  filters?: OrganizationDashboardFilterInput,
) => {
  return definitely(
    await PendingActionsAndChecksCount({
      organization_id,
      pagination,
      filters,
    }),
  )?.OrganizationDashboard;
};

const fetchOrganizationDefaultDashboard = async (organization_id?: string, get_unvalidated_dashboard?: boolean) => {
  return definitely(
    (await OrganizationDashboard({ organization_id, get_unvalidated_dashboard: get_unvalidated_dashboard || false }))
      .OrganizationDashboard,
  );
};

export class OrganizationStore extends StoreBase {
  companiesWithBankAccountsAndAggregatedData = createObservableContainer<CompanyWithBankAccountsAndAggregatedData[]>();
  organizationCompanies = createObservableContainer<CompanyWithBankAccountsAndAggregatedData[]>();
  organizationCompaniesWithBankaccounts = createObservableContainer<CompanyWithBankAccountsAndAggregatedData[]>();
  organizationCompaniesWithPagination =
    createObservableContainer<OrganizationDashboardQuery["OrganizationDashboard"]>();
  pendingActionsAndChecksCount =
    createObservableContainer<PendingActionsAndChecksCountQuery["OrganizationDashboard"]>();
  organizationDefaultDashboard = createObservableContainer<OrganizationDashboardQuery["OrganizationDashboard"]>();

  // vBill organization info
  organizationVBillEnabledCapabilities =
    createObservableContainer<
      IVBillVBillCompaniesSettingsEnabledCapabilitiesQuery["companiesSettings"]["capabilities"]["enabled"]
    >();
  organizationVBillEnabledCompanies = createObservableContainer<IVBillVBillCompaniesQuery["companies"]>();

  async loadOrganizationDashboard(orgId?: string, force?: true, filters?: OrganizationDashboardFilterInput) {
    this.flush();

    const orgIdToRequest = orgId; //TODO: looked into the previous logic here

    if (!this.storeContainer?.SessionStore.isOrganizationIdInSession(orgIdToRequest) && orgIdToRequest !== undefined)
      return;
    await this.companiesWithBankAccountsAndAggregatedData.cachedLoad(
      () => fetchOrganizationCompaniesWithBankaccounts(orgIdToRequest, filters),
      [orgIdToRequest],
      {
        forceUpdate: force,
      },
    );
  }

  loadOrganizationStructure(orgId?: string) {
    if (!this.storeContainer?.SessionStore.isOrganizationIdInSession(orgId)) return;
    this.organizationCompanies.cachedLoad(() => this.fetchOrganizationStructure(orgId), [orgId]);
  }

  async loadCompanies(
    pagination: Pagination,
    forceUpdate: boolean,
    filters: OrganizationDashboardFilterInput,
    orgId?: string,
    get_unvalidated_dashboard?: boolean,
  ) {
    if (get_unvalidated_dashboard || this.storeContainer?.UserSettingsStore.frontendSettingsObservable.isLoaded) {
      return await this.organizationCompaniesWithPagination.cachedLoad(
        () =>
          fetchOrganizationCompaniesWithBankaccountsWithPagination(
            orgId,
            pagination,
            filters,
            get_unvalidated_dashboard,
          ),
        [pagination, filters, orgId],
        { forceUpdate },
      );
    }
  }

  private async fetchOrganizationStructure(
    organization_id: string | undefined,
  ): Promise<CompanyWithBankAccountsAndAggregatedData[]> {
    return definitelyFilter((await OrganizationStructure({ organization_id })).OrganizationDashboard.companies);
  }

  async updateUnconfirmedBankAccount(bank_account_id: string, action: string) {
    await UpdateUnconfirmedBankAccount({ bank_account_id, action });
    this.storeContainer?.SessionStore.loadSession();
  }

  async loadPendingActions(
    pagination: Pagination = { per_page: 10, page: 1 },
    forceUpdate?: boolean,
    filters?: OrganizationDashboardFilterInput,
  ) {
    const orgIdToRequest = this.storeContainer?.ContextStore?.selectedOrganizationId;
    if (!orgIdToRequest) {
      return;
    }

    await this.pendingActionsAndChecksCount.cachedLoad(
      () => fetchPendingActionsAndChecksCount(orgIdToRequest, pagination, filters),
      [orgIdToRequest, pagination, filters],
      { forceUpdate },
    );
  }

  async loadOrganizationCompaniesWithBankaccounts(forceUpdate: boolean = false, orgId?: string) {
    const orgIdToRequest = orgId;
    if (!orgIdToRequest) {
      return;
    }

    await this.organizationCompaniesWithBankaccounts.cachedLoad(
      () => fetchBasicOrganizationCompaniesWithBankaccounts(orgIdToRequest!),
      [orgId],
      { forceUpdate },
    );
  }

  async updateOrganizationName(orgId: string, name: string) {
    const res = await UpdateOrganization({ organizationId: orgId, name });
    return res;
  }

  loadDefaultOrganizationDashboard(orgId: string, get_unvalidated_dashboard?: boolean) {
    this.organizationDefaultDashboard.cachedLoad(
      () => fetchOrganizationDefaultDashboard(orgId, get_unvalidated_dashboard),
      [orgId],
    );
  }

  loadOrganizationVBillEnabledCapabilities(data: IVBillVBillCompaniesSettingsEnabledCapabilitiesQueryVariables) {
    this.organizationVBillEnabledCapabilities.cachedLoad(
      async () => (await VBillCompaniesSettingsEnabledCapabilities(data)).companiesSettings.capabilities.enabled,
      [data],
    );
  }

  loadOrganizationVBillEnabledCompanies(data: IVBillVBillCompaniesQueryVariables) {
    this.organizationVBillEnabledCompanies.cachedLoad(async () => (await VBillCompanies(data)).companies, [data]);
  }

  @computed
  get organizationVBillBatchLedgerEnabled() {
    return this.organizationVBillEnabledCapabilities.data?.includes(IVBillIntegrationSettingsCapabilities.VBillRead);
  }

  @computed
  get organizationVBillPayLedgerEnabled() {
    return this.organizationVBillEnabledCapabilities.data?.includes(IVBillIntegrationSettingsCapabilities.VBillRead);
  }

  // TODO:
  @computed
  get organizationVBillPaymentsLedgerEnabled() {
    return this.organizationVBillEnabledCapabilities.data?.includes(IVBillIntegrationSettingsCapabilities.VBillRead);
  }

  @computed
  get organizationVBillCodingLedgerEnabled() {
    return (
      this.organizationVBillEnabledCapabilities.data?.includes(
        IVBillIntegrationSettingsCapabilities.VBillWriteCreate,
      ) ||
      this.organizationVBillEnabledCapabilities.data?.includes(IVBillIntegrationSettingsCapabilities.VBillWriteUpdate)
    );
  }

  @computed
  get organizationVBillPayBtnEnabled() {
    return this.organizationVBillEnabledCapabilities.data?.includes(
      IVBillIntegrationSettingsCapabilities.VBillPaymentsWrite,
    );
  }

  private async flush() {
    this.companiesWithBankAccountsAndAggregatedData.flush();
  }
}
