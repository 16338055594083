import { graphqlVBillClient } from "common/graphqlClient";
import { IVBillVBillCompaniesSettingsQuery, IVBillVBillCompaniesSettingsQueryVariables } from "generated/graphql.vbill";
import {
  getSdk,
  IVBillVBillPaymentsQuery,
  IVBillVBillPaymentsQueryVariables,
  IVBillVBillPaymentsStatsQuery,
  IVBillVBillPaymentsStatsQueryVariables,
} from "generated/sdk.vbill";
import { createObservableContainer } from "storeContainer";
import { StoreBase } from "./StoreBase";

const { VBillCompaniesSettings, VBillPayments, VBillPaymentsStats } = getSdk(graphqlVBillClient);

export class VBillPaymentsLedgerStore extends StoreBase {
  paymentsLedgerList = createObservableContainer<IVBillVBillPaymentsQuery["payments"]>();
  companiesSettings =
    createObservableContainer<IVBillVBillCompaniesSettingsQuery["companiesSettings"]["vBill"]["additionalMappings"]>();
  paymentsStats = createObservableContainer<IVBillVBillPaymentsStatsQuery["payments"]>();

  loadPaymentsLedgerList = (data: IVBillVBillPaymentsQueryVariables, forceUpdate?: boolean) => {
    this.paymentsLedgerList.cachedLoad(async () => await (await VBillPayments(data)).payments, [data], { forceUpdate });
  };

  loadCompaniesSettings = (data: IVBillVBillCompaniesSettingsQueryVariables, forceUpdate?: boolean) => {
    this.companiesSettings.cachedLoad(
      async () => await (await VBillCompaniesSettings(data)).companiesSettings.vBill.additionalMappings,
      [data],
      {
        forceUpdate,
      },
    );
  };

  loadPaymentsStats = (data: IVBillVBillPaymentsStatsQueryVariables, forceUpdate?: boolean) => {
    this.paymentsStats.cachedLoad(async () => await (await VBillPaymentsStats(data)).payments, [data], { forceUpdate });
  };
}
