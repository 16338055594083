import { styled, tooltipClasses, TooltipProps } from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Switch from "@mui/material/Switch";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { AclEntityTypeEnum, PermissionGroupInput } from "generated/sdk";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useStore } from "storeContainer";
import { extractLimits, PermissionGroupTypes, PermissionTypesLimits } from "storesMobx/AclStore2";
import { COLORS } from "../../../../../../themes/default";
import { AclPermLimitCreateCheckRulesView } from "../Permissions/AclPermLimitCreateCheckRules";
import { AclPermLimitSimpleMaxValueInput } from "../Permissions/AclPermLimitSimpleMaxValueInput";
import { usePermissionGroupsByCategory } from "./usePermissionGroupsByCategory";

export const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#FFFFFF",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 500,
    border: "1px solid #dadde9",
  },
}));

export const Permissions2Limit: React.FunctionComponent<{
  pgName: PermissionGroupTypes;
  limits?: PermissionTypesLimits;
  outlined?: true;
  user_id?: string;
  orgDirectPermissions?: any;
  onChange?: (pgName: PermissionGroupTypes, enabled: boolean, limits?: PermissionTypesLimits) => void;
}> = observer(function Permissions2Limit({ pgName, limits, onChange, outlined, user_id, orgDirectPermissions }) {
  return (
    <>
      {pgName === "BANK_ACCOUNT_LEDGER_ACCESS" ? (
        <AclPermLimitSimpleMaxValueInput
          user_id={user_id}
          value={limits?.limit_ledger_access_max_amount || 0}
          outlined={outlined}
          orgDirectPermissions={orgDirectPermissions}
          onChange={onChange ? (value) => onChange(pgName, true, { limit_ledger_access_max_amount: value }) : undefined}
        />
      ) : pgName === "BANK_ACCOUNT_CHECK_SIGNER" ? (
        <AclPermLimitSimpleMaxValueInput
          user_id={user_id}
          value={limits?.limit_approve_vchecks_max_amount || 0}
          outlined={outlined}
          orgDirectPermissions={orgDirectPermissions}
          onChange={
            onChange ? (value) => onChange(pgName, true, { limit_approve_vchecks_max_amount: value }) : undefined
          }
        />
      ) : pgName === "BANK_ACCOUNT_CHECK_MANAGE" ? (
        <AclPermLimitSimpleMaxValueInput
          user_id={user_id}
          value={limits?.limit_manage_vchecks_max_amount || 0}
          outlined={outlined}
          orgDirectPermissions={orgDirectPermissions}
          onChange={
            onChange ? (value) => onChange(pgName, true, { limit_manage_vchecks_max_amount: value }) : undefined
          }
        />
      ) : pgName === "BANK_ACCOUNT_CHECK_CREATE" ? (
        <AclPermLimitCreateCheckRulesView
          user_id={user_id}
          outlined={outlined}
          value={limits?.limit_create_vchecks_rules || []}
          orgDirectPermissions={orgDirectPermissions}
          onChange={onChange ? (value) => onChange(pgName, true, { limit_create_vchecks_rules: value }) : undefined}
        />
      ) : null}
      {/* <pre>limit={JSON.stringify({ pgName, limits }, null, 4)}</pre> */}
    </>
  );
});

export const Permissions2EditManage: React.FunctionComponent<{
  isUser: boolean;
  user_id: string;
  entity_type: AclEntityTypeEnum;
  entity_id: string;
}> = observer(function Permissions2EditManage({ isUser, user_id, entity_type, entity_id }) {
  const { organizationId } = useParams<{ organizationId?: string }>();
  const userGroupStore = useStore("UserGroupsStore");
  const acl2 = useStore("AclStore2");
  const sessionStore = useStore("SessionStore");
  const organizationUsersStore = useStore("OrganizationUsersStore");
  const organizationStore = useStore("OrganizationStore");

  const [directPermissions, setDirectPermission] = useState<ReturnType<typeof acl2.getDirectPermissionsOnEntity>>({});
  const [orgDirectPermissions, setOrgDirectPermissions] = useState<
    ReturnType<typeof acl2.getDirectPermissionsOnEntity>
  >({});

  const pgs = acl2.PermissionGroups;

  const entity_types_by_entity_type = {
    [AclEntityTypeEnum.Organization]: [
      AclEntityTypeEnum.Organization,
      AclEntityTypeEnum.Company,
      AclEntityTypeEnum.BankAccount,
    ],
    [AclEntityTypeEnum.Company]: [AclEntityTypeEnum.Company, AclEntityTypeEnum.BankAccount],
    [AclEntityTypeEnum.BankAccount]: [AclEntityTypeEnum.BankAccount],
  };
  const entity_types = entity_types_by_entity_type[entity_type] || [];

  useEffect(() => {
    organizationId && acl2.reload();
  }, [acl2, organizationId]);

  const loadDirect = () =>
    setDirectPermission(acl2.getDirectPermissionsOnEntity(isUser, user_id, entity_type, entity_id));

  useEffect(loadDirect, [acl2, isUser, user_id, entity_type, entity_id, acl2.directPermissionsList]);

  useEffect(() => {
    if (!acl2.directAppliedPermissions.isFetching && organizationId) {
      setOrgDirectPermissions(
        acl2.getDirectPermissionsOnEntity(isUser, user_id, AclEntityTypeEnum.Organization, organizationId),
      );
    }
  }, [acl2, isUser, user_id, organizationId, acl2.directAppliedPermissions.isFetching]);

  const onChange = (pgName: PermissionGroupTypes, enabled: boolean, limits?: PermissionTypesLimits) => {
    limits = limits || {};

    if (pgName === "BANK_ACCOUNT_LEDGER_ACCESS") {
      limits.limit_ledger_access_max_amount = limits.limit_ledger_access_max_amount || -1;
    }
    if (pgName === "BANK_ACCOUNT_CHECK_SIGNER") {
      limits.limit_approve_vchecks_max_amount = limits.limit_approve_vchecks_max_amount || -1;
    }
    if (pgName === "BANK_ACCOUNT_CHECK_MANAGE") {
      limits.limit_manage_vchecks_max_amount = limits.limit_manage_vchecks_max_amount || -1;
    }
    if (pgName === "BANK_ACCOUNT_CHECK_CREATE") {
      limits.limit_create_vchecks_rules = (limits.limit_create_vchecks_rules || []).length
        ? limits.limit_create_vchecks_rules
        : [{ count: 1, above: 0 }];
    }

    const aclApplyData: PermissionGroupInput = {
      [pgName]: [
        {
          organization_user_id: isUser ? user_id : undefined,
          user_group_id: isUser ? undefined : user_id,
          enabled,
          entity_type,
          entity_id,
          ...limits,
        },
      ],
    };

    acl2
      .apply(aclApplyData)
      .then(loadDirect)
      .then(() => acl2.getInheritedPermissions(isUser ? user_id : undefined, isUser ? undefined : user_id, true))
      .then(() => acl2.getDirectAppliedPermissions(isUser ? user_id : undefined, isUser ? undefined : user_id, true));
  };
  const findIfCurrentUserInGroup = () => {
    return !!userGroupStore.userGroupsList.data
      ?.find((group) => group.id === user_id)
      ?.organization_users?.find((user) => user.id == sessionStore.selectedOrganizationUserId!)?.id;
  };

  const hasVbill = organizationStore.organizationDefaultDashboard.data?.vbill_enabled ?? false;

  const catOrder = ["MANAGEMENT", "VCHECKS", ...(hasVbill ? ["VBILL"] : [])];
  const pgsByCategory = usePermissionGroupsByCategory(pgs);

  return (
    <>
      {catOrder.map((cat) => (
        <Box sx={{ width: "100%" }} marginTop="40px" key={cat}>
          <h2>{cat}</h2>
          <TableContainer
            component={Paper}
            sx={{
              ...(acl2.isFetching
                ? {
                    cursor: "wait",
                  }
                : {}),
            }}
          >
            <Table
              sx={{
                minWidth: 650,
                border: "1px solid rgba(224, 224, 224, 1)",
                ...(acl2.isFetching
                  ? {
                      cursor: "wait",
                      filter: "blur(4px)",
                      pointerEvents: "none",
                    }
                  : {}),
              }}
              aria-label="simple table"
            >
              {entity_types
                .filter((entity_type_row) => pgsByCategory[cat].some((pg) => pg.appliesTo.includes(entity_type_row)))
                .map((entity_type_row) => (
                  <>
                    <TableHead sx={{ borderBottom: "1px solid rgba(224, 224, 224, 1)" }}>
                      <TableRow>
                        <TableCell sx={{ width: "300px", fontWeight: "bold", color: COLORS.darkText }}>
                          <span style={{ textTransform: "capitalize" }}>
                            {entity_type_row.toLowerCase().replace("_", " ")}
                          </span>{" "}
                          level access
                        </TableCell>
                        <TableCell
                          align={"center"}
                          sx={{
                            width: "120px",
                            borderLeft: "1px solid rgba(224, 224, 224, 1)",
                            borderRight: "1px solid rgba(224, 224, 224, 1)",
                            fontWeight: "bold",
                            color: COLORS.darkText,
                          }}
                        >
                          Allow
                        </TableCell>
                        <TableCell align="left" sx={{ fontWeight: "bold", color: COLORS.darkText }}>
                          {!!pgsByCategory[cat].filter(
                            (pg) => pg.appliesTo.indexOf(entity_type_row) !== -1 && pg.settings.hasLimits,
                          ).length ? (
                            <>Limits & Approvals</>
                          ) : null}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {pgsByCategory[cat]
                        .filter((pg) => pg.appliesTo.indexOf(entity_type_row) !== -1)
                        .sort((a, b) => a.settings.order - b.settings.order)
                        .map((permGroup) => (
                          <TableRow key={permGroup.id} sx={{ "&:last-child td, &:last-child th": { borderBottom: 0 } }}>
                            <TableCell component="th" scope="row">
                              <Box sx={{ mr: 1, lineHeight: "20px" }}>
                                <HtmlTooltip
                                  title={
                                    <React.Fragment>
                                      <Box sx={{ display: "flex", flexDirection: "column", gap: 1, p: 1 }}>
                                        {permGroup.permission_types?.map((pt) => (
                                          <Typography
                                            key={pt.id}
                                            variant="body2"
                                            sx={{ fontWeight: 500, color: "#707070" }}
                                          >
                                            {pt?.description}
                                          </Typography>
                                        ))}
                                      </Box>
                                    </React.Fragment>
                                  }
                                >
                                  <span style={{ cursor: "help", fontWeight: "bold", color: COLORS.darkText }}>
                                    {permGroup.group_name}
                                  </span>
                                  {/* <InfoIcon fontSize={"small"} sx={{ color: "#a0a0a0", cursor: "help" }} /> */}
                                </HtmlTooltip>
                              </Box>
                            </TableCell>
                            <TableCell
                              sx={{
                                width: "120px",
                                borderLeft: "1px solid rgba(224, 224, 224, 1)",
                                borderRight: "1px solid rgba(224, 224, 224, 1)",
                              }}
                              align={"center"}
                            >
                              <Switch
                                disabled={
                                  (sessionStore.selectedOrganizationUserId === user_id &&
                                    !!!orgDirectPermissions["ORGANIZATION_COMPANY_MANAGER"]) ||
                                  (sessionStore.selectedOrganizationUserId === user_id &&
                                    !!orgDirectPermissions["ORGANIZATION_COMPANY_MANAGER"] &&
                                    (permGroup.internal_alias === "ORGANIZATION_USER_MANAGER" ||
                                      permGroup.internal_alias === "ORGANIZATION_COMPANY_MANAGER")) ||
                                  (permGroup.internal_alias === "ORGANIZATION_USER_MANAGER" &&
                                    isUser &&
                                    toJS(sessionStore.session.data)?.account?.id ===
                                      toJS(organizationUsersStore.organizationUsersList.data)?.find(
                                        (user) => user.id === user_id,
                                      )?.account?.id) ||
                                  (findIfCurrentUserInGroup() &&
                                    !!!orgDirectPermissions["ORGANIZATION_COMPANY_MANAGER"]) ||
                                  (findIfCurrentUserInGroup() &&
                                    !!orgDirectPermissions["ORGANIZATION_COMPANY_MANAGER"] &&
                                    (permGroup.internal_alias === "ORGANIZATION_USER_MANAGER" ||
                                      permGroup.internal_alias === "ORGANIZATION_COMPANY_MANAGER"))
                                }
                                checked={!!directPermissions[permGroup.internal_alias]}
                                onChange={(event) => {
                                  onChange(permGroup.internal_alias, event.target.checked);
                                }}
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            </TableCell>
                            <TableCell align="left">
                              {!!directPermissions[permGroup.internal_alias] && permGroup.settings.hasLimits ? (
                                <Permissions2Limit
                                  pgName={permGroup.internal_alias}
                                  limits={extractLimits(directPermissions[permGroup.internal_alias])}
                                  onChange={onChange}
                                  outlined={true}
                                  user_id={user_id}
                                  orgDirectPermissions={orgDirectPermissions}
                                />
                              ) : null}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </>
                ))}
            </Table>
          </TableContainer>
        </Box>
      ))}
    </>
  );
});
