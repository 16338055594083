import { Button, TableCell, TableRow } from "@mui/material";
import { formatCurrency } from "common/helpers/utils";
import { IVBillVBillGetBillsQuery, IVBillVBillPaymentQuery } from "generated/sdk.vbill";
import { useMemo } from "react";
import { COLORS } from "themes/default";
import { useVBillFormattedDate, useVBillUrl } from "../../../hooks";

const defaultTableCellSx = {
  padding: "5px 16px",
  fontWeight: "500",
};

interface IBillLineItemProps {
  bill: IVBillVBillGetBillsQuery["getBills"]["items"][number];
  payableInvoices?: IVBillVBillPaymentQuery["payment"]["payableInvoices"];
}

export const BillLineItem = ({ bill, payableInvoices }: IBillLineItemProps) => {
  const { id, invoiceNumber, invoiceDateDue } = bill;

  const formattedDate = useVBillFormattedDate(invoiceDateDue);
  const memo = useMemo(
    () => payableInvoices?.find((invoice) => invoice?.invoiceId === id)?.memo ?? "-",
    [id, payableInvoices],
  );
  const amount = useMemo(
    () => formatCurrency(Number(payableInvoices?.find((invoice) => invoice?.invoiceId === id)?.paymentAmount)),
    [id, payableInvoices],
  );
  const url = useVBillUrl(bill);

  return (
    <TableRow key={id} sx={{ cursor: "default" }}>
      <TableCell sx={{ ...defaultTableCellSx, color: COLORS.newSecondaryColor }}>#{id}</TableCell>
      <TableCell sx={defaultTableCellSx}>{invoiceNumber}</TableCell>
      <TableCell sx={{ ...defaultTableCellSx, fontSize: "12px" }}>{memo}</TableCell>
      <TableCell sx={defaultTableCellSx}>{formattedDate}</TableCell>

      <TableCell sx={{ ...defaultTableCellSx, textAlign: "right" }}>{amount}</TableCell>
      <TableCell sx={{ ...defaultTableCellSx, textAlign: "right" }}>
        <a href={url} target="_new">
          <Button
            sx={{
              padding: 0,
              fontSize: "12px",
              fontWeight: "500",
              height: "24px",
              color: COLORS.newSecondaryColor,
              "&:hover": { textDecoration: "underline" },
            }}
          >
            Details
          </Button>
        </a>
      </TableCell>
    </TableRow>
  );
};
