import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import TablePagination from "@mui/material/TablePagination";
import Typography from "@mui/material/Typography";
import { formatCurrency } from "common/helpers/utils";
import { useCheckFilters } from "common/hooks/useCheckFilters";
import { CompanyBankAccountFiltersProvider } from "common/hooks/useSharedCompanyBankAccountsFilters";
import { PaymentsNavigation } from "components/common/PaymentsNavigation/PaymentsNavigation";
import { Spinner } from "components/common/Spinner/Spinner";
import { format } from "date-fns";
import { CompanyBasic } from "generated/graphql";
import {
  CheckFiltersType,
  CheckQuickFiltersEnumType,
  LedgerCheck,
  OrganizationBasic,
  VCheckChecksQueryResult,
} from "generated/sdk";
import { isEqual, uniq } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { useHistory } from "react-router";
import { useLocation, useParams, useRouteMatch } from "react-router-dom";
import { useDeepCompareEffect, usePrevious } from "react-use";
import { useStore } from "storeContainer";
import { DepositCheckModal } from "../UndepositedChecks/Popups/DepositCheckModal";
import BulkActions from "./BulkActions";
import { Filters } from "./Filters/Filters";
import QuickFilters from "./QuickFilters/QuickFilters";
import { UnvalidatedQuickFilters } from "./QuickFilters/UnvalidatedQuickFilters";
import ReviewAndConfirm from "./ReviewAndConfirm";
import { IVChecksProps, VCheckChecksAllQuickFiltersEnum } from "./types";
import { formatDateToDesign } from "./utils";
import VCheckModalWrapper from "./VCheckModalWrapper";
import styles from "./VChecks.module.scss";
import VChecksTable from "./VChecksTable";

interface ExtendedLedgerCheck extends Omit<LedgerCheck, "amount" | "status" | "required_actions"> {
  amount: string | number;
  senderName: string;
  senderAccountNumber: string;
  status: string;
  required_actions: string;
}

const VChecks = ({ unvalidatedLedger = false }: IVChecksProps) => {
  const { organizationId, selectedQuickFilter: selectedQuickFilterParam } = useParams<{
    organizationId?: string;
    selectedQuickFilter: VCheckChecksAllQuickFiltersEnum;
  }>();
  const { filters, setFilters, pagination, setPagination, checkTabId } = useCheckFilters<CheckFiltersType>();
  const previousFilters = usePrevious(filters);
  const vChecksStore = useStore("VChecksStore");
  const OrganizationStore = useStore("OrganizationStore");
  const userSettingsStore = useStore("UserSettingsStore");
  const AccountsStore = useStore("AccountsStore");
  const sessionStore = useStore("SessionStore");
  const { organizations } = sessionStore;
  const { checksList, checksStats } = vChecksStore;
  const { organizationCompaniesWithBankaccounts, organizationCompanies } = OrganizationStore;
  const [isReviewAndConfirmLoading, setIsReviewAndConfirmLoading] = useState(false);
  const [openVCheckModal, setOpenVCheckModal] = useState(false);
  const [check, setCheck] = useState<LedgerCheck>();
  const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);
  const [openReviewAndConfirm, setOpenReviewAndConfirm] = useState<boolean>(false);
  const [actionInfo, setActionInfo] = useState({ action: "", label: "" });
  const [isLoadingCsv, setIsLoadingCsv] = useState(false);
  const [csvData, setCsvData] = useState<ExtendedLedgerCheck[]>([]);
  const [actionSuccess, setActionSuccess] = useState(false);
  const [isSuccessWithErrors, setIsSuccessWithErrors] = useState<boolean>(false);
  const [openSelectBankAccountModal, setOpenSelectBankAccountModal] = useState<boolean>(false);
  const [availableBankAccounts, setAvailableBankAccounts] = useState<(OrganizationBasic & { aggKeys: string })[]>();
  const [selectedBankAccountId, setSelectedBankAccountId] = useState<string>();
  const csvInstance = useRef<any | null>(null);
  const history = useHistory();

  const selectedQuickFilter = selectedQuickFilterParam === "all" ? undefined : selectedQuickFilterParam;
  const matchPayments = useRouteMatch("/org/:organizationId/vChecks/payments/:selectedQuickFilter");
  const matchDeposits = useRouteMatch("/org/:organizationId/vChecks/deposits/:selectedQuickFilter");
  const matchOutsideOrg = useRouteMatch("/depositAccounts/ledger/:selectedQuickFilter");

  const csvType = useMemo(() => {
    if (matchPayments) {
      return "Payments";
    } else if (matchDeposits) {
      return "Deposits";
    } else if (matchOutsideOrg) {
      return "Deposit-only";
    } else {
      return undefined;
    }
  }, [matchPayments, matchDeposits, matchOutsideOrg]);

  const handleVCheck = (role: string, _roleId: string, vCheckId: string) => {
    const check = checksList.data?.checks?.find((check) => check.id === vCheckId && check.role === role);
    setCheck(check);
    setOpenVCheckModal(true);
  };
  const findStatByQuickFilter = (quickFilter: CheckQuickFiltersEnumType) => {
    const stat = checksStats.data?.quick_filters?.find((item) => {
      return item?.quick_filter === quickFilter;
    });
    return stat;
  };

  const everyStats: Record<string, any> = Object.fromEntries(
    Object.values(CheckQuickFiltersEnumType).map((item) => [item, findStatByQuickFilter(item)]),
  );

  const handleAction = (check: NonNullable<LedgerCheck>, action?: any, option = false) => {
    setCheck(check);
    if (!option) {
      setActionInfo({ action: check.action?.action!, label: check.action?.label! });
    } else {
      setActionInfo({ action: action.action, label: action.label });
    }
    if (check.action?.action === "deposit") {
      setSelectedRowIds([`${check.id}-${check.role}`]);
      setOpenSelectBankAccountModal(true);
    } else setOpenReviewAndConfirm(true);
  };
  const handleReload = useCallback(async () => {
    vChecksStore.loadChecksStats(organizationId, true);
    await vChecksStore.loadChecks(organizationId, { ...filters, quick_filter: selectedQuickFilter }, pagination, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, organizationId, pagination, vChecksStore]);

  const handleModalClose = () => {
    setOpenVCheckModal(false);
  };

  const handleCloseReviewAndConfirm = () => {
    if (isReviewAndConfirmLoading) return;
    setOpenReviewAndConfirm(false);
    if (actionSuccess) {
      setSelectedRowIds([]);
    }
    if (actionSuccess || isSuccessWithErrors) {
      vChecksStore.loadChecks(organizationId, { ...filters, quick_filter: selectedQuickFilter }, pagination, true);
    }
    setActionSuccess(false);
  };

  const scrollToTop = () => {
    document?.querySelector("#scrollToTop")?.scrollTo({ top: 10, left: 0 });
  };

  const handleChangePage = async (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    vChecksStore.loadChecks(organizationId, filters, { ...pagination, page: newPage + 1 });
    setPagination({ ...pagination, page: newPage + 1 });
    setSelectedRowIds([]);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    scrollToTop();
    setPagination({ ...pagination, per_page: parseInt(event.target.value, 10), page: 1 });
    setSelectedRowIds([]);

    vChecksStore.loadChecks(
      organizationId,
      { ...filters, quick_filter: selectedQuickFilter },
      { page: 1, per_page: parseInt(event.target.value, 10) },
      true,
    );
  };
  const handleCheckAction = (action: string, label: string) => {
    setActionInfo({ action: action, label: label });
    if (action === "deposit") {
      setOpenSelectBankAccountModal(true);
    } else {
      setOpenReviewAndConfirm(true);
    }
  };

  let checkIdx = -1;
  if (!!check) {
    checkIdx = checksList.data?.checks?.findIndex((c) => {
      return c.id === check!.id;
    })!;
  }

  const hasNext = () => {
    if (!check) {
      return false;
    }
    return (
      checkIdx + 1 < checksList.data?.checks?.length! ||
      pagination.page < checksList.data?.pagination?.total! / pagination.per_page
    );
  };

  const goNext = async () => {
    const newCheckIdx = checkIdx + 1;

    if (newCheckIdx >= checksList.data?.checks?.length!) {
      const newPagination = { ...pagination, page: pagination.page + 1 };
      setPagination(newPagination);
      const newChecksList = await (
        await vChecksStore.loadChecks(
          organizationId,
          { ...filters, quick_filter: selectedQuickFilter },
          newPagination,
          undefined,
        )
      ).promise;
      // this should now be cached and so the effect above shouldn't trigger a new api call
      setCheck(newChecksList!.checks![0]);
    } else {
      setCheck(checksList.data?.checks![newCheckIdx]);
    }
  };

  const hasPrev = () => {
    if (!check) {
      return false;
    }

    return checkIdx - 1 >= 0 || pagination.page > 1;
  };

  const goPrev = async () => {
    const newCheckIdx = checkIdx - 1;
    if (newCheckIdx < 0) {
      const newPagination = { ...pagination, page: pagination.page - 1 };
      setPagination(newPagination);
      const newChecksList = await (
        await vChecksStore.loadChecks(
          organizationId,
          { ...filters, quick_filter: selectedQuickFilter },
          newPagination,
          undefined,
        )
      ).promise;
      // this should now be cached and so the effect above shouldn't trigger a new api call
      setCheck(newChecksList!.checks![newChecksList!.checks!.length - 1]);
    } else {
      setCheck(checksList.data?.checks![newCheckIdx]);
    }
  };

  const isLoading = checksList?.isFetching || checksList?.isLoading;
  const isLoadingCompanies =
    organizationCompaniesWithBankaccounts.isFetching || organizationCompaniesWithBankaccounts.isLoading;

  const csvHeaders = [
    { label: "Type", key: "role" },
    { label: "Bank Account Name", key: "senderName" },
    { label: "Bank Account Number", key: "senderAccountNumber" },
    { label: "vCheck Number", key: "number" },
    { label: "PTTOO Name", key: "pttoo" },
    { label: "Recipient Email Address", key: "recipient_email" },
    { label: "Check Date", key: "date" },
    { label: "Sync Date", key: "sync_date" },
    { label: "Signed Date", key: "signed_date" },
    { label: "Deposited Date", key: "deposited_date" },
    { label: "Amount", key: "amount" },
    { label: "Memo", key: "memo" },
    { label: "Status", key: "status" },
    { label: "State", key: "state" },
    { label: "Required Actions", key: "required_actions" },
  ];

  const location = useLocation();

  const sanitizeCsvField = (str?: string): string => {
    //prepend strings that begin with special characters with apostrophe

    if (!str) {
      return "";
    }

    const firstChar = str.charAt(0);
    if (
      firstChar === "=" ||
      firstChar === "+" ||
      firstChar === "-" ||
      firstChar === "@" ||
      firstChar === "\t" ||
      firstChar === "\r"
    ) {
      str = `'${str}`;
    }
    // Escape double quotes with another double quote
    str = str.replace(/"/g, '""');

    return str;
  };
  const fetchBankAccounts = useCallback(async () => {
    await AccountsStore.getBankAccounts();
  }, [AccountsStore]);
  const handleCloseDepositAll = () => {
    setOpenSelectBankAccountModal(false);
    setSelectedRowIds([]);
    setSelectedBankAccountId("");
  };

  const transformStatus = (status?: string) => {
    if (!status) {
      return "";
    }
    const words = status.split("-");
    words[0] = words?.[0].charAt(0).toUpperCase() + words[0].slice(1);

    return words.join(" ");
  };

  const getCsvData = async () => {
    if (!isLoadingCsv) {
      setIsLoadingCsv(true);
      Promise.resolve(
        await (
          await vChecksStore.loadChecksForCSV(
            organizationId,
            { ...filters, quick_filter: selectedQuickFilter },
            {
              per_page: 50000,
              page: 1,
            },
            true,
          )
        ).promise
          ?.then(async function (result: VCheckChecksQueryResult | null | undefined) {
            if (result) {
              const newCsvData = result?.checks?.map((result: LedgerCheck) => ({
                ...result,
                role: result.role === "sender" ? "Debit" : "Credit",
                senderName: sanitizeCsvField(result?.sender?.name!),
                senderAccountNumber: sanitizeCsvField("**" + result?.sender?.account_number!),
                number: sanitizeCsvField(result?.number!),
                pttoo: sanitizeCsvField(result?.pttoo!),
                recipient_email: sanitizeCsvField(result?.recipient_email!),
                date: sanitizeCsvField(formatDateToDesign(result?.date!)!),
                sync_date: sanitizeCsvField(formatDateToDesign(result?.created_at!)!),
                signed_date: sanitizeCsvField(formatDateToDesign(result?.signed_at!)!),
                deposited_date: sanitizeCsvField(formatDateToDesign(result?.deposited_at!)!),
                amount: sanitizeCsvField(formatCurrency(result?.amount!)),
                memo: sanitizeCsvField(result?.memo!),
                status: sanitizeCsvField(transformStatus(result?.status?.status!)),
                state: sanitizeCsvField(result?.status?.state!),
                required_actions: sanitizeCsvField(
                  result?.required_actions?.map((action) => action?.description).join("; "),
                ),
              }));

              setIsLoadingCsv(false);
              if (newCsvData) {
                setCsvData(newCsvData);
              }
              csvInstance.current.link.click();
            }
          })
          .catch(() => {
            setIsLoadingCsv(false);
          }),
      );
    }
  };

  const getButton = () => (
    <Button
      onClick={() => getCsvData()}
      startIcon={<FileDownloadIcon htmlColor="#697281" />}
      sx={{ height: "42px", backgroundColor: "#EBF4FF", color: "#697281" }}
      disabled={isLoadingCsv}
    >
      {!isLoadingCsv ? (
        <Typography textTransform="none" fontSize={"12px"}>
          CSV
        </Typography>
      ) : (
        <>
          <Spinner /> <Typography>Loading... Might take up to 2 minutes</Typography>
        </>
      )}
    </Button>
  );

  useEffect(() => {
    if (AccountsStore.depositAccounts && !(AccountsStore.depositAccounts instanceof Error)) {
      const formattedResult: (OrganizationBasic & { aggKeys: string })[] = [];
      AccountsStore.depositAccounts.DepositBankAccounts?.forEach((org) => {
        const arrayOfRelated: string[] = [org?.name!];
        org?.companies.forEach((comp, index) => {
          const arrayOrRelatedForCompany: string[] = [comp.name];
          comp.bank_accounts?.forEach((ba) => {
            arrayOrRelatedForCompany.push(ba?.name!);
            arrayOfRelated.push(ba?.name || "");
          });
          arrayOfRelated.push(comp.name);
          comp = { ...comp, aggKeysForCompany: arrayOrRelatedForCompany.join() } as CompanyBasic & {
            aggKeysForCompany: string;
          };
          org.companies[index] = comp;
        });
        formattedResult.push({ ...org!, aggKeys: arrayOfRelated.join() });
      });
      setAvailableBankAccounts(
        formattedResult.filter((item) => {
          return !(item.name === "Deposit Accounts" && item.companies[0]?.bank_accounts?.length === 0);
        }),
      );
    }
  }, [AccountsStore.depositAccounts, organizationCompanies?.data]);
  useEffect(() => {
    if (!availableBankAccounts) {
      fetchBankAccounts();
    }
    if (!organizations.find((item) => item.id === organizationId) && organizationId) {
      history.push("/accessRestricted");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableBankAccounts, fetchBankAccounts]);

  useDeepCompareEffect(() => {
    if (
      (!vChecksStore.checksList.isLoaded && !vChecksStore.checksList.isLoading) ||
      (vChecksStore.checksList.isLoaded && !vChecksStore.checksList.isLoading && !isEqual(filters, previousFilters))
    ) {
      vChecksStore.loadChecks(
        organizationId,
        { ...filters, quick_filter: selectedQuickFilter },
        { page: 1, per_page: pagination.per_page },
        true,
      );
      const expirationDate = new Date().getTime() + 10 * 60 * 1000;

      localStorage.setItem(
        `checkPagination_${checkTabId}`,
        JSON.stringify({ value: { page: 1, per_page: 50 }, expires: expirationDate }),
      );
    }
  }, [filters, organizationId, pagination.per_page, previousFilters, selectedQuickFilter, setPagination, vChecksStore]);

  useEffect(() => {
    if (selectedRowIds.length) {
      setSelectedRowIds([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vChecksStore.checksList.data]);

  useEffect(() => {
    OrganizationStore.loadOrganizationCompaniesWithBankaccounts(true, organizationId);
  }, [OrganizationStore, organizationId]);

  useEffect(() => {
    vChecksStore.loadChecksStats(organizationId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSettingsStore.companiesSelectedIds]);

  const showBulkActions = selectedRowIds && selectedRowIds?.length > 0;

  useDeepCompareEffect(() => {
    let companiesSelectedBankAccounts: string[] = [];

    if (!matchOutsideOrg) {
      companiesSelectedBankAccounts = userSettingsStore.companiesSelectedIds.reduce<string[]>(
        (result, companiesSelectedId) => {
          const companyWithBankAccount = organizationCompaniesWithBankaccounts.data?.find(
            ({ id }) => id === companiesSelectedId,
          );

          result = [...result, ...(companyWithBankAccount?.bank_accounts?.map(({ id }) => id) ?? [])];

          return result;
        },
        [],
      );
    }

    const currentBankAccounts = uniq([...companiesSelectedBankAccounts, ...(filters?.bank_accounts ?? [])]);

    if (
      currentBankAccounts.length &&
      selectedQuickFilterParam === "all" &&
      isEqual(filters?.bank_accounts, previousFilters?.bank_accounts)
    ) {
      setFilters((prevFilters) => ({ ...prevFilters, bank_accounts: currentBankAccounts }));
    }
  }, [userSettingsStore.companiesSelectedIds, organizationCompaniesWithBankaccounts.data, filters, location.pathname]);

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "1400px",
      }}
      className={styles.vcheckContainer}
    >
      {!unvalidatedLedger ? (
        <>
          <PaymentsNavigation />

          <QuickFilters
            pendingSignatureSum={everyStats[CheckQuickFiltersEnumType.PendingSignature]?.sum}
            pendingSignatureCount={everyStats[CheckQuickFiltersEnumType.PendingSignature]?.count}
            actionsRequiredCount={everyStats[CheckQuickFiltersEnumType.ActionRequired]?.count}
            actionsRequiredSum={everyStats[CheckQuickFiltersEnumType.ActionRequired]?.sum}
            unresolvedChatsCount={everyStats[CheckQuickFiltersEnumType.UnresolvedChats]?.count}
            unresolvedChatsSum={everyStats[CheckQuickFiltersEnumType.UnresolvedChats]?.sum}
            processingCount={everyStats[CheckQuickFiltersEnumType.Processing]?.count}
            processingSum={everyStats[CheckQuickFiltersEnumType.Processing]?.sum}
            readyToDepositCount={everyStats[CheckQuickFiltersEnumType.ReadyToDeposit]?.count}
            readyToDepositSum={everyStats[CheckQuickFiltersEnumType.ReadyToDeposit]?.sum}
            isRow
            isLoading={checksStats?.isLoading}
          />
        </>
      ) : (
        <UnvalidatedQuickFilters
          actionsRequiredCount={everyStats[CheckQuickFiltersEnumType.ActionRequired]?.count}
          actionsRequiredSum={everyStats[CheckQuickFiltersEnumType.ActionRequired]?.sum}
          processingCount={everyStats[CheckQuickFiltersEnumType.Processing]?.count}
          processingSum={everyStats[CheckQuickFiltersEnumType.Processing]?.sum}
          readyToDepositCount={everyStats[CheckQuickFiltersEnumType.ReadyToDeposit]?.count}
          readyToDepositSum={everyStats[CheckQuickFiltersEnumType.ReadyToDeposit]?.sum}
          isRow
          isLoading={checksStats?.isLoading}
        />
      )}

      <CompanyBankAccountFiltersProvider filters={filters}>
        <Filters
          unvalidated={false}
          exportCSV={getButton}
          isLoadingCompanies={isLoadingCompanies}
          unvalidatedLedger={unvalidatedLedger}
        />
      </CompanyBankAccountFiltersProvider>
      <CSVLink
        headers={csvHeaders}
        filename={`Cherry-${format(new Date(), "MM-dd-yyyy")}-${csvType}.csv`}
        data={csvData || []}
        ref={csvInstance}
      />
      {showBulkActions ? (
        <BulkActions
          checks={checksList.data?.checks!}
          selectedRowIds={selectedRowIds}
          setSelectedRowIds={setSelectedRowIds}
          selectedRowIdsShouldIncludeRole
          handleButtonAction={(action: string) => handleCheckAction(action, action)}
        />
      ) : null}

      {checksList?.data ? (
        <>
          <VChecksTable
            checks={checksList.data.checks!}
            handleAction={handleAction}
            handleVCheck={handleVCheck}
            selectedRowIds={selectedRowIds}
            setSelectedRowIds={setSelectedRowIds}
            selectedRowIdsShouldIncludeRole
            filters={filters}
            setFilters={setFilters}
            reload={handleReload}
            unvalidated={false}
            isLoading={isLoading}
            rowsPerPage={pagination?.per_page || 10}
            hasInProgressOnboarding={sessionStore.session.data?.has_in_progress_onboarding as boolean}
          ></VChecksTable>
        </>
      ) : checksList.isFetching ? (
        <Box sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
          <Spinner />
        </Box>
      ) : null}

      <VCheckModalWrapper
        check={check!}
        isModalOpened={openVCheckModal}
        modalClose={handleModalClose}
        hasNext={hasNext()}
        hasPrev={hasPrev()}
        goNext={goNext}
        goPrev={goPrev}
        handleStartCheckAction={handleAction}
        handleReloadChecks={handleReload}
        setSelectedRowIds={setSelectedRowIds}
      />
      <DepositCheckModal
        allChecks={checksList.data?.checks!?.filter((check) => selectedRowIds.includes(`${check.id}-${check.role}`))}
        availableBankAccounts={availableBankAccounts!}
        close={handleCloseDepositAll}
        openSelectBankAccountModal={openSelectBankAccountModal}
        selectedBankAccount={selectedBankAccountId}
        setSelectedBankAccountId={setSelectedBankAccountId}
        setOpenReviewAndConfirm={setOpenReviewAndConfirm}
        handleCloseDepositAll={handleCloseDepositAll}
      />
      <Modal
        style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
        open={openReviewAndConfirm}
        onClose={handleCloseReviewAndConfirm}
      >
        <Box>
          <ReviewAndConfirm
            setIsReviewAndConfirmLoading={setIsReviewAndConfirmLoading}
            checksIds={selectedRowIds}
            checks={checksList.data?.checks!?.filter((check) => selectedRowIds.includes(`${check.id}-${check.role}`))}
            selectedRowIds={selectedRowIds}
            actionInfo={actionInfo}
            close={handleCloseReviewAndConfirm}
            setSelectedRowIds={setSelectedRowIds}
            setActionSuccessVChecks={setActionSuccess}
            setIsSuccessWithErrors={setIsSuccessWithErrors}
            selectedBankAccountId={selectedBankAccountId}
            setOpenSelectBankAccountModal={setOpenSelectBankAccountModal}
          />
        </Box>
      </Modal>
      <TablePagination
        showFirstButton
        showLastButton
        count={checksList?.data ? checksList.data.pagination?.total! : 0}
        page={(pagination?.page || 1) - 1}
        onRowsPerPageChange={handleChangeRowsPerPage}
        onPageChange={handleChangePage}
        rowsPerPage={pagination?.per_page || 10}
        className={styles.paginationSticky}
        component={"form"}
      />
    </Box>
  );
};

export default observer(VChecks);
