import { Box } from "@mui/material";
import Tabs, { TabsActions } from "@mui/material/Tabs";
import { formatCurrency } from "common/helpers/utils";
import { ActivityLog } from "components/common/ActivityLog/ActivityLog";
import { format } from "date-fns";
import { IActionlogObjectType } from "generated/graphql.actionlog";
import { observer } from "mobx-react";
import {
  ChatDetailDashboard,
  IChatChannelParentType,
  IChatContentBlockMessageRelatedType,
  TBaseIoChannel,
} from "modules/Chat";
import { forwardRef, ReactNode, SyntheticEvent, useImperativeHandle, useMemo, useRef } from "react";
import { useParams } from "react-router";
import { useStore } from "storeContainer";
import { StringParam, useQueryParam, withDefault } from "use-query-params";
import { a11yProps, SidebarTabBtn } from "./SidebarTabBtn/SidebarTabBtn";
import { SidebarTabPanel } from "./SidebarTabPanel/SidebarTabPanel";

export const SidebarTabQSKey = "sidebar-tab-panel";

export enum SidebarTabPanels {
  "CHAT" = "chat",
  "ACTIVITY_LOG" = "activity-log",
}

const defaultSidebarTabPanelQSParam = withDefault<any, SidebarTabPanels>(StringParam, SidebarTabPanels.CHAT);

export interface ISidebarTabsRef {
  tabsUpdateIndicator: () => void;
}

export const SidebarTabs = observer(
  forwardRef<ISidebarTabsRef, { children?: ReactNode }>((_props, ref) => {
    const budgetManagementStore = useStore("BudgetManagementStore");
    const sessionStore = useStore("SessionStore");
    const { organizationId } = useParams<{ organizationId: string }>();
    const sessionAccountId = useMemo(
      () => sessionStore.session.data?.account?.id ?? "",
      [sessionStore.session.data?.account?.id],
    );

    const tabsDomAction = useRef<TabsActions>(null);
    const [sidebarTabPanel, setSidebarTabPanel] = useQueryParam<SidebarTabPanels>(
      SidebarTabQSKey,
      defaultSidebarTabPanelQSParam,
    );

    useImperativeHandle(ref, () => ({
      tabsUpdateIndicator() {
        tabsDomAction.current?.updateIndicator();
      },
    }));

    const chatDetailDashboardBaseIoChannel: TBaseIoChannel = useMemo(
      () => ({
        parentContext: "vCheckBatch_tab",
        parentKey: `${budgetManagementStore.checkBatchData.data?.GetCheckBatchQuery?.id ?? ""}`,
        parentType: IChatChannelParentType.VcheckBatch,
      }),
      [budgetManagementStore.checkBatchData.data?.GetCheckBatchQuery?.id],
    );
    const activityLogFilters = useMemo(
      () => ({
        type: IActionlogObjectType.Checkbatch,
        key: budgetManagementStore.checkBatchData.data?.GetCheckBatchQuery?.id ?? "",
        organizationId,
      }),
      [budgetManagementStore.checkBatchData.data?.GetCheckBatchQuery?.id, organizationId],
    );

    return (
      <Box>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={Object.values(SidebarTabPanels).indexOf(sidebarTabPanel)}
            onChange={(_event: SyntheticEvent<Element, Event>, value: number) =>
              setSidebarTabPanel(Object.values(SidebarTabPanels)[value])
            }
            aria-label="batch details sidebar tabs"
            centered
            action={tabsDomAction}
          >
            <SidebarTabBtn
              label="Chat"
              // icon={<ChatIcon />}
              {...a11yProps(0)}
            />
            <SidebarTabBtn
              label="Activity log"
              // icon={<HistoryIcon />}
              {...a11yProps(1)}
            />
          </Tabs>
        </Box>
        <SidebarTabPanel activePannel={sidebarTabPanel} panelName={SidebarTabPanels.CHAT}>
          <ChatDetailDashboard
            config={{
              baseIoChannel: chatDetailDashboardBaseIoChannel,
              organizationId: organizationId,
              sessionAccountId: sessionAccountId,
            }}
            relatedItems={(budgetManagementStore.checkBatchData.data?.GetCheckBatchQuery?.items?.rows ?? [])?.map(
              (row) => ({
                key: `${budgetManagementStore.checkBatchData.data?.GetCheckBatchQuery?.id ?? ""}`,
                related: {
                  key: `${row?.id}`,
                  label: `${row?.check?.number ?? ""} - ${format(
                    new Date(row?.check?.date ?? new Date()),
                    "MM/dd/yy",
                  )} - ${formatCurrency(row?.check?.amount ?? 0)}`,
                  type: IChatContentBlockMessageRelatedType.Bill,
                },
              }),
            )}
          />
        </SidebarTabPanel>
        <SidebarTabPanel activePannel={sidebarTabPanel} panelName={SidebarTabPanels.ACTIVITY_LOG}>
          <ActivityLog
            filters={activityLogFilters}
            reloadObserver={budgetManagementStore.checkBatchData.data?.GetCheckBatchQuery}
          />
        </SidebarTabPanel>
      </Box>
    );
  }),
);
