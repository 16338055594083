import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { useParams } from "react-router-dom";
import { useStore } from "storeContainer";
import { BudgetManagementDetailsAddedChecksTable } from "./BudgetManagementDetailsAddedChecksTable";
import { BudgetManagementDetailsAvailableChecksTable } from "./BudgetManagementDetailsAvailableChecksTable";
import { CheckBatchDetailsFilters } from "./CheckBatchDetailsFilters";
import { useBatchDetailsContext } from "./useBatchDetailsContext";

export const BudgetManagementDetailsChecksTab = observer(function BudgetManagementDetailsChecksTab() {
  const BudgetManagementStore = useStore("BudgetManagementStore");
  const { batchId } = useParams<{ batchId: string }>();
  const [isDragging, setIsDragging] = useState(false);

  const { fetchAvailableChecksForBatchQuery, fetchCheckBatch, createCheckBatchItems, deleteCheckBatchItems } =
    BudgetManagementStore;

  const {
    props: { isBatchClosed },
  } = useBatchDetailsContext();

  const onDragEnd = async (result: DropResult) => {
    const { source, destination, draggableId } = result;
    setIsDragging(false);

    if (!destination || isBatchClosed) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      return;
    } else if (source.droppableId === "droppableTable1" && destination.droppableId === "droppableTable2") {
      createCheckBatchItems(batchId, [draggableId], {}, true);
    } else if (source.droppableId === "droppableTable2" && destination.droppableId === "droppableTable1") {
      deleteCheckBatchItems(batchId, [draggableId], true);
    }

    await fetchAvailableChecksForBatchQuery(batchId, { per_page: 10, page: 1 }, {}, true);
    await fetchCheckBatch(batchId, {}, { page: 1, per_page: 10 }, true);
  };
  const onDragStart = () => {
    setIsDragging(true);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd} nonce={(window as any).nonce} onDragStart={onDragStart}>
      <Box marginTop="15px">
        <CheckBatchDetailsFilters />
      </Box>

      {!isBatchClosed && (
        <>
          <BudgetManagementDetailsAvailableChecksTable isDragging={isDragging} />
          <Box display="flex" justifyContent="center" width="100%" alignItems="center" marginTop="20px">
            <CompareArrowsIcon sx={{ fontSize: "24px", transform: "rotate(90deg);" }} />
          </Box>
        </>
      )}
      <BudgetManagementDetailsAddedChecksTable isDragging={isDragging} />
    </DragDropContext>
  );
});
