import Box from "@mui/material/Box";
import { BankAccountWithAggregatedData } from "generated/sdk";
import { BankAccountCard } from "./BankAccountCard";
import { useStore } from "../../../../../storeContainer";
import Divider from "@mui/material/Divider";

export const BankAccountsList: React.FunctionComponent<{
  companyId: string;
  depositAccounts: boolean;
  bankAccounts: BankAccountWithAggregatedData[];
  backgroundColor: string;
  handleChangeBankAccountName: (id: string, name: string) => void;
}> = ({ bankAccounts, backgroundColor, depositAccounts, companyId, handleChangeBankAccountName }) => {
  const SessionStore = useStore("SessionStore");
  const aclStore2 = useStore("AclStore2");

  const { organizationUsersList } = aclStore2;
  const userId = SessionStore?.selectedOrganizationUserId;
  const user = organizationUsersList?.find((user) => user.id === userId);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      {!bankAccounts?.length ? (
        <Box>No results</Box>
      ) : (
        bankAccounts.map((bankAccount, index) => (
          <Box key={bankAccount.id}>
            {index === 0 && <Divider />}
            <BankAccountCard
              handleChangeBankAccountName={(name) => {
                handleChangeBankAccountName(bankAccount.id, name);
              }}
              user={user}
              companyId={companyId}
              depositAccounts={depositAccounts}
              backgroundColor={backgroundColor}
              bankAccount={bankAccount}
            />
            {index !== bankAccounts?.length - 1 ? <Divider /> : null}
          </Box>
        ))
      )}
    </Box>
  );
};
