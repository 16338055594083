import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import InputBase from "@mui/material/InputBase";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { CompanyBasic, LedgerCheck, OrganizationBasic } from "generated/sdk";
import { useState } from "react";
import { COLORS } from "themes/default";
import styles from "../UndepositedChecks.module.scss";
import { CompaniesWithBankAccountsOfOrg } from "./CompaniesWithBankAccountsOfOrg";
import { TextField } from "@mui/material";

export const BrowseAccounts = ({
  allChecks,
  availableBankAccounts,
  selectedBankAccount,
  setSelectedBankAccountId,
  handleCloseDepositAll,
}: {
  allChecks: LedgerCheck[];
  availableBankAccounts: (OrganizationBasic & { aggKeys: string })[];
  selectedBankAccount?: string;
  setSelectedBankAccountId: Function;
  setOpenReviewAndConfirm?: Function;
  handleCloseDepositAll: () => void;
}) => {
  const filterForOrgWithBA = (orgs: (OrganizationBasic & { aggKeys: string })[]) => {
    return orgs?.filter((org) => {
      let baExist = false;
      org.companies.forEach((comp) => {
        if (comp.bank_accounts) {
          baExist = true;
        }
        return;
      });
      if (!baExist) return false;

      return true;
    });
  };
  const [search, setSearch] = useState<string>("");
  const [filteredAvailableBankAccounts, setFilteredAvailableBankAccounts] = useState<
    (OrganizationBasic & {
      aggKeys: string;
    })[]
  >(filterForOrgWithBA(availableBankAccounts));
  const [selectedOrgWithCompaniesAndBankAccounts, setSelectedOrgWithCompaniesAndBankAccounts] =
    useState<OrganizationBasic>();
  const [filteredSelectedOrgWithCompaniesAndBankAccounts, setFilteredSelectedOrgWithCompaniesAndBankAccounts] =
    useState<OrganizationBasic>();
  const handleSelectOrg = (org: OrganizationBasic) => {
    setSelectedOrgWithCompaniesAndBankAccounts(org);
    const filteredByCompanyCompanies = {
      ...org!,
      companies: org!.companies.filter((company) => {
        return (company as CompanyBasic & { aggKeysForCompany: string }).aggKeysForCompany
          .toLowerCase()
          .includes(search.toLowerCase());
      }),
    };
    const finalFiltered = {
      ...filteredByCompanyCompanies,
      companies: filteredByCompanyCompanies!.companies.map((company) => {
        if (
          (company as CompanyBasic & { aggKeysForCompany: string }).aggKeysForCompany
            .split(",")[0]
            .toLowerCase()
            .includes(search.toLowerCase())
        ) {
          return company;
        }
        return {
          ...company,
          bank_accounts: company.bank_accounts?.filter((ba) => ba?.name.includes(search.toLowerCase())),
        };
      }),
    };
    setFilteredSelectedOrgWithCompaniesAndBankAccounts(finalFiltered);
  };
  const handleSearchThroughBankAccounts = (e: any) => {
    setSearch(e.target.value);
    if (selectedOrgWithCompaniesAndBankAccounts) {
      const filteredByCompanyCompanies = {
        ...selectedOrgWithCompaniesAndBankAccounts!,
        companies: selectedOrgWithCompaniesAndBankAccounts!.companies.filter((company) =>
          (company as any).aggKeysForCompany.toLowerCase().includes(e.target.value.toLowerCase()),
        ),
      };
      const finalFiltered = {
        ...filteredByCompanyCompanies,
        companies: selectedOrgWithCompaniesAndBankAccounts.companies.map((company) => {
          return {
            ...company,
            bank_accounts: company.bank_accounts?.filter(
              (ba) =>
                ba?.name.toLowerCase().includes(e.target.value.toLowerCase()) ||
                company.name.toLowerCase().includes(e.target.value.toLowerCase()),
            ),
          };
        }),
      };
      setFilteredSelectedOrgWithCompaniesAndBankAccounts(finalFiltered);
    }
    const filteredAvailableBankAccountsVar = availableBankAccounts.filter((org) => {
      let baExist = false;
      org.companies.forEach((comp) => {
        if (comp.bank_accounts) {
          baExist = true;
        }
        return;
      });
      if (!baExist) {
        return false;
      }
      if (e.target.value) {
        return org.aggKeys.toLowerCase().includes(e.target.value.toLowerCase());
      }
      return true;
    });
    setFilteredAvailableBankAccounts(filteredAvailableBankAccountsVar);
  };
  return (
    <Box sx={{ padding: "0 20px 20px 20px" }}>
      <Box className={styles.scrollbarDiv} sx={{ justifyContent: "center" }}>
        <Box>
          <TextField
            onChange={handleSearchThroughBankAccounts}
            sx={{ ml: 1, flex: 1 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            placeholder="Search Organization / Company"
            id="search"
            fullWidth
            name="search"
          />
        </Box>
        <Box
          sx={{
            width: "100%",
            paddingRight: "10px",
            marginTop: "10px",
            overflowX: "auto",
            overflowY: "auto",
            scrollbarWidth: "10px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {!!selectedOrgWithCompaniesAndBankAccounts && (
            <Box width="100%" borderBottom="0.5px solid #DFE7F2 !important">
              <Button
                onClick={() => {
                  setSelectedOrgWithCompaniesAndBankAccounts(undefined);
                  setSelectedBankAccountId(undefined);
                }}
                startIcon={<ChevronLeft />}
                sx={{
                  textTransform: "capitalize",
                  maxWidth: "300px",
                  height: "42px",
                  border: "none !important",
                  whiteSpace: "nowrap",
                }}
              >
                {selectedOrgWithCompaniesAndBankAccounts?.name}
              </Button>
            </Box>
          )}
          {selectedOrgWithCompaniesAndBankAccounts ? (
            <CompaniesWithBankAccountsOfOrg
              allChecks={allChecks}
              availableBankAccounts={
                filteredSelectedOrgWithCompaniesAndBankAccounts || selectedOrgWithCompaniesAndBankAccounts
              }
              selectedBankAccount={selectedBankAccount}
              setSelectedBankAccountId={setSelectedBankAccountId}
            />
          ) : (
            <Box display="flex" flexDirection="column" gap="15px" width="100%" paddingLeft="20px">
              {filteredAvailableBankAccounts ? (
                filteredAvailableBankAccounts?.map((org) => (
                  <Button
                    key={org.id}
                    variant="outlined"
                    onClick={() => {
                      handleSelectOrg(org);
                    }}
                    fullWidth
                    endIcon={<ChevronRight />}
                    sx={{
                      textTransform: "capitalize",
                      width: "100%",
                      height: "42px",
                      border: "none !important",
                      borderBottom: "0.5px solid #DFE7F2 !important",
                    }}
                  >
                    <Typography
                      variant="h2"
                      whiteSpace="nowrap"
                      width="100%"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      title={org.name}
                    >
                      {org.name}
                    </Typography>
                  </Button>
                ))
              ) : (
                <Typography variant="body1">No results</Typography>
              )}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};
