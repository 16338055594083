import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

import { Permissions2Edit } from "components/pages/Settings/Users/User/Permissions2/Permissions2Edit";

export const AddPermissionsModal: React.FunctionComponent<{
  organizationUserId: string;
  entity_type: string;
  user_type: string;
}> = () => {
  return (
    <Grid container justifyContent={"center"} alignItems={"center"}>
      <Paper>
        <Permissions2Edit />
      </Paper>
    </Grid>
  );
};
