import { graphqlClient } from "common/graphqlClient";
import {
  getSdk,
  Pagination,
  PendingChecks,
  PendingChecksFilterInput,
  VCheckUndepositedChecksFilterInput,
  VCheckUndepositedChecksQueryResult,
} from "generated/sdk";
import { definitely } from "generated/utils";

import { createObservableContainer } from "storeContainer";
import { StoreBase } from "storesMobx/StoreBase";

const { VCheckUndepositedChecks, DepositChecksMutation, getPendingChecks } = getSdk(graphqlClient);

export class UndepositedChecksStore extends StoreBase {
  undepositedChecks = createObservableContainer<NonNullable<VCheckUndepositedChecksQueryResult>>();
  pendingChecks = createObservableContainer<NonNullable<PendingChecks>>();
  async fetchUndepositedChecks(
    filters: VCheckUndepositedChecksFilterInput | undefined,
    pagination: Pagination | undefined,
  ): Promise<VCheckUndepositedChecksQueryResult | undefined> {
    return definitely((await VCheckUndepositedChecks({ filters, pagination }))?.VCheckUndepositedChecks);
  }
  getUndepositedChecks(filters?: VCheckUndepositedChecksFilterInput, pagination?: Pagination, forceUpdate?: boolean) {
    return this.undepositedChecks.cachedLoad(
      () => this.fetchUndepositedChecks(filters, pagination),
      [filters, pagination],
      { forceUpdate },
    );
  }

  async depositChecks(bank_account_id: string, checks: string[]) {
    return DepositChecksMutation({ bank_account_id, checks });
  }
  async fetchPendingChecks(
    company_id: string,
    integration_id: string,
    pagination: Pagination,
    filters?: PendingChecksFilterInput,
  ) {
    return definitely(
      (await getPendingChecks({ companyId: company_id, integrationId: integration_id, filters, pagination }))
        .PendingChecks,
    );
  }
  checkFilters = (filters: Record<string, any> | undefined) => {
    for (let key in filters) {
      if (filters[key]) return true;
    }
    return false;
  };
  async getPendingChecksFunction(
    company_id: string,
    integration_id: string,
    pagination: Pagination,
    forceUpdate = false,
    filters?: PendingChecksFilterInput,
  ) {
    if (!this.checkFilters(filters)) filters = undefined;
    return this.pendingChecks.cachedLoad(
      () => this.fetchPendingChecks(company_id, integration_id, pagination, filters),
      [company_id, integration_id, pagination, filters],
      { forceUpdate },
    );
  }
}
