import { Box, Grid, IconButton, Skeleton, Typography } from "@mui/material";

import DownloadIcon from "@mui/icons-material/Download";
import classNames from "classnames";
import { observer } from "mobx-react";
import { ReactNode } from "react";
import { useStore } from "storeContainer";
import { formatDateToDateTime } from "../../utils";
import styles from "../VCheckCheck.module.scss";

const replaceURLsWithLinks = (text: string) => {
  const urlRegex = /https:\/\/[^\s]+/g;
  const parts = text.split(urlRegex);

  const urls = text.match(urlRegex);

  const elements: ReactNode[] = [];
  for (let i = 0; i < parts.length; i++) {
    elements.push(parts[i]);
    if (urls && urls[i]) {
      elements.push(
        <a
          style={{ textDecoration: "underline", color: "blue" }}
          href={urls[i]}
          target="_blank"
          rel="noreferrer"
          title={urls[i]}
        >
          Click&nbsp;here
        </a>,
      );
    }
  }

  return elements;
};

const previewableExtensions = ["jpeg", "jpg", "png", "webp", "gif"];

export const VCheckActivityLog = observer(function VCheckActivityLog() {
  const vChecksStore = useStore("VChecksStore");
  const checkDetails = vChecksStore.check.data;

  return (
    <ul className={styles.activityList}>
      {checkDetails?.activity?.map((activity, idx) => {
        return (
          <li key={`${activity.id}-${idx}`}>
            {vChecksStore.check.isFetching ? (
              <Skeleton variant="text" />
            ) : (
              <Box display="flex" gap="10px" alignItems="start" flexDirection="column">
                <Box display="flex" gap="5px" alignItems="center">
                  <Typography className={styles.activityDate}>{formatDateToDateTime(activity.created_at)}</Typography>

                  {activity.username && (
                    <Typography variant="h1" fontWeight={400} fontSize="11px" lineHeight="13px" color="#697281">
                      {` - @${activity.username}`}
                    </Typography>
                  )}
                </Box>
                {(activity?.title ||
                  activity?.note ||
                  (activity?.attachments?.length && activity.attachments.some((att) => att.show_download))) && (
                  <Grid container alignItems="center">
                    <Grid item>
                      {(activity?.title || activity?.note) && (
                        <Typography
                          className={classNames(styles.activityTitle, {
                            [styles.activityComment]: activity.type === 1,
                          })}
                        >
                          {activity?.title || replaceURLsWithLinks(activity?.note ?? "")}
                        </Typography>
                      )}
                    </Grid>

                    {activity?.attachments?.map((attachment, index) =>
                      attachment.show_download ? (
                        <Grid item key={index}>
                          <a href={`/notes/${activity?.id}/media/${attachment.media?.id}`}>
                            <IconButton>
                              <DownloadIcon color="secondary" />
                            </IconButton>
                          </a>
                        </Grid>
                      ) : null,
                    )}
                  </Grid>
                )}

                {activity?.attachments?.map((attachment, index) =>
                  attachment.media?.path &&
                  previewableExtensions.includes(attachment.media.type?.toLocaleLowerCase()!) ? (
                    <Box key={index}>
                      <img className={styles.imageWrapper} src={attachment.media.path} alt="activity attachment" />
                    </Box>
                  ) : attachment.media?.filename ? (
                    <Grid
                      key={index}
                      alignItems="center"
                      justifyContent="space-between"
                      container
                      sx={{ paddingX: "35px" }}
                    >
                      <Typography fontSize={16}>{attachment.media.filename}</Typography>
                      <a href={`/notes/${activity?.id}/media/${attachment.media.id}`}>
                        <IconButton>
                          <DownloadIcon color="secondary" />
                        </IconButton>
                      </a>
                    </Grid>
                  ) : null,
                )}
              </Box>
            )}
          </li>
        );
      })}
    </ul>
  );
});
