import { Box } from "@mui/material";
import { VBillAdditionalMappingsFilter } from "components/pages/common/VBill/FilterComponents/VBillAdditionalMappingsFilter/VBillAdditionalMappingsFilter";
import { VBillAmountFilter } from "components/pages/common/VBill/FilterComponents/VBillAmountFilter/VBillAmountFilter";
import { VBillDateFilter } from "components/pages/common/VBill/FilterComponents/VBillDateFilter/VBillDateFilter";
import { VBillRelativeDueDateFilter } from "components/pages/common/VBill/FilterComponents/VBillRelativeDueDateFilter/VBillRelativeDueDateFilter";
import { VBillSearchFilter } from "components/pages/common/VBill/FilterComponents/VBillSearchFilter/VBillSearchFilter";
import {
  IStatusOption,
  VBillStatusFilter,
} from "components/pages/common/VBill/FilterComponents/VBillStatusFilter/VBillStatusFilter";
import { friendlyIntegrationStatusNames } from "components/pages/common/VBill/utils";
import {
  IVBillBillsFiltersMappingInput,
  IVBillIIntegrationIntegrationSettingsVBillAdditionalMappingsEntryShowIn,
  IVBillInvoiceIntegrationStatus,
  IVBillStoreIoDataType,
} from "generated/graphql.vbill";
import { pick, sortBy } from "lodash";
import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { useStore } from "storeContainer";
import { usePayLedgerFilters, useVBillPayLedgerChildRoute } from "../hooks";

const statusFilterOptions: IStatusOption[] = Object.values(IVBillInvoiceIntegrationStatus).map((value) => ({
  label: friendlyIntegrationStatusNames[value],
  value,
}));

export const PayListFilters = observer(function PayListFilters() {
  const vBillPayLedgerStore = useStore("VBillPayLedgerStore");
  const [paymentLedgerFilters, setPayLedgerFilters] = usePayLedgerFilters();
  const { isAllChildRoute, isOverDueChildRoute } = useVBillPayLedgerChildRoute();

  const additionalMappingsSettingsData = vBillPayLedgerStore.companiesSettings.data?.invoice;
  const additionalMappingsVendorFirst = useMemo(() => {
    const withoutPayment = additionalMappingsSettingsData?.filter(
      (additionalMapping) =>
        additionalMapping.showIn !== IVBillIIntegrationIntegrationSettingsVBillAdditionalMappingsEntryShowIn.Payment &&
        additionalMapping.showIn !== IVBillIIntegrationIntegrationSettingsVBillAdditionalMappingsEntryShowIn.None,
    );

    return sortBy(withoutPayment ?? [], ["isContact"]);
  }, [additionalMappingsSettingsData]);

  const handleAdditionalMappingChange = async (value: IVBillBillsFiltersMappingInput) => {
    const currentMappings = paymentLedgerFilters.filters?.mappings ?? [];
    const newMappings = currentMappings.find(({ key }) => key === value.key)
      ? currentMappings.map((mapping) => (mapping.key === value.key ? value : mapping))
      : [...currentMappings, value];
    const newMappingsExcludeEmptyValuesIn = newMappings.filter(({ valuesIn }) => valuesIn.length > 0);

    setPayLedgerFilters((prev) => ({
      filters: { ...prev.filters, mappings: newMappingsExcludeEmptyValuesIn },
      pagination: { page: 1, per_page: prev.pagination.per_page },
    }));
  };

  return (
    <Box sx={{ display: "flex", flexWrap: "wrap", gap: "10px", alignItems: "center", padding: "10px 0" }}>
      {/* <VBillFullDatesPickerBtn /> */}

      <VBillSearchFilter
        term={paymentLedgerFilters.filters?.search}
        onTermChange={(term) => {
          setPayLedgerFilters((prev) => ({
            filters: { search: term?.length ? term : undefined },
            pagination: { page: 1, per_page: prev.pagination.per_page },
          }));
        }}
      />

      {isAllChildRoute && (
        <VBillStatusFilter
          status={paymentLedgerFilters.filters?.integrationStatus}
          onStatusChange={(value) =>
            setPayLedgerFilters((prev) => ({
              filters: {
                ...prev.filters,
                integrationStatus: value as IVBillInvoiceIntegrationStatus,
              },
              pagination: { page: 1, per_page: prev.pagination.per_page },
            }))
          }
          options={statusFilterOptions}
        />
      )}

      <VBillAmountFilter
        amount={{
          amount_from: paymentLedgerFilters.filters?.amount_from,
          amount_to: paymentLedgerFilters.filters?.amount_to,
        }}
        onAmountChange={({ amount_from, amount_to }) =>
          setPayLedgerFilters((prev) => ({
            filters: { ...prev.filters, amount_from, amount_to },
            pagination: { page: 1, per_page: prev.pagination.per_page },
          }))
        }
      />
      {/* <VBillDateFilter
          date={{
            date: paymentLedgerFilters.filters?.date,
            date_to: paymentLedgerFilters.filters?.date_to,
          }}
          onDateChange={({ date, date_to }) =>
            setPayLedgerFilters((prev) => ({
              filters: { ...prev.filters, date, date_to },
              pagination: { page: 1, per_page: prev.pagination.per_page },
            }))
          }
        /> */}

      <VBillDateFilter
        date={{
          date: paymentLedgerFilters.filters?.date,
          date_to: paymentLedgerFilters.filters?.date_to,
        }}
        onDateChange={({ date, date_to }) =>
          setPayLedgerFilters((prev) => ({
            filters: { ...prev.filters, date, date_to },
            pagination: { page: 1, per_page: prev.pagination.per_page },
          }))
        }
        btnText="Date"
      />

      {!isOverDueChildRoute && (
        <VBillRelativeDueDateFilter
          btnText="due date range"
          onDatesSubmit={async (dates) =>
            setPayLedgerFilters((prev) => ({
              filters: { ...prev.filters, ...dates },
              pagination: { page: 1, per_page: prev.pagination.per_page },
            }))
          }
          dates={pick(paymentLedgerFilters.filters, [
            "relativeReferenceDate",
            "relativeDateDueFrom",
            "relativeDateDueTo",
          ])}
          dialogTitle="Bills relative to "
        />
      )}

      {additionalMappingsVendorFirst.map(({ label, key, storeDataType }) => (
        <VBillAdditionalMappingsFilter
          key={key}
          storeType={storeDataType as unknown as IVBillStoreIoDataType}
          storeKey={key}
          batchCompanyIds={paymentLedgerFilters.filters?.companyIds ?? []}
          searchPlaceholder={`Search ${label ?? ""}`}
          inputLabel={label ?? ""}
          mapping={paymentLedgerFilters.filters?.mappings?.find((mapping) => mapping.key === key)}
          onMappingChange={handleAdditionalMappingChange}
        />
      ))}
    </Box>
  );
});
