import { Box, Typography } from "@mui/material";
import { TVBillFormFields } from "components/pages/VBill/types";
import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { useFormState } from "react-hook-form";
import { useStore } from "storeContainer";

export const VBillToolbarFormErrors = observer(() => {
  const vBillStore = useStore("VBillStore");
  const { invoice } = vBillStore;
  const invoiceData = useMemo(() => invoice.data, [invoice.data]);
  const formState = useFormState<TVBillFormFields>();
  // https://github.com/react-hook-form/react-hook-form/issues/3455
  const hasUpdateFormErrors = useMemo(() => !!Object.keys(formState.errors).length, [formState]);

  if (!hasUpdateFormErrors && !invoiceData?.displayError) {
    return null;
  }

  return (
    <Box sx={{ display: "flex", marginTop: "10px", justifyContent: "end" }}>
      {hasUpdateFormErrors ? (
        <Typography sx={{ color: "#B00020" }}>Please fix errors</Typography>
      ) : invoiceData?.displayError ? (
        <Typography sx={{ color: "#B00020" }}>{invoiceData.displayError.message}</Typography>
      ) : null}
    </Box>
  );
});
