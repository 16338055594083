import { formatIsoDateOnMidnight, normalizeIsoDateOnMidnight } from "common/helpers/dates";
import {
  IVBillBatchStatus,
  IVBillBillsFiltersOutput,
  IVBillInvoiceActionRequiredStatus,
  IVBillInvoiceIncomingStatus,
  IVBillInvoiceIntegrationStatus,
  IVBillInvoicePushStatus,
  IVBillInvoiceQuickFilterStatus,
  IVBillInvoicesFiltersInput,
  IVBillNullableCreateBatchInput,
  IVBillPaymentIntegrationStatus,
  IVBillPaymentListFiltersInput,
  IVBillPaymentPushStatus,
  IVBillPaymentStatus,
  IVBillPaymentsGroupingStatus,
} from "generated/sdk.vbill";
import { COLORS } from "themes/default";

export function getVBillUrl(organizationId: string, invoiceId: number, status: IVBillInvoiceIncomingStatus) {
  const leadingPath = `/org/${organizationId}/vbill/${invoiceId}`;

  switch (status) {
    case IVBillInvoiceIncomingStatus.Queued:
      return `${leadingPath}/incoming`;
    case IVBillInvoiceIncomingStatus.New:
      return `${leadingPath}/new`;
    case IVBillInvoiceIncomingStatus.Mapped:
    case IVBillInvoiceIncomingStatus.Approved:
    case IVBillInvoiceIncomingStatus.Declined:
    case IVBillInvoiceIncomingStatus.Deleted:
    case IVBillInvoiceIncomingStatus.History:
    case IVBillInvoiceIncomingStatus.PendingApproval:
      return `${leadingPath}/summary`;
    default:
      return `${leadingPath}/incoming`;
  }
}

export const getVBillFormattedDate = (dateString?: string | null) => {
  if (!dateString?.length) {
    return "-";
  }

  return formatIsoDateOnMidnight(dateString, "MM/dd/yy");
};

export const friendlyBatchStatusNames: { [key in IVBillBatchStatus]: string } = {
  [IVBillBatchStatus.Open]: "Open",
  [IVBillBatchStatus.Closed]: "Closed",
  [IVBillBatchStatus.Deleted]: "Deleted",
};

export const getBatchStatusStyleColor = (status?: IVBillBatchStatus | null) =>
  status === IVBillBatchStatus.Open
    ? COLORS.success
    : status === IVBillBatchStatus.Closed
      ? COLORS.orange
      : COLORS.newPrimaryColor;

export const friendlyIntegrationStatusNames: { [key in IVBillInvoiceIntegrationStatus]: string } = {
  [IVBillInvoiceIntegrationStatus.InvoiceApproved]: "Approved",
  [IVBillInvoiceIntegrationStatus.InvoiceDraft]: "Draft",
  [IVBillInvoiceIntegrationStatus.InvoicePaid]: "Paid",
  [IVBillInvoiceIntegrationStatus.InvoicePartialPaid]: "Partial Paid",
  [IVBillInvoiceIntegrationStatus.InvoicePaymentPending]: "Payment Pending",
  [IVBillInvoiceIntegrationStatus.InvoiceVoided]: "Voided",
};

export const getIntegrationStatusStyleColor = (status?: IVBillInvoiceIntegrationStatus | null) =>
  status === IVBillInvoiceIntegrationStatus.InvoicePaid
    ? COLORS.success
    : status === IVBillInvoiceIntegrationStatus.InvoiceVoided
      ? COLORS.orange
      : COLORS.newPrimaryColor;

export const friendlyPaymentsGroupingStatusNames: { [key in IVBillPaymentsGroupingStatus]: string } = {
  [IVBillPaymentsGroupingStatus.Error]: "Voided by system",
  [IVBillPaymentsGroupingStatus.Voided]: "Voided by user",
  [IVBillPaymentsGroupingStatus.Completed]: "Completed",
  [IVBillPaymentsGroupingStatus.Draft]: "Draft",
  [IVBillPaymentsGroupingStatus.Pending]: "Pending",
  [IVBillPaymentsGroupingStatus.Processing]: "Pending",
};

export const getPaymentsGroupingStatusStyleColor = (status?: IVBillPaymentsGroupingStatus | null) =>
  status === IVBillPaymentsGroupingStatus.Completed
    ? COLORS.success
    : status === IVBillPaymentsGroupingStatus.Error || status === IVBillPaymentsGroupingStatus.Voided
      ? COLORS.orange
      : COLORS.newPrimaryColor;

export const friendlyPaymentIntegrationStatusNames: { [key in IVBillPaymentIntegrationStatus]: string } = {
  [IVBillPaymentIntegrationStatus.Completed]: "Completed",
  [IVBillPaymentIntegrationStatus.Draft]: "Draft",
  [IVBillPaymentIntegrationStatus.Pending]: "Pending",
  [IVBillPaymentIntegrationStatus.Voided]: "Voided",
  [IVBillPaymentIntegrationStatus.Unknown]: "Unknown",
};

export const getPaymentIntegrationStatusStyleColor = (status?: IVBillPaymentIntegrationStatus | null) =>
  status === IVBillPaymentIntegrationStatus.Completed
    ? COLORS.success
    : status === IVBillPaymentIntegrationStatus.Voided
      ? COLORS.orange
      : COLORS.newPrimaryColor;

export const friendlyPaymentPushStatusNames: { [key in IVBillPaymentPushStatus]: string } = {
  [IVBillPaymentPushStatus.Pending]: "Pending enable",
  [IVBillPaymentPushStatus.SubmittedError]: "Error",
  [IVBillPaymentPushStatus.SubmittedOk]: "Submitted ok",
};

export const getPaymentPushStatusStyleColor = (status?: IVBillPaymentPushStatus | null) =>
  status === IVBillPaymentPushStatus.SubmittedOk
    ? COLORS.success
    : status === IVBillPaymentPushStatus.SubmittedError
      ? COLORS.orange
      : COLORS.newPrimaryColor;

export const friendlyPaymentStatusNames: { [key in IVBillPaymentStatus]: string } = {
  [IVBillPaymentStatus.Draft]: "Draft",
  [IVBillPaymentStatus.Error]: "Error",
  [IVBillPaymentStatus.Completed]: "Completed",
  [IVBillPaymentStatus.Pending]: "Pending",
  [IVBillPaymentStatus.Voided]: "Voided",
};

export const getPaymentStatusStyleColor = (status?: IVBillPaymentStatus | null) =>
  status === IVBillPaymentStatus.Completed
    ? COLORS.success
    : status === IVBillPaymentStatus.Error || status === IVBillPaymentStatus.Voided
      ? COLORS.orange
      : COLORS.newPrimaryColor;

export const friendlyInvoiceIncomingStatusNames: { [key in IVBillInvoiceIncomingStatus]: string } = {
  [IVBillInvoiceIncomingStatus.Queued]: "Incoming",
  [IVBillInvoiceIncomingStatus.New]: "Mapping",
  [IVBillInvoiceIncomingStatus.Mapped]: "Mapped",
  [IVBillInvoiceIncomingStatus.Approved]: "Approved",
  [IVBillInvoiceIncomingStatus.Declined]: "Declined",
  [IVBillInvoiceIncomingStatus.Deleted]: "Deleted",
  [IVBillInvoiceIncomingStatus.History]: "History",
  [IVBillInvoiceIncomingStatus.PendingApproval]: "Pending Approval",
  [IVBillInvoiceIncomingStatus.Duplicate]: "Duplicate",
};

export const friendlyInvoiceQuickFilterStatusNames: { [key in IVBillInvoiceQuickFilterStatus]: string } = {
  [IVBillInvoiceQuickFilterStatus.Queued]: "Incoming",
  [IVBillInvoiceQuickFilterStatus.New]: "Mapping",
  [IVBillInvoiceQuickFilterStatus.Mapped]: "Mapped",
  [IVBillInvoiceQuickFilterStatus.Approved]: "Approved",
  [IVBillInvoiceQuickFilterStatus.Declined]: "Declined",
  [IVBillInvoiceQuickFilterStatus.Deleted]: "Deleted",
  [IVBillInvoiceQuickFilterStatus.History]: "History",
  [IVBillInvoiceQuickFilterStatus.PendingApproval]: "Pending Approval",
  [IVBillInvoiceQuickFilterStatus.Duplicate]: "Duplicate",

  // [IVBillInvoiceQuickFilterStatus.PushError]: "n/a",
  [IVBillInvoiceQuickFilterStatus.PushDone]: "Synced",
  [IVBillInvoiceQuickFilterStatus.PushPending]: "Syncing",
};

export const friendlyInvoicePushStatusNames: { [key in IVBillInvoicePushStatus]: string } = {
  [IVBillInvoicePushStatus.Error]: "Failed to sync",
  [IVBillInvoicePushStatus.Queued]: "Syncing in progress",
  [IVBillInvoicePushStatus.Done]: "Synced",
};

export const friendlyInvoiceActionRequiredStatusNames: { [key in IVBillInvoiceActionRequiredStatus]: string } = {
  [IVBillInvoiceActionRequiredStatus.None]: "",
  [IVBillInvoiceActionRequiredStatus.IntegrationSyncError]: "Integration Sync Error",
  [IVBillInvoiceActionRequiredStatus.PendingApprovalExpired]: "Pending Approval Expired",
};

export const getInvoiceIncomingStatusStyleColor = (status?: IVBillInvoiceIncomingStatus | null) =>
  status === IVBillInvoiceIncomingStatus.Declined ||
  status === IVBillInvoiceIncomingStatus.Deleted ||
  status === IVBillInvoiceIncomingStatus.Duplicate ||
  status === IVBillInvoiceIncomingStatus.History
    ? COLORS.orange
    : status === IVBillInvoiceIncomingStatus.Approved
      ? COLORS.success
      : COLORS.newSecondaryColor;

export const getInvoicePushStatusStyleColor = (status?: IVBillInvoicePushStatus | null) =>
  status === IVBillInvoicePushStatus.Error
    ? COLORS.orange
    : status === IVBillInvoicePushStatus.Queued
      ? COLORS.newPrimaryColor
      : COLORS.success;

// TODO: get rid of this
export const transformDatesAsSODUTCIsoString = (
  dates:
    | ((Pick<IVBillBillsFiltersOutput, "date" | "date_to" | "date_due_from" | "date_due_to"> &
        Pick<IVBillPaymentListFiltersInput, "dateFrom" | "dateTo">) &
        Pick<IVBillNullableCreateBatchInput, "batchDate" | "batchDateDue"> &
        Pick<
          IVBillInvoicesFiltersInput,
          | "createDateFrom"
          | "createDateTo"
          | "invoiceDateDueFrom"
          | "invoiceDateDueTo"
          | "invoiceDateFrom"
          | "invoiceDateTo"
        >)
    | undefined
    | null,
) => {
  if (!dates) {
    return {};
  }

  const {
    date,
    date_to,
    date_due_from,
    date_due_to,
    dateTo,
    dateFrom,
    batchDate,
    batchDateDue,
    createDateFrom,
    createDateTo,
    invoiceDateDueFrom,
    invoiceDateDueTo,
    invoiceDateFrom,
    invoiceDateTo,
  } = dates;

  return {
    ...(date ? { date: normalizeIsoDateOnMidnight(date) } : {}),
    ...(date_to ? { date_to: normalizeIsoDateOnMidnight(date_to) } : {}),
    ...(date_due_from ? { date_due_from: normalizeIsoDateOnMidnight(date_due_from) } : {}),
    ...(date_due_to ? { date_due_to: normalizeIsoDateOnMidnight(date_due_to) } : {}),
    ...(dateFrom ? { dateTo: normalizeIsoDateOnMidnight(dateFrom) } : {}),
    ...(dateTo ? { dateTo: normalizeIsoDateOnMidnight(dateTo) } : {}),
    ...(batchDate ? { batchDate: normalizeIsoDateOnMidnight(batchDate) } : {}),
    ...(batchDateDue ? { batchDateDue: normalizeIsoDateOnMidnight(batchDateDue) } : {}),
    ...(createDateFrom ? { createDateFrom: normalizeIsoDateOnMidnight(createDateFrom) } : {}),
    ...(createDateTo ? { createDateTo: normalizeIsoDateOnMidnight(createDateTo) } : {}),
    ...(invoiceDateDueFrom ? { invoiceDateDueFrom: normalizeIsoDateOnMidnight(invoiceDateDueFrom) } : {}),
    ...(invoiceDateDueTo ? { invoiceDateDueTo: normalizeIsoDateOnMidnight(invoiceDateDueTo) } : {}),
    ...(invoiceDateFrom ? { invoiceDateFrom: normalizeIsoDateOnMidnight(invoiceDateFrom) } : {}),
    ...(invoiceDateTo ? { invoiceDateTo: normalizeIsoDateOnMidnight(invoiceDateTo) } : {}),
  };
};
