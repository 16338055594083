import { TextField, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { RootDelegationGroupWidget } from "components/common/Approval/RootDelegationGroupWidget";
import Card from "components/common/Card";
import { VBillCopyTextToClipboard } from "components/pages/common/VBill/VBillCopyTextToClipboard/VBillCopyTextToClipboard";
import { VBillReimbursementTokenDetails } from "components/pages/common/VBill/VBillReimbursementTokenDetails/VBillReimbursementTokenDetails";
import { CompanyDetailsQuery, DelegationGroupScopeEnum } from "generated/sdk";
import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";
import { useParams } from "react-router";
import { useStore } from "storeContainer";

interface IVBillDetailsProps {
  isCompanyDetailsLoading: boolean;
  companyDetails: CompanyDetailsQuery["CompanyDetails"];
}

export const VBillDetails: FunctionComponent<IVBillDetailsProps> = observer(
  ({ companyDetails, isCompanyDetailsLoading }) => {
    const sessionStore = useStore("SessionStore");
    const params = useParams<{ organizationId: string; companyId: string }>();

    if (isCompanyDetailsLoading || !companyDetails?.vbill_enabled) {
      return null;
    }

    return (
      <Card
        sx={{ marginTop: "20px" }}
        title={
          <Typography sx={{ fontSize: "18px" }} variant="h5">
            Bill
          </Typography>
        }
      >
        {companyDetails.vbill_email && (
          <Box sx={{ marginBottom: "10px" }}>
            <Typography sx={{ marginBottom: "5px" }}>Bills email:</Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                width: "600px",
              }}
            >
              <TextField
                defaultValue={companyDetails.vbill_email}
                InputProps={{
                  sx: { height: "32px" },
                  readOnly: true,
                }}
                fullWidth
              />

              <VBillCopyTextToClipboard textToCopy={companyDetails.vbill_email} />
            </Box>
          </Box>
        )}

        <VBillReimbursementTokenDetails
          organizationId={params.organizationId}
          companyId={params.companyId}
          sx={{ marginBottom: "10px" }}
        />

        {sessionStore.acl.canManageOrganization && (
          <>
            <Typography sx={{ marginTop: "20px", fontSize: "16px" }}>Default bill approver:</Typography>
            <RootDelegationGroupWidget
              scope={DelegationGroupScopeEnum.VbillApprove}
              organizationId={params.organizationId}
              companyId={params.companyId}
            />
          </>
        )}
      </Card>
    );
  },
);
