import { CheckBatchStateEnum, CheckBatchType, CheckFiltersType, ChecksPaginatedType } from "generated/sdk";
import { observer } from "mobx-react-lite";
import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useStore } from "storeContainer";
import { debounce, isEqual } from "lodash";
import { JsonParam, useQueryParam } from "use-query-params";
import { useDeepCompareEffect } from "react-use";
import { DEFAULT_PAGINATION_PER_PAGE_FOR_AVAILABLE_CHECKS } from "./utils";

interface Props {
  isLoading: boolean;
  errorAll: boolean;
  isBatchClosed: boolean;
  batchQuery: CheckBatchType;
  checksQuery: ChecksPaginatedType;
  page: number;
  rowsPerPage: number;
  pageAvailableChecks: number;
  rowsPerPageAvailableChecks: number;
  handleChangePage: (event: unknown, newPage: number) => void;
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangePageAvailableCheck: (event: unknown, newPage: number) => void;
  handleChangeRowsPerPageAvailableChecks: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

type BatchDetailsContextType = {
  props: Props;
};

const BatchDetailsContext = createContext<BatchDetailsContextType | undefined>(undefined);

export const BatchDetailsProvider: React.FC = observer(function BatchDetailsProvider({ children }) {
  const BudgetManagementStore = useStore("BudgetManagementStore");
  const { batchId } = useParams<{ organizationId: string; batchId: string }>();
  const [filters] = useQueryParam<CheckFiltersType>("filters", JsonParam);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_PAGINATION_PER_PAGE_FOR_AVAILABLE_CHECKS);
  const [pageAvailableChecks, setPageAvailableChecks] = useState(0);
  const [rowsPerPageAvailableChecks, setRowsPerPageAvailableChecks] = useState(
    DEFAULT_PAGINATION_PER_PAGE_FOR_AVAILABLE_CHECKS,
  );
  const {
    fetchCheckBatch,
    fetchAvailableChecksForBatchQuery,
    checkBatchData,
    updatedCheckBatch,
    deletedCheckBatchItems,
    createdCheckBatchItems,
    availableChecksForBatchQuery,
    fetchRawCheckBatch,
    fetchRawAvailableChecksForBatchQuery,
  } = BudgetManagementStore;

  const [batchQuery, setBatchQuery] = useState(checkBatchData.data?.GetCheckBatchQuery);
  const [checksQuery, setChecksQuery] = useState(availableChecksForBatchQuery?.data?.GetAvailableChecksForBatchQuery);

  const isLoading =
    (checkBatchData.isLoading && checkBatchData?.isFetching) ||
    (updatedCheckBatch.isLoading && updatedCheckBatch.isFetching) ||
    (createdCheckBatchItems.isLoading && createdCheckBatchItems.isFetching) ||
    (deletedCheckBatchItems.isLoading && deletedCheckBatchItems.isFetching) ||
    (availableChecksForBatchQuery.isLoading && availableChecksForBatchQuery.isFetching);
  const errorAll = Boolean(
    checkBatchData.error ||
      updatedCheckBatch.error ||
      createdCheckBatchItems.error ||
      deletedCheckBatchItems.error ||
      availableChecksForBatchQuery.error,
  );
  const isBatchClosed = checkBatchData?.data?.GetCheckBatchQuery?.state === CheckBatchStateEnum.Closed;

  const fetchData = async (filters: CheckFiltersType) => {
    await fetchCheckBatch(batchId, filters, { per_page: rowsPerPage, page: page + 1 }, true);
    setBatchQuery(checkBatchData.data?.GetCheckBatchQuery);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetchData = useCallback(
    debounce((newValue: CheckFiltersType) => fetchData(newValue), 300),
    [],
  );

  const fetchAvailableChecksData = async (filters: CheckFiltersType) => {
    await fetchAvailableChecksForBatchQuery(
      batchId,
      { per_page: rowsPerPageAvailableChecks, page: pageAvailableChecks + 1 },
      filters,
      true,
    );

    setChecksQuery(availableChecksForBatchQuery.data?.GetAvailableChecksForBatchQuery);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetchAvailableChecksData = useCallback(
    debounce((newValue: CheckFiltersType) => fetchAvailableChecksData(newValue), 300),
    [],
  );

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    fetchCheckBatch(batchId, filters, { page: newPage + 1, per_page: rowsPerPage }, true);
  };
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    fetchCheckBatch(batchId, filters, { page: 1, per_page: parseInt(event.target.value, 10) }, true);
  };

  const handleChangePageAvailableCheck = (event: unknown, newPage: number) => {
    setPageAvailableChecks(newPage);
    fetchAvailableChecksForBatchQuery(
      batchId,
      { page: newPage + 1, per_page: rowsPerPageAvailableChecks },
      filters,
      true,
    );
  };
  const handleChangeRowsPerPageAvailableChecks = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPageAvailableChecks(parseInt(event.target.value, 10));
    setPageAvailableChecks(0);
    fetchAvailableChecksForBatchQuery(
      batchId,
      {
        page: pageAvailableChecks + 1,
        per_page: parseInt(event.target.value, 10),
      },
      undefined,
      true,
    );
  };

  useDeepCompareEffect(() => {
    debouncedFetchData(filters);

    return () => {
      debouncedFetchData.cancel();
    };
  }, [debouncedFetchData, filters]);

  useEffect(() => {
    if (checkBatchData.data?.GetCheckBatchQuery) {
      setBatchQuery(checkBatchData.data?.GetCheckBatchQuery);
    }
  }, [checkBatchData.data?.GetCheckBatchQuery]);

  useEffect(() => {
    const intervalId = setInterval(async () => {
      const newBatchQuery = await fetchRawCheckBatch(batchId, filters, { page: page, per_page: rowsPerPage });
      if (!isEqual(newBatchQuery, batchQuery)) {
        setBatchQuery(newBatchQuery);
      }
    }, 5000);
    return () => {
      clearInterval(intervalId);
    };
  }, [batchId, batchQuery, fetchRawCheckBatch, filters, page, rowsPerPage]);

  useEffect(() => {
    if (isBatchClosed) {
      return;
    }
    const intervalId = setInterval(async () => {
      const newBatchQuery = await fetchRawAvailableChecksForBatchQuery(
        batchId,
        {
          page: pageAvailableChecks,
          per_page: rowsPerPageAvailableChecks,
        },
        filters,
      );
      if (!isEqual(newBatchQuery, checksQuery)) {
        setChecksQuery(newBatchQuery!);
      }
    }, 5000);
    return () => {
      clearInterval(intervalId);
    };
  }, [
    batchId,
    checksQuery,
    fetchRawAvailableChecksForBatchQuery,
    filters,
    isBatchClosed,
    pageAvailableChecks,
    rowsPerPageAvailableChecks,
  ]);

  useEffect(() => {
    if (availableChecksForBatchQuery?.data?.GetAvailableChecksForBatchQuery && !isBatchClosed) {
      setChecksQuery(availableChecksForBatchQuery?.data?.GetAvailableChecksForBatchQuery);
    }
  }, [availableChecksForBatchQuery?.data?.GetAvailableChecksForBatchQuery, isBatchClosed]);

  useDeepCompareEffect(() => {
    if (!isBatchClosed) {
      debouncedFetchAvailableChecksData(filters);
    }
    return () => {
      debouncedFetchAvailableChecksData.cancel();
    };
  }, [debouncedFetchAvailableChecksData, filters, isBatchClosed]);

  return (
    <BatchDetailsContext.Provider
      value={{
        props: {
          isLoading,
          errorAll,
          isBatchClosed,
          batchQuery: batchQuery as CheckBatchType,
          checksQuery: checksQuery as ChecksPaginatedType,
          page,
          rowsPerPage,
          pageAvailableChecks,
          rowsPerPageAvailableChecks,
          handleChangePage,
          handleChangeRowsPerPage,
          handleChangePageAvailableCheck,
          handleChangeRowsPerPageAvailableChecks,
        },
      }}
    >
      {children}
    </BatchDetailsContext.Provider>
  );
});

export const useBatchDetailsContext = (): BatchDetailsContextType => {
  const context = useContext(BatchDetailsContext);
  if (context === undefined) {
    throw new Error("useMyContext must be used within a MyProvider");
  }
  return context;
};
