import { TableCell, TableRow, Tooltip, Typography } from "@mui/material";
import { formatCurrency } from "common/helpers/utils";
import { lineItemAdditionalMappingsToFormLineFields } from "components/pages/VBill/utils";
import {
  IVBillIIntegrationIntegrationSettingsVBillAdditionalMappingsEntryShowIn,
  IVBillLineItemSchema,
} from "generated/sdk.vbill";
import { partition } from "lodash";
import { observer } from "mobx-react";
import { Fragment, useMemo } from "react";
import { useStore } from "storeContainer";

const defaultTableCellSx = {
  padding: "8px 5px",
  verticalAlign: "top",
};

interface ISummaryLineItemProps {
  lineItem: IVBillLineItemSchema;
  index: number;
}

export const SummaryLineItem = observer(({ lineItem, index }: ISummaryLineItemProps) => {
  const vBillStore = useStore("VBillStore");
  const vBillAdditionalMappingsSettings = useMemo(
    () => vBillStore.settings.data?.vBill.additionalMappings,
    [vBillStore.settings.data?.vBill.additionalMappings],
  );
  const [glAccMapping, addMappings] = useMemo(() => {
    const lineAdditionalMappings = (vBillAdditionalMappingsSettings?.line ?? []).filter(
      (additionalMapping) =>
        additionalMapping.showIn !== IVBillIIntegrationIntegrationSettingsVBillAdditionalMappingsEntryShowIn.Payment &&
        additionalMapping.showIn !== IVBillIIntegrationIntegrationSettingsVBillAdditionalMappingsEntryShowIn.None,
    );

    return partition(lineAdditionalMappings, (map) => map.isGlAccount);
  }, [vBillAdditionalMappingsSettings?.line]);
  const lineItemMappings = useMemo(() => lineItemAdditionalMappingsToFormLineFields(lineItem), [lineItem]);

  const { description, qty, rate, amount } = lineItem;

  return (
    <TableRow sx={{ cursor: "default" }}>
      <TableCell sx={defaultTableCellSx}>
        <Tooltip arrow title="Line number">
          <span>#{index + 1}</span>
        </Tooltip>
      </TableCell>
      <TableCell sx={defaultTableCellSx}>
        <Typography sx={{ wordWrap: "break-word" }}>
          <Tooltip arrow title="Description">
            <span>{description ?? "-"}</span>
          </Tooltip>
        </Typography>
      </TableCell>

      <TableCell sx={defaultTableCellSx}>
        {glAccMapping.map((additionalMapping) => (
          <Fragment key={additionalMapping.key}>
            {lineItemMappings[additionalMapping.storeDataType] ? (
              <Typography>
                <Tooltip arrow title={additionalMapping.label}>
                  <span>{`${lineItemMappings[additionalMapping.storeDataType]?.label ?? "-"}${
                    lineItemMappings[additionalMapping.storeDataType]?.label2
                      ? ` - ${lineItemMappings[additionalMapping.storeDataType]?.label2}`
                      : ""
                  }`}</span>
                </Tooltip>
              </Typography>
            ) : (
              "-"
            )}
          </Fragment>
        ))}
      </TableCell>

      {addMappings.length
        ? addMappings.map((additionalMapping) =>
            lineItemMappings[additionalMapping.storeDataType] ? (
              <TableCell key={additionalMapping.key} sx={defaultTableCellSx}>
                <Typography>
                  <Tooltip arrow title={additionalMapping.label}>
                    <span>{`${lineItemMappings[additionalMapping.storeDataType]?.label ?? "-"}${
                      lineItemMappings[additionalMapping.storeDataType]?.label2
                        ? ` - ${lineItemMappings[additionalMapping.storeDataType]?.label2}`
                        : ""
                    }`}</span>
                  </Tooltip>
                </Typography>
              </TableCell>
            ) : (
              <TableCell key={additionalMapping.key}>-</TableCell>
            ),
          )
        : null}

      <TableCell sx={defaultTableCellSx}>
        <Typography sx={{ wordWrap: "break-word" }}>
          <Tooltip arrow title="Qty">
            <span>{qty ?? "1"}</span>
          </Tooltip>
        </Typography>
      </TableCell>

      <TableCell sx={defaultTableCellSx}>
        <Typography sx={{ wordWrap: "break-word" }}>
          <Tooltip arrow title="Rate">
            <span>{rate ?? "0"}</span>
          </Tooltip>
        </Typography>
      </TableCell>

      <TableCell sx={{ ...defaultTableCellSx, textAlign: "right" }}>
        <Typography sx={{ wordWrap: "break-word" }}>
          <Tooltip arrow title="Total">
            <span>{formatCurrency(Number(amount ?? 0))}</span>
          </Tooltip>
        </Typography>
      </TableCell>
    </TableRow>
  );
});
