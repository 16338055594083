/* eslint-disable */
const __webpack_nonce__ = (window as any).nonce ?? "";

import { Box } from "@mui/material";
import { graphqlClient } from "common/graphqlClient";
import { ApprovalResolutionEnum, ApprovalTypeEnum, DelegationGroupScopeEnum, getSdk } from "generated/sdk";
import { IVBillInvoiceSchema } from "generated/sdk.vbill";
import { useState } from "react";
import { useStore } from "storeContainer";
import LoadingButton from "../LoadingButton";
import { AssignButton } from "./AssignButton";
import { getApprovalFlags } from "./getApprovalFlags";
import "./react-d3-tree-style-workaround.css";
import { IApprovalEntityParams } from "./types";

interface ApprovalButtonsProps {
  scope: DelegationGroupScopeEnum;
  entityParams: IApprovalEntityParams;
  approvalStatus: IVBillInvoiceSchema["approvalStatus"];
  showAssign: boolean;
  showApproval: boolean;
  showDecline: boolean;
  reloadParent: () => void;
}

export const ApprovalButtons: React.FunctionComponent<ApprovalButtonsProps> = ({
  scope,
  entityParams,
  approvalStatus,
  showAssign,
  showApproval,
  showDecline,
  reloadParent,
}) => {
  const { selectedOrganizationUserId } = useStore("SessionStore");
  const [approvalLoading, setApprovalLoading] = useState<boolean>(false);

  if (!approvalStatus || !selectedOrganizationUserId) {
    return null;
  }

  const { showApprovalWidget, hasUserApproved, canUserApprove, hasUserReviewed, canUserReview, hasUserDeclined } =
    getApprovalFlags(approvalStatus, selectedOrganizationUserId);

  if (!showApprovalWidget) {
    return null;
  }

  let buttonText!: string;
  let buttonActive!: boolean;
  let approvalType!: ApprovalTypeEnum;
  if (hasUserDeclined) {
    buttonText = "Declined";
    buttonActive = false;
  } else if (hasUserApproved) {
    approvalType = ApprovalTypeEnum.Approval;
    buttonText = "Approved";
    buttonActive = false;
  } else if (canUserApprove) {
    approvalType = ApprovalTypeEnum.Approval;
    buttonText = "Approve";
    buttonActive = true;
  } else if (hasUserReviewed) {
    approvalType = ApprovalTypeEnum.Review;
    buttonText = "Reviewed";
    buttonActive = false;
  } else if (canUserReview) {
    approvalType = ApprovalTypeEnum.Review;
    buttonText = "Review";
    buttonActive = true;
  } else {
    return null;
  }

  const runApprovalAction = async (resolution: ApprovalResolutionEnum) => {
    setApprovalLoading(true);

    await getSdk(graphqlClient).SaveApproval({
      organization_id: entityParams.organizationId,
      bill_id: entityParams.billId,
      check_id: entityParams.checkId,
      type: approvalType,
      resolution,
    });

    setApprovalLoading(false);

    reloadParent();
  };

  return (
    <Box sx={{ display: "flex", gap: "15px" }}>
      {showAssign && (
        <AssignButton
          isActive={buttonActive}
          approvalStatus={approvalStatus}
          scope={scope}
          entityParams={entityParams}
        />
      )}

      {showApproval && (
        <LoadingButton
          loading={approvalLoading}
          onClick={() => runApprovalAction(ApprovalResolutionEnum.Positive)}
          variant="contained"
          color="secondary"
          disabled={!buttonActive}
          sx={{ height: "32px" }}
        >
          {buttonText}
        </LoadingButton>
      )}

      {showDecline && !hasUserDeclined && (
        <LoadingButton
          loading={approvalLoading}
          onClick={() => runApprovalAction(ApprovalResolutionEnum.Negative)}
          variant="outlined"
          color="error"
          sx={{ height: "32px" }}
        >
          Decline
        </LoadingButton>
      )}
    </Box>
  );
};
