import {
  IVBillInvoiceActionRequiredStatus,
  IVBillInvoiceQuickFilterStatus,
  IVBillInvoicesFiltersInput,
} from "generated/sdk.vbill";
import { vBillCodingLedgerChildRoute } from "./hooks";

export enum VBillCodingLedgerChildRoutes {
  ALL = "",
  PENDING_REVIEW = "pending-review",
  CODING = "coding",
  APPROVALS = "approvals",
  ACTIONS_REQUIRED = "actions-required",
  COMPLETED = "completed",
}

export function getVBillCodingLedgerBaseFiltersByChildRoute(
  childRoute: vBillCodingLedgerChildRoute,
  organizationId: string,
  companyIds: string[],
): IVBillInvoicesFiltersInput {
  const {
    isAllChildRoute,
    isPendingReviewChildRoute,
    isCodingChildRoute,
    isApprovalsChildRoute,
    isActionRequiredChildRoute,
    isCompletedChildRoute,
  } = childRoute;

  if (isAllChildRoute) {
    return { organizationId };
  }

  if (isPendingReviewChildRoute) {
    return { organizationId, quickFilterStatusList: [IVBillInvoiceQuickFilterStatus.Queued] };
  }

  if (isCodingChildRoute) {
    return {
      organizationId,
      quickFilterStatusList: [IVBillInvoiceQuickFilterStatus.New, IVBillInvoiceQuickFilterStatus.Mapped],
    };
  }

  if (isApprovalsChildRoute) {
    return { organizationId, companyIds, quickFilterStatusList: [IVBillInvoiceQuickFilterStatus.PendingApproval] };
  }

  if (isActionRequiredChildRoute) {
    return {
      organizationId,
      companyIds,
      actionRequiredStatusList: [
        IVBillInvoiceActionRequiredStatus.IntegrationSyncError,
        IVBillInvoiceActionRequiredStatus.PendingApprovalExpired,
      ],
    };
  }

  if (isCompletedChildRoute) {
    return {
      organizationId,
      companyIds,
      quickFilterStatusList: [
        IVBillInvoiceQuickFilterStatus.PushPending,
        IVBillInvoiceQuickFilterStatus.PushDone,
        IVBillInvoiceQuickFilterStatus.Declined,
      ],
    };
  }

  return { organizationId };
}

export enum CodingDateGroups {
  "CreateDate",
  "BillDue",
  "BillDate",
}

export const staticCodingDateLines = [
  {
    label: "Create date",
    group: CodingDateGroups.CreateDate,
  },
  {
    label: "Bill due",
    group: CodingDateGroups.BillDue,
  },
  {
    label: "Bill date",
    group: CodingDateGroups.BillDate,
  },
];
