import { CheckFiltersDatePresetEnumType } from "generated/sdk";
import { ComponentProps } from "react";

export interface IFiltersProps extends ComponentProps<any> {
  unvalidated: boolean;
}

export const presetArray = [
  { key: "any_time", value: "Any Time" },
  { key: CheckFiltersDatePresetEnumType.LastDay, value: "Last Day" },
  { key: CheckFiltersDatePresetEnumType.LastWeek, value: "Last Week" },
  { key: CheckFiltersDatePresetEnumType.LastMonth, value: "Last Month" },
  { key: CheckFiltersDatePresetEnumType.LastYear, value: "Last Year" },
  { key: "custom_range", value: "Custom Range..." },
];
