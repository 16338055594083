import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { IVBillVBillGetBillsQuery, IVBillVBillPaymentQuery } from "generated/sdk.vbill";
import { COLORS } from "themes/default";
import { BillLineItem } from "./BillLineItem/BillLineItem";

const defaultTableHeadCellSx = {
  padding: "10px 16px",
};

interface IBillLineItemsProps {
  payment?: IVBillVBillPaymentQuery["payment"];
  paidBillsItems?: IVBillVBillGetBillsQuery["getBills"]["items"];
}

export const BillLineItems = ({ payment, paidBillsItems }: IBillLineItemsProps) => (
  <Box>
    <Typography sx={{ fontSize: 16, marginBottom: "15px", fontWeight: 500 }} color={COLORS.logoBlue}>
      BILLS:
    </Typography>

    <TableContainer>
      <Table>
        <TableHead>
          <TableRow sx={{ cursor: "default" }}>
            <TableCell sx={defaultTableHeadCellSx}>Bill #</TableCell>
            <TableCell sx={defaultTableHeadCellSx}>Ref</TableCell>
            <TableCell sx={defaultTableHeadCellSx}>Memo</TableCell>
            <TableCell sx={defaultTableHeadCellSx}>Due Date</TableCell>
            <TableCell sx={{ ...defaultTableHeadCellSx, textAlign: "right" }}>Pay Amount</TableCell>
            <TableCell sx={defaultTableHeadCellSx} />
          </TableRow>
        </TableHead>
        <TableBody>
          {paidBillsItems?.map((bill) => (
            <BillLineItem key={bill.id} bill={bill} payableInvoices={payment?.payableInvoices} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </Box>
);
