import { Checkbox, TableCell, TableHead, TableRow, TableSortLabel, Tooltip } from "@mui/material";
import { vBillLedgerTHeadSx } from "components/pages/common/VBill/styles";
import { IVBillSortOrderType } from "generated/sdk.vbill";
import { observer } from "mobx-react";
import { useMemo } from "react";
import { useStore } from "storeContainer";
import { useTableHeadCells } from "./useTableHeadCells";

export const PayHeader = observer(function PayHeader() {
  const vBillPayLedgerStore = useStore("VBillPayLedgerStore");
  const tableHeadCells = useTableHeadCells();

  const handleSelectPaymentToPayCheckboxClick = () => {
    if (isSelectPaymentToPayCheckboxChecked || isSelectPaymentToPayCheckboxIndeterminate) {
      vBillPayLedgerStore.updateSelectedBillIdsToPay([]);
    } else {
      const billIds = (billsLedgerList?.data?.items ?? [])
        .filter(({ amounts, canBePaid }) => Number(amounts?.amountFreeToPay ?? 0) > 0 && canBePaid)
        .map(({ id }) => id);

      vBillPayLedgerStore.updateSelectedBillIdsToPay(billIds);
    }
  };
  const { selectedBillIdsToPay, billsLedgerList } = vBillPayLedgerStore;
  const totalSelectedBillIdsToPay = selectedBillIdsToPay.length;
  const totalBillsLedgerListItems = useMemo(
    () =>
      (billsLedgerList.data?.items ?? []).filter(
        ({ amounts, canBePaid }) => Number(amounts?.amountFreeToPay ?? 0) > 0 && canBePaid,
      ).length,
    [billsLedgerList.data?.items],
  );
  const isSelectPaymentToPayCheckboxChecked = useMemo(
    () => (totalBillsLedgerListItems === 0 ? false : totalSelectedBillIdsToPay === totalBillsLedgerListItems),
    [totalBillsLedgerListItems, totalSelectedBillIdsToPay],
  );
  const isSelectPaymentToPayCheckboxIndeterminate = useMemo(
    () => (totalSelectedBillIdsToPay ? totalSelectedBillIdsToPay < totalBillsLedgerListItems : false),
    [totalBillsLedgerListItems, totalSelectedBillIdsToPay],
  );

  return (
    <TableHead>
      <TableRow sx={{ cursor: "default" }}>
        <TableCell sx={vBillLedgerTHeadSx}>
          <Tooltip
            title={
              isSelectPaymentToPayCheckboxChecked || isSelectPaymentToPayCheckboxIndeterminate
                ? "Remove all from payment"
                : "Add all to payment"
            }
          >
            <Checkbox
              color="primary"
              checked={isSelectPaymentToPayCheckboxChecked}
              indeterminate={isSelectPaymentToPayCheckboxIndeterminate}
              onClick={handleSelectPaymentToPayCheckboxClick}
            />
          </Tooltip>
        </TableCell>

        {tableHeadCells.map((headerCell) =>
          headerCell.sortable ? (
            <TableCell
              key={headerCell.id}
              sx={{ ...vBillLedgerTHeadSx, ...(headerCell.id === "amount_sort" ? { textAlign: "right" } : {}) }}
              sortDirection={headerCell.value === IVBillSortOrderType.Asc ? "asc" : "desc"}
            >
              <TableSortLabel
                active={headerCell.value === IVBillSortOrderType.Asc}
                direction={headerCell.value === IVBillSortOrderType.Asc ? "asc" : "desc"}
                sx={{ ...(headerCell.id === "amount_sort" ? { flexDirection: "row-reverse" } : {}) }}
                onClick={() =>
                  headerCell.onChange?.(
                    headerCell.value === IVBillSortOrderType.Asc ? IVBillSortOrderType.Desc : IVBillSortOrderType.Asc,
                  )
                }
              >
                {headerCell.label}
              </TableSortLabel>
            </TableCell>
          ) : (
            <TableCell sx={vBillLedgerTHeadSx} key={headerCell.id}>
              {headerCell.label}
            </TableCell>
          ),
        )}

        {/* empty last col */}
        <TableCell sx={vBillLedgerTHeadSx} />
      </TableRow>
    </TableHead>
  );
});
