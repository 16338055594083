import React, { useState } from "react";
import { LimitCreateVChecksRule } from "../../../../../../generated/sdk";
import { cleanLimitCreateVChecksRule, LimitCreateVChecksRuleClean } from "../../../../../../storesMobx/AclStore";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";

import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { COLORS } from "../../../../../../themes/default";

const cleanRules = (rules: LimitCreateVChecksRule[]): LimitCreateVChecksRuleClean[] => {
  const cleanRules = cleanLimitCreateVChecksRule(rules).sort((a, b) => a.above - b.above);
  if (!cleanRules.length) {
    cleanRules.push({ above: 0, count: 0 });
  }
  if (cleanRules[0].above) {
    cleanRules[0].above = 0;
  }
  cleanRules.forEach((rule, idx) => {
    const prevRule = cleanRules[idx - 1];
    if (prevRule && prevRule.count > rule.count) {
      rule.count = prevRule.count;
    }
  });
  return cleanRules;
};

const removeDuplicated = (rules: LimitCreateVChecksRule[]) => {
  const newRules: LimitCreateVChecksRule[] = [];
  rules.forEach((rule) => {
    if (newRules.length && newRules[newRules.length - 1].count === rule.count) return;
    newRules.push(rule);
  });
  return newRules;
};

const checkForErrors = (rules: LimitCreateVChecksRuleClean[]) => {
  const newRules: (LimitCreateVChecksRuleClean & { errAbove?: string; errCount?: string })[] = [];
  rules.forEach((rule, idx) => {
    const nextRule = rules[idx + 1];
    let errAbove, errCount;
    if (nextRule && nextRule.above < rule.above) {
      errAbove = `Value is greated than the next one`;
    }
    if (nextRule && nextRule.count < rule.count) {
      errCount = `Value is greated than the next one`;
    }
    newRules.push({ ...rule, errAbove, errCount });
  });

  return newRules;
};

export const AclPermLimitCreateCheckRulesInput = ({
  value,
  onChange,
  onCancel,
  showButtons,
}: {
  value: LimitCreateVChecksRule[];
  onChange: (value: LimitCreateVChecksRule[]) => void;
  onCancel?: () => void;
  showButtons?: true;
}) => {
  const [rules, setRules] = useState<(LimitCreateVChecksRuleClean & { errAbove?: string; errCount?: string })[]>(
    cleanRules(value),
  );
  const [originalRules] = useState(cleanRules(value));
  const [changes, setChanges] = useState(false);
  const [errCount, setErrCount] = useState(0);

  const countOptions = [
    { value: 0, label: "Auto approve" },
    { value: 1, label: "Approval required" },
    { value: 2, label: "2 Approvals" },
    { value: 3, label: "3 Approvals" },
    { value: 4, label: "4 Approvals" },
    { value: 5, label: "5 Approvals" },
    { value: 6, label: "6 Approvals" },
  ];

  const addRule = () => {
    const lastRule = rules[rules.length - 1];
    const newRules = [...rules, { above: lastRule.above + 100, count: Math.min(lastRule.count + 1, 6) }];
    setRules(newRules);
    setChanges(true);

    return newRules;
  };

  const removeRule = (idx: number) => {
    let newRules = [...rules];
    newRules.splice(idx, 1);
    newRules = checkForErrors(newRules);
    setRules(cleanRules(newRules));
    setChanges(true);
    const errCount = newRules.map((e) => !!e.errAbove || !!e.errCount).filter((e) => !!e).length;
    setErrCount(errCount);

    return newRules;
  };

  const updateRule = (idx: number, count: number, above: number) => {
    let newRules = [...rules];

    newRules[idx] = { count, above };
    newRules = checkForErrors(newRules);
    setRules(newRules);

    const errCount = newRules.map((e) => !!e.errAbove || !!e.errCount).filter((e) => !!e).length;
    setErrCount(errCount);
    setChanges(true);
  };

  return (
    <Grid container rowSpacing={1} columnSpacing={1}>
      <Grid item xs={5}>
        <Typography variant="body2" sx={{ fontWeight: 500, color: COLORS.darkGrey }}>
          Range
        </Typography>
      </Grid>
      <Grid item xs={7}>
        <Typography variant="body2" sx={{ fontWeight: 500, color: COLORS.darkGrey }}>
          Approval
        </Typography>
      </Grid>
      {rules.map((rule, idx) => {
        const nextRule = rules[idx + 1];
        const nextValue = nextRule?.above || "";
        return (
          <React.Fragment key={idx}>
            <Grid item xs={5} key={`${idx}-1`}>
              <Box
                component="form"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  py: 0.4,
                  width: "100%",
                  border: 1,
                  borderRadius: 1,
                  borderColor: !!rule.errAbove ? COLORS.error : COLORS.borderColor,
                }}
              >
                <InputBase
                  fullWidth
                  readOnly={!idx}
                  disabled={!idx}
                  sx={{ px: 1, fontWeight: 600, fontSize: "14px" }}
                  type="number"
                  value={rule.above}
                  onChange={(e) => {
                    updateRule(idx, +rule.count, +e.target.value);
                  }}
                  error={!!rule.errAbove}
                  title={rule.errAbove}
                  color={!!rule.errAbove ? "error" : undefined}
                />
                <Divider orientation="vertical" sx={{ color: COLORS.borderColor }} flexItem />
                <InputBase
                  fullWidth
                  readOnly
                  type="number"
                  sx={{ px: 1, fontWeight: 600, fontSize: "14px" }}
                  value={nextValue}
                  placeholder="No limit"
                  disabled={true}
                />
              </Box>
              {/*<Box sx={{ display: "flex" }}>*/}

              {/*</Box>*/}
            </Grid>
            <Grid item xs={5} key={`${idx}-2`}>
              <Select
                size="small"
                fullWidth
                value={`${rule.count}`}
                onChange={(e) => updateRule(idx, +e.target.value, rule.above)}
                error={!!rule.errCount}
                title={rule.errCount}
                color={!!rule.errCount ? "error" : undefined}
              >
                {countOptions.map((o) => (
                  <MenuItem
                    value={`${o.value}`}
                    key={`${o.value}`}
                    // disabled={idx ? rules[idx - 1].count > o.value : undefined}
                  >
                    {o.label}
                  </MenuItem>
                ))}
              </Select>
            </Grid>

            <Grid item xs={2} key={`${idx}-3`}>
              {idx ? (
                <IconButton>
                  <DeleteForeverIcon sx={{ color: COLORS.mediumGrey }} onClick={() => removeRule(idx)} />
                </IconButton>
              ) : null}
            </Grid>
          </React.Fragment>
        );
      })}
      <Grid item xs={12}>
        <Button onClick={addRule} sx={{ textTransform: "capitalize" }} variant="text">
          + Add Rule
        </Button>
      </Grid>

      {changes || showButtons ? (
        <Grid item xs={12}>
          <Button
            disabled={!!errCount || !changes}
            onClick={() => {
              if (errCount) {
                return console.error("Please check the errors");
              }
              onChange(removeDuplicated(rules));
            }}
            variant="contained"
          >
            Save
          </Button>{" "}
          <Button
            onClick={() => {
              setRules(originalRules);
              setChanges(false);
              onCancel && onCancel();
            }}
            variant="outlined"
          >
            Cancel
          </Button>
        </Grid>
      ) : null}
    </Grid>
  );
};
