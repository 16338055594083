import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { Spinner } from "components/common/Spinner/Spinner";
import { GroupIcon } from "components/common/icons";
import { IChatMemberType } from "generated/sdk.chat";
import { isNil } from "lodash";
import { observer } from "mobx-react";
import { useChatChannelStore } from "modules/Chat/hooks/hooks";
import { FunctionComponent, useState } from "react";
import { ChangeMembersDialog } from "./ChangeMembersDialog/ChangeMembersDialog";

export const ToolbarChangeMembers: FunctionComponent = observer(() => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const chatChannelStore = useChatChannelStore();
  const [isMembersReqLoading, setIsMembersReqLoading] = useState(false);

  const handleSaveMembers = async (memberIds: string[]) => {
    setDialogOpen(false);
    setIsMembersReqLoading(true);

    const memberstoAdd = (chatChannelStore?.otherPossibleMembers ?? []).filter(
      (oPosMember) =>
        memberIds.includes(`${oPosMember.accountId ?? ""}`) &&
        !(chatChannelStore?.otherMembers ?? []).find((oMember) => oMember.accountId === oPosMember.accountId),
    );
    const memberstoRemove = (chatChannelStore?.otherMembers ?? [])
      .filter(({ accountId }) => !memberIds.includes(`${accountId ?? ""}`))
      .map(({ id }) => id)
      .filter((id): id is number => !isNil(id));

    if (memberstoAdd.length) {
      await chatChannelStore?.setAddMembers(memberstoAdd);
    }

    if (memberstoRemove.length) {
      await chatChannelStore?.setRemoveMembers(memberstoRemove);
    }

    setIsMembersReqLoading(false);
  };

  const changeMembersDialogAllMembers = (chatChannelStore?.otherPossibleMembers ?? []).map(
    ({ accountId, name, email, type }) => ({
      id: `${accountId}`,
      name: `${type === IChatMemberType.Admin ? "Admin: " : ""}${name}`,
      label: email,
    }),
  );
  const changeMembersDialogSelectedMembersIds = (chatChannelStore?.otherMembers ?? []).map(
    ({ accountId }) => accountId ?? "",
  );

  return (
    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
      <Tooltip title="View Members">
        <>
          <Typography sx={{ fontSize: "16px", fontWeight: 500, marginRight: "10px" }}>
            <strong>{chatChannelStore?.otherMembers?.length ?? 0}</strong>
          </Typography>
          <IconButton onClick={() => setDialogOpen(true)}>
            {isMembersReqLoading ? <Spinner /> : <GroupIcon color="primary" sx={{ fontSize: "24px" }} />}
          </IconButton>
        </>
      </Tooltip>

      {dialogOpen && (
        <ChangeMembersDialog
          selectedMembersIds={changeMembersDialogSelectedMembersIds}
          allMembers={changeMembersDialogAllMembers}
          onCloseDialog={() => setDialogOpen(false)}
          onSaveMembers={handleSaveMembers}
        />
      )}
    </Box>
  );
});
