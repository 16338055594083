import { useEffect, useState } from "react";

const INTERVAL_TIME = 1000 * 60; // 1min

interface IMessageRealTimeAgoProps {
  getFormattedTime: () => string;
}

export const MessageRealTimeAgo = ({ getFormattedTime }: IMessageRealTimeAgoProps) => {
  const initialTime = getFormattedTime();

  const [formattedTime, setFormattedTime] = useState(initialTime);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setFormattedTime(getFormattedTime());
    }, INTERVAL_TIME);

    return () => {
      clearInterval(intervalId);
    };
  });

  return <>{formattedTime}</>;
};
