import { SvgIcon, SvgIconProps } from "@mui/material";

export const LockedDragIndicator = (props: SvgIconProps) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <g opacity="0.25" clipPath="url(#clip0_15957_12800)">
        <path
          d="M16.5 9H15.75V7.5C15.75 5.43 14.07 3.75 12 3.75C9.93 3.75 8.25 5.43 8.25 7.5V9H7.5C6.675 9 6 9.675 6 10.5V18C6 18.825 6.675 19.5 7.5 19.5H16.5C17.325 19.5 18 18.825 18 18V10.5C18 9.675 17.325 9 16.5 9ZM9.75 7.5C9.75 6.255 10.755 5.25 12 5.25C13.245 5.25 14.25 6.255 14.25 7.5V9H9.75V7.5ZM16.5 18H7.5V10.5H16.5V18ZM12 15.75C12.825 15.75 13.5 15.075 13.5 14.25C13.5 13.425 12.825 12.75 12 12.75C11.175 12.75 10.5 13.425 10.5 14.25C10.5 15.075 11.175 15.75 12 15.75Z"
          fill="#052048"
        />
      </g>
      <defs>
        <clipPath id="clip0_15957_12800">
          <rect width="18" height="18" fill="white" transform="translate(3 3)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
