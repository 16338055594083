import { Box, SxProps, Theme } from "@mui/material";
import { FunctionComponent, ReactNode } from "react";
import { vBillBatchChatPanels, vBillBatchSidebarPanels } from "../SidebarTabs";

interface ISidebarTabPanelProps {
  children: ReactNode;
  panelName: vBillBatchSidebarPanels | vBillBatchChatPanels;
  activePannel: vBillBatchSidebarPanels | vBillBatchChatPanels;
  sx?: SxProps<Theme>;
}

export const SidebarTabPanel: FunctionComponent<ISidebarTabPanelProps> = ({
  children,
  panelName,
  activePannel,
  sx,
}) => (
  <div
    role="tabpanel"
    hidden={activePannel !== panelName}
    id={`batch-details-sidebar-tabpanel-${panelName}`}
    aria-labelledby={`batch-details-sidebar-tab-${panelName}`}
  >
    {activePannel === panelName && <Box sx={{ paddingLeft: "10px", ...sx }}>{children}</Box>}
  </div>
);
