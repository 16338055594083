import { Box, Button, Chip, SxProps, Theme, Tooltip } from "@mui/material";
import { formatCurrency } from "common/helpers/utils";
import { DeleteIcon } from "components/common/icons";
import {
  friendlyBatchStatusNames,
  friendlyIntegrationStatusNames,
  friendlyInvoiceActionRequiredStatusNames,
  friendlyInvoiceQuickFilterStatusNames,
  friendlyPaymentsGroupingStatusNames,
  getVBillFormattedDate,
} from "components/pages/common/VBill/utils";
import {
  IVBillBatchDateUnitType,
  IVBillBatchListFiltersInput,
  IVBillBillsFiltersInput,
  IVBillBillsFiltersOutput,
  IVBillInvoicesFiltersInput,
  IVBillPaymentListFiltersInput,
  IVBillTemporality,
} from "generated/sdk.vbill";
import { observer } from "mobx-react";
import { useMemo } from "react";
import { useStore } from "storeContainer";
import { COLORS } from "themes/default";
import { relatedDatesGetRelativeDate } from "../../../common/VBill/VBillRelativeDatesSelector/VBillRelativeDatesSelector";

type IVBillFilters = Pick<
  IVBillBillsFiltersInput,
  | "search"
  | "integrationStatus" // TODO: better name, "status" name conflict
  | "amount_from"
  | "amount_to"
  | "date"
  | "date_to"
  | "date_due_from"
  | "date_due_to"
  | "mappings"
  | "companyIds"
  | "amount_from"
> &
  Pick<IVBillBatchListFiltersInput, "budget_from" | "budget_to"> &
  Pick<IVBillPaymentListFiltersInput, "paymentStatus"> & { batchStatus?: IVBillBatchListFiltersInput["status"] } & Pick<
    IVBillBillsFiltersOutput,
    "relativeReferenceDate" | "relativeDateDueFrom" | "relativeDateDueTo"
  > &
  Pick<
    IVBillInvoicesFiltersInput,
    | "quickFilterStatusList"
    | "actionRequiredStatusList"
    | "createDateFrom"
    | "createDateTo"
    | "invoiceDateDueFrom"
    | "invoiceDateDueTo"
    | "invoiceDateFrom"
    | "invoiceDateTo"
    | "invoice_number_sort"
    | "invoice_id_sort"
    | "amount_sort"
    | "dates_sort"
  >;

interface IVBillActiveFiltersProps {
  hideClearAllBtn?: boolean;
  hideClearChipBtn?: boolean;
  filters?: IVBillFilters | null;
  onFiltersChange: (values: IVBillFilters) => void;
  showCompanies?: boolean;
  chipSmallHeight?: boolean;
  mappingLabels?: Array<{ key: string; label: string }>;
  sx?: SxProps<Theme>;
}

export const VBillActiveFilters = observer(function ActiveFilters({
  hideClearAllBtn,
  hideClearChipBtn,
  filters,
  onFiltersChange,
  showCompanies,
  chipSmallHeight,
  mappingLabels,
  sx,
}: IVBillActiveFiltersProps) {
  const organizationStore = useStore("OrganizationStore");
  const allCompanies = useMemo(
    () =>
      showCompanies
        ? (organizationStore.organizationVBillEnabledCompanies.data?.items ?? []).map(({ name, companyId }) => ({
            name,
            id: companyId,
          }))
        : [],
    [showCompanies, organizationStore.organizationVBillEnabledCompanies.data?.items],
  );
  const searchChip = useMemo(() => (filters?.search ? `Search: ${filters.search}` : undefined), [filters?.search]);
  const companiesChip = useMemo(
    () =>
      showCompanies && filters?.companyIds?.length
        ? `Companies: ${filters.companyIds
            .map((companyId) => allCompanies.find(({ id }) => id === companyId)?.name)
            .join(", ")}`
        : undefined,
    [allCompanies, filters?.companyIds, showCompanies],
  );
  const invoiceCodingQuickFilterStatusChip = useMemo(
    () =>
      filters?.quickFilterStatusList
        ? `Status: ${filters.quickFilterStatusList
            .map((status) => friendlyInvoiceQuickFilterStatusNames[status])
            .join(", ")}`
        : undefined,
    [filters?.quickFilterStatusList],
  );
  const invoiceCodingActionRequiredStatusChip = useMemo(
    () =>
      filters?.actionRequiredStatusList
        ? `Status: ${filters.actionRequiredStatusList
            .map((status) => friendlyInvoiceActionRequiredStatusNames[status])
            .join(", ")}`
        : undefined,
    [filters?.actionRequiredStatusList],
  );
  const integrationStatusChip = useMemo(
    () =>
      filters?.integrationStatus ? `Status: ${friendlyIntegrationStatusNames[filters.integrationStatus]}` : undefined,
    [filters?.integrationStatus],
  );
  const paymentStatusChip = useMemo(
    () =>
      filters?.paymentStatus?.length
        ? `Status: ${filters.paymentStatus.map((pStatus) => friendlyPaymentsGroupingStatusNames[pStatus]).join(", ")}`
        : undefined,
    [filters?.paymentStatus],
  );
  const batchStatusChip = useMemo(
    () => (filters?.batchStatus ? `Status: ${friendlyBatchStatusNames[filters.batchStatus]}` : undefined),
    [filters?.batchStatus],
  );
  const amountChip = useMemo(
    () =>
      filters?.amount_from?.length || filters?.amount_to?.length
        ? `Amount: ${filters?.amount_from?.length ? formatCurrency(Number(filters.amount_from)) : "-"} to ${
            filters?.amount_to?.length ? formatCurrency(Number(filters.amount_to)) : "-"
          }`
        : undefined,
    [filters?.amount_from, filters?.amount_to],
  );
  const budgetChip = useMemo(
    () =>
      filters?.budget_from?.length || filters?.budget_to?.length
        ? `Budget: ${filters?.budget_from?.length ? formatCurrency(Number(filters.budget_from)) : "-"} to ${
            filters?.budget_to?.length ? formatCurrency(Number(filters.budget_to)) : "-"
          }`
        : undefined,
    [filters?.budget_from, filters?.budget_to],
  );
  const invoiceCreateDateChip = useMemo(
    () =>
      filters?.createDateFrom || filters?.createDateTo
        ? `Create date: ${getVBillFormattedDate(filters.createDateFrom)} to ${getVBillFormattedDate(
            filters.createDateTo,
          )}`
        : undefined,
    [filters?.createDateFrom, filters?.createDateTo],
  );
  const invoiceBillDueChip = useMemo(
    () =>
      filters?.invoiceDateDueFrom || filters?.invoiceDateDueTo
        ? `Bill due: ${getVBillFormattedDate(filters.invoiceDateDueFrom)} to ${getVBillFormattedDate(
            filters.invoiceDateDueTo,
          )}`
        : undefined,
    [filters?.invoiceDateDueFrom, filters?.invoiceDateDueTo],
  );
  const invoiceBillDateChip = useMemo(
    () =>
      filters?.invoiceDateFrom || filters?.invoiceDateTo
        ? `Bill date: ${getVBillFormattedDate(filters.invoiceDateFrom)} to ${getVBillFormattedDate(
            filters.invoiceDateTo,
          )}`
        : undefined,
    [filters?.invoiceDateFrom, filters?.invoiceDateTo],
  );
  const dateChip = useMemo(
    () =>
      filters?.date || filters?.date_to
        ? `Date: ${getVBillFormattedDate(filters.date)} to ${getVBillFormattedDate(filters.date_to)}`
        : undefined,
    [filters?.date, filters?.date_to],
  );
  const dateDueChip = useMemo(
    () =>
      filters?.date_due_from || filters?.date_due_to
        ? `Date due: ${getVBillFormattedDate(filters.date_due_from)} to ${getVBillFormattedDate(filters.date_due_to)}`
        : undefined,
    [filters?.date_due_from, filters?.date_due_to],
  );
  const relativeDatesChip = useMemo(
    () =>
      filters?.relativeReferenceDate && (filters?.relativeDateDueFrom || filters?.relativeDateDueTo)
        ? getRelativeDatesChip({
            relativeReferenceDate: filters.relativeReferenceDate,
            relativeDateDueFrom: filters.relativeDateDueFrom,
            relativeDateDueTo: filters.relativeDateDueTo,
          })
        : undefined,
    [filters?.relativeReferenceDate, filters?.relativeDateDueFrom, filters?.relativeDateDueTo],
  );

  const mappingChips = useMemo(() => filters?.mappings, [filters?.mappings]);
  const hasClearFiltersBtn = useMemo(
    () =>
      !hideClearAllBtn &&
      Boolean(
        searchChip ||
          companiesChip ||
          integrationStatusChip ||
          invoiceCodingQuickFilterStatusChip ||
          invoiceCodingActionRequiredStatusChip ||
          batchStatusChip ||
          paymentStatusChip ||
          amountChip ||
          budgetChip ||
          dateChip ||
          invoiceCreateDateChip ||
          invoiceBillDateChip ||
          invoiceBillDueChip ||
          dateDueChip ||
          relativeDatesChip ||
          mappingChips?.length,
      ),
    [
      amountChip,
      batchStatusChip,
      budgetChip,
      companiesChip,
      dateChip,
      invoiceCreateDateChip,
      invoiceBillDateChip,
      invoiceBillDueChip,
      dateDueChip,
      hideClearAllBtn,
      integrationStatusChip,
      invoiceCodingQuickFilterStatusChip,
      invoiceCodingActionRequiredStatusChip,
      mappingChips?.length,
      paymentStatusChip,
      relativeDatesChip,
      searchChip,
    ],
  );
  const hasClearSortBtn = useMemo(
    () =>
      !!filters?.invoice_number_sort || !!filters?.invoice_id_sort || !!filters?.amount_sort || !!filters?.dates_sort,

    [filters?.amount_sort, filters?.dates_sort, filters?.invoice_id_sort, filters?.invoice_number_sort],
  );

  const chipStyles = useMemo(
    () => ({
      height: chipSmallHeight ? "22px" : "38px",
    }),
    [chipSmallHeight],
  );

  if (
    !searchChip &&
    !companiesChip &&
    !integrationStatusChip &&
    !invoiceCodingQuickFilterStatusChip &&
    !invoiceCodingActionRequiredStatusChip &&
    !batchStatusChip &&
    !paymentStatusChip &&
    !amountChip &&
    !budgetChip &&
    !dateChip &&
    !invoiceCreateDateChip &&
    !invoiceBillDateChip &&
    !invoiceBillDueChip &&
    !dateDueChip &&
    !relativeDatesChip &&
    !mappingChips?.length &&
    !hasClearSortBtn
  ) {
    return null;
  }

  return (
    <Box sx={{ display: "flex", flexWrap: "wrap", gap: "15px", alignItems: "center", ...sx }}>
      {searchChip && (
        <Tooltip arrow title={searchChip}>
          <Chip
            sx={chipStyles}
            variant="outlined"
            label={searchChip}
            onDelete={!hideClearChipBtn ? () => onFiltersChange({ ...filters, search: undefined }) : undefined}
            deleteIcon={<DeleteIcon sx={{ fontSize: "15px !important" }} />}
          />
        </Tooltip>
      )}

      {companiesChip && (
        <Tooltip arrow title={companiesChip}>
          <Chip
            sx={chipStyles}
            variant="outlined"
            label={companiesChip}
            onDelete={!hideClearChipBtn ? () => onFiltersChange({ ...filters, companyIds: undefined }) : undefined}
            deleteIcon={<DeleteIcon sx={{ fontSize: "15px !important" }} />}
          />
        </Tooltip>
      )}

      {integrationStatusChip && (
        <Tooltip arrow title={integrationStatusChip}>
          <Chip
            sx={chipStyles}
            variant="outlined"
            label={integrationStatusChip}
            onDelete={
              !hideClearChipBtn ? () => onFiltersChange({ ...filters, integrationStatus: undefined }) : undefined
            }
            deleteIcon={<DeleteIcon sx={{ fontSize: "15px !important" }} />}
          />
        </Tooltip>
      )}

      {invoiceCodingQuickFilterStatusChip && (
        <Tooltip arrow title={invoiceCodingQuickFilterStatusChip}>
          <Chip
            sx={chipStyles}
            variant="outlined"
            label={invoiceCodingQuickFilterStatusChip}
            onDelete={
              !hideClearChipBtn ? () => onFiltersChange({ ...filters, actionRequiredStatusList: undefined }) : undefined
            }
            deleteIcon={<DeleteIcon sx={{ fontSize: "15px !important" }} />}
          />
        </Tooltip>
      )}

      {invoiceCodingActionRequiredStatusChip && (
        <Tooltip arrow title={invoiceCodingActionRequiredStatusChip}>
          <Chip
            sx={chipStyles}
            variant="outlined"
            label={invoiceCodingActionRequiredStatusChip}
            onDelete={
              !hideClearChipBtn ? () => onFiltersChange({ ...filters, quickFilterStatusList: undefined }) : undefined
            }
            deleteIcon={<DeleteIcon sx={{ fontSize: "15px !important" }} />}
          />
        </Tooltip>
      )}

      {batchStatusChip && (
        <Tooltip arrow title={batchStatusChip}>
          <Chip
            sx={chipStyles}
            variant="outlined"
            label={batchStatusChip}
            onDelete={!hideClearChipBtn ? () => onFiltersChange({ ...filters, batchStatus: undefined }) : undefined}
            deleteIcon={<DeleteIcon sx={{ fontSize: "15px !important" }} />}
          />
        </Tooltip>
      )}

      {paymentStatusChip && (
        <Tooltip arrow title={paymentStatusChip}>
          <Chip
            sx={chipStyles}
            variant="outlined"
            label={paymentStatusChip}
            onDelete={!hideClearChipBtn ? () => onFiltersChange({ ...filters, paymentStatus: undefined }) : undefined}
            deleteIcon={<DeleteIcon sx={{ fontSize: "15px !important" }} />}
          />
        </Tooltip>
      )}

      {amountChip && (
        <Tooltip arrow title={amountChip}>
          <Chip
            sx={chipStyles}
            variant="outlined"
            label={amountChip}
            onDelete={
              !hideClearChipBtn
                ? () => onFiltersChange({ ...filters, amount_from: undefined, amount_to: undefined })
                : undefined
            }
            deleteIcon={<DeleteIcon sx={{ fontSize: "15px !important" }} />}
          />
        </Tooltip>
      )}

      {budgetChip && (
        <Tooltip arrow title={budgetChip}>
          <Chip
            sx={chipStyles}
            variant="outlined"
            label={budgetChip}
            onDelete={
              !hideClearChipBtn
                ? () => onFiltersChange({ ...filters, budget_from: undefined, budget_to: undefined })
                : undefined
            }
            deleteIcon={<DeleteIcon sx={{ fontSize: "15px !important" }} />}
          />
        </Tooltip>
      )}

      {dateChip && (
        <Tooltip arrow title={dateChip}>
          <Chip
            sx={chipStyles}
            variant="outlined"
            label={dateChip}
            onDelete={
              !hideClearChipBtn ? () => onFiltersChange({ ...filters, date: undefined, date_to: undefined }) : undefined
            }
            deleteIcon={<DeleteIcon sx={{ fontSize: "15px !important" }} />}
          />
        </Tooltip>
      )}

      {invoiceCreateDateChip && (
        <Tooltip arrow title={invoiceCreateDateChip}>
          <Chip
            sx={chipStyles}
            variant="outlined"
            label={invoiceCreateDateChip}
            onDelete={
              !hideClearChipBtn
                ? () => onFiltersChange({ ...filters, createDateFrom: undefined, createDateTo: undefined })
                : undefined
            }
            deleteIcon={<DeleteIcon sx={{ fontSize: "15px !important" }} />}
          />
        </Tooltip>
      )}

      {invoiceBillDateChip && (
        <Tooltip arrow title={invoiceBillDateChip}>
          <Chip
            sx={chipStyles}
            variant="outlined"
            label={invoiceBillDateChip}
            onDelete={
              !hideClearChipBtn
                ? () => onFiltersChange({ ...filters, invoiceDateFrom: undefined, invoiceDateTo: undefined })
                : undefined
            }
            deleteIcon={<DeleteIcon sx={{ fontSize: "15px !important" }} />}
          />
        </Tooltip>
      )}

      {invoiceBillDueChip && (
        <Tooltip arrow title={invoiceBillDueChip}>
          <Chip
            sx={chipStyles}
            variant="outlined"
            label={invoiceBillDueChip}
            onDelete={
              !hideClearChipBtn
                ? () => onFiltersChange({ ...filters, invoiceDateDueFrom: undefined, invoiceDateDueTo: undefined })
                : undefined
            }
            deleteIcon={<DeleteIcon sx={{ fontSize: "15px !important" }} />}
          />
        </Tooltip>
      )}

      {dateDueChip && (
        <Tooltip arrow title={dateDueChip}>
          <Chip
            sx={chipStyles}
            variant="outlined"
            label={dateDueChip}
            onDelete={
              !hideClearChipBtn
                ? () => onFiltersChange({ ...filters, date_due_from: undefined, date_due_to: undefined })
                : undefined
            }
            deleteIcon={<DeleteIcon sx={{ fontSize: "15px !important" }} />}
          />
        </Tooltip>
      )}

      {relativeDatesChip && (
        <Tooltip arrow title={relativeDatesChip}>
          <Chip
            sx={chipStyles}
            variant="outlined"
            label={relativeDatesChip}
            onDelete={
              !hideClearChipBtn
                ? () =>
                    onFiltersChange({
                      ...filters,
                      relativeReferenceDate: undefined,
                      relativeDateDueFrom: undefined,
                      relativeDateDueTo: undefined,
                    })
                : undefined
            }
            deleteIcon={<DeleteIcon sx={{ fontSize: "15px !important" }} />}
          />
        </Tooltip>
      )}

      {mappingChips?.map(({ key, valuesIn }) => {
        const label = `${mappingLabels?.find((label) => label.key === key)?.label ?? key}: ${valuesIn.length} selected`;

        return (
          <Tooltip key={key} arrow title={label}>
            <Chip
              sx={chipStyles}
              variant="outlined"
              label={label}
              onDelete={
                !hideClearChipBtn
                  ? () => {
                      const mappings = mappingChips.filter((mapping) => mapping.key !== key);

                      onFiltersChange({ ...filters, mappings: mappings.length ? mappings : undefined });
                    }
                  : undefined
              }
              deleteIcon={<DeleteIcon sx={{ fontSize: "15px !important" }} />}
            />
          </Tooltip>
        );
      })}

      {hasClearFiltersBtn && (
        <Button
          onClick={() =>
            onFiltersChange({
              ...filters,
              search: undefined,
              quickFilterStatusList: undefined,
              actionRequiredStatusList: undefined,
              amount_from: undefined,
              amount_to: undefined,
              createDateFrom: undefined,
              createDateTo: undefined,
              invoiceDateDueFrom: undefined,
              invoiceDateDueTo: undefined,
              invoiceDateFrom: undefined,
              invoiceDateTo: undefined,
            })
          }
          sx={{
            fontSize: "14px",
            height: "26px",
            textDecorationLine: "underline",
            color: COLORS.darkRed,
            ":hover": {
              textDecorationLine: "underline",
            },
          }}
        >
          Clear Filters
        </Button>
      )}

      {hasClearSortBtn && (
        <Button
          onClick={() =>
            onFiltersChange({
              ...filters,
              invoice_number_sort: undefined,
              invoice_id_sort: undefined,
              amount_sort: undefined,
              dates_sort: undefined,
            })
          }
          sx={{
            fontSize: "14px",
            height: "26px",
            textDecorationLine: "underline",
            color: COLORS.darkRed,
            ":hover": {
              textDecorationLine: "underline",
            },
          }}
        >
          Clear Sorting
        </Button>
      )}
    </Box>
  );
});

const getRelativeDatesChip = ({
  relativeReferenceDate,
  relativeDateDueFrom,
  relativeDateDueTo,
}: Pick<IVBillBillsFiltersOutput, "relativeReferenceDate" | "relativeDateDueFrom" | "relativeDateDueTo">) => {
  if (!relativeReferenceDate) {
    return "";
  }

  const newLocalRelativeReferenceDate = new Date(
    relativeReferenceDate?.customDate ? relativeReferenceDate.customDate : new Date() /* today */,
  ).toString();

  let formattedOutputText = "Date due range: ";

  if (relativeDateDueFrom) {
    const value = `${relativeDateDueFrom.value}`;
    const temporality = relativeDateDueFrom.temporality ? relativeDateDueFrom.temporality : IVBillTemporality.Before;
    const newSelectedDateUnitType = relativeDateDueFrom?.unit ? relativeDateDueFrom.unit : IVBillBatchDateUnitType.Day;
    const relativeDateValue = relatedDatesGetRelativeDate(
      newLocalRelativeReferenceDate,
      value,
      temporality,
      newSelectedDateUnitType,
    );

    formattedOutputText += getVBillFormattedDate(relativeDateValue);
  } else {
    formattedOutputText += "-";
  }

  if (relativeDateDueTo) {
    const value = `${relativeDateDueTo.value}`;
    const temporality = relativeDateDueTo.temporality ? relativeDateDueTo.temporality : IVBillTemporality.Before;
    const newSelectedDateUnitType = relativeDateDueTo?.unit ? relativeDateDueTo.unit : IVBillBatchDateUnitType.Day;
    const relativeDateValue = relatedDatesGetRelativeDate(
      newLocalRelativeReferenceDate,
      value,
      temporality,
      newSelectedDateUnitType,
    );

    formattedOutputText += ` to ${getVBillFormattedDate(relativeDateValue)}`;
  } else {
    formattedOutputText += " -";
  }

  return formattedOutputText;
};
