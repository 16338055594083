import { graphqlVBillClient } from "common/graphqlClient";
import {
  getSdk,
  IVBillBatchGroupingType,
  IVBillVBillGetBatchListQuery,
  IVBillVBillGetBatchListQueryVariables,
  IVBillVBillGetBatchListStatsQuery,
  IVBillVBillGetBatchListStatsQueryVariables,
} from "generated/sdk.vbill";
import { createObservableContainer } from "storeContainer";
import { StoreBase } from "./StoreBase";

const { VBillGetBatchList, VBillGetBatchListStats } = getSdk(graphqlVBillClient);

export class VBillBatchLedgerStore extends StoreBase {
  batchLedgerList = createObservableContainer<IVBillVBillGetBatchListQuery["getBatchList"]["data"]>();
  batchStats = createObservableContainer<IVBillVBillGetBatchListStatsQuery["getBatchListStats"]["data"]>();

  loadBatchLedgerList = (data: IVBillVBillGetBatchListQueryVariables, forceUpdate?: boolean) => {
    this.batchLedgerList.cachedLoad(async () => (await VBillGetBatchList(data)).getBatchList.data, [data], {
      forceUpdate,
    });
  };

  loadBatchStats = (data: IVBillVBillGetBatchListStatsQueryVariables, forceUpdate?: boolean) => {
    // this.batchStats.cachedLoad(async () => (await VBillGetBatchListStats({...data, grouping: { ...data.grouping, type: IVBillBatchGroupingType.BatchPaymentsStatus }})).getBatchListStats.data, [data], {
    this.batchStats.cachedLoad(
      async () =>
        (
          await VBillGetBatchListStats({
            ...data,
            grouping: { ...data.grouping, type: IVBillBatchGroupingType.BatchStatus },
          })
        ).getBatchListStats.data,
      [data],
      {
        forceUpdate,
      },
    );
  };
}
