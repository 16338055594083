import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import { ExtendedCheckFiltersType1, useCheckFilters } from "common/hooks/useCheckFilters";
import { LedgerFilterInputType } from "components/Filter/types";
import { CheckSearchOptionsEnumType } from "generated/sdk";
import { SearchIcon } from "icons/svg/SearchIcon";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { clearFiltersBtnStyle } from "./ActiveFilters";

interface FormInputs {
  [key: string]: any;
}

interface SearchOption {
  value: CheckSearchOptionsEnumType;
  label: string;
}

interface AdvancedSearchFilterConfig {
  type: LedgerFilterInputType;
  order: number;
  value: string;
  placeholder: string;
  label: string;
  keyName: string;
  keyNameInput: string;
  keyNameOptions: string;
  keyNameAdditionalInput: string;
  keyNameAdditionalOptions: string;
  tooltip: string;
  searchOptions: SearchOption[];
  additionalSearchOptions: SearchOption[];
}

interface InitialValues {
  [key: string]: any;
}

export const AdvancedSearchInput: React.FC<{
  config: AdvancedSearchFilterConfig;
  initialData: InitialValues;
  handleCloseMenu: () => void;
  handleOnSubmit: (formData: { [key: string]: any }) => void;
  handleClearClick: () => void;
}> = ({ config, initialData, handleCloseMenu, handleOnSubmit, handleClearClick }) => {
  const { handleSubmit, control, setValue, getValues } = useForm<FormInputs>({
    defaultValues: {
      [config?.keyNameInput]: initialData?.[config?.keyNameInput],
      [config?.keyNameAdditionalInput]: initialData?.[config?.keyNameAdditionalInput],
      [config?.keyNameOptions]: config?.searchOptions?.reduce((acc: any, key: any) => {
        acc[key.value] = initialData?.[config?.keyNameOptions]?.includes(key.value);
        return acc;
      }, {}),
      [config?.keyNameAdditionalOptions]: config.additionalSearchOptions?.reduce((acc: any, key: any) => {
        acc[key.value] = initialData?.[config.keyNameAdditionalOptions]?.includes(key.value);
        return acc;
      }, {}),
    },
    shouldUnregister: false,
  });
  const { filters } = useCheckFilters<ExtendedCheckFiltersType1>();
  const [showAdvancedSearchOptions, setShowAdvancedSearchOptions] = useState(
    initialData?.[config?.keyNameInput]?.length > 0 && initialData?.[config?.keyNameAdditionalInput]?.length > 0,
  );
  const onSubmit = (data: FormInputs) => {
    let finalPayload = { ...data };

    finalPayload[config?.keyNameOptions] = Object.keys(data[config?.keyNameOptions])?.filter(
      (item) => data[config?.keyNameOptions]?.[item],
    );

    finalPayload[config?.keyNameAdditionalOptions] = Object.keys(data[config?.keyNameAdditionalOptions])?.filter(
      (item) => data[config?.keyNameAdditionalOptions]?.[item],
    );

    if (data[config?.keyNameAdditionalInput]?.length > 0 && !data[config?.keyNameInput]?.length) {
      finalPayload = {
        [config?.keyNameInput]: data[config?.keyNameAdditionalInput],
        [config?.keyNameOptions]: Object.keys(data[config?.keyNameAdditionalOptions])?.filter(
          (item) => data[config?.keyNameAdditionalOptions]?.[item],
        ),
      };
    }
    if (!finalPayload[config?.keyNameInput] && !filters[config.keyName]) {
      handleCloseMenu();
      return;
    }
    handleOnSubmit(finalPayload);
    handleCloseMenu();
  };
  const handleClear = () => {
    handleClearClick();
    setValue(config.keyNameInput, "");
    config.searchOptions.forEach((option) => {
      setValue(`${config.keyNameOptions}[${option.value}]`, false);
    });
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box display="flex" flexDirection="column">
        <Box padding="2px 10px" display="flex">
          <Box display="flex" flexDirection="column">
            <Controller
              name={config.keyNameInput}
              control={control}
              defaultValue=""
              render={({ field }) => (
                <>
                  <InputLabel variant="standard" htmlFor="search">
                    Search
                  </InputLabel>
                  <TextField
                    {...field}
                    placeholder={"Search term"}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {getValues()?.[config?.keyNameInput]?.length > 0 ? (
                            <IconButton onClick={handleClear} edge="start">
                              <CloseIcon />
                            </IconButton>
                          ) : (
                            <SearchIcon />
                          )}
                        </InputAdornment>
                      ),
                    }}
                    sx={{ width: "291px" }}
                  />
                </>
              )}
            />

            <Box display="flex" flexDirection="column" gap="5px" paddingLeft="10px" paddingY="5px">
              {config.searchOptions.map((option, index) => (
                <FormControlLabel
                  key={`${index}-a`}
                  sx={{ magin: 0 }}
                  control={
                    <Controller
                      name={`${config.keyNameOptions}[${option.value}]`}
                      control={control}
                      defaultValue={false}
                      render={({ field }) => {
                        return <Checkbox {...field} checked={field.value !== undefined ? field.value : false} />;
                      }}
                    />
                  }
                  label={option.label}
                />
              ))}
            </Box>
          </Box>
          <Box display={showAdvancedSearchOptions ? "flex" : "none"} flexDirection="column">
            <Divider orientation="vertical">AND</Divider>
          </Box>

          <Box display={showAdvancedSearchOptions ? "flex" : "none"} flexDirection="column">
            <Controller
              name={config.keyNameAdditionalInput}
              control={control}
              defaultValue=""
              render={({ field }) => (
                <>
                  <InputLabel variant="standard" htmlFor="search">
                    Additional Search
                  </InputLabel>
                  <TextField
                    {...field}
                    placeholder="Search term"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {getValues()?.[config?.keyNameAdditionalInput]?.length > 0 ? (
                            <IconButton
                              onClick={() => {
                                setValue(config?.keyNameAdditionalInput, "");
                                config.additionalSearchOptions.forEach((option) => {
                                  setValue(`${config.keyNameAdditionalOptions}[${option.value}]`, false);
                                });
                              }}
                              edge="start"
                            >
                              <CloseIcon />
                            </IconButton>
                          ) : (
                            <SearchIcon />
                          )}
                        </InputAdornment>
                      ),
                    }}
                    sx={{ width: "291px" }}
                  />
                </>
              )}
            />
            <Box display="flex" flexDirection="column" gap="5px" paddingLeft="10px" paddingY="5px">
              {config.additionalSearchOptions.map((option, index) => (
                <FormControlLabel
                  key={`${index}-b`}
                  sx={{ magin: 0 }}
                  control={
                    <Controller
                      name={`${config.keyNameAdditionalOptions}[${option.value}]`}
                      control={control}
                      defaultValue={false}
                      render={({ field }) => (
                        <Checkbox {...field} checked={field.value !== undefined ? field.value : false} />
                      )}
                    />
                  }
                  label={option.label}
                />
              ))}
            </Box>
          </Box>
        </Box>
        <Divider sx={{ marginBottom: "10px", marginLeft: "-16px" }} />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingX: "10px",
            gap: "5px",
            width: "311px",
          }}
        >
          <Typography
            sx={clearFiltersBtnStyle}
            onClick={() => {
              setShowAdvancedSearchOptions(false);
              handleClear();
            }}
          >
            Clear
          </Typography>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => {
              setShowAdvancedSearchOptions(true);
            }}
            sx={{ visibility: showAdvancedSearchOptions ? "hidden" : "visible" }}
          >
            Extend
          </Button>

          <Button variant="contained" color="primary" type="submit">
            Done
          </Button>
        </Box>
      </Box>
    </form>
  );
};
