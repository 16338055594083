import { Box, Button } from "@mui/material";
import { observer } from "mobx-react";
import { useState } from "react";
import { useParams } from "react-router";
import { useStore } from "storeContainer";
import { DueDateOverBudgetDialog } from "./DueDateOverBudgetDialog";

interface IDueDateOverBudgetButtonProps {
  btnText?: string;
  leftBudgetAmount: number;
}

export const DueDateOverBudgetButton = observer(function DueDateOverBudgetButton({
  btnText = "Due date Overbuget",
  leftBudgetAmount,
}: IDueDateOverBudgetButtonProps) {
  const vBillBatchDetailsStore = useStore("VBillBatchDetailsStore");
  const { batchId, organizationId } = useParams<{ batchId: string; organizationId: string }>();
  const [overBudgetDialogVisible, setOverBudgetDialogVisible] = useState<boolean>(false);

  return (
    <>
      <Box>
        <Button
          sx={{ ":hover": { textDecoration: "underline" }, height: "30px" }}
          onClick={() => setOverBudgetDialogVisible(true)}
        >
          {btnText}
        </Button>
      </Box>

      {overBudgetDialogVisible && (
        <DueDateOverBudgetDialog
          leftBudgetAmount={leftBudgetAmount}
          onCloseDialog={() => setOverBudgetDialogVisible(false)}
        />
      )}
    </>
  );
});
