import { LoadingButton } from "@mui/lab";
import { Box, Button, Divider, Typography } from "@mui/material";
import { Spinner } from "components/common/Spinner/Spinner";
import { useEffect, useState } from "react";
import { FixedSizeList } from "react-window";
import { CheckboxOption, IOption } from "./CheckboxOption/CheckboxOption";
import styles from "./membersCheckboxList.module.scss";
import { SearchField } from "./SearchField/SearchField";

interface IMembersCheckboxListProps {
  onClose: () => void;
  options: IOption[];
  selectedIds: string[];
  onOptionChange: (id: string, value: boolean) => void;
  onSearchTermChange?: (value: string) => void;
  onSave: () => Promise<void>;
  title?: string;
  saveBtnDisabled?: boolean;
  searchPlaceholder: string;
  showSearchSpinner?: boolean;
}

export const MembersCheckboxList = ({
  onClose,
  options,
  selectedIds,
  onOptionChange,
  onSearchTermChange,
  onSave,
  title,
  saveBtnDisabled,
  searchPlaceholder,
  showSearchSpinner,
}: IMembersCheckboxListProps) => {
  const [searchedOptions, setSearchedOptions] = useState<IOption[]>(options);
  const [searchTerm, setSearchTerm] = useState("");
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    setSearchedOptions(options);
  }, [options]);

  useEffect(() => {
    if (searchTerm.length) {
      const newOptions = options.filter(({ name, label }) =>
        `${name.toLowerCase()} ${(label ?? "").toLowerCase()}`.includes(searchTerm),
      );

      setSearchedOptions(newOptions);
    } else {
      setSearchedOptions(options);
    }
  }, [searchTerm, options]);

  const handleSaveBtnClick = async () => {
    setShowLoader(true);
    await onSave();
    setShowLoader(false);
  };

  const handleSetSearchTerm = (value: string) => {
    setSearchTerm(value);
    onSearchTermChange?.(value);
  };

  return (
    <Box sx={{ padding: "10px" }}>
      {title && <Typography sx={{ fontSize: "20px", marginBottom: "20px" }}>{title}</Typography>}

      <SearchField searchTerm={searchTerm} setSearchTerm={handleSetSearchTerm} placeholder={searchPlaceholder} />

      {showSearchSpinner && (
        <Box sx={{ textAlign: "center", paddingTop: "15px" }}>
          <Spinner />
        </Box>
      )}

      <Divider sx={{ margin: "20px 0 10px" }} />

      <Box>
        <FixedSizeList
          height={400}
          itemCount={searchedOptions.length}
          itemSize={48}
          width={300}
          itemData={{
            options: searchedOptions,
            selectedIds,
            searchTerm,
            onOptionChange,
          }}
          className={styles.fixedListContainer}
        >
          {CheckboxOption}
        </FixedSizeList>
      </Box>

      <Divider sx={{ margin: "20px 0" }} />

      <Box sx={{ display: "flex", justifyContent: "end", gap: "15px" }}>
        <LoadingButton
          variant="contained"
          loading={showLoader}
          onClick={handleSaveBtnClick}
          disabled={saveBtnDisabled}
          sx={{ height: "30px" }}
        >
          Save
        </LoadingButton>
        <Button variant="outlined" onClick={onClose} sx={{ height: "30px" }}>
          Cancel
        </Button>
      </Box>
    </Box>
  );
};
