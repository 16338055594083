import { Box, Table, TableContainer } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useParams } from "react-router";
import { useStore } from "storeContainer";
import { transformDatesAsSODUTCIsoString } from "../common/VBill/utils";
import { VBillRouteGuardRedirect } from "../common/VBill/VBillRouteGuardRedirect/VBillRouteGuardRedirect";
import { BatchActiveFilters } from "./BatchActiveFilters/BatchActiveFilters";
import { BatchFooter } from "./BatchFooter/BatchFooter";
import { BatchHeader } from "./BatchHeader/BatchHeader";
import { BatchLedgerList } from "./BatchLedgerList/BatchLedgerList";
import { BatchListFilters } from "./BatchListFilters/BatchListFilters";
import { BatchQuickFilters } from "./BatchQuickFilters/BatchQuickFilters";
import { useBatchLedgerFilters, useVBillBatchLedgerChildRoute } from "./hooks";
import { getVBillBatchLedgerStatusByChildRoute } from "./utils";

export const VBillBatchLedger = observer(function VBillBatchLedger() {
  const { organizationId } = useParams<{ organizationId: string }>();
  const vBillBatchLedgerStore = useStore("VBillBatchLedgerStore");
  const orgUserStore = useStore("OrganizationUsersStore");
  const [batchLedgerFilters] = useBatchLedgerFilters();
  const userSettingsStore = useStore("UserSettingsStore");
  const batchLedgerChildRoute = useVBillBatchLedgerChildRoute();

  useEffect(() => {
    const statusByChildRoute = getVBillBatchLedgerStatusByChildRoute(batchLedgerChildRoute);

    vBillBatchLedgerStore.loadBatchLedgerList({
      filters: {
        organizationId,
        status: statusByChildRoute,
        // companyIds: userSettingsStore.companiesSelectedIds.length ? userSettingsStore.companiesSelectedIds : undefined,
        ...batchLedgerFilters.filters,
        ...transformDatesAsSODUTCIsoString(batchLedgerFilters.filters),
      },
      pagination: batchLedgerFilters.pagination,
    });
  }, [
    vBillBatchLedgerStore,
    organizationId,
    batchLedgerFilters.filters,
    batchLedgerFilters.pagination,
    batchLedgerChildRoute,
  ]);

  useEffect(() => {
    orgUserStore.loadOrganizationUsers(); // TODO:
  }, [orgUserStore]);

  useEffect(() => {
    vBillBatchLedgerStore.loadBatchStats({ filters: { organizationId } });
  }, [organizationId, vBillBatchLedgerStore]);

  useEffect(() => {
    return () => {
      vBillBatchLedgerStore.batchLedgerList.flush();
      vBillBatchLedgerStore.batchStats.flush();
    };
  }, [vBillBatchLedgerStore]);

  return (
    <Box>
      <VBillRouteGuardRedirect route="BATCH_LEDGER" />

      <BatchQuickFilters />

      <BatchListFilters />

      <BatchActiveFilters />

      <TableContainer sx={{ marginBottom: "100px" }}>
        <Table>
          <BatchHeader />

          <BatchLedgerList />
        </Table>
      </TableContainer>

      <BatchFooter />
    </Box>
  );
});
