const headerCells = [
  {
    id: "name",
    sortable: true,
    label: "Name",
  },
  {
    id: "company",
    sortable: false,
    label: "Company",
  },
  {
    id: "budget",
    sortable: false,
    label: "Budget",
  },
  {
    id: "items_checks",
    sortable: false,
    label: "Checks",
  },
  {
    id: "items_members",
    sortable: true,
    label: "Members",
  },
  {
    id: "state",
    sortable: true,
    label: "State",
  },

  {
    id: "created_by",
    sortable: false,
    label: "Created",
  },
  {
    id: "actions",
    sortable: false,
    label: "Actions",
  },
];

export const DEFAULT_PAGINATION_PER_PAGE_FOR_AVAILABLE_CHECKS: number = 50;

export const formatCurrency = (value: number) => {
  return new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(value);
};

export const DEFAULT_PAGINATION_PER_PAGE_FOR_CHECKS_IN_BATCH: number = 50;

export { headerCells };
