import styled from "@emotion/styled";
import { Autocomplete, autocompleteClasses, Dialog, IconButton, TextField, Tooltip, Typography } from "@mui/material";
import { CloseIcon } from "components/common/icons";
import { observer } from "mobx-react-lite";
import { useChatChannelStore } from "modules/Chat/hooks/hooks";
import { FunctionComponent, useMemo } from "react";
import { TMentionSomething, TMentionSomethingType } from "../useChatForm";

interface IAutocompleteOption {
  label: string;
  value: string;
  firstLetter: AutocompleteFirstLetter;
}

enum AutocompleteFirstLetter {
  "Members" = "Members",
  "Related" = "Related",
}

interface IMentionSomethingPickerProps {
  dialogOpen: boolean;
  onDialogClose: () => void;
  onSelectMention: (value: TMentionSomething) => void;
}

export const MentionSomethingPicker: FunctionComponent<IMentionSomethingPickerProps> = observer(
  ({ dialogOpen, onDialogClose, onSelectMention }) => {
    const chatChannelStore = useChatChannelStore();

    const handleAutocompleteChange = (option: IAutocompleteOption) => {
      if (option.firstLetter === AutocompleteFirstLetter.Members) {
        const member = (
          chatChannelStore?.forcePublic
            ? chatChannelStore?.otherPossibleMembers ?? []
            : chatChannelStore?.otherMembers ?? []
        ).find(({ accountId }) => accountId === option.value);

        if (member) {
          onSelectMention({ type: TMentionSomethingType.MEMBER, data: member });
        }
      }

      if (option.firstLetter === AutocompleteFirstLetter.Related) {
        const related = chatChannelStore?.relatedItems?.find(({ related }) => related.key === option.value);

        if (related) {
          onSelectMention({ type: TMentionSomethingType.RELATED, data: related });
        }
      }

      onDialogClose();
    };

    const autocompleteOptions: IAutocompleteOption[] = useMemo(() => {
      const members = (
        chatChannelStore?.forcePublic
          ? chatChannelStore?.otherPossibleMembers ?? []
          : chatChannelStore?.otherMembers ?? []
      ).map(({ name, accountId }) => ({
        label: name,
        value: accountId ?? "",
        firstLetter: AutocompleteFirstLetter.Members,
      }));

      const related = (chatChannelStore?.relatedItems ?? []).map(({ related }) => ({
        label: related.label,
        value: related.key,
        firstLetter: AutocompleteFirstLetter.Related,
      }));

      return [...members, ...related];
    }, [
      chatChannelStore?.forcePublic,
      chatChannelStore?.otherMembers,
      chatChannelStore?.otherPossibleMembers,
      chatChannelStore?.relatedItems,
    ]);

    return (
      <Dialog onClose={onDialogClose} open={dialogOpen} PaperProps={{ sx: { padding: "25px", position: "relative" } }}>
        <Tooltip arrow title="Close">
          <IconButton onClick={onDialogClose} sx={{ position: "absolute", top: "5px", right: "5px", zIndex: 1 }}>
            <CloseIcon sx={{ fontSize: "20px" }} />
          </IconButton>
        </Tooltip>

        <Typography sx={{ fontSize: "20px", marginBottom: "20px" }}>Mention something</Typography>

        <Autocomplete
          id="mentionSomething-autocomplete"
          open
          PopperComponent={StyledPopperComponent}
          options={autocompleteOptions}
          groupBy={(option) => option.firstLetter}
          getOptionLabel={(option) => option.label}
          onChange={(_event, value) => {
            if (value) {
              handleAutocompleteChange(value);
            }
          }}
          sx={{ width: 300 }}
          renderInput={(params) => (
            <TextField
              sx={{ width: "100%" }}
              ref={params.InputProps.ref}
              inputProps={params.inputProps}
              autoFocus
              label="Filter mentions"
            />
          )}
          renderGroup={(params) => (
            <li key={params.key}>
              <Typography sx={{ fontSize: "16px", margin: "10px 0", textDecoration: "underline" }}>
                {params.group}
              </Typography>
              <Typography>{params.children}</Typography>
            </li>
          )}
        />
      </Dialog>
    );
  },
);

const StyledPopperComponent = styled("div")({
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: "none",
    margin: 0,
  },
  [`& .${autocompleteClasses.listbox}`]: {
    padding: 0,
    [`& .${autocompleteClasses.option}`]: {},
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: "relative",
  },
});
