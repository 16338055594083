import { graphqlChatClient } from "common/graphqlClient";
import {
  getSdk,
  IChatChatGetMemberLatestMessagesQuery,
  IChatChatGetMemberNotificationsStatsQuery,
  IChatChatIoMemberChannelNotificationStatsOutput,
} from "generated/sdk.chat";
import { cloneDeep, groupBy, isNil, pick } from "lodash";
import { action, makeObservable, observable, runInAction, toJS } from "mobx";
import { getChatChannelStore, __$$chatChannelStoresCache } from "./chatChannelStoresCache";

const { ChatGetMemberNotificationsStats, ChatGetMemberLatestMessages } = getSdk(graphqlChatClient);

export class ChatMemberStore {
  organizationId?: string;
  memberNotificationsStats?: Pick<
    NonNullable<NonNullable<IChatChatGetMemberNotificationsStatsQuery["getMemberNotificationsStats"]>["data"]>,
    "perChannelCount" | "totalCount"
  >;
  memberLatestMessages?: NonNullable<IChatChatGetMemberLatestMessagesQuery["getMemberLatestMessages"]>["data"];

  private _memberNotificationTimeoutId?: number;
  private _memberNotificationRefetchInterval: number = 5000;

  constructor() {
    makeObservable(this, {
      memberNotificationsStats: observable,
      memberLatestMessages: observable,
      dispatchMemberNotifications: action,
    });
  }

  subscribeToNotifications = async () => {
    this.startMemberNotificationInterval(this._memberNotificationRefetchInterval);
  };

  unSubscribeToNotifications = () => {
    window.clearTimeout(this._memberNotificationTimeoutId);
    this._memberNotificationTimeoutId = undefined;
  };

  startMemberNotificationInterval = async (intervalTime: number) => {
    if (this._memberNotificationTimeoutId) {
      return;
    }

    this._memberNotificationTimeoutId = window.setTimeout(async () => {
      this._memberNotificationTimeoutId = undefined;
      this.startMemberNotificationInterval(this._memberNotificationRefetchInterval);
    }, intervalTime);
    await this._loadGetMemberNotificationsStats();
  };

  private _loadGetMemberNotificationsStats = async () => {
    const resp = await ChatGetMemberNotificationsStats();

    const memberNotificationsStats = resp.getMemberNotificationsStats?.data;

    if (memberNotificationsStats?.perChannelCount.length) {
      this.dispatchMemberNotifications(memberNotificationsStats.perChannelCount);
      this.mightReloadMemberLatestMessages(memberNotificationsStats.perChannelCount);
    }

    this._memberNotificationRefetchInterval = Math.max(5000, memberNotificationsStats?.refetchInterval ?? 5000);
  };

  dispatchMemberNotifications = (perChannelCount: IChatChatIoMemberChannelNotificationStatsOutput[]) => {
    // ignore other organizations
    const perChannelCountInCurrentOrganization = isNil(this.organizationId)
      ? perChannelCount
      : perChannelCount.filter((channel) => channel.parentOrganizationId === this.organizationId);

    // ignore visible channels in viewport
    const perChannelCountHiddenInViewport = perChannelCountInCurrentOrganization.filter((channel) => {
      const chatChannelStore = getChatChannelStore(pick(channel, ["parentContext", "parentKey", "parentType"]));

      return !chatChannelStore?.isChannelVisibleInViewport;
    });
    const totalCountHiddenInViewport = perChannelCountHiddenInViewport.reduce((res, curr) => (res += curr.count), 0);

    this.memberNotificationsStats = {
      totalCount: totalCountHiddenInViewport,
      perChannelCount: perChannelCountHiddenInViewport,
    };

    const channelGroupNotifications = groupBy(perChannelCount, "channelId");

    __$$chatChannelStoresCache.forEach((chatChannelStore) => {
      // send to particular channel
      chatChannelStore?.setChannelNotifications(channelGroupNotifications[chatChannelStore?.channel?.id ?? ""]);
    });
  };

  mightReloadMemberLatestMessages = (perChannelCount: IChatChatIoMemberChannelNotificationStatsOutput[]) => {
    const memberLatestMessagesItems = this.memberLatestMessages?.items ?? [];

    for (const channel of perChannelCount) {
      const channelInMemberLatestMessage = memberLatestMessagesItems.find(
        ({ channelId }) => channelId === channel.channelId,
      );

      // Trigger a reload if we have a message in a channel that is not in the memberLatestMessagesItems || message in memberLatestMessagesItems is not last message
      if (
        !channelInMemberLatestMessage ||
        channelInMemberLatestMessage.channel.lastMessageId !== channel.lastMessageId
      ) {
        // admin app
        if (!this.organizationId) {
          this.loadInitialMemberLatestMessages();
          break;
          // cherry app
        } else {
          if (channel.parentOrganizationId !== this.organizationId) {
            continue;
          }
          this.loadInitialMemberLatestMessages();
          break;
        }
      }
    }
  };

  loadInitialMemberLatestMessages = async () => {
    const resp = await ChatGetMemberLatestMessages({
      filters: { organizationId: this.organizationId },
      pagination: { page: 1, per_page: 100 },
    });

    if (resp.getMemberLatestMessages?.data) {
      runInAction(() => {
        this.memberLatestMessages = resp.getMemberLatestMessages?.data;
      });
    }
  };

  loadMoreMemberLatestMessages = async () => {
    if (!this.memberLatestMessages?.items?.length) {
      return;
    }

    const resp = await ChatGetMemberLatestMessages({
      filters: {
        organizationId: this.organizationId,
        fromMessageId: this.memberLatestMessages.items[this.memberLatestMessages.items.length - 1].id,
      },
      pagination: { page: 1, per_page: 100 },
    });

    runInAction(() => {
      if (resp.getMemberLatestMessages?.data) {
        const { hasMore, total, items } = resp.getMemberLatestMessages.data;
        this.memberLatestMessages = {
          hasMore,
          total: total,
          items: [...(this.memberLatestMessages?.items ?? []), ...items],
        };
      }
    });
  };

  reOrderMemberLatestMessages = (channelId?: number | null) => {
    if (isNil(channelId)) {
      return;
    }

    const sortedMemberLatestMessagesItems = cloneDeep(toJS(this.memberLatestMessages?.items ?? [])).sort((a) =>
      a.channelId === channelId ? -1 : 0,
    );

    runInAction(() => {
      if (this.memberLatestMessages) {
        this.memberLatestMessages = {
          ...this.memberLatestMessages,
          items: sortedMemberLatestMessagesItems,
        };
      }
    });
  };

  setOrganizationId = (organizationId?: string) => {
    this.organizationId = organizationId;
  };
}

export const chatMemberStore = new ChatMemberStore();
