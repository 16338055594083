import React from "react";
import { styled } from "@mui/material";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { a11yProps } from "../../utils";
import { useSelectedOrganizationCompanyId } from "components/pages/Settings/OrganizationCompanySelector/useSelectedOrganizationCompanyId";
import { Link } from "react-router-dom";

interface StyledTabProps {
  label: string;
  to: string;
}
interface StyledTabsProps {
  children?: React.ReactNode;
  value: number;
}

const StyledTabs = styled((props: StyledTabsProps) => (
  <Tabs {...props} TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }} />
))({
  "& .MuiTabs-indicator": {
    height: "1.5px",
  },
});

const StyledTab = styled((props: StyledTabProps) => (
  <Tab
    component={Link}
    // onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    //   event.preventDefault();
    // }}
    disableRipple
    {...props}
  />
))(() => ({
  textTransform: "none",
  fontWeight: 500,
  fontSize: "17px",
}));

type Props = {
  selectedTab: number;
  className?: any;
};

function UsersTabs({ selectedTab, className }: Props) {
  const { organizationId } = useSelectedOrganizationCompanyId();
  // const handleChange = (event: React.SyntheticEvent, newValue: number) => {
  //   onSelectedTab(newValue);
  // };

  return (
    <Box sx={{ width: "100%" }} className={className}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <StyledTabs value={selectedTab}>
          <StyledTab label={"Users & User Groups"} {...a11yProps(0)} to={`/org/${organizationId}/settings/users2`} />
          {/* <StyledTab label={"Permissions"} {...a11yProps(1)} to={`/org/${organizationId}/users2/permissions`} /> */}
        </StyledTabs>
      </Box>
    </Box>
  );
}

export default UsersTabs;
