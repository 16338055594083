import { observer } from "mobx-react";
import { useMemo, useState } from "react";
import { useStore } from "storeContainer";
import { PayVBillsWithCompaniesSettings } from "./PayVBillsDialog/PayVBillsWithCompaniesSettings";
import { PayVBillsDrawer } from "./PayVBillsDrawer/PayVBillsDrawer";

export const PayVBills = observer(function PayVBills() {
  const vBillPayLedgerStore = useStore("VBillPayLedgerStore");
  const [isPayBillsDialogOpen, setIsPayBillsDialogOpen] = useState(false);
  const { selectedBillIdsToPay } = vBillPayLedgerStore;
  const isPayBillsDrawerOpen = useMemo(() => selectedBillIdsToPay.length > 0, [selectedBillIdsToPay.length]);

  return (
    <>
      {isPayBillsDialogOpen && <PayVBillsWithCompaniesSettings onCloseDialog={() => setIsPayBillsDialogOpen(false)} />}

      {isPayBillsDrawerOpen && <PayVBillsDrawer onPayBillsBtnClick={() => setIsPayBillsDialogOpen(true)} />}
    </>
  );
});
