import { Box, Menu, MenuItem } from "@mui/material";
import { chatStaticEmojiList } from "common/static/chatStaticEmojiList";
import { FunctionComponent, useRef } from "react";

interface IReactionsPickerMenuProps {
  menuOpen: boolean;
  onCloseMenu: () => void;
  onMenuItemClick: (name: string) => void;
  menuPosition?: "TOP" | "BOTTOM";
}

export const ReactionsPickerMenu: FunctionComponent<IReactionsPickerMenuProps> = ({
  menuOpen,
  children,
  onCloseMenu,
  onMenuItemClick,
  menuPosition = "TOP",
}) => {
  const menuRef = useRef<HTMLDivElement>(null);

  return (
    <Box
      ref={menuRef}
      id="reactionspicker-menu"
      aria-controls={menuOpen ? "reactionspicker-menu-menu" : undefined}
      aria-haspopup="true"
      aria-expanded={menuOpen ? "true" : undefined}
    >
      <Menu
        id="reactionspicker-menu-menu"
        anchorEl={menuRef.current}
        open={menuOpen}
        onClose={onCloseMenu}
        MenuListProps={{
          "aria-labelledby": "reactionspicker-menu",
        }}
        anchorOrigin={{ vertical: menuPosition === "TOP" ? "top" : "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: menuPosition === "TOP" ? "bottom" : "top", horizontal: "left" }}
      >
        {chatStaticEmojiList.map(({ name, hex }) => (
          <MenuItem key={name} onClick={() => onMenuItemClick(name)}>
            <span role="img" dangerouslySetInnerHTML={{ __html: hex }} />
          </MenuItem>
        ))}
      </Menu>
      {children}
    </Box>
  );
};
