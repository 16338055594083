import { Box, IconButton, Popper } from "@mui/material";
import Modal from "@mui/material/Modal";
import { useCheckFilters } from "common/hooks/useCheckFilters";
import { VCheckChecksAllQuickFiltersEnum } from "components/pages/VChecks/types";
import { CheckFiltersType, LedgerCheck } from "generated/sdk";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useStore } from "storeContainer";
import { MoreHorizIcon } from "../../../../icons/svg/MoreHorizIcon";
import ReviewAndConfirm from "../ReviewAndConfirm";
import styles from "./CheckOptions.module.scss";

export const CheckOptions = ({
  check,
  setDeselected,
  selectedRowIds,
}: {
  check: LedgerCheck;
  setDeselected?: Function;
  selectedRowIds: string[];
}) => {
  const [anchorEl, setAnchorEl] = useState<(EventTarget & HTMLButtonElement) | null>(null);
  const [openReviewAndConfirm, setOpenReviewAndConfirm] = useState<boolean>(false);
  const { filters, pagination } = useCheckFilters<CheckFiltersType>();
  const vChecksStore = useStore("VChecksStore");
  const { organizationId } = useParams<{ organizationId?: string }>();
  const { selectedQuickFilter: selectedQuickFilterParam } = useParams<{
    selectedQuickFilter: VCheckChecksAllQuickFiltersEnum;
  }>();
  const selectedQuickFilter = selectedQuickFilterParam === "all" ? undefined : selectedQuickFilterParam;

  const handlePopoverClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const onClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    setOpenReviewAndConfirm(true);
  };

  const handleCloseReviewAndConfirm = async () => {
    await vChecksStore.loadChecks(organizationId, filters, pagination, true);

    setOpenReviewAndConfirm(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    const handleClick = (event: any) => {
      if (id && !document?.getElementById(id)?.contains(event.target)) {
        setAnchorEl(null);
      }
    };

    if (anchorEl) {
      document.addEventListener("mousedown", handleClick);
    }

    return function cleanup() {
      document.removeEventListener("mousedown", handleClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anchorEl]);

  return (
    <div className={styles.moreContainer}>
      <IconButton onClick={handlePopoverClick}>
        <MoreHorizIcon sx={{ fontSize: "28px", fill: "#052048" }} />
      </IconButton>
      <Popper id={id} open={open} anchorEl={anchorEl}>
        <div className={styles.popover}>
          {check.options ? (
            check.options?.map((option: any, index: number) => (
              <div
                key={index}
                onClick={(e) => {
                  onClick(e);
                  handlePopoverClose();
                }}
              >
                {option.label}
              </div>
            ))
          ) : (
            <div className={styles.noAvailableOptions}>No available options</div>
          )}
        </div>
      </Popper>
      <Modal
        style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
        open={openReviewAndConfirm}
        onClose={handleCloseReviewAndConfirm}
      >
        <Box>
          <ReviewAndConfirm
            checksIds={[`${check.id}-${check.role}`]}
            checks={[check]}
            actionInfo={{ action: check.options?.[0]?.action, label: check.options?.[0]?.label }}
            setDeselected={setDeselected}
            selectedRowIds={selectedRowIds}
            close={handleCloseReviewAndConfirm}
          />
        </Box>
      </Modal>
    </div>
  );
};
