import { graphqlClient } from "common/graphqlClient";
import {
  AssignGroupToCompanyMutationVariables,
  CreateCompanyGroupMutationVariables,
  DeleteCompanyGroupMutationVariables,
  EditCompanyGroupMutationVariables,
  GetCompanyGroupsQuery,
  GetCompanyGroupsQueryVariables,
  getSdk,
} from "generated/sdk";
import { runInAction } from "mobx";
import { createObservableContainer, IDataLoaderOpts } from "storeContainer";

const { GetCompanyGroups, CreateCompanyGroup, EditCompanyGroup, DeleteCompanyGroup, AssignGroupToCompany } =
  getSdk(graphqlClient);

export class CompanyGroupsStore {
  companyGroups = createObservableContainer<GetCompanyGroupsQuery["GetCompanyGroupsQuery"]>();

  getCompanyGroups = (data: GetCompanyGroupsQueryVariables, loaderOpts?: IDataLoaderOpts) => {
    this.companyGroups.cachedLoad(async () => (await GetCompanyGroups(data)).GetCompanyGroupsQuery, [data], loaderOpts);
  };

  createCompanyGroup = async (data: CreateCompanyGroupMutationVariables) => {
    const resp = await CreateCompanyGroup(data);

    if (resp.CreateCompanyGroupMutation) {
      runInAction(() => {
        this.companyGroups._dataObsevable = resp.CreateCompanyGroupMutation;
      });
    }
  };

  editCompanyGroup = async (data: EditCompanyGroupMutationVariables) => {
    const resp = await EditCompanyGroup(data);

    if (resp.EditCompanyGroupMutation) {
      runInAction(() => {
        this.companyGroups._dataObsevable = resp.EditCompanyGroupMutation;
      });
    }
  };

  deleteCompanyGroup = async (data: DeleteCompanyGroupMutationVariables) => {
    const resp = await DeleteCompanyGroup(data);

    if (resp.DeleteCompanyGroupMutation) {
      runInAction(() => {
        this.companyGroups._dataObsevable = resp.DeleteCompanyGroupMutation;
      });
    }
  };

  assignGroupToCompany = async (data: AssignGroupToCompanyMutationVariables) => {
    const resp = await AssignGroupToCompany(data);

    if (resp.AssignGroupToCompanyMutation) {
      runInAction(() => {
        this.companyGroups._dataObsevable = resp.AssignGroupToCompanyMutation;
      });
    }
  };
}
