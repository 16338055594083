import gql from "graphql-tag";
import { GraphQLClient } from "graphql-request";
import { RequestInit } from "graphql-request/dist/types.dom";
import { useMutation, useQuery, UseMutationOptions, UseQueryOptions } from "@tanstack/react-query";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never };

function fetcher<TData, TVariables extends { [key: string]: any }>(
  client: GraphQLClient,
  query: string,
  variables?: TVariables,
  requestHeaders?: RequestInit["headers"],
) {
  return async (): Promise<TData> =>
    client.request({
      document: query,
      variables,
      requestHeaders,
    });
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: { input: any; output: any };
};

export enum IChatChannelParentType {
  Account = "ACCOUNT",
  Vbill = "VBILL",
  VbillBatch = "VBILL_BATCH",
  Vcheck = "VCHECK",
  VcheckBatch = "VCHECK_BATCH",
}

export type IChatChatIoChannelCloseOutput = {
  __typename?: "ChatIOChannelCloseOutput";
  channel: IChatChatIoChannelOutputSchema;
  message?: Maybe<IChatChatIoMessageOutputSchema>;
};

export type IChatChatIoChannelCloseResponseOutput = {
  __typename?: "ChatIOChannelCloseResponseOutput";
  data?: Maybe<IChatChatIoChannelCloseOutput>;
  error?: Maybe<Scalars["String"]["output"]>;
};

export type IChatChatIoChannelInputSchema = {
  parentContext: Scalars["String"]["input"];
  parentKey: Scalars["String"]["input"];
  parentType: IChatChannelParentType;
};

export type IChatChatIoChannelOutputSchema = {
  __typename?: "ChatIOChannelOutputSchema";
  canBeClosed?: Maybe<Scalars["Boolean"]["output"]>;
  exists?: Maybe<Scalars["Boolean"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  isClosed?: Maybe<Scalars["Boolean"]["output"]>;
  isManaged?: Maybe<Scalars["Boolean"]["output"]>;
  isMember?: Maybe<Scalars["Boolean"]["output"]>;
  isPrivate?: Maybe<Scalars["Boolean"]["output"]>;
  lastMessageId?: Maybe<Scalars["Float"]["output"]>;
  name: Scalars["String"]["output"];
  parentContext: Scalars["String"]["output"];
  parentKey: Scalars["String"]["output"];
  parentOrganizationId?: Maybe<Scalars["String"]["output"]>;
  parentType: IChatChannelParentType;
  url: Scalars["String"]["output"];
};

export type IChatChatIoChannelResponseOutput = {
  __typename?: "ChatIOChannelResponseOutput";
  data?: Maybe<IChatChatIoChannelOutputSchema>;
  error?: Maybe<Scalars["String"]["output"]>;
};

export type IChatChatIoCreateChannelInputSchema = {
  isManaged?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPrivate?: InputMaybe<Scalars["Boolean"]["input"]>;
  parentContext: Scalars["String"]["input"];
  parentKey: Scalars["String"]["input"];
  parentOrganizationId?: InputMaybe<Scalars["String"]["input"]>;
  parentType: IChatChannelParentType;
};

export type IChatChatIoLatestMessageOutputSchema = {
  __typename?: "ChatIOLatestMessageOutputSchema";
  accountId?: Maybe<Scalars["String"]["output"]>;
  channel: IChatChatIoChannelOutputSchema;
  channelId?: Maybe<Scalars["Float"]["output"]>;
  content: IChatChatIoMessageContentBlockOutput;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  member: IChatChatIoMemberOutputSchema;
  memberId?: Maybe<Scalars["Float"]["output"]>;
  organizationUserId?: Maybe<Scalars["String"]["output"]>;
  replyToMessage?: Maybe<IChatChatIoMessageOutputSchema>;
  replyToMessageId?: Maybe<Scalars["Float"]["output"]>;
  type: IChatMessageType;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type IChatChatIoLatestMessagesFiltersInput = {
  fromMessageId?: InputMaybe<Scalars["Float"]["input"]>;
  organizationId?: InputMaybe<Scalars["String"]["input"]>;
};

export type IChatChatIoLatestMessagesPaginatedOutput = {
  __typename?: "ChatIOLatestMessagesPaginatedOutput";
  hasMore: Scalars["Boolean"]["output"];
  items: Array<IChatChatIoLatestMessageOutputSchema>;
  total: Scalars["Int"]["output"];
};

export type IChatChatIoLatestMessagesPaginatedResponseOutput = {
  __typename?: "ChatIOLatestMessagesPaginatedResponseOutput";
  data?: Maybe<IChatChatIoLatestMessagesPaginatedOutput>;
  error?: Maybe<Scalars["String"]["output"]>;
};

export type IChatChatIoMemberChannelNotificationStatsOutput = {
  __typename?: "ChatIOMemberChannelNotificationStatsOutput";
  channelId: Scalars["Int"]["output"];
  count: Scalars["Int"]["output"];
  lastMessageId?: Maybe<Scalars["Int"]["output"]>;
  lastSeenMessageId?: Maybe<Scalars["Int"]["output"]>;
  name: Scalars["String"]["output"];
  parentContext: Scalars["String"]["output"];
  parentKey: Scalars["String"]["output"];
  parentOrganizationId: Scalars["String"]["output"];
  parentType: IChatChannelParentType;
  type?: Maybe<IChatMemberNotificationType>;
  unseenMentionIds?: Maybe<Array<Scalars["Int"]["output"]>>;
  unseenReactionIds?: Maybe<Array<Scalars["Int"]["output"]>>;
  url: Scalars["String"]["output"];
};

export type IChatChatIoMemberInputSchema = {
  accountId?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  organizationUserId?: InputMaybe<Scalars["String"]["input"]>;
};

export type IChatChatIoMemberNotificationStatsOutput = {
  __typename?: "ChatIOMemberNotificationStatsOutput";
  perChannelCount: Array<IChatChatIoMemberChannelNotificationStatsOutput>;
  refetchInterval: Scalars["Int"]["output"];
  totalCount: Scalars["Int"]["output"];
};

export type IChatChatIoMemberNotificationsResponseOutput = {
  __typename?: "ChatIOMemberNotificationsResponseOutput";
  data?: Maybe<IChatChatIoMemberNotificationStatsOutput>;
  error?: Maybe<Scalars["String"]["output"]>;
};

export type IChatChatIoMemberOutputSchema = {
  __typename?: "ChatIOMemberOutputSchema";
  accountId?: Maybe<Scalars["String"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  hasUnseenMention?: Maybe<Scalars["Boolean"]["output"]>;
  hasUnseenReaction?: Maybe<Scalars["Boolean"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  lastSeenMessageId?: Maybe<Scalars["Float"]["output"]>;
  name: Scalars["String"]["output"];
  notificationSubscription: IChatNotificationSubscriptionType;
  organizationUserId?: Maybe<Scalars["String"]["output"]>;
  status: IChatMemberStatus;
  type: IChatMemberType;
  unseenMentionIds?: Maybe<Array<Scalars["Int"]["output"]>>;
  unseenReactionIds?: Maybe<Array<Scalars["Int"]["output"]>>;
};

export type IChatChatIoMemberResponseOutput = {
  __typename?: "ChatIOMemberResponseOutput";
  data?: Maybe<IChatChatIoMemberOutputSchema>;
  error?: Maybe<Scalars["String"]["output"]>;
};

export type IChatChatIoMembersAddRemoveOutput = {
  __typename?: "ChatIOMembersAddRemoveOutput";
  members: Array<IChatChatIoMemberOutputSchema>;
  message?: Maybe<IChatChatIoMessageOutputSchema>;
};

export type IChatChatIoMembersAddRemoveResponseOutput = {
  __typename?: "ChatIOMembersAddRemoveResponseOutput";
  data?: Maybe<IChatChatIoMembersAddRemoveOutput>;
  error?: Maybe<Scalars["String"]["output"]>;
};

export type IChatChatIoMembersResponseOutput = {
  __typename?: "ChatIOMembersResponseOutput";
  data?: Maybe<Array<IChatChatIoMemberOutputSchema>>;
  error?: Maybe<Scalars["String"]["output"]>;
};

export type IChatChatIoMessageAndSystemMessageOutputSchemaOutput = {
  __typename?: "ChatIOMessageAndSystemMessageOutputSchemaOutput";
  message: IChatChatIoMessageOutputSchema;
  systemMessage?: Maybe<IChatChatIoMessageOutputSchema>;
};

export type IChatChatIoMessageContentBlockInput = {
  columns?: InputMaybe<Array<IChatChatIoMessageContentBlockTableCellInput>>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  related?: InputMaybe<Array<IChatChatIoMessageContentBlockMessageRelatedInput>>;
  rows?: InputMaybe<Array<IChatChatIoMessageContentBlockTableRowInput>>;
  type: IChatContentBlockType;
};

export type IChatChatIoMessageContentBlockMessageRelatedInput = {
  key: Scalars["String"]["input"];
  related: IChatChatIoMessageRelatedInput;
};

export type IChatChatIoMessageContentBlockMessageRelatedOutput = {
  __typename?: "ChatIOMessageContentBlockMessageRelatedOutput";
  key: Scalars["String"]["output"];
  related: IChatChatIoMessageRelatedOutput;
};

export type IChatChatIoMessageContentBlockOutput = {
  __typename?: "ChatIOMessageContentBlockOutput";
  columns?: Maybe<Array<IChatChatIoMessageContentBlockTableCellOutput>>;
  message?: Maybe<Scalars["String"]["output"]>;
  related?: Maybe<Array<IChatChatIoMessageContentBlockMessageRelatedOutput>>;
  rows?: Maybe<Array<IChatChatIoMessageContentBlockTableRowOutput>>;
  type: IChatContentBlockType;
};

export type IChatChatIoMessageContentBlockTableCellInput = {
  value?: InputMaybe<Scalars["String"]["input"]>;
};

export type IChatChatIoMessageContentBlockTableCellOutput = {
  __typename?: "ChatIOMessageContentBlockTableCellOutput";
  value?: Maybe<Scalars["String"]["output"]>;
};

export type IChatChatIoMessageContentBlockTableRowInput = {
  cells?: InputMaybe<Array<IChatChatIoMessageContentBlockTableCellInput>>;
};

export type IChatChatIoMessageContentBlockTableRowOutput = {
  __typename?: "ChatIOMessageContentBlockTableRowOutput";
  cells?: Maybe<Array<IChatChatIoMessageContentBlockTableCellOutput>>;
};

export type IChatChatIoMessageInputSchema = {
  accountId?: InputMaybe<Scalars["String"]["input"]>;
  channelId?: InputMaybe<Scalars["Float"]["input"]>;
  content: IChatChatIoMessageContentBlockInput;
  memberId?: InputMaybe<Scalars["Float"]["input"]>;
  organizationUserId?: InputMaybe<Scalars["String"]["input"]>;
  replyToMessageId?: InputMaybe<Scalars["Float"]["input"]>;
};

export type IChatChatIoMessageOutputSchema = {
  __typename?: "ChatIOMessageOutputSchema";
  accountId?: Maybe<Scalars["String"]["output"]>;
  channelId?: Maybe<Scalars["Float"]["output"]>;
  content: IChatChatIoMessageContentBlockOutput;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  memberId?: Maybe<Scalars["Float"]["output"]>;
  organizationUserId?: Maybe<Scalars["String"]["output"]>;
  replyToMessage?: Maybe<IChatChatIoMessageOutputSchema>;
  replyToMessageId?: Maybe<Scalars["Float"]["output"]>;
  type: IChatMessageType;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type IChatChatIoMessageRelatedInput = {
  key: Scalars["String"]["input"];
  label: Scalars["String"]["input"];
  type: IChatContentBlockMessageRelatedType;
};

export type IChatChatIoMessageRelatedOutput = {
  __typename?: "ChatIOMessageRelatedOutput";
  key: Scalars["String"]["output"];
  label: Scalars["String"]["output"];
  type: IChatContentBlockMessageRelatedType;
};

export type IChatChatIoMessageResponseOutput = {
  __typename?: "ChatIOMessageResponseOutput";
  data?: Maybe<IChatChatIoMessageAndSystemMessageOutputSchemaOutput>;
  error?: Maybe<Scalars["String"]["output"]>;
};

export type IChatChatIoMessagesFiltersInput = {
  search?: InputMaybe<Scalars["String"]["input"]>;
};

export type IChatChatIoMessagesPaginatedOutput = {
  __typename?: "ChatIOMessagesPaginatedOutput";
  hasMore: Scalars["Boolean"]["output"];
  items: Array<IChatChatIoMessageOutputSchema>;
  total: Scalars["Int"]["output"];
};

export type IChatChatIoMessagesPaginatedResponseOutput = {
  __typename?: "ChatIOMessagesPaginatedResponseOutput";
  data?: Maybe<IChatChatIoMessagesPaginatedOutput>;
  error?: Maybe<Scalars["String"]["output"]>;
};

export type IChatChatIoMessagesPaginationInput = {
  page: Scalars["Float"]["input"];
  per_page?: InputMaybe<Scalars["Float"]["input"]>;
};

export type IChatChatIoMessagesRelativeToMessageFiltersInput = {
  messageId?: InputMaybe<Scalars["Float"]["input"]>;
  type: IChatGetMessagesRelativeToMessageType;
};

export type IChatChatIoMessagesRelativeToMessagePaginationInput = {
  per_page: Scalars["Float"]["input"];
};

export enum IChatContentBlockMessageRelatedType {
  Account = "account",
  Bill = "bill",
  OrgUser = "orgUser",
  Reaction = "reaction",
}

export enum IChatContentBlockType {
  Attachment = "ATTACHMENT",
  Message = "MESSAGE",
  Table = "TABLE",
}

export enum IChatGetMessagesRelativeToMessageType {
  After = "AFTER",
  Around = "AROUND",
  Before = "BEFORE",
}

export enum IChatMemberNotificationType {
  Mention = "MENTION",
  Message = "MESSAGE",
  Reaction = "REACTION",
}

export enum IChatMemberStatus {
  Invited = "INVITED",
  Member = "MEMBER",
  NotInvited = "NOT_INVITED",
  Removed = "REMOVED",
}

export enum IChatMemberType {
  Admin = "ADMIN",
  Client = "CLIENT",
}

export enum IChatMessageType {
  Chat = "CHAT",
  System = "SYSTEM",
}

export type IChatMutation = {
  __typename?: "Mutation";
  acceptInviteToChannel: IChatChatIoMembersAddRemoveResponseOutput;
  addMembers?: Maybe<IChatChatIoMembersAddRemoveResponseOutput>;
  closeChannel: IChatChatIoChannelCloseResponseOutput;
  createChannel: IChatChatIoChannelResponseOutput;
  makePrivateChannel: IChatChatIoChannelResponseOutput;
  reactToMessage: IChatChatIoMessageResponseOutput;
  removeMembers?: Maybe<IChatChatIoMembersAddRemoveResponseOutput>;
  requestInviteToChannel: IChatChatIoMembersAddRemoveResponseOutput;
  sendMessage: IChatChatIoMessageResponseOutput;
  updateLastSeenMessage?: Maybe<IChatChatIoMemberResponseOutput>;
  updateNotificationSubscription?: Maybe<IChatChatIoMemberResponseOutput>;
};

export type IChatMutationAcceptInviteToChannelArgs = {
  channelIO: IChatChatIoChannelInputSchema;
  memberIO: IChatChatIoMemberInputSchema;
};

export type IChatMutationAddMembersArgs = {
  channelIO: IChatChatIoChannelInputSchema;
  membersIO: Array<IChatChatIoMemberInputSchema>;
};

export type IChatMutationCloseChannelArgs = {
  channelIO: IChatChatIoChannelInputSchema;
};

export type IChatMutationCreateChannelArgs = {
  channelIO: IChatChatIoCreateChannelInputSchema;
  membersIO: Array<IChatChatIoMemberInputSchema>;
};

export type IChatMutationMakePrivateChannelArgs = {
  channelIO: IChatChatIoChannelInputSchema;
};

export type IChatMutationReactToMessageArgs = {
  channelIO: IChatChatIoChannelInputSchema;
  messageId: Scalars["Int"]["input"];
  messageRelatedIO: IChatChatIoMessageContentBlockMessageRelatedInput;
};

export type IChatMutationRemoveMembersArgs = {
  channelIO: IChatChatIoChannelInputSchema;
  membersIds: Array<Scalars["Int"]["input"]>;
};

export type IChatMutationRequestInviteToChannelArgs = {
  channelIO: IChatChatIoChannelInputSchema;
};

export type IChatMutationSendMessageArgs = {
  channelIO: IChatChatIoChannelInputSchema;
  messageIO: IChatChatIoMessageInputSchema;
};

export type IChatMutationUpdateLastSeenMessageArgs = {
  channelIO: IChatChatIoChannelInputSchema;
  messageId: Scalars["Int"]["input"];
};

export type IChatMutationUpdateNotificationSubscriptionArgs = {
  channelIO: IChatChatIoChannelInputSchema;
  subscription: IChatNotificationSubscriptionType;
};

export enum IChatNotificationSubscriptionType {
  All = "ALL",
  Mention = "MENTION",
  None = "NONE",
}

export type IChatQuery = {
  __typename?: "Query";
  getChannel?: Maybe<IChatChatIoChannelResponseOutput>;
  getMemberLatestMessages?: Maybe<IChatChatIoLatestMessagesPaginatedResponseOutput>;
  getMemberNotificationsStats?: Maybe<IChatChatIoMemberNotificationsResponseOutput>;
  getMembers?: Maybe<IChatChatIoMembersResponseOutput>;
  getMessages?: Maybe<IChatChatIoMessagesPaginatedResponseOutput>;
  getMessagesRelativeToMessage?: Maybe<IChatChatIoMessagesPaginatedResponseOutput>;
  getPossibleMembers?: Maybe<IChatChatIoMembersResponseOutput>;
};

export type IChatQueryGetChannelArgs = {
  channelIO: IChatChatIoChannelInputSchema;
};

export type IChatQueryGetMemberLatestMessagesArgs = {
  filters: IChatChatIoLatestMessagesFiltersInput;
  pagination: IChatChatIoMessagesPaginationInput;
};

export type IChatQueryGetMembersArgs = {
  channelIO: IChatChatIoChannelInputSchema;
};

export type IChatQueryGetMessagesArgs = {
  channelIO: IChatChatIoChannelInputSchema;
  filters: IChatChatIoMessagesFiltersInput;
  pagination: IChatChatIoMessagesPaginationInput;
};

export type IChatQueryGetMessagesRelativeToMessageArgs = {
  channelIO: IChatChatIoChannelInputSchema;
  filters: IChatChatIoMessagesRelativeToMessageFiltersInput;
  pagination: IChatChatIoMessagesRelativeToMessagePaginationInput;
};

export type IChatQueryGetPossibleMembersArgs = {
  channelIO: IChatChatIoChannelInputSchema;
  orgId?: InputMaybe<Scalars["String"]["input"]>;
};

export type IChatChatAddMembersMutationVariables = Exact<{
  membersIo: Array<IChatChatIoMemberInputSchema> | IChatChatIoMemberInputSchema;
  channelIo: IChatChatIoChannelInputSchema;
}>;

export type IChatChatAddMembersMutation = {
  __typename?: "Mutation";
  addMembers?: {
    __typename?: "ChatIOMembersAddRemoveResponseOutput";
    error?: string | null;
    data?: {
      __typename?: "ChatIOMembersAddRemoveOutput";
      members: Array<{
        __typename?: "ChatIOMemberOutputSchema";
        organizationUserId?: string | null;
        accountId?: string | null;
        name: string;
        email?: string | null;
        id?: number | null;
        type: IChatMemberType;
        status: IChatMemberStatus;
        notificationSubscription: IChatNotificationSubscriptionType;
        lastSeenMessageId?: number | null;
        unseenMentionIds?: Array<number> | null;
        hasUnseenMention?: boolean | null;
        unseenReactionIds?: Array<number> | null;
        hasUnseenReaction?: boolean | null;
      }>;
      message?: {
        __typename?: "ChatIOMessageOutputSchema";
        memberId?: number | null;
        organizationUserId?: string | null;
        accountId?: string | null;
        channelId?: number | null;
        replyToMessageId?: number | null;
        id?: number | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        type: IChatMessageType;
        content: {
          __typename?: "ChatIOMessageContentBlockOutput";
          type: IChatContentBlockType;
          message?: string | null;
          related?: Array<{
            __typename?: "ChatIOMessageContentBlockMessageRelatedOutput";
            key: string;
            related: {
              __typename?: "ChatIOMessageRelatedOutput";
              key: string;
              type: IChatContentBlockMessageRelatedType;
              label: string;
            };
          }> | null;
          columns?: Array<{ __typename?: "ChatIOMessageContentBlockTableCellOutput"; value?: string | null }> | null;
          rows?: Array<{
            __typename?: "ChatIOMessageContentBlockTableRowOutput";
            cells?: Array<{ __typename?: "ChatIOMessageContentBlockTableCellOutput"; value?: string | null }> | null;
          }> | null;
        };
        replyToMessage?: {
          __typename?: "ChatIOMessageOutputSchema";
          memberId?: number | null;
          organizationUserId?: string | null;
          accountId?: string | null;
          channelId?: number | null;
          replyToMessageId?: number | null;
          id?: number | null;
          createdAt?: any | null;
          updatedAt?: any | null;
          type: IChatMessageType;
          content: {
            __typename?: "ChatIOMessageContentBlockOutput";
            type: IChatContentBlockType;
            message?: string | null;
            related?: Array<{
              __typename?: "ChatIOMessageContentBlockMessageRelatedOutput";
              key: string;
              related: {
                __typename?: "ChatIOMessageRelatedOutput";
                key: string;
                type: IChatContentBlockMessageRelatedType;
                label: string;
              };
            }> | null;
            columns?: Array<{ __typename?: "ChatIOMessageContentBlockTableCellOutput"; value?: string | null }> | null;
            rows?: Array<{
              __typename?: "ChatIOMessageContentBlockTableRowOutput";
              cells?: Array<{ __typename?: "ChatIOMessageContentBlockTableCellOutput"; value?: string | null }> | null;
            }> | null;
          };
        } | null;
      } | null;
    } | null;
  } | null;
};

export type IChatChatCloseChannelMutationVariables = Exact<{
  channelIo: IChatChatIoChannelInputSchema;
}>;

export type IChatChatCloseChannelMutation = {
  __typename?: "Mutation";
  closeChannel: {
    __typename?: "ChatIOChannelCloseResponseOutput";
    error?: string | null;
    data?: {
      __typename?: "ChatIOChannelCloseOutput";
      channel: {
        __typename?: "ChatIOChannelOutputSchema";
        parentType: IChatChannelParentType;
        parentKey: string;
        parentContext: string;
        parentOrganizationId?: string | null;
        isPrivate?: boolean | null;
        isManaged?: boolean | null;
        id?: number | null;
        name: string;
        url: string;
        exists?: boolean | null;
        lastMessageId?: number | null;
        isMember?: boolean | null;
        isClosed?: boolean | null;
        canBeClosed?: boolean | null;
      };
      message?: {
        __typename?: "ChatIOMessageOutputSchema";
        memberId?: number | null;
        organizationUserId?: string | null;
        accountId?: string | null;
        channelId?: number | null;
        replyToMessageId?: number | null;
        id?: number | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        type: IChatMessageType;
        content: {
          __typename?: "ChatIOMessageContentBlockOutput";
          type: IChatContentBlockType;
          message?: string | null;
          related?: Array<{
            __typename?: "ChatIOMessageContentBlockMessageRelatedOutput";
            key: string;
            related: {
              __typename?: "ChatIOMessageRelatedOutput";
              key: string;
              type: IChatContentBlockMessageRelatedType;
              label: string;
            };
          }> | null;
          columns?: Array<{ __typename?: "ChatIOMessageContentBlockTableCellOutput"; value?: string | null }> | null;
          rows?: Array<{
            __typename?: "ChatIOMessageContentBlockTableRowOutput";
            cells?: Array<{ __typename?: "ChatIOMessageContentBlockTableCellOutput"; value?: string | null }> | null;
          }> | null;
        };
        replyToMessage?: {
          __typename?: "ChatIOMessageOutputSchema";
          memberId?: number | null;
          organizationUserId?: string | null;
          accountId?: string | null;
          channelId?: number | null;
          replyToMessageId?: number | null;
          id?: number | null;
          createdAt?: any | null;
          updatedAt?: any | null;
          type: IChatMessageType;
          content: {
            __typename?: "ChatIOMessageContentBlockOutput";
            type: IChatContentBlockType;
            message?: string | null;
            related?: Array<{
              __typename?: "ChatIOMessageContentBlockMessageRelatedOutput";
              key: string;
              related: {
                __typename?: "ChatIOMessageRelatedOutput";
                key: string;
                type: IChatContentBlockMessageRelatedType;
                label: string;
              };
            }> | null;
            columns?: Array<{ __typename?: "ChatIOMessageContentBlockTableCellOutput"; value?: string | null }> | null;
            rows?: Array<{
              __typename?: "ChatIOMessageContentBlockTableRowOutput";
              cells?: Array<{ __typename?: "ChatIOMessageContentBlockTableCellOutput"; value?: string | null }> | null;
            }> | null;
          };
        } | null;
      } | null;
    } | null;
  };
};

export type IChatChatCreateChannelMutationVariables = Exact<{
  membersIo: Array<IChatChatIoMemberInputSchema> | IChatChatIoMemberInputSchema;
  channelIo: IChatChatIoCreateChannelInputSchema;
}>;

export type IChatChatCreateChannelMutation = {
  __typename?: "Mutation";
  createChannel: {
    __typename?: "ChatIOChannelResponseOutput";
    error?: string | null;
    data?: {
      __typename?: "ChatIOChannelOutputSchema";
      parentType: IChatChannelParentType;
      parentKey: string;
      parentContext: string;
      parentOrganizationId?: string | null;
      isPrivate?: boolean | null;
      isManaged?: boolean | null;
      id?: number | null;
      name: string;
      url: string;
      exists?: boolean | null;
      lastMessageId?: number | null;
      isMember?: boolean | null;
      isClosed?: boolean | null;
      canBeClosed?: boolean | null;
    } | null;
  };
};

export type IChatChatGetChannelQueryVariables = Exact<{
  channelIo: IChatChatIoChannelInputSchema;
}>;

export type IChatChatGetChannelQuery = {
  __typename?: "Query";
  getChannel?: {
    __typename?: "ChatIOChannelResponseOutput";
    error?: string | null;
    data?: {
      __typename?: "ChatIOChannelOutputSchema";
      parentType: IChatChannelParentType;
      parentKey: string;
      parentContext: string;
      parentOrganizationId?: string | null;
      isPrivate?: boolean | null;
      isManaged?: boolean | null;
      id?: number | null;
      name: string;
      url: string;
      exists?: boolean | null;
      lastMessageId?: number | null;
      isMember?: boolean | null;
      isClosed?: boolean | null;
      canBeClosed?: boolean | null;
    } | null;
  } | null;
};

export type IChatChatGetMemberLatestMessagesQueryVariables = Exact<{
  pagination: IChatChatIoMessagesPaginationInput;
  filters: IChatChatIoLatestMessagesFiltersInput;
}>;

export type IChatChatGetMemberLatestMessagesQuery = {
  __typename?: "Query";
  getMemberLatestMessages?: {
    __typename?: "ChatIOLatestMessagesPaginatedResponseOutput";
    error?: string | null;
    data?: {
      __typename?: "ChatIOLatestMessagesPaginatedOutput";
      total: number;
      hasMore: boolean;
      items: Array<{
        __typename?: "ChatIOLatestMessageOutputSchema";
        memberId?: number | null;
        organizationUserId?: string | null;
        accountId?: string | null;
        channelId?: number | null;
        replyToMessageId?: number | null;
        id?: number | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        type: IChatMessageType;
        content: {
          __typename?: "ChatIOMessageContentBlockOutput";
          type: IChatContentBlockType;
          message?: string | null;
          related?: Array<{
            __typename?: "ChatIOMessageContentBlockMessageRelatedOutput";
            key: string;
            related: {
              __typename?: "ChatIOMessageRelatedOutput";
              key: string;
              type: IChatContentBlockMessageRelatedType;
              label: string;
            };
          }> | null;
          columns?: Array<{ __typename?: "ChatIOMessageContentBlockTableCellOutput"; value?: string | null }> | null;
          rows?: Array<{
            __typename?: "ChatIOMessageContentBlockTableRowOutput";
            cells?: Array<{ __typename?: "ChatIOMessageContentBlockTableCellOutput"; value?: string | null }> | null;
          }> | null;
        };
        replyToMessage?: {
          __typename?: "ChatIOMessageOutputSchema";
          memberId?: number | null;
          organizationUserId?: string | null;
          accountId?: string | null;
          channelId?: number | null;
          replyToMessageId?: number | null;
          id?: number | null;
          createdAt?: any | null;
          updatedAt?: any | null;
          type: IChatMessageType;
        } | null;
        channel: {
          __typename?: "ChatIOChannelOutputSchema";
          parentType: IChatChannelParentType;
          parentKey: string;
          parentContext: string;
          parentOrganizationId?: string | null;
          isPrivate?: boolean | null;
          isManaged?: boolean | null;
          id?: number | null;
          name: string;
          url: string;
          exists?: boolean | null;
          lastMessageId?: number | null;
          isMember?: boolean | null;
          isClosed?: boolean | null;
          canBeClosed?: boolean | null;
        };
        member: {
          __typename?: "ChatIOMemberOutputSchema";
          organizationUserId?: string | null;
          accountId?: string | null;
          name: string;
          email?: string | null;
          id?: number | null;
          type: IChatMemberType;
          status: IChatMemberStatus;
          notificationSubscription: IChatNotificationSubscriptionType;
          lastSeenMessageId?: number | null;
          unseenMentionIds?: Array<number> | null;
          hasUnseenMention?: boolean | null;
          unseenReactionIds?: Array<number> | null;
          hasUnseenReaction?: boolean | null;
        };
      }>;
    } | null;
  } | null;
};

export type IChatChatGetMemberNotificationsStatsQueryVariables = Exact<{ [key: string]: never }>;

export type IChatChatGetMemberNotificationsStatsQuery = {
  __typename?: "Query";
  getMemberNotificationsStats?: {
    __typename?: "ChatIOMemberNotificationsResponseOutput";
    error?: string | null;
    data?: {
      __typename?: "ChatIOMemberNotificationStatsOutput";
      totalCount: number;
      refetchInterval: number;
      perChannelCount: Array<{
        __typename?: "ChatIOMemberChannelNotificationStatsOutput";
        channelId: number;
        parentType: IChatChannelParentType;
        parentKey: string;
        parentContext: string;
        parentOrganizationId: string;
        type?: IChatMemberNotificationType | null;
        name: string;
        url: string;
        count: number;
        lastMessageId?: number | null;
        lastSeenMessageId?: number | null;
        unseenMentionIds?: Array<number> | null;
        unseenReactionIds?: Array<number> | null;
      }>;
    } | null;
  } | null;
};

export type IChatChatGetMembersQueryVariables = Exact<{
  channelIo: IChatChatIoChannelInputSchema;
}>;

export type IChatChatGetMembersQuery = {
  __typename?: "Query";
  getMembers?: {
    __typename?: "ChatIOMembersResponseOutput";
    error?: string | null;
    data?: Array<{
      __typename?: "ChatIOMemberOutputSchema";
      organizationUserId?: string | null;
      accountId?: string | null;
      name: string;
      email?: string | null;
      id?: number | null;
      type: IChatMemberType;
      status: IChatMemberStatus;
      notificationSubscription: IChatNotificationSubscriptionType;
      lastSeenMessageId?: number | null;
      unseenMentionIds?: Array<number> | null;
      hasUnseenMention?: boolean | null;
      unseenReactionIds?: Array<number> | null;
      hasUnseenReaction?: boolean | null;
    }> | null;
  } | null;
};

export type IChatChatGetMessagesQueryVariables = Exact<{
  pagination: IChatChatIoMessagesPaginationInput;
  filters: IChatChatIoMessagesFiltersInput;
  channelIo: IChatChatIoChannelInputSchema;
}>;

export type IChatChatGetMessagesQuery = {
  __typename?: "Query";
  getMessages?: {
    __typename?: "ChatIOMessagesPaginatedResponseOutput";
    error?: string | null;
    data?: {
      __typename?: "ChatIOMessagesPaginatedOutput";
      total: number;
      hasMore: boolean;
      items: Array<{
        __typename?: "ChatIOMessageOutputSchema";
        memberId?: number | null;
        organizationUserId?: string | null;
        accountId?: string | null;
        channelId?: number | null;
        replyToMessageId?: number | null;
        id?: number | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        type: IChatMessageType;
        content: {
          __typename?: "ChatIOMessageContentBlockOutput";
          type: IChatContentBlockType;
          message?: string | null;
          related?: Array<{
            __typename?: "ChatIOMessageContentBlockMessageRelatedOutput";
            key: string;
            related: {
              __typename?: "ChatIOMessageRelatedOutput";
              key: string;
              type: IChatContentBlockMessageRelatedType;
              label: string;
            };
          }> | null;
          columns?: Array<{ __typename?: "ChatIOMessageContentBlockTableCellOutput"; value?: string | null }> | null;
          rows?: Array<{
            __typename?: "ChatIOMessageContentBlockTableRowOutput";
            cells?: Array<{ __typename?: "ChatIOMessageContentBlockTableCellOutput"; value?: string | null }> | null;
          }> | null;
        };
        replyToMessage?: {
          __typename?: "ChatIOMessageOutputSchema";
          memberId?: number | null;
          organizationUserId?: string | null;
          accountId?: string | null;
          channelId?: number | null;
          replyToMessageId?: number | null;
          id?: number | null;
          createdAt?: any | null;
          updatedAt?: any | null;
          type: IChatMessageType;
          content: {
            __typename?: "ChatIOMessageContentBlockOutput";
            type: IChatContentBlockType;
            message?: string | null;
            related?: Array<{
              __typename?: "ChatIOMessageContentBlockMessageRelatedOutput";
              key: string;
              related: {
                __typename?: "ChatIOMessageRelatedOutput";
                key: string;
                type: IChatContentBlockMessageRelatedType;
                label: string;
              };
            }> | null;
            columns?: Array<{ __typename?: "ChatIOMessageContentBlockTableCellOutput"; value?: string | null }> | null;
            rows?: Array<{
              __typename?: "ChatIOMessageContentBlockTableRowOutput";
              cells?: Array<{ __typename?: "ChatIOMessageContentBlockTableCellOutput"; value?: string | null }> | null;
            }> | null;
          };
        } | null;
      }>;
    } | null;
  } | null;
};

export type IChatChatGetMessagesRelativeToMessageQueryVariables = Exact<{
  pagination: IChatChatIoMessagesRelativeToMessagePaginationInput;
  filters: IChatChatIoMessagesRelativeToMessageFiltersInput;
  channelIo: IChatChatIoChannelInputSchema;
}>;

export type IChatChatGetMessagesRelativeToMessageQuery = {
  __typename?: "Query";
  getMessagesRelativeToMessage?: {
    __typename?: "ChatIOMessagesPaginatedResponseOutput";
    error?: string | null;
    data?: {
      __typename?: "ChatIOMessagesPaginatedOutput";
      total: number;
      hasMore: boolean;
      items: Array<{
        __typename?: "ChatIOMessageOutputSchema";
        memberId?: number | null;
        organizationUserId?: string | null;
        accountId?: string | null;
        channelId?: number | null;
        replyToMessageId?: number | null;
        id?: number | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        type: IChatMessageType;
        content: {
          __typename?: "ChatIOMessageContentBlockOutput";
          type: IChatContentBlockType;
          message?: string | null;
          related?: Array<{
            __typename?: "ChatIOMessageContentBlockMessageRelatedOutput";
            key: string;
            related: {
              __typename?: "ChatIOMessageRelatedOutput";
              key: string;
              type: IChatContentBlockMessageRelatedType;
              label: string;
            };
          }> | null;
          columns?: Array<{ __typename?: "ChatIOMessageContentBlockTableCellOutput"; value?: string | null }> | null;
          rows?: Array<{
            __typename?: "ChatIOMessageContentBlockTableRowOutput";
            cells?: Array<{ __typename?: "ChatIOMessageContentBlockTableCellOutput"; value?: string | null }> | null;
          }> | null;
        };
        replyToMessage?: {
          __typename?: "ChatIOMessageOutputSchema";
          memberId?: number | null;
          organizationUserId?: string | null;
          accountId?: string | null;
          channelId?: number | null;
          replyToMessageId?: number | null;
          id?: number | null;
          createdAt?: any | null;
          updatedAt?: any | null;
          type: IChatMessageType;
          content: {
            __typename?: "ChatIOMessageContentBlockOutput";
            type: IChatContentBlockType;
            message?: string | null;
            related?: Array<{
              __typename?: "ChatIOMessageContentBlockMessageRelatedOutput";
              key: string;
              related: {
                __typename?: "ChatIOMessageRelatedOutput";
                key: string;
                type: IChatContentBlockMessageRelatedType;
                label: string;
              };
            }> | null;
            columns?: Array<{ __typename?: "ChatIOMessageContentBlockTableCellOutput"; value?: string | null }> | null;
            rows?: Array<{
              __typename?: "ChatIOMessageContentBlockTableRowOutput";
              cells?: Array<{ __typename?: "ChatIOMessageContentBlockTableCellOutput"; value?: string | null }> | null;
            }> | null;
          };
        } | null;
      }>;
    } | null;
  } | null;
};

export type IChatChatGetPossibleMembersQueryVariables = Exact<{
  channelIo: IChatChatIoChannelInputSchema;
  orgId?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type IChatChatGetPossibleMembersQuery = {
  __typename?: "Query";
  getPossibleMembers?: {
    __typename?: "ChatIOMembersResponseOutput";
    error?: string | null;
    data?: Array<{
      __typename?: "ChatIOMemberOutputSchema";
      organizationUserId?: string | null;
      accountId?: string | null;
      name: string;
      email?: string | null;
      id?: number | null;
      type: IChatMemberType;
      status: IChatMemberStatus;
      notificationSubscription: IChatNotificationSubscriptionType;
      lastSeenMessageId?: number | null;
      unseenMentionIds?: Array<number> | null;
      hasUnseenMention?: boolean | null;
      unseenReactionIds?: Array<number> | null;
      hasUnseenReaction?: boolean | null;
    }> | null;
  } | null;
};

export type IChatChatMakePrivateChannelMutationVariables = Exact<{
  channelIo: IChatChatIoChannelInputSchema;
}>;

export type IChatChatMakePrivateChannelMutation = {
  __typename?: "Mutation";
  makePrivateChannel: {
    __typename?: "ChatIOChannelResponseOutput";
    error?: string | null;
    data?: {
      __typename?: "ChatIOChannelOutputSchema";
      parentType: IChatChannelParentType;
      parentKey: string;
      parentContext: string;
      parentOrganizationId?: string | null;
      isPrivate?: boolean | null;
      isManaged?: boolean | null;
      id?: number | null;
      name: string;
      url: string;
      exists?: boolean | null;
      lastMessageId?: number | null;
      isMember?: boolean | null;
      isClosed?: boolean | null;
      canBeClosed?: boolean | null;
    } | null;
  };
};

export type IChatChatReactToMessageMutationVariables = Exact<{
  messageRelatedIo: IChatChatIoMessageContentBlockMessageRelatedInput;
  messageId: Scalars["Int"]["input"];
  channelIo: IChatChatIoChannelInputSchema;
}>;

export type IChatChatReactToMessageMutation = {
  __typename?: "Mutation";
  reactToMessage: {
    __typename?: "ChatIOMessageResponseOutput";
    error?: string | null;
    data?: {
      __typename?: "ChatIOMessageAndSystemMessageOutputSchemaOutput";
      message: {
        __typename?: "ChatIOMessageOutputSchema";
        memberId?: number | null;
        organizationUserId?: string | null;
        accountId?: string | null;
        channelId?: number | null;
        replyToMessageId?: number | null;
        id?: number | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        type: IChatMessageType;
        content: {
          __typename?: "ChatIOMessageContentBlockOutput";
          type: IChatContentBlockType;
          message?: string | null;
          related?: Array<{
            __typename?: "ChatIOMessageContentBlockMessageRelatedOutput";
            key: string;
            related: {
              __typename?: "ChatIOMessageRelatedOutput";
              key: string;
              type: IChatContentBlockMessageRelatedType;
              label: string;
            };
          }> | null;
          columns?: Array<{ __typename?: "ChatIOMessageContentBlockTableCellOutput"; value?: string | null }> | null;
          rows?: Array<{
            __typename?: "ChatIOMessageContentBlockTableRowOutput";
            cells?: Array<{ __typename?: "ChatIOMessageContentBlockTableCellOutput"; value?: string | null }> | null;
          }> | null;
        };
        replyToMessage?: {
          __typename?: "ChatIOMessageOutputSchema";
          memberId?: number | null;
          organizationUserId?: string | null;
          accountId?: string | null;
          channelId?: number | null;
          replyToMessageId?: number | null;
          id?: number | null;
          createdAt?: any | null;
          updatedAt?: any | null;
          type: IChatMessageType;
        } | null;
      };
    } | null;
  };
};

export type IChatChatRemoveMembersMutationVariables = Exact<{
  membersIds: Array<Scalars["Int"]["input"]> | Scalars["Int"]["input"];
  channelIo: IChatChatIoChannelInputSchema;
}>;

export type IChatChatRemoveMembersMutation = {
  __typename?: "Mutation";
  removeMembers?: {
    __typename?: "ChatIOMembersAddRemoveResponseOutput";
    error?: string | null;
    data?: {
      __typename?: "ChatIOMembersAddRemoveOutput";
      members: Array<{
        __typename?: "ChatIOMemberOutputSchema";
        organizationUserId?: string | null;
        accountId?: string | null;
        name: string;
        email?: string | null;
        id?: number | null;
        type: IChatMemberType;
        status: IChatMemberStatus;
        notificationSubscription: IChatNotificationSubscriptionType;
        lastSeenMessageId?: number | null;
        unseenMentionIds?: Array<number> | null;
        hasUnseenMention?: boolean | null;
        unseenReactionIds?: Array<number> | null;
        hasUnseenReaction?: boolean | null;
      }>;
      message?: {
        __typename?: "ChatIOMessageOutputSchema";
        memberId?: number | null;
        organizationUserId?: string | null;
        accountId?: string | null;
        channelId?: number | null;
        replyToMessageId?: number | null;
        id?: number | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        type: IChatMessageType;
        content: {
          __typename?: "ChatIOMessageContentBlockOutput";
          type: IChatContentBlockType;
          message?: string | null;
          related?: Array<{
            __typename?: "ChatIOMessageContentBlockMessageRelatedOutput";
            key: string;
            related: {
              __typename?: "ChatIOMessageRelatedOutput";
              key: string;
              type: IChatContentBlockMessageRelatedType;
              label: string;
            };
          }> | null;
          columns?: Array<{ __typename?: "ChatIOMessageContentBlockTableCellOutput"; value?: string | null }> | null;
          rows?: Array<{
            __typename?: "ChatIOMessageContentBlockTableRowOutput";
            cells?: Array<{ __typename?: "ChatIOMessageContentBlockTableCellOutput"; value?: string | null }> | null;
          }> | null;
        };
        replyToMessage?: {
          __typename?: "ChatIOMessageOutputSchema";
          memberId?: number | null;
          organizationUserId?: string | null;
          accountId?: string | null;
          channelId?: number | null;
          replyToMessageId?: number | null;
          id?: number | null;
          createdAt?: any | null;
          updatedAt?: any | null;
          type: IChatMessageType;
          content: {
            __typename?: "ChatIOMessageContentBlockOutput";
            type: IChatContentBlockType;
            message?: string | null;
            related?: Array<{
              __typename?: "ChatIOMessageContentBlockMessageRelatedOutput";
              key: string;
              related: {
                __typename?: "ChatIOMessageRelatedOutput";
                key: string;
                type: IChatContentBlockMessageRelatedType;
                label: string;
              };
            }> | null;
            columns?: Array<{ __typename?: "ChatIOMessageContentBlockTableCellOutput"; value?: string | null }> | null;
            rows?: Array<{
              __typename?: "ChatIOMessageContentBlockTableRowOutput";
              cells?: Array<{ __typename?: "ChatIOMessageContentBlockTableCellOutput"; value?: string | null }> | null;
            }> | null;
          };
        } | null;
      } | null;
    } | null;
  } | null;
};

export type IChatChatSendMessageMutationVariables = Exact<{
  messageIo: IChatChatIoMessageInputSchema;
  channelIo: IChatChatIoChannelInputSchema;
}>;

export type IChatChatSendMessageMutation = {
  __typename?: "Mutation";
  sendMessage: {
    __typename?: "ChatIOMessageResponseOutput";
    error?: string | null;
    data?: {
      __typename?: "ChatIOMessageAndSystemMessageOutputSchemaOutput";
      message: {
        __typename?: "ChatIOMessageOutputSchema";
        memberId?: number | null;
        organizationUserId?: string | null;
        accountId?: string | null;
        channelId?: number | null;
        replyToMessageId?: number | null;
        id?: number | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        type: IChatMessageType;
        content: {
          __typename?: "ChatIOMessageContentBlockOutput";
          type: IChatContentBlockType;
          message?: string | null;
          related?: Array<{
            __typename?: "ChatIOMessageContentBlockMessageRelatedOutput";
            key: string;
            related: {
              __typename?: "ChatIOMessageRelatedOutput";
              key: string;
              type: IChatContentBlockMessageRelatedType;
              label: string;
            };
          }> | null;
          columns?: Array<{ __typename?: "ChatIOMessageContentBlockTableCellOutput"; value?: string | null }> | null;
          rows?: Array<{
            __typename?: "ChatIOMessageContentBlockTableRowOutput";
            cells?: Array<{ __typename?: "ChatIOMessageContentBlockTableCellOutput"; value?: string | null }> | null;
          }> | null;
        };
        replyToMessage?: {
          __typename?: "ChatIOMessageOutputSchema";
          memberId?: number | null;
          organizationUserId?: string | null;
          accountId?: string | null;
          channelId?: number | null;
          replyToMessageId?: number | null;
          id?: number | null;
          createdAt?: any | null;
          updatedAt?: any | null;
          type: IChatMessageType;
          content: {
            __typename?: "ChatIOMessageContentBlockOutput";
            type: IChatContentBlockType;
            message?: string | null;
            related?: Array<{
              __typename?: "ChatIOMessageContentBlockMessageRelatedOutput";
              key: string;
              related: {
                __typename?: "ChatIOMessageRelatedOutput";
                key: string;
                type: IChatContentBlockMessageRelatedType;
                label: string;
              };
            }> | null;
            columns?: Array<{ __typename?: "ChatIOMessageContentBlockTableCellOutput"; value?: string | null }> | null;
            rows?: Array<{
              __typename?: "ChatIOMessageContentBlockTableRowOutput";
              cells?: Array<{ __typename?: "ChatIOMessageContentBlockTableCellOutput"; value?: string | null }> | null;
            }> | null;
          };
        } | null;
      };
      systemMessage?: {
        __typename?: "ChatIOMessageOutputSchema";
        memberId?: number | null;
        organizationUserId?: string | null;
        accountId?: string | null;
        channelId?: number | null;
        replyToMessageId?: number | null;
        id?: number | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        type: IChatMessageType;
      } | null;
    } | null;
  };
};

export type IChatChatUpdateLastSeenMessageMutationVariables = Exact<{
  messageId: Scalars["Int"]["input"];
  channelIo: IChatChatIoChannelInputSchema;
}>;

export type IChatChatUpdateLastSeenMessageMutation = {
  __typename?: "Mutation";
  updateLastSeenMessage?: {
    __typename?: "ChatIOMemberResponseOutput";
    error?: string | null;
    data?: {
      __typename?: "ChatIOMemberOutputSchema";
      organizationUserId?: string | null;
      accountId?: string | null;
      name: string;
      email?: string | null;
      id?: number | null;
      type: IChatMemberType;
      status: IChatMemberStatus;
      notificationSubscription: IChatNotificationSubscriptionType;
      lastSeenMessageId?: number | null;
      unseenMentionIds?: Array<number> | null;
      hasUnseenMention?: boolean | null;
      unseenReactionIds?: Array<number> | null;
      hasUnseenReaction?: boolean | null;
    } | null;
  } | null;
};

export type IChatChatUpdateNotificationSubscriptionMutationVariables = Exact<{
  subscription: IChatNotificationSubscriptionType;
  channelIo: IChatChatIoChannelInputSchema;
}>;

export type IChatChatUpdateNotificationSubscriptionMutation = {
  __typename?: "Mutation";
  updateNotificationSubscription?: {
    __typename?: "ChatIOMemberResponseOutput";
    error?: string | null;
    data?: {
      __typename?: "ChatIOMemberOutputSchema";
      organizationUserId?: string | null;
      accountId?: string | null;
      name: string;
      email?: string | null;
      id?: number | null;
      type: IChatMemberType;
      status: IChatMemberStatus;
      notificationSubscription: IChatNotificationSubscriptionType;
      lastSeenMessageId?: number | null;
      unseenMentionIds?: Array<number> | null;
      hasUnseenMention?: boolean | null;
      unseenReactionIds?: Array<number> | null;
      hasUnseenReaction?: boolean | null;
    } | null;
  } | null;
};

export const ChatAddMembers = gql`
  mutation ChatAddMembers($membersIo: [ChatIOMemberInputSchema!]!, $channelIo: ChatIOChannelInputSchema!) {
    addMembers(membersIO: $membersIo, channelIO: $channelIo) {
      data {
        members {
          organizationUserId
          accountId
          name
          email
          id
          type
          status
          notificationSubscription
          lastSeenMessageId
          unseenMentionIds
          hasUnseenMention
          unseenReactionIds
          hasUnseenReaction
        }
        message {
          memberId
          organizationUserId
          accountId
          channelId
          content {
            type
            message
            related {
              key
              related {
                key
                type
                label
              }
            }
            columns {
              value
            }
            rows {
              cells {
                value
              }
            }
          }
          replyToMessageId
          id
          createdAt
          updatedAt
          type
          replyToMessage {
            memberId
            organizationUserId
            accountId
            channelId
            replyToMessageId
            id
            createdAt
            updatedAt
            type
            content {
              type
              message
              related {
                key
                related {
                  key
                  type
                  label
                }
              }
              columns {
                value
              }
              rows {
                cells {
                  value
                }
              }
            }
          }
        }
      }
      error
    }
  }
`;
export const ChatCloseChannel = gql`
  mutation ChatCloseChannel($channelIo: ChatIOChannelInputSchema!) {
    closeChannel(channelIO: $channelIo) {
      data {
        channel {
          parentType
          parentKey
          parentContext
          parentOrganizationId
          isPrivate
          isManaged
          id
          name
          url
          exists
          lastMessageId
          isMember
          isClosed
          canBeClosed
        }
        message {
          memberId
          organizationUserId
          accountId
          channelId
          content {
            type
            message
            related {
              key
              related {
                key
                type
                label
              }
            }
            columns {
              value
            }
            rows {
              cells {
                value
              }
            }
          }
          replyToMessageId
          id
          createdAt
          updatedAt
          type
          replyToMessage {
            memberId
            organizationUserId
            accountId
            channelId
            replyToMessageId
            id
            createdAt
            updatedAt
            type
            content {
              type
              message
              related {
                key
                related {
                  key
                  type
                  label
                }
              }
              columns {
                value
              }
              rows {
                cells {
                  value
                }
              }
            }
          }
        }
      }
      error
    }
  }
`;
export const ChatCreateChannel = gql`
  mutation ChatCreateChannel($membersIo: [ChatIOMemberInputSchema!]!, $channelIo: ChatIOCreateChannelInputSchema!) {
    createChannel(membersIO: $membersIo, channelIO: $channelIo) {
      data {
        parentType
        parentKey
        parentContext
        parentOrganizationId
        isPrivate
        isManaged
        id
        name
        url
        exists
        lastMessageId
        isMember
        isClosed
        canBeClosed
      }
      error
    }
  }
`;
export const ChatGetChannel = gql`
  query ChatGetChannel($channelIo: ChatIOChannelInputSchema!) {
    getChannel(channelIO: $channelIo) {
      data {
        parentType
        parentKey
        parentContext
        parentOrganizationId
        isPrivate
        isManaged
        id
        name
        url
        exists
        lastMessageId
        isMember
        isClosed
        canBeClosed
      }
      error
    }
  }
`;
export const ChatGetMemberLatestMessages = gql`
  query ChatGetMemberLatestMessages(
    $pagination: ChatIOMessagesPaginationInput!
    $filters: ChatIOLatestMessagesFiltersInput!
  ) {
    getMemberLatestMessages(pagination: $pagination, filters: $filters) {
      data {
        items {
          memberId
          organizationUserId
          accountId
          channelId
          content {
            type
            message
            related {
              key
              related {
                key
                type
                label
              }
            }
            columns {
              value
            }
            rows {
              cells {
                value
              }
            }
          }
          replyToMessageId
          id
          createdAt
          updatedAt
          type
          replyToMessage {
            memberId
            organizationUserId
            accountId
            channelId
            replyToMessageId
            id
            createdAt
            updatedAt
            type
          }
          channel {
            parentType
            parentKey
            parentContext
            parentOrganizationId
            isPrivate
            isManaged
            id
            name
            url
            exists
            lastMessageId
            isMember
            isClosed
            canBeClosed
          }
          member {
            organizationUserId
            accountId
            name
            email
            id
            type
            status
            notificationSubscription
            lastSeenMessageId
            unseenMentionIds
            hasUnseenMention
            unseenReactionIds
            hasUnseenReaction
          }
        }
        total
        hasMore
      }
      error
    }
  }
`;
export const ChatGetMemberNotificationsStats = gql`
  query ChatGetMemberNotificationsStats {
    getMemberNotificationsStats {
      data {
        totalCount
        perChannelCount {
          channelId
          parentType
          parentKey
          parentContext
          parentOrganizationId
          type
          name
          url
          count
          lastMessageId
          lastSeenMessageId
          unseenMentionIds
          unseenReactionIds
        }
        refetchInterval
      }
      error
    }
  }
`;
export const ChatGetMembers = gql`
  query ChatGetMembers($channelIo: ChatIOChannelInputSchema!) {
    getMembers(channelIO: $channelIo) {
      data {
        organizationUserId
        accountId
        name
        email
        id
        type
        status
        notificationSubscription
        lastSeenMessageId
        unseenMentionIds
        hasUnseenMention
        unseenReactionIds
        hasUnseenReaction
      }
      error
    }
  }
`;
export const ChatGetMessages = gql`
  query ChatGetMessages(
    $pagination: ChatIOMessagesPaginationInput!
    $filters: ChatIOMessagesFiltersInput!
    $channelIo: ChatIOChannelInputSchema!
  ) {
    getMessages(pagination: $pagination, filters: $filters, channelIO: $channelIo) {
      data {
        items {
          memberId
          organizationUserId
          accountId
          channelId
          content {
            type
            message
            related {
              key
              related {
                key
                type
                label
              }
            }
            columns {
              value
            }
            rows {
              cells {
                value
              }
            }
          }
          replyToMessageId
          id
          createdAt
          updatedAt
          type
          replyToMessage {
            memberId
            organizationUserId
            accountId
            channelId
            replyToMessageId
            id
            createdAt
            updatedAt
            type
            content {
              type
              message
              related {
                key
                related {
                  key
                  type
                  label
                }
              }
              columns {
                value
              }
              rows {
                cells {
                  value
                }
              }
            }
          }
        }
        total
        hasMore
      }
      error
    }
  }
`;
export const ChatGetMessagesRelativeToMessage = gql`
  query ChatGetMessagesRelativeToMessage(
    $pagination: ChatIOMessagesRelativeToMessagePaginationInput!
    $filters: ChatIOMessagesRelativeToMessageFiltersInput!
    $channelIo: ChatIOChannelInputSchema!
  ) {
    getMessagesRelativeToMessage(pagination: $pagination, filters: $filters, channelIO: $channelIo) {
      data {
        items {
          memberId
          organizationUserId
          accountId
          channelId
          content {
            type
            message
            related {
              key
              related {
                key
                type
                label
              }
            }
            columns {
              value
            }
            rows {
              cells {
                value
              }
            }
          }
          replyToMessageId
          id
          createdAt
          updatedAt
          type
          replyToMessage {
            memberId
            organizationUserId
            accountId
            channelId
            replyToMessageId
            id
            createdAt
            updatedAt
            type
            content {
              type
              message
              related {
                key
                related {
                  key
                  type
                  label
                }
              }
              columns {
                value
              }
              rows {
                cells {
                  value
                }
              }
            }
          }
        }
        total
        hasMore
      }
      error
    }
  }
`;
export const ChatGetPossibleMembers = gql`
  query ChatGetPossibleMembers($channelIo: ChatIOChannelInputSchema!, $orgId: String) {
    getPossibleMembers(channelIO: $channelIo, orgId: $orgId) {
      data {
        organizationUserId
        accountId
        name
        email
        id
        type
        status
        notificationSubscription
        lastSeenMessageId
        unseenMentionIds
        hasUnseenMention
        unseenReactionIds
        hasUnseenReaction
      }
      error
    }
  }
`;
export const ChatMakePrivateChannel = gql`
  mutation ChatMakePrivateChannel($channelIo: ChatIOChannelInputSchema!) {
    makePrivateChannel(channelIO: $channelIo) {
      data {
        parentType
        parentKey
        parentContext
        parentOrganizationId
        isPrivate
        isManaged
        id
        name
        url
        exists
        lastMessageId
        isMember
        isClosed
        canBeClosed
      }
      error
    }
  }
`;
export const ChatReactToMessage = gql`
  mutation ChatReactToMessage(
    $messageRelatedIo: ChatIOMessageContentBlockMessageRelatedInput!
    $messageId: Int!
    $channelIo: ChatIOChannelInputSchema!
  ) {
    reactToMessage(messageRelatedIO: $messageRelatedIo, messageId: $messageId, channelIO: $channelIo) {
      data {
        message {
          memberId
          organizationUserId
          accountId
          channelId
          content {
            type
            message
            related {
              key
              related {
                key
                type
                label
              }
            }
            columns {
              value
            }
            rows {
              cells {
                value
              }
            }
          }
          replyToMessageId
          id
          createdAt
          updatedAt
          type
          replyToMessage {
            memberId
            organizationUserId
            accountId
            channelId
            replyToMessageId
            id
            createdAt
            updatedAt
            type
          }
        }
      }
      error
    }
  }
`;
export const ChatRemoveMembers = gql`
  mutation ChatRemoveMembers($membersIds: [Int!]!, $channelIo: ChatIOChannelInputSchema!) {
    removeMembers(membersIds: $membersIds, channelIO: $channelIo) {
      data {
        members {
          organizationUserId
          accountId
          name
          email
          id
          type
          status
          notificationSubscription
          lastSeenMessageId
          unseenMentionIds
          hasUnseenMention
          unseenReactionIds
          hasUnseenReaction
        }
        message {
          memberId
          organizationUserId
          accountId
          channelId
          content {
            type
            message
            related {
              key
              related {
                key
                type
                label
              }
            }
            columns {
              value
            }
            rows {
              cells {
                value
              }
            }
          }
          replyToMessageId
          id
          createdAt
          updatedAt
          type
          replyToMessage {
            memberId
            organizationUserId
            accountId
            channelId
            replyToMessageId
            id
            createdAt
            updatedAt
            type
            content {
              type
              message
              related {
                key
                related {
                  key
                  type
                  label
                }
              }
              columns {
                value
              }
              rows {
                cells {
                  value
                }
              }
            }
          }
        }
      }
      error
    }
  }
`;
export const ChatSendMessage = gql`
  mutation ChatSendMessage($messageIo: ChatIOMessageInputSchema!, $channelIo: ChatIOChannelInputSchema!) {
    sendMessage(messageIO: $messageIo, channelIO: $channelIo) {
      data {
        message {
          memberId
          organizationUserId
          accountId
          channelId
          content {
            type
            message
            related {
              key
              related {
                key
                type
                label
              }
            }
            columns {
              value
            }
            rows {
              cells {
                value
              }
            }
          }
          replyToMessageId
          id
          createdAt
          updatedAt
          type
          replyToMessage {
            memberId
            organizationUserId
            accountId
            channelId
            replyToMessageId
            id
            createdAt
            updatedAt
            type
            content {
              type
              message
              related {
                key
                related {
                  key
                  type
                  label
                }
              }
              columns {
                value
              }
              rows {
                cells {
                  value
                }
              }
            }
          }
        }
        systemMessage {
          memberId
          organizationUserId
          accountId
          channelId
          replyToMessageId
          id
          createdAt
          updatedAt
          type
        }
      }
      error
    }
  }
`;
export const ChatUpdateLastSeenMessage = gql`
  mutation ChatUpdateLastSeenMessage($messageId: Int!, $channelIo: ChatIOChannelInputSchema!) {
    updateLastSeenMessage(messageId: $messageId, channelIO: $channelIo) {
      data {
        organizationUserId
        accountId
        name
        email
        id
        type
        status
        notificationSubscription
        lastSeenMessageId
        unseenMentionIds
        hasUnseenMention
        unseenReactionIds
        hasUnseenReaction
      }
      error
    }
  }
`;
export const ChatUpdateNotificationSubscription = gql`
  mutation ChatUpdateNotificationSubscription(
    $subscription: NotificationSubscriptionType!
    $channelIo: ChatIOChannelInputSchema!
  ) {
    updateNotificationSubscription(subscription: $subscription, channelIO: $channelIo) {
      data {
        organizationUserId
        accountId
        name
        email
        id
        type
        status
        notificationSubscription
        lastSeenMessageId
        unseenMentionIds
        hasUnseenMention
        unseenReactionIds
        hasUnseenReaction
      }
      error
    }
  }
`;

export const ChatAddMembersDocument = `
    mutation ChatAddMembers($membersIo: [ChatIOMemberInputSchema!]!, $channelIo: ChatIOChannelInputSchema!) {
  addMembers(membersIO: $membersIo, channelIO: $channelIo) {
    data {
      members {
        organizationUserId
        accountId
        name
        email
        id
        type
        status
        notificationSubscription
        lastSeenMessageId
        unseenMentionIds
        hasUnseenMention
        unseenReactionIds
        hasUnseenReaction
      }
      message {
        memberId
        organizationUserId
        accountId
        channelId
        content {
          type
          message
          related {
            key
            related {
              key
              type
              label
            }
          }
          columns {
            value
          }
          rows {
            cells {
              value
            }
          }
        }
        replyToMessageId
        id
        createdAt
        updatedAt
        type
        replyToMessage {
          memberId
          organizationUserId
          accountId
          channelId
          replyToMessageId
          id
          createdAt
          updatedAt
          type
          content {
            type
            message
            related {
              key
              related {
                key
                type
                label
              }
            }
            columns {
              value
            }
            rows {
              cells {
                value
              }
            }
          }
        }
      }
    }
    error
  }
}
    `;

export const useChatAddMembersMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<IChatChatAddMembersMutation, TError, IChatChatAddMembersMutationVariables, TContext>,
  headers?: RequestInit["headers"],
) => {
  return useMutation<IChatChatAddMembersMutation, TError, IChatChatAddMembersMutationVariables, TContext>(
    ["ChatAddMembers"],
    (variables?: IChatChatAddMembersMutationVariables) =>
      fetcher<IChatChatAddMembersMutation, IChatChatAddMembersMutationVariables>(
        client,
        ChatAddMembersDocument,
        variables,
        headers,
      )(),
    options,
  );
};

export const ChatCloseChannelDocument = `
    mutation ChatCloseChannel($channelIo: ChatIOChannelInputSchema!) {
  closeChannel(channelIO: $channelIo) {
    data {
      channel {
        parentType
        parentKey
        parentContext
        parentOrganizationId
        isPrivate
        isManaged
        id
        name
        url
        exists
        lastMessageId
        isMember
        isClosed
        canBeClosed
      }
      message {
        memberId
        organizationUserId
        accountId
        channelId
        content {
          type
          message
          related {
            key
            related {
              key
              type
              label
            }
          }
          columns {
            value
          }
          rows {
            cells {
              value
            }
          }
        }
        replyToMessageId
        id
        createdAt
        updatedAt
        type
        replyToMessage {
          memberId
          organizationUserId
          accountId
          channelId
          replyToMessageId
          id
          createdAt
          updatedAt
          type
          content {
            type
            message
            related {
              key
              related {
                key
                type
                label
              }
            }
            columns {
              value
            }
            rows {
              cells {
                value
              }
            }
          }
        }
      }
    }
    error
  }
}
    `;

export const useChatCloseChannelMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<IChatChatCloseChannelMutation, TError, IChatChatCloseChannelMutationVariables, TContext>,
  headers?: RequestInit["headers"],
) => {
  return useMutation<IChatChatCloseChannelMutation, TError, IChatChatCloseChannelMutationVariables, TContext>(
    ["ChatCloseChannel"],
    (variables?: IChatChatCloseChannelMutationVariables) =>
      fetcher<IChatChatCloseChannelMutation, IChatChatCloseChannelMutationVariables>(
        client,
        ChatCloseChannelDocument,
        variables,
        headers,
      )(),
    options,
  );
};

export const ChatCreateChannelDocument = `
    mutation ChatCreateChannel($membersIo: [ChatIOMemberInputSchema!]!, $channelIo: ChatIOCreateChannelInputSchema!) {
  createChannel(membersIO: $membersIo, channelIO: $channelIo) {
    data {
      parentType
      parentKey
      parentContext
      parentOrganizationId
      isPrivate
      isManaged
      id
      name
      url
      exists
      lastMessageId
      isMember
      isClosed
      canBeClosed
    }
    error
  }
}
    `;

export const useChatCreateChannelMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    IChatChatCreateChannelMutation,
    TError,
    IChatChatCreateChannelMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<IChatChatCreateChannelMutation, TError, IChatChatCreateChannelMutationVariables, TContext>(
    ["ChatCreateChannel"],
    (variables?: IChatChatCreateChannelMutationVariables) =>
      fetcher<IChatChatCreateChannelMutation, IChatChatCreateChannelMutationVariables>(
        client,
        ChatCreateChannelDocument,
        variables,
        headers,
      )(),
    options,
  );
};

export const ChatGetChannelDocument = `
    query ChatGetChannel($channelIo: ChatIOChannelInputSchema!) {
  getChannel(channelIO: $channelIo) {
    data {
      parentType
      parentKey
      parentContext
      parentOrganizationId
      isPrivate
      isManaged
      id
      name
      url
      exists
      lastMessageId
      isMember
      isClosed
      canBeClosed
    }
    error
  }
}
    `;

export const useChatGetChannelQuery = <TData = IChatChatGetChannelQuery, TError = unknown>(
  client: GraphQLClient,
  variables: IChatChatGetChannelQueryVariables,
  options?: UseQueryOptions<IChatChatGetChannelQuery, TError, TData>,
  headers?: RequestInit["headers"],
) => {
  return useQuery<IChatChatGetChannelQuery, TError, TData>(
    ["ChatGetChannel", variables],
    fetcher<IChatChatGetChannelQuery, IChatChatGetChannelQueryVariables>(
      client,
      ChatGetChannelDocument,
      variables,
      headers,
    ),
    options,
  );
};

export const ChatGetMemberLatestMessagesDocument = `
    query ChatGetMemberLatestMessages($pagination: ChatIOMessagesPaginationInput!, $filters: ChatIOLatestMessagesFiltersInput!) {
  getMemberLatestMessages(pagination: $pagination, filters: $filters) {
    data {
      items {
        memberId
        organizationUserId
        accountId
        channelId
        content {
          type
          message
          related {
            key
            related {
              key
              type
              label
            }
          }
          columns {
            value
          }
          rows {
            cells {
              value
            }
          }
        }
        replyToMessageId
        id
        createdAt
        updatedAt
        type
        replyToMessage {
          memberId
          organizationUserId
          accountId
          channelId
          replyToMessageId
          id
          createdAt
          updatedAt
          type
        }
        channel {
          parentType
          parentKey
          parentContext
          parentOrganizationId
          isPrivate
          isManaged
          id
          name
          url
          exists
          lastMessageId
          isMember
          isClosed
          canBeClosed
        }
        member {
          organizationUserId
          accountId
          name
          email
          id
          type
          status
          notificationSubscription
          lastSeenMessageId
          unseenMentionIds
          hasUnseenMention
          unseenReactionIds
          hasUnseenReaction
        }
      }
      total
      hasMore
    }
    error
  }
}
    `;

export const useChatGetMemberLatestMessagesQuery = <TData = IChatChatGetMemberLatestMessagesQuery, TError = unknown>(
  client: GraphQLClient,
  variables: IChatChatGetMemberLatestMessagesQueryVariables,
  options?: UseQueryOptions<IChatChatGetMemberLatestMessagesQuery, TError, TData>,
  headers?: RequestInit["headers"],
) => {
  return useQuery<IChatChatGetMemberLatestMessagesQuery, TError, TData>(
    ["ChatGetMemberLatestMessages", variables],
    fetcher<IChatChatGetMemberLatestMessagesQuery, IChatChatGetMemberLatestMessagesQueryVariables>(
      client,
      ChatGetMemberLatestMessagesDocument,
      variables,
      headers,
    ),
    options,
  );
};

export const ChatGetMemberNotificationsStatsDocument = `
    query ChatGetMemberNotificationsStats {
  getMemberNotificationsStats {
    data {
      totalCount
      perChannelCount {
        channelId
        parentType
        parentKey
        parentContext
        parentOrganizationId
        type
        name
        url
        count
        lastMessageId
        lastSeenMessageId
        unseenMentionIds
        unseenReactionIds
      }
      refetchInterval
    }
    error
  }
}
    `;

export const useChatGetMemberNotificationsStatsQuery = <
  TData = IChatChatGetMemberNotificationsStatsQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: IChatChatGetMemberNotificationsStatsQueryVariables,
  options?: UseQueryOptions<IChatChatGetMemberNotificationsStatsQuery, TError, TData>,
  headers?: RequestInit["headers"],
) => {
  return useQuery<IChatChatGetMemberNotificationsStatsQuery, TError, TData>(
    variables === undefined ? ["ChatGetMemberNotificationsStats"] : ["ChatGetMemberNotificationsStats", variables],
    fetcher<IChatChatGetMemberNotificationsStatsQuery, IChatChatGetMemberNotificationsStatsQueryVariables>(
      client,
      ChatGetMemberNotificationsStatsDocument,
      variables,
      headers,
    ),
    options,
  );
};

export const ChatGetMembersDocument = `
    query ChatGetMembers($channelIo: ChatIOChannelInputSchema!) {
  getMembers(channelIO: $channelIo) {
    data {
      organizationUserId
      accountId
      name
      email
      id
      type
      status
      notificationSubscription
      lastSeenMessageId
      unseenMentionIds
      hasUnseenMention
      unseenReactionIds
      hasUnseenReaction
    }
    error
  }
}
    `;

export const useChatGetMembersQuery = <TData = IChatChatGetMembersQuery, TError = unknown>(
  client: GraphQLClient,
  variables: IChatChatGetMembersQueryVariables,
  options?: UseQueryOptions<IChatChatGetMembersQuery, TError, TData>,
  headers?: RequestInit["headers"],
) => {
  return useQuery<IChatChatGetMembersQuery, TError, TData>(
    ["ChatGetMembers", variables],
    fetcher<IChatChatGetMembersQuery, IChatChatGetMembersQueryVariables>(
      client,
      ChatGetMembersDocument,
      variables,
      headers,
    ),
    options,
  );
};

export const ChatGetMessagesDocument = `
    query ChatGetMessages($pagination: ChatIOMessagesPaginationInput!, $filters: ChatIOMessagesFiltersInput!, $channelIo: ChatIOChannelInputSchema!) {
  getMessages(pagination: $pagination, filters: $filters, channelIO: $channelIo) {
    data {
      items {
        memberId
        organizationUserId
        accountId
        channelId
        content {
          type
          message
          related {
            key
            related {
              key
              type
              label
            }
          }
          columns {
            value
          }
          rows {
            cells {
              value
            }
          }
        }
        replyToMessageId
        id
        createdAt
        updatedAt
        type
        replyToMessage {
          memberId
          organizationUserId
          accountId
          channelId
          replyToMessageId
          id
          createdAt
          updatedAt
          type
          content {
            type
            message
            related {
              key
              related {
                key
                type
                label
              }
            }
            columns {
              value
            }
            rows {
              cells {
                value
              }
            }
          }
        }
      }
      total
      hasMore
    }
    error
  }
}
    `;

export const useChatGetMessagesQuery = <TData = IChatChatGetMessagesQuery, TError = unknown>(
  client: GraphQLClient,
  variables: IChatChatGetMessagesQueryVariables,
  options?: UseQueryOptions<IChatChatGetMessagesQuery, TError, TData>,
  headers?: RequestInit["headers"],
) => {
  return useQuery<IChatChatGetMessagesQuery, TError, TData>(
    ["ChatGetMessages", variables],
    fetcher<IChatChatGetMessagesQuery, IChatChatGetMessagesQueryVariables>(
      client,
      ChatGetMessagesDocument,
      variables,
      headers,
    ),
    options,
  );
};

export const ChatGetMessagesRelativeToMessageDocument = `
    query ChatGetMessagesRelativeToMessage($pagination: ChatIOMessagesRelativeToMessagePaginationInput!, $filters: ChatIOMessagesRelativeToMessageFiltersInput!, $channelIo: ChatIOChannelInputSchema!) {
  getMessagesRelativeToMessage(
    pagination: $pagination
    filters: $filters
    channelIO: $channelIo
  ) {
    data {
      items {
        memberId
        organizationUserId
        accountId
        channelId
        content {
          type
          message
          related {
            key
            related {
              key
              type
              label
            }
          }
          columns {
            value
          }
          rows {
            cells {
              value
            }
          }
        }
        replyToMessageId
        id
        createdAt
        updatedAt
        type
        replyToMessage {
          memberId
          organizationUserId
          accountId
          channelId
          replyToMessageId
          id
          createdAt
          updatedAt
          type
          content {
            type
            message
            related {
              key
              related {
                key
                type
                label
              }
            }
            columns {
              value
            }
            rows {
              cells {
                value
              }
            }
          }
        }
      }
      total
      hasMore
    }
    error
  }
}
    `;

export const useChatGetMessagesRelativeToMessageQuery = <
  TData = IChatChatGetMessagesRelativeToMessageQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables: IChatChatGetMessagesRelativeToMessageQueryVariables,
  options?: UseQueryOptions<IChatChatGetMessagesRelativeToMessageQuery, TError, TData>,
  headers?: RequestInit["headers"],
) => {
  return useQuery<IChatChatGetMessagesRelativeToMessageQuery, TError, TData>(
    ["ChatGetMessagesRelativeToMessage", variables],
    fetcher<IChatChatGetMessagesRelativeToMessageQuery, IChatChatGetMessagesRelativeToMessageQueryVariables>(
      client,
      ChatGetMessagesRelativeToMessageDocument,
      variables,
      headers,
    ),
    options,
  );
};

export const ChatGetPossibleMembersDocument = `
    query ChatGetPossibleMembers($channelIo: ChatIOChannelInputSchema!, $orgId: String) {
  getPossibleMembers(channelIO: $channelIo, orgId: $orgId) {
    data {
      organizationUserId
      accountId
      name
      email
      id
      type
      status
      notificationSubscription
      lastSeenMessageId
      unseenMentionIds
      hasUnseenMention
      unseenReactionIds
      hasUnseenReaction
    }
    error
  }
}
    `;

export const useChatGetPossibleMembersQuery = <TData = IChatChatGetPossibleMembersQuery, TError = unknown>(
  client: GraphQLClient,
  variables: IChatChatGetPossibleMembersQueryVariables,
  options?: UseQueryOptions<IChatChatGetPossibleMembersQuery, TError, TData>,
  headers?: RequestInit["headers"],
) => {
  return useQuery<IChatChatGetPossibleMembersQuery, TError, TData>(
    ["ChatGetPossibleMembers", variables],
    fetcher<IChatChatGetPossibleMembersQuery, IChatChatGetPossibleMembersQueryVariables>(
      client,
      ChatGetPossibleMembersDocument,
      variables,
      headers,
    ),
    options,
  );
};

export const ChatMakePrivateChannelDocument = `
    mutation ChatMakePrivateChannel($channelIo: ChatIOChannelInputSchema!) {
  makePrivateChannel(channelIO: $channelIo) {
    data {
      parentType
      parentKey
      parentContext
      parentOrganizationId
      isPrivate
      isManaged
      id
      name
      url
      exists
      lastMessageId
      isMember
      isClosed
      canBeClosed
    }
    error
  }
}
    `;

export const useChatMakePrivateChannelMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    IChatChatMakePrivateChannelMutation,
    TError,
    IChatChatMakePrivateChannelMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    IChatChatMakePrivateChannelMutation,
    TError,
    IChatChatMakePrivateChannelMutationVariables,
    TContext
  >(
    ["ChatMakePrivateChannel"],
    (variables?: IChatChatMakePrivateChannelMutationVariables) =>
      fetcher<IChatChatMakePrivateChannelMutation, IChatChatMakePrivateChannelMutationVariables>(
        client,
        ChatMakePrivateChannelDocument,
        variables,
        headers,
      )(),
    options,
  );
};

export const ChatReactToMessageDocument = `
    mutation ChatReactToMessage($messageRelatedIo: ChatIOMessageContentBlockMessageRelatedInput!, $messageId: Int!, $channelIo: ChatIOChannelInputSchema!) {
  reactToMessage(
    messageRelatedIO: $messageRelatedIo
    messageId: $messageId
    channelIO: $channelIo
  ) {
    data {
      message {
        memberId
        organizationUserId
        accountId
        channelId
        content {
          type
          message
          related {
            key
            related {
              key
              type
              label
            }
          }
          columns {
            value
          }
          rows {
            cells {
              value
            }
          }
        }
        replyToMessageId
        id
        createdAt
        updatedAt
        type
        replyToMessage {
          memberId
          organizationUserId
          accountId
          channelId
          replyToMessageId
          id
          createdAt
          updatedAt
          type
        }
      }
    }
    error
  }
}
    `;

export const useChatReactToMessageMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    IChatChatReactToMessageMutation,
    TError,
    IChatChatReactToMessageMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<IChatChatReactToMessageMutation, TError, IChatChatReactToMessageMutationVariables, TContext>(
    ["ChatReactToMessage"],
    (variables?: IChatChatReactToMessageMutationVariables) =>
      fetcher<IChatChatReactToMessageMutation, IChatChatReactToMessageMutationVariables>(
        client,
        ChatReactToMessageDocument,
        variables,
        headers,
      )(),
    options,
  );
};

export const ChatRemoveMembersDocument = `
    mutation ChatRemoveMembers($membersIds: [Int!]!, $channelIo: ChatIOChannelInputSchema!) {
  removeMembers(membersIds: $membersIds, channelIO: $channelIo) {
    data {
      members {
        organizationUserId
        accountId
        name
        email
        id
        type
        status
        notificationSubscription
        lastSeenMessageId
        unseenMentionIds
        hasUnseenMention
        unseenReactionIds
        hasUnseenReaction
      }
      message {
        memberId
        organizationUserId
        accountId
        channelId
        content {
          type
          message
          related {
            key
            related {
              key
              type
              label
            }
          }
          columns {
            value
          }
          rows {
            cells {
              value
            }
          }
        }
        replyToMessageId
        id
        createdAt
        updatedAt
        type
        replyToMessage {
          memberId
          organizationUserId
          accountId
          channelId
          replyToMessageId
          id
          createdAt
          updatedAt
          type
          content {
            type
            message
            related {
              key
              related {
                key
                type
                label
              }
            }
            columns {
              value
            }
            rows {
              cells {
                value
              }
            }
          }
        }
      }
    }
    error
  }
}
    `;

export const useChatRemoveMembersMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    IChatChatRemoveMembersMutation,
    TError,
    IChatChatRemoveMembersMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<IChatChatRemoveMembersMutation, TError, IChatChatRemoveMembersMutationVariables, TContext>(
    ["ChatRemoveMembers"],
    (variables?: IChatChatRemoveMembersMutationVariables) =>
      fetcher<IChatChatRemoveMembersMutation, IChatChatRemoveMembersMutationVariables>(
        client,
        ChatRemoveMembersDocument,
        variables,
        headers,
      )(),
    options,
  );
};

export const ChatSendMessageDocument = `
    mutation ChatSendMessage($messageIo: ChatIOMessageInputSchema!, $channelIo: ChatIOChannelInputSchema!) {
  sendMessage(messageIO: $messageIo, channelIO: $channelIo) {
    data {
      message {
        memberId
        organizationUserId
        accountId
        channelId
        content {
          type
          message
          related {
            key
            related {
              key
              type
              label
            }
          }
          columns {
            value
          }
          rows {
            cells {
              value
            }
          }
        }
        replyToMessageId
        id
        createdAt
        updatedAt
        type
        replyToMessage {
          memberId
          organizationUserId
          accountId
          channelId
          replyToMessageId
          id
          createdAt
          updatedAt
          type
          content {
            type
            message
            related {
              key
              related {
                key
                type
                label
              }
            }
            columns {
              value
            }
            rows {
              cells {
                value
              }
            }
          }
        }
      }
      systemMessage {
        memberId
        organizationUserId
        accountId
        channelId
        replyToMessageId
        id
        createdAt
        updatedAt
        type
      }
    }
    error
  }
}
    `;

export const useChatSendMessageMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<IChatChatSendMessageMutation, TError, IChatChatSendMessageMutationVariables, TContext>,
  headers?: RequestInit["headers"],
) => {
  return useMutation<IChatChatSendMessageMutation, TError, IChatChatSendMessageMutationVariables, TContext>(
    ["ChatSendMessage"],
    (variables?: IChatChatSendMessageMutationVariables) =>
      fetcher<IChatChatSendMessageMutation, IChatChatSendMessageMutationVariables>(
        client,
        ChatSendMessageDocument,
        variables,
        headers,
      )(),
    options,
  );
};

export const ChatUpdateLastSeenMessageDocument = `
    mutation ChatUpdateLastSeenMessage($messageId: Int!, $channelIo: ChatIOChannelInputSchema!) {
  updateLastSeenMessage(messageId: $messageId, channelIO: $channelIo) {
    data {
      organizationUserId
      accountId
      name
      email
      id
      type
      status
      notificationSubscription
      lastSeenMessageId
      unseenMentionIds
      hasUnseenMention
      unseenReactionIds
      hasUnseenReaction
    }
    error
  }
}
    `;

export const useChatUpdateLastSeenMessageMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    IChatChatUpdateLastSeenMessageMutation,
    TError,
    IChatChatUpdateLastSeenMessageMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    IChatChatUpdateLastSeenMessageMutation,
    TError,
    IChatChatUpdateLastSeenMessageMutationVariables,
    TContext
  >(
    ["ChatUpdateLastSeenMessage"],
    (variables?: IChatChatUpdateLastSeenMessageMutationVariables) =>
      fetcher<IChatChatUpdateLastSeenMessageMutation, IChatChatUpdateLastSeenMessageMutationVariables>(
        client,
        ChatUpdateLastSeenMessageDocument,
        variables,
        headers,
      )(),
    options,
  );
};

export const ChatUpdateNotificationSubscriptionDocument = `
    mutation ChatUpdateNotificationSubscription($subscription: NotificationSubscriptionType!, $channelIo: ChatIOChannelInputSchema!) {
  updateNotificationSubscription(
    subscription: $subscription
    channelIO: $channelIo
  ) {
    data {
      organizationUserId
      accountId
      name
      email
      id
      type
      status
      notificationSubscription
      lastSeenMessageId
      unseenMentionIds
      hasUnseenMention
      unseenReactionIds
      hasUnseenReaction
    }
    error
  }
}
    `;

export const useChatUpdateNotificationSubscriptionMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    IChatChatUpdateNotificationSubscriptionMutation,
    TError,
    IChatChatUpdateNotificationSubscriptionMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    IChatChatUpdateNotificationSubscriptionMutation,
    TError,
    IChatChatUpdateNotificationSubscriptionMutationVariables,
    TContext
  >(
    ["ChatUpdateNotificationSubscription"],
    (variables?: IChatChatUpdateNotificationSubscriptionMutationVariables) =>
      fetcher<
        IChatChatUpdateNotificationSubscriptionMutation,
        IChatChatUpdateNotificationSubscriptionMutationVariables
      >(client, ChatUpdateNotificationSubscriptionDocument, variables, headers)(),
    options,
  );
};
